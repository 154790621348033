import { Icon } from "@iconify/react"
import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import i18n from "util/base/i18n"
import { selectProps } from "util/props/props"
import { IFlexFieldValues } from "../../IProjectionModeling"
import { IConditionsForm, ListConditions, StepOneProps } from "./IStepOne"
import ConditionLine from "./components/ConditionLine"
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters"

export default function StepOne({
    form,
    originOptions,
    eventData,
    stepOneListConditions,
    setStepOneListConditions,
    loadFlexFields,
    flexFieldValues,
    selectedEvent,
    setSelectedEvent,
    currentStep,
    flexFieldList,
    ledgerList,
    grouperData,
    grouperList,
    setGrouperList,
    setOriginSelected
}: StepOneProps) {
    const grouperDataFomatted: IModalityFilter[] = grouperData.map(({ id, description, externalCode }) => {
        return ({
            label: `${externalCode} - ${description}`,
            value: id
        })
    });

    const [filteredFlexFieldValues, setFilteredFlexFieldValues] = useState<IFlexFieldValues[]>([]);
    const [conditionsForm] = Form.useForm();
    const originSelected = Form.useWatch("origin", form);
    const levelSelected = Form.useWatch("levels", conditionsForm);

    useEffect(() => {
        if (levelSelected) {
            const filteredValues = flexFieldValues.filter(item => item.flexFieldId === levelSelected);
            setFilteredFlexFieldValues(filteredValues);
        }
    }, [levelSelected])

    useEffect(() => {
        const selectedValue = form.getFieldValue("grouper");

        if (!selectedValue && grouperList.length > 0) {

            form.setFieldsValue({ grouper: grouperList[0].value });
        }
    }, [grouperList, form]);


    function validateAndFilterConditions(currentConditions: ListConditions[], newConditions: ListConditions[]): ListConditions[] {
        const isEqualCondition = (currentCond: ListConditions, newCond: ListConditions): boolean => {
            return currentCond.levels === newCond.levels &&
                currentCond.levelsValue === newCond.levelsValue &&
                currentCond.operation === newCond.operation
        };

        const filteredNewConditions: ListConditions[] = newConditions.filter(newCond =>
            !currentConditions.some(currentCond => isEqualCondition(currentCond, newCond))
        );

        return [...currentConditions, ...filteredNewConditions];
    }

    function handleIncludeConditions({ levels, levelsValue, operation }: IConditionsForm) {
        setStepOneListConditions((prevConditions) => {
            const newConditions: ListConditions[] = levelsValue.map((value) => {
                return ({
                    levelsValue: value,
                    levels,
                    operation
                })
            })

            return validateAndFilterConditions(prevConditions, newConditions);
        });
        setFilteredFlexFieldValues([]);
        conditionsForm.resetFields();
    }

    function handleRemoveCondition(index) {
        setStepOneListConditions(stepOneListConditions.filter((item, itemIndex) => itemIndex !== index));
    }

    function handleDragEnd() {

    }

    function handleSelectEvent(event) {
        form.resetFields(["origin"]);
        conditionsForm.resetFields(["levels", "levelsValue"]);
        setSelectedEvent(event);
        if (event) {
            loadFlexFields(event);
        }
    }

    return (
        <div className="projection-main" style={{ display: currentStep !== 0 ? "none" : "block" }}>
            <Form
                form={form}
                name="new-account-form"
                layout="vertical"
                requiredMark={false}
            >
                <div className="fields-container-form1">
                    <Form.Item
                        className="fields-container-form-item"
                        name="description"
                        label={i18n.t<string>("description")}
                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        required={true}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        className="fields-container-form-item"
                        name="ledgerId"
                        label={i18n.t<string>("ledger_book")}
                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        required={true}
                    >
                        <Select
                            options={ledgerList}
                            placeholder={i18n.t<string>('select')}
                            {...selectProps}
                        />
                    </Form.Item>
                    <Form.Item
                        className="fields-container-form-item"
                        name="event"
                        label={i18n.t<string>("event")}
                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                    >
                        <Select
                            id="input-event"
                            options={eventData}
                            placeholder={i18n.t<string>('select')}
                            onChange={handleSelectEvent}
                            {...selectProps}
                        />
                    </Form.Item>
                    <Form.Item
                        className="fields-container-form-item"
                        name="origin"
                        label={`${i18n.t<string>("origin")} (01)`}
                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                    >
                        <Select
                            id="input-origin"
                            options={originOptions}
                            placeholder={i18n.t<string>('select')}
                            onChange={(event) => {
                                const origin = originOptions.find(origin => origin.value === event);
                                setOriginSelected(origin.value);
                            }}
                            {...selectProps}
                        />
                    </Form.Item>
                    <div className="fields-container-form-item-grouper-container">
                        <Form.Item
                            className="fields-container-form-item-grouper"
                            name="grouper"
                            label={i18n.t<string>("grouper")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                key="input-grouper"
                                id="input-grouper"
                                options={grouperDataFomatted}
                                placeholder={i18n.t<string>('select')}
                                {...selectProps}
                            />
                        </Form.Item>
                    </div>
                    <Form.Item
                        className="fields-container-form-item"
                        name="calculationOrder"
                        label={i18n.t<string>("calculation_order")}
                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                    >
                        <InputNumber
                            className="input-order"
                            controls={false} />
                    </Form.Item>
                </div>
            </Form>
            <Form
                form={conditionsForm}
                onFinish={handleIncludeConditions}
                layout="vertical"
                requiredMark={false}
            >
                <h4 className="conditions-title">{i18n.t<string>("conditions")}</h4>
                <div className="fields-container">
                    <Row gutter={20}>
                        <Col span={7}>
                            <Form.Item
                                name="levels"
                                label={i18n.t<string>("levels")}
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <Select
                                    id="input-levels-condition"
                                    options={flexFieldList}
                                    placeholder={i18n.t<string>('select')}
                                    {...selectProps}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="operation"
                                label={i18n.t<string>("operation")}
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <Select
                                    id="input-opration-conditions"
                                    options={[
                                        { value: "EQUALS", label: "Igual a" },
                                        { value: "NOT_EQUALS", label: "Diferente de" }
                                    ]}
                                    placeholder={i18n.t<string>('select')}
                                    {...selectProps}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                name="levelsValue"
                                label={i18n.t<string>("levels_value")}
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <Select
                                    id="input-levels-value-condition"
                                    options={filteredFlexFieldValues}
                                    placeholder={i18n.t<string>('select')}
                                    mode="multiple"
                                    {...selectProps}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3} className="plus-button-container">
                            <Button htmlType="submit"
                                className="plus-button gs-secondary-button"
                                icon={<Icon icon="akar-icons:circle-plus-fill" />}>
                                {i18n.t<string>("add")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
            <div className="step-one-list-container">
                <h3 className="detail-filters-title">{i18n.t<string>("detail_filters")}</h3>
                {stepOneListConditions.length > 0 ? (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable
                            droppableId="attribute_parameterization-new-rule-modal-conditions-workarea"
                            direction="vertical"
                        >
                            {(provided) => (
                                <div
                                    className="attribute_parameterization-new-attribute-modal-rules-workarea"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {stepOneListConditions.map((condition, index) => {
                                        return (
                                            <ConditionLine
                                                key={index}
                                                index={index}
                                                selectedLevel={flexFieldList.find(item => item.value === condition.levels)?.label}
                                                operation={condition.operation}
                                                selectedLevelValue={flexFieldValues.find(item => item.value === condition.levelsValue)?.label}
                                                handleRemove={(index) => handleRemoveCondition(index)}
                                            />
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                ) : (
                    <span>{i18n.t<string>("no_conditions_filters")}</span>
                )}
            </div>
        </div>
    )

}    