import i18n from "util/base/i18n";
import { Button } from "antd";
import { Icon } from "@iconify/react";
import './styles.sass'

interface PendenciesProps {
  //type: 'ok' | 'warning' | 'danger';
  amount: number;
}

export function Pendencies({amount}: PendenciesProps) {
  const penAmount = amount > 9 ? amount : amount === 0 ? amount: "0" + amount;
  const iconColor = amount === 0 ?  '#41AF52' : amount < 5 ? '#F69726' : "#F13131"
  const iconType = amount === 0 ?  'el:ok-sign' : amount < 5 ? 'akar-icons:circle-alert-fill' : 'bxs:error-alt'

  return (
    <Button 
	  hidden={true}
      onClick={() => {}}
      id="btn-pendencies"
      style={{
        display: "flex", 
        alignItems: "center", 
        height: 60,
        borderRadius: 8
      }}
    >
      <Icon 
        icon={iconType} 
        width={24} 
        style={{
          color: iconColor
        }}
      />
      <span style={{
        display: "flex", 
        flexDirection: "column", 
        alignItems: "start",
        margin: "0 0 0 10px"
      }}>
        <p style={{margin: 0}}>{i18n.t<string>("total_pending")}</p>  
        <strong style={{fontSize: 18, lineHeight: 1}}>{penAmount}</strong>
      </span>
    </Button>
  )
}