import { useState } from "react";

import { Button, Popover } from "antd";

import i18n from "util/base/i18n";
import './Filter.sass';
import 'components/small/Small.sass'
import { IFilter } from "../IDashboardAnalytics";
import { Icon } from "@iconify/react";

import FilterContent from "./FilterContent";

interface Props {

	filter: IFilter

	callBack: Function

}

export default function Filter(props: Props) {

	const [visiblePopover, setVisiblePopover]: [boolean, Function] = useState(false);

	const handleVisibleChange = (visible: boolean) => {
		setVisiblePopover(visible);
	}

	const openPopover = () => {
		setVisiblePopover(true);
	}

	return (
		<Popover destroyTooltipOnHide={true} onVisibleChange={handleVisibleChange} visible={visiblePopover} overlayClassName="filter-popover"
			title={i18n.t<string>("filterBy")} content={
				props.filter != null &&
				<FilterContent callBack={props.callBack} filter={props.filter} visiblePopover={handleVisibleChange} />
			} trigger="click">
			<Button className="cm-step1 cm-step1-main" onClick={openPopover} type="text" size="large" icon={<Icon icon={"clarity:filter-solid"} />} />
		</Popover>
	)
}