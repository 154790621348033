import { Alert, Button, Spin, Tooltip, Typography } from "antd";
import moment from "moment";
import { Key, useEffect, useState } from "react";
import { MessageType, RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { PremissesFilter } from "./components/PremissesFilters";
import { PremissesTable } from "./components/PremissesTable";
import { usePremissesFilters } from "./context/usePremissesFilters";
import { Premise } from "./IPremisses";
import './Premisses.sass'
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Icon } from "@iconify/react";
import { ROOT_PATH } from "util/rootPath";
import { Notification } from "components/notification/Notification";
import { formatNumber } from "util/formatNumber";
import { handleRedirect } from "util/functions/handleRedirect";
import { useNavigate } from "react-router";

export function Premisses() {
	const [premisses, setPremisses] = useState<Premise[]>([]);
	const [isFetchingPremisses, setIsFetchingPremisses] = useState(true)
	const [isFetchingDefaultPremissesData, setIsFetchingDefaultPremissesData] = useState(true)
	const [isSaving, setIsSaving] = useState(true)
	const [isApplyingPremisses, setIsApplyingPremisses] = useState(false)
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [isClonningPremisses, setIsClonningPremisses] = useState(false)
	const [isApplyingPremissesBtnDisabled, setIsApplyingPremissesBtnDisabled] = useState(true)
	const [savedValue, setSavedValue] = useState(false)
	const [hasNextYear, setHasNextYear] = useState(false);
	const { businessUnit, businessUnitOptions, costCategory, costCategoryOptions, fieldHR, year, userInfo, flexFieldsFiltersSelected, isFetchingCostCategoryOptions } = usePremissesFilters()
	const monthList = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
	const navigate = useNavigate()
	const { Text } = Typography

	const tableData = premisses.map(item => {
		return {
			id: item.id,
			key: item.key,
			businessUnit: item.businessUnit ? item.businessUnit.name : i18n.t("all_units"),
			costCategory: item.costCategory ? item.costCategory.name : i18n.t("all_categories"),
			fieldHR: item.field.description,
			indexer: 'Nenhum',
			year: item.year,
			plannedValue_january: item.january ?? 0,
			plannedValue_february: item.february ?? 0,
			plannedValue_march: item.march ?? 0,
			plannedValue_april: item.april ?? 0,
			plannedValue_may: item.may ?? 0,
			plannedValue_june: item.june ?? 0,
			plannedValue_july: item.july ?? 0,
			plannedValue_august: item.august ?? 0,
			plannedValue_september: item.september ?? 0,
			plannedValue_october: item.october ?? 0,
			plannedValue_november: item.november ?? 0,
			plannedValue_december: item.december ?? 0,
		}
	})

	const editableCellProps: any = {
		align: 'center',
		editable: true,
		className: 'editable-value-cell',
		render: (value: number) => formatNumber(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
	}

	const columns: any = [
		{
			title: i18n.t("business_unit"),
			dataIndex: 'businessUnit',
			key: 'businessUnit',
			sorter: (a, b) => a.businessUnit > b.businessUnit,
			width: 200,
			fixed: "left",
			ellipsis: {
				showTitle: false,
			},
			render: (text: string) => {
				const isbroken = text.length > 20
				return (
					<Tooltip placement="topLeft" title={isbroken ? text : ''}>
						<Text ellipsis={{ tooltip: false }}>{text}</Text>
					</Tooltip>
				)
			},
		},
		{
			title: i18n.t("cost_category"),
			dataIndex: 'costCategory',
			key: 'costCategory',
			sorter: (a, b) => a.costCategory > b.costCategory,
			width: 200,
			fixed: "left",
			ellipsis: {
				showTitle: false,
			},
			render: (text: string) => {
				const isbroken = text.length > 20
				return (
					<Tooltip placement="topLeft" title={isbroken ? text : ''}>
						<Text ellipsis={{ tooltip: false }}>{text}</Text>
					</Tooltip>
				)
			},
		},
		{
			title: i18n.t("account_registration.hr_field"),
			dataIndex: 'fieldHR',
			key: 'fieldHR',
			sorter: (a, b) => a.fieldHR > b.fieldHR,
			width: 200,
			fixed: "left",
			ellipsis: {
				showTitle: false,
			},
			render: (text: string) => {
				const isbroken = text.length > 20
				return (
					<Tooltip placement="topLeft" title={isbroken ? text : ''}>
						<Text ellipsis={{ tooltip: false }}>{text}</Text>
					</Tooltip>
				)
			},
		},
		{
			title: i18n.t("indexer"),
			dataIndex: 'indexer',
			key: 'indexer',
			width: 150,
			fixed: "left",
			ellipsis: {
				showTitle: false,
			},
			render: (text: string) => {
				const isbroken = text.length > 20
				return (
					<Tooltip placement="topLeft" title={isbroken ? text : ''}>
						<Text ellipsis={{ tooltip: false }}>{text}</Text>
					</Tooltip>
				)
			},
		},
		{
			title: i18n.t('month.full.0').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_january',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.1').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_february',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.2').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_march',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.3').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_april',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.4').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_may',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.5').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_june',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.6').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_july',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.7').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_august',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.8').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_september',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.9').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_october',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.10').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_november',
			...editableCellProps,
			width: 75
		},
		{
			title: i18n.t('month.full.11').slice(0, 3) + ` ${year}`,
			dataIndex: 'plannedValue_december',
			...editableCellProps,
			width: 75
		},
	];

	useEffect(() => {
		if (!costCategoryOptions || businessUnitOptions.length === 0) return
		if (costCategoryOptions.length === 0) {
			setIsFetchingDefaultPremissesData(false)
		}
		handleFetchPremisses()
	}, [fieldHR, costCategoryOptions, businessUnitOptions])

	useEffect(() => {
		if (year && userInfo.selection.organizationId) {
			handleFindNextYear()
		}
	}, [year])

	function handleFetchPremisses() {
		setSelectedRowKeys([])
		setIsFetchingPremisses(true)

		const data = {
			businessUnit: businessUnit.length > 0 ? businessUnit.map(bus => bus.value) : null,
			year: year[0],
			costCategory: costCategory.length > 0 ? costCategory.map(cc => cc.value) : null,
			flexKey: flexFieldsFiltersSelected
		}

		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: '/human-resources/premisses/findAll?' +
				'scenario={scenario}' +
				'&client={client}' +
				'&user={user}' +
				'&locale={locale}' +
				'&organization={organization}',
			params: data
		}, handleUpdateTableData)
	}

	function handleFindNextYear() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/budget-base/control-panel?' +
				'scenario={scenario}' +
				'&client={client}' +
				'&user={user}' +
				'&locale={locale}' +
				`&year=${year[0] + 1}` +
				`&organization=${userInfo.selection.organizationId}` +
				`&module=HUMAN_RESOURCES`
		}, handleHasNextYear.bind(this))
	}

	function handleHasNextYear(data: any) {
		const controlPanel = data.find(control => control.organizationId === userInfo.selection.organizationId)
		setHasNextYear(controlPanel.year ? true : false);
	}

	function handleUpdateTableData(data: Premise[]) {
		const defaultPremissesData = createDefaultPremissesData()

		if (data.length > 0) {
			const dataLoadedWithKey = data.map(item => {
				return {
					...item,
					key: item.key ? item.key : `${item.businessUnit?.id ?? ''}:${item.costCategory?.id ?? ''}:${item.field.id}`,
				}
			})

			const updatedPremisses = defaultPremissesData.map((item) => {
				const prem = dataLoadedWithKey.find(prem => prem.key === item.key)
				if (prem) return prem
				const premisse = premisses.find(prem => prem.key === item.key)
				if (premisse) return premisse
				return item
			})

			setPremisses(updatedPremisses)
		} else {
			setPremisses(defaultPremissesData)
		}
		setIsFetchingPremisses(false)
		setIsFetchingDefaultPremissesData(false)
		setIsSaving(false)
	}

	function createDefaultPremissesData() {
		let updatedDefaultPremissesData: Premise[] = []
		const businessUnitList = businessUnit.length > 0 ? businessUnit : [{ value: null, label: i18n.t("all_units") }]
		const costCatogoryList = costCategory.length > 0 ? costCategory : [{ value: null, label: i18n.t("all_categories") }]

		for (let i = 0; i < businessUnitList.length; i++) {
			for (let j = 0; j < costCatogoryList.length; j++) {
				for (let k = 0; k < fieldHR.length; k++) {
					const combination = {
						id: null,
						key: `${businessUnitList[i].value ? businessUnitList[i].value : ''}:${costCatogoryList[j].value ? costCatogoryList[j].value : ''}:${fieldHR[k].value}`,
						scenario: {
							id: userInfo.selection.scenarioId,
						},
						...(businessUnit.length > 0 && {
							businessUnit: {
								id: businessUnitList[i].value,
								name: businessUnitList[i].label,
							}
						}),
						organizationId: userInfo.selection.organizationId,
						...(costCategory.length > 0 && {
							costCategory: {
								id: costCatogoryList[j].value,
								name: costCatogoryList[j].label
							}
						}),
						field: {
							id: fieldHR[k].value,
							description: fieldHR[k].label,
							type: 'PERCENTAGE',
						},
						flexKey: flexFieldsFiltersSelected,
						year: year.length > 0 ? year[0] : moment().year(),
						templateValue: 12,
					};
					updatedDefaultPremissesData.push(combination);
				}
			}
		}

		return updatedDefaultPremissesData.sort((a, b) => {
			if (a.businessUnit && a.businessUnit.name < b.businessUnit.name) return -1
			if (a.businessUnit && b.businessUnit.name < a.businessUnit.name) return 1
			if (a.costCategory && a.costCategory.name < b.costCategory.name) return -1
			if (a.costCategory && b.costCategory.name < a.costCategory.name) return 1
			if (a.field?.id < b.field?.id) return -1
			return 1
		})
	}

	function handleSavePremisses(premisses) {
		setIsSaving(true)
		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: `/human-resources/premisses?localeId={locale}&clientId={client}&user={user}&organization={organization}`,
			params: premisses
		}, (data) => {
			handleUpdateTableData(data)
			setSavedValue(true)
			setTimeout(() => {
				setSavedValue(false)
			}, 3000)
		}, (err) => {
			handleErrorRequest(err)
			setIsSaving(false)
		})
	}

	function handleApplyPremisses() {
		setIsApplyingPremisses(true)

		const premissesWithValues = premisses.filter(pre => Object.keys(pre).some(key => monthList.includes(key) && pre[key] !== 0)).filter(prem => selectedRowKeys.includes(prem.key))

		if(premissesWithValues.length > 0){
			const extractIds = (arr, prop) => arr.reduce((acc, item) => {
				if (item[prop] && !acc.includes(item[prop].id)) {
					acc.push(item[prop].id);
				}
				return acc;
			}, []);
		
			const businessUnitIds = extractIds(premissesWithValues, 'businessUnit');
			const costCategoryIds = extractIds(premissesWithValues, 'costCategory');
		
			if (businessUnitIds.length === 0) {
				businessUnitIds.push(...businessUnitOptions.map(bu => bu.value));
			}
		
			if (costCategoryIds.length === 0) {
				costCategoryIds.push(...costCategoryOptions.map(cc => cc.value));
			}
		
			const data = {
				templates: premissesWithValues,
				businessUnitIds,
				costCategoryIds,
				flexKey: premissesWithValues[0]?.flexKey || null
			};

			ServiceCaller.doRequest({
				type: RequestType.PUT,
				url: `/human-resources/premisses/apply?scenarioId={scenario}&year=${year}&organizationId={organization}&locale={locale}&userId={user}&clientId={client}&buId={businessUnit}`,
				params: data
			}, (data) => {
				setIsApplyingPremisses(false)
				setSelectedRowKeys([])
				Notification({
					type: data.employedResourceIds.length > 0 ? 'success' : 'warning',
					message: data.employedResourceIds.length > 0 ? i18n.t("premisses.premises_applied_successfully") : i18n.t("premisses.there_are_no_hires"),
					duration: data.employedResourceIds.length > 0 ? 3 : 5
				})
			}, (err) => {
				setSelectedRowKeys([])
				setIsApplyingPremisses(false)
				handleErrorRequest(err)
			})
		}else {
			Notification({
				type: 'warning',
				message: 'Não há premissas com valores selecionadas.',
				duration: 5
			})
			setIsApplyingPremisses(false)
		}
	}

	function handleClonePremisses() {
		setIsClonningPremisses(true)

		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: `/human-resources/premisses/clonePremisses?originYear=${year}&destinyYear=${Number(year) + 1}&scenarioId={scenario}`,
			params: {}
		}, (data) => {
			setIsClonningPremisses(false)
			Notification({
				type: 'success',
				message: i18n.t("premisses.premises_successfully_cloned"),
				duration: 3
			})
		}, (err) => {
			setIsClonningPremisses(false)
			handleErrorRequest(err)
		})
	}

	function redirectToRegistration(path: string) {
		ServiceCaller.doSendMessage({
			messageType: MessageType.REDIRECT_TO,
			params: {
				url: `${ROOT_PATH}/mf/budget/collaborators/${path}`
			}
		});
	}

	function onChangeRowSelection(key: Key[]) {
		setSelectedRowKeys(key)
		if (key.length > 0) {
			setIsApplyingPremissesBtnDisabled(false)
		} else {
			setIsApplyingPremissesBtnDisabled(true)
		}
	}

	return (
		<>
			<div style={{ justifyContent: 'space-between' }} className="page-title-content">
				<h1>{i18n.t("premisses.hr_assumptions")}</h1>
				{/* <h1>{i18n.t<string>("simulation_scenario")}</h1>
				<Button 
				onClick={()=>{}} 
				style={{ borderRadius: 4 }} 
				icon={<Icon style={{ color: '#4F9ACF', fontSize: 26 }} icon="clarity:info-line" />}
				/> */}
				<div></div>
			</div>
			{
				(isFetchingDefaultPremissesData || isFetchingCostCategoryOptions) ?
					<div className="premisses-spin-container">
						<Spin tip={`${i18n.t('loading')}...`} />
					</div>
					: fieldHR.length > 0 && costCategoryOptions.length > 0 ?
						<>
							<PremissesFilter
								handleFetchPremisses={handleFetchPremisses}
								handleApplyPremisses={handleApplyPremisses}
								handleClonePremisses={handleClonePremisses}
								isApplyingPremisses={isApplyingPremisses}
								isClonningPremisses={isClonningPremisses}
								isApplyingPremissesBtnDisabled={isApplyingPremissesBtnDisabled}
								hasNextYear={hasNextYear}
							/>
							<div className="premisses-save-alert-container">
								{!isSaving &&
									<Alert
										className="successfully-saved-budget-alert"
										style={{
											opacity: savedValue ? 1 : 0
										}}
										message={i18n.t("changes_made_successfully")}
										type="success"
										showIcon
										icon={<Icon icon="material-symbols:done-rounded" />}
									/>}
								{isSaving && <Spin style={{ marginRight: 14, marginTop: 2 }} size={'small'} />}
							</div>
							<PremissesTable
								premisses={premisses}
								setPremisses={setPremisses}
								isFetching={isFetchingPremisses}
								handleSavePremisses={handleSavePremisses}
								monthList={monthList}
								columns={columns}
								tableData={tableData}
								selectedRowKeys={selectedRowKeys}
								onChange={onChangeRowSelection}
							/>
						</>
						:
						<div className="budget-initial-content" style={{ marginTop: 50 }}>
							<img src="/assets/images/budget.png" alt="wfn-budget" />
							<h2>{fieldHR.length === 0 ? i18n.t("premisses.there_are_no_hr_fields_registered") : i18n.t("there_are_no_cost_category_registered")}</h2>
							<p>{fieldHR.length === 0 ? i18n.t("premisses.to_create_a_premise") : i18n.t("to_create_a_premise_create_cost_category")}</p>
							<div>
								<Button
									onClick={() => {
										handleRedirect(
											`${ROOT_PATH}/mf/budget/collaborators/${fieldHR.length === 0 ? 'additionalFields' : 'cost-category'}`,
											true,
											navigate
										)
									}}
									className="gs-main-button"
									style={{ padding: '0 15px' }}
								>
									{fieldHR.length === 0 ? i18n.t("premisses.go_to_hr_fields") : i18n.t("register_cost_category")}
								</Button>
							</div>
						</div>
			}
		</>
	)
}