import {
	useState,
	useEffect
} from "react";

import { DefaultRecordType } from "rc-table/lib/interface"

import {
	Card,
	Space,
	Table,
	Tag,
	Typography
} from "antd";

import { Icon } from "@iconify/react";

import i18n from "util/base/i18n";

import {
	IDataDataSource,
	IIndexer,
	IIndexerRate,
} from "./IIndexer";
import { FormattedNumber } from "react-intl";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

export default function IndexerCard(props) {

	const [loading, setLoading]: [boolean, Function] = useState(true);

	const columns: DefaultRecordType[] = [{
		key: "indexer",
		dataIndex: "indexerName"
	}, {
		key: "rate",
		title: i18n.t<string>("rates"),
		dataIndex: "rate",
		width: 80,
		render: buildRateTag.bind(this)
	}]

	const [dataGrid, setDataGrid]: [IIndexer[], Function] = useState([{
		indexerName: `SELIC (${i18n.t<string>("day")})`,
		rate: {
			value: 0,
			increased: false
		}
	}, {
		indexerName: `CDI (${i18n.t<string>("day")})`,
		rate: {
			value: 0,
			increased: false
		}
	}, {
		indexerName: `IPCA (${i18n.t<string>("month_name")})`,
		rate: {
			value: 0,
			increased: false
		}
	}]);

	function buildRateTag({ value, increased }: IIndexerRate): JSX.Element {
		let arrowIcon: React.ReactElement;

		if (increased) {
			arrowIcon = <Icon className="gs-font-color-green" icon="akar-icons:arrow-up" />;
		} else {
			arrowIcon = <Icon className="gs-font-color-red" icon="akar-icons:arrow-down" />;
		}

		return (
			<Tag className="white" icon={arrowIcon}>
				<FormattedNumber value={value / 100} style={`percent`} minimumFractionDigits={2} maximumFractionDigits={2} />
			</Tag>
		)
	}

	useEffect(() => {
		function buildData(e: IDataDataSource[]) {
			setDataGrid(dataGrid.map((item) => {
				return (
					{
						indexerName: item.indexerName,
						rate: e.find(element => item.indexerName.includes(element.externalCode)).rate
					}
				)
			}))

			setLoading(false);
		}

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/dashboard-analytics/widget/financing/indexer-values?client={client}"
		}, buildData.bind(this));
	}, []);

	return (
		<Card title={
			<Space align="center">
				<Icon icon={"ant-design:line-chart-outlined"} fontSize={20} color={"#7EB5DC"} />
				<Typography.Title level={5}>{i18n.t<string>("indexers")}</Typography.Title>
			</Space>
		} loading={loading} bordered={false}>
			<Table columns={columns} dataSource={dataGrid} pagination={false} />
		</Card>
	)

}
