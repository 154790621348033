import moment from "moment";
import i18n from "util/base/i18n";

import { createContext, useEffect, useState } from 'react';
import { useUserContext } from "context/UserContext";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { ILoadReportParams, IReportViewContext, IReportVisualizationFilters, IUpdatedReportVisualizationConfig, ReportComparativeRequestBean } from '../IReportView';
import { IReport, IReportConfig, IReportFilter } from "../components/Filters/IFilters";
import { buildGridColumns } from "../components/ReportTable/buildColumnsGrid";
import { sortList } from "util/functions/sortList";

export const ReportViewContext = createContext<IReportViewContext>({} as IReportViewContext);

export function ReportViewProvider({ children }) {
    const { userInfo } = useUserContext();

    const [period, setPeriod] = useState<[moment.Moment, moment.Moment]>(() => {
        return [moment().month(11).endOf('month'), moment().startOf('year')];
    });

    const [periodBase, setPeriodBase] = useState<[moment.Moment, moment.Moment]>(() => {
        return [moment().month(11).endOf('month'), moment().startOf('year')];
    });

    const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
    const [selectedReportId, setSelectedReportId] = useState<number>(null);
    const [reportConfig, setReportConfig] = useState<IReportConfig>();
    const [report, setReport] = useState(null);
    const [selectedFlexFieldValues, setSelectedFlexFieldValues] = useState([]);
    const [selectedFlexFieldOrganizationValues, setSelectedFlexFieldOrganizationValues] = useState([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState<number[]>([]);
    const [defaultColumns, setDefaultColumns] = useState<any>([]);
    const [isPopOverAvailable, setIsPopOverAvailable] = useState<boolean>(false);
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
    const [popOverConfigs, setPopOverConfigs] = useState<IUpdatedReportVisualizationConfig>(null);
    const [isDetailingLevel, setIsDetailingLevel] = useState(false);
    const [selectedModalityType, setSelectedModalityType] = useState('');
    const [selectedPeriodicityType, setSelectedPeriodicityType] = useState<number>(0);
    const [selectedFlexFieldValuesDetails, setSelectedFlexFieldValueDetails] = useState<any[]>([]);
    const [selectedCurrency, setSelectedCurrency] = useState<number>(0);
    const [dataTarget, setDataTarget] = useState<any[]>([
        {
            key: '1',
            name: i18n.t<string>("attributes"),
            index: 0,
            valueType: 'ATTRIBUTE'

        },
        {
            key: '2',
            name: i18n.t<string>("ledger_account"),
            index: 1,
            valueType: 'ACCOUNT_HIERARCHY'
        },
        {
            key: '3',
            name: i18n.t<string>("cost_center"),
            index: 2,
            valueType: 'COST_CENTER'
        },
    ]);

    const [baseType, setBaseType] = useState<"budgeted" | "accomplished">("budgeted");
    const [baseTarget, setBaseTarget] = useState<"budgeted" | "accomplished">("budgeted");
    const [scenarioToCompare, setScenarioToCompare] = useState<{ value: string, label: string }>({
        value: userInfo?.selection?.scenarioId ? userInfo.selection.scenarioId.toString() : '0',
        label: userInfo?.selection?.scenarioName ? userInfo.selection.scenarioName : 'Selecione...'
    });
    const [showRowFilterComparative, setShowRowFilterComparative] = useState(false);
    const [yearToCompare, setYearToCompare] = useState<number>(parseFloat(moment().format("YYYY")));
    const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
    const [isPlanningReportSelected, setIsPlanningReportSelected] = useState(false);
    const [rowsIdToDetail, setRowsIdToDetail] = useState<number[]>([]);
    const [consolidations, setConsolidations] = useState<any[]>([]);
    const [allReports, setAllReports] = useState<IReportFilter[]>([]);
    const [consolidationSelected, setConsolidationSelected] = useState<string>(null);

    function handleIsPopOverAvailable(isAvailable: boolean) {
        setIsPopOverAvailable(isAvailable);
    }

    function closeFilterModal() {
        setIsFilterModalOpen(false);
    }

    function openFilterModal() {
        setIsFilterModalOpen(true);
    }

    function handleChangePeriod(newPeriod: [moment.Moment, moment.Moment]) {
        setPeriod(newPeriod);
    }

    function handleSelectReportId(reportId: number) {
        setSelectedReportId(reportId);
    }

    function getIntValueByModality(modality: string): number {
        switch (modality) {
            case "MONTHLY":
                return 1;
            case "BIMONTHLY":
                return 2;
            case "QUARTERLY":
                return 3;
            case "SEMESTERLY":
                return 6;
            case "YEARLY":
                return 12;
            default:
                return 0;
        }
    }

    function fetchConsolidation() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/consolidation`
        }, onLoadTableData.bind(this));
    }

    function onLoadTableData(data) {
        if (data.length === 0) {
            return;
        };

        const updateTableData = data.map(res => {
            return {
                value: res.id,
                label: res.externalCode + ' - ' + res.description,
            };
        }).sort((a, b) => sortList(a.label, b.label));

        setConsolidations(updateTableData);
    };

    function handleReportConfig(reportConfig: IReportConfig) {
        setSelectedPeriodicityType(getIntValueByModality(reportConfig.periodType));
        setSelectedCurrency(reportConfig.currencyId);
        setSelectedModalityType(reportConfig.modalityType);
        setReportConfig(reportConfig);
    }

    function handleIsDetailingLevel(isDetailing: boolean) {
        setIsDetailingLevel(isDetailing);
    }

    function handleDataTarget(data: any) {
        setDataTarget(data);
    }

    function handleSelectedModalityType(modalityType: string) {
        setSelectedModalityType(modalityType);
    }

    function handleSelectedPeriodicityType(periodicityType: string) {
        setSelectedPeriodicityType(getIntValueByModality(periodicityType));
    }

    function handleSelectedCurrency(currency: any) {
        setSelectedCurrency(currency);
    }

    function handleBaseType(e) {
        setBaseType(e.target.value);
    }

    function handleBaseTarget(e) {
        setBaseTarget(e.target.value);
    }

    function handleScenarioToCompare(scenario) {
        setScenarioToCompare(scenario);
    }

    function handleYearToCompare(year) {
        setYearToCompare(year);
    }

    function handlePeriodBase(newPeriod: [moment.Moment, moment.Moment]) {
        if (!newPeriod) {
            setPeriodBase(null);
            return;
        }

        if (newPeriod[1].year() > newPeriod[0].year()) {
            newPeriod[1] = moment(newPeriod[0]).endOf('year');
        }

        setPeriodBase(newPeriod);
    }

    function handleShowRowFilterComparative(show: boolean) {
        setShowRowFilterComparative(show);
    }

    function handleDetailModal(isOpen: boolean) {
        setIsDetailModalOpen(isOpen);
    }

    function handleConsolidationSelected(consolidationId: string) {
        setConsolidationSelected(consolidationId);
    }

    function buildGridContent(report: any) {
        const mappedRows = report.levels
            .filter(level => !level.invisible)
            .map(level => {
                let children: any[] = [];
                let isSubLevel: boolean = level.subLevels && level.subLevels.length > 0;
                let isFragment: boolean = level.reportValues?.fragments && level.reportValues.fragments.length > 0;
                const isDetailsPlan: boolean = !!level.reportValues.detailsPlan?.length;

                if (isSubLevel) {
                    children = buildLevelsContent(level.subLevels, report);
                } else if (isFragment) {
                    children = buildLevelsContent(level.reportValues.fragments, report);
                } else if (isDetailsPlan) {
                    children = buildLevelsContent(level.reportValues.detailsPlan, report);
                }

                let content = {
                    ...level,
                    key: level.id && !level.levelId ? level.id + "-" + level.description : level.levelId + "-" + level.name + "-" + level.formulaValueType,
                    children,
                    reportInfo: {
                        creationDate: report.creationDate,
                        description: report.description,
                        id: report.id,
                        periodType: report.periodType,
                        updateDate: report.updateDate,
                    }
                };

                return content;
            });

        return mappedRows;
    }

    function buildLevelsContent(subLevels: any[], report) {
        let subLevelsContent = subLevels
            .filter(level => !level.invisible)
            .flatMap(fragment => {
                if (fragment.useItems) {
                    return fragment.items;
                } else {
                    return fragment;
                }
            })
            .map(level => {
                let childrens = [];

                let isSubLevel: boolean = level.subLevels && level.subLevels.length > 0;
                let isFragment: boolean = level.reportValues?.fragments && level.reportValues.fragments.length > 0;
                let isItem: boolean = level.items && level.items.length > 0;

                if (isSubLevel) {
                    childrens = buildLevelsContent(level.subLevels, report);
                } else if (isFragment) {
                    childrens = buildLevelsContent(level.reportValues.fragments, report);
                } else if (isItem) {
                    childrens = buildLevelsContent(level.items, report);
                }

                let content = {
                    ...level,
                    key: Math.random(),
                    children: childrens,
                    reportInfo: {
                        creationDate: report.creationDate,
                        description: report.description,
                        id: report.id,
                        periodType: report.periodType,
                        updateDate: report.updateDate,
                    }
                };

                return content;
            });

        return subLevelsContent;
    }


    function getLastPlannedPeriod(): Promise<any> {
        return new Promise((resolve, reject) => {
            ServiceCaller.doRequest({
                url: `/budget-base/control-panel/find-realized-period-revenue?scenarioId={scenario}&organizationId={organization}&year=${period[1].year()}`,
                type: RequestType.GET
            }, (data: any) => {
                resolve(data);
            }, (err: ErrorRequest) => {
                handleErrorRequest(err);
            });
        });
    }

    function fetchReport(updatedReportConfig: any, isDetailingLevelFetch = false) {
        const currencyDefaultId = userInfo.currencyId;
        const scenarioId = userInfo.selection.scenarioId;
        const localeId = userInfo.localeId;

        const comparativeRequest: ReportComparativeRequestBean[] = [
            {
                scenarioId: userInfo.selection.scenarioId,
                year: periodBase[0].year(),
                isBase: true,
                revenueType: baseType === "budgeted" ? "PLANNED" : "REALIZED"
            },
            {
                scenarioId: Number(scenarioToCompare.value),
                year: yearToCompare,
                isBase: false,
                revenueType: baseTarget === "budgeted" ? "PLANNED" : "REALIZED"
            }
        ];

        const reportPayload: ILoadReportParams = {
            reportId: selectedReportId,
            localeId: localeId,
            scenarioId: scenarioId,
            currencyDefaultId: currencyDefaultId,
            organizationId: userInfo.selection.organizationId,
            organizationIds: selectedOrganizations,
            clientId: userInfo.clientId,
            userId: userInfo.id,
            isDetailing: isDetailingLevelFetch,
            comparativeRequest: comparativeRequest,
            groupIds: userInfo.groupIds,
            businessUnitId: userInfo.selection.businessUnitId,
            flexFieldValueIdsDetails: selectedFlexFieldValuesDetails,
            consolidationId: consolidationSelected ? Number(consolidationSelected) : null
        };

        if (selectedFlexFieldValues !== null) {
            const isFlexFieldValuesEmpty = Object.keys(selectedFlexFieldValues).length === 0;

            if (isFlexFieldValuesEmpty === false) {
                reportPayload["flexFields"] = selectedFlexFieldValues;
            }

            if (selectedFlexFieldOrganizationValues.length) {
                reportPayload["companyFlexFieldIds"] = selectedFlexFieldOrganizationValues;
            }
        }

        if (isDetailingLevelFetch) {
            reportPayload["detailsOrder"] = dataTarget
                .filter(target => rowsIdToDetail.includes(target.index))
                .map((target, index) => ({
                    order: index + 1,
                    type: target.valueType
                }));
        }

        setReport(null);
        handleIsLoadingReport(true);

        ServiceCaller.doRequest(
            {
                url: `/revenue/report/load-report`,
                type: RequestType.POST,
                params: reportPayload
            },
            async (data: any) => {
                const { periodRealized } = await getLastPlannedPeriod();

                const reportVisualizationFilters: IReportVisualizationFilters = {
                    decimalPlaces: updatedReportConfig.decimalPlaces,
                    showGraphic: updatedReportConfig.showGraphic,
                    showThousand: updatedReportConfig.showThousand,
                    showUnity: updatedReportConfig.showUnity
                };

                setPopOverConfigs({
                    decimalPlaces: updatedReportConfig.decimalPlaces,
                    showGraphic: updatedReportConfig.showGraphic,
                    showThousand: updatedReportConfig.showThousand,
                    showUnity: updatedReportConfig.showUnity
                });

                buildGridColumns(
                    updatedReportConfig,
                    period,
                    data,
                    reportVisualizationFilters,
                    false,
                    periodRealized,
                    periodBase,
                    !!consolidationSelected
                )
                    .then((generatedGrid) => {
                        new Promise((resolve, reject) => {
                            resolve(setDefaultColumns(generatedGrid))
                        })
                            .then(() => {
                                const mappedReport = buildGridContent(data);
                                setReport(mappedReport);
                            });
                    })
                    .then(() => {
                        handleIsLoadingReport(false);
                        handleIsPopOverAvailable(true);
                    });

            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );

        handleRowsIdToDetail([]);
    }

    async function handleReportDataChange(updatedReportVisualizationConfig: IUpdatedReportVisualizationConfig) {
        handleIsLoadingReport(true);

        setPopOverConfigs(updatedReportVisualizationConfig);

        const { periodRealized } = await getLastPlannedPeriod();

        buildGridColumns(
            reportConfig,
            period,
            report,
            updatedReportVisualizationConfig,
            true,
            periodRealized,
            periodBase,
            !!consolidationSelected
        )
            .then((generatedGrid) => {
                new Promise((resolve, reject) => {
                    resolve(setDefaultColumns(generatedGrid))
                })
            })
            .then(() => {
                handleIsLoadingReport(false);
            })
    }

    function handleIsLoadingReport(isLoading: boolean) {
        setIsLoadingReport(isLoading);
    }

    function handleRowsIdToDetail(rowsIds: number[]) {
        setRowsIdToDetail(rowsIds);
    }

    function loadAllReports(): void {
        ServiceCaller.doRequest(
            {
                url: `/revenue/report/list`,
                type: RequestType.GET,
            },
            (data: IReport[]) => {
                setAllReports(data.map(
                    report => ({
                        label: report.description,
                        value: report.id,
                        reportType: report.reportType
                    }))
                );
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }

    useEffect(() => {
        loadAllReports();
    }, []);

    useEffect(() => {
        const reportType = selectedReportId ? allReports.find(report => report.value === selectedReportId)?.reportType : null;
        if("PLANNING" === reportType) {
            fetchConsolidation();
        }
    }, [selectedReportId]);

    useEffect(() => {
        if (userInfo.selection.scenarioId) {
            handleScenarioToCompare({ value: userInfo.selection.scenarioId, label: userInfo.selection.scenarioName });
        }
    }, [userInfo.selection])

    useEffect(() => {
        setDataTarget(state => {
            const updatedState = state.map(item => {
                if (item.valueType === 'ACCOUNT_HIERARCHY') {
                    return {
                        ...item,
                        name: i18n.t<string>(isPlanningReportSelected ? "ledger_account" : "revenue.report_viewer.account_group")
                    };
                }
                return item;
            });

            updatedState.sort((a, b) => {
                if (a.index < b.index) return -1;
                if (a.index > b.index) return 1;
                return 0;
            });

            return updatedState;
        });
    }, [isPlanningReportSelected]);

    return (
        <ReportViewContext.Provider
            value={{
                isFilterModalOpen,
                openFilterModal,
                closeFilterModal,
                period,
                handleChangePeriod,
                selectedReportId,
                handleSelectReportId,
                reportConfig,
                handleReportConfig,
                fetchReport,
                report,
                defaultColumns,
                setDefaultColumns,
                selectedFlexFieldValues,
                setSelectedFlexFieldValues,
                selectedOrganizations,
                setSelectedOrganizations,
                setReport,
                buildGridContent,
                isLoadingReport,
                handleIsLoadingReport,
                isPopOverAvailable,
                handleReportDataChange,
                popOverConfigs,
                isDetailingLevel,
                handleIsDetailingLevel,
                handleDataTarget,
                dataTarget,
                selectedModalityType,
                selectedPeriodicityType,
                selectedCurrency,
                handleSelectedModalityType,
                handleSelectedPeriodicityType,
                handleSelectedCurrency,
                baseType,
                handleBaseType,
                baseTarget,
                handleBaseTarget,
                scenarioToCompare,
                handleScenarioToCompare,
                yearToCompare,
                handleYearToCompare,
                periodBase,
                handlePeriodBase,
                showRowFilterComparative,
                handleShowRowFilterComparative,
                isDetailModalOpen,
                handleDetailModal,
                setSelectedFlexFieldValueDetails,
                selectedFlexFieldValuesDetails,
                selectedFlexFieldOrganizationValues,
                setSelectedFlexFieldOrganizationValues,
                setIsPlanningReportSelected,
                isPlanningReportSelected,
                handleRowsIdToDetail,
                rowsIdToDetail,
                allReports,
                consolidations,
                consolidationSelected,
                handleConsolidationSelected
            }}
        >
            {children}
        </ReportViewContext.Provider>
    );
}
