import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Button, Input } from 'antd';
import { Icon } from '@iconify/react';
import i18n from 'util/base/i18n';

import { ServiceCaller } from 'util/service/ServiceCaller';
import { RequestType } from 'util/service/IServiceCaller';
import { usePermissionContext } from 'context/PermissionContext';
import { handleExportGridData } from "util/functions/handleExportGridData";

import { columnsToFormat } from './utils/columnsToFormat'
import { scenarioBroadcast } from 'util/BroadcastChannels';

import ContractValuesTable from './components/ContractValuesTable';
import ContractCounter from 'module/leaseProjection/components/contractCounter/ContractCounter';

import { IContractValues } from './IContractValues';
import './style.sass';

export default function ContractValues() {
    const location = useLocation();
    const [loadColumns, setLoadColumns] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [contractName, setContractName] = useState("")
    const [scenarioStatus, setScenarioStatus] = useState("");
    const [isFetching, setIsFetching] = useState(true)

    const [contractId, setContractId] = useState("");

    const { functionalityPermissions, setFunctionalityPermissions } = usePermissionContext();

    scenarioBroadcast.onmessage = (message) => {
        if (message.data === 'scenario-changed') {
            window.top.location.href = process.env.REACT_APP_GS_ROOT_PATH + process.env.REACT_APP_GS_BASE_PATH;
        }
    }

    useEffect(() => {
        let id = window.location.href
        let splitUrl = id.split("/");
        let index = splitUrl.findIndex(function (elemento) {
            return elemento === "values";
        });
        let value = splitUrl[index + 1];

        setContractId(value)
        getContractValues(value)
    }, [])

    useEffect(() => {
        ServiceCaller.doRequest({
            useProxy: false,
            type: RequestType.GET,
            url: "/projectionlease/contract/"
        }, onLoadPermissions.bind(this));
    }, [location])

    function onLoadPermissions(permList) {
        setFunctionalityPermissions(permList);
    }

    function getContractValues(contractId) {
        ServiceCaller.doRequest({
            useProxy: false,
            type: RequestType.GET,
            url: `/projectionlease/contract/values/${contractId}`
        }, onLoadColumns.bind(this));
    }

    function onLoadColumns(data: IContractValues) {
        const contractDataName = data.contractName
        setLoadColumns(data.columns)
        setDataTable(data.values)
        setContractName(contractDataName)
        setScenarioStatus(data.scenarioStatus)
        setIsFetching(false)
    }

    function handleExport() {
        handleExportGridData(dataTable, loadColumns, contractName)
    }

    function addRenderToColumns(columns, dataIndexArray) {
        return columns.map((column) => {
            if (Array.isArray(column.children)) {
                const updatedChildren = column.children.map((child) => {
                    let updatedChild = { ...child };

                    if (['referenceDate', 'paymentDate', 'disbursementValue', 'principalValueShort', 'principalValueLong', 'grossBalance'].includes(child.dataIndex)) {
                        updatedChild.fixed = 'left';
                    }

                    if (dataIndexArray.includes(child.dataIndex)) {
                        updatedChild.render = (value) => value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        updatedChild.align = 'right';
                    }

                    return updatedChild;
                });

                return {
                    ...column,
                    children: updatedChildren,
                };
            }

            return column;
        });
    }

    function handleCalculate() {
        setIsFetching(true)
        ServiceCaller.doRequest({
            useProxy: false,
            type: RequestType.POST,
            url: '/projectionlease/contract/calculateContract',
            params: contractId
        }, onCalculate.bind(this));
    }

    function onCalculate() {
        getContractValues(contractId)
    }

    const tableColumns = addRenderToColumns(loadColumns, columnsToFormat);

    return (
        <>
            <main id='contract-values-main' className='contract-values-main'>
                <h1 className='undo-title-and-planning'>{i18n.t<string>('contract_values')}</h1>
                <div className='top-button-contract-value'>
                    <div>
                        {functionalityPermissions.export &&
                            <Button
                                size='middle'
                                type='default'
                                onClick={handleExport}
                                className='gs-secondary-button'
                            >
                                <Icon icon={"bxs:file-export"} />
                                {i18n.t<string>('export')}
                            </Button>
                        }
                    </div>
                    {functionalityPermissions.calccontracts &&
                        <Button
                            size='middle'
                            type='default'
                            onClick={handleCalculate}
                            className='gs-secondary-button'
                            disabled={scenarioStatus !== 'RELEASED'}
                        >
                            <Icon icon={"mdi:calculator"} />
                            {i18n.t<string>('calculate')}
                        </Button>
                    }
                </div>
                <div className='contract-info'>
                    <div className="contract-name">
                        {i18n.t<string>('contract')}
                        <div className="contract-label">
                            <p>{contractName}</p>
                        </div>
                    </div>
                    <Input
                        prefix={<Icon icon="charm:search" />}
                        className="gs-search"
                        placeholder={i18n.t<string>('search')}
                        size={"middle"}
                        style={{ width: 250, height: 32 }}
                    />
                </div>
                <div className='top-table-info'>
                    <ContractCounter />
                </div>
                <div className='table-contract-values'>
                    <ContractValuesTable
                        columns={tableColumns}
                        dataTable={dataTable}
                        isFetching={isFetching}
                    />
                </div>
            </main>
        </>
    )

}