import { Layout } from "antd";
import SidebarContent from "./SidebarContent";
import { Key, useEffect, useState } from "react";
import { AccountData, AccountList, IContent, LevelItem } from "../IFromTo";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { ErrorRequest } from "util/types/types";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Loading } from "components/loading/Loading";
import TableContainer from "./TableContainer";
import { ImageBox } from "components/imageBox/ImageBox";
import i18n from "util/base/i18n";
import { useUserContext } from "context/UserContext";
import { Notification } from "components/notification/Notification";
import moment from "moment";

export default function Content({
    treeData,
    costCenterList,
    checkedKeys,
    setCheckedKeys,
    expandedKeys,
    setExpandedKeys,
    isFetching,
    selectedTab,
    referenceDate
}: IContent) {

    const [isLastTreeNode, setIsLastTreeNode] = useState(false);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [accountData, setAccountData] = useState<AccountData>({} as AccountData);
    const [isSelectingAllLinked, setIsSelectingAllLinked] = useState(false);
    const [isSelectingAllUnlinked, setIsSelectingAllUnlinked] = useState(false);
    const [selectedLinkedRowKeys, setSelectedLinkedRowKeys] = useState<React.Key[]>([]);
    const [selectedUnlinkedRowKeys, setSelectedUnlinkedRowKeys] = useState<React.Key[]>([]);
    const [classification, setClassification] = useState(0);
    const { userInfo } = useUserContext();

    useEffect(() => {
        if (checkedKeys.length === 0) return;
        if (checkedKeys.length > 1) return;

        setIsLoadingTable(true);
        if (selectedTab === 'ledger_account') {
            const nodeSelected: LevelItem = findNodeTree(treeData, checkedKeys[0]);
            const isLastNode = nodeSelected.classificationId !== null && nodeSelected?.children.length === 0;
            setClassification(nodeSelected.classificationId);
            setIsLastTreeNode(isLastNode);
            if (isLastNode) getAccountTable(nodeSelected.classificationId);
        } else {
            setIsLastTreeNode(true);
            getCostCenterTable();
        }
    }, [checkedKeys, referenceDate]);

    function getAccountTable(classificationId) {
        const formattedYear = referenceDate.year();
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/planning/management-account-parameterization?clientId={client}&localeId={locale}` +
                    `&organizationId={organization}&classificationId=${classificationId}&hierarchyId=${checkedKeys[0]}` +
                    `&year=` + formattedYear,
                useProxy: true,
            },
            onLoadAccountTable.bind(this),
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }

    function getCostCenterTable() {
        const formattedYear = referenceDate.year();
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/planning/management-cost-center-parameterization?clientId={client}&localeId={locale}` +
                    `&organizationId={organization}&managementCostCenterId=${checkedKeys[0]}` +
                    `&year=` + formattedYear,
                useProxy: true,
            },
            onLoadCostCenterTable.bind(this),
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }

    function onLoadAccountTable(data: AccountData) {
        const updateAvailableAccounts = data.availableAccounts.map((item) => ({ ...item, key: item.id, name: item.externalCode + ' - ' + item.name }));
        const updateLinkedAccounts = data.linkedAccounts.map((item) => ({ ...item, key: item.id, name: item.externalCode + ' - ' + item.name }));
        setAccountData({
            availableAccounts: updateAvailableAccounts,
            linkedAccounts: updateLinkedAccounts
        });
        setIsLoadingTable(false);
    }

    function onLoadCostCenterTable(data) {
        const updateAvailableAccounts = data.availableCostCenter.map((item) => ({ ...item, key: item.id, name: item.name }));
        const updateLinkedAccounts = data.linkedCostCenter?.map((item) => ({ ...item, key: item.id, name: item.name }));
        setAccountData({
            availableAccounts: updateAvailableAccounts,
            linkedAccounts: updateLinkedAccounts
        });
        setIsLoadingTable(false);
    }

    function findNodeTree(lines: LevelItem[], key: Key) {
        let obj = null;

        for (let l = lines.length; l--;) {
            const level = lines[l];

            if (level.key === key) {
                obj = level;
                break;
            } else if (level.children.length > 0) {
                obj = findNodeTree(level.children, key);

                if (obj) {
                    break;
                }
            }
        }

        return obj;
    }

    function saveLink(isLinked: boolean) {
        isLinked ? setIsSelectingAllUnlinked(false) : setIsSelectingAllLinked(false);
        setIsLoadingTable(true);

        const dataToSave = selectedLinkedRowKeys.map(id => {
            if (selectedTab === 'ledger_account') {
                return {
                    organizationId: userInfo.selection.organizationId,
                    year: referenceDate.year(),
                    managementAccountPlanHierarchyId: checkedKeys[0],
                    accountAccountId: id
                };
            } else {
                return {
                    organizationId: userInfo.selection.organizationId,
                    year: referenceDate.year(),
                    managementCostCenterId: checkedKeys[0],
                    costCenterId: id
                };
            }
        });

        let url;

        selectedTab === 'ledger_account'
            ? url = "/planning/management-account-parameterization"
            : url = "/planning/management-cost-center-parameterization";

        ServiceCaller.doRequest({
            type: isLinked ? RequestType.DELETE : RequestType.POST,
            url: isLinked
                ? `${url}?ids=${selectedUnlinkedRowKeys.toString()}`
                : url,
            params: dataToSave
        }, () => onSaveLink(isLinked), handleErrorRequest);
    }

    function onSaveLink(isLinked: boolean) {
        Notification({
            type: "success",
            message: isLinked
                ? i18n.t("groupings_texts.unlinked_successfully")
                : i18n.t("groupings_texts.linked_successfully"),
        });
        selectedTab === "ledger_account" ? getAccountTable(classification) : getCostCenterTable();;
    }

    return (
        <Layout>
            <Layout.Sider width={"unset"}>
                <SidebarContent
                    treeData={treeData}
                    costCenterList={costCenterList}
                    checkedKeys={checkedKeys}
                    setCheckedKeys={setCheckedKeys}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                    isFetching={isFetching}
                    selectedTab={selectedTab}
                />
            </Layout.Sider>
            <Layout.Content>
                {isFetching ? (
                    <Loading />
                ) : (
                    isLastTreeNode && checkedKeys.length === 1 ? (
                        <TableContainer
                            selectedTab={selectedTab}
                            isLoadingTable={isLoadingTable}
                            accountData={accountData}
                            isSelectingAllLinked={isSelectingAllLinked}
                            setIsSelectingAllLinked={setIsSelectingAllLinked}
                            isSelectingAllUnlinked={isSelectingAllUnlinked}
                            setIsSelectingAllUnlinked={setIsSelectingAllUnlinked}
                            selectedLinkedRowKeys={selectedLinkedRowKeys}
                            selectedUnlinkedRowKeys={selectedUnlinkedRowKeys}
                            setSelectedLinkedRowKeys={setSelectedLinkedRowKeys}
                            setSelectedUnlinkedRowKeys={setSelectedUnlinkedRowKeys}
                            saveLink={saveLink}
                        />
                    ) : (
                        checkedKeys.length > 1 ? (
                            <div className="tree-level-warning-container">
                                <ImageBox
                                    imgStyles={{ width: 250 }}
                                    imgName="image_bw_01"
                                    message={i18n.t("groupings_texts.select_one_level_at_a_time_to_link_accounts")}
                                />
                            </div>
                        ) : (
                            <div className="tree-level-warning-container">
                                <ImageBox
                                    imgStyles={{ width: 230 }}
                                    imgName="image_bw_02"
                                    message={i18n.t("groupings_texts.select_the_last_tree_level_to_link")}
                                />
                            </div>
                        )
                    )
                )}
            </Layout.Content>
        </Layout >
    )
}