import { Modal } from "antd";
import i18n from "util/base/i18n";

interface IProps{
    handleDelete: (any) => void;
    isModalVisible: boolean;
    setIsModalVisible: (value: boolean) => void;
    getTableData: () => void;
}

export default function ConfirmModal({ handleDelete, isModalVisible, setIsModalVisible, getTableData }:IProps){

    function handleCancel() {
        setIsModalVisible(false);
        getTableData();
    }

    return (
        <Modal
            title={i18n.t("confirm_delete")}
            onOk={() => handleDelete(true)}
            onCancel={handleCancel}
            visible={isModalVisible}
        >
            <p>{i18n.t("additional_field_has_formula")}</p>
        </Modal>
    )
}