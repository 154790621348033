import { Col, DatePicker, Form, Modal, Row, Select } from "antd";
import { IReplicatScenarioModalProps } from "../IBudgetIntegrations";
import i18n from "util/base/i18n";
import FormItem from "antd/es/form/FormItem";
import { Label } from "components/label/Label";
import { useEffect, useState } from "react";
import moment from "moment";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Options } from "util/types/types";
import { selectProps } from "util/props/props";
import { Notification } from "components/notification/Notification";
import { handleErrorRequest } from "util/functions/handleErrorRequest";

export default function ReplicatScenarioModal({ isOpen, setIsOpen }: IReplicatScenarioModalProps) {
    const [date, setDate] = useState<moment.Moment>(moment());
    const [scenarioData, setScenarioData] = useState<Options[]>([]);
    const [scenarioSelected, setScenarioSelected] = useState<number>();
    const [form] = Form.useForm();
    const [isScenarioLoading, setIsScenarioLoading] = useState(true);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    function handleCancel() {
        setIsOpen(false);
    }

    function onLoadScenario(data) {
        const updatedSelect = data.map((scenario) => {
            return {
                value: scenario.id,
                label: scenario.name,
            };
        });
        setScenarioData(updatedSelect);
        setIsScenarioLoading(false);
    }

    async function handleSubmitForm() {
        setIsSaveLoading(true);
        await ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `budget-base/budget-integration/replicate?yearMonth=${date.format(
                "YYYY-MM"
            )}&destinationScenarioId=${scenarioSelected}`,
            params: {},
        }).catch((err) => {
            handleErrorRequest(err.data);
            setIsOpen(false);
            return;
        });

        Notification({
            message: i18n.t("replicated_integrations"),
            type: "info",
        });
        setIsSaveLoading(false);
    }

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/monolith/scenario?locale={locale}&client={client}",
            },
            onLoadScenario
        );
    }, []);

    return (
        <Modal
            open={isOpen}
            title={i18n.t("replicate_data_taken_from_the_scenario")}
            okText={i18n.t("save")}
            okButtonProps={{
                htmlType: "submit",
                form: "replicate-form",
                className: "gs-main-button",
                loading: isSaveLoading,
            }}
            cancelText={i18n.t("close")}
            onCancel={handleCancel}
            width={500}
            className="replicate-scenario-modal"
            destroyOnClose
        >
            <Form form={form} name="replicate-form" onFinish={handleSubmitForm} layout="vertical">
                <Row gutter={20}>
                    <Col span={8}>
                        <FormItem name={"date"}>
                            <Label
                                title={i18n.t("month_name")}
                                children={
                                    <DatePicker
                                        picker="month"
                                        value={date}
                                        onChange={setDate}
                                        className="replicate-scenario-month"
                                        prevIcon={<></>}
                                        allowClear={false}
                                    />
                                }
                            />
                        </FormItem>
                    </Col>
                    <Col span={16}>
                        <FormItem
                            name={"scenario"}
                            label={`${i18n.t("scenario")} ${i18n.t("destination")}`}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            requiredMark={false}
                        >
                            <Select
                                value={scenarioSelected}
                                className="gs-select"
                                options={scenarioData}
                                onChange={setScenarioSelected}
                                loading={isScenarioLoading}
                                maxTagCount="responsive"
                                {...selectProps}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
