export function  convertToNumber(value): number {
    if (typeof(value) === "number") {
        return value;
    }

    const valueWithoutPrefix = value.replace(/R\$\s/g, '');
    const valueWithoutMile = valueWithoutPrefix.replace(/\./g, '');
    const ValueWithDecimal = valueWithoutMile.replace(/,/g, '.');
    const number = parseFloat(ValueWithDecimal.replace(/[^0-9.]/g, ''));

    return number;
};

export function getLocaleByIso(codigoISO) {
    const locale = Intl.NumberFormat.supportedLocalesOf([], { style: 'currency', currency: codigoISO })[0];
    return locale;
};