import { Button, Form, FormInstance, Input, InputRef, Radio, RadioChangeEvent, Table, Tooltip } from "antd";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Column, ColumnTypes, EditableCellProps, EditableRowProps, TableData } from "./IDistributionModal";
import moment from 'moment';
import { validateMonetaryInput } from "util/functions/validateKey";
import { convertValues } from "./functions/convertValues";
import { Icon } from "@iconify/react";
import { BudgetGridData } from "module/budget/pages/fixedExpense/IFixedExpense";
import i18n from "util/base/i18n";
import { useWindowSize } from "hooks/useWindowSize";

interface IHistoricTab {
    tableData: TableData[];
    setTableData: Function;
    calculationMode: 'percent' | 'currency'
    setCalculationMode: Function;
    hasHistoricValues: boolean;
}

export function HistoricTab({
    tableData,
    setTableData,
    calculationMode,
    setCalculationMode,
    hasHistoricValues
}: IHistoricTab) {
    const EditableContext = createContext<FormInstance<any> | null>(null);
    const windowSize = useWindowSize()

    function onRadioChange(e: RadioChangeEvent) {
        const mode = e.target.value
        setCalculationMode(mode)
        const updatedTableData = JSON.parse(JSON.stringify(tableData))
        convertValues(updatedTableData, mode)
        setTableData(updatedTableData)
    }

    function handleClearValues() {
        const updatedTableData = JSON.parse(JSON.stringify(tableData))
        updatedTableData.forEach(item => {
            item.value = 0
        })
        setTableData(updatedTableData)
    }

    function handleReplicateValues(record: TableData, direction: 'up' | 'down') {
        const updatedTableData: TableData[] = JSON.parse(JSON.stringify(tableData))
        updatedTableData.forEach(item => {
            if (direction === 'up' && moment(item.month).isBefore(record.month)) {
                item.value = record.value
            } else if (direction === 'down' && moment(item.month).isAfter(record.month)) {
                item.value = record.value
            }
        })
        setTableData(updatedTableData)
    }

    const columns: Column[] = [
        {
            title: i18n.t("months"),
            dataIndex: 'month',
            key: 'month',
            className: 'month-column',
            render: (text) => {
                const titleMonthCell = moment(text).format('MMM/YYYY').charAt(0).toUpperCase()
                    + moment(text).format('MMM/YYYY').slice(1)
                return titleMonthCell
            }
        },
        {
            title: i18n.t("historic"),
            dataIndex: 'historicValue',
            align: 'center',
            key: 'historicValue',
            render: (value: number) => value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })
        },
        {
            title: calculationMode === 'percent' ? i18n.t("percentage") : i18n.t("additional_value"),
            dataIndex: 'value',
            align: 'center',
            key: 'percent',
            editable: true,
            render: (value: number) => {
                if (calculationMode === 'currency') {
                    return value === 0 ? '-' : value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }
                return (value === 0) ? '-' : `${value.toFixed(2)} %`
            }
        },
        {
            title: i18n.t("result"),
            align: 'center',
            dataIndex: 'result',
            key: 'result',
            render: (_, record: TableData) => {
                if (calculationMode === 'currency') {
                    return record.value === 0 ? record.historicValue.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }) : (record.historicValue + record.value).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                } else {
                    return ((record.historicValue + (record.historicValue * record.value) / 100)).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })
                }
            }
        },
    ]


    const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false} >
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };


    const EditableCell: React.FC<EditableCellProps> = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef<InputRef>(null);
        const form = useContext(EditableContext)!;

        useEffect(() => {
            if (editing) {
                inputRef.current!.focus();
                inputRef.current!.select();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        };

        const save = async () => {
            try {
                const values: { key: 'string' } = await form.validateFields();
                const key = Object.keys(values)[0]
                const value = Object.values(values)[0] ?
                    Number(Object.values(values)[0].toString().replace(',', '.'))
                    : 0

                if (record[key] === value) {
                    toggleEdit();
                    return
                }

                const updatedTableData = JSON.parse(JSON.stringify(tableData))
                updatedTableData.forEach(item => {
                    if (item.month === record.month) item.value = value
                })

                setTableData(updatedTableData)
                toggleEdit();
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let index = null
        if (record) {
            index = tableData.indexOf(record)
        }

        let childNode = children;
        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{ margin: 0, padding: '2px 4px' }}
                    name={dataIndex}
                >
                    <Input
                        onKeyDown={validateMonetaryInput}
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        max={calculationMode === 'currency' ? 100 : 100}
                    />
                </Form.Item>
            ) : (
                <div className="dist-modal-table-input-cell">
                    <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                        {children}
                    </div>
                    <div className="dist-modal-replicate-buttons-container">
                        {index !== 0 &&
                            <Tooltip title={i18n.t("fixed_expense_texts.replicate_up")}>
                                <Icon onClick={() => handleReplicateValues(record, 'up')} icon="mdi:chevron-up-box-outline" />
                            </Tooltip>}
                        {index < (tableData.length - 1) &&
                            <Tooltip title={i18n.t("fixed_expense_texts.replicate_down")}>
                                <Icon onClick={() => handleReplicateValues(record, 'down')} icon="mdi:chevron-down-box-outline" />
                            </Tooltip>}
                    </div>
                </div>
            )
        }

        return <td {...restProps}>{childNode}</td>;
    };


    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const cols = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: BudgetGridData) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                align: 'center',
            }),
        };
    });

    return (
        <div>
            {hasHistoricValues ?
                <>
                    <div className="dist-period-inputs-container">
                        <Radio.Group onChange={onRadioChange} value={calculationMode}>
                            <Radio value={'currency'}>{i18n.t("value")}</Radio>
                            <Radio value={'percent'}>{i18n.t("percentage")}</Radio>
                        </Radio.Group>
                        <Button onClick={handleClearValues} type="default" icon={<Icon icon="majesticons:eraser-line" />}>{i18n.t("clear_values")}</Button>
                    </div>

                    <Table
                        scroll={{ y: windowSize.height - 340 }}
                        className="gs-table"
                        components={components}
                        columns={cols as ColumnTypes}
                        dataSource={tableData}
                        bordered
                        pagination={{ hideOnSinglePage: true, pageSize: 100 }}
                        summary={(data: readonly TableData[]) => createGridSummary(data, calculationMode)}
                    />
                </>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, padding: 30 }}>
                    <Icon style={{ fontSize: 16 }} icon="fluent:calendar-empty-20-regular" />
                    <p style={{ margin: 0 }}>{i18n.t("fixed_expense_texts.there_is_no_history")}</p>
                </div>
            }
        </div>
    )
}

function createGridSummary(data: readonly TableData[], mode) {
    const totals = data.reduce((acc, row) => {
        acc.totalAmount = acc.totalAmount + row.value
        acc.totalHistoryValue = acc.totalHistoryValue + row.historicValue
        acc.totalResult = acc.totalResult + row.value + row.historicValue
        return acc
    }, { totalHistoryValue: 0, totalAmount: 0, totalResult: 0 })

    return (
        <>

            <Table.Summary.Row style={{ background: '#FBE6E6' }}>
                <Table.Summary.Cell index={0} align="left">Total</Table.Summary.Cell>
                <Table.Summary.Cell index={0} align="center">
                    {totals.totalHistoryValue.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center">
                    {mode === 'currency' ?
                        totals.totalAmount.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })
                        :
                        (totals.totalAmount / 100).toLocaleString("pt-BR", {
                            style: "percent",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })
                    }
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                    {totals.totalResult.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    )
}