import { Button, InputNumber, InputRef, RadioChangeEvent } from 'antd';
import { Form, Input, Radio, Table } from "antd";
import { createContext, Key, useContext, useEffect, useRef, useState } from "react";
import { validateMonetaryInput } from 'util/functions/validateKey';
import moment from 'moment';
import { FormInstance } from 'antd/es/form';
import { Column, ColumnTypes, EditableCellProps, EditableRowProps, TableData } from './IDistributionModal';
import { updateRemainingAmount } from './functions/updateRemainingAmount';
import { Notification } from 'components/notification/Notification';
import { convertValues } from './functions/convertValues';
import { Icon } from '@iconify/react';
import { BudgetGridData } from 'module/budget/pages/fixedExpense/IFixedExpense';
import { NumericFormat } from "react-number-format";
import i18n from 'util/base/i18n';
import { cloneDeep } from 'lodash';
import { useWindowSize } from 'hooks/useWindowSize';

interface IPeriodTab {
    tableData: TableData[]
    setTableData: Function
    remainingAmount: number
    setRemainingAmount: Function
    valueAmount: number
    setValueAmount: Function
    calculationMode: 'percent' | 'currency'
    setCalculationMode: Function
    selectedRowKeys: Key[]
    setSelectedRowKeys: Function
    blockedMonths: string[]
}

export function PeriodTab({
    tableData,
    setTableData,
    remainingAmount,
    setRemainingAmount,
    valueAmount,
    setValueAmount,
    calculationMode,
    setCalculationMode,
    selectedRowKeys,
    setSelectedRowKeys,
    blockedMonths
}: IPeriodTab) {
    const EditableContext = createContext<FormInstance<any> | null>(null);
    const inputTotalRef = useRef(null);
    const windowSize = useWindowSize()

    useEffect(() => {
        if (calculationMode === 'currency') {
            const updatedTableData = cloneDeep(tableData)
            const amount = selectedRowKeys.filter(month => !blockedMonths.includes(month as string)).length
            const monthValue = valueAmount < 0 ? Math.ceil(valueAmount / amount) : Math.floor(valueAmount / amount)
            let hasRest = true
            updatedTableData.forEach((item, index) => {
                if (!selectedRowKeys.includes(item.month) || blockedMonths.includes(item.month)) {
                    item.value = 0
                } else {
                    if (hasRest) {
                        item.value = monthValue + (valueAmount % amount)
                        hasRest = false
                    } else {
                        item.value = monthValue
                    }
                }
            })
            setTableData(updatedTableData)
        }
    }, [selectedRowKeys])

    function onRadioChange(e: RadioChangeEvent) {
        const mode = e.target.value
        setCalculationMode(mode)
        const updatedTableData = JSON.parse(JSON.stringify(tableData))
        convertValues(updatedTableData, mode, valueAmount)
        const updatedRemainingAmount = updateRemainingAmount(updatedTableData, valueAmount, mode)
        setTableData(updatedTableData)
        setRemainingAmount(updatedRemainingAmount)
    }

    function onChangeValueAmount(value) {
        setValueAmount(value)
        const updatedRemainingAmount = updateRemainingAmount(tableData, value, calculationMode)
        const updatedTableData: TableData[] = JSON.parse(JSON.stringify(tableData))
        if (calculationMode === 'currency') {

            const amount = updatedTableData.filter(item => !blockedMonths.includes(item.month) && selectedRowKeys.includes(item.key)).length
            const monthValue = value < 0 ? Math.ceil(value / amount) : Math.floor(value / amount)

            let hasRest = true
            updatedTableData.forEach((item, index) => {
                if (!blockedMonths.includes(item.month) && selectedRowKeys.includes(item.key)) {
                    if (hasRest) {
                        item.value = monthValue + (value % amount)
                        hasRest = false
                    } else {
                        item.value = monthValue
                    }
                }
            })
            setTableData(updatedTableData)
        }
        setRemainingAmount(updatedRemainingAmount)
        return Number(value).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });
    }

    function handleClearValues() {
        const updatedTableData = JSON.parse(JSON.stringify(tableData))
        updatedTableData.forEach(item => {
            item.value = 0
        })

        const updatedRemainingAmount = updateRemainingAmount(updatedTableData, valueAmount, calculationMode)
        setRemainingAmount(updatedRemainingAmount)
        setTableData(updatedTableData)
    }

    const columns: Column[] = [
        {
            title: i18n.t("months"),
            dataIndex: 'month',
            key: 'month',
            className: 'month-column',
            render: (text) => {
                const titleMonthCell = moment(text).format('MMM/YYYY').charAt(0).toUpperCase()
                    + moment(text).format('MMM/YYYY').slice(1)
                return titleMonthCell
            }
        },
        {
            title: calculationMode === 'percent' ? i18n.t("percentage") : i18n.t("value"),
            dataIndex: 'value',
            align: 'center',
            key: 'percent',
            editable: true,
            render: (value: number) => {
                if (calculationMode === 'currency') {
                    return value === 0 ? '-' : value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                }
                return value === 0 ? '-' : (value / 100).toLocaleString("pt-BR", {
                    style: "percent",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            }
        },
    ]

    if (calculationMode === 'percent') {
        columns.push({
            title: i18n.t("result"),
            align: 'center',
            dataIndex: 'result',
            key: 'result',
            render: (_, record: TableData) => {
                return record.value === 0 ? '-' : ((valueAmount * record.value) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            }
        })
    }

    const handleFocus = () => {
        if (inputTotalRef.current) {
            inputTotalRef.current.select();
        }
    };

    const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false} >
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell: React.FC<EditableCellProps> = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef<InputRef>(null);
        const form = useContext(EditableContext)!;

        useEffect(() => {
            if (editing) {
                inputRef.current!.focus();
                inputRef.current!.select();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        };

        const save = async () => {
            try {
                const values: { key: 'string' } = await form.validateFields();
                const key = Object.keys(values)[0]
                const value = Object.values(values)[0] ?
                    Number(Object.values(values)[0].toString().replace(',', '.'))
                    : 0

                if (record[key] === value) {
                    toggleEdit();
                    return
                }

                const percentRemaining = 100 - ((valueAmount - remainingAmount) * 100 / valueAmount) + record.value;

                if ((calculationMode === 'currency' && value > (remainingAmount + record.value)) ||
                    (calculationMode === 'percent' && value > percentRemaining)) {
                    Notification({
                        type: 'warning',
                        message: calculationMode === 'currency' ?
                            i18n.t("fixed_expense_texts.amount_cannot_exceed_the_remaining_balance") :
                            i18n.t("fixed_expense_texts.amount_cannot_exceed_the_remaining_percentage")
                    })
                    toggleEdit();
                    return
                }

                const updatedTableData = JSON.parse(JSON.stringify(tableData))

                updatedTableData.forEach(item => {
                    if (item.month === record.month) item.value = value
                })

                const updatedRemainingAmount = updateRemainingAmount(updatedTableData, valueAmount, calculationMode)
                setRemainingAmount(updatedRemainingAmount)
                setTableData(updatedTableData)
                toggleEdit();
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;
        if (editable && selectedRowKeys.includes(record?.key)) {
            childNode = editing ? (
                <Form.Item
                    style={{ margin: 0, padding: '2px 4px' }}
                    name={dataIndex}
                >
                    <Input
                        onKeyDown={validateMonetaryInput}
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        max={calculationMode === 'currency' ? remainingAmount : 100}
                    />
                </Form.Item>
            ) : (
                <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                    {children}
                </div>
            )
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selRowKeys: React.Key[], selectedRows: TableData[]) => {
            setSelectedRowKeys(selRowKeys)
        },
        renderCell: (checked, record: TableData, index, originNode) => {
            if (blockedMonths.includes(record.month)) {
                return null
            }
            return originNode
        }
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const cols = columns.map(col => {
        if (!col?.editable || calculationMode === 'currency') {
            return col;
        }
        return {
            ...col,
            onCell: (record: TableData) => ({
                record,
                editable: blockedMonths.includes(record.month) ? false : col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                align: 'center',
            }),
        };
    });

    return (
        <div className="dist-tab-content">
            <div className="dist-period-inputs-container">
                <InputNumber
                    ref={inputTotalRef}
                    onFocus={handleFocus}
                    onChange={onChangeValueAmount}
                    value={valueAmount}
                    controls={false}
                    width={"100%"}
                    precision={2}
                    decimalSeparator=","
                    prefix="R$"
                />
                <Radio.Group onChange={onRadioChange} value={calculationMode}>
                    <Radio value={'currency'}>{i18n.t("value")}</Radio>
                    <Radio value={'percent'}>{i18n.t("percentage")}</Radio>
                </Radio.Group>
            </div>
            <div className="dist-period-remainingAmount-container">
                {calculationMode === 'percent' ?
                    <p>
                        {`${i18n.t("fixed_expense_texts.remaining_balance")} ${remainingAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('-R$', 'R$ -')}`}
                    </p>
                    : <p></p>}
                <Button onClick={handleClearValues} type="default" icon={<Icon icon="majesticons:eraser-line" />}>{i18n.t("clear_values")}</Button>
            </div>
            <Table
                className="gs-table"
                components={components}
                columns={cols as ColumnTypes}
                scroll={{ y: windowSize.height - 340 }}
                dataSource={tableData}
                rowClassName={(record: TableData) => {
                    if (blockedMonths.includes(record.month)) {
                        return 'blocked-month';
                    }
                }}
                bordered
                rowSelection={calculationMode === 'currency' ? rowSelection : null}
                summary={(data: readonly TableData[]) => createGridSummary(data, calculationMode, valueAmount)}
                pagination={{ hideOnSinglePage: true, pageSize: 100 }}
            />
        </div>
    )
}

function createGridSummary(data: readonly TableData[], calculationMode, valueAmount) {
    const totals = data.reduce((acc, row) => {

        acc.totalResult = acc.totalResult + row.value
        acc.totalAmount = acc.totalAmount + (row.value / 100 * valueAmount)
        return acc
    }, { totalHistoryValue: 0, totalAmount: 0, totalResult: 0 })

    return (
        <Table.Summary fixed>
            <Table.Summary.Row style={{ background: '#FBE6E6' }}>
                {calculationMode === 'currency' && <Table.Summary.Cell index={0} />}
                <Table.Summary.Cell index={calculationMode === 'currency' ? 1 : 0} align="left">{i18n.t("total")}</Table.Summary.Cell>
                {calculationMode === 'currency' ?
                    <Table.Summary.Cell index={2} align="center">
                        {totals.totalResult.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    </Table.Summary.Cell>
                    :
                    <Table.Summary.Cell index={1} align="center">
                        {(totals.totalResult / 100).toLocaleString("pt-BR", {
                            style: "percent",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </Table.Summary.Cell>
                }
                {calculationMode === 'percent' && <Table.Summary.Cell index={3} align="center">
                    {totals.totalAmount.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </Table.Summary.Cell>}
            </Table.Summary.Row>
        </Table.Summary>
    )
}