import { Table } from "antd";
import i18n from "util/base/i18n";
import { CollaboratorsTableProps } from "../ICollaborators";
import { usePermissionContext } from "context/PermissionContext";

export default function CollaboratorsTable({
    isFetching,
    tableData,
    selectedRowKeys,
    onChange,
    tableColumns,
}: CollaboratorsTableProps) {
    const { functionalityPermissions } = usePermissionContext()
    const rowSelection = { selectedRowKeys, onChange, getCheckboxProps: (record) => ({
        disabled: record.key <= 0
      }) };

    return (
        <Table
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            className="gs-table"
            dataSource={tableData}
            columns={tableColumns}
            rowSelection={functionalityPermissions.edit || functionalityPermissions.remove ? rowSelection : null}
            pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 20 }}
            bordered
        />
    );
}