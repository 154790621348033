/* eslint-disable no-restricted-globals */
import { Button, Result, Typography } from "antd";
import { useErrorBoundary } from "react-error-boundary";
import './Error.sass'
import i18n from "util/base/i18n";
import { ContainerComponents } from "module/budget/components/containerComponents/ContainerComponents";
import { ServiceCaller } from "util/service/ServiceCaller";
import { MessageType } from "util/service/IServiceCaller";
const { Paragraph, Text } = Typography;

export interface ErrorFallbackProps {
    error: {
        code?: number;
        title?: string;
        message?: string;
        image?: string;
        stack?: string;
        error?: string
        status?: number
        errorMessage?: string,
        errorCode?: 'AUTH0001',
        hasAction?: boolean;
    }
}

export function ErrorFallback({ error }: ErrorFallbackProps) {
    const { resetBoundary } = useErrorBoundary();
    
    if (error?.errorCode === 'AUTH0001') {
        ServiceCaller.doSendMessage({
            messageType: MessageType.REFRESH_SCREEN,
        });
        return
    }
    return (
        <>
            <ContainerComponents key="containerComponentes" />
            <div
                className="error-boundary-container"
            >
                <Result
                    icon={<img src={`/assets/images/${error.image ? error.image : '500'}.png`} alt="wfn-budget" />}
                    title={error.title ? error.title : error.status}
                    subTitle={error.message}
                    extra={error.hasAction &&
                        <Button
                            className="gs-main-button"
                            onClick={() => {
                                resetBoundary()
                                if (error.code === 403) {
                                    window.history.back()
                                } else {
                                    window.location.reload()
                                }
                            }}
                            style={{ margin: 'auto' }}
                        >
                            {error.code === 403 ? i18n.t('back') : i18n.t('reload')}
                        </Button>}
                >
                    {process.env.NODE_ENV !== 'production' ?
                        <div className="desc">
                            <Paragraph>
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    {(error.message || error.code) && `[DEV] ${i18n.t('request_error_message')}`}
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                {error.message ? error.message : error.code}
                            </Paragraph>

                            <Paragraph>
                                {error.stack?.replaceAll(/(at )/g, "\nat ")}
                            </Paragraph>

                        </div>
                        : null
                    }
                </Result>
            </div>
        </>

    )
}