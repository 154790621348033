import { Icon } from "@iconify/react";
import { Input, Menu, Space } from "antd";
import { useEffect, useState } from "react";
import { Select } from "antd";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import { SideFlexFieldListProps } from "../IFlexFieldValues";
import Search from "antd/lib/input/Search";
import { FlexFieldData } from "../../IFlexField";
// import Search from "antd/lib/transfer/search";

export function SideFlexFieldList({
	flexFieldList,
	selectedFlexField,
	setSelectedFlexField,
	modules
}: SideFlexFieldListProps) {
	const [searchValue, setSearchValue] = useState('');
	const [selectedModules, setSelectedModules] = useState(['EXPENSES', 'REVENUE', 'HUMAN_RESOURCES']);

	useEffect(() => {
		if (flexFieldList.length === 0) return
		setSelectedFlexField(flexFieldList[0].key.toString())
	}, [flexFieldList])

	function modulesContainsSelected({ modules = [], informative }: FlexFieldData) {
		return selectedModules.some(module => (modules?.indexOf(module) >= 0 || informative && module === "DETAIL"));
	}

	function filterFlexField(flexField: FlexFieldData) {
		return flexField.description?.includes(searchValue) && (selectedModules.length === 0 || modulesContainsSelected(flexField));
	}

	const items = flexFieldList
		.filter((item) => filterFlexField(item))
		.map((item) => {
			return {
				key: item.id,
				label: item.description
			}
		})


	function handleFetchValues({ item, key }) {
		setSelectedFlexField(key)
	}

	function handleSearch(e) {
		setSearchValue(e.target.value)
	}

	function handleModulesSearch(e) {
		setSelectedModules(e)
	}

	return (
		<Space
			className="side-flex-field-list-container"
			direction="vertical"
		>
			<div>
				<Select
					id="flex-field-module"
					mode="multiple"
					placeholder={i18n.t<string>('filter_by_module')}
					options={[...modules, { label: i18n.t("detail"), value: "DETAIL" }]}
					value={selectedModules}
					onChange={handleModulesSearch}
					style={{
						width: '100%'
					}}
					{...selectProps}
				></Select>
			</div>
			<Search
				placeholder={i18n.t('search')}
				onChange={handleSearch}
				style={{ width: '100%' }}
			/>
			<Menu
				onClick={handleFetchValues}
				selectedKeys={[selectedFlexField]}
				mode="vertical"
				items={items}
			/>
		</Space >
	)
}