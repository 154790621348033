import { IDimensionSetter, IDirections, ICoachMarkPlacement } from "../ICoachMark";

export function coachMarkPlacementCalculator({ dimension, position, customPosition }: ICoachMarkPlacement): IDirections {
	let CMarkTop: number;
	let CMarkBottom: number;
	let CMarkLeft: number;
	let CMarkRight: number;
	let FSpotTop: number;
	let FSpotBottom: number;
	let FSpotLeft: number;
	let FSpotRight: number;

	let cPosition = {
		top: customPosition?.top || 0,
		bottom: customPosition?.bottom || 0,
		right: customPosition?.right || 0,
		left: customPosition?.left || 0
	}

	switch (position) {
		case 'top-left':
			CMarkBottom = dimension.bottomSpace + dimension.height + 30
			CMarkLeft = dimension.leftSpace + 30
			FSpotBottom = dimension.bottomSpace + dimension.height + 15
			FSpotLeft = dimension.leftSpace + 15
			break;
		case "top-right":
			CMarkBottom = dimension.bottomSpace + dimension.height + 5
			CMarkLeft = dimension.leftSpace + dimension.width 
			FSpotBottom = dimension.bottomSpace + dimension.height - 10
			FSpotLeft = dimension.leftSpace + dimension.width - 15
			break;
		case "bottom-right":
			CMarkTop = dimension.topSpace + dimension.height + 25
			CMarkLeft = dimension.leftSpace + dimension.width 
			FSpotTop = dimension.topSpace + dimension.height + 10
			FSpotLeft = dimension.leftSpace + dimension.width -15
			break;
		case "bottom-left":
			CMarkTop = dimension.topSpace + dimension.height + 30
			CMarkLeft = dimension.leftSpace + 30
			FSpotTop = dimension.topSpace + dimension.height + 15
			FSpotLeft = dimension.leftSpace + 15
			break;
		case "bottom-center":
			CMarkTop = dimension.topSpace + dimension.height + 30
			CMarkLeft = dimension.leftSpace + dimension.width/2 + 15
			FSpotTop = dimension.topSpace + dimension.height + 15
			FSpotLeft = dimension.leftSpace + dimension.width/2
		break;
		case "center":
			CMarkTop = dimension.topSpace + dimension.height/2 + 15
			CMarkLeft = dimension.leftSpace + dimension.width/2 + 15
			FSpotTop = dimension.topSpace + dimension.height/2
			FSpotLeft = dimension.leftSpace + dimension.width/2
		break;
		case "left":
			CMarkTop = dimension.topSpace + 15
			CMarkRight = dimension.rightSpace + dimension.width + 20
			FSpotTop = dimension.topSpace 
			FSpotRight = dimension.rightSpace + dimension.width + 6
		break;
		case "right":
			CMarkTop = dimension.topSpace + 15
			CMarkLeft = dimension.leftSpace + dimension.width + 30
			FSpotTop = dimension.topSpace
			FSpotLeft = dimension.leftSpace + dimension.width + 15
		break;
		default:
			CMarkTop = dimension.topSpace + dimension.height + 30
			CMarkLeft = dimension.leftSpace + dimension.width 
			FSpotTop = dimension.topSpace + dimension.height + 15
			FSpotLeft = dimension.leftSpace + dimension.width -15
			break;
	}

	if(customPosition) {
		CMarkTop += cPosition.top
		CMarkBottom += cPosition.bottom
		CMarkLeft += cPosition.left
		CMarkRight += cPosition.right
		FSpotBottom += cPosition.bottom
		FSpotTop += cPosition.top
		FSpotRight += cPosition.right
		FSpotLeft += cPosition.left
	}

	return {
		CMarkTop,
		CMarkBottom,
		CMarkLeft,
		CMarkRight,
		FSpotTop,
		FSpotBottom,
		FSpotLeft,
		FSpotRight
	}

}

export function dimensionSetter({ element, setDimension }: IDimensionSetter) {
	let rect = element.getBoundingClientRect();
	if (!rect) return;
	setDimension({
		height: rect.height,
		width: rect.width,
		leftSpace: rect.left,
		rightSpace: (window.innerWidth - rect.right),
		topSpace: rect.top,
		bottomSpace: (window.innerHeight - rect.bottom),
	})
}
