import moment from "moment";
import { Moment } from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ModuleType } from "util/types/types";
import { IReversalPeriodPerformedContext, YearlyData } from "../IReversalPeriodPerformed";
import "../ReversalPeriodPerformed.sass";

export const ReversalPeriodPerformedContext = createContext<IReversalPeriodPerformedContext>(
    {} as IReversalPeriodPerformedContext
);

export function ReversalPeriodPerformedProvider({ children }) {
    const [realizedPeriod, setRealizedPeriod] = useState<Moment>(() => {
        return moment().startOf("year");
    });

    const [organizationList, setOrganizationList] = useState<YearlyData[]>([]);

    const [selectedTab, setSelectedTab] = useState(ModuleType.EXPENSERESOURCES);

    useEffect(() => {
        getPeriodsByOrganizations();
    }, []);

    const getPeriodsByOrganizations = () => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/budget-base/control-panel/find-periods-by-organizations?scenario={scenario}&organization={organization}`,
            },
            onLoadRealizedPeriod
        );
    };

    const onLoadRealizedPeriod = (data: YearlyData[]) => {
        setOrganizationList(data);

        const newObj = {};
        Object.keys(data).map((key) => {
            const yearObject = data[key];

            const startDate = yearObject?.expensesAndResourcesOpeningStart
                ? moment(new Date(yearObject.expensesAndResourcesOpeningStart)).format("YYYY-MM-DD")
                : null;
            const endDate = yearObject?.expensesAndResourcesOpeningEnd
                ? moment(new Date(yearObject.expensesAndResourcesOpeningEnd)).format("YYYY-MM-DD")
                : null;

            return (newObj[key] = {
                entryPeriod: [startDate, endDate],
                accomplished: yearObject?.expensesAndResourcesRealizedPeriod
                    ? moment(new Date(yearObject.expensesAndResourcesRealizedPeriod)).format("YYYY-MM")
                    : null,
            });
        });

        setRealizedPeriod(moment().startOf("year"));
    };

    return (
        <ReversalPeriodPerformedContext.Provider
            value={{
                realizedPeriod,
                setRealizedPeriod,
                organizationList,
                setOrganizationList,
                selectedTab,
                setSelectedTab,
                getPeriodsByOrganizations,
            }}
        >
            {children}
        </ReversalPeriodPerformedContext.Provider>
    );
}
export const useReversalPeriodPerformedContext: () => IReversalPeriodPerformedContext = () => {
    return useContext(ReversalPeriodPerformedContext);
};
