import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { BudgetGridData, CurrenciesRate, DetailData, PlannedMonth, RowType } from "../../../IFixedExpense";
import moment from "moment";
import { updateRowTotals } from "./updateRowTotals";
import i18n from "util/base/i18n";

export async function addDetailRow(
    budgetGridData: BudgetGridData,
    detail: DetailData,
    plannedMonthsMock: PlannedMonth[],
    userDefaultCurrency: number,
    currenciesOptions: Options[],
    isByQuantity: boolean,
    currency: number,
    scenarioId: number
) {
    const isTransformingToDetailAccount =
        budgetGridData.detailList && budgetGridData.detailList.length > 0 ? false : true;
    const monthsArray = Object.keys(budgetGridData.plannedMonths);
    const startDate = moment(monthsArray[0], "YYYY-MM");
    const endDate = moment(monthsArray[monthsArray.length - 1], "YYYY-MM");
    let currencyIsoCode = currenciesOptions.find((cur) => cur.value === currency).label;

    const budgetOpening = {
        budgetOpening: budgetGridData.budgetOpening,
        standardAccount: budgetGridData.standardAccount,
        spendingPackage: budgetGridData.spendingPackage,
        budgetOpeningIndexerLink: [],
        canEdit: budgetGridData.canEdit,
        accounting: detail.description,
        currencyId: currency,
        detailId: detail.id,
        budgetOpeningId: budgetGridData.budgetOpening.id,
        currencyIsoCode,
        plannedTotal: 0,
        realizedTotal: 0,
        historicTotal: 0,
        apportionmentTotal: 0,
        projectedTotal: 0,
    };
    let rate = [];

    if (currency !== userDefaultCurrency) {
        await ServiceCaller.doAsyncRequest<CurrenciesRate[]>({
            type: RequestType.GET,
            url: `/budget-base/currency-conversion-tax/currency-exchange-rate?origin=${userDefaultCurrency}&destination=${currency}&scenario={scenario}&startDate=${startDate.format(
                "YYYY-MM"
            )}&endDate=${endDate.format("YYYY-MM")}`,
        }).then((res) => {
            if (res.data.length > 0) {
                rate = res.data;
            } else {
                while (startDate.isSameOrBefore(endDate)) {
                    rate.push({ month: startDate.format("YYYY-MM"), rate: 1 });
                    startDate.add(1, "month");
                }
            }
        });
    } else {
        while (startDate.isSameOrBefore(endDate)) {
            rate.push({ month: startDate.format("YYYY-MM"), rate: 1 });
            startDate.add(1, "month");
        }
    }

    const plannedMonths = plannedMonthsMock.map((plannedMonth, i) => {
        let unitValue = isByQuantity ? 0 : null;
        let detailValueId = null;
        let plannedValueId = isTransformingToDetailAccount ? budgetGridData.plannedMonths[plannedMonth.month].id : null;
        let plannedValue =
            isTransformingToDetailAccount && !isByQuantity
                ? budgetGridData.plannedMonths[plannedMonth.month].plannedValue
                : 0;
        let quantity = isByQuantity ? 0 : null;

        return {
            id: plannedValueId,
            month: plannedMonth.month,
            plannedValue,
            detailId: detail.id,
            currencyId: currency,
            detailValueId: detailValueId,
            unitValue: unitValue,
            quantity,
            rate: rate.find((monthRate) => monthRate.month === plannedMonth.month).rate,
            scenarioId,
            detailName: detail.description,
            realizedValue: null,
            projectedValue: null,
            historicValue: null,
            apportionmentValue: null,
        };
    });

    const newDetail = {
        ...budgetOpening,
        key: `${budgetGridData.budgetOpening.id}_${detail.id}`,
        accounting: detail.description,
        plannedMonths: plannedMonths.reduce(
            (acc, plannedMonth) => ({
                ...acc,
                [plannedMonth.month]: plannedMonth,
            }),
            {}
        ),
        rowType: RowType.DETAIL,
    } as BudgetGridData;
    updateRowTotals(newDetail);
    if (isByQuantity) {
        Object.assign(newDetail, {
            children: [
                {
                    ...budgetOpening,
                    key: `${budgetGridData.budgetOpening.id}_${detail.id}_quantity`,
                    plannedMonths: plannedMonths.reduce(
                        (acc, plannedMonth) => ({
                            ...acc,
                            [plannedMonth.month]: plannedMonth,
                        }),
                        {}
                    ),
                    rowType: RowType.UNITVALUE,
                    quantityTotal: 0,
                    unitValueTotal: 0,
                    accounting: i18n.t("fixed_expense_texts.unitary_value"),
                },
                {
                    ...budgetOpening,
                    key: `${budgetGridData.budgetOpening.id}_${detail.id}_unitValue`,
                    plannedMonths: plannedMonths.reduce(
                        (acc, plannedMonth) => ({
                            ...acc,
                            [plannedMonth.month]: plannedMonth,
                        }),
                        {}
                    ),
                    rowType: RowType.QUANTITY,
                    quantityTotal: 0,
                    unitValueTotal: 0,
                    accounting: i18n.t("amount"),
                },
            ],
        });
    }
    if (isTransformingToDetailAccount && isByQuantity) {
        budgetGridData.plannedTotal = 0;
    }

    budgetGridData.detailIdList.push(detail.id);

    if (!budgetGridData.detailList) {
        Object.assign(budgetGridData, {
            detailList: [newDetail],
        });
    } else {
        budgetGridData.detailList.push(newDetail);
    }

    return plannedMonths;
}
