import i18n from "util/base/i18n"
import { Icon } from '@iconify/react';
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Button, Form, FormInstance, Input, InputRef, message, Modal, Table } from "antd"
import moment from 'moment';

import { validateMonetaryInput } from "util/functions/validateKey";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Notification } from "components/notification/Notification";
import { ImportModal } from "components/importExportMenu/import/ImportModal";

import { AverageCostModalProps, Column, ColumnTypes, EditableCellProps, EditableRowProps } from "../../IScenarioProjection"
import './AverageCostModalStyles.sass'
import { MultipleSearch } from "components/input/search/MultipleSearch";

export function AverageCostModal({
  isModalVisible,
  handleCancel,
  selectedRowKeys,
}: AverageCostModalProps) {

  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [filterdTableData, setFilterdTableData] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [isOpenImportModal, setIsOpenImportModal] = useState(false)

  useEffect(() => {
    setFilterdTableData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (selectedRowKeys != null && isModalVisible) {
      ServiceCaller.doRequest({
        type: RequestType.GET,
        url: `/projectionlease/averageCost/list?scenarioId=${selectedRowKeys}`,
        useProxy: false,
      }, onLoadAverageCost.bind(this));
    }
  }, [isModalVisible, selectedRowKeys]);

  function onLoadAverageCost(data) {
    const updatedData = data.map((row, index) => ({
      key: index,
      rate: row.rate + '%',
      referencePeriod: moment(row.referencePeriod).format('MM/YYYY')
    }));
    setTableData(updatedData);
  }

  const tableColumns: Column[] = [
    {
      title: i18n.t<string>('reference_month_and_year'),
      dataIndex: "referencePeriod",
      key: "referencePeriod",
      align: "center",
      width: 300,
    },
    {
      title: i18n.t<string>('risk_rate'),
      dataIndex: "rate",
      key: "rate",
      align: "left",
      width: 700,
      editable: true,
    }
  ];

  const EditableContext = createContext<FormInstance<any> | null>(null);

  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false} >
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
      if (editing) {
        inputRef.current!.focus();
        inputRef.current!.select();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      setIsSubmitDisabled(false)

      try {
        const values: { key: 'string' } = await form.validateFields();
        const key = Object.keys(values)[0]
        const value = Object.values(values)[0] ?
          Number(Object.values(values)[0].toString().replace(',', '.'))
          : 0

        if (key === 'rate' && (value <= 0 || value >= 1000)) {
          message.error(i18n.t<string>('risk_rate_validation'));
          return;
        }

        if (record[key] === value) {
          toggleEdit();
          return
        }

        const updatedData = tableData.map((tableRecord) => {
          if (tableRecord.key === record.key) {
            return { ...tableRecord, [key]: value + '%'};
          } else {
            return tableRecord;
          }
        });

        setTableData(updatedData);

        toggleEdit();
      } catch (errInfo) {
      }
    };

    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0, padding: '2px 4px' }}
          name={dataIndex}
        >
          <Input
            onKeyDown={validateMonetaryInput}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <div className="dist-modal-table-input-cell">
          <div className="editable-cell-value-wrap" onClick={toggleEdit}>
            {children}
          </div>
        </div>
      )
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const cols = tableColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        align: 'center',
      }),
    };
  });

  function onUpdateAverageCost() {
    Notification({ type: 'success', message: i18n.t<string>('successfully_saved') });
    setIsLoading(false)
    handleCancel()
  }

  function handleSubmit() {
    setIsLoading(true)

    const formattedData = {
      scenarioId: selectedRowKeys[0],
      averageCosts: tableData.map((item) => ({
        referencePeriod: moment(item.referencePeriod, 'MM/YYYY').valueOf(),
        rate: parseInt(item.rate, 10),
      })),
    };

    ServiceCaller.doRequest({
      type: RequestType.POST,
      useProxy: false,
      url: "/projectionlease/averageCost/update",
      params: formattedData,
    }, onUpdateAverageCost);
  }

  return (
    <Modal
      width={1000}
      title={i18n.t<string>('projection_lease.average_cost_table')}
      visible={isModalVisible}
      onCancel={() => {
        handleCancel()
      }}
      onOk={handleSubmit}
      okButtonProps={{ disabled: isSubmitDisabled }}
      className="gs-modal average-cost-modal"
      cancelText={i18n.t<string>("cancel")}
      okText={i18n.t<string>("save")}
      maskClosable={false}
      destroyOnClose
      confirmLoading={isLoading}
    >
      <div className="averagecost-buttons-wrapper">
        <Button
          className="import-button"
          shape="round"
          icon={<Icon width={20} height={20} icon={'mdi:file-import'} />}
          onClick={() => setIsOpenImportModal(true)}
        >
          {i18n.t<string>('import_spreadsheet')}
        </Button>

        <div>
          <MultipleSearch
            tableData={tableData}
            setTableData={setFilterdTableData}
            options={[
              { i18nString: 'reference_month_and_year', description: 'referencePeriod' },
              { i18nString: 'risk_rate', description: 'rate' },
            ]}
          />
        </div>
      </div>
      <Table
        loading={{
          spinning: false,
          tip: i18n.t<string>("loading")
        }}
        className="gs-table"
        dataSource={filterdTableData}
        components={components}
        columns={cols as ColumnTypes}
        pagination={{ hideOnSinglePage: true }}
        bordered
      />

      <ImportModal
        type='excel'
        uploadType={"1"}
        useProxy={false}
        isOpen={isOpenImportModal}
        setImportModalOpen={setIsOpenImportModal}
        handleCancel={() => setIsOpenImportModal(false)}
        importUrl="/projectionlease/averageCost/import"
        templateUrl="/projectionlease/averageCost/downloadTemplate"
      />
    </Modal>
  )
}