import {
	useEffect,
	useState
} from "react";

import { Button, Popover, Tooltip } from "antd";
import { Chart, DatePicker } from "components";
import { ChartType, IChartConfig } from "components/chart/IChart";

import { ChartData, IChartWidgetProps, IWidgetResponse } from "./IChartWidget";
import moment from "moment";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import { LoadingOutlined } from "@ant-design/icons";
import GroupBy from "./GroupBy";

export default function FundingPosition(props: IChartWidgetProps) {

	const [chartData, setChartData]: [ChartData[], Function] = useState([]);

	const [visiblePopover, setVisiblePopover]: [boolean, Function] = useState(false);

	const [date, setDate]: [moment.Moment, Function] = useState(moment(props.endDate));

	const [filter, setFilter]: [string, Function] = useState(props.groupBy);

	const [shouldUpdateFilter, setShouldUpdateFilter]: [boolean, Function] = useState(false);

	const [loading, setLoading]: [boolean, Function] = useState(false);	

	const [useCache, setUseCache]: [boolean, Function] = useState(true);

	const [cached, setCached]: [boolean, Function] = useState(false);
	const [cacheDate, setCacheDate]: [Date, Function] = useState(null);

	const chartConfig: IChartConfig = {
		paletteColors: "#b3cee1, #7eb5dc, #4f9acf, #44759a, #104b77",
		showLabels: false,
		showValues: true,
		drawCustomLegendIcon: true,
		legendIconSides: "1"
	};

	const handleVisibleChange = (visible: boolean) => {
		if (!loading) {
			setVisiblePopover(visible);
		}
	}

	function onLoadData(response: IWidgetResponse) {
		setCached(response.cached);
		setCacheDate(response.date);
		setChartData(response.data.map(({ label, value }) => {
			label = label || i18n.t<string>("uninformed");

			return {
				label,
				value,
				tooltext: `
					<div class="chart-tooltip-parent">
						<b>$percentValue</b> 
						<br /> 
						<div>${label}</div>
					</div>
				`
			}
		}).sort(({ value: valueA }, { value: valueB }) => valueA - valueB));

		setLoading(false);
		setUseCache(false);
	}

	const openPopover = () => {
		setVisiblePopover(true);
	};

	const onApplyGroupBy = (groupBy: string): void => {
		if (filter === groupBy) {
			setVisiblePopover(false);
			loadChart();
		} else {
			setFilter(groupBy);
			setShouldUpdateFilter(true);
		}
	};

	const doFilter: Function = () => {
		ServiceCaller.doRequest({
			url: "/dashboard-analytics/widget/filter",
			type: RequestType.PUT,
			params: {
				id: props.filterId,
				groupBy: filter,
				startDate: null,
				endDate: date.format("YYYY-MM-DD")
			}
		}, loadChart());

		setVisiblePopover(false);
	};

	const onChangeDate = (newDate: moment.Moment) => {
		if (date.isSame(newDate)) {
			loadChart();
		} else {
			setShouldUpdateFilter(true);
			setDate(newDate);
		}
	};

	const loadChart: Function = () => {
		setLoading(true);

		const filterValuesQuery = props.globalFilter.values.map(value => `filterValue=${value}`).join("&");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/financing/funding/position?scenario={scenario}&client={client}&user={user}&locale={locale}&currency=${props.currencyId}&groupBy=${filter}&date=${date.format("YYYY-MM-DD")}&${filterValuesQuery}&filterBy=${props.globalFilter.filterBy}&useCache=${useCache}`,
		}, onLoadData.bind(this))
	};

	useEffect(() => {
		if (shouldUpdateFilter) {
			doFilter();
		}
	}, [date, filter]);

	useEffect(loadChart.bind(this), [props.currencyId, props.globalFilter]);

	return <>
		<div className="funding-position-widget">
			<div className="widget-header-actions-container">
				{
					cached && <>
						<Tooltip title={ i18n.t<string>("dashboard.analytics.text_002", {date: new Intl.DateTimeFormat(i18n.language.replace("_", "-"), { dateStyle: "short", timeStyle: "medium" }).format(new Date(cacheDate))}) }>
							<Button onClick={loadChart.bind(this)} type="text" size="large" icon={<Icon icon={"mdi:reload-alert"} />} disabled={loading} data-html2canvas-ignore="true" />
						</Tooltip>
					</>
				}

				<Popover onVisibleChange={handleVisibleChange} placement={"bottomLeft"} visible={visiblePopover} overlayClassName="filter-popover" title={i18n.t<string>("filterBy")} content={<GroupBy value={filter} onApply={onApplyGroupBy} />} trigger="click" destroyTooltipOnHide={true}>
					<Button onClick={openPopover} type="text" size="large" icon={<Icon icon={"clarity:filter-solid"} />} disabled={loading} data-html2canvas-ignore="true" />
				</Popover>

				<DatePicker value={date} onChange={onChangeDate.bind(this)} format="DD/MM/YYYY" picker="date" disabled={loading} />
			</div>

			{
				loading ? <>
					<div className="loading-container">
						<LoadingOutlined />
					</div>
				</> : <Chart id="widget-2" type={ChartType.PIE_2D} data={chartData} config={chartConfig} />
			}
		</div>
	</>;

}