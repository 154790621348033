import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import { ManagementCostCenterTableData } from "./IManagementCostCenter";
import { Form } from "antd";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Notification } from "components/notification/Notification";
import { sortList } from "util/functions/sortList";
import { handleExportGridData } from "util/functions/handleExportGridData";
import ManagementCostCenterModal from "./components/ManagementCostCenterModal";
import ManagementCostCenterTable from "./components/ManagementCostCenterTable";

export default function ManagementCostCenter() {
    const [tableData, setTableData] = useState<ManagementCostCenterTableData[]>([]);
    const [filterdTableData, setFilterdTableData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ManagementCostCenterTableData[]>([]);
    const [isNewCostCenter, setIsNewCostCenter] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [modalList, setModalList] = useState<ManagementCostCenterTableData[]>([]);
    const [form] = Form.useForm();

    const importProps: IImportModal[] = [
        {
            importUrl: "/planning/management-cost-center/import",
            templateUrl: "/planning/management-cost-center/template?locale={locale}",
            type: 'excel'
        }
    ]

    const tableColumns: ColumnsType = [
        {
            title: i18n.t<string>("external_code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "center",
            width: 300,
        },
        {
            title: i18n.t<string>("description"),
            dataIndex: "description",
            key: "description",
            align: "left",
            className: 'account-registration-columns'
        },
    ];

    useEffect(() => {
        fetchCostCenter();
    }, []);

    useEffect(() => {
        setFilterdTableData(tableData);
    }, [tableData]);

    function fetchCostCenter() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/management-cost-center`
        }, onLoadTableData.bind(this));
    }

    function onLoadTableData(data) {
        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };
        const updateTableData = data.map(res => {
            return {
                key: res.id,
                id: res.id,
                externalCode: res.externalCode,
                description: res.description,
            };
        }).sort((a, b) => sortList(a.externalCode, b.externalCode));

        setTableData(updateTableData);
        setIsFetching(false);
    };

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: ManagementCostCenterTableData[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    function handleOpenModal(isNew: boolean) {
        if (isNew) {
            setIsNewCostCenter(true);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            form.resetFields();
        } else {
            form.setFieldsValue({
                externalCode: selectedRows[0].externalCode,
                description: selectedRows[0].description,
            });
            setIsNewCostCenter(false);
        }
        setModalList([]);
        setIsModalVisible(true);
    };

    function handleIncludeCostCenter(data: ManagementCostCenterTableData) {
        if (isNewCostCenter) {
            setModalList([...modalList, data]);
        } else {
            handleSaveCostCenter([data]);
        }
        form.resetFields();
    }

    function handleSaveCostCenter(data) {
        const dataToSave = data.map(
            cc => {
                return {
                    id: isNewCostCenter ? null : selectedRowKeys[0],
                    externalCode: isNewCostCenter ? cc.externalCode : selectedRows[0].externalCode,
                    description: cc.description,
                }
            });

        setSelectedRowKeys([]);
        setSelectedRows([]);

        setIsModalVisible(false);
        setIsFetching(true);

        ServiceCaller.doRequest({
            type: isNewCostCenter ? RequestType.POST : RequestType.PUT,
            url: '/planning/management-cost-center',
            params: isNewCostCenter ? dataToSave : dataToSave[0],
        }, onSaveCostCenter.bind(this));
    }

    function onSaveCostCenter(response) {
        if (response) {
            Notification({
                type: "success",
                message: isNewCostCenter ? i18n.t<string>("successfully_saved") : i18n.t<string>("successfully_edited"),
            });
        }
        form.resetFields();
        fetchCostCenter();
    };

    function handleDeleteCostCenter() {
        setIsFetching(true);
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/planning/management-cost-center?ids=${selectedRowKeys.toString()}`,
        }, onDeleteCostCenter.bind(this), onRequestError.bind(this));
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function handleCloseModal() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setModalList([]);
        form.resetFields();
        setIsModalVisible(false);
    };

    function onDeleteCostCenter(response) {
        if (response) {
            Notification({
                type: "success",
                message: i18n.t<string>("successfully_deleted"),
            });
        }
        fetchCostCenter();
    };

    function onRequestError() {
        Notification({
            type: "warning",
            message: i18n.t<string>("unable_to_delete_with_linked_data"),
        });
        setIsFetching(false);
    }

    return (
        <>
            <div className="page-title-content" id="cost-category-container">
                <h1>{i18n.t("management_cost_center_registration")}</h1>
            </div>
            <div id="top-buttons-account-registration">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t("new_cost_center")}
                        handleNew={() => handleOpenModal(true)}
                        handleEdit={() => handleOpenModal(false)}
                        handleDelete={handleDeleteCostCenter}
                        isEditable={selectedRows.length === 1}
                        isDeletable={selectedRows.length > 0}
                        multipleSearch={{
                            tableData: tableData,
                            setTableData: setFilterdTableData,
                            options: [
                                { i18nString: 'code', description: 'externalCode' },
                                { i18nString: 'description', description: 'description' },
                            ]
                        }}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(tableData, tableColumns, i18n.t("management_cost_center")),
                            importProps: importProps
                        }}
                    />
                </div>
            </div>
            <main id="account-registration-main">
                <ManagementCostCenterTable
                    isFetching={isFetching}
                    tableData={filterdTableData}
                    selectedRowKeys={selectedRowKeys}
                    onChange={onSelectRowChange}
                    columns={tableColumns}
                />
                <ManagementCostCenterModal
                    isModalVisible={isModalVisible}
                    isNewCostCenter={isNewCostCenter}
                    handleSubmit={handleIncludeCostCenter}
                    handleCancel={handleCloseModal}
                    form={form}
                    modalList={modalList}
                    setModalList={setModalList}
                    handleSaveCostCenter={handleSaveCostCenter}
                    tableData={tableData}
                    selectedRowKeys={selectedRowKeys}
                />
            </main>
        </>
    )
}