import { Table } from "antd";
import { usePermissionContext } from "context/PermissionContext";
import i18n from "util/base/i18n";

export default function InvestmentList({
    isFetching,
    tableData,
    tableColumns,
    setDetailId,
    setInvestmentSelected
}) {
    return (
        <div className="hired-table-container">
            <Table
                loading={{
                    spinning: isFetching,
                    tip: `${i18n.t<string>("loading")}...`
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setDetailId(Number(record.key));
                            setInvestmentSelected(record);
                        }
                    };
                }}
                className="hired-table"
                dataSource={tableData}
                rowClassName={() => 'hired-table-row'}
                columns={tableColumns}
                pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 100 }}
            />
        </div>
    )
}