import { Card, Skeleton } from "antd";
import '../Skeleton.sass'

interface ICardSkeleton {
    amount: number;
    cardClassName?: string;
    containerClassName?: string;
    active: boolean;
    direction: 'row' | 'column';
    gap?: number;
    size?: "small" | "large" | "default";
    shape?: "circle" | "square"
}

export function FilterSkeleton({amount, cardClassName = '', containerClassName = '', active, direction, gap = 0, size = 'small', shape = 'circle'}: ICardSkeleton) {
    let cards = []
    for (let index = 0; index < amount; index++) {
        cards.push(
            <Card key={index} className={`card-skeleton-filter ${cardClassName}`}>
                <Skeleton.Input active={active} size={size} />
                <Skeleton.Avatar active={active} size={size} shape={shape} />
            </Card>
        )
    }
    return (
        <div 
            style={{
                display: 'flex',
                flexDirection: direction,
                gap
            }}
            className={`cards-skeleton-container ${containerClassName}`}
        >
            {cards}
        </div>
    )
}