import { useState } from "react";
import i18n from "util/base/i18n";
import useCachedQuery from "hooks/useCachedQuery";

import { OrganizationList } from "./components/OrganizationList";
import { OrganizationPanel } from "./components/OrganizationPanel/OrganizationPanel";
import { ImageBox } from "components/imageBox/ImageBox";
import { OrganizationBudgetDates, SegmentTypeResponse, UnitConversionResponse } from "./IControlPanel";
import { RequestType } from "util/service/IServiceCaller";
import { ModuleType, Options } from "util/types/types";
import './ControlPanel.sass';

export function ControlPanel() {
    const [selectedTab, setSelectedTab] = useState(ModuleType.EXPENSERESOURCES);
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [isRevisionModalOpen, setIsRevisionModalOpen] = useState(false);

    const { data: organizationList, isLoading } = useCachedQuery<OrganizationBudgetDates[]>(
        ['organizationList'],
        {
            type: RequestType.GET,
            url: `budget-base/control-panel?client={client}&user={user}&locale={locale}&scenario={scenario}`
        },
    );
    const { data: segmentTypeOptions } = useCachedQuery<Options[]>(
        ['segment-type-options'],
        {
            type: RequestType.GET,
            url: 'budget-base/control-panel/find-all-segments'
        },
        {},
        (data: SegmentTypeResponse[]) => data.map(item => {
            return {
                label: item.caption,
                value: item.id
            }
        })
    );
    const { data: unitConversionOptions } = useCachedQuery<Options[]>(
        ['unit-conversion-options'],
        {
            type: RequestType.GET,
            url: 'budget-base/unit-conversion'
        },
        {},
        (data: UnitConversionResponse[]) => data.map(item => {
            return {
                label: item.description,
                value: item.id
            }
        })
    );

    const organization = organizationList?.find(item => item.organizationId === Number(selectedOrganization));

    return (
        <>
            <header className="page-title-content" style={{height: 72}}>
                <h1>{i18n.t("control_panel")}</h1>
            </header >
            <main className="control-panel-content">
                <OrganizationList
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    isLoadingList={isLoading}
                    organizationList={organizationList}
                    selectedOrganization={selectedOrganization}
                    setSelectedOrganization={setSelectedOrganization}
                />
                {organization ?
                    <OrganizationPanel
                        organization={organization}
                        moduleType={selectedTab}
                        isRevisionModalOpen={isRevisionModalOpen}
                        setIsRevisionModalOpen={setIsRevisionModalOpen}
                        organizationList={organizationList}
                        segmentTypeOptions={segmentTypeOptions}
                        unitConversionOptions={unitConversionOptions}
                    />
                    :
                    <ImageBox
                        styles={{ flex: 1, marginTop: 100 }}
                        imgName="image_bw_01"
                        title={i18n.t('select_a_company_to_get_started')}
                        message={i18n.t('to_manage_budgets_select_a_company')}
                    />
                }
            </main>
        </>
    )

}