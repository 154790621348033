import { handleExportGridData } from "util/functions/handleExportGridData";
import { createTableTotalsRow } from "./createTableTotalsRow";
import { BudgetGridData, RowType, ViewModeSelected } from "../../../IFixedExpense";
import { cloneDeep } from "lodash";

export function exportGrid(
    columnsData,
    data: BudgetGridData[],
    viewModeSelected: ViewModeSelected[],
    selectedRows: BudgetGridData[]
) {
    let tableData = [];
    let isByRowChecked = false;
    if (selectedRows.length > 0 && selectedRows.every((row) => row.rowType === RowType.PLANNED)) {
        tableData = cloneDeep(selectedRows);
        isByRowChecked = true;
    } else {
        tableData = cloneDeep(data);
    }
    const columns = cloneDeep(columnsData);

    const formattedColumns = columns.map((column) => {
        if (column.dataIndex.includes("accounting")) {
            delete column.render;
        }
        if (column.dataIndex.includes("operation")) {
            column.dataIndex = "exportComment";
            delete column.render;
        }
        return column;
    });

    const formattedTableData = tableData.reduce((acc, row) => {
        if (row.comment) {
            Object.assign(row, { exportComment: row.comment.comment });
        }
        acc.push(row);
        if (row.detailList && row.detailList.length > 0) {
            row.detailList.forEach((detail: any) => {
                detail.accounting = "-        " + detail.accounting;
                acc.push(detail);
                if (detail.children && detail.children.length > 0) {
                    detail.children.forEach((child) => {
                        child.accounting = "-                 " + child.accounting;
                        acc.push(child);
                    });
                }
            });
        }
        return acc;
    }, []);

    const totalTableData = createTableTotalsRow(isByRowChecked ? selectedRows : tableData, viewModeSelected);

    const formattedTotalRow = {
        accounting: "Total",
        plannedMonths: {},
    };
    const prefix = {
        1: "plannedValue",
        2: "projectedValue",
        3: "realizedValue",
        4: "apportionmentValue",
        5: "historicValue",
        6: "plannedTotal",
        7: "projectedTotal",
        8: "realizedTotal",
        9: "apportionmentTotal",
        10: "historicTotal",
    };
    totalTableData.forEach((total) => {
        if (total.month.startsWith("9")) {
            Object.assign(formattedTotalRow, { [prefix[total.order]]: total.value });
        } else {
            Object.assign(formattedTotalRow.plannedMonths, {
                [total.month]: {
                    ...formattedTotalRow.plannedMonths[total.month],
                    [prefix[total.order]]: total.value,
                },
            });
        }
    });

    formattedTableData.push(formattedTotalRow);

    handleExportGridData(formattedTableData, formattedColumns, "orcamento");
}
