import { Divider, Layout } from "antd";
import "./AccountingBalances.sass";
import Content from "./components/Content";
import { Title } from "./components/Title";
import { AccountingBalancesProvider } from "./context/AccountingBalancesContext";

export default function AccountingBalances() {
    return (
        <AccountingBalancesProvider>
            <Layout.Header>
                <Title />
            </Layout.Header>

            <Divider className="title_divider" />

            <Layout.Content>
                <Content />
            </Layout.Content>
        </AccountingBalancesProvider>
    );
}
