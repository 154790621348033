import { TableData } from "../IDistributionModal";

export function convertValues(tableData: TableData[], type: 'percent' | 'currency', valueAmount?: number) {
    tableData.forEach(item => {
        let newValue = 0
        if (valueAmount !== undefined) {
            if (type === 'percent') {
                if (item.value === 0) return
                newValue = Number((item.value / valueAmount * 100).toFixed(8))
            } else {
                newValue = Number((valueAmount * item.value / 100).toFixed(8))
            }
        } else {
            if (type === 'percent') {
                if (item.historicValue === 0) {
                    newValue = 0
                } else {
                    newValue = Number((item.value / item.historicValue * 100).toFixed(8))
                }
            } else {
                newValue = Number((item.value * item.historicValue / 100).toFixed(8))
            }
        }
        item.value = newValue
    })

    return tableData
}