import { DataLevels, LevelItem } from "../IGroupings";

export function getTreeMaxLevelAmount(dataLevels: DataLevels[] | LevelItem[]): number {
    let maxLevels = 0;

    function countLevels(node: DataLevels, level: number) {
        level++;

        if (node.children.length === 0) {
            maxLevels = Math.max(maxLevels, level);
        } else {
            node.children.forEach(child => {
                countLevels(child, level);
            });
        }
    }

    dataLevels.forEach(root => {
        countLevels(root, 0);
    });

    return maxLevels;
}