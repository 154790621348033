import { Col, Form, Modal, Row, Select } from "antd";
import { IReplicateConsolidationRequest, IReplicateFormFields, IReplicateSaveResponse, IReplicateValuesModalProps } from "../../IConsolidation";
import { useContext, useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { useUserContext } from "context/UserContext";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import i18n from "util/base/i18n";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/es/form/interface";
import moment from "moment";
import { Notification } from "components/notification/Notification";
import { NotifType } from "components/notification/INotification";
import { ConsolidationEliminationContext } from "../context/ConsolidationEliminationContext";

export default function ReplicateValuesModal({
	isOpen,
	setIsOpen,
	consolidationId,
	onSave
}: IReplicateValuesModalProps) {
	const { userInfo: { selection: { organizationId, scenarioId } } } = useUserContext();
	const [selectYears, setSelectYears] = useState<GenericSelection[]>([]);
	const [form] = Form.useForm<IReplicateFormFields>();
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const { organizationIds } = useContext(ConsolidationEliminationContext);

	useEffect(() => {
		if (!(scenarioId && organizationId)) return;
		ServiceCaller.doRequest({
			url: `/budget-base/control-panel/find-all-years-by-scenario?scenario={scenario}&user={user}&client={client}`,
			type: RequestType.GET,
		}, handleLoadYears, handleErrorRequest);
	}, [organizationId, scenarioId]);

	const handleLoadYears = (data: number[]) => {
		const newYearsSelect: GenericSelection[] = data.map((year: number) => ({
			label: year.toString(),
			value: year.toString()
		}))
		setSelectYears(newYearsSelect);
	}

	const handleCancel = () => {
		setIsOpen(false);
		form.resetFields();
		setIsSaving(false);
	}

	const onSaveReplicate = ({ message, type }: IReplicateSaveResponse) => {
		Notification({
			message: i18n.t(`consolidation.${message}`),
			type: type
		})

		if (type === "success") onSave();
	}

	const handleFinishForm = (data: IReplicateFormFields) => {
		setIsSaving(true);
		const params: IReplicateConsolidationRequest = {
			...data,
			consolidationId,
			scenarioId,
			organizationIds
		}

		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: "/planning/consolidation/replicate-rate",
			params
		}, onSaveReplicate, handleErrorRequest)

		handleCancel();
	}

	const validateOriginYear = async (_: RuleObject, value: StoreValue): Promise<any> => {
		const destinyYear: string = form.getFieldValue("destinyYear");
		if (!value) return Promise.reject(i18n.t<string>("required_field"));
		if (!destinyYear) return Promise.resolve();

		const destinyYearFormatted: moment.Moment = moment(destinyYear);
		const originYearFormatted: moment.Moment = moment(value);

		if (destinyYearFormatted.isSameOrBefore(originYearFormatted)) {
			return Promise.reject(new Error(i18n.t("consolidation.origin_year_greater")));
		}

		Promise.resolve();
	}

	const validateDestinyYear = async (_: RuleObject, value: StoreValue) => {
		const originYear: string = form.getFieldValue("originYear");
		if (!value) return Promise.reject(i18n.t<string>("required_field"));
		if (!originYear) return Promise.resolve();

		const originYearFormatted: moment.Moment = moment(originYear);
		const destinyYearFormatted: moment.Moment = moment(value);

		if (destinyYearFormatted.isSameOrBefore(originYearFormatted)) {
			return Promise.reject(new Error(i18n.t("consolidation.origin_year_greater")))
		}

		Promise.resolve();
	}

	return (
		<Modal
			open={isOpen}
			okButtonProps={{
				htmlType: "submit",
				form: "replicate-values-form",
				loading: isSaving
			}}
			onCancel={handleCancel}
			className="gs-modal"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("save")}
			centered
			title={i18n.t("consolidation.replicate_values_last_year")}
		>
			<Form
				name="replicate-values-form"
				onFinish={handleFinishForm}
				layout="vertical"
				form={form}
			>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item
							label={i18n.t("year_of_origin")}
							name={"originYear"}
							rules={[{ validator: validateOriginYear }]}
							dependencies={["destinyYear"]}
						>
							<Select
								placeholder={i18n.t<string>("select")}
								options={selectYears}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={i18n.t("year_of_destiny")}
							name={"destinyYear"}
							rules={[{ validator: validateDestinyYear }]}
							dependencies={["originYear"]}
						>
							<Select
								placeholder={i18n.t<string>("select")}
								options={selectYears}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	)
}