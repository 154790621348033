import { Space, Typography } from "antd";
import i18n from "util/base/i18n"; 

export function Title() {
    return (
        <div className="title-container">
            <Space align="center">
                <Typography.Title level={3} className="gs-font-color-primary">
                    {i18n.t("accounting_ledger")}
                </Typography.Title>
            </Space>
        </div>
    );
}
