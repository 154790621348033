import moment from 'moment';
import i18n from 'util/base/i18n';
import { Button, Form, Input, InputRef, Table, notification } from 'antd';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { FormInstance } from 'antd/es/form';
import { NotificationPlacement } from 'antd/lib/notification';

import { handleErrorRequest } from 'util/functions/handleErrorRequest';
import { RequestType } from 'util/service/IServiceCaller';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { ErrorRequest } from 'util/types/types';
import { AttributeParameterizationContext } from '../context/AttributeParameterizationContext';
import { FormattedNumber } from 'react-intl';
import { IAttribute } from '../IAttributeParameterization';

const EditableContext = createContext<FormInstance<any> | null>(null);

interface IRow {
    attributeRuleId: number;
    description: string;
    externalCode: string;
    key?: number;
    moduleType: string;
    order: number;
    periodValues: {
        id?: number;
        period: string;
        value: number;
    }[];
}

interface EditableRowProps {
    index: number;
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof IRow;
    record: IRow;
    handleSave: (
        month: string,
        value: number,
        attributeRuleId: number,
        currentLine: any,
        selectedAttribute: IAttribute
    ) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function EditableRow({ index, ...props }: EditableRowProps) {
    const [form] = Form.useForm();

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
}

function EditableCell({ title, editable, children, dataIndex, record, handleSave, ...restProps }: EditableCellProps) {
    const { isYearPeriod, period, lastAccomplishedPeriod, reloadTable, selectedAttribute } = useContext(AttributeParameterizationContext);
    const form = useContext(EditableContext)!;

    const [showDuplicateButton, setShowDuplicateButton] = useState(false);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);

    function toggleEdit() {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    }

    function replicatedTaxesNotification(placement: NotificationPlacement) {
        notification.success({
            message: i18n.t<string>("revenue.taxes_replicated_successfully"),
            placement,
            duration: 2,
        });
    }

    function handleReplicateTax() {
        let cellPeriodInRecord = moment(dataIndex, 'MMM YYYY').format('YYYY-MM');

        if (isYearPeriod) {
            cellPeriodInRecord = moment(dataIndex, 'MMM YYYY').format('YYYY');
        }

        let finalDate = moment(period[0].clone(), 'MMM YYYY').format('YYYY-MM');

        const recordCellIndex = record.periodValues.findIndex(recordPeriod => {
            return recordPeriod.period === cellPeriodInRecord;
        });

        const recordCellData = record.periodValues[recordCellIndex];

        const replicatePeriods = {
            attributeRuleId: record.attributeRuleId,
            value: recordCellData.value,
            startPeriod: moment(dataIndex, 'MMM YYYY').format('YYYY-MM'),
            endPeriod: finalDate,
            moduleType: record.moduleType
        };

        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: "/revenue/grouper/attribute/rule/tax/replicate-values",
            params: replicatePeriods,
        }, (response: any) => {
            replicatedTaxesNotification('topRight');
            reloadTable();
        }, (error: ErrorRequest) => {
            handleErrorRequest(error);
        });
    }

    async function save() {
        const values = await form.validateFields();
        try {
            const editedCellValue = values[dataIndex].toString().replace(',', '.');
            toggleEdit();
            handleSave(
                dataIndex,
                editedCellValue,
                record.attributeRuleId,
                record,
                selectedAttribute
            );
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    }

    let childNode = children;
    let rowValue;
    let isAccomplished = false;

    if (record && record.periodValues.length > 0) {
        if (isYearPeriod) {
            const momentDate = moment(dataIndex, 'YYYY');
            const formattedDateToString = momentDate.format('YYYY');

            if (record.periodValues.find((row) => row.period === formattedDateToString)) {
                rowValue = record.periodValues.find((row) => row.period === formattedDateToString)?.value;
            }
        } else {
            const momentDate = moment(dataIndex, 'MMM YYYY');
            const formattedDateToString = momentDate.format('YYYY-MM');
            const periodValue = record.periodValues.find((row) => row.period === formattedDateToString);

            const lastAccomplishedPeriodMoment = moment(lastAccomplishedPeriod)

            if (periodValue) {
                const periodValueMoment = moment(periodValue.period);

                if (periodValueMoment.isSameOrBefore(lastAccomplishedPeriodMoment)) {
                    isAccomplished = true;
                }

                rowValue = record.periodValues.find((row) => row.period === formattedDateToString)?.value;
            }
        }
    }

    let renderedValue;

    if (selectedAttribute?.grouperName === 'MARKETING_COST_FIXED') {
        renderedValue = (
            <FormattedNumber
                value={rowValue}
                style={`currency`}
                currency={"BRL"}
                currencyDisplay="symbol"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
            />
        );
    } else {
        renderedValue = rowValue === 0 ? 0 : `${rowValue}%`
    }

    if (editable) {
        if (isAccomplished) {
            childNode = (
                <div className="editable-cell-value-wrap">
                    <span
                        style={{
                            cursor: 'text',
                        }}
                    >
                        {renderedValue}
                    </span>
                </div>
            )
        } else if (editing) {
            childNode = (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    initialValue={rowValue === 0 ? 0 : `${rowValue}`}
                >
                    <Input
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                    />
                </Form.Item>
            );
        } else {
            childNode = (
                <div
                    className="editable-cell-value-wrap"
                    onMouseOver={() => setShowDuplicateButton(true)}
                    onMouseOut={() => setShowDuplicateButton(false)}
                >
                    <span
                        style={{
                            cursor: 'text',
                        }}
                        onClick={toggleEdit}
                    >
                        {renderedValue}
                    </span>
                    <Button
                        className={showDuplicateButton ? 'tax-btn-copy' : 'tax-btn-copy-disabled'}
                        icon={(
                            <Icon
                                icon="solar:copy-bold"
                                onClick={handleReplicateTax}
                            />
                        )}
                    />
                </div>
            );
        }
    }

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);


    const updatedProps = {
        ...restProps,
        style: {
            ...restProps,
            backgroundColor: isAccomplished ? "rgba(46, 182, 104, 0.12)" : "#fff",
        }
    }

    return (
        <td
            {...updatedProps}
        >
            {childNode}
        </td>
    )
}

export function RulesTable() {
    const {
        handleSelectedRows,
        tableData,
        defaultColumns,
        isYearPeriod,
        reloadTable,
        selectedAttribute,
        isFetchingData,
        searchTextTable,
        selectedRows,
        setHasSavedValue,
        setIsLoading
    } = useContext(AttributeParameterizationContext);

    const treatedData = tableData
        .filter((rule) => rule.description.toLowerCase().includes(searchTextTable.toLowerCase()))
        .map((rule) => ({
            ...rule,
            key: rule.attributeRuleId,
        }))
        .sort((a, b) => (a.externalCode > b.externalCode ? 1 : -1));

    let columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    function handleSave(month, newValue, attributeRuleId, currentLine, selectedAttribute) {
        if (!newValue) return;

        const momentDate = isYearPeriod ? moment(month, 'MMM YYYY').set('month', 0) : moment(month, 'MMM YYYY');
        const formattedDate = isYearPeriod ? momentDate.format('YYYY') : momentDate.format('YYYY-MM');
        const monthToBeSended = isYearPeriod ? `${formattedDate}-01` : formattedDate;

        const previousValue = currentLine.periodValues.find(tax => tax.period === formattedDate)?.value;

        if (Number(newValue) === previousValue) {
            return;
        }

        if(Number(newValue) > 100 && !selectedAttribute?.grouperName?.includes('MARKETING_COST_FIXED')) {
            newValue = 100;
        }

        const existingTaxInCell = isYearPeriod ?
            currentLine.periodValues.find((tax) => tax.period === formattedDate) :
            currentLine.periodValues.find((tax) => tax.period === formattedDate).hasOwnProperty('id');

        if (existingTaxInCell) {
            setIsLoading(true);
            setHasSavedValue(false);
            const tax = currentLine.periodValues.find((tax) => tax.period === formattedDate)

            ServiceCaller.doRequest(
                {
                    url: `/revenue/grouper/attribute/rule/tax`,
                    type: RequestType.PUT,
                    params: {
                        id: tax.id,
                        attributeRuleId,
                        month: monthToBeSended,
                        value: Number(newValue),
                    },
                },
                (data) => {
                    setIsLoading(false);
                    setHasSavedValue(true);
                    reloadTable();
                },
                (err: ErrorRequest) => {
                    handleErrorRequest(err);
                }
            );
        } else {
            ServiceCaller.doRequest(
                {
                    url: `/revenue/grouper/attribute/rule/tax`,
                    type: RequestType.PUT,
                    params: {
                        attributeRuleId,
                        month: monthToBeSended,
                        value: Number(newValue),
                    },
                },
                (data) => {
                    setIsLoading(false);
                    setHasSavedValue(true);
                    reloadTable();
                },
                (err: ErrorRequest) => {
                    handleErrorRequest(err);
                }
            );
        }
    }

    const rowSelection = {
        onChange: (_, selectedRows) => {
            handleSelectedRows(selectedRows);
        },
        selectedRowKeys: selectedRows.map(selectedRow => selectedRow.key)
    };

    const showLoading = (isFetchingData && selectedAttribute) ? true : false;

    return (
        <Table
            className="gs-table"
            columns={columns as ColumnTypes}
            dataSource={treatedData}
            scroll={{ x: true }}
            components={{
                body: {
                    row: EditableRow,
                    cell: EditableCell
                }
            }}
            rowClassName={() => 'editable-row'}
            rowSelection={rowSelection}
            bordered
            loading={showLoading}
            pagination={{ hideOnSinglePage: true }}
        />
    );
}
