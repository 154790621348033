import { Icon } from "@iconify/react";
import { Button } from "antd";
import './OpenFloatMenuButton.sass'
import { useUserContext } from "context/UserContext";
import { useEffect, useState } from "react";

export function OpenFloatMenuButton() {
    const { setIsFloatMenuOpen } = useUserContext()
    const [verticalPosition, setVerticalPosition] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [prevMousePosition, setPrevMousePosition] = useState({ y: 0 });

    useEffect(() => {
        let animationFrameId;

        const handleMouseMove = (event) => {
            if (isDragging) {
                const deltaY = event.clientY - prevMousePosition.y;

                cancelAnimationFrame(animationFrameId);
                animationFrameId = requestAnimationFrame(() => {
                    setVerticalPosition((prevPosition) => (prevPosition + deltaY));
                    setPrevMousePosition({ y: event.clientY });
                });
            }
        };

        const handleMouseUp = () => {
            setIsDragging(false);
            cancelAnimationFrame(animationFrameId);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            cancelAnimationFrame(animationFrameId);
        };
    }, [isDragging, prevMousePosition]);

    const handleMouseDown = (event) => {
        event.preventDefault();
        setIsDragging(true);
        setPrevMousePosition({ y: event.clientY });
    };

    return (
        <Button
            onClick={() => setIsFloatMenuOpen(true)}
            className="open-float-menu-button"
            icon={<Icon icon="fluent:re-order-dots-vertical-16-filled" />}
            style={{ transform: `translateY(${verticalPosition}px)`, }}
            onMouseDown={handleMouseDown}
        />
    );
}