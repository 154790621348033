import { Icon } from "@iconify/react";
import moment from "moment";
import i18n from "util/base/i18n";
import { formatNumber } from "util/formatNumber";
import { Column } from "module/budget/pages/detailLayout/IDetailLayout";
import { DetailTableData, ViewColumns, ViewModeSelected } from "../Detail/IDetail";

export function createTableColumns(
    detailTableData: DetailTableData[],
    openModal: Function,
    viewModeSelected: ViewModeSelected,
    viewColumns
) {
    let valuesColumn: Column[] = [];

    Object.keys(detailTableData[0]).forEach(month => {
        if (month !== 'key' && month !== 'description') {
            const momentMonth = moment(month, 'YYYY-MM');
            let columnClassName = '';

            const titleMonthCell = momentMonth.format('MMM YYYY').charAt(0).toUpperCase()
                + momentMonth.format('MMM YYYY').slice(1);

            const children: Column[] = [
                {
                    title: "Valor Orçado",
                    align: 'center',
                    className: columnClassName,
                    width: 90,
                    editable: false,
                    dataIndex: [month, "totalValue"],
                    render: (value) => {
                        return formatNumber(value ? value : 0, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                    }
                },
                {
                    title: "Valor Corrigido",
                    align: 'center',
                    className: columnClassName,
                    width: 90,
                    editable: false,
                    dataIndex: [month, "convertedTotalValue"],
                    render: (value) => {
                        return formatNumber(value ? value : 0, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                    }
                }
            ]

            if (viewColumns.length > 0) {
                viewColumns.forEach(viewMode => {
                    const title = viewMode === ViewColumns.QUANTITY ? "Quantidade"
                        : viewMode === ViewColumns.UNITY_VALUE ? "Valor Unitário"
                            : viewMode === ViewColumns.CONVERTED_ACCOMPLISHED_VALUE ? "Valor Realizado"
                                : viewMode === ViewColumns.DEPRECIATED_VALUE ? "Valor de Depreciação"
                                    : viewMode === ViewColumns.ACTIVATION_DATE ? "Data de Ativação"
                                        : "Valor da Taxa";
                    const indexProp = viewMode === ViewColumns.QUANTITY ? "quantity"
                        : viewMode === ViewColumns.UNITY_VALUE ? "unityValue"
                            : viewMode === ViewColumns.CONVERTED_ACCOMPLISHED_VALUE ? "convertedAccomplishedValue"
                                : viewMode === ViewColumns.DEPRECIATED_VALUE ? "depreciatedValue"
                                    : viewMode === ViewColumns.ACTIVATION_DATE ? "activationDate"
                                        : "exchangeRate";
                    children.push({
                        title,
                        align: 'center',
                        className: columnClassName,
                        width: 90,
                        editable: viewMode === ViewColumns.QUANTITY || viewMode === ViewColumns.UNITY_VALUE || viewMode === ViewColumns.ACTIVATION_DATE ? true : false,
                        dataIndex: [month, indexProp],
                        render: (value) => {
                            if (indexProp === ViewColumns.ACTIVATION_DATE) {
                                return value ? moment(value, 'YYYY-MM-DD').format('MM/YYYY') : "-";
                            } else {
                                return formatNumber(value ? value : 0, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                            }
                        }
                    })
                })
            }

            const columnOrder = ['quantity', 'unityValue', 'totalValue', 'activationDate', 'convertedAccomplishedValue', 'exchangeRate', 'convertedTotalValue', 'depreciatedValue'];

            function orderColumnsByDataIndex() {
                const orderedColumns = [];
                columnOrder.forEach(dataIndex => {
                    const matchingColumn = children.find(column => column.dataIndex[1] === dataIndex);
                    if (matchingColumn) {
                        orderedColumns.push(matchingColumn);
                    }
                });
                return orderedColumns;
            };

            const newChildrenColumn = orderColumnsByDataIndex();

            valuesColumn.push({
                title: titleMonthCell, // Jan 2023
                align: 'center',
                className: columnClassName,
                editable: true,
                children: [...newChildrenColumn]
            })
        }
    });

    const columnData: Column[] = [
        {
            fixed: 'left',
            width: 255,
            title: viewModeSelected === ViewModeSelected.TEMPLATE ? "Template" : "Contabilização",
            dataIndex: 'description',
            align: 'left',
            className: 'table-first-column',
            filterMode: 'tree',
            filterSearch: true,
        }
    ];

    columnData.push(...valuesColumn);

    columnData.push({
        title: i18n.t("total"),
        dataIndex: 'plannedTotal',
        align: 'center',
        fixed: 'right',
        width: 100,
        render: (text, record: DetailTableData) => {
            const total = Object.keys(record).reduce((acc, value) => {
                //@ts-ignore
                if (value !== "key" && value !== "description" && record[value].totalValue) {
                    //@ts-ignore
                    acc += record[value].totalValue;
                }
                return acc;
            }, 0);
            return formatNumber(total, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
        }
    },
        {
            title: i18n.t("comment"),
            dataIndex: 'operation',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (_, record: DetailTableData) => {
                //@ts-ignore
                const hasComment = Object.entries(record).some(([key, value]) => key !== 'key' && key !== 'description' && value.justification !== "" && value.justification !== undefined && value.justification !== null);
                return (
                    <Icon
                        style={{ color: hasComment ? '#4F9ACF' : '#ADADAD' }}
                        onClick={() => openModal(record)}
                        icon="fa6-solid:comments"
                    />
                );
            },
        })

    return columnData;
}