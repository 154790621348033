import i18n, { languages } from "util/base/i18n";
import { Col, DatePicker, Form, InputNumber, Modal, Row, Select } from "antd";
import { ContractSimulationModalProps } from "../../IContractSimulation";

import './AdditiveModalStyles.sass'
import CurrencyInput from "module/leaseProjection/components/currencyInput/CurrencyInput";
import { selectProps } from "util/props/props";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { useEffect, useState } from "react";

export function AdditiveModal({
  isModalVisible,
  handleCancel,
  handleSubmit,
  form,
  isLoading
}: ContractSimulationModalProps) {
  const [indexers, setIndexers] = useState([]);

  useEffect(() => {
    if (isModalVisible) {
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: "/projectionlease/scenario/indexer"
      }, onLoadIndexers.bind(this));
    }
  }, [isModalVisible]);

  function onLoadIndexers(indexers) {
    setIndexers(indexers)
  }

  return (
    <Modal
      width={774}
      title={i18n.t<string>('additive')}
      visible={isModalVisible}
      okButtonProps={{ htmlType: "submit", form: "additive-form" }}
      onCancel={handleCancel}
      className="gs-modal additive-modal"
      cancelText={i18n.t<string>("cancel")}
      okText={i18n.t<string>("save")}
      maskClosable={false}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        name="additive-form"
        className={"form-additive"}
        layout="vertical"
        onFinish={(data) => { handleSubmit(data) }}
      >
        <Row justify={"start"} gutter={16}>
          <Col span={8}>
            <Form.Item
              name="baseDate"
              label={i18n.t<string>('start_date')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    const baseDate = new Date(value).setHours(0, 0, 0, 0);
                    const endDate = new Date(form.getFieldValue("endDate")).setHours(0, 0, 0, 0);

                    if (baseDate && endDate && baseDate >= endDate)
                      return Promise.reject(i18n.t<string>('end_date_greater_than_start_date'))

                    return Promise.resolve()
                  },
                },
              ]}
            >
              <DatePicker locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="endDate"
              label={i18n.t<string>('end_date')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    const baseDate = new Date(form.getFieldValue("baseDate")).setHours(0, 0, 0, 0);
                    const endDate = new Date(value).setHours(0, 0, 0, 0);

                    if (baseDate && endDate && baseDate >= endDate)
                      return Promise.reject(i18n.t<string>('end_date_greater_than_start_date'))

                    return Promise.resolve()
                  },
                },
              ]}
            >
              <DatePicker locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="installments"
              label={i18n.t<string>('installments')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    if (form.getFieldValue("installments") > 0)
                      return Promise.resolve()
                    else
                      return Promise.reject(i18n.t<string>('installments_greater_zero'))
                  }
                }
              ]}
            >
              <CurrencyInput
                form={form}
                name='installments'
                className="ant-input-number"
                value={form.getFieldValue('installments')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"start"} gutter={16}>
          <Col span={8}>
            <Form.Item
              name="rate"
              label={i18n.t<string>('discount_rate')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    if (!(form.getFieldValue("rate") < 1000))
                      return Promise.reject(i18n.t<string>('rates_less_one_thousand'))
                    else if (form.getFieldValue("rate") <= 0)
                      return Promise.reject(i18n.t<string>('rates_greater_than_zero'))
                    else
                      return Promise.resolve()
                  }
                }
              ]}
            >
              <InputNumber
                controls={false}
                decimalSeparator={","}
                width={"100%"}
                precision={8}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                name="indexer"
                label={i18n.t<string>("indexer")}
                dependencies={["firstCapitalization"]}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value > 0 && form.getFieldValue("firstCapitalization") != null)
                        return Promise.resolve()
                      else if (value === undefined && form.getFieldValue("firstCapitalization") != null)
                        return Promise.reject(i18n.t<string>('indexer_not_selected'))
                      else
                        return Promise.resolve()
                    }
                  }
                ]}
              >
                <Select
                  maxTagCount="responsive"
                  allowClear
                  options={indexers}
                  placeholder={i18n.t<string>("select")}
                  {...selectProps}
                />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="firstCapitalization"
              label={i18n.t<string>('first_capitalization_restatement')}
              dependencies={["indexer"]}
              rules={[
                {
                  validator: (_, value) => {
                    if (value != null && (value >= form.getFieldValue("endDate") || value <= form.getFieldValue("baseDate")))
                      return Promise.reject(i18n.t<string>('first_capitalization_restatement_errorperiod'))
                    else if (value == null && form.getFieldValue("indexer") != null)
                      return Promise.reject(i18n.t<string>('first_capitalization_restatement_not_filled'))
                    else
                      return Promise.resolve()
                  }
                }
              ]}
            >
              <DatePicker className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={"start"} gutter={16}>
          <Col span={8}>
            <Form.Item
              name="operationalCostValue"
              label={i18n.t<string>('operation_cost_value')}
            >
              <CurrencyInput
                form={form}
                name='operationalCostValue'
                className="ant-input-number"
                value={form.getFieldValue('operationalCostValue')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="doublePercentual"
              label={i18n.t<string>('additional_december')}
              rules={[
                {
                  validator: (_, value) => {
                    if (value != null && value >= 1000)
                      return Promise.reject(i18n.t<string>('additional_december_less_one_thousand'))
                    else if (value != null && value <= 0)
                      return Promise.reject(i18n.t<string>('additional_december_greater_than_zero'))
                    else
                      return Promise.resolve()
                  }
                }
              ]}
            >
              <InputNumber
                controls={false}
                decimalSeparator={","}
                width={"100%"}
                precision={2}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}