import { Icon } from "@iconify/react";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import i18n from 'util/base/i18n';

import "./contractCounter.sass";
import { useUserContext } from "context/UserContext";

export default function ContractCounter() {
  const [isLoading, setIsLoading] = useState(false);
  const [amountOfContracts, setAmountOfContracts] = useState(0)

  const { userInfo } = useUserContext()

  useEffect(() => {
    if (userInfo.selection.scenarioId)
      countContractsToCalculate(userInfo.selection.scenarioId)
  }, [userInfo.selection.scenarioId])

  const handleClick = () => {
    setIsLoading(true);
    countContractsToCalculate(userInfo.selection.scenarioId)
  };

  function countContractsToCalculate(scenarioId) {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: `/projectionlease/contract/countContractsToCalculate?scenarioId=${scenarioId}`
    }, onGetContractsToCalculate.bind(this));
  }

  function onGetContractsToCalculate(response) {
    setAmountOfContracts(response)
    setTimeout(() => {
      setIsLoading(false);
    }, 1250)
  }

  return (
    <div className="contract-counter">
      <div className="counter-label">{i18n.t<string>('contracts_to_calculate')}</div>
      <span className="counter">{amountOfContracts}</span>
      <Button
        className="refresh-button"
        shape="circle"
        onClick={handleClick}
      >
        <div className={`refresh-icon ${isLoading ? "rotate-icon" : ""}`}>
          <Icon icon={"fa6-solid:arrow-rotate-right"} />
        </div>
      </Button>
    </div>
  );
}
