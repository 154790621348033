import { Menu, Spin, Tabs, Input } from "antd";
import { useState } from "react";
import i18n from "util/base/i18n";
import { OrganizationBudgetDates } from "../IControlPanel";
const { Search } = Input

interface IOrganizationList {
    selectedTab: string
    isLoadingList: boolean
    selectedOrganization: string
    organizationList: OrganizationBudgetDates[]
    setSelectedOrganization: any
    setSelectedTab: any
}

export function OrganizationList({ selectedTab, isLoadingList, selectedOrganization, organizationList, setSelectedOrganization, setSelectedTab }: IOrganizationList) {
    const [searchValue, setSearchValue] = useState('')
    
    const items = organizationList
        ?.filter((item) => item.organizationName?.toLowerCase().includes(searchValue.toLowerCase()))
        .map((item) => {
            return {
                key: item.organizationId,
                label: item.organizationName
            }
        }) ?? []

    return (
        <Tabs activeKey={selectedTab} type="card" className="gs-tab organization-list-tab" onChange={(tab) => setSelectedTab(tab)}>
            <Tabs.TabPane tab={`${i18n.t("flexField.modules.expenses")} ${i18n.t("and")} ${i18n.t("flexField.modules.humanResources")}`} key="expensesResources">
                <Spin spinning={isLoadingList}>
                    <Search
                        allowClear
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={i18n.t("search")}
                        className="permissions-list-input"
                        style={{ padding: "0 16px 16px" }}
                    />
                    <Menu
                        onClick={({ item, key }) => setSelectedOrganization(key)}
                        selectedKeys={[selectedOrganization]}
                        mode="vertical"
                        className="organization-list-content"
                        items={items}
					/>
                </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane tab={i18n.t("flexField.modules.revenue")} key="revenue">
                <Spin spinning={isLoadingList}>
                    <Search
                        allowClear
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={i18n.t("search")}
                        className="permissions-list-input"
                        style={{ padding: "0 16px 16px" }}
					/>
                    <Menu
                        onClick={({ item, key }) => setSelectedOrganization(key)}
                        selectedKeys={[selectedOrganization]}
                        className="organization-list-content"
                        mode="vertical"
                        items={items}
                    />
                </Spin>
            </Tabs.TabPane>
            <Tabs.TabPane tab={i18n.t("planning")} key="planning">
                <Spin spinning={isLoadingList}>
                    <Search
                        allowClear
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder={i18n.t("search")}
                        className="permissions-list-input"
                        style={{ padding: "0 16px 16px" }}
                        />
                    <Menu
                        onClick={({ item, key }) => setSelectedOrganization(key)}
                        selectedKeys={[selectedOrganization]}
                        className="organization-list-content"
                        mode="vertical"
                        items={items}
                    />
                </Spin>
            </Tabs.TabPane>
        </Tabs>
    )
}