import { useCMComponent } from "components/coachMark/hooks/useCMComponent";
import { ICoachProps } from "components/coachMark/ICoachMark";
import { useState } from "react";
import i18n from "util/base/i18n";

export function useCoachMark() {

	const [currentStep, setCurrentStep] = useState(1);

	const [openCoachMark, setOpenCoachMark] = useState(false)

	const [openedSettings, setOpenedSettings] = useState(false);

	const updateVisibleSettings = () => {
		setOpenedSettings(state => !state);
	}

	const coachMarkStepList: Array<ICoachProps> = [
		{
			step: 1,
			activate: currentStep === 1,
			component:
				useCMComponent(
					currentStep,
					5,
					i18n.t<string>("dashboard.analytics.coach_mark.step1_title"),
					i18n.t<string>("dashboard.analytics.coach_mark.step1_content"),
					setCurrentStep,
					setOpenCoachMark
				),
			componentPosition: "left",
			useBackground: { apply: false },
			customPosition: { right: 6, top: 35 }
		},
		{
			step: 2,
			activate: currentStep === 2,
			component:
				useCMComponent(
					currentStep,
					5,
					i18n.t<string>("dashboard.analytics.coach_mark.step2_title"),
					i18n.t<string>("dashboard.analytics.coach_mark.step2_content"),
					setCurrentStep,
					setOpenCoachMark
				),
			componentPosition: "left",
			useBackground: { apply: false, color: "#fff" },
			customPosition: { top: 235, right: 350 },
			customization: updateVisibleSettings
		},
		{
			step: 3,
			activate: currentStep === 3,
			component:
				useCMComponent(
					currentStep,
					5,
					i18n.t<string>("dashboard.analytics.coach_mark.step4_title"),
					i18n.t<string>("dashboard.analytics.coach_mark.step4_content"),
					setCurrentStep,
					setOpenCoachMark
				),
			componentPosition: "right",
			useBackground: { apply: false },
		},
		{
			step: 4,
			activate: currentStep === 4,
			component:
				useCMComponent(
					currentStep,
					5,
					i18n.t<string>("dashboard.analytics.coach_mark.step3_title"),
					i18n.t<string>("dashboard.analytics.coach_mark.step3_content"),
					setCurrentStep,
					setOpenCoachMark
				),
			componentPosition: "left",
			useBackground: { apply: false, color: "#fff" },
			customPosition: { right: 6 },
		},
		{
			step: 5,
			activate: currentStep === 5,
			component:
				useCMComponent(
					currentStep,
					5,
					i18n.t<string>("dashboard.analytics.coach_mark.step5_title"),
					i18n.t<string>("dashboard.analytics.coach_mark.step5_content"),
					setCurrentStep,
					setOpenCoachMark,
				),
			componentPosition: "bottom-left",
			useBackground: { apply: false }
		}
	]

	const step = coachMarkStepList[currentStep - 1]

	return {
		openCoachMark,
		step,
		coachMarkStepList,
		setCurrentStep,
		setOpenCoachMark,
		openedSettings,
		setOpenedSettings
	}
}