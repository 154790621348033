import { Key } from "react";
import { BudgetPeriodDates } from "../../fixedExpense/IFixedExpense";
import moment from "moment";

export interface RevenueItemTable {
    key: string;
    keyCreationCoin?: string;
    fieldValueId?: number;
    name: string;
    value?: number;
    children?: RevenueItemTable[];
    currencyId?: number;
    flexFieldValueId?: number;
    flexFieldValueIds?: number[];
    measuringUnitId?: number;
    currencyName?: string;
    dates?: RevenueItemDateTable[];
    measuringUnitName?: string;
    monthDate?: string;
    total?: number;
    isoCode?: string;
    fieldId?: number[];
    hasCheckbox: boolean;
    id?: number;
    ids?: number[];
    formatType?: string;
    isLastRowBeforeCoin?: boolean;
    symbol?: string;
    type?: string;
    idTemplate?: number;
    parentId?: number;
}

export interface RevenueGridRequest {
    templateField?: TemplateField;
    measuringUnit?: MeasuringUnit;
    flexFieldValue?: FlexFieldValueRequest;
    currency?: CurrencyElement;
    childrens: RevenueGridRequest[];
    key: string;
    fieldValueId?: number;
    name: string;
    value?: number;
    children?: RevenueItemTable[];
    currencyId?: number;
    flexFieldValueId?: number;
    measuringUnitId?: number;
    currencyName?: string;
    dates?: RevenueItemDateTable[];
    measuringUnitName?: string;
    monthDate?: string;
    values?: CaptionValue;
    total?: number;
    isoCode?: string;
    fieldId?: number[];
    hasCheckbox: boolean;
    id?: number;
    ids?: number[];
    formatType?: string;
    isLastRowBeforeCoin?: boolean;
    flexFieldValueIds: number[];
}

export interface TemplateField {
    id: number;
    caption: string;
    type: string;
    formatType: string;
}

export interface MeasuringUnit {
    id: number;
    name: string;
}

export interface FlexFieldValueRequest {
    id: number;
    name: string;
}

export interface IRevenueCardDetailList {
    itensOrdened: RevenueFilterOrder[];
    setItensOrdened: (value: RevenueFilterOrder[]) => void;
}

export interface CaptionValue {
    id: number;
    name: string;
    values: MonthValue[];
    type: string;
}

export type MonthValue = {
    monthDate: string;
    value: number;
    monthId: number;
    type: string;
}

export type RevenueItemDateTable = {
    id: number;
    monthDate: string;
    total: any[];
    accomplished: boolean;
    value?: number;
}

export interface RevenueItemChildrenTable {
    key: React.ReactNode;
    name: string;
    value: number;
    children?: RevenueItemTable[];
    currencyId: number;
    flexFieldValueId: number;
    measuringUnitId: number;
}

export type RevenueCardDetailItem = {
    name: string;
    currencyId: number;
    flexFieldValueId: number;
    measuringUnitId: number;
}

export type IRevenueCardFilter = {
    key: number;
    title: string;
    description: string[];
    itens: RevenueCardFilterItens[];
}

export type RevenueCardFilterItens = {
    id: number;
    checked: boolean;
    name: string;
}

export interface RevenueFlexField {
    key: number;
    title: string;
    itens: RevenueFlexFieldItem[];
    description: string[];
    listKeys: string[];
    listActives: any[];
    order: number;
}

export type RevenueFlexFieldItem = {
    id: number;
    checked: boolean;
    label: string;
    value: number;
}

export type RevenueHeaderInformations = {
    planned: number;
    accomplished: number;
}

export type RevenueFilterOrder = {

    id: number;
    text: string;
    checked: boolean;
    order: number;
    originalOrder: number;

}

export type RevenueFilterFlexField = {
    flexFieldId: number;
    flexFieldValueIds: number[];
    measuringUnitId: number;
    currencyId: number;
}

export interface RevenueFilterFlexFieldItem {
    flexFieldId: number;
    order: number;
    active: boolean;
    values: RevenueFilterFlexFieldValue[];
}

export type RevenueFilterFlexFieldValue = {
    flexFieldValueId: number;
}

export type RevenueMeasuringUnit = {
    id: number;
    externalCode: string;
    description: string;
    symbol: string;
}

export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: RevenueItemTable;
    handleSave: (record: any, value: number, month: string) => void;
}

export type MonthAbbreviated = {
    abbr: string;
    num: string;
}

export interface IFilterContainer {
    flexFields: RevenueFlexField[];
    setFlexFields: (value: RevenueFlexField[]) => void;
    isFilterChanged: boolean;
    setIsFilterChanged: (value: boolean) => void;
    itensOrdened: RevenueFilterOrder[];
    setItensOrdened;
    currencyId: number;
    measuringUnitId: number;
}

export interface RevenueFields {
    id: number;
    flexFieldId: number;
    order: number;
    active: boolean;
    values: RevenueFieldValues[];
}

export interface RevenueFieldValues {
    fieldId: number;
    flexFieldValueId: number;
    measuringUnitId: number;
    currencyId: number;
    dates: any[];
}

export type CurrencyElement = {
    id: number;
    name: string;
    isoCode?: string;
}

export type CurrencyAndMeasuringChoice = {
    currencyId: number;
    measuringId: number;
}

export interface IRevenueTable {
    itensOrdened: RevenueFilterOrder[];
    setItensOrdened: (value: RevenueFilterOrder[]) => void;
    hasSavedValue: boolean;
    setHasSavedValue: (value: boolean) => void;
    setCardHeaderInformation: (value: RevenueHeaderInformations) => void;
    isFilterChanged: boolean;
    setIsFilterChanged: (value: boolean) => void;
    fieldsDefault;
}

export interface IHeaderBarGrid {
    itensOrdened: RevenueFilterOrder[];
    setItensOrdened: (value: RevenueFilterOrder[]) => void;
    hasSavedValue: boolean;
    flexFieldTree: RevenueItemTable[];
    setFlexFieldTree: (value: RevenueItemTable[]) => void;
    listRowsSelected: RevenueItemTable[],
    updateTreeGrid: (value: boolean) => void;
    columnsGrid;
    lastMonthAccomplished;
    setExpandedRowKeys;
    period: [moment.Moment, moment.Moment];
}

export interface IColumnGrid {
    editable?: boolean;
    title: string,
    dataIndex: number | string,
    key?: number | string,
    width?: number | string,
    onCell?: (a: any) => any;
    render?: (a: any, b: any) => any;
    fixed?: 'left' | 'right' | boolean;
    align: string;
}

export interface IMainColumn {
    monthsColumns: string[];
    selectedRow: Key;
    setSelectedRow: (value: Key) => void;
    currencyList: CurrencyElement[];
    measuringList: RevenueMeasuringUnit[];
    currencyAndMeasuringChoice: CurrencyAndMeasuringChoice;
    setCurrencyAndMeasuringChoice: (value: CurrencyAndMeasuringChoice) => void;
    handleIsLoading: (value: boolean) => void;
    updateTreeGrid: any;
    isEdit: boolean;
    setIsEdit: (value: boolean) => void;
    expandedRowKeys: Key[];
    setExpandedRowKeys: (value: Key[]) => void;
    setHasSavedValue: (value: boolean) => void;
    functionalityPermissions?;
}

export interface IMonthColumn {
    month: string;
    monthColumns: string[];
}

export interface ITotalColumn {
    selectedRow: Key;
    monthsColumns: string[];
}

export interface CurrencyUnitDefault {
    id: number;
    image: string;
    isoCode: string;
    name: string;
}

export interface MeasuringUnitDefault {
    id: number;
    description: string;
    symbol: string;
}

export interface RevenueStandard {
    id: number;
    gross: number;
    tax: number;
    net: number;
    growth: number;
    months: string[];
    fields: FieldsRevenueFilter[];
}

export interface FieldsRevenueFilter {
    id: number;
    flexFieldId: number;
    order: number;
    active: boolean;
    values: any[];
}

export interface FieldsValueRevenueFilter {
    fieldId: number;
    flexFieldValueId: number;
    measuringUnitId: number;
    currencyId: number;
    dates: FieldsValueDatesRevenueFilter[];
}

export interface FieldsValueDatesRevenueFilter {
    id: number;
    monthDate: string;
    accomplished: boolean;
    values: any;
    companyValues: any;
}

export interface MeasuringUnitConversion {
    id: number;
    externalCode: string;
    description: string;
    symbol: string;
}

export interface FiltersIdsAndOrder {
    fieldId: number;
    flexFieldId: number;
    order: number;
}

export interface FlexFieldFilter {
    id: number;
    flexFieldId: number;
    flexFieldDescription: string;
    flexFieldOrdenation: number;
    description: string;
    externalCode: string;
    flexFieldFilters: number[];
}

export interface FieldSelected {
    flexFieldId: number;
    order: number;
    originalOrder: number;
    flexFieldValuesId: number[];
}

export enum RevenueConstants {
    CALCULATED = "CALCULATED"
}

export interface Organization {
    id: number;
    name: string;
    businessUnits: BusinessUnit[];
}

export interface BusinessUnit {
    id: number;
    name: string;
    isChecked?: boolean;
}

export interface Option {
    id: number;
    description: string;
}

export interface SelectedViewType {
    label: string;
    value: "PLANNED" | "ACCOMPLISHED" | "FORECAST"
}

export interface RevenueContextProps {
    selectedViewType: SelectedViewType;
    handleSelectViewType: (view: SelectedViewType[]) => void;
    handleIsLoading: (view: boolean) => void;
    isLoading: boolean;
    isFetching: boolean;
    handleIsFetching: (value: boolean) => void;
    hasChangeOnGrid: boolean;
    handleHasChangeOnGrid: (value: boolean) => void;
    isAllFilterChecked: boolean;
    handleIsAllFilterChecked: (value: boolean) => void;
    period: [moment.Moment, moment.Moment];
    handleChangePeriod: (value: [moment.Moment, moment.Moment]) => void;
    filtersFatherActive: number[];
    handleFiltersFatherFactive: (value: number[]) => void;
    updateFiltersFlexField: boolean;
    handleUpdateFiltersFlexField: (value: boolean) => void;
    flexFieldsSelected: FieldSelected[];
    handleFlexFieldsSelected: (value: FieldSelected[]) => void;
    isOrdenationChanged: boolean;
    handleIsOrdenationChanged: (value: boolean) => void;
    isViewTypeChanged: boolean;
    handleIsViewTypeChanged: (value: boolean) => void;
    monthColumns: string[];
    handleMonthColumns: (value: string[]) => void;
    hasBudgetYear: boolean;
    openedCalendarPeriod: moment.Moment[][]
    periodAccomplished: moment.Moment;
    lastMonthAccomplished: string;
};