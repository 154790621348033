import { Icon } from "@iconify/react";
import { Spin, Tree, Input, Button, Tooltip } from "antd";
import { Key, useState } from "react";
import i18n from "util/base/i18n";
import { LevelItem, SidebarContentProps } from "../IManagementAccount";
const { Search } = Input;

export default function SidebarContent({
    setCheckedNodeKeys,
    checkedNodeKeys,
    treeData,
    isTreeDisabled,
    expandedKeys,
    setExpandedKeys,
    addNewSubLevel
}: SidebarContentProps) {
    const [search, setSearch] = useState('');

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearch(value);
    };

    function buildTreeData(data: LevelItem[], search: string): LevelItem[] {
        if (search.length === 0) return data;
        return [
            ...data.map((line) => {
                if (line.children) {
                    line.children = buildTreeData(line.children, search);

                    if (line.children.filter((child) => child).length === 0) {
                        if (!line.title.toLowerCase().includes(search)) {
                            line = undefined;
                        }
                    }
                } else {
                    if (!line.title.toLowerCase().includes(search)) {
                        line = undefined;
                    }
                }

                return line;
            }).filter((child) => child)
        ]
    }

    function onExpand(expandedKeys: number[], obj: { expanded: boolean }) {
        setExpandedKeys(expandedKeys)
    }

    function chooseIconExpanded(props: { expanded: boolean }) {
        return props.expanded ? <Icon icon="akar-icons:minus" /> : <Icon icon="akar-icons:plus" />;
    }

    function onCheck(checked: { checked: Key[]; halfChecked: Key[] }) {
        setCheckedNodeKeys(checked.checked as number[]);
    }

    function onClickAddLevel(id: number) {
        addNewSubLevel(id);
    }

    function addButtonLines(line) {
        return (
            <span className='tree-title-content'>
                <p>
                    {line.title}
                </p>
                <div>
                    {line.level < 4 && <Button onClick={() => onClickAddLevel(line.key)} icon={<Icon id={line.key} icon="akar-icons:plus" />} />}
                </div>
            </span >
        )
    }

    return (
        <div className='sider-content'>
            <Search style={{ marginBottom: 8 }} placeholder={i18n.t("search")} onChange={onChange} />
            <Spin spinning={isTreeDisabled}>
                {treeData.length > 0 ?
                    <Tree
                        checkStrictly
                        checkedKeys={checkedNodeKeys}
                        switcherIcon={chooseIconExpanded}
                        treeData={buildTreeData(JSON.parse(JSON.stringify(treeData)), search.toLowerCase())}
                        showLine={{ showLeafIcon: false }}
                        checkable
                        onCheck={onCheck}
                        titleRender={addButtonLines}
                        disabled={isTreeDisabled}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        selectable={false}
                    />
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 40 }}>
                        <Icon icon="clarity:tree-view-line" />
                        {i18n.t("groupings_texts.no_registered_level")}
                    </div>
                }
            </Spin>
        </div>
    )
}