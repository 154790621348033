import { CSSProperties, ReactNode } from 'react';
import './Label.sass'

interface ILabel {
    title: string;
    styles?: CSSProperties;
    children: ReactNode | string;
}

export function Label({ title, styles, children }: ILabel) {
    return (
        <label className="label-container" style={styles}>
            <p className='label-title'>{title}</p>
            {children}
        </label>
    )
}
