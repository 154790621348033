import { Input } from "antd";
import { IInputText } from "../../../../../../../../src/components/input/text/IInputText";
import "../../../../../../../../src/components/input/text/styles.sass";

interface CustomInputTextProps extends IInputText {
    disabled?: boolean;
}

export function CustomInputText({
    label,
    id,
    value,
    onChange,
    placeholder,
    containerStyle,
    inputStyle,
    disabled,
}: CustomInputTextProps) {
    return (
        <label style={containerStyle} className="gs-input-label" htmlFor={id}>
            {label}
            <Input
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                style={inputStyle}
                disabled={disabled}
            />
        </label>
    );
}
