import { FlexFieldValuesData } from "../../flexField/flexFieldValues/IFlexFieldValues";
import { IReportConfig, IReportFilter } from "./components/Filters/IFilters";

export interface IReportViewContext {
    closeFilterModal: () => void;
    openFilterModal: () => void;
    isFilterModalOpen: boolean;
    period: [moment.Moment, moment.Moment];
    selectedReportId: number;
    handleChangePeriod: (period: [moment.Moment, moment.Moment]) => void;
    handleSelectReportId: (reportId: number) => void;
    handleReportConfig: (reportConfig: IReportConfig) => void;
    reportConfig: IReportConfig;
    fetchReport: (updatedReportConfig: any, isDetailingLevelFetch?: boolean) => void;
    report: any;
    defaultColumns: any[];
    setDefaultColumns: (columns: any[]) => void;
    selectedFlexFieldValues: any[];
    selectedOrganizations: any[];
    setSelectedOrganizations: (organizations: any[]) => void;
    setSelectedFlexFieldValues: (flexFieldValues) => void;
    setReport: (report: any) => void;
    buildGridContent: (report: any) => any;
    isLoadingReport: boolean;
    handleIsLoadingReport: (isLoading: boolean) => void;
    isPopOverAvailable: boolean;
    handleReportDataChange: (updatedReportConfig: any) => void;
    popOverConfigs: IUpdatedReportVisualizationConfig;
    isDetailingLevel: boolean;
    handleIsDetailingLevel: (value: boolean) => void;
    dataTarget: any[];
    handleDataTarget: (data: any[]) => void;
    selectedModalityType: string;
    handleSelectedModalityType: (value: any) => void;
    selectedPeriodicityType: number;
    handleSelectedPeriodicityType: (value: string) => void;
    selectedCurrency: any;
    handleSelectedCurrency: (value: any) => void;
    baseType: "budgeted" | "accomplished";
    handleBaseType: (baseType) => void;
    baseTarget: "budgeted" | "accomplished";
    handleBaseTarget: (baseTarget) => void;
    scenarioToCompare: { value: string; label: string };
    handleScenarioToCompare: (scenario) => void;
    yearToCompare: number;
    handleYearToCompare: (year: number) => void;
    periodBase: [moment.Moment, moment.Moment];
    handlePeriodBase: (period: [moment.Moment, moment.Moment]) => void;
    showRowFilterComparative: boolean;
    handleShowRowFilterComparative: (show: boolean) => void;
    isDetailModalOpen: boolean;
    handleDetailModal: (isOpen: boolean) => void;
    setSelectedFlexFieldValueDetails: (value: any) => void;
    selectedFlexFieldValuesDetails: any;
    selectedFlexFieldOrganizationValues: any[];
    setSelectedFlexFieldOrganizationValues: React.Dispatch<React.SetStateAction<any[]>>;
    isPlanningReportSelected: boolean;
    setIsPlanningReportSelected: React.Dispatch<React.SetStateAction<boolean>>;
    handleRowsIdToDetail: (rowsIds: number[]) => void;
    rowsIdToDetail: number[];
    allReports: IReportFilter[];
    consolidations: any;
    consolidationSelected: string;
    handleConsolidationSelected: (consolidation: string) => void;
}

export interface IReport {
    id: number;
    description: string;
    levels: any[];
    periodType: string;
    creationDate: string;
    updateDate: string;
    children: IReport[];
    key: string;
    style: any[];
    ordination: number;
    informative: boolean;
    invisible: boolean;
    value: any[];
    column: any;
}

export interface IReportLevels {
    id: number;
    isoCode: string;
    columnValueBeans: any;
    description: string;
    informative: boolean;
    invisible: boolean;
    levelStyle: any;
    ordination: number;
    subLevels: any[];
    upperLevelId: number;
    valueBeans: any[];
}

export interface IPopOverConfigs {
    id?: number;
    decimalPlaces: number;
    showThousand: boolean;
    showGraphic: boolean;
    showUnity: boolean;
}

export interface IReportVisualizationFilters {
    decimalPlaces: number;
    showThousand: boolean;
    showGraphic: boolean;
    showUnity: boolean;
}

export enum Periodicity {
    YEARLY = "YEARLY",
    SEMESTERLY = "SEMESTERLY",
    QUARTERLY = "QUARTERLY",
    BIMONTHLY = "BIMONTHLY",
    MONTHLY = "MONTHLY",
}

export enum ModalityType {
    PLANNED = "PLANNED",
    ACCOMPLISHED = "ACCOMPLISHED",
    COMPARATIVE_ACCOMPLISHED_PLANNED = "COMPARATIVE_ACCOMPLISHED_PLANNED",
    FORECAST = "FORECAST",
    COMPARATIVE_SCENARIO_YEARS = "COMPARATIVE_SCENARIO_YEARS",
}

export interface IUpdatedReportVisualizationConfig {
    id?: number;
    decimalPlaces: number;
    showGraphic: boolean;
    showThousand: boolean;
    showUnity: boolean;
}

export interface PartFormula {
    id: number;
    name: string;
}

export interface Item {
    period: string;
    partFormula: PartFormula;
    value: string;
    attribute: boolean;
    costCenter: boolean;
    bussinessUnit: boolean;
    accountingHierarchy: boolean;
}

export interface Data {
    [key: string]: {
        [key: string]: Item[];
    };
}

export interface DataType {
    key: string;
    name: string;
    index?: number;
    valueType: "ATTRIBUTE" | "COST_CENTER" | "BUSINESS_UNIT" | "ACCOUNT_HIERARCHY";
}

export enum OptionLevelType {
    FLEX_FIELD,
    BUSINESS_UNIT,
    ORGANIZATION,
}

export interface IOptionLevel {
    id?: number;
    description?: string;
    label?: string;
    type?: OptionLevelType;
    values?: any[];
    isDetail?: boolean;
}

export interface IFlexFieldRevenue {
    [id: number]: [value: number];
}

export interface FlexFieldValuesRevenue extends FlexFieldValuesData {
    flexField: { id: number };
}

export interface DetailOrder {
    order: number;
    type: string;
}

export interface IExportGridExcelProps {
    report: IReport[];
    defaultColumns: any;
    handleExportGridData: any;
    selectedModality: any;
    popOverConfigs: IUpdatedReportVisualizationConfig;
    selectedPeriodicityType: string;
}

export interface IReport {
    id: number;
    externalCode: string;
    description: string;
    invisible: boolean;
    informative: boolean;
    subLevels: [];
    reportValues: {
        valueBeans: IReportValues;
        columnValueBeans: {
            totalValue: number;
        };
        lastPeriodRealized: string;
    };
    key: string;
    reportInfo: IReportInfo;
}

export interface IReportValues {
    period: string;
    value: number;
    plannedValue: number;
    accomplishedValue: number;
    variableValue: number;
    variablePercente: number;
    valueBase: number;
    valueTarget: number;
    isChild: boolean;
    accomplished: boolean;
}

export interface IReportInfo {
    id: number;
    creationDate: string;
    description: string;
    updateDate: string;
}

export interface IReportConfigParams {
    id: number;
    startPeriod: string;
    endPeriod: string;
    periodType: string;
    currencyId: number;
    modalityType: string;
}

export interface ILoadReportParams {
    reportId: number;
    localeId: number;
    scenarioId: number;
    currencyDefaultId: number;
    organizationId: number;
    organizationIds: number[];
    clientId: number;
    userId: number;
    isDetailing: boolean;
    comparativeRequest: ReportComparativeRequestBean[];
    groupIds: number[];
    businessUnitId: number;
    flexFieldValueIdsDetails: number[];
    flexFields?: Record<number, number[]>;
    companyFlexFieldIds?: number[];
    detailsOrder?: ReportDetailBean[];
    consolidationId?: number;
}

export interface ReportComparativeRequestBean {
    scenarioId: number;
	year: number;
	isBase: boolean;
	revenueType: string;
}

interface ReportDetailBean {
    order: number;
    type: string;
}
