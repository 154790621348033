import { Layout } from "antd";
import "./ReversalPeriodPerformed.sass";
import Content from "./components/Content";
import { Title } from "./components/Title";
import { ReversalPeriodPerformedProvider } from "./context/ReversalPeriodPerformedContext";

export default function ReversalPeriodPerformed() {
    return (
        <ReversalPeriodPerformedProvider>
            <Layout.Header>
                <Title />
            </Layout.Header>

            <Layout.Content>
                <Content />
            </Layout.Content>
        </ReversalPeriodPerformedProvider>
    );
}
