import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { Input, Modal, Radio } from "antd";
import type { RadioChangeEvent } from "antd";

import { IStandardAccountModal } from "../IGroupings";
import { Icon } from "@iconify/react";

const { Search } = Input;

export function StandardAccountModal({
    open,
    handleCancel,
    onDefineStandardAccount,
    packageAccountLinkedList,
    standardAccountId,
    packageLevel,
}: IStandardAccountModal) {
    const [value, setValue] = useState(undefined);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (standardAccountId !== undefined) {
            setValue(standardAccountId);
        }
    }, [standardAccountId]);

    if (!packageLevel) {
        return null;
    }

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };
    const clearSelection = () => {
        setValue(undefined);
    }

    return (
        <Modal
            width={550}
            centered={true}
            open={open}
            title={i18n.t("standard_accounting_account")}
            onCancel={handleCancel}
            className="gs-modal standard-account-package-modal"
            cancelText={i18n.t<string>("close")}
            okText={i18n.t<string>("save")}
            onOk={() => onDefineStandardAccount(value)}
            bodyStyle={{ height: '400px' }}
            maskClosable={false}
            
        >
            <span className="gs-tag blue">{`${i18n.t("spending_package")}: ${packageLevel.description}`}</span>
            <Search value={search} onChange={(e) => setSearch(e.target.value)} style={{ margin: "8px 0" }} placeholder={i18n.t("search")} />
            <Radio.Group onChange={onChange} value={value}
             style={{ maxHeight: '350px', overflowY: 'auto' }}    
            >
                <Radio
                    key={"no_option"}
                    value={undefined}
                    onClick={clearSelection}
                >{i18n.t("no_option")}</Radio>
                {packageAccountLinkedList
                    .filter((item) => item.label.toLowerCase().includes(search.toLowerCase()))
                    .map((account) => {
                        return (
                            <Radio key={account.value} value={account.value}>
                                <div className="gs-flex align-center jc-sb">
                                    <span>{account.label}</span>
                                    {value === account.value && (
                                        <Icon
                                            style={{ color: "#0065B3", fontSize: 22 }}
                                            icon="bitcoin-icons:verify-outline"
                                        />
                                    )}
                                </div>
                            </Radio>
                        );
                    })}
            </Radio.Group>
        </Modal>
    );
}
