import { Dispatch, SetStateAction } from "react";
import { ColorResult } from "react-color";

export interface IColorPickerButtonProps {
	color: string;
	label: string;
	onChange: (color: string) => void;
	onChangeCustomColor: (color: string) => void;
}

export type ColorChangeHandler = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => void;

export const defaultColors: string[] = [
	"#CA0C62",
	"#E56B15",
	"#2B853D",
	"#7DB530",
	"#9D9D9D",
	"#241818",
	"#BE1A21",
	"#F0B81D",
	"#198472",
	"#61AEDA",
	"#126FB5",
	"#202B61",
	"#D93116",
	"#F4E92B",
	"#22944C",
	"#AB94C0",
	"#073E8D",
	"#641D7B",
	"#C88A13",
	"#FFFFFF",
	"#F1B9AA",
	"#ECADC8",
	"#993300",
	"#7A5547",
]

export const defaultCustomColors: string[] = [
	"#fffffa",
	"#fffffb",
	"#fffffc",
	"#fffffd",
	"#fffffe",
	"#ffffff",
]

export interface ICustomColorPickerProps {
	handleSave: (colorResult: string) => void;
	setIsVisible: Dispatch<SetStateAction<boolean>>;
	defaultSelected: string;
}