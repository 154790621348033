import moment from "moment";
import { Table } from 'antd';
import { InvestmentConfiguration } from "../IInvestment";

export type DetailTableData = {
    id: number;
    key: number;
    description: string;
    [key: string]: Months | number | string | boolean;
}

export type DetailData = {
    templates: {
        templateId: number;
        templateExternalCode: string;
        templateDescription: string;
        months: Months[];
    }[];
    accounting: {
        accountingId: number;
        accountingExternalCode: string;
        accountingDescription: string;
        months: Months[];
    }
}

export type Months = {
    quantity: number;
    totalValue: number;
    unityValue: number;
    activationDate: string;
    justification: string;
}

export enum ViewModeSelected {
    TEMPLATE = 'TEMPLATE',
    ACCOUNTING = 'ACCOUNTING'
}

export enum ViewColumns {
    QUANTITY = 'quantity',
    UNITY_VALUE = 'unityValue',
    ACTIVATION_DATE = 'activationDate',
    CONVERTED_ACCOMPLISHED_VALUE = 'convertedAccomplishedValue',
    DEPRECIATED_VALUE = 'depreciatedValue',
    EXCHANGE_RATE = 'exchangeRate'
}

export interface IDetailTableContainer {
    detailTableData: DetailTableData[]
    setDetailTableData(d: DetailTableData[]): void
    isFetching: boolean
    setIsFetching(b: boolean): void;
    viewModeSelected: ViewModeSelected;
    setViewModeSelected(d: ViewModeSelected): void;
    handleSaveDetailValue(d: DetailTableData, month: string): void;
    handleOpenModal(b: boolean): void;
    detailId: number;
    loadInvestmentDetail;
    detailData;
    configurations: InvestmentConfiguration;
    controlPanelPeriods;
    controlPanelValues;
    setToggle: (b: boolean) => void;
}

export interface IDetail {
    detailId: number;
    investmentSelected;
    handleOpenModal(b: boolean): void;
    detailTableData: DetailTableData[];
    setDetailTableData(d: DetailTableData[]): void;
    isModalVisible: boolean;
    form: any;
    flexFieldsOptions;
    setFlexFieldsOptions;
    currenciesList;
    loadInvestmentDetail;
    detailData: DetailData;
    isFetching: boolean;
    setIsFetching: (b: boolean) => void;
    handleClickBackButton;
    viewModeSelected: ViewModeSelected;
    setViewModeSelected(d: ViewModeSelected): void;
    periodFilter: moment.Moment[];
    configurations: InvestmentConfiguration;
    controlPanelPeriods;
    controlPanelValues;
    setToggle: (b: boolean) => void;
}

export type EditableTableProps = Parameters<typeof Table>[0];

export interface EditableRowProps {
    index: number;
}

export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof DetailTableData;
    record: DetailTableData;
    onCell: (a: any) => any;
}

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export type Column = ColumnTypes[number] &
{
    editable?: boolean;
    dataIndex?: string;
    children?: Column[]
};