import { Icon } from "@iconify/react";
import { Button, Col, Row } from "antd";
import moment from "moment";
import i18n from "util/base/i18n";

export default function SecondStageModal({
    businessUnitList,
    costCenterList,
    currenciesList,
    accountingList,
    templateList,
    editDisebled,
    handleEditListItem,
    handleDeleteListItem,
    item,
    formattedValue
}) {

    function findLabel(list, id) {
        const option = list.find(item => item.value === id);
        return option ? option.label : null;
    }

    return (
        <Row key={item.itemKey} align="middle" gutter={2}>
            <Col span={3}>{findLabel(businessUnitList, item.businessUnitId)}</Col>
            <Col span={3}>{findLabel(costCenterList, item.costCenterId)}</Col>
            <Col span={3}>{findLabel(accountingList, item.accountingId)}</Col>
            <Col span={3}>{item.templateId === 0 ? "Nenhum template" : findLabel(templateList, item.templateId)}</Col>
            <Col span={1} style={{ marginLeft: 0, width: 25 }} >{item.justification ? <Icon icon="mdi:chat" /> : ""}</Col>
            <Col span={3}>{findLabel(currenciesList, item.currencyId)}</Col>
            <Col span={2}>{formattedValue(item.totalValue)}</Col>
            <Col span={2}>{moment(item.acquisitionDate).format('MM/YYYY')}</Col>
            <Col span={2}>{moment(item.activationDate).format('MM/YYYY')}</Col>
            <Col span={1}>
                <Button
                    disabled={editDisebled}
                    onClick={() => handleEditListItem(item)}
                    icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                />
            </Col>
            <Col span={1}>
                <Icon
                    className="delete-icon"
                    onClick={() => handleDeleteListItem(item.itemKey)}
                    icon="fa6-solid:trash"
                />
            </Col>
        </Row>

    )
}