import i18n from "util/base/i18n";
import "./AllocationParameterization.sass";
import { Row, Col } from "antd";
import AllocationTable from "./components/AllocationTable";
import { useState } from "react";
import NewAllocationModal from "./components/NewAllocationModal";
import { IAllocationTableData } from "./IAllocationParameterization";

export default function AllocationParameterization() {
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [allocationIdForEdit, setAllocationIdForEdit] = useState<IAllocationTableData>();

	return (
		<div className="allocation-parameterization-page">
			<Row className="page-title-content">
				<Col>
					<h1>{i18n.t("hr_allocations.title")}</h1>
				</Col>
			</Row>
			<AllocationTable
				selectedRowKeys={selectedRowKeys}
				setSelectedRowKeys={setSelectedRowKeys}
				setIsModalOpen={setIsModalOpen}
				setAllocationIdForEdit={setAllocationIdForEdit}
				isModalOpen={isModalOpen}
			/>
			<NewAllocationModal
				isOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				rowForEdit={allocationIdForEdit}
			/>
		</div>
	)
}