import i18n from "util/base/i18n";
import AccountingLedgerMultipleSearch from "./AccountingLedgerMultipleSearch";
import GrouperLedger from "./GrouperLedger";

import { Icon } from "@iconify/react";
import { Tooltip } from "antd";
import { IOptionsForSearch } from "components/input/search/IMultipleSearch";
import { useAccountingLedgerContext } from "../context/AccountingLedgerContext";

export default function GridOperationAccountingLedger() {
	const { handleExport, tableData, defaultSearchOptions} = useAccountingLedgerContext();
	
	return (
		<div className="operation-accounting-ledger-content-table"
		>
			<AccountingLedgerMultipleSearch options={defaultSearchOptions}/>

			{tableData?.length > 0 && (
				<>
					<GrouperLedger />
					<Tooltip
						placement="bottom"
						title={i18n.t<string>("export")}
					>
						<Icon icon="ph:export"
							id="export"
							style={{ color: 'black', fontSize: 20 }}
							onClick={() => { handleExport() }}
						/>
					</Tooltip>
				</>
			)}
		</div>
	);
}