import {
	useEffect,
	useState
} from "react";

import {
	Button,
	Radio
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import CheckboxFilter from "./checkbox/CheckboxFilter";
import DoFilter from "./DoFilter";
import { FilterBy, IFilter } from "../IDashboardAnalytics";

interface Props {
	filter: IFilter,
	callBack: Function
	visiblePopover: Function
}

export default function FilterContent(props: Props) {

	const [loading, setLoading]: [boolean, Function] = useState(true);

	const [value, setValue]: [FilterBy, Function] = useState(props.filter.filterBy);

	const [optionsFilter, setOptionsFilter]: [{}, Function] = useState({});

	const [optionsSelected, setOptionsSelected]: [number[], Function] = useState(props.filter.values);

	const [disabledButton, setDisabledButton]: [boolean, Function] = useState(false);

	const [defaultChecked, setDefaultCheked]: [number[], Function] = useState(props.filter.values);

	const filterEndpoints = {
		[FilterBy.CONSOLIDATION_GROUP]: "consolidation-group?client={client}",
		[FilterBy.ORGANIZATION]: "organization?client={client}&user={user}&locale={locale}",
		[FilterBy.BUSINESS_UNIT]: "business-unit?client={client}&user={user}&locale={locale}"
	};

	const optionSelected = (optionsId: number[]) => {
		if (optionsId.length === 0) {
			setDisabledButton(true)
		} else {
			setDisabledButton(false)
		}

		setOptionsSelected(optionsId)
	};

	const onChange = e => {
		const value = e.target.value
		if (value === props.filter.filterBy) {
			setDefaultCheked(props.filter.values);
			setDisabledButton(false);
		} else {
			setDefaultCheked([]);
			setDisabledButton(true);
		}

		setLoading(true);
		setValue(value);
	};

	const onLoadFilter: Function = (items: any[]) => {
		setOptionsFilter({
			...optionsFilter,
			[value]: items.map(({ id, name }) => {
				return {
					id,
					name
				}
			}).sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
		});

		setLoading(false);
	};

	const onClickFilter = () => {
		props.visiblePopover(false);
		setDefaultCheked(optionSelected);

		ServiceCaller.doRequest({
			url: `/dashboard-analytics/filter/${props.filter.id}`,
			type: RequestType.PUT,
			params: {
				filterBy: value,
				values: optionsSelected
			}
		});

		props.callBack({ filterBy: value, values: optionsSelected, id: props.filter.id });
	};

	useEffect(() => {
		if (value) {
			if (optionsFilter.hasOwnProperty(value)) {
				setLoading(false);
			} else {
				ServiceCaller.doRequest({
					url: `/monolith/${filterEndpoints[value]}`,
					type: RequestType.GET
				}, onLoadFilter.bind(this));
			}
		}
	}, [value]);

	useEffect(() => {
		setValue(props.filter.filterBy);
	}, []);

	return (
		<div className="content-filters">
			<div className="filter-header-content">
				<small className="gs-small">{i18n.t<string>("dashboard.analytics.select_filter_analyze")}</small>
				<Radio.Group onChange={onChange} value={value}>
					<Radio value={"CONSOLIDATION_GROUP"}>{i18n.t<string>("consolidation_group")}</Radio>
					<Radio value={"ORGANIZATION"}>{i18n.t<string>("organization")}</Radio>
					<Radio value={"BUSINESS_UNIT"}>{i18n.t<string>("operating_unit")}</Radio>
				</Radio.Group>
			</div>
			<div className="filter-content">
					{
						loading ? <LoadingOutlined /> : <>
							<DoFilter active={value}>
								<CheckboxFilter key={"CONSOLIDATION_GROUP"} checkedValues={defaultChecked} optionSelected={optionSelected} allOptions={optionsFilter[value]} />
								<CheckboxFilter key={"ORGANIZATION"} checkedValues={defaultChecked} optionSelected={optionSelected} allOptions={optionsFilter[value]} />
								<CheckboxFilter key={"BUSINESS_UNIT"} checkedValues={defaultChecked} optionSelected={optionSelected} allOptions={optionsFilter[value]} />
							</DoFilter>
						</>
					}
			</div>
			<Button disabled={disabledButton} onClick={onClickFilter} type="primary"> {i18n.t<string>("filter")} </Button>
		</div>
	)
}