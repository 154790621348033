import * as React from "react";

import "../index.sass";

import i18n from "util/base/i18n";

import { Icon } from "@iconify/react";
import {
	Button,
	DatePicker,
	Popover
} from "antd";

import type { RangePickerProps } from 'antd/es/date-picker';

import locale_ptBR from "antd/es/date-picker/locale/pt_BR";
import locale_enUS from "antd/es/date-picker/locale/en_US";
import locale_esES from "antd/es/date-picker/locale/es_ES";

import {
	IDateProps,
	IDateState,
} from "../IDate";

import moment from "moment";
import InputDate from "../input/InputDate";

export default class DateRange extends React.Component<IDateProps, IDateState> {

	constructor(props: IDateProps) {
		super(props); 

		this.state = {
			inputIndex: 0,
			value: props.value,
			originalValue: [...(props.value as moment.Moment[])],
			opened: false,
			languages: {
				pt_BR: locale_ptBR,
				en_US: locale_enUS,
				es_ES: locale_esES
			},
			closeTimeout: null,
			disabledButtonFilter: false
		};
	};

	onApplyDate = (newValue: moment.Moment) => {
		const {
			inputIndex,
			value
		} = this.state;

		if (newValue) {
			value[inputIndex] = newValue;
		}

		const [
			startDate,
			endDate
		] = value as moment.Moment[];

		if (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
			this.setState({
				opened: false,
				value,
				originalValue: [...(value as moment.Moment[])]
			});

			this.props.onChange(value);
		}
	};

	onChangeDate = (newValue: moment.Moment | moment.Moment[]) => {
		let {
			disabledButtonFilter,
			inputIndex,
			value
		} = this.state;

		disabledButtonFilter = false;

		if (newValue instanceof Array) {
			value[inputIndex] = newValue[inputIndex];
		} else {
			value[inputIndex] = newValue;
		}

		if(inputIndex === 0) {
			if(moment(value[inputIndex]).diff(value[(inputIndex === 0 ? 1 : 0)], "milliseconds") > 0){
				disabledButtonFilter = true;
			}
		} else {
			if(moment(value[inputIndex]).diff(value[(inputIndex === 0 ? 1 : 0)], "milliseconds") < 0){
				disabledButtonFilter = true;
			}
		}
		
		this.setState({ value, disabledButtonFilter});
	};

	onOpen = (inputIndex: number) => {
		const { disabled } = this.props;
		const { closeTimeout } = this.state;
		this.setState({ disabledButtonFilter: false});

		if (disabled) {
			return;
		}

		clearTimeout(closeTimeout);
		this.setState({
			inputIndex,
			opened: true
		});
	};

	onClose = () => {
		const { disabled } = this.props;

		if (disabled) {
			return;
		}

		this.setState({
			closeTimeout: setTimeout((() => {
				const { originalValue } = this.state;

				this.setState({
					opened: false,
					value: [...(originalValue as moment.Moment[])]
				});
			}), 0)
		})
	};

	disabledDate: RangePickerProps['disabledDate'] = (current) => {
		const { period } = this.props;

		if (!period) {
			return false;
		}

		return current < moment(period[0]) || current > moment(period[1])
	};

	render() {
		const {
			value,
			opened,
			languages,
			inputIndex,
			disabledButtonFilter
		} = this.state;

		const {
			format,
			formatEdit,
			picker,
			disabled,
			showToday
		} = this.props;

		const rangePicker: React.ReactElement = <>
			<div className="gs-date-picker-container">
				<DatePicker value={value[inputIndex]} onChange={this.onChangeDate.bind(this)} picker={picker} locale={languages[i18n.language]} format={format} allowClear={false} 
					disabledDate={this.disabledDate}
					open={true} showToday={false}
					renderExtraFooter={() => <>
						{
							(!!showToday || true) && <>
								<Button onClick={this.onChangeDate.bind(this, moment())} type="link">
									{i18n.t<string>("today")}
								</Button>
							</>
						}
 
						<Button disabled={disabledButtonFilter} className="button-filter-footer" onClick={this.onApplyDate.bind(this, null)} type="text">
							{i18n.t<string>("filter")}
						</Button>
					</>}
					getPopupContainer={(node: HTMLElement) => node.parentElement}
				/>
			</div>
		</>;

		return <>
			<Popover visible={opened} content={rangePicker} trigger="click" placement="bottomRight" destroyTooltipOnHide={true} overlayClassName="gs-date-overlay-container">
				<div className={"gs-date-content " + (disabled ? "disabled" : "")}>
					<Icon className="gs-date-icon" icon="bx:calendar" />
					<InputDate value={value[0]} format={opened ? (formatEdit || format) : format} disabled={disabled} onChange={this.onChangeDate.bind(this)} onEnter={this.onApplyDate.bind(this)} onOpen={this.onOpen.bind(this, 0)} onClose={this.onClose.bind(this)} />
					<Icon className="gs-date-separator" icon="ant-design:line-outlined" />
					<InputDate value={value[1]} format={opened ? (formatEdit || format) : format} disabled={disabled} onChange={this.onChangeDate.bind(this)} onEnter={this.onApplyDate.bind(this)} onOpen={this.onOpen.bind(this, 1)} onClose={this.onClose.bind(this)} />
				</div>
			</Popover>
		</>;
	};

}
