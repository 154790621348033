import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Spin } from "antd";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { FlexFieldModalProps, FlexFieldValuesData } from "../IFlexFieldValues";
import { selectProps } from "util/props/props";
import { validateFormField } from "util/functions/validateFormField";
import { ImageBox } from "components/imageBox/ImageBox";

export default function FlexFieldValuesModal({
	form,
	handleSubmit,
	handleSave,
	handleCancel,
	isModalVisible,
	isNewFlexFieldValues,
	listToSave,
	setListToSave,
	tableData,
	isAccountingAccountVisible,
	isBusinessUnitVisible,
	isCostCenterVisible,
	isFlexFieldVisible,
	linkedFlexFilters,
	isLoadingLinkedFilters,
	selectedRowKeys,
	accountingList,
	isFetchingAccounting,
	costCenterList,
	isFetchingCostCenter,
	businessUnitList,
	isFetchingBusinessUnit,
	isFetchingOrganizationList,
	isOrganizationVisible,
	organizationList,
	isLoadingLinkedDetails,
	linkedFlexDetails
}: FlexFieldModalProps) {
	const [isDisabled, setIsDisabled] = useState(false);

	function removeLineFromList(externalCode: string) {
		const index = listToSave.findIndex(c => c.externalCode === externalCode)
		const updatedCostCategoryList = [...listToSave]
		updatedCostCategoryList.splice(index, 1)
		setListToSave(updatedCostCategoryList)
	}

	function handleEditFlexField(flexField: FlexFieldValuesData) {
		setIsDisabled(true);
		form.setFieldsValue(flexField);
		const index = listToSave.indexOf(flexField)
		const updatedListToSave = [...listToSave];
		updatedListToSave.splice(index, 1);
		setListToSave(updatedListToSave);
	}
 	
	return (
		<Modal
			width="50%"
			title={isNewFlexFieldValues ? i18n.t("new-value") : i18n.t("edit-value")}
			open={isModalVisible}
			okButtonProps={{
				htmlType: isNewFlexFieldValues ? "button" : "submit",
				form: "flex-field-values-form"
			}}
			onCancel={() => {
				setIsDisabled(false);
				handleCancel();
			}}
			onOk={() => {
				setIsDisabled(false)
				if (isNewFlexFieldValues) {
					handleSave(listToSave)
				}
			}}
			className="gs-modal flex-field-modal"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("save")}
			centered
		>
			<Form
				form={form}
				name="flex-field-values-form"
				onFinish={(data) => {
					setIsDisabled(false)
					handleSubmit([data])
				}}
				layout="vertical"
				requiredMark={false}
				className="flex-field-values-form"
			>
				<Row wrap gutter={20}>
					<Col span={8}>
						<Form.Item
							label={i18n.t("external_code")}
							name="externalCode"
							rules={[{ required: true, message: i18n.t("required_field"), },
							() => ({
								validator(_, value: string) {
									return validateFormField(value, 'externalCode', tableData, listToSave, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedRowKeys[0]);
								},
							}),
							]}
						>
							<Input placeholder={i18n.t<string>("type_here")} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label={i18n.t("description")}
							name="description"
							rules={[{ required: true, message: i18n.t("required_field") }]}
						>
							<Input placeholder={i18n.t<string>("type_here")} />
						</Form.Item>
					</Col>
					{!isNewFlexFieldValues ?
						<Col span={8}>
							<Form.Item
								label={i18n.t("flexField.inactivation_period")}
								name="inactivationDate"
							>
								<DatePicker
									picker="month"
									format="DD/MM/YYYY"
								/>
							</Form.Item>
						</Col>
						: <></>
					}
					{
						isAccountingAccountVisible &&
						<Col span={8}>
							<Form.Item
								label={i18n.t("accounting_account")}
								name="accountingAccountFilters"
								rules={[{ required: true, message: i18n.t("required_field") }]}
							>
								<Select
									loading={isFetchingAccounting}
									maxTagCount="responsive"
									mode="multiple"
									options={accountingList}
									placeholder={isFetchingAccounting ? 'Carregando...' : 'Selecione'}
									{...selectProps}
								/>
							</Form.Item>
						</Col>
					}
					{
						isBusinessUnitVisible &&
						<Col span={8}>
							<Form.Item
								label={i18n.t("business_unit")}
								name="businessUnitFilters"
								rules={[{ required: true, message: i18n.t("required_field") }]}
							>
								<Select
									loading={isFetchingBusinessUnit}
									maxTagCount="responsive"
									mode="multiple"
									options={businessUnitList}
									placeholder={isFetchingBusinessUnit ? 'Carregando...' : 'Selecione'}
									{...selectProps}
								/>
							</Form.Item>
						</Col>
					}
					{
						isCostCenterVisible &&
						<Col span={8}>
							<Form.Item
								label={i18n.t("cost_center")}
								name="costCenterFilters"
								rules={[{ required: true, message: i18n.t("required_field") }]}
							>
								<Select
									loading={isFetchingCostCenter}
									maxTagCount="responsive"
									mode="multiple"
									options={costCenterList}
									placeholder={isFetchingCostCenter ? 'Carregando...' : 'Selecione'}
									{...selectProps}
								/>
							</Form.Item>
						</Col>
					}
					{isOrganizationVisible &&
						<Col span={8}>
							<Form.Item
								label={i18n.t("organization")}
								name="organizationFilters"
								rules={[{ required: true, message: i18n.t("required_field") }]}
							>
								<Select
									loading={isFetchingOrganizationList}
									maxTagCount="responsive"
									mode="multiple"
									options={organizationList}
									placeholder={isFetchingOrganizationList ? 'Carregando...' : 'Selecione'}
									{...selectProps}
								/>
							</Form.Item>
						</Col>
					}
					{isLoadingLinkedFilters ?
						<Spin /> :
						linkedFlexFilters.length ?
							(<Row className="flex-field-line">
								<Row>
									<p>
										{i18n.t("flexible_field")}
									</p>
								</Row>
								<Row gutter={7} wrap>
									{linkedFlexFilters.map(flexFilter => {
										return (
											<Col span={8} key={flexFilter.flexFieldId}>
												<Form.Item
													label={flexFilter.flexFieldDescription}
													name={`flexFieldId-${flexFilter.flexFieldId}`}
													rules={[{ required: true, message: i18n.t("required_field") }]}
												>
													<Select
														maxTagCount="responsive"
														mode="multiple"
														options={flexFilter.flexFieldValues}
														placeholder={i18n.t("select")}
														{...selectProps}
													/>
												</Form.Item>
											</Col>
										)
									})}
								</Row>
							</Row>) : <></>
					}
				</Row>
				{isLoadingLinkedDetails ?
					<Spin /> :
					linkedFlexDetails.length ?
						(<Row className="flex-field-line">
							<Row >
								<p>
									{i18n.t("detail")}
								</p>
							</Row>
							<Row gutter={7} wrap>
								{linkedFlexDetails.map(flexFilter => {
									return (
										<Col span={8} key={flexFilter.flexFieldId}>
											<Form.Item
												label={flexFilter.flexFieldDescription}
												name={`detail-${flexFilter.flexFieldId}`}
												rules={[{ required: true, message: i18n.t("required_field") }]}
											>
												<Select
													maxTagCount="responsive"
													options={flexFilter.flexFieldValues}
													placeholder={i18n.t("select")}
													{...selectProps}
												/>
											</Form.Item>
										</Col>
									)
								})}
							</Row>
						</Row>) : <></>
				}
				{isNewFlexFieldValues &&
					<Button
						style={{ marginRight: 10 }}
						icon={<Icon icon="akar-icons:circle-plus-fill" />}
						htmlType="submit"
						className="gs-secondary-button"
					>
						{i18n.t<string>("addToList")}
					</Button>
				}
			</Form>
			{
				isNewFlexFieldValues &&
				<div className="flex-field-list-container">
					{listToSave.length > 0
						?
						<>
							<div className="flex-field-list-header">
								<Row align="middle" gutter={2}>
									<Col span={8}>{i18n.t("external_code")}</Col>
									<Col span={14}>{i18n.t("description")}</Col>
									<Col span={1} />
									<Col span={1} />
								</Row>
							</div>
							<div className="flex-field-list-content">
								{listToSave.map((item) => {
									return (
										<Row align="middle" key={item.externalCode} gutter={2}>
											<Col span={8}>{item.externalCode}</Col>
											<Col span={14}>{item.description}</Col>
											<Col span={1} style={{ justifyContent: 'flex-end' }}>
												<Button
													disabled={isDisabled}
													onClick={() => handleEditFlexField(item)}
													icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
												/>
											</Col>
											<Col span={1} style={{ justifyContent: 'center' }}>
												<Icon
													className="delete-icon"
													onClick={() => removeLineFromList(item.externalCode)}
													icon="fa6-solid:trash"
												/>
											</Col>
										</Row>
									);
								})}
							</div>
						</>
						:
						<ImageBox styles={{ paddingTop: 25 }} />
					}
				</div>
			}
		</Modal >
	);
}