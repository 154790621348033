import { useState } from "react";
import { InvestmentTemplateModalProps, ValueForm } from "../IInvestmentTemplate";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import i18n from "util/base/i18n";
import { Notification } from "components/notification/Notification";
import { Icon } from "@iconify/react";
import { validateFormField } from "util/functions/validateFormField";
import { ImageBox } from "components/imageBox/ImageBox";


export default function InvestmentTemplateModal({
    isModalVisible,
    isNewTemplate,
    handleSubmit,
    handleCancel,
    form,
    modalList,
    setModalList,
    handleSaveTemplate,
    tableData,
    selectedRowKeys,
    accountingList,
    isFetchingAccountingList
}: InvestmentTemplateModalProps) {

    const [editDisebled, setEditDisebled] = useState(false);

    const modalTitle = isNewTemplate
        ? i18n.t("investment_template.new_investment_template")
        : i18n.t("investment_template.edit_investment_template");

    function handleEditListItem(template: ValueForm) {
        setEditDisebled(true);
        form.setFieldsValue({
            externalCode: template.externalCode,
            description: template.description,
            investmentAccountId: template.investmentAccountId,
        });
        handleDeleteListItem(template.externalCode);
    }

    function handleDeleteListItem(externalCode) {
        const templates = [...modalList];
        const i = templates.findIndex((category) => (category.externalCode === externalCode));
        templates.splice(i, 1);
        setModalList(templates);
    }

    return (
        <Modal
            width={1200}
            title={modalTitle}
            visible={isModalVisible}
            okButtonProps={modalList.length > 0 || !isNewTemplate ? { htmlType: "submit", form: "new-account-form" } : { disabled: true }}
            onCancel={() => {
                handleCancel()
            }}
            className="gs-modal account-registration-modal template-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            onOk={() => {
                if (isNewTemplate) {
                    if (modalList.length > 0) {
                        handleSaveTemplate(modalList);
                    } else {
                        Notification({
                            type: 'warning',
                            message: i18n.t("no_items_added_to_list")
                        })
                    }
                }
            }}
        >
            <Form
                form={form}
                name="new-account-form"
                className={isNewTemplate ? "form-new-account" : ""}
                onFinish={(data) => {
                    setEditDisebled(false)
                    handleSubmit(data)
                }}
                layout="vertical"
                requiredMark={false}
            >
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            name="externalCode"
                            label={i18n.t<string>("external_code")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                                () => ({
                                    validator(_, value: string) {
                                        return validateFormField(value, 'externalCode', tableData, modalList, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedRowKeys[0]);
                                    },
                                })
                            ]}
                        >
                            <Input placeholder={i18n.t<string>("type_here")} disabled={!isNewTemplate ?? false} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="description"
                            label={i18n.t<string>("description")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                            ]}
                        >
                            <Input placeholder={i18n.t<string>("type_here")} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="investmentAccountId"
                            label={i18n.t("investment_template.accounting")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                loading={isFetchingAccountingList}
                                options={accountingList}
                                showSearch={true}
                                optionFilterProp={"children"}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                placeholder={i18n.t<string>("select")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {isNewTemplate &&
                    <Button
                        style={{ marginTop: -10, marginLeft: "auto" }}
                        type="default"
                        htmlType="submit"
                        className="gs-secondary-button"
                        icon={<Icon icon="akar-icons:circle-plus-fill" />}
                    >
                        {i18n.t('addToList')}
                    </Button>}
            </Form>
            {isNewTemplate &&
                (modalList.length > 0 ?
                    <>
                        <div className="new-account-table-head">
                            <Row align="middle" gutter={2}>
                                <Col span={6}>{i18n.t<string>("code")}</Col>
                                <Col span={8}>{i18n.t<string>("description")}</Col>
                                <Col span={8}>{i18n.t("investment_template.accounting")}</Col>
                                <Col span={1} />
                                <Col span={1} />
                            </Row>
                        </div>
                        <div className="new-account-table-body">
                            {modalList.map((template) => {
                                return (
                                    <Row key={template.externalCode} align="middle" gutter={2}>
                                        <Col span={6}>{template.externalCode}</Col>
                                        <Col span={8}>{template.description}</Col>
                                        <Col span={8}>{(() => {
                                            const accountingOption = accountingList.find(accounting => accounting.value === template.investmentAccountId);
                                            return template ? accountingOption.label : null;
                                        })()}</Col>
                                        <Col span={1}>
                                            <Button
                                                disabled={editDisebled}
                                                onClick={() => handleEditListItem(template)}
                                                icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Icon
                                                className="delete-icon"
                                                onClick={() => handleDeleteListItem(template.externalCode)}
                                                icon="fa6-solid:trash"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                    :
                    <ImageBox />
                )
            }
        </Modal>
    )
}
