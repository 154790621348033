import { TopButtons } from "components/topButtons/TopButtons";
import TableParameterization from "./components/TableParameterization";
import './ParameterizationFromTo.sass';
import { Icon } from "@iconify/react";
import { useState } from "react";
import i18n from "util/base/i18n";
import ModalAddParameterization from "./components/ModalAddParameterization";
import { useParameterizationContext } from "./context/ParameterizationContext";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { Input } from "antd";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { ColumnsType } from "antd/lib/table";
import { IParametizationData } from "./IParameterizationFromTo";
import { Notification } from "components/notification/Notification";

export default function ParameterizationFromTo() {

    const { selectedRowKeys, dataSource, loadTable, flexFieldValueOptions, clearSelectedRowKeys } = useParameterizationContext();
    const [filteredDataSource, setFilteredDataSource] = useState(null);
    const filterEdit: any = dataSource.flatMap(item => item.childrens).filter(chil => chil.id === selectedRowKeys[0])[0];
    const { Search } = Input;
    const [isNewParameterization, setIsNewParameterization] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const importProps: IImportModal[] = [
        {
            importUrl: "/revenue/parameterization/import",
            templateUrl: "/revenue/parameterization/import/template?user={user}&client={client}&locale={locale}",
            type: 'excel',
            title: i18n.t("import_parameterizations")
        }
    ]

    const onSearch = searchText => {
        const fieldItensToCompareBeforeChange = JSON.parse(JSON.stringify(dataSource));
        const filteredData = fieldItensToCompareBeforeChange.map(item => {
            let newChildrens = item.childrens.filter(item => {
                return (
                    item.originCode.toLowerCase().includes(searchText.toLowerCase()) ||
                    flexFieldValueOptions.some(value => value.description.toLowerCase().includes(searchText.toLowerCase()) && value.id === item.destinyValue)
                )
            }).map(item => ({
                ...item,
                destinyValueDescription: flexFieldValueOptions.find(value => value.id === item.destinyValue)?.description || item.destinyValue
            }))
            item.children = newChildrens;
            item.childrens = newChildrens;
            return item;
        })
        setFilteredDataSource(filteredData);
    };

    function handleDelete(response) {
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/revenue/parameterization?ids=${selectedRowKeys}`,
        }, () => {
            loadTable();
            if (response) {
                Notification({
                    type: "success",
                    message: i18n.t("successfully_deleted")
                });
            }
        });
        selectedRowKeys.length = 0;
    }

    function handleModalVisibility(isVisible) {
        setIsModalVisible(isVisible);
    }

    function handleOpenModal(isNewParameterization: boolean) {
        if (isNewParameterization) {
            setIsNewParameterization(true);
            clearSelectedRowKeys();
        } else {
            setIsNewParameterization(false);
        }; setIsModalVisible(true);
    }

    function buildDataToExport(): any[] {
        const dataToExport = dataSource.flatMap(father => {
            let childrens = father.childrens.map(chil => {
                return {
                    destinyInterface: father.destinyInterface,
                    originCode: chil.originCode,
                    destinyValue: chil.destinyValue
                }
            })
            return childrens;
        });
        return dataToExport;
    }

    const tableColumns: ColumnsType<IParametizationData> = [
        {
            title: i18n.t("target_interface"),
            dataIndex: "destinyInterface",
            key: "destinyInterface",
        },
        {
            title: i18n.t("source_code"),
            dataIndex: "originCode",
            key: "originCode"
        },
        {
            title: i18n.t("target_value"),
            dataIndex: "destinyValue",
            key: "destinyValue",
            render: (destinyValue) => {
                const flexFieldValue = flexFieldValueOptions.find((value) => value.id === destinyValue);
                return flexFieldValue ? flexFieldValue.description : "";
            },
        }
    ]

    return (
        <div className="parameterization-from-to-page">

            <div className="title-page">
                <h1>Parametrizações</h1>
                <label>
                    <Icon icon="pajamas:information-o" color="#0065b3" width="14" height="14" />
                </label >
            </div>

            < div className="subtitle-page">
                <TopButtons
                    mainButtonTitle={i18n.t<string>("new_parameterization")}
                    handleNew={() => handleOpenModal(true)}
                    handleEdit={() => handleOpenModal(false)}
                    handleDelete={handleDelete}
                    isEditable={selectedRowKeys?.length === 1}
                    isDeletable={selectedRowKeys?.length > 0}
                    importExportOptions={{
                        exportGridData: () => handleExportGridData(buildDataToExport(), tableColumns, i18n.t<string>("parameterization")),
                        importProps: importProps
                    }}
                />

                <Search
                    placeholder={i18n.t<string>("search")}
                    onSearch={onSearch}
                    style={{ width: 373, marginRight: 120 }}
                />

                <ModalAddParameterization
                    filterEdit={filterEdit}
                    dataSource={dataSource}
                    isModalVisible={isModalVisible}
                    isNewParameterization={isNewParameterization}
                    handleVisibilityModal={handleModalVisibility}
                />
            </div>

            <div>
                {filteredDataSource !== null && filteredDataSource.length > 0 ? (
                    <TableParameterization
                        dataSource={filteredDataSource}
                        tableColumns={tableColumns}
                    />
                ) : (
                    <TableParameterization dataSource={dataSource}
                        tableColumns={tableColumns} />
                )}
            </div>

        </div>
    );
};