import { useState, useEffect, useRef } from "react";
import { FilterBase } from "./FilterBase";
import { IFilter } from "./IFilter";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

export function Filter(props: IFilter ) {
	const [hasPagination, setHasPagination] = useState(false);
	const [allFilterOptions, setAllFilterOptions] = useState([]);
	
	useEffect(() => {
		if(!props.datepicker && props.link) {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `${props.link}?locale={locale}&client={client}&user={user}&${props.params ? props.params : ''}`,
			}, handleAddFilter)
		} else {
			if (!props.data) return
			handleAddFilter(props.data)
		}
	}, [props.data])

	function handleAddFilter(data) {
		if (data.length > 100) {
			setHasPagination(true)
		}
		setAllFilterOptions(data)
	}

	return (
		<FilterBase
			id={props.id}
			value={props.value}
			setValue={props.setValue}
			title={props.title}
			type={props.type}
			searchPlaceholder={props.searchPlaceholder}
			hasSearch={props.hasSearch ? props.hasSearch : props.data?.length > 6 ? true : false}
			datepicker={props.datepicker}
			buttonStyles={props.buttonStyles}
			headerStyles={props.headerStyles}
			popoverStyles={props.popoverStyles}
			key={props.title}
			hasPagination={hasPagination}
			allFilterOptions={allFilterOptions}
			hasSelectNone={!!props.hasSelectNone}
			isFetching={props.isFetching !== undefined ? props.isFetching : false}
			footer={props.footer}
			defaultCheckboxSelected={props.defaultCheckboxSelected}
			disabled={props.disabled}
		/>
	)
}
