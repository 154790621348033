import { Icon } from "@iconify/react";
import { Button } from "antd";
import i18n from "util/base/i18n";

interface IOperationsSelectProps {
    onChooseOperation: (operation: string) => void;
    hasValueOperator?: boolean;
}

export function OperationsSelectFields({ onChooseOperation, hasValueOperator = false }: IOperationsSelectProps) {
    return (
        <div className="operations-select-container" style={{ display: "flex", flexDirection: "column" }}>
            <label className="gs-input-label">{i18n.t<string>("operations")}</label>
            <div className="operations-select-buttons">
                <Button icon={<Icon icon="akar-icons:plus" />} onClick={() => onChooseOperation("PLUS")} />
                <Button icon={<Icon icon="akar-icons:minus" />} onClick={() => onChooseOperation("LESS")} />
                <Button icon={<Icon icon="akar-icons:cross" />} onClick={() => onChooseOperation("MULTIPLY")} />
                <Button icon={<Icon icon="ic:baseline-divide" />} onClick={() => onChooseOperation("DIVIDER")} />
                <Button
                    icon={<p>(</p>}
                    style={{ textAlign: "center" }}
                    onClick={() => onChooseOperation("LEFT_PARENTHESIS")}
                />
                <Button
                    icon={<p>)</p>}
                    style={{ textAlign: "center" }}
                    onClick={() => onChooseOperation("RIGHT_PARENTHESIS")}
                />
                {hasValueOperator && (
                    <Button style={{ textAlign: "center" }} onClick={() => onChooseOperation("VALUE")}>
                        {i18n.t("value")}
                    </Button>
                )}
            </div>
        </div>
    );
}
