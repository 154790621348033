import * as React from "react";

import "../index.sass";

import {
	Button,
	DatePicker,
	Popover
} from "antd";

import locale_ptBR from "antd/es/date-picker/locale/pt_BR";
import locale_enUS from "antd/es/date-picker/locale/en_US";
import locale_esES from "antd/es/date-picker/locale/es_ES";

import { Icon } from "@iconify/react";

import i18n from "util/base/i18n";

import {
	IDateProps,
	IDateState
} from "../IDate";
import moment from "moment";
import InputDate from "../input/InputDate";

export default class PickerDate extends React.Component<IDateProps, IDateState> {

	constructor(props: IDateProps) {
		super(props);

		this.state = {
			value: props.value,
			originalValue: props.value,
			opened: false,
			languages: {
				pt_BR: locale_ptBR,
				en_US: locale_enUS,
				es_ES: locale_esES
			}
		};
	};

	onApplyDate = (newValue: moment.Moment) => {
		const { value } = this.state;

		this.setState({
			opened: false,
			value: newValue || value,
			originalValue: newValue || value
		});

		this.props.onChange(newValue || value);
	};

	onChangeDate = (value: moment.Moment) => {
		this.setState({ value });
	};

	onOpen = () => {
		const { disabled } = this.props;

		if (disabled) {
			return;
		}

		this.setState({ opened: true });
	};

	onClose = () => {
		const { disabled } = this.props;
		const { originalValue } = this.state;

		if (disabled) {
			return;
		}

		this.setState({
			opened: false,
			value: originalValue
		});
	};

	render() {
		const {
			value,
			opened,
			languages
		} = this.state;

		const {
			format,
			formatEdit,
			picker,
			showToday,
			disabled
		} = this.props;

		const datePicker: React.ReactElement = <>
			<div className="gs-date-picker-container">
				<DatePicker value={value as moment.Moment} onChange={this.onChangeDate.bind(this)} picker={picker} locale={languages[i18n.language]} format={format} allowClear={false} 
					open={true} showToday={!!showToday || true}
					renderExtraFooter={() => <>
						<Button className="button-filter-footer" onClick={this.onApplyDate.bind(this, null)} type="text">
							{i18n.t<string>("filter")}
						</Button>
					</>}
					getPopupContainer={(node: HTMLElement) => node.parentElement}
				/>
			</div>
		</>;

		return <>
			<Popover visible={opened} content={datePicker} trigger="click" placement="bottomRight" destroyTooltipOnHide={true} overlayClassName="gs-date-overlay-container">
				<div className={"gs-date-content " + (disabled ? "disabled" : "")}>
					<Icon className="gs-date-icon" icon="bx:calendar" />
					<InputDate value={value as moment.Moment} format={opened ? (formatEdit || format) : format} disabled={disabled} onChange={this.onChangeDate.bind(this)} onEnter={this.onApplyDate.bind(this)} onOpen={this.onOpen.bind(this)} onClose={this.onClose.bind(this)} />
				</div>
			</Popover>
		</>;
	};

}
