import { Icon } from "@iconify/react";
import { Button, Form, FormInstance, Input, Menu, Popconfirm, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { tableProps } from "util/props/props";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FunctionalityPermissions, Options } from "util/types/types";
import { CurrencyConversion, CurrencyConversionResponse, NewRateFormData, RateDataResponse, RateTableData } from "../IBudgetProjection";
import { NewCoversionModal } from "./NewCoversionModal";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { AddCurrencyRateModal } from "./AddCurrencyRateModal";

const { Search } = Input

interface ICurrenciesTab {
    currenciesOptions: Options[];
    userCurrencyDefaultId: number
    buttonTitle: string;
    functionalityPermissions: FunctionalityPermissions;
}

export function CurrenciesTab({
    currenciesOptions,
    userCurrencyDefaultId,
    buttonTitle,
    functionalityPermissions
}: ICurrenciesTab) {
    const [searchValue, setSearchValue] = useState('');
    const [searchMonth, setSearchMonth] = useState('');
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [isCurrencyRateModalOpen, setIsCurrencyRateModalOpen] = useState(false);
    const [form] = Form.useForm()
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRateData, setSelectedRateData] = useState<RateTableData>({} as RateTableData);
    const [isNewCoversionModalOpen, setIsNewCoversionModalOpen] = useState(false)
    const [isNewRate, setIsNewRate] = useState(false)
    const [isRateDataLoading, setIsRateDataLoading] = useState(false)
    const [isConversionListLoading, setIsConversionListLoading] = useState(true)
    const [rateTableData, setRateTableData] = useState<RateTableData[]>([])
    const [currenciesConversionList, setCurrenciesConversionList] = useState<CurrencyConversion[]>([])
    const currenciesMenuList = currenciesConversionList.filter((item) => item.label?.includes(searchValue));
    const { userInfo } = useUserContext()

    useEffect(() => {
        if (currenciesOptions.length === 0) return
        fecthConverionList();
    }, [currenciesOptions]);

    useEffect(() => {
        if (selectedMenuItem.length === 0) return
        setIsRateDataLoading(true)
        fecthCurrencyRateData()
    }, [selectedMenuItem])

    function handleOpenNewRateModal(isNew: boolean, item: any) {
        setIsNewRate(isNew);
        setIsCurrencyRateModalOpen(true);
        if (isNew) {
            form.resetFields()
        } else {
            form.setFieldsValue({ rate: item.rate })
        }

    }
    function fecthCurrencyRateData() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/currency-conversion-tax?scenario={scenario}&conversion=${selectedMenuItem}`,
        }, (data: RateDataResponse[]) => {
            setIsRateDataLoading(false)
            const updatedTableData = data.map(item => {
                return {
                    key: item.id,
                    conversionId: item.conversionId,
                    month: item.month,
                    rate: item.rate
                }
            }).sort((a, b) => {
                if (moment(a.month).isBefore(b.month)) return -1;
                return 1
            })
            setRateTableData(updatedTableData)
        }, () => setIsRateDataLoading(false))

    }
    function fecthConverionList() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/currency-conversion`,
        }, (data: CurrencyConversionResponse[]) => {
            const updatedCurrenciesConversionList: CurrencyConversion[] = data.reduce((acc: CurrencyConversion[], val) => {
                const index = acc.findIndex(item => item.key === val.originId)
                if (index < 0) {
                    acc.push({
                        key: val.originId,
                        label: currenciesOptions.find(cur => cur.value === val.originId)?.label ?? i18n.t("name_not_found"),
                        children: [
                            {
                                key: val.id,
                                label: currenciesOptions.find(cur => cur.value === val.destinationId)?.label ?? i18n.t("name_not_found"),
                                conversionId: val.id,
                                currencyId: val.destinationId,
                                parentId: val.originId
                            }
                        ]
                    })
                } else {
                    acc[index].children.push({
                        key: val.id,
                        label: currenciesOptions.find(cur => cur.value === val.destinationId)?.label ?? i18n.t("name_not_found"),
                        conversionId: val.id,
                        currencyId: val.destinationId,
                        parentId: val.originId
                    })
                }
                return acc
            }, [])
            setCurrenciesConversionList(updatedCurrenciesConversionList)
            setIsConversionListLoading(false)
            if (updatedCurrenciesConversionList.length > 0) {
                setSelectedMenuItem(updatedCurrenciesConversionList[0].children[0].key.toString())
            }
        }, () => setIsConversionListLoading(false))
    }

    function handleCreateNewConversion(data) {
        setIsConversionListLoading(true)
        setIsNewCoversionModalOpen(false)
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/budget-base/currency-conversion`,
            params: data
        }, () => {
            setIsConversionListLoading(false)
            fecthConverionList()
        })
    }

    function handleCreateCurrencyRate(data: NewRateFormData) {
        const period = data.period.map(date => date ? date.format('YYYY-MM') : null);
        const periodToSave = period[1] ? period : [period[0], period[0]];    

        const dataToSave = {
            scenarioId: userInfo.selection.scenarioId,
            conversionId: Number(selectedMenuItem),
            period: periodToSave,
            rate: Number(data.rate.replace(',', '.')),
            increment: data.increment && isNewRate ? Number(data.increment.replace(',', '.')) : 0,
        };
        form.resetFields()
        setIsCurrencyRateModalOpen(false);
        setIsRateDataLoading(true);
        setSelectedRowKeys([]);
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/budget-base/currency-conversion-tax`,
            params: dataToSave
        }, fecthCurrencyRateData);
    }

    function handleDeleteRate() {
        setIsRateDataLoading(true)
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/budget-base/currency-conversion-tax?ids=${selectedRowKeys}`,
        }, () => {
            setSelectedRowKeys([]);
            fecthCurrencyRateData()
        })
    }

    const columns: ColumnsType = [
        {
            title: i18n.t('date'),
            dataIndex: "month",
            key: "date",
            align: "center",
            render: (month) => {
                return moment(month).format('MMM/YYYY')
            }
        }, {
            title: i18n.t('rate'),
            dataIndex: "rate",
            key: "rate",
            align: "center",
        }
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRow) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRateData(selectedRow[0]);
        }
    };

    return (
        <div className="budget-projection-tab-content">
            <div className="budget-projection-sidebar-container">
                <Space
                    className="budget-projection-sidebar-space-content"
                    direction="vertical"
                    size={0}
                >
                    <Search
                        placeholder={i18n.t('search')}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{ width: '100%' }}
                    />
                    <Button
                        icon={<Icon icon="ic:round-plus" />}
                        onClick={() => setIsNewCoversionModalOpen(true)}
                        className="gs-secondary-button"
                    >
                        {i18n.t("unit_of_measurement.new_conversion")}
                    </Button>
                    {isConversionListLoading ?
                        <Spin
                            style={{
                                margin: '50px auto 60px',
                                width: '100%'
                            }}
                            spinning={isConversionListLoading}
                            tip={`${i18n.t('loading')}...`}
                        />
                        :
                        <Menu
                            onClick={({ item, key }) => setSelectedMenuItem(key)}
                            selectedKeys={[selectedMenuItem]}
                            mode="inline"
                            items={currenciesMenuList}
                            defaultOpenKeys={currenciesMenuList.length > 0 ? [currenciesMenuList[0].key.toString()] : null}
                        />
                    }
                </Space>
            </div>
            <div className="budget-projection-table-container">
                <div className="budget-projection-table-operations">
                    <div style={{ display: "flex" }}>
                        {functionalityPermissions.new &&
                            <Button
                                onClick={() => handleOpenNewRateModal(true, {})}
                                className="gs-main-button"
                                style={{ marginRight: "5px" }}
                            >
                                {buttonTitle}
                            </Button>
                        }
                        {functionalityPermissions.edit &&
                            <Button
                                className="budget-projection-table-operations-buttons edit"
                                onClick={() => handleOpenNewRateModal(false, selectedRateData)}
                                icon={<Icon icon="icomoon-free:pencil" />}
                                disabled={selectedRowKeys.length !== 1}
                            />
                        }
                        {functionalityPermissions.remove &&
                            <Popconfirm
                                placement="bottom"
                                overlayClassName="budget-projection-table-operations-buttons"
                                title={i18n.t<string>("delete_confirm_message")}
                                onConfirm={handleDeleteRate}
                                disabled={selectedRowKeys.length === 0}
                                okText={i18n.t<string>("yes")}
                                cancelText={i18n.t<string>("cancel")}
                                okButtonProps={{ danger: true, className: 'popconfirm-delete-button' }}
                            >
                                <Button
                                    disabled={selectedRowKeys.length === 0}
                                    className="budget-projection-table-operations-buttons trash"
                                    icon={<Icon icon="icomoon-free:bin" />}
                                />
                            </Popconfirm>
                        }
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                        <Search
                            placeholder={i18n.t('search')}
                            onChange={(e) => setSearchMonth(e.target.value)}
                            style={{ width: '100%' }}
                        />
                        {functionalityPermissions.export &&
                            <ImportExportMenu
                                exportGridData={() => handleExportGridData(rateTableData, columns, i18n.t("currencies"))}
                                buttonType="3dots"
                            />
                        }
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={rateTableData.filter(item => moment(item.month, 'YYYY-MM').format('MMM/YYYY').includes(searchMonth))}
                    loading={isRateDataLoading}
                    rowSelection={functionalityPermissions.edit || functionalityPermissions.remove ? rowSelection : null}
                    {...tableProps}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 12
                    }}
                />
            </div>
            <NewCoversionModal
                isNewRate={isNewRate}
                isOpen={isNewCoversionModalOpen}
                setIsOpen={setIsNewCoversionModalOpen}
                handleSubmit={handleCreateNewConversion}
                currenciesOptions={currenciesOptions}
                userCurrencyDefaultId={userCurrencyDefaultId}
                currenciesMenuList={currenciesMenuList}
            />
            <AddCurrencyRateModal
                form={form}
                isOpen={isCurrencyRateModalOpen}
                setIsOpen={setIsCurrencyRateModalOpen}
                handleSubmit={handleCreateCurrencyRate}
                isNew={isNewRate}
                disableMonthList={rateTableData.map(rate => rate.month)}
                selectedRateData={selectedRateData}
            />
        </div>
    )
}