import moment from "moment";
import { OrganizationBudgetDates } from "../../IControlPanel";
import { ModuleType } from "util/types/types";
import { Label } from "components/label/Label";
import { Button, DatePicker } from "antd";
import i18n from "util/base/i18n";

interface INewFiscalYearSelect {
    handleAddNewFiscalYear(): void;
    setIsSettingYear(b: boolean): void;
    selectedNewFiscalYear: moment.Moment;
    setSelectedNewFiscalYear(data: moment.Moment): void;
    moduleType: ModuleType;
    organization: OrganizationBudgetDates;
}

export function NewFiscalYearSelect({
    handleAddNewFiscalYear,
    selectedNewFiscalYear,
    setSelectedNewFiscalYear,
    setIsSettingYear,
    organization,
    moduleType
}: INewFiscalYearSelect) {
    
	function disableDate(current: moment.Moment) {
		let blockedYears: number[] = [];

		if (moduleType === ModuleType.EXPENSERESOURCES && organization.expensesResources) {
			blockedYears = organization.expensesResources.map(item => item.year);
		} else if (moduleType === ModuleType.REVENUE && organization.revenue) {
			blockedYears = organization.revenue.map(item => item.year);
		} else if (moduleType === ModuleType.PLANNING && organization.planning) {
			blockedYears = organization.planning.map(item => item.year);
		}

		return current && (current < moment().startOf('year') || blockedYears.includes(current.year()));
	}

    return (
        <div className="new-fiscal-year-select-container">
            <>
                <Label title={i18n.t('budget_year')}>
                    <DatePicker
                        format="YYYY"
                        className="gs-date-picker"
                        placeholder={i18n.t('select_a_year')}
                        allowClear={false}
                        style={{ width: 280 }}
                        picker="year"
                        value={selectedNewFiscalYear}
                        onChange={setSelectedNewFiscalYear}
                        disabledDate={disableDate}
                    />
                </Label>
                <Button
                    className="gs-main-button"
                    style={{ marginLeft: 20 }}
                    onClick={handleAddNewFiscalYear}
                >
                    {i18n.t('continue')}
                </Button>
                <Button
                    className="gs-secondary-button"
                    onClick={() => {
                        setIsSettingYear(false)
                        setSelectedNewFiscalYear(null)
                    }}
                >
                    {i18n.t('cancel')}
                </Button>
            </>
        </div>
    )
}