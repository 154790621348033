import { Icon } from "@iconify/react";
import { DatePicker, Input, Select } from "antd";
import moment from "moment";
import i18n from "util/base/i18n";
import { GenericSelection } from "../../flexField/IFlexField";
import { useAccountingLedgerContext } from "../context/AccountingLedgerContext";
import { useEffect } from "react";

const { Search } = Input;

export default function AccountingLedgerMultipleSearch({
    options,
}) {

    const { searchType, handleSearchType, inputValue, handleInputValue, onLoadAccountingLedger, period, isReorder,setCurrentPage } = useAccountingLedgerContext();
    const optionsSearch: GenericSelection[] = options.map(opt => ({
        label: `${i18n.t('search_by')} ${i18n.t(opt.i18nString)}`,
        value: opt.description,
    }));

    useEffect(() => {
        handleInputValue("");

    }, [searchType]);

    const handleSearch = (e) => {
        const value = e.target ? e.target.value : e;
        handleInputValue(value);
        if (value === "") {
            onLoadAccountingLedger(isReorder, false, false, undefined, undefined, undefined, 0, 20);
            setCurrentPage(1);
        };
    }
    const placeholder = options.find(opt => opt.description === searchType)?.i18nString ?? options[0].i18nString;

    const disabledDate = (current) => {
        return current.month() !== period[0].month() || current.year() !== period[0].year();
    };

    const renderSearchField = () => {
        switch (searchType) {
            case "creation_date":
                return (
                    <DatePicker
                        disabledDate={disabledDate}
                        picker="date"
                        className="replicate-scenario-month"
                        suffixIcon={<Icon icon="fa6-solid:calendar" />}
                        allowClear={true}
                        format="DD/MMM"
                        onChange={(value: any) => {
                            if (value) {
                                onLoadAccountingLedger(isReorder, false, true, moment(value, "DD/MMM/YYYY").format("YYYY-MM-DD"), undefined, undefined, 0, 20);
                                setCurrentPage(1);
                            } else {
                                onLoadAccountingLedger(isReorder, false, false, undefined, undefined, undefined, 0, 20);
                                setCurrentPage(1);
                            }
                        }}
                        defaultPickerValue={period[0]}
                        style={{ width: 200 }}
                    />
                );
            default:
                return (
                    <Search
                        className="search-field"
                        placeholder={`${i18n.t('search_by')} ${i18n.t(placeholder)}`}
                        onChange={handleSearch}
                        value={inputValue}
                        onPressEnter={() => {
                            onLoadAccountingLedger(isReorder, false, true, inputValue, undefined, undefined, 0, 20);
                            setCurrentPage(1);
                        }}
                        onSearch={() => {
                            onLoadAccountingLedger(isReorder, false, true, inputValue, undefined, undefined, 0, 20);
                            setCurrentPage(1);
                            
                        }}
                    />
                );
        }
    };

    return (
        <Input.Group
            compact
            className="accounting-ledger-multiple-search"
        >
            <Select
                className="select-search"
                value={searchType}
                onChange={handleSearchType}
                options={optionsSearch}
            />
            {renderSearchField()}
        </Input.Group>
    );
}

