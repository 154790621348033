import { Icon } from "@iconify/react";
import { Button, Checkbox, Divider, Dropdown, Input, Space, Tooltip } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import i18n from "util/base/i18n";

import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { Notification } from "components/notification/Notification";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { AttributeParameterizationContext } from "../context/AttributeParameterizationContext";
import LoadingChanges from "../../mainFlow/grid/header/LoadingChanges";
import { parseDate } from '../utils/parseDate';
import { CalculateParams, Item, TranslationKey } from "../IAttributeParameterization";
import { useUserContext } from "context/UserContext";

const { Search } = Input;

export function QueryBar() {
    const {
        handleRemoveRows,
        handleReplicateRules,
        handleSearch,
        searchTextTable,
        selectedRows,
        selectedAttribute,
        openNewRuleModal,
        openNewRuleModalDF,
        openNewRuleModalFixedMarketingCost,
        openEditRuleModal,
        openEditRuleModalDF,
        openEditRuleModalFixedMarketingCost,
        isLoading,
        hasSavedValue,
        period,
        lastAccomplishedPeriod
    } = useContext(AttributeParameterizationContext);

    const [calculating, setCalculating] = useState<boolean>(false);
    const [isCalculateDisabled, setIsCalculateDisabled] = useState<boolean>(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const translations: TranslationKey[] = [
        { label: i18n.t<string>("fixed_expense"), translationKey: "FIXED_EXPENSE" },
        { label: i18n.t<string>("sale_deduction"), translationKey: "SALE_DEDUCTION" },
        { label: i18n.t<string>("variable_expenses"), translationKey: "VARIABLE_EXPENSES" },
        { label: i18n.t<string>("tribute"), translationKey: "TRIBUTE" },
        { label: i18n.t<string>("marketing_cost_variable"), translationKey: "MARKETING_COST_VARIABLE" },
        { label: i18n.t<string>("marketing_cost_fixed"), translationKey: "MARKETING_COST_FIXED" },
    ];

    const handleSelectAttributesToCalc = (
        translationKey: string
    ) => {
        setAttributesSelectedToCalc(
            attributesSelectedToCalc.includes(translationKey) ?
                attributesSelectedToCalc.filter(item => item !== translationKey) :
                [...attributesSelectedToCalc, translationKey]
        );
    };

    const [attributesSelectedToCalc, setAttributesSelectedToCalc] = useState<string[]>(
        translations.map(translation => translation.translationKey.toUpperCase())
    );

    const createItem = (key: string, labelText: string, translationKey: string): Item => ({
        key,
        label:
            <Checkbox
                checked={attributesSelectedToCalc.includes(translationKey)}
                onClick={() => handleSelectAttributesToCalc(translationKey.toUpperCase())}>
                <span>
                    {labelText}
                </span>
            </Checkbox>,
        translationKey,
    });

    const generateItems = (translations: TranslationKey[]): Item[] => {
        return translations.map((translation, index) =>
            createItem((index + 1).toString(), translation.label, translation.translationKey)
        );
    };

    const attributes: Item[] = generateItems(translations);

    const { userInfo } = useUserContext();

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/revenue/grouper/attribute/check-status?client={client}&user={user}"
        }, setIsCalculateDisabled)
    }, [])

    const importProps: IImportModal[] = [
        {
            importUrl: "/revenue/realized-by-attribute/import",
            templateUrl: "/revenue/realized-by-attribute/import/template?user={user}&client={client}&locale={locale}",
            type: "excel",
            title: i18n.t("import"),
        },
        {
            importUrl: "/revenue/grouper/attribute/rule/import",
            templateUrl: "/revenue/grouper/attribute/rule/template?user={user}&client={client}&locale={locale}",
            type: 'excel',
            title: i18n.t("import_rules")
        },
        {
            importUrl: "/revenue/grouper/attribute/expenses-attribute/import",
            templateUrl: "/revenue/grouper/attribute/expenses-attribute/template?user={user}&client={client}&locale={locale}",
            type: 'excel',
            title: i18n.t("import_tax_atributte_expenses")
        },
        {
            importUrl: "/revenue/grouper/attribute/revenues-attribute/import",
            templateUrl: "/revenue/grouper/attribute/revenues-attribute/template?user={user}&client={client}&locale={locale}",
            type: 'excel',
            title: i18n.t("import_tax_atributte_revenue")
        }
    ];

    const openRuleModalByGrouper = useCallback(() => {
        if (selectedAttribute?.grouperName === "FIXED_EXPENSE") {
            openNewRuleModalDF();
        } else if (selectedAttribute?.grouperName === "MARKETING_COST_FIXED") {
            openNewRuleModalFixedMarketingCost();
        } else {
            openNewRuleModal();
        }
    }, [selectedAttribute, openNewRuleModal, openNewRuleModalDF, openNewRuleModalFixedMarketingCost]);

    function handleCalculate() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/revenue/grouper/attribute/check-status?client={client}&user={user}"
        }, (res: boolean) => {
            if (!res) {
                const startPeriod: string = period[1].format("YYYY-MM") + "-01";
                const endPeriod: string = period[0].format("YYYY-MM") + "-01";

                const params: CalculateParams = {
                    groupers: attributesSelectedToCalc,
                    startPeriod: startPeriod,
                    endPeriod: endPeriod,
                    clientId: userInfo.clientId,
                    userId: userInfo.id,
                    localeId: userInfo.localeId,
                    scenarioId: userInfo.selection.scenarioId,
                    organizationId: userInfo.selection.organizationId,
                    businessUnitId: userInfo.selection.businessUnitId
                };

                ServiceCaller.doRequest(
                    {
                        type: RequestType.POST,
                        url: `/revenue/grouper/attribute/calculate`,
                        params: params,
                    },
                    (res: boolean) => {
                        Notification({
                            type: "info",
                            message: i18n.t<string>("calculation_on_progress"),
                            duration: 5,
                        });
                    },
                    (err: ErrorRequest) => {
                        handleErrorRequest(err);
                    }
                );
            } else {
                Notification({
                    type: "info",
                    message: i18n.t<string>("calculation_on_progress"),
                    duration: 5,
                });
            }
        })
    }

    let editButtonOnClick;

    if (selectedAttribute?.grouperName === "FIXED_EXPENSE") {
        editButtonOnClick = openEditRuleModalDF;
    } else if (selectedAttribute?.grouperName === "MARKETING_COST_FIXED") {
        editButtonOnClick = openEditRuleModalFixedMarketingCost;
    } else {
        editButtonOnClick = openEditRuleModal;
    }

    const handleOpenChange = (flag: boolean) => {
        setIsDropdownOpen(flag);
    };

    return (
        <div id="attribute_parameterization-content-query-bar">
            <div id="attribute_parameterization-option-buttons">
                <Button
                    id="attribute_parameterization-new_rule_button"
                    type="primary"
                    shape="round"
                    icon={<Icon icon="akar-icons:circle-plus-fill" />}
                    onClick={openRuleModalByGrouper}
                    disabled={!selectedAttribute}
                >
                    {i18n.t<string>("new_rule")}
                </Button>
                <Tooltip title={i18n.t<string>("edit")} color={"#424242"}>
                    <Button
                        className="top-tool-buttons edit"
                        icon={<Icon icon="icomoon-free:pencil" />}
                        style={{
                            border: 0,
                            background: "transparent",
                            outline: "none",
                            boxShadow: "none",
                        }}
                        disabled={selectedRows.length !== 1}
                        onClick={editButtonOnClick}
                    />
                </Tooltip>
                <Tooltip title={i18n.t<string>("delete")} color={"#424242"}>
                    <Button
                        className="top-tool-buttons trash"
                        icon={<Icon icon="icomoon-free:bin" />}
                        style={{
                            border: 0,
                            background: "transparent",
                            outline: "none",
                            boxShadow: "none",
                        }}
                        disabled={selectedRows.length < 1}
                        onClick={handleRemoveRows}
                    />
                </Tooltip>
                <Tooltip
                    title={i18n.t<string>("collaborators.additional_fields.configuration.replicable")}
                    color={"#424242"}
                >
                    <Button
                        icon={<Icon icon="ps:copy" />}
                        style={{
                            border: 0,
                            background: "none",
                            outline: "none",
                            boxShadow: "none",
                        }}
                        disabled={selectedRows.length < 1}
                        onClick={handleReplicateRules}
                    />
                </Tooltip>
                <Tooltip title={i18n.t<string>("calculate")} color={"#424242"}>
                    <Dropdown menu={{ items: attributes }} placement="bottomLeft" open={isDropdownOpen} onOpenChange={handleOpenChange} dropdownRender={
                        (menu) => (
                            <div>
                                {menu}
                                <Divider style={{ margin: 0 }} />
                                <Space style={{ padding: 8 }}>
                                    <Button
                                        type="primary"
                                        disabled={!attributesSelectedToCalc.length}
                                        onClick={() => handleCalculate()}
                                        size="small"
                                    >
                                        {i18n.t<string>("calculate")}
                                    </Button>
                                </Space>
                            </div>
                        )
                    }>
                        <Button
                            icon={<Icon icon="material-symbols:calculate-outline" />}
                            style={{
                                border: 0,
                                background: "none",
                                outline: "none",
                                boxShadow: "none",
                            }}>
                        </Button>
                    </Dropdown>
                </Tooltip>
                <Tooltip
                    title={i18n.t<string>("last_realized_period")}
                    color='#424242'
                >
                    <p id="lastRealizedMonth" hidden={!lastAccomplishedPeriod}>
                        {i18n.t<string>("accomplished")}: {parseDate(lastAccomplishedPeriod)}
                    </p>
                </Tooltip>
            </div>

            <div
                style={{
                    display: "flex",
                    gap: 5,
                }}
            >
                <div
                    style={{
                        marginRight: 10,
                        paddingTop: 5,
                    }}
                >
                    <LoadingChanges isLoading={isLoading} hasSavedValue={hasSavedValue} />
                </div>
                <Search
                    placeholder={i18n.t<string>("search")}
                    onChange={handleSearch}
                    value={searchTextTable}
                    style={{ width: 264 }}
                    disabled={!selectedAttribute}
                />
                <ImportExportMenu
                    importProps={importProps}
                    buttonType="3dots"
                    iconStyles={{
                        fontSize: 27,
                        color: "#424242",
                        cursor: "pointer",
                    }}
                />
            </div>
        </div>
    );
}
