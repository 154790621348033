import i18n from "util/base/i18n";
import { RevenueItemTable } from "../IRevenue";

export function buildChildrenGrid(parent: any, data: any, periodStart: moment.Moment, periodEnd: moment.Moment, parentIndex: number[]) {
    let itens = [];
    data.forEach((element: any, index: number) => {
        let keyFlex: string;
		const indexKey: string = `-${parentIndex.join("-")}-${index}`;

        if (element.flexFieldValue && element.flexFieldValue.name) {
            keyFlex = element.flexFieldValue.name.replaceAll(" ", "") + "-" + element.flexFieldValue.id + "-" + element.currency.name.replaceAll(" ", "") + "-" + element.id;

            if (element.parentId) {
                keyFlex += ("-" + element.parentId);
            }
        } else {
            keyFlex = element.measuringUnit.id + "-" + element.currency.id
        }

		keyFlex += indexKey;

        let lineBeforeCoin = "";
        if (element.measuringUnit && element.measuringUnit.name) {
            lineBeforeCoin = "lineBeforeCoin-" + element.measuringUnit.name.replaceAll(" ", "") + "-" + element.currency.id + "-" + indexKey;
            let lineCreateCoinBeforeCoin = {
                id: element.id ? element.id : null,
                fieldId: element.fieldId ? element.fieldId : null,
                key: lineBeforeCoin,
                name: "",
                value: element.currencyId,
                flexFieldValueId: element.flexFieldValue ? element.flexFieldValue.id : null,
                measuringUnitId: element.measuringUnit ? element.measuringUnit.id : null,
                measuringUnitName: element.measuringUnit ? element.measuringUnit.name : null,
                currencyName: element.currency ? element.currency.name : null,
                currencyId: element.currency ? element.currency.id : null,
                dates: element.dates,
                total: element.total ? element.total : null,
                hasCheckbox: false,
                isoCode: element.currency ? element.currency.isoCode : null,
                ids: element.ids ? element.ids : null,
                parentId: element.parentId ? element.parentId : null,
                flexFieldValueIds: parent.flexFieldValueIds
            };

            itens.push(lineCreateCoinBeforeCoin);
        }

        let flex: RevenueItemTable = {
            id: element.id ? element.id : null,
            fieldId: element.fieldId ? element.fieldId : null,
            key: keyFlex,
            keyCreationCoin: lineBeforeCoin ? lineBeforeCoin : null,
            name: element.flexFieldValue ? element.flexFieldValue.name : "",
            value: element.currencyId,
            flexFieldValueId: element.flexFieldValue ? element.flexFieldValue.id : null,
            measuringUnitId: element.measuringUnit ? element.measuringUnit.id : null,
            measuringUnitName: element.measuringUnit ? element.measuringUnit.name : null,
            currencyName: element.currency ? element.currency.name : null,
            currencyId: element.currency ? element.currency.id : null,
            dates: element.dates,
            total: element.total ? element.total : null,
            hasCheckbox: lineBeforeCoin ? true : false,
            isoCode: element.currency ? element.currency.isoCode : null,
            symbol: element.measuringUnit ? element.measuringUnit.symbol : null,
            ids: element.ids ? element.ids : null,
            parentId: element.parentId ? element.parentId : null,
            flexFieldValueIds: element.flexFieldValueIds,
            children: []
        };

        if (element.childrens && !element.measuringUnit) {
            flex.hasCheckbox = false;
            flex.children = buildChildrenGrid(element, element.childrens, periodStart, periodEnd, [...parentIndex, index]);
        } else {
            if (element.childrens) {
                element.childrens.forEach(captionRow => {
                    let keyItem: string = captionRow.templateField.caption + "-"
                        + captionRow.templateField.id + "-"
                        + captionRow.templateField.type + "-"
                        + captionRow.total + "-"
                        + element.id + "-"
                        + element.measuringUnit.id + "-"
                        + element.currency.id + "-"
                        + element.measuringUnit.id + "-"
                        + element.fieldId

                    if (element.parentId) {
                        keyItem += ("-" + element.parentId);
                    }

					keyItem += indexKey;

                    let newConst: RevenueItemTable = {
                        key: keyItem,
                        name: i18n.t<string>(captionRow.templateField.caption),
                        currencyId: element.currency ? element.currency.id : null,
                        currencyName: element.currency ? element.currency.name : null,
                        fieldValueId: element.id,
                        measuringUnitId: element.measuringUnit ? element.measuringUnit.id : null,
                        total: captionRow.total,
                        isoCode: element.currency ? element.currency.isoCode : null,
                        hasCheckbox: false,
                        dates: captionRow.dates,
                        formatType: captionRow.templateField.formatType,
                        type: captionRow.templateField.type,
                        idTemplate: captionRow.templateField.id,
                        flexFieldValueIds: parent.flexFieldValueIds
                    };

                    flex.children.push(newConst);
                });
            }
        }

        itens.push(flex);
    });

    return itens;
};

export default buildChildrenGrid;