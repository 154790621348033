import { Form, FormInstance, Input, InputRef, Table, Tooltip } from "antd";
import { EditableRowProps } from "module/investment/pages/investment/Detail/IDetail";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import i18n from "util/base/i18n";
import { createTableColumns } from "./util/createTableColumns";
import { IConsolidationEliminationTable } from "../../../IConsolidation";
import HeaderGrid from "../../components/HeaderGrid";
import { Icon } from "@iconify/react";
import { ConsolidationEliminationContext } from "../../context/ConsolidationEliminationContext";
import { ColumnsType } from "antd/lib/table";

export default function ConsolidationEliminationTable({
    isFetching,
    treeGridData,
    handleSaveOrReplicateRate,
    setIsLoading,
    filteredTreeGrid,
}: IConsolidationEliminationTable) {
    const [columnsGrid, setColumnsGrid] = useState<any[]>([]);
	const EditableContext = createContext<FormInstance<any> | null>(null);
	const {
		setTitleToExport,
		setCurrentTableData,
		setCurrentColumns
	} = useContext(ConsolidationEliminationContext);

	useEffect(() => {
		setTitleToExport(i18n.t<string>("participation_rate"));
	}, [])

	useEffect(() => {
		if (!treeGridData.length) return;

		const newColumns: ColumnsType<any> = createTableColumns(treeGridData);
		setCurrentColumns(newColumns);
		setColumnsGrid(newColumns);
		setCurrentTableData(treeGridData);
	}, [treeGridData]);

    const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        ...restProps
    }) => {

        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                const enteredValue = values[dataIndex].toString().replace(',', '.');
                const numericValue = Number(enteredValue);

                toggleEdit();

                if (isNaN(numericValue) || numericValue === record?.[dataIndex]) return;

                setIsLoading(true);
                handleSaveOrReplicateRate({ ...record }, numericValue, dataIndex);
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div className="gs-table-input-editable-cell">
                    <div
                        className="editable-cell-value-wrap"
                        onClick={toggleEdit}
                    >
                        {children}
                    </div>
                    <div className="gs-table-replicate-buttons-container right">
                        <Tooltip title={i18n.t("replicate_to_next_months")}>
                            <Icon
                                onClick={() => {
                                    setIsLoading(true);
                                    handleSaveOrReplicateRate({ ...record }, record[dataIndex], "", true);
                                }}
                                icon="material-symbols:content-copy"
                                style={{
                                    marginLeft: "auto",
                                    boxShadow: "none",
                                    color: "#A6A7A7",
                                    background: "transparent",
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
            );
        }

        return <td className={`${record?.isEdited ? "edited-value" : { ...restProps }}`}>{childNode} </td>;
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    return (
		<div className="consolidation-elimination-table">
            <Table
                className='gs-table'
                columns={columnsGrid}
                dataSource={filteredTreeGrid}
                pagination={false}
                bordered={true}
                components={components}
                loading={{
                    spinning: isFetching,
                    tip: i18n.t<string>("loading")
                }}
                scroll={{ x: 'max-content', y: 500 }}
            />
        </div>
    )
}
