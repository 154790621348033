import { MessageType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ROOT_PATH } from "util/rootPath";

export function handleRedirect(url: string, isBudgetModule: boolean, navigate: Function) {
    if(window.location.href.split('/').pop() === url.split('/').pop()) {
        return
    }
    if ((url.includes('/mf/') || url.includes('module')) && isBudgetModule) {
        ServiceCaller.doSendMessage({
            messageType: MessageType.REPLACE_URL,
            params: {
                url
            }
        });
        navigate(url.replace(ROOT_PATH, '').replace('/mf', ''))
    } else {
        ServiceCaller.doSendMessage({
            messageType: MessageType.REDIRECT_TO,
            params: {
                url
            }
        });
    }
}