import { Col, Form, Input, Modal, Row } from "antd";
import i18n from "util/base/i18n";
import { validateFormField } from "util/functions/validateFormField";
import { Indexer } from "../IBudgetProjection";

interface INewIndexerModal {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    handleSubmit: (a: any) => void;
    indexerList: Indexer[];
    selectedMenuItem: string;
    form: any;
    isNewIndexer: boolean;
}

export function NewIndexerModal({ isOpen, setIsOpen, handleSubmit, indexerList, selectedMenuItem, form, isNewIndexer }: INewIndexerModal) {
    return (
        <Modal
            title={i18n.t("new_indexer")}
            open={isOpen}
            onCancel={() => {
                setIsOpen(false)
                form.resetFields();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        handleSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okText={i18n.t<string>("save")}
            cancelText={i18n.t<string>("cancel")}
            okButtonProps={{
                htmlType: "submit",
                form: "new-indexer-form"
            }}
        >
            <Form
                name="new-indexer-form"
                form={form}
                layout="vertical"
                requiredMark={false}
            >
                <Row style={{ width: '100%' }} gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={i18n.t("external_code")}
                            name="externalCode"
                            rules={[{ required: true, message: i18n.t<string>("required_field") },
                            () => ({
                                validator(_, value: string) {
                                    const filteredList = isNewIndexer ? [...indexerList] : indexerList.filter(item => item.key.toString() !== selectedMenuItem);
                                    return validateFormField(value, 'externalCode', filteredList, filteredList, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedMenuItem);
                                },
                            })]}
                        >
                            <Input tabIndex={1} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={i18n.t("description")}
                            name="description"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Input tabIndex={2} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}