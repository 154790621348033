import { useEffect, useState } from 'react';
import i18n from 'util/base/i18n';

import { Modal, Button, Input, Select } from 'antd';
import { Icon } from '@iconify/react';

import { ServiceCaller } from 'util/service/ServiceCaller';
import { MessageType, RequestType } from 'util/service/IServiceCaller';

import { useUserContext } from 'context/UserContext';
import { scenarioBroadcast } from 'util/BroadcastChannels';

import { LookupOption } from './IStepModal';
import './stepModalStyles.sass'

import { DefaultOptionType } from 'antd/lib/select';

import StringUtil from 'util/StringUtil';

export function StepModal() {
  const { userInfo, isBudgetModule } = useUserContext();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [password, setPassword] = useState<string>(undefined);
  const [confirmPassword, setConfirmPassword] = useState<string>(undefined);

  const [scenarioArray, setScenarioArray] = useState<LookupOption[]>([]);
  const [organizationArray, setOrganizationArray] = useState<LookupOption[]>([]);
  const [businessUnitArray, setBusinessUnitArray] = useState<LookupOption[]>([]);

  const [currentScenario, setCurrentScenario] = useState(null)
  const [isLoadingScenarioArray, setIsLoadingScenarioArray] = useState(true);

  const [currentOrganization, setCurrentOrganization] = useState(null)
  const [isLoadingOrganizationArray, setIsLoadingOrganizationArray] = useState(true);

  const [currentBusinessUnit, setCurrentBusinessUnit] = useState(null)
  const [isLoadingBusinessUnitArray, setIsLoadingBusinessUnitArray] = useState(true);

  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNextButtonAble, setIsNextButtonAble] = useState<boolean>(false);
  const [isLeaseProjection, setIsLeaseProjection] = useState<boolean>(false);
  const [showPasswordStep, setShowPasswordStep] = useState<boolean>(false);

  useEffect(() => {
    if (filteredSteps[currentStep].preStepAction)
      filteredSteps[currentStep].preStepAction()
  }, [currentStep])

  useEffect(() => {
    ServiceCaller.doRequest({
      type: RequestType.GET,
      url: "/showPasswordStep",
      useProxy: false,
    }, onShowPasswordStep.bind(this));
  }, [])

  function onShowPasswordStep(data) {
    setShowPasswordStep(data)
  }

  useEffect(() => {
    if (password === '' || confirmPassword === '') {
      setError(i18n.t<string>('please_provide_password'))
      setIsNextButtonAble(false)
    } else if (password !== confirmPassword && (confirmPassword !== undefined && password !== undefined)) {
      setError(i18n.t<string>('passwords_do_not_match'))
      setIsNextButtonAble(false)
    } else if (confirmPassword !== undefined && password !== undefined) {
      setIsNextButtonAble(true)
      setError(null)
    }
  }, [password, confirmPassword])

  //step 1
  function handleChangePassword() {
    ServiceCaller.doRequest({
      type: RequestType.POST,
      url: "/myAccount/changePasswordStep",
      useProxy: false,
      params: JSON.stringify({ password: password })
    }, onChangePassword.bind(this));
  }

  function onChangePassword(res) {
    if (res.success) {
      setError('')
      nextSuccess()
    } else {
      setError(res.message)
      nextError()
    }
  }

  //step 2
  function handleEnvironment(isLeaseProjection) {
    setIsLeaseProjection(isLeaseProjection)

    const environmentBtns = document.querySelectorAll('.environment-btn');
    const activeIndex = isLeaseProjection ? 0 : 1;
    const inactiveIndex = isLeaseProjection ? 1 : 0;

    environmentBtns[activeIndex].querySelector('.icon-wrapper').classList.add('border-active');
    environmentBtns[activeIndex].querySelector('.btn-label').classList.add('border-active');

    environmentBtns[inactiveIndex].querySelector('.icon-wrapper').classList.remove('border-active');
    environmentBtns[inactiveIndex].querySelector('.btn-label').classList.remove('border-active');

    setIsNextButtonAble(true);
  }

  function setLeaseProjection() {
    ServiceCaller.doSendMessage({
      messageType: MessageType.SET_LEASE_ENVIRONMENT,
      params: { isLeaseProjection }
    });

    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.POST,
      url: '/setLeaseProjection',
      params: isLeaseProjection,
    }, () => nextSuccess());
  }

  //step 3
  function getScenarioArray() {
    ServiceCaller.doRequest({
      type: RequestType.GET,
      url: "/business/ScenarioArray",
      useProxy: false
    }, onGetScenarioArray.bind(this));
  }

  function onGetScenarioArray(data) {
    setScenarioArray(data)
    setIsLoadingScenarioArray(false)
  }

  function onChangeScenario(option: DefaultOptionType) {
    setCurrentScenario({ key: option.key, value: String(option.value) })
    setOrganizationArray([])
    setBusinessUnitArray([])
    setCurrentOrganization(null)
    setCurrentBusinessUnit(null)
    getOrganizationArray(option.key)
  }

  function getOrganizationArray(scenarioId) {
    ServiceCaller.doRequest({
      type: RequestType.GET,
      url: `/business/OrganizationArrayFromScenary?scenarioId=${scenarioId}&financingCalc=false`,
      useProxy: false
    }, onGetOrganizationArray);
  };

  function onGetOrganizationArray(data) {
    setOrganizationArray(data)
    setIsLoadingOrganizationArray(false)
  }

  function onChangeOrganization(option: DefaultOptionType) {
    setCurrentOrganization({ key: option.key, value: String(option.value) })
    setBusinessUnitArray([])
    getBusinessUnit(option.key)
  }

  function getBusinessUnit(organizationId) {
    ServiceCaller.doRequest({
      type: RequestType.POST,
      url: `/business/jsonBusinessArray`,
      params: JSON.stringify({ scenarioId: currentScenario.key, organizationId: organizationId }),
      useProxy: false
    }, onGetBusinessUnit.bind(this));
  };

  function onGetBusinessUnit(data) {
    setBusinessUnitArray(data)
    setIsLoadingBusinessUnitArray(false)
  }

  function onChangeBusinessUnit(option: DefaultOptionType) {
    setCurrentBusinessUnit({ key: option.key, value: String(option.value) })
    setIsNextButtonAble(true)
  }

  const steps = [
    {
      title: i18n.t<string>('welcome_message'),
      subtitle: i18n.t<string>('change_password_policy'),
      image: <img src="/assets/images/change_password_step.png" alt={i18n.t<string>('change_password')} />,
      onNext: () => handleChangePassword(),
      content: (
        <div className='pass-inputs'>
          <label className="gs-input-label">
            {i18n.t<string>('new_password')}
            <Input value={password} onChange={(e) => setPassword(e.target.value)} type='password' />
          </label>
          <label className="gs-input-label">
            {i18n.t<string>('confirm_password')}
            <Input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} type='password' />
          </label>
        </div>
      ),
    },
    {
      title: i18n.t<string>('select_environment_access'),
      image: <img src="/assets/images/choose_environment_step.png" alt={i18n.t<string>('set_environment')} />,
      onNext: () => setLeaseProjection(),
      content: (
        <div className="environment-btns">
          <div style={{ width: '100%' }} className="environment-btn" onClick={() => handleEnvironment(true)}>
            <div className="icon-wrapper">
              <Icon color="#0065B3" width={27} icon="mdi:world" />
            </div>
            <label className="btn-label">{i18n.t<string>('planning')}</label>
          </div>
          <div style={{ width: '100%' }} className="environment-btn" onClick={() => handleEnvironment(false)}>
            <div className="icon-wrapper">
              <Icon color="#0065B3" width={27} icon="fa6-solid:chart-column" />
            </div>
            <label className="btn-label">{i18n.t<string>('operational')}</label>
          </div>
        </div>
      ),
    },
    {
      title: i18n.t<string>('select_scenario_company_unit'),
      image: <img src="/assets/images/choose_scenario_step.png" alt={i18n.t<string>('select_scenario_company_unit')} />,
      onNext: () => saveAllStructure(),
      preStepAction: () => getScenarioArray(),
      content: (
        <div className='lookup-wrapper'>
          <div className="scenario-lookup">
            <span className="label">{i18n.t<string>('scenery')}</span>
            <Select
              showSearch
              style={{ width: 590 }}
              value={currentScenario}
              loading={isLoadingScenarioArray}
              placeholder={i18n.t<string>('enter_scenario')}
              notFoundContent={isLoadingScenarioArray ? null : undefined}
              onChange={(value, option) => onChangeScenario(option as any)}
              filterOption={(input, option) =>
                StringUtil.getWithoutSpecials(option?.props?.children as string, true).toLowerCase().indexOf(
                  StringUtil.getWithoutSpecials(input, true).toLowerCase()
                ) >= 0
              }
            >
              {scenarioArray.map((item) => (
                <Select.Option key={item.id} value={item.description}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="organization-lookup">
            <span className="label">{i18n.t<string>('organization')}</span>
            <Select
              showSearch
              style={{ width: 590 }}
              value={currentOrganization}
              loading={isLoadingOrganizationArray}
              placeholder={i18n.t<string>('enter_organization')}
              notFoundContent={isLoadingOrganizationArray ? null : undefined}
              onChange={(value, option) => onChangeOrganization(option as any)}
              filterOption={(input, option) =>
                StringUtil.getWithoutSpecials(option?.props?.children as string, true).toLowerCase().indexOf(
                  StringUtil.getWithoutSpecials(input, true).toLowerCase()
                ) >= 0
              }
            >
              {organizationArray.map((item) => (
                <Select.Option key={item.id} value={item.description}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="business-unit-lookup">
            <span className="label">{i18n.t<string>('operating_unit')}</span>
            <Select
              showSearch
              style={{ width: 590 }}
              value={currentBusinessUnit}
              loading={isLoadingBusinessUnitArray}
              notFoundContent={isLoadingBusinessUnitArray ? null : undefined}
              placeholder={i18n.t<string>('enter_business_unit')}
              onChange={(value, option) => onChangeBusinessUnit(option as any)}
              filterOption={(input, option) =>
                StringUtil.getWithoutSpecials(option?.props?.children as string, true).toLowerCase().indexOf(
                  StringUtil.getWithoutSpecials(input, true).toLowerCase()
                ) >= 0
              }
            >
              {businessUnitArray.map((item) => (
                <Select.Option key={item.id} value={item.description}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      ),
    },
  ];
  const filteredSteps = showPasswordStep ? steps : steps.slice(1);

  function handleNext() {
    filteredSteps[currentStep].onNext()
    setIsLoading(true)
    setIsNextButtonAble(false)
  };

  function nextSuccess() {
    setCurrentStep(currentStep + 1);
    setIsLoading(false)
  }

  function nextError() {
    setIsLoading(false)
  }

  const saveAllStructure = () => {
    setIsLoading(true)

    ServiceCaller.doRequest({
      type: RequestType.POST,
      url: "/business/allStructure",
      params: {
        scenarioSelectedId: currentScenario.key,
        organizationSelectedId: currentOrganization.key,
        businessUnitSelectedId: currentBusinessUnit.key,
        yearSelected: userInfo.selection.year
      },
      useProxy: false,
    }, onSaveAllStructure.bind(this));
  };

  function onSaveAllStructure() {
    scenarioBroadcast.postMessage('scenario-changed')

    ServiceCaller.doSendMessage({
      messageType: MessageType.REFRESH_SCREEN
    });
  };

  if (isBudgetModule) {
    return (
      <Modal
        closable={false}
        className='step-modal'
        width='957px'
        visible={false}
        footer={[
          <Button key="next" loading={isLoading} type="primary" onClick={handleNext} disabled={!isNextButtonAble}>
            {currentStep === filteredSteps.length - 1 ? i18n.t<string>('finish') : i18n.t<string>('next')}
          </Button>,
        ]}
      >
        <div className="step-wrapper">
          <div className='step-image'>
            <div style={{ width: '220px', height: '214px' }}>
              {filteredSteps[currentStep].image}
            </div>
            <div className="custom-steps">
              {filteredSteps.map((step, index) => (
                <div key={index} className={`custom-step ${index === currentStep ? 'active' : ''}`} />
              ))}
            </div>
          </div>
          <div className="step-content">
            <p className='step-title'>{filteredSteps[currentStep].title}</p>
            <p className='step-subtitle'>{filteredSteps[currentStep]?.subtitle}</p>
            {filteredSteps[currentStep].content}
            {error !== '' && <label style={{ color: 'red' }}>{error}</label>}
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <div id='step-modal-dialog' className='step-modal-wfn'>
        <div className="step-wrapper">
          <div className='step-image'>
            <div style={{ width: '220px', height: '214px' }}>
              {filteredSteps[currentStep].image}
            </div>
            <div className="custom-steps">
              {filteredSteps.map((step, index) => (
                <div key={index} className={`custom-step ${index === currentStep ? 'active' : ''}`} />
              ))}
            </div>
          </div>
          <div className="step-content">
            <p className='step-title'>{filteredSteps[currentStep].title}</p>
            <p className='step-subtitle'>{filteredSteps[currentStep]?.subtitle}</p>
            {filteredSteps[currentStep].content}
            {error !== '' && <label style={{ color: 'red' }}>{error}</label>}
            <Button key="next" loading={isLoading} type="primary" onClick={handleNext} disabled={!isNextButtonAble}>
              {currentStep === filteredSteps.length - 1 ? i18n.t<string>('finish') : i18n.t<string>('next')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
};


