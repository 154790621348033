import { Row, Col, Spin } from "antd";
import { IPanelHeaderProps } from "../IBudgetIntegrations";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";
import { LoadingOutlined } from "@ant-design/icons";

export default function PanelHeader({
	title,
	runningProcesses,
	isLoading
}: IPanelHeaderProps) {
	const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
	return (
		<Row >
			<Col span={12}>
				<Row gutter={8}>
					<Col >
						<Icon icon="mingcute:sitemap-fill" />
					</Col>
					<Col>
						{title}
						{isLoading &&
							<Spin indicator={antIcon} style={{ marginLeft: '15px', color: '#FFF' }} size="small" />
						}
					</Col>
				</Row>
			</Col>
			{runningProcesses > 0 &&
				<Col className="running-processes" span={12}>
					<p className="gs-tag blue">
						{`${runningProcesses} ${i18n.t("running_processes")}`}
					</p>
				</Col>
			}
		</Row >
	)
}