import { Key } from "react";
import { DataLevels, LevelItem } from "../IGroupings";

export function findNodeTree(lines: DataLevels[] | LevelItem[], key: Key): DataLevels {
    let obj = null;

    for (let l = lines.length; l--;) {
        const level = lines[l];

        if (level.key === key) {
            obj = level;
            break;
        } else if (level.children.length > 0) {
            obj = findNodeTree(level.children, key);

            if (obj) {
                break;
            }
        }
    }

    return obj;
}