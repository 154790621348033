import {
	createContext,
	useContext,
	useEffect,
	useState
} from "react";
import { useLocation } from "react-router-dom";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useErrorBoundary } from "react-error-boundary";
import {
	RequestType
} from "util/service/IServiceCaller";
import { PermissionContextData, UserProviderProps } from "./IUserContext";
import { FunctionalityPermissions } from "util/types/types";
import { ErrorFallbackProps } from "components/error/ErrorFallback";
import i18n from "util/base/i18n";

const permissionContext = createContext<PermissionContextData>({} as PermissionContextData);

export function PermissionProvider({ children }: UserProviderProps) {
	const location = useLocation()
	const { showBoundary, resetBoundary } = useErrorBoundary();
	const [isFetchingFuncPermissions, setIsFetchingFuncPermissions] = useState(true);
	const [functionalityPermissions, setFunctionalityPermissions] = useState<FunctionalityPermissions>(
		{
			read: false,
			new: false,
			edit: false,
			remove: false,
			export: false,
			import: false,
		})
	
	useEffect(() => {
		if (!location.pathname.includes('module') &&
			!location.pathname.includes('/base/') &&
			!location.pathname.includes('/dashboard/analytics') &&
			!location.pathname.includes('/projectionlease')) {
			setIsFetchingFuncPermissions(true)

			ServiceCaller.doRequest({
				type: RequestType.GET,
				getPermissions: true,
				url: `/mf${location.pathname}`
			}, (data: FunctionalityPermissions) => {
				if (!data.read) {
					showBoundary({
						code: 403,
						title: '403',
						message: i18n.t('not_authorized_message'),
						image: '403'
					} as ErrorFallbackProps['error'])
				}
				setFunctionalityPermissions(data)

				setIsFetchingFuncPermissions(false)
			}, (err) => {
				showBoundary(err)
			});
		} else if (location.pathname.includes('module')) {
			setIsFetchingFuncPermissions(false)
			resetBoundary()
		} else {
			setIsFetchingFuncPermissions(false)
		}
	}, [location])

	return (
		<permissionContext.Provider value={{
			isFetchingFuncPermissions,
			functionalityPermissions,
			setFunctionalityPermissions
		}}>
			{children}
		</permissionContext.Provider>
	)
}

export function usePermissionContext() {
	return useContext(permissionContext)
};
