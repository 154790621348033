import { Col, Form, Input, Modal, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import { MeasureList, MeasureTableData } from "../IBudgetProjection";
import { validateMonetaryInput } from "util/functions/validateKey";

interface INewCoversionModal {
    isNewConversion: boolean;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    handleSubmit: (a: any) => void;
    measureList: MeasureList[];
    form: any;
    rateTableData: MeasureTableData[]
}

export function NewMeasureConversionModal({ 
    isNewConversion, 
    isOpen, 
    setIsOpen, 
    handleSubmit,
    measureList,
    form,
    rateTableData
}: INewCoversionModal) {

    const modalTitle = isNewConversion
        ? i18n.t<string>("unit_of_measurement.new_conversion")
        : i18n.t<string>("unit_of_measurement.edit_conversion");

    return (
        <Modal
            width={800}
            title={modalTitle}
            visible={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        handleSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okText={i18n.t<string>("save")}
            cancelText={i18n.t<string>("cancel")}
            okButtonProps={{
                htmlType: "submit",
                form: "new-conversion-form"
            }}
        >
            <Form
                name="new-conversion-form"
                form={form}
                layout="vertical"
                requiredMark={false}
            >
                <Row style={{ width: '100%' }} gutter={20}>
                    <Col span={9}>
                        <Form.Item
                            label={i18n.t<string>("from")}
                            name="unitOriginId"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                disabled
                                {...selectProps}
                                tabIndex={0}
                            >
                                {measureList.map(measure => {
                                    return <Option key={measure.key} value={measure.key}>{measure.label}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item
                            label={i18n.t<string>("for")}
                            name="unitDestinyId"
                            rules={[{ required: true, message: i18n.t<string>("required_field") },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if(isNewConversion) {
                                            const resultTableData = rateTableData.find((rate) => (
                                                rate.unitDestinyId === value
                                            ));

                                            if(resultTableData) {
                                                return Promise.reject(new Error(i18n.t<string>("unit_of_measurement.already_established_unit_conversion")));
                                            } else if(value == getFieldValue('unitOriginId')){
                                                return Promise.reject(new Error(i18n.t<string>("unit_of_measurement.source_drive_cannot_be_destination_drive")));
                                            } else {
                                                return Promise.resolve();
                                            }
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}
                        >
                            <Select
                                disabled={!isNewConversion}
                                {...selectProps}
                                tabIndex={1}
                            >
                                {measureList.map(measure => {
                                    return <Option key={measure.key} value={measure.key}>{measure.label}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={i18n.t<string>("rate")}
                            name="rate"
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                                {
                                    validator: (_, value) => {
                                      if (value === "," || value === ".") {
                                        return Promise.reject(i18n.t("please_enter_a_valid_value"));
                                      }
                                      return Promise.resolve();
                                    },
                                  },
                            ]}
                        >
                            <Input id="input-template-detail" onKeyDown={validateMonetaryInput} tabIndex={2}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}