import { Row, Col } from "antd";
import { FinalStepModalProps } from "../../IHiredDashboard";
import { DistributionList } from "./DistributionList";
import i18n from "util/base/i18n";
export default function FinalStepModal({ distributionList, form, isFormSection, setDistributionList, }: FinalStepModalProps) {
	const resultValue = distributionList.reduce((acc, val) => acc + (val.resultValue ? val.resultValue : 0), 0);
	return (
		<div className="final-step-distribution-values">
			<Row gutter={3}>
				<Col>
					<span className="gs-tag blue">
						{i18n.t("human_resources.budget_impact")}
					</span>
				</Col>
				{/* <Col>
					<span className={`gs-tag ${resultValue >= 0 ? "green" : "red"}`}>
						{
							new Intl.NumberFormat(i18n.language.replace("_", "-"), {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
							}).format(resultValue)
						}
					</span>
				</Col> */}
			</Row>
			<DistributionList
				isFormSection={isFormSection}
				form={form}
				setDistributionList={setDistributionList}
				distributionList={distributionList}
			/>
		</div>
	)
}