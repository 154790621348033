import useCachedQuery from "./useCachedQuery";
import { RequestType } from "util/service/IServiceCaller";
import { BudgetPeriodDates } from "module/budget/pages/fixedExpense/IFixedExpense";
import { ModuleType } from "util/types/types";

export function useBudgetDates(moduleType: ModuleType) {
	const moduleT: Record<ModuleType, string> = {
		expensesResources: "EXPENSES_RESOURCES",
		revenue: "REVENUE",
		planning: "PLANNING",
	}
    const data = useCachedQuery<BudgetPeriodDates>(["budget-period-dates", moduleType], {
        type: RequestType.GET,
		url: `/expenses/budget-opening/scenario?scenario={scenario}&locale={locale}&client={client}&user={user}&organization={organization}&module=${moduleT[moduleType]}`,
    });
    return data;
}
