import { Icon } from "@iconify/react";
import moment from "moment";
import i18n from "util/base/i18n";
import "../ReversalPeriodPerformed.sass";

interface PanelProps {
    year: string;
    realizedPeriod: number;
}

const Panel: React.FC<PanelProps> = ({ year, realizedPeriod }) => {
    const formatDate = (timestamp: number) => {
        if (!timestamp) {
            return i18n.t("reversal_period_performed.not_realized");
        }
        const date = moment(timestamp);
        return date.format("MMM YYYY");
    };

    return (
        <div className="organization-panel-container">
            <div className="organization-panel-header">
                <h3 style={{ marginRight: "1rem" }}>{year}</h3>
                {realizedPeriod && (
                    <div className="gs-tag green gs-flex align-center" style={{ marginRight: 8 }}>
                        <Icon icon="ic:round-done" style={{ marginRight: 5, fontSize: 18 }} />
                        {`${i18n.t("realized")}: ${formatDate(realizedPeriod)}`}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Panel;
