import { Icon } from "@iconify/react";
import { Button, Form } from "antd";
import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FormulaItem, IAdditionalFieldsForm, ITableData, MathOperationsFromServer } from "./IAdditionalFields";
import { Notification } from "components/notification/Notification";
import "./style.sass";
import AdditionalFieldsModal from "./components/AdditionalFieldsModal";
import AdditionalFieldsTable from "./components/AdditionalFieldsTable";
import i18n from "util/base/i18n";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { ColumnsType } from "antd/lib/table";
import ConfirmModal from "./components/ConfirmModal";
import { useUserContext } from "context/UserContext";

export default function AdditionalFields() {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNewAdditionalFields, setIsNewAdditionalFields] = useState(true);
    const [data, setData] = useState<ITableData[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedRowsKeys, setSelectedRowsKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ITableData[]>([]);
    const [formula, setFormula] = useState<FormulaItem[]>([]);
    const [searchField, setSearchField] = useState("");
    const [isFormula, setIsFormula] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [fieldsFormula, setFieldsFormula] = useState([]);
    const [fieldsSelect, setFieldsSelect] = useState([]);
    const { userInfo } = useUserContext();

    const tableColumns: ColumnsType = [
        {
            title: i18n.t("additional_field_name"),
            align: "left",
            dataIndex: "fieldName",
            key: "fieldName",
        },
        {
            title: i18n.t("type"),
            align: "left",
            dataIndex: "type",
            key: "center",
        },
        {
            title: i18n.t("incidence"),
            align: "left",
            dataIndex: "incidence",
            key: "incidence",
        },
        {
            title: i18n.t("basis_for"),
            align: "left",
            dataIndex: "baseFor",
            key: "baseFor",
        },
        {
            title: i18n.t("setting"),
            align: "left",
            dataIndex: "configuration",
            key: "configuration",
        },
    ];

    function onClickEdit() {
        setIsNewAdditionalFields(false);
        const field = selectedRows[0];
        form.setFieldsValue({
            active: field.active,
            externalCode: field.externalCode,
            fieldName: field.fieldName,
            type: field.type,
            incidence: field.incidence,
            base: field.baseFor,
            configuration: field.configuration,
            formulaConfiguration: field.formulaConfiguration
        });

        if (field.formulaItems) {
            setIsFormula(true);

            const formulaToEdit: FormulaItem[] = field.formulaItems.map(({ id, operationCalcType, fieldValue, informedValue }) => {
                if (operationCalcType) {
                    return {
                        type: "operator",
                        content: operationCalcType,
                    };
                }else if(informedValue) {
                    return {
                        type: "value",
                        content: {id: `INFORMED_VALUE-${informedValue}`},
                        key: `INFORMED_VALUE-${informedValue}`,
                        informedValue: informedValue
                    };
                } else {
                    const selectedField = selectedRows[0];

                    if(fieldValue === selectedField.id){
                        return {
                            key: id,
                            type: "field",
                            content: {
                                id: selectedField.id,
                                name: selectedField.fieldName,
                                type: "field"
                            }
                        }
                    }

                    const field = fieldsSelect.find((field) => field.id == fieldValue);

                    return {
                        key: id,
                        type: "field",
                        content: { id: field.id, type: "field", name: field.description },
                    };
                }
            });
            setFormula(formulaToEdit);

            if(field.formulaConfiguration.includes("isExternalInfo")){
                const fields: any = [{
                    label: field.fieldName,
                    value: JSON.stringify({
                            id: field.id,
                            type: "field",
                            name: field.externalCode
                    })
                }];
                fields.push(...fieldsFormula);
                setFieldsFormula(fields);
            }
        }

        loadFieldFormula()
        setIsModalVisible(true);
    }

    function onClickDelete(ignoreValidation) {
        setIsFetching(true);
        ServiceCaller.doRequest({
                type: RequestType.DELETE,
                url: `/human-resources/fields?ids=${selectedRowsKeys}&ignoreValidation=${ignoreValidation}`,
                useProxy: true,
            }, onDeleteFields.bind(this)
        );
    }

    function onDeleteFields(data: any){
        if (data.succes){
            Notification({
                type: "info",
                message: i18n.t("successfully_deleted"),
            });
            setIsConfirmModalVisible(false);
        } else if (data.message === "field_formula_vinculated") {
            setIsConfirmModalVisible(true);
            return;
        } else {
            Notification({
                type: "error",
                message: i18n.t(data.message),
            });
            setIsConfirmModalVisible(false);
        }
        getTableData()
    }

    function onClickNewField() {
        setIsNewAdditionalFields(true);
        setIsModalVisible(true);
        loadFieldFormula();
    }

    function onLoadFields(data) {
        setData(
            data.map((item) => {
                return {
                    id: item.id,
                    baseFor: [
                        item.isCalculationBase ? "isCalculation" : null,
                        item.isFgtsBase ? "isFgts" : null,
                        item.isInssBase ? "isInss" : null,
                        item.isOvertimeBase ? "isOvertime" : null,
                        item.isThirteenthBase ? "isThirteenth" : null,
                        item.isVacationBase ? "isVacation" : null,
                    ].filter((text) => text),
                    configuration: [
                        item.phisical ? "phisical" : null,
                        item.premisses ? "premisse" : null,
                        item.replicable ? "replicable" : null,
                        item.restatment ? "restatment" : null,
                        item.indexing ? "indexing" : null,
                        item.balance ? "balance" : null,
                        item.isFormula ? "formula" : null,
                        item.isCalculable ? "calculate_value" : null,
                    ].filter((text) => text),
                    formulaConfiguration: [
                        item.isExternalInfo ? "isExternalInfo" : null,
                        item.isBalance ? "isBalance" : null
                    ].filter((text) => text),
                    fieldName: item.description,
                    incidence: item.incidenceEnum,
                    type: item.valueType,
                    externalCode: item.externalCode,
                    active: item.active,
                    order: item.order,
                    inputGroup: item.inputGroup,
                    formulaItems: item.formulaItems,
                };
            })
        );
        setIsFetching(false);
    }

    function saveBatch(data: IAdditionalFieldsForm[]) {
        if (data.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t("empty_list_message"),
            });
            return;
        }
        setIsFetching(true);
        setIsModalVisible(false);
        const dataToSave = data.map((item) => {
            const base = item.base || [""];
            const configuration = item.configuration || [""];
            const formulaConfiguration = item.formulaConfiguration || [""];
            return {
                id: isNewAdditionalFields ? null : selectedRowsKeys[0],
                description: item.fieldName,
                active: item.active,
                valueType: item.type,
                incidenceEnum: item.incidence,
                externalCode: item.externalCode,
                isCalculationBase: base.includes("isCalculation"),
                isVacationBase: base.includes("isVacation"),
                isOvertimeBase: base.includes("isOvertime"),
                isThirteenthBase: base.includes("isThirteenth"),
                isInssBase: base.includes("isInss"),
                isFgtsBase: base.includes("isFgts"),
                replicable: configuration.includes("replicable"),
                phisical: configuration.includes("phisical"),
                indexing: configuration.includes("indexing"),
                premisses: configuration.includes("premisse"),
                restatment: configuration.includes("restatment"),
                externalInfo: configuration.includes("externalInfo"),
                isFormula: configuration.includes("formula"),
                isCalculable: item.type.toString() == "PERCENTAGE" ? true : configuration.includes("calculate_value"),
                isExternalInfo: formulaConfiguration.includes("isExternalInfo"),
                isBalance: formulaConfiguration.includes("isBalance"),
                formulaItems: item.formulaItems ? item.formulaItems : [],
                order: item.order,
                inputGroup: 0,
                fieldType: 0,
                defaultDefinitions: 0,
                isDefault: false,
            };
        });

        setSelectedRows([]);
        ServiceCaller.doRequest(
            {
                type: isNewAdditionalFields ? RequestType.POST : RequestType.PUT,
                url: "/human-resources/fields?locale={locale}&scenarioId={scenario}&userId={user}",
                useProxy: true,
                params: isNewAdditionalFields ? dataToSave : dataToSave[0],
            },
            onSave.bind(this)
        );
    }

    function onSave() {
        getTableData();
    }

    function getTableData() {
        setSelectedRows([]);
        setSelectedRowsKeys([]);
        setIsFetching(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/human-resources/fields",
            },
            onLoadFields.bind(this)
        );
    }

    function onChangeRowSelection(selectedRowKeys: Key[], selectedRows: ITableData[]) {
        setSelectedRowsKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    }

    function handleSearch(e) {
        setSearchField(e.target.value);
    }

    function handleLoadFormulaFields(data: any[]) {
        const fields = [];

        const initialInformation = data.filter(field => field.inputGroup === "INITIAL_INFORMATION");
        const transactions = data.filter(field => field.inputGroup === "TRANSACTIONS");
        const events = data.filter(field => field.inputGroup === "EVENTS");
        const legalTaxes = data.filter(field => field.inputGroup === "LEGAL_TAXES");
        const custom = data.filter(field => field.inputGroup === "CUSTOM");
        const resultQuantity = data.filter(field => field.inputGroup === "RESULT_QUANTITY");
        const resultValues = data.filter(field => field.inputGroup === "RESULT_VALUES");

        if(initialInformation.length !== 0 ){
            fields.push({
                label: 'Informações Iniciais',
                inputGroup: "INITIAL_INFORMATION",
                options: initialInformation.map(field => {
                    return {
                        label: field.description,
                        value: JSON.stringify({ id: field.id, type: "field", name: field.description 
                    })}
                }),
            })
        }

        if(transactions.length !== 0 ){
            fields.push({
                label: 'Movimentações e Ajustes',
                inputGroup: "TRANSACTIONS",
                options: transactions.map(field => {
                    return {
                        label: field.description,
                        value: JSON.stringify({ id: field.id, type: "field", name: field.description 
                    })}
                }),
            })
        }

        if(legalTaxes.length !== 0 ){
            fields.push({
                label: 'Taxas Legais',
                inputGroup: "LEGAL_TAXES",
                options: legalTaxes.map(field => {
                    return {
                        label: field.description,
                        value: JSON.stringify({ id: field.id, type: "field", name: field.description 
                    })}
                }),
            })
        }

        if(custom.length !== 0 ){
            fields.push({
                label: 'Campos Adicionais',
                inputGroup: "CUSTOM",
                options: custom.map(field => {
                    return {
                        label: field.description,
                        value: JSON.stringify({ id: field.id, type: "field", name: field.description 
                    })}
                }),
            })
        }

        if(resultQuantity.length !== 0 ){
            fields.push({
                label: 'Resultado (Quantidade)',
                inputGroup: "RESULT_QUANTITY",
                options: resultQuantity.map(field => {
                    return {
                        label: field.description,
                        value: JSON.stringify({ id: field.id, type: "field", name: field.description 
                    })}
                }),
            })
        }

        if(resultValues.length !== 0 ){
            fields.push({
                label: 'Resultado (Valores)',
                inputGroup: "RESULT_VALUES",
                options: resultValues.map(field => {
                    return {
                        label: field.description,
                        value: JSON.stringify({ id: field.id, type: "field", name: field.description 
                    })}
                }),
            })
        }

        setFieldsSelect(data);
        setFieldsFormula(fields);
    }

    useEffect(() => {
        getTableData();
        loadFieldFormula();
    }, []);

    function loadFieldFormula() {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/human-resources/fields/findNotFormula?userId={user}&clientId={client}&locale={locale}`,
            },
            handleLoadFormulaFields.bind(this)
        );
    }

    let filteredData = data.filter((obj) => obj.fieldName.toLowerCase().includes(searchField.toLowerCase()));

    return (
        <div className="budget-additional-fields">
            <div className="page-title-content">
                <h1>{i18n.t("collaborators.additional_fields.registration_of_additional_fields")}</h1>
                {/* <Button
					onClick={() => { }}
					style={{ borderRadius: 4 }}
					icon={<Icon style={{ color: '#4F9ACF', fontSize: 26 }} icon="clarity:info-line" />}
				/> */}
            </div>
            <div id="top-buttons-additional-fields">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t("flexField.button")}
                        handleNew={onClickNewField}
                        handleEdit={onClickEdit}
                        handleDelete={() => onClickDelete(false)}
                        isDeletable={selectedRowsKeys.length ? true : false}
                        isEditable={selectedRowsKeys.length === 1 ? true : false}
                        searchPlaceholder={i18n.t<string>("search")}
                        handleSearch={handleSearch}
                    />
                </div>
                <ImportExportMenu
                    exportGridData={() => handleExportGridData(data, tableColumns, i18n.t("additional_fields"))}
                    buttonType="3dots"
                />
            </div>
            <AdditionalFieldsModal
                handleSave={saveBatch}
                tableData={data}
                isNewAdditionalFields={isNewAdditionalFields}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                form={form}
                lineChecked={selectedRowsKeys[0]}
                selectedRowsKeys={selectedRowsKeys}
                fieldsFormula={fieldsFormula}
                setFieldsFormula={setFieldsFormula}
                formula={formula}
                setFormula={setFormula}
                isFormula={isFormula}
                setIsFormula={setIsFormula}
            />
            <AdditionalFieldsTable
                selectedRowKeys={selectedRowsKeys}
                isFetching={isFetching}
                data={filteredData}
                onChange={onChangeRowSelection}
                onLoadFields={onLoadFields}
            />
            <ConfirmModal
                handleDelete={onClickDelete}
                isModalVisible={isConfirmModalVisible}
                setIsModalVisible={setIsConfirmModalVisible}
                getTableData={getTableData}
            />
        </div>
    );
}
