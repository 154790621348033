import { DataLevels, LevelItem } from "../IGroupings";

export function verifyIfHasLinkedAtLastLevel(dataLevels: DataLevels[]| LevelItem[]): boolean {
    let showIconAtLastLevel = false;

    function searchNode(node: DataLevels) {
        if (node.children.length === 0) {
            if (node.showIcon === true) {
                showIconAtLastLevel = true;
            }
        } else {
            node.children.forEach(child => {
                searchNode(child);
            });
        }
    }

    dataLevels.forEach(root => {
        searchNode(root);
    });

    return showIconAtLastLevel;
}