import { Icon } from "@iconify/react";
import { Dropdown, Input, Menu } from "antd";
import { useRef, useState } from "react";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { IMultipleSearch } from "./IMultipleSearch";
import i18n from "util/base/i18n";
import './MultipleSearch.sass'

const { Search } = Input

export function MultipleSearch({ tableData, setTableData, options }: IMultipleSearch) {
    const [value, setValue] = useState('')
    const [searchType, setSearchType] = useState(options[0]?.description)
    const searchRef = useRef(null)

    const optionsSearch: ItemType[] = options.map(opt => {
        return {
            label: `${i18n.t('search_by')} ${i18n.t(opt.i18nString)}`,
            key: opt.description
        }
    })

    function handleSearch(e) {
        setValue(e.target.value)
        let filteredData = [];
        const option = options.find(item => item.description === searchType);

        if (option?.isArray) {
            filteredData = tableData.filter(item => item[searchType].some(obj => obj[option?.descriptionArray].toString().toLowerCase().includes(e.target.value.toLowerCase())));
        } else {
            filteredData = tableData.filter(item => {
                return item[searchType]?.toString().toLowerCase().includes(e.target.value.toLowerCase())
            });
        }
        setTableData(filteredData)
    }

    function onChangeType({ key }) {
        setSearchType(key)
        searchRef.current!.focus()
    }

    const placeholder = options.find(opt => opt.description === searchType)?.i18nString ?? options[0].i18nString

    return (
        <Search
            className="gs-multiple-search"
            ref={searchRef}
            placeholder={`${i18n.t('search_by')} ${i18n.t(placeholder)}`}
            value={value}
            onChange={handleSearch}
            suffix={
                <Dropdown overlay={<Menu items={optionsSearch} onClick={onChangeType} />} trigger={["click"]}>
                    <Icon icon="majesticons:chevron-down-line" />
                </Dropdown>
            }
        />
    )
}