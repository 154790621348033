import { Tag } from "module/budget/pages/revenue/attributeParameterization/components/Tag";
import { FormulaItem } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { InputNumber } from "antd";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import { chooseIconOperation } from "./constants";

interface IFormulaAreaProps {
	formula: FormulaItem[];
	removeItem: (index: number) => void;
	hasValueOperator?: boolean;
	setFormula?: React.Dispatch<React.SetStateAction<FormulaItem[]>>;
}

export default function MultipleFormulaArea({
	formula,
	removeItem,
	setFormula,
}: IFormulaAreaProps) {
	const formulaWithOperatorKeys = formula.map((item) => {
		if (item.type === "operator") {
			return {
				...item,
				key: `OPERATORS-${Math.random()}`,
			};
		}
		return item;
	});

	function onChaneValueInput(newValue: number, ordination: number): void {
		setFormula((state) => {
			const itemToChange = { ...state[ordination], value: newValue };
			state[ordination] = itemToChange;
			return [...state];
		});
	}

	const SortableItem = SortableElement(({ value, position }) => {
		let elementFormula: JSX.Element = null;
		if (value.type === "operator") {
			elementFormula = chooseIconOperation({ key: value.key, position: position, removeItem: removeItem })[value.content]
		}

		if (value.type === "attribute") {
			elementFormula = (
				<div key={position}>
					<Tag itemIndex={position} removeItem={removeItem}>
						{value.content.name}
					</Tag>
				</div>
			);
		}

		if (value.type === "value") {
			elementFormula = (
				<div key={position}>
					<Tag itemIndex={position} removeItem={removeItem} isMathOperation>
						<InputNumber
							onChange={(newValue) => onChaneValueInput(newValue, position)}
							value={value.value}
							controls={false}
						/>
					</Tag>
				</div>
			);
		}

		return elementFormula;
	})

	const SortableList = SortableContainer(({ items }) => {
		return (
			<div className="attribute_parameterization-new-attribute-modal-formula-workarea" id="attribute_parameterization-new-attribute-modal-formula-workarea" >
				{items.map((value, index) => (
					<SortableItem value={value} key={`item-${value.key}`} position={index} index={index} />
				))}
			</div>
		);
	});

	const onSortEnd = ({ oldIndex, newIndex }) => {
		setFormula((items) => {
			return arrayMove(items, oldIndex, newIndex);
		})
	};

	return (
		<div >
			<SortableList
				distance={10}
				onSortEnd={onSortEnd}
				axis="xy"
				items={formulaWithOperatorKeys}
			/>
		</div >
	);
}