import { Form, Modal, Select } from "antd";
import { EditableRowProps } from "module/budget/pages/detailLayout/IDetailLayout";
import { useContext, useEffect, useState } from "react";
import { EditableContext } from "../../RevenueTable";
import { EditableCell } from "./EditableCell";

import Table from "antd/es/table";
import i18n from "util/base/i18n";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { Notification } from "components/notification/Notification";
import generateDefaultColumns from "./DataColumns";
import { RevenueContext } from "../../../RevenueContext";

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const components = {
    body: {
        row: EditableRow,
        cell: EditableCell,
    },
};

export default function CopyValuesModal({
    isCopyValuesModalOpen,
    handleCancel,
    setIsCopyValuesModalOpen,
    updateTreeGrid
}) {

    const { userInfo } = useUserContext();

    const [selectedYearDestiny, setSelectedYearDestiny] = useState<number>(parseFloat(moment().format("YYYY")));
    const [selectedYearOrigin, setSelectedYearOrigin] = useState<number>(parseFloat(moment().subtract(1, "years").format("YYYY")));
    const [optionsYears, setOptionsYears] = useState<any[]>([]);
    const [buttonOkLoading, setButtonOkLoading] = useState<boolean>(false);
    const [tablePeriods, setTablePeriods] = useState<any[]>(() => {
        const result = generateDefaultColumns(parseFloat(moment().format("YYYY")))

        return result;
    });

    useEffect(() => {
        if (!userInfo.selection.scenarioId) return;
        ServiceCaller.doRequest({
            url: `/budget-base/control-panel/find-all-years-revenue?organization=${userInfo.selection.organizationId}&scenario=${userInfo.selection.scenarioId}`,
            type: RequestType.GET,
        }, handleLoadYears.bind(this), handleErrorRequest);
    }, [userInfo.selection]);

    useEffect(() => {
        cleanValues();
    }, [selectedYearDestiny]);

    const defaultColumns: any = [
        {
            title: i18n.t<string>("period"),
            dataIndex: 'period',
            key: 'period',
            align: 'center'
        },
        {
            title: '% ' + i18n.t<string>("volume"),
            dataIndex: 'volume',
            key: 'volume',
            align: 'center',
            editable: true
        },
        {
            title: '% ' + i18n.t<string>("price"),
            dataIndex: 'price',
            key: 'price',
            align: 'center',
            editable: true
        },
    ];

    const columns = defaultColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                setTablePeriods,
                tablePeriods
            }),
        };
    });

    function handleSelectYearDestiny(year) {
        setSelectedYearDestiny(year);
    }

    function handleSelectYearOrigin(year) {
        setSelectedYearOrigin(year);
    }

    function doCopyValues() {
        setButtonOkLoading(true);
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: '/revenue/field/value/date/replicate-values-year',
            params: {
                organizationId: userInfo.selection.organizationId,
                scenarioId: userInfo.selection.scenarioId,
                yearOrigin: selectedYearOrigin + "-01",
                yearDestiny: selectedYearDestiny + "-01",
                volumePriceDateBeans: tablePeriods.map((t) => {
                    let volumeFormated = t.volume.replace(',', '.');
                    let priceFormated = t.price.replace(',', '.');
                    return {
                        period: selectedYearDestiny + "-" + t.date,
                        volume: parseFloat(volumeFormated),
                        price: parseFloat(priceFormated)
                    }
                })
            }
        }, handleSuccessCallBack, handleErrorRequest);
    }

    function handleLoadYears(years: any[]) {
        let list = years.map((y) => {
            return {
                value: y,
                label: y.toString()
            }
        });

        setOptionsYears(list);
    }

    function handleSuccessCallBack() {
        setIsCopyValuesModalOpen(false);
        Notification({
            type: 'success',
            message: i18n.t<string>("values_copied_successfully")
        });

        cleanValues();
        updateTreeGrid(false);
        setButtonOkLoading(false);
    }

    function cleanValues() {
        setTablePeriods(generateDefaultColumns(selectedYearDestiny));
    }

    return (
        <Modal title={i18n.t<string>("copy_of_values")} visible={isCopyValuesModalOpen} onOk={doCopyValues} onCancel={handleCancel} confirmLoading={buttonOkLoading} >
            <div style={{
                display: "flex",
                gap: 70
            }}>
                <div className="select-origin" style={{ display: 'inline-grid' }}>
                    <span>{i18n.t<string>("year_of_origin")}</span>
                    <Select
                        style={{ width: 200 }}
                        defaultValue={moment().subtract(1, "years").format("YYYY")}
                        options={optionsYears}
                        placeholder={i18n.t<string>("select")}
                        onChange={handleSelectYearOrigin}
                    />
                </div>
                <div className="select-destiny" style={{ display: 'inline-grid' }}>
                    <span>{i18n.t<string>("year_of_destiny")}</span>
                    <Select
                        style={{ width: 200 }}
                        defaultValue={moment().format("YYYY")}
                        options={optionsYears}
                        placeholder={i18n.t<string>("select")}
                        onChange={handleSelectYearDestiny}
                    />
                </div>
            </div>

            <div style={{ paddingTop: 20 }}>
                <Table
                    dataSource={tablePeriods}
                    className='gs-table'
                    columns={columns}
                    pagination={false}
                    components={components}
                    bordered
                />
            </div>
        </Modal>
    );
}