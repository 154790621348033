import { Modal } from 'antd'
import i18n from 'util/base/i18n';
import { ResetBalancesModalProps } from '../IManagementAccount';

export default function ResetBalancesModal({ isModalVisible, handleCancel, handleClick }: ResetBalancesModalProps) {
    return (
        <Modal
            width={550}
            centered={true}
            title={i18n.t("clear_balances")}
            open={isModalVisible}
            onCancel={handleCancel}
            className="gs-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("yes")}
            okButtonProps={{
                className: "delete-button",
                onClick: handleClick
            }}
            destroyOnClose={true}
        >
            {i18n.t("reset_balances_message")}
        </Modal>
    );
}