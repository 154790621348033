import { PlusCircleFilled } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Button, Col, Collapse, Form, Row } from "antd";
import { useUserContext } from "context/UserContext";
import { FlexField, FlexFieldValue, ICondition, IConditionGrouperType, ILevelsValue, RuleConditionOperations, RuleConditionOperationsTempCaptions } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { CustomSelect } from "module/budget/pages/revenue/attributeParameterization/components/custom/CustomSelect";
import { ContainsOp, EqualOp, FromToOp, NotContainsOp, NotEqualOp } from "module/budget/pages/revenue/attributeParameterization/components/operators/MathOperators";
import { Condition } from "module/budget/pages/revenue/attributeParameterization/components/rules/Condition";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FilterType, ILineFilterProps, IReportFilterSignal, LevelFilterPlanning, OptionsFilter } from "../../../../IRegistrationSalesReports";
import { useNewReportContext } from "../../../NewReportContext";
import PlanningLineFilter from "./PlanningLineFilter";
import PlanningCondition from "./PlannningCondition";
const { Panel } = Collapse;

export default function LineFilter({
	onClickSave,
	conditionsEdit,
	levelsValue = [],
	setLevelsValue,
	setHasUnsavedChanges,
	levels,
	setLevels,
	operations,
	setOperations,
	classNameForPulseButton,
	availableOperations,
	conditions,
	flexFieldsValues,
	setAvailableOperations,
	setConditions,
	setFlexFieldsValues,
	form,
	setFilterType,
	filterType,
	planningConditionsEdit
}: ILineFilterProps) {
	const { reportType, isPlanningReport } = useNewReportContext();
	const { userInfo } = useUserContext();
	const [flexFields, setFlexFields] = useState<OptionsFilter[]>([]);
	const [costCenterOptions, setCostCenterOptions] = useState<OptionsFilter[]>([]);
	const [accountOptions, setAccountOptions] = useState<OptionsFilter[]>([]);
	const [planningCondition, setPlanningCondition] = useState<LevelFilterPlanning[]>([]);
	const [signal, setSignal] = useState<IReportFilterSignal>(IReportFilterSignal.PLUS);
	const [fromCostCenterExternalCode, setFromCostCenterExternalCode] = useState<string>("");
	const [toCostCenterExternalCode, setToCostCenterExternalCode] = useState<string>("");
	const [fromAccountingExternalCode, setFromAccountingExternalCode] = useState<string>("");
	const [toAccountingExternalCode, setToAccountingExternalCode] = useState<string>("");

	function validateAndFilterConditions(currentConditions: ICondition[], newConditions: ICondition[]): ICondition[] {
		const isEqualCondition = (currentCond: ICondition, newCond: ICondition): boolean => {
			return currentCond.selectedLevel.id === newCond.selectedLevel.id &&
				currentCond.selectedLevelValue.id === newCond.selectedLevelValue.id &&
				currentCond.operations === newCond.operations
		};
		const filteredNewConditions = newConditions.filter(newCond =>
			!currentConditions.some(currentCond => isEqualCondition(currentCond, newCond))
		);
		return [...currentConditions, ...filteredNewConditions];
	}

	const isAddButtonDisabled: boolean = !getButtonStatus();

	function getButtonStatus(): boolean {
		const allExtenalCode: string[] = [fromCostCenterExternalCode, toCostCenterExternalCode, fromAccountingExternalCode, toAccountingExternalCode];
		const isPlanningFilterValid: boolean = allExtenalCode.every((externalCode) => externalCode && externalCode !== "");
		const isRevenueFilterValid: boolean = !!levelsValue?.length;
		return isRevenueFilterValid || isPlanningFilterValid;
	}

	function createRevenueCondition() {
		setConditions((prevConditions) => {
			const newConditions: ICondition[] = levelsValue.map((levelValue) => {
				return ({
					grouperType: IConditionGrouperType.REVENUE,
					operations: operations == RuleConditionOperations.DIFFERENT.toUpperCase() ? RuleConditionOperations.NOT_EQUALS : operations,
					selectedLevel: levels,
					selectedLevelValue: levelValue,
					filterType
				})
			})
			return validateAndFilterConditions(prevConditions, newConditions);
		});
		form.resetFields();
		setOperations(null);
		setLevels(null);
		setLevelsValue(null);
		setFlexFieldsValues([]);
		setHasUnsavedChanges(false);
	}

	function resetPlanningFields() {
		setToAccountingExternalCode("");
		setFromAccountingExternalCode("");
		setToCostCenterExternalCode("");
		setFromCostCenterExternalCode("");
		setSignal(IReportFilterSignal.PLUS);
	}

	function createPlanningCondition() {
		const newCondition: LevelFilterPlanning = {
			fromAccountingExternalCode,
			fromCostCenterExternalCode,
			toAccountingExternalCode,
			toCostCenterExternalCode,
			signal,
			id: Math.random()
		}

		setPlanningCondition(state => state.concat(newCondition));
		resetPlanningFields();
	}

	function handleRemovePlanningCondition(conditionId: number) {
		setPlanningCondition(state => state.filter(({ id }) => conditionId !== id));
	}

	function handleEditPlanningCondition(conditionId: number) {
		const {
			fromAccountingExternalCode,
			fromCostCenterExternalCode,
			signal,
			toAccountingExternalCode,
			toCostCenterExternalCode
		} = planningCondition.find(({ id }) => conditionId === id);
		setFromAccountingExternalCode(fromAccountingExternalCode);
		setToAccountingExternalCode(toAccountingExternalCode);
		setFromCostCenterExternalCode(fromCostCenterExternalCode);
		setToCostCenterExternalCode(toCostCenterExternalCode);
		setSignal(signal);

		setPlanningCondition(state => state.filter(({ id }) => id !== conditionId));
	}

	function handleCreateCondition() {
		if (isPlanningReport()) {
			createPlanningCondition();
		} else {
			createRevenueCondition();
		}
	}

	function sortFlexFields(accounts: OptionsFilter[]): OptionsFilter[] {
		return accounts.sort((a, b) => {
			const descA = a.label;
			const descB = b.label;

			const isNumberA = /^[0-9]/.test(descA);
			const isNumberB = /^[0-9]/.test(descB);

			if (isNumberA && !isNumberB) {
				return 1;
			} else if (!isNumberA && isNumberB) {
				return -1;
			} else {
				return descA.localeCompare(descB);
			}
		})
	}

	function handleLevels(fieldSelected: string) {
		if (!fieldSelected) return;
		const { label, value } = flexFields.find(({ value }) => value === fieldSelected);
		setLevelsValue(null);
		setFlexFieldsValues([]);
		setHasUnsavedChanges(true);

		setLevels({
			id: value,
			description: label,
		});

		setFilterType(FilterType.FLEX_FIELD);
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/flex-field-value/find-flex-field-values-with-security-revenue?user={user}&flexFieldId=${fieldSelected}&group=${userInfo.groupIds}`,
		}, (response: FlexFieldValue[]) => {
			setFlexFieldsValues(response);
		});
	}

	function getMathOperator(operation: string) {
		switch (RuleConditionOperations[operation]) {
			case RuleConditionOperations.EQUALS:
				return <EqualOp />;
			case RuleConditionOperations.NOT_EQUALS:
				return <NotEqualOp />;
			case RuleConditionOperations.CONTAINS:
				return <ContainsOp />
			case RuleConditionOperations.NOT_CONTAINS:
				return <NotContainsOp />;
			case RuleConditionOperations.FROM_TO:
				return <FromToOp />;
		}
	}

	function removeCondition(index: number) {
		setConditions((prevConditions) => {
			const newConditions = [...prevConditions];
			newConditions.splice(index, 1);
			return newConditions;
		});
	}

	function handleOperations(value: RuleConditionOperations) {
		if (!value) return;
		setOperations(value);
		setHasUnsavedChanges(true);
	}

	function handleLevelsValue(stringifiedSelectedLevelValue: string[]) {
		if (!stringifiedSelectedLevelValue) return;
		const selectedLevelValue: ILevelsValue[] = stringifiedSelectedLevelValue.map((fieldSelected) => {
			const { id, description }: FlexFieldValue = JSON.parse(fieldSelected);
			return { description, id };
		});
		setHasUnsavedChanges(true);
		setLevelsValue(selectedLevelValue);
	}

	function onClearFlexField() {
		form.resetFields();
		setOperations(null);
		setLevelsValue(null);
		setLevels(null);
		setFlexFieldsValues([]);
		setHasUnsavedChanges(false);
	}

	function onClearOperations() {
		setOperations(null);
	}

	useEffect(() => {
		onClickSave(conditions, planningCondition);
	}, [conditions, planningCondition]);

	useEffect(setConditions.bind(this, conditionsEdit), [conditionsEdit]);

	useEffect(setPlanningCondition.bind(this, planningConditionsEdit), [planningConditionsEdit])

	useEffect(() => {
		if (isPlanningReport()) {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/planning/management-account-plan-hierarchy`,
			}, (response: FlexFieldValue[]) => {
				const accounts: OptionsFilter[] = response.map(({ id, externalCode, description }) => {
					const account: OptionsFilter = {
						value: externalCode,
						label: `${externalCode} - ${description}`
					}
					return account;
				});

				setAccountOptions(sortFlexFields(accounts));
			});

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/planning/management-cost-center`,
			}, (response: FlexFieldValue[]) => {
				const costCenters: OptionsFilter[] = response.map(({ id, externalCode, description }) => {
					const costCenter: OptionsFilter = {
						value: externalCode,
						label: `${externalCode} - ${description}`
					}
					return costCenter;
				});
				setCostCenterOptions(sortFlexFields(costCenters));
			});
		} else {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/flex-field/find-all-by-module?module=REVENUE`,
			}, (response: FlexField[]) => {
				setFlexFields(response.map(({ description, id }) => ({ label: description, value: id.toString() })));
			});
		}

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/revenue/operation?isCalcOptions=false`,
		}, (response: any) => {
			setAvailableOperations(response);
		});
	}, [reportType]);

	return (
		<Collapse
			className="attribute_parameterization-new-rule-modal"
			expandIconPosition="right"
			expandIcon={({ isActive }) => <Icon icon="tabler:chevron-down"
				rotate={isActive ? 90 : 0}
				fontSize={20}
			/>
			}>
			<Panel id="attribute_parameterization-new-rules-modal-rules-section" key={1} header={
				<Row align="middle" gutter={28} wrap={false}>
					<Col>
						<Icon icon="clarity:filter-solid" />
					</Col>
					<Col>
						{i18n.t("new_sales_report.line_filter")}
					</Col>
				</Row>
			}>
				{
					isPlanningReport() ?
						<PlanningLineFilter
							account={accountOptions}
							costCenter={costCenterOptions}
							filterSignalType={signal}
							setFilterSignalType={setSignal}
							fromAccountingExternalCode={fromAccountingExternalCode}
							fromCostCenterExternalCode={fromCostCenterExternalCode}
							setFromAccountingExternalCode={setFromAccountingExternalCode}
							setFromCostCenterExternalCode={setFromCostCenterExternalCode}
							setToAccountingExternalCode={setToAccountingExternalCode}
							setToCostCenterExternalCode={setToCostCenterExternalCode}
							toAccountingExternalCode={toAccountingExternalCode}
							toCostCenterExternalCode={toCostCenterExternalCode}
						/>
						:
						<Form form={form} className="attribute_parameterization-new-rule-modal-form">
							<div className="form-field">
								<Form.Item name="levels">
									<CustomSelect
										id="levels"
										labelName={i18n.t<string>("new_sales_report.filter_options")}
										clearSelects
										onChange={handleLevels}
										options={flexFields}
										onClear={onClearFlexField}
									/>
								</Form.Item>
							</div>
							<div className="form-field">
								<Form.Item name="operations">
									<CustomSelect
										id="operations"
										labelName={i18n.t<string>("operations")}
										onChange={handleOperations}
										clearSelects
										options={availableOperations.map((operation) => {
											return {
												label: RuleConditionOperationsTempCaptions[
													operation.name
												],
												value: operation.name,
											};
										})}
										onClear={onClearOperations}
									/>
								</Form.Item>
							</div>
							<div className="form-field">
								<Form.Item name="orderLevel"
									className="select-value-filter"
								>
									<CustomSelect
										id="orderLevel"
										mode="multiple"
										hasSearch
										labelName={i18n.t<string>("new_sales_report.value_of_filters")}
										onChange={handleLevelsValue}
										disabled={flexFieldsValues.length === 0}
										options={flexFieldsValues.map((flexValue) => {
											return {
												label: flexValue.description,
												value: JSON.stringify(flexValue),
											};
										})}
										onClear={() => setLevelsValue(null)}
									/>
								</Form.Item>
							</div>
						</Form>
				}
				<div id="attribute_parameterization-new-attribute-modal-add-button-wrapper">
					<Button
						id=""
						type="primary"
						shape="round"
						icon={<PlusCircleFilled />}
						onClick={handleCreateCondition}
						className={`gs-secondary-button ${classNameForPulseButton}`}
						disabled={isAddButtonDisabled}
					>
						{i18n.t<string>("add")}
					</Button>
				</div>
				<div className="attribute_parameterization-new-attribute-modal-rules-workarea">
					{isPlanningReport() ?
						<PlanningCondition
							conditions={planningCondition}
							handleRemove={handleRemovePlanningCondition}
							handleEdit={handleEditPlanningCondition}
						/>
						:
						<>
							{conditions?.map((condition, index) => {
								const {
									operations,
									selectedLevel,
									selectedLevelValue,
								} = condition;

								getMathOperator(operations);

								return (
									<Condition
										key={index}
										index={index}
										handleRemove={removeCondition}
									>
										<div className="attribute_parameterization-new-attribute-modal-rule-label">
											{selectedLevel.description}
										</div>

										{getMathOperator(operations)}

										<div className="attribute_parameterization-new-attribute-modal-rule-label">
											{selectedLevelValue.description}
										</div>
									</Condition>
								);
							})}
						</>
					}
				</div>
			</Panel>
		</Collapse>
	)
}