import { Col, DatePicker, Radio, Row, Select, Space } from "antd";
import { useContext, useEffect, useState } from "react";

import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { selectProps } from "util/props/props";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ReportViewContext } from "../../../context/ReportViewContext";

const { RangePicker } = DatePicker;

export function RowFiltersComparative({ userInfo, scenarios }) {

    const { handleBaseType, handleBaseTarget, handleYearToCompare, handleScenarioToCompare, handlePeriodBase, periodBase, baseType, baseTarget } = useContext(ReportViewContext);

    const [optionsYears, setOptionsYears] = useState<any[]>([]);

    function handleLoadYears(years: any[]) {
        setOptionsYears(years.map((y) => {
            return {
                value: y,
                label: y.toString()
            }
        }));
    }

    useEffect(() => {
        if (!userInfo.selection.scenarioId) return;
        ServiceCaller.doRequest({
            url: `/budget-base/control-panel/find-all-years-revenue?organization=${userInfo.selection.organizationId}&scenario=${userInfo.selection.scenarioId}`,
            type: RequestType.GET,
        }, handleLoadYears.bind(this), handleErrorRequest);
    }, [userInfo.selection]);

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        if(!periodBase) {
            return false;
        }

        const yearBase = periodBase[0].year();
        if (current.year() !== yearBase) {
            return true;
        }

        return false;
    };

    return (
        <Row
            id="revenue-row-filter"
            wrap={false}
            align="middle"
            gutter={[0, 30]}
            style={{
                paddingTop: 25
            }}
        >
            <Col style={{ display: 'flex', flexFlow: 'column' }}>
                <span>{i18n.t<string>("base_period")}</span>
                <RangePicker
                    className="revenue-period-filter-card"
                    name="period"
                    disabledDate={disabledDate}
                    onChange={handlePeriodBase}
                    value={periodBase}
                    format="YYYY/MM"
                    picker="month"
                    allowClear={true}
                    style={{
                        cursor: 'pointer',
                        background: 'white'
                    }}
                />
            </Col>
            <Col style={{ paddingLeft: 20 }}>
                <Radio.Group onChange={handleBaseType} value={baseType} defaultValue={"budgeted"}>
                    <Space direction="vertical">
                        <Radio value={"budgeted"}>{i18n.t<string>("budgeted")}</Radio>
                        <Radio value={"accomplished"}>{i18n.t<string>("accomplished")}</Radio>
                    </Space>
                </Radio.Group>
            </Col>
            <Col style={{
                borderLeft: '#DBDBDB solid 1px',
                paddingLeft: 20,
            }}>
                <p>{i18n.t<string>("scenario_comparison")}</p>
                <Select
                    defaultValue={() => {
                        if (scenarios.length > 0 && userInfo?.selection?.scenarioId) {
                            let scenarioSelected = scenarios.find(scenario => scenario.value === userInfo.selection.scenarioId);
                            if (scenarioSelected) {
                                return scenarioSelected.value;
                            }
                        }
                    }}
                    style={{ width: 350 }}
                    onChange={(e: any) => handleScenarioToCompare(scenarios.find(scenario => scenario.value === e))}
                    options={scenarios}
                    {...selectProps}
                />
            </Col>
            <Col style={{ paddingLeft: 20 }}>
                <p>{i18n.t<string>("year_comparison")}</p>
                <Select
                    defaultValue={parseFloat(moment().format("YYYY"))}
                    style={{ width: 200 }}
                    onChange={handleYearToCompare}
                    options={optionsYears}
                    {...selectProps}
                />
            </Col>
            <Col style={{ paddingLeft: 20 }}>
                <Radio.Group onChange={handleBaseTarget} value={baseTarget} defaultValue={"budgeted"}>
                    <Space direction="vertical">
                        <Radio value={"budgeted"}>{i18n.t<string>("budgeted")}</Radio>
                        <Radio value={"accomplished"}>{i18n.t<string>("accomplished")}</Radio>
                    </Space>
                </Radio.Group>
            </Col>
        </Row>
    )
}