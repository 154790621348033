import i18n from "util/base/i18n";

export default function NoPermissions() {
    return (
        <div className="budget-initial-content">
            <img src="/assets/images/500.png" alt="wfn-budget" />
            <h2>{i18n.t("you_do_not_have_budget_shapes_permissions")}</h2>
            <p>{i18n.t("request_administrator_access")}</p>
        </div>
    )
}