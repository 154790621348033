import { Icon } from "@iconify/react";
import { Input, Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import { Key, useState } from "react";
import i18n from "util/base/i18n";
import StringUtil from "util/StringUtil";
const { Search } = Input

interface Props {
	data: DataNode[];
	selectedSideKey: number;
	setSelectedSideKey: (selected: number) => void;
}

export default function SiderContent({ data, setSelectedSideKey, selectedSideKey }: Props) {
	const [searchValue, setSearchValue] = useState("");

	function onSelect(selected: Key[]) {
		setSelectedSideKey(Number(selected[0]));
	}

	function onChangeSearch({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		setSearchValue(value);
	}

	return (
		<div className="sider-content">
			<Search
				value={searchValue}
				onChange={onChangeSearch}
				placeholder={i18n.t("search")}
			/>
			<Tree
				selectable
				treeData={data.filter(item => StringUtil.getWithoutSpecials(item.title.toString()).includes(StringUtil.getWithoutSpecials(searchValue)))}
				selectedKeys={[selectedSideKey]}
				onSelect={onSelect}
			/>
		</div>
	)
}