import * as React from "react";

import {
	Chart,
	DateRange
} from "components";

import {
	Button,
	Tooltip
} from "antd";

import { ChartType } from "components/chart/IChart";

import {
	ChartData,
	IChartWidgetProps,
	IChartWidgetState,
	IWidgetResponse
} from "./IChartWidget";

import i18n from "util/base/i18n";
import moment from "moment";

import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

import { Icon } from "@iconify/react";
import { LoadingOutlined } from "@ant-design/icons";

export default class ChartWidget extends React.Component<IChartWidgetProps, IChartWidgetState> {

	constructor(props: IChartWidgetProps) {
		super(props);

		const startDate = moment(props.startDate);
		const endDate = moment(props.endDate);

		this.state = {
			startDate,
			endDate,
			data: [],
			paletteColors: "#599FD1",
			plotGradientColor: "#C4E4FB",
			loading: false,
			useCache: true,
			cached: false
		};
	}

	componentDidMount(): void {
		this.loadData();
	}

	shouldComponentUpdate(nextProps: Readonly<IChartWidgetProps>): boolean {
		const {
			currencyId: prevCurrencyId,
			globalFilter: {
				filterBy: prevFilterBy,
				values: prevValues
			}
		} = this.props;

		const {
			currencyId: nextCurrencyId,
			globalFilter: {
				filterBy: nextFilterBy,
				values: nextValues
			}
		} = nextProps;

		if (prevCurrencyId !== nextCurrencyId || prevFilterBy !== nextFilterBy || prevValues !== nextValues) {
			const {
				data,
			} = this.state;

			this.setState({
				data: data.map(({ label }) => {
					return {
						label,
						value: 0
					}
				})
			}, this.loadData.bind(this));
		}

		return true;
	}

	loadData(): void {
		this.setState({ loading: true });

		const {
			startDate,
			endDate,
			useCache
		} = this.state;

		const {
			globalFilter: {
				filterBy,
				values
			},
			currencyId
		} = this.props;

		const filterValuesQuery = values.map(value => `filterValue=${value}`).join("&");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/financing/debit-progression?scenario={scenario}&currency=${currencyId}&filterBy=${filterBy}&${filterValuesQuery}&client={client}&user={user}&startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}&useCache=${useCache}`
		}, this.onLoadData.bind(this))
	}

	onLoadData(response: IWidgetResponse): void {
		this.setState({
			data: response.data.sort(({ label: label1 }, { label: label2 }) => {
				const date1: moment.Moment = moment(label1, "MM/YYYY");
				const date2: moment.Moment = moment(label2, "MM/YYYY");

				if (date1.isSame(date2)) {
					return 0;
				} else if (date1.isBefore(date2)) {
					return -1;
				} else {
					return 1;
				}
			}).map(({ label, value, isoCode }) => {
				const {
					0: month,
					1: year
				} = label.split("/");

				return this.buildChartData(Number(month)-1, Number(year), value, isoCode);
			}),
			loading: false,
			useCache: false,
			cached: response.cached,
			cacheDate: response.date
		});
	}

	buildChartData(month: number, year: number, value: number, isoCode?: string): ChartData {
		let formattedValue: string = "0";

		if (isoCode) {
			formattedValue = new Intl.NumberFormat(i18n.language.split("_")[0], {
				style: "currency",
				currencyDisplay: "symbol",
				currency: isoCode
			}).format(value);
		}

		return {
			label: `<b>${i18n.t<string>(`month.medium.${month}`)} <br /> ${year}</b>`,
			value,
			isoCode,
			tooltext: `
				<div class="chart-tooltip-parent">
					<b>${formattedValue}</b>
					<br />
					<div>${i18n.t<string>(`month.medium.${month}`) + " " + year}</div>
				</div>
			`
		}
	}

	onChangeDate(dates: moment.Moment[]): void {
		let [
			newStartDate,
			newEndDate
		] = dates;

		const {
			startDate,
			endDate
		} = this.state;

		if (startDate.isSame(newStartDate) && endDate.isSame(newEndDate)) {
			this.loadData();
		} else {
			const {
				filterId
			} = this.props;

			newEndDate = newEndDate.endOf("month");

			this.setState({
				startDate: newStartDate,
				endDate: newEndDate
			});

			ServiceCaller.doRequest({
				type: RequestType.PUT,
				url: "/dashboard-analytics/widget/filter",
				params: {
					id: filterId,
					startDate: newStartDate.format("YYYY-MM-DD"),
					endDate: newEndDate.format("YYYY-MM-DD")
				}
			}, this.loadData.bind(this));
		}
	}

	render() {
		const {
			startDate,
			endDate,
			data,
			paletteColors,
			plotGradientColor,
			loading,
			cached,
			cacheDate
		} = this.state;

		return <>
			<div className="widget-header-actions-container">
				{
					cached && <>
						<Tooltip title={ i18n.t<string>("dashboard.analytics.text_002", {date: new Intl.DateTimeFormat(i18n.language.replace("_", "-"), { dateStyle: "short", timeStyle: "medium" }).format(new Date(cacheDate))}) }>
							<Button onClick={this.loadData.bind(this)} type="text" size="large" icon={<Icon icon={"mdi:reload-alert"} />} disabled={loading} data-html2canvas-ignore="true" />
						</Tooltip>
					</>
				}

				<DateRange format="MMM YYYY" picker="month" formatEdit="MM/YYYY" value={[startDate, endDate]} onChange={this.onChangeDate.bind(this)} disabled={loading} />
			</div>

			{
				loading ? <>
					<div className="loading-container">
						<LoadingOutlined />
					</div>
				</> : <Chart id="widget-1" type={ChartType.COLUMN_2D} config={{ paletteColors, plotGradientColor, showValues: true }} data={data}></Chart>
			}
		</>
	}

}
