import * as React from "react";
import moment from "moment";

import { DefaultRecordType } from "rc-table/lib/interface";

import { Icon } from "@iconify/react";
import { LoadingOutlined } from '@ant-design/icons';

import {
	Space,
	Table,
	Tooltip
} from "antd";

import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import {
	ICashFlowData,
	ICashFlowMode
} from "./ICashFlowWidget";

import i18n from "util/base/i18n";
import Button from "antd/es/button";

import { IChartWidgetProps, IWidgetResponse } from "./IChartWidget";

import CashFlowStepperWidget from "./CashFlowStepperWidget";

import { DateRange } from "components";
import { FormattedNumber } from "react-intl";
import { useUserContext } from "context/UserContext";

export default function CashFlowWidget(props: IChartWidgetProps) {

	const [mode, setMode]: [ICashFlowMode, Function] = React.useState(props.content.flowId !== null ? ICashFlowMode.FLOW_VIEW : ICashFlowMode.SELECTION_STEP);

	const [loading, setLoading]: [boolean, Function] = React.useState(false);

	const [startDate, setStartDate]: [moment.Moment, Function] = React.useState(moment(props.startDate));
	const [endDate, setEndDate]: [moment.Moment, Function] = React.useState(moment(props.endDate));

	const [flowId, setFlowId]: [number, Function] = React.useState(props.content.flowId);
	const [levels, setLevels]: [number[], Function] = React.useState(props.content.levels);
	const [budgetId, setBudgetId]: [number, Function] = React.useState(props.content.budgetId);

	const [useCache, setUseCache]: [boolean, Function] = React.useState(true);

	const renderColumnValue = (value: number, { isoCode }): React.ReactElement => {
		return <FormattedNumber value={value || 0} style={`currency`} currencyDisplay="symbol" currency={isoCode || "BRL"} />
	};
	
	const { userInfo } = useUserContext();

	let budgetGridColumns: DefaultRecordType[] = [];

	if (budgetId != null && budgetId !== 0) {
		budgetGridColumns = [{
			title: i18n.t<string>("budgeted"),
			dataIndex: "budget",
			key: "budget",
			render: renderColumnValue
		}, {
			title: i18n.t<string>("variation"),
			dataIndex: "variation",
			key: "variation",
			render: (variation: number): React.ReactElement => {
				return <FormattedNumber value={variation} style={`percent`} minimumFractionDigits={2} maximumFractionDigits={2} />
			}
		}];
	}

	const flowGridColumns: DefaultRecordType[] = [{
		title: i18n.t<string>("financial_demonstrative"),
		dataIndex: "description",
		key: "description"
	}, {
		title: i18n.t<string>("forecast"),
		dataIndex: "provided",
		key: "provided",
		render: renderColumnValue
	}, {
		title: i18n.t<string>("accomplished"),
		dataIndex: "accomplished",
		key: "accomplished",
		render: renderColumnValue
	},
	...budgetGridColumns,
	{
		width: 60,
		title: userInfo.plan != "FREEMIUM" ? <>
			<Tooltip title={i18n.t<string>("change_selection")}>
				<Button type="text" size="large" icon={<Icon icon={"fluent:text-bullet-list-square-edit-24-regular"} width={24} />} onClick={setMode.bind(this, ICashFlowMode.SELECTION_STEP)} disabled={loading} />
			</Tooltip>
		</> : null
	}];

	const [flowGridData, setFlowGridData]: [ICashFlowData[], Function] = React.useState([]);

	const [cached, setCached]: [boolean, Function] = React.useState(false);
	const [cacheDate, setCacheDate]: [Date, Function] = React.useState(null);

	const loadGrid = (): void => {
		if (mode === ICashFlowMode.SELECTION_STEP) {
			return;
		}

		setLoading(true);
		setFlowGridData([]);

		const {
			currencyId,
			globalFilter: {
				filterBy,
				values
			}
		} = props;

		const startDateMillis: string = startDate.format("x");
		const endDateMillis: string = endDate.format("x");

		const levelsQuery: string = levels.map(level => `level=${level}`).join("&");
		const filterValuesQuery: string = values.map(value => `filterValue=${value}`).join("&");

		const budgetQuery: string = budgetId != null && budgetId !== 0 ? `&budget=${budgetId}` : "";

		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/cash/flow?id=${flowId}&${levelsQuery}${budgetQuery}&filterType=${filterBy}&${filterValuesQuery}&currency=${currencyId}&startDate=${startDateMillis}&endDate=${endDateMillis}&useCache=${useCache}`,
		}, onLoadGrid.bind(this));
	};

	const onLoadGrid = (response: IWidgetResponse) => {
		setCached(response.cached);
		setCacheDate(response.date);
		setFlowGridData(response.data);

		setLoading(false);
		setUseCache(false);
	};

	React.useEffect(loadGrid, [mode, startDate, endDate, props.currencyId, props.globalFilter]);

	const reloadFlowView = (flowId: number, levels: number[], budgetId: number) => {
		setFlowId(flowId);
		setLevels(levels);
		setBudgetId(budgetId);

		setMode(ICashFlowMode.FLOW_VIEW);
	};

	const onChangeDate = (dates: Array<moment.Moment>): void => {
		const [
			newStartDate,
			newEndDate
		] = dates;

		if (startDate.isSame(newStartDate) && endDate.isSame(newEndDate)) {
			loadGrid();
		} else {
			const {
				filterId
			} = props;

			setStartDate(newStartDate);
			setEndDate(newEndDate);

			ServiceCaller.doRequest({
				type: RequestType.PUT,
				url: "/dashboard-analytics/widget/filter",
				params: {
					id: filterId,
					startDate: newStartDate.format("YYYY-MM-DD"),
					endDate: newEndDate.format("YYYY-MM-DD")
				}
			});
		}
	};

	if (mode === ICashFlowMode.SELECTION_STEP) {
		return <CashFlowStepperWidget contentId={props.content.id} flowId={flowId} levels={levels} budgetId={budgetId} reloadView={reloadFlowView} />;
	} else {
		return <>
			<div className="widget-header-actions-container">			
				{
					cached && <>
						<Tooltip title={ i18n.t<string>("dashboard.analytics.text_002", {date: new Intl.DateTimeFormat(i18n.language.replace("_", "-"), { dateStyle: "short", timeStyle: "medium" }).format(new Date(cacheDate))}) }>
							<Button onClick={loadGrid.bind(this)} type="text" size="large" icon={<Icon icon={"mdi:reload-alert"} />} disabled={loading} data-html2canvas-ignore="true" />
						</Tooltip>
					</>
				}

				<DateRange format="DD/MMM" formatEdit="DD/MM/YYYY" picker="date" value={[startDate, endDate]} onChange={onChangeDate.bind(this)} disabled={loading} />
			</div>

			<Space direction="vertical" size={10}>
				<Table columns={flowGridColumns} dataSource={flowGridData} pagination={false} rowKey="id" scroll={{
					y: 275
				}} locale={{emptyText: loading ? <LoadingOutlined style={{fontSize: "40px", padding: "100px 0"}} /> : null}} />
			</Space>
		</>
	}

}
