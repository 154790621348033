import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Key, useEffect, useState } from "react";
import { Hired, HiredTableList, HiredTable } from "../IHumanResoucers";
import i18n from "util/base/i18n";
import AllocationModal from "./allocationModal/AllocationModal";
import { useAllocationModalContext } from "./allocationModal/AllocationModalContext";
import { FlexFilter } from "util/types/types";

interface Props {
	hiredList: HiredTable[];
	isFetching: boolean;
	setDeitalId: (id: number) => void;
	setIsDashboard: (value: boolean) => void;
	tableColumns: ColumnsType;
	selectedRowKeys: Key[];
	onChange: (selectedRowKeys: Key[], selectedRows: HiredTableList[]) => void;
	flexFieldValues: FlexFilter[];
	setFlexFieldValues: React.Dispatch<React.SetStateAction<FlexFilter[]>>;
	getTableData: () => void;
}
const allocatedIcon = <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M4.17857 0C4.94754 0 5.57143 0.623594 5.57143 1.39286V4.17857C5.57143 4.94754 4.94754 5.57143 4.17857 5.57143H1.39286C0.623594 5.57143 0 4.94754 0 4.17857V1.39286C0 0.623594 0.623594 0 1.39286 0H4.17857ZM3.71429 1.85714H1.85714V3.71429H3.71429V1.85714ZM4.17857 7.42857C4.94754 7.42857 5.57143 8.05245 5.57143 8.82143V11.6071C5.57143 12.3761 4.94754 13 4.17857 13H1.39286C0.623594 13 0 12.3761 0 11.6071V8.82143C0 8.05245 0.623594 7.42857 1.39286 7.42857H4.17857ZM3.71429 9.28571H1.85714V11.1429H3.71429V9.28571ZM7.42857 1.39286C7.42857 0.623594 8.05245 0 8.82143 0H11.6071C12.3761 0 13 0.623594 13 1.39286V4.17857C13 4.94754 12.3761 5.57143 11.6071 5.57143H8.82143C8.05245 5.57143 7.42857 4.94754 7.42857 4.17857V1.39286ZM9.28571 3.71429H11.1429V1.85714H9.28571V3.71429ZM10.2143 12.0714H11.1429V13H10.2143V12.0714ZM13 13H12.0714V12.0714H13V13ZM12.0714 7.42857H13V11.1429H10.2143V10.2143H9.28571V13H7.42857V7.42857H10.2143V8.35714H12.0714V7.42857Z" fill="#0D2F48" />
</svg>
export function HiredList({
	hiredList,
	isFetching,
	setDeitalId,
	tableColumns,
	setIsDashboard,
	selectedRowKeys,
	onChange,
	flexFieldValues,
	setFlexFieldValues,
	getTableData
}: Props) {
	const [tableData, setTableData] = useState<HiredTableList[]>([])
	const rowSelection = { selectedRowKeys, onChange, };
	const {
		setIsOpen,
		setResourcesList,
		isDisabledAllocatedButton,
		setResourceSelected,
		setIsEditing
	} = useAllocationModalContext();

	function onClickAllocation() {
		if (selectedRowKeys.length === 1) {
			const resourceSelected = hiredList.find(({ id }) => id === selectedRowKeys[0]);
			setResourceSelected(resourceSelected);
			setIsEditing(resourceSelected.isAllocated);
			setIsOpen(!resourceSelected.isAllocated);
		} else {
			setResourcesList(hiredList.filter(({ id }) => selectedRowKeys.some((key) => key === id)));
			setIsOpen(true);
		}
	}

	function verifyIsClickOnIcon(target: EventTarget) {
		const stringTarget: string = target.toString().toLowerCase();
		return stringTarget.includes("path") || stringTarget.includes("svg");
	}

	useEffect(() => {
		if (!hiredList) return
		const data: HiredTableList[] = hiredList.map(hired => {
			return {
				key: hired.id,
				businessUnit: hired.businessUnit.label,
				collaborator: hired.resourceName,
				costCategory: hired.costCategory.label,
				costCenter: hired.costCenter.label,
				professionalCategory: hired.professionalCategory.label,
				isAllocated: !!hired.isAllocated
			}
		})
		setTableData(data);
	}, [hiredList])

	return (
		<div className="hired-table-container">
			<div className="hired-header">
				<h3>{i18n.t("collaborators_name")} <span>({hiredList.length})</span></h3>
				<div className="hired-budget">
					<span className="gs-tag blue">{i18n.t("menu.module.budget")}</span>
					<Button
						className="collaborator-header-right-buttons"
						onClick={onClickAllocation}
						disabled={isDisabledAllocatedButton}
						icon={allocatedIcon}
					>
						{i18n.t("human_resources.allocate_resource")}
					</Button>
				</div>
			</div>
			<Table
				loading={{
					spinning: isFetching,
					tip: `${i18n.t<string>("loading")}...`
				}}
				onRow={(record: Hired) => {
					return {
						onClick: ({ target }) => {
							if (!verifyIsClickOnIcon(target)) {
								setDeitalId(Number(record.key));
								setIsDashboard(true);
							}
						}
					}
				}}
				className="hired-table"
				dataSource={tableData}
				rowClassName={({ isAllocated }: HiredTable) => {
					let defaultRowClass = 'hired-table-row';

					if (isAllocated) defaultRowClass += (" allocated-row")
					return defaultRowClass;
				}}
				columns={tableColumns}
				rowSelection={rowSelection}
				pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 100 }}
			/>
			<AllocationModal
				flexFieldValues={flexFieldValues}
				setFlexFieldValues={setFlexFieldValues}
				getTableData={getTableData}
			/>
		</div>
	)
}