export const tableProps = {
	pagination: { hideOnSinglePage: true },
	className: "gs-table",
	bordered: true
}
export const tabProps = {
	className: "gs-tab",
	type: "card",
	moreIcon: null
} as {type: 'card'}

export const selectProps = {
	showSearch: true,
	optionFilterProp: "children",
	filterOption: (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
	filterSort: (optionA, optionB) =>
		(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
}