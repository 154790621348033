import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from "./useCachedQuery";
import { RequestType } from "util/service/IServiceCaller";
import { Options } from "util/types/types";

export function useBusinessUnitOptions(queryProps = {} as UseQueryOptions<Options[]>) {
    const queryInfo = useCachedQuery<Options[]>(
        ['business-unit-options'],
        {
            type: RequestType.GET,
            url: '/monolith/business-unit/findAllByOrganizationIdWithSecurity?localeId={locale}&clientId={client}&organizationId={organization}&userId={user}',
        },
        queryProps,
        (data) => data.map((item) => ({
            label: item.name,
            value: item.id,
        }))
    )
    
    return queryInfo;
}

