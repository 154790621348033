import { DataLevels, LevelItem } from "../IGroupings";

export function getLevelById(id: number, dataLevels: DataLevels[] | LevelItem[]): number {
    let level = 0;

    function searchNode(node: DataLevels, currentLevel: number) {
        currentLevel++;

        if (node.key === id) {
            level = currentLevel;
            return;
        }

        node.children.forEach(child => {
            searchNode(child, currentLevel);
        });
    }

    dataLevels.forEach(root => {
        searchNode(root, 0);
    });

    return level;
}