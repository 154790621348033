import { Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useBudgetDates } from "hooks/useBudgetDates";
import moment from "moment";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { verifyBudgetPeriod } from "util/functions/verifyBudgetPeriod";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ModuleType } from "util/types/types";
import {
    DefaultColumn,
    ITableContainer,
    ITableData,
    ITableDataResponse,
    LevelItem,
    Period
} from "../IAccountingBalances";

export default function TableContainer({
    isLoadingTable,
    setIsLoadingTable,
    treeData,
    period,
    costCenterId,
    checkedKeys,
    isAccomplished,
    ledgerId,
    companyFlexId,
}: ITableContainer) {
    const [columns, setColumns] = useState<ColumnsType<ITableData>>([]);
    const [data, setData] = useState<ITableData[]>([]);

    const { data: budgetPeriodDates } = useBudgetDates(ModuleType.PLANNING);

    const defaultColumn: DefaultColumn = {
        key: "description",
        dataIndex: "description",
        title: i18n.t("description"),
    };

    useEffect(() => {
        setIsLoadingTable(true);

        if (!checkedKeys) return;

        if (Array.isArray(period) && period.length !== 2) return;

        const [startDate, endDate] = Array.isArray(period)
            ? period.map((date) => moment(date).format("YYYY-MM-DD"))
            : [moment(period).format("YYYY-MM-DD")];

        const nodeSelected: LevelItem = findNodeTree(treeData, checkedKeys[0]);
        let url = `/planning/planning-balance/find-summarized-values?startDate=${startDate}&endDate=${endDate}&accomplished=${
            isAccomplished === 2
        }&scenarioId={scenario}&organizationId={organization}&hierarchyId=${
            nodeSelected?.children.length > 0
                ? nodeSelected.key
                : typeof nodeSelected.key === "string"
                ? nodeSelected.key.split("-")[0]
                : nodeSelected.key
        }&ledgerId=${ledgerId}`;

        if (nodeSelected?.children.length === 0) {
            url += `&costCenterId=${nodeSelected.classificationId}`;
        }

        if (companyFlexId > 0) {
            url += `&companyFlexId=${companyFlexId}`;
        }

        fetchSummarizedValues(url);
    }, [period, costCenterId, isAccomplished, checkedKeys, ledgerId, companyFlexId]);

    function fetchSummarizedValues(url) {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: url,
            },
            handleResponse
        );
    }

    const getCurrentBudgetPeriod = (date: moment.Moment): Period => {
        const yearSelected = date.year();
        return budgetPeriodDates.period.find((period) => period.year === yearSelected);
    };

    const getBudgetPeriodClass = (date: moment.Moment): string => {
        const currentPeriod: Period = getCurrentBudgetPeriod(date);
        if (currentPeriod) {
            const { className } = verifyBudgetPeriod(
                currentPeriod,
                date,
                budgetPeriodDates.localDate,
                ModuleType.PLANNING
            );

            return className;
        }
        return "";
    };

    function handleResponse(response: ITableDataResponse[]) {
        const [startDate, endDate] = Array.isArray(period)
            ? period.map((date) => moment(date).format("YYYY-MM-DD"))
            : [moment(period).format("YYYY-MM-DD")];

        const startOfMonth = moment(startDate).startOf("month");
        const endOfMonth = moment(endDate).endOf("month");

        const newColumns: DefaultColumn[] = [];
        let currentDate = startOfMonth.clone();
        while (currentDate.isSameOrBefore(endOfMonth, "month")) {
            const currentDateFormatted = currentDate.format("YYYY-MM");
            const className = getBudgetPeriodClass(currentDate);

            const colTitle: string = currentDate.format("MMM").toUpperCase() + "/" + currentDate.year();
            const newCol = {
                title: colTitle,
                dataIndex: currentDateFormatted,
                key: currentDateFormatted,
                className: className,
                render: (value: number) => formatCurrency(value),
            };

            newColumns.push(newCol);
            currentDate.add(1, "month");
        }

        const formatCurrency = (value: number): string => {
            return new Intl.NumberFormat("pt-BR", {
                notation: "standard",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value);
        };

        const newData: ITableData[] = response.map(({ line, months }) => {
            const i18nLine = i18n.t(`${line.replace(/\./g, "_")}`);
            const newLine = { description: i18nLine };

            newColumns.forEach(({ key }) => {
                let value = 0.00;
                const month = months.find(({ yearMonth }) => yearMonth === key);
                if (month && month.yearMonth === key) {
                    value = month.value;
                }

                Object.assign(newLine, { [key]: value });
            });
            return newLine;
        });

        setColumns([defaultColumn, ...newColumns]);
        setData(newData);
        setIsLoadingTable(false);
    }

    function findNodeTree(lines: LevelItem[], key: Key): LevelItem {
        let obj = null;

        for (let l = lines.length; l--; ) {
            const level = lines[l];

            if (level.key === key) {
                obj = level;
                break;
            } else if (level.children.length > 0) {
                obj = findNodeTree(level.children, key);

                if (obj) {
                    break;
                }
            }
        }

        return obj;
    }

    return (
        <Spin spinning={isLoadingTable}>
            <div className="content-tables">
                <Table
                    scroll={{ x: true, y: 580 }}
                    className="gs-table"
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        showSizeChanger: true,
                        hideOnSinglePage: data.length < 10 ? true : false,
                        defaultPageSize: 100,
                    }}
                />
            </div>
        </Spin>
    );
}
