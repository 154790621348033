import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useContext } from "react";
import i18n from "util/base/i18n";
import { IAttribute } from "../../IAttributeParameterization";
import { AttributeParameterizationContext } from "../../context/AttributeParameterizationContext";

interface AttributeProps {
    data: {
        attribute: any
        handleDeleteAttribute: (attribute: IAttribute) => void
        openEditAttributeModal: (attribute: any) => void
    }
}

export default function Attribute(props: AttributeProps) {
    const { selectedAttribute, handleSelectedAttribute } = useContext(AttributeParameterizationContext);

    return (
        <li
            className={
                selectedAttribute?.id ===
                    props.data.attribute.id
                    ? "attribute_parameterization-attribute-option attribute_parameterization-attribute-option-active"
                    : "attribute_parameterization-attribute-option"
            }
            onClick={() => handleSelectedAttribute(props.data.attribute)}
        >
            <span>
                {props.data.attribute.formulaAttribute ?
                    i18n.t<string>(props.data.attribute.name) : props.data.attribute.name}
            </span>
            <>
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <Menu
                            style={{
                                width: 140,
                            }}
                        >
                            <Menu.Item
                                onClick={() => props.data.openEditAttributeModal(props.data.attribute)}
                                key="1"
                            >
                                <span>Editar</span>
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => props.data.handleDeleteAttribute(props.data.attribute)}
                                key="2"
                            >
                                <span>Excluir</span>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button
                        style={{
                            border: 0,
                            background: "none",
                            outline: "none",
                            boxShadow: "none",
                        }}
                    >
                        <MoreOutlined
                            style={{
                                fontSize: "24px",
                            }}
                        />
                    </Button>
                </Dropdown>
            </>
        </li>
    );
}
