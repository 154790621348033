import { Button, Col, Form, Input, Modal, Row, Select, Table } from "antd";
import FormItem from "antd/es/form/FormItem";
import { Rule } from "antd/lib/form";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { IAllocationDefaultFields, IAllocationLink, IAllocationTableData, INewAllocationModalProps } from "../IAllocationParameterization";
import { useForm } from "antd/es/form/Form";
import NewAllocationTable from "./NewAllocationTable";
import { CurrencyDetailLayoutSelect } from "module/budget/pages/detailLayout/IDetailLayout";
import { Notification } from "components/notification/Notification";
import { handleErrorRequest } from "util/functions/handleErrorRequest";

export default function NewAllocationModal({
	isOpen,
	setIsModalOpen,
	rowForEdit
}: INewAllocationModalProps) {
	const [isFetchingAccounting, setIsFetchingAccounting] = useState(true);
	const [accountingList, setAccountingList] = useState<IAllocationLink[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const defaultRules: Rule[] = [{ required: true, message: i18n.t<string>("required_field") }];
	const [form] = useForm();

	function handleCancel() {
		setIsModalOpen(false);
		form.resetFields();
		setAccountingList([]);
		setIsLoading(false);
	}

	function validateAccountList(): boolean {
		return accountingList.every(({ isOriginalValue = false, accountingAccountId }) => {
			let isValid: boolean = true;
			if (isOriginalValue && accountingAccountId) isValid = false;
			if (!isOriginalValue && !accountingAccountId) isValid = false;
			return isValid;
		})
	}

	function onSave() {
		handleCancel();
		Notification({
			type: "success",
			message: i18n.t("hr_allocations.successfully_message_modal")
		})
	}

	function onFinishForm({ description, externalCode }: IAllocationDefaultFields) {
		if (!validateAccountList()) {
			Notification({
				message: i18n.t("hr_allocations.error_message_modal"),
				type: "warning"
			})
			return;
		}
		const allocationLink: IAllocationLink[] = accountingList.map((item) => {
			return ({
				...item,
				id: rowForEdit ? item.id : null,
			})
		})
		const dataToSave: IAllocationTableData = {
			description,
			externalCode,
			allocationLink,
			id: rowForEdit ? rowForEdit.id : null
		}

		setIsLoading(true);

		ServiceCaller.doRequest({
			type: rowForEdit ? RequestType.PUT : RequestType.POST,
			url: "/human-resources/allocation",
			params: dataToSave
		}, onSave, handleErrorRequest)
	}

	useEffect(() => {
		if (!isOpen) return;
		setIsFetchingAccounting(true);
		if (rowForEdit) {
			const { allocationLink, description, externalCode } = rowForEdit;
			setAccountingList(allocationLink.map((item) => {
				return ({
					...item,
					description: item.resourceAccount.description,
					externalCode: item.resourceAccount.externalCode
				})
			}));

			form.setFieldsValue({
				description,
				externalCode
			})
			setIsFetchingAccounting(false);
		} else {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/human-resources/resource-account/find-all?organizationId={organization}`,
			}, (data: IAllocationLink[]) => {
				setAccountingList(data.map((item) => {
					return ({
						...item,
						resourceAccount: {
							id: item.id
						},
						id: item.id
					})
				}));
				setIsFetchingAccounting(false);
			})

		}

	}, [isOpen, rowForEdit])

	return (
		<Modal
			open={isOpen}
			onCancel={handleCancel}
			width={1000}
			title={i18n.t("human_resources.allocate_resource")}
			okButtonProps={{
				className: "gs-main-button",
				loading: isLoading,
				disabled: !accountingList.length,
				htmlType: "submit",
				form: "allocation-modal-form"
			}}
			okText={i18n.t<string>("save")}
			cancelButtonProps={{ type: "text" }}
			cancelText={i18n.t<string>("close")}
			className="new-allocation-modal"
			centered
		>
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinishForm}
				name="allocation-modal-form"
			>
				<Row gutter={7}>
					<Col span={5}>
						<FormItem
							label={i18n.t("code")}
							name={"externalCode"}
							rules={defaultRules}
						>
							<Input placeholder={i18n.t("type_here")} />
						</FormItem>
					</Col>
					<Col span={8}>
						<FormItem
							label={i18n.t("description")}
							name={"description"}
							rules={defaultRules}
						>
							<Input placeholder={i18n.t("type_here")} />
						</FormItem>
					</Col>
				</Row>
			</Form>
			<NewAllocationTable
				tableData={accountingList}
				isTableDataFetching={isFetchingAccounting}
				setAccountingList={setAccountingList}
			/>
		</Modal>
	)
}