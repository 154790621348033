import { Table } from "antd";
import i18n from "util/base/i18n";
import { FlexFieldValuesTableProps } from "../IFlexFieldValues";

export function FlexFieldValuesTable({ isFetching, onChange, selectedRowKeys, tableData, tableColumns }: FlexFieldValuesTableProps) {
	const rowSelection = { selectedRowKeys, onChange };

	const data = tableData.map((line) => {
		return (
			{
				key: line.id,
				...line
			}
		)
	})

	return (
		<Table
			dataSource={data ?? []}
			columns={tableColumns}
			rowSelection={rowSelection}
			className="gs-table flex-field-table"
			bordered
			pagination={{ hideOnSinglePage: true }}
			scroll={tableColumns.length > 5 && {x: 1000}}
			loading={{
				spinning: isFetching,
				tip: `${i18n.t<string>("loading")}...`
			}}
		/>
	)
}