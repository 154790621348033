import { Notification } from "components/notification/Notification";
import i18n from "util/base/i18n";
import { ErrorRequest } from "util/types/types";

export function handleErrorRequest(err: ErrorRequest) {
    Notification({
        type: 'error',
        message: err.message ? i18n.t(err.message) : err.exception ? err.exception  : i18n.t("notifications.something_went_wrong"),
    })
}
