import * as React from "react";
import ReactDOM from "react-dom";
import App from "./util/base/app/App.tsx";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { Router } from "router";
import { UserProvider } from "context/UserContext";
import "./index.sass";
import { ErrorBoundary } from "react-error-boundary";
import { PermissionProvider } from "context/PermissionContext";
import { ErrorFallback } from "components/error/ErrorFallback";
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from "util/queryClient";
import { ModalFilterProvider } from "context/ModalFilterContext";

ReactDOM.render(
	<React.StrictMode>
		<App>
			<QueryClientProvider client={queryClient}>
				<UserProvider>
					<BrowserRouter>
						<ErrorBoundary FallbackComponent={ErrorFallback}>
							<PermissionProvider>
								<ModalFilterProvider>
									<Router />
								</ModalFilterProvider>
							</PermissionProvider>
						</ErrorBoundary>
					</BrowserRouter>
				</UserProvider>
				{/* <ReactQueryDevtools initialIsOpen={false} /> */}
			</QueryClientProvider>
		</App>
	</React.StrictMode>,

	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
