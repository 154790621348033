import { Modal } from "antd";
import i18n from "util/base/i18n";

export default function ConfirmDelete({handleOk, isModalVisible, setIsModalVisible}){

    function onCancel(){
        setIsModalVisible(!isModalVisible)
    }

    return (
        <Modal
            title={i18n.t("human_resources.delete_hiring")}
            visible={isModalVisible}
            onCancel={onCancel}
            cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("confirm")}
			onOk={handleOk}
        >
            {i18n.t("human_resources.confirm_delete_hiring")}
        </Modal>
    )
}