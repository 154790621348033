import { BudgetGridData } from "../../../IFixedExpense"

export function updateRowTotals(budgetGridData: BudgetGridData) {
	budgetGridData.plannedTotal = 0
	budgetGridData.historicTotal = 0
	budgetGridData.realizedTotal = 0
	budgetGridData.projectedTotal = 0
	budgetGridData.apportionmentTotal = 0
	Object.keys(budgetGridData.plannedMonths).forEach(month => {
		budgetGridData.plannedTotal += budgetGridData.plannedMonths[month].plannedValue
		budgetGridData.historicTotal += budgetGridData.plannedMonths[month].historicValue
		budgetGridData.realizedTotal += budgetGridData.plannedMonths[month].realizedValue
		budgetGridData.projectedTotal += budgetGridData.plannedMonths[month].projectedValue
		budgetGridData.apportionmentTotal += budgetGridData.plannedMonths[month].apportionmentValue
	})
}