import * as React from "react";

import {
	IInputDateProps,
	IInputDateState
} from "../IDate";

import moment from "moment";
import ElementUtil from "util/ElementUtil";

export default class InputDate extends React.Component<IInputDateProps, IInputDateState> {

	constructor(props: IInputDateProps) {
		super(props);

		this.state = {
			value: props.value,
			format: props.format,
			valueText: this.getCapitalizedText(props.value.format(props.format)),
			currentPosition: 0
		};
	};

	shouldComponentUpdate(nextProps: Readonly<IInputDateProps>, nextState: Readonly<IInputDateState>): boolean {
		const {
			value,
			format,
		} = nextState;

		if (nextProps.value !== value || nextProps.format !== format) {
			this.setState({
				value: nextProps.value,
				format: nextProps.format,
				valueText: this.getCapitalizedText(nextProps.value.format(nextProps.format))
			});
		}

		return true;
	};

	onSelectValueText = (event: React.MouseEvent): void => {
		const {
			onOpen
		} = this.props;

		onOpen();

		const inputElement: HTMLInputElement = (event.target as HTMLInputElement);

		this.setSelectionRangeByPosition(inputElement, inputElement.selectionStart, true);
	};

	setSelectionRangeByPosition(inputElement: HTMLInputElement, position: number, initializePosition: boolean) {
		const [
			startRange,
			endRange
		] = this.getSelectionRangeByCaretPosition(position);

		inputElement.setSelectionRange(0, 0);
		inputElement.setSelectionRange(startRange, endRange);

		if (initializePosition) {
			this.setState({ currentPosition: startRange });
		}
	}

	getSelectionRangeByCaretPosition(caretPosition: number): number[] {		
		let start: number = caretPosition;
		let end: number = caretPosition;

		while (!this.isDateSeparator(start, start-1)) {
			start--;
		}

		while (!this.isDateSeparator(end, end+1)) {
			end++;
		}

		return [start, end];
	};

	isDateSeparator = (start: number, end: number): boolean => {
		const { format } = this.props;

		return !/[a-z]/i.test(format.substring(start, end));
	};

	onKeyUp = (event: React.KeyboardEvent): void => {
		const { code: keyCode } = event;
		const inputElement: HTMLInputElement = (event.target as HTMLInputElement);
		const {
			format,
			onOpen
		} = this.props;

		const {
			currentPosition
		} = this.state;

		onOpen();

		if (keyCode === "ArrowLeft") {
			if (inputElement.selectionStart === 0) {
				this.setSelectionRangeByPosition(inputElement, format.length, true);
			} else {
				this.setSelectionRangeByPosition(inputElement, inputElement.selectionStart-1, true);
			}
		} else if (keyCode === "ArrowUp") {
			this.setSelectionRangeByPosition(inputElement, 0, true);
		} else if (keyCode === "ArrowRight") {
			if (inputElement.selectionEnd === format.length) {
				this.setSelectionRangeByPosition(inputElement, 0, true);
			} else {
				this.setSelectionRangeByPosition(inputElement, inputElement.selectionEnd+1, true);
			}
		} else if (keyCode === "ArrowDown") {
			this.setSelectionRangeByPosition(inputElement, format.length, true);
		} else if (keyCode === "Escape") {
			inputElement.blur();
		} else {
			this.setSelectionRangeByPosition(inputElement, currentPosition, false);

			event.preventDefault();
		}
	};

	onEnterValueText = (event: React.KeyboardEvent): void => {
		const { code: keyCode, key: digit } = event;

		const inputElement: HTMLInputElement = (event.target as HTMLInputElement);

		const isEnter: boolean = keyCode.indexOf("Enter") >= 0;
		const isDigit: boolean = keyCode.startsWith("Digit") || keyCode.startsWith("Numpad");

		const {
			value,
			disabled,
			format,
			onEnter,
			onChange
		} = this.props;

		let {
			valueText,
			currentPosition
		} = this.state;

		if (disabled) {
			return;
		}

		if (isEnter) {
			inputElement.blur();

			this.setState({
				valueText: this.getCapitalizedText(value.format(format))
			}, onEnter.bind(this, value));
		} else if (!isDigit) {
			event.preventDefault();
		} else {
			valueText = valueText.substring(0, currentPosition) + digit + valueText.substring(currentPosition+1, valueText.length);
			currentPosition++;

			this.setState({
				currentPosition,
				valueText
			}, () => {
				if (currentPosition === valueText.length) {
					this.setState({
						currentPosition: 0
					});
				} else if (this.isDateSeparator(currentPosition, currentPosition+1)) {
					this.setState({
						currentPosition: currentPosition+1
					});
				}

				const valueDate = moment(valueText, format);
		
				if (valueText.length === format.length && valueDate.isValid()) {
					onChange(valueDate);
				}
			});
		}
	};

	onFocus = (event: React.MouseEvent) => {
		const { onOpen } = this.props;
		onOpen();

		const inputElement: HTMLInputElement = (event.target as HTMLInputElement);
		setTimeout(this.setSelectionRangeByPosition.bind(this, inputElement, 0, true), 0);
	};

	onBlur = () => {
		const {
			value,
			format,
			onClose
		} = this.props;

		this.setState({
			valueText: this.getCapitalizedText(value.format(format))
		});

		onClose();
	};

	disableEvent = (event: React.MouseEvent): void => {
		event.preventDefault();
	};

	getCapitalizedText = (dateText: string): string => {
		const separator: string = dateText.indexOf("/") ? "/" : " ";

		return dateText
			.split(separator)
			.map(fragment => fragment.charAt(0).toUpperCase() + fragment.substring(1))
			.join(separator);
	};

	render() {
		const { disabled } = this.props;
		const { valueText } = this.state;

		return <input type="text" onDragStart={this.disableEvent.bind(this)} onPaste={this.disableEvent.bind(this)} onMouseMove={this.disableEvent.bind(this)} readOnly={disabled} value={valueText} onClick={this.onSelectValueText.bind(this)} onKeyUp={this.onKeyUp.bind(this)} onKeyPress={this.onEnterValueText.bind(this)} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} style={{width: `${ElementUtil.getWidthOfText(valueText, "bold 14px")}px`}} />;
	};

}
