import { useState } from 'react';
import { Col, Popover, Space } from 'antd';
import { Icon } from '@iconify/react';
import { FilterPopoverProps } from './IFilters';

const FilterRadioPopover: React.FC<FilterPopoverProps> = ({ title, content, selectedValue }) => {
    const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);

    const onOpenPopover = (visible: boolean) => {
        setIsPopoverVisible(visible);
    };

    return (
        <Col id="revenue-period-filter-card-popover">
            <Popover
                open={isPopoverVisible}
                onOpenChange={onOpenPopover}
                trigger='click'
                placement="bottom"
                content={content}
            >
                <div className="filter-popover" onClick={() => setIsPopoverVisible(state => !state)}>
                    <div>
                        <Space direction="vertical" size={2}>
                            <Space>
                                <p className="filter-name" style={{ paddingTop: selectedValue ? 0 : 10 }}>
                                    {title}
                                </p>
                            </Space>
                            <div className="filter-value">
                                {selectedValue &&
                                    <p className="filter-selected-value">
                                        {selectedValue}
                                    </p>
                                }
                            </div>
                        </Space>
                    </div>
                    <Icon className="open-filter-icon" icon="akar-icons:chevron-down" />
                </div>
            </Popover>
        </Col>
    );
};

export default FilterRadioPopover;
