import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { useContext, useState } from 'react';

import i18n from 'util/base/i18n';
import { AttributeParameterizationContext } from '../../context/AttributeParameterizationContext';
import { Groupers } from './Groupers';

const { Search } = Input;

export function Sidebar() {
    const [searchText, setSearchText] = useState('');

    const { handleSelectedGrouper, openNewAttributeModal } = useContext(AttributeParameterizationContext);

    function handleCollapsePanel(id) {
        if (id === undefined) return;
        handleSelectedGrouper(id);
    }

    function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchText(event.target.value);
    }

    return (
        <Space
            direction="vertical"
            id="attribute_parameterization-content-atributes-section"
        >
            <Search
                placeholder={i18n.t<string>("search")}
                onChange={handleSearch}
            />
            <Button
                id="attribute_parameterization-new-attribute-button"
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                onClick={openNewAttributeModal}
            >
                {i18n.t<string>("revenue.new_attribute")}
            </Button>

            <Groupers
                filterText={searchText}
                handleCollapsePanel={handleCollapsePanel}
            />
        </Space>
    );
}
