import { useState } from "react";
import { Alert, Button, Checkbox, Popover, Spin, Tooltip, Input } from "antd";
import moment from "moment";
import { Icon } from "@iconify/react";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { BudgetPeriodDates, IGridOperations } from "../../../IFixedExpense";
import { useBudgetOpeningContext } from "../../../context/useBudgetOpeningContext";
import i18n from "util/base/i18n";
import { importProps } from "../utils/importProps";
import { usePermissionContext } from "context/PermissionContext";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Notification } from "components/notification/Notification";
import { PeriodBoardInfo } from "module/budget/components/PeriodBoardInfo/PeriodBoardInfo";
import { ModuleType } from "util/types/types";
import * as React from "react";

const CheckboxGroup = Checkbox.Group;

const { Search } = Input;

export function GridOperations({
    handleDelete,
    isOpenAccountDetailPopover,
    setIsOpenAccountDetailPopover,
    isOpenDeletePopover,
    setIsOpenDeletePopover,
    isOpenViewModePopover,
    setIsOpenViewModePopover,
    isDeleteButtonDisabled,
    isAddDetailButtonDisabled,
    saveStatus,
    setOpenDistributionModal,
    isDistributionButtonDisabled,
    onViewModeCheckboxChange,
    viewModeSelected,
    setViewModeSelected,
    viewModeChecked,
    setViewModeChecked,
    handleExportGrid,
    setOpenIndexerModal,
    isIndexerButtonDisabled,
    onOpenDetailModal,
    searchValue,
    setSearchValue,
}: IGridOperations) {
    const { functionalityPermissions } = usePermissionContext();
    const { budgetPeriodDates, handleRefreshAccountLinked, period, setAccountsWithValue, accountsWithValue } = useBudgetOpeningContext();
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
    const [isButtonActive, setIsButtonActive] = useState<boolean>(false);

    const onRecalculateBudget = () => {
        Notification({
            type: "info",
            message: "Recálculo iniciado...",
        });
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `expenses/budget-calculation/recalculation?client={client}&user={user}&scenario={scenario}&organization={organization}&businessUnit={businessUnit}&period=${[
                    period[0].format("YYYY-MM"),
                    period[1].format("YYYY-MM"),
                ]}`,
                params: {},
            },
            (data) =>
                Notification({
                    type: "success",
                    message: i18n.t("budget_successfully_recalculated"),
                }),
            handleErrorRequest
        );
    };

    const deletePopoverContent = (
        <div className="left-buttons-popover-container">
            <Button type="text" onClick={() => setIsOpenDeletePopover(false)}>
                {i18n.t<string>("cancel")}
            </Button>
            <Button className="gs-main-button delete-button" onClick={() => handleDelete()}>
                {i18n.t("yes")}
            </Button>
        </div>
    );

    const addDetailPopoverContent = (
        <div className="left-buttons-popover-container">
            <Button type="text" onClick={() => setIsOpenAccountDetailPopover(false)}>
                {i18n.t<string>("cancel")}
            </Button>
            <Button className="gs-main-button" onClick={() => onOpenDetailModal(null)}>
                {i18n.t("yes")}
            </Button>
        </div>
    );

    const viewModeOptions = [
        { value: "projectedValue", label: i18n.t("adjusted") },
        { value: "realizedValue", label: i18n.t("accomplished") },
        { value: "historicValue", label: i18n.t("historic") },
        { value: "apportionmentValue", label: i18n.t("apportionment") },
    ];

    const viewModePopoverContent = (
        <div className="grid-operations-viewMode-popover-container">
            <div style={{ padding: "0 10px" }}>
                <CheckboxGroup options={viewModeOptions} value={viewModeChecked} onChange={onViewModeCheckboxChange} />
            </div>
            <div className="grid-operations-viewMode-popover-footer">
                <Button
                    type="text"
                    onClick={() => {
                        setViewModeChecked(viewModeSelected);
                        setIsOpenViewModePopover(false);
                    }}
                >
                    {i18n.t<string>("cancel")}
                </Button>
                <Button
                    className="gs-main-button"
                    onClick={() => {
                        setViewModeSelected(viewModeChecked);
                        setIsOpenViewModePopover(false);
                    }}
                >
                    {i18n.t<string>("apply")}
                </Button>
            </div>
        </div>
    );

    const menuItem: ItemType[] = [
        functionalityPermissions.refreshrhlinked
            ? {
                key: "refresh-account-linked",
                label: (
                    <Button
                        className="grid-operation-importExport-btn"
                        onClick={() => {
                            handleRefreshAccountLinked();
                        }}
                        type="text"
                        icon={<Icon icon="mi:refresh" width="20" />}
                    >
                        {i18n.t("fixed_expense_texts.update_linked_accounts")}
                    </Button>
                ),
            }
            : null,
        {
            key: "recalculation",
            label: (
                <Button
                    className="grid-operation-importExport-btn"
                    onClick={() => {
                        onRecalculateBudget();
                    }}
                    type="text"
                    icon={<Icon icon="mdi:file-document-refresh-outline" width="19" />}
                >
                    Recalcular orçamentos
                </Button>
            ),
        },
    ];

    const PeriodTag = ({ budgetPeriodDates, period }: { budgetPeriodDates: BudgetPeriodDates; period: moment.Moment[] }) => {
        if (!budgetPeriodDates.period || budgetPeriodDates.period.length === 0) return null;

        const periodsWithinSelectedRange = budgetPeriodDates.period.filter(periodItem =>
            periodItem.year >= period[0].year() && periodItem.year <= period[1].year()
        );

        const periodTags = periodsWithinSelectedRange.map(periodItem => {
            const realizedTag = periodItem.expensesResourcesRealizedPeriod ? (
                <span key={`realized_${periodItem.year}`} className="gs-tag green" style={{ margin: "0 15px" }}>
                    {i18n.t("accomplished_until")}:{" "}
                    {moment(periodItem.expensesResourcesRealizedPeriod).format("MMM/YYYY")}
                </span>
            ) : null;

            const frozenTag = periodItem.expensesResourcesFrozenPeriod ? (
                <span key={`frozen_${periodItem.year}`} className="gs-tag blue" style={{ margin: "0 15px" }}>
                    {i18n.t("frozen_until")}:{" "}
                    {moment(periodItem.expensesResourcesFrozenPeriod).format("MMM/YYYY")}
                </span>
            ) : null;

            return (
                <React.Fragment key={`period_${periodItem.year}`}>
                    {realizedTag}
                    {frozenTag}
                </React.Fragment>
            );
        });

        return <>{periodTags}</>;
    }


    function handleButtonClick() {
        setIsButtonActive(!isButtonActive);
        setAccountsWithValue(!isButtonActive);
    };

    return (
        <div className="grid-operations-container">
            <div className="grid-operations-left-content">
                {functionalityPermissions.linkdetail ? (
                    <Tooltip title={i18n.t("detail_account_costs")} placement="topRight" arrowPointAtCenter={false}>
                        <Popover
                            open={isOpenAccountDetailPopover}
                            overlayClassName="currencies-popover"
                            content={addDetailPopoverContent}
                            title={i18n.t("fixed_expense_texts.want_to_add_account_detail")}
                            trigger="click"
                            placement="bottom"
                        >
                            <Button
                                className="grid-operation-button"
                                onClick={() => setIsOpenAccountDetailPopover(true)}
                                icon={<Icon style={{ fontSize: 17 }} icon="fa6-solid:chart-bar" />}
                                disabled={isAddDetailButtonDisabled}
                                style={{ color: isAddDetailButtonDisabled ? "#A6A7A7" : "#5c5c5c" }}
                            />
                        </Popover>
                    </Tooltip>
                ) : null}

                {functionalityPermissions.remove ? (
                    <Tooltip title={i18n.t("fixed_expense_texts.delete_account")}>
                        <Popover
                            overlayClassName="currencies-popover"
                            content={deletePopoverContent}
                            title={i18n.t<string>("delete_confirm_message")}
                            trigger="click"
                            placement="bottom"
                            open={isOpenDeletePopover}
                        >
                            <Button
                                className="grid-operation-button"
                                onClick={() => setIsOpenDeletePopover(true)}
                                icon={
                                    <Icon
                                        style={{
                                            fontSize: 18,
                                        }}
                                        icon="icomoon-free:bin"
                                    />
                                }
                                disabled={isDeleteButtonDisabled}
                                style={{ color: isDeleteButtonDisabled ? "#A6A7A7" : "#5c5c5c" }}
                            />
                        </Popover>
                    </Tooltip>
                ) : null}

                {functionalityPermissions.distribute ? (
                    <Tooltip title={i18n.t("human_resources.distribute_values")} placement="top">
                        <Button
                            className="grid-operation-button"
                            onClick={() => setOpenDistributionModal(true)}
                            style={{ color: isDistributionButtonDisabled ? "#A6A7A7" : "#5c5c5c" }}
                            disabled={isDistributionButtonDisabled}
                            icon={<Icon style={{ fontSize: 18 }} icon="lucide:align-horizontal-distribute-center" />}
                        />
                    </Tooltip>
                ) : null}
                {functionalityPermissions.indexation ? (
                    <Tooltip title={`${i18n.t("add")} ${i18n.t("indexer")}`} placement="top">
                        <Button
                            className="grid-operation-button"
                            onClick={() => setOpenIndexerModal(true)}
                            style={{ color: isIndexerButtonDisabled ? "#A6A7A7" : "#5c5c5c" }}
                            disabled={isIndexerButtonDisabled}
                            icon={
                                <Icon style={{ fontSize: 19, marginBottom: -1 }} icon="heroicons-outline:receipt-tax" />
                            }
                        />
                    </Tooltip>
                ) : null}
                {functionalityPermissions.vewmode ? (
                    <Popover
                        overlayClassName="currencies-popover"
                        content={viewModePopoverContent}
                        title={i18n.t("view")}
                        trigger="click"
                        placement="bottom"
                        open={isOpenViewModePopover}
                    >
                        <Tooltip
                            //color="rgba(0,0,0,0.85)"
                            title={i18n.t("fixed_expense_texts.view_mode")}
                            placement="top"
                        >
                            <Button
                                className="grid-operation-button"
                                onClick={() => setIsOpenViewModePopover(true)}
                                style={{ color: "#5c5c5c" }}
                                icon={
                                    viewModeSelected.length > 0 ? (
                                        <Icon
                                            icon="ic:round-remove-red-eye"
                                            style={{ fontSize: 20, marginBottom: -2, color: "#0065B3" }}
                                        />
                                    ) : (
                                        <Icon
                                            style={{ fontSize: 20, marginBottom: -2 }}
                                            icon="ic:outline-remove-red-eye"
                                        />
                                    )
                                }
                            />
                        </Tooltip>
                    </Popover>
                ) : null}
                <Tooltip title={i18n.t("only_active_accounts")} placement="top">
                    <Button
                        className="grid-operation-button"
                        type={isButtonActive ? 'primary' : 'default'}
                        style={{ width: 32 }}
                        onClick={handleButtonClick}
                        icon={
                            <Icon
                                icon="mdi:number-0-circle-outline"
                                style={{ color: isButtonActive ? '#0065B3' : '#5C5C5C' }}
                            />
                        }
                    />
                </Tooltip>
                {viewModeSelected.length > 0 ? (
                    <Button
                        className="viewMode-close-btn"
                        onClick={() => {
                            setViewModeChecked([]);
                            setViewModeSelected([]);
                        }}
                    >
                        <p>{i18n.t("fixed_expense_texts.active_view_mode")}</p>
                        <span>
                            <Icon icon="icon-park-solid:back" />
                            {i18n.t("exit")}
                        </span>
                    </Button>
                ) : null}
                <PeriodTag budgetPeriodDates={budgetPeriodDates} period={period} />
                <PeriodBoardInfo
                    budgetPeriodDates={budgetPeriodDates}
                    moduleType={ModuleType.EXPENSERESOURCES}
                    period={period}
                />
            </div>
            <div className="grid-operations-right-content">
                {!saveStatus.isSaving && (
                    <Alert
                        className="successfully-saved-budget-alert"
                        style={{
                            opacity: saveStatus.savedValue ? 1 : 0,
                        }}
                        message={i18n.t("changes_made_successfully")}
                        type="success"
                        showIcon
                        icon={<Icon icon="material-symbols:done-rounded" />}
                    />
                )}
                {saveStatus.isSaving && <Spin style={{ marginRight: 14 }} size={"small"} />}
                <Search value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={i18n.t("search")} />
                {functionalityPermissions.import ||
                    functionalityPermissions.export ||
                    functionalityPermissions.refreshrhlinked ? (
                    <ImportExportMenu
                        buttonType="3dots"
                        exportGridData={handleExportGrid}
                        otherFeatures={menuItem}
                        importProps={importProps}
                        importModalOpen={isImportModalOpen}
                        setImportModalOpen={setIsImportModalOpen}
                    />
                ) : null}
            </div>
        </div>
    );
}