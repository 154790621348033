import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import { IRegistrationSalesReportsTableProps } from "../IRegistrationSalesReports";

export default function RegistrationSalesReportsTable({
	onChange,
	selectedRowKeys,
	setTableData,
	tableData,
	isFetching,
	setIsFetching
}: IRegistrationSalesReportsTableProps) {
	const rowSelection = { selectedRowKeys, onChange };
	const columns: ColumnsType = [
		{
			title: i18n.t("description"),
			width: "90%",
			dataIndex: "description",
			key: "description",
		}
	]

	return (
		<Table
			columns={columns}
			pagination={{ hideOnSinglePage: true, pageSize: 15 }}
			bordered
			style={{ padding: '0 24px' }}
			loading={{
				spinning: isFetching,
				tip: `${i18n.t<string>("loading")}...`
			}}
			className="gs-table"
			rowSelection={rowSelection}
			dataSource={tableData}
			rowKey={"id"}
		/>
	)
}