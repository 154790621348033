import { Col, Form, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import { Options } from "util/types/types";
import { CurrencyConversion } from "../IBudgetProjection";

interface INewCoversionModal {
    isNewRate: boolean;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    handleSubmit: (a: any) => void;
    currenciesOptions: Options[];
    userCurrencyDefaultId: number;
    currenciesMenuList: CurrencyConversion[];
}

export function NewCoversionModal({ isNewRate, isOpen, setIsOpen, currenciesOptions, handleSubmit, userCurrencyDefaultId, currenciesMenuList }: INewCoversionModal) {
    const [destinationCurrencyOptions, setDestinationCurrencyOptions] = useState([]);
    const [form] = Form.useForm();
    const sourceCurrencySelected = Form.useWatch('originId', form);

    useEffect(() => {
        if (sourceCurrencySelected) {
            const currenciesMenuIds = currenciesMenuList.find(item => item.key === sourceCurrencySelected)?.children.map(child => child.currencyId);

            const newListOptions = currenciesOptions.filter(item => {
                if (currenciesMenuIds) {
                    return !currenciesMenuIds.includes(item.value) && item.value !== sourceCurrencySelected;
                } else {
                    return item.value !== sourceCurrencySelected;
                }
            });

            setDestinationCurrencyOptions(newListOptions);
        }
    }, [sourceCurrencySelected, isOpen]);

    return (
        <Modal
            title={i18n.t<string>("unit_of_measurement.new_conversion")}
            visible={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        handleSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okText={i18n.t<string>("save")}
            cancelText={i18n.t<string>("cancel")}
            okButtonProps={{
                htmlType: "submit",
                form: "new-conversion-form"
            }}
        >
            <Form
                name="new-conversion-form"
                form={form}
                layout="vertical"
                requiredMark={false}
                initialValues={{
                    originId: userCurrencyDefaultId
                }}
            >
                <Row style={{ width: '100%' }} gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={i18n.t<string>("budget_projection_texts.origin_currency")}
                            name="originId"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                options={currenciesOptions}
                                {...selectProps}
                                tabIndex={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={i18n.t<string>("budget_projection_texts.destination_currency")}
                            name="destinationId"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                options={destinationCurrencyOptions}
                                {...selectProps}
                                tabIndex={1}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}