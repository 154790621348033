import { Key, useEffect, useState } from "react";
import { Form } from "antd";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { CostCategoryTable } from "./components/CostCategoryTable";
import { CostCategoryData, SelectedCostCategory } from "./ICostCategory";
import { TopButtons } from "components/topButtons/TopButtons";
import i18n from "util/base/i18n";
import CostCategoryModal from "./components/CostCategoryModal";
import './style.sass'
import { Notification } from "components/notification/Notification";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { ColumnsType } from "antd/lib/table";
import { useErrorBoundary } from "react-error-boundary";
import { sortList } from "util/functions/sortList";
import { invalidadeQuery, queryClient } from "util/queryClient";
import { useUserContext } from "context/UserContext";

export default function CostCategory() {
    const [selectedRows, setSelectedRows] = useState<SelectedCostCategory[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [costCategoryList, setCostCategoryList] = useState<CostCategoryData[]>([]);
    const [tableData, setTableData] = useState<CostCategoryData[]>([]);
    const [filterdTableData, setFilterdTableData] = useState<CostCategoryData[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isNewCategory, setIsNewCategory] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const { userInfo } = useUserContext();
    const { showBoundary } = useErrorBoundary()

    const importProps: IImportModal[] = [
        {
            importUrl: "/human-resources/cost-category/import",
            templateUrl: "/human-resources/cost-category/template?locale={locale}",
            type: 'excel',
            hasImportOption: true
        },
    ]

    useEffect(() => {
        fetchCostCategoryData()
    }, []);

    useEffect(() => {
        setFilterdTableData(tableData);
    }, [tableData]);

    function fetchCostCategoryData() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/human-resources/cost-category?scenario={scenario}&organization={organization}",
            useProxy: true
        }, onLoadCostCategoryTableData.bind(this), err => showBoundary(err))
    }

    function onLoadCostCategoryTableData(data) {
        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };
        const updateTableDate = data.map(res => {
            return {
                key: res.id,
                scenarioId: res.scenarioId,
                organizationId: res.organizationId,
                externalCode: res.externalCode,
                name: res.name
            };
        }).sort((a, b) => sortList(a.externalCode, b.externalCode));
        
        setTableData(updateTableDate);
        
        setIsFetching(false);
    };

    function onSaveCostCategory(data) {
        Notification({
            type: 'success',
            message: i18n.t<string>("successfully_saved"),
        });
        invalidadeQuery(['cost-category-options'])
        fetchCostCategoryData();
        setIsNewCategory(true);
    }

    function onDeleteCostCategory(data) {
        setSelectedRowKeys([])
        setSelectedRows([])
        invalidadeQuery(['cost-category-options'])
        Notification({
            type: 'success',
            message: 'Deletado com sucesso!'
        })
        fetchCostCategoryData()
    }

    function handleOpenModal(isToEdit: boolean) {
        if (isToEdit) {
            setIsNewCategory(false);
            form.setFieldsValue({
                name: selectedRows[0].name,
                externalCode: selectedRows[0].externalCode
            })
        } else {
            setIsNewCategory(true);
        }
        setIsModalVisible(true);
    };

    function handleDeleteCostCategory() {
        setIsFetching(true)
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/human-resources/cost-category?ids=${selectedRowKeys}`,
            useProxy: true,
        }, onDeleteCostCategory.bind(this), onRequestError.bind(this))
    }

    function onRequestError() { 
        Notification({
            type: "warning", 
            message: "Não é possível excluir estes registros pois existem movimentos de RH associados à esta Categoria de Custo.", 
        });
        setIsFetching(false)
    }

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: SelectedCostCategory[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    function handleCancel() {
        setSelectedRows([]);
        setSelectedRowKeys([]);
        form.resetFields();
        setIsModalVisible(false);
    };

    function handleAddToList(data: CostCategoryData[]) {
        if (isNewCategory) {
            setCostCategoryList([...costCategoryList, data[0]])
        } else {
            handleSaveCostCategory(data)
        }
        form.resetFields()
    }

    function handleSaveCostCategory(data: CostCategoryData[]) {
        setIsFetching(true)
        setIsModalVisible(false)
        const dataToSave = data.map(costCategory => {
            return {
                ...costCategory,
                id: isNewCategory ? null : selectedRows[0].key,
                scenarioId: userInfo.selection.scenarioId,
                organizationId: userInfo.selection.organizationId
            }
        })
        setSelectedRows([])
        setSelectedRowKeys([])
        setCostCategoryList([])
        ServiceCaller.doRequest({
            type: isNewCategory ? RequestType.POST : RequestType.PUT,
            url: "/human-resources/cost-category",
            useProxy: true,
            params: isNewCategory ? dataToSave : dataToSave[0]
        }, onSaveCostCategory.bind(this))
    }

    const tableColumns: ColumnsType = [
        {
            title: i18n.t<string>("external_code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "center",
            width: 200,
        }, {
            title: i18n.t<string>("name"),
            dataIndex: "name",
            key: "name",
            align: "left",
            className: 'cost-category-name-column'
        }
    ];

    return (
        <>
            <div className="page-title-content" id="cost-category-container">
                <h1>{i18n.t("costCategory.title")}</h1>
            </div>
            <div id="top-buttons-cost-category">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t("costCategory.button")}
                        handleNew={() => handleOpenModal(false)}
                        handleEdit={() => handleOpenModal(true)}
                        handleDelete={handleDeleteCostCategory}
                        searchPlaceholder={i18n.t("costCategory.search_cost_category")}
                        isEditable={selectedRows.length === 1}
                        isDeletable={selectedRows.length > 0}
                        multipleSearch={{
                            tableData: tableData,
                            setTableData: setFilterdTableData,
                            options: [
                                { i18nString: 'external_code', description: 'externalCode' },
                                { i18nString: 'cost_category', description: 'name' }
                            ]
                        }}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(tableData, tableColumns, 'Categoria-de-Custo'),
                            importProps: importProps,
                            handleSuccessImport: () => fetchCostCategoryData()
                        }}
                    />
                </div>
            </div>
            <CostCategoryTable
                selectedRowKeys={selectedRowKeys}
                onChange={onSelectRowChange}
                isFetching={isFetching}
                tableData={filterdTableData}
                tableColumns={tableColumns}
            />
            <CostCategoryModal
                form={form}
                handleSubmit={handleAddToList}
                handleSave={handleSaveCostCategory}
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                isNewCategory={isNewCategory}
                costCategoryList={costCategoryList}
                setCostCategoryList={setCostCategoryList}
                tableData={tableData}
                lineCheckedKey={selectedRowKeys[0]}
            />
        </>
    );
}