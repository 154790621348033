import type { ColumnsType } from 'antd/es/table/interface';
import { Checkbox, Table, Tooltip } from "antd";
import { DraggableBodyRowProps, FlexFieldData, FlexFieldTableProps } from "../IFlexField";
import i18n from "util/base/i18n";
import { Icon } from '@iconify/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { RequestType } from 'util/service/IServiceCaller';
import { Notification } from "components/notification/Notification";

export function FlexFieldTable({ tableData, selectedRowKeys, onChange, isFetching, tableColumns, setTableData, filters, modules, onLoadTableData }: FlexFieldTableProps) {

	const [data, setData] = useState(tableData);
	const rowSelection = { selectedRowKeys, onChange };

	const DraggableBodyRow = ({
		index,
		moveRow,
		className,
		style,
		...restProps
	}: DraggableBodyRowProps) => {
		const type = 'DraggableBodyRow';
		const ref = useRef<HTMLTableRowElement>(null);
		const [{ isOver, dropClassName }, drop] = useDrop({
			accept: type,
			collect: monitor => {
				const { index: dragIndex } = monitor.getItem() || {};
				if (dragIndex === index) {
					return {};
				}
				return {
					isOver: monitor.isOver(),
					dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
				};
			},
			drop: (item: { index: number }) => {
				moveRow(item.index, index);
			},
		});
		const [, drag] = useDrag({
			type,
			item: { index },
			collect: monitor => ({
				isDragging: monitor.isDragging(),
			}),
		});
		drop(drag(ref));

		return (
			<tr
				ref={ref}
				className={`${className}${isOver ? dropClassName : ''}`}
				style={{ cursor: 'move', ...style }}
				{...restProps}
			/>
		);
	};

	function onUpdateOrder(data: FlexFieldData[]) {
		onLoadTableData(data);
		Notification({
			type: 'success',
			message: i18n.t<string>("successfully_saved"),
			
		})
	}

	const moveRow = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragRow = data[dragIndex];
			let newData: FlexFieldData[] = JSON.parse(JSON.stringify(data));
			newData.splice(dragIndex, 1);
			newData.splice(hoverIndex, 0, dragRow);
			setTableData(newData.map((item, index) => ({
                ...item,
                ordenation: index + 1
            })));
			ServiceCaller.doRequest({
				type: RequestType.PUT,
				params: newData.map((item, index) => {
					return ({
						id: item.id,
						ordenation: index + 1
					}
					)
				}),
				url: "/budget-base/flex-field/saveOrdenation",
			}, onUpdateOrder.bind(this));
			return newData;
		}, [data]);

	useEffect(() => {
		setData(tableData);
	}, [tableData])

	return (
		<>
			<DndProvider backend={HTML5Backend}>
				<Table
					loading={{
						spinning: isFetching,
						tip: `${i18n.t<string>("loading")}...`
					}}
					rowClassName={(record: any) => record.isFrozen ? "frozen" : ""}
					className="gs-table flex-field-table"
					dataSource={data}
					columns={tableColumns}
					rowSelection={rowSelection}
					bordered
					pagination={{ hideOnSinglePage: true, pageSize: 30 }}
					components={{
						body: {
							row: DraggableBodyRow,
						}
					}}
					onRow={(_, index) => {
						const attr = {
							index,
							moveRow,
						};
						return attr as React.HTMLAttributes<any>;
					}}
				/>
			</DndProvider>
		</>
	)
}