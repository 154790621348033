import { createContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import moment from 'moment';
import { NotificationPlacement } from 'antd/lib/notification';
import { handleErrorRequest } from 'util/functions/handleErrorRequest';
import { RequestType } from 'util/service/IServiceCaller';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { ErrorRequest } from 'util/types/types';
import i18n from 'util/base/i18n';

import {
    IAttribute,
    IAttributeParameterizationContext,
    IGrouper,
    IRowFromTable,
    IRule,
    ITableAttributeRule,
} from '../IAttributeParameterization';

export const AttributeParameterizationContext =
    createContext<IAttributeParameterizationContext>(null);

export function AttributeParameterizationProvider({ children }) {
    const [tableData, setTableData] = useState<ITableAttributeRule[]>([]);
    const [selectedRows, setSelectedRows] = useState<IRowFromTable[]>([]);
    const [selectedAttribute, setSelectedAttribute] = useState<IAttribute>();
    const [searchTextTable, setSearchTextTable] = useState('');
    const [selectedGrouper, setSelectedGrouper] = useState<IGrouper>();

    const [isNewAttributeModalOpen, setIsNewAttributeModalOpen] = useState(false);
    const [isEditAttributeModalOpen, setIsEditAttributeModalOpen] = useState(false);

    const [isNewRuleModalOpen, setIsNewRuleModalOpen] = useState(false);
    const [isEditRuleModalOpen, setIsEditRuleModalOpen] = useState(false);

    const [isNewRuleModalDFOpen, setIsNewRuleModalDFOpen] = useState(false);
    const [isEditRuleModalDFOpen, setIsEditRuleModalDFOpen] = useState(false);

    const [isNewRuleModalFixedMarketingCostOpen, setIsNewRuleModalFixedMarketingCostOpen] = useState(false);
    const [isEditRuleModalFixedMarketingCostOpen, setIsEditRuleModalFixedMarketingCostOpen] = useState(false);

    const [isDeleteAttributeModalOpen, setIsDeleteAttributeModalOpen] = useState(false);
    const [editAttributeInfo, setEditAttributeInfo] = useState(null);
    const [isYearPeriod, setIsYearPeriod] = useState(false);
    const [period, setPeriod] = useState<[moment.Moment, moment.Moment]>(() => {
        return [moment().month(11).endOf('month'), moment().startOf('year')];
    })
    const [labelDeleteAttributeModal, setLabelDeleteAttributeModal] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasSavedValue, setHasSavedValue] = useState<boolean>(false);
    const [rules, setRules] = useState<IRule[]>([]);
    const [defaultColumns, setDefaultColumns] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [lastAccomplishedPeriod, setLastAccomplishedPeriod] = useState<string>("");

    function handleChangePeriod() {
        let columnsList = [];
        let startPeriod = period[1].clone();
        let endPeriod = period[0].clone();

        if (isYearPeriod) {
            while (startPeriod.isSameOrBefore(endPeriod)) {
                let yearFormat = startPeriod.format('YYYY');
                let yearTitle = yearFormat.charAt(0).toUpperCase() + yearFormat.slice(1);

                const column = {
                    title: yearTitle,
                    dataIndex: yearFormat,
                    editable: true,
                };

                startPeriod.add(1, 'year');
                columnsList.push(column);
            }
        } else {
            while (startPeriod.isSameOrBefore(endPeriod)) {
                let month = startPeriod;

                let monthFormat: string = month.format('MMM YYYY');
                let monthFormatted: string =
                    monthFormat.charAt(0).toUpperCase() + monthFormat.slice(1);

                let column = {
                    title: monthFormatted,
                    editable: true,
                    dataIndex: monthFormat,
                };

                startPeriod.add(1, 'month');
                columnsList.push(column);
            }
        }

        setDefaultColumns(() => {
            const newState = [
                {
                    title: i18n.t<string>('code'),
                    dataIndex: 'externalCode',
                },
                {
                    title: i18n.t<string>('description'),
                    dataIndex: 'description',
                },
                {
                    title: i18n.t<string>('order'),
                    dataIndex: 'order',
                },
                ...columnsList,
            ];

            return newState;
        });
    }

    function handleSelectedRows(rows: IRowFromTable[]) {
        setSelectedRows(rows);
    }

    function reloadTable() {
        handleChangeTableData(selectedAttribute);
    }

    function deletedRulesNotification(placement: NotificationPlacement) {
        notification.success({
            message: `Regra(s) deletadas com sucesso!`,
            description: 'As regras já foram deletadas do sistema!!',
            placement,
            duration: 3,
        });
    }

    function handleRemoveRows() {
        const attributeRulesIds = selectedRows
            .map((row) => row.attributeRuleId)
            .reduce((prev, curr) => {
                if (prev === "") {
                    return curr.toString();
                } else {
                    return prev + ',' + curr.toString();
                }
            }, "");

        ServiceCaller.doRequest(
            {
                type: RequestType.DELETE,
                url: `revenue/grouper/attribute/rule?ids=${attributeRulesIds}`,
            },
            (response) => {
                handleChangeTableData(selectedAttribute);
                deletedRulesNotification('topRight');
            }
        );
    }

    function handleAddRule({
        attributeId,
        description,
        externalCode,
        levels,
        levelsValue,
        operations,
        ruleOrder,
    }: IRule) {
        setRules((rules) => [
            ...rules,
            {
                attributeId,
                description,
                externalCode,
                levels,
                levelsValue,
                operations,
                ruleOrder,
            },
        ]);
    }

    function handleRemoveRule(externalCode: string) {
        setRules((rules) =>
            rules.filter((rule) => rule.externalCode !== externalCode)
        );
    }

    function clearRules() {
        setRules([]);
    }

    function clearSelectedRows() {
        setSelectedRows([]);
    }

    function handleSelectedGrouper(grouper: IGrouper) {
        setSelectedGrouper(grouper);

        if (selectedRows.length > 0) {
            clearSelectedRows();
        }
    }

    function handleSelectedAttribute(clickedAttribute: IAttribute) {
        if (clickedAttribute.id !== selectedAttribute?.id) {
            setSelectedAttribute(clickedAttribute);
            handleChangeTableData(clickedAttribute);
            handleClearSearch();

            if (clickedAttribute.grouperName === 'FIXED_EXPENSE') {
                setIsYearPeriod(true);
            } else {
                setIsYearPeriod(false);
            }

            if (selectedRows.length > 0) {
                clearSelectedRows();
            }
        }
    }

    function cleanTableData() {
        setTableData([]);
        setIsFetchingData(true);
    }

    function handleChangeTableData(clickedAttribute: IAttribute) {
        cleanTableData();

        const startPeriodFormatted = period[1].format('YYYY-MM');
        const endPeriodFormatted = period[0].format('YYYY-MM');

        if (clickedAttribute && clickedAttribute.id) {
            ServiceCaller.doRequest(
                {
                    type: RequestType.GET,
                    url: `revenue/grouper/attribute/rule/get-all-by-attribute-id-and-period?attributeId=${clickedAttribute.id}&startPeriod=${startPeriodFormatted}&endPeriod=${endPeriodFormatted}&scenario={scenario}&clientId={client}&localeId={locale}`,
                },
                (response: any) => {
                    setSelectedRows([]);
                    setIsFetchingData(false);
                    setTableData(response);
                },
                (error: ErrorRequest) => {
                    handleErrorRequest(error);
                }
            );
        }
    }

    function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchTextTable(event.target.value);
    }

    function replicateRulesNotification(placement: NotificationPlacement) {
        notification.success({
            message: `Regra(s) replicada(s) com sucesso!`,
            description: 'As regras já estão disponíveis no sistema!',
            placement,
            duration: 3,
        });
    }

    function handleReplicateRules() {
        const attributeRulesIds = selectedRows
            .map((row) => row.attributeRuleId)
            .reduce((prev, curr) => {
                if (prev === "") {
                    return curr.toString();
                } else {
                    return prev + ',' + curr.toString();
                }
            }, "");

        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `/revenue/grouper/attribute/rule/replicate-rule?id=${attributeRulesIds}&clientId={client}&localeId={locale}`,
                params: {},
            },
            (response) => {
                replicateRulesNotification('topRight')
                reloadTable();
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }

    function handleClearSearch() {
        setSearchTextTable('');
    }

    function openEditAttributeModal(attribute) {
        setIsEditAttributeModalOpen(true);

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/revenue/grouper/attribute/${attribute.id}`,
            },
            (response) => {
                setEditAttributeInfo(response);
            }
        );
    }

    function closeEditAttributeModal() {
        setIsEditAttributeModalOpen(false);
        setEditAttributeInfo(null);
    }

    function openNewAttributeModal() {
        setIsNewAttributeModalOpen(true);
    }

    function closeNewAttributeModal() {
        setIsNewAttributeModalOpen(false);
    }

    function openNewRuleModal() {
        setIsNewRuleModalOpen(true);
    }

    function openDeleteAttributeModal() {
        setIsDeleteAttributeModalOpen(true);
    }

    function closeNewRuleModal() {
        setIsNewRuleModalOpen(false);
    }

    function openNewRuleModalDF() {
        setIsNewRuleModalDFOpen(true);
    }

    function closeNewRuleModalDF() {
        setIsNewRuleModalDFOpen(false);
    }

    function openEditRuleModal() {
        setIsEditRuleModalOpen(true);
    }

    function closeEditRuleModal() {
        setIsEditRuleModalOpen(false);
    }

    function openEditRuleModalDF() {
        setIsEditRuleModalDFOpen(true);
    }

    function closeEditRuleModalDF() {
        setIsEditRuleModalDFOpen(false);
    }

    function openEditRuleModalFixedMarketingCost() {
        setIsEditRuleModalFixedMarketingCostOpen(true);
    }

    function closeEditRuleModalFixedMarketingCost() {
        setIsEditRuleModalFixedMarketingCostOpen(false);
    }

    function openNewRuleModalFixedMarketingCost() {
        setIsNewRuleModalFixedMarketingCostOpen(true);
    }

    function closeNewRuleModalFixedMarketingCost() {
        setIsNewRuleModalFixedMarketingCostOpen(false);
    }

    function closeDeleteAttributeModal() {
        setIsDeleteAttributeModalOpen(false);
    }

    useEffect(() => {
        ServiceCaller.doRequest({
            url: `/budget-base/control-panel/find-realized-period-revenue?scenarioId={scenario}&organizationId={organization}&year=${period[1].year()}`,
            type: RequestType.GET
        }, (response: { periodRealized: string }) => {
            // setLastAccomplishedPeriod(parseDate(response.periodRealized));
            setLastAccomplishedPeriod(response.periodRealized);
        }, (err: ErrorRequest) => handleErrorRequest(err))
    }, []);

    useEffect(() => {
        handleChangePeriod();
    }, [period, isYearPeriod]);

    useEffect(() => {
        handleChangeTableData(selectedAttribute);
    }, [period])

    return (
        <AttributeParameterizationContext.Provider
            value={{
                searchTextTable,
                selectedGrouper,
                selectedAttribute,
                tableData,
                rules,
                selectedRows,
                isNewAttributeModalOpen,
                isEditAttributeModalOpen,
                isNewRuleModalOpen,
                isNewRuleModalDFOpen,
                isEditRuleModalOpen,
                isEditRuleModalDFOpen,
                isFetchingData,
                editAttributeInfo,
                period,
                defaultColumns,
                isYearPeriod,
                openEditAttributeModal,
                openNewAttributeModal,
                closeEditAttributeModal,
                closeNewAttributeModal,
                openNewRuleModal,
                closeNewRuleModal,
                openNewRuleModalDF,
                closeNewRuleModalDF,
                closeEditRuleModal,
                openEditRuleModal,
                handleSearch,
                handleClearSearch,
                handleSelectedGrouper,
                handleSelectedAttribute,
                handleSelectedRows,
                handleRemoveRows,
                handleAddRule,
                handleRemoveRule,
                clearRules,
                setPeriod,
                handleChangeTableData,
                setDefaultColumns,
                handleChangePeriod,
                clearSelectedRows,
                handleReplicateRules,
                reloadTable,
                openEditRuleModalDF,
                closeEditRuleModalDF,
                hasSavedValue,
                setHasSavedValue,
                isLoading,
                setIsLoading,
                isDeleteAttributeModalOpen,
                closeDeleteAttributeModal,
                labelDeleteAttributeModal,
                setLabelDeleteAttributeModal,
                openDeleteAttributeModal,
                lastAccomplishedPeriod,
                isEditRuleModalFixedMarketingCostOpen,
                isNewRuleModalFixedMarketingCostOpen,
                closeNewRuleModalFixedMarketingCost,
                closeEditRuleModalFixedMarketingCost,
                openNewRuleModalFixedMarketingCost,
                openEditRuleModalFixedMarketingCost
            }}
        >
            {children}
        </AttributeParameterizationContext.Provider>
    );
}
