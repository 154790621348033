import { Icon } from "@iconify/react";
import { Button, Dropdown, Form, Input, Menu, Popconfirm, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { tableProps } from "util/props/props";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Measure, MeasureDataResponse, MeasureList, MeasureTableData, NewConversionFormData, RateTableData } from "../IBudgetProjection";
import { NewMeasureConversionModal } from "./NewMeasureConversionModal";
import { NewMeasureModal } from "./NewMeasureModal";
import { Notification } from "components/notification/Notification";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { FunctionalityPermissions } from "util/types/types";
import { handleErrorRequest } from "util/functions/handleErrorRequest";

const { Search } = Input

interface IMeasureTab {
    measureList: MeasureList[];
    isMeasureListLoading: boolean;
    setIsMeasureListLoading: (boolean) => void;
    loadUnits: () => void;
    selectedRows: RateTableData[];
    setSelectedRows: (row: RateTableData[]) => void;
    selectedMenuItem: string
    setSelectedMenuItem: (string) => void;
    buttonTitle: string;
    functionalityPermissions: FunctionalityPermissions;
}

export function MeasureTab({
    measureList,
    isMeasureListLoading,
    setIsMeasureListLoading,
    loadUnits,
    selectedRows,
    setSelectedRows,
    selectedMenuItem,
    setSelectedMenuItem,
    buttonTitle,
    functionalityPermissions
}: IMeasureTab) {
    const [searchValue, setSearchValue] = useState('');
    const [searchConversionValue, setSearchConversionValue] = useState('');
    const [selectedRateData, setSelectedRateData] = useState<RateTableData>({} as RateTableData);
    const [isNewMeasureModalOpen, setIsNewMeasureModalOpen] = useState(false);
    const [isRateDataLoading, setIsRateDataLoading] = useState(true);
    const [rateTableData, setRateTableData] = useState<MeasureTableData[]>([]);
    const [measureModalList, setMeasureModalList] = useState<Measure[]>([]);
    const [isNewMeasure, setIsNewMeasure] = useState(true);
    const [isNewRate, setIsNewRate] = useState(false)
    const [isConversionModalOpen, setIsConversionModalOpen] = useState(false);
    const [conversionForm] = Form.useForm()
    const [measureForm] = Form.useForm();
    const [selectedConversionRowKeys, setSelectedConversionRowKeys] = useState([]);

    const dropdown =
        <Dropdown trigger={["click"]} overlay={
            <Menu>
                <Menu.Item className="menu-dropdown" onClick={() => handleOpenModal(false)}>
                    <Icon icon="icomoon-free:pencil" />&nbsp;
                    {i18n.t<string>("unit_of_measurement.edit_unit")}
                </Menu.Item>
                <Menu.Item className="menu-dropdown" onClick={() => handleDeleteMeasure()}>
                    <Icon icon="icomoon-free:bin" />&nbsp;
                    {i18n.t<string>("unit_of_measurement.delete_unit")}
                </Menu.Item>
            </Menu>
        } placement="bottomLeft">
            <Button><Icon icon="bi:three-dots-vertical" /></Button>
        </Dropdown>;

    const items = measureList
        .map(item => {
            return {
                key: item.key,
                title: item.label,
                icon: dropdown,
                label: <>
                    <span className="gs-tag blue">{item.symbol}</span>
                    <label>{item.label}</label>
                </>,
            }
        }).filter((item) => item.title?.toLowerCase().includes(searchValue.toLowerCase()));

    useEffect(() => {
        if (selectedMenuItem.length === 0) {
            setIsRateDataLoading(false)
            return
        } else {
            setIsRateDataLoading(true);
            fetchMeasureData();
            setSelectedRows([]);
            setSelectedConversionRowKeys([]);
        }
    }, [selectedMenuItem]);

    useEffect(() => {
        if (measureList.length === 0) return
        setIsRateDataLoading(true);
        fetchMeasureData()
        setSelectedMenuItem(measureList[0].key.toString());
    }, [measureList]);

    function handleOpenNewRateModal(isNew: boolean, item: any) {
        setIsNewRate(isNew);

        if (isNew) {
            setSelectedConversionRowKeys([]);
            setSelectedRows([]);
            conversionForm.resetFields();
            if (selectedMenuItem) {
                conversionForm.setFieldsValue({ unitOriginId: Number(selectedMenuItem) });
            }
        } else {
            conversionForm.setFieldsValue({
                unitOriginId: selectedConversionRowKeys[0].unitOriginId,
                unitDestinyId: selectedConversionRowKeys[0].unitDestinyId,
                rate: selectedConversionRowKeys[0].rate
            });
        }
        setIsConversionModalOpen(true);
    }

    function fetchMeasureData() {
        if (selectedMenuItem) {
            ServiceCaller.doRequest({
                type: RequestType.GET,
                url: `/budget-base/unit-conversion-tax?UnitOriginId=${selectedMenuItem}`,
            }, (data: MeasureDataResponse[]) => {
                setIsRateDataLoading(false);
                const updatedTableData = data.map(item => {

                    const unitDestinyIdDescription = measureList.find(measure => measure.key == item.unitDestinyId);

                    return {
                        key: item.id,
                        unitOriginId: item.unitOriginId,
                        unitDestinyId: item.unitDestinyId,
                        unitDestinyIdDescription: unitDestinyIdDescription.label,
                        rate: item.rate
                    }
                });
                setRateTableData(updatedTableData);
            }, () => setIsRateDataLoading(false));
        }
    }

    function handleCreateNewMeasure(data) {

        if (data.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t<string>("empty_list"),
            });
            return;
        }

        setIsMeasureListLoading(true);
        setIsNewMeasureModalOpen(false);

        const paramsToSave = data.map(measure => {
            return {
                id: isNewMeasure ? null : selectedMenuItem,
                externalCode: measure.externalCode,
                description: measure.description,
                symbol: measure.symbol
            }
        });

        ServiceCaller.doRequest({
            type: isNewMeasure ? RequestType.POST : RequestType.PUT,
            url: `/budget-base/unit-conversion`,
            params: isNewMeasure ? paramsToSave : paramsToSave[0]
        }, (data) => {
            loadUnits();
            fetchMeasureData();
        })

    }

    function handleSubmitNewMeasureForm(data: Measure[]) {
        if (isNewMeasure) {
            setMeasureModalList([...measureModalList, data[0]]);
        } else {
            handleCreateNewMeasure(data);
        }
        measureForm.resetFields();
    }

    function handleCreateMeasureConversion(data: NewConversionFormData) {

        const dataToSave = {
            id: isNewRate ? null : selectedConversionRowKeys[0].key,
            unitOriginId: data.unitOriginId,
            unitDestinyId: data.unitDestinyId,
            rate: Number(data.rate)
        }

        setIsConversionModalOpen(false);
        setIsRateDataLoading(true);
        setSelectedConversionRowKeys([]);
        setSelectedRows([]);

        ServiceCaller.doRequest({
            type: isNewRate ? RequestType.POST : RequestType.PUT,
            url: `/budget-base/unit-conversion-tax`,
            params: dataToSave
        }, fetchMeasureData, (err) => {
            handleErrorRequest(err)
            setIsRateDataLoading(false);
        });
    }

    function handleDeleteRate() {
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/budget-base/unit-conversion-tax?ids=${selectedConversionRowKeys.toString()}`,
        }, onDeleteRate.bind(this));
        setSelectedConversionRowKeys([]);
        setSelectedRows([]);
    }

    function onDeleteRate(data) {
        if (data) {
            Notification({
                type: "success",
                message: i18n.t("successfully_deleted"),
            });
        }
        fetchMeasureData();
    };


    const columns: ColumnsType = [
        {
            title: i18n.t<string>("unit_of_measurement.destination_unit"),
            dataIndex: "unitDestinyId",
            key: "unitDestinyId",
            align: "center",
            render: (unit) => {
                const obj = measureList.find(measure => measure.key === unit);
                return obj.label;
            }
        }, {
            title: i18n.t<string>("rates"),
            dataIndex: "rate",
            key: "rate",
            align: "center",
        }
    ];

    const rowSelection = {
        selectedConversionRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRow) => {
            setSelectedConversionRowKeys(selectedRowKeys);
            setSelectedRateData(selectedRow[0]);
            setSelectedRows(selectedRow);
        }
    };

    function handleDeleteMeasure() {
        setIsMeasureListLoading(true)
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `budget-base/unit-conversion?id=${selectedMenuItem.toString()}`
        }, onDeleteMeasure.bind(this));
    }

    function onDeleteMeasure(data) {
        if (data) {
            Notification({
                type: "success",
                message: i18n.t("successfully_deleted"),
            });
        }
        loadUnits();
    };

    function handleOpenModal(isNewMeasure: boolean) {
        if (isNewMeasure) {
            setIsNewMeasure(true);
            setMeasureModalList([]);
            measureForm.resetFields();
        } else {
            const valuesForm = measureList.find(item => item.key == selectedMenuItem);
            measureForm.setFieldsValue({
                externalCode: valuesForm.externalCode,
                description: valuesForm.label,
                symbol: valuesForm.symbol
            });
            setIsNewMeasure(false);
        }
        setIsNewMeasureModalOpen(true);
    };

    let filteredData = rateTableData.filter(obj => obj.unitDestinyIdDescription.toLowerCase().includes(searchConversionValue.toLowerCase()));

    return (
        <div className="budget-projection-tab-content">
            <div className="budget-projection-sidebar-container">
                <Space
                    className="budget-projection-sidebar-space-content"
                    direction="vertical"
                    size={0}
                >
                    <Search
                        placeholder={i18n.t('search')}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{ width: '100%' }}
                    />
                    <Button
                        icon={<Icon icon="ic:round-plus" />}
                        onClick={() => handleOpenModal(true)}
                        className="gs-secondary-button"

                    >
                        {i18n.t<string>("unit_of_measurement.new_unit")}
                    </Button>
                    {isMeasureListLoading ?
                        <Spin
                            style={{
                                margin: '50px auto 60px',
                                width: '100%'
                            }}
                            spinning={isMeasureListLoading}
                            tip={`${i18n.t('loading')}...`}
                        />
                        :
                        <Menu
                            onClick={({ item, key }) => setSelectedMenuItem(key)}
                            selectedKeys={[selectedMenuItem]}
                            mode="inline"
                            items={items}
                            defaultOpenKeys={items.length > 0 ? [items[0].key] : null}
                        />
                    }
                </Space>
            </div>
            <div className="budget-projection-table-container">
                <div className="budget-projection-table-operations">
                    <div style={{ display: "flex" }}>
                        {functionalityPermissions.new &&
                            <Button
                                onClick={() => handleOpenNewRateModal(true, {})}
                                className="gs-main-button"
                                style={{ marginRight: "5px" }}
                            >
                                {buttonTitle}
                            </Button>
                        }
                        {functionalityPermissions.edit &&
                            <Button
                                className="budget-projection-table-operations-buttons edit"
                                onClick={() => handleOpenNewRateModal(false, selectedRateData)}
                                icon={<Icon icon="icomoon-free:pencil" />}
                                disabled={selectedConversionRowKeys.length !== 1}
                            />
                        }
                        {functionalityPermissions.remove &&
                            <Popconfirm
                                placement="bottom"
                                overlayClassName="budget-projection-table-operations-buttons"
                                title={i18n.t<string>("delete_confirm_message")}
                                onConfirm={handleDeleteRate}
                                disabled={selectedConversionRowKeys.length === 0}
                                okText={i18n.t<string>("yes")}
                                cancelText={i18n.t<string>("cancel")}
                                okButtonProps={{ danger: true, className: 'popconfirm-delete-button' }}
                            >
                                <Button
                                    disabled={selectedConversionRowKeys.length === 0}
                                    className="budget-projection-table-operations-buttons trash"
                                    icon={<Icon icon="icomoon-free:bin" />}
                                />
                            </Popconfirm>
                        }
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                        <Search
                            placeholder={i18n.t('search')}
                            onChange={(e) => setSearchConversionValue(e.target.value)}
                            style={{ width: '100%' }}
                        />
                        {functionalityPermissions.export &&
                            <ImportExportMenu
                                exportGridData={() => handleExportGridData(rateTableData, columns, i18n.t("budget_projection_texts.measuring_unit"))}
                                buttonType="3dots"
                            />
                        }
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    loading={isRateDataLoading}
                    rowSelection={functionalityPermissions.edit || functionalityPermissions.remove ? rowSelection : null}
                    {...tableProps}
                />
            </div>
            <NewMeasureModal
                isNewRate={isNewMeasureModalOpen}
                isOpen={isNewMeasureModalOpen}
                setIsOpen={setIsNewMeasureModalOpen}
                handleSubmit={handleSubmitNewMeasureForm}
                handleSave={handleCreateNewMeasure}
                isNew={isNewMeasure}
                measureModalList={measureModalList}
                setMeasureList={setMeasureModalList}
                measureForm={measureForm}
                measureList={measureList}
                selectedMenuItem={selectedMenuItem}
                isNewMeasure={isNewMeasure}
            />
            <NewMeasureConversionModal
                isOpen={isConversionModalOpen}
                setIsOpen={setIsConversionModalOpen}
                handleSubmit={handleCreateMeasureConversion}
                isNewConversion={isNewRate}
                measureList={measureList}
                form={conversionForm}
                rateTableData={rateTableData}
            />
        </div>
    )
}