import { Excel } from 'antd-table-saveas-excel';
import { IStyle } from 'antd-table-saveas-excel/app';
import i18n from 'util/base/i18n';

export function handleExportGridData(tableData: any[], columns: any[], fileName: string, headerStyle?: IStyle): void {
	const excel = new Excel();
	excel.setTHeadStyle(headerStyle ? headerStyle : {
		color: "ffffff",
		background: "44759A",
		border: true,
		borderColor: "f0f0f0",
	})
	excel.addSheet("Teste")
		.addColumns(columns)
		.addDataSource(tableData)
	excel.saveAs(`${fileName}.xlsx`)
}

export function handleExportGridDataWithChildren(tableData: any[], columns: any[], fileName: string, headerStyle?: IStyle): void {
	const excel = new Excel();
	const flattedTableData: any[] = flattenArray(tableData);

	function flattenArray(items: any[]): any[] {
		return items.reduce<any[]>((acc, item) => {
			const { children, ...rest } = item;
			return children
				? [...acc, rest, ...flattenArray(children)]
				: [...acc, rest];
		}, []);
	}

	excel.setTHeadStyle(headerStyle ? headerStyle : {
		color: "ffffff",
		background: "44759A",
		border: true,
		borderColor: "f0f0f0",
	})

	excel.addSheet(i18n.t("import_modal.standard_worksheet"))
		.addColumns(columns)
		.addDataSource(flattedTableData)
	excel.saveAs(`${fileName}.xlsx`)
}
