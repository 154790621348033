import { Icon } from "@iconify/react";
import { Button, Spin, Table } from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { SelectAllCard } from "components/selectAllCard/SelectAllCard";
import { useState } from "react";
import i18n from "util/base/i18n";
import { AccountList, ITableContainer } from "../IFromTo";

export default function TableContainer({
    selectedTab,
    isLoadingTable,
    accountData,
    isSelectingAllLinked,
    setIsSelectingAllLinked,
    isSelectingAllUnlinked,
    setIsSelectingAllUnlinked,
    selectedLinkedRowKeys,
    selectedUnlinkedRowKeys,
    setSelectedLinkedRowKeys,
    setSelectedUnlinkedRowKeys,
    saveLink
}: ITableContainer) {

    const [searchValueUnlinked, setSearchValueUnlinked] = useState("");
    const [searchValueLinked, setSearchValueLinked] = useState("");
    const [tableColumnsLinked, setTableColumnsLinked] = useState<ColumnsType>([
        {
            title:
                selectedTab === "ledger_account"
                    ? i18n.t<string>("accounts_already_linked")
                    : i18n.t<string>("cost_center_already_linked"),
            dataIndex: "name",
            sorter: (a: AccountList, b: AccountList) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            },
            render(value, record) {
                return (
                    <div className="title-unlinked-table">
                        <p>{value}</p>
                        <Icon icon="akar-icons:link-chain" />
                    </div>
                );
            },
        },
    ]);

    const tableColumnsUnlinked: ColumnsType = [
        {
            title: i18n.t<string>("available"),
            dataIndex: "name",
            sorter: (a: AccountList, b: AccountList) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            },
        },
    ];

    const tableDataFiltered = accountData.availableAccounts
        ?.filter((line) => line.name?.toLowerCase().includes(searchValueUnlinked.toLowerCase()));

    const tableDataLinkedFiltered = accountData.linkedAccounts
        ?.filter((line) => line?.name?.toLowerCase().includes(searchValueLinked.toLowerCase()));

    const onChangeLinked = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedLinkedRowKeys(selectedRowKeys);
        },
    };

    const onChangeUnlinked = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUnlinkedRowKeys(selectedRowKeys);
        },
    };

    return (
        <>
            <div className="content-header">
                <div>
                    <p>{i18n.t(selectedTab)}</p>
                </div>
                <Search
                    placeholder={i18n.t("search")}
                    style={{ marginBottom: 5 }}
                    className={"linked-search"}
                    onChange={({ target }) => setSearchValueUnlinked(target.value)}
                />
                <Search
                    placeholder={i18n.t("search")}
                    style={{ marginBottom: 5 }}
                    onChange={({ target }) => setSearchValueLinked(target.value)}
                />
            </div>
            <Spin spinning={isLoadingTable}>
                <div className="content-tables">
                    <div>
                        {isSelectingAllLinked && (
                            <SelectAllCard
                                selectedRowKeys={selectedLinkedRowKeys}
                                setSelectedRowKeys={setSelectedLinkedRowKeys}
                                tableData={tableDataFiltered}
                                setIsSelectingAll={setIsSelectingAllLinked}
                            />
                        )}
                        <Table
                            className={
                                isSelectingAllLinked ? "gs-table selected-all-table" : "gs-table"
                            }
                            rowSelection={{
                                type: "checkbox",
                                ...onChangeLinked,
                                onSelectAll: (selected) => setIsSelectingAllLinked(selected),
                                selectedRowKeys: selectedLinkedRowKeys,
                            }}
                            bordered
                            columns={tableColumnsUnlinked}
                            dataSource={tableDataFiltered}
                            pagination={{ hideOnSinglePage: true, showSizeChanger: true }}
                        />
                        <Button
                            onClick={() => {
                                saveLink(false);
                                setSelectedLinkedRowKeys([]);
                            }}
                            disabled={selectedLinkedRowKeys?.length === 0}
                            className="gs-main-button"
                            icon={<Icon icon="akar-icons:link-chain" />}
                            style={{ marginTop: tableDataFiltered?.length <= 10 ? 12 : 0 }}
                        >
                            {i18n.t("link")}
                        </Button>
                    </div>
                    <div>
                        {isSelectingAllUnlinked && (
                            <SelectAllCard
                                selectedRowKeys={selectedUnlinkedRowKeys}
                                setSelectedRowKeys={setSelectedUnlinkedRowKeys}
                                tableData={tableDataLinkedFiltered}
                                setIsSelectingAll={setIsSelectingAllUnlinked}
                            />
                        )}
                        <Table
                            className={
                                isSelectingAllUnlinked ? "gs-table selected-all-table" : "gs-table"
                            }
                            rowSelection={{
                                ...onChangeUnlinked,
                                onSelectAll: (selected) => setIsSelectingAllUnlinked(selected),
                                selectedRowKeys: selectedUnlinkedRowKeys,
                            }}
                            columns={tableColumnsLinked}
                            bordered
                            dataSource={tableDataLinkedFiltered}
                            pagination={{ hideOnSinglePage: true, showSizeChanger: true }}
                        />
                        {tableDataLinkedFiltered?.length > 0 && (
                            <Button
                                onClick={() => {
                                    saveLink(true);
                                    setSelectedUnlinkedRowKeys([]);
                                }}
                                disabled={selectedUnlinkedRowKeys.length === 0}
                                className="gs-secondary-button"
                                icon={<Icon icon="pajamas:unlink" />}
                                style={{ marginTop: tableDataLinkedFiltered?.length <= 10 ? 12 : 0 }}
                            >
                                {i18n.t("unlink")}
                            </Button>
                        )}
                    </div>
                </div>
            </Spin>
        </>
    )
}