import { FlexFilter } from "util/types/types";
import { BudgetOpeningCachedData } from "../../fixedExpense/IFixedExpense";
import { UserInfo } from "context/IUserContext";

export function buildFlexField(data: any[], userInfo: UserInfo, budgetOpeningCachedData?: BudgetOpeningCachedData): FlexFilter[] {
    const flexField: FlexFilter[] = data
            .sort((a, b) => Number(a.order) - Number(b.order))
            .map((item) => {
                return {
                    label: item.name,
                    value: item.flexFieldId,
                    linkedFilters: item.filters ? item.filters : null,
                    ordenation: item.order,
                    fieldCode: Number(item.fildCode.split("_").pop()),
                    selectedOption: [],
                    children: [],
                    allValues: item.allValues
                };
            });
        const selectedCachedFields = budgetOpeningCachedData?.flexFieldInfoData;
        const isCachedSameAsCurrent =
            selectedCachedFields &&
            selectedCachedFields.every((field) => flexField.find((f) => f.value === field.value)) &&
            selectedCachedFields.length === flexField.length;
        try {
            for (const ff of flexField) {
                if ((selectedCachedFields && isCachedSameAsCurrent) || !ff.linkedFilters) {
                    let childFilterValues = ff.allValues;

                    ff.children = childFilterValues.map((item) => ({
                        value: item.fieldValueId,
                        label: item.externalCode + " - " + item.description,
                        linkedParentFlexFieldId: item.fitler.flexFieldFilters,
                    }));
                }
                if (ff.linkedFilters && ff.linkedFilters.includes("BUSINESS_UNIT")) {
                    const bu = userInfo.selection.businessUnitId.toString();
                    let childFilterValues = ff.allValues;
                    ff.children = childFilterValues
                        ?.filter((item) => item.fitler.businessUnitFilters.includes(Number(bu)))
                        .map((item) => ({
                            value: item.fieldValueId,
                            label: item.externalCode + " - " + item.description,
                            linkedParentFlexFieldId: item.fitler.flexFieldFilters,
                        }));
                }
                if (ff.linkedFilters && ff.linkedFilters.includes("ORGANIZATION")) {
                    const org = userInfo.selection.organizationId.toString();
                    let childFilterValues = ff.allValues;
                    ff.children = childFilterValues
                        ?.filter((item) => item.fitler.organizationFilters.includes(Number(org)))
                        .map((item) => ({
                            value: item.fieldValueId,
                            label: item.externalCode + " - " + item.description,
                            linkedParentFlexFieldId: item.fitler.flexFieldFilters,
                        }));
                }
            }
            if (selectedCachedFields && isCachedSameAsCurrent) {
                flexField.forEach((flex) => {
                    const cachedField = selectedCachedFields.find((flexField) => flexField.value === flex.value);
                    if (flex.linkedFilters) {
                        flex.children = flex.children.filter((fValue) =>
                            cachedField.children.find((f) => f.value === fValue.value)
                        );
                        const hasCachedOption =
                            cachedField.selectedOption?.length > 0 &&
                            flex.children.find((opt) => opt.value === cachedField.selectedOption[0].value);
                        if (hasCachedOption) {
                            flex.selectedOption = cachedField.selectedOption;
                        }
                    } else {
                        const hasCachedOption =
                            cachedField.selectedOption?.length > 0 &&
                            flex.children.find((opt) => opt.value === cachedField.selectedOption[0].value);
                        if (hasCachedOption) {
                            flex.selectedOption = cachedField.selectedOption;
                        }
                    }
                });
            } else {
                flexField.forEach((flex) => {
                    if (flex.allValues.filter(item => item.theLastSelected).length > 0) {
                        const selectedFlexField = flex.allValues.filter(item => item.theLastSelected)[0];
                        flex.selectedOption = [{
                            value: selectedFlexField.fieldValueId,
                            label: selectedFlexField.externalCode + " - " + selectedFlexField.description
                        }];
                    }
                })
            }
        } catch (err) {
            localStorage.removeItem(
                `budget-opening-${userInfo.clientId}-${userInfo.id}-${userInfo.selection.scenarioId}-${userInfo.selection.organizationId}-${userInfo.selection.businessUnitId}`
            );
        }

        return flexField;
}