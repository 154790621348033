import { Form, Tabs } from "antd";
import { useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { DefaultResponse, Options } from "util/types/types";
import { CurrenciesTab } from "./components/CurrenciesTab";
import { Notification } from "components/notification/Notification";
import './BudgetProjection.sass'
import { IndexerTab } from "./components/IndexerTab";
import { MeasureTab } from "./components/MeasureTab";
import i18n from "util/base/i18n";
import { MeasureList } from "./IBudgetProjection";
import { useErrorBoundary } from "react-error-boundary";
import { usePermissionContext } from "context/PermissionContext";

export function BudgetProjection() {
    const [userCurrencyDefaultId, setUserCurrencyDefaultId] = useState(0)
    const [selectedTab, setSelectedTab] = useState('1')
    const [currenciesOptions, setCurrenciesOptions] = useState<Options[]>([]);
    const [measureList, setMeasureList] = useState<MeasureList[]>([])
    const [isMeasureListLoading, setIsMeasureListLoading] = useState(true)
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const { showBoundary } = useErrorBoundary()
    const { functionalityPermissions } = usePermissionContext();

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/currency?locale={locale}&client={client}&user={user}`,
        }, (data) => setCurrenciesOptions(data), err => showBoundary(err));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/business/VerifySelected",
            useProxy: false
        }, (data) => {
            setUserCurrencyDefaultId(data[0].currencyId)
        }, err => showBoundary(err));

        loadUnits();
    }, []);

    function loadUnits() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/unit-conversion?locale={locale}&client={client}&user={user}`,
        }, onLoadUnits.bind(this), err => showBoundary(err));
    }

    function onLoadUnits(data) {
        if (data.length === 0) {
            setMeasureList([]);
            setIsMeasureListLoading(false);
            return;
        };
        const menuItems = data.map(item => {
            return {
                label: item.description,
                key: item.id,
                symbol: item.symbol,
                externalCode: item.externalCode
            };
        });
        setMeasureList(menuItems);
        setIsMeasureListLoading(false);
    };



    function onChangeTab(key) {
        setSelectedTab(key)
    }

    let buttonTitle = selectedTab === '3' ? i18n.t<string>("unit_of_measurement.add_conversion") : i18n.t("budget_projection_texts.add_fee");

    return (
        <div className="budget-projection-page-container">
            <div style={{ justifyContent: 'space-between', height: 70 }} className="page-title-content">
                <h1>{i18n.t<string>("budget_projection")}</h1>
            </div>
            <Tabs activeKey={selectedTab} type="card" className="gs-tab budget-projection-tab-container" onChange={onChangeTab}>
                <Tabs.TabPane tab={i18n.t<string>("currency")} key="1">
                    <CurrenciesTab
                        currenciesOptions={currenciesOptions}
                        userCurrencyDefaultId={userCurrencyDefaultId}
                        buttonTitle={buttonTitle}
                        functionalityPermissions={functionalityPermissions}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t<string>("indexers")} key="2">
                    <IndexerTab
                        buttonTitle={buttonTitle}
                        functionalityPermissions={functionalityPermissions}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t<string>("budget_projection_texts.measuring_unit")} key="3">
                    <MeasureTab
                        measureList={measureList}
                        isMeasureListLoading={isMeasureListLoading}
                        setIsMeasureListLoading={setIsMeasureListLoading}
                        loadUnits={loadUnits}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        selectedMenuItem={selectedMenuItem}
                        setSelectedMenuItem={setSelectedMenuItem}
                        buttonTitle={buttonTitle}
                        functionalityPermissions={functionalityPermissions}
                    />
                </Tabs.TabPane>
            </Tabs>

        </div>
    )
}