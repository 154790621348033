import { Card, Skeleton } from "antd";

interface ICardSkeleton {
    amount: number;
    cardClassName?: string;
    containerClassName?: string;
    active: boolean;
    direction: 'row' | 'column';
    gap?: number;
}

export function CardSkeleton({amount, cardClassName = '', containerClassName = '', active, direction, gap = 0}: ICardSkeleton) {
    let cards = []
    for (let index = 0; index < amount; index++) {
        cards.push(
            <Card key={index} className={`card-skeleton ${cardClassName}`}>
                <Skeleton title={false} paragraph={{rows: 2}} active={active} /> 
            </Card>
        )
    }
    return (
        <div 
            style={{
                display: 'flex',
                flexDirection: direction,
                gap
            }}
            className={`cards-skeleton-container ${containerClassName}`}
        >
            {cards}
        </div>
    )
}