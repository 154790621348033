import { Form, FormInstance, Input, InputRef, Table, Tooltip } from "antd";
import { BudgetGridData, Column, EditableRowProps } from "module/budget/pages/fixedExpense/IFixedExpense";
import { useBudgetOpeningContext } from "module/budget/pages/fixedExpense/context/useBudgetOpeningContext";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { formatNumber } from "util/formatNumber";
import i18n from "util/base/i18n";
import { ImageBox } from "components/imageBox/ImageBox";
import * as React from "react";
import { validateMonetaryInput } from "util/functions/validateKey";
import { Icon } from "@iconify/react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { ModuleType } from "util/types/types";
import { cloneDeep } from "lodash";

interface ITableDetail {
    open: boolean;
    budgetOpening: BudgetGridData;
    handleAddDetail: () => void;
    selectedRowKeys: any[];
    setSelectedRowKeys: (keys: any[]) => void;
    verifyBudgetPeriod: any;
    budgetPeriodDates: any;
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof ITableDetail;
    record: any;
    endPeriod: moment.Moment;
    onCell: (a: any) => any;
    handleSave: (record) => void;
    tableData: any[];
    loadTable: () => void;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const inputRefs = {};

export function TableDetail({
    open,
    budgetOpening,
    selectedRowKeys,
    setSelectedRowKeys,
    verifyBudgetPeriod,
    budgetPeriodDates,
}: ITableDetail) {
    const [columns, setColumns] = useState<Column[]>([]);
    const [monthColumns, setMonthColumns] = useState<any[]>([]);
    const { period, tableData, handleChangeTableData } = useBudgetOpeningContext();
    const EditableCell: React.FC<EditableCellProps> = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        endPeriod,
        loadTable,
        tableData,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef<InputRef>(null);
        const form = React.useContext(EditableContext)!;

        const handleSave = (record) => {
            const formattedRecord = {};
            for (const [key, value] of Object.entries(record)) {
                if (typeof value === "string" && (value.includes(",") || value.includes("."))) {
                    formattedRecord[key] = value.replace(",", ".");
                } else {
                    formattedRecord[key] = value;
                }
            }
            const newData = cloneDeep(tableData.map(item => {
                if (item.key === record.key) {
                    return { ...item, ...formattedRecord };
                }
                return item;
            }));
            handleChangeTableData(newData);
        };

        useEffect(() => {
            if (editing) {
                inputRef.current!.focus();
            }
        }, [editing]);

        const toggleEdit = (): void => {
            setEditing(!editing);
            form.setFieldValue(dataIndex, record[dataIndex]);
        }

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (error) {
                console.log(error);
            }
        }

        const handleReplicateValues = (record, dataIndex): void => {
            const isQuantityCell: boolean = dataIndex.endsWith("quantity");
            const isValueCell: boolean = dataIndex.endsWith("value");

            let keyReference = dataIndex.split("_");
            let currentDate = moment(keyReference[0], "YYYYMM");

            while (currentDate.isSameOrBefore(endPeriod, "month")) {
                const dateKey = currentDate.format("YYYYMM");
                const cellType = isQuantityCell ? "quantity" : isValueCell ? "value" : "";
                record[`${dateKey}${cellType}`] = record[dataIndex];
                currentDate.add(1, "month");
            }
            handleSave({ ...record });
        };

        let childNode = children;

        if (editable) {
            const isMonetaryCell: boolean = dataIndex.endsWith("quantity") || dataIndex.endsWith("value")
            const allowNegativeValue: boolean = dataIndex.endsWith("value");
            const isLastDateOfPeriod: boolean = moment(dataIndex.split("_")[0], "YYYYMM").isSame(endPeriod);
            const canShowReplicateButton: boolean = isMonetaryCell && !isLastDateOfPeriod;
            const inputType = isMonetaryCell ? "numeric" : "text";

            if (editing) {
                childNode = (
                    <Form.Item
                        className="editable-cell"
                        name={dataIndex}
                        rules={[
                            {
                                required: dataIndex.endsWith("name"),
                                message: i18n.t<string>("required_field"),
                            },
                        ]}
                    >
                        <Input
                            type={inputType}
                            inputMode={inputType}
                            onKeyDown={(event) => {
                                if (event.key === "Tab") {
                                    event.preventDefault();
                                    const inputKeys: string[] = Object.keys(inputRefs);
                                    let nextReference: number = inputKeys.indexOf(dataIndex) + (event.shiftKey ? -1 : 1);
                                    if (nextReference === inputKeys.length) {
                                        nextReference = 0;
                                    } else if (nextReference === -1) {
                                        nextReference = inputKeys.length - 1;
                                    }
                                    inputRefs[inputKeys[nextReference]].click();
                                } else if (isMonetaryCell) {
                                    validateMonetaryInput(event, allowNegativeValue);
                                }
                            }}
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                        />
                    </Form.Item>
                );
            } else {
                childNode = (
                    <div className="gs-table-input-editable-cell"
                        onClick={toggleEdit}
                        ref={(el) => {
                            if (el) {
                                inputRefs[dataIndex] = el;
                            }
                        }}>
                        <div className="editable-cell-value-wrap">
                            {children}
                        </div>
                        {canShowReplicateButton && (
                            <div className="gs-table-replicate-buttons-container right">
                                <Tooltip title={i18n.t("replicate_to_next_months")}>
                                    <Icon
                                        onClick={() => handleReplicateValues(record, dataIndex)}
                                        icon="material-symbols:content-copy"
                                        style={{
                                            marginLeft: "auto",
                                            boxShadow: "none",
                                            color: "#A6A7A7",
                                            background: "transparent",
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                );
            }
        }

        return <td {...restProps}>{childNode}</td>;
    };
    useEffect(() => {
        if (open) {
            loadTable();
        }
    }, [open]);

    function loadTable() {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/expenses/detail?openingId=${budgetOpening.budgetOpening.id}&period=${[period[0].valueOf()]}&period=${[period[1].valueOf()]}`
            },
            (response: any) => {
                let convertedData = response.map((item: any, index: number) => {
                    let newData: any = {
                        key: index + 1,
                        name: item.name,
                        note: item.note,
                        id: item.detailId,
                    };
                    item.plannedDetails.forEach((detail: any) => {
                        const dateKey = moment([detail.year, detail.month - 1]).format("YYYYMM");
                        newData[dateKey + "quantity"] = detail.quantity;
                        newData[dateKey + "value"] = detail.value;
                        newData[dateKey + "id"] = detail.uuid;
                    });
                    return newData;
                });
                handleChangeTableData(convertedData);
            }
        );
    }

    const calculateTotalForRow = (record): number => {
        let total = 0;
        for (const key in record) {
            if (key.endsWith("value")) {
                const quantityKey = key.replace("value", "quantity");
                const valueUnitary = parseFloat(record[key] || "0");
                const quantity = parseFloat(record[quantityKey] || "0");
                total += valueUnitary * quantity;
            }
        }
        return total;
    };

    const getColumnDefaultProps = (column: Column, isEditable: boolean): Column => {
        return {
            ...column,
            onCell: (record) => {
                return {
                    record,
                    editable: isEditable ? isEditable : false,
                    align: "center",
                    endPeriod: period[1],
                    dataIndex: column.dataIndex,
                    loadTable,
                    tableData: tableData,
                };
            }
        }
    };

    const renderDescription = (text: string) => {
        if (!text) {
            return (
                <Input
                    className="input-text-cell"
                    placeholder={i18n.t<string>("type_here")}
                />
            );
        }
        if (text.length > 10) {
            return (
                <Tooltip title={text}>
                    <span className="text-cell">{text}</span>
                </Tooltip>
            )
        }
        return text;
    };

    useEffect(() => {
        const descriptionColumn: Column = {
            className: "table-first-column",
            title: (
                <div >
                    <Tooltip title={i18n.t('required_field')}>
                        {i18n.t('detail')}
                    </Tooltip>
                </div>
            ),
            dataIndex: "name",
            key: "description",
            fixed: "left",
            width: 150,
            align: "center",
            render: (text) => renderDescription(text),
        };

        const observationColumn: Column = {
            className: "table-first-column",
            title: i18n.t<string>("observation"),
            dataIndex: "note",
            key: "observation",
            fixed: "left",
            width: 150,
            align: "center",
            render: (text) => renderDescription(text),
        };

        let currentDate = period[0].clone();
        let monthColumnsDetail: Column[] = [];
        while (currentDate.isSameOrBefore(period[1], "month")) {
            const currentBudgetPeriod = budgetPeriodDates.period.find(
                (budDate) => budDate.year === moment(currentDate, "YYYY-MM").year()
            );
            const { isEditable, className } = verifyBudgetPeriod(
                currentBudgetPeriod,
                moment(currentDate, "YYYY-MM"),
                budgetPeriodDates.localDate,
                ModuleType.EXPENSERESOURCES
            );
            const dateKey = currentDate.format("YYYYMM");
            monthColumnsDetail.push({
                title: currentDate.format("MMM YYYY").charAt(0).toUpperCase() + currentDate.format("MMM YYYY").slice(1),
                dataIndex: dateKey,
                key: dateKey,
                align: "center",
                children: [{
                    width: 80,
                    title: i18n.t<string>("amount_SD"),
                    dataIndex: dateKey + "quantity",
                    key: dateKey + "quantity",
                    className: className,
                    render: (text: number = 0) => {
                        const value: number = Number(text.toString().replace(",", "."));
                        return formatNumber(value);
                    }
                }, {
                    width: 120,
                    title: i18n.t<string>("value_unitary_SD"),
                    dataIndex: dateKey + "value",
                    key: dateKey + "value",
                    className: className,
                    render: (text: number = 0) => {
                        const value: number = Number(text.toString().replace(",", "."));
                        return formatNumber(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                    }
                }].map(column => getColumnDefaultProps(column, isEditable))
            });
            currentDate.add(1, "month");
        };
        const totalsColumn: Column = {
            title: i18n.t<string>("total"),
            dataIndex: "total",
            key: "total",
            fixed: "right",
            editable: false,
            width: 100,
            align: "center",
            render: (text, record: any) => formatNumber(calculateTotalForRow(record), { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        };
        setMonthColumns(monthColumnsDetail);
        setColumns(
            [descriptionColumn, observationColumn, ...monthColumnsDetail, totalsColumn].map((column) =>
                getColumnDefaultProps(column, true)
            )
        );
    }, [tableData]);

    const totalSummary = tableData.reduce((acc, record) => {
        let total = 0;
        for (const key in record) {
            if (key.endsWith("value")) {
                const quantityKey = key.replace("value", "quantity");
                const valueUnitary = parseFloat(record[key] || "0");
                const quantity = parseFloat(record[quantityKey] || "0");
                total += valueUnitary * quantity;
            }
        }
        return acc + total;
    }, 0);

    const summaryRow = (lines: any[]) => {
        const monthlySummaries: { [month: string]: number } = {};
        lines.forEach((line) => {
            Object.keys(line).forEach((key) => {
                if (key.includes("quantity") || key.includes("value")) {
                    const summaryType = key.includes("quantity") ? "quantity" : "value";
                    const value = line[key];
                    const date = key.substring(0, 6);
                    if (!monthlySummaries[`${date}${summaryType}`]) {
                        monthlySummaries[`${date}${summaryType}`] = 0;
                    }
                    monthlySummaries[`${date}${summaryType}`] = Number(value) + Number(monthlySummaries[`${date}${summaryType}`]);
                }
            })
        },)

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3} align="center">
                        {i18n.t("total")}
                    </Table.Summary.Cell>
                    {monthColumns.map((value, index) => {
                        const quantityValue = value.children[0].dataIndex;
                        const unitValue = value.children[1].dataIndex;
                        return (
                            <>
                                <Table.Summary.Cell index={index + 4} align="center">
                                    {formatNumber(monthlySummaries[quantityValue] || 0)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={index + 5} align="center">
                                    {formatNumber(monthlySummaries[unitValue] || 0, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                </Table.Summary.Cell>
                            </>
                        );
                    })}
                    <Table.Summary.Cell index={(monthColumns.length * 2) + 3} align="center">
                        {formatNumber(totalSummary, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        )
    }
    return (
        <div>
            {tableData.length > 0 && (
                <Table
                    className="gs-table"
                    scroll={{ x: 1500, y: 200 }}
                    columns={columns}
                    dataSource={tableData}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: setSelectedRowKeys
                    }}
                    bordered
                    summary={summaryRow}
                    pagination={false}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell
                        },
                    }}
                />
            )}
            {tableData.length === 0 && <ImageBox />}
        </div>
    )
}
