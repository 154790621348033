import { Tag as AntdTag } from "antd";
import { useRef } from "react";

interface ITagProps {
    children: any;
    isMathOperation?: boolean;
    itemIndex: number;
    isParenthesis?: boolean;
    removeItem: (index: any) => void;
}

export function Tag({
    children,
    itemIndex,
    removeItem,
    isMathOperation = false,
    isParenthesis = false,
}: ITagProps) {
    const tagRef = useRef(null);

    function handleMouseOver() {
        const closeButton = tagRef.current.querySelector("span");
        closeButton.style.visibility = "initial";
    }

    function handleMouseOut() {
        const closeButton = tagRef.current.querySelector("span");
        closeButton.style.visibility = "hidden";
    }

    return (
        <AntdTag
            ref={tagRef}
            className="attribute_parameterization-new-attribute-modal-formula-workarea-tag"
            closable
            onClose={() => removeItem(itemIndex)}
            style={{
                color: "#0065B3",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: isMathOperation ? "transparent" : "",
                border: isMathOperation ? "none" : "",
                transform: isParenthesis && "translateY(15%)",
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            {children}
        </AntdTag>
    );
}
