import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import i18n from 'util/base/i18n';

import { useUserContext } from 'context/UserContext';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { RequestType } from 'util/service/IServiceCaller';

import './PlanningEnvironmentTagStyles.sass';

export function PlanningEnvironmentTag() {
  const [isLeaseEnvironment, setIsLeaseEnvironment] = useState<Boolean>(false);
  const [showEnviromentModal, setShowEnvironmentModal] = useState<Boolean>(false);

  const { changeEnvironmentModal } = useUserContext();

  useEffect(() => {
    showEnvironmentModalCustomization();
    getLeaseEnvironment();
  }, []);

  function showEnvironmentModalCustomization() {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: '/showEnvironmentModalCustomization',
    }, onGetEnvironmentModalCustomization);
  }

  function onGetEnvironmentModalCustomization(showEnviromentModal) {
    setShowEnvironmentModal(showEnviromentModal)
  }

  function getLeaseEnvironment() {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: '/getLeaseProjection',
    }, onGetLeaseEnvironment.bind(this));
  }

  function onGetLeaseEnvironment(isLeaseEnvironment) {
    setIsLeaseEnvironment(isLeaseEnvironment);
  }

  return (
    <>
      {showEnviromentModal &&
        <div onClick={changeEnvironmentModal.bind(this)} className="environment-tag">
          {!isLeaseEnvironment && (
            <>
              <Icon className="globe-icon" icon="fa-regular:chart-bar" />
              <p>{i18n.t<string>('operational')}</p>
            </>
          )}
          {isLeaseEnvironment && (
            <>
              <Icon className="globe-icon" icon="mdi:world" />
              <p>{i18n.t<string>('planning')}</p>
            </>
          )}
        </div>
      }
    </>
  );
}
