import moment from "moment";
import { OtherEventColumn } from "../../../IOtherEvents";

export function createPlannedMonthsMock(
    period: [moment.Moment, moment.Moment],
    userDefaultCurrency: number
) {
    const monthAmount = period[1].diff(period[0], "months") + 1;
    let plannedMonths: OtherEventColumn[] = [];

    for (let index = 0; index < monthAmount; index++) {
        let monthLabel = moment(period[0]).add(index, "month").format("YYYY-MM");
        plannedMonths.push({
            id: null,
            rate: 1,
            month: parseInt(moment(monthLabel).format("MM")),
            year: parseInt(moment(monthLabel).format("YYYY")),
			monthYear: parseInt(moment(monthLabel).format("YYYYMM")),
            monthLabel: monthLabel,
            plannedValue: 0,
            historicValue: null,
            projectedValue: null,
            currencyId: userDefaultCurrency,
            currencyConversionValue: 0
        });
    }

    return plannedMonths;
}
