import { FormattedNumber } from "react-intl";
import i18n from "util/base/i18n";
import { CellContent } from "../../CellContent";

export function buildColumnComparativeTotal(reportVisualizationFilters, rowsFromBackend) {
    const totalPlanned = {
        title: i18n.t<string>('total_planned'),
        dataIndex: 'totalPlanned',
        align: 'center',
        key: 'total',
        fixed: 'right',
        width: 90,
        render: (text, record) => {
            let textDecoration = "";

            if (record.style && record.style.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record.style && record.style.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            let renderedValue = 0;
            if(record?.reportValues?.columnValueBeans.totalPlanned) {
                renderedValue = record.reportValues.columnValueBeans.totalPlanned;
            } else if(record?.columns?.totalPlanned) {
                renderedValue = record.columns.totalPlanned;
            }

            if (reportVisualizationFilters.showThousand) {
                renderedValue = renderedValue / 1000;
            }

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={renderedValue}
                        style={`decimal`}
                        currencyDisplay="symbol"
                        currency={record.isoCode ? record.isoCode : "BRL"}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    }

    const totalVariable = {
        title: 'Variação % P x R',
        dataIndex: 'totalVariationPlannedAccomplished',
        align: 'center',
        key: 'total',
        fixed: 'right',
        width: 90,
        render: (_, record) => {
            let textDecoration = "";
            let currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
            if(!currentRow) {
                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.name === columnFromBack.description);
            }

            if (record.style && record.style.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record.style && record.style.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            let renderedValue = 0;
            if(record?.reportValues?.columnValueBeans.totalVariationPlannedAccomplished) {
                renderedValue = record.reportValues.columnValueBeans.totalVariationPlannedAccomplished;
            } else if(record?.columns?.totalVariationPlannedAccomplished) {
                renderedValue = record.columns.totalVariationPlannedAccomplished;
            }

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={renderedValue / 100}
                        style={`percent`}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    }

    const totalAccomplished = {
        title: i18n.t<string>('total_accomplished'),
        dataIndex: 'totalAccomplished',
        align: 'center',
        fixed: 'right',
        key: 'total',
        width: 90,
        render: (text, record) => {
            let textDecoration = "";
            let currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
            if(!currentRow) {
                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.name === columnFromBack.description);
            }

            if (record.style && record.style.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record.style && record.style.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            let renderedValue = 0;
            if(record?.reportValues?.columnValueBeans.totalAccomplished) {
                renderedValue = record.reportValues.columnValueBeans.totalAccomplished;
            } else if(record?.columns?.totalAccomplished) {
                renderedValue = record.columns.totalAccomplished;
            }

            if (reportVisualizationFilters.showThousand) {
                renderedValue = renderedValue / 1000;
            }

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={renderedValue}
                        style={`decimal`}
                        currencyDisplay="symbol"
                        currency={record.isoCode ? record.isoCode : "BRL"}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    }

    return [totalPlanned, totalVariable, totalAccomplished];
}
