import { Button, Checkbox, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IAllocationLink, IAllocationTableData, INewAllocationTableProps } from "../IAllocationParameterization";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { Filter } from "components/filter/Filter";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import { cloneDeep } from "lodash";
import { Options } from "util/types/types";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { CurrencyDetailLayoutSelect } from "module/budget/pages/detailLayout/IDetailLayout";
import { RequestType } from "util/service/IServiceCaller";


export default function NewAllocationTable({
	tableData,
	isTableDataFetching,
	setAccountingList
}: INewAllocationTableProps) {
	const [accountingAccountOptions, setAccountingAccountOptions] = useState<Options[]>([]);
	const [isFetchingResourceAccount, setIsFetchingResourceAccount] = useState(true);
	const columns: ColumnsType<IAllocationLink> = [
		{
			dataIndex: "externalCode",
			key: "externalCode",
			title: i18n.t("code"),
			width: "20%",
		},
		{
			dataIndex: "description",
			key: "description",
			title: i18n.t("hr_allocations.hr_account"),
			width: "30%"
		},
		{
			dataIndex: "accountingAccountId",
			key: "accountingAccountId",
			title: i18n.t("hr_allocations.expense_accounts"),
			width: "30%",
			className: "filter-base-column",
			render: (accountId: number = 0, { id, isOriginalValue }) => {
				const value: Options[] = (accountId && !isOriginalValue && accountingAccountOptions.length) ? [accountingAccountOptions.find(({ value }) => accountId === value)] : [];
				const onChangeValue = (data: Options[]) => {
					if (!data.length) return;
					setAccountValue(data, id);
				}
				return (
					<Filter
						isFetching={isFetchingResourceAccount}
						id="accountingAccountId"
						title=""
						type="radio"
						value={value}
						setValue={onChangeValue}
						data={accountingAccountOptions}
						disabled={isOriginalValue}
					/>
				)
			}
		},
		{
			dataIndex: "isOriginalValue",
			key: "isOriginalValue",
			title: i18n.t("hr_allocations.keep_original_accounts"),
			width: "20%",
			align: "center",
			render: (isChecked, { id }) => {
				return (
					<Checkbox value={isChecked} onChange={({ target: { checked } }: CheckboxChangeEvent) => onSelectOriginalAccount(checked, id)} />
				)
			}
		},
	]

	function onSelectOriginalAccount(isChecked: boolean, lineId: number) {
		setAccountingList(state => {
			const newData: IAllocationLink[] = cloneDeep(state);
			const indexItem: number = newData.findIndex(({ id }) => id === lineId);
			const item: IAllocationLink = newData[indexItem];
			newData.splice(indexItem, 1, { ...item, isOriginalValue: isChecked, accountingAccountId: null })
			return newData;
		})
	}

	function setAccountValue([{ value }]: Options[], lineId: number) {
		if (!value) return;
		setAccountingList(state => {
			const newData: IAllocationLink[] = cloneDeep(state);
			const indexItem: number = newData.findIndex(({ id }) => id === lineId);
			const item: IAllocationLink = newData[indexItem];
			newData.splice(indexItem, 1, { ...item, accountingAccountId: value })
			return newData;
		})
	}

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/accounting-account?locale={locale}&client={client}&organization={organization}&bu={businessUnit}`,
		}, (data: CurrencyDetailLayoutSelect[]) => {
			setAccountingAccountOptions(data);
			setIsFetchingResourceAccount(false);
		})
	}, [])

	return (
		<Table
			className="gs-table"
			rowKey={"id"}
			columns={columns}
			dataSource={tableData}
			bordered
			scroll={{ y: 400 }}
			pagination={false}
			loading={isTableDataFetching || isFetchingResourceAccount}
		/>
	)
}