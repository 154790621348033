import { Icon } from "@iconify/react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useState } from "react";
import i18n from "util/base/i18n";
import { validateFormField } from "util/functions/validateFormField";
import { ProfessionalCategoryModalProps, ValueForm } from "../IProfessionalCategory";
import { Notification } from "components/notification/Notification";
import { ImageBox } from "components/imageBox/ImageBox";

export default function ProfessionalCategoryModal({
    isModalVisible,
    isNewCategory,
    handleSubmit,
    handleCancel,
    form,
    newCategoryList,
    setNewCategoryList,
    handleSaveCategory,
    tableData,
    selectedRowKeys
}: ProfessionalCategoryModalProps) {

    const [editDisebled, setEditDisebled] = useState(false);

    const modalTitle = isNewCategory
        ? i18n.t<string>("professional_category.modal_title_new")
        : i18n.t<string>("professional_category.modal_title_edit");

    function handleDeleteListItem(externalCode) {
        const categories = [...newCategoryList];
        const i = categories.findIndex((category) => (category.externalCode === externalCode));
        categories.splice(i, 1);
        setNewCategoryList(categories);
    }

    function handleEditListItem(categoty: ValueForm) {
        setEditDisebled(true)
        form.setFieldsValue({
            externalCode: categoty.externalCode,
            name: categoty.name,
        })
        handleDeleteListItem(categoty.externalCode);
    }

    return (
        <Modal
            width={600}
            title={modalTitle}
            visible={isModalVisible}
            okButtonProps={!isNewCategory && { htmlType: "submit", form: "new-category-form" }}
            onCancel={() => {
                handleCancel()
            }}
            className="gs-modal professional-category-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            onOk={() => {
                if (isNewCategory) {
                    if (newCategoryList.length > 0) {
                        handleSaveCategory(newCategoryList);
                    } else {
                        Notification({
                            type: 'warning',
                            message: i18n.t("no_items_added_to_list")
                        })
                    }
                }
            }}
        >
            <Form
                form={form}
                name="new-category-form"
                className={isNewCategory ? "form-new-category" : ""}
                initialValues={{ resourceType: 0 }}
                onFinish={(data) => {
                    setEditDisebled(false)
                    handleSubmit(data)
                }}
            >
                <div className="fields-container">
                    <div className="input-content">
                        <Form.Item
                            name="externalCode"
                            label={i18n.t<string>("code")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                                () => ({
                                    validator(_, value: string) {
                                        return validateFormField(value, 'externalCode', tableData, newCategoryList, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedRowKeys[0]);
                                    },
                                })
                            ]}
                        >
                            <Input id="input-template-detail" />
                        </Form.Item>
                    </div>
                    <div className="input-content">
                        <Form.Item
                            name="name"
                            label={i18n.t<string>("description")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                            ]}
                        >
                            <Input id="input-template-detail" />
                        </Form.Item>
                    </div>
                </div>
                {isNewCategory &&
                    <Button htmlType="submit"
                        className="plus-button gs-secondary-button"
                        icon={<Icon icon="akar-icons:circle-plus-fill" />}>
                        {i18n.t<string>("addToList")}
                    </Button>}
            </Form>

            {isNewCategory &&
                (newCategoryList.length > 0 ?
                    <>
                        <div className="new-category-table-head">
                            <Row align="middle" gutter={2}>
                                <Col span={5}>{i18n.t<string>("code")}</Col>
                                <Col span={7}>{i18n.t<string>("description")}</Col>
                                <Col span={1} />
                                <Col span={1} />
                            </Row>
                        </div>
                        <div className="new-category-table-body">
                            {newCategoryList.map((category) => {
                                return (
                                    <Row key={category.externalCode} align="middle" gutter={2}>
                                        <Col span={5}>{category.externalCode}</Col>
                                        <Col span={7}>{category.name}</Col>
                                        <Col span={1}>
                                            <Button
                                                disabled={editDisebled}
                                                onClick={() => handleEditListItem(category)}
                                                icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Icon
                                                className="delete-icon"
                                                onClick={() => handleDeleteListItem(category.externalCode)}
                                                icon="fa6-solid:trash"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                    :
                    <ImageBox />
                )
            }
        </Modal>
    )
}