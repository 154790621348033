import { Icon } from "@iconify/react";
import { Button, Col, Dropdown, Popover, Row, Select } from "antd";
import { useRef, useState } from "react";
import { FontSizeOptions, ILevelStyle, IOverflowColorProps } from "../../../IRegistrationSalesReports";
import FontStylePicker from "./FontStylePicker";
import i18n from "util/base/i18n";
import ColorPickerGS from "components/colorPicker/ColorPickerGS";
import { CustomSelect } from "module/budget/pages/revenue/attributeParameterization/components/custom/CustomSelect";

export default function OverflowColor(
	{ selected: {
		color,
		colorFont,
		isBold,
		isItalic,
		isScratched,
		isUnderlined,
		fontSize
	},
		isVisible,
		setIsVisible,
		saveNewStyle,
		index }: IOverflowColorProps) {
	const [colorBackGroundSelected, setColorBackGroundSelected] = useState(color);
	const [colorFontSelected, setColorFontSelected] = useState(colorFont);
	const [isBoldSelected, setIsBoldSelected] = useState(isBold);
	const [isItalicSelected, setIsItalicSelected] = useState(isItalic);
	const [isUnderlinedSelected, setIsUnderlinedSelected] = useState(isUnderlined);
	const [isScratchedSelected, setIsScratchedSelected] = useState(isScratched);
	const [fontSizeSelected, setFontSizeSelected] = useState(fontSize);

	function handleSave() {
		saveNewStyle({
			color: colorBackGroundSelected,
			colorFont: colorFontSelected,
			isBold: isBoldSelected,
			isItalic: isItalicSelected,
			isUnderlined: isUnderlinedSelected,
			isScratched: isScratchedSelected,
			fontSize: fontSizeSelected
		});
	}

	const fontSizeOptions: FontSizeOptions[] = [
		{
			label: "8 px",
			value: 8
		},
		{
			label: "10 px",
			value: 10
		},
		{
			label: "14 px",
			value: 14
		},
		{
			label: "16 px",
			value: 16
		},
		{
			label: "18 px",
			value: 18
		},
	];

	const contentOverlay: JSX.Element = (
		<div className="overlay-color-picker">
			<Row gutter={10} wrap>
				<Col className="header-content">
					<h4>{i18n.t<string>("edit_style_line")}</h4>
				</Col>
				<Row gutter={10} style={{ width: "100%" }}>
					<Col span={12}>
						<ColorPickerGS onChangeCustomColor={(color) => setColorBackGroundSelected(color)} onChange={(color) => setColorBackGroundSelected(color)} color={colorBackGroundSelected} label={i18n.t<string>("background_color")} />
					</Col>
					<Col span={12}>
						<CustomSelect
							id="font_size"
							labelName={i18n.t("font_size")}
							options={fontSizeOptions}
							onChange={setFontSizeSelected}
							value={fontSizeSelected}
							hasOptionGroups={false}
						/>
					</Col>
				</Row>
			</Row>
			<Row gutter={10} wrap>
				<Col span={12} style={{
					display: "flex", alignItems: "end"
				}}>
					<FontStylePicker
						isBoldSelected={isBoldSelected}
						isItalicSelected={isItalicSelected}
						isScratchedSelected={isScratchedSelected}
						isUnderlinedSelected={isUnderlinedSelected}
						setIsBoldSelected={setIsBoldSelected}
						setIsItalicSelected={setIsItalicSelected}
						setIsScratchedSelected={setIsScratchedSelected}
						setIsUnderlinedSelected={setIsUnderlinedSelected}
					/>
				</Col>
				<Col span={12} style={{
					justifyContent: "end", display: "flex",
				}}>
					<ColorPickerGS onChangeCustomColor={(color) => setColorFontSelected(color)} onChange={(color) => setColorFontSelected(color)} color={colorFontSelected} label={i18n.t<string>("font_color")} />
				</Col>
			</Row>
			<Row className="footer-popover">
				<Col>
					<Button onClick={() => setIsVisible(-1)} type="text">
						{i18n.t("cancel")}
					</Button>
				</Col>
				<Col>
					<Button onClick={handleSave} className="gs-main-button" >
						{i18n.t("save")}
					</Button>
				</Col>
			</Row>
		</div>
	);

	function onVisibleChange(visibleChanged: boolean) {
		if (!visibleChanged) {
			setIsVisible(-1);
		}
	}

	return (
		<div>
			<Popover
				content={contentOverlay}
				placement="bottomRight"
				overlayClassName="overlay-color-picker-report"
				trigger={"click"}
				visible={isVisible}
				destroyTooltipOnHide
				onVisibleChange={onVisibleChange}
			>
				<Button
					onClick={e => setIsVisible(index)}
					style={{ width: "100%" }}
					type="text"
					icon={<Icon icon="bxs:color-fill" />}
				/>
			</Popover>
		</div >
	);
}