import { Tabs } from "antd";
import { InitialInfoTable } from "./InitialInfoTable";
import { ICollabBudgetTables } from '../../../IHumanResoucers'
import { tabProps } from "util/props/props";
import i18n from "util/base/i18n";
import { ImageBox } from "components/imageBox/ImageBox";
import { Loading } from "components/loading/Loading";

export function CollabBudgetTables({ collaboratorId, isFetching, tablesData, year, valuesToSave, setValuesToSave, admissions, setAdmissions }: ICollabBudgetTables) {
	return (
		<>
			{(tablesData?.initialInformation.length > 0 || tablesData?.transactions.length > 0 || tablesData?.custom.length > 0 || tablesData?.legalTaxes.length > 0) && 
				<section className="collaborator-tables-container">
					<div className="collaborator-input-budget-tables">
						<Tabs defaultActiveKey="1" {...tabProps}>
							{tablesData?.initialInformation.length && 
								<Tabs.TabPane tab={i18n.t("human_resources.initial_information")} key="1">
									<InitialInfoTable
										collaboratorId={collaboratorId}
										setValuesToSave={setValuesToSave}
										valuesToSave={valuesToSave}
										isFetching={isFetching}
										isInitialInformation
										year={year}
										dataTable={tablesData?.initialInformation}
										realizedPeriod={tablesData?.realizedPeriod}
									/>
								</Tabs.TabPane>
							}
							{tablesData?.transactions.length && 
								<Tabs.TabPane tab={i18n.t("human_resources.movements_and_adjustments")} key="2">
									<InitialInfoTable
										admissions={admissions}
										setAdmissions={setAdmissions}
										collaboratorId={collaboratorId}
										setValuesToSave={setValuesToSave}
										valuesToSave={valuesToSave}
										isFetching={isFetching}
										year={year}
										dataTable={tablesData?.transactions}
										realizedPeriod={tablesData?.realizedPeriod}
									/>
								</Tabs.TabPane>
							}
							{/* <Tabs.TabPane tab="Ocorrências" key="3">
								<InitialInfoTable setValuesToSave={setValuesToSave} isFetching={isFetching} year={year} dataTable={tablesData?.resultQuantity} id={id} />
							</Tabs.TabPane> */}
							{tablesData?.custom.length &&
								<Tabs.TabPane tab={i18n.t("additional_fields")} key="4">
									<InitialInfoTable
										admissions={admissions}
										setAdmissions={setAdmissions}
										collaboratorId={collaboratorId}
										setValuesToSave={setValuesToSave}
										valuesToSave={valuesToSave}
										isFetching={isFetching}
										year={year}
										dataTable={tablesData?.custom}
										realizedPeriod={tablesData?.realizedPeriod}
									/>
								</Tabs.TabPane>
							}
							{tablesData?.legalTaxes.length && 
								<Tabs.TabPane tab={i18n.t("human_resources.legal_fees")} key="5">
									<InitialInfoTable
										isLegalFees
										collaboratorId={collaboratorId}
										setValuesToSave={setValuesToSave}
										valuesToSave={valuesToSave}
										isFetching={isFetching}
										year={year}
										dataTable={tablesData?.legalTaxes}
										realizedPeriod={tablesData?.realizedPeriod}
									/>
								</Tabs.TabPane>
							}
						</Tabs>
					</div>
				</section>
			}
		</>
	)
}