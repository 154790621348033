import { Row, Col, Tooltip, Button } from "antd";
import { useModalFilterContext } from "context/ModalFilterContext";
import { IFilterSelectedProps } from "../ITopButtons";
import i18n from "util/base/i18n";
import { useEffect, useState } from "react";
import { ICurrencyFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";


export default function FilterSelected({
	cleanFilters,
	flexFieldsSelected: { basePeriod, flexFieldsSelected }
}: IFilterSelectedProps) {

	const flexFieldsNode: React.ReactNode = (
		<>
			{flexFieldsSelected.map(({ label, value }, index) => {
				if (index < 7) {
					return (
						<Tooltip title={label} key={value}>
							<Col className="flex-field-selected gs-tag gray" span={2}>
								<p>{label}</p>
							</Col>
						</Tooltip>
					);
				} else if (index === 7) {
					const missingCount = flexFieldsSelected.length - 7;
					const restFlexFields = flexFieldsSelected.slice(7).map(item => item.label);

					return (
						<Tooltip title={
							<>
								+{missingCount} mais
								<br />
								{restFlexFields.join(', ')}
							</>
						} key={value}>
							<Col className="flex-field-selected gs-tag gray" span={1}>
								<p>+{missingCount}...</p>
							</Col>
						</Tooltip>
					);
				}
				return null;
			})}
		</>
	);

	return (
		<Row className="filter-selected-content">
			<Col span={3} className="gs-flex align-center jc-center">
				<p>
					{i18n.t("filtering_results_by")}:
				</p>
			</Col>
			<Col span={2} className="flex-field-selected gs-tag gray">
				<p>
					{basePeriod.format("DD/MM/YYYY")}
				</p>
			</Col>
			{flexFieldsNode}
			<Col span={2}>
				<Button type="link" onClick={cleanFilters}>
					{i18n.t("remove_all")}
				</Button>
			</Col>
		</Row>
	)
}