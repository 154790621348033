import {
	useState,
	useEffect
} from "react";

import { Icon } from "@iconify/react";
import {
	Button,
	Col,
	Popover,
	Row,
	Tooltip
} from "antd";
import { DatePicker } from "components";
import moment from "moment";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import Card from "../card/Card";
import { ICardData } from "./IBankBalance";
import { IChartWidgetProps, IWidgetResponse } from "./IChartWidget";

import "./BankBalanceWidget.sass";
import GroupBy from "./GroupBy";
import { useUserContext } from "context/UserContext";

export default function BankBalanceWidget(props: IChartWidgetProps) {
	const [dataValues, setDataValues]: [ICardData, Function] = useState({
		endBalance: 0,
		input: 0,
		output: 0
	});

	const [visiblePopover, setVisiblePopover]: [boolean, Function] = useState(false);

	const [date, setDate]: [moment.Moment, Function] = useState(moment(props.endDate));

	const [filter, setFilter]: [string, Function] = useState(props.groupBy);

	const [shouldUpdateFilter, setShouldUpdateFilter]: [boolean, Function] = useState(false);
	const [loading, setLoading]: [boolean, Function] = useState(false);
	const [useCache, setUseCache]: [boolean, Function] = useState(true);

	const [cached, setCached]: [boolean, Function] = useState(false);
	const [cacheDate, setCacheDate]: [Date, Function] = useState(null);

	const groupByOptions: string[] = ["ORGANIZATION", "BANK"];

	const {userInfo} = useUserContext();

	const handleVisibleChange = (visible: boolean) => {
		if (!loading) {
			setVisiblePopover(visible);
		}
	};

	function onLoadData(response: IWidgetResponse) {
		setCached(response.cached);
		setCacheDate(response.date);
		setDataValues(response.data);

		setLoading(false);
		setUseCache(false);
	};

	const openPopover = () => {
		setVisiblePopover(true);
	};

	const onApplyGroupBy = (groupBy: string): void => {
		if (filter === groupBy) {
			setVisiblePopover(false);
			loadData();
		} else {
			setFilter(groupBy);
			setShouldUpdateFilter(true);
		}
	};

	const doFilter: Function = () => {
		ServiceCaller.doRequest({
			url: "/dashboard-analytics/widget/filter",
			type: RequestType.PUT,
			params: {
				id: props.filterId,
				groupBy: filter,
				startDate: null,
				endDate: moment(date).format("YYYY-MM-DD")
			}
		}, loadData.bind(this));

		setVisiblePopover(false);
	}

	const onChangeDate = (newDate: moment.Moment) => {
		if (date.isSame(newDate)) {
			loadData();
		} else {
			setShouldUpdateFilter(true);
			setDate(newDate);
		}
	};

	const loadData: Function = () => {
		setDataValues({
			endBalance: 0,
			input: 0,
			output: 0
		});
		setLoading(true);

		const filterValuesQuery = props.globalFilter.values.map(value => `filterValue=${value}`).join("&");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/cash/bank/balance?filterBy=${props.globalFilter.filterBy}&${filterValuesQuery}&client={client}&user={user}&groupBy=${filter}&date=${date.format("YYYY-MM-DD")}&currency=${props.currencyId}&scenario={scenario}&useCache=${useCache}`
		}, onLoadData.bind(this))
	};

	useEffect(() => {
		if (shouldUpdateFilter) {
			doFilter();
		}
	}, [date, filter]);

	useEffect(loadData.bind(this), [props.currencyId, props.globalFilter]);

	return (
		<div className="contract-representativeness-widget">
			<div className="widget-header-actions-container">
				<p style={{marginBottom: "8px"}}>
					{ 
						i18n.t<string>(filter.toLowerCase())
					}
				</p>
				{
					cached && <>
						<Tooltip title={ i18n.t<string>("dashboard.analytics.text_002", {date: new Intl.DateTimeFormat(i18n.language.replace("_", "-"), { dateStyle: "short", timeStyle: "medium" }).format(new Date(cacheDate))}) }>
							<Button onClick={loadData.bind(this)} type="text" size="large" icon={<Icon icon={"mdi:reload-alert"} />} disabled={loading} data-html2canvas-ignore="true" />
						</Tooltip>
					</>
				}

				{userInfo.plan !== "FREEMIUM" &&
				<Popover onVisibleChange={handleVisibleChange} placement={"bottomLeft"} visible={visiblePopover} overlayClassName="filter-popover" title={i18n.t<string>("filterBy")} content={<GroupBy value={filter} onApply={onApplyGroupBy} />} trigger="click" destroyTooltipOnHide={true}>
					<Button onClick={openPopover} type="text" size="large" icon={<Icon icon={"clarity:filter-solid"} />} disabled={loading} data-html2canvas-ignore="true" />
				</Popover>
				}

				<DatePicker value={date} onChange={onChangeDate.bind(this)} format="DD/MM/YYYY" picker="date" disabled={loading} />
			</div>

			<Row gutter={[10, 10]}>
				<Col span={8} className="gutter-row">
					<Card class={"account_balance" + (loading ? " loading" : "")} title={i18n.t<string>("account_end_balance")} icon={loading ? "mdi:loading" : "dashicons:money-alt"} value={dataValues.endBalance} isMonetary={true} />
				</Col>
				<Col span={8} className="gutter-row">
					<Card class={"entry" + (loading ? " loading" : "")} title={i18n.t<string>("entry")} icon={loading ? "mdi:loading" : "akar-icons:arrow-up"} value={dataValues.input} isMonetary={true} />
				</Col>
				<Col span={8} className="gutter-row">
					<Card class={"exits" + (loading ? " loading" : "")} title={i18n.t<string>("exits")} icon={loading ? "mdi:loading" : "akar-icons:arrow-down"} value={dataValues.output} isMonetary={true} />
				</Col>
			</Row>
		</div>
	)

}