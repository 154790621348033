import {
	WidgetType,
	IWidgetFilter,
	IFilter,
	IWidgetContent
} from "./IDashboardAnalytics";

import ApplicationPositionWidget from "./widget/ApplicationPositionWidget";
import BankBalanceWidget from "./widget/BankBalanceWidget";
import BankConciliationWidget from "./widget/BankConciliationWidget";
import CashAvailability from "./widget/CashAvailability";
import CashFlowWidget from "./widget/CashFlowWidget";
import ContractRepresentativeness from "./widget/ContractRepresentativenessWidget";
import DebitProgressionWidget from "./widget/DebtProgressionWidget";
import FundingPosition from "./widget/FundingPositionWidget";
import PaymentConcentration from "./widget/PaymentConcentration";

interface WidgetDefinition {

	title: string

	icon: string

	renderComponent: Function

}

interface IWidgetDefinition {

	[key: string]: WidgetDefinition

}

export const widgetDefinitions: IWidgetDefinition = {
	[WidgetType.DEBT_PROGRESSION]: {
		title: "dashboard.analytics.widgets.debt_progression",
		icon: "heroicons-outline:presentation-chart-line",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter) => {
			return <DebitProgressionWidget globalFilter={globalFilter} currencyId={currencyId} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate} />
		}
	},
	[WidgetType.FUNDING_POSITION]: {
		title: "dashboard.analytics.widgets.funding_position",
		icon: "bxs:bar-chart-alt-2",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter) => {
			return <FundingPosition globalFilter={globalFilter} currencyId={currencyId} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate}
				groupBy={filter.groupBy} />
		}
	},
	[WidgetType.APPLICATION_POSITION]: {
		title: "dashboard.analytics.widgets.application_position",
		icon: "ant-design:aim-outlined",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter) => {
			return <ApplicationPositionWidget globalFilter={globalFilter} currencyId={currencyId} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate} groupBy={filter.groupBy} />
		}
	},
	[WidgetType.CONTRACT_REPRESENTATIVENESS]: {
		title: "dashboard.analytics.widgets.contract_representativeness",
		icon: "ri:file-user-line",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter) => {
			return <ContractRepresentativeness globalFilter={globalFilter} currencyId={currencyId} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate} groupBy={filter.groupBy} />
		}
	},
	[WidgetType.BANK_BALANCE_BUSINESS]: {
		title: "dashboard.analytics.widgets.bank_balance_business",
		icon: "eos-icons:compare-states",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter) => {
			return <BankBalanceWidget globalFilter={globalFilter} currencyId={currencyId} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate} groupBy={filter.groupBy} />
		}
	},
	[WidgetType.BANK_CONCILIATION_PENDING]: {
		title: "dashboard.analytics.widgets.bank_conciliation_pending",
		icon: "mdi:clipboard-clock-outline",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter) => {
			return <BankConciliationWidget globalFilter={globalFilter} currencyId={currencyId} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate} groupBy={filter.groupBy} />
		}
	},
	[WidgetType.PAYMENT_COCENTRATION]: {
		title: "dashboard.analytics.widgets.payment_cocentration",
		icon: "ic:round-credit-card",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter) => {
			return <PaymentConcentration globalFilter={globalFilter} currencyId={currencyId} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate} groupBy={filter.groupBy} />
		}
	},
	[WidgetType.CASH_FLOW_MAIN_ROWS]: {
		title: "dashboard.analytics.widgets.cash_flow_main_rows",
		icon: "mdi:transit-connection-variant",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter, content: IWidgetContent) => {
			return <CashFlowWidget globalFilter={globalFilter} currencyId={currencyId} content={content} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate} />
		}
	},
	[WidgetType.CASH_FLOW_AVAILABILITY]: {
		title: "dashboard.analytics.widgets.cash_flow_availability",
		icon: "mdi:piggy-bank-outline",
		renderComponent: (globalFilter: IFilter, currencyId: number, filter: IWidgetFilter) => {
			return <CashAvailability globalFilter={globalFilter} currencyId={currencyId} filterId={filter.id} startDate={filter.startDate} endDate={filter.endDate} />
		}
	}
}
