/* eslint-disable react-hooks/exhaustive-deps */
import './DetailLayout.sass';
import i18n from "util/base/i18n";
import { Key, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "antd";
import { TopButtons } from "components/topButtons/TopButtons";
import { DetailLayoutTable } from "../detailLayout/components/DetailLayoutTable";
import { NewDetailLayoutModal } from "../detailLayout/components/NewDetailLayoutModal";
import { CurrencyDetailLayoutSelect, Detail, DetailLayoutResponse, DetailLayoutFormData, DetailLayoutTableData } from "./IDetailLayout";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { DefaultResponse, Options } from "util/types/types";
import { Notification } from "components/notification/Notification";
import { useUserContext } from 'context/UserContext';
import moment from 'moment';
import { ImportExportMenu } from 'components/importExportMenu/ImportExportMenu';
import { handleExportGridData } from 'util/functions/handleExportGridData';
import { ColumnsType } from 'antd/lib/table';
import { usePermissionContext } from 'context/PermissionContext';

export function DetailLayout() {
    const [selectedRows, setSelectedRows] = useState<DetailLayoutTableData[]>([]);
    const [tableData, setTableData] = useState<DetailLayoutTableData[]>([]);
    const [currencyOptions, setCurrencyOptions] = useState<Options[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNewDetailLayout, setIsNewDetailLayout] = useState(false);
    const [selectedDetailLayoutIds, setSelectedDetailLayoutIds] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);
    const [detailList, setDetailList] = useState<Detail[]>([]);
    const [form] = Form.useForm();
    const [searchDetailLayout, setSearchDetailLayout] = useState("");
    const { userInfo } = useUserContext()
    const currencySelectedId = useRef(null)

    const tableColumns: ColumnsType = [
		{
			title: "Layout",
			dataIndex: 'description',
			key: 'description',
			render: (text, record: any) => {
				if(record.children) return text;
				const currency = currencyOptions.find(cur => cur.value === record.currencyId)?.label ?? ''
				return (
					<Row className="detail-row-container"> 
						<Col span={5}>
							<p>{record.description}</p> 
						</Col>
						<Col span={2}>
							<span className="gs-tag currency">{currency.split('-').shift()}</span> 
						</Col>
						<Col span={6}>
							{record.detailValues.length > 0 && <span className="gs-tag green" style={{fontSize: 12}}>Cálculo por quantidade</span>}
						</Col>
					</Row>
				)
			}
		},
	];

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/expenses/template-detail?locale={locale}&client={client}`,
        }, onLoadDetailLayoutTableData.bind(this));
        setIsFetchingCurrency(true);

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: '/budget-base/currency?locale={locale}&client={client}',
        }, onLoadCurrencies.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/business/VerifySelected",
            useProxy: false
        }, (data) => {
            currencySelectedId.current = data[0].currencyId
        });
    }, []);

    useEffect(() => {
    }, []);

    function onLoadCurrencies(data: Options[]) {

        setCurrencyOptions(data)
        setIsFetchingCurrency(false);
    }

    function onLoadDetailLayoutTableData(data: DetailLayoutResponse[]) {

        setSelectedDetailLayoutIds([]);
        setSelectedRows([]);
        setDetailList([]);

        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };

        const detailLayoutList: DetailLayoutTableData[] = data.map(detailLayout => {
            const data = {
                key: detailLayout.id,
                detailLayout: detailLayout.description,
                description: detailLayout.description,
                organizationId: detailLayout.organizationId,
                children: detailLayout.detailList.map(item => ({
                    key: item.id,
                    id: item.id,
                    detailLayout: detailLayout.description,
                    parentId: detailLayout.id,
                    description: item.description,
                    currencyId: item.currencyId,
                    isByQuantity: item.detailValues.length > 0 ? true : false,
                    detailValues: item.detailValues
                }))
            }
            return data
        })

        setTableData(detailLayoutList);
        setIsFetching(false);

    };

    function onDeleteDetailLayout(response: DefaultResponse) {
        Notification({
            type: "success",
            message: "Deletado com sucesso!",
        })
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: '/expenses/template-detail?locale={locale}&client={client}',
        }, onLoadDetailLayoutTableData.bind(this));
    };

    function handleOpenDetailLayoutModal(isNew: boolean) {
        if (isNew) {
            setIsNewDetailLayout(true);
            setSelectedDetailLayoutIds([]);
            setDetailList([]);
            form.resetFields();
        } else {
            form.setFieldsValue({
                detailLayout: selectedRows[0].detailLayout,
            })
            setIsNewDetailLayout(false);
        }
        setIsModalVisible(true);
    };
    
    function handleCloseDetailLayoutModal() {
        setSelectedDetailLayoutIds([]);
        form.resetFields();
        setIsModalVisible(false);
    };

    function handleDeleteDetailLayout() {
        setIsFetching(true)
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/expenses/template-detail?locale={locale}&client={client}&ids=${selectedDetailLayoutIds.toString()}`,
        }, onDeleteDetailLayout.bind(this));
    }

    function addMonthValue(data) {
        const detailWithValues: Detail = data
        Object.assign(detailWithValues, { detailValues: [] })
        const year = moment().year()
        for (let index = 0; index < 12; index++) {
            detailWithValues.detailValues.push({
                id: null,
                month: moment({ year: year, month: index }).format('YYYY-MM'),
                value: 0,
            })
        }
        return data
    }

    function handleIncludeDetails(data: DetailLayoutFormData) {
        const detail = {
            id: null,
            ...data
        }
        if (data.isByQuantity) {
            addMonthValue(detail)
        }
        setDetailList([...detailList, detail]);
        form.resetFields(['description'])
        form.setFieldsValue(
            {
                currencyId: currencySelectedId.current,
                isByQuantity: true
            }
        )
    }

    function handleSaveDetailLayout() {

        if(isNewDetailLayout && detailList.length === 0) {
            Notification({
                type: "warning", 
                message: i18n.t<string>("empty_list"), 
            });
            return;
        }

        const detailLayoutToSave: DetailLayoutResponse = {
            id: isNewDetailLayout ? null : Number(selectedDetailLayoutIds[0]),
            description: isNewDetailLayout ? detailList[0].detailLayout : selectedRows[0].detailLayout,
            organizationId: userInfo.selection.organizationId,
            detailList: detailList.map(detail => {
                return {
                    id: detail.id ? detail.id : null,
                    currencyId: detail.currencyId,
                    description: detail.description,
                    organizationId: userInfo.selection.organizationId,
                    detailValues: detail.detailValues
                }
            })
        }

        setIsModalVisible(false);
        setIsFetching(true);

        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: '/expenses/template-detail',
            params: detailLayoutToSave
        }, onSaveNewDetailLayout.bind(this));
    }

    function onSaveNewDetailLayout(response: DefaultResponse) {
        Notification({
            type: "success",
            message: i18n.t<string>("successfully_saved"),
        })
        form.resetFields();
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/expenses/template-detail?locale={locale}&client={client}`,
        }, onLoadDetailLayoutTableData.bind(this));
    };

    function onChangeRowSelection(selectedRowKeys: Key[], selectedRows: DetailLayoutTableData[]) {
        setSelectedDetailLayoutIds(selectedRowKeys);
        setSelectedRows(selectedRows);
        if (selectedRowKeys.length === 0) {
            setDetailList([]);
            return;
        }
        setDetailList(selectedRows[0].children);
    }

    function handleSearch(e) {
        setSearchDetailLayout(e.target.value);
    }

    const filteredTableData = tableData.filter(item => item.detailLayout.includes(searchDetailLayout));

    return (
        <>
            <div className="page-title-content">
                <h1>{i18n.t<string>("detailing_layout")}</h1>
            </div>
            <div id="top-buttons-detail-layout">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t<string>("new_detailing")}
                        handleNew={() => handleOpenDetailLayoutModal(true)}
                        handleEdit={() => handleOpenDetailLayoutModal(false)}
                        handleDelete={handleDeleteDetailLayout}
                        handleSearch={handleSearch}
                        isEditable={selectedDetailLayoutIds.length === 1 ? true : false}
                        isDeletable={selectedDetailLayoutIds.length > 0 ? true : false}
                        searchPlaceholder={i18n.t('search')}
                    />
                </div>
                <ImportExportMenu
                    exportGridData={() => handleExportGridData(tableData, tableColumns, 'Layout de detalhamento')}
                    buttonType="3dots"
                />
            </div>
            <main id="main-detailLayout-page">
                <DetailLayoutTable
                    isFetching={isFetching}
                    tableData={filteredTableData}
                    selectedRowKeys={selectedDetailLayoutIds}
                    onChange={onChangeRowSelection}
                    currencyOptions={currencyOptions}
                    columns={tableColumns}
                />
                <NewDetailLayoutModal
                    form={form}
                    setIsModalVisible={setIsModalVisible}
                    detailList={detailList}
                    setDetailList={setDetailList}
                    isModalVisible={isModalVisible}
                    handleSubmit={handleIncludeDetails}
                    handleCancel={handleCloseDetailLayoutModal}
                    isNewDetailLayout={isNewDetailLayout}
                    handleSaveDetailLayout={handleSaveDetailLayout}
                    isFetchingCurrency={isFetchingCurrency}
                    currencyOptions={currencyOptions}
                    tableData={tableData}
                    selectedDetailLayoutId={selectedDetailLayoutIds[0]}
                />
            </main>
        </>
    )
}