import { Key, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import { Form } from 'antd'

import i18n from 'util/base/i18n';
import { Icon } from '@iconify/react';
import moment from "moment";

import { ServiceCaller } from 'util/service/ServiceCaller';
import { MessageType, RequestType } from 'util/service/IServiceCaller';

import { Filter } from 'module/leaseProjection/components/filter/Filter';
import { Loading } from 'components/loading/Loading';
import { TopButtons } from 'components/topButtons/TopButtons';
import { Notification } from 'components/notification/Notification';

import { ProjectionRangeTag } from 'module/leaseProjection/components/projectionRangeTag/ProjectionRangeTag';

import { AdditiveModal } from './components/additiveModal/AdditiveModal';
import { TerminateModal } from './components/terminateModal/TerminateModal';

import { ContractSimulationModal } from './components/contractSimulationModal/ContractSimulationModal';
import { ContractSimulationTable } from './components/contractSimulationTable/ContractSimulationTable';
import { ContractSimulationEditModal } from './components/contractSimulationEditModal/ContractSimulationEditModal';
import { DataType, SelectedContractSimulations, ValueForm, ValueFormAdditive, ValueFormRescind, ValueFormUpdate } from './IContractSimulation';

import './ContractSimulationStyles.sass'

import { ROOT_PATH } from "util/rootPath";
import { handleUTCDate } from 'module/leaseProjection/functions/handleUTCDate';
import { ColumnsType } from 'antd/lib/table';
import ContractCounter from 'module/leaseProjection/components/contractCounter/ContractCounter';
import { ImportModal } from 'components/importExportMenu/import/ImportModal';

import { usePermissionContext } from 'context/PermissionContext';

export default function ContractSimulation() {
  const location = useLocation();
  const [newContractForm] = Form.useForm();
  const [editContractForm] = Form.useForm();
  const [additiveForm] = Form.useForm();
  const [terminateForm] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isNewContract, setIsNewContract] = useState(true);
  const [selectedRows, setSelectedRows] = useState<SelectedContractSimulations[]>([]);
  const [newContracts, setNewContracts] = useState<ValueForm[]>([]);
  const { setFunctionalityPermissions, functionalityPermissions } = usePermissionContext();
  const [isNewContractModalVisible, setIsNewContractModalVisible] = useState(false);
  const [isEditContractModalVisible, setIsEditContractModalVisible] = useState(false);
  const [isAdditiveModalVisible, setIsAdditiveModalVisible] = useState(false);
  const [isTerminateModalVisible, setIsTerminateModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInsertModal, setIsLoadingInsertModal] = useState(false);
  const [isLoadingEditModal, setIsLoadingEditModal] = useState(false);
  const [isLoadingAdditiveModal, setIsLoadingAdditiveModal] = useState(false);
  const [isLoadingTerminateModal, setIsLoadingTerminateModal] = useState(false);

  const [additive, setAdditive] = useState([]);
  const [listFilters, setListFilters] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const [filterdTableData, setFilterdTableData] = useState([]);
  const [isOpenImportModal, setIsOpenImportModal] = useState(false)
  const [isSelectedRowsCalculating, setIsSelectedRowsCalculating] = useState(false);
  const [isScenarioReleased, setIsScenarioReleased] = useState(false);

  const [hasCopyOriginId, setHasCopyOriginId] = useState(false);

  useEffect(() => {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: "/projectionlease/contract/"
    }, onLoadPermissions.bind(this));

    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: '/projectionlease/scenario/isReleased'
    }, onLoadScenario.bind(this));
  }, [location]);

  function onLoadScenario(isScenarioReleased) {
    setIsScenarioReleased(isScenarioReleased)
  }

  useEffect(() => {
    if (isEditContractModalVisible || isAdditiveModalVisible || isTerminateModalVisible) {
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.POST,
        url: '/projectionlease/contract/checkIsCalculating',
        params: selectedRowKeys
      }, onCheckIsCalculating.bind(this));
    }
  }, [isEditContractModalVisible, isAdditiveModalVisible, isTerminateModalVisible])

  useEffect(() => {
    if (isEditContractModalVisible) {
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: `/projectionlease/contract/findAdditives?contractId=${selectedRowKeys[0]}`
      }, onLoadAdditiveTableData.bind(this));
    }
  }, [isEditContractModalVisible]);

  function onCheckIsCalculating(isCalculating) {
    setIsSelectedRowsCalculating(isCalculating)
  }

  useEffect(() => {
    setFilterdTableData(tableData);
  }, [tableData]);

  const handleSubmitSave = (values: ValueForm) => {
    const request = {
      externalCode: values.externalCode,
      name: values.name,
      startDate: new Date(values.baseDate).setHours(0, 0, 0, 0),
      endDate: new Date(values.endDate).setHours(0, 0, 0, 0),
      amortizationDate: new Date(values.amortizationBaseDate).setHours(0, 0, 0, 0),
      installmentValue: values.installments,
      periodicity: values.frequency,
      interestRate: values.rate,
      indexer: values.indexer,
      firstCapitalization: (values.firstCapitalization != null ? new Date(values.firstCapitalization).setHours(0, 0, 0, 0) : null),
      operationalCostValue: values.operationalCostValue,
      doublePercentual: values.doublePercentual,
      managementGroupId: values.managementGroup,
      contractGroupId: values.contractGroup,
      projectId: values.project,
      divisionGroupId: values.division
    }

    setIsLoadingInsertModal(true)

    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.POST,
      params: request,
      url: "/projectionlease/contract/create"
    }, onLoadSave.bind(this), onRequestError.bind(this));
  }

  const handleSubmitUpdateContract = (values: ValueFormUpdate) => {
    if (!isSelectedRowsCalculating) {
      setIsLoadingEditModal(true)
      const request = {
        additiveId: additive[0].id,
        contractId: selectedRowKeys[0],
        amortizationStartDate: new Date(values.amortizationBaseDate).setHours(0, 0, 0, 0),
        endDate: new Date(values.endDate).setHours(0, 0, 0, 0),
        periodicity: values.frequency,
        interestRate: values.rate,
        installmentValue: values.installments,
        indexer: values.indexer,
        firstCapitalization: (values.firstCapitalization != null ? new Date(values.firstCapitalization).setHours(0, 0, 0, 0) : null),
        operationalCostValue: values.operationalCostValue,
        doublePercentual: values.doublePercentual
      }

      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.POST,
        params: request,
        url: "/projectionlease/contract/update"
      }, onLoadUpdateContract.bind(this));
    } else {
      Notification({
        type: "warning",
        message: i18n.t<string>('contract_simulation.edit_calculating'),
        duration: 5
      });
    }
  }

  const handleSubmitSaveRescind = (values: ValueFormRescind) => {
    if (!isSelectedRowsCalculating) {
      setIsLoadingTerminateModal(true)
      const request = {
        contractIds: selectedRowKeys,
        startDate: new Date(values.baseDate).setHours(0, 0, 0, 0),
        withoutPaying: values.withoutPaying == 1 ? true : false,
        operationalCost: values.withoutPaying == 3
      }
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.POST,
        params: request,
        url: "/projectionlease/contract/rescind"
      }, onLoadRescind.bind(this));
    } else {
      Notification({
        type: "warning",
        message: i18n.t<string>('contract_simulation.rescind_calculating'),
        duration: 5
      });
    }
  }

  const handleSubmitSaveAdditive = (values: ValueFormAdditive) => {
    if (!isSelectedRowsCalculating) {
      setIsLoadingAdditiveModal(true)
      const request = {
        contractId: selectedRowKeys[0],
        startDate: new Date(values.baseDate).setHours(0, 0, 0, 0),
        endDate: new Date(values.endDate).setHours(0, 0, 0, 0),
        interestRate: values.rate,
        installmentValue: values.installments,
        indexer: values.indexer,
        firstCapitalization: new Date(values.firstCapitalization).setHours(0, 0, 0, 0),
        operationalCostValue: values.operationalCostValue,
        doublePercentual: values.doublePercentual
      }
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.POST,
        params: request,
        url: "/projectionlease/contract/addAdditive"
      }, onLoadAdditive.bind(this));
    } else {
      Notification({
        type: "warning",
        message: i18n.t<string>('contract_simulation.edit_calculating'),
        duration: 5
      });
    }
  }

  const isMainButtonAble = functionalityPermissions.new && isScenarioReleased;
  const isImportButtonAble = functionalityPermissions.import && isScenarioReleased;
  const isEditButtonAble = functionalityPermissions.edit && isScenarioReleased && selectedRowKeys.length === 1;
  const isAdditiveButtonAble = functionalityPermissions.edit && isScenarioReleased && selectedRowKeys.length === 1;
  const isRescindButtonAble = functionalityPermissions.edit && isScenarioReleased && selectedRowKeys.length > 0;

  const customButton = [
    (functionalityPermissions.export || functionalityPermissions.import) && {
      onClick: () => { },
      icon: <Icon width={24} icon="fe:elipsis-v" />,
      disabled: !(functionalityPermissions.import && functionalityPermissions.export),
      dropDownItens: [
        functionalityPermissions.export ? {
          onClick: handleExport,
          title: i18n.t<string>('export'),
        } : null,
        isImportButtonAble ? {
          onClick: handleImport,
          title: i18n.t<string>('import_spreadsheet'),
        } : null,
      ].filter(value => value),
    }, {
      icon: <Icon width={24} icon="mdi:filter" />,
      onClick: () => setIsFilterVisible(true),
      toolTip: i18n.t<string>('filter'),
    }
  ];

  const droDownItems = [
    isEditButtonAble && {
      onClick: handleEdit,
      title: 'Editar',
    },
    isAdditiveButtonAble && {
      onClick: handleAdditive,
      title: 'Aditivo',
    },
    isRescindButtonAble && {
      onClick: handleTerminate,
      title: 'Rescindir',
    }
  ].filter(value => value);

  function listTable() {
    const defaultData = {
      divisions: [],
      groupManagements: [],
      groupContracts: [],
      projects: []
    }

    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.POST,
      url: "/projectionlease/contract/list",
      params: defaultData
    }, onLoadContractsTableData.bind(this));
  }

  function onRequestError() {
    Notification({
      type: "warning",
      message: i18n.t<string>("error_saved"),
      duration: 0
    });
  }

  function onLoadAdditiveTableData(data) {
    const dataForm = data.map(obj => ({
      id: obj.id,
      baseDate: handleUTCDate(obj.startDate),
      endDate: handleUTCDate(obj.endDate),
      amortizationBaseDate: handleUTCDate(obj.amortizationDate),
      installments: obj.installmentValue,
      frequency: obj.periodicity,
      rate: obj.interestRate,
      indexer: obj.indexer,
      indexerName: obj.indexerName,
      firstCapitalization: obj.firstCapitalization != null && obj.firstCapitalization > 0 ? handleUTCDate(obj.firstCapitalization) : null,
      operationalCostValue: obj.operationalCostValue,
      doublePercentual: obj.doublePercentual,
      managementGroup: obj.managementGroup,
      contractGroup: obj.contractGroup,
      project: obj.project,
      division: obj.division
    }));
    dataForm.sort((a, b) => { return (b.baseDate - a.baseDate) })
    setAdditive(dataForm);

    editContractForm.setFieldsValue({
      amortizationBaseDate: moment(dataForm[0].amortizationBaseDate),
      endDate: moment(dataForm[0].endDate),
      installments: dataForm[0].installments,
      frequency: dataForm[0].frequency,
      rate: dataForm[0].rate,
      indexer: dataForm[0].indexer,
      firstCapitalization: dataForm[0].firstCapitalization != null ? moment(dataForm[0].firstCapitalization) : null,
      operationalCostValue: dataForm[0].operationalCostValue,
      doublePercentual: dataForm[0].doublePercentual
    });

    setHasCopyOriginId(isNumber(data[data.length - 1].copyOriginId));

    setIsLoading(false)
  };

  function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  function onLoadAdditive(response) {
    if (response.status) {
      Notification({
        type: "success",
        message: i18n.t<string>("contract_simulation.new_additive"),
      });
      setIsAdditiveModalVisible(false)
      listTable();
      setSelectedRowKeys([]);
      setSelectedRows([]);
    } else {
      Notification({
        type: "error",
        message: response.message,
      });
    }
    setIsLoadingAdditiveModal(false)
  }

  function onLoadRescind(response) {
    if (response.status) {
      Notification({
        type: "success",
        message: i18n.t<string>('contract_simulation.terminated_contract'),
      });
      setIsTerminateModalVisible(false)
      listTable();
      setSelectedRowKeys([]);
      setSelectedRows([]);
    } else {
      Notification({
        type: "error",
        message: response.message,
      });
    }

    setIsLoadingTerminateModal(false)
  }

  function onLoadSave(response) {
    if (response.status) {
      Notification({
        type: "success",
        message: i18n.t<string>("successfully_saved"),
      });
      setIsNewContractModalVisible(false)
      listTable();
    } else {
      Notification({
        type: "error",
        message: response.message,
      });
    }

    setIsLoadingInsertModal(false)
  }

  function onLoadUpdateContract(response) {
    if (response.status) {
      Notification({
        type: "success",
        message: i18n.t<string>("successfully_edited"),
      });
      setIsEditContractModalVisible(false)
      listTable();
      setSelectedRowKeys([]);
      setSelectedRows([]);
    } else {
      Notification({
        type: "error",
        message: response.message,
      });
    }

    setIsLoadingEditModal(false)
  }

  function onLoadPermissions(permList) {
    setFunctionalityPermissions(permList);
  }

  function onLoadContractsTableData(data) {
    const dataWithKey = data.map(obj => ({ ...obj, key: obj.id }));
    setTableData(dataWithKey)
    setIsFetching(false);
  };

  function handleOpenNewContractModal() {
    setIsNewContract(true);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setNewContracts([]);
    newContractForm.resetFields();
    setIsNewContractModalVisible(true);
  }

  function handleOpenEditContractModal() {
    setIsLoading(true)
    setIsEditContractModalVisible(true);
  }

  function getLastAdditiveData(contractId) {
    if (contractId != null) {
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: `/projectionlease/contract/lastAdditiveData?contractId=${contractId}`,
      }, onLoadLastAdditiveData.bind(this));
    }
  }

  function onLoadLastAdditiveData(data) {
    additiveForm.setFieldsValue({
      baseDate: moment().startOf('month'),
      endDate: moment(handleUTCDate(data.endDate)),
      installments: data.installmentValue,
      rate: data.interestRate,
      indexer: data.indexer,
      firstCapitalization: data.firstCapitalization != null && data.firstCapitalization > 0 ? moment(handleUTCDate(data.firstCapitalization)) : null,
      operationalCostValue: data.operationalCostValue,
      doublePercentual: data.doublePercentual
    });
  }

  function handleOpenAdditiveModal() {
    setIsNewContract(true);
    setNewContracts([]);
    getLastAdditiveData(selectedRowKeys[0])
    setIsAdditiveModalVisible(true);
  }

  function handleOpenTerminateModal() {
    setIsNewContract(true);
    setNewContracts([]);
    terminateForm.resetFields();
    setIsTerminateModalVisible(true);
  }

  function onSelectRowChange(selectedRowKeys: Key[], selectedRows: SelectedContractSimulations[]) {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  function handleCloseNewContractModal() {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    newContractForm.resetFields();
    setIsNewContractModalVisible(false);
  }

  function handleCloseEditContractModal() {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    editContractForm.resetFields();
    setIsEditContractModalVisible(false);
  }

  function handleCloseAdditiveContractModal() {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    additiveForm.resetFields();
    setIsAdditiveModalVisible(false);
  }

  function handleCloseTerminateContractModal() {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    terminateForm.resetFields();
    setIsTerminateModalVisible(false);
  }

  function handleValueVisualization() {
    let selectedkey = selectedRows[0].key;
    ServiceCaller.doSendMessage({
      params: { url: `${ROOT_PATH}/mf/projectionlease/contract/values/${selectedkey}` },
      messageType: MessageType.REDIRECT_TO_BLANK
    })
  }

  const secondaryButton = {
    onClick: handleValueVisualization,
    title: i18n.t<string>('view_values'),
    className: "custom-button",
    disabled: selectedRowKeys.length !== 1
  }

  function handleImport() {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: `/projectionlease/contract/hasImportInProgress`,
    }, (res) => {
      if (res) {
        Notification({
          type: "warning",
          message: i18n.t<string>('contract_projection_import_fail'),
          duration: 15
        });
      } else {
        setIsOpenImportModal(true)
      }
    });
  }

  function handleExport() {
    const contractIds = filterdTableData.map((contract) => {
      if (contract.status == 'GOING')
        return contract.id
    })

    ServiceCaller.doSendMessage({
      messageType: MessageType.EXPORT_CONTRACT_PROJECTION,
      params: contractIds
    });
  }

  function handleEdit() {
    handleOpenEditContractModal()
  }

  function handleAdditive() {
    handleOpenAdditiveModal()
  }

  function handleTerminate() {
    handleOpenTerminateModal()
  }

  function handleSubmitFilter(data) {
    setIsFetching(true)

    const { projects, divisions, groupContracts, groupManagements } = data;

    ServiceCaller.doRequest({
      type: RequestType.POST,
      url: '/projectionlease/contract/list',
      useProxy: false,
      params: {
        projectIds: projects,
        managementGroupIds: groupManagements,
        contractGroupsIds: groupContracts,
        divisionIds: divisions
      }
    }, onLoadContractsTableData.bind(this));
  }

  const tableColumns: ColumnsType<DataType> = [
    {
      title: i18n.t<string>('code'),
      dataIndex: "id",
      key: "id",
      align: "left",
      width: 200,
      sorter: (a, b) => (a.id ?? 0) - (b.id ?? 0),
    },
    {
      title: i18n.t<string>('external_code'),
      dataIndex: "externalCode",
      key: "externalCode",
      align: "left",
      width: 200,
      sorter: (a, b) => a.externalCode?.localeCompare(b.externalCode) || 0,
    },
    {
      title: i18n.t<string>('contract'),
      dataIndex: "name",
      key: "name",
      align: "left",
      width: 300,
      sorter: (a, b) => a.name?.localeCompare(b.name) || 0,
    },
    {
      title: i18n.t<string>('management_group'),
      dataIndex: "managementGroup",
      key: "managementGroup",
      align: "left",
      width: 200,
      sorter: (a, b) => a.managementGroup?.localeCompare(b.managementGroup) || 0,
    },
    {
      title: i18n.t<string>('operation_type'),
      dataIndex: "operationType",
      key: "operationType",
      align: "left",
      width: 150,
      sorter: (a, b) => a.operationType?.localeCompare(b.operationType) || 0,
    },
    {
      title: i18n.t<string>('signature_date'),
      dataIndex: "baseDate",
      key: "baseDate",
      align: "center",
      width: 200,
      sorter: (a, b) => moment(a.baseDate).diff(b.baseDate) ?? 0,
      render: (date) => moment(handleUTCDate(date)).format('DD/MM/YYYY') ?? ""
    },
    {
      title: i18n.t<string>('end_date'),
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      width: 200,
      sorter: (a, b) => moment(a.endDate).diff(b.endDate) ?? 0,
      render: (date) => moment(handleUTCDate(date)).format('DD/MM/YYYY') ?? ""
    },
    {
      title: i18n.t<string>('contracted_value'),
      dataIndex: "contractedValue",
      key: "contractedValue",
      align: "right",
      width: 200,
      sorter: (a, b) => (a.contractedValue ?? 0) - (b.contractedValue ?? 0),
      render: (data) => (data ? data.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "")
    },
    {
      title: i18n.t<string>('account_setup'),
      dataIndex: "accountSetupName",
      key: "accountSetupName",
      width: 250,
      align: "center",
      sorter: (a, b) => a.accountSetupName?.localeCompare(b.accountSetupName) || 0,
    },
    {
      title: i18n.t<string>('situation'),
      dataIndex: "status",
      key: "status",
      align: "left",
      width: 250,
      sorter: (a, b) => (a.status || "").localeCompare(b.status || ""),
      render: (status: string) => {
        let color = "";
        let bgColor = "";
        switch (status) {
          case "GOING":
            color = "#0065B3";
            bgColor = "#E8F2F9";
            break;
          case "CLOSED":
            color = "#dc2626"
            bgColor = "#fee2e2"
            break;
        }
        return (
          <span
            className="situation-color-span"
            style={{
              color,
              backgroundColor: bgColor,
              padding: '0.25rem 0.5rem',
              borderRadius: '4px',
              whiteSpace: 'nowrap'
            }}
          >
            {status && i18n.t<string>(`contract_simulation_status.${status.toLowerCase()}`)}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <div id="contract-simulation">
        <h1 className='contract-simulation-title'>{i18n.t<string>('projection_lease.projection_contracts')}</h1>
        <div className='tag-wrapper'>
          <ProjectionRangeTag />
        </div>
      </div>
      <Filter
        form={filterForm}
        hasFiltersOnScreen={false}
        listFilters={listFilters}
        setListFilters={setListFilters}
        handleCancel={() => setIsFilterVisible(false)}
        isFilterVisible={isFilterVisible}
        submitRequest={handleSubmitFilter}
      />
      <TopButtons
        mainButtonTitle={i18n.t<string>('new_lease')}
        disabledMainButton={!isMainButtonAble}
        handleNew={handleOpenNewContractModal}
        mainButtonDropDown={droDownItems}
        secondaryButton={secondaryButton}
        searchPlaceholder={i18n.t<string>('loading')}
        multipleSearch={{
          tableData: tableData,
          setTableData: setFilterdTableData,
          options: [
            { i18nString: 'code', description: 'id' },
            { i18nString: 'external_code', description: 'externalCode' },
            { i18nString: 'contract', description: 'name' },
            { i18nString: 'management_group', description: 'managementGroup' },
          ]
        }}
        customButtons={customButton}
      />
      <main id="contract-main">
        {isLoading &&
          <div className='loading-wrapper'>
            <Loading caption={i18n.t<string>('loading')} />
          </div>
        }
        <ContractSimulationModal
          form={newContractForm}
          handleCancel={handleCloseNewContractModal}
          handleSubmit={handleSubmitSave}
          isModalVisible={isNewContractModalVisible}
          isLoading={isLoadingInsertModal}
        />
        <ContractSimulationEditModal
          form={editContractForm}
          handleCancel={handleCloseEditContractModal}
          handleSubmit={handleSubmitUpdateContract}
          isModalVisible={isEditContractModalVisible}
          isLoading={isLoadingEditModal}
          tableData={additive}
          hasCopyOriginId={hasCopyOriginId}
        />
        <AdditiveModal
          form={additiveForm}
          handleCancel={handleCloseAdditiveContractModal}
          handleSubmit={handleSubmitSaveAdditive}
          isModalVisible={isAdditiveModalVisible}
          isLoading={isLoadingAdditiveModal}
        />
        <TerminateModal
          form={terminateForm}
          handleCancel={handleCloseTerminateContractModal}
          handleSubmit={handleSubmitSaveRescind}
          isModalVisible={isTerminateModalVisible}
          isLoading={isLoadingTerminateModal}
        />
        <div className="grid-info">
          <ContractCounter />
          <p className='grid-length'>{i18n.t<string>('viewing')} - {filterdTableData.length} {i18n.t<string>('contracts_found')}</p>
        </div>
        <ContractSimulationTable
          isFetching={isFetching}
          tableData={filterdTableData}
          selectedRowKeys={selectedRowKeys}
          onChange={onSelectRowChange}
          tableColumns={tableColumns}
        />
      </main>

      <ImportModal
        type='excel'
        useProxy={false}
        uploadType={"1"}
        isOpen={isOpenImportModal}
        setImportModalOpen={setIsOpenImportModal}
        handleCancel={() => setIsOpenImportModal(false)}
        importUrl="/projectionlease/contract/import"
        templateUrl="/projectionlease/contract/downloadTemplateLeaseTim"
      />
    </>
  )
}