import { CardSkeleton } from "components/skeleton/cardSkeleton/CardSkeleton";
import { useBudgetOpeningContext } from "module/budget/pages/fixedExpense/context/useBudgetOpeningContext";
import { useEffect, useState } from "react";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Icon } from "@iconify/react";
import { formatNumber } from "util/formatNumber";
import { InvestmentSummaryCards } from "../IInvestment";

export function SummaryCards({ currencyImg, refresh = false, period, savedValue, isFetching, cards }) {
    return (
        <div id='summary-cards-container'>
            {isFetching ?
                <>
                    <CardSkeleton
                        direction='row'
                        active
                        amount={4}
                        gap={10}
                        cardClassName='summary-card-skeleton'
                    />
                </>
                :
                <>
                    <div id="card-container">
                        <Icon icon="charm:chart-line" />
                        <div id="card-content">
                            <p id="card-title">Total Planejado</p>
                            <span>
                                {
                                    formatNumber(cards.planned, {
                                        style: "currency",
                                        currency: "BRL",
                                    })
                                }
                            </span>
                        </div>
                    </div>
                    <div id="card-container">
                        <Icon icon="fa6-solid:list-check" />
                        <div id="card-content">
                            <p id="card-title">Total Realizado</p>
                            <span>
                                {
                                    formatNumber(cards.accomplished, {
                                        style: "currency",
                                        currency: "BRL",
                                    })
                                }
                            </span>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}