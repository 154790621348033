import moment from "moment";
import { PlannedMonth } from "../../../IFixedExpense";

export function createPlannedMonthsMock(
    period: [moment.Moment, moment.Moment],
    userDefaultCurrency: number,
    defaultValues: { [key: string]: any } = {}
) {
    const monthAmount = period[1].diff(period[0], "months") + 1;
    let plannedMonths: PlannedMonth[] = [];

    for (let index = 0; index < monthAmount; index++) {
        plannedMonths.push({
            id: null,
            month: moment(period[0]).add(index, "M").format("YYYY-MM"),
            plannedValue: 0,
            detailId: null,
            detailName: "",
            realizedValue: null,
            currencyId: userDefaultCurrency,
            unitValue: null,
            detailValueId: null,
            quantity: null,
            rate: 1,
            historicValue: null,
            projectedValue: null,
            apportionmentValue: null,
            ...defaultValues,
        });
    }
    return plannedMonths;
}
