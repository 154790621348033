import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { useErrorBoundary } from "react-error-boundary";
import { Form, Tabs } from "antd";
import { Icon } from "@iconify/react";
import { useUserContext } from "context/UserContext";

import Content from "./components/Content";
import { TopButtons } from "components/topButtons/TopButtons";
import { NewLevelModal } from "./components/NewLevelModal";
import { Notification } from "components/notification/Notification";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { SetPackageLevelModal } from "./components/SetPackageLevelModal";
import { StandardAccountModal } from "./components/StandardAccountModal";
import { CostCenterLinkedPackageModal } from "./components/CostCenterLinkedPackageModal";

import { getLevelById } from "./functions/getLevelById";
import { getTreeMaxLevelAmount } from "./functions/getTreeMaxLevelAmount";
import { verifyIfHasLinkedAtLastLevel } from "./functions/verifyIfHasLinkedAtLastLevel";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { sortList } from "util/functions/sortList";
import { Options } from "util/types/types";
import { AccountLinked, CostCenterLinked, DataLevels, LevelItem, NewLevelFormData } from "./IGroupings";

import { CustomButtons } from "components/topButtons/ITopButtons";
import { findNodeTree } from "./functions/findNodeTree";
import { useCostCenterOptions } from "hooks/useCostCenterOptions";
import { useAccountAccountingOptions } from "hooks/useAccountAccountingOptions";
import "./style.sass";
import useCachedQuery from "hooks/useCachedQuery";
import { FieldManingModal } from "./components/fieldManingModal/FieldManingModal";

export default function Groupings() {
    const [groupSelected, setGroupSelected] = useState(i18n.t<string>("ledger_account"));
    const [accountData, setAccountData] = useState<LevelItem[]>([]);
    const [costCenterData, setCostCenterData] = useState<LevelItem[]>([]);
    const [packageLevel, setPackageLevel] = useState<DataLevels>({} as DataLevels);
    const [linkedData, setLinkedData] = useState<any[]>([]);
    const [packageAccountLinkedList, setPackageAccountLinkedList] = useState<Options[]>([]);
    const [isNewLevelModalOpen, setIsNewLevelModalOpen] = useState(false);
    const [isSetPackageLevelModalOpen, setIsSetPackageLevelModalOpen] = useState(false);
    const [isStandardAccountModalOpen, setIsStandardAccountModalOpen] = useState(false);
    const [isCostCenterLinkedPackageOpen, setIsCostCenterLinkedPackageOpen] = useState(false);
    const [lookupLevels, setLookupLevels] = useState(accountData);
    const [form] = Form.useForm();
    const [checkedNodeKeys, setCheckedNodeKeys] = useState<number[]>([]);
    const [isDeletable, setIsDeletable] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isSelectDisabled, setIsSelectDisabled] = useState(false);
    const [isNewLevel, setIsNewLevel] = useState(true);
    const [isTreeDisabled, setIsTreeDisabled] = useState(true);
    const [isSavingCostCenterLinked, setIsSavingCostCenterLinked] = useState(false);
    const [accountMaxLevelAmount, setAccountMaxLevelAmount] = useState(0);
    const [costCenterMaxLevelAmount, setCostCenterMaxLevelAmount] = useState(0);
    const [currentTree, setCurrentTree] = useState<"account-hierarchy" | "cost-center-hierarchy">("account-hierarchy");
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [hasAccountLinkedAtLastLevel, setHasAccountLinkedAtLastLevel] = useState(false);
    const [hasCostCenterLinkedAtLastLevel, setHasCostCenterLinkedAtLastLevel] = useState(false);
    const [isFetchingAccountList, setIsFetchingAccountList] = useState(true);
    const [isFetchingCostCenterList, setIsFetchingCostCenterList] = useState(true);
    const [isSelectingAllLinked, setIsSelectingAllLinked] = useState(false);
    const [standardAccountId, setStandardAccountId] = useState(0);
    const [isSelectingAllUnlinked, setIsSelectingAllUnlinked] = useState(false);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const { data: costCenterOptions } = useCostCenterOptions();
    const { data: accountAccountingOptions } = useAccountAccountingOptions();
    const [isLastTreeNode, setIsLastTreeNode] = useState(false);
    const [availableAccounts, setAvailableAccounts] = useState<Options[]>([]);
    const [availableCostCenters, setAvailableCostCenters] = useState<Options[]>([]);
    const [hasPackageLevel, setHasPackageLevel] = useState(false);
    const [isStandardDisabled, setIsStandardDisabled] = useState(true);
    const [linkedCostCenterToPackage, setLinkedCostCenterToPackage] = useState<Options[]>([]);
    const [levelNumber, setLevelNumber] = useState(0);
    const { userInfo } = useUserContext();
    const { showBoundary } = useErrorBoundary();
    const [isSelectPackageLevel, setIsSelectPackageLevel] = useState(false);
    const [isFieldManingModalOpen, setIsFieldManingModalOpen] = useState<boolean>(false);

    const { data: allAccountLinked } = useCachedQuery<number[]>(
        ["all-account-linked"],
        {
            type: RequestType.GET,
            url: "/budget-base/account-hierarchy-link?locale={locale}&client={client}&hierarchy=0",
        },
        {},
        (data: AccountLinked[]) => data.filter((item) => item.selected).map((item) => item.accountId)
    );

    const { data: allCostCenterLinked } = useCachedQuery<number[]>(
        ["all-cost-center-linked"],
        {
            type: RequestType.GET,
            url: "/budget-base/cost-center-hierarchy-link?locale={locale}&client={client}&hierarchy=0",
        },
        {},
        (data: CostCenterLinked[]) => data.filter((item) => item.selected).map((item) => item.costCenterId)
    );

    const fieldNamingButton: CustomButtons[] = [
        {
            icon: <Icon icon="ri:input-field" style={{ color: "black" }} />,
            onClick: () => setIsFieldManingModalOpen(true),
            toolTip: i18n.t("groupings_texts.field_naming"),
            disabled: false,
        }
    ]

    const customButtons: CustomButtons[] = [
        {
            icon: <Icon icon="f7:cube-box-fill" />,
            onClick: () => {
                setCheckedNodeKeys([]);
                setIsSetPackageLevelModalOpen(true);
            },
            toolTip: i18n.t("spending_package")
            ,
            disabled: lookupLevels.length === 0,

        },
        {
            icon: <Icon icon="bitcoin-icons:verify-filled" style={{ fontSize: 32, marginTop: -4 }} />,
            onClick: () => setIsStandardAccountModalOpen(true),
            toolTip: i18n.t("standard_accounting_account"),
            disabled: checkedNodeKeys.length !== 1 || !hasPackageLevel ||
                !isSelectPackageLevel
        },
        {
            icon: <Icon icon="solar:link-circle-bold" />,
            onClick: () => setIsCostCenterLinkedPackageOpen(true),
            toolTip: i18n.t("cost-center-linked-to-package"),
            disabled:
                !packageLevel?.hasCostCenterLinked ||
                checkedNodeKeys.length !== 1 ||
                packageLevel?.id !== checkedNodeKeys[0]
        },
        ...fieldNamingButton
    ];

    const importProps: IImportModal[] = [
        {
            importUrl: "/budget-base/cost-center-hierarchy/import",
            templateUrl: "/budget-base/cost-center-hierarchy/template?locale={locale}",
            type: "excel",
            title: i18n.t("groupings_texts.imports.cost_center"),
        },
        {
            importUrl: "/budget-base/account-hierarchy/import",
            templateUrl: "/budget-base/account-hierarchy/template?locale={locale}",
            type: "excel",
            title: i18n.t("groupings_texts.imports.account"),
        },
        {
            importUrl: "/budget-base/cost-center-hierarchy-link/import",
            templateUrl: "/budget-base/cost-center-hierarchy-link/template?locale={locale}",
            type: "excel",
            title: i18n.t("groupings_texts.imports.cost_center_link"),
        },
        {
            importUrl: "/budget-base/account-hierarchy-link/import",
            templateUrl: "/budget-base/account-hierarchy-link/template?locale={locale}",
            type: "excel",
            title: i18n.t("groupings_texts.imports.account_link"),
        },
        {
            importUrl: "/budget-base/spending-package/cost-center-link-import",
            templateUrl: "/budget-base/spending-package/template?locale={locale}",
            type: "excel",
            title: i18n.t("groupings_texts.imports.spending_package"),
        },
    ];
    useEffect(() => {
        if (groupSelected.includes(i18n.t("ledger_account"))) {
            setLookupLevels(buildSelectValues(accountData));
        } else {
            setLookupLevels(buildSelectValues(costCenterData));
        }
    }, [accountData, costCenterData])

    useEffect(() => {
        fetchAccountLevelsHierarchy();
        fetchCostCenterLevelsHierarchy();
    }, []);

    useEffect(() => {
        if (!allAccountLinked || !accountAccountingOptions || accountAccountingOptions.length === 0) return;
        setAvailableAccounts(accountAccountingOptions.filter((account) => !allAccountLinked.includes(account.value)));
    }, [accountAccountingOptions, allAccountLinked]);

    useEffect(() => {
        if (!allCostCenterLinked || !costCenterOptions || costCenterOptions.length === 0) return;
        setAvailableCostCenters(
            costCenterOptions.filter((costCenter) => !allCostCenterLinked.includes(costCenter.value))
        );
    }, [costCenterOptions, allCostCenterLinked]);

    useEffect(() => {
        setCheckedNodeKeys([]);
    }, [groupSelected]);

    useEffect(() => {
        if (groupSelected.includes(i18n.t("ledger_account"))) {
            setLookupLevels(buildSelectValues(accountData));
        } else {
            setLookupLevels(buildSelectValues(costCenterData));
        }
    }, [accountData, costCenterData]);

    useEffect(() => {
        if (checkedNodeKeys.length === 0) {
            setIsDeletable(false);
            setIsEditable(false);
            setPackageLevel({} as DataLevels);
            return;
        }
        const treeData = groupSelected === i18n.t("ledger_account") ? accountData : costCenterData;
        const nodeSelected = findNodeTree(treeData, checkedNodeKeys[0]);
        const isLastNode = nodeSelected?.children.length === 0;
        if (isLastNode) {
            const updatedPackage = getPackageLevelIdByChildren(nodeSelected);
            setPackageLevel(updatedPackage);
            setIsLoadingTable(true);
            getTableLinked();
        }
        if (nodeSelected.hasCostCenterLinked) {
            getCostCenterLinkedToPackage(nodeSelected.id);
        }
        if (nodeSelected.isSpendingPackageLevel) {
            setPackageLevel(nodeSelected);
            getLinkedAccountByPackageId(nodeSelected.id);
        }

        const levelNumber = getLevelById(checkedNodeKeys[0], treeData);
        setLevelNumber(levelNumber);
        setIsLastTreeNode(isLastNode);
        setIsDeletable(true);
        if (checkedNodeKeys.length === 1) {
            setIsEditable(true);
        } else {
            setIsEditable(false);
        }
    }, [checkedNodeKeys]);

    useEffect(() => {
        if (isCostCenterLinkedPackageOpen) {
            getCostCenterLinkedToPackage(packageLevel.id);
        }
    }, [isCostCenterLinkedPackageOpen]);

    function getCostCenterLinkedToPackage(id) {
        ServiceCaller.doAsyncRequest<{ costCenters: number[] }>({
            type: RequestType.GET,
            url: `/budget-base/spending-package/get-cost-centers?organization={organization}&spendingPackageId=${id}`,
            useProxy: true,
        })
            .then((res) => {
                const updatedCostCenterList = costCenterOptions.filter((costCenter) => {
                    return res.data.costCenters.includes(costCenter.value);
                });
                setLinkedCostCenterToPackage(updatedCostCenterList);
            })
            .catch(() => { });
    }
    async function getLinkedAccountByPackageId(id) {
        await ServiceCaller.doAsyncRequest<[{ accounts: number[] }]>({
            type: RequestType.GET,
            url: `/budget-base/account-hierarchy-link/findAllAccountsOfSpendingPackage?spendingPackage=${id}`,
            useProxy: true,
        }).then((res) => {
            const updatedAccountLinkedList = accountAccountingOptions.filter((account) => {
                return res.data[0].accounts.includes(account.value);
            });
            setPackageAccountLinkedList(updatedAccountLinkedList);
            if (updatedAccountLinkedList.length > 0) {
                setIsStandardDisabled(false);
                getStandardAccount();
            } else {
                setIsStandardDisabled(true);
            }
        });
    }
    function getStandardAccount() {
        const level = findNodeTree(accountData, checkedNodeKeys[0]);
        const packageLevel = getPackageLevelIdByChildren(level);

        if (!packageLevel) {
            setStandardAccountId(0);
            return null;
        }

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/budget-base/standard-account/find?organization={organization}&spending-package=${packageLevel.id}`,
                useProxy: true,
            },
            (data) => {
                if (data.standardAccounts.length === 0) {
                    setStandardAccountId(0);
                    return;
                }
                setStandardAccountId(data.standardAccounts[0].standardAccountId);
            },
            (err) => showBoundary(err)
        );
    }
    function fetchAccountLevelsHierarchy(externalCode?: string) {
        ServiceCaller.doAsyncRequest<DataLevels[]>({
            type: RequestType.GET,
            url: "/budget-base/account-hierarchy?locale={locale}&client={client}&user={user}&organization={organization}&businessUnit={businessUnit}",
            useProxy: true,
        })
            .then((res) => onLoadLevelsHierarchy(res.data, "account-hierarchy", externalCode))
            .catch((err) => showBoundary(err));
    }

    function fetchCostCenterLevelsHierarchy(externalCode?: string) {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/budget-base/cost-center-hierarchy?locale={locale}&client={client}&user={user}&organization={organization}&businessUnit={businessUnit}",
                useProxy: true,
            },
            (data: DataLevels[]) => onLoadLevelsHierarchy(data, "cost-center-hierarchy", externalCode),
            (err) => showBoundary(err)
        );
    }

    function onLoadLevelsHierarchy(
        data: DataLevels[],
        currentTree: "account-hierarchy" | "cost-center-hierarchy",
        externalCode?: string
    ) {
        if (currentTree === "account-hierarchy") {
            const accountTreeMaxLevelAmount = getTreeMaxLevelAmount(data);
            setAccountMaxLevelAmount(accountTreeMaxLevelAmount);
            const hasLinkedAccount = verifyIfHasLinkedAtLastLevel(data);
            const updatedHasPackageLevel = verifyIfHasPackageLevel(data);
            setHasPackageLevel(updatedHasPackageLevel);
            setHasAccountLinkedAtLastLevel(hasLinkedAccount);
            setAccountData(convertData(data));
            setIsFetchingAccountList(false);
        } else {
            const costCenterTreeMaxLevelAmount = getTreeMaxLevelAmount(data);
            setCostCenterMaxLevelAmount(costCenterTreeMaxLevelAmount);
            const hasLinkedCostCenter = verifyIfHasLinkedAtLastLevel(data);
            setHasCostCenterLinkedAtLastLevel(hasLinkedCostCenter);
            setCostCenterData(convertData(data));
            setIsFetchingCostCenterList(false);
        }

        if (externalCode !== undefined) {
            const level = findTreeNode(convertData(data), [externalCode], "externalCode");
            setExpandedKeys((prevState) => [...prevState, level.parent]);
            setCheckedNodeKeys([level.key]);
        }
        setIsTreeDisabled(false);
    }

    function verifyIfHasPackageLevel(data: DataLevels[]): boolean {
        let hasPackageLevel = false;
        data.forEach((item) => {
            if (item.isSpendingPackageLevel) {
                hasPackageLevel = true;
            } else if (item.children.length > 0) {
                hasPackageLevel = verifyIfHasPackageLevel(item.children);
            }
        });
        return hasPackageLevel;
    }

    function convertData(data: DataLevels[]): LevelItem[] {
        return data
            .map((item) => {
                return {
                    ...item,
                    title: item.description,
                    key: item.id,
                    children: convertData(item.children),
                };
            })
            .sort((a, b) => sortList(a.title, b.title));
    }

    function onSaveNewLevel(externalCode) {
        Notification({
            type: "success",
            message: isNewLevel
                ? i18n.t("groupings_texts.level_created_successfully")
                : i18n.t("groupings_texts.level_successfully_edited"),
        });
        if (currentTree === "account-hierarchy") {
            fetchAccountLevelsHierarchy(externalCode);
        } else {
            fetchCostCenterLevelsHierarchy(externalCode);
        }
        form.resetFields();
    }

    function checkLastLevelAndLinkedData() {
    const hasLinkedAtLastLevel =
        currentTree === "account-hierarchy" ? hasAccountLinkedAtLastLevel : hasCostCenterLinkedAtLastLevel;
    const maxLevelAmount =
        currentTree === "account-hierarchy" ? accountMaxLevelAmount : costCenterMaxLevelAmount;

    return { hasLinkedAtLastLevel, maxLevelAmount };
}

function showLinkedDataNotification() {
    const messageKey =
        currentTree === "account-hierarchy"
            ? "groupings_texts.there_are_linked_accounts"
            : "groupings_texts.there_are_linked_cost_centers";

    Notification({
        type: "warning",
        message: i18n.t(messageKey),
    });
}

function openNewLevelModal(originLevel: number, isSelectDisabled: boolean) {
    form.setFieldsValue({ originLevel });
    setIsSelectDisabled(isSelectDisabled);
    setCheckedNodeKeys([]);
    setIsNewLevel(true);
    setIsNewLevelModalOpen(true);
}

function handleNewLevel(data: NewLevelFormData) {
    const { hasLinkedAtLastLevel, maxLevelAmount } = checkLastLevelAndLinkedData();
    let level =
        Number(data.originLevel) === 0
            ? 0
            : lookupLevels.find((item) => item.key === Number(data.originLevel)).level + 1;

    const isLastLevel = level === maxLevelAmount;
    const hasLinkedData = hasLinkedAtLastLevel;

    if (form.validateFields()) {
        setIsNewLevelModalOpen(false);
        setIsTreeDisabled(true);

        if (isLastLevel && hasLinkedData) {
            showLinkedDataNotification();
            setIsTreeDisabled(false);
            return;
        }

        const levelToSave = {
            description: data.description,
            externalCode: data.externalCode,
            parent: data.originLevel,
            level,
            id: isNewLevel ? null : checkedNodeKeys[0],
        };

        const urlToSave: string = groupSelected.includes(i18n.t("ledger_account"))
            ? "account-hierarchy"
            : "cost-center-hierarchy";

        ServiceCaller.doRequest(
            {
                type: isNewLevel ? RequestType.POST : RequestType.PUT,
                url: `/budget-base/${urlToSave}`,
                params: levelToSave,
            },
            () => onSaveNewLevel(data.externalCode),
            handleErrorRequest
        );
    }
}

function onClickNewLevel() {
    const treeData = currentTree === "account-hierarchy" ? accountData : costCenterData;
    const levelNumber = getLevelById(checkedNodeKeys[0], treeData);
    const { hasLinkedAtLastLevel, maxLevelAmount } = checkLastLevelAndLinkedData();

    if (hasLinkedAtLastLevel && maxLevelAmount === levelNumber) {
        showLinkedDataNotification();
        return;
    }

    openNewLevelModal(0, false);
}

function onAddSubLevel(parentId: number) {
    const { hasLinkedAtLastLevel, maxLevelAmount } = checkLastLevelAndLinkedData();
    const treeData = currentTree === "account-hierarchy" ? accountData : costCenterData;
    const currentLevel = getLevelById(parentId, treeData);

    if (hasLinkedAtLastLevel && maxLevelAmount === currentLevel) {
        showLinkedDataNotification();
        setIsTreeDisabled(false);
        return;
    }

    openNewLevelModal(parentId, true);
}

    async function handleSetPackageLevel(data: { packageLevel: number; hasCostCenterLinked: boolean }) {
        setIsSetPackageLevelModalOpen(false);
        await ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `/budget-base/account-hierarchy/link-spending-packages?level=${data.packageLevel
                }&hasCostCenterLinked=${!!data.hasCostCenterLinked}`,
            params: {},
        }).then(() => {
            fetchAccountLevelsHierarchy();
            Notification({
                type: "success",
                message: i18n.t("successfully_saved"),
            });
        });
    }

    function onChangeTab(activeTab: "account-hierarchy" | "cost-center-hierarchy") {
        let selected: LevelItem[] = [];
        setCurrentTree(activeTab);
        if (activeTab === "account-hierarchy") {
            setGroupSelected(i18n.t<string>("ledger_account"));
            selected = accountData;
        } else {
            setGroupSelected(i18n.t<string>("cost_center"));
            selected = costCenterData;
        }

        setIsSelectingAllLinked(false);
        setIsSelectingAllUnlinked(false);

        setCheckedNodeKeys([]);
        setLookupLevels(buildSelectValues(selected));
    }

    function findTreeNode(
        levels: LevelItem[],
        keys: Key[] | { checked: Key[]; halfChecked: Key[] },
        searchType: "key" | "externalCode"
    ): LevelItem {
        const key: Key = keys[0];

        let obj = null;

        for (let l = levels.length; l--;) {
            const level = levels[l];

            if (searchType === "key" && level.key === key) {
                obj = level;
                break;
            } else if (searchType === "externalCode" && level.externalCode === key) {
                obj = level;
                break;
            } else if (level.children.length > 0) {
                obj = findTreeNode(level.children, keys, searchType);

                if (obj) {
                    break;
                }
            }
        }

        return obj;
    }

    function onClickEdit() {
        const treeData = currentTree === "account-hierarchy" ? accountData : costCenterData;
        const currentNode: LevelItem = findTreeNode(treeData, checkedNodeKeys, "key");
        form.setFieldsValue({ originLevel: currentNode.parent });
        form.setFieldsValue({ description: currentNode.title });
        form.setFieldsValue({ externalCode: currentNode.externalCode });

        setIsNewLevel(false);
        setIsSelectDisabled(true);
        setIsNewLevelModalOpen(true);
    }

    function onClickDelete() {
        setIsTreeDisabled(true);

        ServiceCaller.doRequest(
            {
                type: RequestType.DELETE,
                url: `/budget-base/${currentTree}?locale={locale}&client={client}&ids=${checkedNodeKeys}`,
                useProxy: true,
            },
            () => {
                setCheckedNodeKeys([]);
                if (currentTree === "account-hierarchy") {
                    fetchAccountLevelsHierarchy();
                } else {
                    fetchCostCenterLevelsHierarchy();
                }
            },
            handleErrorRequest
        );
    }

    function buildSelectValues(data: LevelItem[]): LevelItem[] {
        let newData: LevelItem[] = [];

        data.forEach((line) => {
            if (line.children) {
                newData = newData.concat(buildSelectValues(line.children));
            }
            newData.push(line);
        });

        return newData;
    }

    function onDefineStandardAccount(id: number) {
        const level = findNodeTree(accountData, checkedNodeKeys[0]);
        const packageLevel = getPackageLevelIdByChildren(level);

        if (!packageLevel) return;

        ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `/budget-base/standard-account/define`,
            params: {
                organizationId: userInfo.selection.organizationId,
                spendingPackageLevelId: packageLevel.id,
                standardAccountId: id,
            },
        })
            .then(() => {
                Notification({
                    type: "success",
                    message: i18n.t<string>("successfully_saved"),
                });
                setIsStandardAccountModalOpen(false);
            })
            .catch(() => {
                Notification({
                    type: "error",
                    message: i18n.t<string>("notifications.something_went_wrong"),
                });
            });
    }

    function onLinkCostCenterToPackage(costCenterList: number[]) {
        setIsSavingCostCenterLinked(true);
        ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `/budget-base/spending-package/link-cost-center`,
            params: {
                spendingPackage: packageLevel.id,
                costCenters: costCenterList,
            },
        })
            .then(() => {
                Notification({
                    type: "success",
                    message: i18n.t<string>("successfully_saved"),
                });
            })
            .catch(() => {
                Notification({
                    type: "error",
                    message: i18n.t<string>("notifications.something_went_wrong"),
                });
            })
            .finally(() => {
                setIsSavingCostCenterLinked(false);
                setIsCostCenterLinkedPackageOpen(false);
            });
    }
    function getPackageLevelIdByChildren(level: DataLevels): DataLevels | null {
        if (level.isSpendingPackageLevel) {
            return level;
        } else if (level.parent === 0) {
            return null;
        }
        const parent = findNodeTree(accountData, level.parent);

        if (!parent) {
            return null;
        }

        return getPackageLevelIdByChildren(parent);
    }

    function onDeletePackageLevel(level: number) {
        ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `/budget-base/account-hierarchy/unlink-spending-packages?level=${level}`,
            params: {},
        })
            .then((data) => {
                fetchAccountLevelsHierarchy();
                Notification({
                    type: "success",
                    message: i18n.t<string>("successfully_deleted"),
                });
            })
            .catch((data) => {
                Notification({
                    type: "error",
                    message: i18n.t<string>("notifications.something_went_wrong"),
                });
            });
    }

    function getTableLinked() {
        if (checkedNodeKeys.length === 0) return;

        let url = "";

        if (groupSelected === i18n.t<string>("ledger_account")) {
            url = "account-hierarchy-link";
        } else {
            url = "cost-center-hierarchy-link";
        }

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/budget-base/${url}?locale={locale}&client={client}&hierarchy=${checkedNodeKeys[0]}`,
                useProxy: true,
            },
            (data: any[]) => {
                if (groupSelected === i18n.t<string>("ledger_account")) {
                    data.forEach((item) => {
                        item.description =
                            accountAccountingOptions.find((account) => account.value === item.accountId)?.label ?? "";
                        item.treeId = item.hierarchyAccountId;
                        item.key = item.accountId;
                    });
                    setLinkedData(data);
                } else {
                    data.forEach((item) => {
                        item.description =
                            costCenterOptions.find((account) => account.value === item.costCenterId)?.label ?? "";
                        item.treeId = item.hierarchyCostCenterId;
                        item.key = item.costCenterId;
                    });
                    setLinkedData(data);
                }
            },
            handleErrorRequest
        );
    }
    return (
        <div className="budget-groupings">
            <div className="page-title-content">
                <h1>{i18n.t<string>("groupings")}</h1>
            </div>
            <TopButtons
                mainButtonTitle={`${i18n.t<string>("register_levels")} (${groupSelected})`}
                mainButtonStyles={{ minWidth: 265 }}
                customButtons={groupSelected === i18n.t<string>("ledger_account") && customButtons || groupSelected === i18n.t<string>("cost_center") && fieldNamingButton}
                handleEdit={onClickEdit}
                handleNew={onClickNewLevel}
                handleDelete={onClickDelete}
                isDeletable={isDeletable}
                isEditable={isEditable}
                importExportOptions={{
                    importProps,
                }}
            />

            <div className="page-content">
                <Tabs
                    onChange={onChangeTab}
                    centered
                    activeKey={currentTree}
                    className="gs-tab"
                    type="card"
                    moreIcon={null}
                >
                    <Tabs.TabPane tab={i18n.t("ledger_account")} key="account-hierarchy">
                        <Content
                            isTreeDisabled={isTreeDisabled}
                            addNewSubLevel={onAddSubLevel}
                            setCheckedNodeKeys={setCheckedNodeKeys}
                            checkedNodeKeys={checkedNodeKeys}
                            selectedTab="ledger_account"
                            currentTreeData={accountData}
                            maxLevelAmount={accountMaxLevelAmount}
                            expandedKeys={expandedKeys}
                            setExpandedKeys={setExpandedKeys}
                            refreshSideBarItems={fetchAccountLevelsHierarchy}
                            isSelectingAllLinked={isSelectingAllLinked}
                            setIsSelectingAllLinked={setIsSelectingAllLinked}
                            isSelectingAllUnlinked={isSelectingAllUnlinked}
                            setIsSelectingAllUnlinked={setIsSelectingAllUnlinked}
                            isFetchingTableData={isFetchingAccountList}
                            linkedData={linkedData}
                            unlinkedData={availableAccounts}
                            getTableLinked={getTableLinked}
                            standardAccountId={standardAccountId}
                            levelNumber={levelNumber}
                            isLastTreeNode={isLastTreeNode}
                            isLoadingTable={isLoadingTable}
                            setIsLoadingTable={setIsLoadingTable}
                            packageLevel={packageLevel}
                            setIsSelectPackageLevel={setIsSelectPackageLevel}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("cost_center")} key="cost-center-hierarchy">
                        <Content
                            isTreeDisabled={isTreeDisabled}
                            addNewSubLevel={onAddSubLevel}
                            setCheckedNodeKeys={setCheckedNodeKeys}
                            checkedNodeKeys={checkedNodeKeys}
                            selectedTab="cost_center"
                            currentTreeData={costCenterData}
                            maxLevelAmount={costCenterMaxLevelAmount}
                            expandedKeys={expandedKeys}
                            setExpandedKeys={setExpandedKeys}
                            refreshSideBarItems={fetchCostCenterLevelsHierarchy}
                            isSelectingAllLinked={isSelectingAllLinked}
                            setIsSelectingAllLinked={setIsSelectingAllLinked}
                            isSelectingAllUnlinked={isSelectingAllUnlinked}
                            setIsSelectingAllUnlinked={setIsSelectingAllUnlinked}
                            isFetchingTableData={isFetchingCostCenterList}
                            linkedData={linkedData}
                            unlinkedData={availableCostCenters}
                            getTableLinked={getTableLinked}
                            levelNumber={levelNumber}
                            isLastTreeNode={isLastTreeNode}
                            isLoadingTable={isLoadingTable}
                            setIsLoadingTable={setIsLoadingTable}
                            packageLevel={packageLevel}
                            setIsSelectPackageLevel={setIsSelectPackageLevel}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <NewLevelModal
                form={form}
                handleNewLevel={handleNewLevel}
                handleCancel={() => setIsNewLevelModalOpen(false)}
                checkedNodeKeys={checkedNodeKeys}
                isSelectDisabled={isSelectDisabled}
                groupSelected={groupSelected}
                open={isNewLevelModalOpen}
                lookupLevels={lookupLevels}
            />
            <SetPackageLevelModal
                handleSetPackageLevel={handleSetPackageLevel}
                handleCancel={() => setIsSetPackageLevelModalOpen(false)}
                open={isSetPackageLevelModalOpen}
                lookupLevels={lookupLevels}
                onDeletePackageLevel={onDeletePackageLevel}
            />
            <StandardAccountModal
                onDefineStandardAccount={onDefineStandardAccount}
                handleCancel={() => setIsStandardAccountModalOpen(false)}
                open={isStandardAccountModalOpen}
                packageAccountLinkedList={packageAccountLinkedList}
                standardAccountId={standardAccountId}
                packageLevel={packageLevel}
            />
            <CostCenterLinkedPackageModal
                form={form}
                onLinkCostCenter={onLinkCostCenterToPackage}
                handleCancel={() => setIsCostCenterLinkedPackageOpen(false)}
                open={isCostCenterLinkedPackageOpen}
                costCenterOptions={costCenterOptions}
                linkedCostCenter={linkedCostCenterToPackage}
                setLinkedCostCenter={setLinkedCostCenterToPackage}
                isSaving={isSavingCostCenterLinked}
            />
            <FieldManingModal
                handleCancel={() => setIsFieldManingModalOpen(false)}
                open={isFieldManingModalOpen}
                accountData={accountData}
                costCenterData={costCenterData}
                fetchCostCenterLevelsHierarchy={fetchCostCenterLevelsHierarchy}
                fetchAccountLevelsHierarchy={fetchAccountLevelsHierarchy}
            />
        </div>
    );
}