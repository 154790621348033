import { Form } from "antd";
import { Notification } from "components/notification/Notification";
import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import CollaboratorsModal from "./components/CollaboratorsModal";
import CollaboratorsTable from "./components/CollaboratorsTable";
import { SelectedCollaborators, ValueForm } from "./ICollaborators";
import moment from "moment";
import { useUserContext } from "context/UserContext";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { ColumnsType } from "antd/lib/table";
import './style.sass';
import { sortList } from "util/functions/sortList";

export default function Collaborators() {
	const [tableData, setTableData] = useState([]);
	const [isFetching, setIsFetching] = useState(true);
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
	const [selectedRows, setSelectedRows] = useState<SelectedCollaborators[]>([]);
	const [isNewCollaborator, setIsNewCollaborator] = useState(true);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [newCollaboratorsList, setNewCollaboratorsList] = useState<ValueForm[]>([]);
	const [filterdTableData, setFilterdTableData] = useState([]);
	const [form] = Form.useForm();
	const { userInfo: { selection: { organizationId, scenarioId } } } = useUserContext();

	const importProps: IImportModal[] = [
		{
			importUrl: "/human-resources/resources/import",
			templateUrl: "/human-resources/resources/template?locale={locale}",
			type: 'excel'
		}
	]
	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/human-resources/resources/find-all?scenario={scenario}&organization={organization}`
		}, onLoadCollaboratorsTableData.bind(this));
	}, []);

	useEffect(() => {
		setFilterdTableData(tableData);
	}, [tableData]);


	function onLoadCollaboratorsTableData(data) {
		if (data.length === 0) {
			setTableData([]);
			setIsFetching(false);
			return;
		};
		const updateTableDate = data.map(res => {
			return {
				key: res.id,
				organizationId: res.organizationId,
				externalCode: res.externalCode,
				description: res.description,
				hiringDate: res.hiringDate,
				resignationDate: res.resignationDate,
				resourceType: res.resourceType,
				active: res.active
			};
		}).sort((a, b) => sortList(a.externalCode, b.externalCode));

		setTableData(updateTableDate);
		setIsFetching(false);
	};

	function handleOpenModal(isNewCollaborator: boolean) {
		if (isNewCollaborator) {
			setIsNewCollaborator(true);
			setSelectedRowKeys([]);
			setSelectedRows([]);
			setNewCollaboratorsList([]);
			form.resetFields();
		} else {
			form.setFieldsValue({
				externalCode: selectedRows[0].externalCode,
				hiringDate: moment(selectedRows[0].hiringDate),
				description: selectedRows[0].description,
				resourceType: selectedRows[0].resourceType === "NEW" ? 0 : 1
			});
			setIsNewCollaborator(false);
		}
		setIsModalVisible(true);
	};

	function handleCloseTemplateModal() {
		setSelectedRowKeys([]);
		setSelectedRows([]);
		form.resetFields();
		setIsModalVisible(false);
	};

	function onSelectRowChange(selectedRowKeys: Key[], selectedRows: SelectedCollaborators[]) {
		setSelectedRows(selectedRows);
		setSelectedRowKeys(selectedRowKeys);
	};

	function handleDeleteCollaborators() {
		setIsFetching(true)
		ServiceCaller.doRequest({
			type: RequestType.DELETE,
			url: `/human-resources/resources?ids=${selectedRowKeys.toString()}`,
		}, onDeleteCollaborator.bind(this), onRequestError.bind(this));
		setSelectedRowKeys([]);
		setSelectedRows([]);
	}
	
	function onRequestError() {
        Notification({
            type: "warning",
            message: i18n.t("association_warning"),
        });
        setIsFetching(false)
    }

	function onDeleteCollaborator(data) {
		if (data) {
			Notification({
				type: "success",
				message: i18n.t("successfully_deleted"),
			});
		}
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/human-resources/resources/find-all?scenario={scenario}&organization=${organizationId}`,
		}, onLoadCollaboratorsTableData.bind(this));
	};

	function handleIncludeCollaborators(data: ValueForm) {
		if (isNewCollaborator) {
			setNewCollaboratorsList([...newCollaboratorsList, data]);
		} else {
			handleSaveCollaborators([data]);
		}
		form.resetFields();
	}

	function handleSaveCollaborators(data) {

		const collaboratorToSave = data.map(
			collaborator => {
				return {
					id: isNewCollaborator ? null : selectedRowKeys[0],
					scenarioId: scenarioId,
					organizationId: organizationId,
					externalCode: collaborator.externalCode,
					description: collaborator.description,
					hiringDate: moment(collaborator.hiringDate).format('YYYY-MM-DD'),
					resignationDate: null,
					resourceType: collaborator.resourceType,
					active: true,
				}
			});

		setSelectedRowKeys([]);
		setSelectedRows([]);

		setIsModalVisible(false);
		setIsFetching(true);

		ServiceCaller.doRequest({
			type: isNewCollaborator ? RequestType.POST : RequestType.PUT,
			url: '/human-resources/resources',
			params: isNewCollaborator ? collaboratorToSave : collaboratorToSave[0],
		}, onSaveCollaborator.bind(this));
	}

	function onSaveCollaborator(data) {
		if (data) {
			Notification({
				type: "success",
				message: i18n.t("successfully_saved"),
			});
		}
		form.resetFields();
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/human-resources/resources/find-all?scenario={scenario}&organization={organization}`
		}, onLoadCollaboratorsTableData.bind(this));
	};

	const tableColumns: ColumnsType = [
		{
			title: i18n.t<string>("code"),
			dataIndex: "externalCode",
			key: "externalCode",
			align: "center",
			width: 200,
		},
		{
			title: i18n.t<string>("description"),
			dataIndex: "description",
			key: "description",
			align: "left",
			width: 500,
			className: 'collaborators-columns'
		},
		{
			title: i18n.t<string>("admission_date"),
			dataIndex: "hiringDate",
			key: "hiringDate",
			align: "center",
			width: 200,
			render: (date) => date ? moment(date).format('DD/MM/YYYY') : '-'
		},
		{
			title: i18n.t<string>("type"),
			dataIndex: "resourceType",
			key: "resourceType",
			align: "left",
			className: 'collaborators-columns',
			render: (type) => type === "NEW" ? i18n.t<string>("new_o").toUpperCase() :
				(type === "EFETIVE" ? i18n.t<string>("effective_o").toUpperCase() : "")
		}
	];

	return (
		<>
			<div className="page-title-content">
				<h1>{i18n.t<string>("collaborators.collaborators.employee_registration")}</h1>
			</div>
			<div id="top-buttons-collaborators">
				<TopButtons
					mainButtonTitle={i18n.t<string>("collaborators.collaborators.new_employee")}
					handleNew={() => handleOpenModal(true)}
					handleEdit={() => handleOpenModal(false)}
					handleDelete={handleDeleteCollaborators}
					isEditable={selectedRows.length === 1}
					isDeletable={selectedRows.length > 0}
					multipleSearch={{
						tableData: tableData,
						setTableData: setFilterdTableData,
						options: [
							{ i18nString: 'code', description: 'externalCode' },
							{ i18nString: 'description', description: 'description' },
						]
					}}
					importExportOptions={{
						exportGridData: () => handleExportGridData(tableData, tableColumns, i18n.t("collaborators_name")),
						importProps: importProps
					}}
				/>
			</div>
			<main id="collaborators-main">
				<CollaboratorsTable
					isFetching={isFetching}
					tableData={filterdTableData}
					selectedRowKeys={selectedRowKeys}
					onChange={onSelectRowChange}
					tableColumns={tableColumns}
				/>
				<CollaboratorsModal
					isModalVisible={isModalVisible}
					isNewCollaborator={isNewCollaborator}
					handleSubmit={handleIncludeCollaborators}
					handleCancel={handleCloseTemplateModal}
					form={form}
					newCollaboratorsList={newCollaboratorsList}
					setNewCollaboratorsList={setNewCollaboratorsList}
					handleSaveCollaborators={handleSaveCollaborators}
					tableData={filterdTableData}
					selectedRowKeys={selectedRowKeys}
				/>
			</main>
		</>
	)
}