import { useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import {
    Button,
    Divider,
    Grid,
    Input,
    Popover,
    Row,
    Table,
    TableColumnsType,
    Tabs,
    TabsProps    
} from "antd";

import { CustomButtons } from "components/topButtons/ITopButtons";

import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { useUserContext } from "context/UserContext";
import {
    AccountingAccountBean,
    HierarchyAccountBean
} from "./IRecurringContractExporter";
import StringUtil from "util/StringUtil";

const RecurringContractExporter = (): CustomButtons => {
    const { userInfo } = useUserContext();

    const [isPreparingToExport, setPreparingToExport] = useState<boolean>(false);
	const [isShowExportDataContainer, setDisplayExportDataContainer] = useState<boolean>(false);

    const [accountingAccountSearch, setAccountingAccountSearch] = useState<string>("");
    const [accountingAccountSelecteds, setAccountingAccountSelecteds] = useState<number[]>([]);
    const [accountingAccounts, setAccountingAccounts] = useState<AccountingAccountBean[]>([]);
    const accountingAccountGridColumns: TableColumnsType<AccountingAccountBean> = [{
        title: i18n.t<string>("external_code"),
        dataIndex: "externalCode",
        key: "externalCodeColumn"
    }, {
        title: i18n.t<string>("name"),
        dataIndex: "name",
        key: "nameColumn"
    }];

    let accountingAccountsToShow: AccountingAccountBean[] = accountingAccounts;
    if (accountingAccountSearch.trim() !== "") {
        accountingAccountsToShow = accountingAccountsToShow.filter(({externalCode, name}) => StringUtil.getWithoutSpecials(externalCode).includes(accountingAccountSearch) || StringUtil.getWithoutSpecials(name).includes(accountingAccountSearch));
    }

    const [hierarchyAccountSearch, setHierarchyAccountSearch] = useState<string>("");
    const [hierarchyAccountSelecteds, setHierarchyAccountSelecteds] = useState<number[]>([]);
    const [hierarchyAccounts, setHierarchyAccounts] = useState<HierarchyAccountBean[]>([]);
    const hierarchyAccountGridColumns: TableColumnsType<HierarchyAccountBean> = [{
        title: i18n.t<string>("external_code"),
        dataIndex: "externalCode",
        key: "externalCodeColumn"
    }, {
        title: i18n.t<string>("name"),
        dataIndex: "description",
        key: "descriptionColumn"
    }];

    let hierarchyAccountsToShow: HierarchyAccountBean[] = hierarchyAccounts;
    if (hierarchyAccountSearch.trim() !== "") {
        hierarchyAccountsToShow = hierarchyAccountsToShow.filter(({externalCode, description}) => StringUtil.getWithoutSpecials(externalCode).includes(hierarchyAccountSearch) || StringUtil.getWithoutSpecials(description).includes(hierarchyAccountSearch));
    }

    const exporterDataTabs: TabsProps["items"] = [{
        key: "recurring-contract-account-tab",
        label: i18n.t<string>("ledger_account"),
        children: <>
            <Input placeholder={i18n.t<string>("search")} onChange={(event) => setAccountingAccountSearch(StringUtil.getWithoutSpecials(event.target.value))} />

            <Table dataSource={accountingAccountsToShow} columns={accountingAccountGridColumns} rowKey="id" rowSelection={{
                type: "checkbox",
                onChange: (selecteds: number[]) => setAccountingAccountSelecteds(selecteds)
            }} pagination={false} scroll={{ y: 240 }} />
        </>
    }, {
        key: "recurring-contract-account-hierarchy-tab",
        label: `${i18n.t<string>("groupings")} - ${i18n.t<string>("ledger_account")}`,
        children: <>
            <Input placeholder={i18n.t<string>("search")} onChange={(event) => setHierarchyAccountSearch(StringUtil.getWithoutSpecials(event.target.value))} />

            <Table dataSource={hierarchyAccountsToShow} columns={hierarchyAccountGridColumns} rowKey="id" rowSelection={{
                type: "checkbox",
                onChange: (selecteds: number[]) => setHierarchyAccountSelecteds(selecteds)
            }} pagination={false} expandable={{showExpandColumn: false}} scroll={{ y: 240 }} />
        </>
    }];

    const handlePreparingToExport = () => {
        setPreparingToExport(true);

        ServiceCaller.doRequest({
            type: RequestType.POST,
			url: "/revenue/recurring-contract/export/",
			params: {
                scenarioId: userInfo.selection.scenarioId,
                organizationId: userInfo.selection.organizationId,
                accountIds: accountingAccountSelecteds,
                accountHierarchyIds: hierarchyAccountSelecteds
            }
        }, () => {
            setPreparingToExport(false);
            setDisplayExportDataContainer(false);
        }, () => {
            setPreparingToExport(false);
        });
    };

    const exportDataContainer: React.ReactElement = <>
        <Row key="recurring-contract-export-data-container">
            <Tabs items={exporterDataTabs} />
        </Row>

        <Divider />

        <Row justify="end">
            <Button disabled={accountingAccountSelecteds.length === 0 && hierarchyAccountSelecteds.length === 0} loading={isPreparingToExport} onClick={handlePreparingToExport} type="primary">{i18n.t<string>("export")}</Button>
        </Row>
	</>;

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/monolith/accountingaccount/get-all-by-account-type?client={client}&locale={locale}&organization={organization}&classification=3"
        }, (accountingAccounts: AccountingAccountBean[]) => setAccountingAccounts(accountingAccounts));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/budget-base/account-hierarchy/find-last-childrens?&businessUnit={businessUnit}&client={client}&locale={locale}&user={user}&organization={organization}"
        }, (hierarchyAccounts: HierarchyAccountBean[]) => setHierarchyAccounts(hierarchyAccounts));
    }, []);

    return {
		toolTip: i18n.t<string>("revenue.available_new_sales_sms_data_integration"),
		icon: <Popover id="recurring-contract-exporter-popover-container" content={exportDataContainer} trigger="click" open={isShowExportDataContainer} onOpenChange={setDisplayExportDataContainer} placement="bottomLeft" overlayStyle={{
            width: "40vw"
          }}>
			<Icon icon="vaadin:money-exchange" />
		</Popover>
	};

}

export default RecurringContractExporter