import { Icon } from "@iconify/react";
import { Col, Row, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IOptionsForSearch } from "components/input/search/IMultipleSearch";
import { ModulesType } from "components/modalFilter/IModalFilter";
import { Notification } from "components/notification/Notification";
import { CustomButtons } from "components/topButtons/ITopButtons";
import { TopButtons } from "components/topButtons/TopButtons";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { AllContractTableData, ContractToEdit, FlexFilterContract, IRecurringContractCommon, IRecurringContractResponse, IRevenuePeriodsResponse, ISearchType } from "./IRecurringContract";
import "./RecurringContract.sass";
import AllContractTable from "./components/AllContractTable";
import ContractModal from "./components/ContractModal";
import EmptyDate from "./components/EmptyDate";
import RecurringContractExporter from "./components/RecurringContractExporter";
import { RecurringMultipleSearch } from "./components/RecurringMultipleSearch";
import ValueView from "./components/ValueView";

export default function RecurringContract() {
	const [period, setPeriod] = useState<moment.Moment>();
	const [isLoadingPeriod, setIsLoadingPeriod] = useState(true);
	const [allContractTableData, setAllContractTableData] = useState<AllContractTableData[]>([]);
	const [allContractTableDataFiltered, setAllContractTableDataFiltered] = useState<AllContractTableData[]>([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
	const [isContractModalOpen, setIsContractModalOpen] = useState(false);
	const [flexFieldValues, setFlexFieldValues] = useState<FlexFilterContract[]>([]);
	const [isLoadingAllContractTable, setIsLoadingAllContractTable] = useState(true);
	const { userInfo: { groupIds, id: userId, clientId, localeId, selection: { organizationId, scenarioId, businessUnitId } } } = useUserContext();
	const [contractToEdit, setContractToEdit] = useState<any>();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [currentPageSize, setCurrentPageSize] = useState<number>(20);
	const [totalItems, setTotalItems] = useState(0);
	const [columns, setColumns] = useState<ColumnsType<AllContractTableData>>([]);
	const [valueViewId, setValueViewId] = useState<Key>(0);
	const [isRunningIntegration, setIsRunningIntegration] = useState<boolean>(true);
	const [isRunningExpensesIntegration, setIsRunningExpensesIntegration] = useState<boolean>(true);
	const [isLoadingRevenueIntegrationButton, setIsLoadingRevenueIntegrationButton] = useState<boolean>(false);
	const [isLoadingExpensesIntegrationButton, setIsLoadingExpensesIntegrationButton] = useState<boolean>(false);
	const [frozenPeriod, setFrozenPeriod] = useState<moment.Moment>();
	const [searchType, setSearchType] = useState<string>("contract");
	const [searchContract, setSearchContract] = useState<string>("");
	const [searchPeriodicity, setSearchPeriodicity] = useState<string>();
	const [contractType, setContractType] = useState<string>();
	const [flexFieldValueIds, setFlexFieldValueIds] = useState<number[]>([]);
	const defaultSearchOptions: IOptionsForSearch[] = [
		{
			description: "contract",
			i18nString: "contracts"
		},
		{
			description: "contractType",
			i18nString: "contract_type"
		},
		{
			description: "periodicity",
			i18nString: "periodicity"
		},
	];
	const { userInfo } = useUserContext();

	const importProps: IImportModal[] = [
		{
			importUrl: "/revenue/recurring-contract/recurring/importer",
			templateUrl: "/revenue/recurring-contract/recurring/download-template?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t("imports.import_recurring_contract_via_XLSX")
		},
		{
			importUrl: "/revenue/recurring-contract/no-recurring/importer",
			templateUrl: "/revenue/recurring-contract/no-recurring/download-template?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t("imports.import_non_recurring_contract_via_XLSX")
		},
		{
			importUrl: "/revenue/recurring-contract/new-sales/importer",
			templateUrl: "/revenue/recurring-contract/new-sales/download-template?user={user}&client={client}&locale={locale}",
			type: 'excel',
			title: i18n.t("imports.import_new_sales_contract_via_XLSX")
		}
	]
	const viewValuesButton: CustomButtons = {
		title: i18n.t<string>("view_values"),
		disabled: !selectedRowKeys.length,
		className: `gs-secondary-button order-redefinition ${selectedRowKeys.length || "disabled-button"} `,
		onClick: () => {
			setValueViewId(selectedRowKeys[0]);
			setSelectedRowKeys([]);
		}
	}

	const startIntegrationButton: CustomButtons = {
		toolTip: i18n.t<string>(isRunningIntegration ? "revenue.running_integration" : "revenue.available_data_integration"),
		icon: <Icon icon={isRunningIntegration ? "mdi:cash-clock" : "vaadin:money-deposit"} />,
		disabled: isRunningIntegration,
		loading: isLoadingRevenueIntegrationButton,
		onClick: () => {
			setIsLoadingRevenueIntegrationButton(true);

			ServiceCaller.doRequest({
				type: RequestType.POST,
				url: "/revenue/recurring-contract/integration/start",
				params: {
					client: clientId,
					user: userId,
					locale: localeId,
					scenario: scenarioId,
					organization: organizationId
				}
			}, (isRunning: boolean) => {
				Notification({
					message: i18n.t<string>("revenue.text_001"),
					type: "success"
				});

				setIsRunningIntegration(isRunning);
				setIsLoadingRevenueIntegrationButton(false);
			});
		}
	}

	const recurringContractExporterButton: CustomButtons = RecurringContractExporter();

	const startExpensesIntegrationButton: CustomButtons = {
		toolTip: i18n.t<string>(isRunningExpensesIntegration ? "revenue.running_expenses_integration" : "revenue.available_expenses_data_integration"),
		icon: <Icon icon={isRunningExpensesIntegration ? "mdi:cash-clock" : "vaadin:money-withdraw"} />,
		disabled: isRunningExpensesIntegration,
		loading: isLoadingExpensesIntegrationButton,
		onClick: () => {
			setIsLoadingExpensesIntegrationButton(true);

			ServiceCaller.doRequest({
				type: RequestType.POST,
				url: "/revenue/recurring-contract/expenses-integration/start",
				params: {
					client: clientId,
					user: userId,
					locale: localeId,
					scenario: scenarioId,
					organization: organizationId,
					businessUnit: businessUnitId
				}
			}, (isRunning: boolean) => {
				Notification({
					message: i18n.t<string>("revenue.text_001"),
					type: "success",
				});

				setIsRunningExpensesIntegration(isRunning);
				setIsLoadingExpensesIntegrationButton(false);
			});
		}
	}

	const updatePaginationValues = ({ totalElements, pageable: { pageSize, pageNumber } }: IRecurringContractResponse) => {
		setCurrentPageSize(pageSize);
		setCurrentPage(pageNumber + 1);
		setTotalItems(totalElements);
	}
	const chooseSearchType = {
		"contract": "CONTRACT_DESCRIPTION",
		"contractType": "CONTRACT_TYPE",
		"periodicity": "PERIODICITY",
	};

	const pagination: TablePaginationConfig = {
		current: currentPage,
		pageSize: currentPageSize,
		onChange(page: number, pageSize: number) {
			setCurrentPage(page);
			setCurrentPageSize(pageSize);
			doRequest(page, pageSize, flexFieldValueIds, false, searchContract, chooseSearchType[searchType], contractType, searchPeriodicity);
		},
		total: totalItems,
		pageSizeOptions: [10, 20, 50, 100],
		hideOnSinglePage: totalItems < 10 ? true : false,
		showSizeChanger: true,
		defaultPageSize: 15,
		showTotal: (total) => `${total} itens`,
	}

	function onLoadEdit(data: ContractToEdit[]): void {
		if (!data.length) return;
		setContractToEdit(data[0]);
		setSelectedRowKeys([]);
	}

	function handleEdit(): void {
		ServiceCaller.doRequest({
			url: `/revenue/recurring-contract/find-by-ids?ids=${selectedRowKeys}&isModal=true&scenarioId=${scenarioId}&userId={user}`,
			type: RequestType.GET
		}, onLoadEdit)
	}

	function handleDelete(): void {
		ServiceCaller.doRequest({
			type: RequestType.DELETE,
			url: `/revenue/recurring-contract?ids=${selectedRowKeys}`,
		}, doRequest)
		Notification({
			message: i18n.t("successfully_deleted"),
			type: "success"
		})
	}

	const optionsPeriodicityType = {
		MONTHLY: i18n.t("revenue.report_viewer.monthly"),
		BIMONTHLY: i18n.t("revenue.report_viewer.bimonthly"),
		QUARTERLY: i18n.t("revenue.report_viewer.quarterly"),
		FOURMONTHLY: i18n.t<string>("revenue.report_viewer.fourmonthly"),
		SEMESTERLY: i18n.t("revenue.report_viewer.semesterly"),
		YEARLY: i18n.t("revenue.report_viewer.yearly"),
	}

	const chooseOptionsContractType = {
		RECURRING: i18n.t("recurrent"),
		NOT_RECURRING: i18n.t("non_recurring"),
		NEW_SALES: i18n.t("new_sales"),
		CHURN: i18n.t("churn"),
		NEW_SALES_SMS: i18n.t("new_sales_sms"),
	}

	function formatData(data: IRecurringContractCommon[]): AllContractTableData[] {
		const newData: AllContractTableData[] = data.map(({ contractDescription, periodBase, contractType, periodicityType, id, flexFieldValueItens, value }) => {
			return ({
				contract: contractDescription,
				basePeriod: periodBase,
				contractType: chooseOptionsContractType[contractType.toString()],
				periodicity: optionsPeriodicityType[periodicityType],
				key: id,
				flexFieldValueItens,
				value
			})
		})

		return newData;
	}

	function onLoadTableData(data: IRecurringContractResponse): void {
		const formattedData: AllContractTableData[] = formatData(data.content);
		setAllContractTableData(formattedData);
		setIsLoadingAllContractTable(false);
		updatePaginationValues(data);
	}

	const doRequest = (
		page: number = currentPage,
		pageSize: number = currentPageSize,
		flexFieldValueIdsParam: number[] = flexFieldValueIds, 
		isFilterModal: boolean = false,
		search: string = "",
		searchType?: ISearchType,
		contractType?: string,
		periodicityType?: string
	) => {
		if (!organizationId) return;
		setIsLoadingAllContractTable(true);
		const bodyParams = {
			organizationId,
			scenarioId,
			isFilterModal: isFilterModal,
			flexFieldValueIds: flexFieldValueIdsParam || [],
			userId: userInfo.id,
			groupIds: userInfo.groupIds,
			search: search,
			searchType: searchType,
			contractTypes: [contractType].filter(item => item),
			periodicityTypes: [periodicityType].filter(item => item),
			businessUnitId
		}

		if(!pageSize) {
			pageSize = currentPageSize
		}

		if (!page) {
			page = 0;
		} else {
			page = page - 1;
		}

		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: `/revenue/recurring-contract/get-all?page=${page}&size=${pageSize}&sort=id,desc`,
			params: bodyParams
		}, onLoadTableData)
	}
	
	function handleAddFlexOptFilter(data: any[]): void {
		setFlexFieldValues(data.map(flexField => {
			return {
				value: flexField.value,
				label: flexField.label,
				fieldCode: flexField.fieldCode,
				selectedOption: [],
				children: flexField.children.map(ffValues => {
					return {
						value: ffValues.value,
						label: ffValues.externalCode + ' - ' + ffValues.description
					}
				}),
				id: flexField.id
			}
		}).sort((a, b) => {
			if (a.fieldCode < b.fieldCode) {
				return -1;
			} else if (a.fieldCode > b.fieldCode) {
				return 1;
			} else {
				return 0;
			}
		}));
	}

	const checkRevenueIntegrationStatus = () => {
		setIsLoadingRevenueIntegrationButton(true);

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/revenue/recurring-contract/integration/check-status?user={user}&client={client}"
		}, (isRunning: boolean) => {
			setIsRunningIntegration(isRunning);
			setIsLoadingRevenueIntegrationButton(false);
		});
	};

	const checkExpensesIntegrationStatus = () => {
		setIsLoadingRevenueIntegrationButton(true);

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/revenue/recurring-contract/expenses-integration/check-status?user={user}&client={client}"
		}, (isRunning: boolean) => {
			setIsRunningExpensesIntegration(isRunning);
			setIsLoadingExpensesIntegrationButton(false);
		});
	};

	const onLoadRevenuePeriods = ({ periodBase, periodFrozen }: IRevenuePeriodsResponse) => {
		if (periodFrozen) {
			setFrozenPeriod(moment(periodFrozen));
		}
		if (periodBase) {
			setPeriod(moment(periodBase));
		}
		setIsLoadingPeriod(false);
	}

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/flexfilter?module=4&userId={user}&localeId={locale}&clientId={client}`,
		}, handleAddFlexOptFilter.bind(this));

		const year: number = moment().year();
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/control-panel/get-revenue-periods?organizationId={organization}&scenarioId={scenario}&year=${year}`,
		}, onLoadRevenuePeriods);
	}, []);

	useEffect(() => {
		setAllContractTableDataFiltered(allContractTableData);
	}, [allContractTableData]);

	useEffect(() => {
		if (isContractModalOpen || !organizationId || !scenarioId || !period) return;
		doRequest();
		checkRevenueIntegrationStatus();
		checkExpensesIntegrationStatus();
	}, [period, isContractModalOpen, organizationId, scenarioId])

	function handlerExportGrid(columns, fileName) {
		const bodyParams = {
			organizationId,
			scenarioId,
			isFilterModal: false,
			flexFieldValueIds: [],
			userId,
			groupIds,
		};

		ServiceCaller.doRequest(
			{
				type: RequestType.POST,
				url: `/revenue/recurring-contract/export`,
				params: bodyParams,
			},
			(data: AllContractTableData[]) => {
				const formattedData: AllContractTableData[] = formatData(data);
				handleExportGridData(formattedData, columns, fileName);
			}
		);
	}

	return (
		<div className="recurring-contract-page">
			{isLoadingPeriod || (!isLoadingPeriod && period) ? (
				<>
					{valueViewId ? (
						<ValueView
							contractId={valueViewId}
							period={period}
							setContractId={setValueViewId}
						/>
					) : (
						<>
							<div className="header-content">
								<Row className="title-content">
									<Col className="page-title-content">
										<h1>{i18n.t("recurring_contract")}</h1>
									</Col>
									<Col className="period-base-selected">
										<p>{i18n.t("base_period")}</p>
										<span>{period?.format("MM/YYYY")}</span>
									</Col>
								</Row>
								<Row className="container-header">
									<Col>
										<TopButtons
											mainButtonTitle={i18n.t<string>("new_contract")}
											handleNew={() => setIsContractModalOpen(true)}
											mainButtonStyles={{ order: -1 }}
											customButtons={[
												viewValuesButton,
												startIntegrationButton,
												startExpensesIntegrationButton,
												recurringContractExporterButton
											]}
											handleEdit={handleEdit}
											handleDelete={handleDelete}
											isEditable={selectedRowKeys.length === 1}
											isDeletable={!!selectedRowKeys.length}
											moduleType={ModulesType.RECURRING_REVENUE}
											handleFilter={({ flexFieldsSelected }) => {
												const flexFieldsId: number[] = flexFieldsSelected.map(({ value }) => value);
												setFlexFieldValueIds(flexFieldsId); 
												doRequest(null, null, flexFieldsId, true, searchContract, chooseSearchType[searchType], contractType, searchPeriodicity);
											}}
											hasFilter
											onCleanFilters={() => {
												setFlexFieldValueIds([]); 
												doRequest(null, null, [], true);
											}}
										/>
									</Col>
									<Col className="container-header-left">
										<div className="items-header-left">
											<RecurringMultipleSearch
												doRequest={doRequest}
												setTableData={setAllContractTableData}
												tableData={allContractTableData}
												options={defaultSearchOptions}
												setSearchType={setSearchType}
												setSearchContract={setSearchContract}
												setSearchPeriodicity={setSearchPeriodicity}
												setContractType={setContractType}
												contractType={contractType}
												searchContract={searchContract}
												searchPeriodicity={searchPeriodicity}
												searchType={searchType}
												flexFieldValueIds={flexFieldValueIds}
											/>
											<ImportExportMenu
												importProps={importProps}
												exportGridData={() =>
													handlerExportGrid(columns, i18n.t("recurring_contract"))
												}
											/>
										</div>
									</Col>
								</Row>
							</div>
							<main>
								<ContractModal
									isOpen={isContractModalOpen}
									setIsOpen={setIsContractModalOpen}
									periodFrozen={frozenPeriod}
									contractToEdit={contractToEdit}
									setContractToEdit={setContractToEdit}
									flexFieldValues={flexFieldValues}
									basePeriod={period}
								/>
								<AllContractTable
									onChange={(keys) => setSelectedRowKeys(keys)}
									selectedRowKeys={selectedRowKeys}
									tableData={allContractTableDataFiltered}
									isLoading={isLoadingAllContractTable}
									pagination={pagination}
									columns={columns}
									setColumns={setColumns}
									flexFieldValues={flexFieldValues}
								/>
							</main>
						</>
					)}
				</>
			) : (
				<EmptyDate />
			)}
		</div>
	);
}