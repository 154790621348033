import { Button, Checkbox, Popover, Radio, RadioChangeEvent, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import { usePermissionContext } from "context/PermissionContext";
import i18n from "util/base/i18n";
import { ViewColumns, ViewModeSelected } from "../IDetail";
import Search from "antd/lib/transfer/search";
import moment from "moment";

export default function DetailTableOperations({
    handleDelete,
    isOpenDeletePopover,
    setIsOpenDeletePopover,
    isOpenViewModePopover,
    setIsOpenViewModePopover,
    viewModeSelected,
    setViewModeSelected,
    viewModeChecked,
    setViewModeChecked,
    selectedCheckboxes,
    setSelectedCheckboxes,
    handleOpenModal,
    selectedRowKeys,
    setSearchField,
    viewColumns,
    setViewColumns,
    detailData,
    controlPanelPeriods
}) {

    const { functionalityPermissions } = usePermissionContext();
    const CheckboxGroup = Checkbox.Group;
    const deletePopoverContent =
        <div className="left-buttons-popover-container">

            <Button
                type="text"
                onClick={() => setIsOpenDeletePopover(false)}
            >
                {i18n.t<string>("cancel")}
            </Button>
            <Button
                className="gs-main-button delete-button"
                onClick={() => handleDelete()}
            >
                {i18n.t("yes")}
            </Button>
        </div>

    const viewModeOptions = [
        { value: ViewModeSelected.TEMPLATE, label: "Visualizar por Template", disabled: detailData?.templates === null || detailData?.templates?.length === 0 },
        { value: ViewModeSelected.ACCOUNTING, label: "Visualizar por Contabilização", disabled: !detailData?.accounting },
    ];

    const checkBoxOptions = [
        { value: ViewColumns.QUANTITY, label: "Quantidade" },
        { value: ViewColumns.UNITY_VALUE, label: "Valor Unitário" },
        { value: ViewColumns.ACTIVATION_DATE, label: "Data de Ativação" },
        { value: ViewColumns.CONVERTED_ACCOMPLISHED_VALUE, label: "Valor Realizado" },
        { value: ViewColumns.EXCHANGE_RATE, label: "Valor da Taxa" },
        { value: ViewColumns.DEPRECIATED_VALUE, label: "Valor de Depreciação" },
    ];

    const viewModePopoverContent =
        <div className="grid-operations-viewMode-popover-container">
            <div style={{ padding: '10px', borderBottom: "1px solid #F1F2F3" }}>
                <Radio.Group
                    options={viewModeOptions}
                    onChange={onChangeRadio}
                    value={viewModeChecked}
                />
            </div>
            <CheckboxGroup options={checkBoxOptions} value={selectedCheckboxes} onChange={onViewColumnsCheckboxChange} />
            <div className="grid-operations-viewMode-popover-footer">
                <Button
                    type="text"
                    onClick={() => {
                        setViewModeChecked(viewModeSelected)
                        setSelectedCheckboxes(viewColumns);
                        setIsOpenViewModePopover(false)
                    }}
                >
                    {i18n.t<string>("cancel")}
                </Button>
                <Button
                    className="gs-main-button"
                    onClick={() => {
                        setViewModeSelected(viewModeChecked)
                        setViewColumns(selectedCheckboxes);
                        setIsOpenViewModePopover(false)
                    }}
                >
                    {i18n.t<string>("apply")}
                </Button>
            </div>
        </div>;

    function onChangeRadio({ target: { value } }: RadioChangeEvent) {
        setViewModeChecked(value);
    };

    function onViewColumnsCheckboxChange(values) {
        setSelectedCheckboxes(values);
    }

    function handleSearch(e) {
        setSearchField(e.target.value);
    }

    return (
        <div className="grid-operations-container">
            <div className="grid-operations-left-content" style={{ padding: "15px 0" }}>
                <Button
                    icon={<Icon icon="akar-icons:circle-plus-fill" />}
                    onClick={() => handleOpenModal(true)}
                    className="gs-main-button"
                >
                    Novo Investimento
                </Button>
                <Popover
                    overlayClassName="currencies-popover"
                    content={viewModePopoverContent}
                    title={i18n.t("view")}
                    trigger="click"
                    placement="bottom"
                    visible={isOpenViewModePopover}
                >
                    <Tooltip
                        title={i18n.t("fixed_expense_texts.view_mode")}
                        placement="top"
                    >
                        <Button
                            className="grid-operation-button"
                            onClick={() => setIsOpenViewModePopover(true)}
                            style={{ color: '#5c5c5c' }}
                            icon={<Icon style={{ fontSize: 20, marginBottom: -2 }} icon="ic:outline-remove-red-eye" />}
                        />
                    </Tooltip>
                </Popover>
                {functionalityPermissions.remove ?
                    <Tooltip title={i18n.t("fixed_expense_texts.delete_account_or_detail")}>
                        <Popover
                            overlayClassName="currencies-popover"
                            content={deletePopoverContent}
                            title={i18n.t<string>("delete_confirm_message")}
                            trigger="click"
                            placement="bottom"
                            visible={isOpenDeletePopover}
                        >
                            <Button
                                className="grid-operation-button"
                                onClick={() => setIsOpenDeletePopover(true)}
                                icon={<Icon style={{
                                    fontSize: 18,
                                }} icon="icomoon-free:bin" />}
                                disabled={selectedRowKeys.length === 0}
                                style={{ color: selectedRowKeys.length === 0 ? '#A6A7A7' : '#5c5c5c' }}
                            />
                        </Popover>
                    </Tooltip > : null}
                <PeriodTag period={controlPanelPeriods} />
            </div >
            <div className="grid-operations-right-content">
                <Search
                    placeholder={i18n.t('search')}
                    onChange={handleSearch}
                />
            </div>
        </div >
    )
}

function PeriodTag({ period }) {
    if (!period || !period?.accomplished) return null;

    const realizedTag = period.accomplished ?
        <span className="gs-tag green" style={{ margin: '0 15px' }}>
            {i18n.t("accomplished_until")}: {moment(period.accomplished).format('MMM/YYYY')}
        </span>
        : null;

    return (
        <>
            {realizedTag}
        </>
    )
}