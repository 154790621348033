import { FormInstance } from "antd";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { ListConditions } from "./newProjection/stepOne/IStepOne";
import { IndexerType, IProjectionGrouperData } from "../groupers/IProjectionGroupers";
import { IExpressions } from "./newProjection/stepTwo/IStepTwo";

export interface TableData {
    id?: number;
    key: number;
    description?: string;
    event: string;
    originId?: number;
    formula?: string;
    plainFormula?: string;
    calculationOrder?: number;
    startDate?: string;
    endDate?: string;
    currencyId?: number;
    percentualBase?: number;
    cashCreditAccountingAccountId?: number;
    cashDebitAccountingAccountId?: number;
    cashCreditCostCenterId?: number;
    cashDebitCostCenterId?: number;
    accrualCreditAccountingAccountId?: number;
    accrualDebitAccountingAccountId?: number;
    accrualCreditCostCenterId?: number;
    accrualDebitCostCenterId?: number;
    scheduleDays?: number;
    schedulePayment?: string;
    flexFieldsConditions?: any;
    children?: TableData[];
    ledgerId?: number;
	grouperId?: number;
	projectionCopyType?: ICopyType;
	indexerId?: number;
	indexerType?: IndexerType;
}

export interface EventData {
    value: number | string;
    label: string;
}

export interface OriginData {
    value: number;
    label: string;
    event: number | string;
}

export interface GrouperData {
    value: number | string;
    label: string;
}

export interface NewProjectionProps {
    grouperData: IProjectionGrouperData[];
    originData: OriginData[];
    eventData: EventData[];
    costCenterList: IModalityFilter[];
    accountingList: IModalityFilter[];
    onCancel: () => void;
    stepOneListConditions: ListConditions[];
    setStepOneListConditions: React.Dispatch<React.SetStateAction<ListConditions[]>>;
    inputValue: number;
    setInputValue: React.Dispatch<React.SetStateAction<number>>;
    form: FormInstance<FormValues>;
    formula: any[];
    setFormula: React.Dispatch<React.SetStateAction<any[]>>;
    plainFormula: IExpressions[];
    setPlainFormula: React.Dispatch<React.SetStateAction<IExpressions[]>>;
    isEditingProjection: boolean;
    handleSaveProjection: () => void;
    isWhole: boolean;
    setIsWhole: React.Dispatch<React.SetStateAction<boolean>>;
    isInstallment: boolean;
    setIsInstallment: React.Dispatch<React.SetStateAction<boolean>>;
    loadFlexFields: (value: any) => void;
    flexFieldList: IModalityFilter[];
    flexFieldValues: IFlexFieldValues[];
    fieldList: IFieldList[];
    setFieldList: React.Dispatch<React.SetStateAction<any[]>>;
    ledgerList: IModalityFilter[];
    grouperList: IGroupList[];
    setGrouperList: React.Dispatch<React.SetStateAction<any[]>>;
    fetchTableData: () => void;
    selectedEvent;
    setSelectedEvent;
    projectionsOptions;
}

export interface PlanningEventData {
    EXPENSES: IGroupList[];
    HUMAN_RESOURCES: IGroupList[];
    REVENUE: IGroupList[];
    INVESTMENT: IGroupList[];
}

export interface FieldProps {
    label: string;
    value: {
        id: number | string;
        name: string;
        type: string;
    };
}

export interface FormValues {
    description: string;
    event: string;
    origin: number;
    calculationOrder: number;
    initialDate: moment.Moment;
    endDate: moment.Moment;
    currency: number;
    base: number;
    cashCreditAcc: number;
    cashDebitAcc: number;
    cashCreditCc: number;
    cashDebitCc: number;
    competenceCreditAcc: number;
    competenceDebitAcc: number;
    competenceCreditCc: number;
    competenceDebitCc: number;
    boxCronogramWhole: boolean;
    boxCronogramInstallment: boolean;
    ledgerId: number;
	grouper: number | string;
	projectionCopyType: ICopyType;
	indexerType: IndexerType;
	indexerId: number;
}

export interface ICostCenter {
    id: number;
    externalCode: string;
    description: string;
}

export interface IGroupList {
    id?: number;
    event: string;
    children: TableData[];
    value: number | string;
}
export interface IAccounting {
    id: number;
    externalCode: string;
    parentId: number;
    description: string;
}

export interface IFlexFieldValues {
    value: number;
    flexFieldId: number;
    label: string;
}

export interface IFieldList {
    value: {
        id: string;
        type: string;
        name: string;
    };
    label: string;
}

export interface IPeriodColumn {
    title: string;
    dataIndex: string;
    key: number;
}

export interface IPeriodValue {
    [key: string]: string;
}

export enum ICopyType {
    LAST_YEAR_ENTIRE_COPY = "LAST_YEAR_ENTIRE_COPY",
    LAST_MONTH_COPY = "LAST_MONTH_COPY"
}

export type LevelItem = {
    id: string;
    key: string | number;
    parentId: number;
    title: string;
    children: LevelItem[];
    externalCode: string;
    description?: string;
    dataTree?: LevelItem[];
    classificationId: number;
};

export enum PlanningEvent {
    EXPENSES = "expenses",
    HUMAN_RESOURCES = "human.resources",
    REVENUE = "revenue",
    INVESTMENT = "investment",
    PLANNING = "planning",
    PROFIT = "profit",
}

export const PlanningEventMap: { [key: number]: PlanningEvent } = {
    1: PlanningEvent.EXPENSES,
    2: PlanningEvent.HUMAN_RESOURCES,
    3: PlanningEvent.REVENUE,
    4: PlanningEvent.INVESTMENT,
    5: PlanningEvent.PLANNING,
    6: PlanningEvent.PROFIT,
};

export interface IProjection {
    id: number;
    description: string;
}

export enum FormulaStaticField {
    DEVALUATION = "depreciatedValue",
	IMMOBILIZED="totalValue",
	IMMOBILIZED_PROGRESS="totalValue",
	PROJECTED_VALUE="projectedValue",
	PROFIT="profit",
	INITIAL_VALUE="accounting_balances_opening_balance",
	DEBIT_VALUE="accounting_balances_debit_movement",
	CREDIT_VALUE="accounting_balances_credit_movement",
	FINAL_VALUE="accounting_balances_final_balance"
}