import {
	useState,
	useEffect
} from "react";

import { DefaultRecordType } from "rc-table/lib/interface"

import {
	Tag,
	Card,
	Space,
	Table,
	Typography
} from "antd";

import { Icon } from "@iconify/react";

import { IDataSource, IFinance } from "./IFinance";
import i18n from "util/base/i18n";

import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

import { FormattedNumber } from "react-intl";
import { IFilter } from "../../IDashboardAnalytics";

interface IProps {

	globalFilter: IFilter

}

export default function FinanceCard(props: IProps) {

	const [loading, setLoading]: [boolean, Function] = useState(true);

	const columns: DefaultRecordType[] = [{
		key: "contract",
		dataIndex: "contractName"
	}, {
		key: "value",
		dataIndex: "value",
		width: 80,
		render: (value: number) => {
			return <>
				<Tag className="white">
					<FormattedNumber value={value} style={`percent`} minimumFractionDigits={2} maximumFractionDigits={2} />
				</Tag>
			</>
		}
	}];

	const [dataGrid, setDataGrid]: [IFinance[], Function] = useState([])

	const buildData = (dataSource: IDataSource) => {
		dataSource.total -= dataSource.lease;

		const gridData: IFinance[] = [];

		for (var key in dataSource) {
			if (key === "total" || key === "lease") {
				continue;
			}

			gridData.push({
				contractName: i18n.t<string>(key),
				value: (dataSource[key] / dataSource.total) || 0
			});
		}

		setDataGrid(gridData.sort(({ value: valueA }, { value: valueB }) => valueB - valueA));
		setLoading(false);
	}

	useEffect(() => {
		if (props.globalFilter === null) {
			return;
		}

		setLoading(true);

		const {
			filterBy,
			values
		} = props.globalFilter;

		const filterValuesQuery = values.map(value => `filterValue=${value}`).join("&");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/financing/contracts-overall?client={client}&filterBy=${filterBy}&${filterValuesQuery}`
		}, buildData.bind(this));
	}, [props.globalFilter])

	return (
		<Card title={
			<Space align="center">
				<Icon icon={"mdi:finance"} fontSize={20} color={"#7EB5DC"} />
				<Typography.Title level={5}>Financing</Typography.Title>
			</Space>
		} loading={loading} bordered={false} extra={
			<Tag color="blue">{i18n.t<string>("dashboard.analytics.active_contracts")}</Tag>
		}>
			<Table columns={columns} dataSource={dataGrid} pagination={false} />
		</Card>
	)
}
