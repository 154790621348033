import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Popover, Row, Select } from "antd";
import i18n, { languages } from "util/base/i18n";
import "./transferModal.sass";
import { CollaboratorSearch } from "../../../components/CollaboratorSearch";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { FlexFieldValues, HiredTable } from "../../../IHumanResoucers";
import {
    FlexFieldTransferOption,
    FormItems,
    IContributorInformation,
    ITransferForm,
    OptionCascader,
    TransferObject,
} from "../../IHiredDashboard";
import TransferFormItems from "./TransferFormItems";
import moment from "moment";
import { FlexFilterOptions, Options } from "util/types/types";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { BackButton } from "components/button/BackButton";
import { cloneDeep } from "lodash";
import { Notification } from "components/notification/Notification";
const { Option } = Select;

export function TransferForm({
    form,
    hiredList,
    businessUnitOptions,
    costCategoryOptions,
    flexFieldValuesOptions,
    professionalCategoryOptions,
    isModalVisible,
    setTransferList,
    transferList,
    year,
    setResourceSelected,
    resourceSelected,
    realizedPeriod,
    setIsTransfersList,
    getTransferList,
}: ITransferForm) {
    const [showCollabPopover, setShowCollabPopover] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [transferType, setTransferType] = useState();
    const [costCenterOptions, setCostCenterOptions] = useState<Options[]>([]);
    const [formItemsOrigin, setFormItemsOrigin] = useState<FormItems[]>([]);
    const [formItemsDestiny, setFormItemsDestiny] = useState<FormItems[]>([]);
    const [IsFetchingCostCenter, setIsFetchingCostCenter] = useState(false);
    const [selectedFlexFields, setSelectedFlexFields] = useState([]);
    const selectedBusinessUnitId = Form.useWatch("businessUnitDestiny", form);

    const selectProps = {
        showSearch: true,
        optionFilterProp: "children",
        filterOption: (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
        filterSort: (optionA, optionB) =>
            (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase()),
    };

    useEffect(() => {
        if (!selectedBusinessUnitId) return;
        getCostCenterByBuId(selectedBusinessUnitId);
    }, [selectedBusinessUnitId]);

    function getCostCenterByBuId(buId: number) {
        setIsFetchingCostCenter(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/budget-base/cost-center?locale={locale}&client={client}&user={user}&organization={organization}&bu=${buId}`,
            },
            (data) => onLoadCostCenter(data)
        );
    }

    function onLoadCostCenter(data: Options[]) {
        setIsFetchingCostCenter(false);
        if (data.find(({ value }) => value === resourceSelected.costCenter.value)) {
            form.setFieldsValue({ costCenterDestiny: resourceSelected.costCenter.value });
        } else {
            form.setFieldsValue({ costCenterDestiny: null });
        }
        setCostCenterOptions(data);
    }

    function onChangeTransferType(value) {
        setTransferType(value);
    }

    function onChangeResourceSelected(idSelected: number) {
        setResourceSelected(hiredList.find(({ id }) => id === idSelected));
    }

    function buildOriginItems(): FormItems[] {
        return [
            {
                itens: [
                    {
                        label: resourceSelected.businessUnit.label,
                        value: resourceSelected.businessUnit.value,
                        disabled: true,
                    },
                ],
                label: i18n.t("business_unit"),
                selected: resourceSelected.businessUnit.value,
                value: "businessUnit",
            },
            {
                itens: [
                    {
                        label: resourceSelected.costCenter.label,
                        value: resourceSelected.costCenter.value,
                        disabled: true,
                    },
                ],
                label: i18n.t("cost_center"),
                selected: resourceSelected.costCenter.value,
                value: "costCenter",
            },
            {
                itens: [
                    {
                        label: resourceSelected.costCategory.label,
                        value: resourceSelected.costCategory.value,
                        disabled: true,
                    },
                ],
                selected: resourceSelected.costCategory.value,
                label: i18n.t("cost_category"),
                value: "costCategory",
            },
            {
                itens: [
                    {
                        label: resourceSelected.professionalCategory.label,
                        value: resourceSelected.professionalCategory.value,
                        disabled: true,
                    },
                ],
                selected: resourceSelected.professionalCategory.value,
                label: i18n.t("professional_category.professional_category_name"),
                value: "professionalCategory",
            },
            ...buildFlexFieldItemsOrigin(resourceSelected.flexFields),
        ];
    }

    function buildDestinyItems(): FormItems[] {
        return [
            {
                itens: businessUnitOptions,
                label: i18n.t("business_unit"),
                selected: resourceSelected.businessUnit.value,
                value: "businessUnitDestiny",
            },
            {
                itens: costCenterOptions,
                label: i18n.t("cost_center"),
                selected: resourceSelected.costCenter.value,
                value: "costCenterDestiny",
            },
            {
                itens: costCategoryOptions,
                selected: resourceSelected.costCategory.value,
                label: i18n.t("cost_category"),
                value: "costCategoryDestiny",
            },
            {
                itens: professionalCategoryOptions,
                selected: resourceSelected.professionalCategory.value,
                label: i18n.t("professional_category.professional_category_name"),
                value: "professionalCategoryDestiny",
            },
            ...buildFlexFieldItemsDestiny(resourceSelected.flexFields),
        ];
    }

    function buildFlexFieldItemsDestiny(flexFields: FlexFieldValues[]): FormItems[] {
        let newItem: FormItems[] = [];
        flexFieldValuesOptions.forEach(({ value, label, children }) => {
            const fieldId = flexFields.find(({ flexFieldId }) => value === flexFieldId)?.flexFieldValueId;
            newItem.push({
                selected: fieldId ? fieldId : null,
                label: label,
                value: `flexFieldFlexFieldName_${value}_destiny`,
                itens: children,
            });
        });
        return newItem;
    }

    function buildFlexFieldItemsOrigin(flexField: FlexFieldValues[]): FormItems[] {
        let item: FormItems[] = [];
        flexField.forEach((flexField) => {
            item.push({
                itens: [
                    {
                        label: flexField.flexFieldValueName,
                        value: flexField.flexFieldValueId,
                    },
                ],
                selected: flexField.flexFieldValueId,
                label: flexField.flexFieldName,
                value: `flexFieldFlexFieldName_${flexField.flexFieldId}`,
            });
        });
        return item;
    }

    function validateFields() {
        const fieldValues = form.getFieldsValue();
        const isBusinessUnitValid = fieldValues.businessUnitDestiny !== fieldValues.businessUnit;
        const isCostCategoryValid = fieldValues.costCategoryDestiny !== fieldValues.costCategory;
        const isCostCenterValid = fieldValues.costCenterDestiny !== fieldValues.costCenter;
        const isProfessionalCategoryValid =
            fieldValues.professionalCategoryDestiny !== fieldValues.professionalCategory;
        let isFlexFieldValid = false;

        formItemsDestiny.every(({ value }) => {
            const fieldOrigin = typeof value == "string" ? value.replace("_destiny", "") : value;
            const originValue = fieldValues[fieldOrigin];
            const destinyValue = fieldValues[value];

            isFlexFieldValid = !originValue && !destinyValue ? false : !(originValue == destinyValue);
            if (isFlexFieldValid) {
                return false;
            }

            return true;
        });

        if (
            isBusinessUnitValid ||
            isCostCategoryValid ||
            isCostCenterValid ||
            isProfessionalCategoryValid ||
            isFlexFieldValid
        ) {
            return Promise.resolve();
        }

        return Promise.reject(new Error(i18n.t("equals_to_origin")));
    }

    function handleSubmitForm(data: any) {
        const dataToSave: TransferObject = {
            key: Number(moment().milliseconds()),
            amount: data.tranferAmount,
            period: moment(data["initialBudgetPeriod"]).format("MM/YYYY"),
            type: data["transferType"],
            readjustment: data.readjustment,
            originBusiness: {
                value: data["businessUnit"],
                title: findLabelOnOptions(businessUnitOptions, data["businessUnit"]),
            },
            business: {
                value: data["businessUnitDestiny"],
                title: findLabelOnOptions(businessUnitOptions, data["businessUnitDestiny"]),
            },
            originCostCategory: {
                value: data["costCategory"],
                title: findLabelOnOptions(costCategoryOptions, data["costCategory"]),
            },
            costCategory: {
                value: data["costCategoryDestiny"],
                title: findLabelOnOptions(costCategoryOptions, data["costCategoryDestiny"]),
            },
            originCostCenter: {
                value: data["costCenter"],
                title: findLabelOnOptions(costCenterOptions, data["costCenter"]),
            },
            costCenter: {
                value: data["costCenterDestiny"],
                title: findLabelOnOptions(costCenterOptions, data["costCenterDestiny"]),
            },
            originProfessionalCategory: {
                value: data["professionalCategory"],
                title: findLabelOnOptions(professionalCategoryOptions, data["professionalCategory"]),
            },
            professionalCategory: {
                value: data["professionalCategoryDestiny"],
                title: findLabelOnOptions(professionalCategoryOptions, data["professionalCategoryDestiny"]),
            },
            resource: {
                value: resourceSelected.id.toString(),
                title: resourceSelected.resourceName,
            },
            originFlexField: buildFlexFieldOrigin(data),
            flexField: buildFlexField(data),
        };
        setTransferList((state) => [...state, dataToSave]);
        form.resetFields();
    }

    function buildFlexField(data: any) {
        let flexFields: FlexFieldTransferOption[] = [];
        const flexFieldKeys = Object.keys(data).filter((key) => key.startsWith("flexField") && key.endsWith("destiny"));
        flexFieldKeys.forEach((key) => {
            const firstIndexOf = key.indexOf("_");
            flexFields.push({
                flexField: key.slice(firstIndexOf + 1, key.indexOf("_", firstIndexOf + 1)),
                flexFieldValue: data[key],
            });
        });
        return flexFields;
    }

    function buildFlexFieldOrigin(data: any) {
        let flexFields: FlexFieldTransferOption[] = [];
        const flexFieldKeys = Object.keys(data).filter((key) => key.startsWith("flexField") && !key.endsWith("destiny"));
        flexFieldKeys.forEach((key) => {
            const firstIndexOf = key.indexOf("_");
            flexFields.push({
                flexField: key.slice(firstIndexOf + 1, key.indexOf("_", firstIndexOf + 1)),
                flexFieldValue: data[key],
            });
        });
        return flexFields;
    }

    function findLabelOnOptions(options: OptionCascader[], id: string) {
        return options.find(({ value }) => value === id)?.label;
    }

    useEffect(() => {
        if (!resourceSelected) return;
        const newFormItems: FormItems[] = buildOriginItems();

        setFormItemsOrigin(buildFlexFieldItemsOrigin(resourceSelected.flexFields));
        setFormItemsDestiny(buildFlexFieldItemsDestiny(resourceSelected.flexFields));

        form.setFieldsValue(
            newFormItems.concat(buildDestinyItems()).reduce((objectDest, item) => {
                objectDest[item.value] = item.selected;
                return objectDest;
            }, {})
        );
        getCostCenterByBuId(resourceSelected.businessUnit.value);
    }, [resourceSelected]);

    useEffect(() => {
        if (isModalVisible) return;
        form.resetFields();
    }, [isModalVisible]);

    function validateSelectDate(current) {
        if (!realizedPeriod) return false;
        if (current.year() == year && current.year() == realizedPeriod.year())
            return realizedPeriod ? current.month() <= realizedPeriod.month() : false;
        return true;
    }

    return (
        <div className="transfer-form-container">
            <Form
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
                layout="vertical"
                form={form}
                name="transfer-form"
                requiredMark={false}
            >
                <div className="transfer-form-inputs-content">
                    <Col className="column-form">
                        <Form.Item
                            label={i18n.t("period")}
                            name="initialBudgetPeriod"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <DatePicker
                                locale={languages[i18n.language]}
                                format="MMM/YYYY"
                                defaultPickerValue={moment(year, "YYYY")}
                                className="gs-date-picker"
                                picker="month"
                                disabledDate={validateSelectDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="column-form">
                        <Form.Item
                            label={i18n.t("type")}
                            name="transferType"
                            requiredMark={false}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                id="input-origin-scenario"
                                style={{ width: 160 }}
                                onChange={(value: string) => onChangeTransferType(value)}
                                placeholder={i18n.t("select")}
                            >
                                <Option key="transfer" value="transfer">
                                    {i18n.t("human_resources.transfer")}
                                </Option>
                                <Option key="promotion" value="promotion">
                                    {i18n.t("human_resources.promotion")}
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="column-form">
                        <Form.Item label={i18n.t("readjustment")} name="readjustment" rules={[{ required: false }]}>
                            <InputNumber
                                controls={false}
                                decimalSeparator={i18n.language === "pt_BR" ? "," : "."}
                                width={"100%"}
                                precision={2}
                                prefix={"%"}
                                disabled={transferType == "transfer" || !transferType}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="column-form">
                        <Form.Item
                            label={i18n.t("amount")}
                            name="tranferAmount"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            initialValue={1}
                        >
                            <InputNumber id="input-scenario-code" />
                        </Form.Item>
                    </Col>
                    <Col className="column-form">
                        <div className="collaborator-label">Colaborador</div>
                        <Popover
                            visible={showCollabPopover}
                            trigger="click"
                            placement="bottom"
                            onVisibleChange={(visible) => setShowCollabPopover(visible)}
                            content={
                                <CollaboratorSearch
                                    hiredList={hiredList}
                                    onCloseSearch={setShowCollabPopover}
                                    setDeitalId={onChangeResourceSelected}
                                />
                            }
                            overlayClassName="overlay-operation-bar-hr"
                        >
                            <Button onClick={() => setShowCollabPopover(true)} className="collaborator-search">
                                <p>{resourceSelected ? resourceSelected.resourceName : i18n.t("select_employee")}</p>
                                <Icon icon="charm:search" />
                            </Button>
                        </Popover>
                    </Col>
                </div>
                <span className="gs-tag blue">{i18n.t("origin")}</span>
                <Row wrap gutter={9} className="row-items">
                    <Col>
                        <Form.Item name={"businessUnit"} label={i18n.t("business_unit")}>
                            <Select disabled={true} {...selectProps} options={businessUnitOptions}></Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={"costCenter"} label={i18n.t("cost_center")}>
                            <Select disabled={true} {...selectProps} options={costCenterOptions} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name={"costCategory"} label={i18n.t("cost_category")}>
                            <Select disabled={true} {...selectProps} options={costCategoryOptions} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name={"professionalCategory"}
                            label={i18n.t("professional_category.professional_category_name")}
                        >
                            <Select disabled={true} {...selectProps} options={professionalCategoryOptions} />
                        </Form.Item>
                    </Col>
                    {formItemsOrigin.map((item) => {
                        return (
                            <Col>
                                <Form.Item name={item.value} label={item.label}>
                                    <Select disabled={true} {...selectProps}>
                                        {item.itens.map(({ label, value }) => {
                                            return (
                                                <Option key={value} value={value}>
                                                    {label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
                <span className="gs-tag blue">{i18n.t("destination")}</span>
                <Row wrap gutter={9} className="row-items">
                    <Col>
                        <Form.Item
                            name={"businessUnitDestiny"}
                            label={i18n.t("business_unit")}
                            rules={[
                                () => ({
                                    validator() {
                                        return validateFields();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                disabled={resourceSelected ? false : true}
                                {...selectProps}
                                options={businessUnitOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name={"costCenterDestiny"}
                            label={i18n.t("cost_center")}
                            rules={[
                                () => ({
                                    validator() {
                                        return validateFields();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                disabled={resourceSelected ? IsFetchingCostCenter : true}
                                {...selectProps}
                                options={costCenterOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name={"costCategoryDestiny"}
                            label={i18n.t("cost_category")}
                            rules={[
                                () => ({
                                    validator() {
                                        return validateFields();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                disabled={resourceSelected ? false : true}
                                {...selectProps}
                                options={costCategoryOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name={"professionalCategoryDestiny"}
                            label={i18n.t("professional_category.professional_category_name")}
                            rules={[
                                () => ({
                                    validator() {
                                        return validateFields();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                disabled={resourceSelected ? false : true}
                                {...selectProps}
                                options={professionalCategoryOptions}
                            />
                        </Form.Item>
                    </Col>
                    {formItemsDestiny.map((item) => {
                        return (
                            <Col>
                                <Form.Item
                                    name={item.value}
                                    label={item.label}
                                    rules={[
                                        () => ({
                                            validator() {
                                                return validateFields();
                                            },
                                        }),
                                    ]}
                                >
                                    <Select disabled={false} {...selectProps}>
                                        {item.itens.map(({ label, value }) => {
                                            return (
                                                <Option key={value} value={value}>
                                                    {label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
                <div className="add-list">
                    <BackButton onGoBack={() => (setIsTransfersList(true), getTransferList())} />
                    <Button
                        style={{ marginLeft: "15px", marginBottom: "15px" }}
                        type="default"
                        className="gs-secondary-button"
                        icon={<Icon icon="akar-icons:circle-plus-fill" />}
                        disabled={!resourceSelected}
                        onClick={() => {
                            form.validateFields().then((values) => {
                                handleSubmitForm(values);
                            });
                        }}
                    >
                        {i18n.t("add")}
                    </Button>
                </div>
            </Form>
        </div>
    );
}
