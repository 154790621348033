import { TopButtons } from "components/topButtons/TopButtons";
import i18n from "util/base/i18n";
import { useGroupersContext } from "./GroupersContext";
import { Row } from "antd";
import { IProjectionGrouperData } from "../IProjectionGroupers";
import { IImportExportMenu } from "components/importExportMenu/IImportExportMenu";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Notification } from "components/notification/Notification";

export default function Title() {
	const {
		setIsModalOpen,
		selectedRowKeys,
		setItemForEdit,
		tableData,
		getGridData,
		setIsGridFetching,
		setSelectedRowKeys
	} = useGroupersContext();
	const importProps: IImportExportMenu = {
		importProps: [{
			importUrl: "/planning/projection/grouper/import",
			templateUrl: "/planning/projection/grouper/import/template?locale={locale}",
			type: "excel",
			handleSuccessImport: getGridData
		}]
	}
	const handleNew = () => {
		setIsModalOpen(true);
	}

	const handleEdit = () => {
		const grouperToEdit: IProjectionGrouperData = tableData.find(({ id }) => id === selectedRowKeys[0]);
		setItemForEdit(grouperToEdit);
		setIsModalOpen(true);
	}

	function onRequestError() {
		Notification({
			type: "warning",
			message: i18n.t<string>("unable_to_delete_with_linked_data"),
		});
		setIsGridFetching(false);
	}

	function onSuccessDelete() {
		Notification({
			message: i18n.t("successfully_deleted"),
			type: "success"
		})
		getGridData();
	}

	const handleDelete = () => {
		setIsGridFetching(true);
		ServiceCaller.doRequest({
			type: RequestType.DELETE,
			url: `/planning/projection/grouper?ids=${selectedRowKeys}`,
		}, onSuccessDelete, onRequestError)
		setSelectedRowKeys([]);
	}

	return (
		<Row>
			<Row className="page-title-content">
				<h1>{i18n.t("projection_grouper.new_grouper")}</h1>
			</Row>
			<Row>
				<TopButtons
					mainButtonTitle={i18n.t("add_grouper")}
					handleNew={handleNew}
					handleEdit={handleEdit}
					handleDelete={handleDelete}
					isDeletable={!!selectedRowKeys.length}
					isEditable={selectedRowKeys.length === 1}
					importExportOptions={importProps}
				/>
			</Row>
		</Row>
	)
}