import { Button, Carousel } from "antd";
import { Filter } from "components/filter/Filter";
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton";
import { useEffect, useState } from "react";
import "./styles.sass";
import { useBudgetOpeningContext } from "../../context/useBudgetOpeningContext";
import { useWindowSize } from "hooks/useWindowSize";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import { FilterBase } from "components/filter/FilterBase";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { Notification } from "components/notification/Notification";
import { Error500 } from "components/error/Error500";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { onChangeFlexFieldFilter } from "module/budget/pages/flexField/functions/onChangeFlexFieldFilter";
import { generateFlexFieldKey } from "util/functions/generateFlexFieldKey";
import { usePermissionContext } from "context/PermissionContext";
import { IFilters } from "../../IFixedExpense";

export function Filters({ functionalityPermissions }: IFilters) {
    const {
        accountAccounting,
        setAccountAccounting,
        period,
        costCenter,
        setCostCenter,
        costCenterOptions,
        accountAccountingOptions,
        flexFieldsFilters,
        isFetchingFlexFields,
        openedCalendarPeriod,
        setFlexFieldsFilters,
        userInfo,
        isQueryByPackage,
        onChangeQueryType,
        spendingPackageOptions,
        spendingPackage,
        setSpendingPackage,
        currenciesOptions,
        error500,
        setIsLoadingCacheData,
        setIsFetchingGridData,
        setBudgetOpeningCached,
        onLoadBudgetOpening,
        setError500,
        currenciesSelected,
        setCurrenciesSelected,
        handleChangePeriod,
        setAccountsWithValue,
    } = useBudgetOpeningContext();
    const [filtersPerRow, setFiltersPerRow] = useState(5);
    const windowSize = useWindowSize();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 258) / 210);
        setFiltersPerRow(updatedFiltersPerRow);
    }, [windowSize]);

    useEffect(() => {
        setIsButtonEnabled(true);
    }, [accountAccounting, currenciesSelected, period, costCenter, flexFieldsFilters, openedCalendarPeriod, isQueryByPackage, spendingPackage]);

    useEffect(() => {
        if (userInfo && currenciesOptions) {
            const userCurrency = currenciesOptions.find(item => item.value === userInfo.currencyId);
            if (userCurrency) {
                setCurrenciesSelected([{ value: userInfo.currencyId, label: userCurrency.label }]);
            }
        }
    }, [userInfo, currenciesOptions]);

    function onClickNewBudgetOpening() {
        if (costCenter.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.please_select_a_cost_center_to_continue"),
            });
            return;
        }
        if (isQueryByPackage && spendingPackage.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.please_select_at_least_one_package_to_continue"),
            });
            return;
        }
        if (accountAccounting.length === 0 && spendingPackage.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.please_select_at_least_one_ledger_account_to_continue"),
            });
            return;
        }
        if (period[0] === null) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.select_a_period_to_continue"),
            });
            return;
        }
        if (isQueryByPackage) {
            handleCreateBudgetOpening();
        } else {
            handleCreateBudgetOpeningAccount();
        }
        setIsLoadingCacheData(true);
        setIsButtonEnabled(false)
    }

    if (error500) {
        return <Error500 />;
    }

    function handleCreateBudgetOpeningAccount() {
        setIsFetchingGridData(true);
        setAccountsWithValue(false);
        const budgetOpeningDataAccount = {
            organizationId: userInfo?.selection?.organizationId,
            businessUnitId: userInfo?.selection?.businessUnitId,
            costCenterId: costCenter[0].value,
            accounts: accountAccounting.sort((a, b) => a.value - b.value).map((item) => item.value),
            currencyId: currenciesSelected[0].value,
            flexFieldFilter: generateFlexFieldKey(flexFieldsFilters),
            scenarioId: userInfo?.selection?.scenarioId,
            period: [period[0].valueOf(), period[1].valueOf()],
            clientId: userInfo?.clientId,
            localeId: userInfo?.localeId,
            userId: userInfo?.id,
        }
        setBudgetOpeningCached(budgetOpeningDataAccount);
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/expenses/budget-opening/open-budget-by-account",
                params: budgetOpeningDataAccount,
            },
            onLoadBudgetOpening,
            () => setError500(true)
        );
    }
    function handleCreateBudgetOpening() {
        setIsFetchingGridData(true);
        setAccountsWithValue(false);
        const budgetOpeningData = {
            id: null,
            spendingPackage: spendingPackage.map((item) => item.value),
            organizationId: userInfo?.selection?.organizationId,
            businessUnitId: userInfo?.selection?.businessUnitId,
            currencyId: currenciesSelected[0].value,
            costCenterId: costCenter[0].value,
            accounts: isQueryByPackage
                ? []
                : accountAccounting.sort((a, b) => a.value - b.value).map((item) => item.value),
            flexFieldFilter: generateFlexFieldKey(flexFieldsFilters) ?? '',
            scenarioId: userInfo?.selection?.scenarioId,
            localeId: userInfo?.localeId,
            clientId: userInfo?.clientId,
            userId: userInfo?.id,
            period: [period[0].valueOf(), period[1].valueOf()],
            flexFieldInfoData: flexFieldsFilters,
        };
        setBudgetOpeningCached(budgetOpeningData);
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/expenses/budget-opening",
                params: budgetOpeningData,
            },
            onLoadBudgetOpening,
            () => setError500(true)
        );
    }

    const budgetOptions = () => {
        if (functionalityPermissions.accounts && functionalityPermissions.package) {
            return isQueryByPackage ? i18n.t("spending_package") : i18n.t("accounting_account");
        } else if (functionalityPermissions.package) {
            return i18n.t("spending_package");
        } else if (functionalityPermissions.accounts) {
            return i18n.t("accounting_account");
        } else {
            return i18n.t("no_options");
        }
    };

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "baseline",
            }}
            >
                <p>Forma de Orçamento:</p>
                <Button
                    className="gs-secondary-button"
                    style={{ paddingLeft: 4, height: "100%", marginRight: 20, border: "none", width: 160, justifyContent: 'flex-start' }}
                    onClick={() => (functionalityPermissions.accounts && functionalityPermissions.package) && onChangeQueryType()}
                >
                    <div className="gs-flex flex-row align-start"
                        style={{ gap: 10, cursor: "pointer", alignItems: "center" }}
                    >
                        <span>
                            {budgetOptions()}
                        </span>
                        <Icon icon="ep:arrow-down" />
                    </div>
                </Button>
            </div>
            <div className="df-filters-container">
                {isFetchingFlexFields ? (
                    <FilterSkeleton direction="row" active amount={4} gap={10} cardClassName="filter-card-skeleton" />
                ) : (
                    <>

                        <Filter
                            id="period-filter"
                            value={period}
                            setValue={handleChangePeriod}
                            title={i18n.t("period")}
                            type="datepicker"
                            link="/"
                            data={[]}
                            datepicker={{
                                format: "MM/YYYY",
                                picker: "month",
                                period: openedCalendarPeriod,
                                rangePicker: true,
                                allowClear: false,
                            }}
                            buttonStyles={{ cursor: "default" }}
                        />
                        <Carousel {...carouselDefaultProps} slidesPerRow={filtersPerRow}>
                            {flexFieldsFilters.map((ffFilter) => {
                                return (
                                    <Filter
                                        key={ffFilter.value}
                                        id="flex-field-filter"
                                        title={ffFilter.label}
                                        type="radio"
                                        value={ffFilter.selectedOption}
                                        setValue={async (value: any) => {
                                            if (
                                                value.length === 0 ||
                                                ffFilter.selectedOption[0]?.value === value[0]?.value
                                            ) {
                                                return;
                                            }
                                            const flexString = await onChangeFlexFieldFilter(
                                                value[0]?.value,
                                                ffFilter.value,
                                                flexFieldsFilters,
                                                setFlexFieldsFilters,
                                                userInfo.selection.businessUnitId ?? 0,
                                                costCenter[0]?.value ?? 0,
                                                accountAccounting[0]?.value ?? 0,
                                                userInfo.selection.organizationId ?? 0,
                                                userInfo.groupIds
                                            );
                                        }}
                                        data={ffFilter.children}
                                        isFetching={ffFilter.isFetching ?? false}
                                        hasSearch
                                        hasSelectNone
                                    />
                                );
                            })}
                            <Filter
                                id="cost-center-filter"
                                title={i18n.t("cost_center")}
                                type="radio"
                                searchPlaceholder={i18n.t("search_by_cost_center")}
                                value={costCenter}
                                setValue={(value) => {
                                    setCostCenter(value);

                                    if (value[0]?.value != costCenter[0]?.value) {
                                        setAccountAccounting([]);
                                    }

                                    onChangeFlexFieldFilter(
                                        value[0]?.value,
                                        "COST_CENTER",
                                        flexFieldsFilters,
                                        setFlexFieldsFilters,
                                        userInfo.selection.businessUnitId ?? 0,
                                        value[0]?.value,
                                        accountAccounting[0]?.value ?? 0,
                                        userInfo.selection.organizationId ?? 0,
                                        userInfo.groupIds
                                    );
                                }}
                                isFetching={false}
                                data={costCenterOptions}
                            />
                            {isQueryByPackage ? (
                                <Filter
                                    key="spending-package-filter"
                                    id="spending-package-filter"
                                    title={i18n.t("spending_package")}
                                    type="checkbox"
                                    buttonStyles={{ border: "none" }}
                                    value={spendingPackage}
                                    setValue={(value) => {
                                        setSpendingPackage(value);
                                    }}
                                    isFetching={false}
                                    data={spendingPackageOptions}
                                />
                            ) : (
                                <FilterBase
                                    key="account-accounting-filter"
                                    id="account-accounting-filter"
                                    title={i18n.t("accounting_account")}
                                    type="checkbox"
                                    buttonStyles={{ border: "none" }}
                                    searchPlaceholder={i18n.t("search_by_accounting_account")}
                                    value={accountAccounting}
                                    setValue={(value) => {
                                        setAccountAccounting(value);

                                        onChangeFlexFieldFilter(
                                            value[0]?.value,
                                            "ACCOUNTING_ACCOUNT",
                                            flexFieldsFilters,
                                            setFlexFieldsFilters,
                                            userInfo.selection.businessUnitId ?? 0,
                                            costCenter[0]?.value ?? 0,
                                            value[0]?.value,
                                            userInfo.selection.organizationId ?? 0,
                                            userInfo.groupIds
                                        );
                                    }}
                                    hasPagination={accountAccountingOptions?.length > 100}
                                    allFilterOptions={accountAccountingOptions}
                                    isFetching={false}
                                />
                            )}
                            <Filter
                                id="currencies-filter"
                                title={i18n.t("currency")}
                                type="radio"
                                searchPlaceholder="Search by currency"
                                value={currenciesSelected}
                                setValue={(value) => {
                                    setCurrenciesSelected(value);
                                }}
                                data={currenciesOptions}
                            />
                        </Carousel>
                        <div className="gs-flex"
                        >
                            <Button
                                className="gs-main-button openning-budget-button"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingRight: 15,
                                    gap: 5,
                                    width: 80,
                                    marginRight: 15
                                }}
                                title={i18n.t("apply")}
                                onClick={onClickNewBudgetOpening}
                                disabled={!isButtonEnabled}
                            >{i18n.t("apply")}

                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
