import { Icon } from "@iconify/react";
import { Col, Row, Skeleton, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { BackButton } from "components/button/BackButton";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IOptionsForSearch } from "components/input/search/IMultipleSearch";
import { MultipleSearch } from "components/input/search/MultipleSearch";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ContractToEdit, ContractType, IValueViewData, IValueViewProps } from "../IRecurringContract";
import HeaderInfoTag from "./HeaderInfoTag";

export default function ValueView({
	contractId,
	period,
	setContractId
}: IValueViewProps) {

	const { userInfo } = useUserContext();
	const [tableData, setTableData] = useState<IValueViewData[]>([]);
	const [contractValueView, setContractValueView] = useState<ContractToEdit>();
	const [tableDataFiltered, setTableDataFiltered] = useState<IValueViewData[]>([]);
	const [totalValue, setTotalValue] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [columns, setColumns] = useState<ColumnsType<IValueViewData>>([{
		dataIndex: "competencyDate",
		key: "competencyDate",
		title: i18n.t("competency_date")
	},
	{
		dataIndex: "value",
		key: "value",
		title: i18n.t("value")
	},]);

	const multipleSearchOptions: IOptionsForSearch[] = [
		{
			description: "competencyDate",
			i18nString: i18n.t("competency_date")
		},
		{
			description: "value",
			i18nString: i18n.t("value")
		},
		{
			description: "readjustment",
			i18nString: i18n.t("readjustment")
		},
	]
	const chooseOptionsContractType = {
		RECURRING: i18n.t("recurrent"),
		NOT_RECURRING: i18n.t("non_recurring"),
		NEW_SALES: i18n.t("new_sales"),
		CHURN: i18n.t("churn"),
		NEW_SALES_SMS: i18n.t("new_sales_sms"),
	}

	function onLoadTableData(data: ContractToEdit[]) {
		const line: ContractToEdit = data[0];
		let total = 0;
		let newData: IValueViewData[] = [];
		let newColumns = [{
			dataIndex: "tax",
			key: "tax",
			title: i18n.t("tax")
		}, {
			dataIndex: "liquidRevenue",
			key: "liquidRevenue",
			title: i18n.t("net_revenue")
		}];
		setContractValueView(line);

		if (ContractType.RECURRING === line.contractType) {
			newColumns.push({
				dataIndex: "readjustment",
				key: "readjustment",
				title: i18n.t("readjustment")
			});

			newData = line.recurringContractValues.map(({ period, readjustment, tax, value, liquidRevenue }) => {
				total += value;
				return ({
					competencyDate: moment(period).format("MMM/YYYY"),
					value: new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(value),
					readjustment: new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(readjustment),
					tax: new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(tax),
					liquidRevenue: new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(liquidRevenue),
				})
			})
		} else {
			const isNewSales: boolean = line.contractType === "NEW_SALES";
			newData = Object.keys(line.monthValues).sort().map((key) => {
				total += line.monthValues[key];
				let newLine = {
					competencyDate: moment(key).format("MMM/YYYY"),
					value: new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(line.monthValues[key]),
					tax: new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(line.monthTaxes[key] ? line.monthTaxes[key] : 0),
					liquidRevenue: new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(line.monthLiquidRevenue[key] ? line.monthLiquidRevenue[key] : 0)
				}
				if (isNewSales) Object.assign(newLine, {
					monthValuesSms: new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(line.monthValuesSms[key] || 0)
				});
				return (newLine)
			})
			if (isNewSales) {
				newColumns.push({
					dataIndex: "monthValuesSms",
					key: "monthValuesSms",
					title: i18n.t("value_SMS")
				})
			}
		}

		setColumns((state) => {
			return [...state, ...newColumns];
		})
		setTableData(newData);
		setTotalValue(total);
		setIsLoading(false);
	}

	useEffect(() => {
		setTableDataFiltered(tableData);
	}, [tableData]);

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/revenue/recurring-contract/find-by-ids?ids=${[contractId]}&scenarioId={scenario}&isModal=false&userId=${userInfo.id}`
		}, onLoadTableData)
	}, [period])

	return (
		<div className="value-view-content">
			<Row className="header-title">
				<Col className="page-title-content">
					<h1>
						{i18n.t("contract_values")}
					</h1>
				</Col>
				<Col>
					<div className="gs-tag gray">
						<Icon icon="fa6-solid:calendar-days" />
						<p>
							{i18n.t("period")}
						</p>
						<p className="date-period">
							{
								period.format("MM/YYYY")
							}
						</p>
					</div>
				</Col>
			</Row>
			<Row gutter={20} className="header-info">
				<Col>
					<BackButton onGoBack={() => setContractId(0)} />
				</Col>
				{!isLoading ?
					<>
						<HeaderInfoTag
							title={i18n.t("contract")}
							value={`${contractValueView?.contractDescription} - ${chooseOptionsContractType[contractValueView?.contractType]}`}
						/>
						<HeaderInfoTag
							title={i18n.t("initial_date")}
							value={`${moment(contractValueView?.startPeriod).format("DD/MM/YYYY")}`}
						/>
						<HeaderInfoTag
							title={i18n.t("value")}
							value={`${new Intl.NumberFormat(i18n.language.replace("_", "-"), {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2,
								currency: "BRL",
								style: "currency",
							}).format(totalValue)}`}
						/>
						<HeaderInfoTag
							title={i18n.t("projection_end_date")}
							value={`${moment(contractValueView?.endPeriod).format("DD/MM/YYYY")}`}
						/>
						{contractValueView.readjustDate &&
							<HeaderInfoTag
								title={i18n.t("birthday_date")}
								value={`${moment(contractValueView?.readjustDate).format("DD/MM/YYYY")}`}
							/>
						}
					</>
					:
					<>
						<Col span={4}>
							<Skeleton
								active
								paragraph={{ rows: 1 }}
							/>
						</Col>
						<Col span={4}>
							<Skeleton
								active
								paragraph={{ rows: 1 }}
							/>
						</Col>
						<Col span={4}>
							<Skeleton
								active
								paragraph={{ rows: 1 }}
							/>
						</Col>
						<Col span={4}>
							<Skeleton
								active
								paragraph={{ rows: 1 }}
							/>
						</Col>
					</>
				}
			</Row>
			<Row className="search-table">
				<Col>
					<MultipleSearch
						setTableData={setTableDataFiltered}
						tableData={tableData}
						options={multipleSearchOptions}
					/>
				</Col>
				<Col className="gs-flex align-center">
					<ImportExportMenu
						exportGridData={() => handleExportGridData(tableDataFiltered, columns, i18n.t<string>("visualization_of_values"))}
					/>
				</Col>
			</Row>
			<Row>
				<Table
					pagination={{ pageSize: 30, hideOnSinglePage: true }}
					className="gs-table"
					bordered
					dataSource={tableDataFiltered}
					columns={columns}
					loading={isLoading}
				/>
			</Row>
		</div >
	)
}