import { DownSquareOutlined, UpSquareOutlined } from "@ant-design/icons";
import { Form, Input, InputRef, Tooltip } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { EditableContext } from "../../RevenueTable";

import i18n from "util/base/i18n";

export const EditableCell: React.FC<any> = ({ title, editable, children, dataIndex, record, setTablePeriods, tablePeriods, ...restProps }) => {
    const [isButtonsVisible, setIsButtonsVisible] = useState(false);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    function handleSave(cellToBeEdited: any): void {
        setTablePeriods((prevState: any) => {
            const newState = [...prevState];

            newState.forEach((prevCell: any) => {
                if (cellToBeEdited.date === prevCell.date) {
                    if (prevCell.price !== cellToBeEdited.price) {
                        prevCell.price = cellToBeEdited.price ? cellToBeEdited.price.toString() : '0';
                    }

                    if (prevCell.volume !== cellToBeEdited.volume) {
                        prevCell.volume = cellToBeEdited.volume ? cellToBeEdited.volume.toString() : '0';
                    }
                }
            })

            return newState;
        })
    }

    function copyValues(direction: string, row: any, isVolume: boolean): void {
        setTablePeriods((prevState: any) => {
            return prevState.map((cell: any) => {
                const shouldUpdate: boolean = (direction === "up" && cell.date < row.date) || (direction === "down" && cell.date > row.date);

                if (shouldUpdate) {
                    if (isVolume) {
                        return { ...cell, volume: row.volume };
                    } else {
                        return { ...cell, price: row.price };
                    }
                }

                return cell;
            });
        });
    }

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        if (editing) {
            childNode = (
                <Form.Item style={{ margin: 0 }} name={dataIndex} >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            );
        } else {
            let displayedValue: string = '0';

            if (dataIndex === 'volume') {
                displayedValue = record.volume
            }

            if (dataIndex === 'price') {
                displayedValue = record.price;
            }

            childNode = (
                <div className="copy-values-container" onMouseLeave={() => setIsButtonsVisible(false)}>
                    <div className="editable-cell-value-wrap" onClick={toggleEdit} onMouseOver={() => setIsButtonsVisible(true)}>{displayedValue + '%'}</div>

                    {isButtonsVisible && (
                        <div className="copy-values-buttons">
                            {record.date >= "02" && (
                                <div className="copy-value-button" onClick={() => copyValues("up", record, dataIndex === 'volume')}>
                                    <Tooltip title={i18n.t<string>("reply_to_up")} placement="right" className="buttons-reply-copy-up">
                                        <UpSquareOutlined style={{ boxShadow: 'none', border: 'none', color: '#0065B3' }} />
                                    </Tooltip>
                                </div>
                            )}

                            {record.date <= "11" && (
                                <div className="copy-value-button" onClick={() => copyValues("down", record, dataIndex === 'volume')}>
                                    <Tooltip title={i18n.t<string>("reply_to_down")} placement="right" className="buttons-reply-copy-down">
                                        <DownSquareOutlined style={{ boxShadow: 'none', border: 'none' }} />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )
        }
    }

    return <td {...restProps}>{childNode}</td>;
};