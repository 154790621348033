import { Key, useEffect, useState } from "react"
import i18n, { languages } from "util/base/i18n"
import moment from 'moment';

import { Icon } from "@iconify/react"
import { Button, Checkbox, DatePicker, Form, Input, Modal, Table } from "antd"

import { ServiceCaller } from "util/service/ServiceCaller"
import { RequestType } from "util/service/IServiceCaller"

import './FilterStyles.sass'
import { FilterKey, IFilter, FilterState, FilterOption } from "./IFilter"

import { useUserContext } from "context/UserContext";

export function Filter({
	form,
	isFilterVisible,
	handleCancel,
	setListFilters,
	listFilters,
	submitRequest,
	hasFiltersOnScreen
}: IFilter) {

	const { userInfo } = useUserContext()

	const [selectedRows, setSelectedRows] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
	const [tableData, setTableData] = useState<FilterOption[]>([]);
	const [searchedData, setSearchedData] = useState<string>("");

	const [organizations, setOrganizations] = useState<FilterOption[]>([]);
	const [businessUnits, setBusinessUnits] = useState<FilterOption[]>([]);

	const [groupManagements, setGroupManagements] = useState<FilterOption[]>([]);
	const [groupContracts, setGroupContracts] = useState<FilterOption[]>([]);
	const [projects, setProjects] = useState<FilterOption[]>([]);
	const [divisions, setDivisions] = useState<FilterOption[]>([]);

	const [selectedTable, setSelectedTable] = useState<FilterKey>();

	const [filter, setFilter] = useState<FilterState>({
		organizations: [],
		businessUnits: [],
		groupContracts: [],
		groupManagements: [],
		projects: [],
		divisions: []
	})

	useEffect(() => {
		getManagementGroups()
		getContractGroups()
		getProjects()
		getDivisions()
	}, [])

	useEffect(() => {
		if (userInfo.selection.scenarioId) {
			getOrganizations()
			if (userInfo.selection.organizationId)
				getBusinessUnits()
		}

	}, [userInfo.selection.scenarioId])

	useEffect(() => {
		if (hasFiltersOnScreen)
			handleOrganization()
		else
			handleManagementGroup()
	}, [isFilterVisible])

	useEffect(() => {
		if (hasFiltersOnScreen) {
			handleOrganization()
			setFilter({
				...filter,
				organizations: [{
					key: userInfo.selection.organizationId,
					name: userInfo.selection.organizationName
				}],
				businessUnits: [{
					key: userInfo.selection.businessUnitId,
					name: userInfo.selection.businessUnitName
				}]
			})

			setSelectedRowKeys([userInfo.selection.organizationId])
		} else {
			handleManagementGroup()
		}
	}, [organizations])

	useEffect(() => {
		if (filter.organizations.length)
			getBusinessUnits()
	}, [filter.organizations])

	useEffect(() => {
		const filterItems = listFilters.map((filterItem) => filterItem.key)
		let hasTableData = true;

		if (!filterItems.includes(1)) {
			form.resetFields(['startDate'])
			form.resetFields(['endDate'])
		}

		if (!filterItems.includes(2)) {
			form.resetFields(['isNew'])
		}

		if (!filterItems.includes(3)) {
			form.resetFields(['isAdditive'])
		}

		if (!filterItems.includes(4)) {
			form.resetFields(['isTerminated'])
		}

		if (!filterItems.includes(5)) {
			hasTableData = false
			setTableData([])
			setFilter({
				organizations: [],
				businessUnits: [],
				groupContracts: [],
				groupManagements: [],
				projects: [],
				divisions: []
			})
		}

		handleSubmit(form.getFieldsValue(), false, hasTableData)
	}, [listFilters])

	const updateFilter = (key: FilterKey, values: Key[], selectedRows: FilterOption[]) => {
		const newValues = selectedRows.map(row => ({
			key: row.key,
			name: row.name
		}));

		setFilter(prevFilter => {
			if (prevFilter.hasOwnProperty(key)) {
				return {
					...prevFilter,
					[key]: newValues
				};
			} else {
				return prevFilter;
			}
		});
	};

	function onSelectRowChange(selectedRowKeys: Key[], selectedRows: FilterOption[]) {
		Object.keys(filter).forEach(key => {
			if (key === selectedTable) {
				updateFilter(key as FilterKey, selectedRowKeys, selectedRows);
			}
		});

		setSelectedRows(selectedRows);
		setSelectedRowKeys(selectedRowKeys);
	};

	function getOrganizations() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/contractprojection/filter/organization` +
				`?scenarios=${userInfo.selection.scenarioId}`,
			useProxy: false,
		}, (data) => {
			setOrganizations(data.map(({ name, id }) => {
				return { name, key: id }
			}))
		});
	}

	function getBusinessUnits() {
		const organizations = filter.organizations.map((item) => item.key);
		const organizationsParam = organizations.length > 0 ? organizations : [userInfo.selection.organizationId];
		const organizationsQueryString = organizationsParam.join("&organizations=");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/contractprojection/filter/businessunit?scenarios=${userInfo.selection.scenarioId}&organizations=${organizationsQueryString}`,
			useProxy: false,
		}, (data) => {
			setBusinessUnits(data.map(({ name, id }) => ({ name, key: id })));
		});
	}

	function getManagementGroups() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/contractprojection/filter/managementgroup',
			useProxy: false,
		}, (data) => {
			setGroupManagements(data.map(({ name, id }) => {
				return { name, key: id }
			}))
		});
	}

	function getContractGroups() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/contractprojection/filter/contractgroup',
			useProxy: false,
		}, (data) => {
			setGroupContracts(data.map(({ name, id }) => {
				return { name, key: id }
			}))
		});
	}

	function getProjects() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/contractprojection/filter/project',
			useProxy: false,
		}, (data) => {
			setProjects(data.map(({ name, id }) => {
				return { name, key: id }
			}))
		});
	}

	function getDivisions() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/contractprojection/filter/divisiongroup',
			useProxy: false,
		}, (data) => {
			setDivisions(data.map(({ name, id }) => {
				return { name, key: id }
			}))
		});
	}

	function handleOrganization() {
		setSelectedRowKeys(filter.organizations.map((item) => item.key))
		setTableData(organizations)
		setSelectedTable('organizations')
		form.resetFields(['table-search-input'])
	}

	function handleBusinessUnit() {
		setSelectedRowKeys(filter.businessUnits.map((item) => item.key))
		setTableData(businessUnits)
		setSelectedTable('businessUnits')
		form.resetFields(['table-search-input'])
	}

	function handleProject() {
		setSelectedRowKeys(filter.projects.map((item) => item.key))
		setTableData(projects)
		setSelectedTable('projects')
		form.resetFields(['table-search-input'])
	}

	function handleManagementGroup() {
		setSelectedRowKeys(filter.groupManagements.map((item) => item.key))
		setTableData(groupManagements)
		setSelectedTable('groupManagements')
		form.resetFields(['table-search-input'])
	}

	function handleContractGroup() {
		setSelectedRowKeys(filter.groupContracts.map((item) => item.key))
		setTableData(groupContracts)
		setSelectedTable('groupContracts')
		form.resetFields(['table-search-input'])
	}

	function handleDivision() {
		setSelectedRowKeys(filter.divisions.map((item) => item.key))
		setTableData(divisions)
		setSelectedTable('divisions')
		form.resetFields(['table-search-input'])
	}

	function handleSearch(e) {
		setSearchedData(e.target.value);
	}

	function handleFilterList(data, isFromModal) {
		const listFilters = [];

		let formatedDate = false as any

		if (data.startDate !== 0 && data.endDate !== 0) {
			formatedDate =
				new Date(data.startDate).toLocaleDateString('pt-BR') + ' - ' +
				new Date(data.endDate).toLocaleDateString('pt-BR') as any
		} else if (data.startDate !== 0 && data.endDate === 0) {
			formatedDate = 'Desde: ' + new Date(data.startDate).toLocaleDateString('pt-BR')
		} else if (data.startDate === 0 && data.endDate !== 0) {
			formatedDate = 'Até: ' + new Date(data.endDate).toLocaleDateString('pt-BR')
		}

		if (formatedDate)
			listFilters.push({ key: 1, description: formatedDate })

		if (data.isNew)
			listFilters.push({ key: 2, description: i18n.t<string>('new_s') });

		if (data.isAdditive)
			listFilters.push({ key: 3, description: i18n.t<string>('additives') });

		if (data.isTerminated)
			listFilters.push({ key: 4, description: i18n.t<string>('terminated') });

		const businessUnits = data.businessUnits.map(({ key, name }) => ({ key, description: name }));
		const groupManagements = data.groupManagements.map(({ key, name }) => ({ key, description: name }));
		const groupContracts = data.groupContracts.map(({ key, name }) => ({ key, description: name }));
		const projects = data.projects.map(({ key, name }) => ({ key, description: name }));
		const divisions = data.divisions.map(({ key, name }) => ({ key, description: name }));

		const allFilters = [...data.organizations, ...businessUnits, ...groupContracts, ...groupManagements, ...projects, ...divisions];

		if (allFilters.length > 0) {
			listFilters.push({
				key: 5,
				groupItems: allFilters
			});
		}

		if (isFromModal) {
			setListFilters(listFilters)
		}
	}

	function handleSubmit(data, isFromModal, hasTableData) {
		data.startDate = isNaN(data.startDate) ? 0 : new Date(data.startDate).getTime()
		data.endDate = isNaN(data.endDate) ? 0 : new Date(data.endDate).getTime()

		data = {
			...data,
			scenarios: [userInfo.selection.scenarioId],
			isAdjustment: false,
			banks: [],
			operations: [],
		}

		if (hasTableData) {
			data = { ...data, ...filter }
		} else {
			data = {
				...data,
				organizations: [],
				businessUnits: [],
				groupContracts: [],
				groupManagements: [],
				projects: [],
				divisions: []
			}
		}

		handleFilterList(data, isFromModal)

		if (!isFromModal) {
			let formatedData = {
				...data,
				organizations: data.organizations ? data.organizations.map((item) => item.key) : [],
				businessUnits: data.businessUnits ? data.businessUnits.map((item) => item.key) : [],
				groupContracts: data.groupContracts ? data.groupContracts.map((item) => item.key) : [],
				groupManagements: data.groupManagements ? data.groupManagements.map((item) => item.key) : [],
				projects: data.projects ? data.projects.map((item) => item.key) : [],
				divisions: data.divisions ? data.divisions.map((item) => item.key) : [],
			}

			if (!hasFiltersOnScreen) {
				formatedData.organizations = [userInfo.selection.organizationId]
				formatedData.businessUnits = [userInfo.selection.businessUnitId]
			}

			submitRequest(formatedData)
			handleCancel()
		}
	}

	const filteredData = tableData.filter(obj => obj.name.toLowerCase().includes(searchedData.toLowerCase()));

	return (
		<Modal
			width={1212}
			title={i18n.t<string>('filtate')}
			visible={isFilterVisible}
			onCancel={handleCancel}
			className="gs-modal filter-modal"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("save")}
			okButtonProps={{ htmlType: "submit", form: "filter-form" }}
			maskClosable={false}
		>
			<Form
				form={form}
				name="filter-form"
				className={"filter-form"}
				onFinish={(data) => { handleSubmit(data, true, true) }}
			>
				{hasFiltersOnScreen && (
					<div className="top-container">
						<div className="dates-wrapper">
							<Form.Item
								name="startDate"
								label={i18n.t<string>('from')}
								rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								initialValue={moment().startOf('month')}
							>
								<DatePicker locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
							</Form.Item>
							<div className="divider"></div>
							<Form.Item
								initialValue={moment().endOf('month')}
								name="endDate"
								label={i18n.t<string>('until')}
								dependencies={['startDate']}
								rules={[{
									required: true, message: i18n.t<string>("required_field"),
									validator: (_, value) => {
										const endDate = new Date(value).setHours(0, 0, 0, 0)
										const startDate = new Date(form.getFieldValue("startDate")).setHours(0, 0, 0, 0)

										if (endDate !== 0 && endDate > 0 && moment(startDate).isAfter(endDate))
											return Promise.reject(i18n.t<string>('end_date_must_be_later'))

										return Promise.resolve()
									}
								}]}
							>
								<DatePicker locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
							</Form.Item>
						</div>
						<div className="checkboxes-wrapper">
							<Form.Item name='isNew' valuePropName="checked" initialValue={false}>
								<Checkbox>{i18n.t<string>('new_s')}</Checkbox>
							</Form.Item>
							<Form.Item name="isAdditive" valuePropName="checked" initialValue={false}>
								<Checkbox>{i18n.t<string>('additives')}</Checkbox>
							</Form.Item>
							<Form.Item name="isTerminated" valuePropName="checked" initialValue={false}>
								<Checkbox>{i18n.t<string>('terminated')}</Checkbox>
							</Form.Item>
						</div>
					</div>
				)}
				<div className="content">
					<div className="button-column">
						{hasFiltersOnScreen && (
							<>
								<Button onClick={handleOrganization} >
									{i18n.t<string>('organization')}
									<span className="counter">
										({filter.organizations.length})
									</span>
								</Button>
								<Button onClick={handleBusinessUnit}>
									{i18n.t<string>('operating_unit')}
									<span className="counter">
										({filter.businessUnits.length})
									</span>
								</Button>
								<hr className="divider" />
							</>
						)}
						<Button onClick={handleManagementGroup}>
							{i18n.t<string>('management_group')}
							<span className="counter">
								({filter.groupManagements.length})
							</span>
						</Button>
						<Button onClick={handleContractGroup}>
							{i18n.t<string>('contract_group')}
							<span className="counter">
								({filter.groupContracts.length})
							</span>
						</Button>
						<Button onClick={handleProject}>
							{i18n.t<string>('project')}
							<span className="counter">
								({filter.projects.length})
							</span>
						</Button>
						<Button onClick={handleDivision}>
							{i18n.t<string>('division')}
							<span className="counter">
								({filter.divisions.length})
							</span>
						</Button>
					</div>
					<div className="table-wrapper">
						<Form.Item
							name="table-search-input"
						>
							<Input
								prefix={<Icon icon="charm:search" />}
								className="table-search-input gs-search"
								placeholder={i18n.t('search')}
								onChange={handleSearch}
							/>
						</Form.Item>
						<Table
							className="gs-table"
							columns={[{
								title: i18n.t<string>('description'),
								key: 'name',
								dataIndex: 'name',
							}]}
							dataSource={filteredData}
							rowSelection={{ selectedRowKeys, onChange: onSelectRowChange }}
							pagination={false}
							scroll={{ y: 376 }}
						/>
					</div>
				</div>
			</Form>
		</Modal >
	)
}