import { Button } from "antd";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import i18n from "util/base/i18n";
import { IEmptyListProps } from "../../../IOtherEvents";
import { useOtherEventContext } from "../../../context/OtherEventContext";
import { usePermissionContext } from "context/PermissionContext";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";

export default function EmptyList({ onClickNewBudgetOpening, isSpendingPackage }: IEmptyListProps) {
    const {
        isQueryByPackage,
	} = useOtherEventContext();
	const { functionalityPermissions } = usePermissionContext();
	const importProps: IImportModal[] = [
		{
			templateUrl: "/planning/other-event/template?localeId={locale}&userId={user}&clientId={client}&scenarioId={scenario}&organizationId={organization}&businessUnitId={businessUnit}",
			importUrl: `/planning/other-event/import`,
			type: "excel",
		}
	]
    isSpendingPackage = isQueryByPackage
    return (
        <div className="budget-initial-content">
            <img src="/assets/images/budget.png" alt="wfn-budget" />
			<h2>{i18n.t("other_events_texts.shall_we_start_your_other_events")}</h2>
            <p>
                {i18n.t("fixed_expense_texts.select_filters")}{" "}
                <span style={{ fontWeight: 500 }}>{i18n.t("cost_center")}</span> {i18n.t("and")}{" "}
                <span style={{ fontWeight: 500 }}>
                    {isSpendingPackage ? i18n.t("spending_package") : i18n.t("ledger_account")}
                </span>{" "}        
                {i18n.t("fixed_expense_texts.at_the_top_of_the_screen")}
            </p>
            <div className="budget-initial-content-buttons">
				{functionalityPermissions.import ||
					functionalityPermissions.refreshrhlinked ? (
					<ImportExportMenu
						buttonType="button"
						importProps={importProps}
					/>
				) : null}
                <Button
                    style={{ padding: "0 40px" }}
                    onClick={onClickNewBudgetOpening}
                    className="gs-main-button openning-budget-button"
                >
                    {i18n.t("begin")}
                </Button>
            </div>
        </div>
    )
}
