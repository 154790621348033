import { Icon } from "@iconify/react";
import { Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import { IndexerResponse } from "module/budget/pages/budgetProjection/IBudgetProjection";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import moment from "moment";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ContractType, GenericDisplayBean, IContractModalProps, IDatePickerDefaultProps, PeriodicityTypeValues } from "../IRecurringContract";
import FlexContent from "./FlexContent";
import ValuePeriodTable from "./ValuePeriodTable";
import { useNewContractContext } from "./newContractContext";

export default function ContractModal({
	isOpen,
	setIsOpen,
	contractToEdit,
	setContractToEdit,
	flexFieldValues,
	periodFrozen,
	basePeriod
}: IContractModalProps) {
	const { form,
		filterFlexFieldHasValue,
		filterFlexFieldSmsHasValue,
		optionsContractType
	} = useNewContractContext();

	const startDateSelected = Form.useWatch('startDate', form);
	const finalDateSelected = Form.useWatch('finalDate', form);
	const periodicityTypeSelected = Form.useWatch('periodicityType', form);
	const contractType = Form.useWatch<ContractType>('contractType', form);
	const [periodSelected, setPeriodSelected] = useState<[moment.Moment, moment.Moment]>([moment().startOf("year"), moment().endOf("year")]);
	const value = Form.useWatch('value', form);
	const [indexerOptions, setIndexerOptions] = useState<GenericSelection[]>([]);
	const [formattedMonthsValue, setFormattedMonthsValue] = useState<{ [value: string]: number }>({});
	const [isSaving, setIsSaving] = useState(false);
	const [monthsForEdit, setMonthsForEdit] = useState({});
	const defaultDatePickerProps: IDatePickerDefaultProps = {
		picker: "month",
		className: "replicate-scenario-month",
		suffixIcon: < Icon icon="fa6-solid:calendar" />,
		allowClear: false,
		format: "MMM/YYYY"
	}
	const { userInfo: { selection: { organizationId, scenarioId }, currencyId } } = useUserContext();
	const defaultRuleForm = { required: true, message: i18n.t<string>("required_field") };
	const isDisabled = contractType === ContractType.NEW_SALES_SMS;

	const [auxContractType, setAuxContractType] = useState<GenericSelection[]>(optionsContractType);

	const optionsPeriodicityType: GenericSelection[] = [
		{
			label: i18n.t("revenue.report_viewer.monthly"),
			value: "MONTHLY"
		},
		{
			label: i18n.t("revenue.report_viewer.bimonthly"),
			value: "BIMONTHLY"
		},
		{
			label: i18n.t("revenue.report_viewer.quarterly"),
			value: "QUARTERLY"
		},
		{
			label: i18n.t<string>("revenue.report_viewer.fourmonthly"),
			value: "FOURMONTHLY"
		},
		{
			label: i18n.t("revenue.report_viewer.semesterly"),
			value: "SEMESTERLY"
		},
		{
			label: i18n.t("revenue.report_viewer.yearly"),
			value: "YEARLY"
		},
	];

	useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "budget-base/indexer",
            },
            onLoadIndexer
        );
    }, []);

    useEffect(() => {
        if (!contractToEdit) return;
        const {
            indexerId,
            contractType,
            contractDescription,
            readjustDate,
            flexFieldValueItens,
            endPeriod,
            startPeriod,
            value,
            periodicityType,
            monthValues,
            externalCode,
            flexFieldValueSmsItens,
            gracePeriodSms,
            rateSms,
        } = contractToEdit;
        const flexFieldItens = buildFlexFieldItens(flexFieldValueItens);
        const [startPeriodFormated, endPeriodFormated] = [moment(startPeriod), moment(endPeriod)];
        let fields = {
            contractType,
            description: contractDescription,
            startDate: startPeriodFormated,
            finalDate: endPeriodFormated,
            externalCode,
            ...flexFieldItens,
        };

        if (contractType === "RECURRING") {
            fields["readjustDate"] = moment(readjustDate);
            fields["value"] = value;
            fields["periodicityType"] = periodicityType;
            fields["indexerId"] = indexerOptions.find(({ value }) => value === indexerId.toString())?.label;

            let currentPeriod: moment.Moment = startPeriodFormated.clone();
            let newMonthsValues: {} = {};
            while (currentPeriod.isSameOrBefore(endPeriodFormated)) {
                Object.assign(newMonthsValues, {
                    [currentPeriod.format("YYYY-MM")]: value / PeriodicityTypeValues[periodicityTypeSelected],
                });
                currentPeriod.add(1, "month");
            }
            setMonthsForEdit(newMonthsValues);
        } else {
            setMonthsForEdit(monthValues);
            if (contractType === "NEW_SALES") {
                Object.assign(fields, buildFlexFieldItensSMS(flexFieldValueSmsItens));
                fields["gracePeriodSms"] = gracePeriodSms;
                fields["rateSms"] = rateSms;
            }
        }

        form.setFieldsValue(fields);
        setIsOpen(true);
    }, [contractToEdit]);

    useEffect(() => {
        if (!startDateSelected || !finalDateSelected) return;

        setPeriodSelected([startDateSelected, finalDateSelected]);
    }, [startDateSelected, finalDateSelected]);

    useEffect(() => {
        if (!contractToEdit) {
            setAuxContractType((prevAuxContractType) =>
                prevAuxContractType.filter(({ value }) => value !== ContractType.NEW_SALES_SMS)
            );
        } else {
            const { contractType } = contractToEdit;
            const existContractTypeSMS = auxContractType.find(({ value }) => value === ContractType.NEW_SALES_SMS);
            if (contractType === ContractType.NEW_SALES_SMS && !existContractTypeSMS) {
                auxContractType.push({
                    label: i18n.t("new_sales_sms"),
                    value: ContractType.NEW_SALES_SMS.toString(),
                });
            }
        }
    }, [isOpen]);

	function handleCloseModal() {
		setIsOpen(false);
		setIsSaving(false);
		form.resetFields();
		setContractToEdit(null);
		setFormattedMonthsValue(null);
		setMonthsForEdit({});
	}

	function onLoadIndexer(data: IndexerResponse[]) {
		setIndexerOptions(data.map(({ id, description }) => ({ value: id.toString(), label: description })))
	}

	function onSaveContract() {
		handleCloseModal();
		Notification({
			message: i18n.t<string>("contract_saved_successfully"),
			type: "success",
		})
	}

	function handleSubmit(values: any) {
		setIsSaving(true);
		const flexFieldValueIds: number[] = filterFlexFieldHasValue(values);
		const startDate: moment.Moment = moment(values.startDate);
		const finalDate: moment.Moment = moment(values.finalDate);

		let dataToSave = {
			startDate: startDate.isSameOrBefore(finalDate) ? startDate.format("YYYY-MM") :  finalDate.format("YYYY-MM"),
			endDate: finalDate.isSameOrAfter(startDate) ? finalDate.format("YYYY-MM") : startDate.format("YYYY-MM"),
			organizationId,
			scenarioId,
			flexFieldValueIds,
			description: values.description,
			contractType: values.contractType,
			externalCode: values.externalCode,
			currencyDefaultId: currencyId
		};

		if (contractType === ContractType.RECURRING) {
			dataToSave["readjustDate"] = moment(values.readjustDate).format("YYYY-MM");
			dataToSave["value"] = values.value
			dataToSave["periodicityType"] = values.periodicityType
			dataToSave["indexerId"] = indexerOptions.find(({ value }) => value === values.indexerId)?.value;
		} else {
			const newMonthsValues = {};
			Object.keys(formattedMonthsValue).forEach((key) => {
				if (key.includes("-")) {
					const currentDate: moment.Moment = moment(key);

					if (currentDate.isSameOrAfter(startDate) && currentDate.isSameOrBefore(finalDate)) {
						Object.assign(newMonthsValues, { [key]: formattedMonthsValue[key] });
					}
				}
			})

			dataToSave["monthValues"] = newMonthsValues;
			if (contractType === ContractType.NEW_SALES) {
				dataToSave["flexFieldValueSmsIds"] = filterFlexFieldSmsHasValue(values);
				dataToSave["rateSms"] = values.rateSms;
				dataToSave["gracePeriodSms"] = values.gracePeriodSms;
			}
		}
		if (contractToEdit) {
			dataToSave["id"] = contractToEdit.id;
		}

		ServiceCaller.doRequest({
			type: contractToEdit ? RequestType.PUT : RequestType.POST,
			url: "/revenue/recurring-contract",
			params: dataToSave
		}, onSaveContract, handleErrorRequest)
	}

	function disableDate(date: moment.Moment, isInitialDate: boolean = true) {
		const isDisable = isInitialDate
			? date.isSameOrAfter(finalDateSelected)
			: date.isSameOrBefore(startDateSelected);
		return isDisable;
	}

	function onChangeContractType() {
		form.resetFields(["value", "indexerId", "periodicityType", "readjustDate"]);
	}

	function buildFlexFieldItens(flexItens: GenericDisplayBean[]) {
		let values = {};
		flexItens.forEach(({ id }) => {
			const flexField = flexFieldValues.find(({ children }) => children.find(({ value }) => id === value));
			if (flexField?.value) {
				return Object.assign(values, { [`flexField-${flexField.value}-${flexField.fieldCode}`]: id })
			}
		})

		return values;
	}

	function buildFlexFieldItensSMS(flexItens: GenericDisplayBean[] = []) {
		let values = {};
		flexItens.forEach(({ id }) => {
			const flexField = flexFieldValues.find(({ children }) => children.find(({ value }) => id === value));
			if (flexField?.value) {
				return Object.assign(values, { [`flexField-${flexField.value}-${flexField.fieldCode}-SMS`]: id })
			}
		})

		return values;
	}

	async function isValidExternalCode(externalCode: string): Promise<boolean> {
		const { data } = await ServiceCaller.doAsyncRequest<boolean>({
			type: RequestType.GET,
			url: `/revenue/recurring-contract/validate-externalcode?${contractToEdit ? `id=${contractToEdit.id}&` : ""}externalCode=${externalCode}&basePeriod=${basePeriod.format("YYYY-MM-DD")}`
		});

		return data;
	}

	return (
		<Modal
			okButtonProps={{
				htmlType: "submit",
				form: "contract-form",
				loading: isSaving
			}}
			open={isOpen}
			onCancel={handleCloseModal}
			width={window.innerWidth * 0.8}
			className="gs-modal"
			okText={i18n.t<string>("save")}
			cancelText={i18n.t<string>("cancel")}
			title={contractToEdit ? i18n.t("edit_contract") : i18n.t("new_contract")}
			destroyOnClose
		>
			<Form
				form={form}
				layout="vertical"
				className="new-contract-form"
				onFinish={handleSubmit}
				name="contract-form"
			>
				<Row gutter={12}>
					<Col span={8}>
						<Form.Item
							label={i18n.t("contract_type")}
							name="contractType"
							rules={[defaultRuleForm]}
						>
							<Select
								onChange={onChangeContractType}
								options={auxContractType}
								disabled={isDisabled}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							rules={[{...defaultRuleForm}, () => ({
								async validator(_, value) {
									const isValid: boolean = await isValidExternalCode(value);
 
									return !isValid && Promise.reject(new Error(i18n.t<string>("the_external_code_field_cannot_repeat")));
								},
							  })]}
							label={i18n.t("external_code")}
							name="externalCode"
						>
							<Input placeholder={i18n.t("type_here")} disabled={isDisabled} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							rules={[defaultRuleForm]}
							label={i18n.t("description")}
							name="description"
						>
							<Input placeholder={i18n.t("type_here")} disabled={isDisabled} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={12}>
					<Col span={8}>
						<Form.Item
							rules={[defaultRuleForm]}
							initialValue={moment().startOf("year")}
							name={"startDate"}
							label={i18n.t("start_date")}
						>
							<DatePicker
								disabledDate={disableDate}
								{...defaultDatePickerProps}
								disabled={isDisabled}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							rules={[defaultRuleForm]}
							initialValue={moment().endOf("year")}
							name={"finalDate"}
							label={i18n.t("end_date")}
						>
							<DatePicker
								disabledDate={date => disableDate(date, false)}
								{...defaultDatePickerProps}
								disabled={isDisabled}
							/>
						</Form.Item>
					</Col>
					{contractType === ContractType.RECURRING &&
						< Col span={8}>
							<Form.Item
								rules={[defaultRuleForm]}
								name={"readjustDate"}
								label={i18n.t("birthday_date")}
							>
								<DatePicker
									{...defaultDatePickerProps}
									disabled={isDisabled}
								/>
							</Form.Item>
						</Col>
					}
					{contractType === ContractType.NEW_SALES &&
						<>
							<Col span={4}>
								<Form.Item
									name={"rateSms"}
									label={`${i18n.t("rate")} SMS`}
								>
									<InputNumber
										defaultValue={0}
										prefix="%"
										decimalSeparator=","
										precision={10}
										controls={false}
									/>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item
									name={"gracePeriodSms"}
									label={`${i18n.t("shortage")} SMS`}
								>
									<InputNumber
										formatter={value => String(value).replace(/\./g, '')}
										parser={value => String(value).replace(/\./g, '')}
										controls={false}
									/>
								</Form.Item>
							</Col>
						</>

					}
				</Row>
				<Row gutter={12}>
					{contractType === ContractType.RECURRING &&
						<>
							<Col span={8}>
								<Form.Item
									rules={[defaultRuleForm]}
									name={"periodicityType"}
									label={i18n.t("periodicity")}
									initialValue={"MONTHLY"}
								>
									<Select
										options={optionsPeriodicityType}
									/>
								</Form.Item>
							</Col>

							<Col span={8}>
								<Form.Item
									rules={[defaultRuleForm]}
									name={"value"}
									label={i18n.t("value")}
									initialValue={0}
								>
									<InputNumber
										controls={false}
										prefix="R$"
										decimalSeparator=","
										precision={2}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									rules={[defaultRuleForm]}
									name={"indexerId"}
									label={i18n.t("indexer")}
								>
									<Select
										options={indexerOptions}
									/>
								</Form.Item>
							</Col>
						</>
					}
				</Row>
				<Row>
					<ValuePeriodTable
						value={value}
						period={periodSelected}
						isNotRecurring={contractType !== ContractType.RECURRING}
						periodicityTypeSelected={periodicityTypeSelected}
						setMonthsValues={setFormattedMonthsValue}
						monthsForEdit={monthsForEdit}
						periodFrozen={periodFrozen}
						isDisable={isDisabled}
					/>
				</Row>
				<FlexContent
					isNewSales={contractType === ContractType.NEW_SALES}
					flexFieldValues={flexFieldValues}
					isDisable={isDisabled}
				/>
			</Form>
		</Modal >
	)
}