import { InputNumber, Table } from "antd";
import { Column, TableData } from "./IDistributionModal";
import moment from 'moment';
import { Icon } from "@iconify/react";
import { useRef } from "react";
import i18n from "util/base/i18n";
import { useWindowSize } from "hooks/useWindowSize";

interface IPercentHistoricTab {
    tableData: TableData[];
    setTableData: Function;
    valueAmount: number;
    setValueAmount: Function;
    hasHistoricValues: boolean;
}

export function PercentHistoricTab({
    tableData,
    setTableData,
    valueAmount,
    setValueAmount,
    hasHistoricValues
}: IPercentHistoricTab) {
    const inputDistValueRef = useRef(null);
    const windowSize = useWindowSize()

    const totalHistoricAmount = tableData.reduce((acc, val) => {
        acc += val.historicValue
        return acc
    }, 0)

    function onChangeValueAmount(value) {
        const updatedTableData = JSON.parse(JSON.stringify(tableData));
        updatedTableData.forEach(item => {
            if (totalHistoricAmount > 0) {
                const proportion = item.historicValue / totalHistoricAmount;
                item.value = value * proportion
            } else {
                item.value = value / updatedTableData.length
            }
        })
        setTableData(updatedTableData)
        setValueAmount(value)
        return (new Intl.NumberFormat("pt-BR", {
            notation: "compact"
        }).format(Number(value)))
    }

    const handleFocus = () => {
        if (inputDistValueRef.current) {
            inputDistValueRef.current.select();
        }
    };

    const columns: Column[] = [
        {
            title: i18n.t("months"),
            dataIndex: 'month',
            key: 'month',
            className: 'month-column',
            render: (text) => {
                const titleMonthCell = moment(text).format('MMM/YYYY').charAt(0).toUpperCase()
                    + moment(text).format('MMM/YYYY').slice(1)
                return titleMonthCell
            }
        },
        {
            title: i18n.t("historic"),
            dataIndex: 'historicValue',
            align: 'center',
            key: 'historicValue',
            render: (value: number) => value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })
        },
        {
            title: '%',
            align: 'center',
            key: 'percent',
            render: (_, record: TableData) => totalHistoricAmount > 0 ? `${(record.historicValue / totalHistoricAmount * 100).toFixed(2)} %` : 0
        },
        {
            title: i18n.t("fixed_expense_texts.historical_proportion"),
            align: 'center',
            dataIndex: 'value',
            key: 'result',
            render: (value) => value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            })
        },
    ]


    return (
        <div>
            {hasHistoricValues ?
                <>
                    <div className="dist-period-inputs-container" style={{ justifyContent: 'start', gap: 10 }}>
                        <p style={{ margin: 0 }}>{i18n.t("fixed_expense_texts.amount_to_be_distributed")}</p>
                        <InputNumber
                            ref={inputDistValueRef}
                            onFocus={handleFocus}
                            onChange={onChangeValueAmount}
                            value={valueAmount}
                            controls={false}
                            decimalSeparator=","
                            width={"100%"}
                            precision={2}
                            prefix="R$"
                        />
                    </div>

                    <Table
                        scroll={{ y: windowSize.height - 340 }}
                        className="gs-table"
                        columns={columns}
                        dataSource={tableData}
                        bordered
                        summary={(data: readonly TableData[]) => createGridSummary(data)}
                        pagination={{ hideOnSinglePage: true, pageSize: 100 }}
                    />
                </>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10, padding: 30 }}>
                    <Icon style={{ fontSize: 16 }} icon="fluent:calendar-empty-20-regular" />
                    <p style={{ margin: 0 }}>{i18n.t("fixed_expense_texts.there_is_no_history")}</p>
                </div>
            }

        </div>
    )
}

function createGridSummary(data: readonly TableData[]) {
    const totals = data.reduce((acc, row) => {
        acc.totalHistoryValue = acc.totalHistoryValue + row.historicValue
        acc.totalResult = acc.totalResult + row.value
        return acc
    }, { totalHistoryValue: 0, totalAmount: 0, totalResult: 0 })

    return (
        <>

            <Table.Summary.Row style={{ background: '#FBE6E6' }}>
                <Table.Summary.Cell index={0} align="left">Total</Table.Summary.Cell>
                <Table.Summary.Cell index={0} align="center">
                    {totals.totalHistoryValue.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center">100%</Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                    {totals.totalResult.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    )
}