import { Table } from "antd";
import i18n from "util/base/i18n";
import { LinkAccountsTableProps } from "../ILinkAccounts";
import { usePermissionContext } from "context/PermissionContext";

export default function LinkAccountTable({
    isFetching,
    tableData,
    selectedRowKeys,
    onChange,
    columns
}: LinkAccountsTableProps) {
    const { functionalityPermissions } = usePermissionContext()
    const rowSelection = {selectedRowKeys, onChange};
    
    return (
        <Table
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            className="gs-table"
            dataSource={tableData}
            columns={columns}
            rowSelection={functionalityPermissions.edit || functionalityPermissions.remove ? rowSelection : null}
            pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 20 }}
            bordered
        />
    );
}