import { Icon } from "@iconify/react";
import { formatNumber } from "util/formatNumber";
import { SummaryCard } from "./ISummaryCards";

export function Card({
	label,
	icon,
	value,
	type,
	isoCode
}: SummaryCard) {
	return (
		<div id="card-container">
			{icon && <Icon icon={icon} />}
			<div id="card-content">
				<p id="card-title">{label}</p>
				<span>
					{type === "percent" ?
						value.toFixed(2).replace('.', ',') + '%'
						:
					type === "currency" ?
						formatNumber(value, {
							style: type,
							currency: isoCode,
						})
						: 
						value
					}
				</span>
			</div>
		</div>
	)
}