import { useEffect, useState } from "react";
import {
	Button,
	Layout,
	Space,
	Tooltip,
	Typography,
} from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";

import i18n from "util/base/i18n";

import Settings from "./settings/Settings";
import { CurrencyCard, FinanceCard, IndexerCard, Filter, Badge } from "./index";
import "./widget/Widget.sass";

import {
	IConfiguration,
	IFilter
} from "./IDashboardAnalytics";

import { ServiceCaller } from "util/service/ServiceCaller";
import { MessageType, RequestType } from "util/service/IServiceCaller";
import WidgetContent from "./widget/WidgetContent";
import { reOrder } from "./settings/ISettings";

import html2canvas from "html2canvas";
import jsPDF, { jsPDFOptions } from "jspdf";

import moment from "moment";

import CoachMark from "../../../components/coachMark/functions/coach-mark-core";
import { coachMarkChannel } from "../../../util/BroadcastChannels";
import { useUserContext } from "context/UserContext";
import { useCoachMark } from "./hooks/useCoachMark";

export default function DashboardAnalytics() {

	const { userInfo } = useUserContext();

	const [configuration, setConfiguration] = useState({
		id: 0,
		userId: 0,
		conversionCurrencyId: 0,
		selectedCurrencies: [],
		filter: null,
		widgets: [],
		isoCode: ""
	} as IConfiguration);

	const [openedKeys, setOpenedKeys] = useState(configuration.widgets.filter(({ active, opened }) => active && opened).map(({ id }) => `widget-${id}`));

	const {
		coachMarkStepList,
		openCoachMark,
		setCurrentStep,
		setOpenCoachMark,
		step,
		openedSettings,
		setOpenedSettings
	} = useCoachMark()

	function handleOpenCoachMark() {
		coachMarkChannel.postMessage('show-overlay')
		setOpenCoachMark(true)
		setCurrentStep(1)
  }

	coachMarkChannel.onmessage = (message) => {
		if (message.data === 'open-coachMark') {
			handleOpenCoachMark()
		}
	}

	const [exporting, setExporting]: [boolean, Function] = useState(false);

	const [collapsed, setCollapsed] = useState(false);

	const onLoadConfiguration = (configuration: IConfiguration) => {
		if (userInfo.plan === "FREEMIUM"){
			configuration.widgets = configuration.widgets.filter(item => ["PAYMENT_COCENTRATION", "CASH_FLOW_MAIN_ROWS", "CASH_FLOW_AVAILABILITY", "BANK_BALANCE_BUSINESS"].includes(item.type))
		}
		configuration.widgets.sort(({ position: position1 }, { position: position2 }) => {
			if (position1 > position2) {
				return 1;
			} else if (position2 > position1) {
				return -1;
			}

			return 0;
		})
		setConfiguration(configuration);
	}

	const [widgetSettings, setWidgetSettings] = useState([]);

	const onChangedCurrency = (newCurrency: number) => {
		setConfiguration({
			...configuration,
			conversionCurrencyId: newCurrency
		});
	}

	const onChangeCurrency = (newCurrency: number) => {
		ServiceCaller.doRequest({
			type: RequestType.PATCH,
			url: `/dashboard-analytics/configuration/${configuration.id}/conversion-currency/${newCurrency}`
		}, onChangedCurrency.bind(this, newCurrency));
	}

	const callBackFilter = (data: IFilter) => {
		setConfiguration({
			...configuration,
			filter: data
		})
	}

	const callBackSettings = (data: Array<reOrder>) => {
		setConfiguration((state) => {
			return ({
				id: state.id,
				filter: state.filter,
				userId: state.userId,
				conversionCurrencyId: state.conversionCurrencyId,
				selectedCurrencies: state.selectedCurrencies,
				widgets: state.widgets.map((item) => {
					const el = data.find((el) => el.id === item.id);
					item.position = el.position
					item.active = el.active
					return item
				}),
				isoCode: state.isoCode
			})
		})
	}

	const retractAsider: Function = () => {
		setCollapsed(true);
	}

	useEffect(() => {
		if (userInfo.id != null) {
			ServiceCaller.doSendMessage({
				messageType: MessageType.OPEN_COACHMARK
			}, handleOpenCoachMark);

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: "/dashboard-analytics/configuration/{user}"
			}, onLoadConfiguration.bind(this));
		}

	}, [userInfo]);

	useEffect(() => {
		if (configuration.id !== 0) {
			setWidgetSettings(configuration.widgets.map(({ id, active, position, type }) => {
				return {
					id,
					active,
					position,
					type
				}
			}))
		}
	}, [configuration]);

	const doExport = () => {
		if (!exporting) {
			setExporting(true);

			setTimeout(() => {
				let printableContainer: HTMLElement = document.getElementById("analytics-container");

				html2canvas(printableContainer, {
					scale: 2
				}).then(canvas => {
					buildHeaderForExport(canvas);
				});
			}, 0);
		}
	};

	const buildHeaderForExport = (canvas: HTMLCanvasElement): void => {
		const topbarSize: number = 80;
		const {
			width,
			height
		} = canvas;

		const increaseContentHeight = (context: CanvasRenderingContext2D) => {
			context.beginPath();
			context.canvas.height = (height + topbarSize);
			context.closePath();
		};

		const doMoveContentToDown = (context: CanvasRenderingContext2D, content: ImageData) => {
			context.beginPath();
			context.putImageData(content, 0, topbarSize);
			context.closePath();
		};

		const doBuildTopBar = (context: CanvasRenderingContext2D) => {
			context.beginPath();
			context.fillStyle = "#0a5790";
			context.fillRect(0, 0, width, topbarSize);
			context.closePath();
		};

		const doAttachCompanyLogoInTopBar = (context: CanvasRenderingContext2D) => {
			const iconWidth: number = 280;
			const iconHeight: number = 40;
			const iconMargin: number = 20;
			const iconBase64: string = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKYAAAAUCAYAAAAHikYJAAAABHNCSVQICAgIfAhkiAAAA6RJREFUaIHVme190zAQhx/11wG8AWUC2g3MBIUJCBO0nYAwQdmAMEHLBA4TUCaomaBhgj8f5BTXOb8okhzyfGod6e50Okt3Z2iQVEj6KulJETBCAj1Vj9xS0l2M7S1KQ3YKHiUtRvxTjdmTE0mLlt6fkt5NnFdIWjZr3McvS0vouSIDcsuI8Sn07ASmXjozBWVHfqrA3PJTUtHjo2rMnlyo349fR+adNWtK5peT5o8KMB2VcNFZ9Mi/0YOO+w85B+4ObUQb+ZO8z48LWSfaP+7wa4rl2S8nwC2Zg7Ihl57bDDLnoJzrJBxjJCi3XFmnvKRr0gTlllJSeQpYOcQGeEiojIR6nsc3G3vWM2YTKLfNlLkhthfYm/cBWE+UkYWJQQl+De+AVef5pTE2xDel8ewDxj3/ZL0ZseTQI59sd0l+RcrOMc0iLNBWK1+ujHFlssW81DWUm1s549KQYTH5BO3z7Ykx9sE5F3PaTCWXnjqDzBTUhzagjYZPyhVws69s59zkW9A5t7aen06ZbL0pBus+JT2cTZT7jHOuO95ywLV8c+BPgOga/6KEpBWh9l8Zz+Y4AHYYCcoN8BufZhyOidfLVB7V0/cKkNGLIbNIIbdFJeMaUvp20ZaFoasyxpX77a65DzGttaUhb4c9bOpiXuUxnAF3kmaplJtU4HNCkSVQKSBHiqAG7mfQ84ymFzoHJ3VgbrlO+ZYP0VzvKTsIBT44c7bQNsD7mXJ54LiCEvIFJszYX3TOXeBPzlQbXQCLRLK6rIGLwHw2imMLSphY/DB+Xb5ht095LqkYOBVq4NtE/aM455aSvuCv49Cr+NKYcwl8GZhTE2Z/jS8Q6xDDYhkJynvgV89vr8j3co5jJZ57yrH6XmVqPTmQXUQ9tX6P7mMG2FIN+TFQ1lChM/b921rz0hi3wx527vjWusrPFZhfNeNDc7JgPRmxTth6biMGCC7GNNKndM59jLIoM6f4vKwdIAXwKCmop4fxabDT10yhB3y/8UXzV74LEFNJl8azOkJeDD/YtedWkvXpr4++T6BwBEEJPjDv2c0lCuzNCmHV+T+XHvCbkEJOm++J5U1lBXwynpcpZB9DUIKvym9I/wXC6i/m0JOLtXNudQjFTXE0VHTty9EEJcBJUzW/JV3QbIC33eozg55cPADvD2lAk6qsEoo8qqCEpo/Z9NRe452xb+Bsmvm9PbpEenJRA5+dcxdzNr77aALpI3EfDx7wjfyjCkqAvwjUumOOt8zZAAAAAElFTkSuQmCC";

			const icon: HTMLImageElement = new Image();
			icon.src = iconBase64;
			icon.onload = () => {
				context.beginPath();
				context.drawImage(icon, (width - (iconWidth + iconMargin)), iconMargin, iconWidth, iconHeight);
				context.closePath();

				context.save();

				buildAndExportPdf(canvas);
			};
		};

		const context: CanvasRenderingContext2D = canvas.getContext("2d");
		const sourceContent: ImageData = context.getImageData(0, 0, width, height);

		increaseContentHeight(context);
		doMoveContentToDown(context, sourceContent);
		doBuildTopBar(context);
		doAttachCompanyLogoInTopBar(context);
	};

	const buildAndExportPdf = (canvas: HTMLCanvasElement): void => {
		const borderSize: number = 30;
		let pdfOptions: jsPDFOptions = {
			unit: "px",
			hotfixes: ["px_scaling"],
			compress: true
		}

		if (canvas.width > canvas.height) {
			pdfOptions.orientation = "landscape";
			pdfOptions.format = [(canvas.width + (borderSize * 2)), (canvas.height + (borderSize * 2))];
		} else {
			pdfOptions.orientation = "portrait";
			pdfOptions.format = [(canvas.height + (borderSize * 2)), (canvas.width + (borderSize * 2))];
		}

		let pdf: jsPDF = new jsPDF(pdfOptions);
		pdf.addImage(canvas, "PNG", borderSize, borderSize, 0, 0, null, "FAST");
		pdf.save(`Dashboard Analytics ${moment().format("DD-MM-YYYY HH[h]mm")}.pdf`, {
			returnPromise: true
		}).then(setExporting.bind(this, false));
	};

	return (
		<Layout id="analytics-container" className="light">
			<Layout.Header>
				<Space style={{ margin: "0 0 0 1rem" }} align="center">
				{userInfo.plan === "FREEMIUM"
					? <Typography.Title style={{wordBreak: "unset", whiteSpace: "nowrap"}} level={3} className="gs-font-color-secondary">Reporte Financeiro</Typography.Title>
					: <Typography.Title style={{wordBreak: "unset", whiteSpace: "nowrap"}} level={3} className="gs-font-color-secondary">Analytics</Typography.Title>
				}
					
					<Button
						onClick={handleOpenCoachMark}
						style={{ borderRadius: 4, marginTop: 25, marginLeft: 10 }}
						icon={<Icon style={{ color: '#4F9ACF', fontSize: 26 }} icon="clarity:info-line" />}
					></Button>

				</Space>

				<Space className="align-end" data-html2canvas-ignore="true">
					<Tooltip className="cm-step1" title={i18n.t<string>("export")}>
						<Button type="text" size="large" icon={exporting ? <LoadingOutlined /> : <Icon icon={"entypo:export"} />} onClick={doExport} />
					</Tooltip>
					<Tooltip className="cm-step1 cm-step1-main" title={i18n.t<string>("filter")}>
						<Filter callBack={callBackFilter} filter={configuration.filter} />
					</Tooltip>
					<Settings step={step.step} setVisiblePopover={setOpenedSettings} visiblePopover={openedSettings} callBack={callBackSettings} data={widgetSettings} />
				</Space>
			</Layout.Header>

			<Layout className="light">
				<Layout.Sider className="cm-step3 cm-step3-main" collapsedWidth={0} collapsible collapsed={collapsed} trigger={null} width={300}>
					<Space direction="vertical" size={10} style={{ display: "flex" }}>
						<CurrencyCard retractAsider={retractAsider.bind(this)} configId={configuration.id} originCurrency={configuration.conversionCurrencyId} onChangeCurrency={onChangeCurrency.bind(this)} currenciesToConversion={configuration.selectedCurrencies} countryOrigin={userInfo?.countryOrigin} />
						<IndexerCard />
						{userInfo.plan != "FREEMIUM" && <FinanceCard globalFilter={configuration.filter} />}
					</Space>
				</Layout.Sider>
				{collapsed === true &&
					<Button style={{ border: "none", color: "#4F9ACF", margin: "0 10px 0 0", height: "3.7rem", borderRadius: "6px" }}
						icon={<Icon icon={"ant-design:arrow-right-outlined"} />}
						onClick={() => setCollapsed(false)}
						data-html2canvas-ignore="true"
					/>
				}
				<Layout.Content>
					{userInfo.plan !== "FREEMIUM" && < Badge globalFilter={configuration.filter} currencyId={configuration.conversionCurrencyId} />}
					{configuration.widgets.length > 0 &&
						<WidgetContent
							globalFilter={configuration.filter}
							currencyId={configuration.conversionCurrencyId}
							widgets={configuration.widgets}
							configId={configuration.id}
							openedKeys={openedKeys}
							setOpenedKeys={setOpenedKeys}
						/>
					}
				</Layout.Content>
			</Layout>
			{openCoachMark && <CoachMark {...step} steps={coachMarkStepList} />}
		</Layout >
	)
}
