import { useContext } from "react";
import { Divider } from "antd";
import i18n from "util/base/i18n";

import { RuleModalContext } from "../../../../context/RuleModalContext";
import { IConditionGrouperType } from "../../../../IAttributeParameterization";

export function RuleConditionsTabs() {
    const { selectedConditionGrouper, handleSelectedConditionGrouper, conditions } = useContext(RuleModalContext);

    let revenueTabButtonClassName = "";
    if (selectedConditionGrouper === IConditionGrouperType.REVENUE) {
        revenueTabButtonClassName = "rounded-step active-rounded-step";
    } else {
        revenueTabButtonClassName = "rounded-step inactive-rounded-step";
    }

    let fixedExpenseTabButtonClassName = "";

    const hasRevenueCondition = conditions.filter((condition) => condition?.grouperType).some(
        (condition) => condition.grouperType === IConditionGrouperType.REVENUE);

    if (!hasRevenueCondition) {
        fixedExpenseTabButtonClassName = "rounded-step blocked-rounded-step";
    } else if (selectedConditionGrouper === IConditionGrouperType.FIXED_EXPENSE) {
        fixedExpenseTabButtonClassName = "rounded-step active-rounded-step";
    } else {
        fixedExpenseTabButtonClassName = "rounded-step inactive-rounded-step";
    }

    return (
        <>
            <div id="attribute_parameterization-new-rule-modal-conditions-container">
                <h2 id="attribute_parameterization-new-attribute-modal-conditions-title">
                    {selectedConditionGrouper === IConditionGrouperType.REVENUE
                        ? i18n.t<string>("revenue.revenue_conditions")
                        : i18n.t<string>("revenue.fixed_expense_conditions")}
                </h2>

                <div id="attribute_parameterization-new-attribute-modal-conditions-wizard-counter">
                    <button
                        id="attribute_parameterization-new-attribute-modal-conditions-wizard-revenue-button"
                        className={revenueTabButtonClassName}
                        onClick={() => handleSelectedConditionGrouper(IConditionGrouperType.REVENUE)}
                    ></button>
                    <button
                        id="attribute_parameterization-new-attribute-modal-conditions-wizard-fixed_expense-button"
                        disabled={!hasRevenueCondition}
                        className={fixedExpenseTabButtonClassName}
                        onClick={() => handleSelectedConditionGrouper(IConditionGrouperType.FIXED_EXPENSE)}
                    ></button>
                </div>
            </div>

            <Divider />
        </>
    );
}
