import { createContext, useState } from "react";

import {
	FormulaItem,
	IConditionGrouperType,
	IRuleApportionmentType,
} from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { IModalContext } from "./IRuleModalContext";
import { IRuleCondition } from "../components/modals/rule/components/IModalFactory";

export const RuleModalContext = createContext<IModalContext>(null);

export function RuleModalProvider({ children }) {
	const [externalCode, setExternalCode] = useState("");
	const [description, setDescription] = useState("");
	const [order, setOrder] = useState(0);
	const [apportionmentType, setApportionmentType] = useState<IRuleApportionmentType>(IRuleApportionmentType.REVENUE);
	const [selectedConditionGrouper, setSelectedConditionGrouper] = useState<IConditionGrouperType>(
		IConditionGrouperType.REVENUE
	);
	const [conditions, setConditions] = useState<IRuleCondition[]>([]);
	const [formula, setFormula] = useState<FormulaItem[]>([]);
	const [deletedConditionsIds, setDeletedConditionsIds] = useState<number[]>([]);

	function handleExternalCode(event: React.ChangeEvent<HTMLInputElement>) {
		setExternalCode(event.target.value);
	}

	function handleDescription(event: React.ChangeEvent<HTMLInputElement>) {
		setDescription(event.target.value);
	}

	function handleOrder(value: number) {
		setOrder(value);
	}

	function handleApportionmentType(value: IRuleApportionmentType) {
		setApportionmentType(value);
	}

	function handleSelectedConditionGrouper(selectedCondition: IConditionGrouperType) {
		setSelectedConditionGrouper(selectedCondition);
	}

	function handleCreateMultipleCondition(allConditions: IRuleCondition[]) {
		let formattedCondition = allConditions.map((condition) => ({
		  grouperType: condition.grouperType,
		  type: condition.type,
		  selectedLevel: condition.selectedLevel,
		  operation: condition.operation,
		  selectedLevelValue: condition.selectedLevelValue,
		}));

		setConditions((prevConditions) => {
			prevConditions = prevConditions.filter((condition) => condition);

			formattedCondition = formattedCondition.filter((condition) => {
				const existingCondition = prevConditions.find((prevCondition) => {
					if(condition.grouperType === IConditionGrouperType.REVENUE) {
						return (
							prevCondition.grouperType === condition.grouperType &&
							prevCondition.type === condition.type &&
							prevCondition.selectedLevel.label === condition.selectedLevel.label &&
							prevCondition.operation.content === condition.operation.content
						);
					} else if(condition.grouperType === IConditionGrouperType.FIXED_EXPENSE) {
						return (
							prevCondition.grouperType === condition.grouperType &&
							prevCondition.type === condition.type &&
							prevCondition.selectedLevel.label === condition.selectedLevel.label &&
							prevCondition.operation.content === condition.operation.content &&
							condition.selectedLevelValue.label.includes(prevCondition.selectedLevelValue.label)
						);
					}
				});

				return existingCondition ? false : true;
			});

			let combinedConditions = [...prevConditions, ...formattedCondition];
		  
			return combinedConditions;
		});
	}

	function handleAddParsedConditionsFromBackend(existingConditions: IRuleCondition[]) {
		setConditions(existingConditions);
	}

	function removeCondition(index: number) {
		const condition = conditions[index];

		if (condition?.id) {
			setDeletedConditionsIds((prevDeletedConditionsIds) => [...prevDeletedConditionsIds, condition.id]);
		}

		setConditions((prevConditions) => {
			const newConditions = [...prevConditions];
			newConditions.splice(index, 1);
			return newConditions;
		});
	}

	function handleDragEnd(result) {
		if (!result.destination) return;
		const items = Array.from(conditions);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setConditions(items);
	}

	return (
		<RuleModalContext.Provider
			value={{
				externalCode,
				setExternalCode,
				handleExternalCode,

				description,
				setDescription,
				handleDescription,

				order,
				setOrder,
				handleOrder,

				apportionmentType,
				handleApportionmentType,

				selectedConditionGrouper,
				handleSelectedConditionGrouper,

				conditions,
				setConditions,
				handleAddParsedConditionsFromBackend,
				removeCondition,
				handleCreateMultipleCondition,

				handleDragEnd,

				formula,
				setFormula,

				deletedConditionsIds,
				setDeletedConditionsIds,
			}}
		>
			{children}
		</RuleModalContext.Provider>
	);
}
