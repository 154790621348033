import { DefaultRecordType } from "rc-table/lib/interface";

interface ICashFlowData {

	description: string

	provided: number

	accomplished: number

	budget: number

	variation: number

	isoCode: string

}

interface ICashFlowStep {

	index: number

	title: string

	loading: boolean

	status: string

	gridColumns: DefaultRecordType[]

	gridData: any[]

	defaultGridData?: any

	singleSelect: boolean

	selectedValues?: number[]

	defaultSelectedValues?: number[]

}

interface ICashFlowStepProps {

	contentId: number

	flowId: number

	levels: number[]

	budgetId: number

	reloadView: Function

}

enum ICashFlowMode {

	SELECTION_STEP,

	FLOW_VIEW

}

export {

	ICashFlowData,

	ICashFlowStep,

	ICashFlowStepProps,

	ICashFlowMode

};
