import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

import { ItemCardProps } from "structure/module/IModule";

import { MessageType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import "./styles.sass";
import { ROOT_PATH } from "util/rootPath";

export function ItemCard(props: ItemCardProps) {
	const navigate = useNavigate()

	function doRedirect(url: string, event: MouseEvent): void {
		if (!event.ctrlKey) {
			event.preventDefault();
			if (url.includes('/mf/') || url.includes('module')) {
				ServiceCaller.doSendMessage({
					messageType: MessageType.REPLACE_URL,
					params: {
						url: ROOT_PATH + url
					}
				});
				navigate(url.replace(ROOT_PATH, '').replace('/mf', ''))
			} else {
				ServiceCaller.doSendMessage({
					messageType: MessageType.REDIRECT_TO,
					params: {
						url: ROOT_PATH + url
					}
				});
			}
		}
	};

	return <>
		<a href={ROOT_PATH + props.link} onClick={doRedirect.bind(this, props.link)} className="item-card-container">
			{ !props.icon && <Icon icon={props.icon} /> }
			<h3>{props.title}</h3>
		</a>
	</>;

}
