import { Select } from 'antd';
import { ICustomSelect } from '../../IAttributeParameterization';

export function CustomSelect({
	id,
	labelName,
	options,
	onChange,
	disabled,
	defaultValue,
	value,
	key,
	hasSearch = false,
	clearSelects = false,
	onClear,
	mode
}: ICustomSelect) {
	return (
		<div
			key={key}
			className="custom-select"
			style={{ display: 'flex', flexDirection: 'column' }}
		>
			<label className="gs-input-label" htmlFor={id}>
				{labelName}
			</label>

			<Select
				{...(defaultValue && { defaultValue: defaultValue })}
				{...(value && { value: value })}
				{...(mode && { mode: mode })}
				id={id}
				onChange={onChange}
				style={{ marginTop: 5 }}
				options={options}
				disabled={disabled}
				allowClear={clearSelects}
				onClear={onClear}
				showSearch={hasSearch}
			/>
		</div>
	);
}
