import { Row, Col } from "antd";
import { IFlexFieldItensProps } from "../IRecurringContract";
import FlexFieldsSelect from "./FlexFieldsSelect";

export default function FlexFieldItens({ flexFieldValues, isDisable }: IFlexFieldItensProps) {
	return (
		<Row className="flex-field-content" gutter={10} wrap>
			{flexFieldValues.length > 0 &&
				flexFieldValues.map(flexField =>
					<Col span={8} key={flexField.value}>
						<FlexFieldsSelect
							fieldCode={flexField.fieldCode}
							id={flexField.value}
							label={flexField.label}
							values={flexField.children}
							defaultValue={flexField.defaultValue}
							isDisabled={isDisable}
						/>
					</Col>
				)
			}
		</Row>
	)
}