import { Icon } from "@iconify/react"
import { Button, Col, Collapse, Dropdown, Form, FormInstance, Input, InputRef, Menu, Row, Table, Tooltip } from "antd"
import moment from "moment";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import i18n from "util/base/i18n"
import { validateMonetaryInput } from "util/functions/validateKey";
import { Column, ColumnTypes, Detail, EditableCellProps, EditableRowProps, IDetailList } from "../../detailLayout/IDetailLayout"

const { Panel } = Collapse;

export function DetailList({
    detailList, 
    setDetailList, 
    handleDelete, 
    handleEditDetail, 
    currencyOptions, 
    isEditBtnDisabled 
}: IDetailList) {
    const EditableContext = createContext<FormInstance<any> | null>(null);
    return (
        <>
            <Row
                style={{
                    fontWeight: "500",
                    marginBottom: 5,
                    borderTop: '1px solid #ccc',
                    marginTop: 10,
                    paddingTop: 8,
                    padding: '8px 15px 0 39px'
                }}
            >
                <Col span={4}>
                    <span>Layout</span>
                </Col>
                <Col span={8}>
                    {i18n.t<string>("detail")}
                </Col>
                <Col span={4}>
                    <span>{i18n.t<string>("currency")}</span>
                </Col>
                <Col span={6}>
                    <span>{i18n.t("fixed_expense_texts.calculation_by_quantity")}</span>
                </Col>
            </Row>
            {detailList?.map(detail => {
                const currency = currencyOptions.find(currency => currency.value === detail.currencyId)?.label ?? '';

                const detailRow =
                    <Row
                        key={detail.description + detail.currencyId}
                        align="middle"
                        className="detailLayout-list-item"
                        style={detail.isByQuantity ?
                            { border: 'none', paddingLeft: 5 }
                            :
                            { marginBottom: 5, paddingLeft: 39 }
                        }
                    >
                        <Col span={4} style={{ justifyContent: 'start' }}>{detail.detailLayout}</Col>
                        <Col span={8} style={{ justifyContent: 'start' }}>{detail.description}</Col>
                        <Col span={4} style={{ justifyContent: 'start' }}>
                            <Tooltip title={currency ? currency : ''}><span className="gs-tag currency" style={{ margin: 0 }}>{currency.split('-').shift()}</span></Tooltip>
                        </Col>
                        <Col span={6} style={{ justifyContent: 'start' }}>{detail.isByQuantity ? i18n.t("yes") : i18n.t("no")}</Col>
                        <Col span={1} style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                style={{ border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                                disabled={isEditBtnDisabled}
                                onClick={() => handleEditDetail(detail)}
                                icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                            />
                        </Col>
                        <Col span={1} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="trash-item-modal">
                                <Icon
                                    className="delete-icon"
                                    onClick={() => handleDelete(detail.description, detail.currencyId)}
                                    icon="fa6-solid:trash"
                                />
                            </div>
                        </Col>
                    </Row>

                if (detail.isByQuantity) {
                    const columns: Column[] = detail.detailValues.map(item => {
                        const titleMonthCell = moment(item.month).format('MMM YYYY').charAt(0).toUpperCase()
                            + moment(item.month).format('MMM YYYY').slice(1)
                        return {
                            title: titleMonthCell,
                            dataIndex: `detailValue_${item.month}`,
                            key: titleMonthCell,
                            editable: true,
                            width: 80
                        }
                    });

                    const valuesObject = {
                        description: detail.description,
                        currencyId: detail.currencyId,
                    }
                    detail.detailValues.forEach(item => {
                        valuesObject[`detailValue_${item.month}`] = item.value
                    });
                    const tableData = [valuesObject]
                    const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
                        const [form] = Form.useForm();
                        return (
                            <Form form={form} component={false} >
                                <EditableContext.Provider value={form}>
                                    <tr {...props} />
                                </EditableContext.Provider>
                            </Form>
                        );
                    };


                    const EditableCell: React.FC<EditableCellProps> = ({
                        title,
                        editable,
                        children,
                        dataIndex,
                        record,
                        ...restProps
                    }) => {
                        const [editing, setEditing] = useState(false);
                        const inputRef = useRef<InputRef>(null);
                        const form = useContext(EditableContext)!;


                        function handleReplicateValue() {
                            const updatedDetailList = [...detailList]
                            const detail = detailList.find(item => (item.description === record.description && item.currencyId === record.currencyId))
                            const index = updatedDetailList.findIndex(item => (item.description === record.description && item.currencyId === record.currencyId))
                            
                            detail.detailValues.forEach(item => {
                                item.value = record[dataIndex] as number
                            })
                            
                            updatedDetailList.splice(index, 1, detail)
                            setDetailList(updatedDetailList)
                        }

                        useEffect(() => {
                            if (editing) {
                                inputRef.current!.focus();
                                inputRef.current!.select();
                            }
                        }, [editing]);

                        const toggleEdit = () => {
                            setEditing(!editing);
                            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
                        };

                        const save = async () => {
                            try {
                                const values: { key: 'string' } = await form.validateFields();
                                const key = Object.keys(values)[0]
                                const value = Object.values(values)[0] ?
                                    Number(Object.values(values)[0].toString().replace(',', '.'))
                                    : 0
                                if (record[key] === value) {
                                    toggleEdit();
                                    return
                                }

                                record[key] = value
                                const updatedDetailList = [...detailList]
                                const index = updatedDetailList.findIndex(item => (item.description === record.description && item.currencyId === record.currencyId))
                                const detail = updatedDetailList[index]
                                detail.detailValues.forEach(item => {
                                    if (key.includes(item.month)) {
                                        item.value = value
                                    }
                                })
                                updatedDetailList.splice(index, 1, detail)
                                setDetailList(updatedDetailList)
                                toggleEdit();
                            } catch (errInfo) {
                                console.log('Save failed:', errInfo);
                            }
                        };

                        let childNode = children;
                        if (editable) {
                            childNode = editing ? (
                                <Form.Item
                                    style={{ margin: 0, padding: '1px 4px' }}
                                    name={dataIndex as string}
                                >
                                    <Input
                                        style={{ height: 28 }}
                                        onKeyDown={validateMonetaryInput}
                                        ref={inputRef}
                                        onPressEnter={save}
                                        onBlur={save}
                                    />
                                </Form.Item>
                            ) : (
                                <Dropdown
                                    overlayClassName='dropdown-cell-options-container'
                                    overlay={
                                        <Menu onClick={handleReplicateValue}>
                                            <Menu.Item key="replicate">{i18n.t("detail_layout.replicate_value_for_the_coming_months")}</Menu.Item>
                                        </Menu>
                                    }
                                    trigger={['contextMenu']}>
                                    <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                                        {children}
                                    </div>
                                </Dropdown>
                            )
                        }

                        return <td {...restProps}>{childNode}</td>;
                    };


                    const components = {
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    };

                    const cols = columns.map(col => {
                        if (!col.editable) {
                            return col;
                        }
                        return {
                            ...col,
                            onCell: (record: Detail) => ({
                                record,
                                editable: col.editable,
                                dataIndex: col.dataIndex,
                                title: col.title,
                                align: 'center',
                            }),
                        };
                    });

                    return (
                        <Collapse
                            key={detail.id}
                            expandIcon={(panelProps) => panelProps.isActive ? <Icon icon="ion:chevron-up" /> : <Icon icon="ion:chevron-down" />}
                            expandIconPosition="left"
                            defaultActiveKey={'1'}
                            style={{ marginBottom: 5, background: '#FCFCFC' }}
                        >
                            <Panel header={detailRow} key="1">
                                <Table
                                    className="gs-table"
                                    dataSource={tableData}
                                    components={components}
                                    columns={cols as ColumnTypes}
                                    bordered
                                    pagination={{ hideOnSinglePage: true }}
                                />
                            </Panel>
                        </Collapse>
                    )
                } else {
                    return detailRow
                }
            })}
        </>
    )
}