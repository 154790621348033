import { Icon } from "@iconify/react";

export const expandIcon = ({ expanded, onExpand, record }) => {
    if (record.children) {
        if (expanded) {
            return (
                <div className="button-expandable-tree" onClick={(e) => onExpand(record, e)}>
                    <Icon icon="tabler:chevron-down" />
                </div>
            );
        } else {
            return (
                <div className="button-expandable-tree" onClick={(e) => onExpand(record, e)}>
                    <Icon icon="tabler:chevron-right" />
                </div>
            );
        }
    }
};