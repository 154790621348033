import { CalendarOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
import { Button, Space, Typography } from 'antd';
import { Filter } from 'components/filter/Filter';
import { useContext } from 'react';
import i18n from "util/base/i18n";
import { AttributeParameterizationContext } from '../context/AttributeParameterizationContext';

export function Title() {
    const { period, isYearPeriod, setPeriod } =
        useContext(AttributeParameterizationContext);

    function handlePeriod(period: [moment.Moment, moment.Moment]) {
        setPeriod([period[1], period[0]]);
    }

    return (
        <div id='attribute_parameterization-title'>
            <Space id="attribute_parameterization-header-title" align="center">
                <Typography.Title level={3} className="gs-font-color-primary">
                    {i18n.t<string>("revenue.attribute_parameterization")}
                </Typography.Title>
                <Button
                    className="coachmark-button"
                    icon={<Icon icon="clarity:info-line" />}
                />
            </Space>
            <Space style={{ marginRight: 15 }}>
                {isYearPeriod ? (
                    <Filter
                        id="period-filter"
                        value={period}
                        setValue={handlePeriod}
                        title={i18n.t<string>("period")}
                        type="datepicker"
                        link="/"
                        data={[]}
                        datepicker={{
                            format: 'YYYY',
                            picker: 'year',
                            rangePicker: true,
                        }}
                        buttonStyles={{ cursor: 'default', margin: 0, padding: 0 }}
                    />
                ) : (
                    <Filter
                        id="period-filter"
                        value={period}
                        setValue={handlePeriod}
                        title={i18n.t<string>("period")}
                        type="datepicker"
                        link="/"
                        data={[]}
                        datepicker={{
                            format: 'MMM YYYY',
                            picker: 'month',
                            rangePicker: true,
                        }}
                        buttonStyles={{ cursor: 'default', margin: 0, padding: 0 }}
                    />
                )}

                <CalendarOutlined
                    style={{
                        fontSize: 16,
                        position: 'relative',
                        right: 20,
                        top: 4,
                        color: '#333333',
                    }}
                />
            </Space>
        </div>
    );
}
