import { Carousel } from "antd";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import { ErrorFallbackProps } from "components/error/ErrorFallback";
import { Filter } from "components/filter/Filter";
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton";
import { useBudgetDates } from "hooks/useBudgetDates";
import { useWindowSize } from "hooks/useWindowSize";
import moment from "moment";
import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import i18n from "util/base/i18n";
import { onChangeFlexFieldFilter } from "util/functions/onChangeFlexFieldFilter";
import { ModuleType } from "util/types/types";
import { IFilters } from "../IInvestment";

export function Filters({
    businessUnitOptions,
    costCenterOptions,
    accountingOptions,
    flexFieldsOptions,
    setFlexFieldsOptions,
    fetchApportionments,
    periodFilter,
    setPeriodFilter,
    costCenter,
    setCostCenter,
    accounting,
    setAccounting,
    flexFieldStringKey,
    setFlexFieldStringKey,
    businessUnit,
    setBusinessUnit,
}: IFilters) {
    const [isFetchingFlexFields, setIsFetchingFlexFields] = useState(false);
    const [filtersPerRow, setFiltersPerRow] = useState(5);
    const windowSize = useWindowSize();

    const { data: budgetPeriodDates } = useBudgetDates(ModuleType.EXPENSERESOURCES);

    const { showBoundary } = useErrorBoundary();
    const [openedCalendarPeriod, setOpenedCalendarPeriod] = useState<[moment.Moment, moment.Moment][]>([
        [moment().startOf("year"), moment().month(11).endOf("month")],
    ]);

    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 288) / 195);
        setFiltersPerRow(updatedFiltersPerRow);
    }, [windowSize]);

    useEffect(() => {
        if (!budgetPeriodDates) {
            return;
        }
        if (budgetPeriodDates.period?.length === 0) {
            showBoundary({
                code: 400,
                title: "Não há exercício aberto",
                message: "Adicione um exercício na tela de painel de controle, ou peça permissão para o exercício",
                image: "empty_list",
            } as ErrorFallbackProps["error"]);
        }
        const openedPeriods: [moment.Moment, moment.Moment][] = budgetPeriodDates.period?.reduce((acc, val) => {
            const period = [moment(val.expensesResourcesBudgetStartDate), moment(val.expensesResourcesBudgetEndDate)];
            acc.push(period);
            return acc;
        }, []);
        setOpenedCalendarPeriod(openedPeriods);
    }, [budgetPeriodDates]);

    function onChangeFilter(filter: string, option) {
        switch (filter) {
            case 'businessUnit':
                if (option[0]?.value === businessUnit[0]?.value) {
                    return
                }
                fetchApportionments(
                    periodFilter,
                    option[0].value,
                    costCenter[0]?.value ?? 0,
                    accounting[0]?.value ?? 0,
                    flexFieldStringKey
                )
                break;
            case 'costCenter':
                if (option[0]?.value === costCenter[0]?.value) {
                    return
                }
                fetchApportionments(
                    periodFilter,
                    businessUnit[0]?.value ?? 0,
                    option[0].value,
                    accounting[0]?.value ?? 0,
                    flexFieldStringKey
                )
                break;
            case 'accounting':
                if (option[0]?.value === accounting[0]?.value) {
                    return
                }
                fetchApportionments(
                    periodFilter,
                    businessUnit[0]?.value ?? 0,
                    costCenter[0]?.value ?? 0,
                    option[0].value,
                    flexFieldStringKey
                )
                break;
            case 'period':
                if (periodFilter === option) {
                    return
                }
                fetchApportionments(
                    option,
                    option[0].value,
                    costCenter[0]?.value ?? 0,
                    accounting[0]?.value ?? 0,
                    flexFieldStringKey
                )
                break;
            case 'flexField':
                fetchApportionments(
                    periodFilter,
                    businessUnit[0]?.value ?? 0,
                    costCenter[0]?.value ?? 0,
                    accounting[0]?.value ?? 0,
                    option,
                )
                break;

            default:
                break;
        }
    }

    return (
        <div className="apportionment-filters-container">
            {isFetchingFlexFields ?
                <FilterSkeleton
                    direction='row'
                    active
                    amount={4}
                    gap={10}
                    cardClassName='filter-card-skeleton'
                />
                :
                <Carousel
                    className="gs-carousel carousel-infos"
                    {...carouselDefaultProps}
                    slidesPerRow={filtersPerRow}
                >
                    <Filter
                        id="period-filter"
                        value={periodFilter}
                        setValue={(value) => {
                            setPeriodFilter(value)
                            onChangeFilter("period", value)
                        }}
                        title={"Período"}
                        type="datepicker"
                        data={[]}
                        datepicker={{
                            format: "MM/YYYY",
                            picker: "month",
                            period: openedCalendarPeriod,
                            rangePicker: true,
                            allowClear: false,
                            defaultToday: true
                        }}
                        buttonStyles={{ cursor: 'default' }}
                    />
                    <Filter
                        id="businessUnit-filter"
                        title={i18n.t("business_unit")}
                        type="radio"
                        hasSearch
                        hasSelectNone
                        searchPlaceholder={i18n.t("business_unit_search")}
                        value={businessUnit}
                        setValue={(value) => {
                            setBusinessUnit(value)
                            onChangeFlexFieldFilter(
                                value[0]?.value,
                                'BUSINESS_UNIT',
                                flexFieldsOptions,
                                setFlexFieldsOptions,
                                value[0]?.value,
                                costCenter[0]?.value ?? 0,
                                accounting[0]?.value ?? 0
                            )
                            onChangeFilter("businessUnit", value)
                        }}
                        data={businessUnitOptions}
                    />
                    <Filter
                        id="costCenter-filter"
                        title={i18n.t("cost_center")}
                        type="radio"
                        hasSearch
                        hasSelectNone
                        searchPlaceholder={i18n.t("search_by_cost_center")}
                        value={costCenter}
                        setValue={(value) => {
                            setCostCenter(value)
                            onChangeFlexFieldFilter(
                                value[0]?.value,
                                'COST_CENTER',
                                flexFieldsOptions,
                                setFlexFieldsOptions,
                                businessUnit[0]?.value ?? 0,
                                value[0]?.value,
                                accounting[0]?.value ?? 0
                            )
                            onChangeFilter("costCenter", value);
                        }}
                        data={costCenterOptions}
                    />
                    <Filter
                        id="accounting-filter"
                        title={i18n.t("investment.accounting")}
                        type="radio"
                        hasSearch
                        hasSelectNone
                        searchPlaceholder={i18n.t("search_by_accounting")}
                        value={accounting}
                        setValue={(value) => {
                            setAccounting(value)
                            onChangeFilter("accounting", value);
                        }}
                        data={accountingOptions}
                    />
                    {flexFieldsOptions.map(ffFilter => {
                        return (
                            <Filter
                                key={ffFilter.value}
                                id="flex-field-filter"
                                title={ffFilter.label}
                                type="radio"
                                value={ffFilter.selectedOption}
                                setValue={async (value: any) => {
                                    if (value.length > 0) {
                                        const flexString = await onChangeFlexFieldFilter(
                                            value[0]?.value,
                                            ffFilter.value,
                                            flexFieldsOptions,
                                            setFlexFieldsOptions,
                                            businessUnit[0]?.value ?? 0,
                                            costCenter[0]?.value ?? 0,
                                            accounting[0]?.value ?? 0
                                        )
                                        setFlexFieldStringKey(flexString)
                                        onChangeFilter("flexField", flexString)
                                    }
                                }}
                                data={ffFilter.children}
                                isFetching={ffFilter.isFetching ?? false}
                                hasSearch
                                hasSelectNone
                            />
                        )
                    })}
                </Carousel>
            }
        </div>
    )
}