import { Icon } from "@iconify/react";
import { formatNumber } from "util/formatNumber";
import { SummaryCard } from "./ISummaryCards";

export function Card({
	label,
	icon,
	value,
	type,
	isoCode
}: SummaryCard) {
		
	const formatValue = (value: number) => {
		if(type === "currency") {
			const isNegative = value < 0;

			const formattedValue = formatNumber(value, {
				style: type,
				currency: isoCode,
			});

			return isNegative ? `R$ -${formattedValue.slice(3)}` : formattedValue;
		}
	}
	
	return (
		<div id="card-container">
			{icon && <Icon icon={icon} />}
			<div id="card-content">
				<p id="card-title">{label}</p>
				<span>
					{type === "percent" ?
						value.toFixed(2).replace('.', ',') + '%'
						:
					type === "currency" ?
						formatValue(value)
						: 
						value
					}
				</span>
			</div>
		</div>
	)
}