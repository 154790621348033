import { useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FilterBase } from "../../../../../../../components/filter/FilterBase";
import { IFilter } from "../../../../../../../components/filter/IFilter";

export function Filter({
    id,
    value,
    setValue,
    title,
    type,
    searchPlaceholder,
    hasSearch,
    buttonStyles,
    headerStyles,
    popoverStyles,
    link,
    params,
    data,
    datepicker,
    hasSelectNone,
    footer,
    defaultCheckboxSelected
}: IFilter) {
    const [hasPagination, setHasPagination] = useState(false)
    const [isFetching, setIsFetching] = useState(true)
    const [dataToFilter, setDataToFilter] = useState([])

    useEffect(() => {
        if (!datepicker && link) {
            ServiceCaller.doRequest({
                type: RequestType.GET,
                url: `${link}?locale={locale}&client={client}&user={user}&${params ? params : ''}`,
            }, handleAddFilter)
        } else {
            if (!data) return
            handleAddFilter(data)
        }
    }, [data])

    function handleAddFilter(data) {
        if (data.length > 100) {
            setHasPagination(true)
        }

        setDataToFilter(data);
        setIsFetching(false)
    }

    return (
        <FilterBase
            id={id}
            value={value}
            setValue={setValue}
            title={title}
            type={type}
            searchPlaceholder={searchPlaceholder}
            hasSearch={hasSearch ? hasSearch : data.length > 6 ? true : false}
            datepicker={datepicker}
            buttonStyles={buttonStyles}
            headerStyles={headerStyles}
            popoverStyles={popoverStyles}
            key={title}
            hasPagination={hasPagination}
            allFilterOptions={dataToFilter}
            hasSelectNone={!!hasSelectNone}
            isFetching={isFetching}
            footer={footer}
            defaultCheckboxSelected={defaultCheckboxSelected}
        />
    )
}
