import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

import {
	Button,
	Checkbox,
	Input
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { Option } from "../IOptionsFilter";
import i18n from "util/base/i18n";
import StringUtil from "util/StringUtil";

interface Props {
	allOptions: Option[],
	checkedValues: number[]
	optionSelected: Function
}

export default function ChecboxFilter(props: Props) {

	const [optionsToShow, setOptionsToShow]: [Option[], Function] = useState(props.allOptions);

	const [inputValue, setInputValue]: [string, Function] = useState();

	const [checkedValues, setCheckedValues]: [number[], Function] = useState(props.checkedValues);

	const [indeterminate, setIndeterminate]: [boolean, Function] = useState(checkedValues.length > 0 && checkedValues.length < optionsToShow.length);

	const [checkAll, setCheckAll]: [boolean, Function] = useState(checkedValues.length === optionsToShow.length);

	useEffect(() => {
		setOptionsToShow(props.allOptions);
	}, [props.allOptions]);

	const onChangeCheckbox = (ids: number[]) => {
		setIndeterminate(ids.length > 0 && ids.length < optionsToShow.length);
		setCheckAll(ids.length === optionsToShow.length);

		props.optionSelected(ids);
		setCheckedValues(ids);
	}

	const onSearch = (e: { target: { value: string; }; }) => {
		let newInputValue: string = e.target.value;

		setInputValue(newInputValue);

		newInputValue = StringUtil.getWithoutSpecials(newInputValue);

		let options;

		if (newInputValue !== "") {
			options = props.allOptions.filter(({ name }) => StringUtil.getWithoutSpecials(name).includes(newInputValue));
		} else {
			options = props.allOptions;
		}

		setOptionsToShow(options);
		setIndeterminate(checkedValues.length > 0 && checkedValues.length < options.length);
		setCheckAll(checkedValues.length === options.length);
	}

	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		const checkedIds: number[] = e.target.checked ? optionsToShow.map(({id}) => id) : [];

		setIndeterminate(false);
		setCheckAll(e.target.checked);

		props.optionSelected(checkedIds);
		setCheckedValues(checkedIds);
	};

	return (<>
		<Input.Group>
			<Input value={inputValue} onChange={onSearch} />
			<Button icon={<Icon icon="ant-design:search-outlined" />} />
		</Input.Group>

		<Checkbox  indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>{ i18n.t<string>("select_all") }</Checkbox>

		<Checkbox.Group value={checkedValues} options={optionsToShow.map(({ name, id }) => { return { label: name, value: id } })} onChange={onChangeCheckbox} />
	</>
	)
}