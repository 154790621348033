import { FormattedNumber } from "react-intl";
import i18n from "util/base/i18n";
import { RevenueItemTable } from "../IRevenue";

export function findValueByRow(record: RevenueItemTable, month: string) {
    if (record && record.dates) {
        let row = record.dates[month];
        if (row && row.total) {
            let actualRow = row.total;
            if (actualRow && actualRow.value) {
                return (
                    <span style={{
                        fontSize: 14
                    }}>
                        {record.formatType === "DECIMAL" ? (
                            <>
                                {new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                    compactDisplay: "long",
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                }).format(row && row.value ? row.value : 0)}
                            </>
                        ) : (
                            <FormattedNumber
                                value={actualRow.value}
                                style={`currency`}
                                currencyDisplay="symbol"
                                currency={record.isoCode}
                                minimumFractionDigits={2}
                                maximumFractionDigits={4}
                            />
                        )}
                    </span>
                )
            } else {
                return (
                    <span style={{
                        fontSize: 14
                    }}>
                        {record.formatType === "DECIMAL" ? (
                            <>
                                {new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                    compactDisplay: "long",
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                }).format(row && row.value ? row.value : 0)}
                            </>
                        ) : (
                            <FormattedNumber
                                value={0}
                                style={`currency`}
                                currencyDisplay="symbol"
                                currency={record.isoCode}
                                minimumFractionDigits={2}
                                maximumFractionDigits={4}
                            />
                        )}
                    </span>
                )
            }
        } else {
            return (
                <span style={{
                    fontSize: 14
                }}>
                    {record.formatType === "DECIMAL" ? (
                        <>
                            {new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                compactDisplay: "long",
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                            }).format(row && row.value ? row.value : 0)}
                        </>
                    ) : (
                        <FormattedNumber
                            value={row && row.value ? row.value : 0}
                            style={`currency`}
                            currencyDisplay="symbol"
                            currency={record.isoCode}
                            minimumFractionDigits={2}
                            maximumFractionDigits={4}
                        />
                    )}
                </span>
            )
        }
    } else {
        return (
            <span style={{
                fontSize: 14
            }}>
                <FormattedNumber
                    value={0}
                    style={`currency`}
                    currencyDisplay="symbol"
                    currency={record.isoCode}
                    minimumFractionDigits={2}
                    maximumFractionDigits={4}
                />
            </span>
        )
    }
}
