import i18n from "util/base/i18n";
import { getColumnComparative } from "./getColumn";

export function buildColumnComparative(monthFormatted, monthFormatDataIndex, reportVisualizationFilters, rowsFromBackend) {
    const plannedValue = getColumnComparative({
        index: monthFormatDataIndex,
        title: i18n.t<string>('planned'),
        render: "plannedValue",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
    });

    const accomplishedValue = getColumnComparative({
        index: monthFormatDataIndex,
        title: i18n.t<string>('accomplished'),
        render: "accomplishedValue",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
    });

    const variableValue = getColumnComparative({
        index: monthFormatDataIndex,
        title: i18n.t<string>('variation'),
        render: "variableValue",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
    });

    const variablePercente = getColumnComparative({
        index: monthFormatDataIndex,
        title: (i18n.t<string>('variation') + " %"),
        render: "variablePercente",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
    });

    return [plannedValue, accomplishedValue, variableValue, variablePercente];
}
