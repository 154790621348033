import { FormattedNumber } from "react-intl";
import { CellContent } from "../../../CellContent";

interface IGetColumnComparativeProps {
    index: number;
    title: string;
    render: string;
    reportVisualizationFilters: any;
    rowsFromBackend: any;
    orgId?: number;
}

function findCurrentRow(record, rowsFromBackend) {
    if (record?.formulaValueType) {
        return rowsFromBackend.find(columnFromBack =>
            columnFromBack.id === record.levelId &&
            columnFromBack.isFragment &&
            record.levelKey === columnFromBack.levelKey
        );
    } else if (record.levelKey) {
        return rowsFromBackend.find(columnFromBack =>
            columnFromBack.id === record.levelId &&
            columnFromBack.isDetailPlan &&
            record.levelKey === columnFromBack.levelKey
        );
    } else {
        return rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
    }
}

function getTextDecoration(style) {
    let textDecoration = "";
    if (style?.isScratched) textDecoration += "line-through ";
    if (style?.isUnderlined) textDecoration += "underline";
    return textDecoration;
}

function renderCellContent({ currentRow, periodCell, render, reportVisualizationFilters, textDecoration, isoCode, orgId }) {
    if (currentRow?.isTitle) {
        return <CellContent currentRow={currentRow} textDecoration={textDecoration} />;
    }

    const organization = currentRow?.totalOrganizations.find(org => org.id === orgId);
    let valueFinal: number = 0;
    if (render === 'variablePercente' && organization?.variablePercente) {
        valueFinal = organization.variablePercente / 100;
    }

    if(organization?.[render]) {
        valueFinal = reportVisualizationFilters.showThousand ? organization[render] / 1000 : organization[render];
    }

    const style = render === 'variablePercente' ? 'percent' : 'decimal';

    return (
        <CellContent currentRow={currentRow} textDecoration={textDecoration}>
            <FormattedNumber
                value={valueFinal}
                style={style}
                currencyDisplay={style === 'decimal' ? "symbol" : undefined}
                currency={style === 'decimal' ? isoCode : undefined}
                minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
            />
        </CellContent>
    );
}

export function getColumnComparative({ index, title, render, reportVisualizationFilters, rowsFromBackend, orgId }: IGetColumnComparativeProps) {
    const dataIndex = render;

    return {
        title,
        editable: false,
        align: 'center',
        dataIndex,
        width: 85,
        render: (text, record) => {
            const currentRow = findCurrentRow(record, rowsFromBackend);
            const periodCell = currentRow?.columnValues?.find(cell => cell.period === index) || null;
            const textDecoration = getTextDecoration(record?.style);

            return renderCellContent({
                currentRow,
                periodCell,
                render,
                reportVisualizationFilters,
                textDecoration,
                isoCode: record.isoCode,
                orgId
            });
        },
    };
};
