import { Form, FormInstance, Input, Modal, Table, Tabs, InputRef } from "antd";
import i18n from "util/base/i18n";
import { useContext, useEffect, useRef, useState } from "react";
import * as React from "react";
import { Icon } from "@iconify/react";
import { IFieldManingModal } from "../../IGroupings";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Notification } from "components/notification/Notification";
import { handleErrorRequest } from "util/functions/handleErrorRequest";

interface IFieldManingTable {
    key: string;
    accountLevel: string;
    nomenclature: string;
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: IFieldManingTable;
    handleSave: (record: IFieldManingTable) => void;
}

export interface EditableRowProps {
    index: number;
}

export type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;
export type Column = ColumnTypes[any] & {
    editable?: boolean;
    dataIndex?: string | string[];
    children?: Column[];
};

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState<boolean>(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (dataIndex) {
            if (editing && inputRef?.current) {
                console.log(inputRef.current);
                inputRef.current.focus();
            }

            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }
    }, [editing, record, dataIndex, form]);

    const toggleEdit = () => {
        if (editable) {
            setEditing(true);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            setEditing(false);
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <div
                onClick={toggleEdit}
                style={{ display: "flex", alignItems: "center" }}>
                {children}
            </div>
        ) : (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
            >
                <Input
                    ref={inputRef}
                    onPressEnter={save}
                    onBlur={save}
                    suffix={<Icon icon="icomoon-free:pencil" />}
                />
            </Form.Item>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export function FieldManingModal({
    accountData,
    costCenterData,
    open,
    handleCancel,
    fetchCostCenterLevelsHierarchy,
    fetchAccountLevelsHierarchy
}: IFieldManingModal) {
    const [currentTree, setCurrentTree] = useState<"account-hierarchy" | "cost-center-hierarchy">("account-hierarchy");
    const [originalData, setOriginalData] = useState<IFieldManingTable[]>([]);
    const [data, setData] = useState<IFieldManingTable[]>([]);
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(true);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        const updateLevels = (nodes, parentLevel = 0) => {
            nodes.forEach(node => {
                node.level = parentLevel;
                if (node.children && node.children.length > 0) {
                    updateLevels(node.children, parentLevel + 1);
                }
            });
        };

        const flattenData = (nodes) => {
            const levelsMap = new Map();

            const traverse = (node) => {
                if (node.level != null) {
                    levelsMap.set(node.level, node.nomenclature || "");
                }

                if (node.children && node.children.length > 0) {
                    node.children.forEach((child) => traverse(child));
                }
            };

            nodes.forEach((node) => traverse(node));

            return Array.from(levelsMap.entries())
                .sort(([levelA], [levelB]) => levelA - levelB)
                .map(([level, nomenclature], index) => ({
                    key: index.toString(),
                    accountLevel: level.toString() || null,
                    nomenclature: nomenclature
                }));
        };

        const dataToProcess = currentTree === "account-hierarchy" ? accountData : costCenterData;

        if (currentTree === "cost-center-hierarchy") {
            updateLevels(dataToProcess);
        }

        const flatData = flattenData(dataToProcess);

        setData(flatData);
        setOriginalData(flatData);
    }, [accountData, costCenterData, currentTree]);



    const handleSave = (row: IFieldManingTable) => {
        const newData = [...data];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);

        const originalItem = originalData.find(o => o.key === row.key);
        if (originalItem && originalItem.nomenclature !== row.nomenclature) {
            setButtonDisabled(true);
        }
    };

    const getColumns = () => {
        const title = currentTree === "account-hierarchy"
            ? i18n.t("groupings_texts.account_level")
            : i18n.t("groupings_texts.cost_center_level");

        return [
            {
                title: title,
                dataIndex: "accountLevel",
                key: "accountLevel",
                width: '50%',
                editable: false,
            },
            {
                title: i18n.t("groupings_texts.nomenclature"),
                dataIndex: "nomenclature",
                key: "nomenclature",
                editable: true,
                width: '50%',
            },
        ];
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = getColumns().map(col => ({
        ...col,
        onCell: (record: IFieldManingTable) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
        }),
    }));

    function doShowNotification(type: "account-hierarchy" | "cost-center-hierarchy") {
        const message = type === "account-hierarchy"
            ? i18n.t("groupings_texts.successfully_saved_account_hierarchy")
            : i18n.t("groupings_texts.successfully_saved_cost_center_hierarchy");

        Notification({
            type: "success",
            message: message
        });
    }

    function handleSaveMomeclature() {

        const changes = data.reduce((acc: { [key: number]: string }, item: IFieldManingTable) => {
            const originalItem = originalData.find(o => o.key === item.key);
            if (originalItem && originalItem.nomenclature !== item.nomenclature) {
                acc[item.accountLevel] = item.nomenclature;
            }
            return acc;
        }, {});

        const bodyParams = {
            namesByLevel: changes
        };
        const url = currentTree === "account-hierarchy"
            ? `/budget-base/account-hierarchy/create-nomenclature-level`
            : `/budget-base/cost-center-hierarchy/create-nomenclature-level`;

        ServiceCaller.doRequest(
            {
                type: RequestType.PUT,
                url: url,
                params: bodyParams
            },
            () => {
                setIsSaveEnabled(true);
                doShowNotification(currentTree);
            },
            (err) => {
                setIsSaveEnabled(true);
                handleErrorRequest(err);
            }
        );

        const fetchLevelsHierarchy = currentTree === "account-hierarchy"
            ? fetchAccountLevelsHierarchy
            : fetchCostCenterLevelsHierarchy;

        fetchLevelsHierarchy();
        setOriginalData(data);
        setCurrentTree("account-hierarchy");
        setButtonDisabled(false);
        handleCancel();
    }

    const resetData = () => {
        const resetData = originalData.map(item => ({ ...item, nomenclature: item.nomenclature || "" }));
        setData(resetData);
    };

    return (
        <Modal
            getContainer={document.querySelector<HTMLElement>(".budget-groupings")}
            className={"hierarchy-nomenclature-modal-container"}
            width={800}
            title={i18n.t("groupings_texts.field_naming")}
            open={open}
            onOk={handleSaveMomeclature}
            onCancel={() => {
                resetData();
                setButtonDisabled(false);
                setCurrentTree("account-hierarchy");
                handleCancel();
            }}
            centered
            okButtonProps={{
                disabled: !buttonDisabled,
                loading: !isSaveEnabled
            }}
        >
            <Tabs
                onChange={(key) => setCurrentTree(key as "account-hierarchy" | "cost-center-hierarchy")}
                centered
                activeKey={currentTree}
                className="gs-tab"
                type="card"
                moreIcon={null}
            >
                <Tabs.TabPane tab={i18n.t("ledger_account")} key="account-hierarchy">
                    <Table
                        className="gs-table"
                        components={components}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        bordered
                        scroll={{ x: true, y: 300 }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("cost_center")} key="cost-center-hierarchy">
                    <Table
                        className="gs-table"
                        components={components}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        bordered
                        scroll={{ x: true, y: 300 }}
                    />
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
}