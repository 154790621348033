import i18n from "util/base/i18n";
import { useContext } from "react";

import { AttributeParameterizationContext } from "../context/AttributeParameterizationContext";
import { RuleModalProvider } from "../context/RuleModalContext";
import { DeleteAttributeModal, EditAttributeModal, NewAttributeModal } from "./modals/attribute";

import { ModalFlexFieldsModule, ModalType } from "./modals/rule/components/IModalFactory";
import NewRuleModalFactory from "./modals/rule/NewRuleModalFactory";
import EditRuleModalFactory from "./modals/rule/EditRuleModalFactory";

export function Modals() {
    const {
        isNewAttributeModalOpen,
        isEditAttributeModalOpen,
        editAttributeInfo,
        isNewRuleModalOpen,
        isNewRuleModalDFOpen,
        isEditRuleModalOpen,
        isEditRuleModalDFOpen,
        isDeleteAttributeModalOpen,
        labelDeleteAttributeModal,
        isNewRuleModalFixedMarketingCostOpen,
        isEditRuleModalFixedMarketingCostOpen,
    } = useContext(AttributeParameterizationContext);

    return (
        <RuleModalProvider>
            {isNewRuleModalOpen && (
                <NewRuleModalFactory
                    modalType={ModalType.STANDARD}
                    modalTitle={i18n.t<string>("new_rule")}
                    flexFieldsModule={ModalFlexFieldsModule.REVENUE}
                />
            )}
            {isEditRuleModalOpen && (
                <EditRuleModalFactory
                    modalType={ModalType.STANDARD}
                    modalTitle={i18n.t<string>("edit_rule")}
                    flexFieldsModule={ModalFlexFieldsModule.REVENUE}
                />
            )}

            {isNewRuleModalDFOpen && (
                <NewRuleModalFactory
                    modalType={ModalType.FIXED_EXPENSE}
                    modalTitle={i18n.t<string>("new_rule")}
                    flexFieldsModule={ModalFlexFieldsModule.REVENUE_AND_FIXED_EXPENSE}
                />
            )}
            {isEditRuleModalDFOpen && (
                <EditRuleModalFactory
                    modalType={ModalType.FIXED_EXPENSE}
                    modalTitle={i18n.t<string>("edit_rule")}
                    flexFieldsModule={ModalFlexFieldsModule.REVENUE_AND_FIXED_EXPENSE}
                />
            )}

            {isNewRuleModalFixedMarketingCostOpen && (
                <NewRuleModalFactory
                    modalType={ModalType.STANDARD_WITH_FORMULA}
                    modalTitle={i18n.t<string>("new_rule")}
                    flexFieldsModule={ModalFlexFieldsModule.REVENUE}
                />
            )}
            {isEditRuleModalFixedMarketingCostOpen && (
                <EditRuleModalFactory
                    modalType={ModalType.STANDARD_WITH_FORMULA}
                    modalTitle={i18n.t<string>("edit_rule")}
                    flexFieldsModule={ModalFlexFieldsModule.REVENUE}
                />
            )}

            {isNewAttributeModalOpen && <NewAttributeModal />}
            {isEditAttributeModalOpen && editAttributeInfo !== null && <EditAttributeModal data={editAttributeInfo} />}
            {isDeleteAttributeModalOpen && <DeleteAttributeModal description={labelDeleteAttributeModal} />}
        </RuleModalProvider>
    );
}
