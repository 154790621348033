import { Space } from "antd";
import { Filter } from "components/filter/Filter"
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton";
import { useContext } from "react";
import i18n from "util/base/i18n"
import StringUtil from "util/StringUtil";
import { ConsolidationEliminationContext } from "../consolidationElimination/context/ConsolidationEliminationContext";

export default function Filters({
    selectedRows,
    organizationsOptions,
    flexOrganizationOptions,
    currentStep,
    organizationSelected,
    setOrganizationSelected,
    flexOrganizationSelected,
    setFlexOrganizationSelected,
    isFetchingOrganizations,
    isFetchingFlexOrganizations
}) {

    const {
        scenarioOptions,
        isScenarioLoading,
        scenarioSelected,
        setScenarioSelected
    } = useContext(ConsolidationEliminationContext);

    return (
        <div style={{ marginLeft: "10px" }}>
            {(isScenarioLoading || isFetchingOrganizations || isFetchingFlexOrganizations) ?
                <FilterSkeleton
                    direction='row'
                    active
                    amount={4}
                    gap={10}
                    cardClassName='filter-card-skeleton'
                />
                :
                <div style={{ display: "flex" }}>
                    {currentStep == 1 &&
                        <>
                            <Filter
                                id="organization-filter"
                                title={i18n.t("organization")}
                                type="radio"
                                hasSearch
                                searchPlaceholder={i18n.t("search")}
                                value={organizationSelected}
                                setValue={(value) => {
                                    setOrganizationSelected(value)
                                }}
                                data={organizationsOptions}
                            />
                            <Filter
                                id="flex-organization-filter"
                                title={i18n.t("company_flex")}
                                type="radio"
                                hasSearch
                                searchPlaceholder={i18n.t("search")}
                                value={flexOrganizationSelected}
                                setValue={(value) => {
                                    setFlexOrganizationSelected(value)
                                }}
                                data={flexOrganizationOptions}
                            />
                        </>
                    }
                    <Filter
                        id="scenario-filter"
                        title={i18n.t("scenario")}
                        type="radio"
                        hasSearch
                        searchPlaceholder={i18n.t("search")}
                        value={scenarioSelected}
                        setValue={(value) => {
                            setScenarioSelected(value)
                        }}
                        data={scenarioOptions}
					/>
                </div>
            }
        </div>
    )
}
