import { Button, Checkbox, Form, Table, Tabs } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { PermissionItem, TableData } from "../IItemSecurity";
import { useUserContext } from "context/UserContext";
import { Notification } from "components/notification/Notification";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { usePermissionContext } from "context/PermissionContext";
import ItemSecurityTable from "./ItemSecurityTable";

interface Props {
    securityDataURL: "field-security" | "professional-category-security";
    getTableDataURL: "fields/findAll" | "professional-category";
    selectedSideTab: "user" | "group";
    selectedContentTab: "fields" | "professional-category";
    selectedSideKey: number;
    searchValue: string;
}
export default function Content({
    securityDataURL,
    selectedSideTab,
    selectedSideKey,
    getTableDataURL,
    selectedContentTab,
    searchValue,
}: Props) {
    const [isFetching, setIsFetching] = useState(true);
    const [checkedAllEdit, setCheckedAllEdit] = useState(false);
    const [checkedAllView, setCheckedAllView] = useState(false);
    const [checkedAllBlocked, setCheckedAllBlocked] = useState(false);
    const [tableData, setTableData] = useState<TableData[]>([]);
    const [initialList, setInitialList] = useState([]);
    const [selectedTab, setSelectedTab] = useState<
        "INITIAL_INFORMATION" | "TRANSACTIONS" | "CUSTOM" | "LEGAL_TAXES" | "RESULT_QUANTITY" | "RESULT_VALUES"
    >("INITIAL_INFORMATION");
    const { functionalityPermissions } = usePermissionContext();
    const { userInfo } = useUserContext();

    useEffect(() => {
        if (selectedSideKey === 0) return;
        if (tableData.length > 0) {
            fetchPermissionsByItem(initialList);
        } else {
            ServiceCaller.doRequest(
                {
                    type: RequestType.GET,
                    url: `/human-resources/${getTableDataURL}?scenario={scenario}&organization={organization}`,
                },
                (data) => fetchPermissionsByItem(data)
            );
        }
    }, [selectedSideKey, getTableDataURL, selectedSideTab]);

    useEffect(() => {
        if (selectedContentTab === "professional-category") {
            setCheckedAllEdit(tableData.every((item) => item.canEdit));
            setCheckedAllView(tableData.every((item) => item.canView));
            setCheckedAllBlocked(tableData.every((item) => item.isBlocked));
        } else {
            if (selectedTab && tableData) {
                setCheckedAllEdit(
                    tableData.filter((item) => item.inputGroup == selectedTab).every((item) => item.canEdit)
                );
                setCheckedAllView(
                    tableData.filter((item) => item.inputGroup == selectedTab).every((item) => item.canView)
                );
                setCheckedAllBlocked(
                    tableData.filter((item) => item.inputGroup == selectedTab).every((item) => item.isBlocked)
                );
            }
        }
    }, [selectedSideKey, selectedSideTab, selectedContentTab, selectedTab, tableData]);

    function fetchPermissionsByItem(tableData) {
        setInitialList(tableData);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/human-resources/${securityDataURL}/get/${selectedSideTab}/${selectedSideKey}`,
            },
            (data) => buildTableData(tableData, data)
        );
    }

    function buildTableData(contentData: any[], permissionItemList: PermissionItem[]) {
        const updatedTableData: TableData[] = contentData.map((item) => {
            const selected =
                getTableDataURL === "fields/findAll"
                    ? permissionItemList.find((itemTarget) => itemTarget.fieldId === item.id)
                    : permissionItemList.find((itemTarget) => itemTarget.professionalCategoryId === item.id);

            console.log(selected);

            let dataToReturn = {
                key: item.id,
                canEdit: selected?.editable ? true : false,
                canView: selected?.viewable ? true : false,
                isBlocked: !selected?.editable && !selected?.viewable ? (selected != undefined) : false,
                scenarioId: userInfo?.selection.scenarioId ?? null,
            };

            if (getTableDataURL === "fields/findAll") {
                Object.assign(dataToReturn, { description: item.description });
                Object.assign(dataToReturn, { inputGroup: item.inputGroup });
            } else {
                Object.assign(dataToReturn, { description: item.name });
            }

            return dataToReturn;
        });
        setTableData(updatedTableData);
        setIsFetching(false);
    }

    function handleSave() {
        const dataToSave = tableData.map((item) => {
            let itemToSave = {
                viewable: item.canView,
                editable: item.canEdit,
                blocked: item.isBlocked,
                scenarioId: userInfo.selection.scenarioId ?? null,
            };

            if (selectedSideTab === "group") {
                Object.assign(itemToSave, { groupId: selectedSideKey });
            } else {
                Object.assign(itemToSave, { userId: selectedSideKey });
            }

            if (securityDataURL === "field-security") {
                Object.assign(itemToSave, { fieldId: item.key });
            } else {
                Object.assign(itemToSave, { professionalCategoryId: item.key });
            }

            return itemToSave;
        });
        ServiceCaller.doRequest(
            {
                type: RequestType.PUT,
                url: `/human-resources/${securityDataURL}/update/${selectedSideTab}`,
                params: dataToSave,
            },
            () => {
                Notification({
                    type: "success",
                    message: i18n.t<string>("successfully_saved"),
                });
            },
            handleErrorRequest
        );
    }

    function handleCancel() {
        const newData = tableData.map((item) => ({
            ...item,
            canView: false,
            canEdit: false,
        }));
        setTableData(newData);
    }

    let filteredData = tableData.filter((item) => item.description.toLowerCase().includes(searchValue.toLowerCase()));

    return (
        <>
            {getTableDataURL !== "fields/findAll" ? (
                <>
                    <ItemSecurityTable
                        functionalityPermissions={functionalityPermissions}
                        checkedAllView={checkedAllView}
                        checkedAllEdit={checkedAllEdit}
                        checkedAllBlocked={checkedAllBlocked}
                        filteredData={filteredData}
                        isFetching={isFetching}
                        handleSave={handleSave}
                        tableData={tableData}
                        setTableData={setTableData}
                        setCheckedAllEdit={setCheckedAllEdit}
                        setCheckedAllView={setCheckedAllView}
                        setCheckedAllBlocked={setCheckedAllBlocked}
                        selectedTab="PROFESSIONAL_CATEGORY"
                    />
                </>
            ) : (
                <Tabs
                    centered
                    activeKey={selectedTab}
                    onChange={(
                        key:
                            | "INITIAL_INFORMATION"
                            | "TRANSACTIONS"
                            | "CUSTOM"
                            | "LEGAL_TAXES"
                            | "RESULT_QUANTITY"
                            | "RESULT_VALUES"
                    ) => setSelectedTab(key)}
                    className="tabs-sider tabs-table gs-tab"
                    type="card"
                    moreIcon=""
                >
                    <Tabs.TabPane tab={i18n.t("human_resources.initial_information")} key="INITIAL_INFORMATION">
                        <ItemSecurityTable
                            functionalityPermissions={functionalityPermissions}
                            checkedAllView={checkedAllView}
                            checkedAllEdit={checkedAllEdit}
                            checkedAllBlocked={checkedAllBlocked}
                            filteredData={filteredData.filter((item) => item.inputGroup == "INITIAL_INFORMATION")}
                            isFetching={isFetching}
                            handleSave={handleSave}
                            tableData={tableData}
                            setTableData={setTableData}
                            setCheckedAllEdit={setCheckedAllEdit}
                            setCheckedAllView={setCheckedAllView}
                            setCheckedAllBlocked={setCheckedAllBlocked}
                            selectedTab={selectedTab}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("human_resources.movements_and_adjustments")} key="TRANSACTIONS">
                        <ItemSecurityTable
                            functionalityPermissions={functionalityPermissions}
                            checkedAllView={checkedAllView}
                            checkedAllEdit={checkedAllEdit}
                            checkedAllBlocked={checkedAllBlocked}
                            filteredData={filteredData.filter((item) => item.inputGroup == "TRANSACTIONS")}
                            isFetching={isFetching}
                            handleSave={handleSave}
                            tableData={tableData}
                            setTableData={setTableData}
                            setCheckedAllEdit={setCheckedAllEdit}
                            setCheckedAllView={setCheckedAllView}
                            setCheckedAllBlocked={setCheckedAllBlocked}
                            selectedTab={selectedTab}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("additional_fields")} key="CUSTOM">
                        <ItemSecurityTable
                            functionalityPermissions={functionalityPermissions}
                            checkedAllView={checkedAllView}
                            checkedAllEdit={checkedAllEdit}
                            checkedAllBlocked={checkedAllBlocked}
                            filteredData={filteredData.filter((item) => item.inputGroup == "CUSTOM")}
                            isFetching={isFetching}
                            handleSave={handleSave}
                            tableData={tableData}
                            setTableData={setTableData}
                            setCheckedAllEdit={setCheckedAllEdit}
                            setCheckedAllView={setCheckedAllView}
                            setCheckedAllBlocked={setCheckedAllBlocked}
                            selectedTab={selectedTab}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("human_resources.legal_fees")} key="LEGAL_TAXES">
                        <ItemSecurityTable
                            functionalityPermissions={functionalityPermissions}
                            checkedAllView={checkedAllView}
                            checkedAllEdit={checkedAllEdit}
                            checkedAllBlocked={checkedAllBlocked}
                            filteredData={filteredData.filter((item) => item.inputGroup == "LEGAL_TAXES")}
                            isFetching={isFetching}
                            handleSave={handleSave}
                            tableData={tableData}
                            setTableData={setTableData}
                            setCheckedAllEdit={setCheckedAllEdit}
                            setCheckedAllView={setCheckedAllView}
                            setCheckedAllBlocked={setCheckedAllBlocked}
                            selectedTab={selectedTab}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("human_resources.result_quantity")} key="RESULT_QUANTITY">
                        <ItemSecurityTable
                            functionalityPermissions={functionalityPermissions}
                            checkedAllView={checkedAllView}
                            checkedAllEdit={checkedAllEdit}
                            checkedAllBlocked={checkedAllBlocked}
                            filteredData={filteredData.filter((item) => item.inputGroup == "RESULT_QUANTITY")}
                            isFetching={isFetching}
                            handleSave={handleSave}
                            tableData={tableData}
                            setTableData={setTableData}
                            setCheckedAllEdit={setCheckedAllEdit}
                            setCheckedAllView={setCheckedAllView}
                            setCheckedAllBlocked={setCheckedAllBlocked}
                            selectedTab={selectedTab}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("human_resources.result_values")} key="RESULT_VALUES">
                        <ItemSecurityTable
                            functionalityPermissions={functionalityPermissions}
                            checkedAllView={checkedAllView}
                            checkedAllEdit={checkedAllEdit}
                            checkedAllBlocked={checkedAllBlocked}
                            filteredData={filteredData.filter((item) => item.inputGroup == "RESULT_VALUES")}
                            isFetching={isFetching}
                            handleSave={handleSave}
                            tableData={tableData}
                            setTableData={setTableData}
                            setCheckedAllEdit={setCheckedAllEdit}
                            setCheckedAllView={setCheckedAllView}
                            setCheckedAllBlocked={setCheckedAllBlocked}
                            selectedTab={selectedTab}
                        />
                    </Tabs.TabPane>
                </Tabs>
            )}
        </>
    );
}
