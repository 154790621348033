import { Form } from "antd";
import { Notification } from "components/notification/Notification";
import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import LinkAccountsModal from "./components/LinkAccountsModal";
import LinkAccountTable from "./components/LinkAccountsTable";
import { LinkAccountsTableData, ValueLinkForm } from "./ILinkAccounts";
import './style.sass';
import { useUserContext } from "context/UserContext";
import { listIcons } from "@iconify/react";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { ColumnsType } from "antd/lib/table";
import { sortList } from "util/functions/sortList";
import { cloneDeep } from "lodash";

export default function LinkAccounts() {

    const [tableData, setTableData] = useState<LinkAccountsTableData[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNewLink, setIsNewLink] = useState(true);
    const [accountData, setAccountData] = useState([]);
    const [accountValueOption, setAccountValueOption] = useState([]);
    const [isFetchingAccountOption, setIsFetchingAccountOption] = useState(true);
    const [fixedExpenseData, setFixedExpenseData] = useState([]);
    const [isFetchingFixedExpenseData, setIsFetchingFixedExpenseData] = useState(true);
    const [costCategoryData, setCostCategoryData] = useState([]);
    const [costCategoryOption, setCostCategoryOption] = useState([]);
    const [isFetchingCostCategoryOption, setIsFetchingCostCategoryOption] = useState(true);
    const [newLinkList, setNewLinkList] = useState([]);
    const [form] = Form.useForm();
    const { userInfo } = useUserContext()
    const [filterdTableData, setFilterdTableData] = useState([]);
    const [disabledField, setDisabledField] = useState(false);
    const [isFetchingFlexFieldOptions, setIsFetchingFlexFieldOptions] = useState(true);
    const [flexFieldValuesOptions, setFlexFieldValuesOptions] = useState([]);
    const [flexFieldValuesData, setFlexFieldValuesData] = useState([]);
    const [isFlexFieldDisabled, setIsFlexFieldDisabled] = useState(true);
    const [filteredFixedExpensesOptions, setFilteredFixedExpensesOptions] = useState([]);
    const [isCostCategoryDisabled, setIsCostCategoryDisabled] = useState(flexFieldValuesData.length === 0);

    const tableColumns: ColumnsType = [
        {
            title: i18n.t<string>("linkAccount.account_code"),
            dataIndex: "resourceAccountExternalCode",
            key: "resourceAccountExternalCode",
            align: "center",
            width: 200,
        },
        {
            title: i18n.t<string>("budget.permissions.accounting"),
            dataIndex: "resourceAccountName",
            key: "resourceAccountName",
            align: "left",
            width: 400,
            className: 'account-registration-columns'
        },
        {
            title: i18n.t<string>("linkAccount.fixed_expense_code"),
            dataIndex: "accountingAccountExternalCode",
            key: "accountingAccountExternalCode",
            align: "center",
            width: 200
        },
        {
            title: i18n.t<string>("linkAccount.fixed_expense_accounts"),
            dataIndex: "accountingAccountName",
            key: "accountingAccountName",
            align: "left",
            width: 400,
            className: 'account-registration-columns',
        },
        {
            title: i18n.t<string>("linkAccount.linked_cost_category"),
            dataIndex: "costCategories",
            key: "costCategories",
            align: "left",
            width: 600,
            className: 'account-registration-columns',
            render: (costCategories) => costCategories.map((item) => `${item.costCategoryName}; `)
        },
        {
            title: "Campo Flexível",
            dataIndex: "flexFieldValueDescription",
            key: "flexFieldValueDescription",
            align: "left",
            width: 400,
            className: 'account-registration-columns',
        }
    ];

    const tableDataToExport = cloneDeep(tableData).map(item => ({
        ...item,
        costCategories: item.costCategories.map(param => param.costCategoryName)
    }))

    const tableColumnToExport = cloneDeep(tableColumns).map(column => {
        delete column.render
        return column
    })

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/human-resources/cost-category?scenario={scenario}&organization={organization}",
            useProxy: true
        }, onLoadCostCategoryOptions.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/human-resources/resource-account/find-all?organizationId={organization}`,
            useProxy: true
        }, onLoadAccountOptions.bind(this))

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/human-resources/resource-account-link?localeId={locale}&clientId={client}&organizationId={organization}&userId={user}"
        }, onLoadLinkAccountsTableData.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/accounting-account?locale={locale}&client={client}&organization={organization}&bu={businessUnit}`,
            useProxy: true
        }, onLoadFixedExpenseOptions.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/flex-field-value/resource-link?clientId={client}`,
            useProxy: true
        }, onLoadFlexFieldOptions.bind(this));

    }, []);

    useEffect(() => {
        setFilterdTableData(tableData);
    }, [tableData]);

    function onLoadFlexFieldOptions(data) {
        if (data.length === 0) {
            setFlexFieldValuesOptions([]);
            setFlexFieldValuesData([]);
            setIsFetchingFlexFieldOptions(false);
            return;
        };
        const options = data.map(res => {
            return {
                value: res.id,
                label: res.externalCode + ' - ' + res.description
            };
        });

        setFlexFieldValuesData(data);
        setFlexFieldValuesOptions(options);
        setIsFetchingFlexFieldOptions(false);
    };

    function onLoadLinkAccountsTableData(data) {
        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };

        const updateTableDate = data.map(res => ({
            ...res,
            key: res.id,

        })).sort((a, b) => sortList(a.resourceAccountExternalCode, b.resourceAccountExternalCode));

        setTableData(updateTableDate);
        setIsFetching(false);
    };

    function onLoadAccountOptions(data) {
        if (data.length === 0) {
            setAccountValueOption([]);
            setAccountData([]);
            setIsFetchingAccountOption(false);
            return;
        };
        const options = data.sort((a, b) => sortList(a.externalCode, b.externalCode)).map(res => {
            return {
                value: res.id,
                label: res.description
            };
        });

        setAccountData(options);
        setAccountValueOption(options);
        setIsFetchingAccountOption(false);
    };

    function onLoadFixedExpenseOptions(data) {
        if (data.length === 0) {
            setFixedExpenseData([]);
            setFilteredFixedExpensesOptions([]);
            return;
        };
        const options = data.map(res => {
            return {
                value: res.value,
                label: res.label
            };
        });

        setFixedExpenseData(options);
        setFilteredFixedExpensesOptions(options);
        setIsFetchingFixedExpenseData(false);

        let filter = [];

        options.forEach((option) => {
            if (!newLinkList.find(link => link.fixedExpenseAccount === option.value) && !tableData.find(link => link.accountingAccountId === option.value)) {
                filter.push(option);
            }
        });
    };

    function onLoadCostCategoryOptions(data) {
        if (data.length === 0) {
            setCostCategoryOption([]);
            setCostCategoryData([]);
            return;
        };

        const options = data.map(res => {
            return {
                value: res.id,
                label: res.name
            };
        });

        setCostCategoryData(options);
        setCostCategoryOption(options);
        setIsFetchingCostCategoryOption(false);
    };

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: LinkAccountsTableData[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    function handleOpenModal(isNewLink: boolean) {
        if (isNewLink) {
            setIsNewLink(true);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setNewLinkList([]);
            form.resetFields();
        } else {

            const links = tableData.filter(link => link.resourceAccountId === selectedRows[0].resourceAccountId && link.id !== selectedRows[0].id);

            if(flexFieldValuesData.length > 0){
                const linkedFlexFieldIds = tableData.filter(acc => {
                    return acc.id !== selectedRows[0].id && 
                    acc.resourceAccountId == selectedRows[0].resourceAccountId && 
                    acc.accountingAccountId == selectedRows[0].accountingAccountId && 
                    acc.costCategories?.map(cc => cc.costCategoryId)?.some(cc =>  selectedRows[0].costCategories.map(category => {
                        return [category.costCategoryId]
                    }).flat().includes(cc))
                }).map(acc => {
                    return acc.flexFieldValueId;
                });
    
                const filteredFlexFields = flexFieldValuesData.filter(ff => !linkedFlexFieldIds.includes(ff.id)).map(ff => {
                    return {
                        value: ff.id,
                        label: ff.externalCode + ' - ' + ff.description
                    };
                });
    
                setIsFlexFieldDisabled(false);
                setFlexFieldValuesOptions(filteredFlexFields);
            }else{
                filterFixedExpenseOptions(links);
                filterCostCategoryOptions(links);

                const linksFromAccountingAccout = tableData.filter(link => link.accountingAccountId === selectedRows[0].accountingAccountId && link.id !== selectedRows[0].id);
                filterResourceAccountOptions(linksFromAccountingAccout);
            }

            form.setFieldsValue({
                account: selectedRows[0].resourceAccountId,
                fixedExpenseAccount: Number(selectedRows[0].accountingAccountId),
                costCategoryLink: selectedRows[0].costCategories.map(category => {
                    return [category.costCategoryId]
                }),
                flexFieldValueLink: selectedRows[0].flexFieldValueId
            });
            setIsNewLink(false);
        }

        setIsModalVisible(true);
    };

    function handleCloseModal() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        form.resetFields();
        setIsModalVisible(false);
        setNewLinkList([]);
        setAccountValueOption(accountData);
        setFilteredFixedExpensesOptions(fixedExpenseData);
    };

    function handleDeleteLink() {
        setIsFetching(true);
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/human-resources/resource-account-link?ids=${selectedRowKeys.toString()}`,
        }, onDeleteLink.bind(this), onRequestError.bind(this));
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function onDeleteLink(response) {
        if (response.success) {
            Notification({
                type: "success",
                message: i18n.t<string>("successfully_deleted"),
            });

        } else {
            Notification({
                type: "error",
                message: i18n.t<string>(response.message),
            });
        }

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/human-resources/resource-account-link?localeId={locale}&clientId={client}&organizationId={organization}&userId={user}`
        }, onLoadLinkAccountsTableData.bind(this));
    };

    function onRequestError() {
        Notification({
            type: "warning",
            message: i18n.t<string>("unable_to_delete_with_linked_data"),
        });
        setIsFetching(false);
    }

    function handleIncludeLink(data: ValueLinkForm) {
        if (isNewLink) {
            data.key = data.account.toString() + data.fixedExpenseAccount.toString();

            for (let i = 0; i < data.costCategoryLink.length; i++) {
                data.key += data.costCategoryLink[i][0].toString();
            }
            setNewLinkList([...newLinkList, data]);
        } else {
            handleSaveLink([data]);
        }
        form.resetFields();
        setIsFlexFieldDisabled(true);
        setIsCostCategoryDisabled(true);
    }

    function handleSaveLink(data) {
        if (isNewLink && newLinkList.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t<string>("empty_list"),
            });
            return;
        }

        const linkToSave = data.map(
            link => {
                return {
                    id: isNewLink ? null : selectedRowKeys[0],
                    organizationId: userInfo.selection.organizationId,
                    resourceAccountId: link.account,
                    accountingAccountId: link.fixedExpenseAccount,
                    flexFieldValueId: link.flexFieldValueLink,
                    costCategories: link.costCategoryLink.map(category => {
                        return {
                            resourceAccountLinkId: link.account,
                            costCategoryId: category[0]
                        }
                    })
                }
            });
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setNewLinkList([]);

        setIsFetching(true);
        setIsModalVisible(false);

        ServiceCaller.doRequest({
            type: isNewLink ? RequestType.POST : RequestType.PUT,
            url: '/human-resources/resource-account-link?localeId={locale}&clientId={client}',
            params: isNewLink ? linkToSave : linkToSave[0],
        }, onSaveLink.bind(this));
    }

    function onSaveLink(response) {
        if (response) {
            if (isNewLink) {
                if (newLinkList.length > 0) {
                    Notification({
                        type: "success",
                        message: i18n.t<string>("successfully_saved"),
                    });
                } else {
                    Notification({
                        type: "warning",
                        message: i18n.t<string>("linkAccount.warning_message"),
                    });
                }
            } else {
                Notification({
                    type: "success",
                    message: i18n.t<string>("successfully_edited"),
                });
            }
        }
        form.resetFields();
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: '/human-resources/resource-account-link?localeId={locale}&clientId={client}&organizationId={organization}&userId={user}'
        }, onLoadLinkAccountsTableData.bind(this));
    };

    function getCostCateoryDescription(value) {
        const costCategory = costCategoryData.find(category => category.value === value);
        return costCategory?.label;
    }

    function filterFixedExpenseOptions(links) {
        const linkedExpensesAccountIds = links.map(link => link.accountingAccountId);
        const filteredExpensesAccounts = fixedExpenseData.filter(ff => !linkedExpensesAccountIds.includes(ff.value));

        setFilteredFixedExpensesOptions(filteredExpensesAccounts);
    }

    function filterResourceAccountOptions(links) {
        const linkedResourceAccountIds = links.map(link => link.resourceAccountId);
        const filteredResourceAccount = accountData.filter(ff => !linkedResourceAccountIds.includes(Number(ff.value)));

        setAccountValueOption(filteredResourceAccount);
    }

    function filterCostCategoryOptions(links) {
        const costCategoryLinkedIds = links.reduce((acc, val) => {
            const ids = val.costCategories.map(cc => cc.costCategoryId);
            acc = [...acc, ...ids];

            return acc;
        }, []);
        const filteredCostCategories = costCategoryData.filter(ff => !costCategoryLinkedIds.includes(ff.value));

        setCostCategoryOption(filteredCostCategories);
        setIsCostCategoryDisabled(false);
    }

    return (
        <>
            <div className="page-title-content">
                <h1>{i18n.t<string>("linkAccount.link_accounts")}</h1>
            </div>
            <div id="top-buttons-link-accounts">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t<string>("linkAccount.link_new_account")}
                        handleNew={() => handleOpenModal(true)}
                        handleEdit={() => handleOpenModal(false)}
                        handleDelete={handleDeleteLink}
                        searchPlaceholder={i18n.t("account_registration.search_account")}
                        isEditable={selectedRows.length === 1}
                        isDeletable={selectedRows.length > 0}
                        multipleSearch={{
                            tableData: tableData,
                            setTableData: setFilterdTableData,
                            options: [
                                { i18nString: 'linkAccount.account_code', description: 'resourceAccountId' },
                                { i18nString: 'budget.permissions.accounting', description: 'resourceAccountName' },
                                { i18nString: 'linkAccount.fixed_expense_code', description: 'accountingAccountExternalCode' },
                                { i18nString: 'linkAccount.fixed_expense_accounts', description: 'accountingAccountName' },
                                { i18nString: 'linkAccount.linked_cost_category', description: 'costCategories', isArray: true, descriptionArray: 'costCategoryName' },
                            ]
                        }}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(tableDataToExport, tableColumnToExport, i18n.t("linkAccount.link_accounts")),
                        }}
                    />
                </div>
            </div>
            <main id="link-accounts-main">
                <LinkAccountTable
                    isFetching={isFetching}
                    tableData={filterdTableData}
                    selectedRowKeys={selectedRowKeys}
                    onChange={onSelectRowChange}
                    columns={tableColumns}
                />
                <LinkAccountsModal
                    isModalVisible={isModalVisible}
                    isNewLink={isNewLink}
                    handleSubmit={handleIncludeLink}
                    handleCancel={handleCloseModal}
                    form={form}
                    newLinkList={newLinkList}
                    setNewLinkList={setNewLinkList}
                    tableData={tableData}
                    accountData={accountData}
                    accountValueOption={accountValueOption}
                    isFetchingAccountOption={isFetchingAccountOption}
                    costCategoryOption={costCategoryOption}
                    setCostCategoryOption={setCostCategoryOption}
                    isFetchingCostCategoryOption={isFetchingCostCategoryOption}
                    fixedExpenseData={fixedExpenseData}
                    setFixedExpenseData={setFixedExpenseData}
                    isFetchingFixedExpenseData={isFetchingFixedExpenseData}
                    handleSaveLink={handleSaveLink}
                    getCostCateoryDescription={getCostCateoryDescription}
                    fixedExpenseOption={filteredFixedExpensesOptions}
                    setFixedExpenseOption={setFilteredFixedExpensesOptions}
                    costCategoryData={costCategoryData}
                    disabledField={disabledField}
                    setDisabledField={setDisabledField}
                    filterFixedExpenseOptions={filterFixedExpenseOptions}
                    filterCostCategoryOptions={filterCostCategoryOptions}
                    selectedRows={selectedRows}
                    flexFieldValuesOptions={flexFieldValuesOptions}
                    setFlexFieldValuesOptions={setFlexFieldValuesOptions}
                    flexFieldValuesData={flexFieldValuesData}
                    isFetchingFlexFieldOptions={isFetchingFlexFieldOptions}
                    isFlexFieldDisabled={isFlexFieldDisabled}
                    setIsFlexFieldDisabled={setIsFlexFieldDisabled}
                    isCostCategoryDisabled={isCostCategoryDisabled}
                    setIsCostCategoryDisabled={setIsCostCategoryDisabled}
                    setAccountValueOption={setAccountValueOption}
                />
            </main>
        </>
    );
}