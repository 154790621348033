import { Modal } from 'antd';
import { Icon } from '@iconify/react';
import i18n from 'util/base/i18n';
import { useUserContext } from 'context/UserContext';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { RequestType } from 'util/service/IServiceCaller';

import './environmentModalStyles.sass'

export function EnvironmentModal() {

  const { isEnvironmentModalOpen, changeEnvironmentModal, isBudgetModule } = useUserContext();

  function handleLeaseProjection(isLeaseProjection) {
    setLeaseEnvironment(isLeaseProjection);
  }

  function setLeaseEnvironment(isLeaseProjection) {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.POST,
      url: '/setLeaseProjection',
      params: isLeaseProjection,
    }, onSetLeaseProjection.bind(this));
  }

  function onSetLeaseProjection(data) {
    if (data.status) {
      window.top.location.href = process.env.REACT_APP_GS_ROOT_PATH + process.env.REACT_APP_GS_BASE_PATH;
    }
    else {
      window.location.reload()
      handleCancel()
    }
  }

  function handleCancel() {
    changeEnvironmentModal()
  }

  if (isBudgetModule) {
    return (
      <Modal
        width={702}
        title={<p className="change-environment-modal-heading">{i18n.t<string>('environment_to_acess')}</p>}
        visible={isEnvironmentModalOpen}
        onCancel={changeEnvironmentModal.bind(this)}
        className="gs-modal change-environment-modal"
        footer={null}
      >
        <div className="btn-wrapper">
          <div className="change-environment-modal-btn" onClick={() => handleLeaseProjection(true)}>
            <div className="icon-wrapper">
              <Icon color="#0065B3" width={27} icon="mdi:world" />
            </div>
            <label className="btn-label">{i18n.t<string>('planning')}</label>
          </div>
          <div className="change-environment-modal-btn" onClick={() => handleLeaseProjection(false)}>
            <div className="icon-wrapper">
              <Icon color="#0065B3" width={27} icon="fa6-solid:chart-column" />
            </div>
            <label className="btn-label">{i18n.t<string>('operational')}</label>
          </div>
        </div>
      </Modal>
    );
  }
  else {
    return (
      <div id="modal-environment-dialog">
        <div className='environment-modal-header'>
          <h3>Selecione qual ambiente acessar</h3>
          <button onClick={handleCancel}>
            <Icon color="#0065B3" width={27} icon="ant-design:close-outlined" />
          </button>
        </div>
        <hr className="divider" />
        <div className="btn-wrapper">
          <div id="planning-environment-btn" className="change-environment-modal-btn" onClick={() => handleLeaseProjection(true)}>
            <div className="icon-wrapper">
              <Icon color="#0065B3" width={27} icon="mdi:world" />

            </div>
            <label className="btn-label"><span className="btn-text">Planejamento</span></label>
          </div>
          <div id="operating-environment-btn" className="change-environment-modal-btn" onClick={() => handleLeaseProjection(false)}>
            <div className="icon-wrapper">
              <Icon color="#0065B3" width={27} icon="fa6-solid:chart-column" />
            </div>
            <label className="btn-label"><span className="btn-text">Operacional</span></label>
          </div>
        </div>
      </div>
    )
  }
}
