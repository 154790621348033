import { DatePicker, Select, Modal, Space, List, Row, Col, Tooltip, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { BudgetGridData } from "../../../IFixedExpense";
import { Label } from "components/label/Label";
import moment from "moment";
import { ServiceCaller } from "util/service/ServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { useBudgetOpeningContext } from "../../../context/useBudgetOpeningContext";
import { Notification } from "components/notification/Notification";
import { Icon } from "@iconify/react";
import { cloneDeep } from "lodash";

interface IIndexerModal {
    isOpen: boolean;
    setModalOpen(b: boolean): void;
    selectedAccount: BudgetGridData[]
    setSelectedRows(a: any[]): void;
    handleSavePlannedValue(dataToSava, budgetGridData: BudgetGridData, previousValue: BudgetGridData): void;
}

export function IndexerModal({
    isOpen,
    setModalOpen,
    selectedAccount,
    handleSavePlannedValue,
    setSelectedRows
}: IIndexerModal) {
    const [indexer, setIndexer] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)
    const { userInfo, budgetGridData, indexerOptions, openBudgetYears } = useBudgetOpeningContext()
    const [year, setYear] = useState(null)
    const [isNew, setIsNew] = useState(true)
    const [disabledYears, setDisabledYears] = useState([])

    const [indexerList, setIndexerList] = useState<{ value: number, label: string, year: number }[]>([])


    useEffect(() => {
        if (!indexerOptions || indexerOptions.length === 0 || selectedAccount.length === 0) {
            setIndexerList([])
            return
        }
        const yearsWithIndex = selectedAccount[0].budgetOpeningIndexerLink.map(item => item.year)
        setDisabledYears(yearsWithIndex)
        const updatedIndexerList = selectedAccount[0].budgetOpeningIndexerLink?.map(item => {
            const indexer = indexerOptions.find(option => option.value === item.indexerId)
            return {
                value: indexer?.value,
                label: indexer?.label,
                year: item.year
            }
        }) ?? []

        setIndexerList(updatedIndexerList)
    }, [selectedAccount, indexerOptions, openBudgetYears])

    useEffect(() => {
        if (indexer && year) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [year, indexer])

    function onEditIndexer(id, year) {
        setIsNew(false)
        const updatedIndexerList = indexerList.filter(index => index.value !== id && index.year !== year)
        setIndexerList(updatedIndexerList)
        setIndexer(id)
        setYear(moment(year, 'YYYY'))
    }

    function onDeleteIndexer(id, year) {
        const updatedList = indexerList.filter(index => index.value !== id && index.year !== year)
        setIndexerList(updatedList)

        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/expenses/budget-opening/budget-opening-indexer-link?budgetOpening=${selectedAccount[0].budgetOpening.id}&indexer=${id}&year=${year}`,

        }, (data) => updatedIndexerList(false, updatedList), handleErrorRequest)
    }

    function handleAddIndexerToBudgetOpening() {
        setIsDisabled(true)
        ServiceCaller.doRequest({
            type: isNew ? RequestType.POST : RequestType.PUT,
            url: '/expenses/budget-opening/budget-opening-indexer-link',
            params: {
                budgetOpeningId: selectedAccount[0].budgetOpening.id,
                indexerId: indexer,
                year: year.year()
            }
        }, () => updatedIndexerList(true, indexerList), handleErrorRequest)
    }

    function updatedIndexerList(isSaving, indexList) {
        let dataToSave = {
            rowType: selectedAccount[0].rowType,
            budgetOpeningId: selectedAccount[0].budgetOpening.id,
            plannedMonths: [],
            scenarioId: userInfo.selection.scenarioId
        }
        Object.keys(selectedAccount[0].plannedMonths).forEach(month => {
            dataToSave.plannedMonths.push(selectedAccount[0].plannedMonths[month])
        })
        const updatedBudgetData = cloneDeep(budgetGridData)
        const updatedRow = updatedBudgetData.find(opening => opening.budgetOpening.id === selectedAccount[0].budgetOpening.id)
        const updatedIndexList = [...indexList]

        if (isSaving) {
            const newIndexer = indexerOptions.find(option => option.value === indexer)

            updatedIndexList.push({
                label: newIndexer.label,
                value: newIndexer.value,
                year: year.year()
            })
        }
        setIndexerList(updatedIndexList)

        updatedRow.budgetOpeningIndexerLink = updatedIndexList.map(index => {
            return {
                budgetOpeningId: updatedRow.budgetOpening.id,
                indexerId: index.value,
                year: index.year
            }
        })
        setIndexer(null)
        handleSavePlannedValue(dataToSave, updatedRow, updatedRow)
        Notification({
            type: 'success',
            message: isSaving ? 'Indexador adicionado com sucesso!' : 'Indexador deletado com sucesso!'
        })
        setDisabledYears([...disabledYears, year.year()])
        setIsDisabled(false)
        setIndexer(null)
        setYear(null)
    }

    function disabledDate(current) {
        return disabledYears.includes(current.year()) ||
            current.year() < moment().year() ||
            !openBudgetYears.includes(current.year())
    }

    return (
        <Modal
            className="gs-modal"
            okText={i18n.t("save")}
            onCancel={() => {
                setModalOpen(false)
                setIsNew(true)
                setIndexer(null)
                setYear(null)
                setSelectedRows([])
            }}
            okButtonProps={{ disabled: isDisabled }}
            onOk={() => handleAddIndexerToBudgetOpening()}
            visible={isOpen}
            centered
            title={i18n.t("indexer")}
        >
            <Space direction="vertical" size={10} style={{ marginBottom: 20 }}>
                <Label title="Conta" key='account'>
                    {selectedAccount[0]?.accounting}
                </Label>
                <Label title="Ano" key='year'>
                    <DatePicker
                        className="gs-date-picker"
                        picker="year"
                        onChange={(value) => setYear(value)}
                        value={year}
                        disabledDate={disabledDate}
                    />
                </Label>
                <Label title="Índice" key='index'>
                    <Select
                        options={indexerOptions}
                        onChange={(value) => setIndexer(value)}
                        value={indexer}
                        style={{ width: "100%" }}
                    />
                </Label>
            </Space>
            {(indexerList.length > 0) ?
                <List
                    header={<h4 style={{ margin: 0 }}>Indexadores adicionados:</h4>}
                    bordered
                    size="small"
                >
                    {indexerList.map((indexer) => <List.Item key={indexer.value}>
                        <Row style={{ width: '100%' }}>
                            <Col span={18}>
                                <span>{i18n.t('year')}</span>: {indexer.year} - <span>{i18n.t('indexer')}</span>: <span className="gs-tag blue">{indexer.label}</span>
                            </Col>
                            <Col span={6} className="gs-flex jc-end align-center">
                                <Tooltip title={i18n.t("edit")}>
                                    <Icon
                                        style={{ marginRight: 6, fontSize: 17, cursor: 'pointer' }}
                                        onClick={() => onEditIndexer(indexer.value, indexer.year)}
                                        icon="material-symbols:edit-sharp"
                                    />
                                </Tooltip>
                                <Tooltip title={i18n.t("delete")}>
                                    <Popconfirm
                                        placement="bottom"
                                        overlayClassName="popconfirm-delete"
                                        title={i18n.t<string>("delete_confirm_message")}
                                        onConfirm={() => onDeleteIndexer(indexer.value, indexer.year)}
                                        okText={i18n.t<string>("yes")}
                                        cancelText={i18n.t<string>("cancel")}
                                        okButtonProps={{ danger: true, className: 'popconfirm-delete-button' }}
                                    >
                                        <Icon
                                            style={{ cursor: 'pointer' }}
                                            icon="fa6-solid:trash"
                                        />
                                    </Popconfirm>
                                </Tooltip>
                            </Col>
                        </Row>
                    </List.Item>)}
                </List>
                : null}
        </Modal>
    )
}