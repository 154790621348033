import i18n from "util/base/i18n";
import { IExportGridExcelProps, IReport, IUpdatedReportVisualizationConfig, ModalityType } from "./IReportView";

let listRowsToExport = [];

export function exportGridExcel({
    report,
    defaultColumns,
    handleExportGridData,
    selectedModality,
    popOverConfigs,
    selectedPeriodicityType,
}: IExportGridExcelProps) {
    listRowsToExport = [];

    const gridData = getDatesWithValues(
        report,
        selectedModality,
        popOverConfigs,
        defaultColumns,
        selectedPeriodicityType
    );

    flattenObjects(gridData);

    const columns = JSON.parse(JSON.stringify(defaultColumns)).map((column) => {
        return { ...column, width: 200 };
    });

    handleExportGridData(listRowsToExport, columns, i18n.t<string>("report"));
}

function getDatesWithValues(
    rows: IReport[],
    selectedModality: any,
    popOverConfigs,
    defaultColumns,
    selectedPeriodicityType
) {
    const resultList = [];

    rows.forEach((row) => {
        const rowToExcel = buildRow(row, selectedModality, popOverConfigs, defaultColumns, selectedPeriodicityType);

        if (rowToExcel) {
            resultList.push(rowToExcel);
        }

        if (row.children.length > 0) {
            resultList.push(
                getDatesWithValues(
                    row.children,
                    selectedModality,
                    popOverConfigs,
                    defaultColumns,
                    selectedPeriodicityType
                )
            );
        }
    });

    if (resultList.length > 0) {
        return resultList;
    }
}

function buildRow(row, selectedModality, popOverConfigs, defaultColumns, selectedPeriodicityType) {
    if (!row || row.title) {
        return {};
    }

    const rowToExcel = { description: row.description || "" };

    let modality: ModalityType = ModalityType.ACCOMPLISHED;
    for (const value of Object.values(ModalityType)) {
        if (value === selectedModality) {
            modality = value;
        }
    }

    let columnValueBeans, valueBeans;
    if (row.reportValues) {
        valueBeans = row.reportValues.valueBeans;
        columnValueBeans = row.reportValues.columnValueBeans;
    } else {
        valueBeans = row.values;
        columnValueBeans = row.columns;
    }

    switch (modality) {
        case ModalityType.FORECAST:
            rowToExcel["totalForecast"] = buildDecimalRow(popOverConfigs, columnValueBeans.totalForecast);
            rowToExcel["totalVariationPlannedForecast"] = buildDecimalRow(
                popOverConfigs,
                columnValueBeans.totalVariationPlannedForecast
            );
            rowToExcel["totalPlanned"] = buildDecimalRow(popOverConfigs, columnValueBeans.totalPlanned);
            break;
        case ModalityType.COMPARATIVE_ACCOMPLISHED_PLANNED:
            rowToExcel["totalVariationPlannedAccomplished"] = buildDecimalRow(
                popOverConfigs,
                columnValueBeans.totalVariationPlannedAccomplished
            );
            rowToExcel["totalAccomplished"] = buildDecimalRow(popOverConfigs, columnValueBeans.totalAccomplished);
            rowToExcel["totalPlanned"] = buildDecimalRow(popOverConfigs, columnValueBeans.totalPlanned);
            break;
        default:
            rowToExcel["totalValue"] = buildDecimalRow(popOverConfigs, columnValueBeans.totalValue);
            break;
    }

    let allPeriods = [];
    defaultColumns.forEach((column) => {
        const matches: RegExpMatchArray | null = column.dataIndex.match(/\d{4}-\d{2}/);

        if (matches && matches.length > 0) {
            const data: string = matches[0];
            allPeriods.push(data);
        }
    });

    allPeriods.forEach((period) => {
        const exists = valueBeans.some((bean) => bean.period === period);
        if (!exists) {
            valueBeans.push({
                period: period,
                value: 0,
                plannedValue: 0,
                accomplishedValue: 0,
                variableValue: 0,
                valueBase: 0,
                valueTarget: 0,
                variablePercente: 0,
                accomplished: false,
                isChild: false,
            });
        }
    });

    valueBeans.forEach((periodCell: any) => {
        const periodKey = periodCell.period.toString();

        if (modality === ModalityType.PLANNED) {
            rowToExcel[periodKey.concat("plannedValue")] = buildDecimalRow(popOverConfigs, periodCell.value);
        }

        if (modality === ModalityType.ACCOMPLISHED) {
            rowToExcel[periodKey.concat("accomplishedValue")] = buildDecimalRow(popOverConfigs, periodCell.value);
        }

        if (modality === ModalityType.COMPARATIVE_ACCOMPLISHED_PLANNED) {
            rowToExcel[periodKey.concat("plannedValue")] = buildDecimalRow(popOverConfigs, periodCell.plannedValue);
            rowToExcel[periodKey.concat("accomplishedValue")] = buildDecimalRow(
                popOverConfigs,
                periodCell.accomplishedValue
            );
            rowToExcel[periodKey.concat("variableValue")] = buildDecimalRow(popOverConfigs, periodCell.variableValue);
            rowToExcel[periodKey.concat("variablePercente")] = periodCell.variablePercente;
        }

        if (modality === ModalityType.FORECAST) {
            if (periodCell.accomplished) {
                rowToExcel[periodKey] = buildDecimalRow(popOverConfigs, periodCell.accomplishedValue);
            } else {
                rowToExcel[periodKey] = buildDecimalRow(popOverConfigs, periodCell.plannedValue);
            }
        }
    });

    return rowToExcel;
}

function flattenObjects(list) {
    function getObjectInsideList(row, tabAmount: number = 1) {
        row.forEach((element) => {
            element.length > 0
                ? getObjectInsideList(element, tabAmount + 1)
                : listRowsToExport.push({
                      ...element,
                      description: "    ".repeat(tabAmount) + element.description,
                  });
        });
    }

    list.forEach((a) => {
        a.length > 0 ? getObjectInsideList(a) : listRowsToExport.push(a);
    });
}

function buildDecimalRow(popOverConfigs: IUpdatedReportVisualizationConfig, formatValue: any) {
    const { decimalPlaces, showThousand } = popOverConfigs;

    const value = showThousand ? formatValue / 1000 : formatValue;

    return new Intl.NumberFormat(undefined, {
        style: "decimal",
        currencyDisplay: "symbol",
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    }).format(value);
}
