import moment from "moment";

import { Table } from "antd";
import { ContractSimulationTableProps } from "../../IContractSimulation";
import i18n from "util/base/i18n";

import { ROOT_PATH } from "util/rootPath";
import { ServiceCaller } from 'util/service/ServiceCaller';
import { MessageType } from 'util/service/IServiceCaller';

export function ContractSimulationTable({
  isFetching,
  tableData,
  selectedRowKeys,
  onChange,
  tableColumns
}: ContractSimulationTableProps) {
  const rowSelection = { selectedRowKeys, onChange };

  return (
    <Table
      loading={{
        spinning: isFetching,
        tip: i18n.t<string>('loading')
      }}
      className="gs-table"
      dataSource={tableData}
      columns={tableColumns}
      rowSelection={rowSelection}
      pagination={{ hideOnSinglePage: true }}
      onRow={(contract) => {
        return {
          onDoubleClick: () => {
            ServiceCaller.doSendMessage({
              params: { url: `${ROOT_PATH}/mf/projectionlease/contract/values/${contract["id"]}` },
              messageType: MessageType.REDIRECT_TO_BLANK
            })
          }
        };
      }}
      bordered
    />
  );
}
