import { ModuleType } from "util/types/types";

export interface IRevisionPeriodModal {
    isOpen: boolean;
    setIsOpen(value: boolean): void;
    handleSubmit(data: any): void;
    revisionYear: number;
    organization: OrganizationBudgetDates;
    moduleType: ModuleType
}

export interface AnnualDatesFormData {
    fiscalYear: number;
    budgetPeriod: moment.Moment[];
    openPeriod: moment.Moment[];
    frozenPeriod: moment.Moment;
    realizedPeriod: moment.Moment;
    segmentType?: number;
    unitConversionId?: number
    hasAnnualBinding?: boolean;
    basePeriod?: moment.Moment;
    revenueBasePeriod?: number;
}
export interface OrganizationBudgetDates {
    id: number;
    year: number;
    hasAnnualBinding: boolean;
    moduleType: string;
    organizationId: number;
    organizationName: string;
    scenario: number;
    revenue: RevenueDates[] | null;
	expensesResources: ExpensesResourcesDates[] | null;
	planning: PlanningDates[] | null
}

export interface RevisionTableData {
    reviewPeriodStartDate: number | string;
    reviewPeriodEndDate: number | string;
    budgetStartDate: number | string;
    budgetEndDate: number | string;
}

export interface RevisionFormData {
    openPeriod: [moment.Moment, moment.Moment]
    revisionPeriod: [moment.Moment, moment.Moment]
}

export interface ExpensesResourcesDates {
    id: number
    year: number
    hasAnnualBinding: boolean
    expensesResourcesRealizedPeriod: number;
    expensesResourcesFrozenPeriod: number;
    expensesResourcesOpeninigStart: number;
    expensesResourcesOpeninigEnd: number;
    expensesResourcesBudgetStart: number;
    expensesResourcesBudgetEnd: number;
    revisions: RevisionDates[]
}

export interface RevenueDates {
    id: number
    year: number
    revenueOpeninigStart: number;
    revenueOpeninigEnd: number;
    revenueBudgetStart: number;
    revenueBudgetEnd: number;
    revenueRealizedPeriod: number;
    revenueFrozenPeriod: number;
    segmentType: number;
    unitConversionId: number;
    revisions: RevisionDates[]
}

export interface PlanningDates {
	id: number
    year: number
	planningBudgetPeriodStartDate: number;
	planningBudgetPeriodEndDate: number;
	planningOpeningPeriodStartDate: number;
	planningOpeningPeriodEndDate: number;
	planningRealizedPeriod: number;
}

interface RevisionDates {
    reviewPeriodStartDate: number;
    reviewPeriodEndDate: number;
    budgetStartDate: number;
    budgetEndDate: number;
}

export interface UnitConversionResponse {
    id: number;
    description: string;
    externalCode: string;
    symbol: string;
}

export interface SegmentTypeResponse {
    id: number;
    caption: string;
}

export const getOrderByModuleType = {
	expensesResources: 3,
	revenue: 2,
	planning: 5
}