import { Button } from "antd";

import { Icon } from "@iconify/react";
import "./Header.sass";
import { ServiceCaller } from "util/service/ServiceCaller";
import { MessageType } from "util/service/IServiceCaller";
import Notifications from "./Notifications";
import { UserButton } from "./UserButton";
import { useState } from "react";
import { coachMarkChannel } from "../../util/BroadcastChannels";
import { useUserContext } from "context/UserContext";
import FreemiumTag from "components/freemiumTag/FreemiumTag";

export default function Header() {
	const [applyOverlay, setApplyOverlay] = useState(false)
	const { isBudgetModule, setIsMainMenuOpen, openMenuBtnRef, menuSearchRef, userInfo } = useUserContext();

	function onInitialCoahMark() {
		setApplyOverlay(true)
		coachMarkChannel.postMessage('show-overlay')
		coachMarkChannel.postMessage('open-coachMark')
	};

	function openInitialTour() {
		ServiceCaller.doSendMessage({
			messageType: MessageType.OPEN_INITIAL_TOUR
		}, onInitialCoahMark.bind(this));
	};

	function openMenu() {
		if(isBudgetModule) {
			setIsMainMenuOpen(true)
		} else {
			ServiceCaller.doSendMessage({
				messageType: MessageType.OPEN_MENU
			});
		}
		menuSearchRef.current.focus()
	};

	coachMarkChannel.onmessage = (message) => {
		switch (message.data) {
			case 'show-overlay':
				setApplyOverlay(true)
				break;
			case 'hide-overlay':
				setApplyOverlay(false)
				break;
			default:
				break;
		}
	}

	return <>
		<div className="top-bar-main-content">
			<div className={`microfront_overlay ${applyOverlay ? '' : 'hidden'}`}></div>
			<div className="top-bar-left-content">
				<Button ref={openMenuBtnRef} id="btn-main-menu" className="trigger" onClick={openMenu} icon={<Icon icon="typcn:th-menu" />} />
				<img src={"/assets/images/gesplan.png"} alt="Gesplan" />
				{userInfo.plan === "FREEMIUM" && <FreemiumTag />}
			</div>
			<div className="top-bar-right-content">
				<div className="buttons-content">
					<Button hidden={true} onClick={openInitialTour} icon={<Icon icon="clarity:info-line" />} />
					<Notifications />
					<UserButton />
				</div>
			</div>
		</div>
	</>;

}
