import * as React from "react";

import "./Lookup.sass";

import i18n from "util/base/i18n";

import {
	Col,
	Divider,
	Row,
	Select,
	Typography
} from "antd";

import { Icon } from "@iconify/react";

import {
	ILookupProps,
	ILookupState,
	ILookupOption
} from "./ILookup";

import StringUtil from "util/StringUtil";

export default class Lookup extends React.Component<ILookupProps, ILookupState> {

	constructor(props: ILookupProps) {
		super(props);

		this.state = {
			...this.buildOptions(props)
		};
	}

	shouldComponentUpdate(nextProps: Readonly<ILookupProps>): boolean {
		if (this.state.originalOptions !== JSON.stringify(nextProps.options)) {
			this.setState({
				...this.buildOptions(nextProps)
			});
		}

		return true;
	}

	buildOptions(props: ILookupProps): ILookupState {
		let options: Array<any>;

		let fieldValue: string = "id";
		let fieldLabel: string = "name";
		let filterProp: string = props.filterProp || "name";

		if (props.options.hasOwnProperty("data")) {
			const {
				data,
				keyProp = "id",
				labelProp = "name",
				imageProp = null
			} = (props.options as ILookupOption);

			fieldValue = keyProp;
			fieldLabel = labelProp;

			if (imageProp !== null) {
				options = this.makeImageOptions(data, keyProp, labelProp, imageProp);
				filterProp = filterProp + "Text";
			} else {
				options = data;
			}
		} else {
			options = (props.options as Array<any>);
		}

		return {
			originalOptions: JSON.stringify(props.options),
			options,
			fieldValue,
			fieldLabel,
			filterProp
		};
	}

	makeImageOptions(options: Array<any>, keyProp: string, labelProp: string, imageProp: string): Array<any> {
		return options.map(option => {
			return {
				[keyProp]: option[keyProp],
				[labelProp]: <>
					<img alt={`option-${option[keyProp]}`} src={option[imageProp]} />
					<label>{option[labelProp]}</label>
				</>,
				[labelProp + "Text"]: option[labelProp]
			}
		});
	}

	renderLabel(): React.ReactElement {
		const { label } = this.props;

		if (label != null) {
			return <Typography.Text>{ i18n.t<string>(label) }</Typography.Text>;
		}
	}

	onFilter(input: string, option: any) {
		const {
			filterProp
		} = this.state;

		const searchText: string = StringUtil.getWithoutSpecials(input);
		const optionText: string = StringUtil.getWithoutSpecials(option[filterProp]);

		return optionText.indexOf(searchText) >= 0;
	}

    render() {
		const {
			showSearch = true,
			selected,
			onSelect,
			isMultiple = false,
			suffixIcon = <>
				<Divider type="vertical">
					<Icon icon={"simple-line-icons:magnifier"} />
				</Divider>
			</>
		} = this.props;

		const {
			options,
			fieldValue,
			fieldLabel
		} = this.state;

		return <>
			<Row>
				<Col span={24}>
					{ this.renderLabel() }
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Select className="gs-lookup" options={options} showSearch={showSearch} suffixIcon={suffixIcon} value={selected} onChange={onSelect.bind(this)} fieldNames={{
						value: fieldValue,
						label: fieldLabel
					}} filterOption={this.onFilter.bind(this)} {...isMultiple && {mode: "multiple"}} />
				</Col>
			</Row>
		</>
	}

}
