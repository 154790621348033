import { useState } from "react";
import { Table, Tooltip } from "antd";
import i18n from "util/base/i18n";
import { ApportionmentResponse, Column, IApportionmentList } from "../../../IApportionment";
import { paginationConfig } from "util/TableUtils";
import { Icon } from "@iconify/react";
import { ImageBox } from "components/imageBox/ImageBox";

export function ApportionmentList({
    apportionmentList,
    onOpenApportionmentInfo,
    isFetchingApportionments,
    selectedRowKeys,
    setSelectedRowKeys
}: IApportionmentList) {
    const columns: Column[] = [
        {
            dataIndex: 'businessUnitName',
            title: `${i18n.t('origin')} `,
            key: 'businessUnit',
            fixed: 'left',
            children: [

                {
                    dataIndex: 'businessUnitName',
                    title: `${i18n.t('business_unit')} `,
                    key: 'businessUnit',
                    fixed: 'left',

                },
                {
                    dataIndex: 'costCenterName',
                    title: `${i18n.t('cost_center')} `,
                    key: 'costCenter',
                    fixed: 'left'
                },
                {
                    dataIndex: 'accountingAccountName',
                    title: `${i18n.t('account')} `,
                    key: 'account',
                    fixed: 'left'
                },
            ]
        },
        // {
        //     dataIndex: 'flexFields',
        //     title: `${i18n.t('flexField.modalTitle')} `,
        //     key: 'flexFields',
        //     fixed: 'left',
        // },
        {
            dataIndex: 'businessUnitName',
            title: i18n.t("destination"),
            key: 'businessUnit',
            fixed: 'left',
            children: [

                {
                    dataIndex: 'businessUnit',
                    title: `${i18n.t('business_unit')}`,
                    key: 'businessUnit',
                    render(value, record: ApportionmentResponse) {
                        if (!record) return ''
                        const data = getLabelList(record, 'businessUnitName')
                        if (data.list.length > 1) {
                            return <Tooltip title={data.list.map(item => <div key={item}>{item}</div>)}>
                                <span className="gs-tag blue">{`${data.amount} ${i18n.t('account')}`}</span>
                            </Tooltip>
                        } else {
                            return data.list[0]
                        }
                    }
                },
                {
                    dataIndex: 'costCenter',
                    title: `${i18n.t('cost_center')}`,
                    key: 'costCenter',
                    render(value, record: ApportionmentResponse) {
                        if (!record) return ''
                        const data = getLabelList(record, 'costCenterName')
                        if (data.list.length > 1) {
                            return <Tooltip title={data.list.map(item => <div key={item}>{item}</div>)}>
                                <span className="gs-tag blue">{`${data.amount} ${i18n.t('cost_center')}`} </span>
                            </Tooltip>
                        } else {
                            return data.list[0]
                        }
                    }
                },
                {
                    dataIndex: 'account',
                    title: `${i18n.t('account')}`,
                    key: 'account',
                    render(value, record: ApportionmentResponse) {
                        if (!record) return ''
                        const data = getLabelList(record, 'accountingAccountName')
                        if (data.list.length > 1) {
                            return <Tooltip title={data.list.map(item => <div key={item}>{item}</div>)}>
                                <span className="gs-tag blue">{`${data.amount} ${i18n.t('account')}`}</span>
                            </Tooltip>
                        } else {
                            return data.list[0]
                        }
                    }
                },
            ],
        },
        // {
        //     dataIndex: 'flexFields',
        //     title: `${i18n.t('flexField.modalTitle')}`,
        //     key: 'flexFields',
        // },
        {
            dataIndex: 'apportionmentType',
            title: '',
            key: 'apportionmentType',
            render(value) {
                return <span className="gs-tag gray">{i18n.t(value.toLowerCase())}</span>
            },
        },
        {
            dataIndex: 'operation',
            title: '',
            key: 'operation',
            fixed: 'right',
            render(value, record: ApportionmentResponse) {
                return <Icon
                    icon="tabler:chevron-right"
                    className="open-apportionment-info-btn"
                    onClick={() => onOpenApportionmentInfo(record)}
                />
            }
        },
    ]
    if (apportionmentList.some(item => item.transitoryApportionment)) {
        columns.unshift({
            dataIndex: 'transitory',
            title: '',
            key: 'operation',
            className: 'transitory-icon-column',
            fixed: 'right',
            render(_, record: ApportionmentResponse) {
                if (record.transitoryApportionment) {
                    return <Icon icon="fa6-solid:retweet" style={{ color: '#E0A42F' }} />
                }
                return ''
            },
        },)
    }
    function getLabelList(record: ApportionmentResponse, item): { amount: number, list: string[] } {
        const data = record?.destinationApportioments.reduce((acc, value) => {
            if (!acc.list.includes(value[item])) {
                acc.list.push(value[item]);
                acc.amount = acc.amount + 1
            }
            return acc
        }, { list: [], amount: 0 });

        return data
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (key) => setSelectedRowKeys(key),
      };

    return (
        isFetchingApportionments || apportionmentList.length > 0 ?
            <Table
                style={{ width: '100%', marginBottom: 40 }}
                columns={columns}
                loading={isFetchingApportionments}
                className="apportionment-list-table"
                dataSource={apportionmentList.map(item => ({...item, key: item.id}))}
                pagination={paginationConfig(apportionmentList)}
                rowSelection={rowSelection}
            />
            :
            <ImageBox
                imgName="budget"
                message="Sem dados adicionados até o momento."
                styles={{marginTop: 30}}
            />

    )
}