import { Options } from "util/types/types";
import { BudgetOpening, PlannedMonth, BudgetGridData, RowType } from "../../../IFixedExpense";
import { updateRowTotals } from "../functions/updateRowTotals";
import { cloneDeep } from "lodash";
import { updateParentRowValues } from "../functions/updateParentRowValues";
import i18n from "util/base/i18n";

export function createGridData(
    data: BudgetOpening[],
    accountingAccounts: Options[],
    plannedMonths: PlannedMonth[],
    currencySelectedId: number,
    currenciesOptions: Options[],
    spendingPackageOptions: Options[]
) {

    const gridData: BudgetGridData[] = data.map((budgetOpening) => {
        let isOpeningInOtherCurrency = false;
        const currentAccount = accountingAccounts.find(
            (account) => account.value === budgetOpening.budgetOpening.accountId
        );
        if (!currentAccount) return;
        const accountName = currentAccount.label.includes("_") ? currentAccount.label + "_" : currentAccount.label;
        const detailIdList = budgetOpening.plannedMonths.reduce((acc, plannedMonth) => {
            if (plannedMonth.detailName?.includes("openingInOtherCurrency")) {
                isOpeningInOtherCurrency = true;
            }
            if (plannedMonth.detailId && !acc.includes(plannedMonth.detailId)) {
                acc.push(plannedMonth.detailId);
            }
            return acc;
        }, []);
        let currencyIsoCode = "";
        const currencyDefault = currenciesOptions.find((cur) => cur.value === currencySelectedId);
        if (currencyDefault) {
            currencyIsoCode = currencyDefault.label;
        }

        const plannedMonthsObject = budgetOpening.plannedMonths.reduce(
            (acc, plannedMonth) => ({
                ...acc,
                [plannedMonth.month]: plannedMonth,
            }),
            {}
        );
        const spendingPackageLabel: string = spendingPackageOptions.find((sp) => sp.value === budgetOpening.spendingPackage)?.label;

        if (detailIdList.length > 0) {
            const budgetGridRow: BudgetGridData = {
                ...budgetOpening,
                isOpeningInOtherCurrency,
                budgetOpeningIndexerLink: [],
                key: budgetOpening.budgetOpening.id.toString(),
                accounting: accountName,
                rowType: RowType.PLANNED,
                canEdit: currentAccount.canEdit,
                currencyId: currencySelectedId,
                currencyIsoCode: currencyIsoCode,
                plannedMonths: plannedMonths.reduce(
                    (acc, plannedMonth) => ({
                        ...acc,
                        [plannedMonth.month]: plannedMonth,
                    }),
                    {}
                ),
                detailIdList,
                detailList: [],
                spendingPackage: spendingPackageLabel
            };
            detailIdList.forEach((detailId) => {
                const plannedMonthFilteredByDetail = budgetOpening.plannedMonths.filter(
                    (month) => month.detailId === detailId
                );
                let currencyIsoCode = "";
                const currentCurrency = currenciesOptions.find((cur) => cur.value === currencySelectedId);
                if (currentCurrency) {
                    currencyIsoCode = currentCurrency.label;
                }
                if (plannedMonthFilteredByDetail[0].currencyId !== currencySelectedId) {
                    const currentCurrency = currenciesOptions.find(
                        (cur) => cur.value === plannedMonthFilteredByDetail[0].currencyId
                    );
                    if (currentCurrency) {
                        currencyIsoCode = currentCurrency.label;
                    } else {
                        currencyIsoCode = i18n.t("not_found");
                    }
                }

                const plannedMonthObject = plannedMonthFilteredByDetail.reduce(
                    (acc, plannedMonth) => ({
                        ...acc,
                        [plannedMonth.month]: plannedMonth,
                    }),
                    {}
                );

                let detailRow: BudgetGridData = {
                    ...budgetOpening,
                    isOpeningInOtherCurrency,
                    budgetOpeningIndexerLink: [],
                    accounting: plannedMonthFilteredByDetail[0].detailName,
                    key: `${budgetOpening.budgetOpening.id}_${detailId}`,
                    canEdit: currentAccount.canEdit,
                    rowType: RowType.DETAIL,
                    detailId: detailId,
                    currencyIsoCode,
                    standardAccount: budgetOpening.spendingPackage,
                    currencyId: plannedMonthFilteredByDetail[0].currencyId,
                    plannedMonths: plannedMonthObject,
                    plannedTotal: 0,
                    realizedTotal: 0,
                    historicTotal: 0,
                    apportionmentTotal: 0,
                    projectedTotal: 0,
                    spendingPackage: spendingPackageLabel
                };
                if (plannedMonthFilteredByDetail[0].quantity !== null) {
                    Object.assign(detailRow, {
                        children: [
                            {
                                ...budgetOpening,
                                key: `${budgetOpening.budgetOpening.id}_${detailId}_quantity`,
                                budgetOpeningIndexerLink: [],
                                plannedMonths: plannedMonthObject,
                                currencyId: plannedMonthFilteredByDetail[0].currencyId,
                                currencyIsoCode,
                                detailId: detailId,
                                rowType: RowType.UNITVALUE,
                                canEdit: currentAccount.canEdit,
                                accounting: i18n.t("fixed_expense_texts.unitary_value"),
                                plannedTotal: 0,
                                realizedTotal: 0,
                                historicTotal: 0,
                                apportionmentTotal: 0,
                                projectedTotal: 0,
                                spendingPackage: spendingPackageLabel
                            } as BudgetGridData,
                            {
                                ...budgetOpening,
                                budgetOpeningIndexerLink: [],
                                key: `${budgetOpening.budgetOpening.id}_${detailId}_unitValue`,
                                plannedMonths: plannedMonthObject,
                                currencyId: plannedMonthFilteredByDetail[0].currencyId,
                                currencyIsoCode,
                                detailId: detailId,
                                rowType: RowType.QUANTITY,
                                canEdit: currentAccount.canEdit,
                                accounting: i18n.t("amount"),
                                plannedTotal: 0,
                                realizedTotal: 0,
                                historicTotal: 0,
                                apportionmentTotal: 0,
                                projectedTotal: 0,
                                spendingPackage: spendingPackageLabel
                            } as BudgetGridData,
                        ],
                    });
                }
                updateRowTotals(detailRow);
                budgetGridRow.detailList.push(detailRow);
                budgetGridRow.detailList.sort((a: BudgetGridData, b: BudgetGridData) => {
                    const aIsCurrencySelected = a.currencyId === currencySelectedId;
                    const bIsCurrencySelected = b.currencyId === currencySelectedId;
                    if (aIsCurrencySelected && !bIsCurrencySelected) {
                        return -1;
                    }
                    if (!aIsCurrencySelected && bIsCurrencySelected) {
                        return 1;
                    }
                    return 1;
                });
            });
            updateParentRowValues(budgetGridRow);
            return budgetGridRow;
        } else {
            const spendingPackageLabel: string = spendingPackageOptions.find((sp) => sp.value === budgetOpening.spendingPackage)?.label;
            const budgetGridRow = {
                ...budgetOpening,
                budgetOpeningIndexerLink: budgetOpening.budgetOpeningIndexerLink || [],
                isOpeningInOtherCurrency,
                plannedMonths: cloneDeep(plannedMonthsObject),
                rowType: RowType.PLANNED,
                detailIdList: [],
                detailList: [],
                accounting: accountName,
                key: budgetOpening.budgetOpening.id
                    ? budgetOpening.budgetOpening.id.toString()
                    : budgetOpening.budgetOpening.accountId.toString(),
                currencyId: budgetOpening.plannedMonths[0].currencyId,
                currencyIsoCode,
                canEdit: currentAccount.canEdit,
                plannedTotal: 0,
                realizedTotal: 0,
                apportionmentTotal: 0,
                historicTotal: 0,
                projectedTotal: 0,
                spendingPackage: spendingPackageLabel
            };
            updateRowTotals(budgetGridRow);
            return budgetGridRow;
        }
    }).filter(item => item)
        .sort((a, b) => a.accounting.localeCompare(b.accounting))
    return gridData;
}
