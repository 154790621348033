import { ICurrencyFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import moment from "moment";

export interface IModalFilterProps {
	module: ModulesType;
	onApply: (flexFieldValuesIds: IObjectOnApplyFilter) => void;
	filterSelected: IObjectOnApplyFilter;
}

export interface IModalFilterProviderProps {
	children: React.ReactNode;
}

export interface ModalFilterContextData {
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isOpen: boolean;
	selectedRowKeysObject: SelectedRowKeysObject;
	setSelectedRowKeysObject: React.Dispatch<React.SetStateAction<SelectedRowKeysObject>>;
	cleanFilters: () => void;
	filtersSelected: IObjectOnApplyFilter;
	setFiltersSelected: React.Dispatch<React.SetStateAction<IObjectOnApplyFilter>>;
}

export interface IOptionCardProps {
	title: string;
	count: number;
	onClick: () => void;
	isSelected: boolean;
}

export enum ModulesType {
	EXPENSES,
	HUMAN_RESOURCES,
	REVENUE,
	EXPENSES_RESOURCES,
	RECURRING_REVENUE,
	PLANNING
}

export interface OptionsData {
	key: number;
	description: string;
}

export interface SelectedRowKeysObject {
	[key: string]: number[];
}

export interface FlexFieldOptions {
	id: number;
	description: string;
	flexFieldValueIds: number[];
	flexFieldValues: ICurrencyFilter[];
}

export interface IValuesTableProps {
	data: ICurrencyFilter[];
	selectedObject: SelectedRowKeysObject;
	setSelectedObject: React.Dispatch<React.SetStateAction<SelectedRowKeysObject>>;
	flexFieldSelected: number;
}

export interface IObjectOnApplyFilter {
	basePeriod: moment.Moment;
	flexFieldsSelected: ICurrencyFilter[];
}