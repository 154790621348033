import { Icon } from "@iconify/react";
import './Carousel.sass';

export const carouselDefaultProps = {
	arrows: true,
	prevArrow: <Icon hFlip icon="material-symbols:arrow-forward-ios-rounded" />,
	nextArrow: <Icon icon="material-symbols:arrow-forward-ios-rounded" />,
	dots: false,
	infinite: false,
	className: "gs-carousel"
}
