import moment from "moment";

function getBimester(monthIndex) {
    return Math.floor((monthIndex - 1) / 2) + 1;
}

function getTrimester(monthIndex) {
    return Math.floor((monthIndex - 1) / 3) + 1;
}

function getSemester(monthIndex) {
    return Math.floor((monthIndex - 1) / 6) + 1;
}

export function formatToBimester(columnsList: any) {
    let currentBimonthYear = "";

    columnsList.forEach(category => {
        category.children.forEach(bimonth => {
            const bimonthYear = bimonth.dataIndex.split('-')[0];
            const bimonthMonth = parseInt(bimonth.dataIndex.split('-')[1], 10);

            const bimester = getBimester(bimonthMonth);

            if (bimonthYear === currentBimonthYear) {
                bimonth.title = `${bimester}º Bimestre (${bimonthYear})`;
            } else {
                bimonth.title = `${bimester}º Bimestre (${bimonthYear})`;
                currentBimonthYear = bimonthYear;
            }
        })
    })
}

export function formatToTrimester(columnsList: any) {
    let currentTrimesterYear = "";

    columnsList.forEach(category => {
        category.children.forEach(trimester => {
            const trimesterYear = trimester.dataIndex.split('-')[0];
            const trimesterMonth = parseInt(trimester.dataIndex.split('-')[1], 10);

            const trimesterNumber = getTrimester(trimesterMonth);

            if (trimesterYear === currentTrimesterYear) {
                trimester.title = `${trimesterNumber}º Trimestre (${trimesterYear})`;
            } else {
                trimester.title = `${trimesterNumber}º Trimestre (${trimesterYear})`;
                currentTrimesterYear = trimesterYear;
            }
        })
    })
}

export function formatToSemester(columnsList: any) {
    let currentSemesterYear = "";

    columnsList.forEach(category => {
        category.children.forEach(semester => {
            const semesterYear = semester.dataIndex.split('-')[0];
            const semesterMonth = parseInt(semester.dataIndex.split('-')[1], 10);

            const semesterNth = getSemester(semesterMonth);

            if (semesterYear === currentSemesterYear) {
                semester.title = `${semesterNth}º Semestre (${semesterYear})`;
            } else {
                semester.title = `${semesterNth}º Semestre (${semesterYear})`;
                currentSemesterYear = semesterYear;
            }
        })
    })
}

export function formatToYear(columnsList: any) {
    columnsList.forEach(category => {
        category.children.forEach(column => {
            column.title = moment(column.dataIndex.split('-')[0]).format('YYYY');
        })
    })
}
