import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from "./useCachedQuery";
import { RequestType } from "util/service/IServiceCaller";

type AccountAccountingOptions = {
    canEdit: boolean;
    value: number;
    label: string;
};

export function useAccountAccountingOptions(costCenterId = '', queryProps = {} as UseQueryOptions<AccountAccountingOptions[]>) {
    const queryInfo = useCachedQuery<AccountAccountingOptions[]>(
        ['account-accounting-options', costCenterId.toString()],
        {
            type: RequestType.GET,
            url: `/budget-base/accounting-account?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}${costCenterId.toString().length > 0 ? `&costCenter=${costCenterId}` : ''}`,
        },
        queryProps
    )
    
    return queryInfo;
}

