import { Icon } from "@iconify/react";
import { Tag as AntdTag } from "antd";
import { useRef } from "react";
import ProjectionModelingModal from "./ProjectionModelingModal";
import * as React from "react";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { IExpressions } from "../IStepTwo";
import { Options } from "util/types/types";
import { IFieldList } from "../../../IProjectionModeling";

interface ITagProps {
    children: any;
    isMathOperation?: boolean;
    itemIndex: number;
    isParenthesis?: boolean;
    removeItem: (index: any) => void;
    plainFormula?: IExpressions[];
    setPlainFormula?: React.Dispatch<React.SetStateAction<IExpressions[]>>;
    accountingList?: IModalityFilter[];
    projectionsOptions?: Options[];
    isEditingProjection?: boolean;
    fieldList?: IFieldList[];
}

export function Tag({
    children,
    itemIndex,
    removeItem,
    plainFormula,
    setPlainFormula,
    isMathOperation = false,
    isParenthesis = false,
    accountingList,
    projectionsOptions,
    isEditingProjection = false,
    fieldList,
}: ITagProps) {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const tagRef = useRef(null);

    function handleMouseOver() {
        const closeButton = tagRef.current.querySelector("span");
        closeButton.style.visibility = "initial";
    }

    function handleMouseOut() {
        const closeButton = tagRef.current.querySelector("span");
        closeButton.style.visibility = "hidden";
    }

    const formattedChildren =
        typeof children === "string"
            ? children.replace("_", ".")
            : React.isValidElement(children) || Array.isArray(children)
            ? children
            : String(children);

    function openModal(): void {
        setModalOpen(true);
    }

    const handleCancelModal = (): void => {
        setModalOpen(false);
    };

    const fieldItem = fieldList?.find((field) => field.label === formattedChildren);

    return (
        <>
            <AntdTag
                ref={tagRef}
                className="attribute_parameterization-new-attribute-modal-formula-workarea-tag"
                closable
                onClose={() => removeItem(itemIndex)}
                style={{
                    color: "#0065B3",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: isMathOperation ? "transparent" : "",
                    border: isMathOperation ? "none" : "",
                    transform: isParenthesis && "translateY(15%)",
                }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                {fieldItem ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {isEditingProjection ? (
                            <>{formattedChildren}</>
                        ) : (
                            <>
                                {formattedChildren}
                                <Icon
                                    icon="fa6-solid:gear"
                                    style={{ marginTop: 1, marginLeft: 6, cursor: "pointer" }}
                                    onClick={openModal}
                                />
                            </>
                        )}
                    </div>
                ) : (
                    formattedChildren
                )}
            </AntdTag>
            <ProjectionModelingModal
                accountingList={accountingList}
                projectionsOptions={projectionsOptions}
                itemIndex={itemIndex}
                isModalVisible={modalOpen}
                handleCancel={handleCancelModal}
                plainFormula={plainFormula}
                setPlainFormula={setPlainFormula}
            />
        </>
    );
}
