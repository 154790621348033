export function validateMonetaryInput(event, allowNegative = true) {
  const keysAvailable = ['backspace', 'delete', 'arrowright', 'arrowleft'];
  const key = event.key;
  const inputValue = event.target.value;
  let regexPattern: RegExp;

  if (allowNegative) {
    regexPattern = /^-?[0-9,]*(\.[0-9]*)?$/;
  } else {
    regexPattern = /^[0-9,]*(\.[0-9]*)?$/;
  }
  
  if (
    (!regexPattern.test(key) && !keysAvailable.includes(key.toLowerCase()) && !event.ctrlKey) ||
    (key === ',' && inputValue.includes(key)) ||
    (key === '.' && inputValue.includes('.')) ||
    (key === '.' && inputValue.includes(',')) ||
    (key === '-' && inputValue.includes('-')) ||
    (key === '-' && inputValue.includes(',') && inputValue.lastIndexOf(',') > 0) ||
    (key === '-' && inputValue.includes('.') && inputValue.lastIndexOf('.') > 0) ||
    (!allowNegative && inputValue.includes('-'))
  ) {
    event.returnValue = false;
    if (event.preventDefault) event.preventDefault();
  }
}
 