import { Icon } from "@iconify/react";
import { ITransferList } from "../../IHiredDashboard";
import { Row, Col, Button } from "antd";
import moment from "moment";
import i18n from "util/base/i18n";
import { ImageBox } from "components/imageBox/ImageBox";

export default function TransferList({ transferList, isOnFinalStep, deleteFunction, editFunction, isOrigin}: ITransferList) {

	return (
		<div className="list-of-added-items">
			{transferList.length ?
				<>
					<div className="cost-category-list-header">
						<Row align="middle" gutter={3}>
							<Col span={isOnFinalStep ? 4 : 3} >{i18n.t("business_unit")}</Col>
							<Col span={isOnFinalStep ? 4 : 3} >{i18n.t("cost_center")}</Col>
							<Col span={4} >{i18n.t("cost_category")}</Col>
							<Col span={4} >{i18n.t("professional_category.professional_category_name")}</Col>
							<Col span={3} >{i18n.t("collaborator")}</Col>
							<Col span={2} >{i18n.t("amount")}</Col>
							<Col span={1} >{i18n.t("type")}</Col>
							<Col span={2} >{i18n.t("period")}</Col>
						</Row>
					</div>
					<div className="cost-category-list-content">
						{transferList.map((transfer) => {
							return (
								<Row key={transfer.resource.value} align="middle" gutter={3}>
									{isOrigin ?
										<>
											<Col className="table-collaboratos-col" span={isOnFinalStep ? 4 : 3} style={{ justifyContent: 'start' }}>{transfer.originBusiness.title}</Col>
											<Col className="table-collaboratos-col" span={isOnFinalStep ? 4 : 3} style={{ justifyContent: 'start' }}>{transfer.originCostCenter.title}</Col>
											<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{transfer.originCostCategory.title}</Col>
											<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{transfer.originProfessionalCategory.title}</Col>
											<Col className="table-collaboratos-col" span={3} style={{ justifyContent: 'start' }}>{transfer.resource.title}</Col>
											<Col className="table-collaboratos-col" span={2} style={{ justifyContent: 'start' }}>{transfer.amount}</Col>
											<Col className="table-collaboratos-col icon-transfer" span={1} style={{ justifyContent: 'start' }}>{
												<Icon icon={transfer.type.includes("transfer") ? "mingcute:transfer-3-line" : "mdi:prize"} />
											}</Col>
											<Col span={2} style={{ justifyContent: 'start' }}>{transfer.period}</Col>
										</>
									:
										<>
											<Col className="table-collaboratos-col" span={isOnFinalStep ? 4 : 3} style={{ justifyContent: 'start' }}>{transfer.business.title}</Col>
											<Col className="table-collaboratos-col" span={isOnFinalStep ? 4 : 3} style={{ justifyContent: 'start' }}>{transfer.costCenter.title}</Col>
											<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{transfer.costCategory.title}</Col>
											<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{transfer.professionalCategory.title}</Col>
											<Col className="table-collaboratos-col" span={3} style={{ justifyContent: 'start' }}>{transfer.resource.title}</Col>
											<Col className="table-collaboratos-col" span={2} style={{ justifyContent: 'start' }}>{transfer.amount}</Col>
											<Col className="table-collaboratos-col icon-transfer" span={1} style={{ justifyContent: 'start' }}>{
												<Icon icon={transfer.type.includes("transfer") ? "mingcute:transfer-3-line" : "mdi:prize"} />
											}</Col>
											<Col span={2} style={{ justifyContent: 'start' }}>{transfer.period}</Col>
										</>
									}
									{!isOnFinalStep &&
										<>
											<Col span={1} >
												<Button
													onClick={() => editFunction(transfer)}
													icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
												/>
											</Col>
											<Col span={1} >
												<Icon
													className="delete-icon"
													onClick={() => deleteFunction(transfer.key)}
													icon="fa6-solid:trash"
												/>
											</Col>
										</>
									}
								</Row>
							)
						})}
					</div>
				</>
				:
				<ImageBox styles={{borderTop: '1px solid #ccc', paddingTop: 28}}/>
			}
		</div>
	)
}
