import { useContext, useEffect, useState } from "react";
import ConsolidationEliminationTable from "./grid/ConsolidationEliminationTable";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { cloneDeep } from "lodash";
import { child, IStepOne, organization, OrganizationFlex } from "../../IConsolidation";
import { ErrorRequest } from "util/types/types";
import HeaderGrid from "../components/HeaderGrid";
import { ConsolidationEliminationContext } from "../context/ConsolidationEliminationContext";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";

export default function StepOne({
    consolidationId,
    selectedRows,
    currentStep
}: IStepOne) {

    const [treeGridData, setTreeGridData] = useState<organization[]>([]);
    const [filteredTreeGrid, setFilteredTreeGrid] = useState<organization[]>([]);
    const [isFetchingTreeGrid, setIsFetchingTreeGrid] = useState<boolean>(true);
    const [hasSavedValue, setHasSavedValue] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { scenarioSelected, setOrganizationIds } = useContext(ConsolidationEliminationContext);

    const importProps: IImportModal[] = [
        {
            importUrl: "planning/consolidation/import-consolidation-rate",
            templateUrl: "planning/consolidation/template-rate?locale={locale}",
            urlParams: `&consolidationId=${consolidationId}`,
            type: 'excel',
        },
    ];

    useEffect(() => {
        if (scenarioSelected.length === 0) return;
        fetchRates(scenarioSelected[0].value);
    }, [scenarioSelected]);

    async function fetchRates(scenarioId = 0) {
        setIsFetchingTreeGrid(true);
        const response: any = await ServiceCaller.doAsyncRequest({
            type: RequestType.GET,
            url: `/planning/consolidation/find-rates?` +
                (scenarioId > 0 ? `scenario=${scenarioId}&` : '') +
                `consolidation=${consolidationId}&client={client}&user={user}&locale={locale}`
        })

        response.success ? onLoadTreeGridData(response.data) : handleErrorRequest(response.data as any);
    }

    const transformTreeGridData = (dataArray) => {
        const organizationIds: number[] = [];
        const grid: any[] = dataArray.map((data) => {
            const organizationFlex =
                data.organizationFlex && data.organizationFlex.length > 0
                    ? data.organizationFlex
                    : [{ ...data, organizationFlex: null }];

            organizationIds.push(data.id);
            return {
                ...data,
                key: data.id,
                editable: true,
                children: organizationFlex.map((org) => ({
                    ...org,
                    key: `${data.id}-${org.id}`,
                    children: Object.keys(org.monthRate).map((year) => ({
                        name: year,
                        key: `${data.id}-${org.id}-${year}`,
                        organization: data.id,
                        organizationFlex: org.id,
                        ...org.monthRate[year],
                    })),
                    editable: true,
                })),
            };
        });

        setOrganizationIds(organizationIds);
        return grid;
    };

    function onLoadTreeGridData(data) {
        if (data.length === 0) {
            setTreeGridData([]);
            setFilteredTreeGrid([]);
            setIsFetchingTreeGrid(false);
            return;
        };

        setTreeGridData(transformTreeGridData(data));
        setFilteredTreeGrid(transformTreeGridData(data));
        setIsFetchingTreeGrid(false);
    };

    async function handleSaveOrReplicateRate(data: child, rate: number, month: string, isReplaceAll = false) {

        const monthToConvert = isReplaceAll ? "JANUARY" : month;
        const newMonth = new Date(Date.parse(monthToConvert + " 1, 2020"));

        const dataToSave = {
            consolidation: consolidationId,
            scenario: scenarioSelected[0].value,
            organization: data.organization,
            organizationFlex: data.organization !== data.organizationFlex ? data.organizationFlex : null,
            yearMonth: `${data.name}-${String(newMonth.getMonth() + 1).padStart(2, '0')}`,
            rate: rate,
        }

        const response = await ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `/planning/consolidation/` + (isReplaceAll ? `save-all-rates` : `save-rate`),
            params: dataToSave
        });

        if (response.success) {
            const updatedData = cloneDeep(treeGridData);
            const organization = updatedData.find((org) => org.key === data.organization);
            const organizationFlex = organization.children.find((item) => item.id === data.organizationFlex);
            const year = organizationFlex.children.find((year) => year.name === data.name);

            const updateTreeGridData = (month, rate) => {
                if (isReplaceAll) {
                    Object.keys(year).forEach((month: any) => {
                        if (month !== 'key' && month !== 'name' && month !== 'organization' && month !== 'organizationFlex') {
                            year[month] = parseFloat(rate);
                        }
                    })
                } else {
                    year[month] = parseFloat(rate);
                }

                return updatedData;
            };

            const updatedTreeGridData = updateTreeGridData(month, rate);
            const updatedFilteredTreeGrid = updateTreeGridData(month, rate);

            const children = organization.children.find((item) => `${item.key}-${year.name}` === data.key);
            applyEditedValueStyle(children, year.name);

            setTreeGridData(updatedTreeGridData);
            setFilteredTreeGrid(updatedFilteredTreeGrid);
            setIsLoading(false);
            setHasSavedValue(true);
        } else {
            handleErrorRequest(response.data as ErrorRequest);
        }
    }

    function applyEditedValueStyle(children: OrganizationFlex, year: string) {
        if (children?.children) {
            children.children.forEach((child) => {
                if (child.name === year) {
                    child.isEdited = 1;
                }
            });
        }
    }

    function searchByName(searchTerm) {
        const result = [];

        treeGridData.forEach(item => {
            let parentClone = null;

            if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                parentClone = { ...item, children: item.children.slice() };
                result.push(parentClone);
            } else {
                const matchingChildren = item.children.filter(child =>
                    child.name.toLowerCase().includes(searchTerm.toLowerCase())
                );

                if (matchingChildren.length > 0) {
                    parentClone = { ...item, children: matchingChildren };
                    result.push(parentClone);
                }
            }
        });

        setFilteredTreeGrid(result);
    }

    return (
        <div className="steps-consolidation">
            <div style={{ border: '0.2px solid #D7D7D7' }}>
                <HeaderGrid
                    isLoading={isLoading}
                    hasSavedValue={hasSavedValue}
                    search={searchByName}
                    selectedRows={selectedRows}
                    currentStep={currentStep}
                    consolidationId={consolidationId}
                    fetchRates={fetchRates}
                    importProps={importProps}
                    handleSuccessImport={() => fetchRates(scenarioSelected[0].value)}
                />
                <ConsolidationEliminationTable
                    isFetching={isFetchingTreeGrid}
                    treeGridData={treeGridData}
                    handleSaveOrReplicateRate={handleSaveOrReplicateRate}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    hasSavedValue={hasSavedValue}
                    filteredTreeGrid={filteredTreeGrid}
                    setFilteredTreeGrid={setFilteredTreeGrid}
                />
            </div>
        </div>
    )
}