import { Table } from "antd";
import { IContractValuesTableProps } from "../IContractValues";
import i18n from "util/base/i18n";

export default function ContractValuesTable({
    columns,
    dataTable,
    isFetching
}: IContractValuesTableProps) {

    return (
        <div className="table-contract-values" >
            <Table
                loading={{
                    spinning: isFetching,
                    tip: `${i18n.t<string>("loading")}...`
                }}
                scroll={{ x: true }}
                className="gs-table"
                dataSource={dataTable}
                columns={columns}
                pagination={false}
                bordered
            />
        </div >
    )
}