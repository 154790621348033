import { Icon } from "@iconify/react";
import { Checkbox, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CSSProperties } from "react";
import i18n from "util/base/i18n";

interface FilterHeaderProps {
	hasSearch: boolean;
	searchPlaceholder: string;
	setSearch: (search: string) => void
	indeterminate?: boolean;
	onCheckAllChange?: (e: CheckboxChangeEvent) => void;
	checkAll?: boolean;
	type: "checkbox" | "radio" | "select" | "datepicker";
	headerStyles?: CSSProperties;
	showCheckAll?: boolean;
}

export function FilterHeader({
	hasSearch,
	searchPlaceholder,
	setSearch,
	indeterminate,
	onCheckAllChange,
	checkAll,
	type,
	headerStyles = {},
	showCheckAll =  true
}: FilterHeaderProps) {
	return (
		<div
			style={{
				background: hasSearch || (type !== 'radio' && type !== 'select') ? '#F1F2F3' : '#fff',
				padding: hasSearch || (type !== 'radio' && type !== 'select') ? 15 : '5px',
				...headerStyles,
			}}
			className="filter-header"
		>
			{hasSearch &&
				<Input
					autoFocus
					className="search-list-filter"
					onChange={(e) => setSearch(e.target.value)}
					placeholder={searchPlaceholder}
					addonAfter={<Icon icon="charm:search" />}
				/>
			}
			{type === "checkbox" && showCheckAll ?
				<Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
					{i18n.t("select_all")}
				</Checkbox>
				: null
			}
		</div>
	)
}