import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { InputNumber } from "antd";

import { FormulaItem } from "../../IAttributeParameterization";
import { Tag } from "../Tag";
import {
	DivideOp,
	LeftParenthesisOp,
	MinusOp,
	PlusOp,
	RightParenthesisOp,
	TimesOp,
} from "../operators/MathOperators";

interface IFormulaAreaProps {
	formula: FormulaItem[];
	removeItem: (index: number) => void;
	hasValueOperator?: boolean;
	setFormula?: React.Dispatch<React.SetStateAction<FormulaItem[]>>;
}

export function FormulaArea({ formula, removeItem, hasValueOperator = false, setFormula }: IFormulaAreaProps) {
	function handleDragEnd(result) {
		if (!result.destination) return;
		const items = Array.from(formula);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setFormula(items);
	}

	const formulaWithOperatorKeys = formula.map((item) => {
		if (item.type === "operator") {
			return {
				...item,
				key: `OPERATORS-${Math.random()}`,
			};
		}

		return item;
	});

	function onChaneValueInput(newValue: number, ordination): void {
		setFormula(state => {
			let itemToChange = state[ordination];
			itemToChange = { ...itemToChange, value: newValue };
			state[ordination] = itemToChange;
			return state;
		})
	}

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable
				droppableId="attribute_parameterization-new-attribute-modal-formula-workarea"
				direction="horizontal"
			>
				{(provided) => (
					<div
						className="attribute_parameterization-new-attribute-modal-formula-workarea"
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{formulaWithOperatorKeys.map((item, index) => {
							const draggableId = item.type === "operator" ? item.content : "" + item.content.id;

							return (
								<Draggable
									key={(item.type === "operator" || item.type === "value") ? item.key : "" + item.content.id}
									draggableId={draggableId}
									index={index}
								>
									{(provided) => {
										if (item.type === "operator") {
											switch (item.content) {
												case "plus":
													return (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<Tag
																isMathOperation
																itemIndex={index}
																removeItem={removeItem}
															>
																<PlusOp />
															</Tag>
														</div>
													);
												case "minus":
													return (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<Tag
																itemIndex={index}
																isMathOperation
																removeItem={removeItem}
															>
																<MinusOp />
															</Tag>
														</div>
													);
												case "times":
													return (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<Tag
																itemIndex={index}
																removeItem={removeItem}
																isMathOperation
															>
																<TimesOp />
															</Tag>
														</div>
													);
												case "divide":
													return (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<Tag
																itemIndex={index}
																removeItem={removeItem}
																isMathOperation
															>
																<DivideOp />
															</Tag>
														</div>
													);
												case "left_parenthesis":
													return (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<Tag
																itemIndex={index}
																isMathOperation
																removeItem={removeItem}
																isParenthesis
															>
																<LeftParenthesisOp />
															</Tag>
														</div>
													);
												case "right_parenthesis":
													return (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<Tag
																itemIndex={index}
																isMathOperation
																removeItem={removeItem}
																isParenthesis
															>
																<RightParenthesisOp />
															</Tag>
														</div>
													);
												case "value":

											}
										}

										if (item.type === "attribute") {
											return (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<Tag
														itemIndex={index}
														removeItem={removeItem}
													>
														{item.content.name}
													</Tag>
												</div>
											);
										}

										if (item.type === "value") {
											return (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<Tag
														itemIndex={index}
														removeItem={removeItem}
														isMathOperation
													>
														<InputNumber onChange={(newValue) => onChaneValueInput(newValue, index)} value={item.value} controls={false} />
													</Tag>
												</div>
											)
										}

										return null;
									}}
								</Draggable>
							);
						})}

						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}
