import { Button, Input, Radio, RadioChangeEvent, Space, Select, Row, Col, Form, Popover } from "antd";
import { useState } from "react";
import StringUtil from "util/StringUtil";
import { FlexFieldValues, HiredTable } from "../IHumanResoucers";
import i18n from "util/base/i18n";
const { Search } = Input

interface Props {
	onCloseSearch: (boolean) => void;
	hiredList: HiredTable[];
	setDeitalId: (id: number) => void;
}
export function CollaboratorSearch({ onCloseSearch, hiredList, setDeitalId }: Props) {
	const [searchValue, setSearchValue] = useState('');
	const [value, setValue] = useState(0);
	const { getWithoutSpecials } = StringUtil;

	function onChange(e: RadioChangeEvent) {
		setValue(e.target.value);
	};

	function onSearchCollaborator(e) {
		setSearchValue(e.target.value);
	};

	const contentPopover = (operationgUnit: string, costCenter: string, professionalCategory: string, costCategory: string, flexField: FlexFieldValues[]) => {
		return (
			<div className="popover-detail">
				<Row gutter={2}>
					<Col className="title-content" span={12}>
						{i18n.t("operating_unit")}
					</Col>
					<Col className="text-content" span={12}>
						{operationgUnit}
					</Col>
				</Row >
				<Row gutter={2}>
					<Col className="title-content" span={12}>
						{i18n.t("cost_center")}
					</Col>
					<Col className="text-content" span={12}>
						{costCenter}
					</Col>
				</Row>
				<Row gutter={2}>
					<Col className="title-content" span={12}>
						{i18n.t("professional_category.professional_category_name")}
					</Col>
					<Col className="text-content" span={12}>
						{professionalCategory}
					</Col>
				</Row>
				<Row>
					<Col className="title-content" span={12}>
						{i18n.t("cost_category")}
					</Col>
					<Col className="text-content" span={12}>
						{costCategory}
					</Col>
				</Row>
				{
					flexField.map(({ flexFieldName, flexFieldValueName }) => {
						return (
							<Row>
								<Col className="title-content" span={12}>
									{flexFieldName}
								</Col>
								<Col className="text-content" span={12}>
									{flexFieldValueName}
								</Col>
							</Row>
						)
					})
				}
			</div >
		)
	}

	function onClickClose() {
		onCloseSearch(false);
		setValue(0);
		setSearchValue("");
	}

	function onSelect(id: number) {
		onClickClose();
		setDeitalId(id);
	}

	const filteredCollabortors = hiredList.filter(hired => {
		if (!searchValue) return true
		const stringRegex = getWithoutSpecials(searchValue.trim()).split(" ").map(text => `(?=.*${text})`).join("") + ".*";
		const regex = new RegExp(stringRegex);
		const objectToCompare = {
			name: hired.resourceName,
			professionalCategory: hired.professionalCategory.label,
			costCenter: hired.costCenter.label,
			costCategory: hired.costCategory.label,
			businessUnit: hired.businessUnit.label,
			flexFieldValue: hired.flexFields.map(({ flexFieldValueName }) => flexFieldValueName).toString()
		}
		return regex.exec(getWithoutSpecials(Object.values(objectToCompare).toString().replaceAll(",", " ")));
	}
	)

	return (
		<div className="collaborator-search-container">
			<Form layout="vertical">
				<div className="collaborator-search-input-container">
					<Search value={searchValue} onChange={onSearchCollaborator} />
				</div>
				<div className="collaborator-list-search">
					<h4>
						{i18n.t("human_resources.human_resources")}
					</h4>
					{
						filteredCollabortors.length > 0 ?
							<Radio.Group onChange={onChange} value={value}>
								<Space direction="vertical">
									{filteredCollabortors.map(item => {
										return <Radio key={item.id} value={item.id}>
											<Row gutter={3} >
												<Col span={14}>
													{item.resourceName}
												</Col>
												<Col span={10}>
													<Popover overlayClassName="collaborator-search-overlay" content={
														contentPopover(
															item.businessUnit.label,
															item.costCenter.label,
															item.professionalCategory.label,
															item.costCategory.label,
															item.flexFields
														)}>
														<span className="gs-tag blue">{item.costCenter.label}</span>
													</Popover>
												</Col>
											</Row>
										</Radio>
									})}
								</Space>
							</Radio.Group>
							:
							<p style={{ margin: 20, textAlign: 'center' }}>{i18n.t("human_resources.no_contributors_found")}.</p>
					}
				</div>
				<footer className="collaborator-search-footer">
					<Button type="link" onClick={onClickClose}>{i18n.t("close")}</Button>
					<Button disabled={value === 0 ? true : false} onClick={() => onSelect(value)} className="gs-main-button">{i18n.t("select")}</Button>
				</footer>
			</Form>
		</div>
	)
}