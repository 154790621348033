import * as React from "react";

import i18n from "util/base/i18n";

import "./Badge.sass";

import {
	Col,
	Row
} from "antd";

import Card from "../card/Card";

import {
	IBalance,
	IBadgeProps
} from "./IBadge";

import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

export default function Badge(props: IBadgeProps) {

	const [balance, setBalance]: [IBalance, Function] = React.useState({
		application: 0,
		account: 0,
		total: 0
	});

	const onLoadBalance = (balance: IBalance) => {
		setBalance(balance);
	}

	React.useEffect(() => {
		if (props.globalFilter !== null) {
			const {
				filterBy,
				values
			} = props.globalFilter;

			const filterValuesQuery = values.map(value => `filterValue=${value}`).join("&");

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/dashboard-analytics/widget/balance?filterBy=${filterBy}&${filterValuesQuery}&client={client}&user={user}&currency=${props.currencyId}&scenario={scenario}`
			}, onLoadBalance.bind(this));
		}
	}, [props.globalFilter, props.currencyId]);

	return (
		<Row gutter={[10, 10]}>
		<Col  className="gutter-row cm-step5" span={8}>
				<Card title={i18n.t<string>("total_balance")} icon="dashicons:money-alt" value={balance.total} isMonetary={true} />
			</Col>
			<Col className="gutter-row cm-step5 cm-step5-main" span={8}>
				<Card title={i18n.t<string>("application_balance")} icon="bx:trending-up" value={balance.application} isMonetary={true} />
			</Col>
			<Col className="gutter-row cm-step5" span={8}>
				<Card title={i18n.t<string>("account_balance")} icon="ant-design:wallet-outlined" value={balance.account} isMonetary={true} />
			</Col>
		</Row>
	)
}