import { Button, Space, Steps } from 'antd'
import i18n from 'util/base/i18n'
import StepOne from './stepOne/StepOne'
import StepTwo from './stepTwo/StepTwo'
import { useState } from 'react';
import { IConsolidationElimination } from '../IConsolidation';
import { ConsolidationEliminationProvider } from './context/ConsolidationEliminationContext';
import StringUtil from 'util/StringUtil';

export default function ConsolidationElimination({
    consolidationId,
    onCancel,
    selectedRows
}: IConsolidationElimination) {

    const [currentStep, setCurrentStep] = useState<number>(0);

    const isNextButtonDisabled = false;

    const renderStep = {
        0: <StepOne
            consolidationId={consolidationId}
            selectedRows={selectedRows}
            currentStep={currentStep}
        />,
        1: <StepTwo
            consolidationId={consolidationId}
            selectedRows={selectedRows}
            currentStep={currentStep}
        />,
    }

    const steps = [
        {
            key: 'step-one',
            title: i18n.t<string>("participation_rate"),
            stepNumber: 1,
            stepIndex: 0,
            disabled: false
        },
        {
            key: 'step-two',
            title: i18n.t<string>("elimination_rate"),
            stepNumber: 2,
            stepIndex: 1,
            disabled: isNextButtonDisabled
        }
    ];

    function onClickBackStep() {
        if (currentStep === 0) {
            onCancel();
        } else {
            setCurrentStep(prev => prev - 1);
        }
    }

    function onClickNextStep() {
        if (currentStep === 1) {
            onCancel();
        } else {
            setCurrentStep(prev => prev + 1);
        }
    }

    return (
        <div className="new-projection-page">
            <div className="page-title-content">
				<h1>{i18n.t("consolidation_and_elimination_fees")}</h1>
                <Steps
                    type="navigation"
                    size="small"
                    current={currentStep}
                    className="site-navigation-steps"
                >
                    {
                        steps.map(({ stepIndex, stepNumber, title, disabled }) => {
                            return <Steps.Step disabled={disabled} key={stepIndex} title={title} stepNumber={stepNumber} stepIndex={stepIndex} />
                        })
                    }
                </Steps>
            </div>
			<div className='consolidation-elimination-header'>
				<div className="open-filter-button">
					<Space direction="vertical" size={0}>
						<h4 className="filter-name">{StringUtil.capitalizeFirstLetter(i18n.t("consolidation_model"))}</h4>
						<div className="filter-values-container">
							<h3>{selectedRows[0].description}</h3>
						</div>
					</Space>
				</div>
                <div className="button-steps">
                    <Button
                        type="link"
                        onClick={onClickBackStep}
                    >
                        {i18n.t(currentStep === 0 ? "cancel" : "back")}
                    </Button>
                    <Button
                        type="primary"
                        className="gs-main-button"
                        disabled={isNextButtonDisabled}
                        onClick={onClickNextStep}
                    >
                        {i18n.t(currentStep + 1 < steps.length ? "next" : "conclude")}
                    </Button>
                </div>
            </div>
            <main className="consolidation-elimination-main">
                <ConsolidationEliminationProvider>
                    {renderStep[currentStep]}
                </ConsolidationEliminationProvider>
            </main>
        </div>
    )
}