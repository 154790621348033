import { Layout } from 'antd';

import { QueryBar } from './components/QueryBar';
import { Title } from './components/Title';

import { AttributeParameterizationProvider } from './context/AttributeParameterizationContext';

import './AttributeParameterization.sass';
import { Modals } from './components/Modals';
import { RulesTable } from './components/RulesTable';
import { Sidebar } from './components/Sidebar';

export default function AttributeParameterization() {
    return (
        <AttributeParameterizationProvider>
            <Layout id="attribute_parameterization-container" className="light">
                <Layout.Header>
                    <Title />
                </Layout.Header>
                <Layout.Content id="attribute_parameterization-content-container">
                    <QueryBar />
                    <main id="attribute_parameterization-content-main">
                        <Sidebar />
                        <RulesTable />
                        <Modals />
                    </main>
                </Layout.Content>
            </Layout>
        </AttributeParameterizationProvider>
    );
}
