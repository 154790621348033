import { Form, FormInstance, Input, InputRef, Table, Tooltip } from "antd";
import { ColumnTypes, EditableCellProps, EditableRowProps, IPremissesTable, TableDataType } from "../IPremisses";
import { tableProps } from "util/props/props";
import i18n from "util/base/i18n";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import * as moment from "moment"
import { validateMonetaryInput } from "util/functions/validateKey";
import { formatNumber } from "util/formatNumber";
import { Icon } from "@iconify/react";
import { usePremissesFilters } from "../context/usePremissesFilters";

export function PremissesTable({ premisses, setPremisses, isFetching, handleSavePremisses, monthList, columns, tableData, selectedRowKeys, onChange }: IPremissesTable) {
	const EditableContext = createContext<FormInstance<any> | null>(null);
	const { businessUnit, costCategory } = usePremissesFilters()

	const isAllBusiness = businessUnit.length === 0
	const isAllCostCategory = costCategory.length === 0
	
	function handleReplicateValues(record: TableDataType, month: string, value: number, direction: 'left' | 'right') {
		const updatedPremisses = [...premisses]

		const monthIndex = monthList.indexOf(month)
		const index = updatedPremisses.findIndex(item => item.key === record.key)
		const premisse = updatedPremisses.find(item => item.key === record.key)
		Object.keys(premisse).forEach(key => {
			if (direction === 'left') {
				const monthsToApply = monthList.slice(0, monthIndex)
				if (monthsToApply.includes(key)) {
					premisse[key] = value
				}
			} else {
				const monthsToApply = monthList.slice(monthIndex + 1)
				if (monthsToApply.includes(key)) {
					premisse[key] = value
				}
			}
		})
		updatedPremisses.splice(index, 1, premisse)
		setPremisses(updatedPremisses)
		handleSavePremisses(updatedPremisses.filter(p => p.key === record.key))
	}

	const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
		const [form] = Form.useForm();
		return (
			<Form form={form} component={false} >
				<EditableContext.Provider value={form}>
					<tr {...props} />
				</EditableContext.Provider>
			</Form>
		);
	};

	const EditableCell: React.FC<EditableCellProps> = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSaveCellValue,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef<InputRef>(null);
		const form = useContext(EditableContext)!;

		useEffect(() => {
			if (editing) {
				inputRef.current!.focus();
				inputRef.current!.select();
			}
		}, [editing]);

		const toggleEdit = () => {
			setEditing(!editing);
			form.setFieldsValue({ [dataIndex]: record[dataIndex] });
		};

		const save = async () => {
			try {
				const cellObject: { key: string } = await form.validateFields();
				const key = Object.keys(cellObject)[0]
				const month = key.split('_').pop()
				const value = Object.values(cellObject)[0] ?
					Number(Object.values(cellObject)[0].toString().replace(',', '.'))
					: 0

				toggleEdit();

				if (record[key] === value) {
					return
				}
				const updatedPremisses = [...premisses]

				if (isAllBusiness) {
					if (isAllCostCategory) {
						updatedPremisses.forEach(premisse => {
							if (premisse.field.description === record.fieldHR) {
								premisse[month] = value
							}
						})
					} else {
						updatedPremisses.forEach(premisse => {
							if (premisse.field.description === record.fieldHR &&
								premisse.costCategory.name === record.costCategory
							) {
								premisse[month] = value
							}
						})
					}
				} else if (isAllCostCategory) {
					updatedPremisses.forEach(premisse => {
						if (premisse.field.description === record.fieldHR &&
							premisse.businessUnit.name === record.businessUnit
						) {
							premisse[month] = value
						}
					})
				} else {
					updatedPremisses.forEach(premisse => {
						if (premisse.field.description === record.fieldHR &&
							premisse.costCategory.name === record.costCategory &&
							premisse.businessUnit.name === record.businessUnit
						) {
							premisse[month] = value
						}
					})
				}

				setPremisses(updatedPremisses)
				handleSavePremisses(updatedPremisses.filter(p => p.key === record.key))
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;

		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{ margin: 0, padding: '2px 4px' }}
					name={dataIndex}
				>
					<Input
						onKeyDown={validateMonetaryInput}
						ref={inputRef}
						onPressEnter={save}
						onBlur={save}
					/>
				</Form.Item>
			) : (
				<div className="premisses-table-input-cell">
					<div className="premisses-replicate-buttons-container left">
						{!dataIndex.includes('january') &&
							<Tooltip title={i18n.t("replicate_to_left")}>
								<Icon
									hFlip={true}
									onClick={() => handleReplicateValues(record, dataIndex.split('_').pop(), record[dataIndex], 'left')}
									icon="fluent:copy-arrow-right-24-regular"
									style={{ marginRight: 'auto' }}
								/>
							</Tooltip>}
					</div>
					<div className="editable-cell-value-wrap" onClick={toggleEdit}>
						{children}
					</div>
					<div className="premisses-replicate-buttons-container right">
						{!dataIndex.includes('december') &&
							<Tooltip title={i18n.t("replicate_to_right")}>
								<Icon
									onClick={() => {
										handleReplicateValues(record, dataIndex.split('_').pop(), record[dataIndex], 'right')
									}}
									icon="fluent:copy-arrow-right-24-regular"
									style={{ marginLeft: 'auto' }}
								/>
							</Tooltip>}
					</div>
				</div>
			)
				;
		}

		return <td {...restProps}>{childNode}</td>;
	};

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	const cols = columns.map(col => {
		if (!col.editable) {
			return col;
		}
		if (col.children) {
			Object.assign(col.children[0], {
				onCell: (record: TableDataType) => ({
					record,
					editable: col.editable,
					dataIndex: col.children[0].dataIndex,
					title: col.title,
					align: 'center',
				})
			}
			)
		}
		return {
			...col,
			onCell: (record: TableDataType) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				align: 'center',
			}),
		};
	});

	const rowSelection = {selectedRowKeys, onChange};

	return (
		<div className="premisses-table-container">
			<Table
				scroll={{ x: 580, y: 580 }}
				className="gs-table"
				loading={{
					spinning: isFetching,
					tip: `${i18n.t<string>("loading")}...`
				}}
				{...tableProps}
				components={components}
				columns={cols as ColumnTypes}
				dataSource={tableData}
				rowSelection={rowSelection}
				pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 10 }}
			/>
		</div>
	)
}