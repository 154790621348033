import { FormattedNumber } from "react-intl";
import { CellContent } from "../../../CellContent";

export default function buildColumn(rowsFromBackend: any[], reportVisualizationFilters: any) {
    const mappedOrganizations = mapOrganizations(rowsFromBackend).map((organization: any) => createColumnConfig(organization, rowsFromBackend, reportVisualizationFilters));
    const eliminationAndConsolidationColumns = getEliminationAndConsolidationColumns(rowsFromBackend, reportVisualizationFilters);
    return [...mappedOrganizations, ...eliminationAndConsolidationColumns];
}

export function mapOrganizations(rowsFromBackend: any[]): any {
    const organizationSet = new Set();

    return rowsFromBackend.flatMap(row =>
        row.totalOrganizations
            .filter((organization: any) => {
                if (organizationSet.has(organization.id)) {
                    return false;
                } else {
                    organizationSet.add(organization.id);
                    return true;
                }
            })
            .map((organization: any) => ({
                id: organization.id,
                description: organization.description,
            }))
    );
}

function createColumnConfig(organization: any, rowsFromBackend: any[], reportVisualizationFilters: any) {
    return {
        title: organization.description,
        dataIndex: `organization_${organization.id}`,
        key: 'total',
        width: 90,
        render: (text, record) => renderCell(organization, record, rowsFromBackend, reportVisualizationFilters)
    };
}

function renderCell(organization: any, record: any, rowsFromBackend: any[], reportVisualizationFilters: any) {
    const { currentRow, organizationItem } = findCurrentRowAndOrganizationItem(organization, record, rowsFromBackend);

    const textDecoration = getTextDecoration(record);

    if (currentRow?.isTitle) {
        return <CellContent currentRow={currentRow} textDecoration={textDecoration} />;
    }

    let totalValue = organizationItem?.totalValue || 0;
    if (reportVisualizationFilters.showThousand) {
        totalValue = totalValue / 1000;
    }

    return (
        <CellContent currentRow={currentRow} textDecoration={textDecoration}>
            <FormattedNumber
                value={totalValue}
                style="decimal"
                currencyDisplay="symbol"
                minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
            />
        </CellContent>
    );
}

function findCurrentRowAndOrganizationItem(organization: any, record: any, rowsFromBackend: any[], defaultColumn = false) {
    let currentRow, organizationItem;

    if (record?.formulaValueType) {
        currentRow = rowsFromBackend.find(columnFromBack =>
            columnFromBack.id === record.levelId &&
            columnFromBack.isFragment &&
            record.levelKey === columnFromBack.levelKey
        );
    } else if (record?.levelKey) {
        currentRow = rowsFromBackend.find(columnFromBack =>
            columnFromBack.id === record.levelId &&
            columnFromBack.isDetailPlan &&
            record.levelKey === columnFromBack.levelKey
        );
    } else if (defaultColumn) {
        currentRow = rowsFromBackend.find(columnFromBack =>
            columnFromBack.id === record.id
        );
    } else {
        currentRow = rowsFromBackend.find(columnFromBack => {
            organizationItem = columnFromBack.totalOrganizations.find(org => org.id === organization.id && record.id === columnFromBack.id);
            return !!organizationItem;
        });
    }

    return { currentRow, organizationItem };
}

function getTextDecoration(record: any) {
    let textDecoration = "";

    if (record?.style?.isScratched) {
        textDecoration += "line-through ";
    }

    if (record?.style?.isUnderlined) {
        textDecoration += "underline";
    }

    return textDecoration;
}

function getEliminationAndConsolidationColumns(rowsFromBackend: any[], reportVisualizationFilters) {
    return [
        {
            title: 'Eliminação',
            dataIndex: 'elimination',
            key: 'elimination',
            width: 90,
            fixed: 'right',
            render: (text, record) => {
                const { currentRow } = findCurrentRowAndOrganizationItem(record, record, rowsFromBackend, true);
                const textDecoration = getTextDecoration(record);

                if (currentRow?.isTitle) {
                    return <CellContent currentRow={currentRow} textDecoration={textDecoration} />;
                }

                let totalValue = currentRow?.totalElimination?.totalValue || 0;
                if (reportVisualizationFilters.showThousand) {
                    totalValue = totalValue / 1000;
                }

                return (
                    <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                        <FormattedNumber
                            value={totalValue}
                            style="decimal"
                            currencyDisplay="symbol"
                            minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                            maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        />
                    </CellContent>
                );
            }
        },
        {
            title: 'Consolidação',
            dataIndex: 'consolidation',
            key: 'consolidation',
            width: 90,
            fixed: 'right',
            render: (text, record) => {
                const { currentRow } = findCurrentRowAndOrganizationItem(record, record, rowsFromBackend, true);
                const textDecoration = getTextDecoration(record);

                if (currentRow?.isTitle) {
                    return <CellContent currentRow={currentRow} textDecoration={textDecoration} />;
                }

                let totalValue = currentRow?.totalConsolidation?.totalValue || 0;
                if (reportVisualizationFilters.showThousand) {
                    totalValue = totalValue / 1000;
                }

                return (
                    <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                        <FormattedNumber
                            value={totalValue}
                            style="decimal"
                            currencyDisplay="symbol"
                            minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                            maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        />
                    </CellContent>
                );
            }
        }
    ];
}
