import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import { IModuleTableProps, IntegrationStatus, ModuleDataResponse } from "../IBudgetIntegrations";
import { TableRowSelection } from "antd/lib/table/interface";
import { Icon } from "@iconify/react";
import moment from "moment";

export default function ModuleTable({
	onChangeSelectedTable,
	selectedRowKey,
	tableData,
	onSelectItemData
}: IModuleTableProps) {
	const columns: ColumnsType<ModuleDataResponse> = [
		{
			dataIndex: "integrationDescription",
			title: i18n.t("integrations"),
			key: "integrationDescription",
		},
		{
			dataIndex: "active",
			title: i18n.t("status"),
			key: "active",
			render: (active: boolean) => {
				return (<p className={`gs-tag ${active ? "green" : "gray"}`}>{i18n.t(active ? "active" : "inactive")}</p>)
			}
		},
		{
			dataIndex: "lastExecution",
			title: i18n.t("last_run"),
			key: "lastExecution",
			render: (date: moment.Moment) => {
				return (<p>{moment(date).format("DD/MM/YYYY")}</p>)
			}
		},
		{
			dataIndex: "firstErrorDate",
			title: i18n.t("minimum_error_date"),
			key: "firstErrorDate",
			render: (date: moment.Moment) => {
				const formattedDate = date ? moment(date).format("DD/MM/YYYY") : "-";
				return (<p>{formattedDate}</p>)
			}
		},
		{
			dataIndex: "error",
			title: i18n.t("with_error"),
			key: "error",
			render: (value: number, { module, integration }: ModuleDataResponse) => {
				return (
					<div className="status-integration-content">
						<p>
							{value}
						</p>
						{
							value > 0 &&
							<Icon
								onClick={() => onSelectItemData(module, integration, IntegrationStatus.ERROR)} icon="fa6-solid:table-list" />
						}
					</div>
				)
			}
		},
		{
			dataIndex: "processing",
			title: i18n.t("processing"),
			key: "processing",
			render: (value: number, { module, integration }: ModuleDataResponse) => {
				return (
					<div className="status-integration-content">
						<p>
							{value}
						</p>
						{
							value > 0 &&
							<Icon
								onClick={() => onSelectItemData(module, integration, IntegrationStatus.PROCESSING)} icon="fa6-solid:table-list" />
						}
					</div>
				)
			}
		},
		{
			dataIndex: "integrated",
			title: i18n.t("effective"),
			key: "integrated",
			render: (value: number, { module, integration }: ModuleDataResponse) => {
				return (
					<div className="status-integration-content">
						<p>
							{value}
						</p>
						{
							value > 0 &&
							<Icon
								onClick={() => onSelectItemData(module, integration, IntegrationStatus.INTEGRATED)} icon="fa6-solid:table-list" />
						}
					</div>
				)
			}
		},
		{
			dataIndex: "discarted",
			title: i18n.t("discarded"),
			key: "discarted",
			render: (value: number, { module, integration }: ModuleDataResponse) => {
				return (
					<div className="status-integration-content">
						<p>
							{value}
						</p>
						{
							value > 0 &&
							<Icon
								onClick={() => onSelectItemData(module, integration, IntegrationStatus.DISCARTED)} icon="fa6-solid:table-list" />
						}
					</div>
				)
			}
		},
	]

	const rowSelection: TableRowSelection<ModuleDataResponse> = { selectedRowKeys: selectedRowKey, onChange: onChangeSelectedTable, };

	return (
		<Table
			columns={columns}
			dataSource={tableData}
			className="gs-table"
			rowSelection={rowSelection}
			rowKey="integration"
			pagination={false}
		/>
	)
}