import { InputNumber } from "antd";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Options } from "util/types/types";
import { IFieldList } from "../../../IProjectionModeling";
import {
    DivideOp,
    LeftParenthesisOp,
    MinusOp,
    PlusOp,
    RightParenthesisOp,
    TimesOp,
} from "../../operators/MathOperators";
import { FormulaItem, IExpressions } from "../IStepTwo";
import { Tag } from "./Tag";

interface IFormulaAreaProps {
    formula: FormulaItem[];
    removeItem: (index: number) => void;
    hasValueOperator?: boolean;
    setFormula?: React.Dispatch<React.SetStateAction<FormulaItem[]>>;
    plainFormula?: IExpressions[];
    setPlainFormula: React.Dispatch<React.SetStateAction<IExpressions[]>>;
    accountingList?: IModalityFilter[];
    projectionsOptions?: Options[];
    isEditingProjection?: boolean;
    fieldList: IFieldList[];
}

export function FormulaArea({
    formula,
    removeItem,
    hasValueOperator = false,
    setFormula,
    plainFormula,
    setPlainFormula,
    accountingList,
    projectionsOptions,
    isEditingProjection = false,
    fieldList,
}: IFormulaAreaProps) {
    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(formula);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setFormula(items);
    };

	const formulaWithOperatorKeys = formula.map((item) => {
        if (item.type === "field") {
            return {
                ...item,
                key: `${item.content.id}-${Math.random()}`,
            };
        }
        if (item.type === "operator") {
            return {
                ...item,
                key: `OPERATORS-${Math.random()}`,
            };
        }

        return item;
	});

    function onChangeValueInput(newValue: number, ordination: number): void {
        setFormula((state) => {
            const newState: FormulaItem[] = [...state];
            let itemToChange: FormulaItem = newState[ordination];
            itemToChange = { ...itemToChange, informedValue: newValue };
            newState[ordination] = itemToChange;
            return newState;
        });
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
                droppableId="attribute_parameterization-new-attribute-modal-formula-workarea"
                direction="horizontal"
            >
                {(provided) => (
                    <div
                        className="attribute_parameterization-new-attribute-modal-formula-workarea"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {formulaWithOperatorKeys.map((item, index) => {
                            return (
                                <Draggable key={item.key} draggableId={item.key} index={index}>
                                    {(provided) => {
                                        if (item.type === "operator") {
                                            switch (item.content) {
                                                case "PLUS":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                isMathOperation
                                                                itemIndex={index}
                                                                removeItem={removeItem}
                                                            >
                                                                <PlusOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "LESS":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                isMathOperation
                                                                removeItem={removeItem}
                                                            >
                                                                <MinusOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "MULTIPLY":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                removeItem={removeItem}
                                                                isMathOperation
                                                            >
                                                                <TimesOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "DIVIDER":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                removeItem={removeItem}
                                                                isMathOperation
                                                            >
                                                                <DivideOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "LEFT_PARENTHESIS":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                isMathOperation
                                                                removeItem={removeItem}
                                                                isParenthesis
                                                            >
                                                                <LeftParenthesisOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "RIGHT_PARENTHESIS":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                isMathOperation
                                                                removeItem={removeItem}
                                                                isParenthesis
                                                            >
                                                                <RightParenthesisOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                            }
                                        }

                                        if (item.type === "field") {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Tag
                                                        itemIndex={index}
                                                        removeItem={removeItem}
                                                        plainFormula={plainFormula}
                                                        setPlainFormula={setPlainFormula}
                                                        accountingList={accountingList}
                                                        projectionsOptions={projectionsOptions}
                                                        isEditingProjection={isEditingProjection}
                                                        fieldList={fieldList}
                                                    >
                                                        {item.content.name}
                                                    </Tag>
                                                </div>
                                            );
                                        }

                                        if (item.type === "value") {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Tag
                                                        itemIndex={index}
                                                        removeItem={removeItem}
                                                        accountingList={accountingList}
                                                        isMathOperation
                                                    >
                                                        <InputNumber
                                                            onChange={(newValue) => onChangeValueInput(newValue, index)}
                                                            value={
                                                                item?.informedValue
                                                                    ? item.informedValue
                                                                    : item?.content?.id
                                                            }
                                                            controls={false}
                                                            formatter={(value) => {
                                                                return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                            }}
                                                            parser={(value) => {
                                                                const parsedValue = parseFloat(
                                                                    value.replace(/,/g, ".")
                                                                );
                                                                return isNaN(parsedValue) ? 0 : parsedValue;
                                                            }}
                                                        />
                                                    </Tag>
                                                </div>
                                            );
                                        }

                                        if (item.type === "percentage") {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Tag
                                                        itemIndex={index}
                                                        removeItem={removeItem}
                                                        isMathOperation
                                                        accountingList={accountingList}
                                                    >
                                                        <div style={{ display: "flex", alignItems: "baseline" }}>
                                                            <InputNumber
                                                                style={{ alignItems: "end" }}
                                                                onChange={(newValue) =>
                                                                    onChangeValueInput(newValue, index)
                                                                }
                                                                value={
                                                                    item?.informedValue
                                                                        ? item.informedValue
                                                                        : item?.content?.id
                                                                        ? item.content.id.replace("%", "")
                                                                        : ""
                                                                }
                                                                controls={false}
                                                                formatter={(value) => {
                                                                    if (value === undefined || value === null) {
                                                                        return "";
                                                                    }
                                                                    return `${value}`.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ","
                                                                    );
                                                                }}
                                                                parser={(value) => {
                                                                    if (!value) {
                                                                        return 0;
                                                                    }
                                                                    const parsedValue = parseFloat(
                                                                        value.replace(/,/g, "")
                                                                    );
                                                                    return isNaN(parsedValue) ? 0 : parsedValue;
                                                                }}
                                                            />
                                                            <p style={{ marginLeft: "4px", fontWeight: "bold" }}>%</p>
                                                        </div>
                                                    </Tag>
                                                </div>
                                            );
                                        }

                                        return null;
                                    }}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
