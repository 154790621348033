import {
	useEffect,
	useState
} from "react";

import {
	Chart,
	DateRange
} from "components";

import {
	Button,
	Tooltip
} from "antd";

import { Icon } from "@iconify/react";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";

import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import {
	ChartData,
	IChartWidgetProps,
	IWidgetResponse
} from "./IChartWidget";

import {
	ChartType,
	IChartConfig
} from "components/chart/IChart";

import i18n from "util/base/i18n";

export default function PaymentConcentration(props: IChartWidgetProps) {

	const [chartData, setChartData]: [ChartData[], Function] = useState([]);

	const [startDate, setStartDate]: [moment.Moment, Function] = useState(moment(props.startDate));

	const [endDate, setEndDate]: [moment.Moment, Function] = useState(moment(props.endDate));

	const [shouldUpdateFilter, setShouldUpdateFilter]: [boolean, Function] = useState(false);

	const [loading, setLoading]: [boolean, Function] = useState(false);

	const [useCache, setUseCache]: [boolean, Function] = useState(true);

	const [cached, setCached]: [boolean, Function] = useState(false);
	const [cacheDate, setCacheDate]: [Date, Function] = useState(null);

	const chartConfig: IChartConfig = {
		paletteColors: "#b3cee1, #7eb5dc, #4f9acf, #44759a, #104b77",
		showLabels: false,
		showValues: true,
		drawCustomLegendIcon: true,
		legendIconSides: "1"
	};

	const onChangeDate = ([newStartDate, newEndDate]: moment.Moment[]) => {
		if (startDate.isSame(newStartDate) && endDate.isSame(newEndDate)) {
			loadChart();
		} else {
			setShouldUpdateFilter(true);

			setStartDate(newStartDate);
			setEndDate(newEndDate);
		}
	};

	function onLoadData(response: IWidgetResponse) {
		setCached(response.cached);
		setCacheDate(response.date);
		setChartData(response.data.map(({ label, value }) => {
			label = label || i18n.t<string>("uninformed");

			return {
				label,
				value,
				tooltext: `
					<div class="chart-tooltip-parent">
						<b>$percentValue</b>
						<br />
						<div>${label}</div>
					</div>
				`
			}
		}));

		setLoading(false);
		setUseCache(false);
	};

	const loadChart: Function = () => {
		setLoading(true);

		const filterValuesQuery = props.globalFilter.values.map(value => `filterValue=${value}`).join("&");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/cash/payment-cocentration?filterBy=${props.globalFilter.filterBy}&${filterValuesQuery}&client={client}&user={user}&startDate=${startDate.startOf("month").format("YYYY-MM-DD")}&endDate=${endDate.endOf("month").format("YYYY-MM-DD")}&scenario={scenario}&currency=${props.currencyId}&useCache=${useCache}`
		}, onLoadData.bind(this))
	};

	useEffect(() => {
		if (shouldUpdateFilter) {
			ServiceCaller.doRequest({
				type: RequestType.PUT,
				url: "/dashboard-analytics/widget/filter",
				params: {
					id: props.filterId,
					startDate: startDate.startOf("month").format("YYYY-MM-DD"),
					endDate: endDate.endOf("month").format("YYYY-MM-DD")
				}
			}, loadChart())
		}
	}, [startDate, endDate]);

	useEffect(loadChart.bind(this), [props.currencyId, props.globalFilter]);

	return <>
		<div className="payment-concentration-widget">
			<div className="widget-header-actions-container">
				{
					cached && <>
						<Tooltip title={ i18n.t<string>("dashboard.analytics.text_002", {date: new Intl.DateTimeFormat(i18n.language.replace("_", "-"), { dateStyle: "short", timeStyle: "medium" }).format(new Date(cacheDate))}) }>
							<Button onClick={loadChart.bind(this)} type="text" size="large" icon={<Icon icon={"mdi:reload-alert"} />} disabled={loading} data-html2canvas-ignore="true" />
						</Tooltip>
					</>
				}

				<DateRange value={[startDate, endDate]} onChange={onChangeDate.bind(this)} format="MMM YYYY" formatEdit="MM/YYYY" picker="month" disabled={loading} />
			</div>

			{loading ? <>
				<div className="loading-container">
					<LoadingOutlined />
				</div>
			</> : <Chart id="widget-7" type={ChartType.PIE_2D} data={chartData} config={chartConfig} />}
		</div>
	</>;

}
