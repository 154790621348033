import { Input, Radio, RadioChangeEvent, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { UserPermissionsUserListProps } from "../IPermissionSecurity";

const { Search } = Input;

export function PermissionSecurityUserGroupTab({
	setSelectedUser,
	selectedTab,
	setSelectedTab
}: UserPermissionsUserListProps) {
	const [userList, setUserList] = useState<Options[]>([]);
	const [groupList, setGroupList] = useState<Options[]>([]);
	const [selectedUserId, setSelectedUserId] = useState(0);
	const [selectedGroupId, setSelectedGroupId] = useState(0);
	const [isFetchingUsersList, setIsFetchingUsersList] = useState(true);
	const [isFetchingGroupList, setIsFetchingGroupList] = useState(true);

	const [searchGroup, setSearchGroup] = useState("");

	const [searchUser, setSearchUser] = useState("");

	useEffect(() => {
		setIsFetchingUsersList(true);
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/budget-base/user?locale={locale}&client={client}',
		}, loadUsersData.bind(this))
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/monolith/user/group/findAll?localeId={locale}&clientId={client}',
		}, loadGroupData.bind(this))
	}, []);

	function loadUsersData(data: Options[]) {
		setUserList(data);
		if (data.length > 0) {
			setSelectedUserId(data[0].value);
			if (selectedTab === 'user') {
				setSelectedUser(data[0]);
			}
		}
		setIsFetchingUsersList(false);
	};

	function loadGroupData(data) {
		const updatedGroupList = data.map(group => {
			return {
				value: group.id,
				label: group.name,
			}
		})
		if (data.length > 0) {
			setSelectedGroupId(updatedGroupList[0].value);
			if (selectedTab === 'group') {
				setSelectedUser(updatedGroupList[0]);
			}
		}
		setGroupList(updatedGroupList);
		setIsFetchingGroupList(false);

	};

	function onChangeUser(e: RadioChangeEvent) {
		setSelectedUserId(e.target.value);
	};
	function onChangeGroup(e: RadioChangeEvent) {
		setSelectedGroupId(e.target.value);
	};

	function onChangeTab(tab: 'group' | 'user') {
		setSelectedTab(tab)
		if (tab === 'group') {
			const selectedGroup = groupList.find(group => group.value === selectedGroupId)
			setSelectedUser(selectedGroup)
		} else {
			const selectedUser = userList.find(user => user.value === selectedUserId)
			setSelectedUser(selectedUser)
		}
	}

	function handleSearchGroup(e) {
		setSearchGroup(e.target.value);
	}

	function handleSearchUser(e) {
		setSearchUser(e.target.value);
	}

	let filteredGroup = groupList.filter(obj => obj.label.toLowerCase().includes(searchGroup.toLowerCase()));

	let filteredUser = userList.filter(obj => obj.label.toLowerCase().includes(searchUser.toLowerCase()));

	return (
		<Tabs activeKey={selectedTab} type="card" className="gs-tab permission-groupUser-tab-container" onChange={onChangeTab}>
			<Tabs.TabPane tab={i18n.t("group")} key="group">
				<Spin spinning={isFetchingGroupList}>
					<Search
						allowClear
						onChange={handleSearchGroup}
						placeholder={i18n.t("search")}
						className="permissions-list-input"
						style={{marginBottom: "16px"}}
					/>
					<Radio.Group onChange={onChangeGroup} value={selectedGroupId} className="users-list">
						{filteredGroup.map(group => {
							return (
								<Radio
									key={group.value}
									className="user-row"
									value={group.value}
									onClick={() => setSelectedUser(group)}
								>
									{group.label}
								</Radio>
							)
						})}
					</Radio.Group>
				</Spin>
			</Tabs.TabPane>
			<Tabs.TabPane tab={i18n.t("user")} key="user">
				<Spin spinning={isFetchingUsersList}>
					<Search
						allowClear
						onChange={handleSearchUser}
						placeholder={i18n.t("search")}
						className="permissions-list-input"
						style={{marginBottom: "16px"}}
					/>
					<Radio.Group onChange={onChangeUser} value={selectedUserId} className="users-list">
						{filteredUser.map(user => {
							return (
								<Radio
									key={user.value}
									className="user-row"
									value={user.value}
									onClick={() => setSelectedUser(user)}
								>
									{user.label}
								</Radio>
							)
						})}
					</Radio.Group>
				</Spin>
			</Tabs.TabPane>
		</Tabs>
	)
}