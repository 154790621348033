enum ChartType {

	COLUMN_2D = "Column2D",

	BAR_2D = "bar2d",

	PIE_2D = "pie2d",

	MS_STACKED_COLUMN_2D_LINE = "msstackedcolumn2dlinedy"

}

interface IChartConfig {

	paletteColors?: string

	plotGradientColor?: string

	xAxisName?: string

	showLabels?: boolean

	showValues?: boolean

	usePlotGradientColor?: boolean

	drawCustomLegendIcon?: boolean

	legendIconSides?: string

	showYAxisValues?: boolean

}

interface IChartProps {

	id: string

	type: ChartType

	config?: IChartConfig

	data?: any[]

	categories?: any[]

	dataset?: any[]

	lineset?: any[]

}

interface IChartState {

	renderAt: string

	chart: any

}

export {

	IChartConfig,

	ChartType,

	IChartProps,

	IChartState

}