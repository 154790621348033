import { Spin, Tabs } from "antd";
import { Notification } from "components/notification/Notification";
import moment from "moment";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { invalidadeQuery } from "util/queryClient";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ModuleType } from "util/types/types";
import "../ReversalPeriodPerformed.sass";
import { useReversalPeriodPerformedContext } from "../context/ReversalPeriodPerformedContext";
import StaticInfoPanel from "./StaticInfoPanel";
import { useUserContext } from "context/UserContext";

const Content: React.FC = () => {
    const {
        selectedTab,
        setSelectedTab,
        organizationList,
        getPeriodsByOrganizations
    } = useReversalPeriodPerformedContext();
    const [monthOptions, setMonthOptions] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
    const [displayedValue, setDisplayedValue] = useState<string>("");

    const {
        userInfo: {
            id: userId,
            clientId,
            selection: { scenarioId },
        },
    } = useUserContext();

    useEffect(() => {
        const lastRealizedYear = getLastRealizedYear();
        if (lastRealizedYear) {
            const lastRealizedPeriod = getRealizedPeriod(lastRealizedYear);
            if (lastRealizedPeriod) {
                const options = generateMonthOptions(lastRealizedPeriod);
                setMonthOptions(options);
                const values = generateMonthValues(lastRealizedPeriod);
                setDisplayedValue(values[values.length - 1]);
            }
        }
    }, [selectedTab, organizationList]);

    const getRealizedPeriod = (year: string): number => {
        switch (selectedTab) {
            case ModuleType.REVENUE:
                return organizationList[year]?.revenueRealizedPeriod;
            case ModuleType.PLANNING:
                return organizationList[year]?.planningRealizedPeriod;
            default:
                return organizationList[year]?.expensesAndResourcesRealizedPeriod;
        }
    };

    const getLastRealizedYear = (): string | null => {
        const years = Object.keys(organizationList).sort((a, b) => Number(b) - Number(a));
        return years.find((year) => getRealizedPeriod(year)) || null;
    };

    const generateMonthOptions = (timestamp: number) => {
        const date = moment(timestamp);
        return Array.from({ length: date.month() + 1 }, (_, m) => {
            const month = moment().month(m).year(date.year());
            return { label: month.format("MMMM YYYY"), value: month.format("MM-YYYY") };
        });
    };

    const generateMonthValues = (timestamp: number): string[] => {
        const startMonth = moment(timestamp).startOf("month");
        const endMonth = moment(getRealizedPeriod(startMonth.year().toString())).endOf("month");
        const values = [];
        for (let month = startMonth.clone(); month.isSameOrBefore(endMonth); month.add(1, "month")) {
            values.push(month.format("MM-YYYY"));
        }
        return values;
    };

    const onChangePeriod = (value: string[]): void => {
        setDisplayedValue(value[0]);
        const monthValues = generateMonthValues(moment(value, "MM-YYYY").valueOf());
        setSelectedMonths(monthValues);
    };

    const handleRevert = async () => {
        const dateSelected = moment(displayedValue, "MM-YYYY");
        if (!dateSelected.isValid()) return;

        Notification({
            type: "info",
            message: i18n.t<string>("reversal_period_performed.saving_on_progress"),
            duration: 5,
        });

        const data = organizationList[dateSelected.year()];
        const previousMonthValue =
            dateSelected.month() !== 0 ? dateSelected.clone().subtract(1, "month").date(15).valueOf() : null;

        const dataToSave = {
            organizationControlId: data.id,
            year: dateSelected.year(),
            [`${selectedTab}RealizedPeriod`]: previousMonthValue,
            clientId: clientId,
            userId: userId,
            scenarioId: scenarioId,
            accomplishedDate: dateSelected.format("YYYYMM"),
            module: getModuleType(selectedTab),
        };

        await ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `budget-base/control-panel/reversal-accomplished-values`,
            params: dataToSave,
        });

        loadBudgetCalculation();
    };

    const loadBudgetCalculation = () => {
        getPeriodsByOrganizations();

        Notification({
            type: "success",
            message: i18n.t("successfully_saved"),
        });
        invalidadeQuery(["budget-period-dates", selectedTab]);
    };

    const getModuleType = (value: string): string => {
        switch (value) {
            case ModuleType.EXPENSERESOURCES:
                return "EXPENSES_RESOURCES";
            case ModuleType.REVENUE:
                return "REVENUE";
            case ModuleType.PLANNING:
                return "PLANNING";
            default:
                throw new Error(`Invalid module type: ${value}`);
        }
    };

    const renderTabPane = (key: string, label: string) => (
        <Tabs.TabPane tab={label} key={key}>
            {organizationList.length === 0 ? (
                <div className="loading-spin-container">
                    <Spin tip="Carregando...." />
                </div>
            ) : (
                <StaticInfoPanel
                    monthOptions={monthOptions}
                    selectedMonths={selectedMonths}
                    setSelectedMonths={setSelectedMonths}
                    displayedValue={displayedValue}
                    setDisplayedValue={setDisplayedValue}
                    onChangePeriod={onChangePeriod}
                    handleRevert={handleRevert}
                    getRealizedPeriod={getRealizedPeriod}
                />
            )}
        </Tabs.TabPane>
    );

    return (
        <Tabs
            activeKey={selectedTab}
            type="card"
            className="gs-tab organization-list-tab custom-tab"
            onChange={setSelectedTab}
        >
            {renderTabPane(
                ModuleType.EXPENSERESOURCES,
                `${i18n.t("flexField.modules.expenses")} ${i18n.t("and")} ${i18n.t("flexField.modules.humanResources")}`
            )}
            {renderTabPane(ModuleType.REVENUE, i18n.t("flexField.modules.revenue"))}
            {renderTabPane(ModuleType.PLANNING, i18n.t("planning"))}
        </Tabs>
    );
};

export default Content;
