import i18n from "util/base/i18n";

import { Icon } from "@iconify/react";
import { Button } from "antd";

import { Pendencies } from "../widget";
import { HeaderProps } from "../IModule";

export function Header(props: HeaderProps) {

	return <>
		<section className="header-container">
			<div className="header-content">
				<img src="/assets/images/cash.png" alt="ModuleLogo" />

				<div className="header-right-content">
					<div className="header-right-content-box1">
						<span id="module-name">
							<Icon icon={props.icon} />
							<h4>{i18n.t<string>(props.title)}</h4>
						</span>

						<h1>{i18n.t<string>("what_do_you_want_to_do")}</h1>
						<p>{i18n.t<string>("intermediate_page_content")}</p>
						<Button className="btn-open-coachmark" onClick={() => {}} icon={<Icon id="icon-coachmark" icon="clarity:info-line" />}>{i18n.t<string>("need_help")}</Button>
					</div>

					<div className="header-right-content-box2">
						<Pendencies amount={props.pendencies}/>
					</div>
				</div>
			</div>
		</section>
	</>

}
