import i18n from 'util/base/i18n';
import './styles.sass'

interface CoachmarkStepProps {
	currentStep: number;
	stepAmount: number;
	title?: string;
	content?: string;
	finishCoachMark?: () => void;
	doNextStep: () => void;
}

export function CoachmarkStep({
	currentStep,
	stepAmount,
	title = i18n.t("dashboard.coach_mark.step4_title"),
	content = i18n.t("dashboard.coach_mark.step4_content"),
	finishCoachMark,
	doNextStep
}: CoachmarkStepProps) {

	return (
		<div className="coachmark-container">
			<div className="coachmark-title-container">
				<h1 className="coachmark-title">
					{title}
				</h1>
				<div className="coachmark-dot-container">
					{[...Array(stepAmount)].map((e, i) => {
						return <span key={"cm-step-" + i} className={currentStep === i + 1 ? "dot current-dot" : "dot"}></span>
					})}
				</div>
			</div>
			<div className="coachmark-description-container">
				{content}
			</div>
			<div className="coachmark-buttons-container">
				{currentStep < stepAmount &&
					<button onClick={finishCoachMark} className="coachmark-button-skip">Pular</button>
				}
				<button className="coachmark-button-primary" onClick={doNextStep}>
					{currentStep === stepAmount ? 'Finalizar' : i18n.t<string>("next")}
				</button>
			</div>
		</div>
	)
}