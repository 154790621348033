import { IMultipleSearch } from 'components/input/search/IMultipleSearch';
import '../../../../../components/input/search/MultipleSearch.sass';
import { Dropdown, Input, Menu } from 'antd';
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import i18n from 'util/base/i18n';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const { Search } = Input;

export default function MultipleSearch({ tableData, setTableData, options }: IMultipleSearch) {
    const [value, setValue] = useState('')
    const [searchType, setSearchType] = useState(options[0]?.description)
    const searchRef = useRef(null)

    const optionsSearch: ItemType[] = options.map(opt => {
        return {
            label: `${i18n.t('search_by')} ${i18n.t(opt.i18nString)}`,
            key: opt.description
        }
    });

    function handleSearch(e) {
        setValue(e.target.value)
        let filteredData = [];
        filteredData = filterTrees(JSON.parse(JSON.stringify(tableData)), e.target.value);
        setTableData(filteredData)
    };

    const filterTrees = (data, search) => {
        if (!search) return data;

        return data.reduce((filtered, item) => {
            if (searchType === "event") {
                if (item.event.toLowerCase().includes(search.toLowerCase())) {
                    filtered.push({
                        ...item,
                        children: item.children.map((child) => ({
                            ...child,
                            children: child.children.map((grandchild) => ({ ...grandchild })),
                        })),
                    });
                } else {
                    const filteredChildren = item.children.filter((child) =>
                        child.event.toLowerCase().includes(search.toLowerCase())
                    );
                    if (filteredChildren.length) {
                        filtered.push({
                            ...item,
                            children: filteredChildren,
                        });
                    }
                }
            } else if (searchType === "description") {
                const filteredChildren = item.children.reduce((childFiltered, child) => {
                    const filteredGrandchildren = child.children.filter((grandchild) =>
                        grandchild.description.toLowerCase().includes(search.toLowerCase())
                    );
                    if (filteredGrandchildren.length) {
                        childFiltered.push({
                            ...child,
                            children: filteredGrandchildren,
                        });
                    }
                    return childFiltered;
                }, []);

                if (filteredChildren.length) {
                    filtered.push({
                        ...item,
                        children: filteredChildren,
                    });
                }
            }

            return filtered;
        }, []);
    };

    function onChangeType({ key }) {
        setSearchType(key)
        searchRef.current!.focus()
    }

    const placeholder = options.find(opt => opt.description === searchType)?.i18nString ?? options[0].i18nString

    return (
        <Search
            className="gs-multiple-search"
            ref={searchRef}
            placeholder={`${i18n.t('search_by')} ${i18n.t(placeholder)}`}
            value={value}
            onChange={handleSearch}
            suffix={
                <Dropdown overlay={<Menu items={optionsSearch} onClick={onChangeType} />} trigger={["click"]}>
                    <Icon icon="majesticons:chevron-down-line" />
                </Dropdown>
            }
        />
    )
}
