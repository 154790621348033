import { Table } from 'antd';
import i18n from 'util/base/i18n';
import { TableData } from '../IProjectionModeling';
import { TableRowSelection } from 'antd/lib/table/interface';

export default function ProjectionModelingTable({
    isFetching,
    tableData,
    selectedRowKeys,
    onChange,
    columns
}) {
    const rowSelection: TableRowSelection<TableData> = {
        selectedRowKeys, onChange
    };

    return (
        <Table
            style={{ padding: '0 24px' }}
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            className="gs-table"
            dataSource={tableData}
            columns={columns}
            rowSelection={rowSelection}
            pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 20 }}
            bordered
        />
    )
}