import { Divider, Layout } from 'antd';

import { Filters } from './components/Filters/';
import { Title } from './components/Title';
import ReportTable from './components/ReportTable/';
import { ReportViewProvider } from './context/ReportViewContext';

import './ReportView.sass';

export default function ReportView() {
    return (
        <ReportViewProvider>
            <Layout id="report_view-container" className="light">
                <Layout.Header>
                    <Title />
                </Layout.Header>

                <Divider className='title_divider' />

                <Layout.Content id="report_view-content-container">
                    <Filters />
                    <ReportTable />
                </Layout.Content>
            </Layout>
        </ReportViewProvider>
    );
}
