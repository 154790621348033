import { SummaryCard } from "./ISummaryCard";

export function Card({
    label,
    icon,
    value,
    type,
    isoCode
}: SummaryCard) {
    const formatCurrency = (value: number, currency: string) => {
    
        const isNegative = value < 0;
    
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(Math.abs(value));
    
        return isNegative ? `R$ - ${formattedValue.slice(3)}` : formattedValue;
    };

    const formatRecords = (value: number) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
        }).format(value);
    }

    return (
        <div id="card-container-accounting-ledger">
            {icon}
            <div id="card-content-accounting-ledger">
                <p id="card-title-accounting-ledger">{label}</p>
                <span>
                    {type === "currency"
                        ? formatCurrency(value, isoCode || "BRL")
                        : formatRecords(value)
                    }
                </span>
            </div>
        </div>
    );
}
