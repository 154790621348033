import { Form, Select } from "antd";
import i18n from "util/base/i18n";
import { IFlexFieldsSelectProps } from "../IRecurringContract";

export default function FlexFieldsSelect({
	id,
	label,
	values,
	fieldCode,
	isDisabled,
}: IFlexFieldsSelectProps) {

	const selectProps = {
		showSearch: true,
		optionFilterProp: "children",
		filterOption: (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
		filterSort: (optionA: IFlexFieldsSelectProps, optionB: IFlexFieldsSelectProps) =>
			(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
	}

	return (
		<Form.Item
			label={label}
			name={`flexField-${id}-${fieldCode}`}
		>
			<Select
				placeholder={i18n.t("select")}
				{...selectProps}
				options={values}
				allowClear
				disabled={isDisabled}
			/>
		</ Form.Item>
	)
}