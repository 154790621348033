import i18n from "util/base/i18n";
import { formatNumber } from "util/formatNumber";
import { Column } from "module/budget/pages/detailLayout/IDetailLayout";
import { ColumnsType } from "antd/lib/table";
import { organization, treeGridData } from "module/plan/pages/consolidation/IConsolidation";

import moment from 'moment';

export function createTableColumns(
    treeGridData: organization[]
) {
    let valuesColumn: Column[] = [];
    let rates: Column[] = [];

    if (!treeGridData[0]?.children[0]) return;

    const listChild = treeGridData[0]?.children[0]?.children[0];
	const months = Object.keys(listChild)
		.filter((key) => !["key", "isEdited", "children", "name", "organization", "organizationFlex"]
			.includes(key));

    if (months === undefined || months == null || months.length <= 0) return;

    const columnData: ColumnsType<treeGridData> = [
        {
            title: i18n.t("description"),
            dataIndex: 'name',
            fixed: 'left',
            width: 255,
            align: 'left',
            className: 'table-first-column',
            filterMode: 'tree',
            filterSearch: true,
        }
    ];

	months.forEach(month => {
		const date = new Date(Date.parse(month + " 1, 2020"));
		rates.push({
			title: i18n.t(`month.full.${date.getMonth()}`),
			align: 'center',
			className: '',
			width: 90,
			dataIndex: month,
			key: month,
			render: (value, record: any) => {
				if (moment(record.name, 'YYYY', true).isValid()) {
					const percentageValue = value ? value / 100 : 0;
					return formatNumber(percentageValue, { style: "percent", maximumFractionDigits: 2, minimumFractionDigits: 2 });
				}
			},
			onCell: (record: any) => {
				return {
					record,
					editable: !record?.children,
					dataIndex: month,
					title: i18n.t(`month.full.${date.getMonth()}`),
					align: 'center',
				};
			},
		});
    });

    valuesColumn.push({
        title: "Taxas de Participação",
        align: 'center',
        className: '',
        editable: true,
        children: [...rates]
    });

    columnData.push(...valuesColumn);

    return columnData;
}