import { Modal, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import i18n from "util/base/i18n";

import {
    AttributeMathOperations,
    AttributeMathOperationsFromServer,
    IConditionGrouperType,
} from "../../../IAttributeParameterization";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { AttributeParameterizationContext } from "../../../context/AttributeParameterizationContext";
import { useUserContext } from "context/UserContext";
import { RuleModalContext } from "../../../context/RuleModalContext";

import { RuleConditionsDivider } from "./components/RuleConditionsDivider";
import { RuleConditionsFormula } from "./components/RuleConditionsFormula";
import { RuleConditionsForm } from "./components/RuleConditionsForm";
import {
    RuleModalProps,
    ModalType,
    EditedRulePayload,
    buildEditedRulePayloadProps,
    IRuleCondition,
    IRuleLevelType,
    ModalFlexFieldsModule,
    IFormulaPartFromRequest,
} from "./components/IModalFactory";
import { RuleConditionsTabs } from "./components/RuleConditionsTabs";
import { RuleEditInfo } from "./components/RuleEditInfo";
import { NotificationPlacement } from "antd/lib/notification";
import { ErrorRequest } from "util/types/types";
import { handleErrorRequest } from "util/functions/handleErrorRequest";

export default function EditRuleModalFactory({ modalTitle, modalType, flexFieldsModule }: RuleModalProps) {
    const { userInfo } = useUserContext();

    const {
        isEditRuleModalOpen,
        isEditRuleModalDFOpen,
        isEditRuleModalFixedMarketingCostOpen,
        selectedRows,
        closeEditRuleModal,
        closeEditRuleModalDF,
        closeEditRuleModalFixedMarketingCost,
        clearRules,
        selectedAttribute,
        reloadTable,
    } = useContext(AttributeParameterizationContext);

    const [ruleInformation] = selectedRows;

    const {
        externalCode,
        description,
        order,
        conditions,
        formula,
        setExternalCode,
        setDescription,
        setOrder,
        setFormula,
        setConditions,
        deletedConditionsIds,
        selectedConditionGrouper,
        handleAddParsedConditionsFromBackend,
        handleSelectedConditionGrouper,
    } = useContext(RuleModalContext);

	const [okButtonText, setOkButtonText] = useState("");

    function editedRuleNotification(placement: NotificationPlacement = "topRight") {
        notification.success({
            message: i18n.t<string>("revenue.rule_edited_successfully"),
            placement,
            duration: 3,
        });
    }

    function buildEditedRulePayload({
        externalCode,
        description,
        formulas,
        conditionsIdsToDelete,
        createdAndUpdatedConditions,
    }: buildEditedRulePayloadProps): EditedRulePayload {
        return {
            id: ruleInformation.attributeRuleId,
            scenarioId: userInfo.selection.scenarioId,
            externalCode,
            description,
            formulas,
            attributeId: selectedAttribute.id,
            ruleOrder: order,
            conditionsIdsToDelete,
            createdAndUpdatedConditions,
        };
    }

    function updateRule() {
        if (flexFieldsModule === ModalFlexFieldsModule.REVENUE) {
            const createdAndUpdatedConditions = conditions.map((condition, index) => {
                const { grouperType, selectedLevel, operation, selectedLevelValue } = condition;

                if (condition.id) {
                    return {
                        orderCondition: index + 1,
                        id: condition.id,
                    };
                }

                return {
                    orderCondition: index + 1,
                    moduleType: grouperType.toString(),
                    partsConditions: [
                        {
                            flexFieldId: selectedLevel.content.id,
                            operationOrder: 1,
                        },
                        {
                            operationRuleType: operation.content,
                            operationOrder: 2,
                        },
                        {
                            flexFieldValueId: selectedLevelValue.content.id,
                            operationOrder: 3,
                        },
                    ],
                };
            });

            const editedRule = buildEditedRulePayload({
                conditionsIdsToDelete: deletedConditionsIds,
                createdAndUpdatedConditions: createdAndUpdatedConditions,
                description,
                externalCode,
                formulas: [],
            });

            if (modalType === ModalType.STANDARD_WITH_FORMULA) {
                const preparedFormula = formula.map((item, index) => {
                    if (item.type === "attribute") {
                        return {
                            orderPosition: index + 1,
                            attributeId: item.content["id"],
                        };
                    }

                    return {
                        orderPosition: index + 1,
                        type: AttributeMathOperations[item.content],
                    };
                });

                editedRule.formulas = preparedFormula;
            }

            ServiceCaller.doRequest(
                {
                    type: RequestType.PUT,
                    url: `/revenue/grouper/attribute/rule`,
                    params: editedRule,
                },
                (_) => {
                    handleSelectedConditionGrouper(IConditionGrouperType.REVENUE);
                    closeEditRuleModal();
                    closeEditRuleModalFixedMarketingCost();
                    editedRuleNotification();
                    reloadTable();
                }
            );
        }

        if (flexFieldsModule === ModalFlexFieldsModule.REVENUE_AND_FIXED_EXPENSE) {
            const revenueConditions = conditions
                .filter((condition) => condition && condition.grouperType === IConditionGrouperType.REVENUE)
                .map((condition, index) => {
                    const { grouperType, selectedLevel, operation, selectedLevelValue } = condition;

                    return {
                        moduleType: grouperType.toString(),
                        orderCondition: index + 1,
                        id: condition.selectedLevelValue.content.attributeConditionId ?
                            condition.selectedLevelValue.content.attributeConditionId : null,
                        partsConditions: [
                            {
                                flexFieldId: selectedLevel.content.id,
                                operationOrder: 1,
                            },
                            {
                                operationRuleType: operation.content,
                                operationOrder: 2,
                            },
                            {
                                flexFieldValueId: selectedLevelValue.content.id,
                                operationOrder: 3,
                            },
                        ],
                    };
                });

            const fixedExpenseConditions = conditions
                .filter((condition) => condition && condition.grouperType === IConditionGrouperType.FIXED_EXPENSE)
                .map((condition, index) => {
                    const { grouperType, selectedLevel, operation, selectedLevelValue } = condition;

                    switch (selectedLevel.type) {
                        case IRuleLevelType.FLEX_FIELD:
                            return {
                                moduleType: grouperType.toString(),
                                orderCondition: index + 1,
                                id: condition.selectedLevelValue.content.attributeConditionId ?
                                    condition.selectedLevelValue.content.attributeConditionId : null,
                                partsConditions: [
                                    {
                                        flexFieldId: selectedLevel.content.id,
                                        operationOrder: 1,
                                    },
                                    {
                                        operationRuleType: condition.operation.content,
                                        operationOrder: 2,
                                    },
                                    {
                                        flexFieldValueId: selectedLevelValue.content.id,
                                        operationOrder: 3,
                                    },
                                ],
                            };
                        case IRuleLevelType.ACCOUNTING_ACCOUNT:
                            return {
                                moduleType: grouperType.toString(),
                                orderCondition: index + 1,
                                id: condition.selectedLevelValue.content.attributeConditionId ?
                                     condition.selectedLevelValue.content.attributeConditionId : null,
                                partsConditions: [
                                    {
                                        operationRuleType: operation.content,
                                        operationOrder: 2,
                                    },
                                    {
                                        accountingAccountId: selectedLevelValue.content.accountingAccountId
                                            ? selectedLevelValue.content.accountingAccountId
                                            : selectedLevelValue.content.id,
                                        operationOrder: 3,
                                    },
                                ],
                            };
                        case IRuleLevelType.COST_CENTER:
                            return {
                                moduleType: grouperType.toString(),
                                orderCondition: index + 1,
                                id: condition.selectedLevelValue.content.attributeConditionId ?
                                    condition.selectedLevelValue.content.attributeConditionId : null,
                                partsConditions: [
                                    {
                                        operationRuleType: condition.operation.content,
                                        operationOrder: 2,
                                    },
                                    {
                                        costCenterId: selectedLevelValue.content.costCenterId
                                            ? selectedLevelValue.content.costCenterId
                                            : selectedLevelValue.content.id,
                                        operationOrder: 3,
                                    },
                                ],
                            };
                        case IRuleLevelType.BUSINESS_UNIT:
                            return {
                                moduleType: grouperType.toString(),
                                orderCondition: index + 1,
                                id: condition.selectedLevelValue.content.attributeConditionId ?
                                    condition.selectedLevelValue.content.attributeConditionId : null,
                                partsConditions: [
                                    {
                                        operationRuleType: condition.operation.content,
                                        operationOrder: 2,
                                    },
                                    {
                                        businnesUnitId: selectedLevelValue.content.businnesUnitId
                                            ? selectedLevelValue.content.businnesUnitId
                                            : selectedLevelValue.content.id,
                                        operationOrder: 3,
                                    },
                                ],
                            };
                        case IRuleLevelType.ACCOUNTING_ACCOUNT_GROUP:
                            return {
                                moduleType: grouperType.toString(),
                                orderCondition: index + 1,
                                id: condition.selectedLevelValue.content.attributeConditionId ?
                                    condition.selectedLevelValue.content.attributeConditionId : null,
                                partsConditions: [
                                    {
                                        operationRuleType: condition.operation.content,
                                        operationOrder: 2,
                                    },
                                    {
                                        accountHierarchyId: selectedLevelValue.content.accountHierarchyId
                                            ? selectedLevelValue.content.accountHierarchyId
                                            : selectedLevelValue.content.id,
                                        operationOrder: 3,
                                    },
                                ],
                            };
                        default:
                            throw new Error("Invalid condition level type");
                    }
                });

            const preparedFormula = formula.map((item, index) => {
                if (item.type === "attribute") {
                    return {
                        orderPosition: index + 1,
                        attributeId: item.content["id"],
                    };
                }

                return {
                    orderPosition: index + 1,
                    type: AttributeMathOperations[item.content],
                };
            });

            const editedRulePayload = buildEditedRulePayload({
                description,
                externalCode,
                conditionsIdsToDelete: deletedConditionsIds,
                createdAndUpdatedConditions: [...revenueConditions, ...fixedExpenseConditions],
                formulas: preparedFormula,
            });

            ServiceCaller.doRequest(
                {
                    type: RequestType.PUT,
                    url: `/revenue/grouper/attribute/rule`,
                    params: editedRulePayload,
                },
                (response) => {
                    reloadTable();
                    closeEditRuleModalDF();
                    handleSelectedConditionGrouper(IConditionGrouperType.REVENUE);
                },
                (err: ErrorRequest) => {
                    handleErrorRequest(err);
                }
            );
        }
    }

    function handleOk() {
        if (modalType === ModalType.FIXED_EXPENSE) {
            const hasRevenueCondition = conditions.filter((condition) => condition?.grouperType).some(
                (condition) => condition.grouperType === IConditionGrouperType.REVENUE
            );

            if (hasRevenueCondition && selectedConditionGrouper === IConditionGrouperType.REVENUE) {
                handleSelectedConditionGrouper(IConditionGrouperType.FIXED_EXPENSE);
                return;
            }

            const hasExpensesCondition = conditions.filter((condition) => condition?.grouperType).some(
                (condition) => condition.grouperType === IConditionGrouperType.FIXED_EXPENSE
            );

            if (hasRevenueCondition && hasExpensesCondition) {
                updateRule();
                return;
            }

            handleSelectedConditionGrouper(IConditionGrouperType.REVENUE);
            return;
        }

        updateRule();
    }

    function handleEditModalCancel() {
        if (modalType === ModalType.STANDARD) {
            closeEditRuleModal();
        }

        if (modalType === ModalType.FIXED_EXPENSE) {
            closeEditRuleModalDF();
        }

        if (modalType === ModalType.STANDARD_WITH_FORMULA) {
            closeEditRuleModalFixedMarketingCost();
        }

        setConditions([]);
        clearRules();
    }

    function loadRuleInformationInEditModalInputs() {
        setExternalCode("");
        setDescription("");
        setOrder(undefined);
        setFormula([]);
        setConditions([]);

        setExternalCode(ruleInformation.externalCode);
        setDescription(ruleInformation.description);
        setOrder(ruleInformation.order);

        setFormula(() => {
            return ruleInformation?.formulas
                .sort((a, b) => a.orderPosition - b.orderPosition)
                .map((item: IFormulaPartFromRequest) => {
                    if (item.operationId) {
                        if (item.templateFieldId) {
                            return {
                                id: item.operationId,
                                type: "attribute",
                                content: {
                                    id: item.operationId,
                                    name: i18n.t<string>(item.operationName),
                                },
                            };
                        }

                        return {
                            id: item.operationId,
                            ruleId: item.ruleId,
                            type: "attribute",
                            content: {
                                id: item.operationId,
                                name: item.operationName,
                            },
                        };
                    }

                    return {
                        ruleId: item.ruleId,
                        type: "operator",
                        content: AttributeMathOperationsFromServer[item.type],
                    };
                });
        });

        const parsedConditionsFromRequest = ruleInformation.conditionsBeans
            .sort((prev, next) => prev.orderCondition - next.orderCondition)
            .map<IRuleCondition>((condition) => {
                if (condition.partsConditions.some((x) => x.valueType === "BUSINESS_UNIT")) {
                    const operation = condition.partsConditions.find((part) => part.operationOrder === 2);
                    const levelValue = condition.partsConditions.find((part) => part.operationOrder === 3);

                    return {
                        grouperType: IConditionGrouperType[condition.moduleType],
                        type: IRuleLevelType.BUSINESS_UNIT,
                        selectedLevel: {
                            type: IRuleLevelType.BUSINESS_UNIT,
                            label: i18n.t<string>("business_unit"),
                            content: null,
                        },
                        operation: {
                            label: operation.operationRuleType,
                            content: operation.operationRuleType,
                        },
                        selectedLevelValue: {
                            label: levelValue.description,
                            content: levelValue,
                        },
                    };
                }

                if (condition.partsConditions.some((x) => x.valueType === "ACCOUNT_HIERARCHY")) {
                    const operation = condition.partsConditions.find((part) => part.operationOrder === 2);
                    const levelValue = condition.partsConditions.find((part) => part.operationOrder === 3);

                    return {
                        grouperType: IConditionGrouperType[condition.moduleType],
                        type: IRuleLevelType.ACCOUNTING_ACCOUNT_GROUP,
                        selectedLevel: {
                            type: IRuleLevelType.ACCOUNTING_ACCOUNT_GROUP,
                            label: i18n.t<string>("revenue.grouper_account"),
                            content: null,
                        },
                        operation: {
                            label: operation.operationRuleType,
                            content: operation.operationRuleType,
                        },
                        selectedLevelValue: {
                            label: levelValue.description,
                            content: levelValue,
                        },
                    };
                }

                if (condition.partsConditions.some((x) => x.valueType === "COST_CENTER")) {
                    const operation = condition.partsConditions.find((part) => part.operationOrder === 2);
                    const levelValue = condition.partsConditions.find((part) => part.operationOrder === 3);

                    return {
                        grouperType: IConditionGrouperType[condition.moduleType],
                        type: IRuleLevelType.COST_CENTER,
                        selectedLevel: {
                            type: IRuleLevelType.ACCOUNTING_ACCOUNT_GROUP,
                            label: i18n.t<string>("cost_center"),
                            content: null,
                        },
                        operation: {
                            label: operation.operationRuleType,
                            content: operation.operationRuleType,
                        },
                        selectedLevelValue: {
                            label: levelValue.description,
                            content: levelValue,
                        },
                    };
                }

                if (condition.partsConditions.some((x) => x.valueType === "ACCOUNTING_ACCOUNT")) {
                    const operation = condition.partsConditions.find((part) => part.operationOrder === 2);
                    const levelValue = condition.partsConditions.find((part) => part.operationOrder === 3);

                    return {
                        grouperType: IConditionGrouperType[condition.moduleType],
                        type: IRuleLevelType.ACCOUNTING_ACCOUNT,
                        selectedLevel: {
                            type: IRuleLevelType.ACCOUNTING_ACCOUNT,
                            label: i18n.t<string>("accounting_account"),
                            content: null,
                        },
                        operation: {
                            label: operation.operationRuleType,
                            content: operation.operationRuleType,
                        },
                        selectedLevelValue: {
                            label: levelValue.description,
                            content: levelValue,
                        },
                    };
                }

                if (condition.partsConditions.some((x) => x.valueType === "FLEX_FIELD")) {
                    const level = condition.partsConditions.find((part) => part.operationOrder === 1);
                    const operation = condition.partsConditions.find((part) => part.operationOrder === 2);
                    const levelValue = condition.partsConditions.find((part) => part.operationOrder === 3);

                    return {
                        id: condition.id,
                        grouperType: IConditionGrouperType[condition.moduleType],
                        type: IRuleLevelType.FLEX_FIELD,
                        selectedLevel: {
                            id: level.flexFieldId,
                            type: IRuleLevelType.FLEX_FIELD,
                            label: level.description,
                            content: level,
                        },
                        operation: {
                            label: operation.operationRuleType,
                            content: operation.operationRuleType,
                        },
                        selectedLevelValue: {
                            label: levelValue.description,
                            content: levelValue,
                        },
                    };
                }
            });

        handleAddParsedConditionsFromBackend(parsedConditionsFromRequest);
    }

    useEffect(() => {
        setConditions([]);
		loadRuleInformationInEditModalInputs();
	}, []);
	
	useEffect(() => {
		if (modalType !== ModalType.FIXED_EXPENSE) {
			setOkButtonText(i18n.t<string>("save"));
		} else {
			if (selectedConditionGrouper === IConditionGrouperType.FIXED_EXPENSE) {
				setOkButtonText(i18n.t<string>("save"));
			} else {
				setOkButtonText(i18n.t<string>("next"));
			}
		}
	},[selectedConditionGrouper])

    const isFormFilled = externalCode && description && order;
    const isFormulaFilled =
        modalType === ModalType.FIXED_EXPENSE || modalType === ModalType.STANDARD_WITH_FORMULA
            ? formula.length > 0
            : true;

    const hasRevenueCondition = conditions.filter((condition) => condition?.grouperType).some(
        (condition) => condition.grouperType === IConditionGrouperType.REVENUE);
    const hasExpensesCondition = conditions.filter((condition) => condition?.grouperType).some(
        (condition) => condition.grouperType === IConditionGrouperType.FIXED_EXPENSE
    );

    const hasConditionsFilled =
        selectedConditionGrouper === IConditionGrouperType.REVENUE
            ? hasRevenueCondition
            : hasRevenueCondition && hasExpensesCondition;
    const isConditionsFilled = modalType === ModalType.FIXED_EXPENSE ? hasConditionsFilled : conditions.length > 0;

    const isOkButtonDisabled = !(isFormFilled && isConditionsFilled && isFormulaFilled);

    let isModalVisible = false;
    let modalHasAttributeFormula = false;

    if (modalType === ModalType.STANDARD) {
        isModalVisible = isEditRuleModalOpen;
    } else if (modalType === ModalType.FIXED_EXPENSE) {
        isModalVisible = isEditRuleModalDFOpen;
        modalHasAttributeFormula = true;
    } else if (modalType === ModalType.STANDARD_WITH_FORMULA) {
        isModalVisible = isEditRuleModalFixedMarketingCostOpen;
        modalHasAttributeFormula = true;
    }

    return (
        <Modal
            title={modalTitle}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleEditModalCancel}
			width={"80%"}
            wrapClassName="attribute_parameterization-new-rule-modal"
            okText={okButtonText}
            cancelText={i18n.t<string>("close")}
            centered
            destroyOnClose
            okButtonProps={{
                style: {
                    width: "78px",
                },
                disabled: isOkButtonDisabled,
            }}
        >
            <RuleEditInfo hasAttributeFormula={modalHasAttributeFormula} />

            {modalType === ModalType.FIXED_EXPENSE && <RuleConditionsTabs />}

            {(modalType === ModalType.STANDARD || modalType === ModalType.STANDARD_WITH_FORMULA) && (
                <RuleConditionsDivider />
            )}

            <div id="attribute_parameterization-new-rules-modal-rules-section">
                <RuleConditionsForm />
                <RuleConditionsFormula />
            </div>
        </Modal>
    );
}
