import { cloneDeep } from "lodash";
import { useContext, useEffect, useState } from "react"
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import EliminationTable from "./grid/EliminationTable";
import HeaderGrid from "../components/HeaderGrid";
import { ConsolidationEliminationContext } from "../context/ConsolidationEliminationContext";
import { accountingAccountTree, IStepTwo, rate } from "../../IConsolidation";
import { ErrorRequest, Options } from "util/types/types";
import i18n from "util/base/i18n";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";

export default function StepTwo({
    consolidationId,
    selectedRows,
    currentStep
}: IStepTwo) {

    const [treeGridData, setTreeGridData] = useState<accountingAccountTree[]>([]);
    const [filteredTreeGrid, setFilteredTreeGrid] = useState<accountingAccountTree[]>([]);
    const [isFetchingTreeGrid, setIsFetchingTreeGrid] = useState<boolean>(true);
    const [hasSavedValue, setHasSavedValue] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [organizationsOptions, setOrganizationsOptions] = useState<Options[]>([]);
    const [isFetchingOrganizations, setIsFetchingOrganizations] = useState<boolean>(true);
    const [organizationSelected, setOrganizationSelected] = useState<Options[]>([]);
    const [flexOrganizationOptions, setFlexOrganizationOptions] = useState<Options[]>([]);
    const [isFetchingFlexOrganizations, setIsFetchingFlexOrganizations] = useState<boolean>(true);
    const [flexOrganizationSelected, setFlexOrganizationSelected] = useState<Options[]>([]);

    const { scenarioSelected, isScenarioLoading } = useContext(ConsolidationEliminationContext);

    const importProps: IImportModal[] = [
        {
            importUrl: "/planning/consolidation/import-elimination-rate",
            templateUrl: "/planning/consolidation/import/elimination-template?locale={locale}",
            urlParams: `&consolidationId=${consolidationId}`,
            type: 'excel',
        },
    ];

    useEffect(() => {
        if (!scenarioSelected[0]?.value) return;

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/consolidation/get-elimination-organizations?client={client}&user={user}&locale={locale}&scenario=${scenarioSelected[0].value}&consolidation=${consolidationId}`,
        }, (data) => {
            const options = data.map(item => {
                return {
                    value: item.id,
                    label: item.name
                }
            });
            setIsFetchingOrganizations(false);
            setOrganizationSelected(options.slice(0, 1));
            setOrganizationsOptions(options);
        }, (err) => {
            handleErrorRequest(err)
        });

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/consolidation/get-elimination-organizations-flex?scenario=${scenarioSelected[0].value}&consolidation=${consolidationId}`,
        }, (data) => {
            const options = data.map(item => {
                return {
                    value: item.id,
                    label: item.description
                }
            });
            setIsFetchingFlexOrganizations(false);
            setFlexOrganizationSelected(options.slice(0, 1));
            setFlexOrganizationOptions(options);
        }, (err) => {
            handleErrorRequest(err)
        });
    }, [scenarioSelected]);

    useEffect(() => {
        if (isFetchingOrganizations || isFetchingFlexOrganizations || isScenarioLoading) return;
        if (!scenarioSelected[0]?.value || !organizationSelected[0]?.value) {
            setFilteredTreeGrid([]);
            setTreeGridData([]);
            setIsFetchingTreeGrid(false);
            return;
        }
        setIsFetchingTreeGrid(true);
        fetchTreeRates();
    }, [organizationSelected, flexOrganizationSelected]);

    function fetchTreeRates() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/consolidation/find-eliminations?`
                + `scenario=${scenarioSelected[0].value}`
                + `&consolidation=${consolidationId}`
                + `&organization=${organizationSelected[0].value}`
                + (flexOrganizationSelected[0]?.value ? `&organizationFlex=${flexOrganizationSelected[0].value}` : ""),
        }, (data) => {
            fetchTreeGridData(data);
        }, (err) => {
            handleErrorRequest(err)
        });
    }

    function fetchTreeGridData(rates) {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/planning/management-account-plan-hierarchy/tree",
        }, onLoadTreeGridData.bind(this, rates));
    }

    function convertTree(tree: any, rates: rate[], parentIds: string = '', level: number = 1) {
        if (tree && typeof tree === 'object') {
            const id = tree.id;
            tree.parent = parentIds ? `${parentIds}-${id}` : `${id}`;

            if (tree.hasOwnProperty('description')) {
                if (tree.dataTree.length) {
                    tree.children = tree.dataTree;
                }
                tree.key = tree.id;
                delete tree.dataTree;
                delete tree.id;
            }

            if (level === 4) {
                tree.lastLevel = true;
                const rateObj: any = Object.values(rates).find((rate: rate) => rate.managementAccountId === id);
                tree.tax = rateObj?.rate ? rateObj?.rate : 0;
            }

            if (tree.children && Array.isArray(tree.children)) {
                for (let child of tree.children) {
                    convertTree(child, rates, tree.parent, level + 1);
                }
            }
        }
    }

    function onLoadTreeGridData(rates, data) {
        if (data.length === 0) {
            setTreeGridData([]);
            setFilteredTreeGrid([]);
            setIsFetchingTreeGrid(false);
            return;
        }
        const treeCopy = cloneDeep(data);
        treeCopy.forEach((node) => {
            convertTree(node, rates);
        });
        setFilteredTreeGrid(treeCopy);
        setTreeGridData(treeCopy);
        setIsFetchingTreeGrid(false);
    }

    async function handleSaveRate(data: accountingAccountTree, rate: number) {

        const dataToSave = {
            scenario: scenarioSelected[0].value,
            consolidation: consolidationId,
            organization: organizationSelected[0].value,
            organizationFlex: flexOrganizationSelected[0]?.value ? flexOrganizationSelected[0].value : null,
            managementAccount: data.key,
            rate: rate,
        };

        const response = await ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `/planning/consolidation/save-elimination`,
            params: dataToSave
        });

        if (response.success) {
            const updateTreeGridData = (data, rate, treeGrid) => {

                const levelIds: string[] = data.parent.split("-");

                const updatedData = cloneDeep(treeGrid);
                const firstLevel = updatedData.find(item => item.key == levelIds[0]);
                const secondLevel = firstLevel.children.find(item => item.key == levelIds[1]);
                const thirdLevel = secondLevel.children.find(item => item.key == levelIds[2]);
                const fourthLevel = thirdLevel.children.find(item => item.key == levelIds[3]);

                fourthLevel.tax = rate;

                return updatedData;
            };

            const updatedTreeGridData = updateTreeGridData(data, rate, treeGridData);
            const updatedFilteredTreeGrid = updateTreeGridData(data, rate, filteredTreeGrid);

            setTreeGridData(updatedTreeGridData);
            setFilteredTreeGrid(updatedFilteredTreeGrid);
            setIsLoading(false);
            setHasSavedValue(true);
        } else {
            handleErrorRequest(response.data as ErrorRequest);
        }
    }

    function searchByName(searchTerm: string) {
        const result: accountingAccountTree[] = [];

        const searchInItem = (item: accountingAccountTree): accountingAccountTree | null => {
            let parentClone: accountingAccountTree | null = null;

            if (item.description.toLowerCase().includes(searchTerm.toLowerCase())) {
                parentClone = { ...item };
                if (item.children && !item.tax) {
                    parentClone.children = item.children.slice();
                }
            } else if (item.children && !item.tax) {
                const matchingChildren = item.children
                    .map(searchInItem)
                    .filter(child => child !== null) as accountingAccountTree[];

                if (matchingChildren.length > 0) {
                    parentClone = { ...item, children: matchingChildren };
                }
            }

            return parentClone;
        };

        treeGridData.forEach(item => {
            const matchedItem = searchInItem(item);
            if (matchedItem) {
                result.push(matchedItem);
            }
        });

        setFilteredTreeGrid(result);
    };

    return (
        <div className="steps-consolidation">
            <div style={{ border: '0.2px solid #D7D7D7' }}>
                <HeaderGrid
                    isLoading={isLoading}
                    hasSavedValue={hasSavedValue}
                    search={searchByName}
                    selectedRows={selectedRows}
                    organizationsOptions={organizationsOptions}
                    flexOrganizationOptions={flexOrganizationOptions}
                    currentStep={currentStep}
                    consolidationId={consolidationId}
                    organizationSelected={organizationSelected}
                    setOrganizationSelected={setOrganizationSelected}
                    flexOrganizationSelected={flexOrganizationSelected}
                    setFlexOrganizationSelected={setFlexOrganizationSelected}
                    isFetchingOrganizations={isFetchingOrganizations}
                    isFetchingFlexOrganizations={isFetchingFlexOrganizations}
                    importProps={importProps}
                    handleSuccessImport={() => fetchTreeRates()}
                />
                {(organizationsOptions.length === 0 && !isFetchingOrganizations) ?
                    <div className="budget-initial-content">
                        <img src="/assets/images/image_bw_03.png" alt="wfn-budget" />
                        <h2>{i18n.t("add_a_consolidation_fee_into_a_company")}</h2>
                    </div>
                    :
                    <EliminationTable
                        filteredTreeGrid={filteredTreeGrid}
                        handleSaveRate={handleSaveRate}
                        isFetching={isFetchingTreeGrid}
                        setIsLoading={setIsLoading}
                    />
                }
            </div>
        </div >
    )
}