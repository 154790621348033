import { Icon } from "@iconify/react";
import { Button, Tooltip } from "antd";

export function UndoProjectionComponentFilter({
  description,
  id,
  listFilters,
  setListFilters,
  groupItems,
}) {
  const handleRemove = (id) => {
    const newFilter = listFilters.filter((filter) => filter.key !== id);
    setListFilters(newFilter);
  };

  if (groupItems) {
    return (
      <div className="component-filter-undo">
        <Button
          id={id}
          type="text"
          className="content-filter-undo"
          onClick={() => handleRemove(id)}
        >
          <Tooltip
            title={
              <>
                {groupItems.slice(0, 6).map((item) => (
                  <div key={item.key}>{item.description}</div>
                ))}
                {groupItems.length > 6 && (
                  <div>+ {groupItems.length - 6}</div>
                )}
              </>
            }
            placement="topLeft"
          >
            <div className="content-filter-undo">
              {groupItems[0].name}
              <span className="content-filter-undo-counter">
                +{groupItems.length - 1}
              </span>
            </div>
          </Tooltip>
          <Icon className="remove-component-icon-undo" icon="ph:x-bold" />
        </Button>
      </div>
    );
  } else if (id !== 1) {
    return (
      <div className="component-filter-undo">
        <Button
          id={id}
          type="text"
          className="content-filter-undo"
          onClick={() => handleRemove(id)}
        >
          {description}
          <Icon className="remove-component-icon-undo" icon="ph:x-bold" />
        </Button>
      </div>
    );
  } else {
    return (
      <div className="component-filter-undo">
        <Button
          id={id}
          type="text"
          className="content-filter-undo"
        >
          {description}
        </Button>
      </div>
    );
  }
}