interface IModuleItem {

	id: number;

	key: string;

	name: string;

	link: string;

	icon: string;

	index: number;

	active: boolean;

}

interface IModuleProps {

	code: MenuModule;

	title: string;

	icon: string;

}

interface IManageItemModalProps {

	items: IModuleItem[];

	updateItems: (newItems: IModuleItem[]) => void;

}

enum MenuModule {

	BASE = "BASE",

	CURRENCY = "CURRENCY",

	CASH = "CASH",

	FINANCING = "FINANCING",

	LEASE = "LEASE",

	COVENANT = "COVENANT",

	DASHBOARD = "DASHBOARD",

	INTEGRATION = "INTEGRATION",

	WORKFLOW = "WORKFLOW",

	BUDGET = "BUDGET",

	MANAGEMENT = "MANAGEMENT",

	SECURITY = "SECURITY",

	WORKSPACE = "WORKSPACE"

}

interface HeaderProps {

	icon: string;

	title: string;

	pendencies: number;

}

interface ItemCardProps {

	title: string;

	icon: string;

	link: string;

}

interface IMenuItem {

	id?: number;

	parentId?: number;

	caption: string;

	icon: string;

	link: string;

	order: number;

	itemOrder?: number;

	menuItems?: IMenuItem[];

}

interface IModuleItemResponse {

	module: string;

	items: IMenuItem[];

}

export {

	IModuleItem,

	IModuleProps,

	IManageItemModalProps,

	MenuModule,

	HeaderProps,

	ItemCardProps,

	IMenuItem,

	IModuleItemResponse

}