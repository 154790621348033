import { Form, Input, Modal, Select, Space, Table, Popconfirm, Button } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { IParametizationData, IParametizationDataProps } from "../IParameterizationFromTo";
import { ColumnsType } from "antd/lib/table";
import { FlexFieldValue } from "../../attributeParameterization/IAttributeParameterization";
import { Icon } from "@iconify/react";
import { useUserContext } from "context/UserContext";
import { useParameterizationContext } from "../context/ParameterizationContext";
import { ImageBox } from "components/imageBox/ImageBox";
import { Notification } from "components/notification/Notification";
import { handleErrorRequest } from "util/functions/handleErrorRequest";

export default function ModalAddParameterization({
    filterEdit,
    dataSource,
    isModalVisible,
    isNewParameterization,
    handleVisibilityModal
}: IParametizationDataProps & { filterEdit: any }) {

    const [tableData, setTableData] = useState([]);
    const [form] = Form.useForm();
    const interfaceDestinySelected = Form.useWatch("target_interface", form)
    const flexFieldSelected = Form.useWatch("flexible_field", form)
    const [, setIsFlexibleFieldVisible] = useState(false);
    const [targetInterfaceOptions, setTargetInterfaceOptions] = useState<any[]>([]);
    const [flexFieldValueFiltered, setFlexFieldValueFiltered] = useState<FlexFieldValue[]>([]);
    const { userInfo } = useUserContext();
    const { loadTable, flexFieldValueOptions, flexFieldOptions, selectedRowKeys } = useParameterizationContext();

    useEffect(() => {
        if (isNewParameterization) {
            form.resetFields();
            setTableData([]);
        } else if (filterEdit) {
            const destinyValue = filterEdit.destinyValue;
            const { flexFieldId } = flexFieldValueOptions.find(option => option.id === destinyValue);
            form.setFieldsValue({
                target_interface: filterEdit.destiny,
                flexible_field: flexFieldId,
                source_code: filterEdit.originCode,
                target_value: destinyValue
            });
        }
    }, [filterEdit, isNewParameterization]);

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: '/revenue/parameterization/destiny-interface',
            },
            (response: any[]) => {
                const options = response.map((option) => ({
                    value: option.name,
                    label: i18n.t(`parametization_flexfield.${option.name}`.toLowerCase())
                }));
                setTargetInterfaceOptions(options);
            }
        );
    }, []);

    useEffect(() => {
        setFlexFieldValueFiltered(flexFieldValueOptions.filter(flex => flex.flexFieldId === flexFieldSelected));
    }, [flexFieldSelected, flexFieldValueOptions]);

    const handleAdd = (values) => {
        const uniqueId = Date.now();
        const destinyValue = {
            label: flexFieldValueOptions.find(({ id }) => values.target_value === id).description,
            value: values.target_value
        }
        const flexibleField = {
            label: flexFieldOptions.find(({ id }) => values.flexible_field === id).description,
            value: values.flexible_field
        }
        const newData = {
            id: uniqueId,
            flexibleField,
            originCode: values.source_code,
            destinyValue,
            destinyInterface: values.target_interface
        };
        setTableData(prevTableData => [...prevTableData, newData]);
        form.resetFields();
    };

    function handleDelete(index) {
        const newData = [...tableData];
        newData.splice(index, 1);
        setTableData(newData);
    }

    function handleEdit(tableParameterize: IParametizationData) {
        const editList = [...tableData]
        const index = editList.findIndex(item => item.id === tableParameterize.id);
        if (index !== -1) {
            const editData = editList[index];
            form.resetFields()
            form.setFieldsValue({
                flexible_field: editData?.flexibleField.value,
                target_interface: editData.destinyInterface,
                source_code: editData.originCode,
                target_value: editData.destinyValue.value,
            });
            editList.splice(index, 1);
            setTableData(editList);
        }
    }

    function doSave(values) {
        if (filterEdit) {
            updateParameterization(values)
        } else {
            handleAdd(values)
        }
    }
    function doShowNotificationAndLoadTable() {
        Notification({
            type: "success",
            message: i18n.t("successfully_saved")
        });
        loadTable();
    }

    function saveNewParameterization() {
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/revenue/parameterization`,
            params: {
                scenarioId: userInfo.selection.scenarioId,
                organizationId: userInfo.selection.organizationId,
                beansToSave: tableData.map(item => ({
                    destinyInterface: item.destinyInterface,
                    originCode: item.originCode,
                    destinyValue: item.destinyValue.value,
                    flexFieldId: item.flexibleField.value
                }))
            }
        }, doShowNotificationAndLoadTable, (err) => {
            Notification({
                type: "error",
                message: i18n.t("save_parameterization_error")
            });
        });
        handleVisibilityModal(false);
        form.resetFields();
        setTableData([]);
    }

    function updateParameterization(values) {
        const newData = {
            id: selectedRowKeys[0],
            destinyInterface: values.target_interface,
            originCode: values.source_code,
            destinyValue: values.target_value,
            organizationId: userInfo.selection.organizationId,
            scenarioId: userInfo.selection.scenarioId,
            flexFieldId: values.flexible_field
        };
        ServiceCaller.doRequest({
            type: RequestType.PUT,
            url: `/revenue/parameterization`,
            params: newData
        }, () => {
            loadTable();
            if (filterEdit) {
                Notification({
                    type: "success",
                    message: i18n.t("successfully_edited")
                });
            }
        }, (err) => {
            Notification({
                type: "error",
                message: i18n.t("save_parameterization_error")
            });
        });

        handleCancel();
    }

    const handleCancel = () => {
        form.resetFields();
        setTableData([]);
        setIsFlexibleFieldVisible(false);
        handleVisibilityModal(false);
        selectedRowKeys.length = 0;
    }

    const columns: ColumnsType<IParametizationData> = [
        {
            title: i18n.t("target_interface"),
            dataIndex: "destinyInterface",
            key: "destinyInterface",
            render(id) {
                return targetInterfaceOptions.find(({ value }) => value === id).label
            },
        },
        {
            title: i18n.t("flexible_field"),
            dataIndex: "flexibleField",
            key: "flexibleField",
            render(flexibleField) {
                return flexibleField.label
            },
        },
        {
            title: i18n.t("source_code"),
            dataIndex: "originCode",
            key: "originCode"
        },
        {
            title: i18n.t("target_value"),
            dataIndex: "destinyValue",
            key: "destinyValue",
            render(destinyValue) {
                return destinyValue.label
            },
        },
        {
            title: '',
            key: 'action',
            render: (_, index) => (
                <Space size="middle">
                    <Icon
                        icon="ic:outline-edit"
                        style={{ cursor: 'pointer', fontSize: '17px' }}
                        onClick={() => handleEdit(index)}
                    />
                    <Popconfirm
                        title="Tem certeza que deseja excluir este item?"
                        placement="topLeft"
                        onConfirm={() => handleDelete(index)}
                        okText="Sim"
                        cancelText="Cancelar"
                    >
                        <Icon icon="material-symbols-light:delete" style={{ cursor: 'pointer', fontSize: '17px' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <Modal
            className="modal-new-parameterization"
            title={isNewParameterization ? i18n.t<string>("new_parameterization") : i18n.t<string>("update_parameterization")}
            visible={isModalVisible}
            centered
            width={832}
            onCancel={handleCancel}
            onOk={
                () => {
                    if (!filterEdit) {
                        if (tableData.length) {
                            saveNewParameterization();
                        } else {
                            Notification({
                                type: 'warning',
                                message: i18n.t("parametization_flexfield")
                            })
                        }
                    }
                }}

            okButtonProps={{ htmlType: !filterEdit ? 'button' : "submit", form: "new-parameterization" }}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{ target_value: undefined }}
                onFinish={doSave}
                name="new-parameterization"
            >
                <div className="div-first-field">
                    <Form.Item
                        className="target-interface"
                        name="target_interface"
                        label={i18n.t<string>("target_interface")}
                        rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                    >
                        <Select
                            placeholder={i18n.t<string>("select")}
                            className="select-target-interface"
                            style={{ width: interfaceDestinySelected ? 373 : 762 }}
                            options={targetInterfaceOptions}
                        />
                    </Form.Item>

                    {interfaceDestinySelected &&
                        <Form.Item
                            className="flexible-field"
                            name="flexible_field"
                            label={i18n.t<string>("flexible_field")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                placeholder={i18n.t<string>("select")}
                                style={{ width: 373 }}
                                onSelect={() => form.setFieldsValue({
                                    target_value: undefined
                                })}
                                options={flexFieldValueOptions ? flexFieldOptions.map(option => ({
                                    value: option.id,
                                    label: option.description
                                })) : []}
                                className="select-flexible-field"
                            />
                        </Form.Item>
                    }
                </div>

                <div id="second-field">
                    <div>
                        <Form.Item
                            className="source-code"
                            name="source_code"
                            label={i18n.t<string>("source_code")}
                        >
                            <Input
                                className="input"
                                name="source_code"
                                placeholder={i18n.t<string>("type_here")}
                            />
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            className="target-value"
                            name="target_value"
                            label={i18n.t<string>("target_value")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                placeholder={i18n.t<string>("select")}
                                className="input"
                                options={flexFieldValueFiltered.map(value => ({
                                    value: value.id,
                                    label: value.description
                                }))}
                            />
                        </Form.Item>
                    </div>
                </div>

                {(!filterEdit) && (
                    <div className="div-add-table">
                        <Button
                            htmlType="submit"
                            form="new-parameterization"
                            className="gs-secondary-button"
                            icon={<Icon icon="akar-icons:circle-plus-fill" />}
                        >
                            {i18n.t<string>("add")}
                        </Button>
                    </div>
                )}
            </Form>
            {(!filterEdit && tableData && tableData.length > 0) && (
                <Table
                    className="gs-table"
                    columns={columns}
                    dataSource={tableData.map((item, index) => ({ ...item, key: index }))}
                />
            )}
            {(filterEdit || (!tableData || tableData.length === 0)) && (
                <ImageBox />
            )}
        </Modal>
    )
}