import i18n from "util/base/i18n";
import { IContributorInformation } from "../../IHiredDashboard";
import { Tooltip } from "antd";

export default function ContributorInformation({ title, value }: IContributorInformation) {
	return (
		<div style={{ padding: '0 24px', width: 200, overflow: 'hidden', textOverflow: 'ellipsis'}}>
			<h4>
				{i18n.t<string>(title)}
			</h4>
			<Tooltip title={value}>
				<span style={{ color: '#0065b3', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 500, whiteSpace: 'nowrap' }}>
					{value}
				</span>
			</Tooltip>
		</div>
	)
}