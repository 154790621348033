const getWithoutSpecials = (textSource: string, removeSpace: boolean = false, removeAccentuation: boolean = true, removeCase: boolean = true): string => {
	let textDest: string = Object.assign(textSource, "");

	if (removeSpace) {
		textDest = textDest.replace(/ /g, "");
	}

	if (removeAccentuation) {
		textDest = textDest.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}

	if (removeCase) {
		textDest = textDest.toLowerCase();
	}

	return textDest;
};
const capitalizeFirstLetter = (text: string): string => {
	if (!text) return text;
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
const StringUtil = {
	getWithoutSpecials,
	capitalizeFirstLetter
};

export default StringUtil;
