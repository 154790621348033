import { Checkbox, Table } from "antd";
import { useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ITransferTable, TableDataTransfer } from "../../IHiredDashboard";
import { ColumnsType } from "antd/lib/table";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";
import { TableData } from "../../../IHumanResoucers";

export default function TransferTable({ year, tableData, isFetching, setTransfersCancel }: ITransferTable){

    const tableColumns: ColumnsType = [
		{
			title: i18n.t("collaborator"),
            dataIndex: 'originDescription',
			align: 'left',
			className: 'collaborator-column'
		},
        {
			title: i18n.t("period"),
			dataIndex: 'period',
			align: 'left',
			className: 'collaborator-column'
		},
		{
			title: i18n.t("type"),
			dataIndex: 'trasnferTypeDescription',
			align: 'center',
            className: 'collaborator-column'
		},
	]

    function onSelectRow(selectedRowKeys: React.Key[], selectedRows: TableDataTransfer[]){
        const transfers = selectedRows.map(row => {
            return ({
                id: row.id,
                originEmployedResourceId: row.originId,
                destinationEmployedResourceId: row.destinationId,
                referencePeriod: row.period.split("/").reverse().join("-"),
                transferType: row.transferType
            })
        })
        setTransfersCancel(transfers);
    }

    return (
        <Table
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            rowSelection={{
                type: "checkbox",
                onChange: onSelectRow
              }}
            columns={tableColumns}
            dataSource={tableData}
            pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 100 }}
        />
    )
}