import { Divider } from "antd";
import i18n from "util/base/i18n";

export function RuleConditionsDivider() {
    return (
        <div style={{
            marginTop: "1.5rem"
        }}>
            <div id="attribute_parameterization-new-rule-modal-conditions-container">
                <h2 id="attribute_parameterization-new-attribute-modal-conditions-title">
                    {i18n.t<string>("conditions")}
                </h2>
            </div>

            <Divider />
        </div>
    );
}
