import { Table, Tooltip } from "antd";
import { IDataItemContentProps, IDataItemResponse, IntegrationStatus } from "../IBudgetIntegrations";
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { ColumnsType } from "antd/lib/table";
import { TablePaginationConfig, TableRowSelection } from "antd/lib/table/interface";
import StringUtil from "util/StringUtil";
import i18n from "util/base/i18n";
import moment from "moment";
import { useIntegrationsContext } from "../context/IntegrationsContext";

export default function DataItemContent({
	selected: {
		integration,
		module,
		status
	},
	selectedRowKeys,
	setSelectedRowKeys,
}: IDataItemContentProps) {
	const { searchValue, tableData, setTableData, setColumns, columns, startDate, endDate } = useIntegrationsContext();
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageSize, setCurrentPageSize] = useState(100);
	const [totalItens, setTotalItens] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const { getWithoutSpecials } = StringUtil;
	const filteredData = tableData.filter((line) => {
		if (!searchValue) return true;
		const stringRegex = getWithoutSpecials(searchValue.trim()).split(" ").map(text => `(?=.*${text})`).join("") + ".*";
		const regex = new RegExp(stringRegex);
		return regex.exec(getWithoutSpecials(Object.values(line).toString().replaceAll(",", " ")));
	}
	)
	const onLoad = (data: IDataItemResponse) => {
		let creationDateColumn: ColumnsType<any> = [];
		creationDateColumn.push({
			dataIndex: "creationDate",
			key: "creationDate",
			title: i18n.t("creation_date"),
			width: 100,
			render: (value: moment.Moment = moment()) => {
				return (
					<>
						{value && moment(value).format("DD/MM/YY HH:mm:ss")}
					</>
				)
			}
		});

		let newColumns: ColumnsType<any> = [];
		newColumns = newColumns.concat(data.content[0]?.layout.map(({ code, description }) => {
			return ({
				dataIndex: code,
				key: code,
				title: description,
				width: 100,
				render: (value, record) => {
					const codeError: string = Object.keys(record.errors).find(item => item === code);
					return (
						<>
							{
								codeError ?
									<Tooltip title={record.errors[codeError]}>
										<div className="gs-tag red">
											{value ? value : '\u00A0'}
										</div>
									</Tooltip>
									:
									<>
										{value}
									</>
							}
						</>
					)
				}
			})
		}))
		let flexFieldColumns = [];
		const lines: any[] = data.content.map(({ data, id, errors, creationDate }) => {

			let newLine = { ...data, id, errors, creationDate };

			let hasArrayAttribute = Object.values(newLine).some(value => Array.isArray(value));

			if (hasArrayAttribute) {
				newLine.flexFields.map(({ flexField, fieldExternalCode, valueExternalCode }) => {
					Object.assign(newLine, { [flexField]: valueExternalCode });

					if (!flexFieldColumns.some(({ dataIndex }) => dataIndex === flexField) || !flexFieldColumns.length) {
						flexFieldColumns.push({
							dataIndex: flexField,
							key: flexField,
							title: fieldExternalCode,
							width: 100
						});
					}
				})
			}

			return { ...newLine, flexFields: null };
		})

		setTableData(lines);

		setColumns(state => {
			let updatedColumns: ColumnsType<any> = [];
			updatedColumns.unshift(creationDateColumn[0]);
			updatedColumns = updatedColumns.concat(newColumns, flexFieldColumns);
			if (status === IntegrationStatus.ERROR || status === IntegrationStatus.DISCARTED) {
				updatedColumns = updatedColumns.concat([{
					title: i18n.t("error"),
					dataIndex: "errors",
					key: "errors",
					width: 150,
					ellipsis: true,
					render: (value: any) => {
						const allErrors: string[] = Object.values(value);
						return (
							<div className="error-column" >
								{allErrors.map(item =>
									<Tooltip title={item} key={item}>
										<p
											style={{
												textOverflow: "ellipsis",
												overflow: "hidden",
												whiteSpace: "nowrap",
												maxWidth: "120px"
											}}>{item}, </p></Tooltip>)}
							</div>
						);
					}
				}]);
			}
			return updatedColumns;
		});
		setIsLoading(false);
		updatePaginationValues(data);
	}

	const updatePaginationValues = ({ totalElements, pageable: { pageSize, pageNumber } }: IDataItemResponse) => {
		setCurrentPageSize(pageSize);
		setCurrentPage(pageNumber + 1);
		setTotalItens(totalElements);
	}

	const rowSelection: TableRowSelection<any> = {
		selectedRowKeys,
		onChange: setSelectedRowKeys,
	}

	const pagination: TablePaginationConfig = {
		current: currentPage,
		pageSize: currentPageSize,
		onChange(page: number, pageSize: number) {
			setCurrentPage(page);
			setCurrentPageSize(pageSize);
			doRequest(page, pageSize);
		},
		total: totalItens,
		hideOnSinglePage: true,
		showSizeChanger: true,
		pageSizeOptions: ["100", "250", "500", "1000"],
		showTotal: (total) => `${total} itens`,
	}

	const doRequest = (page: number = currentPage, pageSize: number = currentPageSize) => {
		setIsLoading(true);
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `budget-base/budget-integration/find-all-data-item?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}&module=${module}&integration=${integration}&status=${IntegrationStatus[status]}&locale={locale}&page=${page - 1}&size=${pageSize}&sort=lastExecution,id,desc`
		}, onLoad)
	}

	useEffect(() => {
		doRequest();
	}, [module, startDate, endDate]);

	return (
		<Table
			loading={isLoading}
			columns={columns}
			className="gs-table"
			rowSelection={rowSelection}
			dataSource={filteredData}
			pagination={pagination}
			rowKey={"id"}
			scroll={{ scrollToFirstRowOnChange: true, y: 600, x: "100%" }}
		/>
	)
}