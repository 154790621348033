import { Table } from "antd";
import { IUndoProjectionTable } from "../IUndoProjection";
import i18n from "util/base/i18n";

export default function UndoProjectionTable({
    dataTable,
    selectedRowKeys,
    onChange,
    isFetching,
    tableColumns
}: IUndoProjectionTable) {
    const rowSelection = { selectedRowKeys, onChange }

    return (
        <div className="table-container-undo">
            <Table
                loading={{
                    spinning: isFetching,
                    tip: i18n.t<string>('loading')
                }}
                className="gs-table"
                pagination={{ hideOnSinglePage: true }}
                dataSource={dataTable}
                columns={tableColumns}
                rowSelection={rowSelection}
                bordered
            />
        </div>
    )

}