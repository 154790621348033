import { Row, Col, Button } from "antd";
import { IOptionCardProps } from "../IModalFilter";


export default function OptionCard({
	count,
	title,
	onClick,
	isSelected
}: IOptionCardProps) {
	return (
		<Button
			type="text"
			onClick={onClick}
			className={isSelected ? "selected-card" : ""}
		>
			<Row className="option-filter-card">
				<Col>
					{title}
				</Col>
				<Col>
					<p>
						({count})
					</p>
				</Col>
			</Row>
		</Button>
	)
}