import { useState } from "react";

import { MessageType, RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import {
	Space,
	Avatar,
	Image,
	Typography, 
	Dropdown
} from "antd";

import { UserOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { useUserContext } from "context/UserContext";
import { UserMenu } from "structure/menu/user/UserMenu";
import { ROOT_PATH } from "util/rootPath";

export function UserButton() {
	const [useDefaultImage, setUseDefaultImage] = useState(false);
	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
	const { userInfo, isBudgetModule } = useUserContext();

	function openMenu(): void {
		setIsUserMenuOpen(true);

		ServiceCaller.doSendMessage({
			messageType: MessageType.OPEN_USER_MENU
		}, closeMenu);
	};

	function closeMenu(): void {
		setIsUserMenuOpen(false);
	};

	return <>
		{isBudgetModule ? 
			<Dropdown overlay={<UserMenu />} trigger={['click']}>
				<Space className="btn-user-content">
					<Avatar src={useDefaultImage ? <UserOutlined /> : <Image src={`${ROOT_PATH}/user/avatar`} preview={false} onError={() => setUseDefaultImage(true)} />} size={38} />

					<Typography.Title level={5}>{ userInfo.name }</Typography.Title>
					{
						isUserMenuOpen ? <Icon  icon="dashicons:arrow-up-alt2" /> : <Icon icon="dashicons:arrow-down-alt2" />
					}
				</Space>
			</Dropdown>
			:
			<button id="btn-user-info" onClick={openMenu}>
				<Space className="btn-user-content">
					<Avatar src={useDefaultImage ? <UserOutlined /> : <Image src={`${ROOT_PATH}/user/avatar`} preview={false} onError={() => setUseDefaultImage(true)} />} size={38} />

					<Typography.Title level={5}>{ userInfo.name }</Typography.Title>
					{
						isUserMenuOpen ? <Icon  icon="dashicons:arrow-up-alt2" /> : <Icon icon="dashicons:arrow-down-alt2" />
					}
				</Space>
			</button>
		}
	</>;

}
