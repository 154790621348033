import { Table } from "antd";
import { usePermissionContext } from "context/PermissionContext";
import i18n from "util/base/i18n";
import { ColumnTypes, DetailTableData, Months, ViewColumns } from "../IDetail";
import { formatNumber } from 'util/formatNumber'

export default function DetailTable({
    isFetching,
    components,
    cols,
    detailTableData,
    rowSelection,
    viewColumns
}) {
    const { functionalityPermissions } = usePermissionContext();
    return (
        <Table
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            className="gs-table"
            scroll={{ x: 1600, y: 580 }}
            components={components}
            columns={cols as ColumnTypes}
            dataSource={detailTableData}
            bordered
            rowSelection={functionalityPermissions.edit ? rowSelection : null}
            summary={(data: DetailTableData[]) => createGridSummary(data, viewColumns)}
            pagination={{
                showSizeChanger: true,
                pageSizeOptions: [10, 20, 50],
                hideOnSinglePage: detailTableData?.length < 10 ? true : false
            }}
        />
    )
}

type TotalArray = {
    month: string;
    value: string | number;
    order: number;
}

function createGridSummary(data: readonly DetailTableData[], viewColumns: ViewColumns) {
    let totalValueTotal = 0
    const totals = data.reduce((acc, row, index) => {
        Object.keys(row).forEach((key: string) => {
            if (/^\d/.test(key)) {
                if (index === 0) {
                    acc.push(
                        { month: key, value: row[key]['totalValue'], order: 3 },
                        { month: key, value: row[key]['convertedTotalValue'], order: 7 },
                    )
                    if (viewColumns.includes(ViewColumns.QUANTITY)) {
                        acc.push({ month: key, value: row[key]['quantity'], order: 1 });
                    }
                    if (viewColumns.includes(ViewColumns.UNITY_VALUE)) {
                        acc.push({ month: key, value: row[key]['unityValue'], order: 2 });
                    }
                    if (viewColumns.includes(ViewColumns.ACTIVATION_DATE)) {
                        acc.push({ month: key, value: '-', order: 4 });
                    }
                    if (viewColumns.includes(ViewColumns.CONVERTED_ACCOMPLISHED_VALUE)) {
                        acc.push({ month: key, value: row[key]['convertedAccomplishedValue'], order: 5 });
                    }
                    if (viewColumns.includes(ViewColumns.EXCHANGE_RATE)) {
                        acc.push({ month: key, value: row[key]['exchangeRate'], order: 6 });
                    }
                    if (viewColumns.includes(ViewColumns.DEPRECIATED_VALUE)) {
                        acc.push({ month: key, value: row[key]['depreciatedValue'], order: 8 });
                    }
                } else {
                    const totalValue = acc.find(item => item.month === key && item.order === 3);
                    totalValue.value += row[key]['totalValue'];
                    const convertedTotalValue = acc.find(item => item.month === key && item.order === 7);
                    convertedTotalValue.value += row[key]['convertedTotalValue'];

                    if (viewColumns.includes(ViewColumns.QUANTITY)) {
                        const quantity = acc.find(item => item.month === key && item.order === 1);
                        quantity.value += row[key]['quantity'];
                    }
                    if (viewColumns.includes(ViewColumns.UNITY_VALUE)) {
                        const unityValue = acc.find(item => item.month === key && item.order === 2);
                        unityValue.value += row[key]['unityValue'];
                    }
                    if (viewColumns.includes(ViewColumns.CONVERTED_ACCOMPLISHED_VALUE)) {
                        const convertedValue = acc.find(item => item.month === key && item.order === 5);
                        convertedValue.value += row[key]['convertedAccomplishedValue'];
                    }
                    if (viewColumns.includes(ViewColumns.EXCHANGE_RATE)) {
                        const exchangeRate = acc.find(item => item.month === key && item.order === 6);
                        exchangeRate.value += row[key]['exchangeRate'];
                    }
                    if (viewColumns.includes(ViewColumns.DEPRECIATED_VALUE)) {
                        const depreciatedValue = acc.find(item => item.month === key && item.order === 8);
                        depreciatedValue.value += row[key]['depreciatedValue'];
                    }
                }
                totalValueTotal += row[key]['totalValue']
            }
        })
        return acc
    }, [] as TotalArray[]).sort((a, b) => {
        if (a.month > b.month) return 1;
        if (a.month < b.month) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
    });
    totals.push({ month: '', value: totalValueTotal, order: 50 })

    return (
        <>
            <Table.Summary.Row style={{ background: '#FBE6E6' }}>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={1} align="left">{i18n.t("total")}</Table.Summary.Cell>
                {totals.map((value, index) =>
                    <Table.Summary.Cell align="center" key={value.month + index} index={index + 2}>
                        {
                            value.order === 4 ? '-' :
                                value.order === 1 ? value.value ?? 0 :
                                    formatNumber(value.value as number, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                        }
                    </Table.Summary.Cell>
                )}
                <Table.Summary.Cell index={51} />
            </Table.Summary.Row>
        </>
    )
}