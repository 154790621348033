import { Modal, Tabs } from "antd"
import { PeriodTab } from "./PeriodTab";
import './DistributionModal.sass'
import { Key, useEffect, useState } from "react";
import { ResultSection } from "./ResultSection";
import { IDistributionModal, TableData } from "./IDistributionModal";
import { Notification } from "components/notification/Notification";
import { convertValues } from "./functions/convertValues";
import { HistoricTab } from "./HistoricTab";
import { PercentHistoricTab } from "./PercentHistoric";
import { useOtherEventContext } from "../../../../context/OtherEventContext";
import i18n from "util/base/i18n";
import { cloneDeep } from "lodash";
import { OtherEventDataRequest } from "../../../../IOtherEvents";

export function DistributionModal({
    isOpen,
    setModalOpen,
	selectedAccount,
    handleSavePlannedValue,
    handleDisableActionButtons
}: IDistributionModal) {
    const [periodCalcMode, setPeriodCalcMode] = useState<'percent' | 'currency'>('currency');
    const [historicCalcMode, setHistoricCalcMode] = useState<'percent' | 'currency'>('currency');
    const [isDistributionSection, setIsDistributionSection] = useState(true);
    const [remainingAmount, setRemainingAmount] = useState(0);
    const [selectedTab, setSelectedTab] = useState('1');
    const [tableData, setTableData] = useState<TableData[]>([]);
    const [periodTableData, setPeriodTableData] = useState<TableData[]>([]);
    const [historicTableData, setHistoricTableData] = useState<TableData[]>([]);
    const [percentHistTableData, setPercentHistTableData] = useState<TableData[]>([]);
    const [periodValueAmount, setPeriodValueAmount] = useState(0);
    const [percentHisValueAmount, setPercentHisValueAmount] = useState(0);
    const { otherEventDataSource, setOtherEventGridData, selectedRows, userInfo } = useOtherEventContext();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [hasHistoricValues, setHasHistoricValues] = useState(true);
    const [blockedMonths, setBlockedMonths] = useState([]);

    const currentTabData = {
        '1': periodTableData,
        '2': historicTableData,
        '3': percentHistTableData
    };

    const currentTabMode = {
        '1': periodCalcMode,
        '2': historicCalcMode,
    };

    useEffect(() => {
        if (selectedRows.length > 0) {
            createInitialTableData()
        }
    }, [selectedRows]);

    function createInitialTableData() {
        const updatedTableData: TableData[] = [];
        const updatedBlockedMonths = [];
        Object.keys(selectedRows[0].columns).forEach(month => {
            updatedTableData.push({
                key: month,
                month,
                value: 0,
                historicValue: selectedRows[0].columns[month].historicValue ?? 0
            })
        });

        setBlockedMonths(updatedBlockedMonths);
        const hasHistoric = updatedTableData.some(item => item.historicValue !== 0);
        setHasHistoricValues(hasHistoric);
        setSelectedRowKeys(updatedTableData.map(row => row.key));
        setPeriodTableData(updatedTableData);
        setPercentHistTableData(updatedTableData);
        setHistoricTableData(updatedTableData);
    }

    function resetAllValues() {
        setRemainingAmount(0);
        setPeriodValueAmount(0);
        setPercentHisValueAmount(0);
        setSelectedRowKeys([]);
        setPeriodCalcMode('currency');
        setHistoricCalcMode('currency');
        setIsDistributionSection(true);
        setSelectedTab('1');
        setTableData([]);
        createInitialTableData();
    }

    function handleOk() {
        let updatedTableData: TableData[] = JSON.parse(JSON.stringify(currentTabData[selectedTab]));
        const calculationMode = currentTabMode[selectedTab];

        if (isDistributionSection) {
            if (selectedTab === '1') {
                if (remainingAmount < 0) {
                    Notification({
                        type: 'warning',
                        message: i18n.t("fixed_expense_texts.remaining_balance_less")
                    });
                    return;
                }
                
                if (remainingAmount > 0 && calculationMode === 'percent') {
                    Notification({
                        type: 'warning',
                        message: i18n.t("fixed_expense_texts.there_is_still_balance")
                    });
                    return;
                }

                const hasValue = updatedTableData.some(item => {
                    return item.value !== 0;
                })

                if (!hasValue) {
                    Notification({
                        type: 'warning',
                        message: i18n.t("fixed_expense_texts.add_values_continue")
                    })
                    return;
                }

                if (calculationMode === 'percent') {
                    convertValues(updatedTableData, 'currency', periodValueAmount);
                }

                setPeriodTableData(updatedTableData);
                setTableData(updatedTableData);
                setIsDistributionSection(false);
                setPeriodCalcMode('currency');

                return;
            } else {
                if (selectedTab === '2') {
                    if (calculationMode === 'percent') {
                        convertValues(updatedTableData, 'currency');
                    }
                    updatedTableData.forEach(item => item.value += item.historicValue);
                }
                setTableData(updatedTableData);
                setIsDistributionSection(false);

                return;
            }
        } else {
            const budgetGridRow = cloneDeep(selectedAccount[0]);

            let dataToSave: OtherEventDataRequest = {
                organizationId: userInfo.selection.organizationId,
                scenarioId: userInfo.selection.scenarioId,
                currencyId: userInfo.currencyId,
				flexKey: "",
                columns: []
            };

            Object.keys(budgetGridRow.columns).forEach(month => {
                const value = tableData.find(item => item.month === month).value
                if (!blockedMonths.includes(month)) {
                    budgetGridRow.plannedTotal += value - budgetGridRow.columns[month].plannedValue;
                    budgetGridRow.columns[month].plannedValue = value;
                }
                dataToSave.columns.push(budgetGridRow.columns[month]);
            });

            const updatedOtherEventGridData = cloneDeep(otherEventDataSource);
            const index = updatedOtherEventGridData.findIndex(item => item.key === budgetGridRow.key);

            updatedOtherEventGridData.splice(index, 1, budgetGridRow);

            setOtherEventGridData(updatedOtherEventGridData);
            handleSavePlannedValue(dataToSave, budgetGridRow);
            handleDisableActionButtons();
            setModalOpen(false);
            resetAllValues();
        }
    }

    function handleCancel(e) {
        if (isDistributionSection) {
            setModalOpen(false);
            handleDisableActionButtons();
            resetAllValues();
        } else
            setIsDistributionSection(true);
    }

    function onChangeTab(key) {
        setSelectedTab(key);
    }

    return (
        <Modal
            title={i18n.t("fixed_expense_texts.budget_distribution")}
            className="distribution-modal-container gs-modal"
            visible={isOpen}
            onCancel={handleCancel}
            onOk={handleOk}
            okText={isDistributionSection ? i18n.t<string>("next") : i18n.t<string>("apply")}
            cancelText={isDistributionSection ? i18n.t<string>("cancel") : i18n.t<string>("back")}
            style={{ top: 20 }}
            maskClosable={false}
        >
            {isDistributionSection ?
                <Tabs activeKey={selectedTab} type="card" className="gs-tab" onChange={onChangeTab} moreIcon={null}>
                    <Tabs.TabPane tab={i18n.t("period")} key="1">
                        <PeriodTab
                            tableData={periodTableData}
                            setTableData={setPeriodTableData}
                            remainingAmount={remainingAmount}
                            setRemainingAmount={setRemainingAmount}
                            valueAmount={periodValueAmount}
                            setValueAmount={setPeriodValueAmount}
                            calculationMode={periodCalcMode}
                            setCalculationMode={setPeriodCalcMode}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            blockedMonths={blockedMonths}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("historic")} key="2">
                        <HistoricTab
                            tableData={historicTableData}
                            setTableData={setHistoricTableData}
                            calculationMode={historicCalcMode}
                            setCalculationMode={setHistoricCalcMode}
                            hasHistoricValues={hasHistoricValues}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("fixed_expense_texts.historical_percentage")} key="3">
                        <PercentHistoricTab
                            tableData={percentHistTableData}
                            setTableData={setPercentHistTableData}
                            valueAmount={percentHisValueAmount}
                            setValueAmount={setPercentHisValueAmount}
                            hasHistoricValues={hasHistoricValues}
                        />
                    </Tabs.TabPane>
                </Tabs>
                :
                <ResultSection result={tableData} />
            }
        </Modal>
    );
}

