import {
	createContext,
	ReactElement, useContext, useEffect, useState
} from "react";
import { IGroupersContext, IndexerType, IProjectionGrouperData } from "../IProjectionGroupers";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Indexer, IndexerResponse } from "module/budget/pages/budgetProjection/IBudgetProjection";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import i18n from "util/base/i18n";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { TableRowSelection } from "antd/lib/table/interface";
import { Notification } from "components/notification/Notification";

const groupersContext: React.Context<IGroupersContext> = createContext({} as IGroupersContext);
export const GroupersProvider = ({
	children
}: { children: ReactElement }): ReactElement => {
	const [isGridFetching, setIsGridFetching] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [listToSave, setListToSave] = useState<IProjectionGrouperData[]>([]);
	const [indexerList, setIndexerList] = useState<IModalityFilter[]>([]);
	const [tableData, setTableData] = useState<IProjectionGrouperData[]>([]);
	const [itemForEdit, setItemForEdit] = useState<IProjectionGrouperData | null>();
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const indexerTypeList: GenericSelection[] = [
		{
			label: i18n.t("projection_grouper.none"),
			value: IndexerType.NONE
		},
		{
			label: i18n.t("projection_grouper.simple"),
			value: IndexerType.SIMPLE
		},
		{
			label: i18n.t("projection_grouper.accumulated"),
			value: IndexerType.ACCUMULATED
		},
	];
	const getIndexerTypeDescription: Record<IndexerType, string> = {
		ACCUMULATED: i18n.t("projection_grouper.accumulated"),
		NONE: i18n.t("projection_grouper.none"),
		SIMPLE: i18n.t("projection_grouper.simple")
	}
	const rowSelection: TableRowSelection<IProjectionGrouperData> = {
		selectedRowKeys,
		onChange: (key: number[]) => {
			setSelectedRowKeys(key);
		}
	};

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/indexer`,
		}, (data: IndexerResponse[]) => {
			const newIndexer: IModalityFilter[] = data.map(({
				description,
				externalCode,
				id }) => {
				return {
					label: `${externalCode} - ${description}`,
					value: id
				}
			})

			setIndexerList(newIndexer);
		})

		getGridData();
	}, [])

	const getGridData = () => {
		setIsGridFetching(true);
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/planning/projection/grouper"
		}, setTableData, handleErrorRequest)
		setIsGridFetching(false);
	}

	const onSave = () => {
		setIsModalOpen(false);
		setItemForEdit(null);
		setIsSaving(false);
		setListToSave([]);
		setSelectedRowKeys([]);
		Notification({
			message: i18n.t("successfully_saved"),
			type: "success"
		})
		getGridData();
	}

	const handleSave = (grouperToSave?: IProjectionGrouperData) => {
		setIsSaving(true);
		const params: IProjectionGrouperData | IProjectionGrouperData[] = itemForEdit ? { ...grouperToSave, id: itemForEdit.id } : listToSave;
		const type: RequestType = itemForEdit ? RequestType.PUT : RequestType.POST;
		ServiceCaller.doRequest({
			url: "/planning/projection/grouper",
			type,
			params
		}, onSave, handleErrorRequest)
	}

	return (
		<groupersContext.Provider
			value={{
				isGridFetching,
				isModalOpen,
				setIsModalOpen,
				isSaving,
				setIsSaving,
				listToSave,
				setListToSave,
				indexerList,
				setTableData,
				tableData,
				indexerTypeList,
				getIndexerTypeDescription,
				handleSave,
				rowSelection,
				selectedRowKeys,
				setSelectedRowKeys,
				itemForEdit,
				setItemForEdit,
				setIsGridFetching,
				getGridData
			}}
		>
			{children}
		</groupersContext.Provider>
	)
}

export const useGroupersContext = (): IGroupersContext => {
	return useContext(groupersContext);
}