import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import i18n from "util/base/i18n";
import { ProfessionalCategoryTableProps } from "../IProfessionalCategory";
import { usePermissionContext } from "context/PermissionContext";

export default function ProfessionalCategoryTable({
    tableData,
    isFetching,
    selectedRowKeys,
    onChange,
    tableColumns
}: ProfessionalCategoryTableProps){
    const { functionalityPermissions } = usePermissionContext()
    const rowSelection = {selectedRowKeys, onChange};

    return (
        <Table
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            className="gs-table"
            dataSource={tableData}
            columns={tableColumns}
            rowSelection={functionalityPermissions.edit || functionalityPermissions.remove ? rowSelection : null}
            pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 20 }}
            bordered
        />
    );
}