import { Layout } from "antd";
import { Title } from "./components/Title";
import { AccountingLedgerProvider } from "./context/AccountingLedgerContext";
import { FilterHeader } from "./components/Filter/FilterHeader";
import { GridAccountingLedger } from "./components/GridAccountingLedger";
import "./AccountingLedger.sass";
import GridOperationAccountingLedger from "./components/GridOperationAccountingLedger";
import { SummaryCards } from "./components/Card/SummaryCard";

export default function AccountingLedger() {
    return (
        <AccountingLedgerProvider>
            <Layout.Header>
                <div className="accounting-ledger-header">
                    <Title />
                    <SummaryCards />
                </div>
            </Layout.Header>

            <FilterHeader />
            <div className="accounting-ledger-container-filter-operation">
                <GridOperationAccountingLedger />
            </div>

            <Layout.Content>
                <GridAccountingLedger />
            </Layout.Content>
        </AccountingLedgerProvider>
    );
}