import { Form, Input, Modal, Select } from "antd";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import { Key } from "react";
import { NewLevelModalProps } from "../IManagementAccount";

export default function NewLevelModal({
    isModalVisible,
    handleCancel,
    form,
    handleNewLevel,
    isSelectDisabled,
    lookupLevels,
    validateExternalCode
}: NewLevelModalProps) {

    return (
        <Modal
            width={550}
            centered={true}
            title={i18n.t("register_level")}
            open={isModalVisible}
            onCancel={handleCancel}
            afterClose={() => form.resetFields()}
            className="gs-modal"
            cancelText={i18n.t<string>("close")}
            okText={i18n.t<string>("save")}
            okButtonProps={{ htmlType: "submit", form: "new-level" }}
            destroyOnClose={true}
            wrapClassName="budget-groupings"
        >
            <Form
                form={form}
                name="new-level"
                onFinish={handleNewLevel}
                initialValues={{ originLevel: 0 }}
            >
                <div className="new-level-origin">
                    <label htmlFor="origin-level">
                        {i18n.t("origin_level")}
                    </label>
                    <Form.Item
                        name={"originLevel"}
                        rules={[{
                            required: true,
                            message: i18n.t<string>("required_field")
                        }]}
                    >
                        <Select
                            disabled={isSelectDisabled} placeholder={i18n.t("select")} id="origin-level" options={[{
                                label: i18n.t("root_level"),
                                children: null,
                                parent: 0,
                                value: 0
                            }, ...lookupLevels]}
                            {...selectProps}
                        />
                    </Form.Item>
                </div>
                <div className="new-level-inputs">
                    <div>
                        <label htmlFor="external-code">
                            {i18n.t("external_code")}
                        </label>
                        <Form.Item
                            name={"externalCode"}
                            rules={[
                                {
                                    required: true,
                                    message: i18n.t<string>("required_field")
                                },
                                () => ({
                                    validator(_, value: string) {
                                        return validateExternalCode(value,
                                            i18n.t<string>("the_external_code_field_cannot_repeat"));
                                    },
                                })
                            ]}
                        >
                            <Input
                                id="external-code"
                                placeholder={i18n.t("type_here")}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <label htmlFor="description">
                            {i18n.t("description")}
                        </label>
                        <Form.Item
                            name={"description"}
                            rules={[{
                                required: true,
                                message: i18n.t<string>("required_field")
                            }]}
                        >
                            <Input
                                id="description"
                                placeholder={i18n.t("type_here")}
                            />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}