import { useEffect, useState, useCallback } from "react";
import i18n from "util/base/i18n";
import { cloneDeep } from "lodash";
import { ROOT_PATH } from "util/rootPath";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { BudgetGridData, PlanBudgetResponse, RowType } from "./IFixedExpense";
import { useBudgetOpeningContext } from "./context/useBudgetOpeningContext";
import { Loading } from "components/loading/Loading";
import { Filters } from "./components/filters/Filters";
import { GridContainer } from "./components/gridContainer/GridContainer";
import { SummaryCards } from "./components/summaryCards/SummaryCards";
import { Error500 } from "components/error/Error500";
import { Notification } from "components/notification/Notification";
import { updateRowTotals } from "./components/gridContainer/functions/updateRowTotals";
import { updateParentRowValues } from "./components/gridContainer/functions/updateParentRowValues";
import { invalidadeQuery } from "util/queryClient";
import EmptyList from "./components/gridContainer/components/EmptyList";
import { generateFlexFieldKey } from "util/functions/generateFlexFieldKey";
import { usePermissionContext } from "context/PermissionContext";
import NoPermissions from "./components/gridContainer/components/NoPermissions";

export function FixedExpense() {
    const [saveStatus, setSaveStatus] = useState({ isSaving: false, savedValue: false });

    const {
        accountAccounting,
        costCenter,
        period,
        error500,
        setError500,
        userInfo,
        setBudgetOpeningCached,
        budgetGridData,
        setBudgetGridData,
        onLoadBudgetOpening,
        isFetchingGridData,
        setIsFetchingGridData,
        isLoadingCacheData,
        isFirstOpen,
        isFetchingFlexFields,
        currency,
        setIsLoadingCacheData,
        flexFieldsFilters,
        spendingPackage,
        isQueryByPackage,
        currenciesSelected,
        setAccountsWithValue,
        accountAccountingOptions,
        spendingPackageOptions,
        functionalityPermissions,
        setIsFetchingFlexFields,
        isFetchingFuncPermissions
    } = useBudgetOpeningContext();
    useEffect(() => {
        if (isFirstOpen) {
            setIsFetchingGridData(false)
            setIsLoadingCacheData(false)
            setIsFetchingFlexFields(false)
            return
        }
        if (!period[0]) return;
        if (!isQueryByPackage && accountAccounting?.length === 0) {
            setIsFetchingGridData(false);
            return;
        }
        if (isQueryByPackage && spendingPackage?.length === 0) {
            setIsFetchingGridData(false);
            return;
        }
    }, [period, accountAccounting, spendingPackage]);
    const handleSavePlannedValue = useCallback(
        (dataToSave, budgetGridRow: BudgetGridData, previousValue: BudgetGridData) => {
            setSaveStatus({ isSaving: true, savedValue: false });
            dataToSave.plannedMonths = dataToSave.plannedMonths.map((plannedMonth) => {
                return {
                    ...plannedMonth, currencyId: currenciesSelected[0].value
                }
            })
            ServiceCaller.doRequest(
                {
                    type: RequestType.POST,
                    url: "/expenses/plan-budget?scenario={scenario}&organization={organization}&user={user}",
                    params: [dataToSave],
                },
                (data: PlanBudgetResponse[]) => {
                    setSaveStatus({ isSaving: true, savedValue: false });
                    setSaveStatus({ isSaving: false, savedValue: true });
                    const updatedBudgetData = [...budgetGridData];
                    invalidadeQuery(["summary-cards-fixed-expenses"]);
                    data.forEach((planBudgetResponse) => {
                        const index = updatedBudgetData.findIndex(
                            (budget) => budget.budgetOpening.id === budgetGridRow.budgetOpening.id
                        );

                        if (dataToSave.rowType !== RowType.PLANNED) {
                            const detailIdList = planBudgetResponse.plannedMonths.reduce((acc, val) => {
                                const index = acc.findIndex((item) => item === val.detailId);
                                if (index < 0) {
                                    acc.push(val.detailId);
                                }
                                return acc;
                            }, []);
                            detailIdList.forEach((detailId) => {
                                const detailAccount = budgetGridRow.detailList.find(
                                    (detailRow) => detailRow.detailId === detailId
                                );
                                planBudgetResponse.plannedMonths.forEach((plannedMonth) => {
                                    const month = plannedMonth.month;
                                    if (plannedMonth.detailId === detailId) {
                                        detailAccount.plannedMonths[month] = {
                                            ...plannedMonth,
                                            rate: detailAccount.plannedMonths[month].rate,
                                        };
                                        if (detailAccount.children) {
                                            detailAccount.children[0].plannedMonths[month] = {
                                                ...plannedMonth,
                                                rate: detailAccount.plannedMonths[month].rate,
                                            };
                                            detailAccount.children[1].plannedMonths[month] = {
                                                ...plannedMonth,
                                                rate: detailAccount.plannedMonths[month].rate,
                                            };
                                        }
                                    }
                                });
                                updateRowTotals(detailAccount);
                            });
                            updateParentRowValues(budgetGridRow);
                        } else {
                            planBudgetResponse.plannedMonths.forEach((plannedMonth) => {
                                const month = plannedMonth.month;
                                budgetGridRow.plannedMonths[month] = {
                                    ...plannedMonth,
                                    rate: budgetGridRow.plannedMonths[month].rate,
                                };
                            });
                            updateRowTotals(budgetGridRow);
                        }

                        updatedBudgetData.splice(index, 1, budgetGridRow as BudgetGridData);
                    });
                    setBudgetGridData(updatedBudgetData);

                    setTimeout(() => {
                        setSaveStatus({ isSaving: false, savedValue: false });
                    }, 3000);
                },
                (err) => {
                    if (err.message && err.message === "expenses.cannot.change.while.import.data") {
                        Notification({
                            type: "warning",
                            message: i18n.t(err.message),
                        });
                        const updatedBudgetGridData = cloneDeep(budgetGridData);
                        const updatedBudgetGridRow = updatedBudgetGridData.find(
                            (b) => b.budgetOpening.id === budgetGridRow.budgetOpening.id
                        );
                        const index = updatedBudgetGridData.findIndex(
                            (b) => b.budgetOpening.id === budgetGridRow.budgetOpening.id
                        );
                        if (previousValue.rowType === RowType.DETAIL) {
                            const detailRow = updatedBudgetGridRow.detailList.find(
                                (detail) => detail.detailId === previousValue.detailId
                            );
                            detailRow.plannedMonths = previousValue.plannedMonths;
                            if (detailRow.detailList) {
                                detailRow.detailList.forEach(
                                    (child) => (child.plannedMonths = previousValue.plannedMonths)
                                );
                            }
                        } else {
                            updatedBudgetGridRow.plannedMonths = previousValue.plannedMonths;
                        }
                        updatedBudgetGridData.splice(index, 1, updatedBudgetGridRow);
                        setBudgetGridData(updatedBudgetGridData);
                    } else {
                        handleErrorRequest(err);
                    }
                    setSaveStatus({ isSaving: true, savedValue: false });
                }
            );
        },
        [budgetGridData]
    );

    function handleCreateBudgetOpeningAccount() {
        setIsFetchingGridData(true);
        setAccountsWithValue(false);
        const budgetOpeningDataAccount = {
            organizationId: userInfo?.selection?.organizationId,
            businessUnitId: userInfo?.selection?.businessUnitId,
            costCenterId: costCenter[0].value,
            accounts: accountAccounting.sort((a, b) => a.value - b.value).map((item) => item.value),
            currencyId: userInfo?.currencyId,
            flexFieldFilter: generateFlexFieldKey(flexFieldsFilters),
            scenarioId: userInfo?.selection?.scenarioId,
            period: [period[0].valueOf(), period[1].valueOf()],
            clientId: userInfo?.clientId,
            localeId: userInfo?.localeId,
            userId: userInfo?.id,
        }
        setBudgetOpeningCached(budgetOpeningDataAccount);
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/expenses/budget-opening/open-budget-by-account",
                params: budgetOpeningDataAccount,
            },
            onLoadBudgetOpening,
            () => setError500(true)
        );
    }
    function handleCreateBudgetOpening() {
        setIsFetchingGridData(true);
        setAccountsWithValue(false);
        const budgetOpeningData = {
            id: null,
            spendingPackage: spendingPackage.map((item) => item.value),
            organizationId: userInfo?.selection?.organizationId,
            businessUnitId: userInfo?.selection?.businessUnitId,
            currencyId: userInfo?.currencyId,
            costCenterId: costCenter[0].value,
            accounts: isQueryByPackage
                ? []
                : accountAccounting.sort((a, b) => a.value - b.value).map((item) => item.value),
            flexFieldFilter: generateFlexFieldKey(flexFieldsFilters),
            scenarioId: userInfo?.selection?.scenarioId,
            localeId: userInfo?.localeId,
            clientId: userInfo?.clientId,
            userId: userInfo?.id,
            period: [period[0].valueOf(), period[1].valueOf()],
            flexFieldInfoData: flexFieldsFilters,
        };

        setBudgetOpeningCached(budgetOpeningData);
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/expenses/budget-opening",
                params: budgetOpeningData,
            },
            onLoadBudgetOpening,
            () => setError500(true)
        );
    }
    function onClickNewBudgetOpening() {
        if (costCenter.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.please_select_a_cost_center_to_continue"),
            });
            return;
        }
        if (accountAccounting.length === 0 && spendingPackage.length === 0) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.please_select_at_least_one_ledger_account_to_continue"),
            });
            return;
        }
        if (period[0] === null) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.select_a_period_to_continue"),
            });
            return;
        }
        setIsLoadingCacheData(true);
        if (isQueryByPackage) {
            handleCreateBudgetOpening();
        } else {
            handleCreateBudgetOpeningAccount();
        }
    }

    if (error500) {
        return <Error500 />;
    }
    const accountAccountingFilter = budgetGridData.filter((item) => item.accounting);
    const spendingPackageFilter = budgetGridData.filter((item) => item.spendingPackage);

    const getViewContent = (): React.ReactElement => {
        let content: React.ReactElement;

        if (!functionalityPermissions.accounts && !functionalityPermissions.package) {
            content = <NoPermissions />;
        } else if (((isQueryByPackage && spendingPackageFilter.length === 0) || (!isQueryByPackage && accountAccountingFilter.length === 0)) || budgetGridData.length === 0) {
            content = <EmptyList onClickNewBudgetOpening={onClickNewBudgetOpening} isSpendingPackage={spendingPackage.length > 0} />
        } else {
            content =  <div id="df-grid-container">
                <GridContainer
                    key='grid-container'
                    saveStatus={saveStatus}
                    handleSavePlannedValue={handleSavePlannedValue}
                    isFetching={isFetchingGridData}
                    setIsFetching={setIsFetchingGridData}
                    handleCreateBudgetOpeningAccount={handleCreateBudgetOpeningAccount}
                    handleCreateBudgetOpening={handleCreateBudgetOpening}
                    functionalityPermissions={functionalityPermissions}
                />
            </div>
        }

        return content;
    };

    return <>
        <div
            style={{ justifyContent: "space-between", height: 80, margin: "0px auto 16px auto", padding: "0 16px" }}
            className="page-title-content"
        >
            <h1>{i18n.t("fixed_expense_texts.fixed_expenses")}</h1>
            <SummaryCards savedValue={saveStatus.savedValue}/>
            <div style={{ marginRight: "30px" }}>
                <img
                    src={`${ROOT_PATH}/renderer/image/${currency?.image}`}
                    alt={currency?.isoCode}
                    style={{ marginRight: "5px", borderRadius: "50%", objectFit: "cover", width: 16, height: 16 }}
                />
                <label style={{ fontSize: "13px" }}>{currency?.isoCode}</label>
            </div>
        </div>

        <Filters functionalityPermissions={functionalityPermissions} />

        {isLoadingCacheData || isFetchingFlexFields || isFetchingGridData || isFetchingFuncPermissions ? (
            <div id="grid-container">
                <Loading />
            </div>
        ) : (
            <section
                style={{
                    height: "calc( 100vh - 180px)",
                    width: "100%",
                    backgroundColor: "#F1F2F3",
                    padding: "8px",
                }}
            >{getViewContent()}</section>
        )}
    </>;

}
