import { Icon } from "@iconify/react";
import { Button, Divider, Radio, RadioChangeEvent, Select, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { IPopOverConfigs } from "../../IReportView";
import { ReportViewContext } from "../../context/ReportViewContext";
import { Loading } from "components/loading/Loading";

interface IConfigurationsPopOver {
    handleClosePopOver: () => void;
}

export function ConfigurationsPopOver({ handleClosePopOver }: IConfigurationsPopOver) {
    const { reportConfig, handleReportDataChange } = useContext(ReportViewContext);

    const [isInformationsLoaded, setIsInformationsLoaded] = useState(false);
    const [dataDisplayUnit, setDataDisplayUnit] = useState(0);
    const [decimalPlaces, setDecimalPlaces] = useState(0);

    function handleDataDisplayUnit(e: RadioChangeEvent) {
        const { value: selectedRadioOption } = e.target;
        setDataDisplayUnit(selectedRadioOption);
    }

    function handleDecimalPlaces(decimalPlacesQty: number) {
        setDecimalPlaces(decimalPlacesQty);
    }

    function handleSave() {
        const newViewConfig = {
            showUnity: false,
            showThousand: false,
            showGraphic: false,
        };

        if (dataDisplayUnit === 1) {
            newViewConfig.showUnity = true;
        }

        if (dataDisplayUnit === 2) {
            newViewConfig.showThousand = true;
        }

        const configurations = {
            ...newViewConfig,
            decimalPlaces,
            id: reportConfig.id
        };

        ServiceCaller.doRequest({
            type: RequestType.PATCH,
            url: "/revenue/report/configuration/save-config",
            params: configurations
        }, (response: any) => {
            handleReportDataChange(configurations);

            notification.success({
                message: i18n.t<string>('revenue.report_viewer.report_view_filters_setted_succesfully'),
                placement: "topRight",
                duration: 3,
            });

            handleClosePopOver();

        }, (err: ErrorRequest) => {
            handleErrorRequest(err);
            handleClosePopOver();
        });
    }

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/revenue/report/configuration/get-visualization-config/${reportConfig.id}`,
        }, (response: IPopOverConfigs) => {
            setIsInformationsLoaded(true);

            if (response.showUnity) {
                setDataDisplayUnit(1);
            }

            if (response.showThousand) {
                setDataDisplayUnit(2);
            }

            setDecimalPlaces(response.decimalPlaces);
        }, (err: ErrorRequest) => {
            handleErrorRequest(err);
        });
    }, [reportConfig]);

    return (
        <div id="configurations_pop_over_filters">
            {isInformationsLoaded ? (
                <>
                    <div id="configurations_pop_over_title">
                        <h3>{i18n.t<string>('settings')}</h3>
                        <Icon
                            icon="ph:x"
                            onClick={handleClosePopOver}
                        />
                    </div>

                    <Divider style={{ margin: "0", padding: 0, marginTop: 15, marginBottom: 10 }} />

                    <div id="configurations_pop_over_preferences">
                        <span>{i18n.t<string>('preferences')}</span>

                        <Radio.Group
                            options={[
                                { label: i18n.t<string>('revenue.report_viewer.show_in_unit'), value: 1 },
                                { label: i18n.t<string>('revenue.report_viewer.show_in_thousand'), value: 2 },
                            ]}
                            value={dataDisplayUnit}
                            onChange={handleDataDisplayUnit}
                        />
                    </div>

                    <Divider style={{ margin: "0 0 15px 0", padding: 0 }} />

                    <div id="configurations_pop_over_decimal_houses">
                        <span>{i18n.t<string>('decimal_places')}</span>
                        <Select
                            value={decimalPlaces}
                            options={[
                                { label: '0', value: 0 },
                                { label: '0,0', value: 1 },
                                { label: '0,00', value: 2 },
                                { label: '0,000', value: 3 },
                                { label: '0,0000', value: 4 },
                                { label: '0,00000', value: 5 },
                                { label: '0,000000', value: 6 },
                            ]}
                            onChange={e => handleDecimalPlaces(Number(e))}
                        />
                    </div>

                    <div id="configurations_pop_over_buttons">
                        <Button
                            type="primary"
                            onClick={handleSave}
                        >
                            {i18n.t<string>('save')}
                        </Button>
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
}
