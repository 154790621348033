import { Button } from "antd";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import i18n from "util/base/i18n";
import { importProps } from "../utils/importProps";
import { IEmptyListProps } from "../../../IFixedExpense";
import { useBudgetOpeningContext } from "../../../context/useBudgetOpeningContext";

export default function EmptyList({ onClickNewBudgetOpening, isSpendingPackage }: IEmptyListProps) {
    const {
        isQueryByPackage,
    } = useBudgetOpeningContext();
    isSpendingPackage = isQueryByPackage
    return (
        <div className="budget-initial-content">
            <img src="/assets/images/budget.png" alt="wfn-budget" />
            <h2>{i18n.t("fixed_expense_texts.shall_we_start_your_fixed_expense_planning")}</h2>
            <p>
                {i18n.t("fixed_expense_texts.select_filters")}{" "}
                <span style={{ fontWeight: 500 }}>{i18n.t("cost_center")}</span> {i18n.t("and")}{" "}
                <span style={{ fontWeight: 500 }}>
                    {isSpendingPackage ? i18n.t("spending_package") : i18n.t("ledger_account")}
                </span>{" "}        
                {i18n.t("fixed_expense_texts.at_the_top_of_the_screen")}
            </p>
            <div className="budget-initial-content-buttons">
                <ImportExportMenu importProps={importProps} buttonType="button" />
                <Button
                    style={{ padding: "0 40px" }}
                    onClick={onClickNewBudgetOpening}
                    className="gs-main-button openning-budget-button"
                >
                    {i18n.t("begin")}
                </Button>
            </div>
        </div>
    )
}
