import { FlexField, IConditionGrouperType } from "../../../../IAttributeParameterization";

export enum ModalType {
    STANDARD,
    FIXED_EXPENSE,
    STANDARD_WITH_FORMULA
}

export enum ModalFlexFieldsModule {
    REVENUE = "REVENUE",
    FIXED_EXPENSE = "EXPENSES",
    REVENUE_AND_FIXED_EXPENSE = "REVENUE_AND_FIXED_EXPENSE"
}

export interface RuleModalProps {
    modalType: ModalType;
    modalTitle: string;
    flexFieldsModule: ModalFlexFieldsModule;
}

export interface EditedRulePayload {
    id: number;
    scenarioId: number;
    externalCode: string;
    description: string;
    formulas: any[],
    attributeId: number;
    ruleOrder: number;
    conditionsIdsToDelete: number[];
    createdAndUpdatedConditions: any[];
}

export interface buildEditedRulePayloadProps {
    formulas: any[];
    externalCode: string;
    description: string;
    conditionsIdsToDelete: any[];
    createdAndUpdatedConditions: any[];
}

export enum IRuleLevelType {
    ACCOUNTING_ACCOUNT,
    ACCOUNTING_ACCOUNT_GROUP,
    COST_CENTER,
    FLEX_FIELD,
    BUSINESS_UNIT
}
export interface IRuleLevel {
    label: string;
    content: any;
    type: IRuleLevelType;
    externalCode?: string;
}

export interface IRuleLevelFlexField extends IRuleLevel {
    content: FlexField;
}

export interface IRuleOperationFromRequest {
    id: number;
    name: string;
}

export interface IRuleOperation {
    label: string;
    content: string;
}

export interface IRuleLevelValue {
	externalCode?: string;
    label: string;
    content: any;
}

export interface ICostCenterFromRequest {
    id: number;
    name: string;
    isUpgraded: number;
    isDefault: number;
    category: string;
    costCenterPlanId: number;
    externalCode: string;
}

export interface IAccountingAccountFromRequest {
    id: number;
    name: string;
    externalCode: string;
    chartOfAccountId: number;
    classification: string;
    isDefault: number;
    isUpgraded: number;
}

export interface IAccountingAccountGroupFromRequest {
    id: number;
    parent: number;
    showIcon: boolean;
    externalCode: string;
    description: string;
    children: any[];
}

export enum IRuleConditionOperation {
    EQUALS = "equals",
    DIFFERENT = "different",
    CONTAINS = "contains",
    NOT_CONTAINS = "not_contains",
    FROM_TO = "from_to",
}

export interface IConditionToSendToBackend {
    orderCondition: number;
    moduleType: string;
    partsConditions: any[];
}

export interface IRuleCondition {
    id?: number;
    grouperType: IConditionGrouperType;
    type: IRuleLevelType;
    selectedLevel: IRuleLevel;
    operation: IRuleOperation;
    selectedLevelValue: IRuleLevelValue;
}

export interface IRuleToSendToBackend {
    scenarioId: number;
    externalCode: string;
    description: string;
    ruleOrder: number;
    attributeId: number;
    conditions: IConditionToSendToBackend[];
    formulas?: any[];
}


export interface IPartConditionFromRequest {
    attributeConditionId: number;
    operationOrder: number;
    operationRuleType?: string;
    description?: string;
    flexFieldId?: number;
    flexFieldValueId?: number;
    valueType?: string;
}

export interface IConditionFromRequest {
    id: number;
    moduleType: string;
    orderCondition: number;
    partsConditions: IPartConditionFromRequest[];
}

export interface IBusinessUnitFromRequest {
    id: number;
    name: string;
}

export interface IFormulaPartFromRequest {
    orderPosition: number;
    ruleId: number;
    operationId: number;
    operationName: string;
    templateFieldId: number;
    formulaAttribute: boolean;
    type?: string;
}
