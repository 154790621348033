import { useContext, useEffect, useState } from "react";
import { Button, Form, Select } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import i18n from "util/base/i18n";

import { RuleModalContext } from "../../../../context/RuleModalContext";
import { CustomSelect } from "../../../custom/CustomSelect";
import {
	FlexField,
	FlexFieldValue,
	IConditionGrouperType,
} from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { ErrorRequest } from "util/types/types";
import {
	IAccountingAccountFromRequest,
	IAccountingAccountGroupFromRequest,
	IBusinessUnitFromRequest,
	ICostCenterFromRequest,
	IRuleCondition,
	IRuleLevel,
	IRuleLevelFlexField,
	IRuleLevelType,
	IRuleLevelValue,
	IRuleOperation,
	IRuleOperationFromRequest,
	ModalFlexFieldsModule,
} from "./IModalFactory";
import { transformJsonObjectToObject } from "module/budget/pages/revenue/utils/jsonParse";
import { handleErrorRequest } from "util/functions/handleErrorRequest";

export function RuleConditionsForm() {
	const { selectedConditionGrouper, handleCreateMultipleCondition } = useContext(RuleModalContext);

	const [ruleLevels, setRuleLevels] = useState<IRuleLevel[]>([]);
	const [ruleOperations, setRuleOperations] = useState<IRuleOperation[]>([]);
	const [ruleLevelValues, setRuleLevelValues] = useState<IRuleLevelValue[]>([]);

	const [ruleLevelSelected, setRuleLevelSelected] = useState<IRuleLevel>(null);
	const [ruleOperationSelected, setRuleOperationSelected] = useState<IRuleOperation>(null);
	const [rulesLevelValueSelected, setRulesLevelValueSelected] = useState<string[]>([]);

	function createCostCenter(): IRuleLevel {
		return {
			label: i18n.t<string>("cost_center"),
			type: IRuleLevelType.COST_CENTER,
			content: {
				id: 9999,
				description: "COST_CENTER",
			},
			externalCode: ""
		};
	}

	function createAccountingAccount(): IRuleLevel {
		return {
			label: i18n.t<string>("accounting_account"),
			type: IRuleLevelType.ACCOUNTING_ACCOUNT,
			content: {
				id: 9998,
				description: "ACCOUNTING_ACCOUNT",
			},
			externalCode: ""
		};
	}

	function createAccountingAccountGroup(): IRuleLevel {
		return {
			label: i18n.t<string>("revenue.grouper_account"),
			type: IRuleLevelType.ACCOUNTING_ACCOUNT_GROUP,
			content: {
				id: 9997,
				description: "ACCOUNTS_GROUP",
			},
			externalCode: ""
		};
	}

	function createBusinessUnit(): IRuleLevel {
		return {
			label: i18n.t<string>("business_unit"),
			type: IRuleLevelType.BUSINESS_UNIT,
			content: {
				id: 9996,
				description: "BUSINESS_UNIT",
			},
			externalCode: ""
		};
	}

	function getRuleOperations(): Promise<IRuleOperationFromRequest[]> {
		return new Promise((resolve, reject) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: `/revenue/operation?isCalcOptions=false`,
				},
				(response: IRuleOperationFromRequest[]) => {
					resolve(response);
				}
			);
		});
	}

	function getFlexFieldsValues(flexFieldId: number): Promise<FlexFieldValue[]> {
		return new Promise((resolve, reject) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: `/budget-base/flex-field-value?user={user}&flexFieldId=${flexFieldId}`,
				},
				(response: FlexFieldValue[]) => {
					resolve(response);
				}
			);
		});
	}

	function getCostCenters(): Promise<ICostCenterFromRequest[]> {
		return new Promise((resolve, reject) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: `/monolith/cost-center?client={client}&locale={locale}&organization={organization}`,
				},
				(response: ICostCenterFromRequest[]) => {
					resolve(response);
				},
				(err: ErrorRequest) => {
					handleErrorRequest(err);
				}
			);
		});
	}

	function getAccountingAccounts(): Promise<IAccountingAccountFromRequest[]> {
		return new Promise((resolve, reject) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: `/monolith/accountingaccount?client={client}&locale={locale}&organization={organization}`,
				},
				(response: IAccountingAccountFromRequest[]) => {
					resolve(response);
				},
				(err: ErrorRequest) => {
					handleErrorRequest(err);
				}
			);
		});
	}

	function getAccountingAccountGroups(): Promise<IAccountingAccountGroupFromRequest[]> {
		return new Promise((resolve, reject) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: `/budget-base/account-hierarchy/find-last-childrens?&businessUnit={businessUnit}&client={client}&locale={locale}&user={user}&organization={organization}`,
				},
				(response: IAccountingAccountGroupFromRequest[]) => {
					resolve(response);
				},
				(err: ErrorRequest) => {
					handleErrorRequest(err);
				}
			);
		});
	}

	function getBusinessUnits(): Promise<IBusinessUnitFromRequest[]> {
		return new Promise((resolve, reject) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: `/monolith/business-unit/findAllByOrganizationIdWithSecurity?localeId={locale}&clientId={client}&organizationId={organization}&userId={user}`,
				},
				(response: IBusinessUnitFromRequest[]) => {
					resolve(response);
				},
				(err: ErrorRequest) => {
					handleErrorRequest(err);
				}
			);
		});
	}

	function getLevels(): Promise<IRuleLevel[]> {
		setRuleLevels([]);
		setRuleOperations([]);
		setRuleLevelValues([]);
		setRuleLevelSelected(null);
		setRuleOperationSelected(null);
		setRulesLevelValueSelected([]);

		return new Promise(async (resolve, reject) => {
			if (selectedConditionGrouper === IConditionGrouperType.REVENUE) {
				const flexFields = await getFlexFieldsByModule();

				resolve(
					flexFields.map<IRuleLevel>((flexField) => {
						return {
							label: flexField.description,
							type: IRuleLevelType.FLEX_FIELD,
							content: flexField,
							externalCode: flexField.externalCode,
						};
					})
				);
			}

			const flexFields = (await getFlexFieldsByModule()).map<IRuleLevel>((flexField) => {
				return {
					label: flexField.description,
					type: IRuleLevelType.FLEX_FIELD,
					content: flexField,
					externalCode: flexField.externalCode,
				};
			});

			const costCenter = createCostCenter();
			const accountingAccount = createAccountingAccount();
			const accountingAccountGroup = createAccountingAccountGroup();
			const businessUnit = createBusinessUnit();

			resolve([...flexFields, costCenter, accountingAccount, accountingAccountGroup, businessUnit]);
		});
	}

	function getFlexFieldsByModule(): Promise<FlexField[]> {
		const flexFieldsModule =
			selectedConditionGrouper === IConditionGrouperType.REVENUE
				? ModalFlexFieldsModule.REVENUE
				: ModalFlexFieldsModule.FIXED_EXPENSE;

		return new Promise((resolve, reject) => {
			ServiceCaller.doRequest(
				{
					type: RequestType.GET,
					url: `/budget-base/flex-field/find-all-by-module?module=${flexFieldsModule}`,
				},
				(response: FlexField[]) => {
					resolve(response);
				},
				(err: ErrorRequest) => {
					reject(err);
				}
			);
		});
	}

	async function handleLevels(selectedLevelJson: string) {
		setRuleLevelValues([]);
		setRulesLevelValueSelected([]);
		const selectedLevel = transformJsonObjectToObject<IRuleLevel>(selectedLevelJson);

		switch (selectedLevel.type) {
			case IRuleLevelType.FLEX_FIELD:
				const selectedFlexFieldLevel: IRuleLevelFlexField = { ...selectedLevel };
				const flexFieldValues = await getFlexFieldsValues(selectedFlexFieldLevel.content.id);

				setRuleLevelValues(
					flexFieldValues.map((flexFieldValue) => {
						return {
							label: flexFieldValue.description,
							content: flexFieldValue,
							externalCode: flexFieldValue.externalCode
						};
					})
				);
				break;
			case IRuleLevelType.COST_CENTER:
				const costCenters = await getCostCenters();

				setRuleLevelValues(
					costCenters.map((costCenter) => {
						return {
							label: costCenter.name,
							content: costCenter,
							externalCode: costCenter?.externalCode
						};
					})
				);
				break;
			case IRuleLevelType.ACCOUNTING_ACCOUNT:
				const accountingAccounts = await getAccountingAccounts();

				setRuleLevelValues(
					accountingAccounts.map((accountingAccount) => {
						return {
							label: accountingAccount.name,
							content: accountingAccount,
							externalCode: accountingAccount?.externalCode
						};
					})
				);
				break;
			case IRuleLevelType.ACCOUNTING_ACCOUNT_GROUP:
				const accountingAccountGroups = await getAccountingAccountGroups();

				setRuleLevelValues(
					accountingAccountGroups.map((accountingAccountGroup) => {
						return {
							label: accountingAccountGroup.description,
							content: accountingAccountGroup,
							externalCode: accountingAccountGroup?.externalCode
						};
					})
				);
				break;
			case IRuleLevelType.BUSINESS_UNIT:
				const businessUnits = await getBusinessUnits();

				setRuleLevelValues(
					businessUnits.map((businessUnit) => {
						return {
							label: businessUnit.name,
							content: businessUnit,
							externalCode: businessUnit?.name
						};
					})
				);
				break;
		}

		setRuleLevelSelected(selectedLevel);
	}

	function handleOperation(selectedOperationJson: string) {
		const selectedOperation = transformJsonObjectToObject<IRuleOperation>(selectedOperationJson);

		setRuleOperationSelected({
			content: selectedOperation.content,
			label: i18n.t<string>(`revenue.operations.${selectedOperation.label}`),
		});
	}

	function handleLevelsValue(selectedLevelValue: string[]) {
		setRulesLevelValueSelected(selectedLevelValue);
	}

	function handleAddCondition() {
		const allConditions: IRuleCondition[] = rulesLevelValueSelected.map((ruleLevelValue => {
			const { content, label } = transformJsonObjectToObject<IRuleLevelValue>(ruleLevelValue);
			return ({
				grouperType: selectedConditionGrouper,
				type: ruleLevelSelected.type,
				operation: {
					content: ruleOperationSelected.content,
					label: ruleOperationSelected.label,
				},
				selectedLevel: {
					content: ruleLevelSelected.content,
					label: ruleLevelSelected.label,
					type: ruleLevelSelected.type,
					externalCode: ruleLevelSelected.externalCode,
				},
				selectedLevelValue: {
					content: content,
					label: label,
					externalCode: content.externalCode,
				},
			})
		}))
		handleCreateMultipleCondition(allConditions);
		setRuleLevelSelected(null);
		setRuleOperationSelected(null);
		setRulesLevelValueSelected([]);
	}

	useEffect(() => {
		setRuleLevels([]);
		setRuleOperations([]);
		setRuleLevelValues([]);

		getLevels().then((levels) => setRuleLevels(levels));

		getRuleOperations()
			.then((operationsFromRequest) => {
				return operationsFromRequest.map<IRuleOperation>((operationFromRequest) => {
					return {
						label: operationFromRequest.name,
						content: operationFromRequest.name,
					};
				});
			})
			.then((operations) => setRuleOperations(operations));
	}, [selectedConditionGrouper]);

	return (
		<>
			<div className="attribute_parameterization-new-rule-modal-form">
				<div className="form-field">
					<CustomSelect
						id="levels"
						hasSearch
						value={ruleLevelSelected}
						labelName={i18n.t<string>("levels")}
						onChange={handleLevels}
						disabled={ruleLevels.length === 0}
						options={ruleLevels
							.sort((a, b) => {
								const textA = a?.content?.externalCode?.toUpperCase();
								const textB = b?.content?.externalCode?.toUpperCase();
								return textA < textB ? -1 : textA > textB ? 1 : 0;
							})
							.map((level: IRuleLevel) => {
								return {
									label: level.label,
									value: JSON.stringify(level),
								};
							})}
					/>
				</div>
				<div className="form-field">
					<CustomSelect
						id="operations"
						value={ruleOperationSelected}
						labelName={i18n.t<string>("operations")}
						onChange={handleOperation}
						disabled={ruleLevels.length === 0}
						options={ruleOperations.map((operation: IRuleOperation) => {
							return {
								label: i18n.t<string>(`revenue.operations.${operation.label}`),
								value: JSON.stringify(operation),
							};
						})}
					/>
				</div>
				<div className="form-field">
					<CustomSelect
						id="levelValues"
						mode="multiple"
						hasSearch
						value={rulesLevelValueSelected}
						labelName={i18n.t<string>("revenue.value_of_levels")}
						onChange={handleLevelsValue}
						disabled={!ruleLevelSelected || ruleLevelValues.length === 0}
						options={ruleLevelValues
							.sort((a, b) => {
								const textA = a?.content?.externalCode?.toUpperCase();
								const textB = b?.content?.externalCode?.toUpperCase();
								return textA < textB ? -1 : textA > textB ? 1 : 0;
							})
							.map((ruleLevelValue) => {
								return {
									label: ruleLevelValue.label,
									value: JSON.stringify(ruleLevelValue),
								};
							})}
					/>
				</div>
			</div>

			<div id="attribute_parameterization-new-attribute-modal-add-button-wrapper">
				<Button
					type="primary"
					shape="round"
					icon={<PlusCircleFilled />}
					onClick={handleAddCondition}
					disabled={!ruleLevelSelected || !ruleOperationSelected || !rulesLevelValueSelected.length}
				>
					{i18n.t<string>("add")}
				</Button>
			</div>
		</>
	);
}
