import { Icon } from "@iconify/react";
import { Button, DatePicker, Popover } from "antd";
import { Key, useState } from "react";
import { HiredTable, IDeleteResposne } from "../IHumanResoucers";
import { CollaboratorSearch } from "./CollaboratorSearch";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import DistributionValuesModal from "../hiredDashboard/components/distributionValuesModal/DistributionValuesModal";
import moment from "moment";
import i18n from "util/base/i18n";
import { ColumnsType } from "antd/lib/table";
import { Notification } from "components/notification/Notification";
import TransferModal from "../hiredDashboard/components/transferModal/TransferModal";
import RecalculateButton from "./RecalculateButton";
import ConfirmDelete from "./ConfirmDelete";
import { Options } from "util/types/types";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

interface Props {
	setOpenModal(value: boolean): void;
	hiredList: HiredTable[];
	setDeitalId: (id: number) => void;
	year: string;
	onChangeDatePicker: (date: moment.Moment, dateString: string) => void;
	tableColumns: ColumnsType;
	realizedPeriod: moment.Moment;
	getTableData: () => void;
	functionalityPermissions;
	selectedRowKeys: Key[];
	openedCalendarPeriod: number[];
	isClosedBudgetPeriod: boolean;
	setOpenModal(value: boolean): void;
	businessUnitOptions: Options[];
	costCenterOptions: Options[];
	professionalCategoryOptions: Options[];
	costCategoryOptions: Options[];
	addtionalFieldsOptions: Options[];
	isFetching: boolean;
	setIsFetching: (isFetching: boolean) => void;
}
export function OperationsBar({
	setOpenModal,
	hiredList,
	setDeitalId,
	tableColumns,
	year,
	onChangeDatePicker,
	realizedPeriod,
	getTableData,
	functionalityPermissions,
	selectedRowKeys,
	openedCalendarPeriod,
	isClosedBudgetPeriod,
	businessUnitOptions,
	costCenterOptions,
	professionalCategoryOptions,
	costCategoryOptions,
	addtionalFieldsOptions,
	isFetching,
	setIsFetching
}: Props) {
    const [showCollabPopover, setShowCollabPopover] = useState(false);
    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
	const [isdistributionValuesModalOpen, setIsdistributionValuesModalOpen] = useState(false);
	const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

    const importProps: IImportModal[] = [
        {
            importUrl: "/human-resources/hiring/import",
            templateUrl: "/human-resources/hiring/import/template?user={user}&client={client}&locale={locale}",
            urlParams: `&referenceYear=${year}`,
            type: "excel",
            title: i18n.t("human_resources.import_hr_movement"),
            hasImportOption: true,
        },
        {
            importUrl: "/human-resources/hiring/importHeadcount",
            templateUrl: "/human-resources/hiring/importHeadcount/template?user={user}&client={client}&locale={locale}",
            urlParams: `&referenceYear=${year}`,
            type: "excel",
            title: i18n.t("import_headcount_real"),
        },
    ];

    function handleOpenModal(isOpen) {
        if (realizedPeriod?.month() == 11) {
            Notification({
                message: `${i18n.t("human_resources.the_year")} ${year} ${i18n.t("human_resources.is_fully_realized")}`,
                type: "warning",
            });
        } else {
            setOpenModal(isOpen);
        }
    }

	function disableDate(current: moment.Moment) {
		if (!openedCalendarPeriod.includes(current.year())) {
			return true
		}
		return false;
	}
	
	function handleDelete() {
		if (selectedRowKeys.length > 1) {
			Notification({
                message: `${i18n.t("error_message")}`,
                type: "error",
            });
			return;
		}
		
		setIsFetching(true)
		setIsConfirmDeleteModalOpen(false);
		ServiceCaller.doRequest(
            {
                type: RequestType.DELETE,
                url: `/human-resources/hiring/delete?employedId=${selectedRowKeys}&organizationId={organization}&referenceYear=${year}&scenarioId={scenario}`,
            }, onDelete.bind(this)
        );
	}

	function onDelete(data: IDeleteResposne) {
		const message = data.message.toLowerCase();

		if (message !== "success") {
			Notification({
				message: `${i18n.t(message)}`,
				type: "warning",
			});
			getTableData();
		} else {
			Notification({
				message: `${i18n.t("successfully_deleted")}`,
				type: "success",
			});
			getTableData();
		}
	}

	return (
		<div className="actions-content">
			{functionalityPermissions.hire &&
				<Button
					onClick={() => handleOpenModal(true)}
					style={{ padding: '0 35px' }}
					className="gs-main-button"
					disabled={isClosedBudgetPeriod}
				>
					{i18n.t("human_resources.to_hire")}
				</Button>
			}
			<Popover
				visible={showCollabPopover}
				trigger='click'
				placement="bottom"
				onVisibleChange={(visible) => setShowCollabPopover(visible)}
				content={
					<CollaboratorSearch 
						hiredList={hiredList} 
						onCloseSearch={setShowCollabPopover} 
						setDeitalId={setDeitalId} 
					/>
				}
				overlayClassName="overlay-operation-bar-hr"
			>
				<Button
					onClick={() => setShowCollabPopover(true)}
					className="collaborator-search"
				>
					<p>{i18n.t("human_resources.search_by_contributor")}</p>
					<Icon icon="charm:search" />
				</Button>
			</Popover>
			<div>
				<DatePicker
					onChange={onChangeDatePicker}
					format="YYYY"
					picker="year"
					value={moment(year)}
					allowClear={false}
					disabledDate={disableDate}
				/>
			</div>
			<Button
				onClick={() => setIsdistributionValuesModalOpen(true)}
				icon={<Icon icon="fluent:text-bullet-list-ltr-24-filled" />}
				className="collaborator-header-right-buttons"
				disabled={isClosedBudgetPeriod}
			>
				{i18n.t("human_resources.distribute_values")}
			</Button>
			<RecalculateButton
				selectedRowKeys={selectedRowKeys}
				year={year}
				hiredList={hiredList}
				isClosedBudgetPeriod={isClosedBudgetPeriod}
			/>
			<Button
				onClick={() => setIsTransferModalOpen(true)}
				icon={<Icon icon="octicon:arrow-switch-24" />}
				className="collaborator-header-right-buttons"
				disabled={isClosedBudgetPeriod}
			>
				{i18n.t("human_resources.transfer_and_promotion")}
			</Button>
			{functionalityPermissions.remove &&
				<Button 
					onClick={() => setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen)}
					icon={<Icon className="delete-icon" icon="icomoon-free:bin"/>}
					className="collaborator-header-right-buttons"
					disabled={(isFetching ? isFetching : !selectedRowKeys.length) || isClosedBudgetPeriod}
				>
					{i18n.t("delete")}
				</Button>
			}
			{(functionalityPermissions.export || functionalityPermissions.import) && !isClosedBudgetPeriod &&
				<ImportExportMenu
					importProps={importProps}
					buttonType="3dots"
					handleSuccessImport={getTableData}
				/>
			}
			<DistributionValuesModal
				isModalVisible={isdistributionValuesModalOpen}
				setIsModalVisible={setIsdistributionValuesModalOpen}
				year={year}
				businessUnitOptions={businessUnitOptions}
				costCenterOptions={costCenterOptions}
				professionalCategoryOptions={professionalCategoryOptions}
				costCategoryOptions={costCategoryOptions}
				addtionalFieldsOptions={addtionalFieldsOptions}
			/>
			<TransferModal
				isModalVisible={isTransferModalOpen}
				setIsModalVisible={setIsTransferModalOpen}
				hiredList={hiredList}
				year={year}
				getTableData={getTableData}
				realizedPeriod={realizedPeriod}
				businessUnitOptions={businessUnitOptions}
				professionalCategoryOptions={professionalCategoryOptions}
				costCategoryOptions={costCategoryOptions}
			/>

			<ConfirmDelete
				handleOk={handleDelete}
				isModalVisible={isConfirmDeleteModalOpen}
				setIsModalVisible={setIsConfirmDeleteModalOpen}
			/>
		</div>
	)
}
