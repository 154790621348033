import { FormInstance, Table } from "antd";
import { Key } from "react";
import { FlexFilter, FlexFilterOptions, Options } from "util/types/types";

export interface IApportionmentListSection {
    openCalendarPeriod: moment.Moment[];
    apportionmentList: ApportionmentResponse[];
    onClickNewApportionment(): void;
    costCenterOptions: Options[];
    originAccountOptions: Options[];
    businessUnitOptions: Options[];
    isFetchingOriginAccountOptions: boolean;
    setIsFetchingOriginAccountOptions(data: boolean): void;
    flexFieldsOriginOptions: FlexFilter[];
    setFlexFieldsOriginOptions(data: FlexFilter[]): void;
    setOriginAccountOptions(data: Options[]): void;
    onOpenApportionmentInfo(data: ApportionmentResponse): void;
    isFetchingApportionments: boolean;
    apportionmentType: ApportionmentType;
    fetchApportionments(type: ApportionmentType, buId: number, costCenterId: number, accountId: number, flexFieldStringKey: string, year: number): void;
    selectedRowKeys: Key[];
    setSelectedRowKeys(k: Key[]): void
    onDelete(): void
}

export interface IFilters {
    openCalendarPeriod: moment.Moment[]
    costCenterOptions: Options[]
    originAccountOptions: Options[]
    businessUnitOptions: Options[]
    isFetchingOriginAccountOptions: boolean;
    setIsFetchingOriginAccountOptions(data: boolean): void;
    flexFieldsOriginOptions: FlexFilter[]
    setFlexFieldsOriginOptions(data: FlexFilter[]): void;
    setOriginAccountOptions(data: Options[]): void;
    fetchApportionments(type: ApportionmentType, buId: number, costCenterId: number, accountId: number, flexFieldStringKey: string, year: number): void;
}

export interface INewApportionmentSection {
    openCalendarPeriod: moment.Moment[];
    apportionmentPeriod: string[]
    setApportionmentPeriod(s: string[]): void;
    costCenterOptions: Options[]
    originAccountOptions: Options[]
    businessUnitOptions: Options[]
    isFetchingOriginAccountOptions: boolean;
    setIsFetchingOriginAccountOptions(data: boolean): void;
    flexFieldsOriginOptions: FlexFilter[]
    setFlexFieldsOriginOptions(data: FlexFilter[]): void;
    flexFieldsDestinationOptions: FlexFilter[];
    setFlexFieldsDestinationOptions(data: FlexFilter[]): void;
    setOriginAccountOptions(data: Options[]): void;
    originTableData: TableData[];
    setOriginTableData(data: TableData[]): void;
    destinationTableData: TableData[];
    setDestinationTableData(data: TableData[] | ((data: TableData[]) => void)): void;
    originCollapsed: boolean;
    setOriginCollapsed(data: boolean): void;
    destinationCollapsed: boolean;
    setDestinationCollapsed(data: boolean): void;
    setIsApportionmentListVisible(data: boolean): void;
    fetchApportionments(): void;
    apportionmentType: ApportionmentType;
    setApportionmentType(data: ApportionmentType): void;
    apportionmentList: ApportionmentResponse[]
}

export interface IApportionmentForm {
    openCalendarPeriod: moment.Moment[]
    handleSubmit(data: any): void;
    form: FormInstance;
    costCenterOptions: Options[]
    originAccountOptions: Options[]
    destinationAccountOptions: Options[]
    businessUnitOptions: Options[]
    isFetchingOriginAccountOptions: boolean;
    isFetchingDestinationAccountOptions: boolean;
    flexFieldsOriginOptions: FlexFilter[]
    setFlexFieldsOriginOptions(data: FlexFilter[]): void;
    flexFieldsDestinationOptions: FlexFilter[];
    setFlexFieldsDestinationOptions(data: FlexFilter[]): void;
    originCollapsed: boolean;
    setOriginCollapsed(data: boolean): void;
    destinationCollapsed: boolean;
    setDestinationCollapsed(data: boolean): void;
    apportionmentType: ApportionmentType;
    setApportionmentType(data: ApportionmentType): void;
    originTableData: TableData[];
    destinationTableData: TableData[];
    setIsTransitoryAccountOpen(data: boolean): void;
    setSelectedRowKeys(key: Key[]): void;
    onChangeApportionmentType(data: ApportionmentType): void;
}
export interface INewApportionmentList {
    originTableData: TableData[];
    destinationTableData: TableData[];
    setDestinationTableData(data: TableData[]): void
    selectedRowKeys: Key[]
    onChange(selectedRowKeys: React.Key[]): void
    handleEditDestination(): void
    handleDelete(): void
    apportionmentType: ApportionmentType;
    apportionmentPeriod: string[];
    onOpenTrasitoryAccountDrawer(): void;
    width: number,
    costCenterOptions: Options[];
    isLoading: boolean;
}

export interface IApportionmentList {
    apportionmentList: ApportionmentResponse[]
    onOpenApportionmentInfo(data: ApportionmentResponse): void;
    isFetchingApportionments: boolean;
    selectedRowKeys: Key[];
    setSelectedRowKeys(k: Key[]): void
}

export enum ApportionmentType {
    PERIOD = "PERIOD",
    HEADCOUNT = "HEADCOUNT",
    HISTORIC = "HISTORIC",
    ALL = "ALL",
}

export type ApportionmentData = {
    account: number;
    businessUnit: number;
}

export type TableData = {
    key: string;
    year: number;
    account: Options;
    businessUnit: Options;
    costCenter: Options;
    id?: number;
    transitoryApportionment?: {
        id?: number;
        organizationId: number;
        scenarioId: number;
        businessUnitId: number;
        costCenterId: number;
        accountingAccountId: number;
        flexFieldFilter: string;
    }
    flexFields: {
        id?: number;
        label: string,
        value: number,
        flexFieldId?: number;
    }[];
}



export type ApportionmentResponse = {
    id: number;
    year: number;
    organizationId: number;
    scenarioId: number;
    businessUnitId: number;
    businessUnitName: string;
    costCenterId: number;
    costCenterName: string;
    accountingAccountId: number;
    accountingAccountName: string;
    flexFieldFilter: string;
    apportionmentType: ApportionmentType;
    transitoryApportionment?: {
        costCenterId: number;
        accountingAccountId: number;
        flexFieldFilter: string;
    }
    destinationApportioments: {
        organizationId: number;
        scenarioId: number;
        businessUnitId: number;
        businessUnitName: string;
        costCenterId: number;
        costCenterName: string;
        accountingAccountId: number;
        accountingAccountName: string;
        flexFieldFilter: string;
        monthlyValues: {
            monthYear: string,
            value: number
            id: number
        }[]
    }[]
}

export type EditableTableProps = Parameters<typeof Table>[0];

export interface EditableRowProps {
    index: number;
}

export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof TableData;
    record: TableData;
    onCell: (a: any) => any;
}

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export type Column = ColumnTypes[number] &
{
    editable?: boolean;
    dataIndex?: string | string[];
};