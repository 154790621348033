const getWidthOfText = (text: string, fontStyle?: string): number => {
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");

	var fontSpec = (fontStyle || "") + " " + window.getComputedStyle(document.body).fontFamily;

	if (context.font !== fontSpec) {
		context.font = fontSpec;
	}

	return parseFloat(context.measureText(text).width.toFixed(2));
};

const ElementUtil = {

	getWidthOfText

};

export default ElementUtil;
