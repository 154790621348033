interface IConfiguration {

	id: number

	userId: number

	conversionCurrencyId: number

	selectedCurrencies: Array<number>

	filter: IFilter

	widgets: Array<IWidget>

	isoCode: string

}

interface IFilter {

	id: number

	filterBy: FilterBy

	values: Array<number>

}

enum FilterBy {

	CONSOLIDATION_GROUP = "CONSOLIDATION_GROUP",

	ORGANIZATION = "ORGANIZATION",

	BUSINESS_UNIT = "BUSINESS_UNIT"

}

interface IWidget {

	id: number

	type: WidgetType

	position: number

	active: boolean

	opened: boolean

	filter: IWidgetFilter

	content?: IWidgetContent

}

enum WidgetType {

	DEBT_PROGRESSION = "DEBT_PROGRESSION",
	
	FUNDING_POSITION = "FUNDING_POSITION",
	
	APPLICATION_POSITION = "APPLICATION_POSITION",
	
	CONTRACT_REPRESENTATIVENESS = "CONTRACT_REPRESENTATIVENESS",
	
	BANK_BALANCE_BUSINESS = "BANK_BALANCE_BUSINESS",
	
	BANK_CONCILIATION_PENDING = "BANK_CONCILIATION_PENDING",
	
	PAYMENT_COCENTRATION = "PAYMENT_COCENTRATION",
	
	CASH_FLOW_MAIN_ROWS = "CASH_FLOW_MAIN_ROWS",
	
	CASH_FLOW_AVAILABILITY = "CASH_FLOW_AVAILABILITY"

}

interface IWidgetFilter {

	id: number

	groupBy?: WidgetGroupBy

	startDate?: Date

	endDate: Date

}

enum WidgetGroupBy {

	BANK = "BANK",

	CONTRACT_GROUP = "CONTRACT_GROUP",

	DIVISION = "DIVISION",

	MANAGEMENT_GROUP = "MANAGEMENT_GROUP",

	ORGANIZATION = "ORGANIZATION",

	PROJECT = "PROJECT",

	OPERATIONS = "OPERATIONS"

}

interface IWidgetContent {

	id: number

	flowId: number

	levels: number[]

	budgetId?: number

}

export {

	IFilter,

	FilterBy,

	IWidget,

	WidgetType,

	IWidgetFilter,

	IWidgetContent,

	IConfiguration

};
