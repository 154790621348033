import { Icon } from "@iconify/react";
import { Button, Collapse, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import DeletionConfirmationModal from "components/deletionConfirmationModal/deletionConfirmationModal";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { useState } from "react";
import i18n from "util/base/i18n";
import "../ReversalPeriodPerformed.sass";
import { useReversalPeriodPerformedContext } from "../context/ReversalPeriodPerformedContext";
import Panel from "./Panel";
import Captcha from "components/captcha/Captcha";

interface StaticInfoPanelProps {
    monthOptions: DefaultOptionType[];
    selectedMonths: string[];
    setSelectedMonths: (value: any) => void;
    displayedValue: string;
    setDisplayedValue: (value: string) => void;
    onChangePeriod: (value: any) => void;
    handleRevert: () => void;
    getRealizedPeriod: (year: string) => number;
}

const StaticInfoPanel: React.FC<StaticInfoPanelProps> = ({
    monthOptions,
    selectedMonths,
    setSelectedMonths,
    displayedValue,
    setDisplayedValue,
    onChangePeriod,
    handleRevert,
    getRealizedPeriod,
}) => {
	const { organizationList } = useReversalPeriodPerformedContext();

	const confirmDeletion = () => {
        handleRevert();
    };

    const handleDropdownVisibleChange = (open: boolean) => {
        if (open) {
            setSelectedMonths([]);
            setDisplayedValue("");
        }
    };

    const tagRender = ({ label, value }: { label: React.ReactNode; value: string }) =>
        value === displayedValue ? (
            <span>
                <Icon icon="ic:round-done" style={{ marginRight: 5, fontSize: 18 }} />
                {label}
            </span>
        ) : null;

    return (
        <div className="static-info-panel">
            <div className="period-selector">
                <label className="gs-input-label">{i18n.t<string>("period")}</label>
                <div className="selector-container">
                    <Select
                        mode="multiple"
                        options={monthOptions}
                        showSearch
                        optionFilterProp="children"
                        onChange={onChangePeriod}
                        value={selectedMonths}
                        placeholder={i18n.t<string>("select")}
                        allowClear
                        className="month-select"
                        onDropdownVisibleChange={handleDropdownVisibleChange}
                        tagRender={tagRender}
					/>
					<Captcha
						tooltipTitleI18n="reversal_period_performed.revert"
						buttonTitleI18n="reversal_period_performed.revert"
						disabledButton={selectedMonths.length === 0}
						onSuccessfulSubmit={confirmDeletion}
					/>
                </div>
            </div>

            {Object.keys(organizationList)
                .sort((a, b) => Number(b) - Number(a))
                .filter((year) => getRealizedPeriod(year))
                .map((year) => (
                    <Collapse
                        key={year}
                        expandIcon={() => <span style={{ pointerEvents: "none" }}></span>}
                        collapsible="disabled"
                        className="organization-panel-year-box"
                    >
                        <Panel year={year} realizedPeriod={getRealizedPeriod(year)} />
                    </Collapse>
                ))}
        </div>
    );
};

export default StaticInfoPanel;
