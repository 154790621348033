import { PlusCircleFilled } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import { Button, Select, Tooltip } from "antd";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import i18n from "util/base/i18n";
import { CurrencyAndMeasuringChoice, IMainColumn, RevenueConstants, RevenueItemTable } from "../../IRevenue";

export default function MainColumnGrid(mainColumnGrid: IMainColumn) {

    const handleRowClick = (record: RevenueItemTable, isEditStatus: boolean) => {
        mainColumnGrid.setIsEdit(isEditStatus);
        mainColumnGrid.setCurrencyAndMeasuringChoice({ currencyId: record.currencyId, measuringId: record.measuringUnitId });
        mainColumnGrid.setSelectedRow(record.keyCreationCoin);
    };

    const handleMeasuringChoice = (measuring) => {
        let currentChoice: CurrencyAndMeasuringChoice = mainColumnGrid.currencyAndMeasuringChoice;
        currentChoice.measuringId = measuring;

        mainColumnGrid.setCurrencyAndMeasuringChoice(currentChoice);
    }

    const handleCurrencyChoice = (currency) => {
        let currentChoice: CurrencyAndMeasuringChoice = mainColumnGrid.currencyAndMeasuringChoice;
        currentChoice.currencyId = currency;

        mainColumnGrid.setCurrencyAndMeasuringChoice(currentChoice);
    }

    function openRowAfterUpsert(data) {
        mainColumnGrid.updateTreeGrid();
        mainColumnGrid.setHasSavedValue(true);
        let keyCoin = data.measuringUnitId + "-" + data.currencyId + "-" + data.flexFieldValueId + "-" + data.fieldId;
        mainColumnGrid.setExpandedRowKeys([...mainColumnGrid.expandedRowKeys, keyCoin]);
    }

    function updateOrInsertCoin(row: RevenueItemTable) {
        mainColumnGrid.handleIsLoading(true);
        if (mainColumnGrid.isEdit) {
            ServiceCaller.doRequest({
                url: `/revenue/field/value`,
                type: RequestType.POST,
                params: {
                    id: row.id,
                    flexFieldValueIds: row.flexFieldValueIds,
                    currencyId: mainColumnGrid.currencyAndMeasuringChoice.currencyId,
                    measuringUnitId: mainColumnGrid.currencyAndMeasuringChoice.measuringId
                }
            }, openRowAfterUpsert.bind(this), (err: ErrorRequest) => {
                mainColumnGrid.handleIsLoading(false);
                handleErrorRequest(err);
            });
        } else {
            ServiceCaller.doRequest({
                url: `/revenue/field/value`,
                type: RequestType.POST,
                params: {
                    flexFieldValueIds: row.flexFieldValueIds,
                    currencyId: mainColumnGrid.currencyAndMeasuringChoice.currencyId,
                    measuringUnitId: mainColumnGrid.currencyAndMeasuringChoice.measuringId,
                }
            }, openRowAfterUpsert.bind(this), (err: ErrorRequest) => {
                mainColumnGrid.handleIsLoading(false);
                handleErrorRequest(err);
            });
        }

        mainColumnGrid.setSelectedRow(null);
    }

    function verifyClassnameForCellByKey(record: RevenueItemTable): string {
        return record.hasCheckbox && !record.key.includes('lineBeforeCoin') ? 'revenue-colum-principal' :
            !record.hasCheckbox && record.key.includes('lineBeforeCoin') && record.key !== mainColumnGrid.selectedRow ? 'revenue-hidden-row-coin' :
                !record.hasCheckbox && record.key.includes('lineBeforeCoin') && record.key === mainColumnGrid.selectedRow ? 'revenue-row-create-coin' :
                    !record.hasCheckbox && !record.key.includes('lineBeforeCoin') ? 'revenue-colum-principal-row-caption' : null
    }

    return {
        title: i18n.t<string>("hierarchy"),
        dataIndex: 'name',
        key: 'name',
        width: 'max-content',
        fixed: 'left',
        onCell: (record: RevenueItemTable) => ({
            className: verifyClassnameForCellByKey(record),
            colSpan: record.key.includes('lineBeforeCoin') ? mainColumnGrid.monthsColumns.length + 2 : null
        }),
        render: (text, record: RevenueItemTable) => (
            <div style={{ display: 'flex' }}>
                {record && record.measuringUnitId && record.hasCheckbox ? (
                    <>

                        <div style={{ display: 'flex', gap: 4, height: 25, marginTop: mainColumnGrid.selectedRow && record.key === mainColumnGrid.selectedRow ? 10 : null, paddingRight: 30 }}>
                            <p style={{ color: "#5C5C5C", backgroundColor: "#F1F2F3", borderRadius: 2, height: 20, paddingLeft: 5, paddingRight: 5, fontSize: 11 }}>
                                <Tooltip placement="topLeft" title={record.currencyName}>
                                    {record.isoCode}
                                </Tooltip>
                            </p>
                            <p style={{ color: "#0065B3", backgroundColor: "#E8F2F9", borderRadius: 2, height: 20, paddingLeft: 5, paddingRight: 5, fontSize: 11 }}>
                                <Tooltip placement="topLeft" title={record.measuringUnitName}>
                                    {record.symbol}
                                </Tooltip>
                            </p>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {mainColumnGrid.functionalityPermissions.edit && record.children.length > 1 && (
                                    <Tooltip placement="topLeft" title={i18n.t<string>("edit_coin_measuring")}>
                                        <Button
                                            style={{ border: 'none', boxShadow: 'none', background: 'none', top: -4 }}
                                            icon={<Icon icon={"mdi-light:pencil"}
                                                style={{ border: 'none', boxShadow: 'none' }} />}
                                            onClick={() => handleRowClick(record, true)} />
                                    </Tooltip>
                                )}
                                {mainColumnGrid.functionalityPermissions.new && record.children.length > 1 && (
                                    <Tooltip placement="topLeft" title={i18n.t<string>("add_coin_measuring")}>
                                        <Button
                                            style={{ border: 'none', boxShadow: 'none', color: '#0065B3', background: 'none', top: -4 }}
                                            icon={<PlusCircleFilled />}
                                            onClick={() => handleRowClick(record, false)}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        {record.type && record.type === RevenueConstants.CALCULATED ? (
                            <div style={{ display: 'flex', gap: 8 }}>
                                <div className="caption-level" />
                                <span style={{ color: "#424242", opacity: 0.6, fontSize: 11 }}>
                                    {record.name}
                                </span>
                            </div>
                        ) : (
                            <>
                                {record.key.includes('lineBeforeCoin') ? (
                                    <>
                                        {mainColumnGrid.selectedRow && mainColumnGrid.selectedRow === record.key && (
                                            <>
                                                <div style={{ gap: 8, display: 'flex', height: 25 }} >
                                                    <Button
                                                        style={{ border: 'none', boxShadow: 'none', color: '#0065B3', background: 'none', top: -4, pointerEvents: "none" }}
                                                        icon={<PlusCircleFilled />}
                                                    />
                                                    <Select
                                                        style={{ width: 180, height: 25 }}
                                                        options={mainColumnGrid.currencyList.map(currency => ({ label: currency.name, value: currency.id }))}
                                                        defaultValue={mainColumnGrid.isEdit ? mainColumnGrid.currencyList.find((item) => item.id === record.currencyId).name : i18n.t<string>("select_coin")}
                                                        onChange={handleCurrencyChoice}
                                                    />
                                                    <Select
                                                        style={{ width: 180 }}
                                                        options={mainColumnGrid.measuringList.map(measuring => ({ label: measuring.description, value: measuring.id }))}
                                                        defaultValue={mainColumnGrid.isEdit ? mainColumnGrid.measuringList.find((item) => item.id === record.measuringUnitId).description : i18n.t<string>("select_measuring")}
                                                        onChange={handleMeasuringChoice}
                                                    />
                                                    <Button
                                                        style={{
                                                            height: 25,
                                                        }}
                                                        className="gs-secondary-button"
                                                        onClick={() => updateOrInsertCoin(record)}
                                                    >
                                                        {i18n.t<string>("save")}
                                                    </Button>
                                                    <Button
                                                        className="button-cancel"
                                                        style={{
                                                            height: 25
                                                        }}
                                                        onClick={() => (mainColumnGrid.setSelectedRow(null), mainColumnGrid.setCurrencyAndMeasuringChoice({ currencyId: 0, measuringId: 0 }))}>
                                                        {i18n.t<string>("cancel")}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <div style={{ display: 'flex', gap: 8, paddingRight: 30 }}>
                                        {record.idTemplate && (
                                            <div className="caption-level" />
                                        )}
                                        <span style={{ fontSize: 12 }}>
                                            {record.name}
                                        </span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        )
    }
}