import * as React from "react";

import { LoadingOutlined } from '@ant-design/icons';

import { Status } from "rc-steps/lib/interface";

import {
	Space,
	Steps,
	Table
} from "antd";

import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

import {
	ICashFlowStep,
	ICashFlowStepProps
} from "./ICashFlowWidget";

import i18n from "util/base/i18n";
import Button from "antd/es/button";

export default function CashFlowStepperWidget(props: ICashFlowStepProps) {

	const [currentStep, setCurrentStep]: [number, Function] = React.useState(0);

	const [saving, setSaving]: [boolean, Function] = React.useState(false);

	const [flowSelectionStep, setFlowSelectionStep]: [ICashFlowStep, Function] = React.useState({
		index: 0,
		title: i18n.t<string>("select_model"),
		loading: true,
		status: props.flowId != null ? "finish" : "process",
		gridColumns: [{
			title: i18n.t<string>("name"),
			dataIndex: "description",
			key: "description"
		}],
		gridData: [],
		singleSelect: true,
		selectedValues: [],
		defaultSelectedValues: props.flowId != null ? [props.flowId] : []
	});

	const [flowLevelSelectionStep, setFlowLevelSelectionStep]: [ICashFlowStep, Function] = React.useState({
		index: 1,
		title: i18n.t<string>("select_levels"),
		loading: false,
		status: props.levels != null && props.levels.length > 0 ? "finish" : "wait",
		gridColumns: [{
			title: i18n.t<string>("external_code"),
			dataIndex: "externalCode",
			key: "externalCode",
			width: 250
		}, {
			title: i18n.t<string>("name"),
			dataIndex: "description",
			key: "description"
		}],
		gridData: [],
		singleSelect: false,
		selectedValues: [],
		defaultSelectedValues: props.levels != null ? props.levels : []
	});

	const [budgetSelectionStep, setBudgetSelectionStep]: [ICashFlowStep, Function] = React.useState({
		index: 2,
		title: i18n.t<string>("select_budget"),
		loading: false,
		status: props.budgetId != null ? "finish" : "wait",
		gridColumns: [{
			title: i18n.t<string>("name"),
			dataIndex: "description",
			key: "description"
		}],
		gridData: [],
		defaultGridData: {
			id: 0,
			description: i18n.t<string>("without_budget")
		},
		singleSelect: true,
		selectedValues: [],
		defaultSelectedValues: props.budgetId != null ? [props.budgetId] : [0]
	});

	React.useEffect(() => {
		const {
			selectedValues: [ flowId ]
		} = flowSelectionStep;

		if (flowId) {
			setFlowLevelSelectionStep({
				...flowLevelSelectionStep,
				loading: true
			});

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/monolith/cash/flow/${flowId}/level/find-all`
			}, onLoadFlowLevelGrid.bind(this));

			setBudgetSelectionStep({
				...budgetSelectionStep,
				loading: true
			});

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/monolith/cash/budget/find-all?flow=${flowId}`
			}, onLoadBudgetGrid.bind(this));
		}
	}, [flowSelectionStep.selectedValues]);

	const onLoadFlowLevelGrid = (gridData: any[]) => {
		gridData = sortFlowLevelGridData(gridData);

		const defaultValueToSelect = findFlowLevelsByDefaultSelection(gridData);

		setFlowLevelSelectionStep({
			...flowLevelSelectionStep,
			loading: false,
			selectedValues: [...defaultValueToSelect],
			gridData
		});
	};

	const findFlowLevelsByDefaultSelection = (gridData: any[]): number[] => {
		let levels: number[] = [];

		gridData.forEach(({ id, children }) => {
			if (children) {
				levels = levels.concat(findFlowLevelsByDefaultSelection(children));
			}

			if (flowLevelSelectionStep.defaultSelectedValues.indexOf(id) > -1) {
				levels.push(id);
			}
		});

		return levels;
	};

	const sortFlowLevelGridData = (data: any[]): any[] => {
		data = data.map(item => {
			if (item.children && item.children.length > 0) {
				item.children = sortFlowLevelGridData(item.children);
			} else {
				delete item.children;
			}

			return item;
		});

		return data.sort(({ ordenation: ordenationA }, { ordenation: ordenationB }) => ordenationA - ordenationB);
	};

	const onLoadBudgetGrid = (gridData: any[]): void => {
		const defaultValueToSelect = budgetSelectionStep.defaultSelectedValues.filter(defaultValue => defaultValue === 0 || gridData.find(({ id }) => id === defaultValue));

		gridData.sort(({ description: descriptionA }, { description: descriptionB }) => descriptionA.localeCompare(descriptionB));
		gridData.unshift(budgetSelectionStep.defaultGridData);

		setBudgetSelectionStep({
			...budgetSelectionStep,
			loading: false,
			selectedValues: [...defaultValueToSelect],
			gridData
		});
	};

	const getStepByIndex = (index: number): ICashFlowStep => {
		switch (index) {
			case 0:
				return flowSelectionStep;
			case 1:
				return flowLevelSelectionStep;
			case 2:
				return budgetSelectionStep;
		};
	}

	const nextStep = () => {
		setCurrentStep(currentStep+1);
	};

	React.useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/monolith/cash/flow/find-all?client={client}&user={user}"
		}, onLoadFlowGrid.bind(this));
	}, []);

	const onLoadFlowGrid = (gridData: any[]) => {
		gridData.sort(({ description: descriptionA }, { description: descriptionB }) => descriptionA.localeCompare(descriptionB));

		setFlowSelectionStep({
			...flowSelectionStep,
			loading: false,
			selectedValues: [...flowSelectionStep.defaultSelectedValues],
			gridData
		});
	};

	const onSelectGridData = (selectedValues: number[]): void => {
		if (saving) {
			return;
		}

		if (currentStep === 0) {
			setFlowSelectionStep({
				...flowSelectionStep,
				selectedValues,
				status: "finish"
			});

			setFlowLevelSelectionStep({
				...flowLevelSelectionStep,
				gridData: [],
				defaultSelectedValues: [],
				status: "process"
			});

			setBudgetSelectionStep({
				...budgetSelectionStep,
				gridData: [],
				defaultSelectedValues: [0],
				status: "wait"
			});

			nextStep();
		} else if (currentStep === 1) {
			setFlowLevelSelectionStep({
				...flowLevelSelectionStep,
				selectedValues,
				status: selectedValues.length > 0 ? "finish" : "process"
			});

			setBudgetSelectionStep({
				...budgetSelectionStep,
				status: selectedValues.length > 0 ? "finish" : "wait"
			});
		} else if (currentStep === 2) {
			setBudgetSelectionStep({
				...budgetSelectionStep,
				selectedValues
			});
		}
	};

	const onSaveSelection = () => {
		setSaving(false);

		const {
			selectedValues: [flowId]
		} = flowSelectionStep;

		const {
			selectedValues: levels
		} = flowLevelSelectionStep;

		const {
			selectedValues: [budgetId]
		} = budgetSelectionStep;

		props.reloadView(flowId, levels, budgetId)
	};

	const saveSelection = () => {
		setSaving(true);

		const {
			selectedValues: [flowId]
		} = flowSelectionStep;

		const {
			selectedValues: levels
		} = flowLevelSelectionStep;

		const {
			selectedValues: [budgetId]
		} = budgetSelectionStep;

		ServiceCaller.doRequest({
			type: RequestType.PUT,
			url: "/dashboard-analytics/widget/content",
			params: {
				id: props.contentId,
				flowId: flowId,
				levels: levels,
				budgetId: budgetId
			}
		}, onSaveSelection.bind(this));
	};

	const renderCurrentStepContent = (): React.ReactElement[] => {
		const {
			loading,
			gridColumns,
			gridData,
			singleSelect,
			selectedValues
		} = getStepByIndex(currentStep);

		let buttonSave: React.ReactElement = <></>;

		if (currentStep === budgetSelectionStep.index) {
			buttonSave = <>
				<Button onClick={saveSelection} type="primary" loading={saving}>{ i18n.t<string>("apply") }</Button>
			</>;
		}

		return [
			<Table columns={gridColumns} dataSource={gridData} rowSelection={{
				type: singleSelect ? "radio" : "checkbox",
				selectedRowKeys: selectedValues,
				onChange: onSelectGridData.bind(this)
			}} pagination={false} rowKey="id" scroll={{
				y: 275
			}} locale={{emptyText: loading ? <LoadingOutlined style={{fontSize: "40px", padding: "100px 0"}} /> : null}} />,
			buttonSave
		]
	};

	return <>
		<Steps current={currentStep} type="navigation" size="small" onChange={(stepIndex) => {
			if (getStepByIndex(stepIndex).status !== "wait" && !saving) {
				setCurrentStep(stepIndex);
			}
		}}>
			<Steps.Step title={flowSelectionStep.title} status={saving ? "wait" : flowSelectionStep.status as Status} icon={flowSelectionStep.loading ? <LoadingOutlined /> : null} />
			<Steps.Step title={flowLevelSelectionStep.title} status={saving ? "wait" : flowLevelSelectionStep.status as Status} icon={flowLevelSelectionStep.loading ? <LoadingOutlined /> : null} />
			<Steps.Step title={budgetSelectionStep.title} status={saving ? "wait" : budgetSelectionStep.status as Status} icon={budgetSelectionStep.loading ? <LoadingOutlined /> : null} />
		</Steps>

		<Space direction="vertical" size={10} align="end" style={{width: "100%"}}>
			{ renderCurrentStepContent() }
		</Space>
	</>;

}