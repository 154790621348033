import {
	IRequest,
	MessageType,
	ResponseStatus,
	IMessage
} from "./IServiceCaller";

const Window: any = window;

function getCallerUID(): number {
	let callerUID: number = Window.Date.now();

	const {
		callerCallbacks
	} = Window;

	while (callerCallbacks.hasOwnProperty(callerUID)) {
		callerUID = Math.floor(Math.random() * callerUID) + 1;
	}

	return callerUID;
};

function doSendMessage<M extends IMessage>(data: M, successCallback?: Function, errorCallback?: Function): void {
	const hasSuccessCallback: boolean = successCallback !== undefined && successCallback !== null;
	const hasErrorCallback: boolean = errorCallback !== undefined && errorCallback !== null;

	if (hasSuccessCallback || hasErrorCallback) {
		data.callerUID = getCallerUID();
		Window.callerCallbacks[data.callerUID] = {
			...(hasSuccessCallback && { [ResponseStatus.SUCCESS]: successCallback }),
			...(hasErrorCallback && { [ResponseStatus.ERROR]: errorCallback })
		};
	}

	Window.parent.postMessage(data, `${process.env.REACT_APP_GS_ROOT_PATH}`);
}

function doRequest(data: IRequest, successCallback?: Function, errorCallback?: Function): void {
	data.messageType = MessageType.REQUEST;

	if (data.useProxy === undefined) {
		data.useProxy = true;
	}
	if (data.isFile === undefined) {
		data.isFile = false;
	}
	if (data.getPermissions === undefined) {
		data.getPermissions = false;
	}

	doSendMessage(data, successCallback, errorCallback);
}

async function doAsyncRequest<T>(data: IRequest): Promise<{ data: T, success: boolean }> {
	try {
		return await new Promise<{ data: T, success: boolean }>((resolve, reject) => {
			doRequest(
				data,
				(data) => {
					resolve({
						success: true,
						data
					});
				},
				(error) => {
					if (error?.errorCode === 'AUTH0001') {
						ServiceCaller.doSendMessage({
							messageType: MessageType.REFRESH_SCREEN,
						});
					}
					reject({
						success: false,
						data: error
					});
				}
			);
		});
	} catch (error) {
		return {
			success: false,
			data: error
		}
	}
}

export const ServiceCaller = {
	doRequest,
	doSendMessage,
	doAsyncRequest
};
