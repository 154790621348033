import { Icon } from "@iconify/react"
import { Spin, Form, Row, Col, Input, DatePicker, Button, Collapse, Select, Checkbox } from "antd"
import moment from "moment";
import i18n from "util/base/i18n"
import { RevisionContainer } from "./RevisionContainer"
import { OrganizationBudgetDates, AnnualDatesFormData, getOrderByModuleType } from "../../IControlPanel"
import { ModuleType, Options } from "util/types/types"
import { ServiceCaller } from "util/service/ServiceCaller"
import { RequestType } from "util/service/IServiceCaller";
import { useUserContext } from "context/UserContext"
import { Notification } from "components/notification/Notification"
import { handleErrorRequest } from "util/functions/handleErrorRequest"
import { invalidadeQuery } from "util/queryClient"
import { useEffect } from "react"

const { RangePicker } = DatePicker
const { Panel } = Collapse;

interface IAnnualPanel {
    year: number;
    openPeriod: moment.Moment[];
    openBudget: moment.Moment[];
    realizedPeriod: number;
    frozenPeriod: number;
    moduleType: ModuleType;
    organization: OrganizationBudgetDates;
    onOpenRevisionModal(year: number): void;
    segmentTypeOptions: Options[]
    unitConversionOptions: Options[]
    revisions: any[]
    hasAnnualBinding: boolean;
    segmentType: number;
    unitConversionId: number;
    revenueBasePeriod: number;
}

export function AnnualPanel({
    year,
    openPeriod,
    openBudget,
    realizedPeriod,
    frozenPeriod,
    moduleType,
    organization,
    onOpenRevisionModal,
    segmentTypeOptions,
    unitConversionOptions,
    revisions,
    hasAnnualBinding,
    segmentType,
    unitConversionId,
    revenueBasePeriod
}: IAnnualPanel) {
    const [form] = Form.useForm()
    const { userInfo } = useUserContext()

    useEffect(() => {
        form.setFieldsValue({
            fiscalYear: year,
            budgetPeriod: openBudget[0] ? [moment(openBudget[0]), moment(openBudget[1])] : [],
            openPeriod: openPeriod[0] ? [moment(openPeriod[0]), moment(openPeriod[1])] : [],
            realizedPeriod: realizedPeriod ? moment(realizedPeriod) : null,
            frozenPeriod: frozenPeriod ? moment(frozenPeriod) : null,
            hasAnnualBinding: hasAnnualBinding,
            segmentType,
            unitConversionId,
            basePeriod: revenueBasePeriod ? moment(revenueBasePeriod) : null
        })
    }, [year, openPeriod, openBudget, realizedPeriod, frozenPeriod, segmentType, hasAnnualBinding, unitConversionId, form])

    async function onSaveNewFiscalYear(data: AnnualDatesFormData) {
        const dataToSave = {
            organizationId: organization.organizationId,
            scenario: userInfo.selection.scenarioId,
            year: data.fiscalYear,
            hasAnnualBinding: data.hasAnnualBinding,
            moduleType: getOrderByModuleType[moduleType],
            [moduleType]: {
                [`${moduleType}RealizedPeriod`]: data.realizedPeriod ? data.realizedPeriod.date(15).valueOf() : null,
                [`${moduleType}FrozenPeriod`]: data.frozenPeriod ? data.frozenPeriod.date(15).valueOf() : null,
                [`${moduleType}OpeningStart`]: data.openPeriod[0].valueOf(),
                [`${moduleType}OpeningEnd`]: data.openPeriod[1].valueOf(),
                [`${moduleType}BudgetStart`]: data.budgetPeriod[0].date(15).valueOf(),
                [`${moduleType}BudgetEnd`]: data.budgetPeriod[1].date(15).valueOf(),
                unitConversionId: data.unitConversionId,
                segmentType: data.segmentType,
                basePeriod: data.basePeriod ? data.basePeriod.clone().format('YYYY-MM') : null,
                revisions: []
            }
        }

        await ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `budget-base/control-panel?client={client}&user={user}&locale={locale}&scenario={scenario}`,
            params: dataToSave
        }).then(() => {
            Notification({
                type: 'success',
                message: i18n.t("successfully_saved")
            })
            invalidadeQuery(['budget-period-dates', moduleType])
            invalidadeQuery(['organizationList'])

        }).catch(err => {
            handleErrorRequest(err.data)
        })
    }

    function disabledBudgetDate(current, type?: 'frozen') {
        if (type === 'frozen') {
            return current < moment(year, 'YYYY').startOf('year')
        }
        return current < moment(year, 'YYYY').startOf('year') || current > moment(year, 'YYYY').endOf('year')
    }

    return (
        <Collapse
            key={year}
            expandIcon={(panelProps) => <Icon icon={`ion:chevron-${panelProps.isActive ? 'up' : 'down'}`} width={18} />}
            expandIconPosition="end"
            className="organization-panel-year-box"
        >
            <Panel
                key="1"
                header={
                    <div className="gs-flex align-center">
                        <h3 style={{ margin: '0px 10px 0px 0px' }}>{year}</h3>
                        {realizedPeriod &&
                            <div className="gs-tag green gs-flex align-center" style={{ marginRight: 8 }}>
                                <Icon icon="ic:round-done" style={{ marginRight: 5, fontSize: 18 }} />
                                {`${i18n.t('realized')}: ${moment(realizedPeriod).format('MMM YYYY').charAt(0).toUpperCase()}${moment(realizedPeriod).format('MMM YYYY').slice(1)}`}
                            </div>
                        }
                        {frozenPeriod &&
                            <div className="gs-tag blue gs-flex align-center">
                                <Icon icon="ion:snow-outline" style={{ marginRight: 5, fontSize: 16 }} />
                                {`${i18n.t('frozen')}: ${moment(frozenPeriod).format('MMM YYYY').charAt(0).toUpperCase()}${moment(frozenPeriod).format('MMM YYYY').slice(1)}`}
                            </div>
                        }
                    </div>
                }
            >
                <Spin spinning={false}>
                    <Form
                        form={form}
                        onFinish={onSaveNewFiscalYear}
                        layout="vertical"
                        requiredMark={false}
                    >
                        <Row align="middle" gutter={20}>
                            <Col span={8}>
                                <Form.Item
                                    label={i18n.t('budget_year')}
                                    name="fiscalYear"
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={i18n.t("budget_period")}
                                    name="budgetPeriod"
                                    rules={[{ required: true, message: i18n.t("required_field") }]}
                                >
                                    <RangePicker
                                        format='MM/YYYY'
                                        className="gs-date-picker"
                                        picker="month"
                                        disabledDate={disabledBudgetDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={i18n.t("typing_period")}
                                    name="openPeriod"
                                    rules={[{ required: true, message: i18n.t("required_field") }]}
                                >
                                    <RangePicker
                                        format='DD/MM/YYYY'
                                        className="gs-date-picker"
                                        picker="date"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={20}>
                            <Col span={8}>
                                <Form.Item
                                    label={i18n.t("accomplished_until")}
                                    name="realizedPeriod"
                                >
                                    <DatePicker
                                        format='MM/YYYY'
                                        className="gs-date-picker form-org-date-picker"
                                        picker="month"
                                        disabledDate={disabledBudgetDate}
                                    />
                                </Form.Item>
                            </Col>
                            {moduleType !== ModuleType.PLANNING &&
                                <Col span={8}>
                                    <Form.Item
                                        label={i18n.t("frozen_until")}
                                        name="frozenPeriod"
                                    >
                                        <DatePicker
                                            format='MM/YYYY'
                                            className="gs-date-picker form-org-date-picker"
                                            picker="month"
                                            disabledDate={(current) => disabledBudgetDate(current, 'frozen')}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            {moduleType === ModuleType.EXPENSERESOURCES &&
                                <Col span={8}>
                                    <Form.Item
                                        name="hasAnnualBinding"
                                        valuePropName="checked"
                                        style={{ margin: 0 }}
                                    >
                                        <Checkbox>
                                            {i18n.t('automatic_balance_transfer')}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            }
                            {moduleType === ModuleType.REVENUE &&
                                <>
                                    <Col span={8}>
                                        <Form.Item
                                            label={i18n.t("base_period")}
                                            name="basePeriod"
                                        >
                                            <DatePicker
                                                format='MM/YYYY'
                                                className="gs-date-picker form-org-date-picker"
                                                picker="month"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label={i18n.t("budget_projection_texts.measuring_unit")}
                                            name="unitConversionId"
                                            rules={[{ required: true, message: i18n.t("required_field") }]}
                                        >
                                            <Select
                                                options={unitConversionOptions}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label={i18n.t("segment")}
                                            name="segmentType"
                                            rules={[{ required: true, message: i18n.t("required_field") }]}
                                        >
                                            <Select
                                                options={segmentTypeOptions}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                            <Col
                                className="gs-flex align-center jc-end"
                                span={8}
                                offset={16}
                                style={{ marginBottom: 16 }}
                            >
                                <Button
                                    style={{ width: 108 }}
                                    htmlType="submit"
                                    className="gs-main-button"
                                >
                                    {i18n.t<string>('save')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                {moduleType !== ModuleType.PLANNING &&
                    <RevisionContainer
                        organization={organization}
                        revisions={revisions}
                        moduleType={moduleType}
                        onOpenRevisionModal={onOpenRevisionModal}
                        year={year}
                    />
                }
            </Panel>
        </Collapse>
    )

}