import { Icon } from "@iconify/react";
import { Button } from "antd";
import i18n from "util/base/i18n";

interface BtnManageItemsProps {
  showModal: () => void;
}

export function BtnManageItems({showModal}: BtnManageItemsProps) {
  return (
    <Button id="btn-manage-cards" onClick={showModal}>
      <Icon icon="akar-icons:plus" />
      <h3>{i18n.t<string>("customize_menus")}</h3>
    </Button>
  )
}