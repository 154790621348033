import { Col, Form, Modal, Row, DatePicker } from "antd";
import i18n from "util/base/i18n";
import { IRevisionPeriodModal } from "../IControlPanel";
import moment from "moment";

const { RangePicker } = DatePicker

export function RevisionPeriodModal({ isOpen, setIsOpen, handleSubmit, revisionYear, organization, moduleType }: IRevisionPeriodModal) {
    const [form] = Form.useForm();
    //@ts-ignore
    const currentPeriod = organization?.[moduleType]?.find(item => item.year === revisionYear)
    const frozenPeriod = currentPeriod?.[`${moduleType}FrozenPeriod`]
    const realizedPeriod = currentPeriod?.[`${moduleType}RealizedPeriod`]
    function handleCancel() {
        form.resetFields();
        setIsOpen(false);
    };

    function validateCurrentYearDate(current: moment.Moment) {
        const isBeforeFrozenPeriod = current.startOf('month').isSameOrBefore(moment(frozenPeriod).endOf('month'));
        const isBeforeRealizedPeriod = current.startOf('month').isSameOrBefore(moment(realizedPeriod).endOf('month'));
        const isNotRevisionYear = current.year() !== revisionYear;

        return isNotRevisionYear || isBeforeFrozenPeriod || isBeforeRealizedPeriod;
    }

    function validateRevisionPeriod(current: moment.Moment) {
        return current < moment().startOf('day');
    }

    return (
        <Modal
            className="gs-modal"
            title={i18n.t("control_panel_texts.open_review_period")}
            visible={isOpen}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        handleSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okButtonProps={{ htmlType: 'submit', form: "revision-dates-form" }}
            onCancel={handleCancel}
            width={560}
        >
            <Form
                layout="vertical"
                requiredMark={false}
                name="revision-dates-form"
                form={form}
            >
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={i18n.t("control_panel_texts.review_period")}
                            name="revisionPeriod"
                            rules={[{ required: true, message: i18n.t("required_field") }]}
                        >
                            <RangePicker
                                format='MM/YYYY'
                                className="gs-date-picker"
                                picker="month"
                                disabledDate={validateCurrentYearDate}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={i18n.t("control_panel_texts.opening_period_for_review")}
                            name="openPeriod"
                            rules={[{ required: true, message: i18n.t("required_field") }]}
                        >
                            <RangePicker
                                format='DD/MM/YYYY'
                                className="gs-date-picker"
                                picker="date"
                                disabledDate={validateRevisionPeriod}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}