import { Button, Form, Steps } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { IPeriodColumn, IPeriodValue, NewProjectionProps, OriginData } from "../IProjectionModeling";
import StepOne from "./stepOne/StepOne";
import StepThree from "./stepThree/StepThree";
import StepTwo from "./stepTwo/StepTwo";

export default function NewProjection({
    grouperData,
    originData,
    eventData,
    costCenterList,
    accountingList,
    onCancel,
    stepOneListConditions,
    setStepOneListConditions,
    inputValue,
    setInputValue,
    form,
    formula,
    setFormula,
    plainFormula,
    setPlainFormula,
    isEditingProjection = false,
    handleSaveProjection,
    isWhole,
    setIsWhole,
    isInstallment,
    setIsInstallment,
    loadFlexFields,
    flexFieldList,
    flexFieldValues,
    fieldList,
    setFieldList,
    ledgerList,
    grouperList,
    setGrouperList,
    fetchTableData,
    selectedEvent,
    setSelectedEvent,
    projectionsOptions,
}: NewProjectionProps) {
    const [currentStep, setCurrentStep] = useState<number>(0);

    const [periodColumns, setPeriodColumns] = useState<IPeriodColumn[]>([]);
    const [periodValues, setPeriodValues] = useState<IPeriodValue[]>([]);
    const [originSelected, setOriginSelected] = useState<number>(0);
    const [originOptions, setOriginOptions] = useState<OriginData[]>([]);
    const isNextButtonDisabled = false;

    const steps = [
        {
            key: 'step-one',
            title: i18n.t<string>("human_resources.initial_information"),
            stepNumber: 1,
            stepIndex: 0,
            disabled: false
        },
        {
            key: 'step-two',
            title: i18n.t<string>("term_and_operation"),
            stepNumber: 2,
            stepIndex: 1,
            disabled: isNextButtonDisabled
        },
        {
            key: 'step-three',
            title: i18n.t<string>("competition_and_cash"),
            stepNumber: 3,
            stepIndex: 2,
            disabled: isNextButtonDisabled
        },
    ]

    const stepsContents = [
        <StepOne
            key={"step-one"}
            currentStep={currentStep}
            form={form}
            originOptions={originOptions}
            eventData={eventData}
            stepOneListConditions={stepOneListConditions}
            setStepOneListConditions={setStepOneListConditions}
            loadFlexFields={loadFlexFields}
            flexFieldValues={flexFieldValues}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            flexFieldList={flexFieldList}
            ledgerList={ledgerList}
            grouperData={grouperData}
            grouperList={grouperList}
			setGrouperList={setGrouperList}
			setOriginSelected={setOriginSelected}
        />,
        <StepTwo
            key={"step-two"}
            currentStep={currentStep}
            form={form}
            selectedEvent={selectedEvent}
            formula={formula}
            projectionsOptions={projectionsOptions}
            accountingList={accountingList}
            setFormula={setFormula}
            plainFormula={plainFormula}
            setPlainFormula={setPlainFormula}
            loadFlexFields={loadFlexFields}
            isEditingProjection={isEditingProjection}
            setPeriodColumns={setPeriodColumns}
            setPeriodValues={setPeriodValues}
            fieldList={fieldList}
			setFieldList={setFieldList}
            originSelected={originSelected}
			grouperData={grouperData}
        />,
        <StepThree
            key={"step-three"}
            currentStep={currentStep}
            form={form}
            costCenterList={costCenterList}
            accountingList={accountingList}
            inputValue={inputValue}
            setInputValue={setInputValue}
            periodColumns={periodColumns}
            periodValues={periodValues}
            setPeriodValues={setPeriodValues}
            isWhole={isWhole}
            setIsWhole={setIsWhole}
            isInstallment={isInstallment}
            setIsInstallment={setIsInstallment}
        />,
    ];

    useEffect(() => {
        if (selectedEvent) {
            const newOptions = originData.filter(origin => origin.event === selectedEvent || origin.event === null);
            setOriginOptions(newOptions);
        }
    }, [selectedEvent]);

    function onClickBackStep() {
        if (currentStep === 0) {
            setFormula([]);
            setSelectedEvent(null);
            setOriginOptions([]);
            onCancel();
        } else {
            setCurrentStep(prev => prev - 1);
        }
    }

    function onClickNextStep() {
        if (currentStep === 2) {
            form.validateFields()
                .then(() => {
                    handleSaveProjection();
                    form.resetFields();
                })
        } else {
            setCurrentStep(prev => prev + 1);
        }
    }

    return (
        <div className="new-projection-page">
            <div className="page-title-content">
                <h1>{"Modelagem Projeção"}</h1>
                <Steps
                    type="navigation"
                    size="small"
                    current={currentStep}
                    className="site-navigation-steps"
                >
                    {
                        steps.map(({ stepIndex, stepNumber, title, disabled }) => {
                            return <Steps.Step disabled={disabled} key={stepIndex} title={title} stepNumber={stepNumber} stepIndex={stepIndex} />
                        })
                    }
                </Steps>
            </div>
            <div className="button-steps">
                <Button
                    type="link"
                    onClick={onClickBackStep}
                >
                    {i18n.t(currentStep === 0 ? "cancel" : "back")}
                </Button>
                <Button
                    type="primary"
                    className="gs-main-button"
                    disabled={isNextButtonDisabled}
                    onClick={onClickNextStep}
                >
                    {i18n.t(currentStep + 1 < steps.length ? "next" : "conclude")}
                </Button>
            </div>
            <main className="projection-main">
                {stepsContents}
            </main>
        </div>
    )
}