import {
	useState,
	useEffect
} from "react";

import i18n from "util/base/i18n";

import {
	Modal,
	Checkbox
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import "./styles.sass";

import { BtnManageItems } from "../BtnManageItems";
import { IManageItemModalProps } from "structure/module/IModule";

import { IModuleItem, IModuleProps } from "../../IModule";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

export function ManageItemsModal(props: IManageItemModalProps) {

	const [saving, setSaving] = useState<boolean>(false);

	const [checkedItems, setCheckedItems] = useState<IModuleItem[]>([]);
	const [isVisible, setIsVisible] = useState(false);

	function handleCheckedItem(e: CheckboxChangeEvent, index: number) {
		checkedItems[index].active = e.target.checked;
		setCheckedItems([...checkedItems]);
	};

	function handleCancel() {
		setIsVisible(false);
	};

	function showModal() {
		setIsVisible(true);
	};

	function handleSave() {
		setSaving(true);

		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.POST,
			url: "/module/items",
			params: checkedItems.filter(({ id }) => id)
		}, () => {		
			props.updateItems(checkedItems);

			setSaving(false);
			setIsVisible(false);
		});
	}

	useEffect(() => {
		if (isVisible) {
			setCheckedItems(props.items.map(item => {
				return { ...item }
			}));
		}
    }, [isVisible, props.items]);

	return <>
		<BtnManageItems showModal={showModal} />

		<Modal visible={isVisible} onOk={handleSave} onCancel={handleCancel} title={i18n.t<string>("quick_access")}
			cancelText={i18n.t<string>("cancel")} centered={true} okText={i18n.t<string>("save")} cancelButtonProps={{ type: "link" }} okButtonProps={{ loading: saving }}>
			<div id="modal-header">{i18n.t<string>("show_in_quick_access")}</div>
			<ul id="modal-content">
				{
					checkedItems.filter(({ id }) => id).map((item, index) => <>
						<li key={item.name}>
							<Checkbox onChange={(e) => handleCheckedItem(e, index)} checked={item.active}>
								{item.name}
							</Checkbox>
						</li>
					</>)
				}
			</ul>
		</Modal>
	</>;

}
