import i18n from "util/base/i18n";

export default function generateDefaultColumns(selectedYearDestiny) {
    return [
        {
            period: i18n.t<string>("month.medium.0") + "/" + selectedYearDestiny,
            date: '01',
            volume: '0',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.1") + "/" + selectedYearDestiny,
            volume: '0',
            date: '02',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.2") + "/" + selectedYearDestiny,
            volume: '0',
            date: '03',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.3") + "/" + selectedYearDestiny,
            volume: '0',
            date: '04',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.4") + "/" + selectedYearDestiny,
            volume: '0',
            date: '05',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.5") + "/" + selectedYearDestiny,
            volume: '0',
            date: '06',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.6") + "/" + selectedYearDestiny,
            volume: '0',
            date: '07',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.7") + "/" + selectedYearDestiny,
            volume: '0',
            date: '08',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.8") + "/" + selectedYearDestiny,
            volume: '0',
            date: '09',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.9") + "/" + selectedYearDestiny,
            volume: '0',
            date: '10',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.10") + "/" + selectedYearDestiny,
            volume: '0',
            date: '11',
            price: '0'
        },
        {
            period: i18n.t<string>("month.medium.11") + "/" + selectedYearDestiny,
            volume: '0',
            date: '12',
            price: '0'
        }
    ]
}

