import { useEffect, useState, useRef, useContext, createContext, Key } from "react";
import { cloneDeep, reduce } from "lodash";
import { Form, InputRef, FormInstance, Input, DatePicker } from "antd";
import DetailTable from "./DetailTable";
import DetailTableOperations from "./DetailTableOperations";
import { IDetailTableContainer, Months, ColumnTypes, ViewModeSelected, EditableRowProps, EditableCellProps, DetailTableData } from "../IDetail";
import { createTableColumns } from "../../util/createTableColumns";
import CommentsModal from "./CommentModal";
import moment from "moment";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Notification } from "components/notification/Notification";
import i18n from "util/base/i18n";

export default function DetailTableContainer({
    detailTableData,
    setDetailTableData,
    isFetching,
    setIsFetching,
    viewModeSelected,
    setViewModeSelected,
    handleSaveDetailValue,
    handleOpenModal,
    detailId,
    loadInvestmentDetail,
    detailData,
    configurations,
    controlPanelPeriods,
    controlPanelValues,
    setToggle
}: IDetailTableContainer) {

    const [isOpenDeletePopover, setIsOpenDeletePopover] = useState(false);
    const [isOpenViewModePopover, setIsOpenViewModePopover] = useState(false);
    const [viewModeChecked, setViewModeChecked] = useState(viewModeSelected);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [viewColumns, setViewColumns] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
    const EditableContext = createContext<FormInstance<any> | null>(null);
    const [comment, setComment] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
    const [searchField, setSearchField] = useState("");
    const [referenceYear, setReferenceYear] = useState("");

    useEffect(() => {
        if (detailTableData.length === 0) {
            setColumns([]);
            return;
        }
        const columnData = createTableColumns(
            detailTableData,
            openModal,
            viewModeSelected,
            viewColumns
        );

        for (const key in detailTableData[0]) {
            if (key !== "key" && key !== "description") {
                const year = moment(key, "YYYY-MM").format("YYYY");
                setReferenceYear(year);
                break;
            }
        }

        setColumns(columnData as any);
        setIsFetching(false);
    }, [detailTableData, viewColumns]);

    useEffect(() => {
        setViewModeChecked(viewModeSelected);
    }, [viewModeSelected]);

    function openModal(record) {
        setComment(record);
        setIsCommentModalVisible(true);
    }

    function handleDeleteRow() {
        setIsFetching(true);
        setIsOpenDeletePopover(false);
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/investment/delete-opening?headerId=${detailId}` +
                (viewModeSelected === ViewModeSelected.TEMPLATE ? `&templateIds=${selectedRowKeys.toString()}` : '')
        }, (response) => {
            if (response) {
                Notification({
                    type: "success",
                    message: i18n.t<string>("successfully_deleted"),
                });
                loadInvestmentDetail();
            }
        }, onRequestError.bind(this));
        setSelectedRowKeys([]);
    }

    function onRequestError() {
        Notification({
            type: "warning",
            message: i18n.t<string>("unable_to_delete_with_linked_data"),
        });
        setIsFetching(false);
    }

    const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false} >
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell: React.FC<EditableCellProps> = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const [openDatePicker, setOpenDatePicker] = useState(false);
        const inputRef = useRef<InputRef>(null);
        const form = useContext(EditableContext)!;
        let month = ''
        let keyType = ''

        if (record && dataIndex) {
            month = dataIndex[0]
            keyType = dataIndex[1]
        }

        useEffect(() => {
            if (editing && keyType !== 'activationDate') {
                inputRef.current!.focus();
                inputRef.current!.select();
            } else {
                if (editing) {
                    setOpenDatePicker(true)
                } else {
                    setOpenDatePicker(false)
                }
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            if (keyType !== 'activationDate') {
                form.setFieldsValue({ [month]: { [dataIndex[1]]: record[dataIndex[0]][dataIndex[1]] } });
            } else if (record[dataIndex[0]][dataIndex[1]] !== null) {
                form.setFieldsValue({ [month]: { [dataIndex[1]]: moment(record[dataIndex[0]][dataIndex[1]], 'YYYY-MM-DD') } });
            }
        };

        const save = async () => {
            const updatedTableData = cloneDeep(detailTableData);

            try {
                const fieldObject = form.getFieldsValue();
                let newValue = 0
                const inputValue = fieldObject[month][keyType]
                if (keyType === 'activationDate') {
                    newValue = inputValue.date(1).format('YYYY-MM-DD')
                } else {
                    newValue = inputValue ?
                        Number(inputValue.toString().replace(',', '.'))
                        : 0
                }

                if (keyType !== 'activationDate' &&
                    (record[month][keyType] === newValue ||
                        (record[month][keyType] === null && newValue === 0))) {
                    toggleEdit();
                    return
                }

                const updatedRow = updatedTableData.find(item => item.key === record.key);

                updatedRow[month][keyType] = newValue;
                if (updatedRow[month]['activationDate'] === null) {
                    let activation = moment(month).date(1);
                    activation.add(Number(configurations["GRACE_PERIOD"].value), "months");

                    updatedRow[month]['activationDate'] = activation.format('YYYY-MM-DD');
                }

                if ((keyType === 'quantity' && updatedRow[month]['unityValue'] !== null) ||
                    (keyType === 'unityValue' && updatedRow[month]['quantity'] !== null) ||
                    (keyType === 'activationDate' &&
                        updatedRow[month]['unityValue'] !== null &&
                        updatedRow[month]['quantity'] !== null)) {
                    const quantity = updatedRow[month]['quantity']
                    const unityValue = updatedRow[month]['unityValue']
                    updatedRow[month]['totalValue'] = quantity * unityValue
                    handleSaveDetailValue(updatedRow, month)
                }
                toggleEdit();
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
            setToggle(true);
            setDetailTableData(updatedTableData)
        };

        let childNode = children;

        const { currentDate, initialPeriod, finalPeriod } = controlPanelValues(referenceYear);

        if (initialPeriod && finalPeriod) {
            if (editable && !(currentDate < initialPeriod || currentDate > finalPeriod)) {
                childNode = editing && dataIndex[1] === 'activationDate' ? (
                    <Form.Item
                        key='input-table'
                        style={{ margin: 0, padding: '2px 4px' }}
                        name={dataIndex}
                    >
                        <DatePicker
                            className="detail-table-date-picker"
                            format='MM/YYYY'
                            picker="month"
                            onBlur={save}
                            onChange={() => {
                                setOpenDatePicker(false)
                                save()
                            }}
                            suffixIcon={null}
                            open={openDatePicker}
                            disabledDate={(current) => {
                                return moment(current, 'YYYY-MM').isBefore(month);
                            }}
                        />
                    </Form.Item>
                ) : editing && dataIndex[1] !== 'activationDate' ?
                    <Form.Item
                        key='date-picker-table'
                        style={{ margin: 0, padding: '2px 4px' }}
                        name={dataIndex}
                    >
                        <Input
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                        />
                    </Form.Item>

                    : (
                        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                            {children}
                        </div>
                    );
            }
        }
        return <td {...restProps}>{childNode}</td>;
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const cols = columns.map(col => {
        if (!col?.editable) {
            return col;
        }
        if (col.children) {
            col.children.forEach((c, index, array) => {
                if (index < array.length) {
                    Object.assign(c, {
                        onCell: (record: DetailTableData) => {
                            let columnClassName = "";
                            const currentMonth = record[c.dataIndex[0]] as Months;
                            const indexName = c.dataIndex[1];
                            if (currentMonth.activationDate !== null || currentMonth.quantity !== null || currentMonth.unityValue !== null) {
                                if (indexName === 'quantity' || indexName === 'unityValue' || indexName === 'activationDate') {
                                    if (currentMonth[indexName] === null) {
                                        columnClassName = "red-column";
                                    }
                                }
                            }

                            const { currentDate, initialPeriod, finalPeriod, accomplished } = controlPanelValues(referenceYear);

                            if (!(initialPeriod && finalPeriod) || (currentDate < initialPeriod || currentDate > finalPeriod)) {
                                //@ts-ignore
                                columnClassName += " blocked-column";
                            }
                            //@ts-ignore
                            if (c?.dataIndex[0] <= accomplished) columnClassName += " realized-column";
                            return {
                                record,
                                editable: c?.dataIndex[0] <= accomplished ? false : c.editable,
                                dataIndex: c.dataIndex,
                                title: c.title,
                                align: 'center',
                                className: columnClassName
                            }
                        }
                    })
                }
            })
        }
        return {
            ...col,
            onCell: (record: DetailTableData) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                align: 'center',
            }),
        };
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (selRowKeys: React.Key[], selectedRows: DetailTableData[]) => {
            setSelectedRowKeys(selRowKeys);
        },
    };

    let filteredData = detailTableData.filter(obj => obj.description.toLowerCase().includes(searchField.toLowerCase()));

    return (
        <div id="grid-content">
            <DetailTableOperations
                handleDelete={handleDeleteRow}
                isOpenDeletePopover={isOpenDeletePopover}
                setIsOpenDeletePopover={setIsOpenDeletePopover}
                isOpenViewModePopover={isOpenViewModePopover}
                setIsOpenViewModePopover={setIsOpenViewModePopover}
                viewModeSelected={viewModeSelected}
                setViewModeSelected={setViewModeSelected}
                setViewModeChecked={setViewModeChecked}
                viewModeChecked={viewModeChecked}
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={setSelectedCheckboxes}
                handleOpenModal={handleOpenModal}
                selectedRowKeys={selectedRowKeys}
                setSearchField={setSearchField}
                viewColumns={viewColumns}
                setViewColumns={setViewColumns}
                detailData={detailData}
                controlPanelPeriods={controlPanelPeriods[referenceYear]}
            />
            <DetailTable
                isFetching={isFetching}
                components={components}
                cols={cols as ColumnTypes}
                detailTableData={filteredData}
                rowSelection={rowSelection}
                viewColumns={viewColumns}
            />
            <CommentsModal
                isModalVisible={isCommentModalVisible}
                setIsCommentModalVisible={setIsCommentModalVisible}
                comment={comment}
                detailId={detailId}
                viewModeSelected={viewModeSelected}
                loadInvestmentDetail={loadInvestmentDetail}
                setIsFetching={setIsFetching}
            />
        </div>
    )
}