import { Period } from "module/plan/pages/accountingBalances/IAccountingBalances";
import moment from "moment";
import { ModuleType } from "util/types/types";

export function verifyBudgetPeriod(
    budDate: Period,
    momentMonth: moment.Moment,
    localDate: number,
    moduleType: ModuleType
) {
    let isClosedBudgetPeriod = false;
    let isEditable = true;
    let className = "";

    if (
        budDate[`${moduleType}FrozenPeriod`] &&
        momentMonth.endOf("month").isSameOrBefore(moment(budDate[`${moduleType}FrozenPeriod`]).endOf("month"))
    ) {
        isEditable = false;
        className += " frozen-column";
    }
    if (
        budDate[`${moduleType}RealizedPeriod`] &&
        momentMonth.endOf("month").isSameOrBefore(moment(budDate[`${moduleType}RealizedPeriod`]).endOf("month"))
    ) {
        isEditable = false;
        className += " realized-column";
    }
    if (
        (budDate[`${moduleType}OpeningStartDate`] &&
            moment(localDate).isBefore(moment(budDate[`${moduleType}OpeningStartDate`]).startOf("day"))) ||
        (budDate[`${moduleType}OpeningEndDate`] &&
            moment(localDate).isAfter(moment(budDate[`${moduleType}OpeningEndDate`]).endOf("day")))
    ) {
        if (budDate.revision.length > 0) {
            const isOnSchedule = budDate.revision.some((revision) => {
                return (
                    moment(localDate).isAfter(moment(revision.reviewPeriodStartDate).startOf("day")) &&
                    moment(localDate).isBefore(moment(revision.reviewPeriodEndDate).endOf("day"))
                );
            });
            const IsMonthOnRevisionPeriod = budDate.revision.filter((revision) => {
                return (
                    momentMonth.isSameOrAfter(moment(revision.budgetStartDate).startOf("M")) &&
                    momentMonth.isSameOrBefore(moment(revision.budgetEndDate).endOf("M")) &&
                    moment(localDate).isAfter(moment(revision.reviewPeriodStartDate).startOf("day")) &&
                    moment(localDate).isBefore(moment(revision.reviewPeriodEndDate).endOf("day"))
                );
            });
            if (!isOnSchedule) {
                isClosedBudgetPeriod = true;
                isEditable = false;
                className += " out-of-period-column";
            }
            if (IsMonthOnRevisionPeriod.length === 0) {
                className += " out-of-period-column";
                isEditable = false;
            } else {
                className += "revision-period-column";
            }
        } else {
            isClosedBudgetPeriod = true;
            isEditable = false;
            className += " out-of-period-column";
        }
    }
    if (
        (budDate[`${moduleType}BudgetStartDate`] &&
            momentMonth.isBefore(moment(budDate[`${moduleType}BudgetStartDate`]).startOf("month"))) ||
        (budDate[`${moduleType}BudgetEndDate`] &&
            momentMonth.isAfter(moment(budDate[`${moduleType}BudgetEndDate`]).endOf("month")))
    ) {
        isEditable = false;
    }

    return { isEditable, className, isClosedBudgetPeriod };
}
