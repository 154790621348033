/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Modal, Form, Input, Select, Button, Row, Col, Switch } from "antd";
import i18n from "util/base/i18n";
import { Detail, NewDetailLayoutModalProps } from "../../detailLayout/IDetailLayout";
import { Icon } from '@iconify/react';
import { validateFormField } from "util/functions/validateFormField";
import { DetailList } from "./DetailList";
import { selectProps } from "util/props/props";
import { ImageBox } from "components/imageBox/ImageBox";

export function NewDetailLayoutModal({
	isModalVisible,
	handleSubmit,
	handleCancel,
	detailList,
	setDetailList,
	form,
	isNewDetailLayout,
	handleSaveDetailLayout,
	isFetchingCurrency,
	currencyOptions,
	tableData,
	selectedDetailLayoutId
}: NewDetailLayoutModalProps) {
	const [currencySelected, setCurrencySelected] = useState(0);
	const [isDisabled, setIsDisabled] = useState(false);
	const { Option } = Select;

	const modalTitle = isNewDetailLayout
		? i18n.t<string>("new_detailing")
		: i18n.t<string>("edit_detailing")

	function onChangeCurrency(key: string) {
		setCurrencySelected(Number(key))
	}

	function handleDelete(description, currencyId) {
		const details = [...detailList];
		const i = details.findIndex((detail) => (detail.description === description && detail.currencyId === currencyId));
		details.splice(i, 1);
		setDetailList(details);
	}

	function handleEditDetail(detail: Detail) {
		setIsDisabled(true)
		form.setFieldsValue({
			DetailLayout: detail.detailLayout,
			description: detail.description,
			currencyId: detail.currencyId,
			isByQuantity: detail.isByQuantity,

		})
		const index = detailList.indexOf(detail)
		const updatedDetailList = [...detailList]
		updatedDetailList.splice(index, 1)
		setDetailList(updatedDetailList)
	}

	return (
		<Modal
			width={1100}
			title={modalTitle}
			visible={isModalVisible}
			onCancel={handleCancel}
			centered
			className="gs-modal modal-detailLayout-page"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("save")}
			onOk={handleSaveDetailLayout}
		>
			<Form
				form={form}
				name="DetailLayout-detail-form"
				layout="vertical"
				onFinish={handleSubmit}
				requiredMark={false}
			>
				<div className="detailLayout-detail-form-container">
					<Row gutter={20}>
						<Col span={7}>
							<Form.Item
								name="detailLayout"
								label={i18n.t<string>("name")}
								rules={[{ required: true, message: i18n.t<string>("required_field") },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (isNewDetailLayout) {
											if (detailList.every(function (e) {
												return value === e.detailLayout;
											}) || detailList.length === 0) {
												return validateFormField(value, 'detailLayout', tableData, tableData, i18n.t("detail_layout.layout_name_cannot_repeat"));
											} else {
												return Promise.reject(new Error(i18n.t<string>("detailLayout_name_cannot_be_changed")));
											}
										} else {
											return Promise.resolve();
										}
									}
								})
								]}
							>
								<Input id="input-DetailLayout-name" disabled={!isNewDetailLayout} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								name="description"
								label={i18n.t<string>("detail")}
								rules={[
									{ required: true, message: i18n.t<string>("required_field") },
									() => ({
										validator(_, value: string) {
											return validateFormField(value, 'description', detailList, detailList, i18n.t("fixed_expense_texts.detail_name_cannot_repeat"));
										},
									})
								]}
							>
								<Input id="input-DetailLayout-detail" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								name="currencyId"
								label={i18n.t<string>("currency")}
								initialValue={currencySelected || currencyOptions[0]?.value}
								rules={[{ required: true, message: i18n.t<string>("required_field") },
									// ({ getFieldValue }) => ({
									// 	validator(_, value) {

									// 		const result = detailList.find((detail) => (
									// 			detail.description.toLowerCase() === getFieldValue('description').toLowerCase()
									// 			&& detail.currencyId === value
									// 		))

									// 		if(result){
									// 			return Promise.reject(new Error(i18n.t<string>("detail_and_currency_combination")));
									// 		}else{
									// 			return Promise.resolve();
									// 		}

									// 	}
									// })
								]}
							>
								<Select
									id="input-currency"
									// style={{ width: 220 }}
									loading={isFetchingCurrency}
									onChange={(value: string) => onChangeCurrency(value)}
									{...selectProps}
								>
									{currencyOptions.map(currency => {
										return <Option key={currency.value} value={currency.value}>{currency.label}</Option>
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={4} className="ant-col-submit-button" style={{ display: 'flex', justifyContent: 'end' }}>
							<Button htmlType="submit" className="gs-secondary-button">
								<Icon icon="akar-icons:circle-plus-fill" />
								{i18n.t<string>("addToList")}
							</Button>
						</Col>
					</Row>
					<Row gutter={20} align="bottom">
						<Col span={6} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
							<Form.Item
								name="isByQuantity"
								valuePropName="checked"
								labelAlign="right"
								initialValue={true}
							>
								<Switch />
							</Form.Item>
							<span style={{ marginBottom: 24 }}>{i18n.t("calculation_by_quantity")}</span>
						</Col>
					</Row>
				</div>
			</Form>
			{detailList.length > 0 ?
				<DetailList
					detailList={detailList}
					setDetailList={setDetailList}
					handleDelete={handleDelete}
					currencyOptions={currencyOptions}
					handleEditDetail={handleEditDetail}
					isEditBtnDisabled={isDisabled}
				/>
				:
				<ImageBox />
			}

		</Modal>
	)
}