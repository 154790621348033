import { Button, Popover, Row } from "antd";
import { IColorPickerButtonProps, defaultColors, defaultCustomColors } from "./IColorPicker";
import { Icon } from "@iconify/react";
import { BlockPicker, ColorChangeHandler, ColorResult, GithubPicker, HuePicker } from "react-color";
import './colorPickerGS.sass';
import { useEffect, useState } from "react";
import CustomColorPicker from "./customColorPicker/CustomColorPicker";
import { BuildInPlacements } from 'rc-trigger';

export default function ColorPickerGS({
	color,
	label,
	onChange,
	onChangeCustomColor
}: IColorPickerButtonProps) {
	const [customColors, setCustomColors] = useState(defaultCustomColors);
	const [defaultColorSelected, setDefaultColorSelected] = useState(color);
	const [customColorSelected, setCustomColorSelected] = useState(color);
	const [isVisibleCustomPopover, setIsVisibleCustomPopover] = useState(false);
	const [isVisibleColorPickerPopover, setIsVisibleColorPickerPopover] = useState(false);

	function onChangeDefault({ hex }: ColorResult) {
		setCustomColorSelected("");
		setDefaultColorSelected(hex);
		onChange(hex);
		setIsVisibleColorPickerPopover(false);
	}

	function onChangeCustom({ hex }: ColorResult) {
		setDefaultColorSelected("");
		setCustomColorSelected(hex)
		onChangeCustomColor(hex);
	}

	let colorPicker: JSX.Element = <>
		<div className="color-picker-content">
			<Row className="default-colors">
				<GithubPicker
					colors={defaultColors}
					color={defaultColorSelected}
					triangle="hide"
					onChange={onChangeDefault}
				/>
			</Row>
			<Row className="custom-colors-title">
				<span>
					Cores Personalizadas
				</span>
			</Row>
			<Row className="default-colors">
				<GithubPicker
					triangle="hide"
					colors={customColors}
					color={customColorSelected}
					onChange={onChangeCustom}
				/>
			</Row>
			<Row className="default-colors">
				<Popover
					trigger={"click"}
					content={<CustomColorPicker
						handleSave={handleSaveCustom}
						defaultSelected={customColorSelected}
						setIsVisible={setIsVisibleCustomPopover}
					/>}
					destroyTooltipOnHide
					visible={isVisibleCustomPopover}
					onVisibleChange={(visible) => setIsVisibleCustomPopover(visible)}
				>
					<Button className="custom-colors-button">
						<img src="/assets/images/custom_color.png" />
						Definir Cor Personalizada
					</Button>
				</Popover>
			</Row>
		</div>
	</>;

	function handleSaveCustom(color: string) {
		let newCustomColors = customColors;
		const positionToSwitchColor = customColors.findIndex(value => value === customColorSelected);
		newCustomColors.splice(positionToSwitchColor, 1, color);
		setCustomColors(newCustomColors);
		setCustomColorSelected(color);
		setIsVisibleCustomPopover(false);
		localStorage.setItem("customColors", newCustomColors.toString());
		setCustomColorSelected("");
	}

	useEffect(() => {
		const newCustomColors = localStorage.getItem("customColors")?.split(",");
		if (newCustomColors?.length) {
			setCustomColors(localStorage.getItem("customColors").split(","));
		} else {
			localStorage.setItem("customColors", defaultCustomColors.toString());
		}
	}, [])

	return (
		<Popover
			content={colorPicker}
			trigger={"click"}
			overlayStyle={{ visibility: isVisibleCustomPopover ? "hidden" : "visible" }}
			visible={isVisibleColorPickerPopover}
			onVisibleChange={setIsVisibleColorPickerPopover}
		>
			<Row className="color-picker-button">
				<p>
					{label}
				</p>
				<Button
					className="color-selected"
					type="text"
				>
					<div style={{ background: color }} />
					<Icon icon="teenyicons:down-outline" />
				</Button>
			</Row>
		</Popover>
	)
}