import { createContext, useContext, useState } from "react";
import { IModalFilterProviderProps, IObjectOnApplyFilter, ModalFilterContextData, SelectedRowKeysObject } from "../components/modalFilter/IModalFilter";

const modalFilterContext = createContext<ModalFilterContextData>({} as ModalFilterContextData);
export function ModalFilterProvider({ children }: IModalFilterProviderProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedRowKeysObject, setSelectedRowKeysObject] = useState<SelectedRowKeysObject>({});
	const [filtersSelected, setFiltersSelected] = useState<IObjectOnApplyFilter>();

	function cleanFilters() {
		setFiltersSelected(null);
		setSelectedRowKeysObject({});
	}

	return (
		<modalFilterContext.Provider
			value={{
				isOpen,
				setIsOpen,
				selectedRowKeysObject,
				setSelectedRowKeysObject,
				cleanFilters,
				filtersSelected,
				setFiltersSelected
			}}
		>
			{children}
		</modalFilterContext.Provider>
	)
}

export function useModalFilterContext() {
	return useContext(modalFilterContext);
}