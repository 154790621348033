/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "antd";
import { TableParameterizationProps } from "../IParameterizationFromTo";
import { useEffect } from "react";
import { useUserContext } from "context/UserContext";
import { useParameterizationContext, } from "../context/ParameterizationContext";

export default function TableParameterization({ dataSource, tableColumns }: TableParameterizationProps) {

    const { userInfo } = useUserContext();
    const { loadTable, rowSelection } = useParameterizationContext();

    useEffect(() => {
        loadTable();
    }, [userInfo.selection.organizationId]);

    return (
        <Table
            className="gs-table"
            columns={tableColumns}
            dataSource={dataSource}
            rowSelection={rowSelection} />
    );
}