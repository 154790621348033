import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from 'hooks/useCachedQuery';
import { RequestType } from "util/service/IServiceCaller";

type AccountAccountingOptions = {
    canEdit: boolean;
    value: number;
    label: string;
    lastSelected?: boolean;
};

export function useSelectedAccountAccountingOptions(costCenterId = '', queryProps = {} as UseQueryOptions<AccountAccountingOptions[]>) {
    const queryInfo = useCachedQuery<AccountAccountingOptions[]>(
        ['account-accounting-options', costCenterId.toString()],
        {
            type: RequestType.GET,
            url: `/expenses/accounting-account?locale={locale}&client={client}&user={user}&organization={organization}&bu={businessUnit}${costCenterId.toString().length > 0 ? `&costCenter=${costCenterId}` : ''}`,
        },
        {},
        (data: { id: number; name: string; lastSelected: boolean, canEdit: boolean }[] ) => {
            return data.map((item) => ({ label: item.name, value: item.id, lastSelected: item.lastSelected, canEdit: item.canEdit }));
        }
    )
    
    return queryInfo;
}

