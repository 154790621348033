import { Tabs } from "antd";
import { InitialInfoTable } from "../collabBudgetTables/InitialInfoTable";
import { ICollabResultTables } from '../../../IHumanResoucers'
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { useEffect, useState } from "react";
import { Excel, } from "antd-table-saveas-excel";
import i18n from "util/base/i18n";
import { ImageBox } from "components/imageBox/ImageBox";
import { Loading } from "components/loading/Loading";
import { IStyle } from "antd-table-saveas-excel/app";

export function CollabResultTables({
	tablesData,
	year,
	isFetching,
	admissions,
}: ICollabResultTables) {
	const [selectedTab, setSelectedTab] = useState(tablesData?.resultValues.length > 0 ? "result-values" : "result-amount");
	const [valuesTableData, setValuesTableData] = useState([]);
	const [columnsTable, setColumnsTable] = useState([]);
	const [columnsToExport, setColumnsToExport] = useState([]);

	const formatedValuesDataToExport = valuesTableData.map(item => {
		const obj = {}
		Object.keys(item).forEach(key => {
			if (key !== 'period') {
				obj[key.split('-')[1]] = item[key]
			} else {
				obj[key] = item[key].split('-').reverse().join('/')
			}
		})
		return obj;
	})

	const formatedColums = columnsToExport.map(column => {
		const dataIndex: string = column.dataIndex;
		return {
			...column,
			dataIndex: dataIndex.substring(dataIndex.indexOf("-") + 1, dataIndex.lastIndexOf("-"))
		}
	})

	return (
		<>
			{(tablesData?.resultValues.length > 0 || tablesData?.resultQuantity.length > 0 || tablesData?.resultCustom.length > 0) &&
				<section className="collaborator-result-budget-tables">
				
					<Tabs type="card" onChange={(key) => setSelectedTab(key)} activeKey={selectedTab} className="gs-tab">
						{tablesData?.resultQuantity.length &&
							<Tabs.TabPane tab={i18n.t("human_resources.result_quantity")} key="result-amount">
								<InitialInfoTable
									dataTable={tablesData?.resultQuantity}
									year={year}
									isFetching={isFetching}
									isResultTable
									realizedPeriod={tablesData?.realizedPeriod}
									admissions={admissions}
								/>
							</Tabs.TabPane>
						}
						{tablesData?.resultValues.length &&
							<Tabs.TabPane tab={i18n.t("human_resources.result_values")} key="result-values">
								<InitialInfoTable
									selectedTab={selectedTab}
									dataTable={tablesData?.resultValues}
									year={year}
									isFetching={isFetching}
									isResultTable
									admissions={admissions}
									isOnResultValues
									realizedPeriod={tablesData?.realizedPeriod}
									valuesTableData={valuesTableData}
									setValuesTableData={setValuesTableData}
									setColumnsTable={setColumnsTable}
									setColumnsToExport={setColumnsToExport}
								/>
							</Tabs.TabPane>
						}
					</Tabs>
					<div className="result-export-button" style={{ display: selectedTab === 'result-values' ? 'block' : 'none' }}>
						<ImportExportMenu
							buttonType="3dots"
							exportGridData={() => handleExportGridData(formatedValuesDataToExport, formatedColums, 'resultado')}
						/>
					</div>
				</section>
			}
		</>
	);
}
