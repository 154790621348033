import { RevenueItemTable } from "../IRevenue";

import moment from "moment";

export function verifyIfIsAccomplished(row: RevenueItemTable, month: string, periodAccomplished) {
    if (row?.dates) {
        if (periodAccomplished && periodAccomplished instanceof moment) {
            let now = moment(month);
            if (now.isBefore(periodAccomplished) || now.isSame(periodAccomplished)) {
                return true;
            }
        }

        return false;
    };
}