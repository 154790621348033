import { Icon } from "@iconify/react";
import { Button } from "antd";
import i18n from "util/base/i18n";

interface IOperationsSelectProps {
    onChooseOperation: (operation: string) => void;
    hasValueOperator?: boolean
}

export function OperationsSelect({ onChooseOperation, hasValueOperator = false }: IOperationsSelectProps) {
    return (
        <div
            className="operations-select-container"
            style={{ display: "flex", flexDirection: "column" }}
        >
            <label className="gs-input-label">{i18n.t<string>("operations")}</label>
            <div className="operations-select-buttons">
                <Button
                    icon={<Icon icon="akar-icons:plus" />}
                    onClick={() => onChooseOperation("plus")}
                />
                <Button
                    icon={<Icon icon="akar-icons:minus" />}
                    onClick={() => onChooseOperation("minus")}
                />
                <Button
                    icon={<Icon icon="akar-icons:cross" />}
                    onClick={() => onChooseOperation("times")}
                />
                <Button
                    icon={<Icon icon="ic:baseline-divide" />}
                    onClick={() => onChooseOperation("divide")}
                />
                <Button
                    icon={<p>(</p>}
                    style={{ textAlign: "center" }}
                    onClick={() => onChooseOperation("left_parenthesis")}
                />
                <Button
                    icon={<p>)</p>}
                    style={{ textAlign: "center" }}
                    onClick={() => onChooseOperation("right_parenthesis")}
                />
                {hasValueOperator &&
                    <Button
                        style={{ textAlign: "center" }}
                        onClick={() => onChooseOperation("value")}
                    >
                        {i18n.t("value")}
                    </Button>
                }
            </div>
        </div>
    );
}
