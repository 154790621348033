import { useEffect, useState } from "react";
import "./App.sass";

import i18n from "../i18n";

import {
	MessageType,
	ICallerResponse,
	ResponseStatus
} from "../../service/IServiceCaller";
import { ServiceCaller } from "../../service/ServiceCaller";

import "moment/locale/pt-br";
import "moment/locale/es";
import moment from "moment";

import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import Scrollbar from "react-scrollbars-custom";

import enUS from 'antd/es/locale/en_US';
import ptBR from 'antd/es/locale/pt_BR';
import esES from 'antd/es/locale/es_ES';
import * as ReactDOM from "react-dom";

const Window: any = window;
Window.callerCallbacks = {};

export default function App(props) {

	useEffect(() => {
		window.addEventListener("message", onReceiveMessage, false);

		loadUserLocale();

		return () => {
			window.removeEventListener("message", onReceiveMessage);
		};
	}, []);

	const onReceiveMessage = (response: ICallerResponse) => {
		const {
			origin,
			data
		} = response;

		const { callerCallbacks } = Window;
		const responseStatus = data.status || ResponseStatus.SUCCESS;

		if (origin !== `${process.env.REACT_APP_GS_ROOT_PATH}` || !callerCallbacks.hasOwnProperty(data.callerUID) || !callerCallbacks[data.callerUID].hasOwnProperty(responseStatus)) {
			return;
		}

		const {
			callerCallbacks: {
				[data.callerUID]: {
					[responseStatus]: callbackFunction
				}
			}
		} = Window;

		if (origin !== `${process.env.REACT_APP_GS_ROOT_PATH}` || !callerCallbacks.hasOwnProperty(data.callerUID)) {
			return;
		}

		delete callerCallbacks[data.callerUID];

		callbackFunction(data.response);
	};

	const loadUserLocale = () => {
		ServiceCaller.doSendMessage(
			{
				messageType: MessageType.USER_LOCALE
			},
			(language) => {
				i18n.changeLanguage(language, () => {
					const { children } = props;
					moment.locale(language);
					const currentLanguage = language === 'pt_BR' ? ptBR : language === 'es_ES' ? esES : enUS;

					ReactDOM.render(
						<>
							<ConfigProvider locale={currentLanguage}>
								<IntlProvider locale={language.split("_")[0]} children={children} />
							</ConfigProvider>
						</>,
						document.getElementById("app-container")
					);
				});
			}
		);
	};

	return (
		<>
			<Scrollbar noScrollX={true}>
				<div id="app-container"></div>
			</Scrollbar>
		</>
	);
};
