import { Table } from "antd";
import { FlexFilter } from "util/types/types";

export interface OtherEventsDataBean {
    lastLevelDescription: string;
	otherEventValues: Array<OtherEventValuesBean>;
}

export interface OtherEventValuesBean {
    id: number;
	month: number;
	year: number;
	monthYear: number;
	plannedValue: number;
	projectedValue: number;
	currencyConversionValue: number;
	scenarioId: number;
	organizationId: number;
	currencyId: number;
	flexKey: string;
}

export interface OtherEventColumn {
    id: number | null;
    rate: number | null;
    month: number;
    year: number;
    monthYear: number;    
    monthLabel: string;
    currencyId: number;
    plannedValue: number;
    projectedValue: number | null;
    historicValue: number | null;
    currencyConversionValue: number | null;
}

export interface OtherEventRow {
    currencyId: number;
	flexKey: string;
    lastFlexFieldDescription: string;
    columns: OtherEventColumn[];
}

export interface OtherEventDataResponse {
    scenarioId: number;
	organizationId: number;
    rows: OtherEventRow[];
}

export interface OpeningFilterRequest {
    organizationId: number;
    scenarioId: number;
    currencyId: number;
    userId: number;
	flexKey: string[];
    startDate: number;
    finalDate: number;
}

export interface OtherEventColumnRequest {
    id: number;
    month: number;
    year: number;
    monthYear: number;
    plannedValue: number;
    projectedValue: number;
    currencyConversionValue: number;
}

export interface OtherEventDataRequest {
    scenarioId: number;
    organizationId: number;
    currencyId: number;
	flexKey: string;
    columns: OtherEventColumnRequest[] | null;
}

export interface OtherEvent {
    organizationId: number;
	flexFieldFilter: string;
    scenarioId: number;
}

export interface OtherEventGridData {
    otherEvent: OtherEvent;
    key: string;
    lastFlexFieldDescription: string;
    rowType: RowType;
    isOpeningInOtherCurrency?: boolean;
    currencyIsoCode?: string;
    currencyId: number;
    columns: {
        [month: string]: OtherEventColumn;
    };
    plannedTotal?: number;
    projectedTotal?: number;
    historicTotal?: number;
    quantityTotal?: number;
    unitValueTotal?: number;
}

export type BudgetOpeningCachedData = {
    accounts: number[];
    spendingPackage: number[];
    businessUnitId: number;
    clientId: number;
    costCenterId: number;
    currencyId: number;
    flexFieldFilter: string;
    flexFieldInfoData: FlexFilter[];
    id: number;
    localeId: number;
    organizationId: number;
    period: moment.Moment[];
    scenarioId: number;
    userId: number;
};

export interface SummaryProps {
    totals: totalSummary[];
}

export type totalSummary = {
    value: number;
    month: string;
    order: number;
};

export type EditableTableProps = Parameters<typeof Table>[0];

export interface EditableRowProps {
    index: number;
}

export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string | string[];
    record: OtherEventGridData;
    onCell: (a: any) => any;
    handleSaveCellValue: (record: OtherEventGridData, isTotalCell: boolean, key: string, value: number) => void;
    handleSavePlannedValue(request: OtherEventDataRequest, otherEventDataRow: OtherEventGridData, previousValue: OtherEventGridData): void;
}

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export type Column = ColumnTypes[number] & {
    editable?: boolean;
    dataIndex?: string | string[];
};

export interface ImportTemplateModalProps {
    isVisible: boolean;
    setIsVisible: (b: boolean) => void;
    handleAddTemplate: () => void;
}

export type CurrenciesRate = {
    month: string;
    rate: number;
};

export interface IGridOperations {
    isOpenCurPopover: boolean;
    setIsOpenCurPopover(b: boolean): void;
    isOpenViewModePopover: boolean;
    setIsOpenViewModePopover(b: boolean): void;
    currencyId: number;
    setCurrencyId(n: number): void;
    isCurrencyBtnDisabled: boolean;
    userDefaultCurrency: number;
    saveStatus: {
        savedValue: boolean;
        isSaving: boolean;
    };
    setOpenDistributionModal(b: boolean): void;
    isDistributionButtonDisabled: boolean;
    onViewModeCheckboxChange(a: any): void;
    viewModeSelected: ViewModeSelected[];
    setViewModeSelected(a: ViewModeSelected[]): void;
    viewModeChecked: any[];
    setViewModeChecked(a: any[]): void;
    handleExportGrid(): void;
    searchValue: string;
    setSearchValue(a: string): void;
}

export interface IGrid {
    handleSavePlannedValue(request: OtherEventDataRequest, otherEventDataRow: OtherEventGridData, previousValue: OtherEventGridData): void;
    isFetching: boolean;
	setIsFetching(b: boolean): void;
    flexFieldsFilters: FlexFilter[];
    saveStatus: {
        isSaving: boolean;
        savedValue: boolean;
    };
}

export interface IEmptyListProps {
    onClickNewBudgetOpening: () => void;
    isSpendingPackage: boolean;
}

export enum RowType {
    PLANNED = "plannedValue",
    DETAIL = "detail",
    QUANTITY = "quantity",
    UNITVALUE = "unitValue",
}

export enum ViewModeSelected {
    PROJECTED = "projectedValue",
    REALIZED = "realizedValue",
    HISTORIC = "historicValue"
}
