import { cloneDeep } from "lodash";
import { queryClient } from "util/queryClient";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FlexFilter, FlexFilterOptions } from "util/types/types";

export async function onChangeFlexFieldValue(
    ffParentValue: number,
    ffParentId: number,
    setFlexFieldOptions: any,
    form: any,
    linkOptions : {
        flexFieldOptions: any,
        selectedAccount?,
        selectedCostCenter?,
        selectedBusinessUnit?,
    }
) {
    const updatedFlexField = cloneDeep(linkOptions.flexFieldOptions)
    const dependentFlexFieldFilters = updatedFlexField.filter(flex => flex.linkedFilters && flex.linkedFilters.includes(ffParentId.toString()))
    if (dependentFlexFieldFilters.length > 0) {
        if (ffParentValue === undefined) {
            dependentFlexFieldFilters.forEach(f => {
                f.selectedOption = []
                f.children = []
            })
            setFlexFieldOptions(updatedFlexField)
            return
        } else {
            let childFilterValues: any = queryClient.getQueryData(['filterValuesByParentValue', ffParentId, ffParentValue], {});

            if (!childFilterValues) {
                childFilterValues = await ServiceCaller.doAsyncRequest({ 
                    type: RequestType.GET, 
                    url: `/budget-base/flex-field-value/get-all-by-filters-security?userId={user}&flexFieldId=${ffParentId}&flexFieldValueId=${ffParentValue}`
                 })

                queryClient.setQueryData(['filterValuesByParentValue', ffParentId, ffParentValue], childFilterValues, {});
            }
            const formatedValues: FlexFilterOptions[] = childFilterValues.data.map(item => ({
                label: item.externalCode + ' - ' + item.description,
                value: item.id,
                flexFieldId: item.flexFieldId,
                linkedFilterIds: item.flexFieldFilters,
                linkedBusinessUnits: item.businessUnitFilters,
                linkedAccounts: item.accountingAccountFilters,
                linkedCostCenters: item.costCenterFilters,
            }));

            updatedFlexField.forEach(flex => {
                if (flex.linkedFilters && flex.linkedFilters.includes(ffParentId.toString())) {
                    flex.children = formatedValues
                        .filter(item => item.linkedAccounts ? item.linkedAccounts.includes(linkOptions.selectedAccount) : true)
                        .filter(item => item.linkedCostCenters ? item.linkedCostCenters.includes(linkOptions.selectedCostCenter) : true)
                        .filter(item => item.linkedBusinessUnits ? item.linkedBusinessUnits.includes(linkOptions.selectedBusinessUnit) : true)
                        .filter(item => item.flexFieldId === flex.value);
                        
                }
            })

            resetChildFieldsValues(updatedFlexField, setFlexFieldOptions, ffParentId, false, form)
            setFlexFieldOptions(updatedFlexField)
        }
    }
}

function resetChildFieldsValues(flexFields: FlexFilter[], setFlexFieldOptions, id: number, clearValues: boolean, form) {
    flexFields.forEach(field => {
        if (field.linkedFilters && field.linkedFilters.includes(id.toString())) {
            form.resetFields([`ff-${field.value}`])
            if (clearValues) {
                field.children = []
            }
            resetChildFieldsValues(flexFields, setFlexFieldOptions, field.value, true, form)
        }
    })
    setFlexFieldOptions(flexFields)
}