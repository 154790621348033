import { Col, Row, Table } from "antd";
import type { ColumnsType } from 'antd/es/table/interface';
import moment from "moment";
import i18n from "util/base/i18n";
import { DetailLayoutTableData, DetailLayoutTableProps } from "../../detailLayout/IDetailLayout";

export function DetailLayoutTable({ 
    isFetching,
	tableData, 
    selectedRowKeys,
	onChange,
	currencyOptions,
	columns
}: DetailLayoutTableProps) {

	const rowSelection = { 
		selectedRowKeys, 
		onChange,
		renderCell: (checked, record, index, originNode) => {
			if (record.children) return originNode
			return null
		}
	};

	return (
		<Table
			loading={{
				spinning: isFetching,
				tip: `${i18n.t<string>("loading")}...`
			}}
			rowClassName={(record: any) => record.isFrozen ? "frozen" : ""}
			className="gs-table"
			dataSource={tableData}
			columns={columns}
			rowSelection={rowSelection}
			bordered
			pagination={{
				hideOnSinglePage: true
			}}
		/>
	);
}