import { Modal } from "antd";
import { ConfirmationModalProps } from "../IFlexField";
import i18n from "util/base/i18n";
import { useEffect, useState } from "react";

export default function ConfirmationModal({
	isModalVisible, tableData, setConfirmLastLevel, setIsModalVisible, customMessage, message, handleConfirmOk, handleConfirmCancel
}: ConfirmationModalProps) {

	const [lastLevel, setLastLevel] = useState("");

	useEffect(() => {
		tableData.forEach((element) => {
			if (element.isLastLevel) {
				setLastLevel(element.description)
			}
		});
	}, []);

	const handleOk = () => {
		setConfirmLastLevel(true);
		setIsModalVisible(false);
	}

	const handleCancel = () => {
		setConfirmLastLevel(false);
		setIsModalVisible(false);
	}

	return (
		<Modal
			width="30%"
			title={i18n.t("attention")}
			visible={isModalVisible}
			okButtonProps={{ style: { backgroundColor: '#FF4D4F' } }}
			onOk={handleConfirmOk ? handleConfirmOk : handleOk}
			className="gs-modal confirm-last-level-modal-container"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("yes")}
			cancelButtonProps={{onClick: handleConfirmCancel ? handleConfirmCancel : handleCancel}}
			closable={false}
			centered
		>
			<div className="modal-confirmations-last-level">
				{
					customMessage ?
						<p>{message}</p>
					:
						<>
							{i18n.t("flexField.the_flex_field")}
							<span style={{
								marginLeft: 4,
								marginRight: 4,
								color: '#0065B3',
								textDecoration: 'underline'
							}}>
								{lastLevel}
							</span>
							{i18n.t("flexField.is_already_selected_as_the_last_level")}
							<div className="modal-confirm-last-level">
								<span>
									{i18n.t("change_confirm_message")}
								</span>
							</div>
						</>
				}
			</div>
		</Modal>
	);
}