import { Icon } from "@iconify/react";
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { ImageBox } from "components/imageBox/ImageBox";
import { Notification } from "components/notification/Notification";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import SecondStageModal from "./SecondStageModal";
import TextArea from "antd/lib/input/TextArea";
import { ValueForm } from "../IInvestment";
import { selectProps } from "util/props/props";
import { onChangeFlexFieldValue } from "../util/onChangeFlexFieldValue";
import moment from "moment";

export default function InvestmentModal({
    handleSave,
    handleSubmit,
    handleCancel,
    modalList,
    setModalList,
    isModalVisible,
    form,
    templateList,
    isFetchingTemplate,
    isFetchingAccounting,
    accountingList,
    accountingDisabled,
    setAccountingDisabled,
    businessUnitList,
    isFetchingBusinessUnit,
    costCenterList,
    isFetchingCostCenter,
    showJustification,
    setShowJustification,
    currenciesList,
    isFetchingCurrency,
    flexFieldsOptions,
    setFlexFieldsOptions,
    formattedValue,
    isEditingDetail,
    detailSelectedId,
    detailData,
    generateFlexFieldSelectedString,
    monthsToBlock,
	controlPanelPeriods,
	gracePeriod
}) {

    const [editDisebled, setEditDisebled] = useState(false);
    const [disableMonths, setDisableMonths] = useState([]);
    const [accountingOption, setAccountingOption] = useState([]);
    const [modalSecondStage, setModalSecondStage] = useState(false);
    const [secondStageModalList, setSecondStageModalList] = useState([]);
    const [investmentKey, setInvestmentKey] = useState('')
    const costCenter = Form.useWatch('costCenterId', form);
    const businessUnit = Form.useWatch('businessUnitId', form); 
    const templateId = Form.useWatch('templateId', form);
    const quantityEntered = Form.useWatch('quantity', form);
    const priceEntered = Form.useWatch('unityValue', form);

    useEffect(() => {
        if (isEditingDetail) {
            let selectedDetail;
            if (templateId === 0) {
                selectedDetail = detailData.accounting && { ...detailData.accounting };
            } else {
                selectedDetail = detailData.templates.find(item => item.templateId === templateId)
            }
            if (selectedDetail) {
                let updatedDisableMonthList = []
                Object.keys(selectedDetail.months).forEach(month => {
                    if (selectedDetail.months[month]['quantity']) {
                        const numericMonth = parseInt(month, 10) + 1;
                        updatedDisableMonthList.push(`${moment().year()}-${numericMonth < 10 ? `0${numericMonth}` : numericMonth}`);
                    }
                });
                setDisableMonths(updatedDisableMonthList)
            } else {
                setDisableMonths([]);
            }
        } else {
            setDisableMonths([]);
        }
    }, [templateId]);

    useEffect(() => {
        if (!isFetchingAccounting) setAccountingOption(accountingList.filter(accounting => accounting.costCenterId?.some(cc => cc == costCenter) || accounting.costCenterId.length == 0));
	}, [isFetchingAccounting]);

    useEffect(() => {
        let calculation = quantityEntered * priceEntered;
		if (!isNaN(calculation)) form.setFieldsValue({ totalValue: calculation });
    }, [quantityEntered, priceEntered]);

    useEffect(() => {
        if (modalSecondStage) {
            const arrayAgrupado = Object.values(modalList.reduce((acc, obj) => {
                if (!acc[obj.groupingKey]) {
                    acc[obj.groupingKey] = [];
                }
                acc[obj.groupingKey].push(obj);
                return acc;
            }, {}));

            setSecondStageModalList(arrayAgrupado);
        }
    }, [modalSecondStage, modalList]);

    function handleDeleteListItem(key: string) {
        const index = modalList.findIndex(item => item.itemKey === key)
        const updatedList = [...modalList]
        updatedList.splice(index, 1)
        setModalList(updatedList);
        if (updatedList.length < 1) setModalSecondStage(false);
    }

    function handleEditListItem(investment: ValueForm) {
        setEditDisebled(true);
        setModalSecondStage(false);
        loadAccountingList(investment.templateId);
        let flexFields = {};
        Object.keys(investment).forEach(currentKey => {
            if (currentKey.includes('ff-')) {
                flexFields[currentKey] = investment[currentKey] ? investment[currentKey] : null;
            }
        });

        form.setFieldsValue({
            businessUnitId: investment.businessUnitId,
            costCenterId: investment.costCenterId,
            templateId: investment.templateId,
            accountingId: investment.accountingId,
            currencyId: investment.currencyId,
            quantity: investment.quantity,
            unityValue: investment.unityValue,
            totalValue: investment.totalValue,
            acquisitionDate: investment.acquisitionDate,
            activationDate: investment.activationDate,
            justification: investment?.justification,
            ...flexFields
        })
        handleDeleteListItem(investment.itemKey);
        if (investment?.justification) setShowJustification(true);
    }

    function handleOkButtonClickModal() {
        if (modalSecondStage) {
            if (modalList.length > 0) {
                handleSave(secondStageModalList);
                setModalSecondStage(false);
            } else {
                Notification({
                    type: 'warning',
                    message: i18n.t("no_items_added_to_list")
                })
            }
        } else {
            setModalSecondStage(true)
        }
    }

    function handleCancelButtonClickModal() {
        if (!modalSecondStage) {
            setEditDisebled(false);
            handleCancel();
        } else {
            setModalSecondStage(false)
        }
    }

    function disablePeriod(current: moment.Moment) {
        let months = [];
        if (monthsToBlock[investmentKey]) {
            months = monthsToBlock[investmentKey].map(date => moment(date).format('YYYY-MM'));
        }

        if (disableMonths.includes(current.format('YYYY-MM'))) {
            return true;
        }
        if (!isEditingDetail && months.includes(current.format('YYYY-MM'))) {
            return true;
        }
        if (current.format('YYYY-MM') <= controlPanelPeriods[current.format('YYYY')]?.accomplished) {
            return true;
        }
        // if (current.format('YYYY-MM') <= accomplishedPeriod.accomplished) {
        //     return true;
        // }
        // if (current.format('YYYY-MM') <= accomplishedPeriod.accomplished) {
        //     return true;
        // }
        return false;
    }

    function disableDate(current: moment.Moment) {
        if (current.format('YYYY-MM') <= controlPanelPeriods[current.format('YYYY')]?.accomplished) {
            return true;
        }
        // if (current.format('YYYY-MM') <= accomplishedPeriod.accomplished) {
        //     return true;
        // }
        return false;
    }

    function loadAccountingList(templateId) {
        detailSelectedId === 0 && form.resetFields(["accountingId"]);
        generateInvestmentKey();
        if (templateId !== 0) {
            const templateOption = templateList.find(item => item.value === templateId);

            if (templateOption) {
                const accountingOptionToSet = accountingList.find(item => item.value === templateOption.accountingId);
                form.setFieldsValue({ accountingId: accountingOptionToSet.value });
                setAccountingOption([accountingOptionToSet]);
                setAccountingDisabled(true);
            }
        } else if (detailSelectedId === 0) {
            setAccountingOption(accountingList);
            setAccountingDisabled(false);
        }
    }

    function renderFlexfields(flexFields) {
        if (flexFields.length > 1) {
            const colCount = 3;
            const colWidth = 8;

            const rows = [];

            for (let i = 1; i < flexFields.length; i += colCount) {
                const fieldsRow = flexFields.slice(i, i + colCount);

                const cols = fieldsRow.map((ff, index) => (
                    <Col span={colWidth} key={index}>
                        <Form.Item
                            key={`ff-${ff.value}`}
                            name={`ff-${ff.value}`}
                            label={ff.label}
                        >
                            <Select
                                disabled={isEditingDetail}
                                onChange={(value) => {
                                    generateInvestmentKey();
                                    onChangeFlexFieldValue(value, ff.value, 0, costCenter, businessUnit, flexFieldsOptions, setFlexFieldsOptions, form);
                                }}
                                options={ff.children}
                                placeholder={i18n.t<string>("select")}
                                {...selectProps}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                ));

                const row = (
                    <Row gutter={20} key={i / colCount}>
                        {cols}
                    </Row>
                );

                rows.push(row);
            }

            return rows;
        }
    };

    function validateDateFields(acquisitionDate, activationDate) {
        if (!activationDate) {
            return Promise.resolve();
        }
        if (acquisitionDate?.isBefore(activationDate, 'month') || acquisitionDate?.isSame(activationDate, 'month')) {
            return Promise.resolve();
        } else {
            return Promise.reject("A data de ativação não pode ser menor que a data do período");
        }
    }

    function generateInvestmentKey() {
        form.resetFields(["acquisitionDate"]);
        if (isEditingDetail) return;
        let flexFieldsSelecteds = [];

        flexFieldsOptions.forEach(ff => {
            const ffChildrenValue = form.getFieldValue(`ff-${ff.value}`);
            if (ffChildrenValue) {
                flexFieldsSelecteds.push({ fieldCode: ff.fieldCode, valueOption: ffChildrenValue })
            }
        });

        const ffKey = generateFlexFieldSelectedString(flexFieldsSelecteds);

        const key = `${form.getFieldValue(`businessUnitId`)}-${form.getFieldValue(`costCenterId`)}-${form.getFieldValue(`accountingId`)}-${form.getFieldValue(`currencyId`)}-${ffKey}`;
        setInvestmentKey(key);
    }

    const handleDateChange = (date: moment.Moment | null) => {
        if (date && gracePeriod) {
            const newActivationDate = date.clone().add(gracePeriod, "months");
            form.setFieldsValue({ activationDate: newActivationDate });
        } else if (date) {
            form.setFieldsValue({ activationDate: date.clone() });
        }
    };

    return (
        <Modal
            width={1100}
            title={"Novo investimento"}
            open={isModalVisible}
            okButtonProps={modalList.length > 0 ? { htmlType: "submit", form: "new-account-form" } : { disabled: true }}
            onCancel={handleCancelButtonClickModal}
            className="gs-modal account-registration-modal investment-modal"
            cancelText={modalSecondStage ? i18n.t<string>("back") : i18n.t<string>("cancel")}
            okText={modalSecondStage ? i18n.t<string>("save") : i18n.t<string>("next")}
            onOk={handleOkButtonClickModal}
            centered
        >
            {!modalSecondStage ?
                <>
                    <Form
                        form={form}
                        name="new-account-form"
                        className={"form-new-account"}
                        onFinish={(data) => {
                            setEditDisebled(false);
                            setShowJustification(false);
                            handleSubmit(data);
                        }}
                        layout="vertical"
                        requiredMark={false}
                        initialValues={isEditingDetail ? null : {
                            templateId: 0
                        }}
                    >
                        <Row gutter={20}>
                            <Col span={8}>
                                <Form.Item
                                    name="businessUnitId"
                                    label={"Unidade de Negócio"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        disabled={isEditingDetail}
                                        loading={isFetchingBusinessUnit}
                                        options={businessUnitList}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        onChange={() => generateInvestmentKey()}
                                        placeholder={i18n.t<string>("select")}
                                        {...selectProps}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="costCenterId"
                                    label={"Centro de Custo"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        disabled={isEditingDetail}
                                        loading={isFetchingCostCenter}
                                        options={costCenterList}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        onChange={(value) => {
                                            generateInvestmentKey();
                                            setAccountingOption(accountingList.filter(accounting => accounting.costCenterId?.some(cc => cc == value) || accounting.costCenterId.length == 0));
                                        }}
                                        placeholder={i18n.t<string>("select")}
                                        {...selectProps}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            {flexFieldsOptions.slice(0, 1).map(ff => {
                                return (
                                    <Col span={8}>
                                        <Form.Item
                                            key={`ff-${ff.value}`}
                                            name={`ff-${ff.value}`}
                                            label={ff.label}
                                        >
                                            <Select
                                                disabled={isEditingDetail}
                                                onChange={(value) => {
                                                    generateInvestmentKey();
                                                    onChangeFlexFieldValue(value, ff.value, 0, costCenter, businessUnit, flexFieldsOptions, setFlexFieldsOptions, form);
                                                }}
                                                options={ff.children}
                                                {...selectProps}
                                                allowClear
                                                placeholder={i18n.t<string>("select")}
                                            />
                                        </Form.Item>
                                    </Col>
                                )
                            })}
                        </Row>
                        {renderFlexfields(flexFieldsOptions)}
                        <Row gutter={20}>
                            <Col span={8}>
                                <Form.Item
                                    name="templateId"
                                    label={"Template"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        loading={isFetchingTemplate}
                                        options={templateList.map(item => ({ value: item.value, label: item.label }))}
                                        onChange={loadAccountingList}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        placeholder={i18n.t<string>("select")}
                                        {...selectProps}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="accountingId"
                                    label={"Contabilização"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        loading={isFetchingAccounting}
                                        disabled={isEditingDetail || accountingDisabled}
                                        options={accountingOption.length > 0 ? accountingOption : []}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        onChange={() => generateInvestmentKey()}
                                        placeholder={i18n.t<string>("select")}
                                        {...selectProps}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="currencyId"
                                    label={"Moeda"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        disabled={isEditingDetail}
                                        loading={isFetchingCurrency}
                                        options={currenciesList}
                                        showSearch={true}
                                        optionFilterProp={"children"}
                                        onChange={() => generateInvestmentKey()}
                                        placeholder={i18n.t<string>("select")}
                                        {...selectProps}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={4}>
                                <Form.Item
                                    name="quantity"
                                    label={"Quantidade"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Input placeholder={i18n.t<string>("type_here")} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="unityValue"
                                    label={"Preço"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <InputNumber
                                        onChange={value => {
                                            if (Number(value) >= 0) {
                                                return (new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                                    notation: "compact",
                                                    currency: "BRL"
                                                }).format(Number(value)))
                                            }
                                        }}
                                        controls={false}
                                        decimalSeparator={i18n.language === "pt_BR" ? "," : "."}
                                        style={{ width: "100%" }}
                                        precision={2}
                                        placeholder={i18n.t<string>("type_here")}
                                        min={0}
                                        prefix="R$"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="totalValue"
                                    label={"Valor Total"}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <InputNumber
                                        onChange={value => {
                                            if (Number(value) >= 0) {
                                                return (new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                                    notation: "compact",
                                                    currency: "BRL"
                                                }).format(Number(value)))
                                            }
                                        }}
                                        disabled={true}
                                        controls={false}
                                        decimalSeparator={i18n.language === "pt_BR" ? "," : "."}
                                        style={{ width: "100%" }}
                                        precision={2}
                                        min={0}
                                        prefix="R$"
                                    />
                                    {/* /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="acquisitionDate"
                                    label={"Período"}
                                    rules={[
                                        { required: true, message: i18n.t<string>("required_field") },
                                        ({ getFieldValue }) => ({
                                            validator(_, value: any) {
                                                return validateDateFields(value, getFieldValue("activationDate"));
                                            },
                                        }),
                                    ]}
                                >
                                    <DatePicker
                                        className="gs-date-picker"
                                        // locale={languages[i18n.language]} 
                                        picker={'month'}
                                        disabledDate={disablePeriod}
                                        format={'MM/YYYY'}
                                        onChange={handleDateChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="activationDate"
                                    label={"Ativação do Bem"}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value: any) {
                                                const acquisitionDate = getFieldValue("acquisitionDate");
                                                if (!value || !acquisitionDate) {
                                                    return Promise.resolve();
                                                }

                                                return validateDateFields(acquisitionDate, value);
                                            },
                                        }),
                                    ]}
                                >
                                    <DatePicker
                                        className="gs-date-picker"
                                        // locale={languages[i18n.language]} 
                                        picker={'month'}
                                        disabledDate={disableDate}
                                        format={'MM/YYYY'}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={24}>
                                <span
                                    style={{ padding: "5px 0", display: "flex", alignItems: "center", gap: 10, cursor: "pointer" }}
                                    onClick={() => setShowJustification(!showJustification)}
                                >
                                    <Icon icon="mdi:chat-plus" width={20} />Justificativa (Opcional)
                                </span>
                                <Form.Item
                                    name="justification"
                                    rules={[showJustification && { required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    {showJustification &&
                                        <TextArea
                                            rows={4}
                                            placeholder={"Insira uma justificativa"}
                                        />}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button
                            style={{ marginTop: -10, marginLeft: "auto" }}
                            type="default"
                            htmlType="submit"
                            className="gs-secondary-button"
                            icon={<Icon icon="akar-icons:circle-plus-fill" />}
                        >
                            {i18n.t('addToList')}
                        </Button>
                    </Form>
                    {modalList.length > 0 ?
                        <>
                            <div className="new-account-table-head">
                                <Row align="middle" gutter={2}>
                                    <Col span={3}>Unidade</Col>
                                    <Col span={3}>Centro de Custo</Col>
                                    <Col span={3}>Contabilização</Col>
                                    <Col span={3}>Template</Col>
                                    <Col span={1} style={{ width: 25, flex: "0 0 auto" }} />
                                    <Col span={3}>Moeda</Col>
                                    <Col span={2}>Valor Total</Col>
                                    <Col span={2}>Período</Col>
                                    <Col span={2}>Ativação</Col>
                                    <Col span={1} />
                                    <Col span={1} />
                                </Row>
                            </div>
                            <div className={modalSecondStage ? "new-account-table-body investment-second-stage" : "new-account-table-body"}>
                                {modalList.map((item, index) => {
                                    return (
                                        <SecondStageModal
                                            businessUnitList={businessUnitList}
                                            costCenterList={costCenterList}
                                            currenciesList={currenciesList}
                                            accountingList={accountingList}
                                            templateList={templateList}
                                            editDisebled={editDisebled}
                                            handleEditListItem={handleEditListItem}
                                            handleDeleteListItem={handleDeleteListItem}
                                            item={item}
                                            formattedValue={formattedValue}
                                        />
                                    );
                                })}
                            </div>
                        </>
                        :
                        <ImageBox />
                    }
                </> :
                <>
                    <div className="new-account-table-head">
                        <Row align="middle" gutter={2}>
                            <Col span={3}>Unidade</Col>
                            <Col span={3}>Centro de Custo</Col>
                            <Col span={3}>Contabilização</Col>
                            <Col span={3}>Template</Col>
                            <Col span={1} style={{ width: 25, flex: "0 0 auto" }} />
                            <Col span={3}>Moeda</Col>
                            <Col span={2}>Valor Total</Col>
                            <Col span={2}>Período</Col>
                            <Col span={2}>Ativação</Col>
                            <Col span={1} />
                            <Col span={1} />
                        </Row>
                    </div>
                    <div className={modalSecondStage ? "new-account-table-body investment-second-stage" : "new-account-table-body"}>
                        {secondStageModalList.map((item) => {
                            if (item.length > 1) {
                                return (
                                    <div className="grouping-line">
                                        {
                                            item.map((sub) => {
                                                return (
                                                    <SecondStageModal
                                                        businessUnitList={businessUnitList}
                                                        costCenterList={costCenterList}
                                                        currenciesList={currenciesList}
                                                        accountingList={accountingList}
                                                        templateList={templateList}
                                                        editDisebled={editDisebled}
                                                        handleEditListItem={handleEditListItem}
                                                        handleDeleteListItem={handleDeleteListItem}
                                                        item={sub}
                                                        formattedValue={formattedValue}
                                                    />
                                                );
                                            })
                                        }
                                    </div>
                                );
                            } else {
                                return (
                                    <SecondStageModal
                                        businessUnitList={businessUnitList}
                                        costCenterList={costCenterList}
                                        currenciesList={currenciesList}
                                        accountingList={accountingList}
                                        templateList={templateList}
                                        editDisebled={editDisebled}
                                        handleEditListItem={handleEditListItem}
                                        handleDeleteListItem={handleDeleteListItem}
                                        item={item[0]}
                                        formattedValue={formattedValue}
                                    />
                                );
                            }
                        })}
                    </div>
                </>
            }
        </Modal>
    )
}