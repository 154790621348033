import { useUserContext } from 'context/UserContext';
import { createContext, useContext, useEffect, useState } from 'react';
import i18n from 'util/base/i18n';
import { RequestType } from 'util/service/IServiceCaller';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { ErrorRequest } from 'util/types/types';
import { IParameterizationContext, IParametizationData } from '../IParameterizationFromTo';
import { handleErrorRequest } from 'util/functions/handleErrorRequest';
import { FlexField, FlexFieldValue } from '../../attributeParameterization/IAttributeParameterization';

const ParameterizationContext = createContext<IParameterizationContext>({} as IParameterizationContext);

export const ParameterizationProvider = ({ children }) => {

  const { userInfo } = useUserContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState<[]>([]);
  const [dataSource, setDataSource] = useState<IParametizationData[]>([]);
  const [flexFieldOptions, setFlexFieldOptions] = useState<FlexField[]>([]);
  const [flexFieldValueOptions, setFlexFieldValueOptions] = useState<FlexFieldValue[]>([]);
  const rowSelection = {
    onChange: (keys, selectedRows) => {
      setSelectedRowKeys(keys);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const clearSelectedRowKeys = () => {
    setSelectedRowKeys([]);
  };

  function loadTable() {
    if(userInfo.selection.organizationId && userInfo.selection.scenarioId) {
      ServiceCaller.doRequest(
        {
          type: RequestType.GET,
          url: `/revenue/parameterization?organization=${userInfo.selection.organizationId}&scenario=${userInfo.selection.scenarioId}`,
        },
        (response: any) => {
          const flattenedData = flattenData(response);
          const translatedData = flattenedData.map(item => ({
            ...item,
            destinyInterface: item.destinyInterface,
          }));
          setDataSource(translatedData);
        },
        (err: ErrorRequest) => {
          handleErrorRequest(err);
        }
      );
      selectedRowKeys.length = 0;
    }
  }

  const flattenData = (data: any[]): IParametizationData[] => {
    if (!data) return
    return data.flatMap((item, index) => ({ ...item, children: flattenData(item?.childrens), key: item.id ? item.id : index }));
  };

  function loadFlexFieldValues(ids) {
    let flexFieldValuesToAdd: any[] = [];
    ids.forEach((id: number) => {
      ServiceCaller.doRequest(
        {
          type: RequestType.GET,
          url: `/budget-base/flex-field-value/find-all-flexfield-without-security?flexFieldId=${id}&user={user}`,
        },
        (response: FlexFieldValue[]) => {
          response.forEach((value) => flexFieldValuesToAdd.push(value));
        }
      );
    });
    setFlexFieldValueOptions(flexFieldValuesToAdd);
  }

  useEffect(() => {
    ServiceCaller.doRequest(
      {
        type: RequestType.GET,
        url: `/budget-base/flex-field/find-all-by-module?module=REVENUE`,
      },
      (response: FlexField[]) => {
        setFlexFieldOptions(response);
        loadFlexFieldValues(response.map(flex => flex.id));
      }
    );
  }, []);

  return (
    <ParameterizationContext.Provider
      value={{
        selectedRowKeys,
        dataSource,
        loadTable,
        flexFieldOptions,
        flexFieldValueOptions,
        rowSelection,
        clearSelectedRowKeys,
      }}>
      {children}
    </ParameterizationContext.Provider>
  );
};

export const useParameterizationContext = () => {
  return useContext(ParameterizationContext);
};