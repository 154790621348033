import { useEffect, useState } from "react";
import { Modal, Table } from "antd"
import { Icon } from "@iconify/react";

import i18n from 'util/base/i18n';

import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import { DataTypeTable, IChecklistModalProps } from "./IChecklistScenarioModal";
import { ColumnsType } from "antd/lib/table";

import moment from 'moment';

const tableColumns: ColumnsType<DataTypeTable> = [
  {
    title: () => i18n.t<string>("data_position"),
    dataIndex: 'baseDate',
    key: 'baseDate',
    render: (text) => moment(text).format('MM-YYYY'),
  },
  {
    title: () => i18n.t<string>("operational_unit"),
    dataIndex: 'businessUnitName',
    key: 'businessUnitName',
  },
  {
    title: () => i18n.t<string>("origin_contract_quantity"),
    align: "right",
    dataIndex: 'originQuantityContracts',
    key: 'originQuantityContracts',
  },
  {
    title: () => i18n.t<string>("origin_asset_balance"),
    align: "right",
    dataIndex: 'originActiveBalance',
    key: 'originActiveBalance',
    render: (value) => value.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: () => i18n.t<string>("origin_liability_balance"),
    align: "right",
    dataIndex: 'originPassiveBalance',
    key: 'originPassiveBalance',
    render: (value) => value.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: () => i18n.t<string>("destination_contract_quantity"),
    align: "right",
    dataIndex: 'destinyQuantityContracts',
    key: 'destinyQuantityContracts',
  },
  {
    title: () => i18n.t<string>("destination_asset_balance"),
    align: "right",
    dataIndex: 'destinyActiveBalance',
    key: 'destinyActiveBalance',
    render: (value) => value.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: () => i18n.t<string>("destination_liability_balance"),
    align: "right",
    dataIndex: 'destinyPassiveBalance',
    key: 'destinyPassiveBalance',
    render: (value) => value.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: () => i18n.t<string>("check"),
    dataIndex: 'check',
    key: 'check',
    align: "center",
    render: (text, record) => (
      <span>
        {text ? (
          <Icon icon={'octicon:check-16'} width={24} style={{ color: "green" }} />
        ) : (
          <Icon icon={'ph:x-bold'} width={24} style={{ color: "red" }} />
        )}
      </span>
    ),
  },
];

export default function ChecklistScenarioModal({ isModalVisible, handleCancel, selectedScenarioId }: IChecklistModalProps) {
  const [filteredData, setFilteredData] = useState<DataTypeTable[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (selectedScenarioId !== null && selectedScenarioId !== undefined && isModalVisible) {
      setFilteredData([])
      getChecklistScenario(selectedScenarioId)
    }
  }, [isModalVisible, selectedScenarioId])

  function getChecklistScenario(scenarioId) {
    setIsFetching(true)
    ServiceCaller.doRequest({
      type: RequestType.GET,
      url: `/projectionlease/scenario/checklistScenario?scenarioId=${scenarioId}`,
      useProxy: false,
    }, onGetChecklist.bind(this));
  }

  function onGetChecklist(data) {
    setFilteredData(data)
    setIsFetching(false)
  }

  return (
    <Modal
      width={1300}
      title={i18n.t<string>('scenario_checklist')}
      className="gs-modal checklist-scenario-modal"
      visible={isModalVisible}
      footer={null}
      onCancel={() => { handleCancel() }}
      destroyOnClose
    >
      <Table
        id="checklist-scenario-table"
        loading={{
          spinning: isFetching,
          tip: i18n.t<string>("loading")
        }}
        className="gs-table"
        dataSource={filteredData}
        columns={tableColumns}
        pagination={false}
        bordered
      />
    </Modal>
  )
}