import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from 'hooks/useCachedQuery';
import { RequestType } from "util/service/IServiceCaller";
import { Options } from "util/types/types";

interface CostCenterOptions {
    id: number;
    name: string;
    lastSelected: boolean;
}

export function useSelectedCostCenterOptions(queryProps = {} as UseQueryOptions<Options[]>) {
    const queryInfo = useCachedQuery<Options[]>(
        ['cost-center-selected-options'],
        {
            type: RequestType.GET,
            url: `/expenses/cost-center?client={client}&locale={locale}&user={user}&organization={organization}&bu={businessUnit}`,
        },
        {},
        (data: { id: number; name: string; lastSelected: boolean }[]) => {
            return data.map((item) => ({ label: item.name, value: item.id, lastSelected: item.lastSelected }));
        }
    )
    
    return queryInfo;
}

