import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Tag } from "./Tag";
import { DivideOp, LeftParenthesisOp, MinusOp, PlusOp, RightParenthesisOp, TimesOp } from "./operators/MathOperators";
import { InputNumber } from "antd";
import { FormulaItem } from "../IAdditionalFields";

interface IFormulaAreaProps {
    formula: FormulaItem[];
    removeItem: (index: number) => void;
    hasValueOperator?: boolean;
    setFormula?: React.Dispatch<React.SetStateAction<FormulaItem[]>>;
}

export function FormulaAreaFields({ formula, removeItem, hasValueOperator = false, setFormula }: IFormulaAreaProps) {
    function handleDragEnd(result) {
        if (!result.destination) return;
        const items = Array.from(formula);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setFormula(items);
    }

    const formulaWithOperatorKeys = formula.map((item) => {
        if (item.type === "operator") {
            return {
                ...item,
                key: `OPERATORS-${Math.random()}`,
            };
        }

        return item;
    });

    function onChaneValueInput(newValue: number, ordination): void {
        setFormula((state) => {
            let itemToChange = state[ordination];
            itemToChange = { ...itemToChange, informedValue: newValue };
            state[ordination] = itemToChange;
            return state;
        });
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
                droppableId="attribute_parameterization-new-attribute-modal-formula-workarea"
                direction="horizontal"
            >
                {(provided) => (
                    <div
                        className="attribute_parameterization-new-attribute-modal-formula-workarea"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {formulaWithOperatorKeys.map((item, index) => {
                            return (
                                <Draggable
                                    key={item.key}
                                    draggableId={item.type === "operator" ? item.content : "" + item.key}
                                    index={index}
                                >
                                    {(provided) => {
                                        if (item.type === "operator") {
                                            switch (item.content) {
                                                case "PLUS":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                isMathOperation
                                                                itemIndex={index}
                                                                removeItem={removeItem}
                                                            >
                                                                <PlusOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "LESS":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                isMathOperation
                                                                removeItem={removeItem}
                                                            >
                                                                <MinusOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "MULTIPLY":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                removeItem={removeItem}
                                                                isMathOperation
                                                            >
                                                                <TimesOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "DIVIDER":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                removeItem={removeItem}
                                                                isMathOperation
                                                            >
                                                                <DivideOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "LEFT_PARENTHESIS":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                isMathOperation
                                                                removeItem={removeItem}
                                                                isParenthesis
                                                            >
                                                                <LeftParenthesisOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                                case "RIGHT_PARENTHESIS":
                                                    return (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tag
                                                                itemIndex={index}
                                                                isMathOperation
                                                                removeItem={removeItem}
                                                                isParenthesis
                                                            >
                                                                <RightParenthesisOp />
                                                            </Tag>
                                                        </div>
                                                    );
                                            }
                                        }

                                        if (item.type === "field") {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Tag itemIndex={index} removeItem={removeItem}>
                                                        {item.content.name}
                                                    </Tag>
                                                </div>
                                            );
                                        }

                                        if (item.type === "value") {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <Tag itemIndex={index} removeItem={removeItem} isMathOperation>
                                                        <InputNumber
                                                            onChange={(newValue) => onChaneValueInput(newValue, index)}
                                                            value={item.informedValue}
                                                            controls={false}
                                                            formatter={(value) => {
                                                                return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                                            }}
                                                            parser={(value) => {
                                                                const parsedValue = parseFloat(value.replace(/,/g, '.'));
                                                                return isNaN(parsedValue) ? 0 : parsedValue;
                                                            }}                                                     />
                                                    </Tag>
                                                </div>
                                            );
                                        }

                                        return null;
                                    }}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
