import { useEffect, useState } from "react";

import { Button, Popover, Tooltip, Tree } from "antd";
import i18n from "util/base/i18n";
import './Settings.sass'
import { widgetDefinitions } from "../IWidgetDefinition";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Icon } from "@iconify/react";
import { ISettings, reOrder } from "./ISettings";

interface Props {
	data: Array<ISettings>
	callBack: Function
	visiblePopover: boolean
	setVisiblePopover: (b: boolean) => void
	step: number
}

export default function Settings(props: Props) {

	const [checkedKeys, setCheckedKeys]: [String[], Function] = useState([])

	const [gData, setGData]: [any[], Function] = useState(props.data);

	const [disabled, setDisabled] = useState(true);

	const allowDrop = e => {
		let result = true;
		if (e.dropPosition === 0) {
			return false;
		}

		return result;
	}

	const onDrop = info => {
		const dropKey = info.node.key;
		const dragKey = info.dragNode.key;
		const dropPos = info.node.pos.split('-');
		const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

		const loop = (data, key: String, callback) => {
			for (let i = 0; i < data.length; i++) {
				if (data[i].key === key) {
					return callback(data[i], i, data);
				}
			}
		}
		const data = [...gData];

		let dragObj;
		loop(data, dragKey, (item, index, arr) => {
			arr.splice(index, 1);
			dragObj = item;
		});

		if (!info.dropToGap) {
			loop(data, dropKey, item => {
				item.children = item.children || [];
				item.children.unshift(dragObj);
			});
		} else if (info.node.props.expanded && dropPosition === 1) {
			loop(data, dropKey, item => {
				item.children = item.children || [];
				item.children.unshift(dragObj);
			});
		} else {
			let ar: any[];
			let i: number;

			loop(data, dropKey, (item, index, arr) => {
				ar = arr;
				i = index;
			});
			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj);
			} else {
				ar.splice(i + 1, 0, dragObj);
			}
		}
		setGData(data);
	}

	const onCheck = (activeKeys: React.Key[], info: any) => {
		const newData = gData;
		newData.find(item => item.key === info.node.key).active = info.checked;

		setCheckedKeys(activeKeys);
		setGData(newData);
	}

	const onClick = () => {
		let data: Array<reOrder> = gData.map(({ id, active }, index) => {
			return {
				id,
				active,
				position: index
			}
		})

		ServiceCaller.doRequest({
			type: RequestType.PUT,
			url: `/dashboard-analytics/widget`,
			params: data
		})

		props.setVisiblePopover(false);
		props.callBack(data);
	}

	const openPopover = () => {
		props.setVisiblePopover(true);
	}

	const handleVisibleChange = (visible: boolean) => {
		if(props.step != 2) {
			props.setVisiblePopover(visible);
		}
	}

	useEffect(() => {
		if(props.step == 2) {
			setDisabled(true);
		} else {
			setDisabled(false);
			props.setVisiblePopover(false);
		}
	}, [props.step])

	useEffect(() => {
		props.data
			.forEach((item, index) => {
				item.title = i18n.t<string>(widgetDefinitions[item.type].title);
				item.key = index;
			})
		setGData(props.data);
	}, [props.data])

	const widgetSettings: React.ReactElement =
		<div className="content-settings">
			<div className="setting-content-header">
				<small className="gs-small">{i18n.t<string>("dashboard.analytics.select_widget_panel")}</small>
				<Tree
					className="draggable-tre"
					allowDrop={allowDrop}
					checkable={true}
					blockNode={true}
					onCheck={onCheck}
					onDrop={onDrop}
					treeData={gData}
					autoExpandParent={true}
					checkedKeys={(checkedKeys.length === 0 ? gData?.filter(({ active }) => active).map(({ key }) => key) : checkedKeys)}
					draggable
					selectable={false}
					disabled={disabled}
				/>
			</div>
			<Button disabled={disabled} onClick={onClick} type="primary"> {i18n.t<string>("apply")} </Button>
		</div>;

	return (
		<Tooltip title={i18n.t<string>("configure")}>
			<Popover zIndex={10001} destroyTooltipOnHide={true} overlayClassName="cm-step2 analytics-setting-popover" onVisibleChange={handleVisibleChange} visible={props.visiblePopover} content={
				props.data != null &&
				widgetSettings
			} title={i18n.t<string>("settings")} trigger="click">
				<Button className="cm-step1 cm-step1-main cm-step2 cm-step2-main"  onClick={openPopover} type="text" size="large" icon={<Icon icon={"vscode-icons:file-type-light-config"} />} />
			</Popover>
		</Tooltip>
	)
}