import { Tooltip } from "antd"
import { TooltipBoxProps } from "../../IHiredDashboard"
import i18n from "util/base/i18n"

export default function TooltipBox({ data }: TooltipBoxProps) {

	return (
		<>
			{
				data?.length > 0 ?
					<div className="">
						{data[0].label}
					</div>
					:
					<div className="all-selected">
						{i18n.t("all")}
					</div>
			}
			{
				data?.length > 1 &&
				<Tooltip
					color={'rgba(0,0,0,0.9'}
					placement="right"
					title={data.map(({ label }) => <p style={{ margin: 0 }}>{label}</p>)}
				>
					<span className="additional-items">
						{`+${data?.length - 1}`}
					</span>
				</Tooltip>
			}
		</>
	)
}