import { OtherEventGridData, ViewModeSelected, totalSummary } from "../../../IOtherEvents";

export function createTableTotalsRow(
    data: OtherEventGridData[],
    viewModeSelected: ViewModeSelected[]
) {
    const monthArray = Object.keys(data[0].columns);
    let totals: totalSummary[] = [];

    let projectedTotal = 0;
    let historicTotal = 0;
    let plannedTotal = 0;

    monthArray.forEach((month) => {
        let projectedValue = 0;
        let historicValue = 0;
        let plannedValue = 0;

        data.forEach((otherEventData) => {
            plannedValue += otherEventData.columns[month].plannedValue;
            projectedValue += otherEventData.columns[month].projectedValue;
            historicValue += otherEventData.columns[month].historicValue;
            plannedTotal += otherEventData.columns[month].plannedValue;
            projectedTotal += otherEventData.columns[month].projectedValue;
            historicTotal += otherEventData.columns[month].historicValue;
        });

        totals.push({ value: plannedValue, month, order: 1 });

        if (viewModeSelected.includes(ViewModeSelected.PROJECTED))
            totals.push({ value: projectedValue, month, order: 2 });
        if (viewModeSelected.includes(ViewModeSelected.HISTORIC))
            totals.push({ value: historicValue, month, order: 3 });
    });

    totals.push({ value: plannedTotal, month: "99999", order: 4 });

    if (viewModeSelected.includes(ViewModeSelected.PROJECTED))
        totals.push({ value: projectedTotal, month: "999999", order: 5 });
    if (viewModeSelected.includes(ViewModeSelected.HISTORIC))
        totals.push({ value: historicTotal, month: "999999", order: 6 });

    const sorted = totals.sort((a, b) => {
        if (a.month > b.month) return 1;
        if (a.month < b.month) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
    });

    return sorted;
}
