import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import i18n from "util/base/i18n";
import { handleRedirect } from "util/functions/handleRedirect";
import { ROOT_PATH } from "util/rootPath";
import { MessageType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";


export default function EmptyDate() {
	const navigate = useNavigate();
	return (
		<div className="empty-date-page">
			<img src="/assets/images/budget.png" alt="wfn-budget" />
			<h2>{i18n.t("contract_recurring.text_warning_empty_period_base")}</h2>
			<Button onClick={() => {
				handleRedirect(
					`${ROOT_PATH}/mf/budget/control-panel`,
					true,
					navigate
				)
			}}
			>
				{i18n.t("control_panel")}
			</Button>
		</div >
	)
}