export const columnsToFormat: string[] = [
  'disbursementValue',
  'principalValueShort',
  'principalValueLong',
  'grossBalance',
  'installmentValue',
  'netInstallment',
  'depreciationProv',
  'depreciationAccum',
  'depreciationAccumIncurred',
  'reversalDepreciationAccum',
  'depreciationBalance',
  'reversalDepreciationBalance',
  'usufructActiveBalanceShort',
  'reversalActiveShort',
  'usufructActiveBalanceLong',
  'reversalActiveLong',
  'usufructActiveMaturity',
  'usufructActiveGroup',
  'principalValue',
  'principalBalance',
  'reversalPrincipalBalance',
  'principalBalanceShort',
  'reversalPassiveShort',
  'principalBalanceLong',
  'reversalPassiveLong',
  'principalMaturity',
  'restatementRentGroup',
  'interestProv',
  'interestAccum',
  'interestPaid',
  'interestCapitalized',
  'reversalInterest',
  'interestShort',
  'interestLong',
  'interestAccumShort',
  'reversalInterestShort',
  'interestGroup',
  'interestApp',
  'interestAppShort',
  'interestAppLong',
  'interestAppBalance',
  'reversalInterestApp',
  'interestAppBalanceShort',
  'reversalInterestAppShort',
  'interestAppBalanceLong',
  'restatementCapitalizedRent',
  'restatementShort',
  'restatementLong',
  'interestAccumLong',
  'reversalInterestLong',
  'interestMaturity',
  'reversalInterestAppLong',
  'interestAppMaturity',
  'additionalDiscount',
  'additionalIncrease',
  'operationalCostAccumMaturity',
  'operationalCostUsufructShort',
  'operationalCostAccumLong',
  'operationalCostAccumShort',
  'operationalCostPaid',
  'operationalCostPrincipalAccumShort',
  'operationalCostPrincipalAccumLong',
  'operationalCostProv',
  'operationalCostAccum',
  'operationalCostUsufructLong',
  'operationalCostUsufruct',
  'depreciationDisassembly',
  'reversalDisassemblyBalance',
  'depreciationDisassemblyInc',
  'reversalDepreciationDisassemblyAccum',
  'depreciationDisassemblyAccum',
  'disassemblyBalance',
];