import { Button, Col, Row } from "antd";
import i18n from "util/base/i18n";
import { FormulaArea } from "./FormulaArea";
import { OperationsSelect } from "./OperationsSelect";
import { FormulaItem, IFormulaContentProps } from "../IStepTwo";

export default function FormulaContent({
    formula,
    setFormula,
    plainFormula,
    setPlainFormula,
    accountingList,
    projectionsOptions,
    isEditingProjection = false,
    fieldList,
}: IFormulaContentProps) {
    function buildOperationToAdd(selectedOperation: string, lastItem: FormulaItem, openingParenthesesCount: number, closingParenthesesCount: number): FormulaItem {
		let operationToAdd: FormulaItem | null = null;

		if (selectedOperation === "LEFT_PARENTHESIS") {
			const isValid = !lastItem || lastItem.type === "operator" || lastItem.content === "LEFT_PARENTHESIS";
			if (isValid) {
				operationToAdd = { type: "operator", content: selectedOperation };
			}
		} else if (selectedOperation === "RIGHT_PARENTHESIS") {
			const isValid = closingParenthesesCount < openingParenthesesCount && lastItem?.content !== "LEFT_PARENTHESIS";
			if (isValid) {
				operationToAdd = { type: "operator", content: selectedOperation };
			}
		} else if (["VALUE", "PERCENTAGE", "FIELD"].includes(selectedOperation)) {
			const canAddItem = !lastItem ||
				lastItem.content === "LEFT_PARENTHESIS" ||
				lastItem.content === "RIGHT_PARENTHESIS" ||
				(lastItem.type === "operator") ||
				(selectedOperation === "PERCENTAGE" && lastItem.type === "value");

			if (canAddItem) {
				if (selectedOperation === "VALUE") {
					operationToAdd = { type: "value", content: selectedOperation, key: `INFORMED_VALUE-${Math.random()}` };
				} else if (selectedOperation === "PERCENTAGE") {
					operationToAdd = { type: "percentage", content: selectedOperation, key: `INFORMED_VALUE-${Math.random()}` };
				} else if (selectedOperation === "FIELD") {
					operationToAdd = { type: "field", content: selectedOperation, key: `FIELD-${Math.random()}` };
				}
			}
		} else {
			const canAddOperator = lastItem &&
				(lastItem.type === "value" || lastItem.type === "field" || lastItem.type === "percentage" || lastItem.content === "RIGHT_PARENTHESIS");

			if (canAddOperator) {
				operationToAdd = { type: "operator", content: selectedOperation };
			}
		}

		return operationToAdd;
    }

    function addOperationToFormula(selectedOperation: string) {
        const lastItem: FormulaItem = formula[formula.length - 1];
        const openingParenthesesCount: number = formula.filter((item) => item.content === "LEFT_PARENTHESIS").length;
        const closingParenthesesCount: number = formula.filter((item) => item.content === "RIGHT_PARENTHESIS").length;
        let operationToAdd: FormulaItem = buildOperationToAdd(
            selectedOperation,
            lastItem,
            openingParenthesesCount,
            closingParenthesesCount
        );
        if (!operationToAdd) return;
        setFormula([...formula, operationToAdd]);
    }

    function removeItem(index) {
        setFormula((state) => state.filter((_, itemIndex) => itemIndex !== index));
    }

    function handleCleanFormula() {
        setFormula([]);
    }

    return (
        <>
            <Row style={{ marginTop: "16px" }}>
                <Col span={18}>
                    <Row>
                        <Col>
                            <div className="form-field-operations">
                                <OperationsSelect onChooseOperation={addOperationToFormula} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col id="field-modal-formula" span={24}>
                    <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <h3>{i18n.t("additional_field_formula")}</h3>
                        <Button style={{ marginBottom: "8px" }} onClick={handleCleanFormula}>
                            {i18n.t("clear_formula")}
                        </Button>
                    </Row>
                    <FormulaArea
                        formula={formula}
                        removeItem={removeItem}
                        setFormula={setFormula}
                        plainFormula={plainFormula}
                        setPlainFormula={setPlainFormula}
                        accountingList={accountingList}
                        projectionsOptions={projectionsOptions}
                        isEditingProjection={isEditingProjection}
                        fieldList={fieldList}
                    />
                </Col>
            </Row>
        </>
    );
}
