import { Icon } from "@iconify/react";
import { Button, Cascader, Col, Form, Modal, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useState } from "react";
import i18n from "util/base/i18n";
import { LinkAccountsModalProps, ValueLinkForm } from "../ILinkAccounts";
import { ImageBox } from "components/imageBox/ImageBox";
import { selectProps } from "util/props/props";

export default function LinkAccountsModal({
    isModalVisible,
    isNewLink,
    handleSubmit,
    handleCancel,
    form,
    newLinkList,
    setNewLinkList,
    tableData,
    accountData,
    accountValueOption,
    isFetchingAccountOption,
    costCategoryOption,
    setCostCategoryOption,
    isFetchingCostCategoryOption,
    fixedExpenseData,
    isFetchingFixedExpenseData,
    handleSaveLink,
    getCostCateoryDescription,
    fixedExpenseOption,
    setFixedExpenseOption,
    costCategoryData,
    disabledField,
    setDisabledField,
    filterFixedExpenseOptions,
    filterCostCategoryOptions,
    selectedRows,
    flexFieldValuesOptions,
    setFlexFieldValuesOptions,
    flexFieldValuesData,
    isFetchingFlexFieldOptions,
    isFlexFieldDisabled,
    setIsFlexFieldDisabled,
    isCostCategoryDisabled,
    setIsCostCategoryDisabled,
    setAccountValueOption
}: LinkAccountsModalProps) {

    const [editDisebled, setEditDisebled] = useState(false);

    const modalTitle = isNewLink
        ? i18n.t<string>("linkAccount.link_new_account")
        : i18n.t<string>("linkAccount.edit_account_link");

    function findAccountLabel(value) {
        const account = accountData.filter(account => account.value === value);
        return account[0].label;
    }

    function findFixedExpenseLabel(value) {
        const account = fixedExpenseData.filter(account => account.value === value);
        return account[0].label;
    }

    function handleDeleteListItem(key) {
        const categories = [...newLinkList];
        const listFiltered = categories.filter((category) => (category.key !== key));
        setNewLinkList(listFiltered);
        loadCostCategoryOptions();
        return listFiltered;
    }

    function handleEditListItem(linked: ValueLinkForm) {
        setEditDisebled(true);
        setIsCostCategoryDisabled(false);
        
        if(flexFieldValuesData.length == 0){
            const links = tableData.filter(item => item.resourceAccountId === linked.account);
            filterFixedExpenseOptions(links);

            const linksFromAccountingAccount = tableData.filter(link => link.resourceAccountId === linked.account);
            filterCostCategoryOptions(linksFromAccountingAccount);
        }

        form.setFieldsValue({
            account: linked.account,
            fixedExpenseAccount: Number(linked.fixedExpenseAccount),
            costCategoryLink: linked.costCategoryLink.map(category => {
                return category
            })
        });
    }

    function onChangeAccountOption() {
        setDisabledField(false);
    }

    function loadCostCategoryOptions() {
        setCostCategoryOption(costCategoryData);
    }

    function validateKey(){
        const formValues = form.getFieldsValue();
        if(flexFieldValuesData.length > 0){
            if(formValues.account && formValues.fixedExpenseAccount && formValues.costCategoryLink){
                const linkedFlexFieldIds = tableData.filter(acc => {
                    return acc.resourceAccountId === formValues.account && 
                    acc.accountingAccountId === formValues.fixedExpenseAccount && 
                    acc.costCategories?.map(cc => cc.costCategoryId)?.some(cc => formValues.costCategoryLink.flat().includes(cc))
                }).map(acc => {
                    return acc.flexFieldValueId;
                });
    
                const filteredFlexFields = flexFieldValuesData.filter(ff => !linkedFlexFieldIds.includes(ff.id)).map(ff => {
                    return {
                        value: ff.id,
                        label: ff.externalCode + ' - ' + ff.description
                    };
                });
    
                setIsFlexFieldDisabled(false);
                setFlexFieldValuesOptions(filteredFlexFields);
            }else{
                setIsFlexFieldDisabled(true);
            }
        }else{
            const links = tableData.filter(link => link.resourceAccountId === formValues.account);
            const newLinks = newLinkList.filter(link => link.account === formValues.account);

            const linkedExpensesAccountIds = links.map(link => link.accountingAccountId).concat(newLinks.map(link => link.fixedExpenseAccount));
            const filteredExpensesAccounts = fixedExpenseData.filter(ff => !linkedExpensesAccountIds.includes(ff.value));

            setFixedExpenseOption(filteredExpensesAccounts);

            if(formValues.fixedExpenseAccount){
                const linksFromAccountingAccout = tableData.filter(link => link.accountingAccountId === formValues.fixedExpenseAccount);
                const newLinksFromAccountingAccout= newLinkList.filter(link => link.fixedExpenseAccount === formValues.fixedExpenseAccount);

                const linkedResourceAccountIds = linksFromAccountingAccout.map(link => link.resourceAccountId).concat(newLinksFromAccountingAccout.map(link => link.account));
                const filteredResourceAccount = accountData.filter(ff => !linkedResourceAccountIds.includes(Number(ff.value)));

                setAccountValueOption(filteredResourceAccount);
            }

            if(formValues.account && formValues.fixedExpenseAccount){
                const linksFromResourceAccount = tableData.filter(link => link.resourceAccountId === formValues.account);
                const newLinksFromResourceAccount= newLinkList.filter(link => link.account === formValues.account);

                const costCategoryLinkedIds = linksFromResourceAccount.reduce((acc, val) => {
                    const ids = val.costCategories.map(cc => cc.costCategoryId);
                    acc = [...acc, ...ids];
    
                    return acc;
                }, []).concat(newLinksFromResourceAccount.map(link => link.costCategoryLink.flat()).flat());

                const filteredCostCategories = costCategoryData.filter(ff => !costCategoryLinkedIds.includes(ff.value));
    
                const missingValues = formValues.costCategoryLink?.flat()
                .filter(cc => !filteredCostCategories.map(cc => cc.value).includes(cc));

                if(missingValues?.length > 0 && formValues.costCategoryLink){
                    form.setFieldsValue({
                        account: formValues.account,
                        fixedExpenseAccount: Number(formValues.fixedExpenseAccount),
                        costCategoryLink: null
                    });
                }

                setCostCategoryOption(filteredCostCategories);
                setIsCostCategoryDisabled(false);
            }else{
                setIsCostCategoryDisabled(true);
            }
        }
    }
    return (
        <Modal
            width={flexFieldValuesData.length === 0 ? 950 : 1200}
            title={modalTitle}
            visible={isModalVisible}
            okButtonProps={!isNewLink && { htmlType: "submit", form: "new-account-form" }}
            onCancel={() => {
                handleCancel()
            }}
            className="gs-modal link-account-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            onOk={() => {
                if (isNewLink) {
                    handleSaveLink(newLinkList);
                }
            }}
            centered
        >
            <Form
                form={form}
                name="new-account-form"
                className={isNewLink ? "form-new-account" : ""}
                onFinish={(data) => {
                    setEditDisebled(false)
                    handleSubmit(data)
                }}
                layout="vertical"
            >
                <div className="fields-container">
                    <div className="input-content input-parameters" style={{ width: 250, minWidth: 250 }}>
                        <Form.Item
                            name="account"
                            label={i18n.t<string>("budget.permissions.accounting")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                id="input-currency"
                                style={{ width: 250 }}
                                loading={isFetchingAccountOption}
                                options={accountValueOption}
                                onChange={() => {
                                    onChangeAccountOption();
                                    validateKey();
                                }}
                                showSearch={true}
                                optionFilterProp={"children"}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            />
                        </Form.Item>
                    </div>
                    <div className="input-content input-parameters" style={{ width: 250, minWidth: 250 }}>
                        <Form.Item
                            name="fixedExpenseAccount"
                            label={i18n.t<string>("linkAccount.fixed_expense_account")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Select
                                id="input-currency"
                                style={{ width: 250 }}
                                loading={isFetchingFixedExpenseData}
                                disabled={isFetchingFixedExpenseData}
                                options={fixedExpenseOption}
                                onChange={validateKey}
                                {...selectProps}
                            />
                        </Form.Item>
                    </div>
                    <div className="input-content input-parameters" style={{ width: 250, minWidth: 250 }}>
                        <Form.Item
                            name="costCategoryLink"
                            label={i18n.t<string>("linkAccount.cost_category_link")}
                            rules={[{ required: false, message: i18n.t<string>("required_field") }]}
                        >
                            <Cascader
                                style={{ minWidth: 250, width: 250 }}
                                options={costCategoryOption}
                                multiple
                                maxTagCount="responsive"
                                loading={isFetchingCostCategoryOption}
                                className="cost-category-cascader"
                                onChange={validateKey}
                                disabled={isCostCategoryDisabled && flexFieldValuesData.length == 0}
                            />
                        </Form.Item>
                    </div>
                    {
                        flexFieldValuesData.length > 0 &&
                            <div className="input-content input-parameters" style={{ width: 250, minWidth: 250 }}>
                                <Form.Item
                                    name="flexFieldValueLink"
                                    label={flexFieldValuesData[0]?.flexFieldDescription}
                                    rules={[{ required: flexFieldValuesData.length > 0, message: i18n.t<string>("required_field") }]}
                                >
                                <Select
                                        style={{ width: 250 }}
                                        loading={isFetchingFlexFieldOptions}
                                        disabled={isFetchingFlexFieldOptions || isFlexFieldDisabled}
                                        options={flexFieldValuesOptions}
                                        {...selectProps}
                                    />
                                </Form.Item>
                            </div>
                    }
                    
                </div>
                {isNewLink &&
                    <Button
                        style={{ marginTop: -10, marginLeft: "auto" }}
                        type="default"
                        htmlType="submit"
                        className="gs-secondary-button"
                        icon={<Icon icon="akar-icons:circle-plus-fill" />}
                    >
                        {i18n.t('addToList')}
                    </Button>}
            </Form>
            {isNewLink &&
                (newLinkList.length > 0 ?
                    <>
                        <div className="new-account-table-head">
                            <Row align="middle" gutter={2}>
                                <Col span={7}>{i18n.t<string>("budget.permissions.accounting")}</Col>
                                <Col span={7}>{i18n.t<string>("linkAccount.fixed_expense_account")}</Col>
                                <Col span={6}>{i18n.t<string>("linkAccount.cost_category_link")}</Col>
                                <Col span={1} />
                                <Col span={1} />
                            </Row>
                        </div>
                        <div className="new-account-table-body">
                            {newLinkList.map((link) => {
                                return (
                                    <Row key={link.key} align="middle" gutter={2}>
                                        <Col span={7}>{findAccountLabel(link.account)}</Col>
                                        <Col span={7}>{findFixedExpenseLabel(link.fixedExpenseAccount)}</Col>
                                        <Col span={6}>{link.costCategoryLink.map(item => getCostCateoryDescription(item[0])).join("; ")}</Col>
                                        <Col span={1}>
                                            <Button
                                                disabled={editDisebled}
                                                onClick={() => handleEditListItem(link)}
                                                icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Icon
                                                className="delete-icon"
                                                onClick={() => handleDeleteListItem(link.key)}
                                                icon="fa6-solid:trash"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                    :
                    <ImageBox />
                )
            }
        </Modal>
    );
}