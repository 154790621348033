import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Key, useEffect, useState } from "react";
import { IAllocationTableData, IAllocationTableProps } from "../IAllocationParameterization";
import i18n from "util/base/i18n";
import { TableRowSelection } from "antd/lib/table/interface";
import { TopButtons } from "components/topButtons/TopButtons";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

export default function AllocationTable({
	selectedRowKeys,
	setSelectedRowKeys,
	setIsModalOpen,
	setAllocationIdForEdit,
	isModalOpen
}: IAllocationTableProps) {
	const [tableData, setTableData] = useState<IAllocationTableData[]>([]);
	const [tableDataFiltered, setTableDataFiltered] = useState<IAllocationTableData[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const columns: ColumnsType<IAllocationTableData> = [
		{
			dataIndex: "externalCode",
			key: "externalCode",
			title: i18n.t("code"),
			width: "30%"
		},
		{
			dataIndex: "description",
			key: "description",
			title: i18n.t("description")
		},
	]

	const rowSelection: TableRowSelection<IAllocationTableData> = {
		selectedRowKeys,
		onChange: (key: number[]) => {
			setSelectedRowKeys(key);
		}
	}

	function onLoadTableData(data: IAllocationTableData[]) {
		setTableData(data);
		setIsLoading(false);
	}

	function getTableData() {
		if (isModalOpen) return;
		setIsLoading(true);
		setSelectedRowKeys([]);
		setAllocationIdForEdit(null);
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/human-resources/allocation",
		}, onLoadTableData)
	}

	function handleDelete() {
		setIsLoading(true);
		ServiceCaller.doRequest({
			type: RequestType.DELETE,
			url: `human-resources/allocation?ids=${selectedRowKeys}`
		}, getTableData)
	}

	useEffect(() => {
		setTableDataFiltered(tableData);
	}, [tableData])

	useEffect(() => {
		getTableData();
	}, [isModalOpen])

	return (
		<>
			<TopButtons
				mainButtonTitle={i18n.t("human_resources.allocate_resource")}
				handleNew={() => setIsModalOpen(true)}
				handleEdit={() => {
					const row: IAllocationTableData = tableData.find(({ id }) => id === selectedRowKeys[0]);
					setAllocationIdForEdit(row);
					setIsModalOpen(true);
				}}
				handleDelete={handleDelete}
				isDeletable={selectedRowKeys.length > 0}
				isEditable={selectedRowKeys.length === 1}
				multipleSearch={{
					options: [
						{ description: "externalCode", i18nString: "code" },
						{ description: "description", i18nString: "description" },
					],
					setTableData: setTableDataFiltered,
					tableData
				}}
			/>
			<div className="table-content">
				<Table
					className="gs-table"
					columns={columns}
					dataSource={tableDataFiltered}
					rowSelection={rowSelection}
					rowKey={"id"}
					pagination={false}
					loading={isLoading}
				/>
			</div>
		</>
	)
}