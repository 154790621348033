import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ICurrencyInput } from "./ICurrencyInput";

export default function CurrencyInput({
  className,
  value,
  form,
  name,
  onChange,
  disabled
}: ICurrencyInput) {
  const [decimalSeparator, setDecimalSeparator] = useState(null);
  const [groupSeparator, setGroupSeparator] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [installmentsValue, setInstallmentsValue] = useState("");

  const handleValueChange = ({ floatValue }) => {
    form.setFieldsValue({ [name]: floatValue });
    form.validateFields([name]);
  };

  useEffect(() => {
    ServiceCaller.doRequest(
      {
        useProxy: false,
        type: RequestType.GET,
        url: "/getUserCurrencyFormat",
      },
      onLoadUser.bind(this)
    );
  }, []);

  const handleNumericFormatChange = (values) => {
    const floatValue = values.floatValue;
    setInstallmentsValue((floatValue != null ? floatValue.toString() : "0"));
    onChange(values);
    handleValueChange(values);
  };

  function onLoadUser(data) {
    setDecimalSeparator(data.decimalSeparator);
    setGroupSeparator(data.groupSeparator);
    setIsLoading(false);
  }

  return (
    !isLoading && (
      <NumericFormat
        value={value}
        className={className}
        decimalSeparator={decimalSeparator}
        thousandSeparator={groupSeparator}
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale={true}
        onValueChange={handleNumericFormatChange}
        disabled={disabled}
      />
    )
  );
}
