import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from "./useCachedQuery";
import { RequestType } from "util/service/IServiceCaller";

type ProfessionalCategoryOptions = {
    label: string;
    value: number;
    active: boolean;
    externalCode: string;
}

export function useProfessionalCategoryOptions(queryProps = {} as UseQueryOptions<ProfessionalCategoryOptions[]>) {
    const queryInfo = useCachedQuery<ProfessionalCategoryOptions[]>(
        ['professional-category-options'],
        {
            type: RequestType.GET,
            url: '/human-resources/professional-category/findAllWithSecurity?locale={locale}&client={client}&user={user}&scenario={scenario}&organization={organization}',
        },
        queryProps,
        (data) => data.map((item) => ({
            label: item.name,
            value: item.id,
            active: item.active,
            externalCode: item.externalCode
        }))
    )
    return queryInfo;
}

