import { Button, Col, Row } from "antd";
import { IFontStylePickerProps, ILevelStyle } from "../../../IRegistrationSalesReports";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";


export default function FontStylePicker({
	isBoldSelected,
	isItalicSelected,
	isScratchedSelected,
	isUnderlinedSelected,
	setIsBoldSelected,
	setIsItalicSelected,
	setIsScratchedSelected,
	setIsUnderlinedSelected,
}: IFontStylePickerProps) {

	function putClassIsSelected(selected: boolean) {
		return selected ? "active" : "";
	}

	return (
		<Row className="font-style-content">
			<Col className={putClassIsSelected(isBoldSelected)}>
				<Button onClick={() => setIsBoldSelected(state => !state)} type="text" icon={<Icon icon="ooui:bold-b" />} />
			</Col>
			<Col className={putClassIsSelected(isItalicSelected)}>
				<Button onClick={() => setIsItalicSelected(state => !state)} type="text" icon={<Icon icon="tabler:italic" />} />
			</Col>
			<Col className={putClassIsSelected(isUnderlinedSelected)}>
				<Button onClick={() => setIsUnderlinedSelected(state => !state)} type="text" icon={<Icon icon="material-symbols:format-underlined" />} />
			</Col>
			<Col className={putClassIsSelected(isScratchedSelected)}>
				<Button onClick={() => setIsScratchedSelected(state => !state)} type="text" icon={<Icon icon="mingcute:strikethrough-fill" />} />
			</Col>
		</Row>
	)
}