import { Key, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Button, Form, Popconfirm, Tooltip } from 'antd'
import i18n from 'util/base/i18n';
import { Icon } from '@iconify/react';
import moment from "moment";

import { TopButtons } from 'components/topButtons/TopButtons';
import { CustomButtons } from 'components/topButtons/ITopButtons';
import { MessageType, RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Notification } from "components/notification/Notification";

import { ScenarioProjectionModal } from './components/scenarioProjectionModal/ScenarioProjectionModal';
import { ScenarioProjectionTable } from './components/scenarioProjectionTable/ScenarioProjectionTable';
import { AverageCostModal } from './components/averageCostModal/AverageCostModal';

import { SelectedScenarioProjections, ValueForm, DataType } from './IScenarioProjection';

import './ScenarioProjectionStyles.sass';
import { ColumnsType } from 'antd/lib/table';
import { handleUTCDate } from 'module/leaseProjection/functions/handleUTCDate';
import { Loading } from 'components/loading/Loading';
import { useUserContext } from "context/UserContext";

import { usePermissionContext } from 'context/PermissionContext';
import ChecklistScenarioModal from './components/checklistScenarioModal/ChecklistScenarioModal';

export default function ScenarioProjection() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isScenarioProjectionModalVisible, setIsScenarioProjectionModalVisible] = useState(false);
  const [isAverageCostModalVisible, setIsAverageCostModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isNewScenario, setIsNewScenario] = useState(true);
  const [selectedRows, setSelectedRows] = useState<SelectedScenarioProjections[]>([]);
  const [businessUnitsTable, setBusinessUnitsTable] = useState({ scenarioId: null, businessUnits: [] });
  const [isLoadingScenarioModal, setIsLoadingScenarioModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterdTableData, setFilterdTableData] = useState([]);
  const { setFunctionalityPermissions, functionalityPermissions } = usePermissionContext();
  const [isChecklistModalVisible, setIsChecklistModalVisible] = useState(false);

  const { onLoadUserInfo } = useUserContext();

  useEffect(() => {
    setFilterdTableData(tableData);
  }, [tableData]);

  function getScenarioProjectionList() {
    setIsFetching(true);
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: "/projectionlease/scenario/list"
    }, onLoadScenariosTableData.bind(this));
  }

  function onLoadScenariosTableData(data) {
    const dataWithKey = data.map(obj => ({ ...obj, key: obj.id, id: undefined }));
    setTableData(dataWithKey)
    setIsFetching(false);
  };

  function getPermissions() {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: "/projectionlease/scenario"
    }, onLoadPermissions.bind(this));
  }

  function onLoadPermissions(permList) {
    setFunctionalityPermissions(permList);
  }

  useEffect(() => {
    getPermissions()
    getScenarioProjectionList()
  }, [location]);

  function findScenarioById(id) {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: `/projectionlease/scenario/find/${id}`
    }, onLoadFindScenarioById.bind(this));
  }

  function onLoadFindScenarioById(data) {
    form.setFieldsValue({
      description: data.description,
      externalCode: data.externalCode,
      scenarioBase: data.baseScenario.name,
      indexer: data.indexer.name,
      baseDate: moment(handleUTCDate(data.baseDate)),
      accountingDate: moment(handleUTCDate(data.accountingDate)),
      projectionDate: moment(handleUTCDate(data.projectionDate))
    })

    setBusinessUnitsTable({
      scenarioId: data.baseScenario.id,
      businessUnits: data.businessUnits.map(businessUnit => businessUnit.id)
    })

  }

  function handleOpenModal(isNewScenario: boolean) {
    if (isNewScenario) {
      setIsNewScenario(true);
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setBusinessUnitsTable({ scenarioId: null, businessUnits: [] })
      form.resetFields();
    } else {
      findScenarioById(selectedRowKeys[0])
      setIsNewScenario(false);
    }
    setIsScenarioProjectionModalVisible(true);
  }

  function handleSaveScenario(scenarioToSave: ValueForm) {
    scenarioToSave.baseDate = moment(scenarioToSave.baseDate).endOf('month').startOf('day').valueOf();
    scenarioToSave.accountingDate = moment(scenarioToSave.accountingDate).endOf('month').startOf('day').valueOf();
    scenarioToSave.projectionDate = moment(scenarioToSave.projectionDate).endOf('month').startOf('day').valueOf();

    setIsLoadingScenarioModal(true)

    if (isNewScenario) {
      ServiceCaller.doRequest({
        type: RequestType.POST,
        useProxy: false,
        url: "/projectionlease/scenario/create",
        params: scenarioToSave,
      }, function (response) {
        onSave(response, i18n.t<string>("successfully_saved"))
      });
    } else {
      const scenarioToEdit = { ...scenarioToSave, id: selectedRowKeys[0] }

      ServiceCaller.doRequest({
        type: RequestType.POST,
        useProxy: false,
        url: "/projectionlease/scenario/update",
        params: scenarioToEdit,
      }, function (response) {
        onSave(response, i18n.t<string>("successfully_saved"))
      });
    }
  }

  function onSave(response, message) {
    if (response.success) {
      Notification({
        type: 'success',
        message: message
      });

      form.resetFields();

      setSelectedRowKeys([]);
      setSelectedRows([]);

      setIsScenarioProjectionModalVisible(false);
      getScenarioProjectionList()
    } else {
      Notification({
        type: 'warning',
        message: response.message
      });
    }

    setIsLoadingScenarioModal(false);
  };

  function onSelectRowChange(selectedRowKeys: Key[], selectedRows: SelectedScenarioProjections[]) {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  function handleCloseTemplateModal() {
    if (!isLoading) {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      form.resetFields();
      setIsScenarioProjectionModalVisible(false);
    }
  }

  function handleCloseCostModal() {
    setIsAverageCostModalVisible(false);
  }

  function handleChecklistModal() {
    setIsChecklistModalVisible(false);
  }

  const isDeletable =
    selectedRows.length > 0 && functionalityPermissions.remove &&
    selectedRows.every(row => ['RELEASED', 'EXPORTED'].includes(row.situation));

  const isNew = tableData.length < 12 && functionalityPermissions.new

  const isAverageCostButtonAble = selectedRows.length === 1 && selectedRows.every(row => ['GOING', 'RELEASED'].includes(row.situation));

  const isExportButtonAble = selectedRows.length > 0 && selectedRows.every(row => ['ACCOUNTED'].includes(row.situation));

  const isFinishSimulationButtonAble = selectedRows.length === 1 && selectedRows.every(row => ['RELEASED', 'FINISHED_SIMULATION'].includes(row.situation));

  const finishSimulationConfirmTitle = selectedRows[0]?.situation === 'RELEASED' ? i18n.t<string>('want_to_finish_simulation') : i18n.t<string>('release_scenario');

  const finishSimulationTooltip = selectedRows[0]?.situation === 'RELEASED' ? i18n.t<string>('finish_simulation') : i18n.t<string>('release_scenario_tooltip');

  const isAbleToPreviewAccounting = selectedRows.length === 1 && ['RELEASED', 'FINISHED_SIMULATION'].includes(selectedRows[0].situation);

  const isChecklistAble = (selectedRows.length === 1) && !['GOING'].includes(selectedRows[0].situation);

  const isMainButtonAble = !filterdTableData.some(row => row.situation === 'GOING') && isNew;

  const hasPermissionToAccount = functionalityPermissions.account;
  const hasPermissionToRevert = functionalityPermissions.revert;

  const isAccountable = selectedRows.length === 1 && ['FINISHED_SIMULATION'].includes(selectedRows[0].situation);
  const isReversible = selectedRows.length === 1 && ['ACCOUNTED', 'EXPORTED'].includes(selectedRows[0].situation);

  let showAccountBtn = hasPermissionToAccount && ((!hasPermissionToRevert || isAccountable && !isReversible) || (hasPermissionToRevert && !isAccountable && !isReversible));
  let showRevertBtn = hasPermissionToRevert && (!hasPermissionToAccount || (!isAccountable && isReversible));

  if (!hasPermissionToAccount && !hasPermissionToRevert) {
    showAccountBtn = false
    showRevertBtn = false
  }

  const customButtonsList = [
    functionalityPermissions.finishsimulation && {
      icon: <Popconfirm
        placement="bottom"
        title={finishSimulationConfirmTitle}
        onConfirm={handleFinishSimulation}
        disabled={!isFinishSimulationButtonAble}
        okText={i18n.t<string>("yes")}
        cancelText={i18n.t<string>("cancel")}
        okButtonProps={{ danger: true, className: 'popconfirm-delete-button' }}
      >
        <Button
          disabled={!isFinishSimulationButtonAble}
          icon={<Icon icon="fluent:clipboard-checkmark-16-regular" />}
        />
      </Popconfirm>,
      disabled: !isFinishSimulationButtonAble,
      toolTip: finishSimulationTooltip,
      className: 'finish-simulation-btn'
    },
    functionalityPermissions.accountingpreview && {
      onClick: handleAccountingPreview,
      icon: <Icon icon="fluent:notebook-eye-20-regular" />,
      disabled: !isAbleToPreviewAccounting,
      toolTip: i18n.t<string>('calculate_accounting_preview')
    },
    showAccountBtn && {
      onClick: handleAccounting,
      icon: <Icon icon="mdi:calculator" />,
      disabled: !isAccountable,
      toolTip: i18n.t<string>('to_account_for'),
    },
    showRevertBtn && {
      onClick: handleRevert,
      icon: <Icon icon="mdi:calculator" />,
      disabled: !isReversible,
      toolTip: i18n.t<string>('reverse_accounting'),
    },
    functionalityPermissions.export && {
      onClick: handleExportAccounting,
      icon: <Icon icon="uil:file-export" />,
      toolTip: i18n.t<string>('export'),
      disabled: !isExportButtonAble
    },
    functionalityPermissions.averagecost && {
      onClick: handleAverageCostModal,
      icon: <Icon icon="fluent:data-trending-24-filled" />,
      disabled: !isAverageCostButtonAble,
      toolTip: i18n.t<string>('projection_lease.average_cost_table'),
    },
    {
      onClick: handleChecklistScenario,
      icon: <Icon icon="ci:list-check" />,
      disabled: !isChecklistAble,
      toolTip: i18n.t<string>('scenario_checklist')
    }
  ].filter(Boolean) as CustomButtons[];

  function handleChecklistScenario() {
    setIsChecklistModalVisible(true)
  }

  function handleFinishSimulation() {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.POST,
      url: "/projectionlease/scenario/finishSimulation",
      params: selectedRowKeys[0]
    }, () => {
      getScenarioProjectionList()
      Notification({
        type: 'success',
        message: selectedRows[0]?.situation === 'RELEASED' ? i18n.t<string>('simulation_finished_success') : i18n.t<string>('scenario_released_simulation')
      })
      setSelectedRowKeys([])
      setSelectedRows([])
    });
  }

  function handleAccountingPreview() {
    setIsLoading(true)
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.POST,
      url: "/projectionlease/scenario/doAccountingPreview",
      params: selectedRowKeys
    }, onLoadAccounting.bind(this));
  }


  function handleAccounting() {
    setIsLoading(true)
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.POST,
      url: "/projectionlease/scenario/doAccounting",
      params: selectedRowKeys
    }, onLoadAccounting.bind(this));
  }

  function handleRevert() {
    setIsLoading(true)
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.POST,
      url: "/projectionlease/scenario/revertAccounting",
      params: selectedRowKeys
    }, onLoadAccounting.bind(this));
  }

  function onLoadAccounting(response) {
    setIsLoading(false)
    Notification({
      type: response.success ? 'success' : 'error',
      message: response.message
    });

    setSelectedRowKeys([]);
    setSelectedRows([]);
    getScenarioProjectionList();
  }

  function handleExportAccounting() {
    setIsLoading(true)

    ServiceCaller.doSendMessage({
      messageType: MessageType.EXPORT_ACCOUNTING,
      params: selectedRowKeys
    });

    setTimeout(() => {
      getScenarioProjectionList();
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setIsLoading(false)
    }, 3500)
  }

  function handleAverageCostModal() {
    setIsAverageCostModalVisible(true)
  }

  function handleDeleteScenarioProjection() {
    setIsFetching(true)
    ServiceCaller.doRequest({
      type: RequestType.POST,
      url: `/projectionlease/scenario/delete`,
      useProxy: false,
      params: selectedRowKeys
    }, onDelete.bind(this));

    setSelectedRowKeys([]);
    setSelectedRows([]);
  }

  function onDelete(data) {
    Notification({
      type: 'success',
      message: i18n.t<string>("successfully_deleted")
    });

    form.resetFields();
    getScenarioProjectionList();
    if (data.success && data.scenarioLogged) {
      reloadUserInfo();
    }
  };

  function reloadUserInfo() {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: "/user/info"
    }, onLoadUserInfo.bind(this));
  }

  const tableColumns: ColumnsType<DataType> = [
    {
      title: i18n.t<string>('external_code'),
      dataIndex: "externalCode",
      key: "externalCode",
      align: "left",
      width: 200,
      sorter: (a, b) => (a.externalCode || "").localeCompare(b.externalCode || ""),
    },
    {
      title: i18n.t<string>('scenario_name'),
      dataIndex: "description",
      key: "description",
      align: "left",
      width: 300,
      sorter: (a, b) => (a.description || "").localeCompare(b.description || ""),
    },
    {
      title: i18n.t<string>('contracts_number'),
      dataIndex: "numberOfContracts",
      key: "numberOfContracts",
      align: "left",
      width: 200,
      sorter: (a, b) => (a.numberOfContracts || 0) - (b.numberOfContracts || 0),
    },
    {
      title: i18n.t<string>('origin'),
      dataIndex: "origin",
      key: "origin",
      align: "left",
      width: 300,
      sorter: (a, b) => (a.origin || "").localeCompare(b.origin || ""),
    },
    {
      title: i18n.t<string>('projection_lease.origin_base_date'),
      dataIndex: "baseDate",
      key: "baseDate",
      align: "center",
      width: 200,
      sorter: (a, b) => moment(a.baseDate).diff(b.baseDate) ?? 0,
      render: (date) => (date ? moment(handleUTCDate(date)).format('MM-YYYY') : ""),
    },
    {
      title: i18n.t<string>('projection_lease.projection_date'),
      dataIndex: "projectionDate",
      key: "projectionDate",
      align: "center",
      width: 200,
      sorter: (a, b) => moment(a.projectionDate).diff(b.projectionDate) ?? 0,
      render: (date) => (date ? moment(handleUTCDate(date)).format('MM-YYYY') : ""),
    },
    {
      title: i18n.t<string>('responsible'),
      dataIndex: "responsible",
      key: "responsible",
      align: "left",
      width: 200,
      sorter: (a, b) => (a.responsible || "").localeCompare(b.responsible || ""),
    },
    {
      title: i18n.t<string>('indexer'),
      dataIndex: "indexer",
      key: "indexer",
      align: "left",
      width: 200,
      sorter: (a, b) => (a.indexer || "").localeCompare(b.indexer || ""),
      render: (indexer: string) => (
        <Tooltip title={indexer}>
          <div style={{ display: 'inline-block', margin: '0 4px' }}>
            {indexer && indexer.length > 15 ? `${indexer.slice(0, 15)}...` : indexer}
          </div>
        </Tooltip>
      ),
    },
    {
      title: i18n.t<string>('situation'),
      dataIndex: "situation",
      key: "situation",
      align: "left",
      width: 400,
      sorter: (a, b) => (a.situation || "").localeCompare(b.situation || ""),
      render: (situation: string) => {
        let color = "";
        let bgColor = "";
        switch (situation) {
          case "GOING":
            color = "rgba(0, 101, 179, 1)";
            bgColor = "rgba(232, 242, 249, 1)";
            break;
          case "RELEASED":
            color = "rgba(46, 182, 104, 1)";
            bgColor = "rgba(46, 182, 104, 0.12)";
            break;
          default:
            color = "rgba(92, 92, 92, 1)";
            bgColor = "rgba(241, 242, 243, 1)";
        }
        return (
          <span
            className="situation-color-span"
            style={{
              color,
              backgroundColor: bgColor,
              padding: '0.25rem 0.5rem',
              borderRadius: '4px',
              whiteSpace: 'nowrap'
            }}
          >
            {situation && i18n.t<string>(`scenario_projection_status.${situation.toLowerCase()}`)}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <h1 className='scenario-projection-title'>
        {i18n.t<string>('projection_lease.scenario_simulation')}
      </h1>

      <TopButtons
        mainButtonTitle={i18n.t<string>('new_scenario')}
        handleNew={() => handleOpenModal(true)}
        disabledMainButton={!isMainButtonAble}
        handleEdit={() => handleOpenModal(false)}
        handleDelete={handleDeleteScenarioProjection}
        multipleSearch={{
          tableData: tableData,
          setTableData: setFilterdTableData,
          options: [
            { i18nString: 'external_code', description: 'externalCode' },
            { i18nString: 'scenario_name', description: 'description' },
            { i18nString: 'origin', description: 'origin' },
            { i18nString: 'responsible', description: 'responsible' },
          ]
        }}
        searchPlaceholder={i18n.t<string>('loading')}
        isDeletable={isDeletable}
        isEditable={selectedRows.length === 1 && functionalityPermissions.edit}
        customButtons={customButtonsList}
      />
      <main id="scenario-main">
        {isLoading &&
          <div className='loading-wrapper'>
            <Loading caption={i18n.t<string>('loading')} />
          </div>
        }
        <ScenarioProjectionModal
          form={form}
          isNewScenario={isNewScenario}
          isModalVisible={isScenarioProjectionModalVisible}
          handleCancel={handleCloseTemplateModal}
          handleSubmit={handleSaveScenario}
          businessUnitsTable={businessUnitsTable}
          isLoading={isLoadingScenarioModal}
        />
        <ScenarioProjectionTable
          isFetching={isFetching}
          tableData={filterdTableData}
          selectedRowKeys={selectedRowKeys}
          onChange={onSelectRowChange}
          tableColumns={tableColumns}
        />
        <AverageCostModal
          isModalVisible={isAverageCostModalVisible}
          handleCancel={handleCloseCostModal}
          selectedRowKeys={selectedRowKeys}
        />
        <ChecklistScenarioModal
          isModalVisible={isChecklistModalVisible}
          handleCancel={handleChecklistModal}
          selectedScenarioId={selectedRowKeys[0]}
        />
      </main>
    </>
  )
}