import "../Card.sass";

import {
	Button,
	Card,
	Space,
	Tooltip,
	Typography,
	Table,
	Tag,
	Popover
} from "antd";

import { Icon } from "@iconify/react";

import i18n from "util/base/i18n";
import { Lookup } from "components";

import {
	IConversion,
	IConversionRate,
	ICurrency,
	ICurrencyProps,
} from "./ICurrency";
import { RequestType } from "util/service/IServiceCaller";

import { ServiceCaller } from "util/service/ServiceCaller";

import CurrencyConfig from "./config/CurrencyConfig";
import { FormattedNumber } from "react-intl";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { useEffect, useState } from "react";

export function CurrencyCard({retractAsider, configId, originCurrency, onChangeCurrency, currenciesToConversion, countryOrigin}: ICurrencyProps) {
	const [currentCurrency, setCurrentCurrency] = useState<ICurrency>(null)
	const [selectedCurrenciesToConversion, setSelectedCurrenciesToConversion] = useState<number[]>([])
	const [loading, setLoading] = useState(true)
	const [loadingCurrencies, setLoadingCurrencies] = useState(false)
	const [currencies, setCurrencies] = useState<ICurrency[]>(null)
	const [openedConfig, setOpenedConfig] = useState(false)
	const [tableData, setTableData] = useState<IConversion[]>([])

	const columns = [{
		key: "currency",
		render: (value, record) => buildCurrencyName(value, record)
	}, {
		width: 60,
		key: "buy-rate",
		title: i18n.t<string>("buy"),
		dataIndex: "rateBuy",
		render: (value) => buildRateTag(value)
	}, {
		width: 60,
		title: i18n.t<string>("sell"),
		dataIndex: "rateSell",
		key: "sell-rate",
		render: (value) => buildRateTag(value)
	}];
	useEffect(() => {
		if (currentCurrency == null && originCurrency !== 0 && !loadingCurrencies) {
			loadCurrencies();
		}
	}, [currentCurrency, originCurrency, loadingCurrencies]);

	useEffect(() => {
		if (currentCurrency != null && currentCurrency.id !== originCurrency) {
			setCurrentCurrency(currencies.find((currency) => currency.id === originCurrency))
		}
	}, [currentCurrency, originCurrency, currencies]);

	useEffect(() => {
		if (selectedCurrenciesToConversion.length === 0) {
			setSelectedCurrenciesToConversion(currenciesToConversion)
			setTableData(buildDefaultConversions(currenciesToConversion))
		}
	}, [currenciesToConversion]);

	useEffect(loadConversions, [countryOrigin, currentCurrency, selectedCurrenciesToConversion]);

	function loadCurrencies(): void {
		setLoadingCurrencies(true)
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/monolith/currency?client={client}&locale={locale}"
		}, onLoadCurrencies);
	}

	function onLoadCurrencies(currencies: Array<ICurrency>): void {
		let currentCurrency: ICurrency;

		currencies = currencies.map(currency => {
			if (originCurrency === currency.id) {
				currentCurrency = currency;
			}

			let image: string;

			if (currency.image) {
				image = `${process.env.REACT_APP_GS_ROOT_PATH}${process.env.REACT_APP_GS_BASE_PATH}/renderer/image/${currency.image}`;
			} else {
				image = `${process.env.REACT_APP_GS_ROOT_PATH}${process.env.REACT_APP_GS_BASE_PATH}/images/global/imagemMoedaDef.png`;
			}

			return {
				...currency,
				image
			};
		}).sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB));
		setLoading(false)
		setCurrencies(currencies)
		setCurrentCurrency(currentCurrency)
		setLoading(false)
		loadConversions()
	}

	function buildDefaultConversions(currenciesToConversion: number[]): IConversion[] {
		return currenciesToConversion.map(currency => {
			return {
				id: currency,
				rateBuy: {
					value: 0,
					increased: true
				},
				rateSell: {
					value: 0,
					increased: true
				}
			}
		});
	}

	function loadConversions(): void {
		if (countryOrigin !== null && currentCurrency !== null && selectedCurrenciesToConversion.length !== 0) {
			const originsQuery: string = selectedCurrenciesToConversion.map(currencyToConversion => `origins=${currencyToConversion}`).join("&");

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/dashboard-analytics/widget/financing/currency-conversions?${originsQuery}&destiny=${currentCurrency.id}&scenario={scenario}&client={client}&countryOrigin=${countryOrigin}`
			}, onLoadConversion);
		}
	}

	function onLoadConversion(conversions: Array<IConversion>): void {
		setTableData(prevState => prevState.map(({ id }) => {
				let conversion = conversions.find(conversion => conversion.id === id);
				if (!conversion) {
					conversion = {
						id,
						rateBuy: {
							increased: true,
							value: 0
						}, rateSell: {
							increased: true,
							value: 0
						}
					}
				}

				return {
					id,
					rateBuy: conversion.rateBuy,
					rateSell: conversion.rateSell
				}
			})
		)
	}

	function buildCurrencyName(_: any, { id }) {
		const currency = currencies?.find(currency => currency.id === id);

		return <>
			<Typography.Text ellipsis={true}>
				{currency?.isoCode ?? null}
			</Typography.Text>
		</>
	}

	function buildRateTag({ value, increased }: IConversionRate) {
		let arrowIcon: React.ReactElement;

		if (increased) {
			arrowIcon = <Icon className="gs-font-color-green" icon="akar-icons:arrow-up" />;
		} else {
			arrowIcon = <Icon className="gs-font-color-red" icon="akar-icons:arrow-down" />;
		}

		if (value === 0) {
			value = 1;
		}

		return <>
			<Tag className="white" icon={arrowIcon}>
				<FormattedNumber value={value} style={`currency`} currencyDisplay="symbol" currency={currentCurrency?.isoCode} minimumFractionDigits={4} maximumFractionDigits={4} />
			</Tag>
		</>
	}

	function onChangeCurrencies(newCurrenciesToConversion: Array<number>): void {
		setOpenedConfig(false)
		setSelectedCurrenciesToConversion(newCurrenciesToConversion)
		setTableData(buildDefaultConversions(newCurrenciesToConversion))
	}

	function onChangeConfigModalVisibility(openedConfig: boolean): void {
		if (loading) {
			return;
		}
		setOpenedConfig(openedConfig)
	}

	function buildConfigModal(): React.ReactElement {
		const title: React.ReactElement = <>
			<Space align="center">
				<Icon className="gs-font-color-secondary" icon={"carbon:currency"} fontSize={20} />
				<Typography.Title level={5}>{i18n.t<string>("currencies")}</Typography.Title>
			</Space>
		</>;

		const content: React.ReactElement = <>
			<CurrencyConfig configId={configId} currencies={currencies} selectedCurrencies={selectedCurrenciesToConversion} onChangeCurrencies={onChangeCurrencies} />
		</>;

		return <>
			<Button style={{ border: "none", color: "rgba(0, 0, 0, 0.85)", boxShadow: "none" }}
				icon={<Icon icon={"ant-design:arrow-left-outlined"} />}
				onClick={retractAsider}
				data-html2canvas-ignore="true"
			/>
			<Popover id="currency-config" visible={openedConfig} content={content} title={title} trigger="click" placement="bottomRight" destroyTooltipOnHide={true} onVisibleChange={onChangeConfigModalVisibility} >
				<Tooltip title={i18n.t<string>("configure")}>
					<Button type="text" icon={<Icon icon={"vscode-icons:file-type-light-config"} />} disabled={loading} data-html2canvas-ignore="true" />
				</Tooltip>
			</Popover>
		</>

	}
	
	return <>
		<Card id="currency-card-container" title={
			<Space align="center">
				<Icon className="gs-font-color-secondary" icon={"carbon:currency"} fontSize={20} />
				<Typography.Title level={5}>{i18n.t<string>("currencies")}</Typography.Title>
			</Space>
		} extra={buildConfigModal()} bordered={false} loading={loading}>
			{
				currencies?.length > 0 &&
				<Lookup label="conversion_into" options={{
					data: currencies,
					imageProp: "image"
				}} selected={originCurrency} onSelect={onChangeCurrency} />
			}
			{
				tableData.length > 0 &&
				<Table columns={columns} dataSource={tableData} pagination={false} />
			}
		</Card>
	</>

}
