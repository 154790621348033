import { Space } from "antd";
import { UndoProjectionComponentFilter } from "./UndoProjectionComponentFilter";
import i18n from "util/base/i18n";

export default function UndoProjectionFilter({ listFilters, setListFilters }) {
    return (
        <Space className="filter-period-undo" direction="horizontal">
            <label>{i18n.t<string>('filtering_results_by')}:</label>
            <div className="space-filters-undo">
                {listFilters.map((filter) => {
                    return <UndoProjectionComponentFilter key={filter.key} groupItems={filter.groupItems} listFilters={listFilters} setListFilters={setListFilters} description={filter.description} id={filter.key} />
                })}
            </div>
        </Space >
    )
}