import { FormattedNumber } from "react-intl";
import { CellContent } from "../../CellContent";

interface IGetColumnComparativeProps {
    index: number;
    title: string;
    render: string;
    reportVisualizationFilters: any;
    rowsFromBackend: any;
}

export function getColumnComparative({ index, title, render, reportVisualizationFilters, rowsFromBackend }: IGetColumnComparativeProps) {
    const dataIndex = index + render;

    if (render === 'variablePercente') {
        return {
            title,
            editable: false,
            align: 'center',
            dataIndex,
            width: 85,
            render: (text, record) => {
                let currentRow;
                if(record?.formulaValueType) {
                    currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
                } else if(record.levelKey) {
                    currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
                } else {
                    currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
                }

                const periodCell = currentRow?.columnValues ? currentRow.columnValues.find(cell => cell.period === index) : null;

                let textDecoration = "";

                if (record.style) {
                    if (record.style.isScratched) {
                        textDecoration = textDecoration + "line-through "
                    }

                    if (record.style.isUnderlined) {
                        textDecoration = textDecoration + "underline"
                    }
                }

                record = { ...record, rowStyle: record.levelStyle };

                if (currentRow?.isTitle) {
                    return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
                }

                return (
                    <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                        <FormattedNumber
                            value={(periodCell && periodCell.variablePercente) ? (periodCell.variablePercente / 100) : 0}
                            style={`percent`}
                            minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                            maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        />
                    </CellContent>
                );
            }
        }
    }

    return {
        title,
        editable: false,
        align: 'center',
        dataIndex,
        width: 85,
        render: (text, record) => {
            let currentRow;
            if(record?.formulaValueType) {
                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
            } else if(record.levelKey) {
                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
            } else {
                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
            }

            const periodCell = currentRow?.columnValues ? currentRow.columnValues.find(cell => cell.period === index) : null;

            let textDecoration = (record.style && record.style.isScratched) ? "line-through " : "";

            if (record?.style && record?.style?.isUnderlined) {
                textDecoration += "underline";
            }

            if (currentRow?.isTitle) {
                return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
            }

            return (
                <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={(periodCell && periodCell[render]) ? reportVisualizationFilters.showThousand ? periodCell[render] / 1000 : periodCell[render] : 0}
                        style={`decimal`}
                        currencyDisplay="symbol"
                        currency={record.isoCode}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        },
    };
};
