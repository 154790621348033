import { FormattedNumber } from "react-intl";
import i18n from "util/base/i18n";
import { CellContent } from "../../CellContent";

export function buildColumnComparativeTotalScenarioYears(reportVisualizationFilters) {
    const totalValueBase = {
        title: i18n.t<string>("total_base"),
        dataIndex: 'totalValueBase',
        align: 'center',
        key: 'total',
        fixed: 'right',
        width: 90,
        render: (text, record) => {
            let textDecoration = "";

            if (record?.style?.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record?.style?.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            let renderedValue = 0;
            if(record?.reportValues?.columnValueBeans?.totalValueBase) {
                renderedValue = record.reportValues.columnValueBeans.totalValueBase;
            } else if(record?.columns?.totalValueBase) {
                renderedValue = record.columns.totalValueBase;
            }

            if (reportVisualizationFilters.showThousand) {
                renderedValue = renderedValue / 1000;
            }

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={renderedValue}
                        style={`decimal`}
                        currencyDisplay="symbol"
                        currency={record.isoCode ? record.isoCode : "BRL"}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    }

    const totalVariable = {
        title: i18n.t<string>("variation_percent_b_c"),
        dataIndex: 'totalVariationBaseTarget',
        align: 'center',
        key: 'total',
        fixed: 'right',
        width: 90,
        render: (text, record) => {
            let textDecoration = "";

            if (record?.style?.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record?.style?.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            let renderedValue = 0;
            if (record?.reportValues?.columnValueBeans?.totalVariationBaseTarget) {
                renderedValue = (record.reportValues.columnValueBeans.totalVariationBaseTarget / 100);
            } else if (record?.columns?.totalVariationBaseTarget) {
                renderedValue = (record.columns.totalVariationBaseTarget / 100);
            }

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={renderedValue}
                        style={`percent`}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    }

    const totalValueTarget = {
        title: i18n.t<string>("total_comparative"),
        dataIndex: 'totalValueTarget',
        align: 'center',
        fixed: 'right',
        key: 'total',
        width: 90,
        render: (text, record) => {
            let textDecoration = "";

            if (record?.style?.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record?.style?.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            let renderedValue = 0;
            if(record?.reportValues?.columnValueBeans?.totalValueTarget) {
                renderedValue = record.reportValues.columnValueBeans.totalValueTarget;
            } else if(record?.columns?.totalValueTarget) {
                renderedValue = record.columns.totalValueTarget;
            }

            if (reportVisualizationFilters.showThousand) {
                renderedValue = renderedValue / 1000;
            }

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={renderedValue}
                        style={`decimal`}
                        currencyDisplay="symbol"
                        currency={record.isoCode ? record.isoCode : "BRL"}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    }

    return [totalValueBase, totalVariable, totalValueTarget];
}
