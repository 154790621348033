import { Icon } from "@iconify/react";

export function EqualOp() {
    return (
        <Icon
            icon="mdi:equal"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function NotEqualOp() {
    return (
        <Icon
            icon="mdi:not-equal-variant"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function PlusOp() {
    return (
        <Icon
            icon="akar-icons:plus"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function MinusOp() {
    return (
        <Icon
            icon="akar-icons:minus"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function TimesOp() {
    return (
        <Icon
            icon="akar-icons:cross"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function DivideOp() {
    return (
        <Icon
            icon="ic:baseline-divide"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function LeftParenthesisOp() {
    return (
        <p
            style={{
                textAlign: "center",
                fontSize: 16,
                height: 16,
                width: 16,
                fontWeight: "bold",
                color: "#424242",
            }}
        >
            (
        </p>
    );
}

export function RightParenthesisOp() {
    return (
        <p
            style={{
                textAlign: "center",
                fontSize: 16,
                height: 16,
                width: 16,
                fontWeight: "bold",
                color: "#424242",
            }}
        >
            )
        </p>
    );
}

export function GreaterThanOp() {
    return (
        <Icon
            icon="ic:baseline-greater-than"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function GreaterThanEqualOp() {
    return (
        <Icon
            icon="ic:baseline-greater-than-equal"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function LessThanOp() {
    return (
        <Icon
            icon="ic:baseline-less-than"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

export function LessThanEqualOp() {
    return (
        <Icon
            icon="ic:baseline-less-than-equal"
            rotate={90}
            fontSize={16}
            style={{
                color: "#424242",
            }}
        />
    );
}

const containsNotContainsFromToOpsStyle = {
    background: "#F1F2F3",
    borderRadius: 2,
    padding: "0 8px",
    fontSize: 12,
}

export function ContainsOp() {
    return (
        <div style={containsNotContainsFromToOpsStyle}>
            Contém
        </div>
    );
}

export function NotContainsOp() {
    return (
        <div style={containsNotContainsFromToOpsStyle}>
            Não Contém
        </div>
    );
}

export function FromToOp() {
    return (
        <div style={containsNotContainsFromToOpsStyle}>
            De - Para
        </div>
    );
}
