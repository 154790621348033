import { Button, Divider, Radio } from "antd";
import { useContext, useState } from "react";
import { RevenueContext } from "../../RevenueContext";
import { SelectedViewType } from "../../IRevenue";

import i18n from "util/base/i18n";

export default function ViewModality({
    setIsViewModalityOpen
}) {
    const { handleSelectViewType, selectedViewType, handleHasChangeOnGrid, hasChangeOnGrid, handleIsFetching, handleIsViewTypeChanged } = useContext(RevenueContext);
    const [selectedViewTypeTemp, setSelectedViewType] = useState<SelectedViewType[]>([selectedViewType]);

    const options: SelectedViewType[] = [
        {
            label: i18n.t<string>("planned"),
            value: "PLANNED"
        }, {
            label: i18n.t<string>("accomplished"),
            value: "ACCOMPLISHED"
        }, {
            label: "Forecast",
            value: "FORECAST"
        }];

    const onChange = (e: any) => {
        const view: SelectedViewType[] = options.filter(f => f.value === e.target.value);
        setSelectedViewType(view);
    };

    function saveView() {
        handleSelectViewType(selectedViewTypeTemp);
        closePopover();
        handleHasChangeOnGrid(!hasChangeOnGrid);
        handleIsFetching(true);
        handleIsViewTypeChanged(true);
    }

    function closePopover() {
        setIsViewModalityOpen(false);
    }

    return (
        <>
            <div className="preview-label" style={{
                marginTop: 5
            }}>
                <span style={{ fontSize: 18 }}>
                    {i18n.t<string>("preview")}
                </span>
            </div>
            <Divider style={{ margin: '12px 0' }} />

            <div className="check-group-view"
                style={{
                    paddingBottom: 20
                }}>
                <Radio.Group options={options} onChange={onChange} defaultValue={selectedViewType.value} />
            </div>

            <div className="buttons-select-view"
                style={{
                    gap: "20px !important"
                }}>
                <Button style={{ border: 'none' }} onClick={closePopover}>{i18n.t<string>("cancel")}</Button>
                <Button style={{
                    borderRadius: 4,
                    border: '1px solid var(--primary-primary, #0065B3)',
                    background: 'var(--primary-primary, #0065B3)',
                    width: 70
                }} type="primary" onClick={saveView}>{i18n.t<string>("save")}</Button>
            </div>
        </>
    )
}