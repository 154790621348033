import { Icon } from "@iconify/react";
import { Tooltip } from "antd";
import moment from "moment";
import i18n from "util/base/i18n";
import { formatNumber } from "util/formatNumber";
import { ModuleType, Options } from "util/types/types";
import { BudgetPeriodDates, BudgetGridData, Column, RowType, ViewModeSelected } from "../../../IFixedExpense";
import { verifyBudgetPeriod } from "util/functions/verifyBudgetPeriod";
import ElementUtil from "util/ElementUtil";
import { createTableTotalsRow } from "../functions/createTableTotalsRow";

export function createGridColumns(
    budgetGridData: BudgetGridData[],
    openCommentModal: Function,
    onOpenDetailModal: Function,
    viewModeSelected: ViewModeSelected[],
    budgetPeriodDates: BudgetPeriodDates,
    monthArray: string[],
    indexerOptions: Options[],
    spendingPackage: Options[],
    isQueryByPackage: boolean
) {
    let valuesColumn: Column[] = [];
    const updatedMonthAmount = monthArray.length;

    const totals = createTableTotalsRow(budgetGridData, viewModeSelected);

    const descriptionWidth = budgetGridData.reduce((acc, value) => {
        const width = Math.ceil(ElementUtil.getWidthOfText(value.accounting, "normal 14px") + 20);
        if (width > acc) {
            return width;
        }
        return acc;
    }, 0);

    monthArray.forEach((month) => {
        const momentMonth = moment(month, "YYYY-MM");
        let isEditable = true;
        let columnClassName = "";
        const budDate = budgetPeriodDates.period.find((budDate) => budDate.year === momentMonth.year());
        if (budDate) {
            isEditable = verifyBudgetPeriod(
                budDate,
                momentMonth,
                budgetPeriodDates.localDate,
                ModuleType.EXPENSERESOURCES
            ).isEditable;
            columnClassName = verifyBudgetPeriod(
                budDate,
                momentMonth,
                budgetPeriodDates.localDate,
                ModuleType.EXPENSERESOURCES
            ).className;
        } else {
            isEditable = false;
            columnClassName += " out-of-period-column";
        }

        let monthTotal = totals.find((item) => item.month === month && item.order === 1);
        let monthTotalValue: number;

        if (monthTotal) {
            monthTotalValue = monthTotal.value
        } else {
            monthTotalValue = 0
        }

        const width = Math.ceil(
            ElementUtil.getWidthOfText(
                formatNumber(monthTotalValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                "normal 14px"
            ) + 20
        );
        if (viewModeSelected.length > 0) {
            const titleMonthCell =
                momentMonth.format("MMM YYYY").charAt(0).toUpperCase() + momentMonth.format("MMM YYYY").slice(1);

            const viewColumn: Column[] = [
                {
                    title: i18n.t("budgeted"),
                    align: "center",
                    width: width > 75 ? width : 75,
                    className: columnClassName,
                    editable: isEditable,
                    dataIndex: ["plannedMonths", month],
                    render: (_, record: BudgetGridData) => {
                        const plannedMonth = record.plannedMonths[month];
                        const value =
                            record.rowType === RowType.QUANTITY
                                ? plannedMonth.quantity
                                : record.rowType === RowType.UNITVALUE
                                    ? plannedMonth.unitValue
                                    : plannedMonth.plannedValue;
                        return formatNumber(
                            value,
                            record.rowType !== RowType.QUANTITY && {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }
                        );
                    },
                },
            ];

            viewModeSelected.forEach((viewMode) => {
                const order =
                    viewMode === "realizedValue"
                        ? 3
                        : viewMode === "historicValue"
                            ? 5
                            : viewMode === "apportionmentValue"
                                ? 4
                                : 2;
                const monthTotalValue = totals.find((item) => item.month === month && item.order === order).value;
                const width = Math.ceil(
                    ElementUtil.getWidthOfText(
                        formatNumber(monthTotalValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                        "normal 14px"
                    ) + 20
                );

                const title =
                    viewMode === "realizedValue"
                        ? i18n.t("accomplished")
                        : viewMode === "historicValue"
                            ? i18n.t("historic")
                            : viewMode === "apportionmentValue"
                                ? i18n.t("apportionment")
                                : i18n.t("adjusted");
                viewColumn.push({
                    title,
                    align: "center",
                    className: columnClassName,
                    width: width > 75 ? width : 75,
                    editable: false,
                    dataIndex: ["plannedMonths", month],
                    render: (_, record: BudgetGridData) => {
                        const plannedMonth = record.plannedMonths[month];
                        return formatNumber(
                            plannedMonth[viewMode] ? plannedMonth[viewMode] : 0,
                            record.rowType !== RowType.QUANTITY && {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }
                        );
                    },
                });
            });

            valuesColumn.push({
                title: titleMonthCell,
                align: "center",
                className: columnClassName,
                editable: isEditable,
                dataIndex: ["plannedMonths", month],
                children: [...viewColumn],
            });
        } else {
            const titleMonthCell =
                momentMonth.format("MMM YYYY").charAt(0).toUpperCase() + momentMonth.format("MMM YYYY").slice(1);
            valuesColumn.push({
                editable: isEditable,
                title: titleMonthCell,
                className: columnClassName,
                align: "center",
                shouldCellUpdate(record, prevRecord) {
                    for (const [key, value] of Object.entries(record)) {
                        if (value !== prevRecord[key]) {
                            return true;
                        }
                    }
                    return false;
                },
                width: width > 75 ? width : 75,
                dataIndex: ["plannedMonths", month],
                render: (_, record: BudgetGridData) => {
                    const plannedMonth = record.plannedMonths[month];
                    let value: number;

                    if (record.rowType === RowType.QUANTITY) {
                        value = plannedMonth.quantity;
                    } else if (record.rowType === RowType.UNITVALUE) {
                        value = plannedMonth.unitValue;
                    } else {
                        value = plannedMonth && plannedMonth.plannedValue ? plannedMonth.plannedValue : 0;
                    }

                    return formatNumber(
                        value,
                        record.rowType !== RowType.QUANTITY && { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    );
                },
            });
        }
    });

    const columnData: Column[] = [
        {
            fixed: "left",
            width: descriptionWidth,
            title: i18n.t("accounting_account"),
            dataIndex: "accounting",
            align: "left",
            className: "table-first-column",
            render: (text, record: BudgetGridData) => {
                const indexer = [];
                if (record.budgetOpeningIndexerLink.length > 0) {
                    record.budgetOpeningIndexerLink.forEach((index) => {
                        const indexerLabel = indexerOptions.find((opt) => opt.value === index.indexerId)?.label;
                        indexer.push(
                            <div
                                key={index.indexerId}
                                className="gs-flex align-center"
                                style={{ width: "fit-content" }}
                            >
                                {record.budgetOpeningIndexerLink.length > 1 && (
                                    <span
                                        className="bullet"
                                        style={{ backgroundColor: "#ffffff", marginRight: 8 }}
                                    ></span>
                                )}
                                <span style={{ marginRight: 4, width: "fit-content" }}>{i18n.t("indexer")}</span>
                                <span style={{ marginRight: 4, width: "fit-content" }}>{indexerLabel}</span>
                                <span style={{ marginRight: 4, width: "fit-content" }}>vinculado ao ano de </span>
                                <span>{index.year}</span>
                            </div>
                        );
                    });
                }
                return (
                    <p>
                        <span>{text.includes("_") ? text.substring(0, text.lastIndexOf("_")) : text}</span>
                        <span className="gs-flex align-center">
                            {!record.canEdit && record.rowType === RowType.PLANNED && (
                                <Tooltip
                                    color="rgba(0,0,0,0.9)"
                                    placement="right"
                                    title={i18n.t("fixed_expense_texts.dont_have_permission_to_edit_this_account")}
                                >
                                    <Icon
                                        icon="fluent:edit-off-24-regular"
                                        style={{ color: "#ff4d4f", marginRight: 4, fontSize: 18 }}
                                    />
                                </Tooltip>
                            )}
                            {record.budgetOpening.isAccountLinkedToHR && record.rowType === RowType.PLANNED && (
                                <Tooltip
                                    color="rgba(0,0,0,0.9)"
                                    placement="right"
                                    title={i18n.t("fixed_expense_texts.account_blocked_due_to_links_to_hr_accounts")}
                                >
                                    <Icon
                                        icon="iconamoon:link-light"
                                        style={{ color: "#ff4d4f", marginRight: 4, fontSize: 20 }}
                                    />
                                </Tooltip>
                            )}
                            {record.budgetOpeningIndexerLink?.length > 0 && record.rowType === RowType.PLANNED && (
                                <Tooltip
                                    color="rgba(0,0,0,0.9)"
                                    placement="right"
                                    title={indexer.map((item) => item)}
                                    overlayInnerStyle={{ width: "max-content" }}
                                >
                                    <Icon
                                        style={{ color: "#4F9ACF", fontSize: 19, marginRight: 4 }}
                                        icon="heroicons-outline:receipt-tax"
                                    />
                                </Tooltip>
                            )}
                            {record.standardAccount &&
                                record.standardAccount === record.budgetOpening.accountId &&
                                isQueryByPackage && (
                                    <Tooltip
                                        color="rgba(0,0,0,0.9)"
                                        placement="right"
                                        title={i18n.t("standard_accounting_account")}
                                        overlayInnerStyle={{ width: "max-content" }}
                                    >
                                        <Icon
                                            icon="bitcoin-icons:verify-outline"
                                            style={{
                                                color: "#4F9ACF",
                                                fontSize: 26,
                                                marginRight: 4,
                                                cursor: "default",
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            {record.hasDetail === true && (
                                <Tooltip
                                    color="rgba(0,0,0,0.9)"
                                    placement="right"
                                    title={i18n.t("detail")}
                                    overlayInnerStyle={{ width: "max-content" }}
                                >
                                    <Icon
                                        style={{ color: "#4F9ACF", fontSize: 16, marginRight: 4 }}
                                        icon="fa6-solid:chart-bar"
                                        onClick={() => onOpenDetailModal(record)}
                                    />
                                </Tooltip>
                            )}
                            {record.detailList.length > 0 &&
                                record.rowType === RowType.PLANNED &&
                                record.isOpeningInOtherCurrency && (
                                    <Tooltip
                                        color="rgba(0,0,0,0.9)"
                                        placement="right"
                                        title={i18n.t("see_budgeted_currencies")}
                                        overlayInnerStyle={{ width: "max-content" }}
                                    >
                                        <Icon
                                            style={{ color: "#4F9ACF", fontSize: 20, marginRight: 4 }}
                                            icon="jam:coin"
                                            onClick={() => onOpenDetailModal(record)}
                                        />
                                    </Tooltip>
                                )}
                        </span>
                    </p>
                );
            },
        },
    ];
    const spendingPackageFilter= budgetGridData.filter((item) => item.spendingPackage);
    if (spendingPackageFilter.length > 0 && isQueryByPackage) {
        columnData.unshift({
            fixed: "left",
            title: i18n.t("spending_package"),
            dataIndex: "spendingPackage",
            align: "left",
            width: descriptionWidth,
            className: "table-first-column",
        });
    }
    columnData.push(...valuesColumn);

    let totalsColumns: Column[] = [];
    const monthTotalValue = totals.find((item) => item.order === 6).value;
    const width = Math.ceil(
        ElementUtil.getWidthOfText(
            formatNumber(monthTotalValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            "normal 14px"
        ) + 20
    );

    if (viewModeSelected.length > 0) {
        let totalsCol: Column[] = [
            {
                title: i18n.t("budgeted"),
                dataIndex: "plannedTotal",
                align: "center",
                fixed: "right",
                editable: true,
                width,
                render: (total, record: BudgetGridData) => {
                    return formatNumber(
                        total,
                        record.rowType !== RowType.QUANTITY && { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    );
                },
            },
        ];
        viewModeSelected.forEach((viewMode) => {
            const order =
                viewMode === "realizedValue"
                    ? 8
                    : viewMode === "historicValue"
                        ? 10
                        : viewMode === "apportionmentValue"
                            ? 9
                            : 7;
            const monthTotalValue = totals.find((item) => item.order === order).value;
            const width = Math.ceil(
                ElementUtil.getWidthOfText(
                    formatNumber(monthTotalValue, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    "normal 14px"
                ) + 20
            );

            const title =
                viewMode === ViewModeSelected.REALIZED
                    ? i18n.t("accomplished")
                    : viewMode === ViewModeSelected.HISTORIC
                        ? i18n.t("historic")
                        : viewMode === "apportionmentValue"
                            ? i18n.t("apportionment")
                            : i18n.t("adjusted");

            totalsCol.push({
                title,
                align: "center",
                fixed: "right",
                width: width > 75 ? width : 75,
                editable: false,
                dataIndex: viewMode.replace("Value", "Total"),
                render: (total, record: BudgetGridData) => {
                    return formatNumber(
                        total,
                        record.rowType !== RowType.QUANTITY && { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                    );
                },
            });
        });

        totalsColumns.push({
            title: i18n.t("total"),
            align: "center",
            editable: true,
            dataIndex: "plannedTotal",
            children: [...totalsCol],
        });
        columnData.push(...totalsColumns);
        columnData.push({
            title: i18n.t("comment"),
            dataIndex: "operation",
            align: "center",
            fixed: "right",
            width: 90,
            render: (_, record: BudgetGridData) => {
                const hasComment = record.comment && record.comment.comment.length > 0 ? true : false;
                return record.detailId ? (
                    ""
                ) : (
                    <Icon
                        style={{ color: hasComment ? "#4F9ACF" : "#ADADAD" }}
                        onClick={() => openCommentModal(record)}
                        icon="fa6-solid:comments"
                    />
                );
            },
        });
    } else {
        columnData.push(
            {
                title: i18n.t("total"),
                dataIndex: "plannedTotal",
                align: "center",
                fixed: "right",
                editable: true,
                width,
                render: (text, record: BudgetGridData) => {
                    if (record.rowType === RowType.QUANTITY || record.rowType === RowType.UNITVALUE) {
                        return null;
                    }

                    return formatNumber(text, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                },
            },
            {
                title: i18n.t("comment"),
                dataIndex: "operation",
                align: "center",
                fixed: "right",
                width: 100,
                render: (_, record: BudgetGridData) => {
                    const hasComment = record.comment && record.comment.comment.length > 0 ? true : false;
                    return record.detailId ? (
                        ""
                    ) : (
                        <Icon
                            style={{ color: hasComment ? "#4F9ACF" : "#ADADAD" }}
                            onClick={() => openCommentModal(record)}
                            icon="fa6-solid:comments"
                        />
                    );
                },
            }
        );
    }
    return { columnData, updatedMonthAmount };
}

