import { Icon } from "@iconify/react"
import { Button, Dropdown, Menu, MenuProps } from "antd"
import { Notification } from "components/notification/Notification"
import { Key, useState } from "react"
import i18n from "util/base/i18n"
import { RequestType } from "util/service/IServiceCaller"
import { ServiceCaller } from "util/service/ServiceCaller"
import { HiredTable } from "../IHumanResoucers"

interface Props {
    selectedRowKeys: Key[],
    year: string,
    hiredList: HiredTable[];
    isClosedBudgetPeriod: boolean;
}

export default function RecalculateButton({ selectedRowKeys, year, hiredList, isClosedBudgetPeriod }: Props){

    const [isFetching, setIsFetching] = useState(false);

    const menu = (
        <Menu onClick={recalculate}>
            <Menu.Item key="calculate_all"> {i18n.t("human_resources.calculate_all")} </Menu.Item>
        </Menu>
    )
	function recalculate({ key }) {
        setIsFetching(true)

		ServiceCaller.doRequest({
			type: RequestType.PUT,
			params: key === "recalculate_selected" ? selectedRowKeys : hiredList.map(hired => hired.id),
			url: `human-resources/hiring/recalculate?scenarioId={scenario}&organizationId={organization}&locale={locale}&userId={user}&buId={businessUnit}&clientId={client}&referenceYear=${year}`,
		}, (data) => {
				Notification({
					message: i18n.t(i18n.t(`human_resources.${data.message}`)),
					type: data.success ? "success" : "error"
				});
                setIsFetching(false);
			},
			() => {
				Notification({
					message: i18n.t(i18n.t(`human_resources.recalculation.error`)),
					type: "error"
				});
                setIsFetching(false);
			}
		)
	}

    return (
        <>
            <div className="calculate-dropdown-container">
                <Button
                    onClick={() => recalculate({key: "recalculate_selected"})}
                    icon={<Icon icon="mdi:calculator" />}
                    className="collaborator-header-right-buttons main-button-dropdown"
                    disabled={selectedRowKeys?.length === 0 || isFetching || isClosedBudgetPeriod ? true : false}
                >
                    {i18n.t("human_resources.calculate_selected")}
                </Button>
                <Dropdown
                    className="collaborator-header-right-buttons human-resources-dropdown"
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomLeft"
                    disabled={isFetching || isClosedBudgetPeriod}
                >
                    <Button icon={<Icon icon="mingcute:down-line" />} ></Button>
                </Dropdown>
            </div>
        </>
    )
}