import {
	useEffect,
	useState
} from "react";

import { Icon } from "@iconify/react";
import {
	Avatar,
	Badge,
	Button
} from "antd";

import { MessageType, RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

export default function Notifications() {

	const [notifications, setNotifications] = useState<number>();

	function onLoadNotifications(notifications: number) {
		setNotifications(notifications);
	};

	const refreshCounter = () => {
		ServiceCaller.doSendMessage({
			messageType: MessageType.REFRESH_NOTIFICATION_COUNTER
		}, refreshCounter);

		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: "/notification/unread-count"
		}, onLoadNotifications.bind(this));
	};

	useEffect(refreshCounter, []);

	const openNotification = (event: MouseEvent) => {
		ServiceCaller.doSendMessage({
			messageType: MessageType.OPEN_NOTIFICATION_MENU,
			params: {
				right: (event.target as HTMLElement).getBoundingClientRect().right
			}
		});
	};

	return <>
		<Button onClick={openNotification.bind(this)}>
			<Badge count={notifications} >
				<Avatar icon={<Icon icon="ic:baseline-notifications" />} shape="square" />
			</Badge >
		</Button>
	</>;

}
