import { Icon } from "@iconify/react";
import { Button } from "antd";
import i18n from "util/base/i18n";

export function BackButton({onGoBack}) {
    return (
        <Button
            className="gs-return-button"
            type="default"
            onClick={onGoBack}
            icon={<Icon icon="akar-icons:arrow-back-thick-fill" />}
        >
            {i18n.t<string>("back")}
        </Button>
    )
}