import { Table } from 'antd'
import i18n from 'util/base/i18n';
import { InvestmentTemplateTableProps } from '../IInvestmentTemplate';

export default function InvestmentTemplateTable({
    isFetching,
    tableData,
    selectedRowKeys,
    onChange,
    columns
}: InvestmentTemplateTableProps) {
    const rowSelection = { selectedRowKeys, onChange, getCheckboxProps: (record) => ({
        disabled: record.key <= 0
    }) };

    return (
        <Table
            style={{ padding: '0 24px' }}
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            className="gs-table"
            dataSource={tableData}
            columns={columns}
            rowSelection={rowSelection}
            pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 20 }}
            bordered
        />
    )
}