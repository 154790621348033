import moment from "moment";
import { CardSkeleton } from "components/skeleton/cardSkeleton/CardSkeleton";
import { Card } from "./Card";
import { RequestType } from "util/service/IServiceCaller";
import { useOtherEventContext } from "../../context/OtherEventContext";

import "./styles.sass";
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";

export function SummaryCards({ savedValue, isFirstOpen }) {
    const { period, currency } = useOtherEventContext();
    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!savedValue && !isFirstOpen) {
            return;
        }
        setIsLoading(true);
        ServiceCaller.doRequest(
            {
                url: `/planning/other-event/total-values?scenario={scenario}&organization={organization}&client={client}&locale={locale}&user={user}&startDate=${
                    period[0] ? period[0].valueOf() : moment().year().valueOf()
                }&endDate=${period[0] ? period[1].valueOf() : moment().year().valueOf()}`,
                type: RequestType.GET,
            },
            handleGetTotalValues.bind(this)
        );
    }, [period, savedValue]);

    function handleGetTotalValues(data) {
        setCards(data);
        setIsLoading(false);
    }

    return (
        <div id="summary-cards-container">
            {isLoading || !currency ? (
                <>
                    <CardSkeleton direction="row" active amount={4} gap={10} cardClassName="summary-card-skeleton" />
                </>
            ) : cards.length > 0 ? (
                cards.map((card) => {
                    return (
                        <Card
                            label={card.label}
                            value={card.value}
                            type={card.type}
                            key={card.label.replace(" ", "-")}
                            icon={card.icon}
                            isoCode={currency?.isoCode}
                        />
                    );
                })
            ) : (
                <div></div>
            )}
        </div>
    );
}
