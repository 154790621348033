import { FormulaItem } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { useEffect } from "react";
import { IFormulaContentProps } from "../../../../IRegistrationSalesReports";
import { Button, Popconfirm } from "antd";
import i18n from "util/base/i18n";
import { OperationsSelect } from "module/budget/pages/revenue/attributeParameterization/components/operators/OperationsSelect";
import MultipleFormulaArea from "./MultipleFormulaArea";

export default function FormulaContent({
	optionsSelected,
	onSaveFormulas,
	setSelectedOptionsList,
	setHasUnsavedChanges,
	onCancelChanges,
	formula,
	setFormula,
	onAcceptFormulaChanges,
	classNameForPulseButton
}: IFormulaContentProps) {
	function removeItem(index: number) {
		setFormula((state) =>
			state.filter((_, itemIndex) => itemIndex !== index)
		);

		const { key } = formula[index];
		setSelectedOptionsList(state => {
			return state.filter(selected => selected !== key);
		})
		setHasUnsavedChanges(true);
	}

	function addAttributeToFormula(attributes: any[]) {
		const formulasAttribute: FormulaItem[] = formula.filter(({ type }) => type === "attribute");
		if (attributes.length > formulasAttribute.length) {
			const [attributeToAdd]: any = attributes.filter(({ value }) => {
				return !formulasAttribute.map(({ key }) => key).includes(value);
			});
			const content = {
				name: attributeToAdd.label,
				id: attributeToAdd.value
			}

			setFormula([
				...formula,
				{
					type: "attribute",
					content,
					key: attributeToAdd.value
				},
			]);

			setHasUnsavedChanges(true);
		} else {
			let newFormula: FormulaItem[] = formula.filter(({ type, key }) => {
				setHasUnsavedChanges(true);
				return type === "operator" || attributes.some(({ value }) => {
					return value === key
				});
			});

			setFormula(newFormula);
		}
	}

	function addOperationToFormula(selectedOperation: string) {
		if (
			selectedOperation === "left_parenthesis" ||
			selectedOperation === "right_parenthesis"
		) {
			setFormula([
				...formula,
				{ type: "operator", content: selectedOperation, key: `OPERATORS-${Math.random()}` },
			]);
			setHasUnsavedChanges(true);
		}

		if (selectedOperation === "value") {
			setFormula([
				...formula,
				{ type: "value", content: selectedOperation, key: `INFORMED_VALUE-${Math.random()}` },
			]);
			setHasUnsavedChanges(true);
			return;
		}

		if (selectedOperation !== "operator") {
			setFormula([
				...formula,
				{ type: "operator", content: selectedOperation, key: `OPERATORS-${Math.random()}` },
			]);
			setHasUnsavedChanges(true);
		}
	}

	function onClickCleanFormula() {
		setFormula([]);
		setSelectedOptionsList([]);
		onSaveFormulas([]);
	}

	useEffect(() => {
		if (!optionsSelected) return;
		addAttributeToFormula(optionsSelected);
	}, [optionsSelected])

	return (
		<div id="attribute_parameterization-new-attribute-modal-formula" className="attribute_parameterization-new-attribute-modal">
			<div className="form-field">
				<OperationsSelect
					onChooseOperation={addOperationToFormula}
					hasValueOperator
				/>
			</div>
			<div className="visualization-formula">
				<div className="header-buttons">
					<h4>{i18n.t<string>("formula_view")}</h4>
					<Popconfirm
						placement="bottomLeft"
						overlayClassName="popconfirm-delete"
						title={i18n.t<string>("clear_formula_level")}
						onConfirm={onClickCleanFormula}
						okText={i18n.t<string>("yes")}
						cancelText={i18n.t<string>("cancel")}
						okButtonProps={{ danger: true, className: 'popconfirm-delete-button' }}
					>
						<Button type="text">
							{i18n.t("clean")}
						</Button>
					</Popconfirm>
				</div>

				<MultipleFormulaArea setFormula={setFormula} formula={formula} removeItem={removeItem} />

				<div className="footer-buttons">
					<Button type="text" onClick={onCancelChanges.bind(this)}>
						{i18n.t("cancel")}
					</Button>
					<Button onClick={onAcceptFormulaChanges} type="default"
						className={`gs-secondary-button ${classNameForPulseButton}`}
					>
						{i18n.t("save")}
					</Button>
				</div>
			</div>
		</div>
	)
}