import { Icon } from "@iconify/react"
import { Button, Col, Row } from "antd"
import moment from "moment";
import { Hired } from "../IHumanResoucers";
import { ImageBox } from "components/imageBox/ImageBox";
import i18n from "util/base/i18n";


interface Props {
	hiredList: Hired[];
	editFunction?: (hired: Hired) => void;
	deleteFunction?: (key: string) => void;
	isOnFinalStep: boolean;
	isEditButtonDisabled?: boolean;
}
export default function TableCollaborators({
	hiredList,
	editFunction,
	isOnFinalStep,
	deleteFunction,
	isEditButtonDisabled
}: Props) {

	return (
		<div className="list-of-added-items">
			{hiredList.length > 0
				?
				<>
					<div className="cost-category-list-header">
						<Row align="middle" gutter={3}>
							<Col span={4} style={{ paddingLeft: 6 }}>{i18n.t("business_unit")}</Col>
							<Col span={4} style={{ paddingLeft: 6 }}>{i18n.t("cost_center")}</Col>
							<Col span={4} >{i18n.t("cost_category")}</Col>
							<Col span={4} >{i18n.t("professional_category.professional_category_name")}</Col>
							<Col span={4} >{i18n.t("collaborator")}</Col>
							<Col span={2} >{i18n.t("period")}</Col>
							<Col span={1}></Col>
							<Col span={1}></Col>
						</Row>
					</div>
					<div className="cost-category-list-content">
						{hiredList.map((costCategory) => {
							return (
								<Row key={costCategory.key} align="middle" gutter={3}>
									<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{costCategory.businessUnit.label}</Col>
									<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{costCategory.costCenter.label}</Col>
									<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{costCategory.costCategory.label}</Col>
									<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{costCategory.professionalCategory.label}</Col>
									<Col className="table-collaboratos-col" span={4} style={{ justifyContent: 'start' }}>{costCategory.collaborator.label}</Col>
									<Col span={2} style={{ justifyContent: 'start' }}>{moment(costCategory.period).format("MMM/YYYY")}</Col>
									{!isOnFinalStep &&
										<>
											<Col span={1} >
												<Button
													disabled={isEditButtonDisabled}
													onClick={() => editFunction(costCategory)}
													icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
												/>
											</Col>
											<Col span={1} >
												<Icon
													className="delete-icon"
													onClick={() => deleteFunction(costCategory.key)}
													icon="fa6-solid:trash"
												/>
											</Col>
										</>
									}
								</Row>
							)
						})}
					</div>
				</>
				:
				<ImageBox styles={{paddingTop: 25}}/>
			}

		</div >
	)
}