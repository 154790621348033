import { Table } from "antd";
import i18n from "util/base/i18n";
import { memo, useEffect, useState } from "react";
import { BudgetGridData, ColumnTypes, ViewModeSelected, totalSummary } from "../../../IFixedExpense";
import { createTableTotalsRow } from "../functions/createTableTotalsRow";
import { formatNumber } from "util/formatNumber";
import { usePermissionContext } from "context/PermissionContext";
import { FunctionalityPermissions, Options } from "util/types/types";
import { useWindowSize } from "hooks/useWindowSize";
import { Loading } from "components/loading/Loading";
import { useBudgetOpeningContext } from "../../../context/useBudgetOpeningContext";

interface GridProps {
    isFetching: boolean;
    monthAmount: number;
    viewModeSelected: ViewModeSelected[];
    components: any; // replace with the correct type
    cols: any[];
    budgetGridData: BudgetGridData[];
    rowExpandable: any; // replace with the correct type
    rowSelection: any; // replace with the correct type
    isQueryByPackage: boolean;
    spendingPackage: Options[]; // replace with the correct type
}

export const Grid = memo(
    ({
        isFetching,
        monthAmount,
        viewModeSelected,
        components,
        cols,
        budgetGridData,
        rowExpandable,
        rowSelection,
        isQueryByPackage,
        spendingPackage,
    }: GridProps) => {
        const { functionalityPermissions } = usePermissionContext();
        const { width } = useWindowSize();
        const { accountsWithValue } = useBudgetOpeningContext();
        const [filteredData, setFilteredData] = useState<BudgetGridData[]>(budgetGridData);

        useEffect(() => {
            if (accountsWithValue) {
                const newFilteredData = budgetGridData.filter(item =>
                    Object.values(item.plannedMonths).some(plannedMonth =>
                        (plannedMonth.projectedValue !== null && plannedMonth.projectedValue !== 0) ||
                        (plannedMonth.realizedValue !== null && plannedMonth.realizedValue !== 0) ||
                        (plannedMonth.historicValue !== null && plannedMonth.historicValue !== 0) ||
                        (plannedMonth.plannedValue !== null && plannedMonth.plannedValue !== 0)
                    )
                );
                setFilteredData(newFilteredData);
            } else {
                setFilteredData(budgetGridData);
            }
        }, [accountsWithValue, budgetGridData]);

        return (
            <Table
                key="table"
                loading={{
                    spinning: isFetching,
                    tip: `${i18n.t<string>("loading")}...`,
                    indicator: <Loading />
                }}
                className="gs-table"
                scroll={
                    monthAmount > 6 || viewModeSelected.length > 0 || width < 1350
                        ? { x: 1600, y: 580 }
                        : { x: true, y: 580 }
                }
                components={components}
                columns={cols as ColumnTypes}
                rowClassName={(record: BudgetGridData) =>
                    record.standardAccount !== null &&
                        record.standardAccount === record.budgetOpening.accountId &&
                        isQueryByPackage
                        ? "standard-account-row"
                        : ""
                }
                dataSource={filteredData}
                expandable={rowExpandable}
                bordered
                rowSelection={functionalityPermissions.edit ? rowSelection : null}
                summary={(data: BudgetGridData[]) =>
                    Summary(
                        data,
                        viewModeSelected,
                        functionalityPermissions,
                        isQueryByPackage && spendingPackage?.length > 0
                    )
                }
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50],
                    hideOnSinglePage: budgetGridData?.length < 10 ? true : false,
                }}
            />
        );
    }
);

function Summary(
    data: BudgetGridData[],
    viewModeSelected: ViewModeSelected[],
    functionalityPermissions: FunctionalityPermissions,
    isQueryByPackage: boolean
) {
    if (data.length === 0) return null;
    const totals: totalSummary[] = createTableTotalsRow(data, viewModeSelected);

    const isEditableAndByPackage = functionalityPermissions.edit && isQueryByPackage;
    const isEditable = functionalityPermissions.edit;

    return (
        <Table.Summary fixed>
            <Table.Summary.Row style={{ background: "#FBE6E6" }}>
                {isEditable ? <Table.Summary.Cell index={0} /> : null}
                <Table.Summary.Cell index={isEditable ? 1 : 0} align="left">
                    {i18n.t("total")}
                </Table.Summary.Cell>
                {isQueryByPackage ? <Table.Summary.Cell index={isEditable ? 2 : 1} /> : null}
                {totals.map((value, index) => (
                    <Table.Summary.Cell
                        align="center"
                        key={value.month + index}
                        index={index + (isEditableAndByPackage ? 3 : isQueryByPackage || isEditable ? 2 : 1)}
                    >
                        {formatNumber(value.value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </Table.Summary.Cell>
                ))}
                <Table.Summary.Cell
                    index={totals.length + (isEditableAndByPackage ? 3 : isQueryByPackage || isEditable ? 2 : 1)}
                />
            </Table.Summary.Row>
        </Table.Summary>
    );
}
