import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Breadcrumb } from "antd";
import i18n from "util/base/i18n";
import ButtonFilterScenery from "./scenery/ButtonFilterScenery";
import "./style.sass";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { coachMarkChannel } from "util/BroadcastChannels";
import { useLocation, useNavigate } from "react-router-dom";
import { ROOT_PATH } from "util/rootPath";
import { useUserContext } from "context/UserContext";
import { handleRedirect } from "util/functions/handleRedirect";
import { PlanningEnvironmentTag } from "module/leaseProjection/components/planningEnvironmentTag/PlanningEnvironmentTag";
import { useErrorBoundary } from "react-error-boundary";

type ILocationFragment = {

	caption: string;

	link: string;

	itemOrder: number;

}

export default function Subheader() {
	const [applyOverlay, setApplyOverlay] = useState(false)
	const [fragments, setFragments] = useState([]);
	const { isBudgetModule } = useUserContext()
	const { resetBoundary } = useErrorBoundary();
	const location = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: "/menu/location-fragments"
		}, onLoadFragments);
	}, [location]);

	const onLoadFragments = (fragments: ILocationFragment[]) => {
		setFragments(fragments
			.sort(({ itemOrder: orderA }, { itemOrder: orderB }) => orderA - orderB)
			.map(fragment => {
				fragment.link = (ROOT_PATH + fragment.link);

				return fragment;
			}));
	};

	function doRedirect(url: string, event: MouseEvent): void {
		if (!event.ctrlKey) {
			event.preventDefault();
			resetBoundary()
			handleRedirect(url, isBudgetModule, navigate)
		}
	};

	coachMarkChannel.onmessage = (message) => {
		switch (message.data) {
			case 'show-overlay':
				setApplyOverlay(true)
				break;
			case 'hide-overlay':
				setApplyOverlay(false)
				break;
			default:
				break;
		}
	}

	return (
		<div className="subheader-main">
			<div className={`microfront_overlay ${applyOverlay ? '' : 'hidden'}`}></div>
			<Breadcrumb separator=">">
				<Breadcrumb.Item href={ROOT_PATH} onClick={doRedirect.bind(this, ROOT_PATH)}>
					<Icon icon="ion:home-sharp" />
					{i18n.t<string>("start")}
				</Breadcrumb.Item>
				{
					fragments.map(({ link, caption }) =>
						<Breadcrumb.Item key={link} href={link} onClick={doRedirect.bind(this, link)}>
							{caption}
						</Breadcrumb.Item>
					)
				}
			</Breadcrumb>

			<PlanningEnvironmentTag />
			<ButtonFilterScenery />
		</div>
	)

}
