import { Icon } from "@iconify/react";
import { Modal, Popconfirm, Table, Tag } from "antd";
import Tooltip from "antd/es/tooltip";
import { ColumnsType } from "antd/lib/table";
import { ImageBox } from "components/imageBox/ImageBox";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

export default function ProcessOrderModal({ onClose }) {

  interface DataType {
    id: number;
    order: number;
    process: string;
    period: string;
    scenario: string;
    startOfExecution: string;
    status: string;
    user: string;
    requestDate: string;

  }

  const [data, setData] = useState<DataType[]>([])
  const { userInfo } = useUserContext()


  useEffect(() => {
    onLoadData()
  }, [])

  const onLoadData = () => {
    ServiceCaller.doRequest({
      type: RequestType.GET,
      url: `/budget-base/integration-parameterization/find-all?locale=${userInfo.localeId}&client=${userInfo.clientId}`
    }, (response) => {
      const data = response.map((item) => {
        return {
          id: item.id,
          order: item.order,
          process: item.integration,
          period: item.baseDate,
          scenario: item.scenarioExternalCode,
          startOfExecution: item.integrationStartTime,
          status: item.statusDescription,
          user: item.userName,
          requestDate: item.requestTime
        }
      })
      setData(data)
    })
  }

  
  const onLoadDelete = () => {
    Notification({
      type: "success",
      message: i18n.t<string>("successfully_deleted"),
    });
  }

  const handleDelete = (index, status) => {
    if (status.includes('processing')) {
      Notification({
        type: "error",
        message: i18n.t<string>("delete_disabled_message"),
      });
    } else {
      ServiceCaller.doRequest({
        type: RequestType.DELETE,
        url: `/budget-base/integration-parameterization/${data[index].id}`,
      }, onLoadDelete, handleErrorRequest);
      onLoadData();
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.t<string>("order"),
      dataIndex: 'order',
      key: 'order',
      align: 'center',
    },
    {
      title: i18n.t<string>("process"),
      dataIndex: 'process',
      key: 'process',
      align: 'center',
    },
    {
      title: i18n.t<string>("period"),
      dataIndex: 'period',
      key: 'period',
      align: 'center',
    },
    {
      title: i18n.t<string>("scenario"),
      dataIndex: 'scenario',
      key: 'scenario',
      align: 'center',
    },
    {
      title: i18n.t<string>("start_of_execution"),
      dataIndex: 'startOfExecution',
      key: 'startOfExecution',
      align: 'center',
    },
    {
      title: i18n.t<string>("status"),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => {
        const isProcessing = status.includes('processing');
        return <Tag color={isProcessing ? 'blue' : ''} style={{ border: 'none' }}>{status}</Tag>;
      }
    },
    {
      title: i18n.t<string>("user"),
      dataIndex: 'user',
      key: 'user',
      align: 'center',
    },
    {
      title: i18n.t<string>("request_date"),
      dataIndex: 'requestDate',
      key: 'requestDate',
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Popconfirm
          title={i18n.t<string>("delete_the_process_from_the_update_list")}
          onConfirm={() => handleDelete(index, record.status)}
          okText={i18n.t<string>("yes")}
          cancelText={i18n.t<string>("cancel")}
          disabled={record.status.includes('processing')}
        >
          <Tooltip title={i18n.t<string>("delete")}>
            {record.status.includes('processing') ? (
              <Icon
                icon="ic:baseline-delete"
                style={{ cursor: 'not-allowed', fontSize: '17px', color: 'gray' }}
              />
            ) : (
              <Icon
                icon="ic:baseline-delete"
                style={{ cursor: 'pointer', fontSize: '17px', color: 'black' }}
              />
            )}
          </Tooltip>
        </Popconfirm>

      )
    }
  ];
  return (
    <Modal
      className="modal-data-request-update"
      title={i18n.t<string>("update_process_order")}
      onCancel={onClose}
      visible={true}
      footer={null}
      width={1300}
    >
      {data.length > 0 ? (
        <Table
          className="table-data-request-update"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ y: 400 }} />
      ) : (
        <ImageBox />
      )
      }
    </Modal>
  )
}