import i18n, { languages } from "util/base/i18n";
import { DatePicker, Form, Input, Modal, Row, Col, Select, InputNumber } from "antd";

import { ContractSimulationModalProps } from "../../IContractSimulation";

import './ContractSimulationModalStyles.sass'
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import CurrencyInput from "module/leaseProjection/components/currencyInput/CurrencyInput";
import { selectProps } from "util/props/props";

export function ContractSimulationModal({
  isModalVisible,
  handleCancel,
  handleSubmit,
  form,
  isLoading,
}: ContractSimulationModalProps) {
  const [indexers, setIndexers] = useState([]);
  const [managementGroup, setManagementGroup] = useState([]);
  const [contractGroup, setContractGroup] = useState([]);
  const [project, setProject] = useState([]);
  const [divisionGroup, setDivisionGroup] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    if (isModalVisible) {
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: "/projectionlease/scenario/indexer"
      }, onLoadIndexers.bind(this));
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: "/projectionlease/contract/findmanagementgroups"
      }, onLoadManagementGroup.bind(this));
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: "/projectionlease/contract/findcontractgroups"
      }, onLoadContractGroup.bind(this));
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: "/projectionlease/contract/findprojects"
      }, onLoadProject.bind(this));
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: "/projectionlease/contract/finddivisiongroups"
      }, onLoadDivisionGroup.bind(this));
    }
  }, [isModalVisible]);

  function onLoadIndexers(indexers) {
    setIndexers(indexers)
  }

  function onLoadManagementGroup(managementGroup) {
    setManagementGroup(managementGroup)
  }

  function onLoadContractGroup(contractGroup) {
    setContractGroup(contractGroup)
  }

  function onLoadProject(project) {
    setProject(project)
  }

  function onLoadDivisionGroup(divisionGroup) {
    setDivisionGroup(divisionGroup)
  }

  function handleExternalCodeChange(e) {
    form.setFieldsValue({ externalCode: e.target.value.replace(/\s/g, '') });
  }

  return (
    <Modal
      width={1000}
      maskClosable={false}
      onCancel={handleCancel}
      visible={isModalVisible}
      okText={i18n.t<string>("save")}
      title={i18n.t<string>('new_lease')}
      cancelText={i18n.t<string>("cancel")}
      className="gs-modal contract-simulation-modal"
      okButtonProps={{
        htmlType: "submit",
        form: "new-contract-simulation-form",
        disabled: isSubmitDisabled
      }}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        className={"form-new-contract"}
        name="new-contract-simulation-form"
        onFinish={(data) => { handleSubmit(data) }}
        onFieldsChange={() =>
          setIsSubmitDisabled(
            form.getFieldsError().some((field) => field.errors.length > 0)
          )
        }
        requiredMark={false}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="externalCode"
              label={i18n.t<string>('external_code')}
              rules={[{ required: true, message: i18n.t<string>("required_field") }]}
            >
              <Input id="input-template-detail" onChange={handleExternalCodeChange} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="name"
              label={i18n.t<string>('contract')}
              rules={[{ required: true, message: i18n.t<string>("required_field") }]}
            >
              <Input id="input-template-detail" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="baseDate"
              label={i18n.t<string>('start_date')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    const baseDate = new Date(value).setHours(0, 0, 0, 0);
                    const endDate = new Date(form.getFieldValue("endDate")).setHours(0, 0, 0, 0);

                    if (baseDate && endDate && baseDate >= endDate)
                      return Promise.reject(i18n.t<string>('end_date_greater_than_start_date'))

                    return Promise.resolve()
                  },
                },
              ]}
            >
              <DatePicker className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="endDate"
              label={i18n.t<string>('end_date')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    const baseDate = new Date(form.getFieldValue("baseDate")).setHours(0, 0, 0, 0);
                    const endDate = new Date(value).setHours(0, 0, 0, 0);

                    if (baseDate && endDate && baseDate >= endDate)
                      return Promise.reject(i18n.t<string>('end_date_greater_than_start_date'))

                    return Promise.resolve()
                  },
                },
              ]}
            >
              <DatePicker className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="amortizationBaseDate"
              label={i18n.t<string>('amortization_start_date')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    const baseDate = new Date(form.getFieldValue("baseDate")).setHours(0, 0, 0, 0);
                    const endDate = new Date(form.getFieldValue("endDate")).setHours(0, 0, 0, 0);
                    const amortizationBaseDate = new Date(value).setHours(0, 0, 0, 0);

                    if (baseDate && endDate && amortizationBaseDate && ((amortizationBaseDate <= baseDate) || (amortizationBaseDate > endDate)))
                      return Promise.reject(i18n.t<string>('amortization_base_date_out_of_range'))

                    return Promise.resolve()
                  },
                },
              ]}
            >
              <DatePicker className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="installments"
              label={i18n.t<string>('installments')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    if (form.getFieldValue("installments") > 0)
                      return Promise.resolve()
                    else
                      return Promise.reject(i18n.t<string>('installments_greater_zero'))
                  }
                }
              ]}
            >
              <CurrencyInput
                form={form}
                name='installments'
                className="ant-input-number"
                value={form.getFieldValue('installments')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="frequency"
              label={i18n.t<string>('frequency')}
              rules={[{ required: true, message: i18n.t<string>("required_field") }]}
            >
              <InputNumber
                step={1}
                formatter={value => String(value).replace(/\./g, '')}
                parser={value => String(value).replace(/\./g, '')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="rate"
              label={i18n.t<string>('discount_rate')}
              rules={[
                { required: true, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    if (!(form.getFieldValue("rate") < 1000))
                      return Promise.reject(i18n.t<string>('rates_less_one_thousand'))
                    else if (form.getFieldValue("rate") <= 0)
                      return Promise.reject(i18n.t<string>('rates_greater_than_zero'))
                    else
                      return Promise.resolve()
                  }
                }
              ]}
            >
              <InputNumber
                controls={false}
                decimalSeparator={","}
                width={"100%"}
                precision={8}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
                name="indexer"
                label={i18n.t<string>("indexer")}
                dependencies={["firstCapitalization"]}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value > 0 && form.getFieldValue("firstCapitalization") != null)
                        return Promise.resolve()
                      else if (value == null && form.getFieldValue("firstCapitalization") != null)
                        return Promise.reject(i18n.t<string>('indexer_not_selected'))
                      else
                        return Promise.resolve()
                    }
                  }
                ]}
              >
                <Select
                  maxTagCount="responsive"
                  allowClear
                  options={indexers}
                  placeholder={i18n.t<string>("select")}
                  {...selectProps}
                />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="firstCapitalization"
              label={i18n.t<string>('first_capitalization_restatement')}
              dependencies={["indexer"]}
              rules={[
                {
                  validator: (_, value) => {
                    if (value != null && (value >= form.getFieldValue("endDate") || value <= form.getFieldValue("baseDate")))
                      return Promise.reject(i18n.t<string>('first_capitalization_restatement_errorperiod'))
                    else if (value == null && form.getFieldValue("indexer") != null)
                      return Promise.reject(i18n.t<string>('first_capitalization_restatement_not_filled'))
                    else
                      return Promise.resolve()
                  }
                }
              ]}
            >
              <DatePicker className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="operationalCostValue"
              label={i18n.t<string>('operation_cost_value')}
            >
              <CurrencyInput
                form={form}
                name='operationalCostValue'
                className="ant-input-number"
                value={form.getFieldValue('operationalCostValue')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="doublePercentual"
              label={i18n.t<string>('additional_december')}
              rules={[
                {
                  validator: (_, value) => {
                    if (value != null && value >= 1000)
                      return Promise.reject(i18n.t<string>('additional_december_less_one_thousand'))
                    else if (value != null && value <= 0)
                      return Promise.reject(i18n.t<string>('additional_december_greater_than_zero'))
                    else
                      return Promise.resolve()
                  }
                }
              ]}
            >
              <InputNumber
                controls={false}
                decimalSeparator={","}
                width={"100%"}
                precision={2}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="managementGroup"
              label={i18n.t<string>('management_group')}
              rules={[{ required: true, message: i18n.t<string>("required_field") }]}
            >
              <Select
                maxTagCount="responsive"
                options={managementGroup}
                placeholder={i18n.t<string>('select')}
                {...selectProps}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="contractGroup"
              label={i18n.t<string>('contract_group')}
            >
              <Select
                maxTagCount="responsive"
                options={contractGroup}
                placeholder={i18n.t<string>('select')}
                {...selectProps}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="project"
              label={i18n.t<string>('project')}
            >
              <Select
                maxTagCount="responsive"
                options={project}
                placeholder={i18n.t<string>('select')}
                {...selectProps}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="division"
              label={i18n.t<string>('division')}
            >
              <Select
                maxTagCount="responsive"
                options={divisionGroup}
                placeholder={i18n.t<string>('select')}
                {...selectProps}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  );
}