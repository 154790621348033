import type { ColumnsType } from 'antd/es/table/interface';
import { Table } from "antd";
import { CostCategoryTableProps } from "../ICostCategory";
import i18n from "util/base/i18n";
import { usePermissionContext } from 'context/PermissionContext';

export function CostCategoryTable({selectedRowKeys, tableData, isFetching, onChange, tableColumns}: CostCategoryTableProps) {
    const { functionalityPermissions } = usePermissionContext()
    const rowSelection = {selectedRowKeys, onChange};

    return (
        <Table 
            style={{padding: '0 24px'}}
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            className="gs-table"
            dataSource={tableData}
            columns={tableColumns}
            rowSelection={functionalityPermissions.edit || functionalityPermissions.remove ? rowSelection : null}
            pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 20 }}
            bordered
        />
    )
}