import { Table, FormInstance, Checkbox, Form, InputRef, Input, Row, Col, Button, Tooltip, Popover } from "antd";
import { EditableRowProps } from "module/budget/pages/detailLayout/IDetailLayout";
import { createContext, useState, useRef, useContext, useEffect, Key } from "react";
import i18n from "util/base/i18n";
import { LevelReport, ITableLevelsProps, ILevelStyle, IconIsVisible, ReportType, SignalType } from "../../../IRegistrationSalesReports";
import OverflowColor from "./OverflowColor";
import { Icon } from "@iconify/react";
import { Notification } from "components/notification/Notification";
import { useNewReportContext } from "../../NewReportContext";
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
interface EditableCellProps {
	title: React.ReactNode;
	editable: boolean;
	children: React.ReactNode;
	dataIndex: keyof LevelReport;
	record: LevelReport;
	handleSave: (record: LevelReport) => void;
}
export default function TableLevels({
	selectedRowKeys,
	tableData,
	onChange,
	setTableData,
	setHasSavedValue,
	setIsLoading,
}: ITableLevelsProps) {
	const { reportType } = useNewReportContext();

	const rowSelection = { selectedRowKeys, onChange };
	const EditableContext = createContext<FormInstance<any> | null>(null);
	const [popoverVisibleId, setPopoverVisibleId] = useState(-1);
	const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
	const [currentColumns, setCurrentColumns] = useState<(ColumnTypes[number] & { editable?: boolean; dataIndex: string, EXPAND_COLUMN?: boolean })[]>([])
	const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string, EXPAND_COLUMN?: boolean })[] = [
		{
			title: i18n.t<string>("order"),
			dataIndex: "ordination",
			width: 90,
			key: "ordination",
			align: "center",
			render: (value, record, index) => {
				const level: LevelReport = record as LevelReport;
				const { isIconDownVisible, isIconUpVisible } = isOrdinationIconVisible(level, index);

				return <>
					<Row className="ordination-column" >
						<Col>
							{`${value + 1}º`}
						</Col>
						<Col>
							{isIconUpVisible &&
								<Tooltip title={i18n.t<string>("move_row_up")} placement="right">
									<Row>
										<Button type="text" icon={<Icon icon="bxs:up-arrow" />} onClick={onSwitchLevelIndex.bind(this, level.key, -1)} />
									</Row>
								</Tooltip>
							}
							{isIconDownVisible &&
								<Tooltip title={i18n.t<string>("move_row_down")} placement="right">
									<Row>
										<Button type="text" icon={<Icon icon="bxs:down-arrow" />} onClick={onSwitchLevelIndex.bind(this, level.key, 1)} />
									</Row>
								</Tooltip>
							}
						</Col >
					</Row >
				</>
			},
		},
		{
			title: i18n.t<string>("code_row"),
			dataIndex: "externalCode",
			key: "externalCode",
			editable: true,
			width: 450,
			className: "line-code-column",
			render: (value) => {
				if (value) {
					return (
						<span>{value}</span>
					)
				}
			}
		},
		{
			title: i18n.t("description"),
			dataIndex: "description",
			key: "description",
			editable: true,
			width: 450,
			render: (value, record) => {
				return (
                    <div style={{ display: "flex", gap: 8, border: "none" }}>
                        <div className={!record["children"]?.length && record["isSubLevel"] ? "caption-level" : ""} />
                        <span style={{ whiteSpace: "pre" }}>{value}</span>
                    </div>
                );
			},
			className: "description-column-registration-sales",
		},
		{
			title: i18n.t<string>("invisible"),
			dataIndex: "invisible",
			key: "invisible",
			width: 90,
			render: (_, record: LevelReport) => (
				<Checkbox
					className="checkbox-invisible"
					onChange={(e) => handleInvisibleCheckboxChange(e.target.checked, record)}
					checked={record["invisible"]}
				/>
			),
			align: "center"
		},
		{
			title: i18n.t<string>("title"),
			dataIndex: "title",
			key: "title",
			width: 90,
			render: (_, record: LevelReport) => (
				<Checkbox
					className="checkbox-invisible"
					disabled={!!record?.children?.length}
					onChange={(e) => handleTitleCheckboxChange(e.target.checked, record)}
					checked={record["title"]}
				/>
			),
			align: "center"
		},
		{
			title: i18n.t<string>("row_style"),
			dataIndex: "lineStyle",
			key: "lineStyle",
			align: "center",
			width: 150,
			render: (_, record: LevelReport) => {
				return <OverflowColor selected={record.levelStyle} saveNewStyle={(newStyle) => changeStyle(newStyle, record)} index={record.key} setIsVisible={setPopoverVisibleId} isVisible={record.key === popoverVisibleId} />
			}
		},
	]

	const addStylesClass = ({ isBold, isItalic, isScratched, isUnderlined }: ILevelStyle) => {
		let classStyled = "";
		classStyled += isBold ? "bold" : "";
		classStyled += isItalic ? " italic" : "";
		classStyled += isScratched ? " scratched" : "";
		classStyled += isUnderlined ? " underlined" : "";
		return classStyled;
	}

	function changeStyle(newStyle: ILevelStyle, record: LevelReport) {
		const lineToChange = findLineByKey(tableData, record.key);
		setTableData(state => {
			return state.map((line) => {
				if (line.key === lineToChange.key) {
					line.levelStyle = { ...line.levelStyle, ...newStyle };
				} else if (line.children?.length) {
					let foundedLineToChange = findLineByKey(line.children, lineToChange.key);
					if (foundedLineToChange) {
						foundedLineToChange.levelStyle = { ...foundedLineToChange.levelStyle, ...newStyle };
						const indexOfLineToChange = line.children.findIndex(({ key }) => key === foundedLineToChange.key);
						line.children.splice(indexOfLineToChange, 1, foundedLineToChange);
					}
				}
				return line;
			})
		})
		setPopoverVisibleId(-1);
	}

	function findLineByKey(records: LevelReport[], key: number): LevelReport {
		let lineResult: LevelReport;
		for (let index = 0; index < records.length; index++) {
			const line = records[index];
			if (line.key === key) {
				lineResult = line;
				break;
			} else if (line.children) {
				lineResult = findLineByKey(line.children, key);
				if (lineResult) break;
			}
		}
		return lineResult;
	}

	const handleInvisibleCheckboxChange = (checked: boolean, record: LevelReport) => {
		const updatedData: LevelReport[] = tableData.map(item => {
			if (item.key === record.key) {
				return { ...item, invisible: checked };
			}
			if (item.children?.length) {
				const itemKeyToChange: number = item.children.findIndex(({ key }) => key === record.key);
				if (itemKeyToChange >= 0) {
					item.children[itemKeyToChange] = { ...item.children[itemKeyToChange], invisible: checked };
				}
			}
			return item;
		});
		setTableData(updatedData);
	};

	const handleTitleCheckboxChange = (checked: boolean, record: LevelReport) => {
		const updatedData: LevelReport[] = tableData.map(item => {
			if (item.key === record.key) {
				return { ...item, title: checked };
			}
			if (item.children?.length) {
				const itemKeyToChange: number = item.children.findIndex(({ key }) => key === record.key);
				if (itemKeyToChange >= 0) {
					item.children[itemKeyToChange] = { ...item.children[itemKeyToChange], title: checked };
				}
			}
			return item;
		});
		setTableData(updatedData);
	};

	const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
		const [form] = Form.useForm();
		const levelStyle: ILevelStyle = props.children[0]?.props.record.levelStyle;
		const backgroundColor = levelStyle?.color;
		const fontColor = levelStyle?.colorFont;
		const fontSize = levelStyle?.fontSize;
		const classStyled = levelStyle ? addStylesClass(levelStyle) : "";
		return (
			<Form form={form} component={false} >
				<EditableContext.Provider value={form}>
					<tr {...props} className={`${classStyled}`}
						style={{ background: backgroundColor, color: fontColor, fontSize: fontSize }} />
				</EditableContext.Provider>
			</Form >
		);
	};

	const handleSave = (row: LevelReport) => {
		const newData = [...tableData];
		const _parentIndex = newData.findIndex(items => {
			return items.children?.some(item => {
				return item.key === row.key;
			});
		});
		if (_parentIndex < 0) {
			newData.splice(newData.findIndex(({ key }) => key === row.ordination), 1, row);
		} else {
			const newChildrenData = [...newData[_parentIndex].children];
			const childIndex = newChildrenData.findIndex(item => row.key === item.key);
			const childItem = newChildrenData[childIndex];
			newChildrenData.splice(childIndex, 1, {
				...childItem,
				...row
			});
			newData[_parentIndex].children = [...newChildrenData];
		}

		setIsLoading(false);
		setHasSavedValue(true);
		setTableData(newData);
	};

	const EditableCell: React.FC<EditableCellProps> = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {

		const [editing, setEditing] = useState(false);
		const inputRef = useRef<InputRef>(null);
		const form = useContext(EditableContext)!;

		useEffect(() => {
			if (editing) {
				inputRef.current!.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			if (dataIndex !== "description" && dataIndex !== "externalCode") return;
			setEditing(!editing);
			if (record[dataIndex] !== i18n.t<string>("enter_the_line_description_here") && record[dataIndex] !== i18n.t<string>("enter_the_line_code_here")) {
				form.setFieldsValue({ [dataIndex]: record[dataIndex] });
			}
		};

		const save = async () => {
			try {
				const values = await form.validateFields();
				toggleEdit();
				if (!values[dataIndex]) return;
				if (values[dataIndex] === record[dataIndex]) return;
				setIsLoading(true);
				handleSave({ ...record, ...values });
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;

		const [expandButton, content] = children as any;
		const fontSize = record?.levelStyle?.fontSize;

		childNode = editing ? (
			<Form.Item
				style={{ margin: 0 }}
				name={dataIndex}
			>
				<Input
					ref={inputRef}
					onPressEnter={save}
					onBlur={save}
					style={{ fontSize }}
				/>
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
			>
				{expandButton}
				<div onClick={toggleEdit}>
					{content}
				</div>
			</div>
		)

		return (
			<td {...restProps}>{childNode}</td>
		);
	};

	const columns = currentColumns.map(col => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record: LevelReport) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
			}),
		};
	});

	const onSwitchLevelIndex = (targetKey: number, indexTo: number): void => {
		const tableDataSource: LevelReport[] = [...tableData];
		const expandedRowKeysSource: Key[] = [...expandedRowKeys];

		doSwitchLevelIndex(tableDataSource, targetKey, indexTo);
		doFixLevelOrdenations(tableDataSource, expandedRowKeysSource);

		setTableData(tableDataSource);
		setExpandedRowKeys(expandedRowKeysSource);
	};

	const doSwitchLevelIndex = (levels: LevelReport[], targetKey: number, indexTo: number): boolean => {
		let switched = false;

		for (let currentIndex: number = levels.length; currentIndex--;) {
			const level = levels[currentIndex];

			if (level.children && level.children.length) {
				switched = doSwitchLevelIndex(level.children, targetKey, indexTo);
			}

			if (switched) {
				break;
			} else if (level.key === targetKey) {
				const targetLine: LevelReport = levels[currentIndex + indexTo];
				const currentOrdenation: number = level.ordination;

				level.ordination = targetLine.ordination;
				targetLine.ordination = currentOrdenation;

				levels[currentIndex] = targetLine;
				levels[currentIndex + indexTo] = level;

				switched = true;

				break;
			}
		}

		return switched;
	};

	const doFixLevelOrdenations = (levels: LevelReport[], expandedRowKeysSource: Key[], ordination: number = 0): number => {
		levels.forEach(level => {
			const sourceKey: number = level.key;

			level.key = ordination;
			level.ordination = ordination++;

			const expandedKeyTarget = expandedRowKeysSource.indexOf(sourceKey);

			if (level.children && level.children.length && expandedKeyTarget > -1) {
				expandedRowKeysSource[expandedKeyTarget] = level.key;
			}

			if (level.children && level.children.length) {
				ordination = doFixLevelOrdenations(level.children, expandedRowKeysSource, ordination);
			}
		});

		return ordination;
	}

	function isOrdinationIconVisible(report: LevelReport, index: number): IconIsVisible {
		if (tableData.length < 2) return { isIconDownVisible: false, isIconUpVisible: false };
		let { isIconDownVisible, isIconUpVisible }: IconIsVisible = { isIconDownVisible: true, isIconUpVisible: true };
		const parentLine = tableData.find(({ children }) => children?.some(({ key }) => key === report.ordination));
		if (parentLine) {
			if (parentLine.children.length - 1) {
				if (index === 0) {
					isIconUpVisible = false;
				} else if (index === parentLine.children.length - 1) {
					isIconDownVisible = false;
				}
			} else {
				isIconUpVisible = false;
				isIconDownVisible = false;
			}
		} else {
			if (index === tableData.length - 1) {
				isIconDownVisible = false;
			} else if (report.ordination === tableData[0].ordination) {
				isIconUpVisible = false;
			}
		}

		return { isIconDownVisible, isIconUpVisible };
	}

	const handleExpand = (expanded: boolean, record: LevelReport) => {
		if (expanded) {
			setExpandedRowKeys([...expandedRowKeys, record.key]);
		} else {
			setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key));
		}
	};

	const translateSignalType = {
		ORIGINAL: i18n.t("new_sales_report.original"),
		POSITIVE: i18n.t("new_sales_report.positive"),
		NEGATIVE: i18n.t("new_sales_report.negative"),
		DEFAULT: ""
	}

	const handleChangeSinal = (sinal: SignalType, record: LevelReport) => {
		const updatedData: LevelReport[] = tableData.map(item => {
			if (item.key === record.key) {
				return { ...item, levelStyle: { ...record.levelStyle, signalType: sinal } };
			}
			if (item.children?.length) {
				const itemKeyToChange: number = item.children.findIndex(({ key }) => key === record.key);
				if (itemKeyToChange >= 0) {
					item.children[itemKeyToChange] = {
						...item.children[itemKeyToChange], levelStyle: { ...record.levelStyle, signalType: sinal }
					}
				}
			}
			return item;
		})
		setTableData(updatedData);
	};

	const popoverSinalContent = (selected: SignalType, record: LevelReport) => {
		const isOriginalSelected: boolean = selected === SignalType.ORIGINAL;
		const isPositiveSelected: boolean = selected === SignalType.POSITIVE;
		const isNegativeSelected: boolean = selected === SignalType.NEGATIVE;
		return (<>
			<Row>
				<Col span={24}>
					<Button
						type="text"
						className={isOriginalSelected ? "sinal-selected" : ""}
						onClick={() => {
							handleChangeSinal(SignalType.ORIGINAL, record)
						}}
					>
						<p>
							{translateSignalType["ORIGINAL"]}
						</p>
						{isOriginalSelected &&
							<Icon icon="carbon:checkmark" />
						}
					</Button>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Button
						type="text"
						className={isPositiveSelected ? "sinal-selected" : ""}
						onClick={() => {
							handleChangeSinal(SignalType.POSITIVE, record)
						}}
					>
						<p>
							{translateSignalType["POSITIVE"]}
						</p>
						{isPositiveSelected &&
							<Icon icon="carbon:checkmark" />
						}
					</Button>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Button
						type="text"
						className={isNegativeSelected ? "sinal-selected" : ""}
						onClick={() => {
							handleChangeSinal(SignalType.NEGATIVE, record)
						}}
					>
						<p>
							{translateSignalType["NEGATIVE"]}
						</p>
						{isNegativeSelected &&
							<Icon icon="carbon:checkmark" />
						}
					</Button>
				</Col>
			</Row>
		</>)
	}

	useEffect(() => {
		if (reportType === ReportType.REVENUE || !reportType) {
			setCurrentColumns(defaultColumns);
		} else {
			const sinalColumn: (ColumnTypes[number] & { editable?: boolean; dataIndex: string, EXPAND_COLUMN?: boolean }) = {
				dataIndex: "sinal",
				key: "sinal",
				title: "Sinal",
				width: 90,
				align: "center",
				render: (_, record: LevelReport) => {
					const { levelStyle: { signalType } } = record;

					return (
						<Popover overlayClassName="sinal-type-content" trigger={"click"} content={popoverSinalContent(signalType, record)}>
							<Button type="text">
								<p>
									{translateSignalType[SignalType[signalType]]}
								</p>
							</Button>
						</Popover>
					)
				},
			};
			const newColumns = [...defaultColumns.slice(0, 3), sinalColumn, ...defaultColumns.slice(3)]
			setCurrentColumns(newColumns);
		}
	}, [reportType, tableData, popoverVisibleId])

	return (
		<Table
			columns={columns as ColumnTypes}
			dataSource={tableData.sort((a, b) => a.ordination - b.ordination)}
			rowSelection={rowSelection}
			className="gs-table"
			pagination={false}
			rowKey="ordination"
			onExpand={handleExpand}
			expandedRowKeys={expandedRowKeys}
			scroll={{
				x: 500, y: 500
			}}
			components={{
				body: {
					row: EditableRow,
					cell: EditableCell,
				}
			}}
			onRow={(_, index) => {
				const attr = {
					index,
				};
				return attr as React.HTMLAttributes<any>;
			}}
			bordered
			expandable={{
				expandIconColumnIndex: 4
			}}
			expandIcon={({ expanded, onExpand, record }: any) => {
				if (record.children) {
					if (expanded) {
						return (
							<span
								className="button-expandable-tree"
								onClick={e => onExpand(record, e)}
							>
								{record.children.length > 0 && (
									<Icon icon="tabler:chevron-down" />
								)}
							</span>
						)
					}
					return (
						<span
							className="button-expandable-tree"
							onClick={e => onExpand(record, e)}
						>
							{record.children.length > 0 && (
								<Icon icon="tabler:chevron-right" />
							)}
						</span>
					)
				}
			}}
		/>
	)
}