import { Modal } from "antd";
import { useContext } from "react";
import i18n from "util/base/i18n";
import { ReportViewContext } from "../../../../context/ReportViewContext";
import TransferModal from "./Transfer";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { IReportConfig } from "../../../Filters/IFilters";

interface DetailModalProps {
    isDetailModalOpen: boolean;
    handleDetailModal: (value: boolean) => void;
}

export default function DetailModal({ isDetailModalOpen, handleDetailModal }: Readonly<DetailModalProps>) {
    const { fetchReport, reportConfig, handleIsDetailingLevel, handleReportConfig, period, selectedModalityType,
        selectedCurrency, selectedPeriodicityType, periodBase, rowsIdToDetail, handleRowsIdToDetail } = useContext(ReportViewContext);

    function getPeriodicityByInt(int: number): string {
        switch (int) {
            case 1:
                return "MONTHLY";
            case 2:
                return "BIMONTHLY";
            case 3:
                return "QUARTERLY";
            case 6:
                return "SEMESTERLY";
            case 12:
                return "YEARLY";
            default:
                return "MONTHLY";
        }
    }

    function handleOk() {
        const isComparativeScenarioYears = selectedModalityType === "COMPARATIVE_SCENARIO_YEARS";
        const startDate = isComparativeScenarioYears ? periodBase[1].isBefore(periodBase[0]) ? periodBase[1].format("YYYY-MM") : periodBase[0].format("YYYY-MM") : period[1].format("YYYY-MM");
        const endDate = isComparativeScenarioYears ? periodBase[0].isAfter(periodBase[1]) ? periodBase[0].format("YYYY-MM") : periodBase[1].format("YYYY-MM") : period[0].format("YYYY-MM");

        const newReportConfig = {
            id: reportConfig.id,
            startPeriod: startDate,
            endPeriod: endDate,
            periodType: getPeriodicityByInt(selectedPeriodicityType),
            currencyId: selectedCurrency,
            modalityType: selectedModalityType,
        };

        ServiceCaller.doRequest(
            {
                url: `/revenue/report/configuration`,
                type: RequestType.POST,
                params: newReportConfig
            },
            (data: IReportConfig) => {
                handleReportConfig(data);
                fetchReport(data, true);
                handleIsDetailingLevel(true);
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );

        handleRowsIdToDetail([]);
        handleDetailModal(false);
    }

    function handleCancel() {
        handleDetailModal(false);
        handleRowsIdToDetail([]);
    }

    return (
        <Modal
            title={i18n.t<string>("detail")}
            open={isDetailModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            okButtonProps={{ disabled: rowsIdToDetail.length === 0 }}
        >
            <TransferModal />
        </Modal>
    )
}