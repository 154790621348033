import { cloneDeep } from "lodash";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FlexFieldValueListBean, FlexFilter } from "util/types/types";

export async function updateFlexFieldFiltersValue(filter: string, form, flexFieldOptions, setFlexFieldOptions, organization?) {

    const costCenter = form.getFieldValue(`costCenter`);
    const account = form.getFieldValue(`typaccount`);
    const businessUnit = form.getFieldValue(`businessUnit`);

    const updatedFlexField = cloneDeep(flexFieldOptions);
    const promises = updatedFlexField.map(flexField => {
        if (flexField.linkedFilters && flexField.linkedFilters.includes(filter)) {
            flexField.isFetching = true;
            return new Promise<void>((resolve, reject) => {
                ServiceCaller.doRequest({
                    type: RequestType.GET,
                    url: `/budget-base/flex-field-value?user={user}&flexFieldId=${flexField.value}`
                }, (data: FlexFieldValueListBean[]) => {
                    flexField.children = data
                        .filter(item => item.accountingAccountFilters ? item.accountingAccountFilters.includes(account) : true)
                        .filter(item => item.costCenterFilters ?  item.costCenterFilters.includes(costCenter) : true)
                        .filter(item => item.businessUnitFilters ? item.businessUnitFilters.includes(businessUnit) : true)
                        .filter(item => item.organizationFilters ? item.organizationFilters.includes(organization) : true)
                        .map(item => ({
                            value: item.id,
                            label: item.externalCode + ' - ' + item.description,
                            linkedParentFlexFieldId: item.flexFieldFilters
                        }));
                    flexField.selectedOption = [];
                    flexField.isFetching = false;
                    resetChildFieldsValues(updatedFlexField, setFlexFieldOptions, filter, false, form);
                    resolve();
                }, (err) => {
                    handleErrorRequest(err);
                    flexField.isFetching = false;
                    setFlexFieldOptions(updatedFlexField);
                    reject();
                });
            });

        }
        return Promise.resolve();
    });
    promises.then(() => setFlexFieldOptions(updatedFlexField));
    return Promise.all(promises);
}

function resetChildFieldsValues(flexFields: FlexFilter[], setFlexFieldOptions, filter: string | number, clearValues: boolean, form) {
    flexFields.forEach(field => {
        if (field.linkedFilters && field.linkedFilters.includes(filter.toString())) {
            form.resetFields([`ff-${field.value}`]);
            if (clearValues) {
                field.children = [];
            }
            resetChildFieldsValues(flexFields, setFlexFieldOptions, field.value, true, form);
        }
    })
    setFlexFieldOptions(flexFields);
}