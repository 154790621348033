import { FormattedNumber } from "react-intl";
import i18n from "util/base/i18n";
import { CellContent } from "../../../CellContent";

export function buildColumnComparativeTotalConsolidation(reportVisualizationFilters, rowsFromBackend) {
    const columns = ["consolidation", "elimination"].map(column => ({
        title: i18n.t<string>(`total_${column}`),
        dataIndex: `total${capitalizeFirstLetter(column)}`,
        children: getColumns(reportVisualizationFilters, rowsFromBackend, column)
    }));

    return columns;
}

function getColumns(reportVisualizationFilters, rowsFromBackend, column) {
    const columns = [
        { titleKey: 'total_planned', dataIndex: 'totalValueBase', renderFunc: renderDecimalValue },
        { titleKey: 'total_variation_percente_planned_accomplished', dataIndex: 'totalVariationPercenteBaseTarget', renderFunc: renderPercentValue },
        { titleKey: 'total_accomplished', dataIndex: 'totalValueTarget', renderFunc: renderDecimalValue }
    ];

    return columns.map(({ titleKey, dataIndex, renderFunc }) => ({
        title: i18n.t<string>(titleKey),
        dataIndex,
        align: 'center',
        key: 'total',
        fixed: 'right',
        width: 90,
        render: (text, record) => {
            const currentRow = findCurrentRow(rowsFromBackend, record);

            const textDecoration = getTextDecoration(record);
            if (record.title) {
                return <CellContent currentRow={currentRow} textDecoration={textDecoration} />;
            }

            let renderedValue = getRenderedValue(record, column, dataIndex);
            if (reportVisualizationFilters.showThousand && dataIndex !== 'totalVariationPercenteBaseTarget') {
                renderedValue /= 1000;
            }

            return (
                <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                    {renderFunc(renderedValue, reportVisualizationFilters, currentRow)}
                </CellContent>
            );
        }
    }));
}

function renderDecimalValue(value, reportVisualizationFilters, record) {
    return (
        <FormattedNumber
            value={value}
            style="decimal"
            currencyDisplay="symbol"
            currency={record.isoCode || "BRL"}
            minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
            maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
        />
    );
}

function renderPercentValue(value, reportVisualizationFilters) {
    return (
        <FormattedNumber
            value={value / 100}
            style="percent"
            minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
            maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
        />
    );
}

function findCurrentRow(rowsFromBackend, record) {
    return (
        rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id) ||
        rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.name === columnFromBack.description)
    );
}

function getTextDecoration(record) {
    let textDecoration = "";
    if (record.style?.isScratched) textDecoration += "line-through ";
    if (record.style?.isUnderlined) textDecoration += "underline";
    return textDecoration;
}

function getRenderedValue(record, column, dataIndex) {
    if (column === "elimination") {
        return record.reportValues?.totalElimination?.[dataIndex] || 0;
    } else if (column === "consolidation") {
        return record.reportValues?.totalConsolidation?.[dataIndex] || 0;
    }
    return 0;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
