import { BudgetGridData } from "../../../IFixedExpense";

export function updateParentRowValues(record: BudgetGridData) {
    Object.keys(record.plannedMonths).forEach((month) => {
        record.plannedMonths[month].plannedValue = 0;
        record.plannedMonths[month].projectedValue = 0;
        record.plannedTotal = 0;
        record.historicTotal = 0;
        record.apportionmentTotal = 0;
        record.projectedTotal = 0;
        record.quantityTotal = 0;
        record.unitValueTotal = 0;
    });

    record.detailList.forEach((budgetGridDetailRow) => {
        Object.keys(budgetGridDetailRow.plannedMonths).forEach((month) => {
            record.plannedMonths[month].plannedValue +=
                budgetGridDetailRow.plannedMonths[month].plannedValue * budgetGridDetailRow.plannedMonths[month].rate;
            record.plannedMonths[month].projectedValue += budgetGridDetailRow.plannedMonths[month].projectedValue;
            record.plannedTotal +=
                budgetGridDetailRow.plannedMonths[month].plannedValue * budgetGridDetailRow.plannedMonths[month].rate;
        });
        record.historicTotal += budgetGridDetailRow.historicTotal;
        record.projectedTotal += budgetGridDetailRow.projectedTotal;
        record.apportionmentTotal += budgetGridDetailRow.apportionmentTotal;
        record.quantityTotal += budgetGridDetailRow.quantityTotal;
        record.unitValueTotal += budgetGridDetailRow.unitValueTotal;
    });
}
