import { Form } from "antd";
import { Notification } from "components/notification/Notification";
import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import ProfessionalCategoryModal from "./components/ProfessionalCategoryModal";
import ProfessionalCategoryTable from "./components/ProfessionalCategoryTable";
import { ProfessionalCategoryTableData, ValueForm } from "./IProfessionalCategory";
import './style.sass';
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { ColumnsType } from "antd/lib/table";
import { sortList } from "util/functions/sortList";
import { invalidadeQuery, queryClient } from "util/queryClient";
import { useUserContext } from "context/UserContext";

export default function ProfessionalCategory(){

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ProfessionalCategoryTableData[]>([]);
    const [tableData, setTableData] = useState<ProfessionalCategoryTableData[]>([]);
    const [isNewCategory, setIsNewCategory] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [newCategoryList, setNewCategoryList] = useState<ValueForm[]>([]);
    const [filterdTableData, setFilterdTableData] = useState([]);
    const [form] = Form.useForm();
    const { userInfo } = useUserContext();

    const importProps: IImportModal[] = [
		{
            importUrl: "/human-resources/professional-category/import",
            templateUrl: "/human-resources/professional-category/template?locale={locale}",
            type: 'excel',
            hasImportOption: true
		},
	]

    useEffect(() => {
        fetchProfessionalCategory();
    }, []);

    function fetchProfessionalCategory() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/human-resources/professional-category?scenario={scenario}&organization={organization}",
        }, onLoadProfessionalCategoryTableData.bind(this));
    }

    useEffect(() => {
        setFilterdTableData(tableData);
    }, [tableData]);

    function onLoadProfessionalCategoryTableData(data) {
        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };

        const updateTableDate = data.map(res => {
            return {
                key: res.id,
                id: res.id,
                scenarioId: res.scenarioId,
                organizationId: res.organizationId,
                externalCode: res.externalCode,
                name: res.name,
            };
        }).sort((a, b) => sortList(a.externalCode, b.externalCode));

        setTableData(updateTableDate);
        setIsFetching(false);
    };

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: ProfessionalCategoryTableData[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    function handleOpenModal(isNewCategory: boolean) {
        if(isNewCategory) {
            setIsNewCategory(true);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setNewCategoryList([]);
            form.resetFields();
        } else {
            form.setFieldsValue({
                externalCode: selectedRows[0].externalCode,
                name: selectedRows[0].name
            });
            setIsNewCategory(false);
        }
        setIsModalVisible(true);
    };

    function handleCloseModal() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        form.resetFields();
        setIsModalVisible(false);
    };

    function handleIncludeCategory(data: ValueForm) {
        if(isNewCategory){
            setNewCategoryList([...newCategoryList, data]);
        } else {
            handleSaveCategory([data]);
        }
        form.resetFields();
    }

    function handleSaveCategory(data) {
        const categoryToSave = data.map(
        category => {
            return {
                id: isNewCategory ? null : selectedRowKeys[0],
                organizationId: userInfo.selection.organizationId,
                scenarioId: userInfo.selection.scenarioId,
                externalCode: category.externalCode,
                name: category.name
            }
        });

        console.log(categoryToSave);

        setSelectedRowKeys([]);
        setSelectedRows([]);
        
        setIsModalVisible(false);
        setIsFetching(true);

        ServiceCaller.doRequest({
            type: isNewCategory ? RequestType.POST : RequestType.PUT,
            url: '/human-resources/professional-category',
            params: isNewCategory ? categoryToSave : categoryToSave[0],  
        }, onSaveCategory.bind(this));
    }

    function onSaveCategory(response) {
        if(response){
            Notification({
                type: "success", 
                message: isNewCategory ? i18n.t<string>("successfully_saved") : i18n.t<string>("successfully_edited"), 
            });
        }
        form.resetFields();
        invalidadeQuery(['professional-category-options']);
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/human-resources/professional-category?scenario={scenario}&organization={organization}"
        }, onLoadProfessionalCategoryTableData.bind(this));
    };

    function handleDeleteCategory() {
        setIsFetching(true);
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/human-resources/professional-category?ids=${selectedRowKeys.toString()}`,
        }, onDeleteCategory.bind(this), onRequestError.bind(this));
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function onRequestError() { 
        Notification({
            type: "warning", 
            message: i18n.t("professional_category.warning_message"), 
        });
        setIsFetching(false)
    }
    
    function onDeleteCategory(response) {
        if(response){
            Notification({
                type: "success", 
                message: i18n.t<string>("successfully_deleted"), 
            });
        }
        invalidadeQuery(['professional-category-options']);
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: '/human-resources/professional-category?scenario={scenario}&organization={organization}',
        }, onLoadProfessionalCategoryTableData.bind(this)); 
    };

    const tableColumns: ColumnsType = [
        {
            title: i18n.t<string>("code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "center",
            width: 200,
        }, 
        {
            title: i18n.t<string>("description"),
            dataIndex: "name",
            key: "name",
            align: "left",
            className: 'collaborators-columns'
        }
    ];

    return (
        <>
            <div className="page-title-content">
                <h1>{i18n.t<string>("professional_category.title")}</h1>
            </div>
            <div id="top-buttons-professional-category">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t<string>("professional_category.modal_button")}
                        handleNew={() => handleOpenModal(true)}
                        handleEdit={() => handleOpenModal(false)}
                        handleDelete={handleDeleteCategory}
                        isEditable={selectedRows.length === 1}
                        isDeletable={selectedRows.length > 0}
                        multipleSearch={{
                            tableData: tableData,
                            setTableData: setFilterdTableData,
                            options: [
                                { i18nString: 'code', description: 'externalCode' },
                                { i18nString: 'description', description: 'name' },
                            ]
                        }}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(tableData, tableColumns, 'Categoria-Profissional'),
                            importProps: importProps,
                            handleSuccessImport: () => fetchProfessionalCategory()
                        }}
                    />
                </div>
            </div>
            <main id="professional-category-main">
                <ProfessionalCategoryTable
                    tableData={filterdTableData}
                    isFetching={isFetching}
                    selectedRowKeys={selectedRowKeys}
                    onChange={onSelectRowChange}
                    tableColumns={tableColumns}
                />
                <ProfessionalCategoryModal
                    isModalVisible={isModalVisible}
                    isNewCategory={isNewCategory}
                    handleSubmit={handleIncludeCategory}
                    handleCancel={handleCloseModal}
                    form={form}
                    newCategoryList={newCategoryList}
                    setNewCategoryList={setNewCategoryList}
                    handleSaveCategory={handleSaveCategory}
                    tableData={tableData}
                    selectedRowKeys={selectedRowKeys}
                />
            </main>
        </>
    )
}