import {
	useEffect,
	useState
} from "react";

import { Icon } from "@iconify/react";

import {
	Button,
	Menu
} from "antd";
import Input from "antd/lib/input/Input";
import Sider from "antd/lib/layout/Sider";

import {
	IMenuItem,
	IModuleItemResponse
} from "structure/module/IModule";

import i18n from "util/base/i18n";

import {
	MessageType,
	RequestType
} from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import StringUtil from "util/StringUtil";

import "./styles.sass";
import { useLocation, useNavigate } from "react-router-dom";
import { ROOT_PATH } from "util/rootPath";
import { useUserContext } from "context/UserContext";
import { handleRedirect } from "util/functions/handleRedirect";
import { useErrorBoundary } from "react-error-boundary";

export function FloatMenu() {
	const [module, setModule] = useState<string>("");
	const [items, setItems] = useState<IMenuItem[]>([]);
	const [searchBy, setSearchBy] = useState<string>("");
	const [openMenus, setOpenMenus] = useState<string[]>([]);
	const [openFilteredMenus, setOpenFilteredMenus] = useState<string[]>([]);
	const { isBudgetModule, isFloatMenuOpen, setIsFloatMenuOpen} = useUserContext()
	const navigate = useNavigate()
	const location = useLocation()
	const { resetBoundary } = useErrorBoundary();

	useEffect(() => {
		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: "/menu/module-items"
		}, (response: IModuleItemResponse) => {
			if (response.items.length === 0) {
				ServiceCaller.doSendMessage({
					messageType: MessageType.HIDE_FLOAT_MENU
				});
			} else {
				setModule(response.module);
				setItems(response.items);
			}
		});
	}, [location]);

	function closeDropDown(){
		if(isBudgetModule) {
			setIsFloatMenuOpen(false)
		} else {
			ServiceCaller.doSendMessage({
				messageType: MessageType.CLOSE_FLOAT_MENU
			});
		}
	};

	function doRedirect(url: string, event: MouseEvent): void {
		if (!event.ctrlKey) {
			event.preventDefault();
			resetBoundary()
			handleRedirect(url, isBudgetModule, navigate)
		}
	};

	function buildMenuItems(items: IMenuItem[]): React.ReactElement[] {
		let menuItems: React.ReactElement[] = [];

		if (items.length > 0) {
			const [ item ] = items;
			if (item.link) {
				items = getFilteredItemsBySearch(items, searchBy);
			}

			menuItems = items.sort(({ itemOrder: orderA }, { itemOrder : orderB}) => orderA - orderB).map(item => {
				if (item.menuItems) {
					const childrens: React.ReactElement[] = buildMenuItems(item.menuItems);

					if (childrens.find(child => child)) {
						return (
							<Menu.SubMenu key={`item-${item.id}`} title={item.caption}>
								{ childrens }
							</Menu.SubMenu>
						)
					}
				} else {
					return (
						<Menu.Item key={`item-${item.parentId}-subitem-${item.id}`}>
							<a href={ROOT_PATH + item.link} onClick={doRedirect.bind(this, ROOT_PATH + item.link)}>{item.caption}</a>
						</Menu.Item>
					)
				}
			});
		}

		return menuItems.filter(menuItem => menuItem);
	};

	const getFilteredItemsBySearch = (items: IMenuItem[], searchBy: string): IMenuItem[] => {
		if (searchBy.trim().length > 0) {
			const searchByText = StringUtil.getWithoutSpecials(searchBy);

			items = items.filter(({ caption }) => {
				const captionText = StringUtil.getWithoutSpecials(caption);

				return captionText.indexOf(searchByText) > -1;
			});
		}

		return items;
	}

	const onOpenMenu = (openKeys: string[]) => {
		if (searchBy.trim().length > 0) {
			setOpenFilteredMenus(openKeys);
		} else {
			setOpenMenus(openKeys);
		}
	};

	function getKeysOfMenuItems(items: IMenuItem[], searchBy: string): string[] {
		let keys: string[] = [];

		if (items.length > 0) {
			const [ item ] = items;
			if (item.link) {
				items = getFilteredItemsBySearch(items, searchBy);
			}

			items.forEach(item => {
				if (item.menuItems) {
					const childKeys: string[] = getKeysOfMenuItems(item.menuItems, searchBy);

					if (childKeys.find(child => child)) {
						keys = keys.concat(childKeys);
						keys.push(`item-${item.id}`);
					}
				} else {
					keys.push(`item-${item.parentId}-subitem-${item.id}`);
				}
			});
		}

		return keys.filter(key => key);
	};

	const onSearch = ({ target: { value } }) => {
		setSearchBy(value);

		if (value.trim().length > 0) {
			setOpenFilteredMenus(getKeysOfMenuItems(items, value));
		} else {
			setOpenFilteredMenus([]);
		}
	};

	return <>
		<Sider 
			style={{right: 0}} 
			id={isBudgetModule ? 'float-menu-budget' : "float-menu-container"} 
			width={300} 
			trigger={null} 
			collapsedWidth={0} 
			defaultCollapsed={isBudgetModule ? true : false} 
			collapsed={isBudgetModule ? !isFloatMenuOpen : false} 
			collapsible
		>
			<div id="float-menu-header">
				<Button onClick={closeDropDown} icon={<Icon icon="dashicons:arrow-right-alt2" />} />

				<p>{module}</p>
			</div>

			<div id="float-menu-search">
				<Input placeholder={i18n.t<string>("search")} value={searchBy} onChange={onSearch} />
			</div>

			<Menu id="float-menu-content" theme="light" mode="inline" selectable={false} openKeys={searchBy.trim().length > 0 ? openFilteredMenus : openMenus} onOpenChange={onOpenMenu}>
				{ buildMenuItems(items) }
			</Menu>
		</Sider>
	</>

}
