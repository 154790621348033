import { Input } from "antd";
import { IInputText } from "./IInputText";
import './styles.sass'

export function InputText({
    label, 
    id, 
    value, 
    onChange, 
    placeholder,
    containerStyle,
    inputStyle
}: IInputText) {
    return (
        <label style={containerStyle} className="gs-input-label" htmlFor={id}>
            {label}
            <Input
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                style={inputStyle}
            />
        </label>
    )
}