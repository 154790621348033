import * as React from "react";

import "./CurrencyConfig.sass";

import {
	Button,
	Row,
	Typography
} from "antd";
import { Icon } from "@iconify/react";

import i18n from "util/base/i18n";

import {
	ICurrencyConfigProps,
	ICurrencyConfigState
} from "./ICurrencyConfig";

import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

import { Lookup } from "components";

export default class CurrencyConfig extends React.Component<ICurrencyConfigProps, ICurrencyConfigState> {

	constructor(props: ICurrencyConfigProps) {
		super(props);

		this.state = {
			selectedCurrencies: [...props.selectedCurrencies]
		};
	}

	onChangeSelectedCurrency(index: number, currency: number): void {
		let {
			selectedCurrencies
		} = this.state;

		selectedCurrencies[index] = currency;

		this.setState({
			selectedCurrencies
		});
	}

	buildSelectedCurrencies(): Array<React.ReactElement> {
		const {
			currencies
		} = this.props;

		const {
			selectedCurrencies
		} = this.state;

		return selectedCurrencies.map((selectedCurrency: number, index: number) => <>
				<Row>
					<Lookup selected={selectedCurrency} options={currencies} onSelect={this.onChangeSelectedCurrency.bind(this, index)} suffixIcon={<Icon icon={"material-symbols:keyboard-arrow-down"} />} />
				</Row>
			</>
		)
	}

	onChangeCurrencies(): void {
		const {
			selectedCurrencies
		} = this.state;

		const {
			configId,
			onChangeCurrencies
		} = this.props;

		ServiceCaller.doRequest({
			type: RequestType.PUT,
			url: `/dashboard-analytics/configuration/${configId}/selected-currency`,
			params: selectedCurrencies
		}, onChangeCurrencies.bind(this, selectedCurrencies));
	}

	render() {
		return <>
			<Row>
				<Typography.Text type="secondary">{i18n.t<string>("dashboard.analytics.text_001")}</Typography.Text>
			</Row>

			<Row>
				<Typography.Title level={5}>{i18n.t<string>("dashboard.analytics.major_currencies")}</Typography.Title>
			</Row>

			{ this.buildSelectedCurrencies() }

			<Row>
				<Button type="primary" onClick={this.onChangeCurrencies.bind(this)}>{i18n.t<string>("save")}</Button>
			</Row>
		</>
	}

}
