import Table from "antd/es/table";
import { Loading } from "components/loading/Loading";
import { useAccountingLedgerContext } from "../context/AccountingLedgerContext";
import i18n from "util/base/i18n";
import { expandIcon } from "../../revenue/mainFlow/grid/row/ExpandIcon";

export function GridAccountingLedger() {
    const { tableData, columns, pagination, isLoading } = useAccountingLedgerContext();
    const dateColumns = ['release_date'];
    const valueColumns = ['entry_value'];

    const columnsWithNoWrap = columns.map(col => ({
        ...col,
        onCell: () => {
            const keyAsString = String(col.key);
            if (dateColumns.includes(keyAsString)) {
                return { className: 'alignment-date-cell' };
            } else if (valueColumns.includes(keyAsString)) {
                return { className: 'alignment-value-cell' };
            } else {
                return { className: 'nowrap-cell' };
            }
        }
    }));

    return (
        <section className="accounting-ledger-container-section">
            {isLoading ? (
                <Loading />
            ) : tableData.length > 0 ? (
				<div className="content-table">
				    <div>
				        <Table
				            className="gs-table"
				            id="accounting-ledger-table"
				            dataSource={tableData}
				            columns={columnsWithNoWrap}
				            loading={isLoading}
				            scroll={{ x: 'max-content', y: 450 }}
				            expandIcon={expandIcon}
				            bordered
				            pagination={pagination}
				        />
				    </div>
                </div>
            ) : (
                <div className="accounting-ledger-no-data">
                    <img src="/assets/images/budget.png" alt="wfn-budget" />
                    <h2>{i18n.t("select_filters_accounting_ledger")}</h2>
                </div>
            )}
        </section>
    );
}