import { Button, Checkbox } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { TableData } from "../IItemSecurity";
import i18n from "util/base/i18n";
import { FunctionalityPermissions } from "util/types/types";

interface TableProps {
    functionalityPermissions: FunctionalityPermissions;
    checkedAllView: boolean;
    checkedAllEdit: boolean;
    checkedAllBlocked: boolean;
    filteredData: TableData[];
    tableData: TableData[];
    isFetching: boolean;
    handleSave: () => void;
    setTableData: (value: TableData[]) => void;
    setCheckedAllView: React.Dispatch<React.SetStateAction<boolean>>;
    setCheckedAllEdit: React.Dispatch<React.SetStateAction<boolean>>;
    setCheckedAllBlocked: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTab?:
        | "INITIAL_INFORMATION"
        | "TRANSACTIONS"
        | "CUSTOM"
        | "LEGAL_TAXES"
        | "RESULT_QUANTITY"
        | "RESULT_VALUES"
        | "PROFESSIONAL_CATEGORY";
}

export default function ItemSecurityTable({
    functionalityPermissions,
    checkedAllView,
    checkedAllEdit,
    checkedAllBlocked,
    filteredData,
    isFetching,
    handleSave,
    tableData,
    setTableData,
    setCheckedAllView,
    setCheckedAllEdit,
    setCheckedAllBlocked,
    selectedTab,
}: TableProps) {
    const columns: ColumnsType<TableData> = [
        {
            dataIndex: "description",
            key: "description",
            width: "70%",
        },
        {
            title: (
                <div className="title-colunm-checkbox">
                    <Checkbox
                        disabled={!functionalityPermissions.edit}
                        checked={checkedAllBlocked}
                        onChange={(e) => handleIsBlockedAllChange(e.target.checked)}
                    />
                    <span>{i18n.t("blocked")}</span>
                </div>
            ),
            dataIndex: "isBlocked",
            key: "isBlocked",
            render: (text, record) => (
                <Checkbox
                    disabled={!functionalityPermissions.edit}
                    checked={record.isBlocked}
                    onChange={(e) => handleIsBlockedChange(record, e.target.checked)}
                />
            ),
        },
        {
            title: (
                <div className="title-colunm-checkbox">
                    <Checkbox
                        disabled={!functionalityPermissions.edit}
                        checked={checkedAllView}
                        onChange={(e) => handleCanViewAllChange(e.target.checked)}
                    />
                    <span>{i18n.t("view")}</span>
                </div>
            ),
            dataIndex: "canView",
            key: "canView",
            render: (text, record) => (
                <Checkbox
                    disabled={!functionalityPermissions.edit}
                    checked={record.canView}
                    onChange={(e) => handleCanViewChange(record, e.target.checked)}
                />
            ),
        },
        {
            title: (
                <div className="title-colunm-checkbox">
                    <Checkbox
                        disabled={!functionalityPermissions.edit}
                        checked={checkedAllEdit}
                        onChange={(e) => handleCanEditAllChange(e.target.checked)}
                    />
                    <span>{i18n.t("edit")}</span>
                </div>
            ),
            dataIndex: "canEdit",
            key: "canEdit",
            render: (text, record) => (
                <Checkbox
                    disabled={!functionalityPermissions.edit}
                    checked={record.canEdit}
                    onChange={(e) => handleCanEditChange(record, e.target.checked)}
                />
            ),
        },
    ];

    function handleIsBlockedChange(record: TableData, checked: boolean) {
        const newData = [...tableData];
        const target = newData.find((item) => item.key === record.key);
        target.isBlocked = checked;
        if (checked) {
            target.canView = false;
            target.canEdit = false;
        }
        setTableData(newData);
    }

    function handleIsBlockedAllChange(checked: boolean) {
        const newData = [...tableData];
        newData.forEach((item) => {
            if (selectedTab !== "PROFESSIONAL_CATEGORY") {
                if (item.inputGroup === selectedTab) {
                    item.isBlocked = checked;
                    if (checked) {
                        item.canView = false;
                        item.canEdit = false;
                    }
                }
            } else {
                item.isBlocked = checked;
                if (checked) {
                    item.canView = false;
                    item.canEdit = false;
                }
            }
        });
        setCheckedAllBlocked(checked);
        setTableData(newData);
    }

    function handleCanViewChange(record: TableData, checked: boolean) {
        const newData = [...tableData];
        const target = newData.find((item) => item.key === record.key);
        target.canView = checked;
        if (target.canEdit && !checked) {
            target.canEdit = false;
        }
        if (checked) target.isBlocked = false;
        setTableData(newData);
    }

    function handleCanViewAllChange(checked: boolean) {
        const newData = [...tableData];
        newData.forEach((item) => {
            if (selectedTab !== "PROFESSIONAL_CATEGORY") {
                if (item.inputGroup === selectedTab) {
                    item.canView = checked;
                    if (item.canEdit && !checked) {
                        item.canEdit = false;
                    }
                    if (checked) item.isBlocked = false;
                }
            } else {
                item.canView = checked;
                if (item.canEdit && !checked) {
                    item.canEdit = false;
                }
                if (checked) item.isBlocked = false;
            }
        });
        setCheckedAllView(checked);
        setTableData(newData);
    }

    function handleCanEditChange(record: TableData, checked: boolean) {
        const newData = [...tableData];
        const target = newData.find((item) => item.key === record.key);
        target.canEdit = checked;
        if (checked) {
            target.canView = true;
            target.isBlocked = false;
        }
        setCheckedAllEdit(checked);
        setTableData(newData);
    }

    function handleCanEditAllChange(checked: boolean) {
        const newData = [...tableData];
        newData.forEach((item) => {
            if (selectedTab !== "PROFESSIONAL_CATEGORY") {
                if (item.inputGroup === selectedTab) {
                    item.canEdit = checked;
                    if (checked) {
                        item.canView = true;
                        item.isBlocked = false;
                    }
                }
            } else {
                item.canEdit = checked;
                if (checked) {
                    item.canView = true;
                    item.isBlocked = false;
                }
            }
        });
        setTableData(newData);
        setCheckedAllEdit(checked);
    }

    return (
        <>
            <Table
                columns={columns}
                className="gs-table"
                dataSource={filteredData}
                pagination={{ hideOnSinglePage: true }}
                loading={{
                    spinning: isFetching,
                    tip: `${i18n.t<string>("loading")}...`,
                }}
            />
            {functionalityPermissions.edit && (
                <div className="footer-buttons">
                    {/* <Button type="link" onClick={handleCancel}>
                            Cancelar
                        </Button> */}
                    <Button className="gs-main-button" type="primary" onClick={() => handleSave()}>
                        {i18n.t<string>("save")}
                    </Button>
                </div>
            )}
        </>
    );
}
