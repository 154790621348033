import { Modal, notification } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { NotificationPlacement } from "antd/lib/notification";
import i18n from "util/base/i18n";

import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import {
    AttributeMathOperations,
    AttributeMathOperationsFromServer,
    INewAttributeModalAttribute
} from "../../../IAttributeParameterization";
import { AttributeParameterizationContext } from "../../../context/AttributeParameterizationContext";
import { CustomInputText } from "../../custom/CustomInput";
import { CustomSelect } from "../../custom/CustomSelect";
import { FormulaArea } from "../../formula/FormulaArea";
import { OperationsSelect } from "../../operators/OperationsSelect";
import { useUserContext } from "context/UserContext";

interface EditAttributeModalProps {
    data: {
        externalCode: string;
        formulaAttribute: boolean;
        grouperId: number;
        grouperName: string;
        id: number;
        name: string;
        partsFormula: any[];
    }
}

export default function EditAttributeModal({ data }: EditAttributeModalProps) {
    const { isEditAttributeModalOpen, closeEditAttributeModal } = useContext(AttributeParameterizationContext);
    const { userInfo } = useUserContext();

    const [externalCode, setExternalCode] = useState(data.externalCode);
    const [description, setDescription] = useState(data.name);
    const [attributes, setAttributes] = useState<INewAttributeModalAttribute[]>([]);
    const [formula, setFormula] = useState<any[]>(() => {
        return data.partsFormula.map((item) => {
            if (item.operationId) {
                if (item.formulaAttribute) {
                    return {
                        id: item.id,
                        type: "attribute",
                        content: {
                            id: item.operationId,
                            name: i18n.t<string>(item.operationName)
                        },
                    };
                }

                return {
                    id: item.id,
                    type: "attribute",
                    content: {
                        id: item.operationId,
                        name: item.operationName,
                    },
                };
            }

            return {
                id: item.id,
                type: "operator",
                content: AttributeMathOperationsFromServer[item.type],
            };
        });
    });

    function handleExternalCode(event: React.ChangeEvent<HTMLInputElement>) {
        setExternalCode(event.target.value);
    }

    function handleDescription(event: React.ChangeEvent<HTMLInputElement>) {
        setDescription(event.target.value);
    }

    function removeItem(index) {
        setFormula((state) => state.filter((item, itemIndex) => itemIndex !== index));
    }

    function addOperationToFormula(selectedOperation: string) {
        if (selectedOperation === "left_parenthesis" || selectedOperation === "right_parenthesis") {
            setFormula([...formula, { type: "operator", content: selectedOperation }]);
        }

        if (formula.length !== 0) {
            if (formula[formula.length - 1].type !== "operator") {
                setFormula([...formula, { type: "operator", content: selectedOperation }]);
            }
        }
    }

    function editedAttributeNotification(placement: NotificationPlacement) {
        notification.success({
            message: i18n.t<string>("revenue.attribute_edited_succesfully"),
            placement,
            duration: 3,
        });
    }

    function addAttributeToFormula(attribute: INewAttributeModalAttribute) {
        let attributeToBeAdded;

        if (attribute.name) {
            attributeToBeAdded = {
                type: "attribute",
                content: {
                    id: attribute.id,
                    name: i18n.t<string>(attribute.name),
                },
            };
        }
        else {
            attributeToBeAdded = {
                type: "attribute",
                content: {
                    id: attribute.id,
                    name: attribute.name,
                },
            };
        }

        setFormula([...formula, attributeToBeAdded]);
    }

    const loadAttributesByGrouper = useCallback(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/revenue/grouper/attribute/get-by-grouper?id=${data.grouperId}&scenario=${userInfo.selection.scenarioId}&organization=${userInfo.selection.organizationId}`,
            },
            (response: INewAttributeModalAttribute[]) => {
                setAttributes(response);
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }, [data.grouperId]);

    function showFormulaAttributes() {
        if (data.grouperName === 'FIXED_EXPENSE') {
            return false;
        }

        if (data.grouperName === 'MARKETING_COST_FIXED') {
            return false;
        }

        return true;
    }

    function handleOK() {
        const preparedFormula = formula.map((item, index) => {
            if (item.type === "attribute") {
                if (!item.id) {
                    return {
                        operationAttributeId: item.content["id"],
                        orderPosition: index + 1,
                    };
                }

                return {
                    id: item.id,
                    operationAttributeId: item.content["id"],
                    orderPosition: index + 1,
                };
            }

            if (!item.id) {
                return {
                    operationCalcType: AttributeMathOperations[item.content],
                    orderPosition: index + 1,
                };
            }

            return {
                id: item.id,
                operationCalcType: AttributeMathOperations[item.content],
                orderPosition: index + 1,
            };
        });

        if (data.grouperName === 'FIXED_EXPENSE' || data.grouperName === 'MARKETING_COST_FIXED') {
            const dataToBeSent = {
                attributeId: data.id,
                description,
                externalCode
            };

            ServiceCaller.doRequest(
                {
                    type: RequestType.PUT,
                    url: `/revenue/grouper/attribute`,
                    params: dataToBeSent,
                },
                (response) => {
                    editedAttributeNotification("topRight");
                    closeEditAttributeModal();
                },
                (err: ErrorRequest) => {
                    handleErrorRequest(err);
                }
            );
        } else {
            const dataToBeSent = {
                attributeId: data.id,
                description,
                externalCode,
                partsFormula: preparedFormula,
            };

            ServiceCaller.doRequest(
                {
                    type: RequestType.PUT,
                    url: `/revenue/grouper/attribute`,
                    params: dataToBeSent,
                },
                (response) => {
                    editedAttributeNotification("topRight");
                    closeEditAttributeModal();
                },
                (err: ErrorRequest) => {
                    handleErrorRequest(err);
                }
            );
        }
    }

    function handleCancel() {
        closeEditAttributeModal();
    }

    const availableAttributes = attributes
        .map((attribute) => {
            if (attribute.grouperId === 0) {
                return {
                    label: i18n.t<string>(attribute.name),
                    value: JSON.stringify(attribute),
                };
            }

            return {
                label: attribute.name,
                value: JSON.stringify(attribute),
            };
        })
        .filter((attribute) => {
            const attributeAlreadyExistsInFormula = formula.find((formulaItem) => {
                if (formulaItem.type === "operator") return null;
                return formulaItem.content.name.toLowerCase() === attribute.label.toLowerCase();
            });

            return !attributeAlreadyExistsInFormula;
        })
        .filter(attribute => {
            const deserializedeAttribute = JSON.parse(attribute.value);
            return deserializedeAttribute.id !== data.id;
        })
        .sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();

            if (labelA < labelB) {
                return -1;
            }

            if (labelA > labelB) {
                return 1;
            }

            return 0;
        });


    useEffect(() => {
        loadAttributesByGrouper();
    }, [loadAttributesByGrouper]);

    return (
        <Modal
            title={i18n.t<string>("revenue.edit_attribute")}
            visible={isEditAttributeModalOpen}
            onOk={handleOK}
            onCancel={handleCancel}
            width={800}
            wrapClassName="attribute_parameterization-new-attribute-modal"
            okText="Salvar"
            cancelText="Fechar"
            centered
            destroyOnClose
        >
            <div id="attribute_parameterization-new-attribute-modal-content-wrapper">
                <form id="attribute_parameterization-new-attribute-modal-form">
                    <div className="form-field">
                        <CustomInputText
                            id="attribute_parameterization-new-attribute-modal-form-external_code"
                            label="Código Externo"
                            onChange={handleExternalCode}
                            value={externalCode}
                            placeholder={i18n.t<string>("type_here")}
                        />
                    </div>
                    <div className="form-field">
                        <CustomInputText
                            id="attribute_parameterization-new-attribute-modal-form-description"
                            label="Descrição"
                            onChange={handleDescription}
                            value={description}
                            placeholder={i18n.t<string>("type_here")}
                        />
                    </div>
                    <div className="form-field">
                        <CustomInputText
                            id="grouper"
                            label="Agrupador"
                            onChange={() => { }}
                            value={data.grouperName}
                            disabled
                        />
                    </div>

                    {showFormulaAttributes() && (
                        <>
                            <div className="form-field">
                                <CustomSelect
                                    id="attributes"
                                    labelName="Atributos"
                                    options={availableAttributes}
                                    onChange={(selectedAttribute) => {
                                        selectedAttribute =
                                            JSON.parse(selectedAttribute);
                                        addAttributeToFormula(
                                            selectedAttribute as INewAttributeModalAttribute
                                        );
                                    }}
                                    hasSearch
                                />
                            </div>
                            <div className="form-field">
                                <OperationsSelect
                                    onChooseOperation={addOperationToFormula}
                                />
                            </div>
                        </>
                    )}
                </form>

                {showFormulaAttributes() && (
                    <div id="attribute_parameterization-new-attribute-modal-formula">
                        <h3>{i18n.t<string>('revenue.base_calculation_formula')}</h3>

                        <FormulaArea formula={formula} removeItem={removeItem} />
                    </div>
                )}
            </div>
        </Modal >
    );
}
