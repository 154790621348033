import { Icon } from "@iconify/react";
import { Row, Col, Input, Checkbox, Button } from "antd";
import Search from "antd/lib/transfer/search";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { AccessType, ContractTypeData, ISalesHierarchyTableProps } from "../IPermissionSecurity";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import Table, { ColumnsType } from "antd/lib/table";
import StringUtil from "util/StringUtil";
import { ContractType } from "../../revenue/recurringContract/IRecurringContract";
import { cloneDeep } from "lodash";
import { Footer } from "antd/lib/layout/layout";
import { Notification } from 'components/notification/Notification';

export default function ContractTypeTable({
	searchPermission,
	selectedId,
	selectedMenuKey
}: ISalesHierarchyTableProps) {
	const [isLoading, setIsLoading] = useState(true);
	const [tableData, setTableData] = useState<ContractTypeData[]>([]);
	const [initialTableData, setInitialTableData] = useState<ContractTypeData[]>([]);
	const [isSavingPermission, setIsSavingPermission] = useState(false);
	const columns: ColumnsType<ContractTypeData> = [
		{
			title: "",
			dataIndex: "title",
			key: "title",
			render: (_, record) => {
				return <p style={{ margin: 0, padding: "0 10px" }}> {chooseOptionsContractType[record.contractType]}</p>
			}
		},
		{
			title:
				<div className="title-colunm-checkbox">
					<Checkbox
						checked={isAllChecked(AccessType.BLOCKED)}
						onChange={(e) => onSelectAll(AccessType.BLOCKED)}
					/>
					<span>{i18n.t("block")}</span>
				</div>,
			dataIndex: "block",
			key: "block",
			width: 180,
			render: (_, { contractType, accessType }: ContractTypeData) => {
				const isChecked: boolean = accessType === AccessType.BLOCKED;
				return (
					<Checkbox
						checked={isChecked}
						onChange={() => changePermission(contractType, AccessType.BLOCKED)}
					/>
				)
			},
		},
		{
			title:
				<div className="title-colunm-checkbox">
					<Checkbox
						checked={isAllChecked(AccessType.ALLOWED)}
						onChange={(e) => onSelectAll(AccessType.ALLOWED)}
					/>
					<span>{i18n.t("release")}</span>
				</div>,
			dataIndex: "allowed",
			key: "allowed",
			width: 180,
			render: (_, { contractType, accessType }: ContractTypeData) => {
				const isChecked: boolean = accessType === AccessType.ALLOWED;
				return (
					<Checkbox
						checked={isChecked}
						onChange={() => changePermission(contractType, AccessType.ALLOWED)}
					/>
				)
			},
		},
	]

	function onSelectAll(accessTypeToSet: AccessType) {
		if (isAllChecked(accessTypeToSet)) {
			accessTypeToSet = null;
		}

		setTableData(state => {
			const newData = cloneDeep(state);

			return newData.map((data) => ({ ...data, accessType: accessTypeToSet }));
		})
	}

	function isAllChecked(accessTypeToCompare: AccessType) {
		return tableData.every(({ accessType }) => accessType === accessTypeToCompare);
	}

	function changePermission(contractTypeToChange: ContractType, accessType: AccessType) {
		setTableData(state => {
			const newData = cloneDeep(state);
			const newItemIndex = newData.findIndex(({ contractType }) => contractType === contractTypeToChange);
			const itemToEdit = newData[newItemIndex];
			newData.splice(newItemIndex, 1, { ...itemToEdit, accessType: itemToEdit.accessType === accessType ? null : accessType });
			return newData;
		});
	}

	const chooseOptionsContractType = {
		RECURRING: i18n.t("recurrent"),
		NOT_RECURRING: i18n.t("non_recurring"),
		NEW_SALES: i18n.t("new_sales"),
		CHURN: i18n.t("churn"),
		NEW_NEGATIVE_SALES: i18n.t("new_negative_sales"),
		NEW_SALES_SMS: i18n.t("new_sales_sms"),
	}

	function handleChangeTableData(data: ContractTypeData[]) {
		const newData = data.map((item, index) => {
			return {
				key: index,
				...item
			}
		}).filter((item => chooseOptionsContractType[item.contractType] )).sort((itemA, itemB) => chooseOptionsContractType[itemA.contractType].localeCompare(chooseOptionsContractType[itemB.contractType]));

		setTableData(newData);
		setInitialTableData(newData);
	}

	function onLoadContracts(data: ContractTypeData[]) {
		handleChangeTableData(data);
		setIsLoading(false);
	}

	function handleCancelChanges() {
		setTableData(initialTableData);
	}

	function onSave(data: ContractTypeData[]) {
		handleChangeTableData(data);
		setIsSavingPermission(false);
		Notification({
			type: "success",
			message: "Permissão salva com sucesso.",
		})
	}

	function handleSavePermissions() {
		setIsSavingPermission(true);
		const dataToSave = tableData.map((line) => {
			const groupId = selectedMenuKey === "group" ? line.groupId : null;
			const userId = selectedMenuKey === "user" ? line.userId : line.userId;
			return ({
				...line,
				groupId,
				userId
			})
		})
		ServiceCaller.doRequest({
			url: "/revenue/recurring-contract-type/security",
			type: RequestType.POST,
			params: dataToSave
		}, onSave)
	}

	function getTableDataToShow(): ContractTypeData[] {
		if (searchPermission) {
			return tableData.filter(({ contractType }) => {
				if (!searchPermission) return true;

				return StringUtil.getWithoutSpecials(chooseOptionsContractType[contractType]).includes(StringUtil.getWithoutSpecials(searchPermission));
			});
		} else {
			return tableData;
		}
	}

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `revenue/recurring-contract-type/security/${selectedMenuKey}/${selectedId}`
		}, onLoadContracts)
	}, []);

	return (
		<div className="contract-permission-content">
			<Row>
				<Table
					loading={isLoading}
					columns={columns}
					dataSource={getTableDataToShow()}
					className="gs-table contract-type-table"
					pagination={false}
				/>
			</Row>
			<Row className="footer-content">
				<Col span={24}>
					<Footer className="permission-security-footer">
						<Button
							type="link"
							onClick={handleCancelChanges}
						>
							{i18n.t<string>("cancel")}
						</Button>
						<Button
							className="gs-main-button"
							onClick={handleSavePermissions}
							style={{ padding: '0 45px' }}
							loading={isSavingPermission}
						>
							{isSavingPermission ? i18n.t<string>('saving') : i18n.t<string>('save')}
						</Button>
					</Footer>
				</Col>
			</Row >
		</div>
	)
}