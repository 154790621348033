import {
	useState,
	useEffect
} from "react";

import "./Module.sass";

import { ItemCard } from "./widget";

import { IModuleItem, IModuleProps } from "./IModule";
import { ManageItemsModal } from "./manage/modal/ManageItemsModal";
import { Header } from "./header/Header";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Spin } from "antd";
import { useLocalStorage } from "hooks/useLocalStorage";

export function Module(props: IModuleProps) {
	const [items, setItems] = useState<IModuleItem[]>([]);
	const [pendencies, setPendencies] = useState<number>(0);
	const [isFetchingItems, setIsFetchingItems] = useState(false);

	const updateItems = (newItems: IModuleItem[]): void => {
		setItems(newItems);
	};

	useEffect(() => {
		setIsFetchingItems(true);
		ServiceCaller.doRequest({
			useProxy: false,
			type: RequestType.GET,
			url: `/module/items?module=${props.code}`,
		}, (data) => {
			const formattedItems = data.map((item) => {
				return {
					...item,
					key: `item-${item.id || item.index}`,
				};
			}).sort(({ index: indexA }, { index: indexB }) => indexA - indexB);
			setItems(formattedItems);
			setIsFetchingItems(false);
		});
	}, [props.code]);

	return <>
		<div className="page-container">
			<Header icon={props.icon} title={props.title} pendencies={pendencies} />

			<section style={{ width: "95%" }}>
				{isFetchingItems ?
					<div className="loading-spin-container">
						<Spin tip="Carregando...." />
					</div>
					:
					<div className="card-wrapper">
						{
							items.filter(({ active }) => active).map(item => {
								return <ItemCard key={item.key} title={item.name} link={item.link} icon={item.icon} />
							})
						}
						<ManageItemsModal items={items} updateItems={updateItems} />
					</div>
				}
			</section>
		</div>
	</>;

}
