import { Form } from "antd";
import { InputText } from "components/input/text/InputText";
import i18n from "util/base/i18n";
import { CustomSelect } from "../../../custom/CustomSelect";
import { useContext, useEffect, useState } from "react";

import { RuleModalContext } from "../../../../context/RuleModalContext";
import { AttributeParameterizationContext } from "module/budget/pages/revenue/attributeParameterization/context/AttributeParameterizationContext";
import { FormulaArea } from "../../../formula/FormulaArea";
import { OperationsSelect } from "../../../operators/OperationsSelect";
import { IGrouper, INewAttributeModalAttribute, IRuleApportionmentType } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { useUserContext } from "context/UserContext";

interface RuleInfoProps {
    hasAttributeFormula: boolean
    hasOccurrence?: boolean;
}

export function RuleInfo({ hasAttributeFormula, hasOccurrence = false }: RuleInfoProps) {
    const { tableData } = useContext(AttributeParameterizationContext);

    const {
        externalCode,
        description,
        apportionmentType,
        handleApportionmentType,
        handleExternalCode,
        handleDescription,
        handleOrder,
        formula,
        setFormula,
    } = useContext(RuleModalContext);

    const { userInfo } = useUserContext();

    const [attributes, setAttributes] = useState<INewAttributeModalAttribute[]>([]);

    function createOrderOptions(n: number) {
        const arr = [];

        for (let i = 1; i <= n; i++) {
            arr.push({ label: i, value: i })
        }

        arr.push({
            label: arr.length + 1,
            value: arr.length + 1,
        });

        return arr;
    }

    function getModalGrouper(): Promise<IGrouper> {
        return new Promise((resolve, reject) => {
            ServiceCaller.doRequest(
                {
                    type: RequestType.GET,
                    url: `/revenue/grouper?isSidebar=false`,
                },
                (response: IGrouper[]) => {
                    const grouper = response.find(grouper => grouper.name === "FIXED_EXPENSE");
                    resolve(grouper);
                }, (error) => {
                    reject(error);
                }
            );
        });
    }

    async function loadAttributes() {
        const grouper = await getModalGrouper();

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/revenue/grouper/attribute/get-by-grouper?id=${grouper.id}&scenario=${userInfo.selection.scenarioId}&organization=${userInfo.selection.organizationId}`,
            },
            (response: INewAttributeModalAttribute[]) => {
                setAttributes(response);
            }
        );
    }

    function addAttributeToFormula(attribute: INewAttributeModalAttribute) {
        const itemAlreadyExistsInFormula = formula.some((item) => {
            if (item.type === "attribute") {
                return item.content.id === attribute.id;
            }

            return false;
        });

        if (!itemAlreadyExistsInFormula) {
            if (attribute.grouperId === 0) {
                const contentWithCaption = {
                    ...attribute,
                    name: i18n.t<string>(attribute.name),
                };

                setFormula([
                    ...formula,
                    {
                        type: "attribute",
                        content: contentWithCaption,
                    },
                ]);

                return;
            }

            if (formula.length === 0) {
                setFormula([
                    ...formula,
                    {
                        type: "attribute",
                        content: attribute,
                    },
                ]);

                return;
            }

            if (formula[formula.length - 1].type !== "attribute") {
                setFormula([
                    ...formula,
                    {
                        type: "attribute",
                        content: attribute,
                    },
                ]);
            }
        }
    }

    function removeItem(index) {
        setFormula((state) =>
            state.filter((item, itemIndex) => itemIndex !== index)
        );
    }

    function addOperationToFormula(selectedOperation: string) {
        if (selectedOperation === "left_parenthesis" || selectedOperation === "right_parenthesis") {
            setFormula([
                ...formula,
                { type: "operator", content: selectedOperation },
            ]);
        }

        if (formula.length !== 0) {
            if (formula[formula.length - 1].type !== "operator") {
                setFormula([
                    ...formula,
                    { type: "operator", content: selectedOperation },
                ]);
            }
        }
    }

    useEffect(() => {
        if (hasAttributeFormula) {
            loadAttributes();
        }
    }, []);

    const orderOptions = createOrderOptions(tableData.length);
    const apportionmentOptions = [{
        label: i18n.t<string>('gross_revenue'),
        value: IRuleApportionmentType.REVENUE,
    }, {
        label: "Ocorrência",
        value: IRuleApportionmentType.OCCURRENCE,
    }];

    const availableAttributes = attributes
        .map((attribute) => {
            if (attribute.grouperId === 0 || attribute.grouperId === null) {
                return {
                    label: i18n.t<string>(attribute.name),
                    value: JSON.stringify(attribute),
                };
            }

            return {
                label: attribute.name,
                value: JSON.stringify(attribute),
            };
        })
        .filter((attribute) => {
            const attributeAlreadyExistsInFormula = formula.find((formulaItem) => {
                if (formulaItem.type === "operator") return null;
                return formulaItem.content.name.toLowerCase() === attribute.label.toLowerCase();
            });

            return !attributeAlreadyExistsInFormula;
        });

    return (
        <div id="attribute_parameterization-new-rule-modal-content-wrapper">
            <Form className={hasOccurrence ? "attribute_parameterization-new-rule-modal-form-df" : "attribute_parameterization-new-rule-modal-form"}>
                <div className="form-field">
                    <InputText
                        id="attribute_parameterization-new-attribute-modal-form-external_code"
                        label={i18n.t<string>("external_code")}
                        onChange={handleExternalCode}
                        value={externalCode}
                        placeholder={i18n.t<string>("type_here")}
                    />
                </div>
                <div className="form-field">
                    <InputText
                        id="attribute_parameterization-new-attribute-modal-form-external_description"
                        label={i18n.t<string>("description")}
                        onChange={handleDescription}
                        value={description}
                        placeholder={i18n.t<string>("type_here")}
                    />
                </div>
                <div className="form-field">
                    <CustomSelect
                        id="order"
                        labelName={i18n.t<string>("order")}
                        onChange={handleOrder}
                        options={orderOptions}
                    />
                </div>

                {hasOccurrence && (
                    <div className="form-field">
                        <CustomSelect
                            id="order"
                            labelName={"Ocorrência"}
                            onChange={handleApportionmentType}
                            value={apportionmentType}
                            options={apportionmentOptions}
                        />
                    </div>
                )}
            </Form>

            {hasAttributeFormula && (
                <div className="attribute_parameterization-new-rule-modal-info-apportionment">
                    <div className="attribute_parameterization-new-rule-modal-info-formula">
                        <div className="form-field">
                            <CustomSelect
                                id="attributes"
                                labelName={i18n.t<string>("attributes")}
                                disabled={!attributes.length}
                                options={
                                    availableAttributes
                                        .sort((a, b) => {
                                            const textA = a?.label.toUpperCase();
                                            const textB = b?.label.toUpperCase();
                                            return textA < textB ? -1 : textA > textB ? 1 : 0;
                                        })
                                        .map((attribute) => {
                                            return {
                                                label: attribute.label,
                                                value: attribute.value,
                                            };
                                        })
                                }
                                onChange={(selectedAttribute) => {
                                    selectedAttribute = JSON.parse(selectedAttribute);
                                    addAttributeToFormula(selectedAttribute as INewAttributeModalAttribute);
                                }}
                            />
                        </div>
                        <div className="form-field new-rule-info-operations-select">
                            <OperationsSelect onChooseOperation={addOperationToFormula} />
                        </div>
                    </div>

                    <FormulaArea
                        formula={formula}
                        removeItem={removeItem}
                        setFormula={setFormula}
                    />
                </div>
            )}
        </div>
    );
}
