import { Icon } from "@iconify/react";
import { Button, Col, Row } from "antd";
import { useState } from "react";
import TabsContent from "./components/TabsContent";
import TabsSiderContent from "./components/TabsSiderContent";
import './style.sass';
import i18n from "util/base/i18n";

export default function ItemSecurity() {

	const [selectedSideTab, setSelectedSideTab] = useState<'user' | 'group'>("group");
	const [selectedSideKey, setSelectedSideKey] = useState(0);

	return (
		<div className="budget-collaborators-item-security">
			<div className="page-title-content">
				<h1>{i18n.t("item_security")}</h1>
				{/* <Button
					onClick={() => { }}
					style={{ borderRadius: 4 }}
					icon={<Icon style={{ color: '#4F9ACF', fontSize: 26 }} icon="clarity:info-line" />}
				/> */}
			</div>
			<Row gutter={10} className="page-content">
				<Col span={5}>
					<TabsSiderContent
						setSelectedTab={setSelectedSideTab}
						selectedTab={selectedSideTab}
						setSelectedSideKey={setSelectedSideKey}
						selectedSideKey={selectedSideKey}
					/>
				</Col>
				<Col span={19}>
					<TabsContent selectedSideKey={selectedSideKey} selectedSideTab={selectedSideTab} />
				</Col>
			</Row>
		</div>
	)
} 