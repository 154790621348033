import { Modal, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import { NotificationPlacement } from "antd/lib/notification";
import { evaluate } from "mathjs";
import i18n from "util/base/i18n";

import { InputText } from "components/input/text/InputText";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import {
    AttributeMathOperations,
    FormulaItem,
    IGrouper,
    INewAttributeModalAttribute,
} from "../../../IAttributeParameterization";
import { AttributeParameterizationContext } from "../../../context/AttributeParameterizationContext";
import { CustomSelect } from "../../custom/CustomSelect";
import { FormulaArea } from "../../formula/FormulaArea";
import { OperationsSelect } from "../../operators/OperationsSelect";
import { useUserContext } from "context/UserContext";

export default function NewAttributeModal() {
    const { isNewAttributeModalOpen, closeNewAttributeModal, reloadTable } = useContext(AttributeParameterizationContext);

    const [groupersList, setGroupersList] = useState<IGrouper[]>([]);
    const [externalCode, setExternalCode] = useState("");
    const [description, setDescription] = useState("");
    const [selectedGrouperInForm, setSelectedGrouperInForm] = useState(null);
    const [attributes, setAttributes] = useState<INewAttributeModalAttribute[]>([]);
    const [formula, setFormula] = useState<FormulaItem[]>([]);
    const { userInfo } = useUserContext();

    function handleExternalCode(event: React.ChangeEvent<HTMLInputElement>) {
        setExternalCode(event.target.value);
    }

    function handleDescription(event: React.ChangeEvent<HTMLInputElement>) {
        setDescription(event.target.value);
    }

    function loadAttributesByGrouper(grouperId: number) {
        setSelectedGrouperInForm(
            groupersList.find((grouper) => grouper.id === grouperId)
        );

        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/revenue/grouper/attribute/get-by-grouper?id=${grouperId}&scenario=${userInfo.selection.scenarioId}&organization=${userInfo.selection.organizationId}`,
            },
            (response: INewAttributeModalAttribute[]) => {
                setAttributes(response);
            }
        );
    }

    function addAttributeToFormula(attribute: INewAttributeModalAttribute) {
        const itemAlreadyExistsInFormula = formula.some((item) => {
            if (item.type === "attribute") {
                return item.content.id === attribute.id;
            }

            return false;
        });

        if (!itemAlreadyExistsInFormula) {
            if (attribute.grouperId === 0) {
                const contentWithCaption = {
                    ...attribute,
                    name: i18n.t<string>(attribute.name),
                };

                setFormula([
                    ...formula,
                    {
                        type: "attribute",
                        content: contentWithCaption,
                    },
                ]);

                return;
            }

            if (formula.length === 0) {
                setFormula([
                    ...formula,
                    {
                        type: "attribute",
                        content: attribute,
                    },
                ]);

                return;
            }

            if (formula[formula.length - 1].type !== "attribute") {
                setFormula([
                    ...formula,
                    {
                        type: "attribute",
                        content: attribute,
                    },
                ]);
            }
        }
    }

    function addOperationToFormula(selectedOperation: string) {
        // Verificar se o ultimo elemento (elemento atual) da formula é um operador, caso ele seja, não poderei adicionar outro operador na sequência
        if (selectedOperation === "left_parenthesis" || selectedOperation === "right_parenthesis") {
            setFormula([
                ...formula,
                { type: "operator", content: selectedOperation },
            ]);
        }

        if (formula.length !== 0) {
            if (formula[formula.length - 1].type !== "operator") {
                setFormula([
                    ...formula,
                    { type: "operator", content: selectedOperation },
                ]);
            }
        }
    }

    function removeItem(index) {
        setFormula((state) =>
            state.filter((item, itemIndex) => itemIndex !== index)
        );
    }

    function openInvalidFormulaNotification(placement: NotificationPlacement) {
        notification.error({
            message: `${i18n.t<string>('error')}: ${i18n.t<string>('revenue.invalid_formula')}`,
            description: `${i18n.t<string>('revenue.invalid_formula_message')}`,
            placement,
            duration: 3,
        });
    }

    function createdAttributeNotification(placement: NotificationPlacement) {
        notification.success({
            message: `${i18n.t<string>('revenue.attribute_created_successfully')}`,
            description: `${i18n.t<string>('revenue.attribute_created_succesfully_message')}`,
            placement,
            duration: 3,
        });
    }

    function handleNewAttributeModalOk() {
        const preparedFormula = formula.map((item, index) => {
            if (item.type === "attribute") {
                return {
                    orderPosition: index + 1,
                    operationAttributeId: item.content["id"],
                };
            }

            return {
                orderPosition: index + 1,
                operationCalcType: AttributeMathOperations[item.content],
            };
        });

        if (selectedGrouperInForm.name === "FIXED_EXPENSE" || selectedGrouperInForm.name === "MARKETING_COST_FIXED") {
            const dataToBeSent = {
                grouperType: selectedGrouperInForm["name"],
                externalCode,
                description,
            };

            ServiceCaller.doRequest(
                {
                    type: RequestType.POST,
                    url: `/revenue/grouper/attribute`,
                    params: dataToBeSent,
                },
                (response) => {
                    createdAttributeNotification("topRight");
                    closeNewAttributeModal();
                    reloadTable();
                },
                (err: ErrorRequest) => {
                    handleErrorRequest(err);
                }
            );
        } else {
            const dataToBeSent = {
                grouperType: selectedGrouperInForm["name"],
                externalCode,
                description,
                partsFormula: preparedFormula,
            };

            if (isFormulaValid(dataToBeSent.partsFormula)) {
                ServiceCaller.doRequest(
                    {
                        type: RequestType.POST,
                        url: `/revenue/grouper/attribute`,
                        params: dataToBeSent,
                    },
                    (response) => {
                        createdAttributeNotification("topRight");
                        closeNewAttributeModal();
                        reloadTable();
                    },
                    (err: ErrorRequest) => {
                        handleErrorRequest(err);
                    }
                );
            } else {
                openInvalidFormulaNotification("topRight");
            }
        }
    }

    function handleNewAttributeModalCancel() {
        closeNewAttributeModal();
    }

    function isFormulaValid(formula) {
        const parsedFormula = formula.reduce((acc, current) => {
            if (current.operationAttributeId) {
                return `${acc}1`;
            }

            if (current.operationCalcType) {
                switch (current.operationCalcType) {
                    case "LESS":
                        return `${acc} - `;
                    case "SUM":
                        return `${acc} + `;
                    case "MULTIPLY":
                        return `${acc} * `;
                    case "DIVIDER":
                        return `${acc} / `;
                }
            }

            return "";
        }, "");

        const result = Math.abs(evaluate(parsedFormula));

        if (result >= 0) {
            return true;
        }

        return false;
    }

    function showFormula() {
        if (selectedGrouperInForm?.name === 'FIXED_EXPENSE') {
            return false;
        }

        if (selectedGrouperInForm?.name === 'MARKETING_COST_FIXED') {
            return false;
        }

        return true;
    }

    function showFormulaAttributes() {
        if (selectedGrouperInForm?.name === 'FIXED_EXPENSE') {
            return false;
        }

        if (selectedGrouperInForm?.name === 'MARKETING_COST_FIXED') {
            return false;
        }

        return true;
    }

    const isGrouperDropdownDisabled =
        formula.length !== 0 &&
        formula.some((item) => item.type === "attribute");

    const availableAttributes = attributes
        .map((attribute) => {
            if (attribute.grouperId === 0) {
                return {
                    label: i18n.t<string>(attribute.name),
                    value: JSON.stringify(attribute),
                };
            }

            return {
                label: attribute.name,
                value: JSON.stringify(attribute),
            };
        })
        .filter((attribute) => {
            const attributeAlreadyExistsInFormula = formula.find((formulaItem) => {
                if (formulaItem.type === "operator") return null;
                return formulaItem.content.name.toLowerCase() === attribute.label.toLowerCase();
            });

            return !attributeAlreadyExistsInFormula;
        })
        .sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();

            if (labelA < labelB) {
                return -1;
            }

            if (labelA > labelB) {
                return 1;
            }

            return 0;
        });

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/revenue/grouper?isSidebar=false`,
            },
            (response: IGrouper[]) => {
                setGroupersList(response);
            }
        );
    }, []);

    let isOkButtonDisabled = false;

    if (externalCode.length === 0 || description.length === 0) {
        isOkButtonDisabled = true;
    }

    return (
        <Modal
            title={i18n.t<string>("revenue.new_attribute")}
            visible={isNewAttributeModalOpen}
            onOk={handleNewAttributeModalOk}
            onCancel={handleNewAttributeModalCancel}
            width={800}
            wrapClassName="attribute_parameterization-new-attribute-modal"
            okText={i18n.t<string>("save")}
            cancelText={i18n.t<string>("close")}
            centered
            okButtonProps={{
                style: {
                    width: "78px",
                },
                disabled: isOkButtonDisabled
            }}
        >
            <div id="attribute_parameterization-new-attribute-modal-content-wrapper">
                <form id="attribute_parameterization-new-attribute-modal-form">
                    <div className="form-field">
                        <InputText
                            id="attribute_parameterization-new-attribute-modal-form-external_code"
                            label={i18n.t<string>("external_code")}
                            onChange={handleExternalCode}
                            value={externalCode}
                            placeholder={i18n.t<string>("type_here")}
                        />
                    </div>
                    <div className="form-field">
                        <InputText
                            id="attribute_parameterization-new-attribute-modal-form-description"
                            label={i18n.t<string>("description")}
                            onChange={handleDescription}
                            value={description}
                            placeholder={i18n.t<string>("type_here")}
                        />
                    </div>
                    <div className="form-field">
                        <CustomSelect
                            id="grouper"
                            labelName={i18n.t<string>("grouper")}
                            options={groupersList?.map((grouper) => {
                                return {
                                    label: i18n.t<string>(grouper.name.toLowerCase()),
                                    value: grouper.id,
                                };
                            })}
                            onChange={(selectedGrouper) => {
                                loadAttributesByGrouper(selectedGrouper);
                            }}
                            disabled={isGrouperDropdownDisabled}
                        />
                    </div>

                    {showFormulaAttributes() && (
                        <>
                            <div className="form-field">
                                <CustomSelect
                                    id="attributes"
                                    labelName={i18n.t<string>("attributes")}
                                    disabled={!attributes.length}
                                    options={availableAttributes}
                                    onChange={(selectedAttribute) => {
                                        selectedAttribute = JSON.parse(selectedAttribute);
                                        addAttributeToFormula(selectedAttribute as INewAttributeModalAttribute);
                                    }}
                                    hasSearch
                                />
                            </div>
                            <div className="form-field">
                                <OperationsSelect onChooseOperation={addOperationToFormula} />
                            </div>
                        </>
                    )}
                </form>

                {showFormula() && (
                    <div id="attribute_parameterization-new-attribute-modal-formula">
                        <h3>{i18n.t<string>("revenue.base_calculation_formula")}</h3>

                        <FormulaArea formula={formula} removeItem={removeItem} />
                    </div>
                )}
            </div>
        </Modal>
    );
}
