import { Button, Checkbox, Col, Row, Table, Tooltip } from "antd";
import { AccessType, AccessTypeCommon, AccessTypeGeneric, AccessTypeGroup, AccessTypeUser, AccessTypeUserRequest, IAllAccessType, IDataSalesHierarchy, IDataSalesHierarchyRequest, ISalesHierarchyTableProps } from "../IPermissionSecurity";
import { ColumnsType } from "antd/lib/table";
import { Key, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { useUserContext } from "context/UserContext";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Footer } from "antd/lib/layout/layout";
import { ErrorFallback } from "components/error/ErrorFallback";
import { Notification } from "components/notification/Notification";
import StringUtil from "util/StringUtil";

export default function SalesHierarchyTable({
	selectedId,
	selectedMenuKey,
	searchPermission }: ISalesHierarchyTableProps) {
	const [isExpanded, setIsExpanded] = useState(false);
	const [tableData, setTableData] = useState<IDataSalesHierarchy[]>([]);
	const [initialRecord, setInitialRecord] = useState<IDataSalesHierarchy[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { selection: { businessUnitId, organizationId, scenarioId }, clientId, localeId, id } = useUserContext().userInfo;
	const [isSavingPermission, setIsSavingPermission] = useState(false);
	const [filteredTableData, setFilteredTableData] = useState<IDataSalesHierarchy[]>([]);
	const columns: ColumnsType<IDataSalesHierarchy> = [
		{
			title: () =>
				<Tooltip
					title={isExpanded ? i18n.t<string>("minimize_rows") : i18n.t<string>("expand_rows")}
					color={'#424242'}
				>
					<Button type="text" className="expand-button" onClick={handleClickExpandButton} icon={
						isExpanded ?
							<Icon
								icon={"fluent:arrow-minimize-vertical-24-regular"} />
							:
							<Icon icon="material-symbols:expand" />} />
				</Tooltip>,
			dataIndex: "title",
			key: "title",
		},
		{
			title:
				<div className="title-colunm-checkbox">
					<Checkbox
						checked={isAllChecked(buildLineForColumnRender(tableData), AccessType.BLOCKED)}
						indeterminate={!isAllChecked(buildLineForColumnRender(tableData), AccessType.BLOCKED) && verifyChildHasAccessType(buildLineForColumnRender(tableData), AccessType.BLOCKED)}
						onChange={(e) => onSelectAll(e.target.checked, AccessType.BLOCKED)}
					/>
					<span>{i18n.t("block")}</span>
				</div>,
			dataIndex: "block",
			key: "block",
			width: 180,
			render: (_, record: IDataSalesHierarchy) => {
				const accessType: AccessTypeGeneric = selectedMenuKey === "group" ? record.accessTypeGroup : record.accessTypeUser;
				const isChecked: boolean = isCheckedCheckbox(record, accessType, AccessType.BLOCKED);
				const indeterminate: boolean = (!isChecked && verifyChildHasAccessType(record, AccessType.BLOCKED));

				return (
					<Checkbox
						indeterminate={indeterminate}
						checked={isChecked}
						onChange={({ target: { checked } }) => onChangeCheckbox(checked, record.flexFieldValueIds, AccessType.BLOCKED, record, accessType?.id)}
					/>
				)
			},
		},
		{
			title:
				<div className="title-colunm-checkbox">
					<Checkbox
						checked={isAllChecked(buildLineForColumnRender(tableData), AccessType.ALLOWED)}
						indeterminate={!isAllChecked(buildLineForColumnRender(tableData), AccessType.ALLOWED) && verifyChildHasAccessType(buildLineForColumnRender(tableData), AccessType.ALLOWED)}
						onChange={(e) => onSelectAll(e.target.checked, AccessType.ALLOWED)}
					/>
					<span>{i18n.t("release")}</span>
				</div>,
			dataIndex: "allowed",
			key: "allowed",
			width: 180,
			render: (_, record: IDataSalesHierarchy) => {
				const accessType: AccessTypeGeneric = selectedMenuKey === "group" ? record.accessTypeGroup : record.accessTypeUser;
				const isChecked: boolean = isCheckedCheckbox(record, accessType, AccessType.ALLOWED);
				const indeterminate: boolean = (!isChecked && verifyChildHasAccessType(record, AccessType.ALLOWED));

				return (
					<Checkbox
						indeterminate={indeterminate}
						checked={isChecked}
						onChange={({ target: { checked } }) => onChangeCheckbox(checked, record.flexFieldValueIds, AccessType.ALLOWED, record, accessType?.id)}
					/>
				)
			},
		}
	];

	function onSelectAll(checked: boolean, accessType: AccessType) {
		setTableData((data) => {
			let newData: IDataSalesHierarchy[] = JSON.parse(JSON.stringify(data));

			const updateLine = (line: IDataSalesHierarchy): IDataSalesHierarchy => {
				if (line.children.length) {
					line.children = line.children.map((child) => updateLine(child));
				} else {
					let accessTypeToSave: AccessTypeGeneric = {};
					if (checked) {
						accessTypeToSave = {
							...selectedMenuKey === "group" && {...line.accessTypeGroup, groupId: selectedId},
							...selectedMenuKey === "user" && {...line.accessTypeUser, userId: selectedId},
							accessType,
							flexFieldValueIds: line.flexFieldValueIds
						};
					} else {
						accessTypeToSave = {
							...selectedMenuKey === "group" && {...line.accessTypeGroup},
							...selectedMenuKey === "user" && {...line.accessTypeUser},
							accessType: undefined,
							notInclude: true
						};
					}

					if (selectedMenuKey === "group") {
						line.accessTypeGroup = accessTypeToSave;
					} else {
						line.accessTypeUser = accessTypeToSave;
					}
				}

				return line;
			}

			newData = newData.map(line => updateLine(line));

			return newData;
		});
	}

	function buildLineForColumnRender(childs: IDataSalesHierarchy[]): IDataSalesHierarchy {
		const data: IDataSalesHierarchy = {
			accessTypeGroup: {},
			accessTypeUser: {},
			children: childs,
			flexFieldValueId: 0,
			flexFieldValueIds: [],
			id: 0,
			key: "",
			title: ""
		};

		return data;
	}

	function isAllChecked(line: IDataSalesHierarchy, accessType: AccessType) {
		let isAllChecked: boolean = true;

		const verify = (childs: IDataSalesHierarchy[]) => {
			for (let index = 0; index < childs.length; index++) {
				if (!isAllChecked) break;

				const { children, accessTypeGroup, accessTypeUser } = childs[index];
				if (children.length) {
					verify(children);
					continue;
				};

				if (selectedMenuKey === "group") {
					if (accessTypeGroup?.accessType !== accessType) isAllChecked = false;
				} else {
					if (accessTypeUser?.accessType !== accessType) isAllChecked = false;
				}
			}
		}

		verify([line]);
		return isAllChecked
	}

	function verifyChildHasAccessType(line: IDataSalesHierarchy, accessType: AccessType): boolean {
		let hasAccessType: boolean = false;

		const verify = (childs: IDataSalesHierarchy[]) => {
			for (let index = 0; index < childs.length; index++) {
				if (hasAccessType) break;

				const { children, accessTypeGroup, accessTypeUser } = childs[index];
				if (children.length) verify(children);

				if (selectedMenuKey === "group") {
					if (accessTypeGroup?.accessType === accessType) hasAccessType = true;
				} else {
					if (accessTypeUser?.accessType === accessType) hasAccessType = true;
				}
			}
		}

		verify([line]);
		return hasAccessType
	}

	function updateAccessTypeWhitChildren(checked: boolean, childrens: IDataSalesHierarchy[], accessType: AccessType) {
		setTableData((data) => {
			let newData: IDataSalesHierarchy[] = JSON.parse(JSON.stringify(data));
			const updateChild = (child: IDataSalesHierarchy): IDataSalesHierarchy => {
				let accessTypeToSave: AccessTypeGeneric = {};
				if (checked) {
					accessTypeToSave = {
						...selectedMenuKey === "group" && {...child.accessTypeGroup},
						...selectedMenuKey === "user" && {...child.accessTypeUser},
						accessType,
						flexFieldValueIds: child.flexFieldValueIds,
						notInclude: false
					};

					accessTypeToSave = selectedMenuKey === "group" ?
						{ ...accessTypeToSave, groupId: selectedId }
						: { ...accessTypeToSave, userId: selectedId }
				} else {
					accessTypeToSave = {
						...selectedMenuKey === "group" && {...child.accessTypeGroup},
						...selectedMenuKey === "user" && {...child.accessTypeUser},
						accessType: undefined,
						notInclude: true
					};
				}

				selectedMenuKey === "group" ?
					child.accessTypeGroup = accessTypeToSave :
					child.accessTypeUser = accessTypeToSave
				return child;
			}

			const mapLines = (lines: IDataSalesHierarchy[]) => {
				lines.forEach((child) => {
					if (child.children.length) {
						child.children = child.children.map((line) => {
							if (line.children.length) {
								mapLines(line.children);
							} else {
								updateChild(line);
							}

							return line;
						})
					} else {
						updateChild(child);
					}
				});

				return lines;
			}
			const changedLines: IDataSalesHierarchy[] = mapLines(childrens);
			const updatedTableData = (dataToChange: IDataSalesHierarchy[]) => {
				for (let index = 0; index < dataToChange.length; index++) {
					let line = dataToChange[index];
					let changedLineIndex: number = 0;

					while (changedLineIndex < changedLines.length) {
						if (isEqualsFlexFieldValueIds(line.flexFieldValueIds, changedLines[changedLineIndex].flexFieldValueIds)) {
							dataToChange[index] = changedLines[changedLineIndex];
						}

						changedLineIndex++;
					}

					if (line.children.length) {
						updatedTableData(line.children);
					}
				}
			}

			updatedTableData(newData);
			return newData;
		})
	}

	function onChangeCheckbox(checked: boolean, flexFieldValueIds: number[], accessType: AccessType, record: IDataSalesHierarchy, accessTypeId: number = null) {
		if (record.children.length) {
			updateAccessTypeWhitChildren(!isAllChecked(record, accessType), record.children, accessType);
			return;
		}

		const accessTypeToChange: AccessTypeCommon = { accessType: checked ? accessType : undefined, flexFieldValueIds };
		setTableData((data) => {
			const updateAccessType = (lines: IDataSalesHierarchy[]): IDataSalesHierarchy[] => {
				return lines.map(item => {
					if (isEqualsFlexFieldValueIds(item.flexFieldValueIds, record.flexFieldValueIds)) {
						return {
							...item,
							[selectedMenuKey === "group" ? "accessTypeGroup" : "accessTypeUser"]: {
								...selectedMenuKey === "group" && {...item.accessTypeGroup, groupId: selectedId},
								...selectedMenuKey === "user" && {...item.accessTypeUser, userId: selectedId},
								...accessTypeToChange,
								id: accessTypeId,
								notInclude: !checked,
							}
						}
					}

					if (item.children.length) {
						item.children = updateAccessType(item.children);
					}

					return item;
				});
			};

			return updateAccessType(data);
		})
	}

	function isCheckedCheckbox(record: IDataSalesHierarchy, accessType: AccessTypeUser | AccessTypeGroup, accessTypeToCompare: AccessType): boolean {
		let isChecked: boolean = accessType?.accessType === accessTypeToCompare;
		if (record.children.length) {
			isChecked = isAllChecked(record, accessTypeToCompare);
		}
		return isChecked;
	}

	function handleClickExpandButton() {
		if (isExpanded) {
			setExpandedRowKeys([]);
		} else {
			setAllRowsExpanded(tableData);
		}
		setIsExpanded(!isExpanded)
	}

	const setAllRowsExpanded = (rows: IDataSalesHierarchy[]) => {
		let keys: Key[] = [];

		rows.forEach(({ key, children }) => {
			if (children) {
				keys = keys.concat(setAllRowsExpanded(children));
			}

			keys.push(key);
		});

		setExpandedRowKeys(keys);
		return keys;
	};

	const handleExpand = (expanded: boolean, record: IDataSalesHierarchy) => {
		if (expanded) {
			setExpandedRowKeys([...expandedRowKeys, record.key]);
		} else {
			setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key));
		}
	};

	function convertData(data: IDataSalesHierarchyRequest, index: number = 0, parentKey: string): IDataSalesHierarchy {
		const { id, flexFieldValue, flexFieldValueIds, childrens = [], accessTypeGroup, accessTypeUser } = data;
		const title: string = flexFieldValue.name;
		const flexFieldValueId: number = flexFieldValue.id
		const key: string = `${parentKey && parentKey + "-"}${index}`;
		const children: IDataSalesHierarchy[] = childrens.map((item, index) => convertData(item, index, key));

		return {
			title,
			id,
			children,
			flexFieldValueId,
			flexFieldValueIds,
			accessTypeGroup,
			accessTypeUser,
			key
		};
	}

	const isEqualsFlexFieldValueIds = (aIds: number[], bIds: number[]) => {
		var _aIds = (aIds || []).sort().toString();
		var _bIds = (bIds || []).sort().toString();

		return _aIds === _bIds;
	};

	function onLoadTree(data: IDataSalesHierarchyRequest[]) {
		const formattedData: IDataSalesHierarchy[] = data.map((item, index) => convertData(item, index, ""));

		setTableData(formattedData);
		setInitialRecord(JSON.parse(JSON.stringify(formattedData)));
		setIsLoading(false);
	}

	function getAccessTypeWithoutChildren(data: IDataSalesHierarchy[]): AccessTypeGeneric[] {
		const accessType: AccessTypeGeneric[] = [];

		function traverse({ children, accessTypeGroup = {}, accessTypeUser = {}, id }: IDataSalesHierarchy): void {
			if (children.length === 0) {
				if (selectedMenuKey === "group") {
					accessType.push(
						Object.keys(accessTypeGroup).length > 0
							? { ...accessTypeGroup }
							: { id, notInclude: true }
					);
				} else {
					accessType.push(
						Object.keys(accessTypeUser).length > 0
							? { ...accessTypeUser }
							: { id, notInclude: true }
					);
				}
			} else {
				children.forEach(child => traverse(child));
			}
		}

		for (const item of data) {
			traverse(item);
		}

		return accessType.filter(permission => !permission.notInclude || permission.id);
	}


	function handleSavePermissions() {
		const dataToSave = getAccessTypeWithoutChildren(tableData);

		setIsLoading(true);
		setIsSavingPermission(true);
		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: "/revenue/field/value/change-access-status",
			params: dataToSave
		}, onSavePermission, ErrorFallback)
	}

	function onSavePermission() {
		Notification({
			message: i18n.t("successfully_saved"),
			type: "success"
		});
		setIsSavingPermission(false);
		loadTree();
	}

	function handleCancelChanges() {
		setTableData(JSON.parse(JSON.stringify(initialRecord)));
	}

	function filterTableDataBySearch(search: string, data: IDataSalesHierarchy[]): IDataSalesHierarchy[] {
		if (!search) return data;

		const filterData = (lines: IDataSalesHierarchy[]): IDataSalesHierarchy[] => {
			let newData: IDataSalesHierarchy[] = [];

			const filterLine = (node: IDataSalesHierarchy): boolean => {
				if (StringUtil.getWithoutSpecials(node.title).includes(StringUtil.getWithoutSpecials(search))) {
					return true;
				}
				return false;
			};

			for (let index = 0; index < lines.length; index++) {
				const line = lines[index];
				if (line.children.length) {
					const filteredChilds = filterData(line.children);
					if (filteredChilds.length) {
						line.children = filteredChilds;
						newData.push(line);
					} else {
						if (filterLine(line)) {
							newData.push(line);
						}
					}
				} else {
					if (filterLine(line)) newData.push(line);
				}

			}

			return newData;
		};

		const filteredData = filterData(JSON.parse(JSON.stringify(data)));
		return filteredData;
	}

	const loadTree = () => {
		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: "/revenue/load-tree",
			params: {
				businessUnitId: businessUnitId,
				clientId: clientId,
				localeId: localeId,
				organizationId: organizationId,
				scenarioId: scenarioId,
				revenueViewType: "SECURITY",
				...selectedMenuKey === "user" && {userId: selectedId},
				...selectedMenuKey === "group" && {groupIds: [selectedId], userId: id}
			}
		}, onLoadTree, handleErrorRequest);
	};

	useEffect(() => {
		loadTree();
	}, [])

	useEffect(() => {
		setFilteredTableData(filterTableDataBySearch(searchPermission, tableData));
	}, [searchPermission, tableData])

	return (
		<div className="sales-hierearchy-permission-content">
			<Row>
				<Table
					dataSource={filteredTableData}
					id="revenue-container"
					expandable={{
						defaultExpandAllRows: isExpanded,
						expandedRowKeys: expandedRowKeys,
						onExpand: handleExpand,
						expandIcon: (({ expanded, onExpand, record }) => {
							if (!record.children.length) return;
							return expanded ? (
								<div className="button-expandable-tree" onClick={e => onExpand(record, e)}><Icon icon="tabler:chevron-down" /></div>
							) : (
								<div className="button-expandable-tree" onClick={e => onExpand(record, e)}><Icon icon="tabler:chevron-right" /></div>
							)
						})
					}}
					pagination={{ hideOnSinglePage: true, showSizeChanger: false, defaultPageSize: 13 }}
					scroll={{ y: 450 }}
					className='gs-table sales-hierarchy-table-permissions'
					columns={columns}
					loading={isLoading}
				/>
			</Row >
			<Row className="footer-content">
				<Col span={24}>
					<Footer className="permission-security-footer">
						<Button
							type="link"
							onClick={handleCancelChanges}
						>
							{i18n.t<string>("cancel")}
						</Button>
						<Button
							className="gs-main-button"
							onClick={handleSavePermissions}
							style={{ padding: '0 45px' }}
							loading={isSavingPermission}
						>
							{isSavingPermission ? i18n.t<string>('saving') : i18n.t<string>('save')}
						</Button>
					</Footer>
				</Col>
			</Row>
		</div>
	)
}