import { Button, Row, Tabs } from "antd";
import { Store } from "antd/lib/form/interface";
import { useNewContractContext } from "../newContractContext";
import { IFlexFieldItensProps } from "../../IRecurringContract";
import i18n from "util/base/i18n";
import FlexFieldItens from "../FlexFieldItens";

export default function NewSalesTabs({ flexFieldValues, isDisable }: IFlexFieldItensProps) {
	const { form } = useNewContractContext();

	function onClickReplicate() {
		let newInitialValues: Store = {};
		flexFieldValues.forEach((flexField) => {
			const defaultValue: number = form.getFieldValue(`flexField-${flexField.value}-${flexField.fieldCode}`);
			const name: string = `flexField-${flexField.value}-${flexField.fieldCode}-SMS`
			newInitialValues[name] = defaultValue;
		});
		form.setFieldsValue(newInitialValues);
	};

	return (
		<div>
			<Tabs type="card" className="gs-tab" items={[
				{
					key: "1",
					label: i18n.t("new_sales"),
					children: <FlexFieldItens flexFieldValues={flexFieldValues} key={1} isDisable={isDisable} />
				},
				{
					key: "2",
					label: "SMS",
					children:
						<>
							<Row>
								<Button type="link" onClick={onClickReplicate}>
									{i18n.t("replicate_settings")}
								</Button>
							</Row>
							<FlexFieldItens flexFieldValues={flexFieldValues.map((flexField) => {
								return {
									...flexField,
									fieldCode: `${flexField.fieldCode}-SMS`
								}
							})}
							isDisable={isDisable}/>
						</>
				},

			]} />
		</div>
	)
}