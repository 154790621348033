import { Periodicity } from '../../../IReportView';
import { formatToBimester, formatToTrimester, formatToSemester, formatToYear } from './';

export function formatColumnList(periodicity, columnsList) {
    if (periodicity === Periodicity.BIMONTHLY) {
        formatToBimester(columnsList);
    }

    if (periodicity === Periodicity.QUARTERLY) {
        formatToTrimester(columnsList);
    }

    if (periodicity === Periodicity.SEMESTERLY) {
        formatToSemester(columnsList);
    }

    if (periodicity === Periodicity.YEARLY) {
        formatToYear(columnsList);
    }
}
