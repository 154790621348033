import { cloneDeep } from "lodash";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FlexFieldValueListBean, FlexFilter } from "util/types/types";

export async function updateFlexFieldFiltersValue(filter: string, form, type: 'origin' | 'destination', flexFieldOptions, setFlexFieldOptions) {

    const costCenter = type === 'origin' ? form.getFieldValue(`${type}-costCenter`) : form.getFieldValue(`${type}-costCenter`).map(value => value[0])
    const account = form.getFieldValue(`${type}-account`)
    const businessUnit = form.getFieldValue(`${type}-businessUnit`)

    const updatedFlexField = cloneDeep(flexFieldOptions)
    const promises = updatedFlexField.map(flexField => {
        if (flexField.linkedFilters && flexField.linkedFilters.includes(filter)) {
            flexField.isFetching = true
            setFlexFieldOptions(updatedFlexField)
            return new Promise<void>((resolve, reject) => {
                ServiceCaller.doRequest({
                    type: RequestType.GET,
                    url: `/budget-base/flex-field-value?user={user}&flexFieldId=${flexField.value}`
                }, (data: FlexFieldValueListBean[]) => {

                    flexField.children = data
                        .filter(item => item.accountingAccountFilters ? item.accountingAccountFilters.includes(account) : true)
                        .filter(item => item.costCenterFilters ? type === 'origin' ? item.costCenterFilters.includes(costCenter) : costCenter.every(costCenterId => item.costCenterFilters.includes(costCenterId)) : true)
                        .filter(item => item.businessUnitFilters ? item.businessUnitFilters.includes(businessUnit) : true)
                        .filter(item => {
                            const values = item.flexFieldValueListBean?.length > 0 ? item.flexFieldValueListBean[0].list.map(valueLinked => valueLinked.flexFieldFilters).flat() : [];
                            return updatedFlexField.some(flex => values.includes(flex.selectedOption[0]?.value));
                        })
                        .map(item => ({
                            value: item.id,
                            label: item.externalCode + ' - ' + item.description,
                            linkedParentFlexFieldId: item.flexFieldFilters
                        }));
                    flexField.selectedOption = []
                    flexField.isFetching = false
                    resetChildFieldsValues(updatedFlexField, setFlexFieldOptions, flexField.value, true, type, form)
                    resolve();
                }, (err) => {
                    handleErrorRequest(err);
                    flexField.isFetching = false
                    setFlexFieldOptions(updatedFlexField)
                    reject();
                });
            });

        }
        return Promise.resolve();
    });
    return Promise.all(promises);
}

function resetChildFieldsValues(flexFields: FlexFilter[], setFlexFieldOptions, id: number, clearValues: boolean, type: 'origin' | 'destination', form) {
    flexFields.forEach(field => {
        if (field.linkedFilters && field.linkedFilters.includes(id.toString())) {
            form.resetFields([`${type}-ff-${field.value}`])
            if (clearValues) {
                field.children = []
            }
            resetChildFieldsValues(flexFields, setFlexFieldOptions, field.value, true, type, form)
        }
    })
    setFlexFieldOptions(flexFields)
}