import { Col, DatePicker, Layout, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { Label } from "components/label/Label";
import { Content, Header } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import OptionCard from "./components/OptionCard";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import moment from "moment";
import { FlexFieldOptions, IModalFilterProps, IObjectOnApplyFilter, IOptionCardProps, ModulesType, OptionsData, SelectedRowKeysObject } from "./IModalFilter";
import "./ModalFilter.sass";
import { Icon } from "@iconify/react";
import ValuesTable from "./components/ValuesTable";
import { ICurrencyFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { useModalFilterContext } from "context/ModalFilterContext";

export const ModalFilter = ({
	module,
	onApply,
}: IModalFilterProps) => {
	const { isOpen, setIsOpen, selectedRowKeysObject, setSelectedRowKeysObject, setFiltersSelected } = useModalFilterContext();
	const [flexFieldOptions, setFlexFieldOpions] = useState<FlexFieldOptions[]>([]);
	const [currentTableData, setCurrentTableData] = useState<ICurrencyFilter[]>([]);
	const [flexFieldSelected, setFlexFieldSelected] = useState<number>(0);
	const [basePeriod, setBasePeriod] = useState<moment.Moment>(moment());

	function handleClose() {
		setIsOpen(false);
		setSelectedRowKeysObject({});
		setBasePeriod(moment());
		setFlexFieldSelected(flexFieldOptions[0]?.id);
		setFiltersSelected(null);
	}

	function onLoadFlexFields(data: FlexFieldOptions[]) {
		setFlexFieldOpions(data.map((flexField) => {
			const flexFieldValues = flexField.flexFieldValues.map((flexValue) => ({ ...flexValue, value: Number(flexValue.value) }));
			return ({
				...flexField,
				flexFieldValues
			})
		}));
		setFlexFieldSelected(data[0]?.id);
	}

	function handleApply() {
		const flexFieldValuesIds: number[] = Object.values(selectedRowKeysObject).flat();
		const flexFieldsSelected: ICurrencyFilter[] = flexFieldValuesIds.map((valueId) => {
			let indexValue: number = 0;
			const label: string = flexFieldOptions.find(({ flexFieldValues }) => flexFieldValues.find(({ value }, index) => {
				const findeValue: boolean = value === valueId;
				if (findeValue) indexValue = index;
				return findeValue;
			})).flexFieldValues[indexValue].label;
			return ({
				label,
				value: valueId
			})
		})
		setFiltersSelected({ flexFieldsSelected, basePeriod })
		onApply({ flexFieldsSelected, basePeriod });
		setFlexFieldSelected(flexFieldOptions[0]?.id);
		setIsOpen(false);
	}

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/flex-field/find-all-with-values-mapped?module=${ModulesType[module]}`,
		}, onLoadFlexFields)
	}, [])

	useEffect(() => {
		if (flexFieldSelected) {
			setCurrentTableData(flexFieldOptions.find(({ id }) => id === flexFieldSelected).flexFieldValues);
		} else {
			setSelectedRowKeysObject({});
		}
	}, [flexFieldSelected])

	return (
		<Modal
			title={i18n.t("filter")}
			className="gs-modal-filter"
			okButtonProps={{
				className: "gs-main-button",
			}}
			cancelButtonProps={{
				type: "link"
			}}
			okText={i18n.t("apply")}
			onOk={handleApply}
			open={isOpen}
			onCancel={handleClose}
			width={1200}
		>
			<Layout>
				<Header>
					<Row>
						<Col>
							<Label
								title={i18n.t("base_date")}
								children={
									<DatePicker
										picker="month"
										value={basePeriod}
										onChange={setBasePeriod}
										className="replicate-scenario-month"
										prevIcon={<></>}
										suffixIcon={<Icon icon="fa6-solid:calendar" />}
										allowClear={false}
										format={"MMM/YYYY"}
									/>
								}
							/>
						</Col>
					</Row>
				</Header>
				<Layout className="filter-content">
					<Sider
						width={230}
					>
						{
							flexFieldOptions.map(({ description, flexFieldValueIds, id }) => {
								const totalItensSelected = flexFieldValueIds.reduce((acc, valueId) => {
									if (selectedRowKeysObject[id]?.some((value) => value === valueId)) {
										return acc + 1;
									}
									return acc;
								}, 0);
								return (
									<OptionCard
										count={totalItensSelected}
										title={description}
										onClick={() => setFlexFieldSelected(id)}
										isSelected={flexFieldSelected === id}
									/>
								)
							})
						}
					</Sider>
					<Content>
						<ValuesTable
							data={currentTableData}
							selectedObject={selectedRowKeysObject}
							setSelectedObject={setSelectedRowKeysObject}
							flexFieldSelected={flexFieldSelected}
						/>
					</Content>
				</Layout>
			</Layout>
		</Modal>
	)
}
