import i18n from "util/base/i18n";

import { MessageType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import { Button, Menu } from "antd";

import { Icon } from "@iconify/react";

import "./styles.sass";

import { useUserContext } from "context/UserContext";

const ROOT_PATH = process.env.REACT_APP_GS_ROOT_PATH + process.env.REACT_APP_GS_BASE_PATH;

export function UserMenu() {

	const { userInfo, changeModal } = useUserContext();

	function doRedirect(url: string, event: MouseEvent): void {
		closeMenu();

		if (!event.ctrlKey) {
			event.preventDefault();

			ServiceCaller.doSendMessage({
				messageType: MessageType.REDIRECT_TO,
				params: {
					url
				}
			});
		}
	};

	function closeMenu() {
		ServiceCaller.doSendMessage({
			messageType: MessageType.CLOSE_USER_MENU
		});
	};

	function openSceneryModal() {
		closeMenu();
		changeModal();
	};

	function openAboutModal() {
		closeMenu();

		ServiceCaller.doSendMessage({
			messageType: MessageType.OPEN_ABOUT_MODAL
		});
	};

	return <>
		<div className="user-container">
			<div className={userInfo.plan === "FREEMIUM" ? "user-info-container user-freemium" : "user-info-container"}>
				<h3>{userInfo.name}</h3>

				<div className="user-info-content">
					{userInfo && <Icon icon="ps:building" />}

					<div>
						<p>{userInfo.selection.businessUnitName}</p>
						<p>{userInfo.selection.organizationName}</p>
						<p>{userInfo.selection.scenarioName}</p>
					</div>
				</div>
				<Button onClick={openSceneryModal} className="button-change-scenery" icon={<Icon icon="el:refresh" />} />
			</div>

			<Menu style={{overflow:"hidden"}} className={userInfo.plan === "FREEMIUM" ? "user-nav-content user-menu-freemium" : "user-nav-content"}
				items={[{
					label: <a href={`${ROOT_PATH}/myAccount`} onClick={doRedirect.bind(this, `${ROOT_PATH}/myAccount`)}>{i18n.t<string>("user_menu.my_account")}</a>,
					key: "0",
					icon: <Icon icon="bxs:user" />,
				}, {
					label: <a href={`${ROOT_PATH}/applicationImprovements`} onClick={doRedirect.bind(this, `${ROOT_PATH}/applicationImprovements`)}>{i18n.t<string>("user_menu.release_notes")}</a>,
					key: "1",
					icon: <Icon icon="el:refresh" />,
				}, {
					label: <a href={`${ROOT_PATH}/termsOfUse`} onClick={doRedirect.bind(this, `${ROOT_PATH}/termsOfUse`)}>{i18n.t<string>("user_menu.terms_of_use")}</a>,
					key: "2",
					icon: <Icon icon="ic:round-library-books" />,
				}, {
					label: <a href={`https://www.gesplan.com.br/politica-de-privacidade`} target="_blank" rel="noreferrer" onClick={closeMenu}>{i18n.t<string>("user_menu.privacy_policy")}</a>,
					key: "3",
					icon: <Icon style={{ fontSize: 16 }} icon="bxs:lock-open-alt" />,
				}, {
					label: <a href="https://gesplan.movidesk.com" target="_blank" rel="noreferrer" onClick={closeMenu}>{i18n.t<string>("user_menu.customer_portal")}</a>,
					key: "4",
					icon: <Icon style={{ fontSize: 18 }} icon="mdi:home-account" />,
				}, {
					label: <a href="https://gesplan.movidesk.com/Kb/" target="_blank" rel="noreferrer" onClick={closeMenu}>{i18n.t<string>("help")}</a>,
					key: "5",
					icon: <Icon style={{ fontSize: 16 }} icon="bxs:help-circle" />,
				}, {
					label: <div onClick={openAboutModal.bind(this)}>{i18n.t<string>("about")}</div>,
					key: "6",
					icon: <Icon style={{ fontSize: 16 }} icon="clarity:info-standard-solid" />,
				}, userInfo.plan === "FREEMIUM" && {
					label: <a href={`https://www.totvs.com/gesplan/`} target="_blank" rel="noreferrer" >Upgrade</a>,
					key: "7",
					icon: <Icon icon="noto:star" />,
				}, {
					label: <a href={`${ROOT_PATH}/security/logout`} onClick={doRedirect.bind(this, `${ROOT_PATH}/security/logout`)}>{i18n.t<string>("exit")}</a>,
					key: "8",
					icon: <Icon icon="subway:exit" />,
				}]} />
		</div>
	</>;

}
