import * as React from "react";

import FusionCharts from "fusioncharts/core";
import Column2D from "fusioncharts/viz/column2d";
import Bar2D from "fusioncharts/viz/bar2d";
import Pie2D from "fusioncharts/viz/pie2d";
import MSStackedColumn2DLine from "fusioncharts/viz/msstackedcolumn2dlinedy";

import {
	ChartType,
	IChartProps,
	IChartState
} from "./IChart";

import i18n from "util/base/i18n";

FusionCharts.options.license({
	key: process.env.REACT_APP_FUSION_CHARTS_LICENSE,
	creditLabel: false
});

export default class Chart extends React.Component<IChartProps, IChartState> {

	constructor(props: IChartProps) {
		super(props);

		this.state = {
			renderAt: props.id + "-container",
			chart: null
		};
	}

	componentDidMount(): void {
		const {
			id,
			type,
			config,
			data,
			categories,
			dataset,
			lineset
		} = this.props;

		this.addChartTypeDependency();

		const chart = new FusionCharts({
			id: id,
			type: type.toString(),
			renderAt: `${id}-container`,
			width: "100%",
			height: "400",
			dataFormat: "json",
			dataSource: {
				chart: {
					bgColor: "#FFFFFF",
					bgAlpha: "0",
					borderAlpha: "100",
					divLineColor: "#FFF",
					divLineAlpha: "15",
					divLineThickness: "1",
					showAlternateHGridColor: "0",
					showCanvasBg: "0",
					canvasBgColor: "#FFFFFF",
					showShadow: "0",
					canvasBorderColor: "#FFFFFF",
					canvasBorderThickness: "0",
					canvasBorderAlpha: "15",
					showCanvasBase: "0",
					canvasBgAlpha: "50",
					canvasBgRatio: "50",
					legendShadow: "0",
					legendBorder: "0",
					legendBgAlpha: "20",
					legendBorderAlpha: "0",
					use3DLighting: "0",
					exportAtClientSide: "1",
					showPlotBorder: false,
					showBorder: "0",
					showValues: "0",
					showLegend: true,
					legendBgColor: "#FFFFFF",
					showAlternateVGridColor: "0",
					toolTipBgColor: "#424242",
					toolTipBgAlpha: "100",
					showToolTipShadow: "0",
					toolTipPosition: "top",
					toolTipColor: "#fff",
					baseFontSize: "12",
					...config
				},
				...(
					data != null ? { data } : {}
				),
				...(
					categories != null ? { categories } : {}
				),
				...(
					dataset != null ? { dataset } : {}
				),
				...(
					lineset != null ? { lineset } : {}
				)
			}
		});

		chart.configure("ChartNoDataText", i18n.t<string>("no_data"));

		this.setState({
			chart: chart.render()
		});
	}

	addChartTypeDependency(): void {
		const {
			type
		} = this.props;

		switch (type) {
			case ChartType.COLUMN_2D:
				FusionCharts.addDep(Column2D);
				break;
			case ChartType.BAR_2D:
				FusionCharts.addDep(Bar2D);
				break;
			case ChartType.PIE_2D:
				FusionCharts.addDep(Pie2D);
				break;
			case ChartType.MS_STACKED_COLUMN_2D_LINE:
				FusionCharts.addDep(MSStackedColumn2DLine);
				break;
			default:
				break;
		}
	}

	shouldComponentUpdate(nextProps: Readonly<IChartProps>, nextState: Readonly<IChartState>): boolean {
		const {
			chart
		} = nextState;

		const {
			originalDataSource
		} = chart;

		if (JSON.stringify(originalDataSource.data) !== JSON.stringify(nextProps.data)) {
			chart.setChartData({
				...originalDataSource,
				data: [...nextProps.data]
			}, "json");
		} else if (JSON.stringify(originalDataSource.categories) !== JSON.stringify(nextProps.categories) || 
			JSON.stringify(originalDataSource.dataset) !== JSON.stringify(nextProps.dataset) || 
			JSON.stringify(originalDataSource.lineset) !== JSON.stringify(nextProps.lineset)) {
			chart.setChartData({
				...originalDataSource,
				categories: [...nextProps.categories],
				dataset: [...nextProps.dataset],
				lineset: [...nextProps.lineset]
			}, "json");
		} else if (nextProps.config.paletteColors !== originalDataSource.chart.paletteColors ||
			nextProps.config.plotGradientColor !== originalDataSource.chart.plotGradientColor ||
			nextProps.config.xAxisName !== originalDataSource.chart.xAxisName) {
			chart.setChartData({
				chart: {
					...originalDataSource.chart,
					...nextProps.config
				},
				data: originalDataSource.data,
				categories: originalDataSource.categories,
				dataset: originalDataSource.dataset,
				lineset: originalDataSource.lineset
			}, "json");
		}

		return true;
	}

	render() {
		const {
			renderAt
		} = this.state;

		return <div id={renderAt}></div>
	}

}
