import { BudgetGridData, ViewModeSelected, totalSummary } from "../../../IFixedExpense";

export function createTableTotalsRow(
    data: BudgetGridData[],
    viewModeSelected: ViewModeSelected[],
    isToDetailModal = false
) {
    const monthArray = Object.keys(data[0].plannedMonths);
    let totals: totalSummary[] = [];

    let realizedTotal = 0;
    let projectedTotal = 0;
    let historicTotal = 0;
    let plannedTotal = 0;
    let apportionmentTotal = 0;
    monthArray.forEach((month) => {
        let realizedValue = 0;
        let projectedValue = 0;
        let historicValue = 0;
        let plannedValue = 0;
        let apportionmentValue = 0;

        data.forEach((budgetGridData) => {
            const rate = isToDetailModal ? budgetGridData.plannedMonths[month].rate : 1;
            plannedValue += budgetGridData.plannedMonths[month].plannedValue * rate;
            realizedValue += budgetGridData.plannedMonths[month].realizedValue;
            projectedValue += budgetGridData.plannedMonths[month].projectedValue;
            apportionmentValue += budgetGridData.plannedMonths[month].apportionmentValue;
            historicValue += budgetGridData.plannedMonths[month].historicValue;
            plannedTotal += budgetGridData.plannedMonths[month].plannedValue * rate;
            realizedTotal += budgetGridData.plannedMonths[month].realizedValue;
            projectedTotal += budgetGridData.plannedMonths[month].projectedValue;
            historicTotal += budgetGridData.plannedMonths[month].historicValue;
            apportionmentTotal += budgetGridData.plannedMonths[month].apportionmentValue;
        });
        totals.push({ value: plannedValue, month, order: 1 });

        if (viewModeSelected.includes(ViewModeSelected.PROJECTED)) {
            totals.push({ value: projectedValue, month, order: 2 });
        }
        if (viewModeSelected.includes(ViewModeSelected.REALIZED)) {
            totals.push({ value: realizedValue, month, order: 3 });
        }
        if (viewModeSelected.includes(ViewModeSelected.APPORTIONMENT)) {
            totals.push({ value: apportionmentValue, month, order: 4 });
        }
        if (viewModeSelected.includes(ViewModeSelected.HISTORIC)) {
            totals.push({ value: historicValue, month, order: 5 });
        }
    });
    totals.push({ value: plannedTotal, month: "99999", order: 6 });

    if (viewModeSelected.includes(ViewModeSelected.PROJECTED)) {
        totals.push({ value: projectedTotal, month: "999999", order: 7 });
    }
    if (viewModeSelected.includes(ViewModeSelected.REALIZED)) {
        totals.push({ value: realizedTotal, month: "999999", order: 8 });
    }
    if (viewModeSelected.includes(ViewModeSelected.APPORTIONMENT)) {
        totals.push({ value: apportionmentTotal, month: "999999", order: 9 });
    }
    if (viewModeSelected.includes(ViewModeSelected.HISTORIC)) {
        totals.push({ value: historicTotal, month: "999999", order: 10 });
    }

    const sorted = totals.sort((a, b) => {
        if (a.month > b.month) return 1;
        if (a.month < b.month) return -1;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
    });

    return sorted;
}
