import { Form, Layout } from "antd";
import { ImageBox } from "components/imageBox/ImageBox";
import { Loading } from "components/loading/Loading";
import { TopButtons } from "components/topButtons/TopButtons";
import SidebarContent from "./components/SidebarContent";
import { useEffect, useState } from "react";
import './style.sass';
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { handleExportGridData } from "util/functions/handleExportGridData";
import i18n from "util/base/i18n";
import { SecudaryButton } from "components/topButtons/ITopButtons";
import NewLevelModal from "./components/NewLevelModal";
import { AccountData, LevelItem, ManagementAccounts, NewLevelFormData, TableDataItems } from "./IManagementAccount";
import { Notification } from "components/notification/Notification";
import { useErrorBoundary } from "react-error-boundary";
import ManagementAccountTable from "./components/ManagementAccountTable";
import { Options } from "util/types/types";
import ResetBalancesModal from "./components/ResetBalancesModal";
import { ColumnsType } from "antd/lib/table";

export default function ManagementAccount() {
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedNodeKeys, setCheckedNodeKeys] = useState<number[]>([]);
    const [allAccounts, setAllAccounts] = useState([]);
    const [treeData, setTreeData] = useState<LevelItem[]>([]);
    const [isTreeDisabled, setIsTreeDisabled] = useState(true);
    const [tableData, setTableData] = useState<TableDataItems>({} as TableDataItems);
    const [isFetchingTableData, setIsFetchingTableData] = useState(false);
    const [form] = Form.useForm();
    const [formTable] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isBalanceModalVisible, setIsBalanceModalVisible] = useState(false);
    const [isNewLevel, setIsNewLevel] = useState(false);
    const [lookupLevels, setLookupLevels] = useState([]);
    const [isSelectDisabled, setIsSelectDisabled] = useState(true);
    const [selectedTree, setSelectedTree] = useState<LevelItem>({} as LevelItem);
    const [accountJSON, setAccountJSON] = useState<AccountData>({} as AccountData);
    const [currenciesList, setCurrenciesList] = useState<Options[]>([]);
    const [isFetchingCurrency, setIsFetchingCurrency] = useState(true);

    const { showBoundary } = useErrorBoundary()

    const classificationsList = [
        { value: 1, label: i18n.t("active") },
        { value: 2, label: i18n.t("passive") },
        { value: 3, label: i18n.t("revenues") },
        { value: 4, label: i18n.t("modules.expenses") },
        { value: 5, label: i18n.t("managerial") },
    ]

    const importProps: IImportModal[] = [
        {
            importUrl: "/planning/management-account-plan-data/import",
            templateUrl: "/planning/management-account-plan-data/template?locale={locale}",
            type: 'excel'
        }
    ]

    const resetBalanceButtonProps: SecudaryButton = {
        onClick: () => setIsBalanceModalVisible(true),
        title: i18n.t("clear_balances")
    };

    const tableColumns: ColumnsType = [
        {
            title: i18n.t<string>("code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "center",
            width: 200,
        },
        {
            title: i18n.t<string>("account"),
            dataIndex: "description",
            key: "description",
            align: "left",
            width: 400,
            className: 'account-registration-columns'
        },
    ];

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/currency?locale={locale}&client={client}&user={user}`,
        }, (data) => {
            setCurrenciesList(data);
            setIsFetchingCurrency(false);
        }, err => showBoundary(err));
        fetchAccountHierarchy();
    }, []);

    useEffect(() => {
        if (checkedNodeKeys.length === 1 && !isNewLevel) {
            const newSelectedTree = findIdInTree(treeData, checkedNodeKeys[0]);
            setSelectedTree(newSelectedTree);
            newSelectedTree.level === 4 ? fetchAccountData(newSelectedTree) : onLoadAccountData(newSelectedTree);
        }
    }, [checkedNodeKeys, treeData]);

    function fetchAccountHierarchy() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: '/planning/management-account-plan-hierarchy',
            useProxy: true
        }, (data) => {
            onLoadTreeData(data);
        }, err => showBoundary(err));
    }

    function fetchAccountData(newSelectedTree = {}) {

        setIsFetchingTableData(true);

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: '/planning/management-account-plan-data/hierarchy/' + checkedNodeKeys[0],
            useProxy: true
        }, (data) => {
            onLoadAccountData({ ...data, ...newSelectedTree });
            setAccountJSON(data);
        }, err => showBoundary(err));
    }

    function onLoadTreeData(data) {
        if (data.length === 0) {
            setLookupLevels([]);
            setTreeData([]);
            setAllAccounts([]);
            setIsTreeDisabled(false);
            return;
        };

        const updateTreeData: LevelItem[] = convertTreeData(data);

        updateTreeData.forEach(tree => {
            addLevelProperty(tree, 1);
        });

        setLookupLevels(data.map(item => ({ value: item.id, label: item.description, code: item.externalCode })));
        setTreeData(updateTreeData);
        setAllAccounts(data);
        setIsTreeDisabled(false);
    };

    function onLoadAccountData(data) {

        if (data.length === 0) {
            setLookupLevels([]);
            setTableData({} as TableDataItems);
            return;
        };

        formTable.setFieldsValue({
            description: data.title,
            code: data.externalCode,
            active: data.active,
            summarizer: data.summarizer,
            resetStartExercise: data.resetStartExercise,
            automaticRealizationOpeningBalances: data.automaticRealizationOpeningBalances,
            currencyConversionId: data.currencyConversionId ? data.currencyConversionId : 0,
            accountClassificationId: data.accountClassificationId ? data.accountClassificationId : 0
        });

        const tableDataContent = {
            id: data.id,
            description: {
                value: data.title,
                type: "text",
                className: "grey-background"
            },
            code: {
                value: data.externalCode,
                type: "text"
            },
        };

        let lastLevelData = {};

        if (data.level === 4) {
            lastLevelData = {
                managementAccountPlanHierarchyId: data.managementAccountPlanHierarchyId,
                active: {
                    value: data.active,
                    type: "switch",
                },
                summarizer: {
                    value: data.summarizer,
                    type: "switch",
                },
                resetStartExercise: {
                    value: data.resetStartExercise,
                    type: "switch",
                },
                automaticRealizationOpeningBalances: {
                    value: data.automaticRealizationOpeningBalances,
                    type: "switch",
                },
                currency: {
                    value: "",
                    type: "none",
                    className: "grey-background"
                },
                currencyConversionId: {
                    value: data.currencyConversionId,
                    type: "select",
                    options: currenciesList,
                    isFetching: isFetchingCurrency
                },
                accountClassificationId: {
                    value: data.accountClassificationId,
                    type: "select",
                    options: classificationsList
                }
            }
        }

        const updatedTableDataContent = {
            ...tableDataContent,
            ...lastLevelData
        };

        setIsFetchingTableData(false);
        setTableData(updatedTableDataContent);
    }

    function convertTreeData(data: ManagementAccounts[], parentId = 0) {
        return data
            .filter(item => item.parentId === parentId)
            .map(item => ({
                id: item.id,
                key: item.id,
                title: item.description,
                externalCode: item.externalCode,
                parentId: parentId,
                level: 0,
                children: convertTreeData(data, item.id)
            }));
    }

    function addLevelProperty(tree, level) {
        if (tree && typeof tree === 'object') {
            tree.level = level;

            if (tree.children.length === 0) {
                const externalCodeSuffix = ` (${tree.externalCode})`;
                tree.title = `${tree.title}${externalCodeSuffix}`;
            }

            if (Array.isArray(tree.children)) {
                tree.children.forEach(child => {
                    addLevelProperty(child, level + 1);
                });
            }
        }
    }

    function findIdInTree(tree, id) {
        function findNodeRecursive(noAtual) {
            if (noAtual.id === id) {
                return noAtual;
            }

            if (noAtual.children && noAtual.children.length > 0) {
                for (let i = 0; i < noAtual.children.length; i++) {
                    const resultado = findNodeRecursive(noAtual.children[i]);
                    if (resultado) {
                        return resultado;
                    }
                }
            }

            return null;
        }

        for (let i = 0; i < tree.length; i++) {
            const resultado = findNodeRecursive(tree[i]);
            if (resultado) {
                return resultado;
            }
        }
        return null;
    }

    function handleOpenModal() {
        setIsSelectDisabled(false);
        setCheckedNodeKeys([]);
        setIsNewLevel(true);
        setIsModalVisible(true);
    };

    function handleDeleteAccount() {
        setIsTreeDisabled(true);

        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/planning/management-account-plan-hierarchy?ids=${checkedNodeKeys}`,
            useProxy: true
        }, () => {
            setCheckedNodeKeys([])
            fetchAccountHierarchy();
        }, handleErrorRequest);
    }

    function handleClickResetBalance() {

    }

    function handleNewLevel(data: NewLevelFormData) {
        if (form.validateFields()) {
            setIsNewLevel(false);
            setIsModalVisible(false);
            setIsTreeDisabled(true);

            const levelToSave = {
                id: null,
                description: data.description,
                externalCode: data.externalCode,
                parentId: data.originLevel,
            }

            ServiceCaller.doRequest({
                type: RequestType.POST,
                url: `/planning/management-account-plan-hierarchy`,
                params: levelToSave
            }, (data) => onSaveNewLevel(data), handleErrorRequest);
        }
    }

    function onSaveNewLevel(data) {
        Notification({
            type: 'success',
            message: i18n.t("groupings_texts.level_created_successfully")
        })

        const newSelectedTree = findIdInTree(treeData, data.parentId);

        form.resetFields();

        if (newSelectedTree?.level === 3) {
            const accountData = {
                id: null,
                managementAccountPlanHierarchyId: data.id,
                active: true,
                summarizer: false,
                resetStartExercise: false,
                automaticRealizationOpeningBalances: false,
                currencyConversionId: null,
                accountClassificationId: null
            }

            ServiceCaller.doRequest({
                type: RequestType.POST,
                url: "/planning/management-account-plan-data",
                params: accountData
            }, () => fetchAccountHierarchy(), handleErrorRequest);
        } else {
            fetchAccountHierarchy();
        }
    }

    function addSubLevel(parentId: number) {
        form.setFieldsValue({ originLevel: parentId });
        setIsNewLevel(true);
        setCheckedNodeKeys([]);
        setIsSelectDisabled(true);
        setIsModalVisible(true);
    }

    function handleSaveTableContent(data) {

        if (data.description !== selectedTree.title || data.code !== selectedTree.externalCode) {
            const hierarchyData = {
                id: checkedNodeKeys[0],
                externalCode: data.code,
                description: data.description,
                parentId: selectedTree.parentId ? selectedTree.parentId : 0
            }

            ServiceCaller.doRequest({
                type: RequestType.PUT,
                url: '/planning/management-account-plan-hierarchy',
                params: hierarchyData,
            }, (data) => {
                selectedTree.level < 4 && onSaveTable(data);
            });
        }

        if (selectedTree.level === 4) {
            const accountData = {
                id: accountJSON.id,
                managementAccountPlanHierarchyId: checkedNodeKeys[0],
                active: data.active,
                summarizer: data.summarizer,
                resetStartExercise: data.resetStartExercise,
                automaticRealizationOpeningBalances: data.automaticRealizationOpeningBalances,
                currencyConversionId: data.currencyConversionId,
                accountClassificationId: data.accountClassificationId
            }

            ServiceCaller.doRequest({
                type: RequestType.PUT,
                url: '/planning/management-account-plan-data',
                params: accountData,
            }, onSaveTable.bind(this));
        }

    }

    function onSaveTable(response) {
        if (response) {
            Notification({
                type: "success",
                message: i18n.t<string>("successfully_edited"),
            });
        }
        fetchAccountHierarchy();
        setTableData({} as TableDataItems)
    }

    function clearTableChanges() {
        formTable.setFieldsValue({
            description: selectedTree.title,
            code: selectedTree.externalCode,
            active: accountJSON.active,
            summarizer: accountJSON.summarizer,
            resetStartExercise: accountJSON.resetStartExercise,
            automaticRealizationOpeningBalances: accountJSON.automaticRealizationOpeningBalances,
            currencyConversionId: accountJSON.currencyConversionId ? accountJSON.currencyConversionId : 0,
            accountClassificationId: accountJSON.accountClassificationId ? accountJSON.accountClassificationId : 0
        });
    }

    function validateExternalCode(value: string, message: string) {
        if (!value) return Promise.resolve();

        const newSelectedTree = allAccounts.find(item => item.id === checkedNodeKeys[0]);

        if (lookupLevels.find(item => item.code === value && item.code !== newSelectedTree?.externalCode)) {
            return Promise.reject(new Error(message));
        }

        return Promise.resolve();
    }

    return (
        <div id="management-account-container">
            <div className="page-title-content" id="cost-category-container">
                <h1>{i18n.t("management_account_registration")}</h1>
            </div>
            <div id="top-buttons-account-registration">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t('account_registration.new_account')}
                        handleNew={handleOpenModal}
                        handleDelete={handleDeleteAccount}
                        isDeletable={checkedNodeKeys.length > 0}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(allAccounts, tableColumns, i18n.t("management_cost_center")),
                            importProps: importProps
                        }}
                        secondaryButton={resetBalanceButtonProps}
                    />
                </div>
                <ResetBalancesModal
                    isModalVisible={isBalanceModalVisible}
                    handleCancel={() => setIsBalanceModalVisible(false)}
                    handleClick={handleClickResetBalance}
                />
            </div>
            <Layout>
                <Layout.Sider width="350px">
                    <SidebarContent
                        setCheckedNodeKeys={setCheckedNodeKeys}
                        checkedNodeKeys={checkedNodeKeys}
                        treeData={treeData}
                        isTreeDisabled={isTreeDisabled}
                        expandedKeys={expandedKeys}
                        setExpandedKeys={setExpandedKeys}
                        addNewSubLevel={addSubLevel}
                    />
                </Layout.Sider>
                <Layout.Content>
                    {isFetchingTableData ?
                        <Loading />
                        : ((checkedNodeKeys.length === 0 || checkedNodeKeys.length > 1) ?
                            <ImageBox
                                styles={{ flex: 1 }}
                                imgName={checkedNodeKeys.length === 0 ? "image_bw_02" : "empty_list"}
                                message={checkedNodeKeys.length === 0 ? i18n.t("select_an_item") : i18n.t("select_just_one_item")}
                            />
                            :
                            <ManagementAccountTable
                                form={formTable}
                                tableData={tableData}
                                handleSubmit={handleSaveTableContent}
                                handleClickClear={clearTableChanges}
                                setIsFetchingTableData={setIsFetchingTableData}
                                setIsFetchingTree={setIsTreeDisabled}
                                validateExternalCode={validateExternalCode}
                            />
                        )
                    }
                </Layout.Content>
            </Layout >
            <NewLevelModal
                form={form}
                handleNewLevel={handleNewLevel}
                handleCancel={() => {
                    setIsModalVisible(false);
                    setIsNewLevel(false);
                }}
                isSelectDisabled={isSelectDisabled}
                isModalVisible={isModalVisible}
                lookupLevels={lookupLevels}
                validateExternalCode={validateExternalCode}
            />
        </div>
    )
}