import { IFlexContentProps } from "../IRecurringContract";
import FlexFieldItens from "./FlexFieldItens";
import NewSalesTabs from "./newSalesTabs/NewSalesTabs";

export default function FlexContent({ flexFieldValues, isNewSales, isDisable }: IFlexContentProps) {
    return (
        <>
            {isNewSales ? (
                <NewSalesTabs flexFieldValues={flexFieldValues} isDisable={isDisable} />
            ) : (
                <FlexFieldItens flexFieldValues={flexFieldValues} isDisable={isDisable} />
            )}
        </>
    );
}