import { Input, Select } from "antd";
import { useEffect, useRef} from "react";
import i18n from "util/base/i18n";
import { IRecurringMultipleSearch } from "../IRecurringContract";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import { useNewContractContext } from "./newContractContext";

export function RecurringMultipleSearch({
    options,
    doRequest,
    setContractType,
    setSearchType,
    setSearchContract,
    setSearchPeriodicity,
    contractType,
    searchContract,
    searchPeriodicity,
    searchType,
    flexFieldValueIds
}: IRecurringMultipleSearch) {
    const { optionsContractType } = useNewContractContext();
    const searchRef = useRef(null);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const chooseSearchType = {
        "contract": "CONTRACT_DESCRIPTION",
        "contractType": "CONTRACT_TYPE",
        "periodicity": "PERIODICITY",
    };

    const optionsPeriodicityType: GenericSelection[] = [
        {
            label: i18n.t("revenue.report_viewer.monthly"),
            value: "MONTHLY"
        },
        {
            label: i18n.t("revenue.report_viewer.bimonthly"),
            value: "BIMONTHLY"
        },
        {
            label: i18n.t("revenue.report_viewer.quarterly"),
            value: "QUARTERLY"
        },
        {
            label: i18n.t<string>("revenue.report_viewer.fourmonthly"),
            value: "FOURMONTHLY"
        },
        {
            label: i18n.t("revenue.report_viewer.semesterly"),
            value: "SEMESTERLY"
        },
        {
            label: i18n.t("revenue.report_viewer.yearly"),
            value: "YEARLY"
        },
    ];

    const optionsSearch: GenericSelection[] = options.map(opt => {
        return {
            label: `${i18n.t('search_by')} ${i18n.t(opt.i18nString)}`,
            value: opt.description
        };
    });

    useEffect(() => {
        timer.current = setTimeout(() => {
            if (!searchContract && !contractType && !searchPeriodicity) {
                doRequest(null, null, flexFieldValueIds, false, null, null, null, null);
            } else {
                doRequest(null, null, flexFieldValueIds, false, searchContract, chooseSearchType[searchType], contractType, searchPeriodicity);
            }
        }, 800);
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [contractType, searchPeriodicity, searchContract]);

    useEffect(() => {
        setSearchContract(undefined);
        setContractType(undefined);
        setSearchPeriodicity(undefined);
    }, [searchType]);

    function handleSearch(e: any) {
        const searchValue: string = e.target.value;

        setSearchContract(searchValue);
    }

    function onChangeSelect(value: string): void {
        setContractType(value);
    }

    function onChangeSelectPeriodicity(value: string): void {
        setSearchPeriodicity(value);
    }

    const placeholder = options.find(opt => opt.description === searchType)?.i18nString ?? options[0].i18nString;

    return (
        <Input.Group compact>
            <Select
                className="select-search"
                value={searchType}
                onChange={setSearchType}
                options={optionsSearch} />
            {
                searchType === "contract" ?
                    <Input
                        className="search-field"
                        ref={searchRef}
                        placeholder={`${i18n.t('search_by')} ${i18n.t(placeholder)}`}
                        onChange={handleSearch}
                        value={searchContract}
                    />
                    :
                    searchType === "contractType" ?
                        <Select
                            className="search-field"
                            key='contractType'
                            onChange={onChangeSelect}
                            placeholder={i18n.t("select")}
                            options={optionsContractType}
                            showArrow
                        />
                        :
                        <Select
                            className="search-field"
                            key='periodicity'
                            onChange={onChangeSelectPeriodicity}
                            placeholder={i18n.t("select")}
                            options={optionsPeriodicityType}
                            showArrow
                        />
            }
        </Input.Group>
    );
}
