import { Table } from "antd";
import i18n from "util/base/i18n";
import { AccountRegistrationTableProps } from "../IAccountRegistration";
import { usePermissionContext } from "context/PermissionContext";

export default function AccountRegistrationTable({
    isFetching,
    tableData,
    selectedRowKeys,
    onChange,
    columns
}: AccountRegistrationTableProps) {
    const { functionalityPermissions } = usePermissionContext()
    const rowSelection = {selectedRowKeys, onChange};

    return (
        <Table
            style={{padding: '0 24px'}}
            loading={{
                spinning: isFetching,
                tip: `${i18n.t<string>("loading")}...`
            }}
            className="gs-table"
            dataSource={tableData}
            columns={columns}
            rowSelection={functionalityPermissions.edit || functionalityPermissions.remove ? rowSelection : null}
            pagination={{ showSizeChanger: true, hideOnSinglePage: tableData?.length < 10 ? true : false, defaultPageSize: 20 }}
            bordered
        />
    );
}   