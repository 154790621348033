import { Button, Col, Row } from "antd";
import { useState } from "react";
import { ColorResult, CustomPicker, HSLColor } from "react-color";
import { ChromePicker } from 'react-color'
import { ICustomColorPickerProps } from "../IColorPicker";
import i18n from "util/base/i18n";
function CustomColorPicker({ handleSave, defaultSelected, setIsVisible }: ICustomColorPickerProps) {
	const [colorSelected, setColorSelected] = useState(defaultSelected);

	return (
		<div className="custom-color-content">
			<Row>
				<div>
					<ChromePicker
						onChange={(color) => setColorSelected(color.hex)}
						color={colorSelected}
						disableAlpha
					/>
				</div>
			</Row>
			<Row className="footer-popover">
				<Col>
					<Button onClick={() => setIsVisible(false)} type="text">
						{i18n.t("cancel")}
					</Button>
				</Col>
				<Col>
					<Button onClick={() => handleSave(colorSelected)} className="gs-main-button" >
						{i18n.t("save")}
					</Button>
				</Col>
			</Row>
		</div>
	)
}

export default CustomPicker(CustomColorPicker);