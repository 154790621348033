interface ICategoryProps {
    id: number | string;
    onClick: any;
    activeOptionLevelIndex: number;
    description: string;
    selectedCheckboxesLength: number;
}

export function Category({ id, onClick, activeOptionLevelIndex, description, selectedCheckboxesLength }: ICategoryProps) {
    const formattedNumber = selectedCheckboxesLength.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });

    return (
        <button
            id={String(id)}
            onClick={() => onClick(id)}
            className={activeOptionLevelIndex === id ?
                "report_viewer_description_block report_viewer_description_block_active" : "report_viewer_description_block"}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <p style={{ margin: 0, padding: 0 }}>{description}</p>
                <p style={{ margin: 0, padding: 0, color: '#0065B3', fontWeight: 500 }}>
                    ({formattedNumber})
                </p>
            </div>
            <span></span>
        </button>
    )
}
