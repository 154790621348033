import { createContext, useContext, useEffect, useState } from "react";
import { HiredTable, IAllocationData, IAllocationModalContext } from "../../IHumanResoucers";
import { Form } from "antd";
import moment from "moment";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

const RuleModalContext = createContext<IAllocationModalContext>(null);

export function AllocationModalProvider({ children }) {
	const [isOpen, setIsOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [resourcesList, setResourcesList] = useState<HiredTable[]>([]);
	const [resourceSelected, setResourceSelected] = useState<HiredTable>();
	const [allocationList, setAllocationList] = useState<IAllocationData[]>([]);
	const [allocationsToSave, setAllocationsToSave] = useState<IAllocationData[]>([]);
	const [isDisabledAllocatedButton, setIsDisabledAllocatedButton] = useState<boolean>(true);
	const [isEditing, setIsEditing] = useState(false);
	const [form] = Form.useForm();

	function closeModal() {
		setIsOpen(false);
		setResourcesList([]);
		setResourceSelected(null);
		setAllocationList([]);
		setAllocationsToSave([]);
		form.resetFields();
		setIsSaving(false);
		setIsEditing(false)
	}

	const chooseAllocationType = {
		"PART_TIME": 0,
		"FULL_TIME": 1
	}

	function onLoadEditAllocation(data: IAllocationData[]) {
		setAllocationList(data.map((item) => {
			return ({
				...item,
				startDate: moment(item.startDate),
				endDate: moment(item.endDate),
				type: chooseAllocationType[item.type],
			})
		}));
		setIsEditing(true);
		form.resetFields();
	}

	useEffect(() => {
		if (!(isEditing && resourceSelected)) return;
		const id = resourceSelected.id ? resourceSelected.id : resourceSelected.key;
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/human-resources/allocation-employed-resource-link/find-by-employed-resource-ids?employedResourceIds=${id}`,
		}, onLoadEditAllocation)
	}, [isEditing, resourceSelected])

	return (
		<RuleModalContext.Provider
			value={{
				isOpen,
				setIsOpen,
				resourcesList,
				setResourcesList,
				resourceSelected,
				setResourceSelected,
				allocationList,
				setAllocationList,
				form,
				closeModal,
				allocationsToSave,
				setAllocationsToSave,
				isSaving,
				setIsSaving,
				isEditing,
				setIsEditing,
				isDisabledAllocatedButton,
				setIsDisabledAllocatedButton
			}}>
			{children}
		</RuleModalContext.Provider>
	)
}

export function useAllocationModalContext() {
	return useContext(RuleModalContext);
};