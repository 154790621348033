import { useEffect, useState } from 'react'
import i18n from 'util/base/i18n'

export default function useOptionFetch(search: string, optionAmount: number, allOptions, hasSelectNone: boolean, isScrollbarVisible: boolean) {
  const [optionsFromFetch, setOptionsFromFetch] = useState([])
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    if(!allOptions) return
    setOptionsFromFetch(allOptions)
  },[allOptions])

  useEffect(() => {
    if (!allOptions) return
    const optionsWithNoneSelect = [...allOptions]

    if (hasSelectNone) {
      optionsWithNoneSelect.unshift({ label: i18n.t("no_option"), value: 0 })
    }

    if (isScrollbarVisible) {
      const itemSelected = optionsWithNoneSelect.find(item => item.isSelected);
      if (itemSelected) {
        const index = optionsWithNoneSelect.findIndex(item => item.value === itemSelected.value);

        if (index !== -1) {
          const item = optionsWithNoneSelect.splice(index, 1)[0];
          optionsWithNoneSelect.unshift(item);
        }
      }
    }

    const updatedOptions = optionsWithNoneSelect.filter(option => option.label.toLowerCase().includes(search.toLowerCase())).slice(0, optionAmount)
    setOptionsFromFetch(updatedOptions)
    setHasMore(updatedOptions.length < allOptions.length)
  }, [optionAmount, allOptions, search])

  return { optionsFromFetch, hasMore }
}