import { useEffect, useState, Key } from "react";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { Button, Layout, Spin, Table } from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType } from "antd/lib/table";
import { useUserContext } from "context/UserContext";

import SiderContent from "./SiderContent";
import { Notification } from "components/notification/Notification";
import { SelectAllCard } from "components/selectAllCard/SelectAllCard";
import { ImageBox } from "components/imageBox/ImageBox";
import { Loading } from "components/loading/Loading";

import { getLevelById } from "../functions/getLevelById";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { DataLevels, DataTypeTable, LevelItem } from "../IGroupings";
import { findNodeTree } from "../functions/findNodeTree";
import { invalidadeQuery } from "util/queryClient";

interface Props {
    selectedTab: "ledger_account" | "cost_center";
    currentTreeData: LevelItem[];
    checkedNodeKeys: number[];
    setCheckedNodeKeys: (keys: number[]) => void;
    addNewSubLevel: (parentId: number) => void;
    selectedKey?: number;
    isTreeDisabled: boolean;
    maxLevelAmount: number;
    expandedKeys: number[];
    setExpandedKeys(n: number[]): void;
    refreshSideBarItems(): void;
    isSelectingAllLinked: boolean;
    setIsSelectingAllLinked: (b: boolean) => void;
    isSelectingAllUnlinked: boolean;
    setIsSelectingAllUnlinked: (b: boolean) => void;
    isFetchingTableData: boolean;
    linkedData: any[];
    unlinkedData: any[];
    getTableLinked(): void;
    standardAccountId?: number;
    levelNumber: number;
    isLastTreeNode: boolean;
    isLoadingTable: boolean;
    setIsLoadingTable: (b: boolean) => void;
    packageLevel: DataLevels;
    setIsSelectPackageLevel: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Content(props: Props) {
    const [selectedLinkedRowKeys, setSelectedLinkedRowKeys] = useState<React.Key[]>([]);
    const [selectedUnlinkedRowKeys, setSelectedUnlinkedRowKeys] = useState<React.Key[]>([]);
    const [tableDataUnlinked, setTableDataUnlinked] = useState<DataTypeTable[]>([]);
    const [tableDataLinked, setTableDataLinked] = useState<DataTypeTable[]>([]);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [searchValueUnlinked, setSearchValueUnlinked] = useState("");
    const [searchValueLinked, setSearchValueLinked] = useState("");
    const { userInfo } = useUserContext();
    const [tableColumnsLinked, setTableColumnsLinked] = useState<ColumnsType<DataTypeTable>>([
        {
            title:
                props.selectedTab === "ledger_account"
                    ? i18n.t<string>("accounts_already_linked")
                    : i18n.t<string>("cost_center_already_linked"),
            dataIndex: "description",
            sorter: (a: DataTypeTable, b: DataTypeTable) => {
                if (a.description > b.description) return 1;
                if (a.description < b.description) return -1;
                return 0;
            },
            render(value, record) {
                return (
                    <div className="title-unlinked-table">
                        <p>{value}</p>
                        {record.accountId === props.standardAccountId && (
                            <Icon icon="bitcoin-icons:verify-outline" style={{ fontSize: 28 }} />
                        )}
                        <Icon icon="akar-icons:link-chain" />
                    </div>
                );
            },
        },
    ]);

    const tableColumnsUnlinked: ColumnsType<DataTypeTable> = [
        {
            title: i18n.t<string>("available"),
            dataIndex: "description",
            sorter: (a: DataTypeTable, b: DataTypeTable) => {
                if (a.description > b.description) return 1;
                if (a.description < b.description) return -1;
                return 0;
            },
        },
    ];

    useEffect(() => {
        setTreeData(props.currentTreeData);
    }, [props.currentTreeData]);

    useEffect(() => {
        setTableDataLinked(props.linkedData);
        props.setIsLoadingTable(false);
    }, [props.linkedData]);

    useEffect(() => {
        if (!props.unlinkedData) return;
        setTableDataUnlinked(
            props.unlinkedData.map((line) => {
                return {
                    description: line.label,
                    key: line.value,
                };
            })
        );
        props.setIsLoadingTable(false);
    }, [props.unlinkedData]);

    useEffect(() => {
        setTableColumnsLinked((state) => [
            {
                ...state[0],
                render(value, record) {
                    return (
                        <div className="title-unlinked-table">
                            <p>{value}</p>
                            <div className="gs-flex align-center jc-center">
                                {record.key === props.standardAccountId && (
                                    <Icon
                                        icon="bitcoin-icons:verify-outline"
                                        style={{ fontSize: 28, marginRight: 8 }}
                                    />
                                )}
                                <Icon icon="akar-icons:link-chain" />
                            </div>
                        </div>
                    );
                },
            },
        ]);
    }, [props.standardAccountId]);

    const onChangeLinked = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedLinkedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: props.checkedNodeKeys[0] ? false : true,
            title: record.title,
        }),
    };

    const onChangeUnlinked = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedUnlinkedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: props.checkedNodeKeys[0] ? false : true,
            title: record.title,
        }),
    };

    function addOrRemoveShowIcon(lines: DataLevels[], isLinked: boolean): DataLevels[] {
        const updatedData = lines;
        const index = updatedData.findIndex((item) => item.key === props.checkedNodeKeys[0]);
        let newTree = findNodeTree(updatedData, props.checkedNodeKeys[0]);
        if (newTree && newTree.children?.length > 0) {
            addOrRemoveShowIcon(newTree.children, isLinked);
        } else {
            return updatedData;
        }

        if (newTree.showIcon && isLinked) {
            newTree.showIcon = false;
        } else if (!newTree.showIcon && !isLinked) {
            newTree.showIcon = true;
        }

        updatedData[index] = newTree;
        return updatedData;
    }

    function updateLinkData(isLinked: boolean, row: React.Key[], dataTable: DataTypeTable[]) {
        let url = "";
        props.setIsLoadingTable(true);

        if (props.selectedTab === "ledger_account") {
            url = "account-hierarchy-link";
        } else {
            url = "cost-center-hierarchy-link";
        }

        const linkedToSave = row.reduce((acc, val) => {
            // row
            const lineSelected = dataTable.find((line) => line.key === val); // data
            if (!lineSelected) return acc;

            let linkedToSave = {
                clientId: userInfo.clientId,
                selected: !isLinked,
                id: lineSelected.id ? lineSelected.id : null,
            };

            if (props.selectedTab === "ledger_account") {
                linkedToSave["hierarchyAccountId"] = props.checkedNodeKeys[0];
                linkedToSave["accountId"] = lineSelected.key;
                linkedToSave["accountName"] = lineSelected.description;
                linkedToSave["spendingPackageId"] = props.packageLevel?.id ?? null;
            } else {
                linkedToSave["hierarchyCostCenterId"] = props.checkedNodeKeys[0];
                linkedToSave["costCenterId"] = lineSelected.key;
                linkedToSave["costCenterName"] = lineSelected.description;
            }

            acc.push(linkedToSave);
            return acc;
        }, []);
        const updatedData = addOrRemoveShowIcon(treeData, isLinked);
        setTreeData(updatedData);

        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: `/budget-base/${url}`,
                useProxy: true,
                params: linkedToSave,
            },
            () => {
                invalidadeQuery([
                    props.selectedTab === "ledger_account" ? "all-account-linked" : "all-cost-center-linked",
                ]);
                Notification({
                    type: "success",
                    message: isLinked
                        ? i18n.t("groupings_texts.unlinked_successfully")
                        : i18n.t("groupings_texts.linked_successfully"),
                });
                setSelectedLinkedRowKeys([]);
                setSelectedUnlinkedRowKeys([]);
                props.setIsSelectingAllLinked(false);
                props.setIsSelectingAllUnlinked(false);
                props.getTableLinked();
                props.refreshSideBarItems();
            }
        );
    }

    function onClickLink() {
        updateLinkData(false, selectedLinkedRowKeys, tableDataFiltered);
    }

    function onClickUnlinked() {
        updateLinkData(true, selectedUnlinkedRowKeys, tableDataLinkedFiltered);
    }

    function onChangeSearchUnlinked(value) {
        setSearchValueUnlinked(value.target.value);
    }

    function onChangeSearchLinked(value) {
        setSearchValueLinked(value.target.value);
    }

    const tableDataFiltered = tableDataUnlinked
        ?.filter((line) => !tableDataLinked.some((linkedLine) => line.key === linkedLine.key))
        ?.filter((line) => line.description?.toLowerCase().includes(searchValueUnlinked.toLowerCase()));

    const tableDataLinkedFiltered = tableDataLinked
        ?.filter((line) => line.treeId === props.checkedNodeKeys[0])
        ?.filter((line) => line?.description?.toLowerCase().includes(searchValueLinked.toLowerCase()));

    // : tableDataUnlinked.length === 0 ?
    // 						<ImageBox
    // 							styles={{ flex: 1 }}
    // 							message={`Parece que não há ${props.selectedTab === "ledger_account" ? 'contas' : 'centros de custo'} liberadas para o seu usúario`}
    // 						/>

    return (
        <Layout>
            <Layout.Sider width={"unset"}>
                <SiderContent
                    tableData={tableDataUnlinked}
                    addNewSubLevel={props.addNewSubLevel}
                    setCheckedNodeKeys={props.setCheckedNodeKeys}
                    checkedNodeKeys={props.checkedNodeKeys}
                    treeData={treeData}
                    isTreeDisabled={props.isTreeDisabled}
                    expandedKeys={props.expandedKeys}
                    setExpandedKeys={props.setExpandedKeys}
                    setIsSelectPackageLevel={props.setIsSelectPackageLevel}
                />
            </Layout.Sider>
            <Layout.Content>
                {props.isFetchingTableData ? (
                    <Loading />
                ) : props.isLastTreeNode &&
                  props.levelNumber === props.maxLevelAmount &&
                  props.checkedNodeKeys.length === 1 ? (
                    <>
                        <div className="content-header">
                            <div>
                                <p>{i18n.t(props.selectedTab)}</p>
                            </div>
                            <Search
                                placeholder={i18n.t("search")}
                                style={{ marginBottom: 5 }}
                                className={"linked-search"}
                                onChange={onChangeSearchUnlinked}
                            />
                            <Search
                                placeholder={i18n.t("search")}
                                style={{ marginBottom: 5 }}
                                onChange={onChangeSearchLinked}
                            />
                        </div>
                        <Spin spinning={props.isLoadingTable}>
                            <div className="content-tables">
                                <div>
                                    {props.isSelectingAllLinked && (
                                        <SelectAllCard
                                            selectedRowKeys={selectedLinkedRowKeys}
                                            setSelectedRowKeys={setSelectedLinkedRowKeys}
                                            tableData={tableDataFiltered}
                                            setIsSelectingAll={props.setIsSelectingAllLinked}
                                        />
                                    )}
                                    <Table
                                        className={
                                            props.isSelectingAllLinked ? "gs-table selected-all-table" : "gs-table"
                                        }
                                        rowSelection={{
                                            type: "checkbox",
                                            ...onChangeLinked,
                                            onSelectAll: (selected) => props.setIsSelectingAllLinked(selected),
                                            selectedRowKeys: selectedLinkedRowKeys,
                                        }}
                                        bordered
                                        columns={tableColumnsUnlinked}
                                        dataSource={tableDataFiltered}
                                        pagination={{ hideOnSinglePage: true, showSizeChanger: true }}
                                    />
                                    <Button
                                        onClick={onClickLink}
                                        disabled={selectedLinkedRowKeys.length === 0}
                                        className="gs-main-button"
                                        icon={<Icon icon="akar-icons:link-chain" />}
                                        style={{ marginTop: tableDataFiltered.length <= 10 ? 12 : 0 }}
                                    >
                                        {i18n.t("link")}
                                    </Button>
                                </div>
                                <div>
                                    {props.isSelectingAllUnlinked && (
                                        <SelectAllCard
                                            selectedRowKeys={selectedUnlinkedRowKeys}
                                            setSelectedRowKeys={setSelectedUnlinkedRowKeys}
                                            tableData={tableDataLinkedFiltered}
                                            setIsSelectingAll={props.setIsSelectingAllUnlinked}
                                        />
                                    )}
                                    <Table
                                        className={
                                            props.isSelectingAllUnlinked ? "gs-table selected-all-table" : "gs-table"
                                        }
                                        rowSelection={{
                                            ...onChangeUnlinked,
                                            onSelectAll: (selected) => props.setIsSelectingAllUnlinked(selected),
                                            selectedRowKeys: selectedUnlinkedRowKeys,
                                        }}
                                        columns={tableColumnsLinked}
                                        bordered
                                        dataSource={tableDataLinkedFiltered}
                                        pagination={{ hideOnSinglePage: true, showSizeChanger: true }}
                                    />
                                    {tableDataLinkedFiltered.length > 0 && (
                                        <Button
                                            onClick={onClickUnlinked}
                                            disabled={selectedUnlinkedRowKeys.length === 0}
                                            className="gs-secondary-button"
                                            icon={<Icon icon="pajamas:unlink" />}
                                            style={{ marginTop: tableDataLinkedFiltered.length <= 10 ? 12 : 0 }}
                                        >
                                            {i18n.t("unlink")}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Spin>
                    </>
                ) : props.checkedNodeKeys.length > 1 ? (
                    <div className="tree-level-warning-container">
                        <ImageBox
                            imgStyles={{ width: 250 }}
                            imgName="image_bw_01"
                            message={i18n.t("groupings_texts.select_one_level_at_a_time_to_link_accounts")}
                        />
                    </div>
                ) : props.isLastTreeNode && props.levelNumber < props.maxLevelAmount ? (
                    <div className="tree-level-warning-container">
                        <Icon icon="carbon:tree-view-alt" style={{ margin: "0 auto" }} />
                        <p style={{ margin: "0 auto" }}>{i18n.t("groupings_texts.incomplete_tree")}</p>
                        <p style={{ maxWidth: 530, margin: "10px auto" }}>
                            {i18n.t("groupings_texts.hierarchy_trees")}
                            {i18n.t("groupings_texts.current_account_linkage_level")} <b>{props.maxLevelAmount}</b>,{" "}
                            {i18n.t("groupings_texts.and_the_selected_level_is")} <b>{props.levelNumber}</b>,
                            {i18n.t("groupings_texts.so_create_more")} <b>{props.maxLevelAmount - props.levelNumber}</b>{" "}
                            {props.maxLevelAmount - props.levelNumber > 1 ? i18n.t("levels") : i18n.t("level")}{" "}
                            {i18n.t("groupings_texts.enable_account")}
                        </p>
                    </div>
                ) : (
                    <div className="tree-level-warning-container">
                        <ImageBox
                            imgStyles={{ width: 230 }}
                            imgName="image_bw_02"
                            message={i18n.t("groupings_texts.select_the_last_tree_level_to_link")}
                        />
                    </div>
                )}
            </Layout.Content>
        </Layout>
    );
}
