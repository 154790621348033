import { CardSkeleton } from "components/skeleton/cardSkeleton/CardSkeleton";
import { useAccountingLedgerContext } from "../../context/AccountingLedgerContext";
import "./styles.sass";
import { Card } from "./Card";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";

export function SummaryCards() {

    const { totalEntryValue, totalItems, isLoading, currency } = useAccountingLedgerContext();
    
    return (
        <div id="summary-cards-container-accounting-ledger">
            {isLoading ? (
                <CardSkeleton direction="row" active amount={2} gap={10} cardClassName="summary-card-skeleton-accounting-ledger" />
            ) : (
                <>
                <Card
                        label={i18n.t("total_records")}
                        icon={<Icon icon="fa6-solid:list-check" style={{ color: "#4F9ACF" }} />}
                        value={totalItems}
                        type="none"
                    />
                    <Card
                        label= {i18n.t("total_accomplished")}
                        icon={<Icon icon="ic:baseline-attach-money" style={{ color: "#4F9ACF" }} />}
                        value={totalEntryValue}
                        type="currency"
                        isoCode={currency?.isoCode}
                    />
                    
                </>
            )}
        </div>
    );
}




