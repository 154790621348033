import { UseQueryOptions } from '@tanstack/react-query';

import useCachedQuery from "./useCachedQuery";
import { RequestType } from "util/service/IServiceCaller";

import { ICurrency } from 'util/types/types';

export function useUserCurrency(userId: number, queryProps = {} as UseQueryOptions<ICurrency>) {
    const queryInfo = useCachedQuery<ICurrency>(
        ['user-currency'],
        {
            type: RequestType.GET,
            url: `/monolith/currency/findById?id=${userId}&clientId={client}&locale={locale}`,
        },
        queryProps
    )
    
    return queryInfo;
}

