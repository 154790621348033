import { Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { IAccounting } from "../../../IProjectionModeling";
import { IExpressions, IFormModalProps } from "../IStepTwo";
import { selectProps } from "util/props/props";

interface ProjectionModelingModalProps {
    accountingList: Options[];
    projectionsOptions;
    itemIndex: number;
    isModalVisible: boolean;
    plainFormula: IExpressions[];
    setPlainFormula: React.Dispatch<React.SetStateAction<IExpressions[]>>;
    handleCancel: () => void;
}

export default function ProjectionModelingModal({
    accountingList,
    projectionsOptions,
    itemIndex,
    isModalVisible,
    plainFormula,
    setPlainFormula,
    handleCancel,
}: ProjectionModelingModalProps) {
    const [costCenterOptions, setCostCenterOptions] = useState<Options[]>([]);
    const [costCenterSelected, setCostCenterSelected] = useState<number>(null);
    const [accountingSelected, setAccountingSelected] = useState<number>(null);
    const [projectionSelected, setProjectionSelected] = useState<number>(null);
    const [periodSelected, setPeriodSelected] = useState<string>("");
    const [taxSelected, setTaxSelected] = useState<string>('0');
    const [isSaving, setIsSaving] = useState(false);

    const [form] = Form.useForm<IFormModalProps>();

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/planning/management-cost-center",
            },
            onLoadCostCenter
        );
    }, []);

    useEffect(() => {
        if (!plainFormula || itemIndex === undefined) {
            return;
        }

        const currentItem = plainFormula.find((item) => item.position === itemIndex) || null;

        if (currentItem) {
            form.setFieldsValue({
                projectionId: currentItem.projectionId,
                accountingId: currentItem.managementAccountingAccountId,
                costCenterId: currentItem.managementCostCenterId,
                acquisitionDate: currentItem.period ? moment(currentItem.period, "YYYY-MM") : null,
                tax: currentItem.rate,
            });

            setProjectionSelected(currentItem.projectionId);
            setAccountingSelected(currentItem.managementAccountingAccountId);
            setCostCenterSelected(currentItem.managementCostCenterId);
            setPeriodSelected(currentItem.period);
            setTaxSelected(currentItem.rate ? currentItem.rate.toString() : "0");
        }
    }, [plainFormula, itemIndex, form]);

    const onLoadCostCenter = (data: IAccounting[]) => {
        const costCenters: Options[] = [
            {
                value: null,
                label: "Todos",
            },
            ...data.map((item) => ({
                value: item.id,
                label: `${item.externalCode} - ${item.description}`,
            })),
        ];

        setCostCenterOptions(costCenters);
    };

    const validateDateFields = (acquisitionDate: Moment, activationDate: Moment) => {
        if (!activationDate) return Promise.resolve();
        if (acquisitionDate.isBefore(activationDate, "month") || acquisitionDate.isSame(activationDate, "month")) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error("A data de ativação não pode ser menor que a data do período"));
        }
    };

    const handleDateChange = (date: Moment | null) => {
    if (date && date.isValid()) {
        const newActivationDate = date.clone().format("YYYY-MM");
        setPeriodSelected(newActivationDate);
        setTaxSelected('100');
        form.setFieldsValue({ tax: 100 });
    }
};

    const disabledDate = (date: Moment): boolean => {
        return false;
    };

    function submitModal(): void {
        if (!projectionSelected || !accountingSelected || periodSelected === "" || taxSelected === "0") {
            return;
        }

        setIsSaving(true);

        const auxPlainFormula = [...plainFormula];
        const existingExpressionIndex = auxPlainFormula.findIndex((expression) => expression.position === itemIndex);

        const newExpression: IExpressions = {
            position: itemIndex,
            staticField: "",
            fieldType: null,
            operator: "",
            value: null,
            modularFieldId: null,
            scenarioId: null,
            organizationId: null,
            rate: Number(taxSelected),
            projectionId: projectionSelected,
            managementAccountingAccountId: accountingSelected,
            managementCostCenterId: costCenterSelected,
            period: periodSelected,
        };

        if (existingExpressionIndex !== -1) {
            auxPlainFormula[existingExpressionIndex] = {
                ...auxPlainFormula[existingExpressionIndex],
                ...newExpression,
            };
        } else {
            auxPlainFormula.push(newExpression);
        }

        setPlainFormula(auxPlainFormula);
        setIsSaving(false);
        handleCancel();
    }

    return (
        <Modal
            width={600}
            okButtonProps={{
                htmlType: "submit",
                form: "projection-modeling",
                loading: isSaving,
            }}
            title={"Projeção de Modelagem"}
            visible={isModalVisible}
            onCancel={handleCancel}
            className="gs-modal account-registration-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            onOk={submitModal}
        >
            <Form form={form} layout="vertical" name="projection-modeling">
                <div>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="projectionId"
                                label={"Projeção"}
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <Select
                                    onChange={(value) => setProjectionSelected(value)}
                                    options={projectionsOptions}
                                    showSearch
                                    placeholder={i18n.t<string>("select")}
									allowClear
									{...selectProps}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="accountingId"
                                label={"Conta Contábil Gerencial"}
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <Select
                                    onChange={(value) => setAccountingSelected(value)}
                                    value={accountingSelected}
                                    options={accountingList}
                                    showSearch
                                    placeholder={i18n.t<string>("select")}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="costCenterId" label={"Centro de Custo Gerencial"}>
                                <Select
                                    defaultValue={null}
                                    onChange={(value) => setCostCenterSelected(value)}
                                    options={costCenterOptions}
                                    showSearch={true}
                                    placeholder={i18n.t<string>("select")}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="acquisitionDate"
                                label={"Período"}
                                rules={[
                                    { required: true, message: i18n.t<string>("required_field") },
                                    ({ getFieldValue }) => ({
                                        validator(_, value: Moment) {
                                            return validateDateFields(value, getFieldValue("activationDate"));
                                        },
                                    }),
                                ]}
                            >
                                <DatePicker
                                    className="gs-date-picker"
                                    picker={"month"}
                                    disabledDate={disabledDate}
                                    format={"MM/YYYY"}
                                    onChange={handleDateChange}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="tax"
                                label={"Taxa (%)"}
                                rules={[
                                    { required: true, message: i18n.t<string>("required_field") },
                                    () => ({
                                        validator(_, value: any) {
                                            if (value > 100) {
                                                return Promise.reject(
                                                    new Error("A taxa não pode ser maior do que 100%")
                                                );
                                            }

                                            if (value === 0) {
                                                return Promise.reject(
                                                    new Error("A taxa deve ser maior que 0% para gerar valores")
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    className="tax input-order"
                                    style={{ width: "100%" }}
                                    placeholder={"Taxa"}
                                    onChange={(event) => setTaxSelected(event.target.value) }
                                    type="number"
                                    min={0}
                                    max={100}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Modal>
    );
}
