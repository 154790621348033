import { Icon } from "@iconify/react"
import { Collapse } from "antd"
import { ReactElement, useContext, useState } from "react"
import i18n from "util/base/i18n"
import { RequestType } from "util/service/IServiceCaller"
import { ServiceCaller } from "util/service/ServiceCaller"
import { IFilter, IWidget, WidgetType } from "../IDashboardAnalytics"
import { widgetDefinitions } from "../IWidgetDefinition"
import ButtonExpand from "./ButtonExpand"
import { useUserContext } from "context/UserContext"

interface Props {
	currencyId: number,
	configId: Number,
	globalFilter: IFilter,
	widgets: Array<IWidget>,
	openedKeys: string[];
	setOpenedKeys: React.Dispatch<React.SetStateAction<string[]>>
}

export default function WidgetContent(props: Props) {

	const { userInfo } = useUserContext();

	const onChange = (e) => {
		let id: String;
		let type: String;

		if (e.length < props.openedKeys.length) {
			id = props.openedKeys.filter((index) => {
				return !e.includes(index);
			}).toString();
			type = 'close';
		} else {
			id = e[e.length - 1];
			type = 'open';
		}

		id = id.split('-')[1];
		ServiceCaller.doRequest({
			type: RequestType.PATCH,
			url: `/dashboard-analytics/widget/${id}/${type}`
		});
		props.setOpenedKeys(e);
	}

	const onClickExpand = (e) => {
		if (e) {
			props.setOpenedKeys(props.widgets.filter(({ active }) => active).map((item) => 'widget-' + item.id));
		} else {
			props.setOpenedKeys([])
		}
	}

	const buildWidgets = (): ReactElement[] => {
		const widgets = props.widgets;

		return widgets
			.sort(({ position: position1 }, { position: position2 }) => (position1 < position2 ? -1 : 1))
			.filter(({ active }) => active)
			.map(({ id, type, filter, content }) => {
				const definitions = widgetDefinitions[type];
				if (userInfo.plan === "FREEMIUM") {
					if (type !== WidgetType.BANK_CONCILIATION_PENDING) {
						return (
							<>
								<Collapse.Panel className="widget-content cm-step4" key={`widget-${id}`} header={<><Icon icon={definitions.icon} /> {i18n.t<string>(definitions.title)}</>}>
									{definitions.renderComponent(props.globalFilter, props.currencyId, filter, content)}
								</Collapse.Panel>
							</>
						)
					}
				} else {
					return (
						<>
							<Collapse.Panel className="widget-content cm-step4" key={`widget-${id}`} header={<><Icon icon={definitions.icon} /> {i18n.t<string>(definitions.title)}</>}>
								{definitions.renderComponent(props.globalFilter, props.currencyId, filter, content)}
							</Collapse.Panel>
						</>
					)
				}
				
			});
	}

	return (
		<div className="widgets-main">
			<ButtonExpand widgetLength={props.widgets.length} onClick={onClickExpand} configId={props.configId} activeKeys={props.openedKeys} />
			<Collapse defaultActiveKey={props.openedKeys} activeKey={props.openedKeys} onChange={onChange} ghost className="widget-collapse" expandIconPosition="right" >
				{buildWidgets()}
			</Collapse>
		</div>
	)
}