import { FlexFieldFilterResponse, FlexFieldValueListBean, FlexFilter, ModuleType, Options } from "util/types/types";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { OtherEventProviderProps, OtherEventContextProps } from "./IOtherEventContext";
import {
    OtherEventGridData,
    BudgetOpeningCachedData,
    CurrenciesRate,
    OtherEventDataResponse,
    OtherEventColumn,
} from "../IOtherEvents";
import { useUserContext } from "context/UserContext";
import { useLocalStorage } from "hooks/useLocalStorage";
import { createGridData } from "../components/gridContainer/utils/createGridData";
import { useErrorBoundary } from "react-error-boundary";
import { createPlannedMonthsMock as createotherEventColumnMock } from "../components/gridContainer/functions/createPlannedMonthsMock";
import useCachedQuery from "hooks/useCachedQuery";
import { queryClient } from "util/queryClient";
import { useCurrencyOptions } from "hooks/useCurrencyOptions";
import { useUserCurrency } from "hooks/useUserCurrency";
import { useBudgetDates } from "hooks/useBudgetDates";
import { ErrorFallbackProps } from "components/error/ErrorFallback";

const OtherEventContext = createContext<OtherEventContextProps>({} as OtherEventContextProps);

export function OtherEventProvider({ children }: OtherEventProviderProps) {
    const { userInfo } = useUserContext();
    const [otherEventCached, setOtherEventsCached] = useLocalStorage(
        `other-events-${userInfo.clientId}-${userInfo.id}-${userInfo.selection.scenarioId}-${userInfo.selection.organizationId}-${userInfo.selection.businessUnitId}`,
        {}
    );
    const [otherEventDataSource, setOtherEventGridData] = useState<OtherEventGridData[]>([]);
	const [flexFieldStringKey, setFlexFieldStringKey] = useState<string[]>([]);
    const [period, setPeriod] = useState<[moment.Moment, moment.Moment]>([null, null]);
    const [currenciesOptions, setCurrenciesOptions] = useState<Options[]>(null);
    const [flexFieldsFilters, setFlexFieldsFilters] = useState<FlexFilter[]>([]);
    const [isFetchingFlexFields, setIsFetchingFlexFields] = useState(true);
    const [error500, setError500] = useState(false);
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [isQueryByPackage, setIsQueryByPackage] = useState(false);
    const [isLoadingCacheData, setIsLoadingCacheData] = useState(true);
    const [isFetchingGridData, setIsFetchingGridData] = useState(true);
    const [selectedRows, setSelectedRows] = useState<OtherEventGridData[]>([]);
    const [isOnlyChangingPeriod, setIsOnlyChangingPeriod] = useState(false);
    const [currenciesSelected, setCurrenciesSelected] = useState<Options[]>([{value:userInfo.currencyId, label:''}]);
	const [openedCalendarPeriod, setOpenedCalendarPeriod] = useState<[moment.Moment, moment.Moment][]>([
        [moment().startOf("year"), moment().month(11).endOf("month")],
    ]);
    const [otherEventColumnMock, setOtherEventColumnMock] = useState<OtherEventColumn[]>([]);
    const [openBudgetYears] = useState([]);
    const { showBoundary } = useErrorBoundary();
    const { data: currencyOptions } = useCurrencyOptions();
    const { data: currency } = useUserCurrency(userInfo.currencyId, { enabled: !!userInfo.id });
    const { data: currenciesConversions } = useCachedQuery(
        ["currenciesConversions"],
        {
            type: RequestType.GET,
            url: `/budget-base/currency-conversion/find-conversion-by-origin?origin=${userInfo.currencyId}`,
        },
        { enabled: !!userInfo.id }
	);
	const { data: budgetPeriodDates } = useBudgetDates(ModuleType.PLANNING);
    
    useEffect(() => {
        if (userInfo && currenciesOptions) {
            const userCurrency = currenciesOptions.find(item => item.value === userInfo.currencyId);
            if (userCurrency) {
                setCurrenciesSelected([{ value: userInfo.currencyId, label: userCurrency.label }]);
            }
			getFlexFieldFilters();
		}

    }, [userInfo, currenciesOptions]);

    useEffect(() => {
        if (!currencyOptions || !currenciesConversions) return;
        handleAddCurrenciesOptFilter(currencyOptions, currenciesConversions);
    }, [currencyOptions, currenciesConversions]);

    useEffect(() => {
        if (
            !userInfo.id ||
            !userInfo.currencyId ||
            !currenciesOptions ||
            otherEventDataSource.length > 0
        )
            return;

        let [startDateFormated, finalDateFormated]: [number, number] = [
            moment(period[0]).startOf("month").valueOf(),
            moment(period[1]).endOf("month").valueOf(),
        ];
        const defaultFormat: string = "YYYY/MM";

        if (
            otherEventCached.organizationId &&
            userInfo.selection.organizationId === otherEventCached.organizationId &&
            userInfo.id === otherEventCached.userId &&
            userInfo.selection.scenarioId === otherEventCached.scenarioId &&
            (moment(startDateFormated).format(defaultFormat) === openedCalendarPeriod[0][0].format(defaultFormat) &&
                moment(finalDateFormated).format(defaultFormat) === openedCalendarPeriod[0][1].format(defaultFormat))
        ) {
            ServiceCaller.doRequest(
                {
                    type: RequestType.POST,
                    url: "/planning/other-event",
                    params: { ...otherEventCached, startDate: startDateFormated, finalDate: finalDateFormated },
                },
				(data: OtherEventDataResponse[]) => {
                    setFlexFieldStringKey(otherEventCached.flexKey);
                    onLoadOtherEventData(data);
                },
                (err) => showBoundary(err)
            );
        } else {
            setIsLoadingCacheData(false);
            setIsFetchingGridData(false);
        }
    }, [
        currenciesOptions,
        userInfo,
        otherEventCached,
    ]);

    useEffect(() => {
        if (period[0] !== null && userInfo.currencyId) {
            const plannedMonths = createotherEventColumnMock(period, userInfo.currencyId);

            setOtherEventColumnMock(plannedMonths);
        }
	}, [period, userInfo.currencyId]);

	useEffect(() => {
		if (!budgetPeriodDates) {
			return;
		}
		if (budgetPeriodDates.period?.length === 0) {
			showBoundary({
				code: 400,
				title: "Não há exercício aberto",
				message: "Adicione um exercício na tela de painel de controle, ou peça permissão para o exercício",
				image: "empty_list",
			} as ErrorFallbackProps["error"]);
		}
		const openedPeriods = budgetPeriodDates.period?.reduce((acc, val) => {
			const period = [moment(val.expensesResourcesBudgetStartDate), moment(val.expensesResourcesBudgetEndDate)];
			acc.push(period);
			return acc;
		}, []);
		setOpenedCalendarPeriod(openedPeriods);
	}, [budgetPeriodDates])

    function onChangeQueryType() {
        setIsQueryByPackage((state) => !state);
    }

	async function onLoadFlexField(data: FlexFieldFilterResponse[]) {
        const flexField: FlexFilter[] = data
            .sort((a, b) => Number(a.ordenation) - Number(b.ordenation))
            .map((item) => {
                return {
                    label: item.description,
                    value: item.id,
                    linkedFilters: item.filters ? item.filters : null,
                    ordenation: item.ordenation,
                    fieldCode: Number(item.fieldCode.split("_").pop()),
                    selectedOption: [],
                    children: [],
                };
			});

        try {
			for (const ff of flexField) {
                    let childFilterValues: any = queryClient.getQueryData(
                        ["flexField_values_with_no_link", ff.value],
                        {}
                    );

                    if (!childFilterValues) {
                        childFilterValues = await ServiceCaller.doAsyncRequest({
                            type: RequestType.GET,
                            url: `/budget-base/flex-field-value?user={user}&flexFieldId=${ff.value}`,
                        });

                        queryClient.setQueryData(["flexField_values_with_no_link", ff.value], childFilterValues, {});
                    }

                    ff.children = childFilterValues.data.map((item) => ({
                        value: item.id,
                        label: `${item.externalCode} - ${item.description}`,
                        linkedParentFlexFieldId: item.flexFieldFilters,
                    }));
			}
        } catch (err) {
            localStorage.removeItem(
                `budget-opening-${userInfo.clientId}-${userInfo.id}-${userInfo.selection.scenarioId}-${userInfo.selection.organizationId}-${userInfo.selection.businessUnitId}`
            );
        }

        setFlexFieldsFilters(flexField);
        setIsFetchingFlexFields(false);
    }

	async function onLoadOtherEventData(otherEventDataResponse: OtherEventDataResponse[]) {
        setIsFirstOpen(false);

		const currentPeriod: [moment.Moment, moment.Moment] = period[0] ? period : otherEventCached.period.map((date: moment.MomentInput) => moment(date));
		const otherEventColumnMock: OtherEventColumn[] = createotherEventColumnMock(currentPeriod, userInfo.currencyId);

		setOtherEventColumnMock(otherEventColumnMock);

		otherEventDataResponse.forEach(({ rows }) => {
			rows.forEach(row => {
				row.columns.forEach((column) => {
					if (otherEventColumnMock.find(x => x.monthYear === column.monthYear)) {
						let i = otherEventColumnMock.findIndex(x => x.monthYear === column.monthYear);
						otherEventColumnMock[i] = { ...otherEventColumnMock[i], ...column };
					}
				})

				row.columns = [...otherEventColumnMock];
			});
		})
        
        await ServiceCaller.doAsyncRequest<CurrenciesRate[]>({
            type: RequestType.GET,
            url: `/budget-base/currency-conversion-tax/currency-exchange-rate?origin=${
                    currenciesSelected[0].value
                }&destination=${
                    userInfo.currencyId
                }&scenario={scenario}&startDate=${
                    currentPeriod[0].format("YYYY-MM")
                }&endDate=${
                    currentPeriod[1].format("YYYY-MM")
                }`,
		}).then((res) => {
			otherEventDataResponse.forEach(({ rows }) => {
				rows.forEach((row) => {
					row.columns.forEach((otherEventColumn) => {
						const rateOfMonth = res.data.find((rate) => rate.month === otherEventColumn.monthLabel);
						if (rateOfMonth) {
							otherEventColumn.rate = rateOfMonth.rate;
						} else {
							otherEventColumn.rate = 1;
						}
					});
				});
			})
        });
            
        const updatedGridData = createGridData(
            otherEventDataResponse,
            otherEventColumnMock,
            userInfo.currencyId,
            currenciesOptions
        );

        setOtherEventGridData(updatedGridData);

        setIsFetchingGridData(false);
        setIsLoadingCacheData(false);
    }

	async function getFlexFieldFilters() {
        let flexfields: any = queryClient.getQueryData(["all-flex-field-by-module", "planning"], {});

        if (!flexfields) {
            flexfields = await ServiceCaller.doAsyncRequest({
                type: RequestType.GET,
                url: "/budget-base/flex-field/find-all-by-module?module=PLANNING",
            });

            queryClient.setQueryData(["all-flex-field-by-module", "planning"], flexfields, {});
        }
		onLoadFlexField(flexfields.data);
	}

    function handleAddCurrenciesOptFilter(currencyOptions, currenciesConversions) {
        setCurrenciesOptions(
            currencyOptions.filter(
                (cur) => currenciesConversions.includes(cur.value) || cur.value === userInfo.currencyId
            )
        );
    }

    function handleChangePeriod(period) {
        setPeriod(period);
        setIsOnlyChangingPeriod(true);
    }

    return (
        <OtherEventContext.Provider
            value={{
                period,
                setPeriod,
                currenciesOptions,
                flexFieldsFilters,
                setFlexFieldsFilters,
                flexFieldStringKey,
                setFlexFieldStringKey,
                isFetchingFlexFields,
                error500,
                setError500,
                openedCalendarPeriod,
                selectedRows,
                setSelectedRows,
                userInfo,
                otherEventCached,
                setOtherEventsCached,
                otherEventColumnMock,
                otherEventDataSource,
                setOtherEventGridData,
				onLoadOtherEventData,
                isFirstOpen,
                isFetchingGridData,
                setIsFetchingGridData,
                isLoadingCacheData,
                currency,
                setIsLoadingCacheData,
                openBudgetYears,
                isQueryByPackage,
                setIsQueryByPackage,
                onChangeQueryType,
                currenciesSelected,
                setCurrenciesSelected,
                isOnlyChangingPeriod,
                setIsOnlyChangingPeriod,
                handleChangePeriod
            }}
        >
            {children}
        </OtherEventContext.Provider>
    );
}

export function useOtherEventContext() {
    const context = useContext(OtherEventContext);

    return context;
}
