import { Button, Input } from "antd";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import LoadingChanges from "module/budget/pages/revenue/mainFlow/grid/header/LoadingChanges";
import i18n from "util/base/i18n";
import Filters from "../../components/Filters";
import { IHeaderGrid } from "../../IConsolidation";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { Icon } from "@iconify/react";
import { handleExportGridDataWithChildren } from "util/functions/handleExportGridData";
import { useContext, useState } from "react";
import { ConsolidationEliminationContext } from "../context/ConsolidationEliminationContext";
import ReplicateValuesModal from "./ReplicateValuesModal";

export default function HeaderGrid({
    isLoading,
    hasSavedValue,
    search,
    selectedRows,
    organizationsOptions,
    flexOrganizationOptions,
    currentStep,
    organizationSelected,
    setOrganizationSelected,
    flexOrganizationSelected,
    setFlexOrganizationSelected,
    isFetchingOrganizations,
    isFetchingFlexOrganizations,
    consolidationId,
    fetchRates,
    importProps,
    handleSuccessImport
}: IHeaderGrid) {
    const [isReplicateOpen, setIsReplicateOpen] = useState<boolean>(false);
    const { scenarioSelected } = useContext(ConsolidationEliminationContext);
    const replicateValuesItem: ItemType[] = [
        {
            key: "replicate",
            label: (
                <Button
                    className="grid-operation-importExport-btn"
                    type="text"
                    icon={<Icon icon="system-uicons:replicate" />}
                    onClick={() => onClickReplicate()}
                >
                    {i18n.t("consolidation.replicate_values_last_year")}
                </Button>
            ),
        },
    ]

    const onClickReplicate = () => {
        setIsReplicateOpen(true);
    }

    const onSaveReplicate = () => {
        fetchRates(scenarioSelected[0].value);
    }
    const {
        currentColumns,
        currentTableData,
        titleToExport
    } = useContext(ConsolidationEliminationContext);

    const exportGridData = () => {
        handleExportGridDataWithChildren(currentTableData, currentColumns, titleToExport);
    }

    return (
        <div className="consolidation-table-header">
            <Filters
                selectedRows={selectedRows}
                organizationsOptions={organizationsOptions}
                flexOrganizationOptions={flexOrganizationOptions}
                currentStep={currentStep}
                organizationSelected={organizationSelected}
                setOrganizationSelected={setOrganizationSelected}
                flexOrganizationSelected={flexOrganizationSelected}
                setFlexOrganizationSelected={setFlexOrganizationSelected}
                isFetchingOrganizations={isFetchingOrganizations}
                isFetchingFlexOrganizations={isFetchingFlexOrganizations}
            />
            <div className="consolidation-search-container">
                <LoadingChanges
                    isLoading={isLoading}
                    hasSavedValue={hasSavedValue}
                />
                <div style={{ display: 'flex' }}>
                    <Input placeholder={i18n.t<string>("flexField.search_by_description")} onInput={(e: any) => search(e.target.value)} style={{ width: 200 }} />
                    <div style={{
                        paddingTop: 5
                    }}>
                        <ImportExportMenu
                            exportGridData={exportGridData}
                            buttonType="3dots"
                            otherFeatures={replicateValuesItem}
                            importProps={importProps}
                            handleSuccessImport={handleSuccessImport}
                        />
                    </div>
                </div>
            </div>
            <ReplicateValuesModal
                isOpen={isReplicateOpen}
                setIsOpen={setIsReplicateOpen}
                consolidationId={consolidationId}
                onSave={onSaveReplicate}
            />
        </div>
    )
}