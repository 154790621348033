import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ICoachProps, IDimension } from '../ICoachMark';
import { dimensionSetter, coachMarkPlacementCalculator } from '../utils/coachUtils';

const CoachMark: React.FC<ICoachProps> = (props) => {

    let element = document.querySelector(`.cm-step${props.step}-main`);

    const [dimension, setDimension] = useState<IDimension | null>(null);

	useEffect(() => {
		if (!(props.activate && element)) return;

		dimensionSetter({ element: element, setDimension});

		const elements = document.getElementsByClassName(`cm-step${props.step}`)
		Object.keys(elements).forEach(key => {
			elements[key].style.zIndex = 10001
			elements[key].style.position = 'relative'
			elements[key].style.pointerEvents = 'none'
			if(props.useBackground.apply) {
				elements[key].style.backgroundColor = props.useBackground.color
			}
		})
		if(props.customization) {
			props.customization()
		}

		//eslint-disable-next-line
	}, [props.activate, element, props.step]);

	if (!dimension || !props.activate || !element) {
		if ((dimension && props.activate) && !element) {
			console.error('Ref is not passed properly @Coach-Mark');
		}
		return null;
	}

	
	const { 
		CMarkTop, CMarkBottom, CMarkLeft, CMarkRight, 
		FSpotTop, FSpotBottom, FSpotLeft, FSpotRight } = coachMarkPlacementCalculator({ dimension, position: props.componentPosition, customPosition: props.customPosition  })
		
	const CMStyle = {
		top: CMarkTop || '',
		bottom: CMarkBottom || '',
		left: CMarkLeft || '',
		right: CMarkRight || '',
	}
	const FSStyle = {
		top: FSpotTop || '',
		bottom: FSpotBottom || '',
		left: FSpotLeft || '',
		right: FSpotRight || '',
	}
	
	const overlay = <div className="coachmark-overlay"></div>;
	const focusSpot = <span className="focus-spot" style={FSStyle}></span>;
	const stepContainer =	<div className="hcm-tooltip-base" style={CMStyle}>{props.component}</div>;

	return (
		<>
			{createPortal(overlay, document.body)}
			{createPortal(stepContainer, document.body)}
			{createPortal(focusSpot, document.body)}
		</>
	);
};

export default CoachMark;

