import { useEffect, useState } from "react";
import { DateRange } from "components";
import { IChartWidgetProps, IWidgetResponse } from "./IChartWidget";
import moment from "moment";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { IDataTable, IBank } from "./IBankConciliation";
import { Button, Col, Row, Space, Table, Tooltip } from "antd";
import i18n from "util/base/i18n";
import Card from "../card/Card";
import "./BankConciliationWidget.sass";
import { Icon } from "@iconify/react";
import { LoadingOutlined } from "@ant-design/icons";
import { FormattedNumber } from "react-intl";

import { DefaultRecordType } from "rc-table/lib/interface";

export default function BankConciliationWidget(props: IChartWidgetProps) {

	const [dataTable, setDataTable]: [IDataTable, Function] = useState({
		cashUnreconciled: 0,
		bankUnreconciled: 0,
		banks: []
	});

	const [startDate, setStartDate]: [moment.Moment, Function] = useState(moment(props.startDate));
	const [endDate, setEndDate]: [moment.Moment, Function] = useState(moment(props.endDate));

	const [shouldUpdateFilter, setShouldUpdateFilter]: [boolean, Function] = useState(false);
	const [loading, setLoading]: [boolean, Function] = useState(false);
	const [useCache, setUseCache]: [boolean, Function] = useState(true);

	const [cached, setCached]: [boolean, Function] = useState(false);
	const [cacheDate, setCacheDate]: [Date, Function] = useState(null);

	const renderFormattedValue = (value: number): React.ReactElement => {
		return <FormattedNumber notation="standard" value={value} />;
	};

	const bankGridColumns: DefaultRecordType[] = [{
		title: i18n.t<string>("bank"),
		dataIndex: "name",
		key: "bankName"
	}, {
		title: i18n.t<string>("current_account"),
		dataIndex: ["data", "accountCount"],
		key: "currentAccount",
		render: renderFormattedValue
	}, {
		title: i18n.t<string>("bank"),
		dataIndex: ["data", "bankUnreconciled"],
		key: "bankUnreconciled",
		render: renderFormattedValue
	},
	{
		title: i18n.t<string>("cash"),
		dataIndex: ["data", "cashUnreconciled"],
		key: "cashUnreconciled",
		render: renderFormattedValue
	}];

	const onChangeDate = ([newStartDate, newEndDate]: moment.Moment[]) => {
		if (startDate.isSame(newStartDate) && endDate.isSame(newEndDate)) {
			loadData();
		} else {
			setShouldUpdateFilter(true);
			setStartDate(newStartDate);
			setEndDate(newEndDate);
		}
	}

	const onLoadData: Function = (response: IWidgetResponse) => {
		response.data.banks.sort(({ name: bankA }, { name: bankB }) => bankA.localeCompare(bankB));

		setCached(response.cached);
		setCacheDate(response.date);
		setDataTable(response.data);

		setLoading(false);
		setUseCache(false);
	}

	const loadData: Function = () => {
		setDataTable({
			cashUnreconciled: 0,
			bankUnreconciled: 0,
			banks: []
		});
		setLoading(true);

		const filterValuesQuery = props.globalFilter.values.map(value => `filterValue=${value}`).join("&");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/cash/bank/conciliation?filterBy=${props.globalFilter.filterBy}&${filterValuesQuery}&client={client}&user={user}&startDate=${startDate.startOf("month").format("YYYY-MM-DD")}&endDate=${endDate.endOf("month").format("YYYY-MM-DD")}&useCache=${useCache}`
			,
		}, onLoadData.bind(this))
	}

	useEffect(() => {
		if (shouldUpdateFilter) {
			ServiceCaller.doRequest({
				type: RequestType.PUT,
				url: "/dashboard-analytics/widget/filter",
				params: {
					id: props.filterId,
					startDate: startDate.startOf("month").format("YYYY-MM-DD"),
					endDate: endDate.endOf("month").format("YYYY-MM-DD")
				}
			})
		}

		loadData();
	}, [startDate, endDate, props.globalFilter]);

	return (
		<div className="bank-conciliation-widget">
			<div className="widget-header-actions-container">
				{
					cached && <>
						<Tooltip title={ i18n.t<string>("dashboard.analytics.text_002", {date: new Intl.DateTimeFormat(i18n.language.replace("_", "-"), { dateStyle: "short", timeStyle: "medium" }).format(new Date(cacheDate))}) }>
							<Button onClick={loadData.bind(this)} type="text" size="large" icon={<Icon icon={"mdi:reload-alert"} />} disabled={loading} data-html2canvas-ignore="true" />
						</Tooltip>
					</>
				}

				<DateRange value={[moment(startDate), moment(endDate)]} onChange={onChangeDate.bind(this)} format="MMM YYYY" formatEdit="MM/YYYY" picker="month" disabled={loading} />
			</div>

			<div className="bank-conciliation-content">
				<Row key={"bank-conciliation-header"} className="bank-conciliation-header" gutter={[12, 12]}>
					<Col span={1} className="gutter-row col-banks">
						<Card title={i18n.t<string>("total_banks")} class={loading ? "loading" : ""} icon={loading ? "mdi:loading" : "fluent:building-bank-48-filled"} value={dataTable.bankUnreconciled} isMonetary={false} />
					</Col>
					<Col span={1} className="gutter-row col-banks">
						<Card title={i18n.t<string>("cash_total")} class={loading ? "loading" : ""} icon={loading ? "mdi:loading" : "dashicons:money-alt"} value={dataTable.cashUnreconciled} isMonetary={false} />
					</Col>
				</Row>

				<Space direction="vertical" size={10}>
					<Table columns={bankGridColumns} dataSource={dataTable.banks} pagination={false} rowKey="id" scroll={{
						y: 275
					}} locale={{emptyText: loading ? <LoadingOutlined style={{fontSize: "40px", padding: "93px 0"}} /> : i18n.t<string>("no_data")}} />
				</Space>
			</div>
		</div>
	)
}