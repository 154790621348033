import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import { useContext, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import {
	IAttribute,
	IGrouper,
} from "../../IAttributeParameterization";
import { AttributeParameterizationContext } from "../../context/AttributeParameterizationContext";
import Attribute from "./Attribute";
import { ErrorRequest } from "util/types/types";
import { Notification } from "components/notification/Notification";

const { Panel } = Collapse;

interface IGroupersProps {
	handleCollapsePanel: (id: any) => void;
	filterText: string;
}

export function Groupers({ filterText, handleCollapsePanel }: IGroupersProps) {
	const [attributes, setAttributes] = useState<IAttribute[]>([]);
	const [groupers, setGroupers] = useState<IGrouper[]>([]);

	const {
		selectedGrouper,
		openEditAttributeModal,
		isNewAttributeModalOpen,
		openDeleteAttributeModal,
		setLabelDeleteAttributeModal
	} = useContext(AttributeParameterizationContext);

	function loadAttributes() {
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/revenue/grouper/attribute`,
			},
			(response: IAttribute[]) => {
				response.map(attribute => {
					if (attribute.grouperName === 'MARKETING_COST_VARIABLE' || attribute.grouperName === 'MARKETING_COST_FIXED') {
						attribute.isMarketingCost = true;
					}

					return attribute;
				})

				setAttributes(response);
			}
		);
	}

	function openDeletedAttributeNotification(placement: NotificationPlacement) {
		notification.success({
			message: i18n.t<string>("revenue.attribute_deleted_succesfully"),
			placement,
			duration: 3,
		});
	}

	function handleErrorDeleteAttribute({ message }: ErrorRequest) {
		setLabelDeleteAttributeModal(i18n.t(errorDeleteAttributeType[message]));
		openDeleteAttributeModal();
	}

	const errorDeleteAttributeType = {
		"formula_vinculed": "revenue.atribute_cannot_be_deleted_link_report",
		"attribute_vinculed": "revenue.atribute_cannot_be_deleted_link_attribute",
		"default": "something_went_wrong"
	}

	function handleDeleteAttribute(attribute: IAttribute) {
		ServiceCaller.doRequest(
			{
				type: RequestType.DELETE,
				url: `/revenue/grouper/attribute?id=${attribute.id}`,
			},
			() => {
				openDeletedAttributeNotification("topRight");
				loadAttributes();
			}, handleErrorDeleteAttribute
		);
	}

	useEffect(() => {
		loadAttributes();
	}, []);

	useEffect(() => {
		loadAttributes();
	}, [isNewAttributeModalOpen]);

	useEffect(() => {
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/revenue/grouper?isSidebar=true`,
			},
			(response: IGrouper[]) => {
				setGroupers(response);
			}
		);
	}, []);

	useEffect(() => {
		return () => {
			setGroupers([]);
			setAttributes([]);
		};
	}, []);

	return (
		<Collapse
			expandIconPosition="right"
			expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
			ghost
			onChange={(collapseKey) => handleCollapsePanel(collapseKey)}
			accordion
		>
			{groupers.map((grouper) => {
				const grouperName = i18n.t<string>(grouper.name.toLowerCase());

				return (
					<Panel
						key={grouper.id}
						header={grouperName}
						className={selectedGrouper?.id === grouper.id ? "attribute_parameterization-new-attribute-panel" : ""}
					>
						{attributes
							.filter(attribute => attribute.name !== 'occurrence')
							.filter(attribute => attribute.name !== 'gross_revenue')
							.map((attribute) => {
								if (filterText !== "" && !attribute.name.toLowerCase().includes(filterText.toLowerCase())) {
									return null;
								}

								if (attribute.isMarketingCost && (grouper.name + "") === "MARKETING_COST") {
									return (
										<Attribute
											key={attribute.id}
											data={{
												attribute,
												handleDeleteAttribute,
												openEditAttributeModal,
											}}
										/>
									)
								}
								if (attribute.grouperId === grouper.id) {
									return (
										<Attribute
											key={attribute.id}
											data={{
												attribute,
												handleDeleteAttribute,
												openEditAttributeModal,
											}}
										/>
									)
								} else {
									return "";
								}
							})}
					</Panel>
				);
			}
			)}
		</Collapse>
	);
}
