import { Modal, Input } from "antd";
import { useEffect } from "react";
import i18n from "util/base/i18n";

const { TextArea } = Input;

export function CommentsModal({showModal, comment, setShowModal, handleSaveComment, setComment, commentTextArea }) {

	useEffect(() => {
		if(commentTextArea.current !== null) {
			commentTextArea.current!.focus({
				cursor: 'end',
			})
		}
	}, [comment, commentTextArea])

	return (
		<Modal
			title={i18n.t("comment")}
			visible={showModal}
			onCancel={() => setShowModal(false)}
			onOk={handleSaveComment}
			centered
			className="gs-modal"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("save")}
		>
			<div id="comments-modal-content">
				<TextArea 
					ref={commentTextArea}
					onPressEnter={handleSaveComment}
					rows={4}
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					maxLength={400}
					placeholder={i18n.t("insert_a_comment_here")}
				/>
			</div>
		</Modal>
	)
}