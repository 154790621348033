import { Table } from "antd";
import { FlexFilter, FunctionalityPermissions } from "util/types/types";

export interface PlanBudgetResponse {
    budgetOpeningId: number;
    plannedMonths: PlannedMonth[];
}
export interface BudgetGridData {
    budgetOpening: {
        id: number;
        accountId: number;
        currencyId: number;
        organizationId: number;
        businessUnitId: number;
        costCenterId: number;
        flexFieldFilter: string;
        scenarioId: number;
        isAccountLinkedToHR?: boolean;
    };
    budgetOpeningIndexerLink?: {
        budgetOpeningId: number;
        indexerId: number;
        year: number;
    }[];
    key: string;
    accounting: string;
    rowType: RowType;
    detailId?: number;
    standardAccount: number | null;
    spendingPackage: string | null;
    isOpeningInOtherCurrency?: boolean;
    canEdit: boolean;
    currencyIsoCode?: string;
    detailIdList?: number[];
    currencyId: number;
    plannedMonths: {
        [month: string]: PlannedMonth;
    };
    comment?: {
        id: number | null;
        comment: string;
        budgetOpeningId: number;
    } | null;
    plannedTotal?: number;
    projectedTotal?: number;
    historicTotal?: number;
    apportionmentTotal?: number;
    realizedTotal?: number;
    quantityTotal?: number;
    unitValueTotal?: number;
    detailList?: BudgetGridData[];
    children?: BudgetGridData[];
    hasDetail?: boolean;
    spendingPackageId?: number | null;
}

export enum RowType {
    PLANNED = "plannedValue",
    DETAIL = "detail",
    QUANTITY = "quantity",
    UNITVALUE = "unitValue",
}

export enum ViewModeSelected {
    PROJECTED = "projectedValue",
    REALIZED = "realizedValue",
    HISTORIC = "historicValue",
    APPORTIONMENT = "apportionmentValue",
}

export type BudgetOpening = {
    spendingPackage: number | null;
    standardAccount: number | null;
    budgetOpening: {
        id: number;
        accountId: number;
        currencyId: number;
        organizationId: number;
        businessUnitId: number;
        costCenterId: number;
        flexFieldFilter: string;
        scenarioId: number;
        isAccountLinkedToHR?: boolean;
    };
    budgetOpeningIndexerLink: {
        id: number;
        budgetOpeningId: number;
        indexerId: number;
        year: number;
    }[];
    comment: {
        id: number | null;
        comment: string;
        budgetOpeningId: number;
    } | null;
    plannedMonths: PlannedMonth[];
};

export type BudgetOpeningCachedData = {
    accounts: number[];
    spendingPackage: number[];
    businessUnitId: number;
    clientId: number;
    costCenterId: number;
    currencyId: number;
    flexFieldFilter: string;
    flexFieldInfoData: FlexFilter[];
    id: number;
    localeId: number;
    organizationId: number;
    period: moment.Moment[];
    scenarioId: number;
    userId: number;
};

export interface PlannedMonth {
    id: number | null;
    month: string;
    plannedValue: number;
    currencyId: number;
    detailId: number | null;
    detailName: string | null;
    realizedValue: number | null;
    quantity: number | null;
    unitValue: number | null;
    detailValueId: number | null;
    rate: number | null;
    projectedValue: number | null;
    historicValue: number | null;
    apportionmentValue: number | null;
}

export interface SummaryProps {
    totals: totalSummary[];
}

export type totalSummary = {
    value: number;
    month: string;
    order: number;
};

export type EditableTableProps = Parameters<typeof Table>[0];

export interface EditableRowProps {
    index: number;
}

export interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string | string[];
    record: BudgetGridData;
    onCell: (a: any) => any;
    handleSaveCellValue: (record: BudgetGridData, isTotalCell: boolean, key: string, value: number) => void;
}

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export type Column = ColumnTypes[any] & {
    editable?: boolean;
    dataIndex?: string | string[];
    children?: Column[];
};

export interface ImportTemplateModalProps {
    isVisible: boolean;
    setIsVisible: (b: boolean) => void;
    handleAddTemplate: () => void;
}

export type DetailOptions = DetailData;

export type DetailData = {
    id: number;
    description?: string;
    externalCode: string;
    currencyId: number;
    isByQuantity?: boolean;
};

export type CurrenciesRate = {
    month: string;
    rate: number;
};
export type BudgetPeriodDates = {
    projectionHorizonIni: number;
    projectionHorizonEnd: number;
    localDate: number;
    period: {
        year: number;
        expensesResourcesBudgetEndDate: number;
        expensesResourcesBudgetStartDate: number;
        expensesResourcesOpeningEndDate: number;
        expensesResourcesFrozenPeriod: number;
        expensesResourcesRealizedPeriod: number;
        expensesResourcesOpeningStartDate: number;
        revenueRealizedPeriod: number;
        revenueFrozenPeriod: number;
        revenueStartDate: number;
        revenueEndDate: number;
        revenueOpeningStartDate: number;
        revenueOpeningEndDate: number;
        revenueBudgetStartDate: number;
        revenueBudgetEndDate: number;
        planningRealizedPeriod: number;
        planningOpeningStartDate: number;
        planningOpeningEndDate: number;
        planningBudgetStartDate: number;
        planningBudgetEndDate: number;
        revision: {
            budgetEndDate: number;
            budgetStartDate: number;
            reviewPeriodEndDate: number;
            reviewPeriodStartDate: number;
        }[];
    }[];
};

export interface IGridOperations {
    handleDelete(): void;
    isOpenAccountDetailPopover: boolean;
    setIsOpenAccountDetailPopover(b: boolean): void;
    isOpenCurPopover: boolean;
    setIsOpenCurPopover(b: boolean): void;
    isOpenDeletePopover: boolean;
    setIsOpenDeletePopover(b: boolean): void;
    isOpenViewModePopover: boolean;
    setIsOpenViewModePopover(b: boolean): void;
    handleAddCurrencyAccountRow(n: number): void;
    isDeleteButtonDisabled: boolean;
    isAddDetailButtonDisabled: boolean;
    currencyId: number;
    setCurrencyId(n: number): void;
    isCurrencyBtnDisabled: boolean;
    userDefaultCurrency: number;
    saveStatus: {
        savedValue: boolean;
        isSaving: boolean;
    };
    setOpenDistributionModal(b: boolean): void;
    isDistributionButtonDisabled: boolean;
    setOpenIndexerModal(b: boolean): void;
    isIndexerButtonDisabled: boolean;
    onViewModeCheckboxChange(a: any): void;
    viewModeSelected: ViewModeSelected[];
    setViewModeSelected(a: ViewModeSelected[]): void;
    viewModeChecked: any[];
    setViewModeChecked(a: any[]): void;
    budgetPeriodDates: BudgetPeriodDates;
    handleExportGrid(): void;
    onOpenDetailModal(data: BudgetGridData | null): void;
    searchValue: string;
    setSearchValue(a: string): void;
}
export interface IGrid {
    handleSavePlannedValue(dataToSava, budgetGridData: BudgetGridData, previousValue: BudgetGridData): void;
    isFetching: boolean;
    setIsFetching(b: boolean): void;
    saveStatus: {
        isSaving: boolean;
        savedValue: boolean;
    };
    handleCreateBudgetOpeningAccount: () => void;
    handleCreateBudgetOpening: () => void;
    functionalityPermissions: FunctionalityPermissions;
}

export const plannedMonthObject = {
    id: null,
    month: null,
    plannedValue: null,
    currencyId: null,
    detailId: null,
    detailName: null,
    realizedValue: null,
    quantity: null,
    unitValue: null,
    detailValueId: null,
    rate: null,
    projectedValue: null,
    historicValue: null,
    apportionmentValue: null,
    plannedTotal: 0,
    realizedTotal: 0,
    historicTotal: 0,
    apportionmentTotal: 0,
    projectedTotal: 0,
};

export interface IEmptyListProps {
    onClickNewBudgetOpening: () => void;
    isSpendingPackage: boolean;
}

export interface IFilters {
    functionalityPermissions: FunctionalityPermissions;
}