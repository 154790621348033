import { Icon } from "@iconify/react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { FormInstance } from "antd/es/form";
import { useState } from "react";
import i18n from "util/base/i18n";
import { Measure, MeasureList } from "../IBudgetProjection";
import { validateFormField } from "util/functions/validateFormField";

interface INewMeasureModal {
    isNewRate: boolean;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    handleSubmit: (a: any) => void;
    handleSave: (data: Measure[]) => void;
    measureModalList: Measure[]
    setMeasureList: (a: any[]) => void;
    isNew: boolean;
    measureForm: FormInstance
    measureList: MeasureList[];
    selectedMenuItem: string
    isNewMeasure: boolean;
}

export function NewMeasureModal({
    isOpen,
    setIsOpen,
    handleSubmit,
    handleSave,
    measureModalList,
    setMeasureList,
    isNew,
    measureForm,
    measureList,
    selectedMenuItem,
    isNewMeasure
}: INewMeasureModal) {
    const [isDisabled, setIsDisabled] = useState(false);

    const modalTitle = isNew
        ? i18n.t<string>("unit_of_measurement.new_unit")
        : i18n.t<string>("unit_of_measurement.edit_unit");

    function handleDeleteFromList(description: string) {
        const index = measureModalList.findIndex(m => m.description === description)
        const updatedMeasureList = [...measureModalList]
        updatedMeasureList.splice(index, 1)
        setMeasureList(updatedMeasureList)
    }

    function handleEditCostCategory(measure: any) {
        setIsDisabled(true)
        measureForm.setFieldsValue({
            externalCode: measure.externalCode,
            description: measure.description,
            symbol: measure.symbol
        })
        const index = measureModalList.indexOf(measure)
        const updatedMeasureList = [...measureModalList]
        updatedMeasureList.splice(index, 1)
        setMeasureList(updatedMeasureList)
    }

    return (
        <Modal
            width={isNew ? 800 : 500}
            title={modalTitle}
            visible={isOpen}
            onCancel={() => {
                setIsOpen(false);
                setMeasureList([]);
                measureForm.resetFields();
            }}
            onOk={() => {
                if (isNew) {
                    handleSave(measureModalList);
                }
            }}
            okText={i18n.t<string>("save")}
            cancelText={i18n.t<string>("cancel")}
            okButtonProps={{
                htmlType: isNew ? 'button' : "submit",
                form: "new-measure-form"
            }}
        >
            <Form
                name="new-measure-form"
                form={measureForm}
                layout="vertical"
                requiredMark={false}
                onFinish={(data) => {
                    setIsDisabled(false)
                    handleSubmit([data])
                }}
            >
                <Row style={{ width: '100%' }} align="middle" gutter={20}>
                    <Col span={isNew ? 8 : 16}>
                        <Form.Item
                            name="externalCode"
                            label={i18n.t<string>("code")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                                () => ({
                                    validator(_, value: string) {
                                        const filteredList = isNewMeasure ? [...measureList] : measureList.filter(item => item.key.toString() !== selectedMenuItem);
                                        return validateFormField(value, 'externalCode', filteredList, measureModalList, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedMenuItem);
                                    },
                                })
                            ]}
                        >
                            <Input id="input-template-detail" />
                        </Form.Item>
                    </Col>
                    <Col span={isNew ? 10 : 16}>
                        <Form.Item
                            label={i18n.t<string>("description")}
                            name="description"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={isNew ? 6 : 8}>
                        <Form.Item
                            label={i18n.t<string>("symbol")}
                            name="symbol"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ marginBottom: -10, marginLeft: "auto" }}>
                        {isNew &&
                            <Button
                                style={{ marginTop: -10, marginLeft: "15px" }}
                                type="default"
                                htmlType="submit"
                                className="gs-secondary-button"
                                icon={<Icon icon="akar-icons:circle-plus-fill" />}
                            >
                                {i18n.t<string>("addToList")}
                            </Button>}
                    </Col>
                </Row>
            </Form>
            {isNew ?
                <div className="list-of-added-items">
                    {measureModalList.length > 0
                        ?
                        <>
                            <div className="cost-category-list-header">
                                <Row align="middle" gutter={2}>
                                    <Col span={8} style={{ paddingLeft: 12 }}>{i18n.t("external_code")}</Col>
                                    <Col span={8} style={{ paddingLeft: 12 }}>{i18n.t("description")}</Col>
                                    <Col span={6} style={{ paddingLeft: 0 }}>{i18n.t("symbol")}</Col>
                                    <Col span={1} />
                                    <Col span={1} />
                                </Row>
                            </div>
                            <div className="cost-category-list-content">
                                {measureModalList.map((measure) => {
                                    return (
                                        <Row key={measure.description} align="middle" gutter={2}>
                                            <Col span={8} style={{ justifyContent: 'start' }}>{measure.externalCode}</Col>
                                            <Col span={8} style={{ justifyContent: 'start' }}>{measure.description}</Col>
                                            <Col span={6} style={{ justifyContent: 'start' }}><span className="gs-tag blue">{measure.symbol}</span></Col>
                                            <Col span={1} >
                                                <Button
                                                    disabled={isDisabled}
                                                    onClick={() => handleEditCostCategory(measure)}
                                                    icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                                                />
                                            </Col>
                                            <Col span={1} >
                                                <Icon
                                                    className="delete-icon"
                                                    onClick={() => handleDeleteFromList(measure.description)}
                                                    icon="fa6-solid:trash"
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </div>
                        </>
                        :
                        <div className="cost-category-list-empty">
                            <Icon icon="icomoon-free:file-empty" />
                            <p>{i18n.t("no_items_added_to_list")}</p>
                        </div>
                    }
                </div>
                :
                null
            }

        </Modal>
    )
}