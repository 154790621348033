import { Col, Skeleton } from "antd";
import { IHeaderInfoTagProps } from "../IRecurringContract";

export default function HeaderInfoTag({
	title = "",
	value = ""
}: IHeaderInfoTagProps) {

	return (
		<Col>
			<div className="header-info-tag">
				{title === "" ?
					<Skeleton />
					:
					<p >
						{title}
					</p>
				}
				{value === "" ?
					<Skeleton />
					:
					<p className="gs-tag blue">
						{value}
					</p>
				}
			</div>
		</Col>
	)
}