/* eslint-disable no-restricted-globals */
import { Button, Result } from "antd";
import './Error.sass'
import i18n from "util/base/i18n";

export function Error500() {
    return (
        <div
            className="error-500-container"
        >
            <Result
                status="error"
                title="500"
                subTitle={i18n.t("notifications.oops_something_went_wrong")}
                extra={
                    <Button 
                        className="gs-main-button"
                        onClick={() => window.location.reload()}
                        style={{margin: 'auto'}}
                    >
                        Recarregar
                    </Button>}
            />
        </div>
    )
}