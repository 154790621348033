export const onCloseMultiStepModal = (
	{ currentTarget }: React.MouseEvent<HTMLElement>,
	isOnFirstStep: boolean,
	onCloseModal: () => void,
	onStepBack: () => void
) => {
	if (isOnFirstStep || currentTarget.getAttribute("aria-label") === "Close") {
		onCloseModal();
	} else {
		onStepBack();
	}
}