import moment from "moment";
import { useEffect, useState } from "react";
import i18n, { languages } from "util/base/i18n";

import { ColumnsType } from "antd/lib/table";
import { Col, DatePicker, Form, InputNumber, Modal, Row, Table, Select } from "antd";

import CurrencyInput from "module/leaseProjection/components/currencyInput/CurrencyInput";

import './ContractSimulationEditModalStyles.sass'
import { ContractSimulationModalProps, ValueForm } from "../../IContractSimulation";
import { selectProps } from "util/props/props";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";

export function ContractSimulationEditModal({
  isModalVisible,
  handleCancel,
  handleSubmit,
  tableData,
  form,
  isLoading,
  hasCopyOriginId,
}: ContractSimulationModalProps) {
  const [indexers, setIndexers] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [installmentsValue, setInstallmentsValue] = useState('');

  useEffect(() => {
    if (isModalVisible) {
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: "/projectionlease/scenario/indexer"
      }, onLoadIndexers.bind(this));

      const fieldValue = form.getFieldValue('installments');
      if (fieldValue !== installmentsValue) {
        setInstallmentsValue(fieldValue);
      }
    }
  }, [isModalVisible]);

  function onLoadIndexers(indexers) {
    setIndexers(indexers)
  }

  const tableColumns: ColumnsType = [
    {
      title: i18n.t<string>('start_date'),
      dataIndex: 'baseDate',
      sorter: true,
      onCell: ((record: ValueForm) => ({
        className: tableData.indexOf(record) !== 0 ? 'value-disabled-opacity' : null
      })),
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: i18n.t<string>('end_date'),
      dataIndex: 'endDate',
      sorter: true,
      onCell: ((record: ValueForm) => ({
        className: tableData.indexOf(record) !== 0 ? 'value-disabled-opacity' : null
      })),
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: i18n.t<string>('installments'),
      dataIndex: 'installments',
      sorter: true,
      onCell: ((record: ValueForm) => ({
        className: tableData.indexOf(record) !== 0 ? 'value-disabled-opacity' : null
      })),
      render: (value) => value.toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    {
      title: i18n.t<string>('discount_rate'),
      dataIndex: 'rate',
      sorter: true,
      onCell: ((record: ValueForm) => ({
        className: tableData.indexOf(record) !== 0 ? 'value-disabled-opacity' : null
      })),
      render: (value) => value.toLocaleString('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 8,
        maximumFractionDigits: 8
      })
    },
    {
      title: i18n.t<string>('indexer'),
      dataIndex: 'indexerName',
      onCell: ((record: ValueForm) => ({
        className: tableData.indexOf(record) !== 0 ? 'value-disabled-opacity' : null
      }))
    }
  ];

  return (
    tableData.length ?
      <Modal
        width={1130}
        title={i18n.t<string>('edit')}
        visible={isModalVisible}
        onCancel={handleCancel}
        className="gs-modal edit-contract-modal"
        cancelText={i18n.t<string>("cancel")}
        okText={i18n.t<string>("save")}
        maskClosable={false}
        confirmLoading={isLoading}
        okButtonProps={{
          htmlType: "submit",
          form: "edit-contract-simulation-form",
          disabled: isSubmitDisabled
        }}
      >
        <Table
          className="gs-table"
          dataSource={tableData}
          columns={tableColumns}
          pagination={false}
          bordered
          title={() => i18n.t<string>('additive')}
        />

        <div className="edit-info">
          <div className="edit-info-group">
            <div className="title">{i18n.t<string>('start_date')}</div>
            <div className="value">{moment(tableData[0].baseDate).format('DD/MM/YYYY') ? moment(tableData[0].baseDate).format('DD/MM/YYYY') : "N/A"}</div>
          </div>
          <div className="edit-info-group">
            <div className="title">{i18n.t<string>('management_group')}</div>
            <div className="value">{tableData[0].managementGroup ? tableData[0].managementGroup : "N/A"}</div>
          </div>
          <div className="edit-info-group">
            <div className="title">{i18n.t<string>('contract_group')}</div>
            <div className="value">{tableData[0].contractGroup ? tableData[0].contractGroup : "N/A"}</div>
          </div>
          <div className="edit-info-group">
            <div className="title">{i18n.t<string>('project')}</div>
            <div className="value">{tableData[0].project ? tableData[0].project : "N/A"}</div>
          </div>
          <div className="edit-info-group">
            <div className="title">{i18n.t<string>('division')}</div>
            <div className="value">{tableData[0].division ? tableData[0].division : "N/A"}</div>
          </div>
        </div>

        <Form
          form={form}
          name="edit-contract-simulation-form"
          className={"form-edit-contract"}
          layout="vertical"
          onFinish={(data) => { handleSubmit(data) }}
          onFieldsChange={() => setIsSubmitDisabled(
            form.getFieldsError().some((field) => field.errors.length > 0)
          )}
        >
          <Row justify={"start"} gutter={16}>
            <Col span={8}>
              <Form.Item
                name="endDate"
                label={i18n.t<string>('end_date')}
                rules={[
                  { required: true, message: i18n.t<string>("required_field") },
                  {
                    validator: (_, value) => {
                      const baseDate = new Date(tableData[0].baseDate).setHours(0, 0, 0, 0);
                      const endDate = new Date(value).setHours(0, 0, 0, 0);
  
                      if (baseDate && endDate && baseDate >= endDate)
                        return Promise.reject(i18n.t<string>('end_date_greater_than_start_date'))
  
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <DatePicker disabled={hasCopyOriginId} locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="amortizationBaseDate"
                label={i18n.t<string>('amortization_start_date')}
                rules={[
                  { required: true, message: i18n.t<string>("required_field") },
                  {
                    validator: (_, value) => {
                      const baseDate = new Date(tableData[0].baseDate).setHours(0, 0, 0, 0);
                      const amortizationBaseDate = new Date(value).setHours(0, 0, 0, 0);
                      const endDate = new Date(form.getFieldValue("endDate")).setHours(0, 0, 0, 0);
  
                      if (baseDate && endDate && amortizationBaseDate && ((amortizationBaseDate <= baseDate) || (amortizationBaseDate > endDate)))
                        return Promise.reject(i18n.t<string>('amortization_base_date_out_of_range'))
  
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <DatePicker disabled={hasCopyOriginId} locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='installments'
                label={i18n.t<string>('installments')}
                rules={[
                  { required: true, message: i18n.t<string>("required_field") },
                  {
                    validator: (_, value) => {
                      if (form.getFieldValue("installments") > 0)
                        return Promise.resolve()
                      else
                        return Promise.reject(i18n.t<string>('installments_greater_zero'))
                    }
                  }
                ]}
              >
                <CurrencyInput
                  form={form}
                  name='installments'
                  className="ant-input-number"
                  value={installmentsValue}
                  onChange={(newValue) => setInstallmentsValue(newValue)}
                  disabled={hasCopyOriginId}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={"start"} gutter={16}>
            <Col span={8}>
              <Form.Item
                name="frequency"
                label={i18n.t<string>('frequency')}
                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
              >
                <InputNumber
                  step={1}
                  formatter={value => String(value).replace(/\./g, '')}
                  parser={value => String(value).replace(/\./g, '')}
                  disabled={hasCopyOriginId}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="rate"
                label={i18n.t<string>('discount_rate')}
                rules={[
                  { required: true, message: i18n.t<string>("required_field") },
                  {
                    validator: (_, value) => {
                      if (!(form.getFieldValue("rate") < 1000))
                        return Promise.reject(i18n.t<string>('rates_less_one_thousand'))
                      else if (form.getFieldValue("rate") <= 0)
                        return Promise.reject(i18n.t<string>('rates_greater_than_zero'))
                      else
                        return Promise.resolve()
                    }
                  }
                ]}
              >
                <InputNumber
                  controls={false}
                  decimalSeparator={","}
                  width={"100%"}
                  precision={8}
                  disabled={hasCopyOriginId}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                  name="indexer"
                  label={i18n.t<string>("indexer")}
                  dependencies={["firstCapitalization"]}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value != null && form.getFieldValue("firstCapitalization") != null)
                          return Promise.resolve()
                        else if (value == null && form.getFieldValue("firstCapitalization") != null)
                          return Promise.reject(i18n.t<string>('indexer_not_selected'))
                        else 
                          return Promise.resolve()
                      }
                    }
                  ]}
                >
                  <Select
                    maxTagCount="responsive"
                    allowClear
                    options={indexers}
                    placeholder={i18n.t<string>("select")}
                    {...selectProps}
                    disabled={hasCopyOriginId}
                    className="select-indexer"
                  />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="firstCapitalization"
                label={i18n.t<string>('first_capitalization_restatement')}
                dependencies={["indexer"]}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value != null && (value >= form.getFieldValue("endDate") || value <= tableData[0].baseDate))
                        return Promise.reject(i18n.t<string>('first_capitalization_restatement_errorperiod'))
                      else if (value == null && form.getFieldValue("indexer") != null)
                        return Promise.reject(i18n.t<string>('first_capitalization_restatement_not_filled'))
                      else
                        return Promise.resolve()
                    }
                  }
                ]}
              >
                <DatePicker disabled={hasCopyOriginId} className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="operationalCostValue"
                label={i18n.t<string>('operation_cost_value')}
              >
                <CurrencyInput
                  form={form}
                  name='operationalCostValue'
                  className="ant-input-number"
                  value={form.getFieldValue('operationalCostValue')}
                  disabled={hasCopyOriginId}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="doublePercentual"
                label={i18n.t<string>('additional_december')}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value != null && value >= 1000)
                        return Promise.reject(i18n.t<string>('additional_december_less_one_thousand'))
                      else if (value != null && value <= 0)
                        return Promise.reject(i18n.t<string>('additional_december_greater_than_zero'))
                      else
                        return Promise.resolve()
                    }
                  }
                ]}
              >
                <InputNumber
                  controls={false}
                  decimalSeparator={","}
                  width={"100%"}
                  precision={2}
                  disabled={hasCopyOriginId}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal >
      : null
  );
}