import { Tabs } from "antd";
import { DataNode } from "antd/lib/tree";
import { useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import SiderContent from "./SiderContent";
import { Option } from "../../../../../dashboard/analytics/filter/IOptionsFilter"
import i18n from "util/base/i18n";

interface Props {
	selectedTab: 'group' | 'user';
	setSelectedTab: (selected: 'group' | 'user') => void;
	selectedSideKey: number;
	setSelectedSideKey: (selected: number) => void;
}
export default function TabsSiderContent({
	setSelectedTab,
	selectedTab,
	setSelectedSideKey,
	selectedSideKey
}: Props) {
	const [usersData, setUsersData] = useState<DataNode[]>([]);
	const [groupData, setGroupData] = useState<DataNode[]>([]);

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/monolith/user/group/findAll?localeId={locale}&clientId={client}"
		}, onLoadGroupData.bind(this))

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/monolith/user/findAll?localeId={locale}&clientId={client}"
		}, onLoadUsersData.bind(this))
	}, [])

	function onLoadUsersData(data: Option[]) {
		setUsersData(convertData(data));
	}

	function onLoadGroupData(data: Option[]) {
		if(data.length > 0) {
			setSelectedSideKey(data[0].id)
		}
		setGroupData(convertData(data));

	}

	function convertData(data: Option[]): DataNode[] {
		return data.map(item => {
			return (
				{
					key: item.id,
					title: item.name
				}
			)
		})
	}

	function onChangeSiderContent(selected: 'group' | 'user') {
		setSelectedTab(selected);
		if (selected === 'group') {
			const key = groupData.length > 0 ? Number(groupData[0].key) : null
			setSelectedSideKey(key)
		} else {
			const key = usersData.length > 0 ? Number(usersData[0].key) : null
			setSelectedSideKey(key)
		}
	}

	return (
		<>
			<Tabs centered activeKey={selectedTab} onChange={onChangeSiderContent} className="tabs-sider gs-tab" defaultActiveKey="group" type="card" moreIcon="">
				<Tabs.TabPane tab={i18n.t("groups")} key="group">
					<SiderContent
						setSelectedSideKey={setSelectedSideKey}
						selectedSideKey={selectedSideKey}
						data={groupData}
					/>
				</Tabs.TabPane>
				<Tabs.TabPane tab={i18n.t("users")} key="user">
					<SiderContent
						setSelectedSideKey={setSelectedSideKey}
						selectedSideKey={selectedSideKey}
						data={usersData}
					/>
				</Tabs.TabPane>
			</Tabs>
		</>
	)
}