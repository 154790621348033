import { Col, Row, Select } from "antd";
import Search from "antd/lib/input/Search";
import { IHeaderRightContentProps } from "../IBudgetIntegrations";
import { DateRange } from "components";
import moment from "moment";
import i18n from "util/base/i18n";
import { useState } from "react";
import DataRequestModal from "./DataRequestModal";
import ProcessOrderModal from "./ProcessOrderModal";
import { useIntegrationsContext } from "../context/IntegrationsContext";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { Icon } from "@iconify/react";
import { handleExportGridData } from "util/functions/handleExportGridData";
export default function HeaderRightContent({
	endDate,
	setEndDate,
	setStartDate,
	startDate,
	integrationSelected,
}: IHeaderRightContentProps) {
	const [selectValue, setSelectValue] = useState("");
	const [modalVisible, setModalVisible] = useState(false);
	const [processOrderModalVisible, setProcessOrderModalVisible] = useState(false);
	const { searchValue, setSearchValue, refresh, tableData, columns } = useIntegrationsContext();

	const onChangeDate = (dates: moment.Moment[]) => {
		setStartDate(dates[0]);
		setEndDate(dates[1]);
	}
	function handleModalClose() {
		setSelectValue("");
		setModalVisible(false);
		setProcessOrderModalVisible(false);
	}

	function handleChange(value) {
		setSelectValue(value);
		if (value === "Consult") {
			setProcessOrderModalVisible(true);
		} else if (value === "request") {
			setModalVisible(true);
		}
	}

	return (
		<div className="header-right">
			<Row className="date-range-button" style={{ alignItems: 'center', gap: '5px', marginBottom: '4px' }}>
				<DateRange
					picker="date"
					format="DD/MM/YYYY"
					value={[startDate, endDate]}
					onChange={onChangeDate}
				/>
				<Icon icon="tabler:refresh"
					onClick={refresh} />
			</Row>
			<Row gutter={17}>
				<Col style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
					<Select
						style={{ width: '100%' }}
						value={selectValue || i18n.t("erp_data")}
						className='select-consult'
						onChange={handleChange}
						options={[
							{
								value: 'Consult',
								label: i18n.t("consult"),
							},
							{
								value: 'request',
								label: i18n.t("request"),
							}
						]}
					/>
					<DataRequestModal onClose={handleModalClose} open={modalVisible} />
					{processOrderModalVisible && <ProcessOrderModal
						onClose={handleModalClose} />}
					<Search value={searchValue} onChange={({ target: { value } }) => setSearchValue(value)} placeholder={i18n.t("search")} />
					<Col style={{ paddingLeft: '4.5px', paddingRight: '0px', marginTop: '6px' }}>
						{integrationSelected && (
							<ImportExportMenu
								exportGridData={() => {
									handleExportGridData(
										tableData.map((item) => ({ ...item, errors: (Object.values(item.errors)) })),
										columns.map((column) => ({ ...column, render: "" })),
										i18n.t("budget_integrations"))
								}}
							/>
						)}
					</Col>
				</Col>
			</Row>
		</div>
	)
}
