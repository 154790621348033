import { Icon } from "@iconify/react"
import { Button, Carousel, Checkbox, Col, Popover, Row } from "antd"
import { Filter } from "components/filter/Filter"
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton"
import { useEffect, useState } from "react"
import { usePremissesFilters } from "../context/usePremissesFilters"
import { IPremissesFilter } from "../IPremisses"
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { FlexFilter, Options } from "util/types/types"
import { useLocalStorage } from "hooks/useLocalStorage"
import { carouselDefaultProps } from "components/carousel/ICarousel"
import { useWindowSize } from "hooks/useWindowSize"
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu"
import { handleExportGridData } from "util/functions/handleExportGridData"
import { usePermissionContext } from "context/PermissionContext"
import i18n from "util/base/i18n"

export function PremissesFilter({ handleFetchPremisses, handleApplyPremisses, handleClonePremisses, isApplyingPremisses, isClonningPremisses, isApplyingPremissesBtnDisabled, hasNextYear }: IPremissesFilter) {
    const [filtersChecked, setFiltersChecked] = useLocalStorage('premisses-filters-default', []);
    const [filtersPerRow, setFiltersPerRow] = useState(5)
    const windowSize = useWindowSize();
    const { functionalityPermissions } = usePermissionContext()
    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 288) / 210)
        setFiltersPerRow(updatedFiltersPerRow)
    }, [windowSize])

    const {
        year, setYear,
        isFetchingFlexFields,
        businessUnit,
        setBusinessUnit,
        businessUnitOptions,
        costCategory,
        setCostCategory,
        costCategoryOptions,
        flexFieldsFilters,
        setFlexFieldsFilters,
        generateFlexFieldSelectedString
    } = usePremissesFilters()

    function handleApplyFlexFilter(flexFilter: FlexFilter, value: Options[]) {
        if (value.length === 0) return
        flexFilter.selectedOption = value
        const updatedFlexFilter = flexFieldsFilters.filter(flex => flex.fieldCode !== flexFilter.fieldCode)
        setFlexFieldsFilters([...updatedFlexFilter, flexFilter])
        generateFlexFieldSelectedString(flexFilter, value)
    }

    const onChangeFilterSettings = (checkedValues: CheckboxValueType[]) => {
        setFiltersChecked(checkedValues);
    };

    const blockedOptions = [
        { label: i18n.t("period"), value: 'period', disabled: true },
        { label: i18n.t("business_unit"), value: 'bunit', disabled: true },
        { label: i18n.t("cost_category"), value: 'costcategory', disabled: true },
    ];

    const filterSettingsPopover =
        <div className="premisses-filters-settings-popover-content">
            <Checkbox.Group options={blockedOptions} defaultValue={blockedOptions.map(b => b.value)} />
            <Checkbox.Group options={flexFieldsFilters} defaultValue={filtersChecked} onChange={onChangeFilterSettings} />
        </div>

    return (
        <div className="premisses-filters-container">
            {isFetchingFlexFields ?
                <FilterSkeleton
                    direction='row'
                    active
                    amount={4}
                    gap={10}
                    cardClassName='filter-card-skeleton'
                />
                :
                <Carousel
                    className="gs-carousel carousel-infos"
                    {...carouselDefaultProps}
                    slidesPerRow={filtersPerRow}
                >
                    <Filter
                        id="period-filter"
                        value={year}
                        setValue={(moment) => setYear([moment[0].year()])}
                        title={i18n.t("period")}
                        type="datepicker"
                        link='/'
                        data={[]}
                        datepicker={{
                            defaultToday: true,
                            picker: "year",
                            format: "YYYY"
                        }}
                        buttonStyles={{ cursor: 'default' }}
                    />
                    <Filter
                        id="businessUnit-filter"
                        title={i18n.t("business_unit")}
                        type="checkbox"
                        hasSearch
                        searchPlaceholder={i18n.t("business_unit_search")}
                        value={businessUnit}
                        setValue={setBusinessUnit}
                        data={businessUnitOptions}
                    />
                    <Filter
                        id="costCategory-filter"
                        title={i18n.t("cost_category")}
                        type="checkbox"
                        hasSearch
                        searchPlaceholder={i18n.t("search_by_cost_category")}
                        value={costCategory}
                        setValue={setCostCategory}
                        data={costCategoryOptions}
                    />
                    {flexFieldsFilters.filter(ff => filtersChecked.includes(ff.value)).sort((a, b) => {
                        if (a.fieldCode < b.fieldCode) return -1;
                        return 1
                    }).map(ffFilter => {
                        return (
                            <Filter
                                key={ffFilter.fieldCode}
                                id="flex-field-filter"
                                title={ffFilter.label}
                                type="radio"
                                value={ffFilter.selectedOption}
                                setValue={(value: any) => handleApplyFlexFilter(ffFilter, value)}
                                data={ffFilter.children}
                                hasSelectNone
                            />
                        )
                    })}
                </Carousel>
            }
            <div className="gs-flex align-center jc-center">
                <Popover
                    overlayClassName="premisses-filters-settings-popover-container"
                    content={filterSettingsPopover}
                    title={i18n.t("premisses.select_which_filters")}
                    trigger="click"
                    placement="bottom"
                >
                    <Icon
                        icon="mdi:gear"
                        className="premisses-filters-settings"
                    />
                </Popover>
                    {
                        (functionalityPermissions.copy && hasNextYear) &&
                            <Button
                                style={{ padding: '0 20px', margin: '0 10px 0 10px', height: 35 }}
                                className="gs-secondary-button"
                                onClick={handleClonePremisses}
                                loading={isClonningPremisses}
                            >
                                {isApplyingPremisses ? i18n.t("clone") : i18n.t("copy_to_next_year")}
                            </Button>
                    }
                    {
                        functionalityPermissions.apply &&
                            <Button
                                style={{ padding: '0 20px', margin: '0 20px 0 10px', height: 35 }}
                                className="gs-secondary-button"
                                onClick={handleApplyPremisses}
                                loading={isApplyingPremisses}
                                disabled={isApplyingPremissesBtnDisabled}
                            >
                                {isApplyingPremisses ? i18n.t("premisses.applying") : i18n.t("premisses.apply_assumptions")}
                            </Button>
                    }
                <Button
                    style={{ padding: '0 20px', width: 150 }}
                    className='gs-main-button'
                    onClick={handleFetchPremisses}
                >
                    {i18n.t<string>("search")}
                </Button>
            </div >
        </div >
    )
}