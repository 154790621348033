import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from "./useCachedQuery";
import { RequestType } from "util/service/IServiceCaller";

type CostCategoryOptions = {
    value: number;
    label: string;
    externalCode: string;
};

export function useCostCategoryOptions(queryProps = {} as UseQueryOptions<CostCategoryOptions[]>) {
    const queryInfo = useCachedQuery<CostCategoryOptions[]>(
        ['cost-category-options'],
        {
            type: RequestType.GET,
            url: "/human-resources/cost-category?scenario={scenario}&organization={organization}",
        },
        queryProps,
        (data) => data.map((item) => ({
            label: item.name,
            value: item.id,
            externalCode: item.externalCode
        }))
    )

    return queryInfo;
}

