import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { AllContractTableData, FlexFilterContract, IAllContractTableProps } from "../IRecurringContract";
import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import i18n from "util/base/i18n";
import moment from "moment";

export default function AllContractTable({
	tableData,
	onChange,
	selectedRowKeys,
	isLoading,
	pagination,
	flexFieldValues,
	columns,
	setColumns
}: IAllContractTableProps) {
	const rowSelection = { selectedRowKeys, onChange };
	const [tableDataFormatted, setTableDataFormatted] = useState<AllContractTableData[]>(tableData);
	const defaultCols: ColumnsType<AllContractTableData> = [
		{
			dataIndex: "contract",
			key: "contract",
			title: i18n.t("contracts"),
			align: "center"
		},
		{
			dataIndex: "basePeriod",
			key: "basePeriod",
			title: i18n.t("base_period"),
			render: (value) => {
				return capitalizeFirstLetter( moment(value).format("MMM/YYYY"))
			},
			align: "center"
		},
		{
			dataIndex: "contractType",
			key: "contractType",
			title: i18n.t("contract_type"),
			align: "center"
		},
		{
			dataIndex: "periodicity",
			key: "periodicity",
			title: i18n.t("periodicity"),
			align: "center"
		},
		{
			dataIndex: "value",
			key: "value",
			title: i18n.t("value"),
			align: "center",
			render: (value) => {
				if (value) {
					return new Intl.NumberFormat(i18n.language.replace("_", "-"), {
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
						currency: "BRL",
						style: "currency",
					}).format(value)
				}
			}
		},
	]

	function capitalizeFirstLetter(word: string): string {
		return word.charAt(0).toUpperCase() + word.slice(1);
	}

	function buildFlexColumn(flexField: FlexFilterContract[]): ColumnsType<AllContractTableData> {
		return cloneDeep(flexField.map(({ label, value }) => {
			return ({
				title: label,
				key: value,
				dataIndex: value,
				align: "center"
			})
		}))
	}

	function buildTableData(flexField: FlexFilterContract[], data: AllContractTableData[]): AllContractTableData[] {
		const newData = data.map((line) => {
			flexField.forEach(({ value, children }) => {
				const fieldValue = children.find(({ value }) => line.flexFieldValueItens.some(({ id }) => id === value))
				if (fieldValue) {
					line[value] = fieldValue.label;
				}
			})
			return line;
		})
		return newData;
	}

	useEffect(() => {
		let newData: AllContractTableData[] = [];
		let allItens: FlexFilterContract[] = [];

		tableData.forEach(({ flexFieldValueItens }) => {
			flexFieldValueItens.forEach(({ id }) => {
				const flexFieldToAdd = flexFieldValues.find(({ children }) => children.find(({ value }) => id === value));
				if (flexFieldToAdd && !allItens.some(({ value }) => value === flexFieldToAdd?.value)) {
					allItens.push(flexFieldToAdd);
				}
			})
		});

		setColumns(defaultCols.concat(buildFlexColumn(allItens)));
		newData = buildTableData(allItens, tableData);
		setTableDataFormatted(newData);
	}, [flexFieldValues, tableData])

	return (
		<Table
			dataSource={tableDataFormatted}
			columns={columns}
			rowSelection={rowSelection}
			loading={isLoading}
			className="gs-table"
			bordered={true}
			pagination={pagination}
		/>
	)
}