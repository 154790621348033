export interface ICaptchaProps {
	charactersLength?: number;
	onSuccessfulSubmit?: () => void;
	tooltipTitleI18n?: string;
	buttonTitleI18n?: string;
	hasReloadButton?: boolean;
	disabledButton?: boolean;
	captchaType?: ICaptchType;
}

export interface ILoadCaptchaEngingeProps {
	captchaType?: ICaptchType;
	numberOfCharacters?: number;
}

export enum ICaptchType {
	upper = "upper",
	lower = "lower",
	number = "number",
	special = "special",
	default = "default"
}