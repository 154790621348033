import { Form } from "antd";
import { Notification } from "components/notification/Notification";
import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import AccountRegistrationModal from "./components/AccountRegistrationModal";
import AccountRegistrationTable from "./components/AccountRegistrationTable";
import { AccountRegistrationTableData, ParametersSelect, ValueForm } from "./IAccountRegistration";
import { useUserContext } from 'context/UserContext';
import './style.sass';
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { ColumnsType } from "antd/lib/table";
import { useErrorBoundary } from "react-error-boundary";
import { cloneDeep } from "lodash";

export default function AccountRegistration() {

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState<AccountRegistrationTableData[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isFetchingParameters, setIsFetchingParameters] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNewAccount, setIsNewAccount] = useState(true);
    const [newAccountList, setNewAccountList] = useState([]);
    const [parametersData, setParametersData] = useState<ParametersSelect[]>([]);
    const [form] = Form.useForm();
    const [filterdTableData, setFilterdTableData] = useState([]);
    const [businessUnitSelectedId, setBusinessUnitSelectedId] = useState(null);
    const [parametersOption, setParametersOption] = useState([]);
    const { userInfo } = useUserContext()
    const { showBoundary } = useErrorBoundary()

    const tableColumns: ColumnsType = [
        {
            title: i18n.t<string>("code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "center",
            width: 200,
        },
        {
            title: i18n.t<string>("account"),
            dataIndex: "description",
            key: "description",
            align: "left",
            width: 400,
            className: 'account-registration-columns'
        },
        {
            title: i18n.t<string>("account_registration.hr_field"),
            dataIndex: "parameters",
            key: "parameters",
            align: "left",
            className: 'account-registration-columns',
            render: (parameters) => parameters.map((item) => `${item.description}; `)
        }
    ];
    const tableDataToExport = cloneDeep(tableData).map(item => ({
        ...item,
        parameters: item.parameters.map(param => param.description)
    }))

    const tableColumnToExport = cloneDeep(tableColumns).map(column => {
        delete column.render
        return column
    })

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/business/VerifySelected",
            useProxy: false
        }, (data) => {
            setBusinessUnitSelectedId(data[0].businessUnitSelectedId)
            requestTableData(data[0].businessUnitSelectedId);
        }, err => showBoundary(err));
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/human-resources/resource-account/parameters"
        }, onLoadParameters.bind(this), err => showBoundary(err));
    }, []);

    useEffect(() => {
        setFilterdTableData(tableData);
    }, [tableData]);

    useEffect(() => {
        if (isModalVisible) {
            loadHRField();
        }
    }, [newAccountList, isModalVisible]);

    function requestTableData(unit) {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/human-resources/resource-account/find-all?organizationId={organization}"
        }, onLoadAccountTableData.bind(this), err => showBoundary(err));
    }

    function onRequestError() {
        Notification({
            type: "warning",
            message: i18n.t<string>("unable_to_delete_with_linked_data"),
        });
        setIsFetching(false);
    }

    function onLoadAccountTableData(data) {
        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };

        const updateTableDate = data.map(res => {
            return {
                key: res.id,
                id: res.id,
                organizationId: res.organizationId,
                externalCode: res.externalCode,
                description: res.description,
                active: res.active,
                parameters: [...res.parameters]
            };
        }).sort((a,b) => a.externalCode - b.externalCode);

        setTableData(updateTableDate);
        setIsFetching(false);
    };

    function onLoadParameters(data) {
        if (data.length === 0) {
            setParametersData([]);
            setParametersOption([]);
            setIsFetchingParameters(false);
            return;
        };

        const updateParameters = data.map(res => {
            return {
                value: res.id,
                label: res.description,
            };
        });

        setParametersData(updateParameters);
        setParametersOption(updateParameters);
        setIsFetchingParameters(false);
    };

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: AccountRegistrationTableData[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    function handleOpenModal(isNewAccount: boolean) {
        if (isNewAccount) {
            setIsNewAccount(true);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            form.resetFields();
        } else {
            form.setFieldsValue({
                externalCode: selectedRows[0].externalCode,
                description: selectedRows[0].description,
                parameters: selectedRows[0].parameters.map(parameter => {
                    return [parameter.id]
                })
            });
            setIsNewAccount(false);
        }
        setNewAccountList([]);
        setIsModalVisible(true);
    };

    function handleCloseModal() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setNewAccountList([]);
        form.resetFields();
        setIsModalVisible(false);
    };

    function handleIncludeAccount(data: ValueForm) {
        if (isNewAccount) {
            setNewAccountList([...newAccountList, { ...data, parameters: data.parameters.map(p => p[0]) }]);
        } else {
            handleSaveAccount([data]);
        }
        // loadHRField();
        form.resetFields();
    }

    function handleSaveAccount(data) {
        const accountToSave = data.map(
            account => {
                return {
                    id: isNewAccount ? null : selectedRowKeys[0],
                    organizationId: userInfo.selection.organizationId,
                    externalCode: account.externalCode,
                    description: account.description,
                    parameters:
                        account.parameters.map(parameter => {
                            return { id: isNewAccount ? parameter : parameter[0] }
                        }),
                    active: true
                }

            });

        setSelectedRowKeys([]);
        setSelectedRows([]);

        setIsModalVisible(false);
        setIsFetching(true);

        ServiceCaller.doRequest({
            type: isNewAccount ? RequestType.POST : RequestType.PUT,
            url: '/human-resources/resource-account',
            params: isNewAccount ? accountToSave : accountToSave[0],
        }, onSaveCategory.bind(this));
    }

    function onSaveCategory(response) {
        if (response) {
            Notification({
                type: "success",
                message: isNewAccount ? i18n.t<string>("successfully_saved") : i18n.t<string>("successfully_edited"),
            });
        }
        form.resetFields();
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/human-resources/resource-account/find-all?organizationId={organization}`
        }, onLoadAccountTableData.bind(this));
    };

    function handleDeleteAccount() {
        setIsFetching(true);
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/human-resources/resource-account?ids=${selectedRowKeys.toString()}`,
        }, onDeleteAccount.bind(this), onRequestError.bind(this));
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function onDeleteAccount(response) {
        if (response) {
            Notification({
                type: "success",
                message: i18n.t<string>("successfully_deleted"),
            });
        }
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/human-resources/resource-account/find-all?organizationId={organization}`
        }, onLoadAccountTableData.bind(this));
    };

    function loadHRField() {
        let accounts = tableData;

        if (!isNewAccount) {
            accounts = tableData.filter(link => {
                if (link.id !== selectedRows[0].id) {
                    return true;
                }
            });
        }

        const accountIds = accounts.reduce((acc, val) => {
            const ids = val.parameters.map(cc => cc.id);
            acc = [...acc, ...ids];
            return acc;
        }, []);

        const filteredData = parametersData.filter(cc => !accountIds.includes(cc.value));

        let newAccounts = newAccountList;

        const newAccountsIds = newAccounts.reduce((acc, val) => {
            const ids = val.parameters.map(cc => cc);
            acc = [...acc, ...ids];
            return acc;
        }, []);

        const updatedOptions = filteredData.filter(cc => !newAccountsIds.includes(cc.value));
        setParametersOption(updatedOptions);
    }

    return (
        <>
            <div className="page-title-content" id="cost-category-container">
                <h1>{i18n.t<string>("account_registration.account_registration")}</h1>
            </div>
            <div id="top-buttons-account-registration">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t<string>("account_registration.new_account")}
                        handleNew={() => handleOpenModal(true)}
                        handleEdit={() => handleOpenModal(false)}
                        handleDelete={handleDeleteAccount}
                        isEditable={selectedRows.length === 1}
                        isDeletable={selectedRows.length > 0}
                        multipleSearch={{
                            tableData: tableData,
                            setTableData: setFilterdTableData,
                            options: [
                                { i18nString: 'code', description: 'externalCode' },
                                { i18nString: 'account', description: 'description' },
                                { i18nString: 'parameters', description: 'parameters', isArray: true, descriptionArray: 'description' },
                            ]
                        }}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(tableDataToExport, tableColumnToExport, i18n.t("account_registration.account_registration")),
                        }}
                    />
                </div>
            </div>
            <main id="account-registration-main">
                <AccountRegistrationTable
                    isFetching={isFetching}
                    tableData={filterdTableData}
                    selectedRowKeys={selectedRowKeys}
                    onChange={onSelectRowChange}
                    columns={tableColumns}
                />
                <AccountRegistrationModal
                    isModalVisible={isModalVisible}
                    isNewAccount={isNewAccount}
                    handleSubmit={handleIncludeAccount}
                    handleCancel={handleCloseModal}
                    form={form}
                    newAccountList={newAccountList}
                    setNewAccountList={setNewAccountList}
                    handleSaveAccount={handleSaveAccount}
                    parametersData={parametersData}
                    tableData={tableData}
                    isFetchingParameters={isFetchingParameters}
                    parametersOption={parametersOption}
                    selectedRowKeys={selectedRowKeys}
                />
            </main>
        </>
    );
}