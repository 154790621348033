
import './style.sass';
import { Key, useEffect, useState } from 'react';
import { Form, Tooltip } from 'antd';
import { InvestmentAccountingTableData, SelectList, TransferData, ValueForm } from './IInvestmentAccounting';
import { ColumnsType } from 'antd/lib/table';
import i18n from 'util/base/i18n';
import { TopButtons } from 'components/topButtons/TopButtons';
import { handleExportGridData } from 'util/functions/handleExportGridData';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { RequestType } from 'util/service/IServiceCaller';
import { sortList } from 'util/functions/sortList';
import { Notification } from 'components/notification/Notification';
import { IImportModal } from 'components/importExportMenu/IImportExportMenu';
import { useUserContext } from 'context/UserContext';
import InvestmentAccountingModal from './components/InvestmentAccountingModal';
import InvestmentAccountingTable from './components/InvestmentAccountingTable';
import { cos } from 'mathjs';
import { Icon } from '@iconify/react';

export default function InvestmentAccounting() {
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ValueForm[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNewAccounting, setIsNewAccounting] = useState(true);
    const [tableData, setTableData] = useState<InvestmentAccountingTableData[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [modalList, setModalList] = useState([]);
    const [filterdTableData, setFilterdTableData] = useState([]);
    const [accountingAccountList, setAccountingAccountList] = useState<SelectList[]>([]);
    const [devaluationList, setDevaluationList] = useState<SelectList[]>([]);
    const [costCenterList, setCostCenterList] = useState<TransferData[]>([]);
    const [isFetchingAccountingAccountList, setIsFetchingAccountingAccountList] = useState(true);
    const [isFetchingDevaluationList, setIsFetchingDevaluationList] = useState(true);
    const [isFetchingCostCenterList, setIsFetchingCostCenterList] = useState(true);
    const [selectedCostCenters, setSelectedCostCenters] = useState<TransferData[]>([]);
    const [showTransfer, setShowTransfer] = useState(false);
    const [form] = Form.useForm();
    const { userInfo } = useUserContext()

    const importProps: IImportModal[] = [
        {
            importUrl: "/investment/accounting/import",
            templateUrl: "/investment/accounting/import/template?locale={locale}",
            type: 'excel'
		},
		{
			importUrl: "/investment/accounting/import-link",
			templateUrl: "/investment/accounting/import-link/template?locale={locale}",
			type: 'excel',
			title: i18n.t("imports.import_link"),
		},
    ]

    const tableColumns: ColumnsType = [
        {
            title: i18n.t<string>("external_code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "center",
        },
        {
            title: i18n.t<string>("description"),
            dataIndex: "description",
            key: "description",
            align: "left",
            className: 'account-registration-columns'
        },
        {
            title: i18n.t("investment_accounting.depreciation"),
            dataIndex: "devaluationAccountId",
            key: "devaluationAccountId",
            align: "left",
            className: 'account-registration-columns',
            render: (devaluationId) => {
                const devaluation = devaluationList.find((item) => item.value === devaluationId);
                return devaluation?.label;
            }
        },
        {
            title: i18n.t("investment_accounting.immobilized"),
            dataIndex: "immobilizedAccountId",
            key: "immobilizedAccountId",
            width: 250,
            align: "left",
            render: (immobilized) => {
                const accounting = accountingAccountList.find((item) => item.value === immobilized);
                return accounting?.label;
            }
        },
        {
            title: i18n.t("investment_accounting.immobilized_progress"),
            dataIndex: "immobilizedProgressAccountId",
            key: "immobilizedProgressAccountId",
            align: "left",
            render: (immobilized) => {
                const accounting = accountingAccountList.find((item) => item.value === immobilized);
                return accounting?.label;
            }
        },
        {
            title: `${i18n.t<string>("rate")} (%)`,
            dataIndex: "devaluationRate",
            key: "devaluationRate",
            align: "center",
        },
        {
            title: i18n.t("cost_center"),
            dataIndex: "costCenterId",
            key: "costCenterId",
            align: "center",
            render: (immobilized) => {
                if(immobilized.length > 0){
                    return <Icon icon="material-symbols:check-circle-outline-rounded"  style={{color: "#279033"}} />
                }
            }
        }
    ];

    useEffect(() => {
        getLoadAccounting();

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/monolith/accountingaccount/get-all-investment?client={client}&organization={organization}&classification=1`
        }, onLoadAccountingAccountList.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/monolith/accountingaccount/get-all-investment?client={client}&organization={organization}&classification=4`
        }, onLoadDevaluationList.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/monolith/cost-center?client={client}&locale={locale}&organization={organization}`
        }, onLoadCostCenterList.bind(this));
    }, []);

    useEffect(() => {
        setFilterdTableData(tableData)
    }, [tableData]);

    useEffect(() => {
        if (tableData.length > 0 && !isFetchingAccountingAccountList && !isFetchingDevaluationList) {
            setTableData(tableData.map(item => {
                const immobilizedName = accountingAccountList.find(account => account.value === item.immobilizedAccountId);
                const immobilizedProgressName = accountingAccountList.find(account => account.value === item.immobilizedProgressAccountId);
                const devaluationName = devaluationList.find(account => account.value === item.devaluationAccountId);

                return {
                    ...item,
                    immobilizedName: immobilizedName?.label,
                    immobilizedProgressName: immobilizedProgressName?.label ?? "",
                    devaluationName: devaluationName?.label
                };
            }));
        }
    }, [isFetchingAccountingAccountList, isFetchingDevaluationList, isFetching])

    function getLoadAccounting() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/investment/accounting?organization={organization}",
        }, onLoadAccountingTableData.bind(this));
    }

    function onLoadAccountingTableData(data) {
        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };
        const updateTableData = data.map(res => {
            return {
                key: res.id,
                id: res.id,
                externalCode: res.externalCode,
                description: res.description,
                devaluationAccountId: res.devaluationAccountId,
                immobilizedAccountId: res.immobilizedAccountId,
                immobilizedProgressAccountId: res.immobilizedProgressAccountId,
                costCenterId: res.costCenterId,
                devaluationRate: formattedDevaluation(res.devaluationRate),
                immobilizedName: "",
                devaluationName: "",
                immobilizedProgressName: ""
            };
        }).sort((a, b) => sortList(a.externalCode, b.externalCode));

        setTableData(updateTableData);
        setIsFetching(false);
    };

    function onLoadDevaluationList(data) {
        if (data.length === 0) {
            setDevaluationList([]);
            setIsFetchingDevaluationList(false);
            return;
        };
        const updateList = data.map(res => {
            return {
                value: res.id,
                label: res.name
            };
        });

        setDevaluationList(updateList);
        setIsFetchingDevaluationList(false);
    };

    function onLoadAccountingAccountList(data) {
        if (data.length === 0) {
            setAccountingAccountList([]);
            setIsFetchingAccountingAccountList(false);
            return;
        };
        const updateList = data.map(res => {
            return {
                value: res.id,
                label: res.name
            };
        });

        setAccountingAccountList(updateList);
        setIsFetchingAccountingAccountList(false);
    };

    function onLoadCostCenterList(data){
        if (data.length === 0) {
            setCostCenterList([]);
            setIsFetchingCostCenterList(false);
            return;
        };
        const updateList = data.map(res => {
            return {
                key: res.id,
                value: res.id,
                name: res.name
            };
        });

        setCostCenterList(updateList);
        setIsFetchingCostCenterList(false);
    }

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: ValueForm[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    function handleOpenModal(isNewAccount: boolean) {
        if (isNewAccount) {
            setIsNewAccounting(true);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            form.resetFields();
        } else {
            form.setFieldsValue({
                externalCode: selectedRows[0].externalCode,
                description: selectedRows[0].description,
                devaluationAccountId: selectedRows[0].devaluationAccountId,
                immobilizedAccountId: selectedRows[0].immobilizedAccountId,
                immobilizedProgressAccountId: selectedRows[0].immobilizedProgressAccountId > 0 ? selectedRows[0].immobilizedProgressAccountId : undefined,
                devaluationRate: selectedRows[0].devaluationRate
            });
            setSelectedCostCenters(costCenterList.filter(costCenter => selectedRows[0].costCenterId.includes(Number(costCenter.key))));
            setIsNewAccounting(false);
        }
        setModalList([]);
        setIsModalVisible(true);
    };

    function handleIncludeAccount(data: ValueForm) {
        if (isNewAccounting) {
            data.costCenterId = selectedCostCenters.map(costCenter => costCenter.value);
            setModalList([...modalList, data]);
        } else {
            data.costCenterId = selectedCostCenters.map(costCenter => costCenter.value);
            handleSaveAccounting([data]);
        }
        form.resetFields();
    }

    function handleSaveAccounting(data) {
        const accountingToSave = data.map(
            accounting => {
                return {
                    id: isNewAccounting ? null : selectedRowKeys[0],
                    externalCode: isNewAccounting ? accounting.externalCode : selectedRows[0].externalCode,
                    description: accounting.description,
                    devaluationAccountId: accounting.devaluationAccountId,
                    immobilizedAccountId: accounting.immobilizedAccountId,
                    immobilizedProgressAccountId: accounting.immobilizedProgressAccountId ? accounting.immobilizedProgressAccountId : 0,
                    devaluationRate: parseFloat(accounting.devaluationRate),
                    costCenterId: selectedCostCenters.map(costCenter => costCenter.value),
                    businessUnitId: userInfo.selection.businessUnitId,
                    organizationId: userInfo.selection.organizationId
                }
            });

        setSelectedRowKeys([]);
        setSelectedRows([]);

        setIsModalVisible(false);
        setIsFetching(true);
        
        ServiceCaller.doRequest({
            type: isNewAccounting ? RequestType.POST : RequestType.PUT,
            url: '/investment/accounting',
            params: isNewAccounting ? accountingToSave : accountingToSave[0],
        }, onSaveAccounting.bind(this));
    }

    function onSaveAccounting(response) {
        if (response) {
            Notification({
                type: "success",
                message: isNewAccounting ? i18n.t<string>("successfully_saved") : i18n.t<string>("successfully_edited"),
            });
        }
        form.resetFields();
        setSelectedCostCenters([]);
        
        getLoadAccounting();
    };

    function handleCloseModal() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setModalList([]);
        form.resetFields();
        setIsModalVisible(false);
        setShowTransfer(false);
        setSelectedCostCenters([]);
    };

    function handleDeleteAccounting() {
        setIsFetching(true);
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/investment/accounting?ids=${selectedRowKeys.toString()}`,
        }, onDeleteAccounting.bind(this), onRequestError.bind(this));
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function onDeleteAccounting(response) {
        if (response) {
            Notification({
                type: "success",
                message: i18n.t<string>("successfully_deleted"),
            });
        }

        getLoadAccounting();
    };

    function onRequestError() {
        Notification({
            type: "warning",
            message: i18n.t<string>("unable_to_delete_with_linked_data"),
        });
        setIsFetching(false);
    }

    function formattedDevaluation(devaluation) {
        return new Intl.NumberFormat(i18n.language.replace("_", "-"), {
            notation: "compact",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(Number(devaluation)).replace('.', ',');
    };

    return (
        <>
            <div className="page-title-content" id="cost-category-container">
                <h1>{i18n.t("investment_accounting.accounting_register")}</h1>
            </div>
            <div id="top-buttons-account-registration">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t("new_register")}
                        handleNew={() => handleOpenModal(true)}
                        handleEdit={() => handleOpenModal(false)}
                        handleDelete={handleDeleteAccounting}
                        handleSuccessImport={getLoadAccounting}
                        isEditable={selectedRows.length === 1}
                        isDeletable={selectedRows.length > 0}
                        multipleSearch={{
                            tableData: tableData,
                            setTableData: setFilterdTableData,
                            options: [
                                { i18nString: 'code', description: 'externalCode' },
                                { i18nString: 'description', description: 'description' },
                                { i18nString: 'investment_accounting.depreciation', description: 'devaluationName' },
                                { i18nString: 'investment_accounting.immobilized', description: 'immobilizedName' },
                                { i18nString: 'investment_accounting.immobilized_progress', description: 'immobilizedProgressName' },
                                { i18nString: 'rate', description: 'devaluationRate' },
                            ]
                        }}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(tableData, tableColumns, i18n.t("investment_accounting.accounting_register")),
                            importProps: importProps
                        }}
                    />
                </div>
            </div>
            <main id="account-registration-main">
                <InvestmentAccountingTable
                    isFetching={isFetching}
                    tableData={filterdTableData}
                    selectedRowKeys={selectedRowKeys}
                    onChange={onSelectRowChange}
                    columns={tableColumns}
                />
                <InvestmentAccountingModal
                    isModalVisible={isModalVisible}
                    isNewAccounting={isNewAccounting}
                    handleSubmit={handleIncludeAccount}
                    handleCancel={handleCloseModal}
                    form={form}
                    modalList={modalList}
                    setModalList={setModalList}
                    handleSaveAccount={handleSaveAccounting}
                    tableData={tableData}
                    selectedRowKeys={selectedRowKeys}
                    accountingAccountList={accountingAccountList}
                    isFetchingAccountingAccountList={isFetchingAccountingAccountList}
                    devaluationList={devaluationList}
                    isFetchingDevaluationList={isFetchingDevaluationList}
                    formattedDevaluation={formattedDevaluation}
                    costCenterList={costCenterList}
                    setCostCenterList={setCostCenterList}
                    isFetchingCostCenterList={isFetchingCostCenterList}
                    setSelectedCostCenters={setSelectedCostCenters}
                    selectedCostCenters={selectedCostCenters}
                    showTransfer={showTransfer}
                    setShowTransfer={setShowTransfer}
                />
            </main>
        </>
    )
}
