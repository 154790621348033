import { Icon } from '@iconify/react';
import { notification as NotificationAntd } from 'antd';
import { INotification } from './INotification';
import i18n from 'util/base/i18n';

export const Notification = ({
	type,
	message,
	...args
}: INotification) => {
	const notificationTitle =
		type === 'success' ? i18n.t("notifications.success")
			: type === 'error' ? i18n.t("notifications.oh_no")
				: type === 'warning' ? i18n.t("notifications.oops")
					: i18n.t("notifications.notice")

	const icon =
		type === 'success' ? <Icon icon="ant-design:check-circle-filled" />
			: type === 'error' ? <Icon icon="fluent-mdl2:status-error-full" />
				: type === 'warning' ? <Icon icon="ant-design:warning-filled" />
					: <Icon icon="ant-design:info-circle-filled" />

	NotificationAntd[type]({
		top: 5,
		message: notificationTitle,
		description: message,
		className: `notification-${type}`,
		icon,
		...args
	})

}

