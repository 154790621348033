import { TableData } from "../IDistributionModal";

export function updateRemainingAmount(tableData: TableData[], valueAmount: number, calculationMode: string): number {
    const amountApplied = tableData.reduce((acc, val) => {
        if(calculationMode === 'currency') {
            acc += val.value
        } else {
            acc += (val.value * valueAmount / 100)
        }
        
        return acc
    },0)
    
    let result = valueAmount - amountApplied 
    
    if(result < 0.01 && result > -0.01) {
        result = 0
    }

    return result
}