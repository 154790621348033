import i18n from "util/base/i18n";
import { SelectAllCardProps } from "./ISelectAllCard";
import "./style.sass";

export function SelectAllCard({
    selectedRowKeys,
    setSelectedRowKeys,
    tableData,
    setIsSelectingAll
}: SelectAllCardProps){
    
    function handleClickMarkAll() {
        setSelectedRowKeys(tableData.map(item => item.key));
    }

    function handleClearSelection() {
        setIsSelectingAll(false);
        setSelectedRowKeys([]);
    }

    return (
        <div className="all-items-checked-info">
            <p><b>{selectedRowKeys.length}</b> {i18n.t<string>("items_selected")}</p>
            {selectedRowKeys.length === tableData.length ?
                <button onClick={() => handleClearSelection()}>{i18n.t<string>("clear_selection")}</button> :
                <button onClick={() => handleClickMarkAll()}>
                    {i18n.t<string>("select_all_items") + tableData.length + i18n.t<string>("items")}
                </button>
            }
        </div>
    )
}