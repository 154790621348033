import { Periodicity } from "../../../../IReportView";
import { buildColumnComparativeScenarioYears } from "./buildColumn";

export function constructColumnComparativeScenarioYears(periodicity, monthFormatted, monthFormatDataIndex, reportVisualizationFilters, rowsFromBackend, currentDate, columnsList) {
    let listComparative = buildColumnComparativeScenarioYears(monthFormatted, monthFormatDataIndex, reportVisualizationFilters, rowsFromBackend);

    const column = {
        title: monthFormatted,
        dataIndex: monthFormatDataIndex,
        children: []
    };

    listComparative.forEach(element => {
        column.children.push(element);
    });

    if (periodicity !== Periodicity.YEARLY) {
        const valuePeriod = getPeriodValue(periodicity);

        const adjustedMonth = currentDate.month() + 1;

        if (adjustedMonth % valuePeriod === 0) {
            columnsList.push(column);
        }
    } else {
        columnsList.push(column);
    }
}

function getPeriodValue(periodicity) {
    switch (periodicity) {
        case Periodicity.MONTHLY:
            return 1;
        case Periodicity.BIMONTHLY:
            return 2;
        case Periodicity.QUARTERLY:
            return 3;
        case Periodicity.SEMESTERLY:
            return 6;
        default:
            return 1;
    }
}