import { Icon } from "@iconify/react";
import { useUserContext } from "context/UserContext";
import i18n from "util/base/i18n";

export default function ButtonFilterScenery() {

	const { userInfo, changeModal } = useUserContext();

	const getSelectionLabel = () => {
		const {
			scenarioName,
			organizationName,
			businessUnitName
		} = userInfo.selection;

		let label: string;

		if (!scenarioName || !organizationName || !businessUnitName) {
			label = i18n.t<string>("scenery_selection");
		} else {
			label = `${scenarioName} | ${organizationName} | ${businessUnitName}`
		}

		return label;
	}

	return (
		<button onClick={changeModal.bind(this)}>
			<div className='screen-filter-main'>
				<Icon icon='ps:building' />
				<p>{getSelectionLabel()}</p>
				<Icon icon='el:refresh' />
			</div>
		</button>
	)

}
