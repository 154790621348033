import * as React from "react";
import moment from "moment";

import {
	Space,
	Button,
	Tooltip
} from "antd";

import { Icon } from "@iconify/react";

import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import {
	ChartType,
	IChartConfig
} from "components/chart/IChart";
import { IChartWidgetProps, IWidgetResponse } from "./IChartWidget";

import {
	Chart,
	DateRange
} from "components";

import i18n from "util/base/i18n";

import { LoadingOutlined } from "@ant-design/icons";

export default function CashAvailability(props: IChartWidgetProps) {

	const chartInputColor: string = "#00FFD1, #06D1AD";
	const chartOutputColor: string = "#FF0C0C, #A30E0E";
	const chartBalanceColor: string = "#4F9ACF";

	const buildChartLabel = (date: moment.Moment): string => {
		let day: string = date.format("DD");
		let month: number = Number(date.format("M"))-1;

		return `<b>${day}/<br>${i18n.t<string>(`month.medium.${month}`)}</b>`;
	};

	const [loading, setLoading]: [boolean, Function] = React.useState(false);
	const [useCache, setUseCache]: [boolean, Function] = React.useState(true);

	const [startDate, setStartDate]: [moment.Moment, Function] = React.useState(moment(new Date()));
	const [endDate, setEndDate]: [moment.Moment, Function] = React.useState(moment(new Date().setDate(new Date().getDate() + 7)));

	const [chartData, setChartData]: [any, Function] = React.useState([]);

	const [cached, setCached]: [boolean, Function] = React.useState(false);
	const [cacheDate, setCacheDate]: [Date, Function] = React.useState(null);

	const chartConfig: IChartConfig = {
		usePlotGradientColor: false,
		drawCustomLegendIcon: true,
		legendIconSides: "0",
		showValues: true
	};

	const loadChartData = (): void => {
		setLoading(true);

		const {
			currencyId,
			globalFilter: {
				filterBy,
				values
			}
		} = props;

		const filterValuesQuery: string = values.map(value => `filterValue=${value}`).join("&");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/cash/availability?filterBy=${filterBy}&${filterValuesQuery}&client={client}&user={user}&startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}&scenario={scenario}&currency=${currencyId}`,
		}, onLoadChart.bind(this));
	};

	const buildTooltip = (date: moment.Moment, value: number, isoCode: string): string => {
		let day: string = date.format("DD");
		let month: number = Number(date.format("M"))-1;
		let formattedValue: string;
		isoCode = isoCode.replace(/[0-9]/g, '');

		try {
			formattedValue = new Intl.NumberFormat(i18n.language.split("_")[0], {
				style: "currency",
				currencyDisplay: "symbol",
				currency: isoCode
			}).format(value);
			
		} catch (error) {
			formattedValue = value.toString();
			console.error(error.message);
		}

		return `
			<div class="chart-tooltip-parent">
				<b>${formattedValue}</b> 
				<br /> 
				<div>${day + " " + i18n.t<string>(`month.medium.${month}`)}</div>
			</div>
		`;
	}

	const onLoadChart = (response: IWidgetResponse) => {
		response.data = response.data.sort(({date: dateA}, {date: dateB}) => dateA.localeCompare(dateB));
		
 		let inputs = response.data.map(({ date, input: value, isoCode }) => {
			return {
				value,
				tooltext: buildTooltip(moment(date), value, isoCode)
			}
		});

		let outputs = response.data.map(({ date, output: value, isoCode }) => {
			return {
				value,
				tooltext: buildTooltip(moment(date), value, isoCode)
			}
		});

		setCached(response.cached);
		setCacheDate(response.date);
		setChartData({
			categories: [{
				category: response.data.map(({ date }) => {
					return {
						label: buildChartLabel(moment(date))
					}
				})
			}],
			dataset: [{
				"dataset": [{
					"seriesname": i18n.t<string>("inputs"),
					"color": chartInputColor,
					"data": inputs
				}]
			}, {
				"dataset": [{
					"seriesname": i18n.t<string>("outputs"),
					"color": chartOutputColor,
					"data": outputs
				}]
			}],
			lineset: [{
				"seriesname": i18n.t<string>("balance"),
				"showvalues": "0",
				"color": chartBalanceColor,
				"data": response.data.map(({ date, balance: value, isoCode }) => {
					return {
						value,
						tooltext: buildTooltip(moment(date), value, isoCode)
					}
				})
			}]
		});

		setLoading(false);
		setUseCache(false);
	};

	React.useEffect(loadChartData, [startDate, endDate, props.currencyId, props.globalFilter]);

	const onChangeDate = (dates: Array<moment.Moment>): void => {
		const [
			newStartDate,
			newEndDate
		] = dates;

		if (startDate.isSame(newStartDate) && endDate.isSame(newEndDate)) {
			loadChartData();
		} else {
			const {
				filterId
			} = props;

			setStartDate(newStartDate);
			setEndDate(newEndDate);
		}
	};

	return <>
		<Space direction="vertical" size={10} style={{width: "100%"}}>
			<div className="widget-header-actions-container">
				{
					cached && <>
						<Tooltip title={ i18n.t<string>("dashboard.analytics.text_002", {date: new Intl.DateTimeFormat(i18n.language.replace("_", "-"), { dateStyle: "short", timeStyle: "medium" }).format(new Date(cacheDate))}) }>
							<Button onClick={loadChartData.bind(this)} type="text" size="large" icon={<Icon icon={"mdi:reload-alert"} />} disabled={loading} data-html2canvas-ignore="true" />
						</Tooltip>
					</>
				}

				<DateRange format="DD/MMM" formatEdit="DD/MM/YYYY" picker="date" value={[startDate, endDate]} onChange={onChangeDate.bind(this)} disabled={loading} />
			</div>

			{
				loading ? <>
					<div className="loading-container">
						<LoadingOutlined />
					</div>
				</> : <Chart id="widget-9" type={ChartType.MS_STACKED_COLUMN_2D_LINE} config={chartConfig} categories={chartData.categories} dataset={chartData.dataset} lineset={chartData.lineset} />
			}
		</Space>
	</>;

}
