import { coachMarkChannel } from "../../../util/BroadcastChannels";
import { CoachmarkStep } from "../CoachMark";

export function useCMComponent(
	currentStep: number,
	stepAmount: number,
	title: string,
	content: string,
	setCurrentStep: (step: number) => void,
	setOpenCoachMark: (b: boolean) => void,
): JSX.Element {

	function resetStyleSheet() {
		const elements = document.getElementsByClassName(`cm-step${currentStep}`)
		Object.keys(elements).forEach(key => {
			elements[key].style.zIndex = ''
			elements[key].style.position = ''
			elements[key].style.pointerEvents = ''
		})
	}

	function finishCoachMark() {
		setCurrentStep(1)
		coachMarkChannel.postMessage('hide-overlay')
		setOpenCoachMark(false)
		resetStyleSheet()
	}
	
	function doNextStep() {
		if (currentStep === stepAmount) {
			setCurrentStep(1)
			coachMarkChannel.postMessage('hide-overlay')
			setOpenCoachMark(false)
		} else {
			setCurrentStep(currentStep + 1)
		}
		resetStyleSheet()
	}

	return (
		<CoachmarkStep
			stepAmount={stepAmount}
			currentStep={currentStep}
			title={title}
			content={content}
			finishCoachMark={finishCoachMark}
			doNextStep={doNextStep}
		/>
	)
}