import moment from "moment";
import { CardSkeleton } from "components/skeleton/cardSkeleton/CardSkeleton";
import { Card } from "./Card";
import { useBudgetOpeningContext } from "module/budget/pages/fixedExpense/context/useBudgetOpeningContext";

import "./styles.sass";
import { useEffect } from "react";

export function SummaryCards({ savedValue }) {
    const { currency, isLoading, cards, refreshCard } = useBudgetOpeningContext();

    useEffect(() => {
        refreshCard()
    }, [savedValue]);

    return (
        <div id="summary-cards-container">
            {isLoading || !currency ? (
                <>
                    <CardSkeleton direction="row" active amount={4} gap={10} cardClassName="summary-card-skeleton" />
                </>
            ) : cards.length > 0 ? (
                cards.map((card) => {
                    return (
                        <Card
                            label={card.label}
                            value={card.value}
                            type={card.type}
                            key={card.label.replace(" ", "-")}
                            icon={card.icon}
                            isoCode={currency?.isoCode}
                        />
                    );
                })
            ) : (
                <div></div>
            )}
        </div>
    );
}
