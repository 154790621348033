import { Icon } from "@iconify/react";
import { Button, Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import { OrganizationBudgetDates, RevisionTableData } from "../../IControlPanel";
import { ColumnType } from "antd/lib/table";
import i18n from "util/base/i18n";
import moment from "moment";
import { ModuleType } from "util/types/types";

const { Panel } = Collapse;

interface IRevisionContainer {
    organization: OrganizationBudgetDates;
    moduleType: ModuleType;
    onOpenRevisionModal(n: number): void;
    year: number;
    revisions: any[];
}

export function RevisionContainer({ onOpenRevisionModal, revisions, year }: IRevisionContainer) {
    const [tableData, setTableData] = useState<RevisionTableData[]>([
        {
            budgetEndDate: 'MM/AAAA',
            budgetStartDate: 'MM/AAAA',
            reviewPeriodEndDate: 'DD/MM/AAAA',
            reviewPeriodStartDate: 'DD/MM/AAAA'
        }
    ])

    useEffect(() => {
        if (!revisions) return
        setTableData(revisions)
    }, [revisions])

    function formatDate(date: number | string, format: string) {
        if ( typeof date === 'number') {
            return moment(date).format(format)
        }
        return <span className="date-placeholder">{date}</span>
    }

    const columns: ColumnType<RevisionTableData>[] = [
        {
            key: 'budgetStartDate',
            dataIndex: 'budgetStartDate',
            title: i18n.t("start_date"),
            render: (date) => formatDate(date, 'MM/YYYY')
        },
        {
            key: 'budgetEndDate',
            dataIndex: 'budgetEndDate',
            title: i18n.t("end_date"),
            render: (date) => formatDate(date , 'MM/YYYY')
        },
        {
            key: 'reviewPeriodStartDate',
            dataIndex: 'reviewPeriodStartDate',
            title: i18n.t("review_of"),
            render: (date) => formatDate(date, 'DD/MM/YYYY')
        },
        {
            key: 'reviewPeriodEndDate',
            dataIndex: 'reviewPeriodEndDate',
            title: i18n.t("review_until"),
            render: (date) => formatDate(date, 'DD/MM/YYYY')
        },
    ]

    return (
        <Collapse
            expandIcon={(panelProps) => <Icon icon={`ion:chevron-${panelProps.isActive ? 'up' : 'down'}`} width={18} />}
            expandIconPosition="right"
        >
            <Panel header={i18n.t('review_dates')} key="1">
                <Table
                    style={{ marginTop: 10, padding: '0 16px' }}
                    className="gs-table"
                    columns={columns}
                    dataSource={tableData}
                    bordered
                    pagination={{ hideOnSinglePage: true }}
                />
                <Button
                    className="gs-secondary-button"
                    onClick={() => onOpenRevisionModal(year)}
                    style={{margin: '16px 16px 16px auto'}}
                >
                    {i18n.t('add_review')}
                </Button>
            </Panel>
        </Collapse>
    )
}