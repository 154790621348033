import moment from 'moment';
import { Moment } from "moment";
import { FormattedNumber } from "react-intl";
import i18n from "util/base/i18n";

import { ModalityType, Periodicity } from "../../IReportView";
import {
    buildColumnComparative,
    buildColumnComparativeTotal,
    buildColumnForecastTotal,
    formatColumnList,
    formatColumnListForecast
} from './functions';

import { CellContent } from './CellContent';
import { buildColumnComparativeTotalScenarioYears } from './functions/comparativeScenarioYears/buildTotalColumns';
import { constructColumnComparativeScenarioYears } from './functions/comparativeScenarioYears/constructColumn';
import buildColumn, { mapOrganizations } from './functions/organization/defaultColumn/buildColumn';
import { buildColumnComparativeConsolidation } from './functions/organization/comparativeAccomplishedPlanned/buildColumn';
import { buildColumnComparativeTotalConsolidation } from './functions/organization/comparativeAccomplishedPlanned/buildTotalColumns';

function flattenLevels(levels: any[], isFragment: boolean = false, isDetailPlan: boolean = false) {
    const flattenedLevels = [];

    levels.forEach(level => {
        let flattenedLevel: any;
        if (isFragment) {
            flattenedLevel = {
                id: level.levelId,
                description: level.name,
                columnValues: level.values,
                totalColumnsValues: level.columns,
                formulaValueType: level.formulaValueType,
                isFragment: true,
                levelKey: level.levelKey
            };
        } else if (isDetailPlan) {
            flattenedLevel = {
                id: level.levelId,
                description: level.description,
                columnValues: level.values,
                totalColumnsValues: level.columns,
                formulaValueType: level.type,
                isFragment: false,
                isDetailPlan: true,
                levelKey: level.levelKey
            };
        } else {
            flattenedLevel = {
                id: level.id,
                description: level.description,
                isInvisible: level.invisible,
                isInformative: level.informative,
                isTitle: level.title,
                columnValues: level.reportValues.valueBeans,
                totalColumnsValues: level.reportValues.columnValueBeans,
                rowStyle: level.levelStyle,
                forecastLastPeriodRealized: level.reportValues.lastPeriodRealized,
                isFragment: false,
                levelKey: level.levelKey,
                totalOrganizations: level.reportValues.totalOrganization,
                totalConsolidation: level.reportValues.totalConsolidation,
                totalElimination: level.reportValues.totalElimination
            };
        }

        flattenedLevels.push(flattenedLevel);

        if (level.subLevels && level.subLevels.length > 0) {
            const subLevels = flattenLevels(level.subLevels);
            flattenedLevels.push(...subLevels);
        }

        if (level.reportValues?.fragments && level.reportValues.fragments.length > 0) {
            const fragments = flattenLevels(level.reportValues.fragments, true);
            flattenedLevels.push(...fragments);
        }

        if (level.items && level.items.length > 0) {
            const fragments = flattenLevels(level.items, isFragment, isDetailPlan);
            flattenedLevels.push(...fragments);
        }

        if (level.reportValues?.detailsPlan?.length) {
            const subLevels = flattenLevels(level.reportValues.detailsPlan, false, true);
            flattenedLevels.push(...subLevels);
        }
    });

    return flattenedLevels;
}

export async function buildGridColumns(
    reportConfig,
    period,
    report,
    reportVisualizationFilters,
    isToRebuildGrid = false,
    lastPeriodRealized,
    periodBase,
    isConsolidation = false
) {
    const rowsFromBackend = isToRebuildGrid ? flattenLevels(report) : flattenLevels(report.levels);
    let startPeriod = reportConfig.modalityType === ModalityType.COMPARATIVE_SCENARIO_YEARS ? periodBase[1].isBefore(periodBase[0]) ? periodBase[1].clone() : periodBase[0].clone() : period[1].clone();
    let endPeriod = reportConfig.modalityType === ModalityType.COMPARATIVE_SCENARIO_YEARS ? periodBase[0].isAfter(periodBase[1]) ? periodBase[0].clone() : periodBase[1].clone() : period[0].clone();
    const periodicity = reportConfig ? reportConfig.periodType : "MONTHLY";

    let columnsList = [];
    if (endPeriod.isBefore(startPeriod)) {
        let startDate = endPeriod.clone();
        let endDate = startPeriod.clone();

        startPeriod = startDate.clone();
        endPeriod = endDate.clone();
    }

    let currentDate: Moment = startPeriod.clone();

    while (currentDate.isSameOrBefore(endPeriod)) {
        if (periodicity === "YEARLY") {
            currentDate.month(11);
        }

        let monthFormat = currentDate.format('MMM YYYY');
        let monthFormatted;
        if (reportConfig.modalityType === ModalityType.COMPARATIVE_SCENARIO_YEARS) {
            monthFormatted = currentDate.format('MMM').charAt(0).toUpperCase() + currentDate.format('MMM').slice(1);
        } else {
            monthFormatted = monthFormat.charAt(0).toUpperCase() + monthFormat.slice(1);
        }

        let monthFormatDataIndex = currentDate.format('YYYY-MM');

        if (reportConfig) {
            if (reportConfig.modalityType === ModalityType.PLANNED) {
                if (periodicity === Periodicity.MONTHLY) {
                    const column = {
                        title: monthFormatted,
                        editable: false,
                        align: 'center',
                        dataIndex: monthFormatDataIndex + "plannedValue",
                        width: 'auto',
                        render: (text, record) => {
                            let currentRow;
                            if (record?.formulaValueType) {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
                            } else if (record.levelKey) {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
                            } else {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
                            }

                            const periodCell = currentRow?.columnValues ? currentRow.columnValues
                                .find(cell => cell.period === monthFormatDataIndex) : null;

                            let textDecoration = "";

                            if (currentRow?.rowStyle && currentRow.rowStyle.isScratched) {
                                textDecoration = textDecoration + "line-through "
                            }

                            if (currentRow?.rowStyle && currentRow.rowStyle.isUnderlined) {
                                textDecoration = textDecoration + "underline"
                            }

                            if (currentRow?.isTitle) {
                                return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
                            }

                            let renderedValue = (periodCell && periodCell?.value) ? periodCell.value : 0;

                            if (reportVisualizationFilters?.showThousand) {
                                renderedValue = renderedValue / 1000;
                            }

                            return (
                                <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                                    <FormattedNumber
                                        value={renderedValue}
                                        style={`decimal`}
                                        currencyDisplay="symbol"
                                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                    />
                                </CellContent>
                            );
                        }
                    };

                    columnsList.push(column);
                } else {
                    const column = {
                        title: monthFormatted,
                        editable: false,
                        align: 'center',
                        dataIndex: monthFormatDataIndex + "plannedValue",
                        width: 90,
                        render: (text, record) => {
                            let currentRow;
                            if (record?.formulaValueType) {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
                            } else if (record.levelKey) {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
                            } else {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
                            }

                            const periodCell = currentRow?.columnValues ?
                                currentRow.columnValues.find(cell => cell.period === monthFormatDataIndex) : null;

                            let textDecoration = "";

                            if (currentRow.rowStyle && currentRow.rowStyle.isScratched) {
                                textDecoration = textDecoration + "line-through "
                            }

                            if (currentRow.rowStyle && currentRow.rowStyle.isUnderlined) {
                                textDecoration = textDecoration + "underline"
                            }

                            if (currentRow?.isTitle) {
                                return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
                            }

                            let renderedValue = (periodCell && periodCell.value) ? periodCell.value : 0;

                            if (reportVisualizationFilters.showThousand) {
                                renderedValue = renderedValue / 1000;
                            }

                            return (
                                <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                                    <FormattedNumber
                                        value={renderedValue}
                                        style={`decimal`}
                                        currencyDisplay="symbol"
                                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                    />
                                </CellContent>
                            );
                        }
                    };

                    let valuePeriod;

                    if (periodicity === Periodicity.MONTHLY) {
                        valuePeriod = 1;
                    }
                    if (periodicity === Periodicity.BIMONTHLY) {
                        valuePeriod = 2;
                    }
                    if (periodicity === Periodicity.QUARTERLY) {
                        valuePeriod = 3;
                    }
                    if (periodicity === Periodicity.SEMESTERLY) {
                        valuePeriod = 6;
                    }

                    if (periodicity === Periodicity.YEARLY) {
                        columnsList.push(column);
                    } else {
                        const adjustedMonth = currentDate.month() + 1;

                        if (adjustedMonth % valuePeriod === 0) {
                            columnsList.push(column);
                        }
                    }
                }
            }

            if (reportConfig.modalityType === ModalityType.ACCOMPLISHED) {
                const column = {
                    title: monthFormatted,
                    editable: false,
                    align: 'center',
                    dataIndex: monthFormatDataIndex + "accomplishedValue",
                    width: 90,
                    render: (text, record) => {
                        let currentRow;
                        if (record?.formulaValueType) {
                            currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
                        } else if (record.levelKey) {
                            currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
                        } else {
                            currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
                        }

                        const periodCell = currentRow?.columnValues ?
                            currentRow.columnValues.find(cell => cell.period === monthFormatDataIndex) : null;

                        let textDecoration = "";

                        if (record.style && record.style.isScratched) {
                            textDecoration = textDecoration + "line-through "
                        }

                        if (record.style && record.style.isUnderlined) {
                            textDecoration = textDecoration + "underline"
                        }

                        if (currentRow?.isTitle) {
                            return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
                        }

                        let renderedValue = (periodCell && periodCell.value) ? periodCell.value : 0;

                        if (reportVisualizationFilters.showThousand) {
                            renderedValue = renderedValue / 1000;
                        }

                        return (
                            <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                                <FormattedNumber
                                    value={renderedValue}
                                    style={`decimal`}
                                    currencyDisplay="symbol"
                                    minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                    maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                />
                            </CellContent>
                        );
                    }
                };

                let valuePeriod;

                if (periodicity === Periodicity.MONTHLY) {
                    valuePeriod = 1;
                }
                if (periodicity === Periodicity.BIMONTHLY) {
                    valuePeriod = 2;
                }
                if (periodicity === Periodicity.QUARTERLY) {
                    valuePeriod = 3;
                }
                if (periodicity === Periodicity.SEMESTERLY) {
                    valuePeriod = 6;
                }

                if (periodicity === Periodicity.YEARLY) {
                    columnsList.push(column);
                } else {
                    const adjustedMonth = currentDate.month() + 1;

                    if (adjustedMonth % valuePeriod === 0) {
                        columnsList.push(column);
                    }
                }
            }

            if (reportConfig.modalityType === ModalityType.COMPARATIVE_ACCOMPLISHED_PLANNED) {
                if (periodicity === Periodicity.MONTHLY) {
                    let listComparative = buildColumnComparative(monthFormatted, monthFormatDataIndex,
                        reportVisualizationFilters, rowsFromBackend);

                    const column = {
                        title: monthFormatted,
                        dataIndex: monthFormatDataIndex,
                        children: []
                    };

                    listComparative.forEach(element => {
                        column.children.push(element);
                    });

                    columnsList.push(column);
                } else {
                    let listComparative = buildColumnComparative(monthFormatted, monthFormatDataIndex,
                        reportVisualizationFilters, rowsFromBackend);

                    const column = {
                        title: monthFormatted,
                        dataIndex: monthFormatDataIndex,
                        year: monthFormatDataIndex.split('-')[0],
                        children: []
                    };

                    listComparative.forEach(element => {
                        column.children.push(element);
                    });

                    let valuePeriod;

                    if (periodicity === Periodicity.MONTHLY) {
                        valuePeriod = 1;
                    }
                    if (periodicity === Periodicity.BIMONTHLY) {
                        valuePeriod = 2;
                    }
                    if (periodicity === Periodicity.QUARTERLY) {
                        valuePeriod = 3;
                    }
                    if (periodicity === Periodicity.SEMESTERLY) {
                        valuePeriod = 6;
                    }

                    if (periodicity === Periodicity.YEARLY) {
                        columnsList.push(column);
                    } else {
                        const adjustedMonth = currentDate.month() + 1;

                        if (adjustedMonth % valuePeriod === 0) {
                            columnsList.push(column);
                        }
                    }
                }
            }

            if (reportConfig.modalityType === ModalityType.COMPARATIVE_SCENARIO_YEARS) {
                constructColumnComparativeScenarioYears(periodicity, monthFormatted, monthFormatDataIndex, reportVisualizationFilters, rowsFromBackend, currentDate, columnsList);
            }

            if (reportConfig.modalityType === ModalityType.FORECAST) {
                if (periodicity === Periodicity.MONTHLY) {
                    const column: any = {
                        title: monthFormatted,
                        editable: false,
                        align: 'center',
                        width: 90,
                        dataIndex: monthFormatDataIndex,
                        render(text, record) {
                            let currentRow;
                            if (record?.formulaValueType) {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
                            } else if (record.levelKey) {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
                            } else {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
                            }

                            let periodCell = null;

                            if (currentRow.columnValues) {
                                periodCell = currentRow.columnValues.find(cell => cell.period === monthFormatDataIndex);
                            }

                            const isAccomplished = periodCell ? periodCell.accomplished : false;

                            column.onHeaderCell = () => {
                                return {
                                    style: {
                                        backgroundColor: isAccomplished ? '#004D7B' : '',
                                    }
                                }
                            };

                            column.isAccomplished = isAccomplished;

                            let textDecoration = "";

                            if (currentRow.rowStyle && currentRow.rowStyle.isScratched) {
                                textDecoration = textDecoration + "line-through "
                            }

                            if (currentRow.rowStyle && currentRow.rowStyle.isUnderlined) {
                                textDecoration = textDecoration + "underline"
                            }

                            if (currentRow?.isTitle) {
                                return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
                            }

                            if (periodCell === null) {
                                return (
                                    <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                                        <FormattedNumber
                                            value={0}
                                            style={`decimal`}
                                            currencyDisplay="symbol"
                                            minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                            maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                        />
                                    </CellContent>
                                );
                            }

                            let renderedValue;

                            if (isAccomplished) {
                                renderedValue = (periodCell && periodCell.accomplishedValue) ? periodCell.accomplishedValue : 0;
                            } else {
                                renderedValue = (periodCell && periodCell.plannedValue) ? periodCell.plannedValue : 0;
                            }

                            if (reportVisualizationFilters.showThousand) {
                                renderedValue = renderedValue / 1000;
                            }

                            return (
                                <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                                    <FormattedNumber
                                        value={renderedValue}
                                        style={`decimal`}
                                        currencyDisplay="symbol"
                                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                    />
                                </CellContent>
                            );
                        }
                    };

                    columnsList.push(column);
                } else {
                    const column: any = {
                        title: monthFormatted,
                        editable: false,
                        align: 'center',
                        width: 90,
                        dataIndex: monthFormatDataIndex,
                        render(text, record) {
                            let currentRow;
                            if (record?.formulaValueType) {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
                            } else if (record.levelKey) {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
                            } else {
                                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
                            }

                            let periodCell = null;

                            if (currentRow.columnValues) {
                                periodCell = currentRow.columnValues.find(cell => cell.period === monthFormatDataIndex);
                            }

                            const isAccomplished = periodCell ? periodCell.accomplished : false;

                            column.onHeaderCell = () => {
                                return {
                                    style: {
                                        backgroundColor: isAccomplished ? '#004D7B' : '',
                                    }
                                }
                            };

                            column.isAccomplished = isAccomplished;

                            let textDecoration = "";

                            if (currentRow.rowStyle && currentRow.rowStyle.isScratched) {
                                textDecoration = textDecoration + "line-through "
                            }

                            if (currentRow.rowStyle && currentRow.rowStyle.isUnderlined) {
                                textDecoration = textDecoration + "underline"
                            }

                            if (currentRow?.isTitle) {
                                return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
                            }

                            if (periodCell === null) {
                                return (
                                    <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                                        <FormattedNumber
                                            value={0}
                                            style={`decimal`}
                                            currencyDisplay="symbol"
                                            minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                            maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                        />
                                    </CellContent>
                                );
                            }

                            let renderedValue;

                            if (isAccomplished) {
                                renderedValue = (periodCell && periodCell.accomplishedValue) ? periodCell.accomplishedValue : 0;
                            } else {
                                renderedValue = (periodCell && periodCell.plannedValue) ? periodCell.plannedValue : 0;
                            }

                            if (reportVisualizationFilters.showThousand) {
                                renderedValue = renderedValue / 1000;
                            }

                            return (
                                <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                                    <FormattedNumber
                                        value={renderedValue}
                                        style={`decimal`}
                                        currencyDisplay="symbol"
                                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                                    />
                                </CellContent>
                            );
                        }
                    };

                    let valuePeriod;

                    if (periodicity === Periodicity.MONTHLY) {
                        valuePeriod = 1;
                    }
                    if (periodicity === Periodicity.BIMONTHLY) {
                        valuePeriod = 2;
                    }
                    if (periodicity === Periodicity.QUARTERLY) {
                        valuePeriod = 3;
                    }
                    if (periodicity === Periodicity.SEMESTERLY) {
                        valuePeriod = 6;
                    }

                    if (periodicity === Periodicity.YEARLY) {
                        columnsList.push(column);
                    } else {
                        const adjustedMonth = currentDate.month() + 1;

                        if (adjustedMonth % valuePeriod === 0) {
                            columnsList.push(column);
                        }
                    }
                }
            }
        }

        if (periodicity === Periodicity.YEARLY) {
            currentDate.add(1, 'years');
        } else {
            currentDate.add(1, 'months');
        }
    }

    if (reportConfig.modalityType === ModalityType.FORECAST) {
        const lastAccomplishedPeriodMoment = moment(lastPeriodRealized);

        if (lastPeriodRealized.length === 0) {
            const plannedPeriods = columnsList;

            const plannedColumn = {
                title: i18n.t<string>('planned'),
                width: 90,
                onHeaderCell: () => {
                    return {
                        style: {
                            textAlign: "center"
                        }
                    }
                },
                children: plannedPeriods
            };

            const editedColumnsList = [plannedColumn];

            columnsList = editedColumnsList;
        } else {
            const accomplishedPeriods = [];
            const plannedPeriods = [];

            for (const column of columnsList) {
                const columnMoment = moment(column.dataIndex);

                if (columnMoment.isSameOrBefore(lastAccomplishedPeriodMoment)) {
                    accomplishedPeriods.push(column);
                } else {
                    plannedPeriods.push(column);
                }
            }

            const accomplishedColumn = {
                title: i18n.t<string>('accomplished'),
                width: 90,
                onHeaderCell: () => {
                    return {
                        style: {
                            backgroundColor: "#004D7B",
                            textAlign: "center"
                        }
                    }
                },
                children: accomplishedPeriods
            };

            const plannedColumn = {
                title: i18n.t<string>('planned'),
                width: 90,
                onHeaderCell: () => {
                    return {
                        style: {
                            textAlign: "center"
                        }
                    }
                },
                children: plannedPeriods
            };

            const editedColumnsList = [];

            if (accomplishedPeriods.length > 0) {
                editedColumnsList.push(accomplishedColumn);
            }

            if (plannedPeriods.length > 0) {
                editedColumnsList.push(plannedColumn);
            }

            columnsList = editedColumnsList;
        }
    }

    const descriptionColumn = {
        title: i18n.t<string>('description'),
        dataIndex: 'description',
        key: 'description',
        fixed: 'left',
        width: 'auto',
        render: (text, record) => {
            try {
                let currentRow;
                if (record?.formulaValueType) {
                    currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
                } else if (record.levelKey) {
                    currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
                } else {
                    currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
                }

                let textDecoration = "";

                if (currentRow.rowStyle) {
                    if (currentRow.rowStyle.isScratched) {
                        textDecoration = textDecoration + "line-through "
                    }

                    if (currentRow.rowStyle.isUnderlined) {
                        textDecoration = textDecoration + "underline"
                    }
                }

                if (currentRow?.isTitle) {
                    return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
                }

                let textToRender = text ? text : i18n.t<string>(record.name) ? i18n.t<string>(record.name) : record.name;
                return (
                    <CellContent
                        currentRow={currentRow}
                        textDecoration={textDecoration}
                        customStyle={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        {textToRender}
                    </CellContent>
                );
            } catch (e) {
                console.log(e);
            }

        },
    };

    const totalValueColumn = {
        title: i18n.t<string>('total'),
        dataIndex: 'totalValue',
        key: 'total',
        fixed: 'right',
        width: 90,
        render: (text, record) => {
            let currentRow;
            if (record?.formulaValueType) {
                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isFragment && record.levelKey === columnFromBack.levelKey);
            } else if (record.levelKey) {
                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.levelId && columnFromBack.isDetailPlan && record.levelKey === columnFromBack.levelKey)
            } else {
                currentRow = rowsFromBackend.find(columnFromBack => columnFromBack.id === record.id);
            }

            let textDecoration = "";

            if (record.style) {
                if (record.style.isScratched) {
                    textDecoration = textDecoration + "line-through "
                }

                if (record.style.isUnderlined) {
                    textDecoration = textDecoration + "underline"
                }
            }

            if (currentRow?.isTitle) {
                return <CellContent currentRow={currentRow} textDecoration={textDecoration} />
            }

            let totalValue = (currentRow && currentRow.totalColumnsValues) ?
                currentRow.totalColumnsValues.totalValue : 0;

            if (reportVisualizationFilters.showThousand) {
                totalValue = totalValue / 1000;
            }

            return (
                <CellContent currentRow={currentRow} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={totalValue}
                        style={`decimal`}
                        currencyDisplay="symbol"
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    };

    if (reportConfig.modalityType === ModalityType.FORECAST) {
        formatColumnListForecast(periodicity, columnsList);
    } else {
        formatColumnList(periodicity, columnsList);
    }

    let newState: any[] = [descriptionColumn];

    if (!isConsolidation) {
        newState = [...newState, ...columnsList]
    }

    if (isConsolidation) {
        if (reportConfig.modalityType === ModalityType.COMPARATIVE_ACCOMPLISHED_PLANNED) {
            const columns = mapOrganizations(rowsFromBackend).map(org => ({
                title: org.description,
                dataIndex: org.id,
                children: buildColumnComparativeConsolidation(reportVisualizationFilters, rowsFromBackend, org.id),
            }));

            const comparativeTotalColumns = buildColumnComparativeTotalConsolidation(reportVisualizationFilters, rowsFromBackend);

            newState.push(...columns, ...comparativeTotalColumns);
        } else {
            const standardColumns = buildColumn(rowsFromBackend, reportVisualizationFilters);

            newState.push(...standardColumns);
        }
    } else if (reportConfig.modalityType === ModalityType.COMPARATIVE_ACCOMPLISHED_PLANNED) {
        const comparativeAdditionalColumns = buildColumnComparativeTotal(reportVisualizationFilters, rowsFromBackend);
        newState.push(...comparativeAdditionalColumns);
    } else if (reportConfig.modalityType === ModalityType.FORECAST) {
        const forecastAdditionalColumns = buildColumnForecastTotal(reportVisualizationFilters);
        newState.push(...forecastAdditionalColumns);
    } else if (reportConfig.modalityType === ModalityType.COMPARATIVE_SCENARIO_YEARS) {
        const comparativeScenarioYearsAdditionalColumns = buildColumnComparativeTotalScenarioYears(reportVisualizationFilters);
        newState.push(...comparativeScenarioYearsAdditionalColumns);
    } else {
        newState.push(totalValueColumn);
    }

    return newState;
}
