import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from "./useCachedQuery";
import { RequestType } from "util/service/IServiceCaller";
import { Options } from "util/types/types";

export function useCostCenterOptions(queryProps = {} as UseQueryOptions<Options[]>) {
    const queryInfo = useCachedQuery<Options[]>(
        ['cost-center-options'],
        {
            type: RequestType.GET,
            url: `/budget-base/cost-center?locale={locale}&client={client}&bu={businessUnit}&user={user}&organization={organization}`,
        },
        queryProps
    )
    
    return queryInfo;
}

