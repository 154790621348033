import { Key, useEffect, useState } from "react";
import i18n, { languages } from "util/base/i18n";
import { ColumnsType } from "antd/es/table";
import { DatePicker, Form, Input, Modal, Select, Table } from "antd";

import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { GenericSelection, ScenarioProjectionModalProps, SelectedBusinessUnit } from "../../IScenarioProjection";

import { isAfter, addYears, endOfYear } from 'date-fns';

import './ScenarioProjectionModalStyles.sass'
import { selectProps } from "util/props/props";

export function ScenarioProjectionModal({
  isModalVisible,
  isNewScenario,
  handleCancel,
  handleSubmit,
  form,
  businessUnitsTable,
  isLoading
}: ScenarioProjectionModalProps) {

  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SelectedBusinessUnit[]>([]);

  const [baseScenarioList, setBaseScenarioList] = useState<BaseScenarioPayloadType[]>([]);
  const [isFetchingBaseScenarios, setisFetchingBaseScenarios] = useState(true);

  const [indexersList, setIndexersList] = useState<GenericSelection[]>([]);
  const [isFetchingIndexers, setIsFetchingIndexers] = useState(true);

  const [filteredData, setFilteredData] = useState(tableData);
  const [searchText, setSearchText] = useState("");


  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const rowSelection =
    isNewScenario ? { selectedRowKeys, onChange: onSelectRowChange }
      : { selectedRowKeys, onChange: onSelectRowChange, getCheckboxProps: record => ({ disabled: true }) }

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = tableData.filter((record) =>
      record.desc.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    ServiceCaller.doRequest({
      type: RequestType.GET,
      url: "/projectionlease/scenario/indexer",
      useProxy: false,
    }, onLoadIndexer.bind(this));

    if (businessUnitsTable.scenarioId != null) {
      handleSelectBaseScenario(businessUnitsTable.scenarioId as any)
      setSelectedRowKeys(businessUnitsTable.businessUnits)
    }

    setTableData([])
    setFilteredData([])
    setSelectedRows([])
  }, [businessUnitsTable.scenarioId]);

  useEffect(() => {
    if (isModalVisible) {
      setisFetchingBaseScenarios(true)
      ServiceCaller.doRequest({
        type: RequestType.GET,
        url: "/projectionlease/scenario/baseScenarios",
        useProxy: false,
      }, onLoadBaseScenario.bind(this));
    }
  }, [isModalVisible])

  function handleSelectBaseScenario(value: { value: string; label: React.ReactNode }) {
    ServiceCaller.doRequest({
      type: RequestType.GET,
      url: `/projectionlease/scenario/businessUnit?scenarioId=${value}`,
      useProxy: false,
    }, onLoadBusinessUnit.bind(this));
  }

  function onLoadBaseScenario(data) {
    setBaseScenarioList(data);
    setisFetchingBaseScenarios(false)
  }

  function onLoadIndexer(data) {
    setIndexersList(data)
    setIsFetchingIndexers(false)
  }

  function onLoadBusinessUnit(data) {
    const dataToTableFormat = data.map(item => ({ key: item.id, desc: item.name }));
    setTableData(dataToTableFormat)
    setFilteredData(dataToTableFormat)
  }

  interface BaseScenarioPayloadType {
    id: number;
    label: string;
  }

  function onSelectRowChange(selectedRowKeys: Key[], selectedRows: SelectedBusinessUnit[]) {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const modalTitle = isNewScenario ? i18n.t<string>('new_scenario') : i18n.t<string>('edit_scenario');

  const tableColumns: ColumnsType<GenericSelection> = [
    {
      title: i18n.t<string>('description'),
      dataIndex: "desc",
      key: "desc",
      align: "left",
      width: 300,
      sorter: (a, b) => {
        return a.desc.localeCompare(b.desc);
      }
    },
  ];

  return (
    <Modal
      width={1000}
      title={modalTitle}
      visible={isModalVisible}
      onCancel={handleCancel}
      className="gs-modal scenarios-modal"
      cancelText={i18n.t<string>("cancel")}
      okText={i18n.t<string>("save")}
      maskClosable={false}
      okButtonProps={{
        htmlType: "submit",
        form: "new-scenario-projection-form",
        disabled: isSubmitDisabled
      }}
      confirmLoading={isLoading}
    >
      <Form
        onFieldsChange={() =>
          setIsSubmitDisabled(
            form.getFieldsError().some((field) => field.errors.length > 0)
          )
        }
        form={form}
        layout="vertical"
        name="new-scenario-projection-form"
        className={isNewScenario ? "form-new-scenario" : ""}
        onFinish={(data) => {
          data.businessUnitsIds = selectedRowKeys;
          handleSubmit(data)
        }}
      >
        <div className="fields-container">
          <div className="input-content">
            <Form.Item
              name="description"
              label={i18n.t<string>("description")}
              rules={[{ required: true, message: i18n.t<string>("required_field") }]}
            >
              <Input id="input-template-detail" />
            </Form.Item>
          </div>
          <div className="input-content">
            <Form.Item
              name="externalCode"
              label={i18n.t<string>("external_code")}
              rules={[{ required: true, message: i18n.t<string>("required_field") }]}
            >
              <Input id="input-template-detail" />
            </Form.Item>
          </div>
          <div className="input-content">
            <Form.Item
              name="scenarioBase"
              label={i18n.t<string>("projection_lease.base_scenario")}
              rules={[{ required: isNewScenario, message: i18n.t<string>("required_field") }]}
            >
              <Select
                disabled={!isNewScenario}
                options={baseScenarioList}
                placeholder={isFetchingBaseScenarios ? i18n.t<string>("loading") : i18n.t<string>("select")}
                onChange={handleSelectBaseScenario}
              />
            </Form.Item>
          </div>
        </div>
        <div className="fields-container">
          <div className="input-content">
            <Form.Item
              name="baseDate"
              label={i18n.t<string>("projection_lease.origin_base_date")}
              dependencies={['accountingDate', 'projectionDate']}
              rules={[
                { required: isNewScenario, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    const baseDate = new Date(value).setHours(0, 0, 0, 0)
                    const accountingDate = new Date(form.getFieldValue("accountingDate")).setHours(0, 0, 0, 0)
                    const projectionDate = new Date(form.getFieldValue("projectionDate")).setHours(0, 0, 0, 0)

                    if (baseDate === accountingDate)
                      return Promise.reject(i18n.t<string>('scenario_projection_validation.base_date_vs_accounting_date'))

                    if (baseDate === projectionDate)
                      return Promise.reject(i18n.t<string>('scenario_projection_validation.base_date_vs_projection_date'))

                    return Promise.resolve()
                  }
                }
              ]}
            >
              <DatePicker picker={'month'} disabled={!isNewScenario} locale={languages[i18n.language]} format={'MM/YYYY'} />
            </Form.Item>
          </div>
          <div className="input-content">
            <Form.Item
              name="accountingDate"
              label={i18n.t<string>("projection_lease.accounting_date")}
              dependencies={['baseDate', 'projectionDate']}
              rules={[
                { required: isNewScenario, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    const accountingDate = new Date(value).setHours(0, 0, 0, 0)
                    const baseDate = new Date(form.getFieldValue("baseDate")).setHours(0, 0, 0, 0)
                    const projectionDate = new Date(form.getFieldValue("projectionDate")).setHours(0, 0, 0, 0)

                    if (baseDate && accountingDate && isAfter(baseDate, accountingDate)) {
                      return Promise.reject(i18n.t<string>('scenario_projection_validation.accounting_date_vs_origin_base_date'))
                    }

                    if (projectionDate && accountingDate && isAfter(accountingDate, projectionDate)) {
                      return Promise.reject(i18n.t<string>('scenario_projection_validation.projection_date_vs_accounting_date'))
                    }

                    return Promise.resolve()
                  }
                }
              ]}
            >
              <DatePicker picker={'month'} disabled={!isNewScenario} locale={languages[i18n.language]} format={'MM/YYYY'} />
            </Form.Item>
          </div>
          <div className="input-content">
            <Form.Item
              name="projectionDate"
              label={i18n.t<string>("projection_lease.projection_date")}
              dependencies={['baseDate', 'accountingDate']}
              rules={[
                { required: isNewScenario, message: i18n.t<string>("required_field") },
                {
                  validator: (_, value) => {
                    const baseDate = new Date(form.getFieldValue("baseDate")).setHours(0, 0, 0, 0);
                    const accountingDate = new Date(form.getFieldValue("accountingDate")).setHours(0, 0, 0, 0);
                    const projectionDate = new Date(value).setHours(0, 0, 0, 0)

                    if (baseDate && projectionDate && isAfter(projectionDate, endOfYear(addYears(baseDate, 3))))
                      return Promise.reject(i18n.t<string>('scenario_projection_validation.date_difference_limit'))

                    if (accountingDate && projectionDate && isAfter(accountingDate, projectionDate))
                      return Promise.reject(i18n.t<string>('scenario_projection_validation.accounting_date_vs_projection_date'))

                    return Promise.resolve()
                  },
                },

              ]}
            >
              <DatePicker picker={'month'} disabled={!isNewScenario} locale={languages[i18n.language]} format={'MM/YYYY'} />
            </Form.Item>
          </div>
        </div>
        <div className="fields-container">
          <div className="input-content input-indexer">
            <Form.Item
              name="indexer"
              label={i18n.t<string>("indexers")}
              rules={[{ required: isNewScenario, message: i18n.t<string>("required_field") }]}
            >
              <Select
                disabled={!isNewScenario}
                loading={isFetchingIndexers}
                maxTagCount="responsive"
                options={indexersList}
                placeholder={isFetchingIndexers ? i18n.t<string>("loading") : i18n.t<string>("select")}
                {...selectProps}
              />
            </Form.Item>
          </div>
        </div>
        <div className="scenario-modal-table-container">
          <Input.Search
            className="search-business-unit"
            placeholder={i18n.t<string>('search')}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Table
            className="gs-table"
            dataSource={filteredData.sort((a, b) => {
              if (rowSelection.selectedRowKeys.includes(a.key))
                return -1;

              if (rowSelection.selectedRowKeys.includes(b.key))
                return 1;

              return 0;
            })}
            columns={tableColumns}
            rowSelection={rowSelection}
            pagination={{ hideOnSinglePage: true }}
            locale={{
              emptyText:
                <div>
                  {i18n.t<string>("projection_lease.select_base_scenario")}
                </div>
            }}
            bordered
          />
        </div>
      </Form>
    </Modal >
  );
}