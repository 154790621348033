import moment from 'moment';
import { Table } from "antd";
import { Column, TableData } from './IDistributionModal';
import i18n from 'util/base/i18n';
import { useWindowSize } from 'hooks/useWindowSize';

export function ResultSection({ result }) {
    const windowSize = useWindowSize()

    const columns: Column[] = [
        {
            title: i18n.t("months"),
            dataIndex: 'month',
            className: 'month-column',
            key: 'month',
            render: (text) => {
                const titleMonthCell = moment(text).format('MMM/YYYY').charAt(0).toUpperCase()
                    + moment(text).format('MMM/YYYY').slice(1)
                return titleMonthCell
            }
        },
        {
            title: i18n.t("fixed_expense_texts.amount_applied"),
            align: 'center',
            dataIndex: 'result',
            key: 'result',
            render: (_, record: TableData) => {
                return record.value === 0 ? '-' : `R$ ${record.value.toFixed(2)}`
            }
        },
    ]

    return (
        <div style={{ margin: '0 24px 24px' }}>
            <p style={{ margin: '10px 0 5px', fontWeight: 500 }}>{i18n.t("fixed_expense_texts.final_result")}</p>
            <Table
                className="gs-table"
                columns={columns}
                dataSource={result}
                bordered
                scroll={{ y: windowSize.height - 250 }}
                pagination={{ hideOnSinglePage: true, pageSize: 100 }}
                summary={(data: readonly TableData[]) => createGridSummary(data)}
            />
        </div>
    )
}

function createGridSummary(data: readonly TableData[]) {
    const total = data.reduce((acc, row) => {
        acc = acc + row.value
        return acc
    }, 0)

    return (
        <Table.Summary fixed>
            <Table.Summary.Row style={{ background: '#FBE6E6' }}>
                <Table.Summary.Cell index={0} align="left">{i18n.t("total")}</Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center">
                    {total.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    })}
                </Table.Summary.Cell>
            </Table.Summary.Row>
        </Table.Summary>
    )
}