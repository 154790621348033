import { Icon } from "@iconify/react";
import { Button, Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { IFilterContainer, RevenueFilterFlexField, RevenueFilterOrder, RevenueFlexField, RevenueFlexFieldItem } from "../IRevenue";

import 'module/budget/pages/revenue/mainFlow/Revenue.sass';
import { ErrorRequest } from "util/types/types";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import i18n from "util/base/i18n";
import { RevenueContext } from "../RevenueContext";
import { Filter } from "components/filter/Filter";

export function FilterContainer({
    flexFields, setFlexFields,
    itensOrdened,
    currencyId,
    measuringUnitId,
}: IFilterContainer) {
    const [leftProp, setLeftProp] = useState<number>(0);
    const [callbackFooterWithSearchInputValue, setCallbackFooterWithSearchInputValue] = useState(null);
    const maxLeftRange: number = 250 * (flexFields.length - 6);

    const { openedCalendarPeriod, handleIsFetching, handleHasChangeOnGrid, hasChangeOnGrid, period, handleChangePeriod, handleUpdateFiltersFlexField, flexFieldsSelected } = useContext(RevenueContext);

    useEffect(() => {
        if (callbackFooterWithSearchInputValue?.value) {
            ServiceCaller.doRequest({
                type: RequestType.POST,
                url: `/budget-base/flex-field-value/simple-save`,
                params: {
                    flexFieldId: callbackFooterWithSearchInputValue.footerProps.key,
                    description: callbackFooterWithSearchInputValue.value
                }
            }, handleNewFlexFieldValue.bind(this), (err: ErrorRequest) => {
                handleErrorRequest(err);
                setCallbackFooterWithSearchInputValue(null);
            })
        }
    }, [callbackFooterWithSearchInputValue]);

    const loadRevenueTable = () => {
        handleIsFetching(true);
        handleUpdateFiltersFlexField(true);
        handleHasChangeOnGrid(!hasChangeOnGrid);
    };

    const handleNewFlexFieldValue = (data) => {
        let newFlexFieldValue = {
            checked: true,
            id: data.id,
            label: data.description,
            value: data.description
        }

        let flexFieldsNew = flexFields;
        flexFieldsNew.forEach((flex) => {
            if (flex.key === data.flexFieldId) {
                flex.itens.push(newFlexFieldValue);
                flex.description.push(data.description);

                if (data.description === callbackFooterWithSearchInputValue.value) {
                    flex.listKeys.push(data.description);
                }
            }
        })

        setFlexFields(flexFieldsNew);
        checkItem(callbackFooterWithSearchInputValue.footerProps.itens, callbackFooterWithSearchInputValue.footerProps.title, false);
        setCallbackFooterWithSearchInputValue(null);
    }

    function handleScrollFilter(direction: 'left' | 'right') {
        switch (direction) {
            case 'right':
                setLeftProp(prevState => prevState - 660)
                break
            case 'left':
                setLeftProp(0)
                break
        }
    };

    function checkItem(data: RevenueFlexFieldItem[], title: string, updateGrid: boolean) {
        let flex: RevenueFilterFlexField = {
            flexFieldId: 0,
            flexFieldValueIds: [],
            measuringUnitId,
            currencyId
        };

        const fields = flexFields.filter((f) => {
            if (f.title === title) {
                return f.itens;
            }
        });

        const fieldItensToCompareBeforeChange: RevenueFlexField[] = JSON.parse(JSON.stringify(fields));

        flexFields.forEach(element => {
            if (element.title === title) {
                element.itens.forEach((field) => {
                    flex.flexFieldId = element.key;
                    if (data.find((fieldSelected) => fieldSelected.id === field.id)) {
                        flex.flexFieldValueIds.push(field.id);
                        field.checked = true;
                    } else {
                        field.checked = false;
                    }
                })
            }
        });

        const fieldItensToCompareAfterChange = flexFields.filter((f) => {
            if (f.title === title) {
                return f.itens;
            }
        });

        let hasDifference = fieldItensToCompareBeforeChange.some((b, index) =>
            b.itens.some((b2, innerIndex) =>
                b2.checked !== fieldItensToCompareAfterChange[index].itens[innerIndex].checked
            )
        );


        if (!hasDifference) return;
        if (flex.flexFieldId === 0 && !flex.flexFieldValueIds) return;
        if (updateGrid) {
            saveFlexFieldOptions(flex);
        }
    }

    function saveFlexFieldOptions(flex: RevenueFilterFlexField) {
		if (flexFieldsSelected.some(({flexFieldId: selectedFlexFieldId}) => selectedFlexFieldId == flex.flexFieldId)) {
			flexFieldsSelected.find(selectedField => selectedField.flexFieldId == flex.flexFieldId).flexFieldValuesId = flex.flexFieldValueIds;
		} else {
            const ordenedItem: RevenueFilterOrder = itensOrdened.find(({id}) => id === flex.flexFieldId);

			flexFieldsSelected.push({
				flexFieldId: flex.flexFieldId,
                originalOrder: ordenedItem.originalOrder,
				order: ordenedItem.order,
				flexFieldValuesId: flex.flexFieldValueIds
			});
		}

        loadRevenueTable();
        setCallbackFooterWithSearchInputValue(null);
    }

    return (
        <div className="filters-container">
            <div className="filters-content"
                style={{ width: "100%" }}>
                <div style={{ width: 120 * flexFields.length }}>
                    {leftProp < 0 &&
                        <Button
                            type="primary"
                            icon={<Icon icon="material-symbols:arrow-forward-ios-rounded" />}
                            className="scroll-button back"
                            onClick={() => handleScrollFilter('left')}
                        />}
                    <Row
                        wrap={false}
                        align="middle"
                        gutter={[0, 30]}
                        style={{
                            left: leftProp
                        }}>
                        <Col className="revenue-period-filter-card">
                            <Filter
                                id="period-filter"
                                value={period}
                                setValue={handleChangePeriod}
                                title={i18n.t<string>("period")}
                                type="datepicker"
                                link='/'
                                data={[]}
                                datepicker={{
                                    format: "MM/YYYY",
                                    picker: "month",
                                    rangePicker: true,
                                    period: openedCalendarPeriod
                                }}
                                buttonStyles={{ cursor: 'default' }}
                            />
                        </Col>
                        {flexFields.map(flexField => (
                            <Col key={flexField.title}>
                                {flexField.itens && (
                                    <>
                                        <Filter
                                            key={flexField.title}
                                            id={"filter-container"}
                                            data={flexField.itens}
                                            title={flexField.title}
                                            type={"checkbox"}
                                            searchPlaceholder={i18n.t<string>("search_by") + " " + flexField.title}
                                            value={flexField.listActives}
                                            setValue={(value) => checkItem(value, flexField.title, true)}
                                            hasSearch={true}
                                            footer={{
                                                iconFooter: "mdi:plus-circle",
                                                titleFooter: i18n.t<string>("add_new") + " " + flexField.title,
                                                style: {
                                                    paddingRight: 50
                                                },
                                                setCallbackFooterWithSearchInputValue: setCallbackFooterWithSearchInputValue,
                                                callbackFooterWithSearchInputValue: callbackFooterWithSearchInputValue,
                                                footerValue: flexField
                                            }}
                                            defaultCheckboxSelected={flexField.listActives}
                                        />
                                    </>
                                )}
                            </Col >
                        ))}
                    </Row>
                    {leftProp > -maxLeftRange && flexFields.length > 6 ?
                        <Button
                            type="primary"
                            icon={<Icon icon="material-symbols:arrow-forward-ios-rounded" />}
                            className="scroll-button next"
                            onClick={() => handleScrollFilter('right')}
                        />
                        : null
                    }
                </div>
            </div >
        </div>
    )
}

export default FilterContainer;