import { cloneDeep } from "lodash";
import i18n from "util/base/i18n";
import { generateFlexFieldKey } from "util/functions/generateFlexFieldKey";
import { queryClient } from "util/queryClient";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { FlexFilter, FlexFilterOptions } from "util/types/types";

function resetChildFieldsValues(flexFields: FlexFilter[], id: number | string, clearValues: boolean) {
    flexFields.forEach(field => {
        if (field.linkedFilters && field.linkedFilters.includes(id.toString())) {
            if (clearValues) {
                field.children = [];
            }
            field.selectedOption = [{ label: i18n.t("no_option"), value: 0 }];
            field.isFetching = false;
            resetChildFieldsValues(flexFields, field.value, true);
        }
    });
}

export async function onChangeFlexFieldFilter(
    ffParentValue: number,
    ffParentId: number | string,
    flexFieldsFilters: FlexFilter[],
    setFlexFieldsFilters,
    businessUnitId,
    costCenterId,
    accountAccountingId
) {
    if (ffParentValue === undefined) return;

    const updatedFlexField = cloneDeep(flexFieldsFilters);

    if (typeof ffParentId === 'number') {
        const updatedFlex = updatedFlexField.find(flex => flex.value === ffParentId);

        if (updatedFlex.selectedOption.length > 0 && updatedFlex.selectedOption[0].value === ffParentValue) return;

        if (ffParentValue !== 0) {
            const value = updatedFlex.children.find(selValue => selValue.value === ffParentValue);
            updatedFlex.selectedOption = [value];
        } else {
            updatedFlex.selectedOption = [{ label: i18n.t("no_option"), value: 0 }];
        }
    }

    const dependentFlexFieldFilters = updatedFlexField.filter(flex => flex.linkedFilters && flex.linkedFilters.includes(ffParentId.toString()));

    if (dependentFlexFieldFilters.length > 0) {
        for (const flexField of dependentFlexFieldFilters) {
            flexField.selectedOption = [];
            flexField.isFetching = ffParentValue !== 0 ? true : false;
            if (typeof ffParentId === 'string' && flexField.linkedFilters && flexField.linkedFilters.includes(ffParentId)) {
                await fetchChildFilterValues(flexField.value, ffParentValue, businessUnitId, costCenterId, accountAccountingId, updatedFlexField, ffParentId);
            }
        };
        
        setFlexFieldsFilters(updatedFlexField);
        
        if (ffParentValue !== 0 && typeof ffParentId === 'number') {
            await fetchChildFilterValues(ffParentId, ffParentValue, businessUnitId, costCenterId, accountAccountingId, updatedFlexField);
            const flexStringKey = generateFlexFieldKey(updatedFlexField);
            setFlexFieldsFilters(cloneDeep(updatedFlexField));
            return flexStringKey;
        } else {
            const flexStringKey = generateFlexFieldKey(updatedFlexField);
            
            setFlexFieldsFilters(cloneDeep(updatedFlexField));
            return flexStringKey;
        }
    } else {
        setFlexFieldsFilters(updatedFlexField);
        const flexStringKey = generateFlexFieldKey(updatedFlexField);
        return flexStringKey;
    }
}

async function fetchChildFilterValues(ffParentId, ffParentValue, businessUnitId, costCenterId, accountAccountingId, updatedFlexField, filter = '') {
    const correctId = filter.length === 0 ? ffParentId.toString() : filter

    const url = filter.length === 0 
        ? `/budget-base/flex-field-value/get-all-by-filters?user={user}&flexFieldId=${ffParentId}&flexFieldValueId=${ffParentValue}`
        : `/budget-base/flex-field-value?user={user}&flexFieldId=${ffParentId}`;

    let childFilterValues: any  = queryClient.getQueryData(['flex-values-by-parent-value', ffParentId.toString(), ffParentValue.toString()], {});

    if (!childFilterValues) {
        childFilterValues = await ServiceCaller.doAsyncRequest({
            type: RequestType.GET,
            url: url,
        });

        queryClient.setQueryData(['flex-values-by-parent-value', ffParentId.toString(), ffParentValue.toString()], childFilterValues, {});
    }

    const formatedValues: FlexFilterOptions[] = childFilterValues.data.map(item => ({
        label: item.externalCode + ' - ' + item.description,
        value: item.id,
        flexFieldId: item.flexFieldId,
        linkedFilterIds: item.flexFieldFilters,
        linkedBusinessUnits: item.businessUnitFilters,
        linkedAccounts: item.accountingAccountFilters,
        linkedCostCenters: item.costCenterFilters,
    }));
    
    updatedFlexField.forEach(flexField => {
        if (flexField.linkedFilters && flexField.linkedFilters.includes(correctId)) {
            
            flexField.children = formatedValues
                .filter(item => item.linkedAccounts ? item.linkedAccounts.includes(accountAccountingId) : true)
                .filter(item => item.linkedCostCenters ? item.linkedCostCenters.includes(costCenterId) : true)
                .filter(item => item.linkedBusinessUnits ? item.linkedBusinessUnits.includes(businessUnitId) : true)
                .filter(item => item.flexFieldId === flexField.value);

            resetChildFieldsValues(updatedFlexField, correctId, false);
        }
    });
}