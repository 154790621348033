import { SettingFilled } from "@ant-design/icons";
import { Button, Col, Form, Popover, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Notification } from "components/notification/Notification";
import { useEffect, useState } from "react";
import { Options } from "util/types/types";
import { selectProps } from "util/props/props";

export default function SceneSetup({
	integrationSelected,
}) {
	const [scenarioData, setScenarioData] = useState<Options[]>([]);
	const [isScenarioLoading, setIsScenarioLoading] = useState(true);
	const [scenarioSelected, setScenarioSelected] = useState<number>();
	const [isOpenSetup, setIsOpenSetup] = useState(false);
	const [form] = Form.useForm();
	function closePopover() {
		setIsOpenSetup(false);
		form.resetFields();
	}
	const handleCancel = () => {
		closePopover();
	}
	const onSaveConfiguration = () => {
		closePopover();
		Notification({
			message: i18n.t("configuration_saved_successfully"),
			type: "success"
		})
	}
	const handleSubmit = (values: { scenario: number }) => {
		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: `/budget-base/budget-integration/save-standard-configuration?scenarioId=${values.scenario}`,
			params: {}
		}, onSaveConfiguration)
	}
	const scenarioSetupContent: JSX.Element = (
		<div className="scenario-setup-content">
			<Form
				form={form}
				name="configuration-setup"
				onFinish={handleSubmit}
				layout="vertical"
			>
				<Row>

					<FormItem
						name={"scenario"}
						label={`${i18n.t("scenario")} ${i18n.t("destination")}`}
						rules={[{ required: true, message: i18n.t<string>("required_field"), }]}
						requiredMark={false}
					>
						<Select

							value={scenarioSelected}
							className="gs-select"
							options={scenarioData}
							onChange={setScenarioSelected}
							loading={isScenarioLoading}
							maxTagCount="responsive"
							{...selectProps}
						/>
					</FormItem>
				</Row>
				<Row className="configuration-setup-footer">
					<Col onClick={handleCancel}>
						<Button type="text">
							{i18n.t("close")}
						</Button>
					</Col>
					<Col>
						<Button form="configuration-setup" htmlType="submit" className="gs-main-button">
							{i18n.t("save")}
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	)
	function onLoadScenario(data: any[]) {
		const updatedSelect = data.map(scenario => {
			return {
				value: scenario.id,
				label: scenario.name,
			}
		})
		setScenarioData(updatedSelect);
	}
	function onLoadStandardScenario(id: number) {
		form.setFieldValue("scenario", id);
		setIsScenarioLoading(false)
	}
	function onOpenChange(open: boolean) {
		if (open) {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/budget-integration/find-standard-scenario`,
			}, onLoadStandardScenario);
			setIsOpenSetup(open)
		} else {
			closePopover();
		}

	}
	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/monolith/scenario?locale={locale}&client={client}',
		}, 
		onLoadScenario
		)
	}, [])

	return (
		<div>
			{!integrationSelected &&
				<Popover
					trigger={"click"}
					onOpenChange={onOpenChange}
					title={i18n.t("scenario_setup")}
					content={scenarioSetupContent}
					overlayClassName="popover-scenario-setup"
					destroyTooltipOnHide
					open={isOpenSetup}
				>
					<Col className="scenario-setup-button">

						<SettingFilled
							onClick={() => setIsOpenSetup(true)}
							title={i18n.t("scenario_setup")}
						/>
					</Col>
				</Popover>
			}
		</div>
	)
}