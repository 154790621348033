import i18n, { languages } from "util/base/i18n";
import { DatePicker, Form, Modal, Radio } from "antd";
import { ContractSimulationModalProps } from "../../IContractSimulation";

import './TerminateModalStyles.sass'

export function TerminateModal({
  isModalVisible,
  handleCancel,
  handleSubmit,
  form,
  isLoading
}: ContractSimulationModalProps) {

  return (
    <Modal
      width={435}
      title={i18n.t<string>('terminate')}
      visible={isModalVisible}
      okButtonProps={{ htmlType: "submit", form: "terminate-form" }}
      onCancel={handleCancel}
      className="gs-modal terminate-modal"
      cancelText={i18n.t<string>("cancel")}
      okText={i18n.t<string>("save")}
      maskClosable={false}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        name="terminate-form"
        layout="vertical"
        className={"form-terminate"}
        onFinish={(data) => { handleSubmit(data) }}
      >
        <div className="fields-container">
          <div className="input-content">
            <Form.Item
              name="baseDate"
              label={i18n.t<string>('start_date')}
              rules={[{ required: true, message: i18n.t<string>("required_field") }]}
            >
              <DatePicker locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
            </Form.Item>
          </div>
        </div>
        <div className="radio-pair-container">
          <Form.Item name={"withoutPaying"}>
            <Radio.Group>
              <Radio value={1}>{i18n.t<string>('contract_simulation.terminate_without_paying')}</Radio>
              <Radio value={2}>{i18n.t<string>('contract_simulation.terminate_paying')}</Radio>
              <Radio value={3}>{i18n.t<string>('contract_simulation.operational_cost')}</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Form>
    </Modal >
  );
}