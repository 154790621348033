import { Icon } from "@iconify/react";
import { Input, Radio, Spin, Tree } from "antd";
import { Key, useState } from "react";
import i18n from "util/base/i18n";
import { ISidebarContent, LevelItem } from "../IAccountingBalances";
const { Search } = Input;

export default function SidebarContent({
    treeData,
    checkedKeys,
    setCheckedKeys,
    expandedKeys,
    setExpandedKeys,
    isFetching,
}: ISidebarContent) {
    const [search, setSearch] = useState<string>("");

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setSearch(value);
    }

    function onSelected(selectedKeys: Key[]) {
        if (selectedKeys.length > 0) {
            setCheckedKeys([]);
            setCheckedKeys([selectedKeys[0] as number]);
        }
    }

    function onExpand(expandedKeys: number[]) {
        setExpandedKeys(expandedKeys);
    }

    function chooseIconExpanded(props: { expanded: boolean }) {
        return props.expanded ? <Icon icon="akar-icons:minus" /> : <Icon icon="akar-icons:plus" />;
    }

    function addButtonLines(line: LevelItem): JSX.Element {
        return (
            <Radio value={line.key} checked={!!checkedKeys.find((value) => {
                return value === line.key
            })}>
                {line.title}
            </Radio>
        );
    }
    function buildTreeData(data: LevelItem[], search: string): LevelItem[] {
        if (search.length === 0) return data;
        return [
            ...data
                .map((line) => {
                    if (line.children) {
                        line.children = buildTreeData(line.children, search);

                        if (line.children.filter((child) => child).length === 0) {
                            if (!line.title.toLowerCase().includes(search)) {
                                line = undefined;
                            }
                        }
                    } else {
                        if (!line.title.toLowerCase().includes(search)) {
                            line = undefined;
                        }
                    }

                    return line;
                })
                .filter((child) => child),
        ];
    }

    return (
        <div className="sider-content">
            <Search style={{ marginBottom: 8 }} placeholder={i18n.t("search")} onChange={onChange} />
            <Spin spinning={isFetching}>
                {treeData ? (
                    <Tree
                        checkable={false}
                        switcherIcon={chooseIconExpanded}
                        treeData={buildTreeData(JSON.parse(JSON.stringify(treeData)), search.toLowerCase())}
                        showLine={{ showLeafIcon: false }}
                        titleRender={addButtonLines}
                        disabled={isFetching}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        selectable={true}
                        selectedKeys={checkedKeys}
                        onSelect={onSelected}
                    />
                ) : (
                    <div className="sider-content">
                        <Icon icon="clarity:tree-view-line" />
                        {i18n.t("groupings_texts.no_registered_level")}
                    </div>
                )}
            </Spin>
        </div>
    );
}
