import { Layout, Table, Spin, Input, Checkbox, Select } from "antd";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import type { ColumnsType } from 'antd/es/table';
import { IPermissionSecurityContent, PermissionSecurityLevel, TableData, PermissionSecurityData, MenuKey } from "../IPermissionSecurity";
import { Icon } from "@iconify/react";
import { selectProps } from "util/props/props";
import { ImageBox } from "components/imageBox/ImageBox";
import SalesHierarchyTable from "./SalesHierarchyTable";
import ContractTypeTable from "./ContractTypeTable";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";

const { Header, Content } = Layout;
const { Search } = Input;

export function PermissionSecurityContent({
	selectedPermissionItem,
	selectedUser,
	permissionData,
	isFetchingFlexFields,
	isFlexFields,
	tableData,
	isFetching,
	setTableData,
	permissionsCostCenter,
	selectedMenuKey,
	functionalityPermissions,
	onChangeSelect,
	organizationOptions,
	selectedTab,
	isCostCenterLinked
}: IPermissionSecurityContent) {
	const isFlexFieldPermissions = selectedPermissionItem?.url?.includes('flex-field');
	const [searchPermissions, setSearchPermissions] = useState("");
	const [checkedAllEdit, setCheckedAllEdit] = useState(false);
	const [checkedAllView, setCheckedAllView] = useState(false);
	const [checkedAllBlocked, setCheckedAllBlocked] = useState(false);

	const importAccount: IImportModal[] = [
        {
            importUrl: `/budget-base/account-security/import-${selectedTab}-security`,
            templateUrl: `/budget-base/account-security/template-${selectedTab}-security?locale={locale}&clientId={client}`,
            type: "excel",
            title: i18n.t<string>("import"),
        },
    ];

	const importCostCenter: IImportModal[] = [{
		importUrl: `/budget-base/cost-center-security/import-${selectedTab}-security`,
		templateUrl: `/budget-base/cost-center-security/template-${selectedTab}-security?locale={locale}`,
		type: "excel",
		title: i18n.t<string>("import")
	}];

	const importSecurity: IImportModal[] = [
        {
            importUrl: `/budget-base/flex-field-security/import-${selectedTab}-security`,
            templateUrl: `/budget-base/flex-field-security/template-${selectedTab}-security?locale={locale}`,
            type: "excel",
            title: i18n.t<string>("import"),
        },
    ];

	useEffect(() => {
		const updatedTableData = permissionData.map(permission => {
			return {
				...permission,
				key: permission.itemId,
			}
		})
		setTableData(updatedTableData);

		const updatedCheckedViewAll = permissionData.every(item => (item.permissionSecurityLevel !== PermissionSecurityLevel.Blocked && item.permissionSecurityLevel !== null))
		const updatedCheckedEditAll = permissionData.every(item => item.permissionSecurityLevel === PermissionSecurityLevel.Edit)
		const updatedCheckedBlockedAll = permissionData.every(item => item.permissionSecurityLevel === PermissionSecurityLevel.Blocked)
		setCheckedAllView(updatedCheckedViewAll)
		setCheckedAllEdit(updatedCheckedEditAll)
		setCheckedAllBlocked(updatedCheckedBlockedAll)
	}, [permissionData]);

	function onCanViewChange(record: TableData, checked: boolean) {
		const newData = [...tableData];
		const target = newData.find((item) => item.key === record.key);
		const permissionLevel = checked ? PermissionSecurityLevel.View : null
		target.permissionSecurityLevel = permissionLevel;

		setTableData(newData);
	};

	function onCanViewAllChange(checked: boolean) {
		if (checkedAllEdit) return
		const newData = [...tableData];
		const permissionLevel = checked ? PermissionSecurityLevel.View : null
		newData.forEach((item) => {
			item.permissionSecurityLevel = item.permissionSecurityLevel === 'EDIT' ? PermissionSecurityLevel.Edit : permissionLevel;
		});
		setCheckedAllView(checked)
		setCheckedAllBlocked(false);
		setTableData(newData);
	};

	function onCanEditChange(record: any, checked: boolean) {
		const newData = [...tableData];
		const target = newData.find((item) => item.key === record.key);
		const permissionLevel = checked ? PermissionSecurityLevel.Edit : null
		target.permissionSecurityLevel = permissionLevel;

		setTableData(newData);
	};

	function onCanEditAllChange(checked: boolean) {
		const newData = [...tableData];
		const permissionLevel = checked ? PermissionSecurityLevel.Edit : null
		newData.forEach((item) => {
			item.permissionSecurityLevel = permissionLevel;
		});

		setCheckedAllView(checked);
		setCheckedAllEdit(checked);
		setCheckedAllBlocked(false);
		setTableData(newData);
	};

	function onBlockedChange(record: any, checked: boolean) {
		const newData = [...tableData];
		const target = newData.find((item) => item.key === record.key);
		const permissionLevel = checked ? PermissionSecurityLevel.Blocked : null
		target.permissionSecurityLevel = permissionLevel;

		setTableData(newData);
	};

	function onBlockedAllChange(checked: boolean) {
		const newData = [...tableData];
		const permissionLevel = checked ? PermissionSecurityLevel.Blocked : null
		newData.forEach((item) => {
			item.permissionSecurityLevel = permissionLevel;
		});

		setCheckedAllBlocked(checked);
		setCheckedAllView(false);
		setCheckedAllEdit(false);
		setTableData(newData);
	};


	const columns: ColumnsType<PermissionSecurityData & { key: string; }> = [
		{
			title: '',
			dataIndex: 'description',
			width: selectedMenuKey === MenuKey.ACCOUNT ? '60%' : '80%'
		},
		...(selectedMenuKey === MenuKey.ACCOUNT ?
			[
				{
					title:
						<div className="title-colunm-checkbox">
							<Checkbox disabled={!functionalityPermissions.edit} checked={checkedAllBlocked} onChange={(e) => onBlockedAllChange(e.target.checked)} />
							<span>{i18n.t("block")}</span>
						</div>,
					dataIndex: "permissionSecurityLevel",
					key: "blocked",
					width: 180,
					render: (value, record: TableData) => {
						const isChecked = value === 'BLOCKED'
						return <Checkbox disabled={!functionalityPermissions.edit} checked={isChecked} onChange={(e) => onBlockedChange(record, e.target.checked)} />
					},
				},
				{
					title:
						<div className="title-colunm-checkbox">
							<Checkbox disabled={!functionalityPermissions.edit} checked={checkedAllView} onChange={(e) => onCanViewAllChange(e.target.checked)} />
							<span>{i18n.t("view")}</span>
						</div>,
					dataIndex: "permissionSecurityLevel",
					key: "canView",
					width: 180,
					render: (value, record: TableData) => {
						const isChecked = value === 'EDIT' || value === 'VIEW'
						return <Checkbox disabled={!functionalityPermissions.edit} checked={isChecked} onChange={(e) => onCanViewChange(record, e.target.checked)} />
					},
				},
				{
					title:
						<div className="title-colunm-checkbox">
							<Checkbox disabled={!functionalityPermissions.edit} checked={checkedAllEdit} onChange={(e) => onCanEditAllChange(e.target.checked)} />
							<span>{i18n.t("edit")}</span>
						</div>
					,
					dataIndex: "permissionSecurityLevel",
					key: "canEdit",
					width: 180,
					render: (value, record) => {
						const isChecked = value === 'EDIT'
						return <Checkbox disabled={!functionalityPermissions.edit} checked={isChecked} onChange={(e) => onCanEditChange(record, e.target.checked)} />
					},
				},
			] :
			[
				(selectedMenuKey === MenuKey.COSTCENTER || selectedMenuKey === MenuKey.FISCALYEAR || isFlexFieldPermissions ?
					{
						title:
							<div className="title-colunm-checkbox">
								<Checkbox disabled={!functionalityPermissions.edit} checked={checkedAllBlocked} onChange={(e) => onBlockedAllChange(e.target.checked)} />
								<span>{i18n.t("block")}</span>
							</div>,
						dataIndex: "permissionSecurityLevel",
						key: "blocked",
						width: 180,
						render: (value, record: TableData) => {
							const isChecked = value === 'BLOCKED'
							return <Checkbox disabled={!functionalityPermissions.edit} checked={isChecked} onChange={(e) => onBlockedChange(record, e.target.checked)} />
						},
					} : {}),
				{
					title:
						<div className="title-colunm-checkbox">
							<Checkbox disabled={!functionalityPermissions.edit} checked={checkedAllEdit} onChange={(e) => onCanEditAllChange(e.target.checked)} />
							<span>{i18n.t("release")}</span>
						</div>,
					dataIndex: "permissionSecurityLevel",
					key: "canEdit",
					width: 180,
					render: (value, record: TableData) => {
						const isChecked = value === 'EDIT'
						return <Checkbox disabled={!functionalityPermissions.edit} checked={isChecked} onChange={(e) => onCanEditChange(record, e.target.checked)} />
					},
				},
			]
		)

	];

	const headerComponents = {
		[MenuKey.ACCOUNT]: (
		  <Select
			key={MenuKey.ACCOUNT}
			style={{ width: '300px', display: isCostCenterLinked ? 'block' : 'none' }}
			placeholder={i18n.t("select_a_cost_center")}
			onChange={value => onChangeSelect(value)}
			options={permissionsCostCenter.map(item => { return { value: item.itemId, label: item.description } })}
			{...selectProps}
		  />
		),
		[MenuKey.FISCALYEAR]: (
		  <Select
			key={MenuKey.FISCALYEAR}
			style={{ width: '300px' }}
			placeholder={i18n.t("select")}
			onChange={value => onChangeSelect(value)}
			options={organizationOptions}
			{...selectProps}
		  />
		),
		default: (
		  <span className='info-wrapper'>
			<Icon icon="akar-icons:info-fill" />
			{selectedPermissionItem?.subtitle}
		  </span>
		),
		"SEARCH": (
			<Search
				id="search-organization-input"
				onChange={(e) => setSearchPermissions(e.target.value)}
				placeholder={selectedPermissionItem?.label}
			/>
		)
	  };

	let filteredData = tableData.filter(obj => obj.description?.toLowerCase().includes(searchPermissions.toLowerCase()));

	return (
		<Layout style={{ background: "#fff" }}>
			<Header className="permission-security-header">
				<div className="permissions-title-content">
					<h3>{i18n.t<string>("user") + ":"} {selectedUser?.label}</h3>
					<h1>{selectedPermissionItem?.label}</h1>
				</div>
			</Header>
			<Content>
				{selectedMenuKey === MenuKey.SALESHIERARCHY ?
					<div className="checkbox-content">
						<SalesHierarchyTable
							selectedId={selectedUser?.value}
							selectedMenuKey={selectedTab}
							searchPermission={searchPermissions}

						/>
					</div>
					:
					<>
						{(selectedMenuKey !== MenuKey.ACCOUNT || (selectedMenuKey === MenuKey.ACCOUNT && (!isCostCenterLinked || permissionsCostCenter.length > 0))) ?
							<>
								<div className="checkbox-container-info">
									<div className="checkbox-container-info-part">
										{headerComponents[selectedMenuKey] || headerComponents.default}
									</div>
									<div className="checkbox-container-info-part">
										{headerComponents.SEARCH}
										{selectedMenuKey === MenuKey.ACCOUNT &&
											<ImportExportMenu
												importProps={importAccount}
											/>
										}
									</div>
									{selectedMenuKey === MenuKey.COSTCENTER &&
										<ImportExportMenu
											importProps={importCostCenter}
										/>
									}
									{isFlexFields &&
										<ImportExportMenu
											importProps={importSecurity}
										/>
									}
								</div>
								{
									(selectedMenuKey !== MenuKey.ACCOUNT && selectedMenuKey !== MenuKey.FISCALYEAR) ||
										((selectedMenuKey === MenuKey.ACCOUNT || selectedMenuKey === MenuKey.FISCALYEAR) &&
											(filteredData.length > 0 || isFetching)) ?
										selectedMenuKey === MenuKey.CONTRACT ?
											<ContractTypeTable
												searchPermission={searchPermissions}
												selectedId={selectedUser.value}
												selectedMenuKey={selectedTab}
											/>
											:
											<div className="checkbox-content">
												<Table 
													loading={{
														spinning: isFlexFieldPermissions ? isFetchingFlexFields : isFetching,
														tip: `${i18n.t<string>("loading")}...`
													}}
													className='gs-table'
													columns={columns}
													dataSource={filteredData}
													pagination={{ hideOnSinglePage: true, showSizeChanger: false, defaultPageSize: 13 }}
												/>
											</div>
										:
										<ImageBox
											styles={{ flex: 1 }}
											title={selectedMenuKey === MenuKey.ACCOUNT ? i18n.t("select_cost_center_first_to_give_account_permission") : i18n.t("select_organization_first_to_give_fiscalyear_permission")}
											imgName="budget"
										/>
								}
							</>
							:
							<ImageBox
								styles={{ flex: 1 }}
								title={i18n.t("budget.permissions.release_a_cost_center_to_give_accounts_permissions")}
							/>
						}
					</>
				}
			</Content>
		</Layout>
	)
}