import { OpenFloatMenuButton } from "components/openFloatMenuButton/OpenFloatMenuButton";
import { EnvironmentModal } from "module/leaseProjection/components/planningEnvironmentTag/components/EnvironmentModal";

import {
    FloatMenu,
    Header,
    MainMenu,
    ModalSceneryFilter,
    SubHeader,
		StepModal,
} from "structure";

export function ContainerComponents() {

	return <>
		<Header />
		<SubHeader />
		<MainMenu />
		<OpenFloatMenuButton />
		<FloatMenu />
		<ModalSceneryFilter />
		<EnvironmentModal  />
		<StepModal />
	</>;

}