import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ROOT_PATH } from "util/rootPath";
import { useEffect, useState } from 'react';

export function ProjectionGuard({ children }: { children: JSX.Element }) {
  const [isLeaseProjection, setIsLeaseProjection] = useState<boolean | null>(null);

  useEffect(() => {
    ServiceCaller.doRequest(
      {
        useProxy: false,
        type: RequestType.GET,
        url: '/getLeaseProjection',
      },
      onLoadLeaseProjection
    );
  }, []);

  function onLoadLeaseProjection(isLeaseProjection: boolean) {
    setIsLeaseProjection(isLeaseProjection);
  }

  if (isLeaseProjection === null) {
    return null;
  }

  if (isLeaseProjection === false) {
    window.top.location.href = ROOT_PATH;
    return null;
  }

  return children;
}