import { ReactNode } from "react";

interface ICellContentProps {
    children?: ReactNode;
    currentRow: any;
    textDecoration: string;
    customStyle?: React.CSSProperties;
}

export function CellContent({ children, currentRow, textDecoration, customStyle }: ICellContentProps) {
    const defaultStyle = {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: 0,
        margin: 0,
    };

    if (customStyle) {
        return (
            <p
                style={{
                    ...defaultStyle,
                    backgroundColor: currentRow?.rowStyle && currentRow.rowStyle.color ? currentRow.rowStyle.color : "white",
                    color: currentRow?.rowStyle && currentRow.rowStyle.colorFont ? currentRow.rowStyle.colorFont : "black",
                    fontWeight: currentRow?.rowStyle && currentRow.rowStyle.isBold ? "bold" : null,
                    fontStyle: currentRow?.rowStyle && currentRow.rowStyle.isItalic ? "italic" : null,
                    fontSize: currentRow?.rowStyle && currentRow.rowStyle.fontSize ? currentRow.rowStyle.fontSize : null,
                    textDecoration: textDecoration ? textDecoration : null,
                    ...customStyle,
                    whiteSpace: "pre",
                }}
            >
                {children ? children : null}
            </p>
        );
    }

    return (
        <p
            style={{
                ...defaultStyle,
                backgroundColor: currentRow?.rowStyle && currentRow.rowStyle.color ? currentRow.rowStyle.color : "white",
                color: currentRow?.rowStyle && currentRow.rowStyle.colorFont ? currentRow.rowStyle.colorFont : "black",
                fontWeight: currentRow?.rowStyle && currentRow.rowStyle.isBold ? "bold" : null,
                fontStyle: currentRow?.rowStyle && currentRow.rowStyle.isItalic ? "italic" : null,
                fontSize: currentRow?.rowStyle && currentRow.rowStyle.fontSize ? currentRow.rowStyle.fontSize : null,
                textDecoration: textDecoration ? textDecoration : null,
                whiteSpace: "pre-wrap",
            }}
        >
            {children ? children : null}
        </p>
    );
}
