import { CheckOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";

export default function LoadingChanges({
    isLoading,
    hasSavedValue
}) {
    return <>
        {isLoading && (
            <div style={{ backgroundColor: '#F1F2F3', display: 'flex', width: 90, justifyContent: 'space-evenly', borderRadius: 2 }}>
                <Icon className="revenue-icon-rotate" icon={"ci:arrow-reload-02"} />
                <span style={{ color: '#5C5C5C' }}>
                    {i18n.t<string>("saving")}
                </span>
            </div>
        )}

        {hasSavedValue && !isLoading && (
            <div style={{ backgroundColor: '#F1F2F3', width: 135, display: 'flex', justifyContent: 'space-between', borderRadius: 2 }}>
                <CheckOutlined style={{ marginTop: 3, marginLeft: 3 }} />
                <span style={{ marginRight: 5, color: '#5C5C5C' }}>
                    {i18n.t<string>("changes_saved")}
                </span>
            </div>
        )}
    </>
}