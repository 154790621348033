import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Button, Col, Form, Modal, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import i18n from "util/base/i18n";
import { selectProps } from "util/props/props";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import moment from "moment";
import { ViewModeSelected } from "../IDetail";
import { Notification } from "components/notification/Notification";

export default function CommentsModal({
    isModalVisible,
    setIsCommentModalVisible,
    comment,
    detailId,
    viewModeSelected,
    loadInvestmentDetail,
    setIsFetching
}) {

    const monthOptions = [
        { value: "", label: "Selecione o mês", disabled: false },
        { value: "01", label: "Janeiro", disabled: false },
        { value: "02", label: "Fevereiro", disabled: false },
        { value: "03", label: "Março", disabled: false },
        { value: "04", label: "Abril", disabled: false },
        { value: "05", label: "Maio", disabled: false },
        { value: "06", label: "Junho", disabled: false },
        { value: "07", label: "Julho", disabled: false },
        { value: "08", label: "Agosto", disabled: false },
        { value: "09", label: "Setembro", disabled: false },
        { value: "10", label: "Outubro", disabled: false },
        { value: "11", label: "Novembro", disabled: false },
        { value: "12", label: "Dezembro", disabled: false }
    ];

    const [justification, setJustification] = useState([]);
    const [isNewJustification, setIsNewJustification] = useState(false);
    const [availableMonths, setAvailableMonths] = useState(monthOptions);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!isModalVisible) {
            setAvailableMonths(monthOptions);
            return;
        }
        let justificationMonth = [];
        Object.keys(comment).forEach(month => {
            if (month !== 'id' && month !== 'description') {
                if (comment[month].id && comment[month].justification) {
                    justificationMonth.push({ justification: comment[month].justification, month: month.slice(-2) });
                    disableMonth(month.slice(-2));
                } else if (!comment[month].id) {
                    disableMonth(month.slice(-2));
                }
            }
        })

        if (justificationMonth.length === 0) setIsNewJustification(true);
        setJustification(sortByMonth(justificationMonth, "month"));
    }, [isModalVisible]);

    function handleIncludeJustification(data) {

        disableMonth(data.selectedMonth);

        const justificationList = [...justification, {
            justification: data.newJustification,
            month: data.selectedMonth
        }];

        setJustification(sortByMonth(justificationList, "month"));
        setIsNewJustification(false);
        form.resetFields();
    }

    function handleOkButtonClickModal() {

        handleCloseModal();
        setIsFetching(true);

        const listToSave = justification.map((item) => {
            const year = moment().year();
            const date = moment(`01/${item.month}/${year}`, 'DD/MM/YYYY').format('YYYY-MM-DD');
            return {
                headerId: detailId,
                templateId: viewModeSelected === ViewModeSelected.TEMPLATE ? comment.key : null,
                justification: item.justification,
                acquisitionDate: date
            }
        });

        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/investment/save-justification`,
            params: listToSave,
        }, onSaveNewJustification.bind(this));
    }

    function onSaveNewJustification(data) {
        if (data) {
            Notification({
                type: "success",
                message: i18n.t("successfully_saved"),
            });
        }
        loadInvestmentDetail();
    }

    function handleCloseModal() {
        form.resetFields();
        setIsCommentModalVisible(false);
        setIsNewJustification(false);
        setJustification([]);
    };

    function sortByMonth(list, key) {
        return list.sort((a, b) => parseInt(a[key], 10) - parseInt(b[key], 10))
    }

    function disableMonth(monthNumber) {
        const monthIndex = availableMonths.findIndex(month => month.value === monthNumber);

        if (monthIndex !== -1) {
            const newList = [...availableMonths];
            newList[monthIndex].disabled = true;
            setAvailableMonths(newList.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10)));
        }
    }

    function handleJustificationChange(e, month) {
        const index = justification.findIndex(item => item.month === month)
        const updatedList = [...justification]
        updatedList.splice(index, 1);
        updatedList.push({ justification: e, month: month });
        setJustification(sortByMonth(updatedList, "month"));
    }

    return (
        <Modal
            width={500}
            title={"Justificativa"}
            visible={isModalVisible}
            onCancel={handleCloseModal}
            className="gs-modal account-registration-modal comment-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            onOk={handleOkButtonClickModal}
            okButtonProps={{ disabled: isNewJustification || justification.length === 0 }}
            centered
        >
            {!isNewJustification &&
                <Button
                    style={{ marginBottom: 10, marginLeft: "auto" }}
                    type="default"
                    className="gs-secondary-button"
                    icon={<Icon icon="akar-icons:circle-plus-fill" />}
                    onClick={() => setIsNewJustification(true)}
                    disabled={isNewJustification}
                >
                    Adicionar
                </Button>
            }
            <Form
                form={form}
                name="new-account-form"
                onFinish={(data) => {
                    handleIncludeJustification(data);
                }}
                layout="vertical"
                requiredMark={false}
            >
                {isNewJustification &&
                    <Row gutter={20}>
                        <Col span={24}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 5 }}>
                                <Form.Item
                                    name="selectedMonth"
                                    style={{ marginBottom: 0, display: "flex", alignItems: "center" }}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        style={{ width: 150 }}
                                        defaultValue={""}
                                        options={availableMonths.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10))}
                                        {...selectProps}
                                    />
                                </Form.Item>
                                <div className="textarea-buttons-container">
                                    {justification.length > 0 &&
                                        <button
                                            onClick={() => setIsNewJustification(false)}
                                            className="gs-secondary-button"
                                        >
                                            Excluir
                                        </button>
                                    }
                                    <Button
                                        type="default"
                                        htmlType="submit"
                                        className="gs-secondary-button"
                                    >
                                        Confirmar
                                    </Button>
                                </div>
                            </div>
                            <Form.Item
                                name="newJustification"
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={"Insira uma justificativa"}
                                />
                            </Form.Item>

                        </Col>
                    </Row>
                }
                <Row gutter={20}>
                    {justification.length > 0 &&
                        justification.map(item => {
                            return (
                                <Col key={item.month} span={24} style={{ marginBottom: 7 }}>
                                    <label style={{ fontWeight: 500, marginBottom: 5, display: "block" }}>
                                        {monthOptions.find(month => month.value === item.month)?.label}
                                    </label>
                                    <TextArea
                                        rows={4}
                                        defaultValue={item.justification}
                                        placeholder={"Insira uma justificativa"}
                                        onBlur={(e) => handleJustificationChange(e.target.value, item.month,)}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Form>
        </Modal >
    )
}