import moment from 'moment';
import i18n from 'util/base/i18n';
import { Icon } from "@iconify/react";
import { ColumnsType } from 'antd/lib/table';
import { Key, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import { TopButtons } from 'components/topButtons/TopButtons';
import { Filter } from 'module/leaseProjection/components/filter/Filter';
import { handleExportGridData } from "util/functions/handleExportGridData";
import { handleUTCDate } from 'module/leaseProjection/functions/handleUTCDate';
import { ProjectionRangeTag } from 'module/leaseProjection/components/projectionRangeTag/ProjectionRangeTag';

import { useUserContext } from 'context/UserContext';
import { RequestType } from 'util/service/IServiceCaller';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { Notification } from 'components/notification/Notification';

import UndoProjectionTable from './components/UndoProjectionTable';
import UndoProjectionFilter from './components/UndoProjectionFilter';
import { DataType } from './IUndoProjection';
import './UndoProjectionStyles.sass';
import { Loading } from 'components/loading/Loading';
import { Form } from 'antd';
import ContractCounter from 'module/leaseProjection/components/contractCounter/ContractCounter';
import { usePermissionContext } from 'context/PermissionContext';

export default function UndoProjection() {
  const location = useLocation();
  const [filterForm] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [listFilters, setListFilters] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<DataType[]>([]);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [filterdTableData, setFilterdTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isScenarioReleased, setIsScenarioReleased] = useState(false);
  const { setFunctionalityPermissions, functionalityPermissions } = usePermissionContext();
  const { userInfo } = useUserContext()

  useEffect(() => {
    if (userInfo.selection.scenarioId != null) {
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: `/projectionlease/scenario/isReleased`
      }, onLoadScenario.bind(this));

      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.GET,
        url: "/projectionlease/undochanges/loadpermissions"
      }, onLoadPermissions.bind(this));

      loadDefaultContracts()
    }
  }, [userInfo.selection, location]);

  useEffect(() => {
    setFilterdTableData(tableData);
  }, [tableData]);

  function onLoadScenario(isScenarioReleased) {
    setIsScenarioReleased(isScenarioReleased)
  }

  const tableColumns: ColumnsType<DataType> = [
    {
      title: i18n.t<string>('code'),
      dataIndex: 'externalCode',
      key: 'externalCode',
      align: "left",
      sorter: (a, b) => a.externalCode - b.externalCode,
    },
    {
      title: i18n.t<string>('contract'),
      dataIndex: 'name',
      key: 'name',
      align: "left",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      }
    },
    {
      title: i18n.t<string>('type'),
      dataIndex: 'searchType',
      key: 'searchType',
      align: "left",
      sorter: (a, b) => {
        return a.searchType.localeCompare(b.searchType);
      }
    },
    {
      title: i18n.t<string>('operation'),
      dataIndex: 'operation',
      key: 'operation',
      align: "left",
      sorter: (a, b) => {
        return a.operation.localeCompare(b.operation);
      },
      render: (item) => item.name
    },
    {
      title: i18n.t<string>('start_date'),
      dataIndex: 'startDate',
      key: 'startDate',
      align: "center",
      sorter: (a, b) => {
        return a.startDate.localeCompare(b.startDate);
      },
      render: (date) => { if (date) return moment(handleUTCDate(date)).format('DD/MM/YYYY') }
    },
    {
      title: i18n.t<string>('end_date'),
      dataIndex: 'endDate',
      key: 'endDate',
      align: "center",
      sorter: (a, b) => {
        return a.endDate.localeCompare(b.endDate);
      },
      render: (date) => { if (date) return moment(handleUTCDate(date)).format('DD/MM/YYYY') }
    }
  ];

  const tableColumnsWithoutRender = tableColumns.map(column => {
    const { render, ...rest } = column;
    return rest;
  });

  function loadDefaultContracts() {
    setIsFetching(true)

    const firstDayOfMonth = moment().startOf('month');
    const lastDayOfMonth = moment().endOf('month');

    const defaultData = {
      isNew: false,
      isAdditive: false,
      isTerminated: false,
      isAdjustment: false,
      startDate: firstDayOfMonth.valueOf(),
      endDate: lastDayOfMonth.valueOf(),
      scenarios: [userInfo.selection.scenarioId],
      businessUnits: [userInfo.selection.businessUnitId],
      organizations: [userInfo.selection.organizationId],
      modalities: [],
      banks: [],
      operations: [],
      divisions: [],
      groupManagements: [],
      groupContracts: [],
      projects: []
    }

    loadContracts(defaultData)

    setListFilters([
      { "key": 1, "description": firstDayOfMonth.format('DD/MM/YYYY') + ' - ' + lastDayOfMonth.format('DD/MM/YYYY') },
      {
        "key": 5, "groupItems": [
          { "key": userInfo.selection.organizationId, "name": userInfo.selection.organizationName },
          { "key": userInfo.selection.businessUnitId, "description": userInfo.selection.businessUnitName }
        ]
      }
    ])
  }

  function formatTableDataToExport(data: any[]): any[] {
    const formattedData = data.map((item) => {
      const translatedItem = {};
      for (const key in item) {
        if (key === 'type' || key === 'key' || key === 'id') {
          continue;
        }
        if (key === 'startDate' || key === 'endDate') {
          if (item[key]) {
            const date = new Date(item[key]).toLocaleDateString('pt-BR');
            const formattedDate = date;
            translatedItem[key] = formattedDate;
          } else {
            translatedItem[key] = '';
          }
        } else if (key === 'operation') {
          translatedItem[key] = item[key].name;
        } else {
          translatedItem[key] = item[key];
        }
      }
      return translatedItem;
    });

    return formattedData;
  }

  function loadContracts(data) {
    ServiceCaller.doRequest({
      type: RequestType.POST,
      url: '/projectionlease/undochanges/loadcontracts',
      useProxy: false,
      params: data
    }, onGetGridData.bind(this));
  }

  function onLoadPermissions(permList) {
    setFunctionalityPermissions(permList);
  }

  function onSelectRowChange(selectedRowKeys: Key[], selectedRows: DataType[]) {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  function onGetGridData(data) {
    const table = data.map(item => {
      return {
        key: item.id + item.searchType,
        externalCode: item.code,
        bank: item.bank,
        businessUnit: item.businessUnit,
        searchType: item.searchType,
        endDate: handleUTCDate(item.endDate),
        startDate: handleUTCDate(item.startDate),
        name: item.name,
        operation: item.operation,
      }
    })
    setIsFetching(false)
    setTableData(table);
  }

  function handleCloseFilter() {
    setIsFilterVisible(false)
  }

  function handleSubmitFilter(data) {
    if (data.startDate != 0) {
      setIsFetching(true)
      loadContracts(data)
    }
  }

  function checkIsCalculating(contractIds) {
    return new Promise(function (resolve, reject) {
      ServiceCaller.doRequest({
        useProxy: false,
        type: RequestType.POST,
        url: '/projectionlease/contract/checkIsCalculating',
        params: contractIds
      }, function (isCalculating) {
        resolve(isCalculating);
      });
    });
  }

  async function handleUndoChanges() {
    let rowsToUndo = selectedRows

    if (selectedRowKeys.length === 0)
      rowsToUndo = tableData

    const contractIds = rowsToUndo.map((row) => row.externalCode);

    const isSelectedRowsCalculating = await checkIsCalculating(contractIds);

    if (!isSelectedRowsCalculating) {
      setIsLoading(true)

      const { description } = listFilters.find(obj => obj.key === 1);
      const startDate = moment(description.split(" - ")[0], "DD/MM/YYYY").valueOf();
      const endDate = moment(description.split(" - ")[1], "DD/MM/YYYY").valueOf();

      const isNew = listFilters.some(obj => obj.key === 2);
      const isAdditive = listFilters.some(obj => obj.key === 3);
      const isTerminated = listFilters.some(obj => obj.key === 4);

      const contractsNew = rowsToUndo
        .filter((row) => row.searchType.includes('Novos'))
        .map((row) => row.externalCode);

      const contractsRes = rowsToUndo
        .filter((row) => row.searchType.includes('Rescindidos'))
        .map((row) => row.externalCode);

      const contractsAdd = rowsToUndo
        .filter((row) => row.searchType.includes('Aditivos'))
        .map((row) => row.externalCode);

      const data = {
        contractsNew,
        contractsAdd,
        contractsRes,
        startDate,
        endDate,
        isNew,
        isAdditive,
        isTerminated
      }

      ServiceCaller.doRequest({
        type: RequestType.POST,
        url: '/projectionlease/undochanges/undoprojection',
        useProxy: false,
        params: data
      }, onLoadUndoChanges.bind(this));
    } else {
      Notification({
        type: "warning",
        message: i18n.t<string>('contract_simulation.undo_calculating'),
        duration: 8
      });
    }
  }

  function onLoadUndoChanges(status) {
    if (status.success) {
      Notification({
        type: 'success',
        message: i18n.t<string>('contract_simulation.start_undo')
      })

      setIsLoading(false)
      filterForm.resetFields()
      loadDefaultContracts()
    } else {
      Notification({
        type: 'warning',
        message: i18n.t<string>('contract_simulation.start_undo_error')
      })
    }

    setIsLoading(false)
    setSelectedRowKeys([])
    setSelectedRows([])
  }

  const customButtonsList = [
    functionalityPermissions.export && {
      icon: <Icon width={24} icon="tabler:file-export" />,
      onClick: () => handleExport(),
      toolTip: i18n.t<string>('export'),
    },
    {
      icon: <Icon width={24} icon="mdi:filter" />,
      onClick: () => setIsFilterVisible(true),
      toolTip: i18n.t<string>('filter'),
    }
  ];

  function handleExport() {
    if (tableData.length)
      handleExportGridData(
        formatTableDataToExport(tableData),
        tableColumnsWithoutRender,
        i18n.t<string>('contract_projection_spreadsheet')
      )

    else
      Notification({
        type: "warning",
        message: i18n.t<string>('no_data_available_export'),
      });
  }

  const isMainButtonAble = functionalityPermissions.undochanges && isScenarioReleased;

  return (
    <>
      <div id='undo-title-and-planning'>
        <h1 className='undo-projection-title'>{i18n.t<string>('projection_lease.undo_changes')}</h1>
        <div className='tag-wrapper'>
          <ProjectionRangeTag />
        </div>
      </div>
      <Filter
        form={filterForm}
        hasFiltersOnScreen={true}
        listFilters={listFilters}
        setListFilters={setListFilters}
        handleCancel={handleCloseFilter}
        isFilterVisible={isFilterVisible}
        submitRequest={handleSubmitFilter}
      />
      <TopButtons
        mainButtonTitle={selectedRowKeys.length > 0 ? i18n.t<string>('undo_selected') : i18n.t<string>('undo_all')}
        handleNew={handleUndoChanges}
        disabledMainButton={!isMainButtonAble}
        customButtons={customButtonsList}
        multipleSearch={{
          tableData: tableData,
          setTableData: setFilterdTableData,
          options: [
            { i18nString: 'external_code', description: 'externalCode' },
            { i18nString: 'contract', description: 'name' },
          ]
        }}
        searchPlaceholder={i18n.t<string>('search')}
      />
      <main id="undo-main">
        {isLoading &&
          <div className='undo-loading-wrapper'>
            <Loading caption={i18n.t<string>('loading')} />
          </div>
        }
        <UndoProjectionFilter
          listFilters={listFilters}
          setListFilters={setListFilters}
        />
        <div className="grid-info">
          <ContractCounter />
          <p className='grid-length'>{i18n.t<string>('viewing')} - {filterdTableData.length} {i18n.t<string>('contracts_found')}</p>
        </div>
        <UndoProjectionTable
          isFetching={isFetching}
          dataTable={filterdTableData}
          selectedRowKeys={selectedRowKeys}
          onChange={onSelectRowChange}
          tableColumns={tableColumns}
        />
      </main>
    </>
  )
}
