import { Key, useEffect, useState } from "react";
import { OperationsBar } from "./components/OperationsBar";
import { RegistrationModal } from "./components/RegistrationModal";

import './HumanResources.sass'
import { Hired, HiredTable, HiredTableList, IAllocationData, IAllocationTypeSave } from "./IHumanResoucers";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { useUserContext } from "context/UserContext";
import { HiredDashboard } from "./hiredDashboard/HiredDashboard";
import { FirstAccess } from "./components/FirstAccess";
import { HiredList } from "./components/HiredList";
import { Notification } from "components/notification/Notification";
import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { usePermissionContext } from "context/PermissionContext";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorFallbackProps } from "components/error/ErrorFallback";
import { useBudgetDates } from "hooks/useBudgetDates";
import { PeriodBoardInfo } from "module/budget/components/PeriodBoardInfo/PeriodBoardInfo";
import { FlexFilter, ModuleType } from "util/types/types";
import { verifyBudgetPeriod } from "util/functions/verifyBudgetPeriod";
import { useBusinessUnitOptions } from "hooks/useBusinessUnitOptions";
import { useProfessionalCategoryOptions } from "hooks/useProfessionalCategoryOptions";
import { useCostCategoryOptions } from "hooks/useCostCategoryOptions";
import { useCostCenterOptions } from "hooks/useCostCenterOptions";
import { useAdditionalFieldsOptions } from "hooks/useAdditionalFieldsOptions";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { Col, Row, Tooltip } from "antd";
import { useAllocationModalContext } from "./components/allocationModal/AllocationModalContext";

export function HumanResources() {
	const [hired, setHired] = useState<HiredTable[]>([]);
	const [newHiredList, setNewHiredList] = useState<Hired[]>([]);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [isDashboard, setIsDashboard] = useState<boolean>(false);
	const [isNotRealized, setIsNotRealized] = useState<boolean>(false);
	const [realizedPeriod, setRealizedPeriod] = useState<moment.Moment>();
	const [detailSelectedId, setDetailSelectedId] = useState(0);
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
	const [year, setYear] = useState<string>();
	const [openedCalendarPeriod, setOpenedCalendarPeriod] = useState<number[]>([])
	const { userInfo: { selection: { scenarioId, organizationId } } } = useUserContext();
	const { functionalityPermissions } = usePermissionContext();
	const { data: businessUnitOptions } = useBusinessUnitOptions({ refetchOnWindowFocus: true });
	const { data: costCategoryOptions } = useCostCategoryOptions({ refetchOnWindowFocus: true });
	const { data: professionalCategoryOptions } = useProfessionalCategoryOptions({ refetchOnWindowFocus: true });
	const { data: costCenterOptions } = useCostCenterOptions({ refetchOnWindowFocus: true });
	const { data: addtionalFieldsOptions } = useAdditionalFieldsOptions({ refetchOnWindowFocus: true });
	const { setIsEditing, setIsDisabledAllocatedButton, setResourceSelected } = useAllocationModalContext();
	const { showBoundary } = useErrorBoundary()
	const { data: budgetPeriodDates } = useBudgetDates(ModuleType.EXPENSERESOURCES)
	const [isClosedBudgetPeriod, setIsClosedBudgetPeriod] = useState(false)
	const [flexFieldValues, setFlexFieldValues] = useState<FlexFilter[]>([]);
	const allocatedIcon = <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.17857 0C4.94754 0 5.57143 0.623594 5.57143 1.39286V4.17857C5.57143 4.94754 4.94754 5.57143 4.17857 5.57143H1.39286C0.623594 5.57143 0 4.94754 0 4.17857V1.39286C0 0.623594 0.623594 0 1.39286 0H4.17857ZM3.71429 1.85714H1.85714V3.71429H3.71429V1.85714ZM4.17857 7.42857C4.94754 7.42857 5.57143 8.05245 5.57143 8.82143V11.6071C5.57143 12.3761 4.94754 13 4.17857 13H1.39286C0.623594 13 0 12.3761 0 11.6071V8.82143C0 8.05245 0.623594 7.42857 1.39286 7.42857H4.17857ZM3.71429 9.28571H1.85714V11.1429H3.71429V9.28571ZM7.42857 1.39286C7.42857 0.623594 8.05245 0 8.82143 0H11.6071C12.3761 0 13 0.623594 13 1.39286V4.17857C13 4.94754 12.3761 5.57143 11.6071 5.57143H8.82143C8.05245 5.57143 7.42857 4.94754 7.42857 4.17857V1.39286ZM9.28571 3.71429H11.1429V1.85714H9.28571V3.71429ZM10.2143 12.0714H11.1429V13H10.2143V12.0714ZM13 13H12.0714V12.0714H13V13ZM12.0714 7.42857H13V11.1429H10.2143V10.2143H9.28571V13H7.42857V7.42857H10.2143V8.35714H12.0714V7.42857Z" fill="#0D2F48" />
	</svg>

	useEffect(() => {
		if (!budgetPeriodDates) return;
		if (budgetPeriodDates.period.length === 0) {
			showBoundary({
				code: 400,
				title: 'Não há exercício aberto',
				message: 'Adicione um exercício na tela de painel de controle antes de continuar',
				image: 'empty_list'
			} as ErrorFallbackProps['error'])
		} else {
			const year = budgetPeriodDates.period[budgetPeriodDates.period.length - 1].year
			setOpenedCalendarPeriod(budgetPeriodDates.period.map(period => period.year))
			setYear(year.toString())
			const currentBudgetPeriod = budgetPeriodDates.period.find(budDate => budDate.year === year)
			const { isClosedBudgetPeriod } = verifyBudgetPeriod(currentBudgetPeriod, moment(year, 'YYYY'), budgetPeriodDates.localDate, ModuleType.EXPENSERESOURCES)
			setIsClosedBudgetPeriod(isClosedBudgetPeriod)
		}
	}, [budgetPeriodDates])

	useEffect(() => {
		if (organizationId) {
			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/budget-base/flex-field/find-all-by-module?module=HUMAN_RESOURCES`,
			}, handleAddFlexOptFilter.bind(this));
		}
	}, [organizationId])

	async function handleAddFlexOptFilter(data: any[]) {
		const flexField: FlexFilter[] = data
			.sort((a, b) => a.ordenation - b.ordenation)
			.map(item => {
				return {
					label: item.description,
					value: item.id,
					linkedFilters: item.filters ? item.filters : null,
					ordenation: item.ordenation,
					fieldCode: Number(item.fieldCode.split('_').pop()),
					selectedOption: [],
					children: []
				}
			});
		try {
			const promises = flexField.map(ff => {
				if (!ff.linkedFilters || ff.linkedFilters.includes('ORGANIZATION')) {
					return new Promise<void>((resolve, reject) => {
						ServiceCaller.doRequest({
							type: RequestType.GET,
							url: `/budget-base/flex-field-value?user={user}&flexFieldId=${ff.value}`
						}, (data: any[]) => {
							if (ff.linkedFilters?.includes('ORGANIZATION')) {
								ff.children = data
									.filter(item => item.organizationFilters ? item.organizationFilters.includes(organizationId) : true)
									.map(item => ({
										value: item.id,
										label: item.externalCode + ' - ' + item.description,
										flexFieldId: item.flexFieldId
									}))
								return resolve();
							} else {
								ff.children = data.map(item => ({
									value: item.id,
									label: item.externalCode + ' - ' + item.description,
									flexFieldId: item.flexFieldId
								}))
								return resolve();
							}
						}, (err) => {
							handleErrorRequest(err);
							return reject();
						})
					})
				}
				return Promise.resolve()
			})

			await Promise.all(promises);
		} catch (error) {
			handleErrorRequest(error);
		}
		setFlexFieldValues(flexField);
	}


	function handleGetRealizedPeriod(data) {
		const period = data.expensesAndResourcesRealizedPeriod;
		if (data.id != null && period == null) setIsNotRealized(true);
		setRealizedPeriod(period ? moment(period) : null);
	}

	function getTableData() {
		setSelectedRowKeys([]);
		setIsFetching(true);
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/human-resources/hiring?scenarioId=${scenarioId}&locale={locale}&clientId={client}&organizationId={organization}&userId={user}${year ? `&referenceYear=${year}` : ""}&bu={businessUnit}`
		}, onLoadHireds.bind(this), handleFetchError)
	}

	useEffect(() => {
		if (!scenarioId || isDashboard) return;
		if (year) getRealizedPeriod();
		if (!isFetching && year) getTableData();

		setSelectedRowKeys([]);
		if(budgetPeriodDates && year) {
			const currentBudgetPeriod = budgetPeriodDates.period.find(budDate => budDate.year === Number(year));
			const { isClosedBudgetPeriod } = verifyBudgetPeriod(currentBudgetPeriod, moment(year, 'YYYY'), budgetPeriodDates.localDate, ModuleType.EXPENSERESOURCES);
			setIsClosedBudgetPeriod(isClosedBudgetPeriod);
		}

	}, [scenarioId, year])

	function getRealizedPeriod() {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/control-panel/find-by-organization-and-year?organization={organization}&year=${year}&scenario={scenario}`,
		}, handleGetRealizedPeriod.bind(this));
	}

	function onLoadHireds(data: any[]) {
		setHired(data.map(hiredData => {
			return {
				...hiredData,
				businessUnit: { value: hiredData.businessUnit.id, label: hiredData.businessUnit.name },
				costCategory: { value: hiredData.costCategory.id, label: hiredData.costCategory.name },
				professionalCategory: { value: hiredData.professionalCategory.id, label: hiredData.professionalCategory.name },
				costCenter: { value: hiredData.costCenter.id, label: hiredData.costCenter.name },
			};
		}).sort((a, b) => {
			const businessUnitComparison = a.businessUnit.label.localeCompare(b.businessUnit.label);
			if (businessUnitComparison !== 0) {
				return businessUnitComparison;
			}

			const costCenterComparison = a.costCenter.label.localeCompare(b.costCenter.label);
			if (costCenterComparison !== 0) {
				return costCenterComparison;
			}

			return a.professionalCategory.label.localeCompare(b.professionalCategory.label);
		}));

		setIsFetching(false);
	}

	function handleFetchError() {
		Notification({
			message: i18n.t("something_went_wrong"),
			type: "error",
		})
		setIsFetching(false);
		setSelectedRowKeys([]);
	}

	function onSaved() {
		setNewHiredList([]);
		getTableData();
		Notification({
			message: "Contratação Salva com sucesso!",
			type: "success",
		})
	}

	function onChangeDatePicker(date, dateString) {
		setYear(dateString);
	}

	const tableColumns: ColumnsType<HiredTable> = [
		{
			title: i18n.t("business_unit"),
			dataIndex: 'businessUnit',
			align: 'left',
			sorter: (a: any, b: any) => a.businessUnit.localeCompare(b.businessUnit.length),
			sortDirections: ['ascend', 'descend', 'ascend'],
			defaultSortOrder: 'ascend'
		},
		{
			title: i18n.t("cost_center"),
			dataIndex: 'costCenter',
			align: 'left',
			sorter: (a: any, b: any) => a.costCenter.localeCompare(b.costCenter),
			sortDirections: ['ascend', 'descend', 'ascend'],
		},
		{
			title: i18n.t("cost_category"),
			dataIndex: 'costCategory',
			align: 'left',
			sorter: (a: any, b: any) => a.costCategory.localeCompare(b.costCategory),
			sortDirections: ['ascend', 'descend', 'ascend'],
		},
		{
			title: i18n.t("professional_category.professional_category_name"),
			dataIndex: 'professionalCategory',
			align: 'left',
			sorter: (a: any, b: any) => a.professionalCategory.localeCompare(b.professionalCategory),
			sortDirections: ['ascend', 'descend', 'ascend'],
		},
		{
			title: i18n.t("collaborator"),
			dataIndex: 'collaborator',
			align: 'left',
			sorter: (a: any, b: any) => a.collaborator.localeCompare(b.collaborator),
			sortDirections: ['ascend', 'descend', 'ascend'],
			className: 'collaborator-column'
		},
		{
			title: '',
			dataIndex: 'operation',
			align: 'center',
			render: (_, record) => {
				return (
					<Row gutter={10} className="buttons-col" >
						{record.isAllocated &&
							<Col onClick={() => handleClickEditAllocated(record)} className="allocated-icon-button">
								<Tooltip title={i18n.t("human_resources.allocate_resource")}>
									{allocatedIcon}
								</Tooltip>
							</Col>
						}
						<Col>
							<Icon style={{ fontSize: 24, marginBottom: -2 }} icon="bxs:chevron-right-circle" />
						</Col>
					</Row>
				)
			}
		},
	]

	function handleClickEditAllocated(hired: HiredTable) {
		setResourceSelected(hired);
		setIsEditing(true);
	}

	function onChange(selectedRowKeys: Key[], selectedRows: HiredTableList[]) {
		let isDisabledButton = false;
		if (selectedRowKeys.length > 1) {
			isDisabledButton = selectedRows.some(({ isAllocated }) => isAllocated)
		}
		setIsDisabledAllocatedButton(isDisabledButton);
		setSelectedRowKeys(selectedRowKeys);
	}

	return (
		<div className="collaborators-human-resources">
			{!detailSelectedId ?
				<>
					<div style={{ justifyContent: 'space-between', borderBottom: '1px solid #ccc', paddingBottom: 15 }} className="page-title-content">
						<h1>{i18n.t("human_resources.human_resources")}</h1>
					</div>
					<OperationsBar
						hiredList={hired}
						openedCalendarPeriod={openedCalendarPeriod}
						setOpenModal={setIsModalVisible}
						setDeitalId={setDetailSelectedId}
						tableColumns={tableColumns}
						year={year}
						onChangeDatePicker={onChangeDatePicker}
						realizedPeriod={realizedPeriod}
						getTableData={getTableData}
						functionalityPermissions={functionalityPermissions}
						selectedRowKeys={selectedRowKeys}
						isFetching={isFetching}
						setIsFetching={setIsFetching}
						isClosedBudgetPeriod={isClosedBudgetPeriod}
						businessUnitOptions={businessUnitOptions}
						costCenterOptions={costCenterOptions}
						professionalCategoryOptions={professionalCategoryOptions}
						costCategoryOptions={costCategoryOptions}
						addtionalFieldsOptions={addtionalFieldsOptions}
					/>
					<section style={{ background: '#f1f2f3', padding: 10 }}>
						<PeriodBoardInfo
							period={[moment(year, 'YYYY').startOf('year'), moment(year, 'YYYY').endOf('year')]}
							budgetPeriodDates={budgetPeriodDates}
							moduleType={ModuleType.EXPENSERESOURCES}
							styles={{ margin: '6px auto 16px' }}
						/>
						{false ?
							<FirstAccess setOpenModal={setIsModalVisible} />
							:
							<HiredList
								setDeitalId={setDetailSelectedId}
								isFetching={isFetching}
								hiredList={hired}
								setIsDashboard={setIsDashboard}
								tableColumns={tableColumns}
								selectedRowKeys={selectedRowKeys}
								onChange={onChange}
								flexFieldValues={flexFieldValues}
								setFlexFieldValues={setFlexFieldValues}
								getTableData={getTableData}
							/>
						}
					</section>
					<RegistrationModal
						newHiredList={newHiredList}
						setNewHiredList={setNewHiredList}
						isVisible={isModalVisible}
						setIsVisible={setIsModalVisible}
						onSaved={onSaved}
						setIsFetching={setIsFetching}
						year={year}
						realizedPeriod={realizedPeriod}
						isNotRealized={isNotRealized}
						businessUnitOptions={businessUnitOptions}
						costCategoryOptions={costCategoryOptions}
						professionalCategoryOptions={professionalCategoryOptions}
						flexFieldValues={flexFieldValues}
						setFlexFieldValues={setFlexFieldValues}
					/>
				</>
				:
				<HiredDashboard
					hiredList={hired}
					setDeitalId={setDetailSelectedId}
					resource={hired.find(resource => resource.id === detailSelectedId)}
					year={year}
					setYear={setYear}
					setIsDashboard={setIsDashboard}
					getTableDataResorces={getTableData}
					openedCalendarPeriod={openedCalendarPeriod}
				/>
			}
		</div>
	)
}