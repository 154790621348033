import i18n from "util/base/i18n";
import { getColumnComparative } from "./getColumn";

export function buildColumnComparativeConsolidation(reportVisualizationFilters, rowsFromBackend, orgId) {
    const plannedValue = getColumnComparative({
        index: 1,
        title: i18n.t<string>('planned'),
        render: "totalValueBase",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
        orgId
    });

    const accomplishedValue = getColumnComparative({
        index: 2,
        title: i18n.t<string>('accomplished'),
        render: "totalValueTarget",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
        orgId
    });

    const variableValue = getColumnComparative({
        index: 3,
        title: i18n.t<string>('variation'),
        render: "totalVariationBaseTarget",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
        orgId
    });

    const variablePercente = getColumnComparative({
        index: 4,
        title: (i18n.t<string>('variation') + " %"),
        render: "totalVariationPercenteBaseTarget",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
        orgId
    });

    return [plannedValue, accomplishedValue, variableValue, variablePercente];
}
