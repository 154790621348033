import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { Checkbox, Col, Modal, Row, Input, Button } from "antd";
import { Icon } from "@iconify/react";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

import { ICostCenterLinkedPackageModal } from "../IGroupings";
import { Options } from "util/types/types";

const { Search } = Input;

export function CostCenterLinkedPackageModal({
    open,
    handleCancel,
    costCenterOptions,
    onLinkCostCenter,
    linkedCostCenter,
    setLinkedCostCenter,
    isSaving,
}: ICostCenterLinkedPackageModal) {
    const [unlinkedSearch, setUnlinkedSearch] = useState<string>("");
    const [linkedSearch, setlinkedSearch] = useState<string>("");
    const [unlinkedChecked, setUnlinkedChecked] = useState<CheckboxValueType[]>([]);
    const [linkedChecked, setLinkedChecked] = useState<CheckboxValueType[]>([]);
    const [unlinkedCostCenter, setUnlinkedCostCenter] = useState<Options[]>([]);

    useEffect(() => {
        if (!costCenterOptions || costCenterOptions.length === 0) return;
        const updatedUnlinkedCostCenter = costCenterOptions
            .filter((costCenter) => {
                return !linkedCostCenter.some((linkedCostCenter) => linkedCostCenter.value === costCenter.value);
            })
            .sort((a, b) => a.value - b.value);
        setUnlinkedCostCenter(updatedUnlinkedCostCenter);
    }, [costCenterOptions, linkedCostCenter]);

    const onChangeUnlinkedChecked = (checkedValues: CheckboxValueType[]) => {
        setUnlinkedChecked(checkedValues);
    };

    const onChangeLinkedChecked = (checkedValues: CheckboxValueType[]) => {
        setLinkedChecked(checkedValues);
    };

    const onLinked = () => {
        if (unlinkedChecked.length === 0) return;

        const updatedLinkedList = costCenterOptions.filter((costCenter) => {
            return unlinkedChecked.includes(costCenter.value);
        });
        const updatedUnlinkedList = unlinkedCostCenter.filter((costCenter) => {
            return !unlinkedChecked.includes(costCenter.value);
        });
        setUnlinkedChecked([]);
        setUnlinkedCostCenter(updatedUnlinkedList.sort((a, b) => a.value - b.value));
        setLinkedCostCenter((state) => [...state, ...updatedLinkedList]);
    };

    const onUnlinked = () => {
        const updatedLinkedList = linkedCostCenter.filter((costCenter) => {
            return !linkedChecked.includes(costCenter.value);
        });
        const updatedUnlinkedList = linkedCostCenter.filter((costCenter) => {
            return linkedChecked.includes(costCenter.value);
        });
        setLinkedChecked([]);
        setLinkedCostCenter(updatedLinkedList);
        setUnlinkedCostCenter((state) => [...state, ...updatedUnlinkedList].sort((a, b) => a.value - b.value));
    };

    return (
        <Modal
            width={800}
            centered={true}
            open={open}
            title={i18n.t("Centro de Custo vinculado ao pacote")}
            onCancel={handleCancel}
            className="gs-modal cost-center-linked-package-modal"
            cancelText={i18n.t<string>("close")}
            okText={i18n.t<string>("save")}
            onOk={() => {
                if (isSaving) return;
                const ids = linkedCostCenter.map((costCenter) => costCenter.value);
                onLinkCostCenter(ids);
            }}
        >
            <Row gutter={10} align={"middle"}>
                <Col span={11}>
                    <div>
                        <p className="gs-tag blue">Não Vinculado</p>
                        <Search placeholder={i18n.t("search")} onChange={(e) => setUnlinkedSearch(e.target.value)} />
                        <div className="cost-center-list-container">
                            <Checkbox.Group
                                value={unlinkedChecked}
                                options={unlinkedCostCenter.filter((item) => item.label.includes(unlinkedSearch))}
                                onChange={onChangeUnlinkedChecked}
                            />
                        </div>
                    </div>
                </Col>
                <Col span={2}>
                    <div className="gs-flex flex-col jc-center align-center">
                        <Button
                            onClick={onLinked}
                            icon={<Icon icon="ion:chevron-back-outline" hFlip={true} />}
                            style={{ marginBottom: 8 }}
                        />
                        <Button onClick={onUnlinked} icon={<Icon icon="ion:chevron-back-outline" />} />
                    </div>
                </Col>
                <Col span={11} style={{ alignSelf: "start" }}>
                    <p className="gs-tag blue">Vinculado</p>
                    <Search placeholder={i18n.t("search")} onChange={(e) => setlinkedSearch(e.target.value)} />
                    <div className="cost-center-list-container">
                        <Checkbox.Group
                            value={linkedChecked}
                            options={linkedCostCenter.filter((item) => item.label.includes(linkedSearch))}
                            onChange={onChangeLinkedChecked}
                        />
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}
