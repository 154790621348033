import { Icon } from "@iconify/react";
import { Button, DatePicker, DatePickerProps, Form, Popconfirm, Select, SelectProps, Space, notification } from "antd";
import FormItem from "antd/es/form/FormItem";
import Modal from "antd/lib/modal/Modal";
import Table, { ColumnsType } from "antd/lib/table";
import { ImageBox } from "components/imageBox/ImageBox";
import { Notification } from "components/notification/Notification";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { IOrganziationPeriod } from "../IBudgetIntegrations";

export default function DataRequestModal({ onClose, open }) {

    interface DataType {
        key: string;
        process: string;
        period: number;
    }

    const [form] = Form.useForm();
    const [tableData, setTableData] = useState([])
    const [editingIndex, setEditingIndex] = useState(null);
    const [optionsSelect, setOptionsSelect] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [organizationPeriods, setOrganizationPeriods] = useState([]);
    const [selectedOrganizationPeriod, setSelectedOrganizationPeriod] = useState(null);
    const [invalidPeriodOnControlPanel, setInvalidPeriodOnControlPanel] = useState(false);
    const { userInfo } = useUserContext();

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/budget-base/integration-parameterization/fields-lookup?locale={locale}"
        }, (response) => {
            const options = response.map((option) => ({
                label: option.label,
                value: option.integration
            }))
            setOptionsSelect(options);
        });

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/budget-base/integration-parameterization/organization-periods?organization={organization}&scenario={scenario}"
        }, (organizationPeriods: IOrganziationPeriod[]) => setOrganizationPeriods(organizationPeriods.map(organizationPeriod => {
            return {
                ...organizationPeriod,
                start: moment(organizationPeriod.start, "YYYY-MM"),
                end: moment(organizationPeriod.end, "YYYY-MM")
            }
        })));
    }, []);

    useEffect(() => {
        setButtonDisabled(tableData.length === 0);
    }, [tableData]);

    const onChange: DatePickerProps['onChange'] = (date) => {
        form.setFieldsValue({
            period: date
        })
    };

    const handleEdit = (rowData) => {
        const editList = [...tableData]
        const index = editList.findIndex(item => item.key === rowData.key);
        if (index !== -1) {
            const editData = editList[index];
            form.resetFields();
            form.setFieldsValue({
                process: editData.process,
                period: moment(editData.period, 'MM/YYYY'),
            });
            handleIntegrationTypeChange(editData.process);
            editList.splice(index, 1);
            setTableData(editList);
        } else {
            Notification({
                type: "error",
                message: i18n.t<string>("not_found"),
            })
        }
    }

    const onLoadSave = () => {
        Notification({
            type: "success",
            message: i18n.t<string>("successfully_saved"),
        })
    }

    const handleDelete = (rowData) => {
        const newData = [...tableData];
        newData.splice(rowData.key, 1);
        setTableData(newData);
        Notification({
            type: "success",
            message: i18n.t<string>("successfully_deleted"),
        })
    };

    const onSave = () => {
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/budget-base/integration-parameterization/create-integration-request-if-absent?user=${userInfo.id}&scenario={scenario}&locale=${userInfo.localeId}&yearMonth=${moment().format('YYYY-MM')}&integration=${optionsSelect.map((option) => option.value)}`,
            params: tableData.map((data) => {
                return {
                    process: data.process,
                    period: data.period
                }
            })
        }, onLoadSave, handleErrorRequest)
        onClose();
    }

    const onFinish = (formData) => {
        const newData = {
            key: (tableData.length + 1).toString(),
            process: formData.process,
            period: formData.period.format('MM/YYYY'),
        }

        if (editingIndex !== null) {
            handleEdit(newData);

        } else {
            setTableData([...tableData, newData]);
        }

        form.resetFields();
    }

    const columns: ColumnsType<DataType> = [
        {
            title: i18n.t<string>("process"),
            dataIndex: 'process',
            key: 'process',
        },
        {
            title: i18n.t<string>("period"),
            dataIndex: 'period',
            key: 'period',
            width: '25%',

        },
        {
            title: '',
            key: 'action',
            align: 'center',
            width: '10%',
            render: (_, rowData) => (
                <Space>
                    <Icon
                        icon="ic:outline-edit"
                        style={{ cursor: 'pointer', fontSize: '17px' }}
                        onClick={() => handleEdit(rowData)}
                    />
                    <Popconfirm
                        title={i18n.t<string>("delete_the_process_from_the_update_list")}
                        onConfirm={() => handleDelete(rowData.key)}
                        okText={i18n.t<string>("yes")}
                        cancelText={i18n.t<string>("cancel")}
                    >
                        <Icon
                            icon="ic:baseline-delete"
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                        />
                    </Popconfirm>
                </Space>
            )
        }
    ];

    const handleIntegrationTypeChange = (value: { value: string}): void => {
        const organizationPeriod = organizationPeriods.find(({ integration }) => integration === value);

        if (organizationPeriod && organizationPeriod.start.isValid() && organizationPeriod.end.isValid()) {
            setInvalidPeriodOnControlPanel(false);
            setSelectedOrganizationPeriod(organizationPeriod);
            onChange(organizationPeriod.start, organizationPeriod.start.format("MM/YYYY"));
        } else {
            notification.error({
                message: i18n.t<string>("control_panel_texts.text_0001"),
                duration: 3
            });

            onChange(null, null);
            setInvalidPeriodOnControlPanel(true);
        }
    };

    const disabledDate = (current: moment.Moment): boolean => {
        let isDisabled: boolean = false;

        if (selectedOrganizationPeriod != null) {
            const endPeriod = selectedOrganizationPeriod.end.clone().add(1, "month");

            isDisabled = !current.isBetween(selectedOrganizationPeriod.start, endPeriod);
        }

        return isDisabled;
    };

    return (
        <Modal
            className="gs-modal-data-request"
            title={i18n.t("request_erp_data")}
            width={900}
            open={open}
            onOk={onSave}
            onCancel={onClose}
            okButtonProps={{ disabled: buttonDisabled }}
            okText={i18n.t<string>("save")}
            cancelText={i18n.t<string>("cancel")}
        >
            <Form
                className="data-request-form"
                form={form}
                name="data-request-ERP"
                layout="vertical"
                onFinish={onFinish}
            >
                <div className="div-form">
                    <div >
                        <Form.Item
                            name="process"
                            label={i18n.t<string>("process")}
                            rules={[{ required: true }]}
                        >
                            <Select
                                className="select-data-request"
                                style={{ width: '508px' }}
                                placeholder={i18n.t<string>("select")}
                                options={optionsSelect}
                                onChange={handleIntegrationTypeChange}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <FormItem
                            name="period"
                            label={i18n.t<string>("period")}
                            rules={[{ required: true }]}
                        >
                            <DatePicker
                                allowClear={false}
                                format={"MM/YYYY"}
                                placeholder={i18n.t<string>("select")}
                                className="gs-date-picker"
                                style={{ width: '230px' }}
                                onChange={onChange}
                                picker="month"
                                disabled={invalidPeriodOnControlPanel}
                                disabledDate={disabledDate}
                            />

                        </FormItem>
                    </div>
                </div>
                <div className="div-button">
                    <Button
                        htmlType="submit"
                        className="button-add"
                        icon={<Icon icon="akar-icons:circle-plus-fill" />}>
                        {i18n.t("add")}
                    </Button>
                </div>
            </Form>
            {tableData.length > 0 ? (
                <Table
                    className="table-data-request"
                    columns={columns}
                    dataSource={tableData}
                    pagination={{ pageSize: 5 }}
                    scroll={{ y: 100 }}
                />
            ) : (
                <ImageBox />
            )
            }
        </Modal>
    )
}
