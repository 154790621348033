import { Button, Form } from "antd";
import { OrganizationBudgetDates, RevisionFormData, getOrderByModuleType } from "../../IControlPanel";
import { useEffect, useState } from "react";
import moment from "moment";
import { NewAnnualPanel } from "./NewAnnualPanel";
import { Icon } from "@iconify/react";
import { NewFiscalYearSelect } from "./NewFiscalYearSelect";
import { AnnualPanel } from "./AnnualPanel";
import { RevisionPeriodModal } from "../RevisionPeriodModal";
import { ModuleType, Options } from "util/types/types";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Notification } from "components/notification/Notification";
import { cloneDeep } from "lodash";
import { invalidadeQuery, queryClient } from "util/queryClient";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import i18n from "util/base/i18n";

interface IOrganizationPanel {
    organization: OrganizationBudgetDates;
    moduleType: ModuleType;
    isRevisionModalOpen: boolean;
    setIsRevisionModalOpen(b: boolean): void;
    organizationList: OrganizationBudgetDates[]
    segmentTypeOptions: Options[];
    unitConversionOptions: Options[];
}

export function OrganizationPanel({
    organization,
    moduleType,
    isRevisionModalOpen,
    setIsRevisionModalOpen,
    organizationList,
    segmentTypeOptions,
    unitConversionOptions
}: IOrganizationPanel) {
    const [selectedNewFiscalYear, setSelectedNewFiscalYear] = useState<moment.Moment>(null)
    const [revisionYear, setRevisionYear] = useState(0)
    const [isSettingYear, setIsSettingYear] = useState(false)
    const [showNewAnnualPanel, setShowNewAnnualPanel] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        setIsSettingYear(false)
        setSelectedNewFiscalYear(null)
    }, [organization])

    function handleAddNewFiscalYear() {
        setIsSettingYear(false)
        setShowNewAnnualPanel(true)
        form.setFieldsValue({ fiscalYear: selectedNewFiscalYear?.year() })
    }

    function onOpenRevisionModal(year: number) {
        setRevisionYear(year)
        setIsRevisionModalOpen(true)
    }

    function handleSaveRevisionDates(data: RevisionFormData) {
        setIsRevisionModalOpen(false)
        const revision = {
            reviewPeriodStartDate: data.openPeriod[0].valueOf(),
            reviewPeriodEndDate: data.openPeriod[1].valueOf(),
            budgetStartDate: data.revisionPeriod[0].date(15).valueOf(),
            budgetEndDate: data.revisionPeriod[1].date(15).valueOf(),
        }
        //@ts-ignore
        const organizationControlId = organization?.[moduleType].find(item => item.year === revisionYear).id
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: 'budget-base/control-panel/revision',
            params: {
                organizationControlId,
                scenario: organization.scenario,
                module: getOrderByModuleType[moduleType],
                ...revision
            }
        }, () => {
            Notification({
                type: 'success',
                message: i18n.t("successfully_saved")
            })
            invalidadeQuery(['budget-period-dates', moduleType])
            const updatedOrgList = cloneDeep(organizationList)
            const index = updatedOrgList.findIndex(org => org.organizationId === organization.organizationId)
            const org = updatedOrgList[index]
            let item;
            if (moduleType === ModuleType.EXPENSERESOURCES) {
                item = org.expensesResources.find(dates => dates.year === revisionYear)
            } else if(moduleType === ModuleType.REVENUE) {
                item = org.revenue.find(dates => dates.year === revisionYear)
			} else if (moduleType === ModuleType.PLANNING) {
                item = org.planning.find(dates => dates.year === revisionYear)
			}

            if (item.revisions) {
                item.revisions.push(revision)
            } else {
                item.revisions = [revision]
            }
            updatedOrgList.splice(index, 1, org)
            queryClient.setQueryData(['organizationList'], updatedOrgList)
        }, handleErrorRequest)
    };

    return (
        <>
            <div className="organization-panel-container">
                <div className="organization-panel-header">
                    <div className="gs-flex align-center">
                        <Icon icon="fa6-solid:city" />
                        <h3>{organization?.organizationName}</h3>
                    </div>
                    <Button
                        className="gs-main-button"
                        onClick={() => {
                            if(isSettingYear) return
                            setIsSettingYear(true)
                        }}
                    >
                        {i18n.t("new_fiscal_year")}
                    </Button>
                </div>
                {isSettingYear &&
                    <NewFiscalYearSelect
                        setIsSettingYear={setIsSettingYear}
                        handleAddNewFiscalYear={handleAddNewFiscalYear}
                        setSelectedNewFiscalYear={setSelectedNewFiscalYear}
                        selectedNewFiscalYear={selectedNewFiscalYear}
                        organization={organization}
                        moduleType={moduleType}
                    />
                }
                {showNewAnnualPanel &&
                    <NewAnnualPanel
                        form={form}
                        selectedNewFiscalYear={selectedNewFiscalYear}
                        setSelectedNewFiscalYear={setSelectedNewFiscalYear}
                        setShowNewAnnualPanel={setShowNewAnnualPanel}
                        segmentTypeOptions={segmentTypeOptions}
                        unitConversionOptions={unitConversionOptions}
                        moduleType={moduleType}
                        organization={organization}
                        organizationList={organizationList}
                    />
                }
                {organization[moduleType] && organization[moduleType]
                    .sort((a, b) => {
                        if (a.year > b.year) return -1
                        return 1
                    })
                    .map(dates => (
                        <AnnualPanel
                            key={`${organization.id}-${dates.year}`}
                            organization={organization}
                            moduleType={moduleType}
                            year={dates.year}
                            openBudget={[dates[`${moduleType}BudgetStart`], dates[`${moduleType}BudgetEnd`]]}
                            openPeriod={[dates[`${moduleType}OpeningStart`], dates[`${moduleType}OpeningEnd`]]}
                            frozenPeriod={dates[`${moduleType}FrozenPeriod`]}
                            realizedPeriod={dates[`${moduleType}RealizedPeriod`]}
                            onOpenRevisionModal={onOpenRevisionModal}
                            segmentTypeOptions={segmentTypeOptions}
                            unitConversionOptions={unitConversionOptions}
                            revisions={dates.revisions}
                            hasAnnualBinding={dates.hasAnnualBinding}
                            segmentType={dates.segmentType}
                            unitConversionId={dates.unitConversionId}
                            revenueBasePeriod={dates.revenueBasePeriod}
                        />
                    ))
                }
            </div>
            <RevisionPeriodModal
                organization={organization}
                isOpen={isRevisionModalOpen}
                setIsOpen={setIsRevisionModalOpen}
                handleSubmit={handleSaveRevisionDates}
                revisionYear={revisionYear}
                moduleType={moduleType}
            />
        </>
    )

}