import { Icon } from "@iconify/react";
import { Button } from "antd";
import { IPlanningConditionProps } from "../../../../IRegistrationSalesReports";
import { filterOperationToSignal } from "./constants";
import i18n from "util/base/i18n";

export default function PlanningCondition({
	conditions,
	handleRemove,
	handleEdit
}: IPlanningConditionProps) {

	return (
		<>
			{
				conditions?.map(({
					fromAccountingExternalCode,
					fromCostCenterExternalCode,
					toAccountingExternalCode,
					toCostCenterExternalCode,
					signal,
					id
				}) => {
					return (
						<div key={id} className="attribute_parameterization-new-attribute-modal-rule">
							{i18n.t("new_sales_report.from_account")}
							<div className="attribute_parameterization-new-attribute-modal-rule-label">
								{fromAccountingExternalCode}
							</div>
							{i18n.t("till")}
							<div className="attribute_parameterization-new-attribute-modal-rule-label">
								{toAccountingExternalCode}
							</div>
							-
							{i18n.t("new_sales_report.from_cost_center")}
							<div className="attribute_parameterization-new-attribute-modal-rule-label">
								{fromCostCenterExternalCode}
							</div>
							{i18n.t("till")}
							<div className="attribute_parameterization-new-attribute-modal-rule-label">
								{toCostCenterExternalCode}
							</div>
							{filterOperationToSignal[signal]}
							<div className="attribute_parameterization-new-attribute-modal-rule-options">
								<Button
									className="top-tool-buttons edit"
									onClick={() => handleEdit(id)}
									icon={<Icon icon="icomoon-free:pencil" />}
									title={i18n.t<string>("edit")}
								/>
							</div>
							<div className="attribute_parameterization-new-attribute-modal-rule-options">
								<Button
									className="top-tool-buttons edit"
									icon={<Icon icon="mdi:trash" />}
									onClick={() => handleRemove(id)}
									title={i18n.t("delete")}
								/>
							</div>
						</div>
					)
				})
			}
		</>
	)
}