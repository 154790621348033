import { UseQueryOptions } from '@tanstack/react-query';
import useCachedQuery from "./useCachedQuery";
import { RequestType } from "util/service/IServiceCaller";
import { Options } from "util/types/types";

export function useAdditionalFieldsOptions(queryProps = {} as UseQueryOptions<Options[]>) {
    const queryInfo = useCachedQuery<Options[]>(
        ['additional-fields-options'],
        {
            type: RequestType.GET,
            url: '/human-resources/fields/indexing',
        },
        queryProps,
        (data) => data.map((item) => ({
            label: item.description,
            value: item.id,
        }))
    )
    
    return queryInfo;
}

