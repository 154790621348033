import { Affix, Col, Layout, Modal, Row } from "antd";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import { FilterType, Filters, Formula, FormulaType, IStepTwoProps, LevelFilter, LevelFilterPlanning, LevelFormula, LevelReport, Report, ReportType } from "../../../IRegistrationSalesReports";
import TableSideBar from "./TableSideBar";
import OptionsList from "./content/OptionsList";
import LineFilter from "./content/LineFilter";
import FormulaContent from "./content/FormulaContent";
import { useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { FlexFieldValue, FormulaItem, ICondition, IConditionGrouperType, ILevel, ILevelsValue, RuleConditionOperations } from "module/budget/pages/revenue/attributeParameterization/IAttributeParameterization";
import { Notification } from "components/notification/Notification";
import { useForm } from "antd/es/form/Form";
import LoadingChanges from "module/budget/pages/revenue/mainFlow/grid/header/LoadingChanges";
import { useNewReportContext } from "../../NewReportContext";

export default function StepTwo({ report, setReport, optionsForFormula }: IStepTwoProps) {
	const [selectedRowKey, setSelectedRowKey] = useState<number>(getFirstLevelSelect(report.levels));
	const [selectedRowFormulasFormated, setSelectedRowFormulasFormated] = useState<FormulaItem[]>([]);
	const [selectedRowFormulas, setSelectedRowFormulas] = useState<Formula[]>([]);
	const [selectedRowFilters, setSelectedRowFilters] = useState<ICondition[]>([]);
	const [selectRowPlanningFilters, setSelectedRowPlanningFilters] = useState<LevelFilterPlanning[]>([]);
	const [addedOption, setAddedOption] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
	const [hasSavedValue, setHasSavedValue] = useState<boolean>(false);
	const [selectedOptionsList, setSelectedOptionsList] = useState<string[]>([]);
	const [pendingLevelSelected, setPendingLevelSelected] = useState<LevelReport>(null);
	const [formula, setFormula] = useState<FormulaItem[]>(selectedRowFormulasFormated);
	const [levelsValue, setLevelsValue] = useState<ILevelsValue[]>([]);
	const [levels, setLevels] = useState<ILevel>();
	const [operations, setOperations] = useState<RuleConditionOperations>();
	const [classNameForPulseButtonFilter, setClassNameForPulseButtonFilter] = useState("");
	const [classNameForPulseButtonFormula, setClassNameForPulseButtonFormula] = useState("");
	const [container, setContainer] = useState<HTMLDivElement | null>(null);
	const [filterType, setFilterType] = useState<FilterType>(FilterType.FLEX_FIELD);
	const { isPlanningReport } = useNewReportContext();
	const flattenHierarchy = (lines: LevelReport[] = []): LevelReport[] => {
		let newLevels: LevelReport[] = []

		lines.forEach((level) => {
			newLevels.push(level)
			if (level.children?.length) {
				newLevels = newLevels.concat(flattenHierarchy(level.children));
			}
		})
		return newLevels.sort(((a, b) => a.ordination > b.ordination ? 1 : a.ordination === b.ordination ? 0 : -1));
	}
	const allFormulaLevels = flattenHierarchy(report.levels).filter(({ formulas }) => formulas).map(({ levelFormula }) => levelFormula);
	const optionsFormulaFiltered = optionsForFormula.filter(({ value }) => value.startsWith("ATTRIBUTE") || validateLevelsOnFormula(value));
	const [availableOperations, setAvailableOperations] = useState([]);
	const [flexFieldsValues, setFlexFieldsValues] = useState<FlexFieldValue[]>([]);
	const [conditions, setConditions] = useState<ICondition[]>([]);
	const [form] = useForm();

	function validateLevelsOnFormula(value: string): boolean {
		const idLevel = Number(value.split("-")[1]);
		const formulaToLevel = allFormulaLevels.find(({ belongsToId }) => belongsToId === idLevel);
		if (formulaToLevel?.formulas.some(({ levelId }) => levelId === selectedRowKey)) return false;
		return true;
	}

	function getFirstLevelSelect(levels: LevelReport[]): number {
		let idLevel: number = -1;
		for (let index = 0; index < levels.length; index++) {
			const { children, id }: LevelReport = levels[index];
			if (children?.length) {
				idLevel = children[0].id;
				break;
			} else {
				idLevel = id;
				break
			}
		}
		return idLevel
	}

	function onSaveformula(formula: Formula[]) {
		let newReport: Report = report;
		for (let index = 0; index < newReport.levels.length; index++) {
			const line = newReport.levels[index];
			if (line.id === selectedRowKey) {
				line.levelFormula = { belongsToId: line.id, formulas: formula };
				newReport.levels[index] = line;
				setReport(newReport);
				break;
			}
			if (line.children) {
				let findedLine = line.children.find(({ id }) => id === selectedRowKey)
				if (findedLine?.id === selectedRowKey) {
					const findedLineIndex = line.children.findIndex(({ id }) => id === selectedRowKey)
					findedLine.levelFormula = { belongsToId: findedLine.id, formulas: formula };
					line.children[findedLineIndex] = findedLine;
					newReport.levels[index] = line;
					setReport(newReport);
					break;
				}
			}
		}

		setIsLoading(false);
		setHasSavedValue(true);
		setHasUnsavedChanges(false);
		const level: LevelReport = findNextLevelToSelect(selectedRowKey);
		if (level) {
			setSelectedRowKey(level.id);
			setSelectedRowFormulasFormated(buildFormatedFormula(level.levelFormula));
			setSelectedRowFormulas(level.levelFormula?.formulas);
		}
		if (pendingLevelSelected) {
			setSelectedRowKey(pendingLevelSelected.id);
			setPendingLevelSelected(null);
		}
	}

	function isFormulaCorrect(formulas: FormulaItem[]): boolean {
		if (formula.length % 2 === 0 && formulas.length > 0) return false
		let prevType: "attribute" | "operator" | "value" | null = null;
		let firstParenthesis: number = -1;
		let lastParenthesis: number = -1;
		let currentFormulaOnParenthesis: FormulaItem[] = [];
		formulas.filter(({ content }) => content.toString() === "left_parenthesis" || content.toString() === "right_parenthesis");
		for (let index = 0; index < formulas.length; index++) {
			const { type, content } = formulas[index];
			if (firstParenthesis) {
				currentFormulaOnParenthesis.push(formulas[index]);
			} else if (lastParenthesis) {
				if (!isFormulaCorrect(currentFormulaOnParenthesis)) return false;
			}
			if (type === "operator") {
				if (content === "left_parenthesis") {
					firstParenthesis = index
					continue;
				} else if (content === "right_parenthesis") {
					lastParenthesis = index
					continue;
				}
				if (index === 0) return false;
			}
			if (prevType === type) return false;

			prevType = type;
		}
		return true
	}

	function findNextLevelToSelect(currentKey: number): LevelReport {
		let flattedLevels: LevelReport[] = flattenHierarchy(report.levels);
		let indexLineToSelect = flattedLevels.findIndex(({ id }) => id === currentKey);
		let newLevelToSelect: LevelReport = flattedLevels[indexLineToSelect];
		flattedLevels = flattedLevels.filter((_, index) => index > indexLineToSelect);
		const searchLevel = (levels: LevelReport[]) => {
			for (let index = 0; index < levels.length; index++) {
				const level = levels[index];
				if (level.title) continue;
				if (level.isSubLevel) {
					newLevelToSelect = level;
					break;
				} else if (level.children?.length) {
					newLevelToSelect = levels[index + 1];
					break;
				} else {
					newLevelToSelect = levels[index];
					break;
				}
			}
		}
		searchLevel(flattedLevels);
		return newLevelToSelect
	}

	const chooseType = {
		"+": "plus",
		"-": "minus",
		"*": "times",
		"/": "divide",
		"(": "left_parenthesis",
		")": "right_parenthesis",
		"value": "value"
	};

	const chooseTypeOperation = {
		"plus": "+",
		"minus": "-",
		"times": "*",
		"divide": "/",
		"left_parenthesis": "(",
		"right_parenthesis": ")",
		"value": "value"
	};

	function buildFormatedFormula(levelFormula: LevelFormula): FormulaItem[] {
		return (
			levelFormula?.formulas
				.sort(((a, b) => a.ordination > b.ordination ? 1 : a.ordination === b.ordination ? 0 : -1))
				.map(({ type, attributeId, levelId, operator, value }) => {
					const keyItem = FormulaType.OPERATORS === type ? Math.random() : FormulaType.ATTRIBUTE === type ? attributeId : FormulaType.LEVEL === type ?
						levelId : "";
					const typeItem = FormulaType.OPERATORS === type ? "operator" : FormulaType.INFORMED_VALUE === type ? "value" : "attribute";

					return ({
						content: FormulaType.OPERATORS === type ? chooseType[operator] : { id: keyItem, name: getFormattedLabelLevelFormula(type, keyItem) },
						type: typeItem,
						key: `${FormulaType[type]}-${keyItem}`,
						value: value
					})
				}) || []
		);
	}

	function getFormattedLabelLevelFormula(type: FormulaType, keyItem: number | ''): string {
		const label = optionsForFormula.find(({ value }) => value.toString().includes(`${type}-${keyItem}`))?.label;
		return FormulaType.LEVEL === type ? `${i18n.t("report")}: ${label}` : label;
	}

	const onCancelChangeFormulas = () => {
		setSelectedOptionsList(selectedRowFormulasFormated
			.filter(({ key }) => key.toString().startsWith("LEVEL") || key.toString().startsWith("ATTRIBUTE"))
			?.map(({ key }) => key));
		setSelectedRowFormulasFormated([...selectedRowFormulasFormated]);
		setHasUnsavedChanges(false);
	};

	function onClickSaveFilter(conditions: ICondition[], planningCondition: LevelFilterPlanning[]) {
		let reportSource: Report = report;
		let levelFilters: { filters?: ICondition[], levelFilter: LevelFilter };

		if (isPlanningReport()) {
			const conditionsFormatted: Filters[] = planningCondition?.map((condition) => {
				return ({
					filterPlanning: condition,
					id: condition.id
				})
			});
			levelFilters = {
				levelFilter: {
					belongsToId: selectedRowKey,
					filters: conditionsFormatted
				}
			}
		} else {
			const conditionsFormatted: Filters[] = conditions?.map((condition) => {
				return ({
					id: Number(condition.id),
					operation: condition.operations,
					flexFieldId: Number(condition.selectedLevel.id),
					flexFieldValueId: Number(condition.selectedLevelValue.id),
					filterType: condition.filterType,
					managementAccountingAccountId: Number(condition.selectedLevelValue.id),
					managementCostCenterId: Number(condition.selectedLevelValue.id)
				})
			}) || [];
			levelFilters = {
				filters: conditions,
				levelFilter: {
					belongsToId: selectedRowKey,
					filters: conditionsFormatted
				}
			};
		}
		reportSource.levels = doUdpateReportLevelProp(levelFilters, reportSource.levels);

		setReport({ ...reportSource });
	}

	const doUdpateReportLevelProp = (updatedProps: any, levels: LevelReport[] = report.levels): LevelReport[] => {
		return levels.map(level => {
			if (level.id === selectedRowKey) {
				level = {
					...level,
					...updatedProps
				};
			} else if (level.children && level.children.length) {
				level.children = doUdpateReportLevelProp(updatedProps, level.children);
			}

			return level;
		});
	};

	function validateAndSaveFormula() {
		if (!isFormulaCorrect(formula)) {
			Notification({
				message: i18n.t("incorrect_formula"),
				type: "warning"
			})
			return false;
		}

		if (pendingLevelSelected?.levelFormula && validateFormulaIsEqual(pendingLevelSelected?.levelFormula)) {
			return false;
		}
		onSaveformula(formula.map(({ content, key, value }, index) => {
			const [typeOperator, id] = (key as string).split("-");
			const typeFormula: FormulaType = FormulaType[typeOperator];
			setIsLoading(true);
			return {
				ordination: index,
				type: typeFormula,
				attributeId: typeFormula === FormulaType.ATTRIBUTE ? Number(id) : null,
				levelId: typeFormula === FormulaType.LEVEL ? Number(id) : null,
				operator: typeFormula === FormulaType.OPERATORS ? chooseTypeOperation[content] : null,
				value: value
			}
		}));

		return true;
	}

	const onAcceptFormulaChanges = () => {
		const isFormulaValid = validateAndSaveFormula();
		const isFilterValid = validateAndSaveFilter();

		if (isFilterValid && isFormulaValid) {
			setIsLoading(false);
			setHasSavedValue(true);
			setHasUnsavedChanges(false);
			const level: LevelReport = findNextLevelToSelect(selectedRowKey);
			if (level) {
				setSelectedRowKey(level.id);
				setSelectedRowFormulasFormated(buildFormatedFormula(level.levelFormula));
				setSelectedRowFormulas(level.levelFormula?.formulas);
			}
			if (pendingLevelSelected) {
				setSelectedRowKey(pendingLevelSelected.id);
				setPendingLevelSelected(null);
			}
		}
	};

	function removeKeyFormula(formulaToRemove: FormulaItem[]): FormulaItem[] {
		return formulaToRemove.map(({ content, type, value }) => {
			const newContent = Number(content.id) ?
				content :
				{ id: '', name: i18n.t(`new_sales_report.options_formula.${type}`) };
			return {
				content: newContent,
				type,
				value
			}
		});
	}

	function validateFormulaIsEqual(levelFormulas: LevelFormula): boolean {
		const formattedCurrentFormula: string = JSON.stringify(removeKeyFormula(formula));
		if (!formattedCurrentFormula) return true;
		const formattedToCompareFormula: string = JSON.stringify(removeKeyFormula(buildFormatedFormula(levelFormulas)));
		return formattedCurrentFormula === formattedToCompareFormula;
	}

	function isChangedFormula(record: LevelReport): boolean {
		return !validateFormulaIsEqual(record.levelFormula);
	}

	function handleCancelPendingLevel() {
		if (!validateFormulaIsEqual(pendingLevelSelected.levelFormula)) {
			setClassNameForPulseButtonFormula("pulsating-button");
		}

		if (levels) {
			setClassNameForPulseButtonFilter("pulsating-button");
		}
		setPendingLevelSelected(null);
		setTimeout(() => {
			setClassNameForPulseButtonFormula("");
			setClassNameForPulseButtonFilter("");
		}, 6100);
	}

	function validateFilter(): boolean {
		if (isPlanningReport() && conditions.length) {
			let isFilterValid: boolean = true;
			isFilterValid = conditions.some(({ selectedLevel: { id } }, _, allConditions) => {
				if (id === "management_cost_center") {
					return allConditions.some(({ selectedLevel }) => selectedLevel.id === "management_accounting_account");
				} else {
					return allConditions.some(({ selectedLevel }) => selectedLevel.id === "management_cost_center");
				}
			})

			if (!isFilterValid) {
				Notification({
					message: i18n.t("new_sales_report.line_filter_error"),
					type: "warning"
				})

				return false;
			}
		}

		return true;
	}

	function validateAndSaveFilter(): boolean {
		if (validateFilter()) return false;
		handleCreateCondition();
		return true;
	}

	function handleCreateCondition() {
		setConditions((prevConditions) => {
			return ([
				...prevConditions,
				...levelsValue.map((levelValue) => {
					return ({
						grouperType: IConditionGrouperType.REVENUE,
						operations: operations,
						selectedLevel: levels,
						selectedLevelValue: levelValue,
						filterType: filterType
					})
				})
			])
		});
		setOperations(null);
		setLevels(null);
		setLevelsValue(null);
		setFlexFieldsValues([]);
		setHasUnsavedChanges(false);
	}

	useEffect(() => {
		setFormula(selectedRowFormulasFormated);
	}, [selectedRowFormulasFormated])

	useEffect(() => {
		setOperations(null);
		setLevels(null);
		setLevelsValue(null);
		setFlexFieldsValues([]);
		form.resetFields();
	}, [selectedRowKey])

	return (
		<div className="step-one step-two" ref={setContainer}>
			<Layout>
				<Sider
					theme="light"
					width={300}
				>
					<TableSideBar
						options={optionsForFormula}
						report={report}
						setReport={setReport}
						selectedRowKey={selectedRowKey}
						setSelectedRowKey={setSelectedRowKey}
						setSelectedRowFormulaFormated={setSelectedRowFormulasFormated}
						setSelectedRowFormula={setSelectedRowFormulas}
						hasUnsavedChanges={hasUnsavedChanges}
						setPendingLevelSelected={setPendingLevelSelected}
						setSelectedRowFilters={setSelectedRowFilters}
						isChangedFormula={isChangedFormula}
						isFilterValid={validateFilter}
						setSelectedRowPlanningFilters={setSelectedRowPlanningFilters}
					/>
				</Sider>
				<Layout>
					<Affix target={() => container} >
						<Content>
							<Row style={{
								display: 'flex',
								justifyContent: 'space-between'
							}}>
								<h3>
									{i18n.t<string>("link_reference_field")}
								</h3>
								<div style={{
									paddingTop: 5,
									marginTop: 30
								}}>
									<LoadingChanges
										isLoading={isLoading}
										hasSavedValue={hasSavedValue}
									/>
								</div>
							</Row>
							<Row gutter={20}>
								<Col span={8}>
									<OptionsList
										formulas={selectedRowFormulas}
										options={optionsFormulaFiltered}
										reportLevels={report.levels}
										onChangeCheckboxGroup={setAddedOption}
										selectedOptionsList={selectedOptionsList}
										setSelectedOptionsList={setSelectedOptionsList}
										selectedRowKey={selectedRowKey}
									/>
								</Col>
								<Col span={16}>
									<div>
										<LineFilter
											onClickSave={onClickSaveFilter}
											conditionsEdit={selectedRowFilters}
											levelsValue={levelsValue}
											setLevelsValue={setLevelsValue}
											setHasUnsavedChanges={setHasUnsavedChanges}
											levels={levels}
											operations={operations}
											setLevels={setLevels}
											setOperations={setOperations}
											classNameForPulseButton={classNameForPulseButtonFilter}
											availableOperations={availableOperations}
											conditions={conditions}
											flexFieldsValues={flexFieldsValues}
											setAvailableOperations={setAvailableOperations}
											setConditions={setConditions}
											setFlexFieldsValues={setFlexFieldsValues}
											form={form}
											setFilterType={setFilterType}
											filterType={filterType}
											planningConditionsEdit={selectRowPlanningFilters}
										/>
										<FormulaContent
											setSelectedOptionsList={setSelectedOptionsList}
											onSaveFormulas={onSaveformula}
											optionsSelected={addedOption}
											setIsLoading={setIsLoading}
											setHasUnsavedChanges={setHasUnsavedChanges}
											onCancelChanges={onCancelChangeFormulas}
											pendingLevelSelected={pendingLevelSelected}
											setPendingLevelSelected={setPendingLevelSelected}
											formula={formula}
											onAcceptFormulaChanges={validateAndSaveFormula}
											setFormula={setFormula}
											classNameForPulseButton={classNameForPulseButtonFormula}
											optionsForFormula={optionsForFormula}
											pendingLevelSelectedFormula={pendingLevelSelected?.levelFormula}
										/>
									</div>
								</Col>
							</Row>
						</Content>
					</Affix>
				</Layout>
			</Layout>
			<Modal title={i18n.t<string>("attention")} okText={i18n.t<string>("add")} visible={pendingLevelSelected != null} onCancel={handleCancelPendingLevel} onOk={onAcceptFormulaChanges}>
				<p>{i18n.t<string>("revenue.unsaved_changes")}</p>
			</Modal>
		</div>
	)
}