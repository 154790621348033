import { Layout } from "antd";
import Title from "./components/Title";
import GroupersGrid from "./components/GroupersGrid";
import { GroupersProvider } from "./components/GroupersContext";
import GroupersModal from "./components/GrouperModal";
import './ProjectionGroupers.sass';

export default function ProjectionGroupers() {
	return (
		<GroupersProvider>
			<Layout className="light projection-groupres-page">
				<Layout.Header>
					<Title />
				</Layout.Header>
				<Layout.Content>
					<GroupersGrid />
				</Layout.Content>
				<GroupersModal />
			</Layout>
		</GroupersProvider>
	)
}