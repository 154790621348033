import { Icon } from "@iconify/react";
import { Button, Descriptions, Dropdown, Form, FormInstance, Input, Menu, Popconfirm, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useUserContext } from "context/UserContext";
import moment from "moment";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { tableProps } from "util/props/props";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";
import { IIndexerTab, Indexer, IndexerResponse, NewRateFormData, RateDataResponse, RateTableData } from "../IBudgetProjection";
import { NewIndexerModal } from "./NewIndexerModal";
import { ImportExportMenu } from "components/importExportMenu/ImportExportMenu";
import { handleExportGridData } from "util/functions/handleExportGridData";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { AddIndexerRateModal } from "./AddIndexerRateModal";

const { Search } = Input

export function IndexerTab({
    buttonTitle,
    functionalityPermissions,
}: IIndexerTab) {
    const [searchValue, setSearchValue] = useState('');
    const [searchMonth, setSearchMonth] = useState('');
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRateData, setSelectedRateData] = useState<RateTableData>({} as RateTableData);
    const [isNewIndexerModalOpen, setIsNewIndexerModalOpen] = useState<boolean>(false)
    const [isRateDataLoading, setIsRateDataLoading] = useState(true)
    const [isIndexerListLoading, setIsIndexerListLoading] = useState(true)
    const [rateTableData, setRateTableData] = useState<RateTableData[]>([])
    const [monthList, setMonthList] = useState<string[]>([])
    const [indexerList, setIndexerList] = useState<Indexer[]>([])
    const { userInfo } = useUserContext()
    const [isNewRate, setIsNewRate] = useState(false)
    const [isIndexerRateModalOpen, setIsIndexerRateModalOpen] = useState(false);
    const [form] = Form.useForm()
    const [isNewIndexer, setIsNewIndexer] = useState<boolean>(true);
    const dropdown =
        <Dropdown trigger={["click"]} overlay={
            <Menu>
                <Menu.Item className="menu-dropdown" onClick={() => handleOpenModal(false)}>
                    <Icon icon="icomoon-free:pencil" />&nbsp;
                    {i18n.t<string>("unit_of_measurement.edit_unit")}
                </Menu.Item>
            </Menu>
        } placement="bottomLeft">
            <Button><Icon icon="bi:three-dots-vertical" /></Button>
        </Dropdown>;

    const menuListFiltered = indexerList.filter((item) => item.label?.includes(searchValue)).map(item => {
        return {
            ...item,
            icon: dropdown,
        }
    });

    useEffect(() => {
        fecthIndexerList()
    }, [])

    useEffect(() => {
        if (selectedMenuItem.length === 0) return
        setIsRateDataLoading(true)
        fecthIndexerRateData()
    }, [selectedMenuItem])

    function handleOpenNewRateModal(isNew: boolean, item: any) {
        setIsNewRate(isNew);
        setIsIndexerRateModalOpen(true)
        if (isNew) {
            form.resetFields()
        } else {
            form.setFieldsValue({ rate: item.rate })
        }
    }

    function fecthIndexerRateData() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/indexer-tax?scenario={scenario}&indexer=${selectedMenuItem}`,
        }, (data: RateDataResponse[]) => {
            const updatedTableData = data.map(item => {
                return {
                    key: item.id,
                    indexerId: item.indexerId,
                    month: item.month,
                    rate: item.rate
                }
            }).sort((a, b) => {
                if (moment(a.month).isBefore(b.month)) return -1;
                return 1
            })
            setRateTableData(updatedTableData)
            setMonthList(updatedTableData.map(item => item.month))
            setIsRateDataLoading(false)
        }, () => setIsRateDataLoading(false))
    }
    function fecthIndexerList() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/indexer`,
        }, (data: IndexerResponse[]) => {
            const updatedMenuData: Indexer[] = data.map(indexer => {
                return {
                    key: indexer.id,
                    label: indexer.description,
                    externalCode: indexer.externalCode,
                    icon: dropdown,
                }
            })
            setIndexerList(updatedMenuData)
            setIsIndexerListLoading(false)
            if (updatedMenuData.length > 0) {
                setSelectedMenuItem(updatedMenuData[0].key.toString());
            }
        }, () => setIsIndexerListLoading(false))
    }

    function handleCreateNewIndexer(data) {

        setIsNewIndexerModalOpen(false)
        setIsIndexerListLoading(true)
        setSelectedRowKeys([])

        const dataToSave = {
            id: isNewIndexer ? null : selectedMenuItem,
            externalCode: data.externalCode,
            description: data.description,
        }

        ServiceCaller.doRequest({
            type: isNewIndexer ? RequestType.POST : RequestType.PUT,
            url: `/budget-base/indexer`,
            params: dataToSave
        }, () => {
            setIsIndexerListLoading(false)
            fecthIndexerList()
        })
    }

    function handleCreateIndexerRate(data: NewRateFormData) {

        const period = data.period.map(date => date ? date.format('YYYY-MM') : null);
        const periodToSave = period[1] ? period : [period[0], period[0]];

        const dataToSave = {
            scenarioId: userInfo.selection.scenarioId,
            indexerId: selectedMenuItem,
            period: periodToSave,
            rate: Number(data.rate.replace(',', '.')),
            increment: data.increment && Number(data.increment.replace(',', '.')),
        }
        form.resetFields()
        setIsIndexerRateModalOpen(false)
        setIsRateDataLoading(true)
        setSelectedRowKeys([]);
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/budget-base/indexer-tax`,
            params: dataToSave
        }, fecthIndexerRateData)
    }

    function handleDeleteRate() {
        setIsRateDataLoading(true)
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/budget-base/indexer-tax?ids=${selectedRowKeys}`,
        }, () => {
            setSelectedRowKeys([]);
            fecthIndexerRateData()
        })
    }

    function handleOpenModal(isNewIndexer: boolean) {
        if (isNewIndexer) {
            setIsNewIndexer(true);
            form.resetFields();
        } else {
            const valuesForm = indexerList.find(item => item.key.toString() == selectedMenuItem);
            form.setFieldsValue({
                externalCode: valuesForm.externalCode,
                description: valuesForm.label,
            });
            setIsNewIndexer(false);
        }
        setIsNewIndexerModalOpen(true);
    };

    const columns: ColumnsType = [
        {
            title: i18n.t('date'),
            dataIndex: "month",
            key: "date",
            align: "center",
            render: (month) => {
                return moment(month).format('MMM/YYYY')
            }
        }, {
            title: i18n.t('rate'),
            dataIndex: "rate",
            key: "rate",
            align: "center",
        }
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: Key[], selectedRow) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRateData(selectedRow[0]);
        }
    };

    return (
        <div className="budget-projection-tab-content">
            <div className="budget-projection-sidebar-container">
                <Space
                    className="budget-projection-sidebar-space-content"
                    direction="vertical"
                    size={0}
                >
                    <Search
                        placeholder={i18n.t('search')}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{ width: '100%' }}
                    />
                    <Button
                        icon={<Icon icon="ic:round-plus" />}
                        onClick={() => handleOpenModal(true)}
                        className="gs-secondary-button"
                    >
                        {i18n.t<string>("new_indexer")}
                    </Button>
                    {isIndexerListLoading ?
                        <Spin
                            style={{
                                margin: '50px auto 60px',
                                width: '100%'
                            }}
                            spinning={isIndexerListLoading}
                            tip={`${i18n.t('loading')}...`}
                        />
                        :
                        <Menu
                            onClick={(
                                { item, key }) => {
                                setSelectedMenuItem(key)
                            }
                            }
                            selectedKeys={[selectedMenuItem]}
                            mode="inline"
                            items={menuListFiltered}
                            defaultOpenKeys={menuListFiltered.length > 0 ? [menuListFiltered[0].key.toString()] : null}
                        />
                    }
                </Space>
            </div>
            <div className="budget-projection-table-container">
                <div className="budget-projection-table-operations">
                    <div style={{ display: "flex" }}>
                        {
                            functionalityPermissions.new &&
                            <Button
                                onClick={() => {
                                    setSelectedRowKeys([])
                                    handleOpenNewRateModal(true, {})
                                }}
                                className="gs-main-button"
                                style={{ marginRight: "5px" }}
                            >
                                {buttonTitle}
                            </Button>
                        }
                        {
                            functionalityPermissions.edit &&
                            <Button
                                className="budget-projection-table-operations-buttons edit"
                                onClick={() => handleOpenNewRateModal(false, selectedRateData)}
                                icon={<Icon icon="icomoon-free:pencil" />}
                                disabled={selectedRowKeys.length !== 1}
                            />
                        }
                        {
                            functionalityPermissions.remove &&
                            <Popconfirm
                                placement="bottom"
                                overlayClassName="budget-projection-table-operations-buttons"
                                title={i18n.t<string>("delete_confirm_message")}
                                onConfirm={handleDeleteRate}
                                disabled={selectedRowKeys.length === 0}
                                okText={i18n.t<string>("yes")}
                                cancelText={i18n.t<string>("cancel")}
                                okButtonProps={{ danger: true, className: 'popconfirm-delete-button' }}
                            >
                                <Button
                                    disabled={selectedRowKeys.length === 0}
                                    className="budget-projection-table-operations-buttons trash"
                                    icon={<Icon icon="icomoon-free:bin" />}
                                />
                            </Popconfirm>
                        }
                    </div >
                    <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                        <Search
                            placeholder={i18n.t('search')}
                            onChange={(e) => setSearchMonth(e.target.value)}
                            style={{ width: '100%' }}
                        />
                        {functionalityPermissions.export &&
                            <ImportExportMenu
                                exportGridData={() => handleExportGridData(rateTableData, columns, i18n.t("indexers"))}
                                buttonType="3dots"
                            />
                        }
                    </div>
                </div >
                <Table
                    columns={columns}
                    dataSource={rateTableData.filter(item => moment(item.month, 'YYYY-MM').format('MMM/YYYY').includes(searchMonth))}
                    loading={isRateDataLoading}
                    rowSelection={functionalityPermissions.edit || functionalityPermissions.remove ? rowSelection : null}
                    {...tableProps}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 12
                    }}
                />
            </div >
            <NewIndexerModal
                isOpen={isNewIndexerModalOpen}
                setIsOpen={setIsNewIndexerModalOpen}
                handleSubmit={handleCreateNewIndexer}
                indexerList={indexerList}
                selectedMenuItem={selectedMenuItem}
                form={form}
                isNewIndexer={isNewIndexer}
            />
            <AddIndexerRateModal
                form={form}
                isOpen={isIndexerRateModalOpen}
                setIsOpen={setIsIndexerRateModalOpen}
                handleSubmit={handleCreateIndexerRate}
                isNew={isNewRate}
                disableMonthList={monthList}
                selectedRateData={selectedRateData}
            />
        </div >
    )
}
