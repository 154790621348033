import { Modal } from "antd";
import i18n from "util/base/i18n";
import { useContext } from "react";

import { AttributeParameterizationContext } from "../../../context/AttributeParameterizationContext";

interface DeleteAttributeModalProps {
	description: string;
}

export default function DeleteAttributeModal({ description }: DeleteAttributeModalProps) {
	const { isDeleteAttributeModalOpen, closeDeleteAttributeModal } = useContext(AttributeParameterizationContext);

	return (
		<Modal
			visible={isDeleteAttributeModalOpen}
			okText={i18n.t<string>("close")}
			okButtonProps={{
				className: "gs-primary-button"
			}}
			title={i18n.t("attention")}
			onOk={() => closeDeleteAttributeModal()}
			cancelButtonProps={{ style: { visibility: "hidden" } }}
			destroyOnClose
		>
			{description}
		</Modal>
	)
}
