import {
	useRef,
	useState,
	useEffect,
	ChangeEvent
} from "react";

import { Spin, Tree } from "antd";
import Search from "antd/lib/input/Search";

import { IDataTable } from "./ISceneryFilters";
import StringUtil from "util/StringUtil";

interface Props {

	title: string

	data: Array<IDataTable>

	defaultSelected?: string

	onSelected: (s: number, e: any) => void

	selectedFilter: number

	useFilter?: boolean | false

	onStartSearch?: () => void

	onEndSearch?: () => void

	moveToSelected?: boolean | false

	isFetching?: boolean;

};

export default function SceneryFilters(props: Props) {

	const treeRef = useRef<any>();

	const [search, setSearch] = useState<string>("");

	const searchText: string = StringUtil.getWithoutSpecials(search);
	const treeData: IDataTable[] = props.data.filter(({ title }) => StringUtil.getWithoutSpecials(title).includes(searchText));

	function onChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
		if (value.length > 0 && props.onStartSearch) {
			props.onStartSearch();
		} else if (props.onEndSearch) {
			props.onEndSearch();
		}

        setSearch(value);
    }

	const onSelect = (selection: number[], event: any) => {
		props.onSelected(selection[0], event);
	}

	const clearSearch = () => {
		setSearch("");

		if (props.onEndSearch) {
			props.onEndSearch();
		}
	};

	useEffect(() => {
		if (props.moveToSelected) {
			clearSearch();

			setTimeout(() => {
				treeRef.current.scrollTo({
					key: props.selectedFilter,
					align: "top"
				});
			}, 0);
		}
	}, [props.selectedFilter]);

	return <div className="scenery-filters-main">
		<div className="sceneray-title">
			<p>
				{props.title}
			</p>
		</div>
		<Spin spinning={props.isFetching ? true : false}>
			<div className="sceneray-content">
				<Search value={search} onChange={onChange} />
				<Tree onSelect={onSelect} height={300} selectedKeys={[props.selectedFilter]} treeData={treeData} ref={treeRef} />
			</div>
		</Spin>
	</div>

}
