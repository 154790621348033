import i18n from "util/base/i18n";
import { Table } from "antd";
import { ScenarioProjectionTableProps } from "../../IScenarioProjection";

export function ScenarioProjectionTable({
  isFetching,
  tableData,
  selectedRowKeys,
  onChange,
  tableColumns
}: ScenarioProjectionTableProps) {

  const rowSelection = { selectedRowKeys, onChange };

  return (
    <Table
      loading={{
        spinning: isFetching,
        tip: i18n.t<string>("loading")
      }}
      className="gs-table"
      id="table-scenario"
      dataSource={tableData}
      columns={tableColumns}
      rowSelection={rowSelection}
      pagination={ false }
      bordered
    />
  );
}