import { CollabBudgetTables } from "./components/collabBudgetTables/CollabBudgetTables";
import { CollabResultTables } from "./components/collabResultTables/CollabResultTables";
import "./HiredDashboard.sass";
import { useNavigate } from "react-router-dom";
import {
    HiredTable,
    PeriodsValue,
    TableDataResponse,
    TableDataResultResponse,
    ValuesColumns,
    ValuesToSave,
} from "../IHumanResoucers";
import i18n from "util/base/i18n";
import { useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Icon } from "@iconify/react";
import { Button, Carousel, DatePicker, Popover } from "antd";
import "./HiredDashboard.sass";
import { CollaboratorSearch } from "../components/CollaboratorSearch";
import moment from "moment";
import ContributorInformation from "./components/contributorInformation/ContributorInformation";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import { useWindowSize } from "hooks/useWindowSize";
import { ColumnsType } from "antd/lib/table";
import { BackButton } from "components/button/BackButton";
import { Notification } from "components/notification/Notification";
import { log } from "mathjs";
import { ImageBox } from "components/imageBox/ImageBox";
import { Loading } from "components/loading/Loading";
import { PeriodBoardInfo } from "module/budget/components/PeriodBoardInfo/PeriodBoardInfo";
import { useBudgetDates } from "hooks/useBudgetDates";
import { ModuleType } from "util/types/types";

interface Props {
    resource: HiredTable;
    setDeitalId: (id: number) => void;
    hiredList: HiredTable[];
    year: string;
    setYear: (year: string) => void;
    setIsDashboard: (value: boolean) => void;
    getTableDataResorces: () => void;
    openedCalendarPeriod: number[];
}
export function HiredDashboard({
    resource,
    setDeitalId,
    hiredList,
    year,
    setYear,
    setIsDashboard,
    getTableDataResorces,
    openedCalendarPeriod,
}: Props) {
    const [tablesData, setTablesData] = useState<TableDataResponse>();
    const [tablesDataResult, setTablesDataResult] = useState<TableDataResultResponse>();
    const [isFetching, setIsFetching] = useState(true);
    const [isFetchingResult, setIsFetchingResult] = useState(true);
    const [valuesToSave, setValuesToSave] = useState<ValuesToSave[]>([]);
    const [admissions, setAdmissions] = useState(0);
    const [showCollabPopover, setShowCollabPopover] = useState(false);
    const [filtersPerRow, setFiltersPerRow] = useState(5);
    const [hasValues, setHasValues] = useState(false);
    const windowSize = useWindowSize();
    const { data: budgetPeriodDates } = useBudgetDates(ModuleType.EXPENSERESOURCES);

    useEffect(() => {
        fetchTableData();
    }, [year]);

    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 515) / 200);
        setFiltersPerRow(updatedFiltersPerRow);
    }, [windowSize]);

    useEffect(() => {
        const values =
            tablesData?.initialInformation.length > 0 ||
            tablesData?.legalTaxes.length > 0 ||
            tablesData?.transactions.length > 0 ||
            tablesData?.custom.length > 0 ||
            tablesDataResult?.resultQuantity.length > 0 ||
            tablesDataResult?.resultValues.length > 0;

        setHasValues(values);
    }, [tablesData, tablesDataResult]);

    function getTableData() {
        setIsFetching(true);
        setValuesToSave([]);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `human-resources/hiring/findValues?employedResourceId=${resource.id}${
                    year ? `&referenceYear=${year}` : ""
                }&organizationId={organization}&scenario={scenario}&userId={user}&locale={locale}&clientId={client}`,
            },
            onLoadDataTable,
            () => {
                setTablesData(null);
            }
        );
    }

    function getTableDataResult() {
        setIsFetchingResult(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `human-resources/hiring/findResult?employedResourceId=${resource.id}${
                    year ? `&referenceYear=${year}` : ""
                }&organizationId={organization}&scenario={scenario}&userId={user}&locale={locale}&clientId={client}`,
            },
            onLoadDataTableResult,
            () => {
                setTablesDataResult(null);
            }
        );
    }

    function fetchTableData() {
        getTableData();
        getTableDataResult();
    }

    function onLoadDataTable(data: TableDataResponse) {
        setYear(data.year);
        setTablesData(data);
        setIsFetching(false);
    }

    function onLoadDataTableResult(data: TableDataResultResponse) {
        setTablesDataResult({
            ...data,
            resultValues: [...data.resultValues, ...data.resultCustom],
        });
        setIsFetchingResult(false);
    }

    function setAllFetch() {
        setIsFetching(true);
        setIsFetchingResult(true);
    }

    function handleSaveCollaboratorBudget() {
        setIsFetching(true);
        setIsFetchingResult(true);

        let validateFinal: ValuesColumns[] = [];
        valuesToSave.forEach((value) => {
            const data = tablesData.transactions.find((line) => line.field.id === value.fieldId);
            if (data) {
                const periodValuesToSave = valuesToSave
                    .filter(
                        ({ fieldId }) =>
                            data.field.id == fieldId && validateFinal.every(({ field: { id } }) => id !== fieldId)
                    )
                    .map(({ period, entryValue }) => ({ period, value: entryValue }));
                if (data.periodsValues) validateFinal.push({ ...data, periodsValues: periodValuesToSave });
            }
        });

        if (validateFinal.length) {
            validateFinal = validateFinal.filter(({ field: { defaultDefinitions } }) =>
                ["LAYOFFS", "ALLOWANCE_OCCURRENCE", "VACATION_OCCURRENCE"].includes(defaultDefinitions)
            );
            let isValid = true;

            const resultQuantity = tablesDataResult.resultQuantity.find(
                (result) => result.field.defaultDefinitions == "CALCULATED_FINAL"
            );
            const entryValues = tablesData.transactions.find(
                (result) => result.field.defaultDefinitions == "VACATION_OCCURRENCE"
            );

            for (let index = 0; index < validateFinal.length; index++) {
                if (validateFinal[index].field.defaultDefinitions == "ALLOWANCE_OCCURRENCE") {
                    isValid = handleValidateValues(validateFinal[index].periodsValues, entryValues);
                } else {
                    isValid = handleValidateValues(validateFinal[index].periodsValues, resultQuantity);
                }

                if (!isValid) return;
            }
        }

        ServiceCaller.doRequest(
            {
                type: RequestType.PUT,
                params: valuesToSave,
                url: "human-resources/hiring/saveValues?scenarioId={scenario}&organizationId={organization}",
            },
            fetchTableData
        );
    }

    function handleValidateValues(periodsValues: PeriodsValue[], valueToValidate: ValuesColumns): boolean {
        let totalValue = 0;

        for (let index = 0; index < periodsValues.length; index++) {
            const value = periodsValues[index].value;
            const period = periodsValues[index].period;
            const valueFinded =
                valueToValidate.periodsValues.length > 0
                    ? valueToValidate.periodsValues.find((periodValue) => periodValue.period == period).value
                    : 0;

            totalValue += value;

            if (valueFinded < value || totalValue > valueFinded) {
                Notification({
                    type: "error",
                    message: `${i18n.t("human_resources.not_enough_value_in_the_field")} ${
                        valueToValidate.field.description
                    } ${i18n.t("human_resources.for_the_change")}`,
                });

                setIsFetching(false);
                setIsFetchingResult(false);

                return false;
            }
        }
        return true;
    }

    function onChangeDatePicker(date, dateString) {
        setYear(dateString);
        setIsFetching(true);
        setIsFetchingResult(true);
    }

    function disableDate(current: moment.Moment) {
        if (!openedCalendarPeriod.includes(current.year())) {
            return true;
        }
        return false;
    }

    return (
        <div className="collaborator-container">
            <div className="title-hr-collaborators">
                <div className="page-title-content">
                    <div className="gs-flex align-start flex-col">
                        <h1>{i18n.t("human_resources.human_resources")}</h1>
                        <BackButton onGoBack={() => (setDeitalId(0), setIsDashboard(false), getTableDataResorces())} />
                    </div>
                </div>
                {/* <SummaryCards link="/" /> */}
            </div>
            <div className="container-resource">
                <div className="infos-resource">
                    <div className="collaborator-name">
                        <div>
                            <Icon icon="mdi:user" /> <h3>{resource.resourceName}</h3>
                        </div>
                        <Popover
                            visible={showCollabPopover}
                            trigger="click"
                            placement="bottom"
                            content={
                                <CollaboratorSearch
                                    hiredList={hiredList}
                                    onCloseSearch={setShowCollabPopover}
                                    setDeitalId={setDeitalId}
                                />
                            }
                            overlayClassName="overlay-operation-bar-hr"
                        >
                            <Button onClick={() => setShowCollabPopover(true)} className="collaborator-search">
                                <p>{i18n.t("human_resources.search_by_contributor")}</p>
                                <Icon icon="charm:search" />
                            </Button>
                        </Popover>
                    </div>
                    <div className="collaborator-infos">
                        <div>
                            <h4>{i18n.t<string>("period")}</h4>
                            <DatePicker
                                onChange={onChangeDatePicker}
                                format="YYYY"
                                picker="year"
                                value={moment(year)}
                                allowClear={false}
                                disabledDate={disableDate}
                            />
                        </div>
                        <Carousel {...carouselDefaultProps} slidesPerRow={filtersPerRow}>
                            <ContributorInformation title="business_unit" value={resource.businessUnit.label} />
                            <ContributorInformation title="cost_center" value={resource.costCenter.label} />
                            <ContributorInformation
                                title="professional_category.professional_category_name"
                                value={resource.professionalCategory.label}
                            />
                            <ContributorInformation title="cost_category" value={resource.costCategory.label} />
                            {resource.flexFields.length > 0 &&
                                resource.flexFields.map(({ flexFieldName, flexFieldValueName }) => {
                                    return (
                                        <ContributorInformation
                                            key={flexFieldName}
                                            title={flexFieldName}
                                            value={flexFieldValueName}
                                        />
                                    );
                                })}
                        </Carousel>
                    </div>
                </div>
            </div>
            <PeriodBoardInfo
                period={[moment(year, "YYYY").startOf("year"), moment(year, "YYYY").endOf("year")]}
                budgetPeriodDates={budgetPeriodDates}
                moduleType={ModuleType.EXPENSERESOURCES}
                styles={{ margin: "8px auto" }}
            />
            <div className="collaborator-header">
                <div className="collaborator-header-left-content">
                    <h3>{i18n.t("human_resources.filling_in_information")} </h3>
                    <p>{i18n.t("human_resources.fill_in_the_information_regarding_the_employee")}</p>
                </div>
                {hasValues && (
                    <div className="collaborator-header-right-content">
                        <Button
                            style={{ padding: "0 40px", height: 35 }}
                            className="gs-main-button"
                            onClick={handleSaveCollaboratorBudget}
                        >
                            {i18n.t("save")}
                        </Button>
                    </div>
                )}
            </div>

            {hasValues ? (
                <>
                    <CollabBudgetTables
                        admissions={admissions}
                        setAdmissions={setAdmissions}
                        setValuesToSave={setValuesToSave}
                        valuesToSave={valuesToSave}
                        isFetching={isFetching}
                        year={year}
                        tablesData={tablesData}
                        collaboratorId={resource.id}
                    />

                    <CollabResultTables
                        admissions={admissions}
                        isFetching={isFetchingResult}
                        year={year}
                        tablesData={tablesDataResult}
                    />
                </>
            ) : isFetching || isFetchingResult ? (
                <Loading />
            ) : (
                <ImageBox
                    message="Você não possui permissão para visualizar nenhum campo."
                    styles={{ marginTop: 50 }}
                />
            )}
        </div>
    );
}
