import { TableRowSelection } from "antd/lib/table/interface";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";

export interface IGroupersContext {
	isGridFetching: boolean;
	isModalOpen: boolean;
	setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isSaving: boolean;
	setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
	listToSave: IProjectionGrouperData[];
	setListToSave: React.Dispatch<React.SetStateAction<IProjectionGrouperData[]>>;
	indexerList: IModalityFilter[];
	tableData: IProjectionGrouperData[];
	setTableData: React.Dispatch<React.SetStateAction<IProjectionGrouperData[]>>;
	indexerTypeList: GenericSelection[];
	getIndexerTypeDescription: Record<IndexerType, string>;
	handleSave: (grouperToSave?: IProjectionGrouperData) => void;
	rowSelection: TableRowSelection<IProjectionGrouperData>;
	setSelectedRowKeys: React.Dispatch<React.SetStateAction<number[]>>
	selectedRowKeys: number[];
	setItemForEdit: React.Dispatch<React.SetStateAction<IProjectionGrouperData>>;
	itemForEdit: IProjectionGrouperData;
	setIsGridFetching: React.Dispatch<React.SetStateAction<boolean>>;
	getGridData: () => void;
}

export interface IProjectionGrouperData {
	description: string;
	externalCode: string;
	id: number;
	indexerId?: number;
	indexerType: IndexerType;
}

export enum IndexerType {
	NONE = "NONE",
	SIMPLE = "SIMPLE",
	ACCUMULATED = "ACCUMULATED"
}

export interface INewGroupersTableProps {
	listToSave: IProjectionGrouperData[];
	handleEdit: (id: IProjectionGrouperData) => void;
	handleDelete: (id: number) => void;
}