import './style.sass';
import { Form } from 'antd';
import { ColumnsType } from "antd/lib/table";
import { Notification } from 'components/notification/Notification';
import { TopButtons } from "components/topButtons/TopButtons";
import { Key, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { sortList } from 'util/functions/sortList';
import { RequestType } from 'util/service/IServiceCaller';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { InvestmentTemplateTableData, SelectList, ValueForm } from './IInvestmentTemplate';
import { handleExportGridData } from 'util/functions/handleExportGridData';
import { IImportModal } from 'components/importExportMenu/IImportExportMenu';
import { useUserContext } from 'context/UserContext';
import InvestmentTemplateModal from './components/InvestmentTemplateModal';
import InvestmentTemplateTable from './components/InvestmentTemplateTable';

export default function InvestmentTemplate() {
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [tableData, setTableData] = useState<InvestmentTemplateTableData[]>([]);
    const [filterdTableData, setFilterdTableData] = useState([]);
    const [selectedRows, setSelectedRows] = useState<ValueForm[]>([]);
    const [isNewTemplate, setIsNewTemplate] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [modalList, setModalList] = useState<ValueForm[]>([]);
    const [accountingList, setAccountingList] = useState<SelectList[]>([]);
    const [isFetchingAccountingList, setIsFetchingAccountingList] = useState(true);
    const [form] = Form.useForm();
    const { userInfo } = useUserContext()

    const importProps: IImportModal[] = [
        {
            importUrl: "/investment/template/import",
            templateUrl: "/investment/template/import/template?locale={locale}",
            type: 'excel'
        }
    ]

    const tableColumns: ColumnsType = [
        {
            title: i18n.t<string>("external_code"),
            dataIndex: "externalCode",
            key: "externalCode",
            align: "center",
            width: 300,
        },
        {
            title: i18n.t<string>("description"),
            dataIndex: "description",
            key: "description",
            align: "left",
            className: 'account-registration-columns'
        },
        {
            title: i18n.t("investment_template.accounting"),
            dataIndex: "investmentAccountName",
            key: "investmentAccountName",
            align: "left",
            className: 'account-registration-columns'
        },
    ];

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/investment/template?organization={organization}`
        }, onLoadTemplateTableData.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/investment/accounting?organization={organization}`
        }, onLoadAccountingList.bind(this));
    }, []);

    useEffect(() => {
        setFilterdTableData(tableData)
    }, [tableData]);

    useEffect(() => {
        if (tableData.length > 0 && !isFetchingAccountingList) {
            setTableData(tableData.map(item => {
                const accountingName = accountingList.find(accounting => accounting.value === item.investmentAccountId);

                return {
                    ...item,
                    investmentAccountName: accountingName?.label
                };
            }));
        }
    }, [isFetchingAccountingList, isFetching])

    function onLoadTemplateTableData(data) {
        if (data.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        };
        const updateTableData = data.map(res => {
            return {
                key: res.id,
                id: res.id,
                externalCode: res.externalCode,
                description: res.description,
                investmentAccountId: res.investmentAccountId,
                investmentAccountName: ""
            };
        }).sort((a, b) => sortList(a.externalCode, b.externalCode));

        setTableData(updateTableData);
        setIsFetching(false);
    };

    function onLoadAccountingList(data) {
        if (data.length === 0) {
            setAccountingList([]);
            setIsFetchingAccountingList(false);
            return;
        };
        const updateList = data.map(res => {
            return {
                value: res.id,
                label: `${res.externalCode} - ${res.description}`
            };
        });

        setAccountingList(updateList);
        setIsFetchingAccountingList(false);
    };

    function onSelectRowChange(selectedRowKeys: Key[], selectedRows: ValueForm[]) {
        setSelectedRows(selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    function handleOpenModal(isNewTemplate: boolean) {
        if (isNewTemplate) {
            setIsNewTemplate(true);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            form.resetFields();
        } else {
            form.setFieldsValue({
                externalCode: selectedRows[0].externalCode,
                description: selectedRows[0].description,
                investmentAccountId: selectedRows[0].investmentAccountId,
            });
            setIsNewTemplate(false);
        }
        setModalList([]);
        setIsModalVisible(true);
    };

    function handleIncludeTemplate(data: ValueForm) {
        if (isNewTemplate) {
            setModalList([...modalList, data]);
        } else {
            handleSaveTemplate([data]);
        }
        form.resetFields();
    }

    function handleSaveTemplate(data) {
        const templateToSave = data.map(
            template => {
                return {
                    id: isNewTemplate ? null : selectedRowKeys[0],
                    externalCode: isNewTemplate ? template.externalCode : selectedRows[0].externalCode,
                    description: template.description,
                    investmentAccountId: template.investmentAccountId,
                    businessUnitId: userInfo.selection.businessUnitId,
                    organizationId: userInfo.selection.organizationId
                }
            });

        setSelectedRowKeys([]);
        setSelectedRows([]);

        setIsModalVisible(false);
        setIsFetching(true);

        ServiceCaller.doRequest({
            type: isNewTemplate ? RequestType.POST : RequestType.PUT,
            url: '/investment/template',
            params: isNewTemplate ? templateToSave : templateToSave[0],
        }, onSaveTemplate.bind(this));
    }

    function onSaveTemplate(response) {
        if (response) {
            Notification({
                type: "success",
                message: isNewTemplate ? i18n.t<string>("successfully_saved") : i18n.t<string>("successfully_edited"),
            });
        }
        form.resetFields();
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/investment/template?organization={organization}`
        }, onLoadTemplateTableData.bind(this));
    };
    function handleDeleteTemplate() {
        setIsFetching(true);
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/investment/template?ids=${selectedRowKeys.toString()}`,
        },
            onDeleteTemplate.bind(this),
            onRequestError.bind(this)
        );
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function handleCloseModal() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setModalList([]);
        form.resetFields();
        setIsModalVisible(false);
    };

    function onDeleteTemplate(response) {
        if (response) {
            Notification({
                type: "success",
                message: i18n.t<string>("successfully_deleted"),
            });
        }
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/investment/template?organization={organization}`
        }, onLoadTemplateTableData.bind(this));
    };

    function onRequestError() {
        console.error("onRequestError");
        Notification({
            type: "warning",
            message: i18n.t<string>("unable_to_delete_with_linked_data"),
        });
        setIsFetching(false);
    }

    return (
        <>
            <div className="page-title-content" id="cost-category-container">
                <h1>{i18n.t("investment_template.investment_template_registration")}</h1>
            </div>
            <div id="top-buttons-account-registration">
                <div>
                    <TopButtons
                        mainButtonTitle={i18n.t("investment_template.new_template")}
                        handleNew={() => handleOpenModal(true)}
                        handleEdit={() => handleOpenModal(false)}
                        handleDelete={handleDeleteTemplate}
                        isEditable={selectedRows.length === 1}
                        isDeletable={selectedRows.length > 0}
                        multipleSearch={{
                            tableData: tableData,
                            setTableData: setFilterdTableData,
                            options: [
                                { i18nString: 'code', description: 'externalCode' },
                                { i18nString: 'description', description: 'description' },
                                { i18nString: 'investment_template.accounting', description: 'investmentAccountName' },
                            ]
                        }}
                        importExportOptions={{
                            exportGridData: () => handleExportGridData(tableData, tableColumns, i18n.t("template")),
                            importProps: importProps
                        }}
                    />
                </div>
            </div>
            <main id="account-registration-main">
                <InvestmentTemplateTable
                    isFetching={isFetching}
                    tableData={filterdTableData}
                    selectedRowKeys={selectedRowKeys}
                    onChange={onSelectRowChange}
                    columns={tableColumns}
                />
                <InvestmentTemplateModal
                    isModalVisible={isModalVisible}
                    isNewTemplate={isNewTemplate}
                    handleSubmit={handleIncludeTemplate}
                    handleCancel={handleCloseModal}
                    form={form}
                    modalList={modalList}
                    setModalList={setModalList}
                    handleSaveTemplate={handleSaveTemplate}
                    tableData={tableData}
                    selectedRowKeys={selectedRowKeys}
                    accountingList={accountingList}
                    isFetchingAccountingList={isFetchingAccountingList}
                />
            </main>
        </>
    )
}
