import { Icon } from "@iconify/react";
import { Button } from "antd";
import { useState, useEffect } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

interface Props {
	configId: Number
	activeKeys: Array<string>
	onClick: Function
	widgetLength: Number
}

export default function ButtonExpand(props: Props) {

	const [expandedWidget, setExpandWidget] = useState((props.activeKeys.length === 0 ? true : false));

	const [btnIcon, setBtnIcon] = useState("");

	const onClick = (e) => {
		let type: string;

		if (expandedWidget) {
			type = "open"
		} else {
			type = "close";
		}

		ServiceCaller.doRequest({
			type: RequestType.PATCH,
			url: `/dashboard-analytics/configuration/${props.configId}/widget/${type}-all`
		});

		setExpandWidget(expandedWidget => !expandedWidget);
		props.onClick(expandedWidget);
	}

	useEffect(() => {
		let iconBtn: string = !expandedWidget ? "ant-design:shrink-outlined" : "ic:outline-expand";

		setBtnIcon(iconBtn);
	}, [expandedWidget])

	useEffect(() => {
		if (props.activeKeys.length === 0) {
			setExpandWidget(true);
		} else if (props.activeKeys.length === props.widgetLength) {
			setExpandWidget(false);
		}

	}, [props.activeKeys, props.widgetLength])

	return (
		<Button className="cm-step4 cm-step4-main" onClick={onClick} icon={<Icon icon={btnIcon} />} type="text" data-html2canvas-ignore="true" />
	)
}