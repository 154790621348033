import { ErrorFallbackProps } from "components/error/ErrorFallback";
import { useBudgetDates } from "hooks/useBudgetDates";
import { cloneDeep } from "lodash";
import moment from "moment";
import { Moment } from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ModuleType } from "util/types/types";
import { IAccounting } from "../../projectionModeling/IProjectionModeling";
import "../AccountingBalances.sass";
import { IAccountingBalancesContext, LevelItem } from "../IAccountingBalances";

export const AccountingBalancesContext = createContext<IAccountingBalancesContext>({} as IAccountingBalancesContext);

export function AccountingBalancesProvider({ children }) {
    const [treeData, setTreeData] = useState<LevelItem[]>([]);
    const [checkedNodeKeys, setCheckedNodeKeys] = useState<number[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<number[]>([]);
    const [isFetchingTreeData, setIsFetchingTreeData] = useState<boolean>(false);
    const [nodeSelected, setNodeSelected] = useState<LevelItem>({} as LevelItem);

    const [period, setPeriod] = useState<[Moment, Moment]>(() => {
        return [moment().startOf("year"), moment().month(11).endOf("month")];
    });
    const [costCenterId, setCostCenterId] = useState<string>(null);

    const [costCentersList, setCostCentersList] = useState<LevelItem[]>([]);

    const { data: budgetPeriodDates } = useBudgetDates(ModuleType.PLANNING);
    const { showBoundary } = useErrorBoundary();
    const [openBudgetYears, setOpenBudgetYears] = useState<number[]>([]);
    const [openedCalendarPeriod, setOpenedCalendarPeriod] = useState<[moment.Moment, moment.Moment][]>([
        [moment().startOf("year"), moment().month(11).endOf("month")],
    ]);

    useEffect(() => {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/planning/management-cost-center",
            },
            onLoadCostCenter
        );
    }, []);

    useEffect(() => {
        if (costCentersList.length !== 0) {
            loadAccountHierarchy();
        }
    }, [costCentersList]);

    useEffect(() => {
        if (!budgetPeriodDates) {
            return;
        }
        if (budgetPeriodDates.period?.length === 0) {
            showBoundary({
                code: 400,
                title: "Não há exercício aberto",
                message: "Adicione um exercício na tela de painel de controle, ou peça permissão para o exercício",
                image: "empty_list",
            } as ErrorFallbackProps["error"]);
        }
        const openedPeriods: [moment.Moment, moment.Moment][] = budgetPeriodDates.period?.reduce((acc, val) => {
            const period = [moment(val.expensesResourcesBudgetStartDate), moment(val.expensesResourcesBudgetEndDate)];
            acc.push(period);
            return acc;
        }, []);
        setOpenBudgetYears(budgetPeriodDates.period?.map((period) => period.year));
        setOpenedCalendarPeriod(openedPeriods);
    }, [budgetPeriodDates]);

    function onLoadCostCenter(data: IAccounting[]) {
        const costCenters: LevelItem[] = data.map((item) => {
            return {
                id: item.externalCode,
                key: item.externalCode,
                parentId: null,
                externalCode: item.externalCode,
                classificationId: item.id,
                title: item.description,
                description: item.description,
                children: [],
                dataTree: [],
            };
        });

        setCostCentersList(costCenters);
    }

    function loadAccountHierarchy() {
        setIsFetchingTreeData(true);
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: "/planning/management-account-plan-hierarchy/tree",
            },
            onLoadTreeData
        );
    }

    function onLoadTreeData(data: LevelItem[]) {
        if (!data || data.length === 0) {
            setTreeData([]);
            setIsFetchingTreeData(false);
            return;
        }

        const treeCopy: LevelItem[] = cloneDeep(data);
        treeCopy.forEach((node) => {
            addPropertyTitle(node);
        });
        setTreeData(treeCopy);
        setIsFetchingTreeData(false);
    }

    function addPropertyTitle(tree: LevelItem | any) {
        if (tree && typeof tree === "object") {
            const externalCodeSuffix: string = ` (${tree.externalCode})`;
            if (tree.hasOwnProperty("description")) {
                tree.title =
                    tree.dataTree?.length === 0 ? `${tree.description}${externalCodeSuffix}` : tree.description;
                tree.children = tree.dataTree;
                tree.key = tree.id;
                delete tree.description;
                delete tree.dataTree;
                delete tree.id;
            }

            if (tree.children && tree.children.length === 0) {
                const alreadyExistCostCenter: LevelItem = costCentersList.find((item) => {
                    return item.externalCode === tree.externalCode;
                });

                const cloneCostCentersList: LevelItem[] = cloneDeep(costCentersList);

                if (!alreadyExistCostCenter) {
                    cloneCostCentersList.map((item) => {
                        return (item.id = `${tree.key}-${Math.random()}`);
                    });

                    tree.children = cloneCostCentersList;
                }
            }

            for (let key in tree) {
                addPropertyTitle(tree[key]);
            }
        }
    }

    function handleChangePeriod(period) {
        setPeriod(period);
    }

    return (
        <AccountingBalancesContext.Provider
            value={{
                treeData,
                setTreeData,
                checkedNodeKeys,
                setCheckedNodeKeys,
                expandedKeys,
                setExpandedKeys,
                isFetchingTreeData,
                setIsFetchingTreeData,
                nodeSelected,
                setNodeSelected,
                checkedKeys: checkedNodeKeys,
                setCheckedKeys: setCheckedNodeKeys,
                costCentersList: costCentersList,
                setCostCentersList: setCostCentersList,
                period,
                handleChangePeriod,
                costCenterId,
                setCostCenterId,
                openBudgetYears,
                openedCalendarPeriod,
            }}
        >
            {children}
        </AccountingBalancesContext.Provider>
    );
}
export const useAccountingBalancesContext: () => IAccountingBalancesContext = () => {
    return useContext(AccountingBalancesContext);
};
