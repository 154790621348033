import {
	useEffect,
	useState
} from "react";

import { useUserContext } from "context/UserContext";

import i18n from "util/base/i18n";

import {
	MessageType,
	RequestType
} from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";

import { IDataTable } from "./ISceneryFilters";

import "./style.sass";
import SceneryFilters from "./SceneryFilters";
import { Modal, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { scenarioBroadcast } from "util/BroadcastChannels";

export default function ModalSceneryFilter() {
	const { userInfo, changeModal, changeStepModal, isSelectionScenarioModalOpen, isBudgetModule } = useUserContext();

	const [sceneryListData, setSceneryListData] = useState<IDataTable[]>([]);
	const [organizationListData, setOrganizationListData] = useState<IDataTable[]>([]);
	const [businessListData, setBusinessListData] = useState<IDataTable[]>([]);

	const [selectedScenery, setSelectedScenery] = useState<number>(null);
	const [selectedOrganization, setSelectedOrganization] = useState<number>(null);
	const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<number>(null);

	const [moveToSelectedOrganization, setMoveToSelectedOrganization] = useState<boolean>(false);
	const [moveToSelectedUnit, setMoveToSelectedUnit] = useState<boolean>(false);

	const [disabledButton, setDisabledButton] = useState<boolean>(false);
	const [filteringUnit, setFilteringUnit] = useState<boolean>(false);

	const [isFetchingOrganization, setIsFetchingOrganization] = useState(false);
	const [isFetchingBusiness, setIsFetchingBusiness] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: "/business/ScenarioArray",
			useProxy: false
		}, onLoadScenary.bind(this));
	}, [userInfo.selection]);

	function onSaveFilter() {
		scenarioBroadcast.postMessage('scenario-changed')

		ServiceCaller.doSendMessage({
			messageType: MessageType.REFRESH_SCREEN
		});
	};

	function onLoadScenary(data) {
		setSceneryListData((data.map(({ id, description }) => {
			return {
				id,
				title: description,
				key: id
			}
		})).sort(({ title: titleA }, { title: titleB }) => titleA.localeCompare(titleB)));
	};

	function onLoadOrganization(data, scenario) {
		setOrganizationListData(data.map(({ id, description }) => {
			return {
				id,
				title: description,
				key: id
			}
		}).sort(({ title: titleA }, { title: titleB }) => titleA.localeCompare(titleB)));
		if (scenario) {
			getBusinessUnit(scenario)
		}
		setIsFetchingOrganization(false);
	};

	function onLoadBusinessUnit(data) {
		setBusinessListData(data.map(({ id, description, organizationId }) => {
			return {
				id,
				title: description,
				key: id,
				organizationId
			}
		}));

		setIsFetchingBusiness(false);
	};

	function getOrganization(scenarioId) {

		setIsFetchingOrganization(true);

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/business/OrganizationArrayFromScenary?scenarioId=${scenarioId}&financingCalc=false`,
			useProxy: false
		}, (data) => onLoadOrganization(data, scenarioId));
	};

	function getBusinessUnit(scenarioId) {

		setIsFetchingBusiness(true);

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/business/BusinessArray?scenarioId=${scenarioId}`,
			useProxy: false
		}, onLoadBusinessUnit.bind(this));
	};

	const handleOk = () => {
		setIsLoading(true)

		ServiceCaller.doRequest({
			type: RequestType.POST,
			url: "/business/allStructure",
			params: {
				scenarioSelectedId: selectedScenery,
				organizationSelectedId: selectedOrganization,
				businessUnitSelectedId: selectedBusinessUnit,
				yearSelected: userInfo.selection.year
			},
			useProxy: false,
		}, onSaveFilter.bind(this));
	};

	const handleCancel = () => {
		setSelectedOrganization(Number(userInfo.selection.organizationId));
		setSelectedBusinessUnit(Number(userInfo.selection.businessUnitId));
		changeModal();
	};

	function onSelectedScenery(selection: number, { selected }) {

		if (!selected) {
			return;
		}

		setSelectedScenery(selection);
		getOrganization(selection)
	};

	function onSelectedOrganization(selection: number, { selected }) {
		if (!selected) {
			return;
		}

		setSelectedOrganization(selection);
		setFilteringUnit(false);

		const firstUnitOfOrganization: IDataTable = getBusinessUnits(selection).find(({ organizationId }) => selection === organizationId);

		if (firstUnitOfOrganization) {
			setMoveToSelectedUnit(true);
			setSelectedBusinessUnit(firstUnitOfOrganization.id);
		}
	};

	function onSelectedBusinessUnit(selection: number, { selected, node: businessUnit }) {
		if (!selected) {
			return;
		}

		setMoveToSelectedOrganization(true);
		setSelectedBusinessUnit(selection);
		setSelectedOrganization(businessUnit.organizationId);
	};

	const getBusinessUnits = (organization: number = null): IDataTable[] => {
		let units: IDataTable[];

		if (filteringUnit) {
			units = businessListData.sort(({ title: titleA }, { title: titleB }) => titleA.localeCompare(titleB));
		} else {
			units = businessListData.filter(({ organizationId }) => organizationId === (organization || selectedOrganization)).sort(({ title: titleA }, { title: titleB }) => titleA.localeCompare(titleB));
		}

		return units;
	};

	const onStartUnitSearch = () => {
		setFilteringUnit(true);
	};

	const onEndUnitSearch = () => {
		setFilteringUnit(false);
		setMoveToSelectedOrganization(false);
		setMoveToSelectedUnit(false);
	};

	const onEndOrganizationSearch = () => {
		setMoveToSelectedOrganization(false);
	};

	useEffect(() => {
		if (!userInfo.id) return
		if (userInfo.selection.scenarioId === undefined || userInfo.selection.organizationId === undefined || userInfo.selection.businessUnitId === undefined) {
			if (userInfo.isLeaseProjectionModalEnabled)
				changeStepModal();
			else
				changeModal();
			setDisabledButton(true);
		} else if (userInfo.selection.scenarioId !== null && userInfo.selection.organizationId !== null && userInfo.selection.businessUnitId !== null) {
			if (userInfo.selection.scenarioId) {
				getOrganization(userInfo.selection.scenarioId);
				getBusinessUnit(userInfo.selection.scenarioId);
			} else {
				ServiceCaller.doRequest({
					type: RequestType.GET,
					url: "/business/OrganizationArray",
					useProxy: false,
				}, (data) => onLoadOrganization(data, null));
			}

			setSelectedScenery(Number(userInfo.selection.scenarioId));
			setSelectedOrganization(Number(userInfo.selection.organizationId));
			setSelectedBusinessUnit(Number(userInfo.selection.businessUnitId));
		}
	}, [userInfo]);

	useEffect(() => {
		if (organizationListData.length > 0) {
			let selectedOrganization: IDataTable = organizationListData.find(({ key }) => key === Number(userInfo.selection.organizationId));

			setMoveToSelectedOrganization(true);

			if (selectedOrganization) {
				onSelectedOrganization(selectedOrganization.id, { selected: true });
			} else {
				onSelectedOrganization(organizationListData[0].id, { selected: true });
			}
		}
	}, [userInfo.selection, organizationListData]);

	useEffect(() => {
		const businessUnits = getBusinessUnits();

		if (businessUnits.length > 0) {
			let selectedUnit: IDataTable = businessUnits.find(({ key }) => key === Number(userInfo.selection.businessUnitId));

			setMoveToSelectedUnit(true);

			if (selectedUnit) {
				setSelectedBusinessUnit(selectedUnit.id);
			} else {
				setSelectedBusinessUnit(businessUnits[0].id);
			}

			setDisabledButton(false);
		}
	}, [businessListData]);

	const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

	if (isBudgetModule) {
		return (
			<Modal
				visible={isSelectionScenarioModalOpen}
				title={i18n.t<string>("scenery_selection")}
				onOk={handleOk}
				okText={i18n.t<string>("apply")}
				cancelText={i18n.t<string>("cancel")}
				onCancel={handleCancel}
				width={"80%"}
				confirmLoading={isLoading}
			>
				<div className="screen-filter-modal">
					<div className="screen-filter-modal-content">
						<SceneryFilters data={sceneryListData} selectedFilter={selectedScenery} title={i18n.t<string>("scenery")} onSelected={onSelectedScenery} />
						<SceneryFilters data={organizationListData} selectedFilter={selectedOrganization} title={i18n.t<string>("organization")} onSelected={onSelectedOrganization} onEndSearch={onEndOrganizationSearch} moveToSelected={moveToSelectedOrganization} isFetching={isFetchingOrganization} />
						<SceneryFilters data={getBusinessUnits()} selectedFilter={selectedBusinessUnit} title={i18n.t<string>("operating_unit")} onSelected={onSelectedBusinessUnit} onStartSearch={onStartUnitSearch} onEndSearch={onEndUnitSearch} moveToSelected={moveToSelectedUnit} isFetching={isFetchingBusiness} />
					</div>
				</div>
			</Modal>
		)
	} else {
		return (
			<div className="screen-filter-modal">
				<div className="screen-filter-modal-header">
					<p>
						{i18n.t<string>("scenery_selection")}
					</p>
				</div>
				<div className="screen-filter-modal-content">
					<SceneryFilters data={sceneryListData} selectedFilter={selectedScenery} title={i18n.t<string>("scenery")} onSelected={onSelectedScenery} />
					<SceneryFilters data={organizationListData} selectedFilter={selectedOrganization} title={i18n.t<string>("organization")} onSelected={onSelectedOrganization} onEndSearch={onEndOrganizationSearch} moveToSelected={moveToSelectedOrganization} isFetching={isFetchingOrganization} />
					<SceneryFilters data={getBusinessUnits()} selectedFilter={selectedBusinessUnit} title={i18n.t<string>("operating_unit")} onSelected={onSelectedBusinessUnit} onStartSearch={onStartUnitSearch} onEndSearch={onEndUnitSearch} moveToSelected={moveToSelectedUnit} isFetching={isFetchingBusiness} />
				</div>
				<div className="screen-filter-modal-footer">
					<button className="button-cancel" hidden={!userInfo.selection.scenarioId && !userInfo.selection.organizationId && !userInfo.selection.businessUnitId} onClick={handleCancel}>
						<p>
							{i18n.t<string>("cancel")}
						</p>
					</button>
					<button
						className="gs-main-button"
						disabled={disabledButton || isLoading}
						style={{
							backgroundColor: disabledButton || isLoading ? '#5b96c4' : '#0065B3',
							cursor: disabledButton || isLoading ? 'not-allowed' : 'pointer',
						}} onClick={handleOk}
					>
						<p>
							{isLoading && <Spin indicator={antIcon} style={{ marginRight: '1rem', color: '#FFF' }} size="small" />}
							{i18n.t<string>("apply")}
						</p>
					</button>
				</div>
			</div>
		)
	}
}
