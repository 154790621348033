import { RuleConditionOperations } from "../../IAttributeParameterization";
import { ContainsOp, EqualOp, FromToOp, NotContainsOp, NotEqualOp } from "./MathOperators";

export function createMathOperator(operation: string): React.ReactNode {
    switch (RuleConditionOperations[(operation || "").toUpperCase()]) {
        case RuleConditionOperations.EQUALS:
            return <EqualOp />;
        case RuleConditionOperations.DIFFERENT:
            return <NotEqualOp />;
        case RuleConditionOperations.CONTAINS:
            return <ContainsOp />
        case RuleConditionOperations.NOT_CONTAINS:
            return <NotContainsOp />;
        case RuleConditionOperations.FROM_TO:
            return <FromToOp />;
        default:
            return null;
    }
}
