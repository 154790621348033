import { Icon } from "@iconify/react";
import { Button, Checkbox, List, Popover, Tooltip } from "antd"
import { useState } from "react";
import i18n from "util/base/i18n";
import { useAccountingLedgerContext } from "../context/AccountingLedgerContext";
import { ColumnGrid } from "../IAccountingLedger";

export default function GrouperLedger() {

    const { groupColumns, setGroupColumns, onLoadAccountingLedger, isMultipleSearch } = useAccountingLedgerContext();

    const [cursor, setCursor] = useState<string>('grab');
    const [draggingIndex, setDraggingIndex] = useState<number>(null);
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [isAllGroupChecked, setIsAllGroupChecked] = useState<boolean>(true);

    function handlePopoverClose(): void {
        setPopoverVisible(false);
    }

    function handleDragStart(index: number): void {
        setDraggingIndex(index);
    };

    function handleDragEnd(): void {
        setDraggingIndex(null);
    };

    function handleMouseGrab(isGrabbing: boolean): void {
        isGrabbing ? setCursor('grabbing') : setCursor('grab');
    }

    function handleCheckboxChange(id: number | string): void {
        setIsAllGroupChecked(groupColumns.map(item => item.key == id ? { ...item, checked: !item.checked } : item).every(item => item.checked));
        setGroupColumns(groupColumns.map(item => item.key == id ? { ...item, checked: !item.checked } : item));
    };

    function handleSelectAllChange(): void {
        const allChecked: boolean = groupColumns.every(item => item.checked);
        setIsAllGroupChecked(!allChecked);
        setGroupColumns(groupColumns.map(item => ({ ...item, checked: !allChecked })));
    };

    function handleDragOver(index: number): void {
        if (draggingIndex === null || index === draggingIndex) {
            return;
        }

        const newItems: ColumnGrid[] = [...groupColumns];
        const draggingItem: ColumnGrid = newItems[draggingIndex];

        newItems.splice(draggingIndex, 1);
        newItems.splice(index, 0, draggingItem);

        setGroupColumns(newItems);
        setDraggingIndex(index);
    };

    function saveFilters(): void {
        handlePopoverClose();
        onLoadAccountingLedger(true, false, isMultipleSearch);
    }

    return (
        <Popover
            content={
                <div>
                    <div>
                        <div id="revenue-table-revenue-card-filter-header">
                            <p style={{ color: '#424242', fontWeight: 'bold', fontSize: 16, margin: 'none' }}>
                                {i18n.t<string>("revenue.detail_filter")}
                            </p>
                            <Icon onClick={handlePopoverClose} style={{ marginTop: 7, cursor: 'pointer' }} icon="material-symbols:close" />
                        </div>
                        {i18n.t<string>("revenue.select_and_order")}
                    </div>
                    <div id="scrollableDiv"
                        style={{
                            height: 400,
                            overflow: 'auto',
                            padding: '0 16px 0 0',
                        }}>
                        <List dataSource={groupColumns} renderItem={(item, index) => (
                            <div draggable onDragStart={() => handleDragStart(index)} onDragOver={() => handleDragOver(index)} onDragEnd={handleDragEnd}>
                                <List.Item className="revenue-item-row">
                                    <div id="revenue-card-detail-item" onMouseDown={() => handleMouseGrab(true)} onMouseUp={() => handleMouseGrab(false)}>
                                        {item.checked ? (
                                            <div style={{ background: '#F1F2F3', display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: 7 }}>
                                                    <Checkbox checked={item.checked} onChange={() => handleCheckboxChange(item.key)} />
                                                    <p style={{ fontSize: 14 }}>{item.title}</p>
                                                </div>
                                                <Icon style={{ cursor: cursor, justifyContent: 'flex-end' }} icon="radix-icons:drag-handle-dots-2" />
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: 7 }}>
                                                    <Checkbox checked={item.checked} onChange={() => handleCheckboxChange(item.key)} />
                                                    <p style={{ fontSize: 14 }}>{item.title}</p>
                                                </div>
                                                <Icon style={{ cursor: cursor }} icon="radix-icons:drag-handle-dots-2" />
                                            </div>
                                        )}
                                    </div>
                                </List.Item>
                            </div>
                        )}
                            header={
                                <div id="revenue-filter-header">
                                    <Checkbox onChange={() => handleSelectAllChange()} checked={isAllGroupChecked} />
                                    {i18n.t<string>("select_all")}
                                </div>
                            }
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                        <div style={{ display: 'flex', gap: 5 }}>
                            <Button style={{ border: 'none', boxShadow: 'none' }} onClick={handlePopoverClose}>
                                <span style={{ color: '#0065B3', fontStyle: 'normal', fontWeight: 400, fontSize: 14 }}>
                                    {i18n.t<string>("close")}
                                </span>
                            </Button>
                            <Button className="revenue-filter-order-button-save" onClick={saveFilters}>
                                <span style={{ color: '#FFFFFF', fontStyle: 'normal', fontWeight: 400, fontSize: 14 }}>
                                    {i18n.t<string>("save")}
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            }
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={setPopoverVisible}>
            <Tooltip
                placement="bottom"
                title={i18n.t<string>("group_columns")}
            >
                <Button
                    icon={<Icon icon="fa:object-ungroup" />}
                    id="group"
                    style={{ color: 'black', fontSize: 17, border: 'none', backgroundColor: 'transparent' }}
                >
                </Button>
            </Tooltip>
        </Popover>
    );
}