import { Icon } from "@iconify/react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select} from "antd";
import moment from "moment";
import { useState } from "react";
import i18n, { languages } from "util/base/i18n";
import { validateFormField } from "util/functions/validateFormField";
import { selectProps } from "util/props/props";
import { CollaboratorsModalProps, ValueForm } from "../ICollaborators";
import { Notification } from "components/notification/Notification";
import { ImageBox } from "components/imageBox/ImageBox";

export default function CollaboratorsModal({
    isModalVisible,
    isNewCollaborator,
    handleSubmit,
    handleCancel,
    form,
    newCollaboratorsList,
    setNewCollaboratorsList,
    handleSaveCollaborators,
    tableData,
    selectedRowKeys
}: CollaboratorsModalProps){

    const [editDisebled, setEditDisebled] = useState(false);

    const modalTitle = isNewCollaborator
		? i18n.t<string>("collaborators.collaborators.new_employee")
		: i18n.t<string>("collaborators.collaborators.edit_contributor");

    function handleDeleteListItem(externalCode) {
		const collaborators = [...newCollaboratorsList];
		const i = collaborators.findIndex((collaborator) => (collaborator.externalCode === externalCode));
		collaborators.splice(i, 1);
		setNewCollaboratorsList(collaborators);
    }

    function handleEditListItem(collaborator: ValueForm) {
        setEditDisebled(true)
        form.setFieldsValue({
            externalCode: collaborator.externalCode,
            description: collaborator.description,
            hiringDate: collaborator.hiringDate,
            resourceType: collaborator.resourceType
        })
        handleDeleteListItem(collaborator.externalCode);
    }

    return (
        <Modal
			width={1000}
			title={modalTitle}
			visible={isModalVisible}
			okButtonProps={!isNewCollaborator && { htmlType: "submit", form: "new-collaborator-form" }}
			onCancel={() => {
				handleCancel()
			}}
			className="gs-modal collaborators-modal"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("save")}
            onOk={() => {
                if(isNewCollaborator){
                    if(newCollaboratorsList.length > 0){
                        handleSaveCollaborators(newCollaboratorsList);
                    }else{
                        Notification({
							type: 'warning',
							message: i18n.t("no_items_added_to_list")
						})
                    }
                }
            }}
		>
            <Form
				form={form}
				name="new-collaborator-form"
                className={isNewCollaborator ? "form-new-collaborator" : ""}
                initialValues={{resourceType: 0}}
                onFinish={(data) => {
                    setEditDisebled(false)
                    handleSubmit(data)
                }}
			>
                <div className="fields-container">
                    <div className="input-content">
                        <Form.Item
                            name="externalCode"
                            label={i18n.t<string>("code")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                                () => ({
                                    validator(_, value: string) {
                                        return validateFormField(value, 'externalCode', tableData, newCollaboratorsList, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedRowKeys[0]);
                                    },
                                })
                            ]}
                        >
                            <Input id="input-template-detail" />
                        </Form.Item>
                    </div>
                    <div className="input-content">
                        <Form.Item
                            name="hiringDate"
                            label={i18n.t<string>("admission_date")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                            ]}
                        >
                            <DatePicker className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
                        </Form.Item>
                    </div>
                    <div className="input-content">
						<Form.Item
							name="resourceType"
                            label={i18n.t<string>("type")}
							rules={[{ required: true, message: i18n.t<string>("required_field")}]}
						>
							<Select
								id="input-currency"
								style={{ width: 220 }}
                                options={[
                                    {value: 0, label: i18n.t<string>("new_o")},
                                    {value: 1, label: i18n.t<string>("effective_o")}
                                ]}
                                {...selectProps}
							>
							</Select>
						</Form.Item>
					</div>
                    <div className="input-content">
                        <Form.Item
                            name="description"
                            label={i18n.t<string>("description")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                            ]}
                        >
                            <Input id="input-template-detail" />
                        </Form.Item>
                    </div>
                </div>
                {isNewCollaborator && 
                <Button htmlType="submit" 
                    className="gs-secondary-button"
                    icon={<Icon icon="akar-icons:circle-plus-fill" />}>
                    {i18n.t('addToList')}
                </Button>}
            </Form>
            {isNewCollaborator && 
                (newCollaboratorsList.length > 0 ?
                    <>
                        <div className="new-collaborators-table-head">
                            <Row align="middle" gutter={2}>
                                <Col span={5}>{i18n.t<string>("code")}</Col>
                                <Col span={5}>{i18n.t<string>("admission_date")}</Col>
                                <Col span={5}>{i18n.t<string>("type")}</Col>
                                <Col span={7}>{i18n.t<string>("description")}</Col>
                                <Col span={1}/>
                                <Col span={1}/>
                            </Row>
                        </div>
                        <div className="new-collaborators-table-body">
                            {newCollaboratorsList.map((collaborator) => {
                                return(
                                    <Row key={collaborator.externalCode} align="middle" gutter={2}>
                                        <Col span={5}>{collaborator.externalCode}</Col>
                                        <Col span={5}>{moment(collaborator.hiringDate).format('DD/MM/YYYY')}</Col>
                                        <Col span={5}>{
                                            collaborator.resourceType === 0 ? i18n.t<string>("new_o").toUpperCase() : 
                                            (collaborator.resourceType === 1 ? i18n.t<string>("effective_o").toUpperCase() : "")
                                        }</Col>
                                        <Col span={7}>{collaborator.description}</Col>
                                        <Col span={1}>
                                            <Button 
                                                disabled={editDisebled}
                                                onClick={() => handleEditListItem(collaborator)} 
                                                icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Icon 
                                                className="delete-icon"
                                                onClick={() => handleDeleteListItem(collaborator.externalCode)} 
                                                icon="fa6-solid:trash" 
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                :
                    <ImageBox />
                )
            }
        </Modal>
    );
}