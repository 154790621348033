import { ColumnsType } from "antd/lib/table";
import { createContext, useContext, useEffect, useState } from "react";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { Options } from "util/types/types";

export const ConsolidationEliminationContext = createContext(undefined);

export const ConsolidationEliminationProvider: React.FC = ({ children }) => {
    const [scenarioOptions, setScenarioOptions] = useState<Options[]>([]);
    const [scenarioSelected, setScenarioSelected] = useState<Options[]>([]);
	const [isScenarioLoading, setIsScenarioLoading] = useState<boolean>(true);
	const [organizationIds, setOrganizationIds] = useState<number[]>([]);
	const [currentColumns, setCurrentColumns] = useState<ColumnsType<any>>([]);
	const [currentTableData, setCurrentTableData] = useState<any[]>([]);
	const [titleToExport, setTitleToExport] = useState<string>("");

    useEffect(() => {
        fetchScenario();
    }, []);

    function fetchScenario() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/monolith/scenario?locale={locale}&client={client}",
        }, onLoadScenario.bind(this));
    }

    function onLoadScenario(data) {
        const updatedSelect = data.map((scenario) => {
            return {
                value: scenario.id,
                label: scenario.name,
            };
        });
        setScenarioOptions(updatedSelect);
        setScenarioSelected([updatedSelect.find(scenario => scenario.value == 1)]);
        setIsScenarioLoading(false);
    }

    return (
        <ConsolidationEliminationContext.Provider value={{
            scenarioOptions,
            isScenarioLoading,
            scenarioSelected,
			setScenarioSelected,
			setCurrentColumns,
			currentColumns,
			setCurrentTableData,
			currentTableData,
			titleToExport,
			setTitleToExport,
			setOrganizationIds,
			organizationIds
        }}>
            {children}
        </ConsolidationEliminationContext.Provider>
    );
}
