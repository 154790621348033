import { Button, Col, Form, Row, Select } from "antd";
import { selectProps } from "util/props/props";
import { onChangeFlexFieldValue } from "../../../util/onChangeFlexFieldValue";
import { updateFlexFieldFiltersValue } from "../../../util/updateFlexFieldFiltersValue";

export function TransitoryAccount({
    isTransitoryAccountOpen,
    setIsTransitoryAccountOpen,
    costCenterOptions,
    originAccountOptions,
    flexFieldsOriginOptions,
    setFlexFieldsOriginOptions,
    isFetchingOriginAccountOptions,
    handleSubmit,
    businessUnitId,
    form
}) {
    const costCenter = Form.useWatch('costCenterId', form)
    const account = Form.useWatch("accountingAccountId", form)

    return (
        <div className={`transitory-account-drawer ${isTransitoryAccountOpen ? '' : 'collapsed'}`}>
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                requiredMark={false}
                name="transitory-account-form"
                style={{ width: '100%' }}
            >
                <div className="gs-flex align-center jc-sb">
                    <span className="gs-tag blue">Informações da conta transitória</span>
                    <div className="gs-flex align-center">
                        <Button
                            onClick={() => {
                                setIsTransitoryAccountOpen(false)
                                form.resetFields()
                            }}
                            className="gs-secondary-button"
                        >
                            Cancelar
                        </Button>
                        <Button
                            className="gs-main-button"
                            htmlType="submit"
                            style={{ width: 100, marginLeft: 20 }}
                        >
                            Aplicar
                        </Button>
                    </div>
                </div>
                <Row gutter={20} align="middle">
                    <Col span={8}>
                        <Form.Item name="costCenterId" label="Centro de Custo">
                            <Select
                                onChange={() => updateFlexFieldFiltersValue('COST_CENTER', form, 'origin', flexFieldsOriginOptions, setFlexFieldsOriginOptions)}
                                options={costCenterOptions}
                                {...selectProps}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="accountingAccountId" label="Conta">
                            <Select
                                onChange={() => updateFlexFieldFiltersValue('ACCOUNTING_ACCOUNT', form, 'origin',flexFieldsOriginOptions, setFlexFieldsOriginOptions)}
                                options={originAccountOptions}
                                {...selectProps}
                                loading={isFetchingOriginAccountOptions}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={20}>
                    {flexFieldsOriginOptions.map(ff => {
                        return (
                            <Col key={ff.value} span={8}>
                                <Form.Item name={`ff-${ff.value}`} label={ff.label}>
                                    <Select
                                        onChange={(value) => onChangeFlexFieldValue(value, ff.value, 'origin', account, costCenter, businessUnitId, flexFieldsOriginOptions, setFlexFieldsOriginOptions, form)}
                                        options={ff.children}
                                        {...selectProps}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        )
                    })}
                </Row>
            </Form>
        </div>
    )

}