export interface IReportConfig {
	id?: number;
	startPeriod: string;
	endPeriod: string;
	showUnity: boolean;
	showThousand: boolean;
	showGraphic: true;
	decimalPlaces: number;
	periodType: string;
	currencyId: number;
	modalityType: string;
}

export interface IReport {
	id: number;
	description: string;
	reportType: ReportType;
}

export interface IReportFilter {
	value: number;
	label: string;
	reportType: ReportType;
}

export interface IModality {
	id: number;
	name: string;
}

export interface IModalityFilter {
	value: number;
	label: string;
}

export interface IPeriodicity {
	id: number;
	name: string;
}

export interface IPeriodicityFilter {
	value: number;
	label: string;
}

export interface ICurrency {
	id: number;
	name: string;
	isoCode: string;
	image: string | null;
}

export interface ICurrencyFilter {
	label: string;
	value: number;
}

export enum ReportType {
	REVENUE = "REVENUE",
	PLANNING = "PLANNING"
}

export interface FilterPopoverProps {
    title: string;
    content: React.ReactNode;
    selectedValue: string | null;
}
