export function sortList(a, b) {
  const aIsNumber = !isNaN(a);
  const bIsNumber = !isNaN(b);

  if (aIsNumber && bIsNumber) {
    return parseFloat(a) - parseFloat(b);
  } else if (aIsNumber) {
    return -1;
  } else if (bIsNumber) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
};