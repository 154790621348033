import { Col, DatePicker, Form, FormInstance, Input, Modal, Row } from "antd";
import moment from "moment";
import i18n from "util/base/i18n";
import { validateMonetaryInput } from "util/functions/validateKey";
import { RateTableData } from "../IBudgetProjection";
import { useEffect } from "react";

const { RangePicker } = DatePicker;

interface IAddCurrencyRateModal {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    handleSubmit: (values: any) => void;
    form: FormInstance;
    isNew: boolean;
    disableMonthList: string[];
    selectedRateData: RateTableData;
}

export function AddCurrencyRateModal({
    isOpen,
    setIsOpen,
    handleSubmit,
    form,
    isNew,
    selectedRateData
}: IAddCurrencyRateModal) {
    useEffect(() => {
        if (selectedRateData) {
            form.setFieldsValue({
                period: [moment(selectedRateData.month, 'YYYY-MM'), null],
                rate: selectedRateData.rate,
            });
        }
    }, [selectedRateData, form]);

    return (
        <Modal
            title={i18n.t<string>(isNew ? "budget_projection_texts.add_fee" : "budget_projection_texts.edit_fee")}
            visible={isOpen}
            onCancel={() => setIsOpen(false)}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        if (!values.period[1]) {
                            values.period[1] = values.period[0];
                        }
                        form.resetFields();
                        handleSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okText={i18n.t<string>("save")}
            cancelText={i18n.t<string>("cancel")}
            okButtonProps={{
                htmlType: "submit",
                form: "new-conversion-form"
            }}
            destroyOnClose={true}
        >
            <Form
                name="new-conversion-form"
                form={form}
                layout="vertical"
                requiredMark={false}
            >
                <Row style={{ width: '100%' }} gutter={20}>
                    <Col span={14}>
                        <Form.Item
                            label={i18n.t<string>("period")}
                            name="period"
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <RangePicker
                                className="gs-date-picker"
                                picker="month"
                                format='MM/YYYY'
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ width: '100%' }} gutter={20}>
                    {isNew && (
                        <Col span={12}>
                            <Form.Item
                                label={i18n.t<string>("budget_projection_texts.increment")}
                                name="increment"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (value === "," || value === ".") {
                                                return Promise.reject(i18n.t("please_enter_a_valid_value"));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input tabIndex={1} onKeyDown={validateMonetaryInput} />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12}>
                        <Form.Item
                            label={i18n.t<string>("rate")}
                            name="rate"
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                                {
                                    validator: (_, value) => {
                                        if (value === "," || value === ".") {
                                            return Promise.reject(i18n.t("please_enter_a_valid_value"));
                                        } else if (value?.includes('-')) {
                                            return Promise.reject(i18n.t("rates_greater_than_zero"));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input tabIndex={2} onKeyDown={(e) => validateMonetaryInput(e, false)} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}