import { Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ReportViewContext } from '../../../../context/ReportViewContext';

import i18n from 'util/base/i18n';
import './DetailModal.sass';

export default function TransferModal() {
    const { handleDataTarget, dataTarget, isPlanningReportSelected, handleRowsIdToDetail } = useContext(ReportViewContext);

    const [selectedRowKeysTarget, setSelectedRowKeysTarget] = useState<React.Key[]>([]);

    const onSelectChangeTarget = (newSelectedRowKeys: React.Key[]) => {
        handleRowsIdToDetail(newSelectedRowKeys.map((key) => Number(key)));
        setSelectedRowKeysTarget(newSelectedRowKeys);
    };

    const rowSelectionTarget = {
        selectedRowKeys: selectedRowKeysTarget,
        onChange: onSelectChangeTarget,
    };
	
	useEffect(() => {
		if (isPlanningReportSelected) {
			handleDataTarget(dataTarget.filter(data => data.valueType !== "ATTRIBUTE"));
		} else {
			handleDataTarget([
				{
					key: '1',
					name: i18n.t<string>("attributes"),
					index: 0,
					valueType: 'ATTRIBUTE'
				},
                {
					key: '2',
					name: i18n.t<string>("ledger_account"),
					index: 1,
					valueType: 'ACCOUNT_HIERARCHY'
				},
				{
					key: '3',
					name: i18n.t<string>("cost_center"),
					index: 2,
					valueType: 'COST_CENTER'
				},
			]);
		}
	}, [isPlanningReportSelected])

    return (
        <div id='detail-modal-revenue'>
            <div className='detail-modal-revenue-tables'>
                <Table
                    pagination={false}
                    bordered={false}
                    dataSource={dataTarget}
                    rowKey="index"
                    rowSelection={rowSelectionTarget}
                    columns={[
                        {
                            title: i18n.t<string>("level"),
                            dataIndex: 'name',
                            width: 300,
                            className: 'drag-visible',
                            onHeaderCell: () => {
                                return {
                                    style: {
                                        textAlign: 'left',
                                        color: '#045594',
                                        background: '#DBDBDB'
                                    }
                                }
                            }
                        }
                    ]}
                />
            </div>
        </div>
    );
}