import { Button, Form, Input, Modal, Row } from "antd";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { CostCategoryData, CostCategoryModalProps } from "../ICostCategory";
import Col from "antd/es/grid/col";
import { useState } from "react";
import { validateFormField } from "util/functions/validateFormField";
import { Notification } from "components/notification/Notification";
import { ImageBox } from "components/imageBox/ImageBox";

export default function CostCategoryModal({
	form,
	handleSubmit,
	handleSave,
	handleCancel,
	isModalVisible,
	setIsModalVisible,
	isNewCategory,
	costCategoryList,
	setCostCategoryList,
	tableData,
	lineCheckedKey
}: CostCategoryModalProps) {

	const [isDisabled, setIsDisabled] = useState(false)

	function handleDeleteFromList(externalCode: string) {
		const index = costCategoryList.findIndex(c => c.externalCode === externalCode)
		const updatedCostCategoryList = [...costCategoryList]
		updatedCostCategoryList.splice(index, 1)
		setCostCategoryList(updatedCostCategoryList)
	}

	function handleEditCostCategory(costCategory: CostCategoryData) {
		setIsDisabled(true)
		form.setFieldsValue({
			externalCode: costCategory.externalCode,
			name: costCategory.name
		})
		const index = costCategoryList.indexOf(costCategory)
		const updatedCostCategoryList = [...costCategoryList]
		updatedCostCategoryList.splice(index, 1)
		setCostCategoryList(updatedCostCategoryList)
	}

	return (
		<Modal
			width={600}
			title={isNewCategory ? i18n.t("costCategory.button") : i18n.t("costCategory.edit")}
			visible={isModalVisible}
			okButtonProps={{
				htmlType: isNewCategory ? "button" : "submit",
				form: "cost-category-form"
			}}
			onCancel={() => {
				setIsDisabled(false)
				handleCancel()
			}}
			className="gs-modal"
			cancelText={i18n.t<string>("cancel")}
			okText={i18n.t<string>("save")}
			onOk={() => {
				if (isNewCategory) {
					if (costCategoryList.length > 0) {
						handleSave(costCategoryList);
					} else {
						Notification({
							type: 'warning',
							message: i18n.t("no_items_added_to_list")
						})
					}
				} else {
					form.validateFields().then(() => {
						setIsModalVisible(false);
					})
				}
			}}
			centered
		>
			<Form
				form={form}
				name="cost-category-form"
				onFinish={(data) => {
					setIsDisabled(false)
					handleSubmit([data])
				}}
				layout="vertical"
				requiredMark={false}
				initialValues={{ id: null }}
			>
				<div className="inputs-container">
					<Row>
						<Col span={7} style={{ marginRight: 20 }}>
							<Form.Item
								name="externalCode"
								label={i18n.t<string>("external_code")}
								rules={[
									{ required: true, message: i18n.t<string>("required_field") },
									() => ({
										validator(_, value: string) {
											return validateFormField(value, 'externalCode', tableData, costCategoryList, i18n.t<string>("the_external_code_field_cannot_repeat"), lineCheckedKey);
										},
									})
								]}
							>
								<Input id="cost-category-external-code" />
							</Form.Item>
						</Col>
						<Col span={16} >
							<Form.Item
								name="name"
								label={i18n.t<string>("name")}
								rules={[
									{ required: true, message: i18n.t<string>("required_field") },
									() => ({
										validator(_, value: string) {
											return validateFormField(value, 'name', tableData, costCategoryList, i18n.t("the_name_field_cannot_repeat"), lineCheckedKey);
										},
									})
								]}
							>
								<Input id="cost-category-name" />
							</Form.Item>
						</Col>
					</Row>
					{isNewCategory &&
						<Button
							style={{ marginTop: -10 }}
							type="default"
							htmlType="submit"
							className="gs-secondary-button"
							icon={<Icon icon="akar-icons:circle-plus-fill" />}
						>
							{i18n.t<string>("addToList")}
						</Button>}
				</div>
			</Form>
			{isNewCategory &&
				<div className="list-of-added-items">
					{costCategoryList.length > 0
						?
						<>
							<div className="cost-category-list-header">
								<Row align="middle" gutter={2}>
									<Col span={8} style={{ paddingLeft: 12 }}>{i18n.t("code")}</Col>
									<Col span={14} style={{ paddingLeft: 6 }}>{i18n.t("name")}</Col>
									<Col span={1} />
									<Col span={1} />
								</Row>
							</div>
							<div className="cost-category-list-content">
								{costCategoryList.map((costCategory) => {
									return (
										<Row key={costCategory.externalCode} align="middle" gutter={2}>
											<Col span={8} style={{ justifyContent: 'start' }}>{costCategory.externalCode}</Col>
											<Col span={14} style={{ justifyContent: 'start' }}>{costCategory.name}</Col>
											<Col span={1} >
												<Button
													disabled={isDisabled}
													onClick={() => handleEditCostCategory(costCategory)}
													icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
												/>
											</Col>
											<Col span={1} >
												<Icon
													className="delete-icon"
													onClick={() => handleDeleteFromList(costCategory.externalCode)}
													icon="fa6-solid:trash"
												/>
											</Col>
										</Row>
									)
								})}
							</div>
						</>
						:
						<ImageBox styles={{ paddingTop: 25 }} />
					}
				</div>
			}
		</Modal>
	);
}