import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import i18n from "util/base/i18n";

export const importProps: IImportModal[] = [
    {
        importUrl: "/expenses/budget-opening/import/planned",
        templateUrl: "/expenses/budget-opening/template/planned?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
        type: 'excel',
        title: i18n.t("fixed_expense_texts.imports.import_budget"),
        hasImportOption: true,
    },
    {
        importUrl: "/expenses/budget-opening/import/realized",
        templateUrl: "/expenses/budget-opening/template/realized?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
        type: 'excel',
        title: i18n.t("fixed_expense_texts.imports.import_realized"),
        hasImportOption: true,
    },
    {
        importUrl: "/expenses/budget-opening/import/history",
        templateUrl: "/expenses/budget-opening/template/history?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
        type: 'excel',
        title: i18n.t("fixed_expense_texts.imports.import_history"),
        hasImportOption: true,
    },
    {
        importUrl: "/expenses/budget-opening/import/planned/yearly",
        templateUrl: "/expenses/budget-opening/template/planned/yearly?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
        type: 'excel',
        title: i18n.t("fixed_expense_texts.imports.import_budget_12_months"),
        hasImportOption: true,
    },
    {
        importUrl: "/expenses/budget-opening/import/history/yearly",
        templateUrl: "/expenses/budget-opening/template/history/yearly?client={client}&user={user}&locale={locale}&businessUnit={businessUnit}&organization={organization}&scenario={scenario}",
        type: 'excel',
        title: i18n.t("fixed_expense_texts.imports.import_12_month_history"),
        hasImportOption: true,
    },
]
