import { createContext, useState, useContext } from "react";
import { NewReportContextProps, ReportType } from "../IRegistrationSalesReports";

const NewReportContext = createContext<NewReportContextProps | undefined>(undefined);

export const NewReportProvider: React.FC = ({ children }) => {
	const [reportType, setReportType] = useState<ReportType>();

	const isPlanningReport = (type: ReportType = reportType): boolean => {
		return (type === ReportType.PLANNING || type?.toString() === "PLANNING");
	}

	return (
		<NewReportContext.Provider value={{
			reportType,
			setReportType,
			isPlanningReport
		}}>
			{children}
		</NewReportContext.Provider>
	);
};

export const useNewReportContext = () => {
	const context = useContext(NewReportContext);
	if (!context) {
		throw new Error('useNewReportContext deve ser usado dentro de um NewReportProvider');
	}
	return context;
};
