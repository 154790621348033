import { Filter } from "components/filter/Filter";
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton";
import i18n from "util/base/i18n";
import { useAccountingLedgerContext } from "../../context/AccountingLedgerContext";
import { Carousel, Button } from "antd";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import { useEffect, useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { FilterBase } from "components/filter/FilterBase";
import "./styles.sass";
import { useBusinessUnitOptions } from "hooks/useBusinessUnitOptions";

export function FilterHeader() {
    const {
        costCenter,
        setCostCenter,
        period,
        costCenterOptions,
        spendingPackage,
        setSpendingPackage,
        spendingPackageOptions,
        handleChangePeriod,
        flexFieldsFilters,
        isFetchingFlexFields,
        setAccountAccounting,
        accountAccounting,
        onLoadAccountingLedger,
        accountAccountingOptions,
        setFlexFieldsSelected,
        flexFieldDetails,
        flexFieldValueDetailSelected,
        setFlexFieldValueDetailSelected,
        businessUnit,
        setBusinessUnit,
        setCurrentPage,
        currentPage
    } = useAccountingLedgerContext();
    const [filtersPerRow, setFiltersPerRow] = useState<number>(6);
    const windowSize = useWindowSize();
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const { data: businessUnitOptions } = useBusinessUnitOptions();

    useEffect(() => {
        setFlexFieldsSelected(selectedFilters);
    }, [selectedFilters,]);

    useEffect(() => {
        const updatedFiltersPerRow: number = Math.floor((windowSize.width - 258) / 210);
        setFiltersPerRow(updatedFiltersPerRow);
    }, [windowSize]);

    useEffect(() => {
        const isEnabled = period[0] !== null && period[1] !== null;
        setIsButtonEnabled(isEnabled);
    }, [period]);

    const handleFilterChange = async (value, filterId) => {
        setSelectedFilters(prevState => {
            const existingFilter = prevState.find(filter => filter.filterId === filterId);
            if (value.length === 0) {
                const updatedFilters = prevState.filter(filter => filter.filterId !== filterId);
                return updatedFilters;
            } else if (existingFilter) {
                const updatedFilters = prevState.map(filter =>
                    filter.filterId === filterId ? { ...filter, value } : filter
                );
                return updatedFilters;
            } else {
                const updatedFilters = [...prevState, { filterId, value }];
                return updatedFilters;
            }
        });
    };

    const handleApply = () => {
        if (currentPage === 1) {
            onLoadAccountingLedger(false, false, false, null, null, null,);
        }
        setCurrentPage(1);
    };

    return (
        < div className="filter-header-container">
            {isFetchingFlexFields ? (
                <FilterSkeleton direction="row" active amount={4} gap={10} cardClassName="filter-card-skeleton" />
            ) : (
                <>
                    <Filter
                        id="period-filter"
                        value={period}
                        setValue={handleChangePeriod}
                        title={i18n.t("period")}
                        type="datepicker"
                        link="/"
                        data={[]}
                        datepicker={{
                            format: "MM/YYYY",
                            picker: "month",
                        }}
                        buttonStyles={{ cursor: "default" }}
                    />

                    <Carousel {...carouselDefaultProps} slidesPerRow={filtersPerRow} >
                        <Filter
                            id="operating_unit-filter"
                            title={i18n.t("operating_unit")}
                            type="checkbox"
                            searchPlaceholder={i18n.t("search_by_operating_unit")}
                            value={businessUnit}
                            setValue={(value) => {
                                setBusinessUnit(value);
                            }}
                            isFetching={false}
                            data={businessUnitOptions}
                        />
                        <Filter
                            id="cost-center-filter"
                            title={i18n.t("cost_center")}
                            type="checkbox"
                            searchPlaceholder={i18n.t("search_by_cost_center")}
                            value={costCenter}
                            setValue={(value) => {
                                setCostCenter(value);
                            }}
                            isFetching={false}
                            data={costCenterOptions}
                        />

                        <FilterBase
                            key="account-accounting-filter"
                            id="account-accounting-filter"
                            title={i18n.t("accounting_account")}
                            type="checkbox"
                            buttonStyles={{ border: "none" }}
                            searchPlaceholder={i18n.t("search_by_accounting_account")}
                            value={accountAccounting}
                            setValue={(value) => {
                                setAccountAccounting(value);
                            }}
                            hasPagination={accountAccountingOptions?.length > 100}
                            allFilterOptions={accountAccountingOptions}
                            isFetching={false}
                        />

                        {/* {
                                                    FILTRO DE DETALHE VAI SER ADD FUTURAMENTE 

                            flexFieldDetails.map((ffDetail) => {
                                return (
                                    <Filter
                                        key={ffDetail.value}
                                        id="flex-field-filter"
                                        title={ffDetail.label}
                                        type="checkbox"
                                        value={flexFieldValueDetailSelected}
                                        setValue={setFlexFieldValueDetailSelected}
                                        data={ffDetail.flexFieldValues.map(value => ({
                                            value: value.value,
                                            label: value.label,
                                        }))}
                                        hasSearch
                                        hasSelectNone
                                    />)
                            })
                        } */}

                        {flexFieldsFilters.map((ffFilter) => {
                            if (ffFilter.selectedOption?.length > 0) {
                                ffFilter.selectedOption = [];
                            }
                            return (
                                <Filter
                                    key={ffFilter.value}
                                    id="flex-field-filter"
                                    title={ffFilter.label}
                                    type="checkbox"
                                    value={ffFilter.selectedOption}
                                    setValue={(value) => handleFilterChange(value, ffFilter.value)}
                                    data={ffFilter.allValues.map(value => ({
                                        value: value.fieldValueId,
                                        label: value.externalCode + " - " + value.description,
                                        ...value
                                    }))}
                                    isFetching={ffFilter.isFetching ?? false}
                                    hasSearch
                                    hasSelectNone={false}
                                />
                            );
                        })}
                    </Carousel>
                    <div className="gs-flex"
                    >
                        <Button
                            className="gs-main-button"
                            title={i18n.t("apply")}
                            onClick={handleApply}
                            disabled={!isButtonEnabled}
                        >{i18n.t("apply")}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}
