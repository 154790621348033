import i18n from "util/base/i18n";
import { getColumnComparativeScenarioYears } from "./getColumn";

export function buildColumnComparativeScenarioYears(monthFormatted, monthFormatDataIndex, reportVisualizationFilters, rowsFromBackend) {
    const baseValue = getColumnComparativeScenarioYears({
        index: monthFormatDataIndex,
        title: i18n.t<string>('base'),
        render: "valueBase",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
    });

    const targetValue = getColumnComparativeScenarioYears({
        index: monthFormatDataIndex,
        title: i18n.t<string>('revenue.report_viewer.comparative'),
        render: "valueTarget",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
    });

    const variableValue = getColumnComparativeScenarioYears({
        index: monthFormatDataIndex,
        title: i18n.t<string>('variation'),
        render: "variableValue",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
    });

    const variablePercente = getColumnComparativeScenarioYears({
        index: monthFormatDataIndex,
        title: (i18n.t<string>('variation') + " %"),
        render: "variablePercente",
        reportVisualizationFilters: reportVisualizationFilters,
        rowsFromBackend,
    });

    return [baseValue, targetValue, variableValue, variablePercente];
}
