import { FormattedNumber } from "react-intl";
import i18n from "util/base/i18n";
import { CellContent } from "../../CellContent";

export function buildColumnForecastTotal(reportVisualizationFilters: any) {
    const columnTotalList = [];

    columnTotalList.push({
        title: i18n.t<string>('total_forecast'),
        dataIndex: 'totalForecast',
        align: 'center',
        key: 'total',
        fixed: 'right',
        width: 90,
        render: (text, record) => {
            let textDecoration = "";

            if (record.style && record.style.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record.style && record.style.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            const { totalForecast } = record?.reportValues?.columnValueBeans ? record.reportValues.columnValueBeans : record.columns;

            let renderedValue = totalForecast ? totalForecast : 0;

            if (reportVisualizationFilters.showThousand) {
                renderedValue = renderedValue / 1000;
            }

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={renderedValue}
                        style={`decimal`}
                        currencyDisplay="symbol"
                        currency={record.isoCode ? record.isoCode : "BRL"}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    });

    columnTotalList.push({
        title: i18n.t<string>('total_variation_planned_forecast'),
        dataIndex: 'totalVariationPlannedForecast',
        align: 'center',
        key: 'total',
        fixed: 'right',
        width: 90,
        render: (text, record) => {
            let textDecoration = "";

            if (record.style && record.style.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record.style && record.style.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            const { totalVariationPlannedForecast } = record?.reportValues?.columnValueBeans ? record.reportValues.columnValueBeans : record.columns;

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={totalVariationPlannedForecast ? (totalVariationPlannedForecast / 100) : 0}
                        style={`percent`}
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    });

    columnTotalList.push({
        title: i18n.t<string>('total_planned'),
        dataIndex: 'totalPlanned',
        align: 'center',
        fixed: 'right',
        key: 'total',
        width: 90,
        render: (text, record) => {
            let textDecoration = "";

            if (record.style && record.style.isScratched) {
                textDecoration = textDecoration + "line-through "
            }

            if (record.style && record.style.isUnderlined) {
                textDecoration = textDecoration + "underline"
            }

            record = { ...record, rowStyle: record.levelStyle };

            if (record.title) {
                return <CellContent currentRow={record} textDecoration={textDecoration} />
            }

            const { totalPlanned } = record?.reportValues?.columnValueBeans ? record.reportValues.columnValueBeans : record.columns;

            let renderedValue = totalPlanned ? totalPlanned : 0;

            if (reportVisualizationFilters.showThousand) {
                renderedValue = renderedValue / 1000;
            }

            return (
                <CellContent currentRow={record} textDecoration={textDecoration}>
                    <FormattedNumber
                        value={renderedValue}
                        style={`decimal`}
                        currencyDisplay="symbol"
                        minimumFractionDigits={reportVisualizationFilters.decimalPlaces}
                        maximumFractionDigits={reportVisualizationFilters.decimalPlaces}
                    />
                </CellContent>
            );
        }
    });

    return columnTotalList;
}
