import { Form, FormInstance, Input, InputRef, Table, Tooltip } from "antd";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { formatNumber } from "util/formatNumber";
import moment from "moment";
import { validateMonetaryInput } from "util/functions/validateKey";
import { Column, EditableCellProps, EditableRowProps, ValuesColumns, ValuesToSave } from "../../../IHumanResoucers";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { verifyBudgetPeriod } from "util/functions/verifyBudgetPeriod";
import { useBudgetDates } from "hooks/useBudgetDates";
import { ModuleType } from "util/types/types";

interface Props {
	isInitialInformation?: boolean | false;
	dataTable: ValuesColumns[];
	year: string;
	realizedPeriod?: string;
	isFetching: boolean;
	valuesToSave?: ValuesToSave[];
	setValuesToSave?: (data: ValuesToSave[]) => void;
	isResultTable?: boolean;
	collaboratorId?: number
	admissions?: number;
	setAdmissions?: (quantity: number) => void;
	isOnResultValues?: boolean;
	isLegalFees?: boolean;
	valuesTableData?: any[]
	setValuesTableData?(a: any[]): void;
	setColumnsTable?(a: any[]): void;
	selectedTab?: any;
	setColumnsToExport?(a: any[]): void;
}
export function InitialInfoTable({
	dataTable,
	year,
	realizedPeriod,
	isFetching,
	valuesToSave,
	isInitialInformation = false,
	isResultTable = false,
	setValuesToSave,
	collaboratorId,
	isOnResultValues = false,
	isLegalFees = false,
	valuesTableData,
	setValuesTableData,
	selectedTab,
	setColumnsTable,
	setColumnsToExport
}: Props) {
	const [tableData, setTableData] = useState([])
	const [columns, setColumns] = useState([]);
	const EditableContext = createContext<FormInstance<any> | null>(null);
	const defaultMonth: { period: string, value: number }[] = [];
	const { data: budgetPeriodDates } = useBudgetDates(ModuleType.EXPENSERESOURCES)

	for (let month = 1; month <= 12; month++) {
		const monthString = month < 10 ? `0${month}` : `${month}`;
		const monthYearString = moment(`${monthString}/01/${year}`).format('YYYY-MM');
		defaultMonth.push({
			period: monthYearString,
			value: 0
		});
	}

	function buildTableData(data: ValuesColumns[]) {
		let newDataTable = [];
		let newColumns: Column[] = [{
			title: i18n.t("period"),
			key: "period",
			dataIndex: "period",
			editable: false,
			className: 'first-column',
			align: "left",
			render: (month) => moment(month).format('MMM/YYYY').replace(/^\w/, (c) => c.toUpperCase()),
			fixed: "left",
		}];
		const budDate = budgetPeriodDates.period.find(budDate => budDate.year.toString() === year)
		const { isClosedBudgetPeriod } = verifyBudgetPeriod(budDate, moment(), budgetPeriodDates.localDate, ModuleType.EXPENSERESOURCES)
		if (isInitialInformation) {
			const realized = budDate.expensesResourcesRealizedPeriod ? moment(realizedPeriod).month() + 1 : 0;
			const rowsNumber = realized === 12 ? realized : realized === 0 ? 1 : realized + 1
			for (let index = 0; index < rowsNumber; index++) {
				let newLine = {};
				const month = moment(`${year}-${String(index + 1).padStart(2, '0')}`, 'YYYY-MM')
				Object.assign(newLine, { period: month.format('YYYY-MM') })
				Object.assign(newLine, { isEditable: verifyBudgetPeriod(budDate, month, budgetPeriodDates.localDate, ModuleType.EXPENSERESOURCES).isEditable})
				data.forEach((response) => {
					Object.assign(newLine, {
						[`${response.field.id}-${response.field.description}-${response.field.physicalType}_${response.field.totalType}`]
							: response.periodsValues.find(item => moment(item.period).isSame(defaultMonth[index].period))?.value ? response.periodsValues.find(item => moment(item.period).isSame(defaultMonth[index].period))?.value :
								0,
					});
				})
				newDataTable.push(newLine);
			}
			
			data.forEach((response) => {
				newColumns.push({
					title: response.field.description,
					key: response.field.id,
					dataIndex: `${response.field.id}-${response.field.description}-${response.field.physicalType}_${response.field.totalType}`,
					editable: response.isEditable,
					align: 'center',
					render: (value, object) => formatNumber(value, { maximumFractionDigits: 2, minimumFractionDigits: 2, }),
				})
			})
		} else {

			for (let index = 0; index < defaultMonth.length; index++) {
				let newLine = {};
				Object.assign(newLine, { period: moment(defaultMonth[index].period).format('YYYY-MM') })
				data.forEach((response) => {
					const currentPeriodValue = response.periodsValues.find(item => moment(item.period).isSame(defaultMonth[index].period))?.value ?
						response.periodsValues.find(item => moment(item.period).isSame(defaultMonth[index].period)).value : 0;
					Object.assign(newLine, { isEditable: verifyBudgetPeriod(budDate, moment(defaultMonth[index].period, 'YYYY-MM'), budgetPeriodDates.localDate, ModuleType.EXPENSERESOURCES).isEditable})	
					Object.assign(newLine, {
						[`${response.field.id}-${response.field.description}-${response.field.physicalType}_${response.field.totalType}`]: currentPeriodValue,
					});
				})
				newDataTable.push(newLine);
			}
			data.forEach((response) => {
				newColumns.push({
					title: response.field.description,
					key: response.field.id,
					dataIndex: `${response.field.id}-${response.field.description}-${response.field.physicalType}_${response.field.totalType}`,
					editable: isResultTable ? false : response.isEditable,
					align: 'center',
					render: (value) => formatNumber(value, { maximumFractionDigits: 2, minimumFractionDigits: 2, }),
				})
			})
		}

		const newColumnsWithoutFormat = newColumns.map((column) => {
			if (column.key === "period") return column;
			return {
				...column,
				render: (value) => value
			};
		});

		setColumns(newColumns)

		if (selectedTab === 'result-values') {
			setValuesTableData(newDataTable);
			setColumnsTable(newColumns);
			setColumnsToExport(newColumnsWithoutFormat);
		} else {
			setTableData(newDataTable);
		}
	}

	useEffect(() => {
		if (!dataTable) return
		buildTableData(dataTable);
	}, [dataTable])

	function Summary({ totals, }: { totals: number[] }) {
		return (
			<>
				<Table.Summary.Row className="summary-total" style={{ background: '#FBE6E6' }}>
					<Table.Summary.Cell index={0} align="left">Total</Table.Summary.Cell>
					{totals.map((columnTotal, index) => {
						return (
							<Table.Summary.Cell align="center" index={index + 1}>
								{formatNumber(columnTotal, { maximumFractionDigits: 2, minimumFractionDigits: 2, })}
							</Table.Summary.Cell>
						)
					})}
				</Table.Summary.Row>
			</>
		)
	}

	function createSummary(data: readonly any[]) {
		if (!data.length || isLegalFees) return
		const columnsAmount = Object.keys(data[0]).length - 2;
		const total: number[] = data.reduce((acc, val, indexData) => {
			Object.keys(val).filter(k => k !== 'period' && k !== 'isEditable').forEach((key, index) => {
				const isTotalTypeSUM = key.endsWith("SUM");
				if (!isTotalTypeSUM && indexData === data.length - 1) {
					acc[index] = val[key];
				} else {
					acc[index] += val[key];
				}
			})
			return acc;
		}, Array(columnsAmount).fill(0));
		return <Summary totals={total} />
	}

	const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
		const [form] = Form.useForm();
		return (
			<Form form={form} component={false} >
				<EditableContext.Provider value={form}>
					<tr {...props} />
				</EditableContext.Provider>
			</Form>
		);
	};

	function handleReplicateValues(record, isUp: boolean, columnEdited: string) {
		const updatedTableData = selectedTab ? JSON.parse(JSON.stringify(valuesTableData)) : JSON.parse(JSON.stringify(tableData))
		let newDataSave: ValuesToSave[] = [];
		updatedTableData.forEach(item => {
			let newValue = item[columnEdited];
			if (isUp && moment(item.period).isBefore(record.period) && !monthIsLocked(item)) {
				newValue = record[columnEdited];
				newDataSave.push(buildDataToSave(columnEdited.replaceAll(" ", "-"), item, newValue));
			} else if (!isUp && moment(item.period).isAfter(record.period)) {
				newValue = record[columnEdited];
				newDataSave.push(buildDataToSave(columnEdited.replaceAll(" ", "-"), item, newValue));
			}
			item[columnEdited] = newValue;
		})
		setValuesToSave([...valuesToSave, ...newDataSave]);
		if (selectedTab === 'result-amount') {
			setValuesTableData(updatedTableData)
		} else {
			setTableData(updatedTableData);

		};
	}

	//TO_DO: ARRUMAR QUANDO ELE EDITAR UMA CELULA QUE FOI REPLICADA
	function buildDataToSave(dataIndex: string, record: any, value: number): ValuesToSave {
		let indexOfUnderline = 0;
		if (dataIndex.endsWith("FINAL_BALANCE")) {
			indexOfUnderline = dataIndex.lastIndexOf("_", dataIndex.length - 14);
		} else {
			indexOfUnderline = dataIndex.lastIndexOf("_");
		}
		const splitedDataIndex = dataIndex.substring(0, indexOfUnderline).split('-');
		const fieldId = Number(splitedDataIndex[0]);
		const updatedValuesToSave = JSON.parse(JSON.stringify(valuesToSave));
		const indexValue = updatedValuesToSave.findIndex(item => item.fieldId === fieldId && item.period === record.period)
		const physicalType = (splitedDataIndex && !splitedDataIndex.slice(-1).includes("undefined")) ? splitedDataIndex.slice(-1)[0] : undefined;
		let newDataSave: ValuesToSave = {
			employedResourceId: 0,
			entryValue: 0,
			fieldId: 0,
			period: "",
			physicalType: ""
		};
		if (indexValue < 0) {
			newDataSave = {
				period: record.period,
				fieldId,
				entryValue: value,
				employedResourceId: collaboratorId,
				physicalType: physicalType
			}
		} else {
			newDataSave = {
				period: record.period,
				fieldId,
				entryValue: value,
				employedResourceId: collaboratorId,
				physicalType: physicalType
			}
		}
		return newDataSave
	}

	const EditableCell: React.FC<EditableCellProps> = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef<InputRef>(null);
		const form = useContext(EditableContext)!;

		useEffect(() => {
			if (editing) {
				inputRef.current!.focus();
				inputRef.current!.select();
			}
		}, [editing]);

		const toggleEdit = () => {
			if (!record.isEditable) {
				return
			};
			setEditing(!editing);
			form.setFieldsValue({ [dataIndex]: record[dataIndex] });
		};

		const save = async () => {
			try {
				const values: { key: 'string' } = await form.validateFields();
				const key = Object.keys(values)[0]
				const value = Object.values(values)[0] ?
					Number(Object.values(values)[0].toString().replace(',', '.'))
					: 0
				if (record[key] === value) {
					toggleEdit();
					return
				}
				record[key] = value

				const newBuildDataSave = buildDataToSave(key, record, value);
				let filteredValuesToSave = valuesToSave.filter(value => (value.fieldId != newBuildDataSave.fieldId || value.period != newBuildDataSave.period))
				setValuesToSave([...filteredValuesToSave, newBuildDataSave]);
				const updatedTableData = selectedTab ? JSON.parse(JSON.stringify(valuesTableData)) : JSON.parse(JSON.stringify(tableData))
				const index = updatedTableData.findIndex(item => item.period === record.period)
				updatedTableData.splice(index, 1, record)
				if (selectedTab === 'result-amount') {
					setValuesTableData(updatedTableData)
				} else {
					setTableData(updatedTableData);

				};
				toggleEdit();
			} catch (errInfo) {
				console.log('Save failed:', errInfo);
			}
		};

		let childNode = children;
		let index = null
		if (record) {
			index = selectedTab ? valuesTableData.indexOf(record) : tableData.indexOf(record)
		}
		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{ margin: 0, padding: '2px 4px' }}
					name={dataIndex}
				>
					<Input
						onKeyDown={validateMonetaryInput}
						ref={inputRef}
						onPressEnter={save}
						onBlur={save}
					/>
				</Form.Item>
			) : (
				<div className="dist-modal-table-input-cell">
					<div className="editable-cell-value-wrap" onClick={toggleEdit}>
						{children}
					</div>
					{(!isInitialInformation && !monthIsLocked(record)) &&
						<div className="dist-modal-replicate-buttons-container">
							{index !== 0 &&
								<Tooltip title="Replicar para cima">
									<Icon onClick={() => handleReplicateValues(record, true, dataIndex)} icon="mdi:chevron-up-box-outline" />
								</Tooltip>
							}
							{index < (tableData.length - 1) &&
								<Tooltip title="Replicar para baixo">
									<Icon onClick={() => handleReplicateValues(record, false, dataIndex)} icon="mdi:chevron-down-box-outline" />
								</Tooltip>
							}
						</div>
					}
				</div>
			)
		}

		return <td {...restProps}>{childNode}</td>;
	};

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	const cols = columns.map(col => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record: any) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				align: 'center',
			}),
		};
	});

	function monthIsLocked(record) {
		if (realizedPeriod && !moment(record?.period, "YYYY-MM").isAfter(moment(realizedPeriod, "YYYY-MM"))) return "blocked-cell";
		return null;
	}

	return (
		<Table
			className={`gs-table distribution-modal-container human-resources-table ${isOnResultValues ? "is-result-values" : ""}`}
			dataSource={selectedTab ? valuesTableData : tableData}
			columns={cols}
			rowClassName={(record) => {
				if (realizedPeriod && !moment(record?.period, "YYYY-MM").isAfter(moment(realizedPeriod, "YYYY-MM"))) return "blocked-cell";
				return '';
			}}
			pagination={false}
			bordered
			summary={(data) => createSummary(data)}
			components={components}
			loading={{
				spinning: isFetching,
				tip: `${i18n.t<string>("loading")}...`
			}}
			scroll={{ x: "max-content" }}
		/>
	)
}