import Slider, { SliderMarks } from "antd/lib/slider"
import { Checkbox, Col, Form, Input, InputNumber, Row, Select, Table } from "antd"
import { cloneDeep } from "lodash"
import { useEffect, useState } from "react"
import i18n from "util/base/i18n"
import { selectProps } from "util/props/props"
import { StepThreeProps } from "./IStepThree"

export default function StepThree({
    form,
    costCenterList,
    accountingList,
    inputValue,
    setInputValue,
    currentStep,
    periodColumns,
    periodValues,
    setPeriodValues,
    isWhole,
    setIsWhole,
    isInstallment,
    setIsInstallment,
}: StepThreeProps) {
    const [competenceDebitStatus, setCompetenceDebitStatus] = useState<boolean>(false);
    const [competenceCreditStatus, setCompetenceCreditStatus] = useState<boolean>(false);
    const [cashDebitStatus, setCashDebitStatus] = useState<boolean>(false);
    const [cashCreditStatus, setCashCreditStatus] = useState<boolean>(false);
    const budgetAmount = Form.useWatch("budgetAmount", form);
    const baseValue = Form.useWatch("base", form);
    const period = Form.useWatch("period", form);

    const onChange = (newValue: number) => {
        setInputValue(newValue);
        form.setFieldValue("box-cronogram", newValue);
    };

    const fieldFormatting = [
        {
            name: "DebitAcc",
            label: `${i18n.t<string>("accounting_account")} (${i18n.t<string>("debit")})`,
            options: accountingList,
            pair: "DebitCc"
        },
        {
            name: "DebitCc",
            label: `${i18n.t<string>("cost_center")} (${i18n.t<string>("debit")})`,
            options: costCenterList,
            pair: "DebitAcc"
        },
        {
            name: "CreditAcc",
            label: `${i18n.t<string>("accounting_account")} (${i18n.t<string>("credit")})`,
            options: accountingList,
            pair: "CreditCc"
        },
        {
            name: "CreditCc",
            label: `${i18n.t<string>("cost_center")} (${i18n.t<string>("credit")})`,
            options: costCenterList,
            pair: "CreditAcc"
        },
    ]

    const marks: SliderMarks = {
        0: 'AV',
        30: '30d',
        90: '90d',
        180: '180d'
    }

    useEffect(() => {
        if (periodValues[0]) {
            const newValues = cloneDeep(periodValues);

            if (isWhole) {
                const basedValue = budgetAmount * (baseValue / 100);
                const monthsAmount = (inputValue / 30) > 0 ? (inputValue / 30) + 1 : 1;
                const remainer = monthsAmount % 1;

                const firstValue = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                    compactDisplay: "long",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }).format(basedValue * (1 - remainer));

                const secondValue = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                    compactDisplay: "long",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }).format(basedValue * remainer);

                Object.keys(newValues[0]).forEach((key, index) => {
                    if (index + 1 === Math.trunc(monthsAmount + Number(period))) {
                        newValues[0][key] = firstValue;
                        return;
                    }
                    if (index + 1 === Math.trunc(monthsAmount + 1 + Number(period))) {
                        newValues[0][key] = secondValue;
                        return;
                    }

                    newValues[0][key] = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                        compactDisplay: "long",
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }).format(Number("0.00"));

                })

                setPeriodValues([newValues[0]]);
            }

            if (isInstallment) {
                if (inputValue > 30) {
                    const basedValue = budgetAmount * (baseValue / 100);
                    const monthsAmount = (inputValue / 30) > 0 ? (inputValue / 30) : 1;
                    const perMonthValue = inputValue > 30 ? (30 / inputValue) * 100 : 100;
                    const remainer = 100 - (perMonthValue * Math.trunc(monthsAmount));

                    const monthsValue = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                        compactDisplay: "long",
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }).format(basedValue * (perMonthValue / 100));

                    const lastMonthValue = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                        compactDisplay: "long",
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }).format(basedValue * (remainer / 100));

                    Object.keys(newValues[0]).forEach((key, index) => {
                        if (index > Number(period ? period : 0) && index < Math.trunc(monthsAmount + 1 + Number(period))) {
                            newValues[0][key] = monthsValue;
                            return;
                        }

                        if (index == Math.trunc(monthsAmount + 1 + Number(period))) {
                            newValues[0][key] = lastMonthValue;
                            return;
                        }

                        newValues[0][key] = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                            compactDisplay: "long",
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        }).format(Number("0.00"));
                    })
                } else {
                    const basedValue = budgetAmount * (baseValue / 100);
                    const monthsAmount = (inputValue / 30) > 0 ? (inputValue / 30) + 1 : 1;
                    const remainer = monthsAmount % 1;

                    const firstValue = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                        compactDisplay: "long",
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }).format(basedValue * (1 - remainer));

                    const secondValue = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                        compactDisplay: "long",
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }).format(basedValue * remainer);

                    Object.keys(newValues[0]).forEach((key, index) => {
                        if (index + 1 === Math.trunc(monthsAmount + Number(period))) {
                            newValues[0][key] = firstValue;
                            return;
                        }
                        if (index + 1 === Math.trunc(monthsAmount + 1 + Number(period))) {
                            newValues[0][key] = secondValue;
                            return;
                        }

                        newValues[0][key] = new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                            compactDisplay: "long",
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                        }).format(Number("0.00"));

                    })
                }
                setPeriodValues([newValues[0]]);
            }
        }
    }, [budgetAmount, isWhole, isInstallment, inputValue, period])

    function onCheckBoxChangeWhole() {
        setIsWhole(true);
        setIsInstallment(false);
    }

    function onCheckBoxChangeInstallment() {
        setIsInstallment(true);
        setIsWhole(false);
    }

    function validateDateFields(pairFieldValue: string | undefined, fieldName: string, fieldValue: string | undefined) {
        const hasError: boolean = (pairFieldValue === undefined && fieldValue !== undefined) || (pairFieldValue !== undefined && fieldValue === undefined);
        if (fieldName === "competenceDebitAcc" || fieldName === "competenceDebitCc") {
            setCompetenceDebitStatus(hasError);
        } else if (fieldName === "competenceCreditAcc" || fieldName === "competenceCreditCc") {
            setCompetenceCreditStatus(hasError);
        }

        if (fieldName === "cashDebitAcc" || fieldName === "cashDebitCc") {
            setCashDebitStatus(hasError);
        } else if (fieldName === "cashCreditAcc" || fieldName === "cashCreditCc") {
            setCashCreditStatus(hasError);
        }
        return Promise.resolve();
    }

    return (
        <div style={{ display: currentStep != 2 ? "none" : "block" }}>
            <Form
                form={form}
                name="new-account-form"
                layout="vertical"
                requiredMark={false}
            >
                <div className="fields-container">
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="base"
                                label={i18n.t<string>("base")}
                                rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                            >
                                <InputNumber
                                    onChange={value => {
                                        return (new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                            notation: "compact",
                                            currency: "BRL"
                                        }).format(Number(value)))
                                    }}
                                    controls={false}
                                    decimalSeparator={","}
                                    precision={2}
                                    min={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="period"
                                label={`${i18n.t<string>("period")} (+/-)`}
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="budgetAmount"
                                label={`${i18n.t<string>("budget_amount")}`}
                            >
                                <InputNumber
                                    onChange={value => {
                                        return (new Intl.NumberFormat(i18n.language.replace("_", "-"), {
                                            notation: "compact",
                                            currency: "BRL"
                                        }).format(Number(value)))
                                    }}
                                    controls={false}
                                    decimalSeparator={","}
                                    precision={2}
                                    min={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <h4 style={{ color: "#0065B3" }}>{i18n.t<string>("competence")}</h4>
                    <Row gutter={20}>
                        {fieldFormatting.map(item => {
                            const { name, label, options, pair } = item;
                            return (
                                <Col span={6} key={name}>
                                    <Form.Item
                                        key={`competence${name}`}
                                        name={`competence${name}`}
                                        label={label}
                                        validateStatus={/^Debit/.test(name) ? (competenceDebitStatus ? "error" : "") : (competenceCreditStatus ? "error" : "")}
                                        help={/^Debit/.test(name) ? (competenceDebitStatus ? i18n.t("fill_in_the_pair") : "") : (competenceCreditStatus ? i18n.t("fill_in_the_pair") : "")}
                                        rules={[({ getFieldValue }) => ({
                                            validator(_, value: any) {
                                                return validateDateFields(getFieldValue(`competence${pair}`), `competence${name}`, value);
                                            },
                                        }),]}
                                    >
                                        <Select
                                            options={options}
                                            placeholder={i18n.t<string>('select')}
                                            allowClear
                                            {...selectProps}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )
                        })}
                    </Row>
                    <h4 style={{ color: "#0065B3" }}>{i18n.t<string>("cash")}</h4>
                    <Row gutter={20}>
                        {fieldFormatting.map(item => {
                            const { name, label, options, pair } = item;
                            return (
                                <Col span={6} key={name}>
                                    <Form.Item
                                        key={`cash${name}`}
                                        name={`cash${name}`}
                                        label={label}
                                        validateStatus={/^Debit/.test(name) ? (cashDebitStatus ? "error" : "") : (cashCreditStatus ? "error" : "")}
                                        help={/^Debit/.test(name) ? (cashDebitStatus ? i18n.t("fill_in_the_pair") : "") : (cashCreditStatus ? i18n.t("fill_in_the_pair") : "")}
                                        rules={[({ getFieldValue }) => ({
                                            validator(_, value: any) {
                                                return validateDateFields(getFieldValue(`cash${pair}`), `cash${name}`, value);
                                            },
                                        }),]}
                                    >
                                        <Select
                                            options={options}
                                            placeholder={i18n.t<string>('select')}
                                            allowClear
                                            {...selectProps}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )
                        })}
                    </Row>
                    <div className={"box-cronogram-container"}>
                        <Row gutter={24} className={"slider-container"}>
                            <Col span={21}>
                                <Form.Item
                                    name={`box-cronogram`}
                                    label={'Cronograma de Caixa'}
                                    initialValue={inputValue}
                                >
                                    <Slider
                                        min={0}
                                        max={180}
                                        marks={marks}
                                        onChange={onChange}
                                        value={inputValue}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3} className={"slider-input-container"}>
                                <Input.Group compact>
                                    <InputNumber
                                        min={0}
                                        max={180}
                                        style={{ width: "50%" }}
                                        value={inputValue}
                                        onChange={onChange}
                                    />
                                    <Input
                                        placeholder="Dias"
                                        style={{ width: "50%" }}
                                        disabled
                                    />
                                </Input.Group>
                            </Col>
                        </Row>
                        <Row gutter={6}>
                            <Col span={3}>
                                <Form.Item
                                    name={"boxCronogramWhole"}
                                >
                                    <Checkbox
                                        onChange={onCheckBoxChangeWhole}
                                        checked={isWhole}
                                    >
                                        Integral
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    name={"boxCronogramInstallent"}
                                >
                                    <Checkbox
                                        onChange={onCheckBoxChangeInstallment}
                                        checked={isInstallment}
                                    >
                                        Parcelado
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form >
            <Row gutter={24} style={{ marginTop: "16px" }}>
                <Col span={24}>
                    <h4 style={{ color: "#0065B3" }}>Valor Orçamentário</h4>
                    <Table
                        className={"gs-table"}
                        columns={periodColumns}
                        dataSource={periodValues}
                    />
                </Col>
            </Row>
        </div >
    )
}
