import { Button, Cascader, Col, Form, Input, Modal, Row, Spin } from "antd";
import i18n from "util/base/i18n";
import { Icon } from "@iconify/react";
import { AccountRegistrationModalProps, ValueForm } from "../IAccountRegistration";
import { useEffect, useState } from "react";
import { validateFormField } from "util/functions/validateFormField";
import { Notification } from "components/notification/Notification";
import { ImageBox } from "components/imageBox/ImageBox";

export default function AccountRegistrationModal({
    isModalVisible,
    isNewAccount,
    handleSubmit,
    handleCancel,
    form,
    newAccountList,
    setNewAccountList,
    handleSaveAccount,
    parametersData,
    tableData,
    isFetchingParameters,
    parametersOption,
    selectedRowKeys
}: AccountRegistrationModalProps) {

    const [editDisebled, setEditDisebled] = useState(false);

    const modalTitle = isNewAccount
        ? i18n.t<string>("account_registration.new_account")
        : i18n.t<string>("account_registration.edit_account");

    function handleDeleteListItem(externalCode) {
        const categories = [...newAccountList];
        const i = categories.findIndex((category) => (category.externalCode === externalCode));
        categories.splice(i, 1);
        setNewAccountList(categories);
    }

    function handleEditListItem(account: ValueForm) {
        setEditDisebled(true);
        form.setFieldsValue({
            externalCode: account.externalCode,
            description: account.description,
            parameters: account.parameters.map(param => [param]),
        });
        handleDeleteListItem(account.externalCode);
    }

    function getParameterDescription(id: number) {
        const parameter = parametersData.find(parameter => parameter.value === id);
        return parameter.label;
    }

    return (
        <Modal
            width={1000}
            title={modalTitle}
            visible={isModalVisible}
            okButtonProps={ newAccountList.length > 0 || !isNewAccount ? { htmlType: "submit", form: "new-account-form" } : { disabled: true } }
            onCancel={() => {
                handleCancel()
            }}
            className="gs-modal account-registration-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            onOk={() => {
                if (isNewAccount) {
                    if (newAccountList.length > 0) {
                        handleSaveAccount(newAccountList);
                    } else {
                        Notification({
                            type: 'warning',
                            message: i18n.t("no_items_added_to_list")
                        })
                    }
                }
            }}
        >
            <Form
                form={form}
                name="new-account-form"
                className={isNewAccount ? "form-new-account" : ""}
                layout="vertical"
                onFinish={(data) => {
                    setEditDisebled(false)
                    handleSubmit(data)
                }}
            >
                <div className="fields-container">
                    <div className="input-content">
                        <Form.Item
                            name="externalCode"
                            label={i18n.t<string>("code")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                                () => ({
                                    validator(_, value: string) {
                                        return validateFormField(value, 'externalCode', tableData, newAccountList, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedRowKeys[0]);
                                    },
                                })
                            ]}
                        >
                            <Input id="input-template-detail" />
                        </Form.Item>
                    </div>
                    <div className="input-content">
                        <Form.Item
                            name="description"
                            label={i18n.t<string>("account")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                            ]}
                        >
                            <Input id="input-template-detail" />
                        </Form.Item>
                    </div>
                    <div className="input-content input-parameters" style={{ width: 400, minWidth: 400 }}>
                        <Form.Item
                            name="parameters"
                            label={i18n.t<string>("account_registration.hr_field")}
                            rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                        >
                            <Cascader
                                loading={isFetchingParameters}
                                options={parametersOption}
                                placeholder={i18n.t<string>("select")}
                                multiple
                            />
                        </Form.Item>
                    </div>
                </div>
                {isNewAccount &&
                    <Button
                        style={{ marginTop: -10, marginLeft: "auto" }}
                        type="default"
                        htmlType="submit"
                        className="gs-secondary-button"
                        icon={<Icon icon="akar-icons:circle-plus-fill" />}
                    >
                        {i18n.t('addToList')}
                    </Button>}
            </Form>
            {isNewAccount &&
                (newAccountList.length > 0 ?
                    <>
                        <div className="new-account-table-head">
                            <Row align="middle" gutter={2}>
                                <Col span={3}>{i18n.t<string>("code")}</Col>
                                <Col span={6}>{i18n.t<string>("account")}</Col>
                                <Col span={11}>{i18n.t<string>("parameters")}</Col>
                                <Col span={1} />
                                <Col span={1} />
                            </Row>
                        </div>
                        <div className="new-account-table-body">
                            {newAccountList.map((account) => {
                                return (
                                    <Row key={account.externalCode} align="middle" gutter={2}>
                                        <Col span={3}>{account.externalCode}</Col>
                                        <Col span={6}>{account.description}</Col>
                                        <Col span={11}>{account.parameters.map(item => getParameterDescription(item)).join("; ")}</Col>
                                        <Col span={1}>
                                            <Button
                                                disabled={editDisebled}
                                                onClick={() => handleEditListItem(account)}
                                                icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Icon
                                                className="delete-icon"
                                                onClick={() => handleDeleteListItem(account.externalCode)}
                                                icon="fa6-solid:trash"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                    :
                    <ImageBox />
                )
            }
        </Modal>
    );
}