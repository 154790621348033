import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchInterval: 5 * 60 * 1000,
		},
	},
})

export const invalidadeQuery = (key: string[]) => {
	queryClient.invalidateQueries({
		queryKey: key,
		exact: false,
		refetchType: 'all',
	});
}