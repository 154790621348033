import { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import i18n from "util/base/i18n";

import { RuleModalContext } from "../../../../context/RuleModalContext";
import { Condition } from "../../../rules/Condition";
import { createMathOperator } from "../../../operators/MathOperatorFactory";
import { IRuleConditionOperation } from "./IModalFactory";

export function RuleConditionsFormula() {
    const { conditions, handleDragEnd, removeCondition, selectedConditionGrouper } = useContext(RuleModalContext);

    const allConditions = conditions.filter(condition => condition).map((condition, index) => {
        const { operation, selectedLevel, selectedLevelValue, grouperType } = condition;
 
        const mathOperator = createMathOperator(IRuleConditionOperation[operation.content]);

        return {
            conditionIndex: index,
            conditionGrouperType: grouperType,
            render: (
                <Draggable
                    draggableId={index + ''}
                    key={index}
                    index={index}
                >
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Condition
                                key={index}
                                index={index}
                                handleRemove={removeCondition}
                            >
                                <div className="attribute_parameterization-new-attribute-modal-rule-label">
                                    {selectedLevel.label}
                                </div>

                                {mathOperator}

                                <div className="attribute_parameterization-new-attribute-modal-rule-label">
                                    {selectedLevelValue.label}
                                </div>
                            </Condition>
                        </div>
                    )}
                </Draggable>
            )
        }
    });

    return (
        <>
            <h3>{i18n.t<string>("revenue.condition_list")}</h3>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable
                    droppableId="attribute_parameterization-new-rule-modal-conditions-workarea"
                    direction="vertical"
                >
                    {(provided) => (
                        <div
                            className="attribute_parameterization-new-attribute-modal-rules-workarea"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {allConditions.map(condition => {
                                if (condition.conditionGrouperType === selectedConditionGrouper) {
                                    return condition.render;
                                }
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}
