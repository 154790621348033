import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import locale_ptBR from "antd/es/date-picker/locale/pt_BR";
import locale_enUS from "antd/es/date-picker/locale/en_US";
import locale_esES from "antd/es/date-picker/locale/es_ES";

i18n

.use(Backend)

.use(initReactI18next)

.init({
	fallbackLng: false,
	backend: {
		loadPath: "/locales/{{lng}}/{{ns}}.json"
	},
	interpolation: {
		escapeValue: false
	}
});

export default i18n;


export const languages = {
  pt_BR: locale_ptBR,
  en_US: locale_enUS,
  es_ES: locale_esES
}