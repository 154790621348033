import { FlexFilter, FlexFilterOptions, Options } from 'util/types/types';
import moment from 'moment';
import { createContext, useContext, useEffect, useState } from 'react';
import { RequestType } from 'util/service/IServiceCaller';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { FiltersContextProps, PremissesFiltersProviderProps } from './IFilter';
import { useUserContext } from 'context/UserContext';
import { handleErrorRequest } from 'util/functions/handleErrorRequest';

const FiltersContext = createContext<FiltersContextProps>({} as FiltersContextProps)

export function PremissesFiltersProvider({ children }: PremissesFiltersProviderProps) {
	const [flexFieldsFiltersSelected, setFlexFieldsFiltersSelected] = useState(':::::::::::::::::::::::::::::')
	const [fieldHR, setFieldHR] = useState([])
	const [businessUnit, setBusinessUnit] = useState([])
	const [costCategory, setCostCategory] = useState([])
	const [year, setYear] = useState([moment().year()])
	const [flexFieldsFilters, setFlexFieldsFilters] = useState<FlexFilterOptions[]>([])
	const [isFetchingFlexFields, setIsFetchingFlexFields] = useState(true)
	const [isFetchingCostCategoryOptions, setIsFetchingCostCategoryOptions] = useState(true)
	const [costCategoryOptions, setCostCategoryOptions] = useState<Options[]>(null)
	const [businessUnitOptions, setBusinessUnitOptions] = useState<Options[]>([])

	const { userInfo } = useUserContext()

	useEffect(() => {
		if (!userInfo.id) return
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/monolith/business-unit/findAllByOrganizationIdWithSecurity?localeId={locale}&clientId={client}&organizationId={organization}&userId={user}',
		}, (data) => handleUpdateSelectOptions(data, 'businessunit'), handleErrorRequest);
	}, [userInfo])

	useEffect(() => {
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/budget-base/flexfilter?module=1&userId={user}&localeId={locale}&organization={organization}&clientId={client}',
		}, (data) => handleUpdateSelectOptions(data, 'flexField'), handleErrorRequest);

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/human-resources/cost-category?locale={locale}&client={client}&user={user}&organization={organization}&scenario={scenario}',
		}, (data) => {
			setIsFetchingCostCategoryOptions(false)
			handleUpdateSelectOptions(data, 'costCategory')
		}, (err) => {
			setIsFetchingCostCategoryOptions(false)
			handleErrorRequest(err)
		});

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: '/human-resources/premisses/fields?locale={locale}&client={client}&user={user}&organization={organization}',
		}, (data) => handleUpdateSelectOptions(data, 'fieldsHR'), handleErrorRequest);
	}, [])

	function handleUpdateSelectOptions(options, select) {
		const formatedOptions = options.map(option => {
			return {
				value: option.value ? option.value : option.id,
				label: option.name ? option.name : option.description
			}
		})

		switch (select) {
			case 'fieldsHR':
				setFieldHR(formatedOptions)
				break;

			case 'costCategory':
				setCostCategoryOptions(formatedOptions)
				break;

			case 'businessunit':
				setBusinessUnitOptions(formatedOptions);
				break;

			case 'flexField':
				const updatedFFFilters = options.map(flexField => {
					return {
						label: flexField.externalCode + ' - ' + flexField.description,
						...flexField,
						selectedOption: []
					}
				})
				setFlexFieldsFilters(updatedFFFilters)
				setIsFetchingFlexFields(false)
				break;

			default:
				break;
		}
	}

	function generateFlexFieldSelectedString(flexFilter: FlexFilter, value: Options[]) {
		if (value.length === 0) return

		const updatedData = flexFieldsFiltersSelected.split(':')
		const position = flexFilter.fieldCode - 1
		const flexFilterValue = value[0].value === 0 ? '' : value[0].value
		updatedData[position] = flexFilterValue.toString()
		const flexFieldString = updatedData.join(':')
		setFlexFieldsFiltersSelected(flexFieldString)
		return flexFieldString
	}

	return (
		<FiltersContext.Provider
			value={{
				costCategory,
				setCostCategory,
				businessUnit,
				setBusinessUnit,
				fieldHR,
				year,
				setYear,
				businessUnitOptions,
				costCategoryOptions,
				flexFieldsFilters,
				setFlexFieldsFilters,
				flexFieldsFiltersSelected,
				setFlexFieldsFiltersSelected,
				isFetchingFlexFields,
				userInfo,
				generateFlexFieldSelectedString,
				isFetchingCostCategoryOptions
			}}>
			{children}
		</FiltersContext.Provider>
	)
}

export function usePremissesFilters() {
	const context = useContext(FiltersContext)

	return context
}
