import { Key } from "react";

export function validateFormField(value: string, field: string, tableList: any[], modalList: any[], message: string, idOfTableList: Key = null) {
	if (!value) return Promise.resolve();

	for (let index = 0; index < modalList.length; index++) {
		const item: Object = modalList[index];
		if (item[field].toLowerCase() === (value.toLowerCase())) {
			return Promise.reject(new Error(message));
		}
	}

	for (let index = 0; index < tableList.length; index++) {
		const item = tableList[index];
		if (idOfTableList === item.id || idOfTableList === item.key) continue;
		if (item[field].toLowerCase() === value.toLowerCase()) {
			return Promise.reject(new Error(message));
		}
	}

	return Promise.resolve();
}