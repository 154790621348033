import { Icon } from "@iconify/react";
import './ProjectionRangeTagStyles.sass'
import { useEffect, useState } from "react";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import moment from 'moment';
import { handleUTCDate } from 'module/leaseProjection/functions/handleUTCDate';
import i18n from "util/base/i18n";

export function ProjectionRangeTag() {
  const [startDate, setStartDate] = useState("");
  const [projectionDate, setProjectionDate] = useState("");

  useEffect(() => {
    ServiceCaller.doRequest({
      useProxy: false,
      type: RequestType.GET,
      url: "/projectionlease/scenario/projectionRange"
    }, onLoadProjectionRange.bind(this));
  }, []);

  function onLoadProjectionRange(data) {
    setStartDate(moment(handleUTCDate(data.baseDate)).format('MM-YYYY'))
    setProjectionDate(moment(handleUTCDate(data.projectionDate)).format('MM-YYYY'))
  }

  return (
    <div className="projection-date-tag">
      <span>
        <Icon className="gs-date-icon calendar-icon" icon="fa-solid:calendar-alt" />
      </span>
      <span className="date-text">{i18n.t<string>('period')} <span className="date-text-bold">{startDate} - {projectionDate}</span></span>
    </div>
  );
};