import moment from "moment";
import i18n from "util/base/i18n";
import { ColumnGrid } from "../IAccountingLedger";

export function buildNewColumns(accountHierarchySizeColumns: Array<string>, costCenterHierarchySizeColumns: Array<string>, flexFieldValueColumns: Array<string>, allColumns): ColumnGrid[] {
    return allColumns.dynamicColumns.map(column => {
        switch (column.key) {
            case "account_hierarchy":
                return accountHierarchySizeColumns.map((level, index) => ({
                    key: column.key + "-" + index,
                    dataIndex: column.dataIndex + "-" + index,
                    title: level !== null ? level : i18n.t<string>("level_accounting_account") + " " + (index + 1),
                    width: 200,
                    align: "center",
                }));
            case "cost_center_hierarchy":
                return costCenterHierarchySizeColumns.map((level, index) => ({
                    key: column.key + "-" + index,
                    dataIndex: column.dataIndex + "-" + index,
                    title: level !== null ? level : i18n.t<string>("level_accounting_account") + " " + (index + 1),
                    width: 200,
                    align: "center",
                }));
            case "flex_field_value_item":
                return flexFieldValueColumns.map((flexFieldValueName, index) => ({
                    key: column.key + "-" + index,
                    dataIndex: column.dataIndex + "-" + index,
                    title: flexFieldValueName,
                    width: 200,
                    align: "center"
                }));
            default:
                return [];
        }
    }).flat();
}

export function buildColumnDescription(): ColumnGrid[] {
    return [{
        key: "description",
        dataIndex: "description",
        title: i18n.t<string>("grouper"),
        align: "left",
        width: 500,
        render: (text, record) => validateIfIsAMomemntValueAndIfIsFormatPeriod(record)
    }]
}

export function validateIfIsAMomemntValueAndIfIsFormatPeriod(record): string {
    if (record.groupColumn === "RELEASE_DATE") {
        return moment(record.description).format("DD/MM/YY")
    } else {
        return record.description;
    }
}