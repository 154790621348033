import * as React from "react";

import {
	Button,
	Radio
} from "antd";

import i18n from "util/base/i18n";

interface Props {

	value: string

	options?: string[]

	onApply: Function

}

export default function GroupBy(props: Props) {

	const [selected, setSelected]: [string, Function] = React.useState(props.value);

	const defaultOptions: string[] = ["OPERATIONS", "BANK", "CONTRACT_GROUP", "PROJECT", "DIVISION", "MANAGEMENT_GROUP"];

	const onChangeSelection = ({ target: { value } }): void => {
		setSelected(value);
	};

	return <>
		<div className="widget-popover-container">
			<small className="gs-small">{ i18n.t<string>("dashboard.analytics.select_filter_analyze") }</small>

			<Radio.Group onChange={onChangeSelection.bind(this)} defaultValue={selected}>
				{ (props.options || defaultOptions).map(option => <Radio value={option.indexOf(":") > 0 ? option.split(":")[0] : option}>{ i18n.t<string>(option.indexOf(":") > 0 ? option.split(":")[1] : option.toLowerCase()) }</Radio>) }
			</Radio.Group>

			<Button onClick={props.onApply.bind(this, selected)} type="primary">{ i18n.t<string>("filter") }</Button>
		</div>
	</>;

}
