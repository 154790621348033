import { Button, Carousel } from "antd";
import { Filter } from "components/filter/Filter";
import { FilterSkeleton } from "components/skeleton/filterSkeleton/FilterSkeleton";
import { useEffect, useState } from "react";
import "./styles.sass";
import { useOtherEventContext } from "../../context/OtherEventContext";
import { useWindowSize } from "hooks/useWindowSize";
import { carouselDefaultProps } from "components/carousel/ICarousel";
import i18n from "util/base/i18n";
import { Notification } from "components/notification/Notification";
import { Error500 } from "components/error/Error500";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { onChangeFlexFieldFilterMultiple } from "module/budget/pages/flexField/functions/onChangeFlexFieldFilter";
import { OpeningFilterRequest } from "../../IOtherEvents";

export function Filters() {
    const {
        period,
        flexFieldsFilters,
        isFetchingFlexFields,
        openedCalendarPeriod,
        setFlexFieldsFilters,
        userInfo,
        setFlexFieldStringKey,
        isQueryByPackage,
        currenciesOptions,
        error500,
        setIsLoadingCacheData,
        setIsFetchingGridData,
        flexFieldStringKey,
        setOtherEventsCached,
        onLoadOtherEventData,
        setError500,
        currenciesSelected,
        setCurrenciesSelected,
        handleChangePeriod
    } = useOtherEventContext();

    const [filtersPerRow, setFiltersPerRow] = useState(5);
    const windowSize = useWindowSize();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    useEffect(() => {
        const updatedFiltersPerRow = Math.floor((windowSize.width - 258) / 210);
        setFiltersPerRow(updatedFiltersPerRow);
    }, [windowSize]);

	useEffect(() => {
		if (flexFieldsFilters.length > 0 &&
			flexFieldStringKey.length &&
			currenciesSelected?.length &&
			period.every(date => date)
		) {
			setIsButtonEnabled(true);
		} else {
			setIsButtonEnabled(false);
		}

	}, [currenciesSelected, period, flexFieldsFilters, openedCalendarPeriod, isQueryByPackage, flexFieldStringKey]);
    
    useEffect(() => {
        if (userInfo && currenciesOptions) {
            const userCurrency = currenciesOptions.find(item => item.value === userInfo.currencyId);
            if (userCurrency) {
                setCurrenciesSelected([{ value: userInfo.currencyId, label: userCurrency.label }]);
            }
        }
    }, [userInfo, currenciesOptions]);

    function onClickNewOtherEventValues() {
        if (period[0] === null) {
            Notification({
                type: "warning",
                message: i18n.t("fixed_expense_texts.select_a_period_to_continue"),
            });
            return;
        }
        if (isQueryByPackage) {
            handleCreateOtherEventData();
        } else {
            handleCreateOpeningFilterRequest();
        }
        setIsLoadingCacheData(true);
        setIsButtonEnabled(false)
    }

    if (error500) {
        return <Error500 />;
    }

    function handleCreateOpeningFilterRequest() {
        const openingFilterRequest: OpeningFilterRequest = {
            organizationId: userInfo?.selection?.organizationId,
	        scenarioId: userInfo?.selection?.scenarioId,
	        currencyId: currenciesSelected[0].value,
            userId: userInfo?.id,
			flexKey: flexFieldStringKey,
            startDate: period[0].valueOf(),
            finalDate: period[1].valueOf()
        };

        setIsFetchingGridData(true);
        setOtherEventsCached(openingFilterRequest);

        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/planning/other-event",
                params: openingFilterRequest,
			}, onLoadOtherEventData,
            () => setError500(true)
        ); 
    }

    function handleCreateOtherEventData() {
        setIsFetchingGridData(true);

        const openingFilterRequest: OpeningFilterRequest = {
            organizationId: userInfo?.selection?.organizationId,
	        scenarioId: userInfo?.selection?.scenarioId,
	        currencyId: currenciesSelected[0].value,
            userId: userInfo?.id,
			flexKey: flexFieldStringKey,
            startDate: period[0].valueOf(),
            finalDate: period[1].valueOf()
        };

        setOtherEventsCached(openingFilterRequest);
        ServiceCaller.doRequest(
            {
                type: RequestType.POST,
                url: "/planning/other-event",
                params: openingFilterRequest,
			}, onLoadOtherEventData,
            () => setError500(true)
        );
    }
    return (
        <>
            <div className="df-filters-container">
                {isFetchingFlexFields ? (
                    <FilterSkeleton direction="row" active amount={4} gap={10} cardClassName="filter-card-skeleton" />
                ) : (
                    <>
                        <Filter
                            id="period-filter"
                            value={period}
                            setValue={handleChangePeriod}
                            title={i18n.t("period")}
                            type="datepicker"
                            link="/"
                            data={[]}
                            datepicker={{
                                format: "MM/YYYY",
                                picker: "month",
                                period: openedCalendarPeriod,
                                rangePicker: true,
                                allowClear: false,
                            }}
                            buttonStyles={{ cursor: "default" }}
                        />
                        <Carousel {...carouselDefaultProps} slidesPerRow={filtersPerRow}>
								{flexFieldsFilters.map((ffFilter, index) => {
									const isLastFlexField: boolean = index === flexFieldsFilters.length - 1;
									const flexFilterType: string = isLastFlexField ? "checkbox" : "radio";
									return (
										<Filter
											key={ffFilter.value}
											id="flex-field-filter"
											title={ffFilter.label}
											type={flexFilterType as "checkbox" | "radio" | "datepicker"}
											value={ffFilter.selectedOption}
											setValue={async (value: any[]) => {
												if (!isLastFlexField && (value.length === 0 || ffFilter.selectedOption[0]?.value === value[0]?.value)) {
													return;
												} else if (isLastFlexField && !value.length) {
													setFlexFieldStringKey([]);
													return;
												}

												const newFlexKey: string[] = await onChangeFlexFieldFilterMultiple(
													value,
													ffFilter.value,
													flexFieldsFilters,
													setFlexFieldsFilters,
													userInfo.selection.businessUnitId ?? 0,
													0,
													0,
													userInfo.selection.organizationId ?? 0,
													userInfo.groupIds
												);

												setFlexFieldStringKey(newFlexKey);
											}}
											data={ffFilter.children}
											isFetching={ffFilter.isFetching ?? false}
											hasSearch
											hasSelectNone={!isLastFlexField}
										/>
									);
                            })}
                            <Filter
									id="currencies-filter"
									title={i18n.t("currency")}
									type="radio"
									searchPlaceholder={i18n.t("fixed_expense_texts.search_by_currency")}
									value={currenciesSelected}
									setValue={(value) => {
										setCurrenciesSelected(value);
									}}
									data={currenciesOptions}
                            />
                        </Carousel>
                        <div className="gs-flex" >
                            <Button
                                className="gs-main-button openning-budget-button"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingRight: 15,
                                    gap: 5,
                                    width: 80,
                                    marginRight:15
                                }}
                                title={i18n.t("apply")}
                                onClick={onClickNewOtherEventValues}
                                disabled={!isButtonEnabled}>
                                {i18n.t("apply")}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
