import { Key, useState } from 'react';
import i18n from 'util/base/i18n';
import { Icon } from '@iconify/react';
import { Button, Input, Spin, Tooltip, Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { usePermissionContext } from 'context/PermissionContext';
import { DataTypeTable, LevelItem } from '../IGroupings';

const { Search } = Input;

interface Props {
	treeData: any[];
	checkedNodeKeys: number[];
	setCheckedNodeKeys: (key: number[]) => void;
	addNewSubLevel: (parentId: number) => void;
	tableData: DataTypeTable[];
	isTreeDisabled: boolean;
	expandedKeys: number[]
	setExpandedKeys(n: number[]): void;
	setIsSelectPackageLevel: React.Dispatch<React.SetStateAction<boolean>>

}

export default function SiderContent(props: Props) {
	const { functionalityPermissions } = usePermissionContext()
	const [search, setSearch] = useState('');

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearch(value);
	};

	function buildTreeData(data: LevelItem[], search: string): LevelItem[] {
		if (search.length === 0) return data;
		return [
			...data.map((line) => {
				
				if (line.children) {
					line.children = buildTreeData(line.children, search);

					if (line.children.filter((child) => child).length === 0) {
						if (!line.title.toLowerCase().includes(search)) {
							line = undefined;
						}
					}
				} else {
					if (!line.title.toLowerCase().includes(search)) {
						line = undefined;
					}
				}

				return line;
			}).filter((child) => child)
		]
	}

	const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
		let parentKey: React.Key;

		for (let i = 0; i < tree.length; i++) {
			const node = tree[i];
			if (node.children) {
				if (node.children.some(item => item.key === key)) {
					parentKey = node.key;
				} else if (getParentKey(key, node.children)) {
					parentKey = getParentKey(key, node.children);
				}
			}
		}
		return parentKey!;
	};

	function onCheck(checked: {
		checked: Key[];
		halfChecked: Key[];
	}, { node }: any) {
		props.setIsSelectPackageLevel(node.isSpendingPackageLevel);

		props.setCheckedNodeKeys(checked.checked as number[])
	}

	function chooseIconExpanded(props: { expanded: boolean }) {
		return props.expanded ? <Icon icon="akar-icons:minus" /> : <Icon icon="akar-icons:plus" />;
	}

	function onClickAddLevel(id: number) {
		props.addNewSubLevel(id);
	}

	function addButtonLines(line) {
		return (
			<span className='tree-title-content'>
				<p className={line.isSpendingPackageLevel ? 'package-level-title' : ''}>
					{line.title}
				</p>
				<div>
					{functionalityPermissions.new &&
					<Button onClick={() => onClickAddLevel(line.key)} icon={<Icon id={line.key} icon="akar-icons:plus" />} />}
					{
						line.showIcon &&
						< Tooltip title={i18n.t("already_linked")}>
							<Icon icon="akar-icons:link-chain" />
						</Tooltip>
					}
				</div>
			</span >
		)
	}

	function onExpand(expandedKeys: number[], obj: { expanded: boolean }) {
		props.setExpandedKeys(expandedKeys)
	}

	return (
		<div className='sider-content'>
			<Search style={{ marginBottom: 8 }} placeholder={i18n.t("search")} onChange={onChange} />
			<Spin spinning={props.isTreeDisabled}>
				{props.treeData.length > 0 ?
					<Tree
						checkStrictly
						checkedKeys={props.checkedNodeKeys}
						switcherIcon={chooseIconExpanded}
						treeData={buildTreeData(JSON.parse(JSON.stringify(props.treeData)), search.toLowerCase())}
						showLine={{ showLeafIcon: false }}
						checkable
						onCheck={onCheck}
						titleRender={addButtonLines}
						disabled={props.isTreeDisabled}
						expandedKeys={props.expandedKeys}
						onExpand={onExpand}
						selectable={false}
					/>
					:
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', margin: '40px 0' }}>
						<Icon icon="clarity:tree-view-line" />
						{i18n.t("groupings_texts.no_registered_level")}
					</div>
				}
			</Spin>
		</div>
	);
};