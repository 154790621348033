import { Modal, notification } from "antd";
import { useContext, useEffect, useState } from "react";
import i18n from "util/base/i18n";
import { NotificationPlacement } from "antd/lib/notification";

import { AttributeMathOperations, IConditionGrouperType } from "../../../IAttributeParameterization";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { ErrorRequest } from "util/types/types";
import { AttributeParameterizationContext } from "../../../context/AttributeParameterizationContext";
import { useUserContext } from "context/UserContext";
import { RuleModalContext } from "../../../context/RuleModalContext";

import { RuleInfo } from "./components/RuleInfo";
import { RuleConditionsDivider } from "./components/RuleConditionsDivider";
import { RuleConditionsFormula } from "./components/RuleConditionsFormula";
import { RuleConditionsForm } from "./components/RuleConditionsForm";
import {
	ModalFlexFieldsModule,
	RuleModalProps,
	ModalType,
	IConditionToSendToBackend,
	IRuleToSendToBackend,
	IRuleLevelType,
} from "./components/IModalFactory";
import { RuleConditionsTabs } from "./components/RuleConditionsTabs";

export default function NewRuleModalFactory({ modalTitle, modalType, flexFieldsModule }: RuleModalProps) {
	const {
		isNewRuleModalOpen,
		isNewRuleModalDFOpen,
		isNewRuleModalFixedMarketingCostOpen,
		closeNewRuleModalFixedMarketingCost,
		closeNewRuleModal,
		closeNewRuleModalDF,
		clearRules,
		selectedAttribute,
		reloadTable,
	} = useContext(AttributeParameterizationContext);

	const {
		description,
		setDescription,
		externalCode,
		setExternalCode,
		order,
		selectedConditionGrouper,
		handleSelectedConditionGrouper,
		conditions,
		formula,
		setFormula,
		setConditions,
		apportionmentType,
	} = useContext(RuleModalContext);
	const [okButtonText, setOkButtonText] = useState("");

	const { userInfo } = useUserContext();

	function createRule() {
		if (flexFieldsModule === ModalFlexFieldsModule.REVENUE) {
			const preparedConditions: IConditionToSendToBackend[] = conditions.map((condition, index) => {
				const { grouperType, selectedLevel, operation, selectedLevelValue } = condition;

				return {
					moduleType: grouperType.toString(),
					orderCondition: index + 1,
					partsConditions: [
						{
							flexFieldId: selectedLevel.content.id,
							operationOrder: 1,
						},
						{
							operationRuleType: operation.content,
							operationOrder: 2,
						},
						{
							flexFieldValueId: selectedLevelValue.content.id,
							operationOrder: 3,
						},
					],
				};
			});

			const newRule: IRuleToSendToBackend = {
				scenarioId: userInfo.selection.scenarioId,
				externalCode: externalCode,
				description: description,
				ruleOrder: order,
				attributeId: selectedAttribute.id,
				conditions: preparedConditions,
			};

			if (modalType === ModalType.STANDARD_WITH_FORMULA) {
				const preparedFormula = formula.map((item, index) => {
					if (item.type === "attribute") {
						return {
							orderPosition: index + 1,
							attributeId: item.content["id"],
						};
					}

					return {
						orderPosition: index + 1,
						type: AttributeMathOperations[item.content],
					};
				});

				newRule.formulas = preparedFormula;
			}

			ServiceCaller.doRequest(
				{
					type: RequestType.POST,
					url: `/revenue/grouper/attribute/rule`,
					params: newRule,
				},
				(_) => {
					handleSelectedConditionGrouper(IConditionGrouperType.REVENUE);
					createdRuleNotification("topRight");
					reloadTable();

					if (modalType === ModalType.STANDARD) {
						closeNewRuleModal();
					}

					if (modalType === ModalType.STANDARD_WITH_FORMULA) {
						closeNewRuleModalFixedMarketingCost();
					}
				},
				(err: ErrorRequest) => {
					handleErrorRequest(err);
				}
			);

			setConditions([]);
			setExternalCode("");
			setDescription("");
		}

		if (flexFieldsModule === ModalFlexFieldsModule.REVENUE_AND_FIXED_EXPENSE) {
			const revenueConditions = conditions
				.filter((condition) => condition.grouperType === IConditionGrouperType.REVENUE)
				.map((condition, index) => {
					const { grouperType, selectedLevel, operation, selectedLevelValue } = condition;

					return {
						moduleType: grouperType.toString(),
						orderCondition: index + 1,
						partsConditions: [
							{
								flexFieldId: selectedLevel.content.id,
								operationOrder: 1,
							},
							{
								operationRuleType: operation.content,
								operationOrder: 2,
							},
							{
								flexFieldValueId: selectedLevelValue.content.id,
								operationOrder: 3,
							},
						],
					};
				});

			const fixedExpenseConditions = conditions
				.filter((condition) => condition.grouperType === IConditionGrouperType.FIXED_EXPENSE)
				.map((condition, index) => {
					const { grouperType, selectedLevel, operation, selectedLevelValue } = condition;

					switch (selectedLevel.type) {
						case IRuleLevelType.FLEX_FIELD:
							return {
								moduleType: grouperType.toString(),
								orderCondition: index + 1,
								partsConditions: [
									{
										flexFieldId: selectedLevel.content.id,
										operationOrder: 1,
									},
									{
										operationRuleType: operation.content,
										operationOrder: 2,
									},
									{
										flexFieldValueId: selectedLevelValue.content.id,
										operationOrder: 3,
									},
								],
							};
						case IRuleLevelType.ACCOUNTING_ACCOUNT:
							return {
								moduleType: grouperType.toString(),
								orderCondition: index + 1,
								partsConditions: [
									{
										operationRuleType: operation.content,
										operationOrder: 2,
									},
									{
										accountingAccountId: selectedLevelValue.content.id,
										operationOrder: 3,
									},
								],
							};
						case IRuleLevelType.COST_CENTER:
							return {
								moduleType: grouperType.toString(),
								orderCondition: index + 1,
								partsConditions: [
									{
										operationRuleType: operation.content,
										operationOrder: 2,
									},
									{
										costCenterId: selectedLevelValue.content.id,
										operationOrder: 3,
									},
								],
							};
						case IRuleLevelType.ACCOUNTING_ACCOUNT_GROUP:
							return {
								moduleType: grouperType.toString(),
								orderCondition: index + 1,
								partsConditions: [
									{
										operationRuleType: operation.content,
										operationOrder: 2,
									},
									{
										accountHierarchyId: selectedLevelValue.content.id,
										operationOrder: 3,
									},
								],
							};
						case IRuleLevelType.BUSINESS_UNIT:
							return {
								moduleType: grouperType.toString(),
								orderCondition: index + 1,
								partsConditions: [
									{
										operationRuleType: operation.content,
										operationOrder: 2,
									},
									{
										businnesUnitId: selectedLevelValue.content.id,
										operationOrder: 3,
									},
								],
							};
						default:
							throw new Error("Invalid condition level type");
					}
				});

			const preparedFormula = formula.map((item, index) => {
				if (item.type === "attribute") {
					return {
						orderPosition: index + 1,
						attributeId: item.content["id"],
					};
				}

				return {
					orderPosition: index + 1,
					type: AttributeMathOperations[item.content],
				};
			});

			const newRule: any = {
				scenarioId: userInfo.selection.scenarioId,
				externalCode,
				description,
				ruleOrder: order,
				attributeId: selectedAttribute.id,
				conditions: [...revenueConditions, ...fixedExpenseConditions],
				formulas: preparedFormula,
			};

			if (modalType === ModalType.FIXED_EXPENSE) {
				newRule.apportionmentType = apportionmentType;
			}

			ServiceCaller.doRequest(
				{
					type: RequestType.POST,
					url: `/revenue/grouper/attribute/rule`,
					params: newRule,
				},
				(response) => {
					createdRuleNotification("topRight");
					reloadTable();
					closeNewRuleModalDF();
					handleSelectedConditionGrouper(IConditionGrouperType.REVENUE);
				},
				(err: ErrorRequest) => {
					handleErrorRequest(err);
				}
			);
		}
	}

	function handleOk() {
		if (modalType === ModalType.FIXED_EXPENSE) {
			const hasRevenueCondition = conditions.some(
				(condition) => condition.grouperType === IConditionGrouperType.REVENUE
			);

			if (hasRevenueCondition && selectedConditionGrouper === IConditionGrouperType.REVENUE) {
				handleSelectedConditionGrouper(IConditionGrouperType.FIXED_EXPENSE);
				return;
			}

			const hasExpensesCondition = conditions.some(
				(condition) => condition.grouperType === IConditionGrouperType.FIXED_EXPENSE
			);

			if (hasRevenueCondition && hasExpensesCondition) {
				createRule();
				return;
			}

			handleSelectedConditionGrouper(IConditionGrouperType.REVENUE);
			return;
		}

		createRule();
	}

	function handleNewRuleModalCancel() {
		closeNewRuleModal();
		closeNewRuleModalDF();
		closeNewRuleModalFixedMarketingCost();

		handleSelectedConditionGrouper(IConditionGrouperType.REVENUE);
		clearRules();
	}

	function createdRuleNotification(placement: NotificationPlacement) {
		notification.success({
			message: i18n.t<string>("revenue.rule_created_successfully"),
			placement,
			duration: 3,
		});
	}

	useEffect(() => {
		setExternalCode("");
		setDescription("");
		setConditions([]);
		setFormula([]);
	}, []);

	useEffect(() => {
		if (modalType !== ModalType.FIXED_EXPENSE) {
			setOkButtonText(i18n.t<string>("save"));
		} else {
			if (selectedConditionGrouper === IConditionGrouperType.FIXED_EXPENSE) {
				setOkButtonText(i18n.t<string>("save"));
			} else {
				setOkButtonText(i18n.t<string>("next"));
			}
		}
	}, [selectedConditionGrouper])

	const isFormFilled = externalCode && description && order;
	const isFormulaFilled =
		modalType === ModalType.FIXED_EXPENSE || modalType === ModalType.STANDARD_WITH_FORMULA
			? formula.length > 0
			: true;

	const hasRevenueCondition = conditions.some((condition) => condition.grouperType === IConditionGrouperType.REVENUE);
	const hasExpensesCondition = conditions.some(
		(condition) => condition.grouperType === IConditionGrouperType.FIXED_EXPENSE
	);

	const hasConditionsFilled =
		selectedConditionGrouper === IConditionGrouperType.REVENUE
			? hasRevenueCondition
			: hasRevenueCondition && hasExpensesCondition;
	const isConditionsFilled = modalType === ModalType.FIXED_EXPENSE ? hasConditionsFilled : conditions.length > 0;

	const isOkButtonDisabled = !(isFormFilled && isConditionsFilled && isFormulaFilled);

	let isModalVisible = false;
	let modalHasAttributeFormula = false;

	if (modalType === ModalType.STANDARD) {
		isModalVisible = isNewRuleModalOpen;
	} else if (modalType === ModalType.FIXED_EXPENSE) {
		isModalVisible = isNewRuleModalDFOpen;
		modalHasAttributeFormula = true;
	} else if (modalType === ModalType.STANDARD_WITH_FORMULA) {
		isModalVisible = isNewRuleModalFixedMarketingCostOpen;
		modalHasAttributeFormula = true;
	}

	return (
		<Modal
			title={modalTitle}
			visible={isModalVisible}
			onOk={handleOk}
			onCancel={handleNewRuleModalCancel}
			width={"80%"}
			wrapClassName="attribute_parameterization-new-rule-modal"
			okText={okButtonText}
			cancelText={i18n.t<string>("close")}
			centered
			destroyOnClose
			okButtonProps={{
				style: {
					width: "78px",
				},
				disabled: isOkButtonDisabled,
			}}
		>
			<RuleInfo
				hasAttributeFormula={modalHasAttributeFormula}
				hasOccurrence={modalType === ModalType.FIXED_EXPENSE}
			/>

			{modalType === ModalType.FIXED_EXPENSE && <RuleConditionsTabs />}

			{(modalType === ModalType.STANDARD || modalType === ModalType.STANDARD_WITH_FORMULA) && (
				<RuleConditionsDivider />
			)}

			<div id="attribute_parameterization-new-rules-modal-rules-section">
				<RuleConditionsForm />
				<RuleConditionsFormula />
			</div>
		</Modal>
	);
}
