import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { handleErrorRequest } from 'util/functions/handleErrorRequest';
import { IRequest } from 'util/service/IServiceCaller';
import { ServiceCaller } from 'util/service/ServiceCaller';
import { ErrorRequest } from 'util/types/types';

function useCachedQuery<T>(queryKey: string[], requestProps: IRequest, options?: UseQueryOptions<T>, fn?: Function) {
	return useQuery<T>(
		queryKey,
		async () => await fetch<T>(requestProps, fn),
		options
	);
}

async function fetch<T>(requestProps: IRequest, fn?): Promise<T> {
	const response = await ServiceCaller.doAsyncRequest<T>(requestProps)	
	if (response.success) {
		if (fn) {
			return fn(response.data);
		} 
		return response.data;
	} else {
		handleErrorRequest(response.data as unknown as ErrorRequest)
		return [] as unknown as T;
	}
}
export default useCachedQuery;