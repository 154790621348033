import { Spin, Form, Row, Col, Input, DatePicker, Button, Select, Checkbox } from "antd";
import { FormInstance } from "antd/es/form";
import moment from "moment";
import i18n from "util/base/i18n";
import { ModuleType, Options } from "util/types/types";
import { AnnualDatesFormData, OrganizationBudgetDates, getOrderByModuleType } from "../../IControlPanel";
import { useUserContext } from "context/UserContext";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { Notification } from "components/notification/Notification";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { cloneDeep } from "lodash";
import { invalidadeQuery, queryClient } from "util/queryClient";

const { RangePicker } = DatePicker

interface INewAnnualPanel {
    form: FormInstance,
    selectedNewFiscalYear: moment.Moment
    setShowNewAnnualPanel(b: boolean): void;
    setSelectedNewFiscalYear(data: moment.Moment): void;
    unitConversionOptions: Options[];
    segmentTypeOptions: Options[];
    moduleType: ModuleType;
    organization: OrganizationBudgetDates;
    organizationList: OrganizationBudgetDates[];
}

export function NewAnnualPanel({
    form,
    selectedNewFiscalYear,
    setShowNewAnnualPanel,
    setSelectedNewFiscalYear,
    unitConversionOptions,
    segmentTypeOptions,
    moduleType,
    organization,
    organizationList
}: INewAnnualPanel) {
    const { userInfo } = useUserContext()

    function onCancelNewFiscalYear() {
        setSelectedNewFiscalYear(null)
        setShowNewAnnualPanel(false)
        form.resetFields()
    }

    async function onSaveNewFiscalYear(data: AnnualDatesFormData) {
        form.resetFields()
        setShowNewAnnualPanel(false)
        const formValues = {
            [`${moduleType}RealizedPeriod`]: data.realizedPeriod ? data.realizedPeriod.date(15).valueOf() : null,
            [`${moduleType}FrozenPeriod`]: data.frozenPeriod ? data.frozenPeriod.date(15).valueOf() : null,
            [`${moduleType}OpeningStart`]: data.openPeriod[0].valueOf(),
            [`${moduleType}OpeningEnd`]: data.openPeriod[1].valueOf(),
            [`${moduleType}BudgetStart`]: data.budgetPeriod[0].date(15).valueOf(),
            [`${moduleType}BudgetEnd`]: data.budgetPeriod[1].date(15).valueOf(),
            year: data.fiscalYear,
            unitConversionId: data.unitConversionId,
            hasAnnualBinding: data.hasAnnualBinding,
            segmentType: data.segmentType,
            revisions: [],
            revenueBasePeriod: data.revenueBasePeriod
        } as any
        const dataToSave = {
            organizationId: organization.organizationId,
            scenario: userInfo.selection.scenarioId,
            year: data.fiscalYear,
            hasAnnualBinding: data.hasAnnualBinding,
            moduleType: getOrderByModuleType[moduleType],
            [moduleType]: formValues
        }

        await ServiceCaller.doAsyncRequest({
            type: RequestType.POST,
            url: `budget-base/control-panel?client={client}&user={user}&locale={locale}&scenario={scenario}`,
            params: dataToSave
        }).then(() => {
            Notification({
                type: 'success',
                message: i18n.t("successfully_saved")
            })
            invalidadeQuery(['budget-period-dates', moduleType])
            const updatedOrgList = cloneDeep(organizationList)
            const index = updatedOrgList.findIndex(org => org.organizationId === organization.organizationId)
            const org = updatedOrgList[index]

            if (org[moduleType]) {
                org[moduleType].push(formValues)
            } else {
                org[moduleType] = [formValues]
            }

            updatedOrgList.splice(index, 1, org)
            queryClient.setQueryData(['organizationList'], updatedOrgList)
            setSelectedNewFiscalYear(null)

        }).catch((err) => {
            handleErrorRequest(err.data)
            setSelectedNewFiscalYear(null)
        })
    }

    function disabledBudgetDate(current) {
        return current < selectedNewFiscalYear.startOf('year') || current > selectedNewFiscalYear.endOf('year')
    }

    return (
        <div className="new-panel-year-box">
            <h3>{selectedNewFiscalYear?.year()}</h3>
            <Spin spinning={false}>
                <Form
                    form={form}
                    onFinish={onSaveNewFiscalYear}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Row align="middle" gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                label={i18n.t('budget_year')}
                                name="fiscalYear"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={i18n.t("budget_period")}
                                name="budgetPeriod"
                                rules={[{ required: true, message: i18n.t("required_field") }]}
                            >
                                <RangePicker
                                    format='MM/YYYY'
                                    className="gs-date-picker"
                                    picker="month"
                                    disabledDate={disabledBudgetDate}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={i18n.t("typing_period")}
                                name="openPeriod"
                                rules={[{ required: true, message: i18n.t("required_field") }]}
                            >
                                <RangePicker
                                    format='DD/MM/YYYY'
                                    className="gs-date-picker"
                                    picker="date"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                label={i18n.t("accomplished_until")}
                                name="realizedPeriod"
                            >
                                <DatePicker
                                    format='MM/YYYY'
                                    className="gs-date-picker form-org-date-picker"
                                    picker="month"
                                    disabledDate={disabledBudgetDate}
                                />
                            </Form.Item>
						</Col>
						{ moduleType !== ModuleType.PLANNING &&
							<Col span={8}>
								<Form.Item
									label={i18n.t("frozen_until")}
									name="frozenPeriod"
									>
									<DatePicker
										format='MM/YYYY'
										className="gs-date-picker form-org-date-picker"
										picker="month"
										disabledDate={disabledBudgetDate}
										/>
								</Form.Item>
                       		 </Col>
						}
                        {moduleType === ModuleType.EXPENSERESOURCES &&
                            <Col span={8}>
                                <Form.Item
                                    name="hasAnnualBinding"
                                    valuePropName="checked"
                                    style={{ margin: 0 }}
                                >
                                    <Checkbox>
                                        {i18n.t('automatic_balance_transfer')}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        }
                        {moduleType === ModuleType.REVENUE &&
                            <>
                                <Col span={8}>
                                    <Form.Item
                                        label={i18n.t("budget_projection_texts.measuring_unit")}
                                        name="unitConversionId"
                                        rules={[{ required: true, message: i18n.t("required_field") }]}
                                    >
                                        <Select
                                            options={unitConversionOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={i18n.t("segment")}
                                        name="segmentType"
                                        rules={[{ required: true, message: i18n.t("required_field") }]}
                                    >
                                        <Select
                                            options={segmentTypeOptions}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        }

                        <Col
                            className="gs-flex align-center jc-end"
                            span={8}
                            offset={moduleType === ModuleType.REVENUE ? 8 : 16}
                        >
                            <Button
                                style={{ width: 108 }}
                                type="link"
                                onClick={onCancelNewFiscalYear}
                            >
                                {i18n.t<string>('cancel')}
                            </Button>
                            <Button
                                style={{ width: 108 }}
                                htmlType="submit"
                                className="gs-main-button"
                            >
                                {i18n.t<string>('save')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </div>
    )
}