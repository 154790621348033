import { Icon } from "@iconify/react";
import { ICaptchType, ILoadCaptchaEngingeProps } from '../ICaptcha';

let captchaValue: string = '';

export const loadCaptchaEngine = ({
	numberOfCharacters = 6,
	captchaType
}: ILoadCaptchaEngingeProps = {}) => {

	const generateCaptchaText = (): string => {
		let retVal: string = "";
		let charset: string = capthaCharset[captchaType];

		for (let i = 0, n = charset.length; i < numberOfCharacters; ++i) {
			retVal += charset.charAt(Math.floor(Math.random() * n));
		}

		return retVal;
	}

	const buildCaptchaCanva = (element: HTMLCanvasElement): void => {
		let text = captcha;
		let lineheight = 30;

		let lines = text.split('\n');
		ctx.canvas.width = numberOfCharacters * 25;
		ctx.canvas.height = (lines.length * lineheight);
		ctx.fillStyle = "#f0f0f0";
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		ctx.textBaseline = "middle";
		ctx.font = "italic 20px Arial";
		ctx.fillStyle = "#545454";
		let startY = (canvas.height - (lines.length * lineheight)) / 2 + lineheight / 2;

		function drawCaptchaText(ctx, text, x, y) {
			for (let i = 0; i < text.length; i++) {
				ctx.fillText(text[i], x, y);
				x += ctx.measureText(text[i]).width + Math.random() * 10;
			}
		}

		lines.forEach((line, index) => {
			let textWidth = Array.from(line).reduce((acc, char) => acc + ctx.measureText(char).width + Math.random() * 10, 0);
			let x = (canvas.width - textWidth) / 2;
			let y = startY + index * lineheight;
			drawCaptchaText(ctx, line, x, y);
		});
	}

	const capthaCharset: Record<ICaptchType, string> = {
		lower: "abcdefghijklmnopqrstuvwxyz0123456789",
		number: "0123456789",
		special: "~`!@#$%^&*()_+-=[]{}\\|:'<>,.?/",
		upper: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
		default: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
	}

	let captcha = generateCaptchaText();

	captchaValue = captcha;

	let canvas: HTMLCanvasElement = document.getElementById('canv') as HTMLCanvasElement,
		ctx = canvas.getContext('2d');

	buildCaptchaCanva(canvas);
};

export const validateCaptcha = (
	userValue: string,
	numberOfCharacters: number,
	captchaType: ICaptchType,
	reload: boolean = true
): boolean => {
	const isValid: boolean = userValue === captchaValue;
	if (!isValid && reload) {
		loadCaptchaEngine({ numberOfCharacters, captchaType });
	}

	return isValid;
};

export function LoadCanvasTemplate() {
	return (
		<div className="canvas-content">
			<canvas id="canv" />
		</div>
	)
};