import { ApportionmentResponse, TableData } from "../IApportionment"

function generateFlexFieldString(tableData: TableData, flexFieldOptions): string {
    let flexFieldString = Array(30)

    if (tableData.flexFields.length > 0) {
        tableData.flexFields.forEach(flex => {
            const flexField = flexFieldOptions.find(f => f.value === flex.flexFieldId)
            const position = flexField.fieldCode - 1
            flexFieldString[position] = flex.value
        })
    }
    return flexFieldString.join(':')
}

export function createPostPayload(
    originTableData,
    destinationTableData,
    apportionmentType,
    userInfo,
    flexFieldsOriginOptions,
    flexFieldsDestinationOptions,
    apportionmentPeriod?
): ApportionmentResponse {
    const payload = {
        id: originTableData[0].id,
        year: originTableData[0].year,
        scenarioId: userInfo.selection.scenarioId,
        organizationId: userInfo.selection.organizationId,
        apportionmentType,
        businessUnitId: originTableData[0].businessUnit.value,
        costCenterId: originTableData[0].costCenter.value,
        accountingAccountId: originTableData[0].account.value,
        flexFieldFilter: generateFlexFieldString(originTableData[0], flexFieldsOriginOptions),
        destinationApportioments: destinationTableData.map(item => {
            const monthlyValues = []
            Object.keys(item).forEach(key => {
                if (/^\d/.test(key)) {
                    monthlyValues.push({
                        monthYear: key,
                        value: item[key].value,
                        id: item[key].id
                    })
                }
            })
            if (monthlyValues.length === 0) {
                apportionmentPeriod.forEach(month => {
                    monthlyValues.push({
                        monthYear: month,
                    })
                })
            }
            const itemToSave = {
                id: item.id,
                businessUnitId: item.businessUnit.value,
                costCenterId: item.costCenter.value,
                accountingAccountId: item.account.value,
                flexFieldFilter: generateFlexFieldString(item, flexFieldsDestinationOptions),
                scenarioId: userInfo.selection.scenarioId,
                organizationId: userInfo.selection.organizationId,
                monthlyValues,
            }
            return itemToSave
        })
    }
    return payload as ApportionmentResponse
}