import './style.sass';
import { Icon } from "@iconify/react";
import { Input, Button, Col, Row, Table } from "antd";
import { Key, useEffect, useState } from 'react';
import i18n from 'util/base/i18n';

interface TableData {
    key: number | string
    value: number;
    name: string;
}

interface TransferProps {
    leftTableName: string;
    rightTableName: string;
    tableData: TableData[];
    selectedData: TableData[];
    setSelectedData: React.Dispatch<React.SetStateAction<any[]>>;
    setShowTransfer: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Transfer({ 
    leftTableName,
    rightTableName,
    tableData,
    selectedData,
    setSelectedData,
    setShowTransfer
}: TransferProps) {

    const [selectedRowKeysLeft, setSelectedRowKeysLeft] = useState([]);
    const [selectedRowKeysRight, setSelectedRowKeysRight] = useState([]);
    const [tableListData, setTableListData] = useState([]);
    const [selectedListData, setSelectedListData] = useState([]);
    const [searchLeft, setSearchLeft] = useState();
    const [searchRight, setSearchRight] = useState();

    useEffect(() => {
        setTableListData(tableData.filter(data => !selectedData.includes(data)));
        setSelectedListData(selectedData);
    }, []);

    const leftColumns = [
        { 
            title: leftTableName,
            dataIndex: "name"
        }
    ]

    const rightColumns = [
        { 
            title: rightTableName,
            dataIndex: "name"
        }
    ]

    const filteredTableData = tableListData.filter(data => searchLeft ? data.name?.includes(searchLeft) : true);
    const filteredSelectedData = selectedData.filter(data => searchRight ? data.name?.includes(searchRight) : true);

    const rowSelectionLeft = { selectedRowKeys: selectedRowKeysLeft, onChange: onChangeLeft, getCheckboxProps: (record) => ({
        disabled: record.key <= 0
    }) };

    const rowSelectionRight = { selectedRowKeys: selectedRowKeysRight, onChange: onChangeRight, getCheckboxProps: (record) => ({
        disabled: record.key <= 0
    }) };

    function onChangeLeft(selectedRowKeys: Key[]) {
        setSelectedRowKeysLeft(selectedRowKeys);
    }

    function onChangeRight(selectedRowKeys: Key[]) {
        setSelectedRowKeysRight(selectedRowKeys);
    }

    function onSearchLeft(e) {
        setSearchLeft(e.target.value);
    }

    function onSearchRight(e) {
        setSearchRight(e.target.value);
    }

    function handleMoveToRight() {
        const valuesToSelect = tableListData.filter(item => !selectedRowKeysLeft.includes(item.key));
        const selectedItems = tableListData.filter(item => selectedRowKeysLeft.includes(item.key));
        selectedItems.push(...selectedData);

        setSelectedData(selectedItems);
        setTableListData(valuesToSelect);
        setSelectedRowKeysLeft([]);
        setSelectedRowKeysRight([]);
    }

    function handleMoveToLeft() {
        const valuesToSelect = selectedData.filter(item => selectedRowKeysRight.includes(item.key));
        const selectedItems = selectedData.filter(item => !selectedRowKeysRight.includes(item.key));
        valuesToSelect.push(...tableListData);

        setSelectedData(selectedItems);
        setTableListData(valuesToSelect);
        setSelectedRowKeysRight([]);
        setSelectedRowKeysLeft([]);
    }

    function handleCancel() {
        setShowTransfer(false);
        setSelectedRowKeysLeft([]);
        setSelectedRowKeysRight([]);
        if(selectedData !== selectedListData){
            setSelectedData([]);
        }
    }

    function handleSave() {
        setShowTransfer(false);
        setSelectedRowKeysLeft([]);
        setSelectedRowKeysRight([]);
    }

    return (
        <div className="transfer-container">
            <Row className="transfer-content" gutter={25}>
                <Col span={11}>
                    <div className={"transfer-title"}>
                        <h3>Centros de Custo</h3>
                    </div>
                    <Table 
                        className={"filter-table"}
                        columns={leftColumns}
                        dataSource={filteredTableData}
                        pagination={{ showSizeChanger: false, hideOnSinglePage: false, defaultPageSize: 5 }}
                        rowSelection={rowSelectionLeft}
                        showHeader={false}
                        title={() => {
                            return (
                                <Input.Search  
                                    placeholder={i18n.t("search")} 
                                    allowClear 
                                    onChange={onSearchLeft} 
                                />
                            )
                        }}
                        bordered
                    />
                </Col>
                <Col span={2} className={"transfer-buttons"}>
                    <Button onClick={handleMoveToRight} className={"transfer-button"}> 
                        <Icon icon="material-symbols:keyboard-arrow-right"  style={{color: "#424242"}} /> 
                    </Button>
                    <Button onClick={handleMoveToLeft} className={"transfer-button"}> 
                        <Icon icon="material-symbols:keyboard-arrow-left"  style={{color: "#424242"}} /> 
                    </Button>
                </Col>
                <Col span={11}>
                    <div className={"transfer-title"}>
                        <h3>Centros de Custo Vinculados</h3>
                    </div>
                    <Table 
                        className={"filter-table"}
                        columns={rightColumns}
                        dataSource={filteredSelectedData}
                        pagination={{ showSizeChanger: false, hideOnSinglePage: false, defaultPageSize: 5 }}
                        rowSelection={rowSelectionRight}
                        showHeader={false}
                        title={() => {
                            return (
                                <Input.Search  
                                    placeholder={i18n.t("search")} 
                                    allowClear 
                                    onChange={onSearchRight} 
                                />
                            )
                        }}
                        bordered
                    />
                </Col>
            </Row>
            <Row className={"transfer-footer"}>
                <Button type="text" onClick={handleCancel}>{i18n.t("cancel")}</Button>
                <Button type="primary" onClick={handleSave}>{i18n.t("save")}</Button>
            </Row>
        </div>
    );
}