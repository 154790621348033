import { cloneDeep } from "lodash"
import { FlexFilter } from "util/types/types"

export function generateFlexFieldKey(flexFilter: FlexFilter[]) {
    const updatedData = Array(30)
    flexFilter.forEach(flex => {
        const position = flex.fieldCode - 1
        const flexFilterValue = flex.selectedOption.length === 0 ? '' : flex.selectedOption[0].value === 0 ? '' : flex.selectedOption[0].value
        updatedData[position] = flexFilterValue.toString()

    })
    return updatedData.join(':')
}

export function generateFlexFieldMultipleKey(flexFilter: FlexFilter[]): string[] {
	const allData: string[] = [];
	const updatedData = Array(30);
	flexFilter.forEach((flex, index) => {
		if (flexFilter.length - 1 === index) return;
		const position = flex.fieldCode - 1;
		const flexFilterValue = flex.selectedOption.length === 0 ? '' : flex.selectedOption[0].value === 0 ? '' : flex.selectedOption[0].value
		updatedData[position] = flexFilterValue.toString();
	})

	const lastFlexField: FlexFilter = flexFilter[flexFilter.length - 1];
	lastFlexField.selectedOption.forEach(({ value }) => {
		const flexData = cloneDeep(updatedData);
		flexData[lastFlexField.fieldCode - 1] = value;
		allData.push(flexData.join(':'));
	})

	return allData;
}