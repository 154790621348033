import { Icon } from "@iconify/react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { ImageBox } from "components/imageBox/ImageBox";
import { Notification } from "components/notification/Notification";
import { useState } from "react";
import i18n from "util/base/i18n";
import { validateFormField } from "util/functions/validateFormField";
import { ConsolidationTableData } from "../IConsolidation";

export default function ConsolidationModal({
    isModalVisible,
    isNewConsolidation,
    handleCancel,
    form,
    modalList,
    setModalList,
    handleSaveConsolidation,
    tableData,
    selectedRowKeys,
}) {
    const [editDisebled, setEditDisebled] = useState(false);

    const modalTitle = isNewConsolidation
        ? i18n.t("new_consolidation")
        : i18n.t("edit_consolidation");

    function handleIncludeConsolidationSubmit(data: ConsolidationTableData) {
        if (isNewConsolidation) {
            setModalList([...modalList, data]);
        } else {
            handleSaveConsolidation([data]);
        }
        form.resetFields();
    }

    function handleEditListItem(consolidation: ConsolidationTableData) {
        setEditDisebled(true);
        form.setFieldsValue({
            externalCode: consolidation.externalCode,
            description: consolidation.description,
        });
        handleDeleteListItem(consolidation.externalCode);
    }

    function handleDeleteListItem(externalCode) {
        const consolidationList = [...modalList];
        const i = consolidationList.findIndex((cc) => (cc.externalCode === externalCode));
        consolidationList.splice(i, 1);
        setModalList(consolidationList);
    }

    return (
        <Modal
            width={900}
            title={modalTitle}
            visible={isModalVisible}
            okButtonProps={modalList.length > 0 || !isNewConsolidation ? { htmlType: "submit", form: "new-account-form" } : { disabled: true }}
            onCancel={() => {
                handleCancel()
            }}
            className="gs-modal account-registration-modal template-modal"
            cancelText={i18n.t<string>("cancel")}
            okText={i18n.t<string>("save")}
            onOk={() => {
                if (isNewConsolidation) {
                    if (modalList.length > 0) {
                        handleSaveConsolidation(modalList);
                    } else {
                        Notification({
                            type: 'warning',
                            message: i18n.t("no_items_added_to_list")
                        })
                    }
                }
            }}
        >
            <Form
                form={form}
                name="new-account-form"
                className={isNewConsolidation ? "form-new-account" : ""}
                onFinish={(data) => {
                    setEditDisebled(false)
                    handleIncludeConsolidationSubmit(data)
                }}
                layout="vertical"
                requiredMark={false}
            >
                <Row gutter={20}>
                    <Col span={8}>
                        <Form.Item
                            name="externalCode"
                            label={i18n.t<string>("external_code")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                                () => ({
                                    validator(_, value: string) {
                                        return validateFormField(value, 'externalCode', tableData, modalList, i18n.t<string>("the_external_code_field_cannot_repeat"), selectedRowKeys[0]);
                                    },
                                })
                            ]}
                        >
                            <Input placeholder={i18n.t<string>("type_here")} disabled={!isNewConsolidation} />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            name="description"
                            label={i18n.t<string>("description")}
                            rules={[
                                { required: true, message: i18n.t<string>("required_field") },
                            ]}
                        >
                            <Input placeholder={i18n.t<string>("type_here")} />
                        </Form.Item>
                    </Col>
                </Row>
                {isNewConsolidation &&
                    <Button
                        style={{ marginTop: -10, marginLeft: "auto" }}
                        type="default"
                        htmlType="submit"
                        className="gs-secondary-button"
                        icon={<Icon icon="akar-icons:circle-plus-fill" />}
                    >
                        {i18n.t('addToList')}
                    </Button>}
            </Form>
            {isNewConsolidation &&
                (modalList.length > 0 ?
                    <>
                        <div className="new-account-table-head">
                            <Row align="middle" gutter={2}>
                                <Col span={8}>{i18n.t<string>("code")}</Col>
                                <Col span={14}>{i18n.t<string>("description")}</Col>
                                <Col span={1} />
                                <Col span={1} />
                            </Row>
                        </div>
                        <div className="new-account-table-body">
                            {modalList.map((cc) => {
                                return (
                                    <Row key={cc.externalCode} align="middle" gutter={2}>
                                        <Col span={8}>{cc.externalCode}</Col>
                                        <Col span={14}>{cc.description}</Col>
                                        <Col span={1}>
                                            <Button
                                                disabled={editDisebled}
                                                onClick={() => handleEditListItem(cc)}
                                                icon={<Icon className="edit-button" icon="material-symbols:edit-sharp" />}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <Icon
                                                className="delete-icon"
                                                onClick={() => handleDeleteListItem(cc.externalCode)}
                                                icon="fa6-solid:trash"
                                            />
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                    :
                    <ImageBox />
                )
            }
        </Modal>
    )
}
