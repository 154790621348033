import { Spin } from "antd";
import i18n from "util/base/i18n";
import { ILoading } from "./ILoading";
import './styles.sass'


export function Loading({ caption, styles }: ILoading) {
	return (
		<div className="loading-container" style={styles}>
			<Spin />
			<p>{caption ? caption : `${i18n.t<string>("loading")}...`}</p>
		</div>
	)
}