import { Tabs } from "antd";
import Content from "./Content";
import { useEffect, useState } from "react";
import Search from "antd/lib/transfer/search";
import i18n from "util/base/i18n";

interface Props {
	selectedSideTab: 'user' | 'group';
	selectedSideKey: number;
}

type PositionType = 'right';

export default function TabsContent({ selectedSideTab, selectedSideKey }: Props) {
	const [selectedTab, setSelectedTab] = useState<'fields' | 'professional-category'>("fields");
	const [searchValue, setSearchValue] = useState("");

	const searchSlot: Record<PositionType, React.ReactNode> = {
		right: (
			<div className="search-container">
				<Search
					value={searchValue}
					onChange={onChangeSearch}
					placeholder={i18n.t("search")}
				/>
			</div>
		),
	};

	useEffect(() => {
		setSearchValue("");
	}, [selectedTab]); 

	function onChangeSearch({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		setSearchValue(value);
	}

	return (
		<Tabs
			centered
			activeKey={selectedTab}
			onChange={(key: 'fields' | 'professional-category') => setSelectedTab(key)}
			className="tabs-sider tabs-table gs-tab"
			type="card"
			moreIcon=""
			tabBarExtraContent={searchSlot}
		>
			<Tabs.TabPane tab={i18n.t("fields")} key="fields">
				<Content
					getTableDataURL="fields/findAll"
					selectedContentTab={selectedTab}
					selectedSideKey={selectedSideKey}
					selectedSideTab={selectedSideTab}
					securityDataURL="field-security"
					searchValue={searchValue}
				/>
			</Tabs.TabPane>
			<Tabs.TabPane tab={i18n.t("professional_category.professional_category_name")} key="professional-category">
				<Content
					getTableDataURL="professional-category"
					selectedContentTab={selectedTab}
					selectedSideKey={selectedSideKey}
					selectedSideTab={selectedSideTab}
					securityDataURL="professional-category-security"
					searchValue={searchValue}
				/>
			</Tabs.TabPane>
		</Tabs>
	)
}