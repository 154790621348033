import { Icon } from "@iconify/react";
import { Checkbox, Input, Spin, Tree } from "antd";
import { Key, useState } from "react";
import i18n from "util/base/i18n";
import { ISidebarContent, LevelItem } from "../IFromTo";
const { Search } = Input;

export default function SidebarContent({
    treeData,
    costCenterList,
    checkedKeys,
    setCheckedKeys,
    expandedKeys,
    setExpandedKeys,
    isFetching,
    selectedTab
}: ISidebarContent) {

    const [search, setSearch] = useState('');

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target;
        setSearch(value);
    };

    function onCheck(checked: { checked: Key[]; halfChecked: Key[] }) {
        setCheckedKeys(checked.checked as number[]);
    }

    function onExpand(expandedKeys: number[], obj: { expanded: boolean }) {
        setExpandedKeys(expandedKeys)
    }

    function chooseIconExpanded(props: { expanded: boolean }) {
        return props.expanded ? <Icon icon="akar-icons:minus" /> : <Icon icon="akar-icons:plus" />;
    }

    function addButtonLines(line) {
        return (
            <span className='tree-title-content'>
                <p>
                    {line.title}
                </p>
            </span >
        )
    }

    function buildTreeData(data: LevelItem[], search: string): LevelItem[] {
        if (search.length === 0) return data;
        if (selectedTab !== "ledger_account") return;
        return [
            ...data.map((line) => {
                if (line.children) {
                    line.children = buildTreeData(line.children, search);

                    if (line.children.filter((child) => child).length === 0) {
                        if (!line.title.toLowerCase().includes(search)) {
                            line = undefined;
                        }
                    }
                } else {
                    if (!line.title.toLowerCase().includes(search)) {
                        line = undefined;
                    }
                }

                return line;
            }).filter((child) => child)
        ]
    }

    const filteredOptions = costCenterList?.filter(item =>
        item.description.toLowerCase().includes(search.toLowerCase())
    ).map(item => ({
        value: item.id,
        label: `${item.externalCode} - ${item.description}`
    }));

    function onChangeCostCenter(checkedValues) {
        setCheckedKeys(checkedValues);
    }

    return (
        <div className='sider-content'>
            <Search style={{ marginBottom: 8 }} placeholder={i18n.t("search")} onChange={onChange} />
            <Spin spinning={isFetching}>
                {selectedTab === "ledger_account" ?
                    (treeData.length > 0 ?
                        <Tree
                            checkStrictly
                            checkedKeys={checkedKeys}
                            switcherIcon={chooseIconExpanded}
                            treeData={buildTreeData(JSON.parse(JSON.stringify(treeData)), search.toLowerCase())}
                            showLine={{ showLeafIcon: false }}
                            checkable
                            onCheck={onCheck}
                            titleRender={addButtonLines}
                            disabled={isFetching}
                            expandedKeys={expandedKeys}
                            onExpand={onExpand}
                            selectable={false}
                        />
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 40 }}>
                            <Icon icon="clarity:tree-view-line" />
                            {i18n.t("groupings_texts.no_registered_level")}
                        </div>
                    ) :
                    <div>
                        <Checkbox.Group
                            options={filteredOptions}
                            value={checkedKeys}
                            onChange={onChangeCostCenter}
                        />
                    </div>
                }
            </Spin>
        </div>
    )
}