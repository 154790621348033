import { formatNumber } from "util/formatNumber";
import { AccountingOpeningTableColumnBean, ColumnGrid } from "../IAccountingLedger";

import moment from "moment";

export function defaultColumnsTable(i18n, columnData: AccountingOpeningTableColumnBean): ColumnGrid[] {
    return columnData.defaultColumns.map(column => {
        const commonProps: ColumnGrid = {
            key: column.key,
            title: i18n.t(column.key),
            width: 200,
            dataIndex: column.dataIndex,
            align: "center",
        };

        switch (column.key) {
            case "release_date":
                return {
                    ...commonProps,
                    width: 200,
                    render: (value: moment.Moment) => {
                        if (!value) return;
                        return moment(value).format("DD/MM/YY");
                    }
                };
                case "entry_value":
                    return {
                        ...commonProps,
                        width: 200,
                        render: (value: number) => {
                            if (!value) return;
                            return formatNumber(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                        }
                    };
            default:
                return commonProps;
        }
    });
}