import { Icon } from "@iconify/react";
import { Table } from "antd";
import { Key, useContext, useEffect, useState } from "react";
import { ReportViewContext } from "../../context/ReportViewContext";
import { Title } from "./header/Title";

export default function ReportTable() {
    const { report, defaultColumns, isDetailingLevel, isLoadingReport } = useContext(ReportViewContext);

    const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);

    function handleExpand(expanded, record) {
        if (expanded) {
            setExpandedRowKeys([...expandedRowKeys, record.key]);
        } else {
            setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
        }
    }

    function renderRow({ index, moveRow, className, style, ...restProps }) {
        const row = restProps.children[0]?.props.record;
        const backgroundColor = row && row.levelStyle && row.levelStyle.color ? row.levelStyle.color : "#fff";

        return (
            <tr
                {...restProps}
                style={{
                    backgroundColor: backgroundColor,
                }}
            />
        );
    }

    function renderCell({ children, ...restProps }) {
        const updatedClassName = restProps.className.replace("ant-table-cell-row-hover", "");

        let backgroundColor = "#fff";

        if (children[1]?.props?.currentRow?.rowStyle?.color) {
            backgroundColor = children[1].props.currentRow.rowStyle.color;
        }

        const updatedProps = {
            ...restProps,
            className: updatedClassName,
            style: {
                ...restProps.style,
                backgroundColor,
            },
        };

        return <td {...updatedProps}>{children}</td>;
    }

    const getAllTreeRowKeys = (rows: any[]) => {
        let keys: Key[] = [];

        rows.forEach(({ key, children }) => {
            if (children) {
                keys = keys.concat(getAllTreeRowKeys(children));
            }

            keys.push(key);
        });

        return keys;
    };

    useEffect(() => {
        if (isDetailingLevel && report) {
            setExpandedRowKeys(getAllTreeRowKeys(report));
        }
    }, [isDetailingLevel]);

    return (
        <Table
            title={() => <Title />}
            className="gs-table report-table"
            dataSource={report}
            columns={defaultColumns}
            onExpand={handleExpand}
            pagination={false}
            bordered={true}
            components={{
                body: {
                    row: renderRow,
                    cell: renderCell,
                },
            }}
            expandable={{
                indentSize: 15,
                defaultExpandAllRows: true,
                expandIcon: ({ expanded, onExpand, record }) => {
                    const { colorFont } = record?.levelStyle ? record.levelStyle : "black";

                    if (!expanded) {
                        return (
                            <span
                                className="button-expandable-tree"
                                onClick={(e) =>
                                    record.children && record.children.length > 0 ? onExpand(record, e) : null
                                }
                            >
                                {record.children && record.children.length > 0 && (
                                    <Icon icon="tabler:chevron-right" color={colorFont} />
                                )}
                            </span>
                        );
                    }

                    if (record.children && record.children.length > 0) {
                        if (expanded) {
                            return (
                                <span className="button-expandable-tree" onClick={(e) => onExpand(record, e)}>
                                    {record.children.length > 0 && (
                                        <Icon icon="tabler:chevron-down" color={colorFont} />
                                    )}
                                </span>
                            );
                        }

                        if (record.children.length > 0 && isDetailingLevel) {
                            return (
                                <span className="button-expandable-tree" onClick={(e) => onExpand(record, e)}>
                                    <Icon icon="tabler:chevron-right" color={colorFont} />
                                </span>
                            );
                        } else if (record.subLevels?.length > 0) {
                            return (
                                <span className="button-expandable-tree" onClick={(e) => onExpand(record, e)}>
                                    <Icon icon="tabler:chevron-right" color={colorFont} />
                                </span>
                            );
                        }
                    }
                },
            }}
            loading={isLoadingReport}
            scroll={{ x: true, y: "calc(100vh - 25em)" }}
        />
    );
}
