import { Icon } from "@iconify/react";
import i18n from "util/base/i18n";

interface Props {

	icon?: string

	title: string

	value?: number

	class?: string

	isMonetary: boolean

	children?: React.ReactElement

}

export default function Card(props: Props) {

	return (
		<div className="badge">
			<div className={`badge-content ${props.class || ''}`}>
				{props.icon && <Icon icon={props.icon} />}
				<div className="badge-text">
					<div className="badge-title">
						{props.title}
					</div>
					{props.value !== undefined &&
						<div className="badge-subtitle">
							{new Intl.NumberFormat(props.isMonetary ? "en" : i18n.language.replace("_", "-"), {
								notation: props.isMonetary ? "compact" : "standard"
							}).format(props.value)}
						</div>
					}
					{ props.children }
				</div>
			</div>
		</div>
	)
}