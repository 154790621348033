import { Icon } from "@iconify/react";
import { Button, Popover, Table, Tooltip } from "antd";
import { DatePicker } from "components";
import moment from "moment";
import {
	useEffect,
	useState
} from "react";
import i18n from "util/base/i18n";
import { RequestType } from "util/service/IServiceCaller";
import { ServiceCaller } from "util/service/ServiceCaller";
import { DefaultRecordType } from "rc-table/lib/interface"

import { IChartWidgetProps, IWidgetResponse } from "./IChartWidget";
import { IRowGrid } from "./IContractRepresentativeness";

import "./ContractRepresentativeness.sass";
import { FormattedNumber } from "react-intl";

import { LoadingOutlined } from "@ant-design/icons";
import GroupBy from "./GroupBy";

export default function ContractRepresentativeness(props: IChartWidgetProps) {

	const [visiblePopover, setVisiblePopover]: [boolean, Function] = useState(false);

	const [date, setDate]: [moment.Moment, Function] = useState(moment(props.endDate));

	const [filter, setFilter]: [string, Function] = useState(props.groupBy);

	const [dataGrid, setDataGrid]: [IRowGrid[], Function] = useState([]);

	const [shouldUpdateFilter, setShouldUpdateFilter]: [boolean, Function] = useState(false);

	const [loading, setLoading]: [boolean, Function] = useState(false);

	const [useCache, setUseCache]: [boolean, Function] = useState(true);

	const [cached, setCached]: [boolean, Function] = useState(false);
	const [cacheDate, setCacheDate]: [Date, Function] = useState(null);

	const columns: DefaultRecordType[] = [{
		key: "group_value",
		dataIndex: "groupValue",
		title: i18n.t<string>(filter.toLowerCase()),
		align: "center",
		sorter: ({ groupValue: groupValueA }, { groupValue: groupValueB }) => groupValueA.localeCompare(groupValueB),
		render: (groupValue: string) => groupValue || i18n.t<string>("uninformed")
	}, {
        key: "modality",
        dataIndex: "modality",
        title: i18n.t<string>("modality"),
        align: "center",
		defaultSortOrder: "ascend",
		sorter: ({ modality: modalityA }, { modality: modalityB }) => modalityA.localeCompare(modalityB)
    }, {
		key: "month",
		dataIndex: "month",
		title: i18n.t<string>("competency_date"),
		align: "center"
	}, {
		key: "applicationValue",
		dataIndex: "applicationValue",
		title: i18n.t<string>("net_balance"),
		align: "center"
	}, {
		key: "fundingValue",
		dataIndex: "fundingValue",
		title: i18n.t<string>("balance_due"),
		align: "center"
	}, {
		key: "representativeness",
		dataIndex: "representativeness",
		title: i18n.t<string>("representativeness"),
		render: (representativeness: number) => <FormattedNumber value={representativeness} style={`percent`} minimumFractionDigits={2} maximumFractionDigits={2} roundingPriority="morePrecision" />
	}];

	const handleVisibleChange = (visible: boolean) => {
		if (!loading) {
			setVisiblePopover(visible);
		}
	}

	function onLoadData(response: IWidgetResponse) {
		setCached(response.cached);
		setCacheDate(response.date);
		setDataGrid(response.data.map((item) => {
			return {
				...item,
				applicationValue: item.applicationValue === 0 ? "-" : <FormattedNumber value={item.applicationValue} style={`currency`} currencyDisplay="symbol" currency={item.isoCode} />,
				fundingValue: item.fundingValue === 0 ? "-" : <FormattedNumber value={item.fundingValue} style={`currency`} currencyDisplay="symbol" currency={item.isoCode} />,
				month: i18n.t<string>(`month.full.${item.month - 1}`)
			}
		}));

		setLoading(false);
		setUseCache(false);
	}

	const openPopover = () => {
		setVisiblePopover(true);
	}

	const onApplyGroupBy = (groupBy: string): void => {
		if (filter === groupBy) {
			setVisiblePopover(false);
			loadTable();
		} else {
			setFilter(groupBy);
			setShouldUpdateFilter(true);
		}
	};

	const doFilter: Function = () => {
		ServiceCaller.doRequest({
			url: "/dashboard-analytics/widget/filter",
			type: RequestType.PUT,
			params: {
				id: props.filterId,
				groupBy: filter,
				startDate: date.endOf("month").format("YYYY-MM-DD"),
				endDate: date.startOf("month").format("YYYY-MM-DD")
			}
		}, loadTable());

		setVisiblePopover(false);
	};

	const onChangeDate = (newDate: moment.Moment) => {
		if (date.isSame(newDate)) {
			loadTable();
		} else {
			setShouldUpdateFilter(true);
			setDate(newDate);
		}
	};

	const loadTable: Function = () => {
		setLoading(true);
		setDataGrid([]);

		const filterValuesQuery = props.globalFilter.values.map(value => `filterValue=${value}`).join("&");

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/dashboard-analytics/widget/financing/contract-representativeness?scenario={scenario}&client={client}&user={user}&locale={locale}&currency=${props.currencyId}&groupBy=${filter}&startDate=${date.startOf("month").format("YYYY-MM-DD")}&endDate=${date.endOf("month").format("YYYY-MM-DD")}&${filterValuesQuery}&filterBy=${props.globalFilter.filterBy}&useCache=${useCache}`
		}, onLoadData.bind(this))
	};

	useEffect(() => {
		if (shouldUpdateFilter) {
			doFilter();
		}
	}, [date, filter]);

	useEffect(loadTable.bind(this), [props.currencyId, props.globalFilter]);

	return (
		<div className="contract-representativeness-widget">
			<div className="widget-header-actions-container">
				{
					cached && <>
						<Tooltip title={ i18n.t<string>("dashboard.analytics.text_002", {date: new Intl.DateTimeFormat(i18n.language.replace("_", "-"), { dateStyle: "short", timeStyle: "medium" }).format(new Date(cacheDate))}) }>
							<Button onClick={loadTable.bind(this)} type="text" size="large" icon={<Icon icon={"mdi:reload-alert"} />} disabled={loading} data-html2canvas-ignore="true" />
						</Tooltip>
					</>
				}

				<Popover onVisibleChange={handleVisibleChange} placement={"bottomLeft"} visible={visiblePopover} overlayClassName="filter-popover" title={i18n.t<string>("filterBy")} content={<GroupBy value={filter} onApply={onApplyGroupBy} />} trigger="click" destroyTooltipOnHide={true}>
					<Button onClick={openPopover} type="text" size="large" icon={<Icon icon={"clarity:filter-solid"} />} disabled={loading} data-html2canvas-ignore="true" />
				</Popover>

				<DatePicker value={date} onChange={onChangeDate.bind(this)} format="MMM YYYY" formatEdit="MM/YYYY" picker="month" disabled={loading} />
			</div>
			<Table columns={columns} dataSource={dataGrid} pagination={false} scroll={{
				y: 275
			}} locale={{emptyText: loading ? <LoadingOutlined style={{fontSize: "40px", padding: "100px 0"}} /> : null}} />
		</div>
	)
}