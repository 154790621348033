import { Col, Row } from "antd";
import { ITransferFinalStepProps } from "../../IHiredDashboard";
import i18n from "util/base/i18n";
import TransferList from "./TransferList";


export default function TransferFinalStep({ transferList, deleteFunction, editFunction }: ITransferFinalStepProps) {

	return (
		<div className="final-step-transfer">
			<Row gutter={8}>
				<Col>
					<p className="gs-tag blue">
						{i18n.t("origin")}
					</p>
				</Col>
			</Row>
			<Row>
				<TransferList
					transferList={transferList}
					deleteFunction={deleteFunction}
					editFunction={editFunction}
					isOnFinalStep
					isOrigin={true}
				/>
			</Row>
			<Row gutter={8}>
				<Col>
					<p className="gs-tag blue">
						{i18n.t("destination")}
					</p>
				</Col>
			</Row>
			<Row>
				<TransferList
					transferList={transferList}
					deleteFunction={deleteFunction}
					editFunction={editFunction}
					isOnFinalStep
					isOrigin={false}
				/>
			</Row>
		</div>
	)
}